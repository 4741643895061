import React from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { eliminarPlantillaPersonalizadaEmpleadoArchivoAPI } from "../../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";

const EliminarNuevoRegistro = ({ toggleModal, row, detalleColaborador,setDetalleColaborador, uploadedDocumentosPersonalizadosFilesResponses, setUploadedDocumentosPersonalizadosFilesResponses }) => {

  const [managePost] = useManagePostRequest();

  const removeRow = async (e, id, i) => {
    e.preventDefault();
    try {
      let files = [...detalleColaborador.FilesPersonalizados];
      let documentos = {...uploadedDocumentosPersonalizadosFilesResponses};
      if(id != null){
        await managePost(eliminarPlantillaPersonalizadaEmpleadoArchivoAPI(id),{},()=>{
          files.splice(i, 1);
          setDetalleColaborador({ ...detalleColaborador, FilesPersonalizados: files });
          delete documentos[i];
          setUploadedDocumentosPersonalizadosFilesResponses(documentos);
        });
      } else{
        files.splice(i, 1);
        setDetalleColaborador({ ...detalleColaborador, FilesPersonalizados: files });
      }
      toggleModal();
    } catch (error) {
      console.error(error)
    }
  };

  return (
    <GenericModal titulo={"Eliminar"} showModal={toggleModal} width={"620px"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a eliminar el siguiente registro:
          <ul>
            <li>
              <strong>{row.title}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={(e) => removeRow(e,row.IdProcesoCierreEmpleadoArchivo,row.index)}
          >
            Eliminar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default EliminarNuevoRegistro;
