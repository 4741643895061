import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric} from '../../helper/utils';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";

 const AsyncSelectInputGET = ({api, placeholder, handleSelectInputChange, isDisabled, defaultValue}) => {
    const [getRequest] = useGetRequest();
    const defaultEmptyValue = { value: "", label: "" };
    const loadOptions = (inputValue, callback) => {
        if(!inputValue) {
            return callback([]);
        }
        setTimeout(async () => {
            let response = await getRequest(api(inputValue));
            if (responseCode200(response)) {
                let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response?.data, 'IdEmpleado', 'FullName');
                callback(options);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }, 1000);
    };

    const handleInputChange = (newValue) => {
        return newValue;
    };

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={handleInputChange}
                placeholder={placeholder}
                onChange={handleSelectInputChange}
                isDisabled={isDisabled}
                value={defaultValue}
                menuPosition='fixed' // check
            />
        </>
    );
}

export default AsyncSelectInputGET;