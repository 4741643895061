import styled from "styled-components";

import React from 'react'

const DotLoading = ({ rgbColor = "255, 255, 255" }) => {
  return (
    <Loading color={rgbColor}/>
  )
}

export default DotLoading;

const Loading = styled.span`
  margin: 0 15px 0 25px;;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: rgba(${({color}) => color});
  color: rgba(${({color}) => color});
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before, &::after{
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: rgba(${({color}) => color});
    color: rgba(${({color}) => color});
    animation: dot-flashing 1s infinite alternate;
  }

  &::before{
    left: -15px;
    animation-delay: 0s;
  }

  &::after{
    left: 15px;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: rgba(${({color}) => color});
    }
    50%, 100% {
      background-color: rgba(${({color}) => color}, 0.2);
    }
  }
`