import dotenv from 'dotenv';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import store from 'store';
import {PHOTO_URL, EMAIL} from '../constants';

import {validateLogin} from '../consumers/backendConsumer';

dotenv.config();

firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
});

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    auth
        .signInWithPopup(googleProvider)
        .then((res) => {
            if (res.user.email !== null) {
                validateLog(res.user.email, res.user.photoURL);
                store.set(EMAIL, res.user.email);
                store.set(PHOTO_URL, res.user.photoURL);
            }
        })
        .catch((error) => {
            console.error(error.message);
        });
};

const validateLog = async (email, photo) => {
    try {
        const response = await validateLogin(email, photo);
    } catch (e) {
        console.error('error', e);
    }
};
