import React, { useState, useEffect } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { getFormatoPreviewDataAPI } from "../../../consumers/backendApisUrls";
import styled from "styled-components";
import { Spinner } from "react-bootstrap";

const Firma = styled.img`
  max-height: 60px;
  position: relative;
`;

const DivDatoPreview = styled.div`
    width: ${props => props.width ?? "200px"};;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    top: ${props => props.top ?? ""};
    bottom: ${props => props.bottom ?? ""};
    left: ${props => props.left ?? ""};
    right: ${props => props.right ?? ""};
`;

const PreviewFormato = ({ showModal, idSolicitudMovimiento }) => {
  const [dataPreviewFormato, setDataPreviewFormato] = useState({});
  const [loading, setLoading] = useState(true);

  const [executeGet] = useManageGetRequest();

  const init = async () => {
    setLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const manageSearch = async () => {
    let api = getFormatoPreviewDataAPI(idSolicitudMovimiento);
    await executeGet(api, successManageSearchCallback, false);
  };

  const successManageSearchCallback = (res) => {
    let data = res.data;
    setDataPreviewFormato(data);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
    );
  }

  return (
    <GenericModal
      showModal={showModal}
      titulo={"Preview Formato"}
      width="1100px"
    >
      <div className="modal-edit-card-preview-container ml-0 w-auto p-5">
        <div className="card second modal-edit-card-preview justify-content-between align-items-center w-auto h-100 p-0">
          {loading 
          ? <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
          : <>
              <img className="w-100 h-auto" src={dataPreviewFormato?.FormatoImage}/>
              <DivDatoPreview id="Firma" bottom="45px" left="394px"><Firma src={dataPreviewFormato?.Firma}/></DivDatoPreview>
              <DivDatoPreview id="ApellidoPaterno" top="118px" left="62px">{dataPreviewFormato?.ApellidoPaterno}</DivDatoPreview>
              <DivDatoPreview id="ApellidoMaterno" top="118px" left="305px">{dataPreviewFormato?.ApellidoMaterno}</DivDatoPreview>
              <DivDatoPreview id="Nombres" width="150px" top="118px" right="250px">{dataPreviewFormato?.Nombres}</DivDatoPreview>
              <DivDatoPreview id="Cargo" top="118px" right="40px">{dataPreviewFormato?.Cargo}</DivDatoPreview>
              <DivDatoPreview id="FechaIngreso" top="147px" left="118px">{dataPreviewFormato?.FechaIngreso}</DivDatoPreview>
              <DivDatoPreview id="Sede" width="130px" top="147px" left="390px">{dataPreviewFormato?.Sede}</DivDatoPreview>
              <DivDatoPreview id="Codigo" width="80px" top="147px" right="245px">{dataPreviewFormato?.Codigo}</DivDatoPreview>
              <DivDatoPreview id="NumeroDocumento" width="90px" top="147px" right="40px">{dataPreviewFormato?.NumeroDocumento}</DivDatoPreview>
              <DivDatoPreview id="FechaInicio" top="334px" left="288px">{dataPreviewFormato?.FechaInicio}</DivDatoPreview>
              <DivDatoPreview id="FechaFin" top="334px" right="90px">{dataPreviewFormato?.FechaFin}</DivDatoPreview>
              <DivDatoPreview id="FechaRegistro" width="120px" bottom="65px" left="35px">{dataPreviewFormato?.FechaRegistro}</DivDatoPreview>
              <DivDatoPreview id="Observaciones" className="justify-content-start" width="840px" top="73%" left="45px">{dataPreviewFormato?.Observaciones}</DivDatoPreview>
              <DivDatoPreview id="Observaciones" className="justify-content-start" width="840px" top="75%" left="45px">{dataPreviewFormato?.DetalleVaciones}{dataPreviewFormato?.DetalleVacionesExtra}{dataPreviewFormato?.TotalVaciones}</DivDatoPreview>
              <DivDatoPreview id="AprobadoPor" className="text-center" width="220px" bottom="70px" left="160px">{dataPreviewFormato?.AprobadoPor}</DivDatoPreview>
              <DivDatoPreview id="FechaAprobacion" bottom="55px" left="170px">{dataPreviewFormato?.FechaAprobacion}</DivDatoPreview>
              <DivDatoPreview id="GdhAprobadoPor" className="text-center" width="220px" bottom="70px" right="75px">{dataPreviewFormato?.GdhAprobadoPor}</DivDatoPreview>
              <DivDatoPreview id="GdhFechaAprobacion" bottom="55px" right="85px">{dataPreviewFormato?.GdhFechaAprobacion}</DivDatoPreview>
            </>
          }
        </div>
      </div>
    </GenericModal>
  );
};

export default PreviewFormato;
