import React, { useContext, useState } from "react";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import GenericUploadFileV2 from "../GenericUploadFile/GenericUploadFileV2";
import GenericModal from "./GenericModal";
import { useGenerarDescargarEliminarArchivo } from "../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import { jsonEstaVacio, jsonNoEstaVacio } from "../../helper/utils";

const ImportFileGenericModal = ({ api, id, additionalPayload = {}, validExtensions, tittle = "Cargar archivo", toggleModal, refreshPage, hasDownloadFormat, apiDownloadFormat, children }) => {

  const [uploadFileHelpDesk, setUploadFileHelpDesk] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloadingFormat, setIsDownloadingFormat] = useState(false);

  const [executePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        let payload = jsonNoEstaVacio(additionalPayload) ? {...additionalPayload, FileViewModel: uploadFileHelpDesk} : uploadFileHelpDesk;
        await executePost(api, payload, successSubmitCallback);
    } catch (error) {
        console.error(error)
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
        Path: uploadFileHelpDesk.Path,
        OriginalFileName: uploadFileHelpDesk.OriginalFileName
    }
  }

  const handleDownload = async () => {
    setIsDownloadingFormat(true);
    try {
      await downloadAndDelete(apiDownloadFormat(), {})
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloadingFormat(false);
    }
  }

  const successSubmitCallback = async () => {
    refreshPage && await refreshPage();
    toggleModal();
  }

  return (
    <GenericModal
      showModal={toggleModal}
      width={"650px"}
      titulo={tittle}
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
            <div className="form-group row mb-4">
                <label className="col-sm-2 col-form-label modal-label">
                  Archivo
                </label>
                <div className="col-sm-10 px-0 d-flex justify-content-center">
                    <GenericUploadFileV2
                        uploadFileToS3={setUploadFileHelpDesk}
                        id={id}
                        textButton={"Adjuntar archivo"}
                        extensionesValidas={validExtensions}
                        showToastSuccess={false}
                    />
                </div>
            </div>
            <div className="modal-footer justify-content-end">
              <div className="d-flex justify-content-between" style={{gap: "15px"}}>
                {children}
                {hasDownloadFormat && 
                  <button type="button" className="btn generic-button-buscador" disabled={isDownloadingFormat} onClick={handleDownload}>
                    Descargar Formato
                  </button>
                }
                <button
                    type="submit"
                    className="btn generic-button-buscador"
                    disabled={isSubmitting || jsonEstaVacio(uploadFileHelpDesk)}
                    >
                    Cargar
                </button>
              </div>
            </div>
        </form>
      </div>
    </GenericModal>
  );
}

export default ImportFileGenericModal