import React, {useContext, useEffect, useState} from 'react';
import SeccionViewCurriculum from './SeccionViewCurriculum'
import SeccionViewExperienciaLaboral from './SeccionViewExperienciaLaboral'
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {AuthContext} from "../../hooks/context/authContext";

const DatosExperienciaLaboral = ({ empleadoId, puedoEditar}) => {
    const {state} = useContext(AuthContext);
    const [idActualizado, setId] = useState();

    function updateId () {
        if (empleadoId)
            setId(empleadoId);
        else
            setId(state.user.id_empleado);
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    if(idActualizado)
    return (
        <div>
            <div className='card-body'>
                <SeccionViewCurriculum empleadoId={idActualizado} puedoEditar={puedoEditar} />
            </div>
            <div className='card-body'>
                <SeccionViewExperienciaLaboral empleadoId={idActualizado} puedoEditar={puedoEditar}/>
            </div>
        </div>
    );
    return null;
};

export default DatosExperienciaLaboral;
