import React, { useEffect, useState } from 'react'
import Wrapper from '../../../components/atoms/DirectorioWrapper';
import PageHeader from '../../../components/pageHeader/PageHeader';
import EditarPlantilla from './modales/EditarPlantilla';
import TableWebBody from './TableWebBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { formNames } from './formNames';
import { Spinner } from 'react-bootstrap';
import { getAllConfiguracionesAPI, getCorreosTemplatesAPI, getPdfTemplatesAPI, getTiposPlantillaAPI, liquidacionesEliminarConfiguracionAPI } from '../../../consumers/backendApisUrls';
import { setStateWithCallBackResponse } from '../../../helper/utils';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';

const ConfigurarProceso = () => {
  const history = useHistory();
  const [hideModalEditar, setHideModalEditar] = useState(false);
  const [managePostRequest] = useManagePostRequest();

  const [loading, setLoading] = useState(true);
  const [configuraciones, setConfiguraciones] = useState({});
  const [editarFila, setEditarFila] = useState({});
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [refreshTable, setRefreshTable] = useState(false);

  const toggleEditarModal = (fila) => {
    setHideModalEditar(!hideModalEditar);
    setEditarFila(fila);
  };

  const getInitialData = async () => {
    setLoading(true)
    try {
      await setStateWithCallBackResponse(executeGetRequestCallback(getAllConfiguracionesAPI()), setConfiguraciones);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getInitialData();
  }, [refreshTable])

  const eliminar = async function (idConfiguracion) {
    await managePostRequest(liquidacionesEliminarConfiguracionAPI(), {IdConfiguracionLiquidaciones: idConfiguracion}, (response)=> setRefreshTable(prev => !prev) );
  }

  if (loading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {hideModalEditar &&
          <div className="modal-contactanos modal-response-ficha-background">
            <EditarPlantilla
              toggleModal={toggleEditarModal}
              fila={editarFila}
              refreshTable={() => setRefreshTable(prev => !prev)}
            />
          </div>
        }
        <Wrapper className='App'>
          <PageHeader title={"Configurar proceso de liquidación"} />
          <div className="generic-buscador-container">
            <div className="filtros-left-section">
              <button className='generic-button-buscador' onClick={() => history.goBack()}>
                <FontAwesomeIcon icon='angle-left' size="lg" className="hand-pointer" title="Atras" /> Atrás
              </button>
            </div>
            <div className="filtros-right-section">
              <button
                onClick={(e) => toggleEditarModal(null)}
                type='button'
                className='generic-button-buscador'>
                Adicionar plantilla
              </button>
            </div>
          </div>
          <div className='container-fluid body-content'>
            <div className='table-responsive form-group-sm pt-3'>
              <table className='table table-hover listar-table-cierre-anio'>
                <thead className="listar-thead">
                  <tr>
                    <th className="col-2 pl-1 pr-1">
                      <span className='font-gothan-medium d-inline'>Tipo de plantilla</span>
                    </th>
                    <th className="col-2 pl-1 pr-1">
                      <span className='font-gothan-medium d-inline'>Nombre plantilla</span>
                    </th>
                    <th className="col-4 pl-1 pr-1">
                      <span className='font-gothan-medium d-inline'>Nombre archivo generado</span>
                    </th>
                    <th className="col-2 pl-5 pr-1 text-center">
                      <span className='font-gothan-medium d-inline'>Cargar archivo</span>
                    </th>
                    <th className="col-1 pl-1 pr-1">
                      <span className='font-gothan-medium d-inline'>Acciones</span>
                    </th>
                  </tr>
                </thead>
                <TableWebBody configuraciones={configuraciones} editar={toggleEditarModal} eliminar={eliminar} refreshTable={() => setRefreshTable(prev => !prev)}/>
              </table>
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  )
}

export default ConfigurarProceso
