import { useEffect } from 'react';

function GoogleAnalyticsScript() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src =  `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
    script.async = true;

    const initializeGA = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

      // Envía la página inicial como pageview al cargar la aplicación
      gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_title: document.title,
        page_search: window.location.search,
      });
    };

    script.onload = initializeGA;
    document.head.appendChild(script);
  }, []);

  return null;
}

export default GoogleAnalyticsScript;