import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';

const TableWebBodyDirectorio = ({filas, toggleEditarSedeModal}) => {

    return (
        <tbody className="listar-tbody">
        {filas.map((user, i) => (
            <tr key={i}>
                <td>{user.Sede}</td>
                <td>{user.NombreDepartamento}</td>
                <td>{user.NumeroColegio}</td>
                <td>{user.Correo}</td>
                <td>{user.Ubicacion}</td>
                
                <td style={{width: '100px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <div style={{display: 'flex', marginRight: '5px'}}>
                            <FontAwesomeIcon icon='edit' transform="left-1" className="hand-pointer blue-standard-color" title={`Editar sede de '${user.Sede}'`} onClick={() => {
                                        toggleEditarSedeModal(user?.IdSede)
                                    }} />
                        </div>
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyDirectorio;
