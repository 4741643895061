import React from "react";

const InputMultiCheckbox = ({
  label,
  options,
  values,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
}) => {
  const handleCheck = (data) => {
    let idRaw = parseInt(data.target.value);
    let id = isNaN(idRaw) ? data.target.value : idRaw;
    let isCheked = data.target.checked;
    let temp = isCheked
      ? [...(values ?? ""), id]
      : values.filter((o) => o !== id);
    let selected = {
      target: {
        value: temp,
        name: name,
      },
    };
    onChange(selected);
  };

  return (
    <>
      <div className="form-group">
        <label className={`col-sm-12 pl-1 col-form-label modal-label ${isRequired ? "required" : ""}`}>
          {label}
        </label>
      </div>
      <div className="form-group d-flex flex-column align-items-center overflow-y-scroll" style={{maxHeight: "360px"}}>
        {options.map((option, i) => (
          <div
            key={`${option.label}-${i}-${option.value}`}
            className="col-sm-9 black-color scrollBarAsignarTipoContrato d-flex align-items-start mb-3"
          >
            <input
              id={`${option.label}-${i}-${option.value}`}
              type="checkbox"
              name={name}
              value={option.value}
              checked={values?.includes(option.value) ?? false}
              onChange={handleCheck}
              disabled={isDisabled}
              className="mt-2"
            />
            <label
              className="black-color font-gothan-light pl-3"
              htmlFor={`${option.label}-${i}-${option.value}`}
            >
              {option.label}
            </label>
          </div>
        ))}
        <div className="col-sm-9">
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-${name}-validation-message`}
          ></p>
        </div>
      </div>
    </>
  );
};

export default InputMultiCheckbox;
