import React, { useState } from 'react'
import ModalForm from '../../../../../components/modalComponents/ModalForm';
import InputDate from '../../../../../components/modalComponents/InputDate';
import InputNumber from '../../../../../components/modalComponents/InputNumber';
import InputEditorHtml from '../../../../../components/modalComponents/InputEditorHtml';
import InputMultiSelect from '../../../../../components/modalComponents/InputMultiSelect';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { updateCuponTiempoLibreAPI } from '../../../../../consumers/backendApisUrls';
import { handleFind, handleFindDropdownMultiSelected } from '../../../../../helper/utils';

const EditarCuponAsignado = ({ toggleModal, refreshPage, row, dropdowns }) => {

  const SECTION_NAME = "editarPlantillasCupon";
  const KEYS = {lineamientos: "LineamientosHTML", desde: "FechaInicio", hasta: "FechaFin", cantidadDias: "CantidadDias", idsCargos: "IdsCargos"};
  const REQUERIMIENTO_KEYS = { fecha: "Fecha", cargos: "Cargos", cantidadDias: "CantidadDias" };

  const [formValues, setFormValues] = useState(row);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitForm] = useSubmitForm();
  const [onChange] = useForm(formValues, setFormValues);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const api = updateCuponTiempoLibreAPI();
    const payload = getPayloadEditCupon(formValues);
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(e)
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  const getPayloadEditCupon = (cupon) => {
    const { IdCuponTiempoLibre, LineamientosHTML, FechaInicio, FechaFin, CantidadDias, IdsCargos } = cupon;
    return { IdCuponTiempoLibre, LineamientosHTML, FechaInicio, FechaFin, CantidadDias, IdsCargos};
  }  

  return(
  <ModalForm showModal={toggleModal} onSubmit={handleSubmit} titulo="Editar cupón" width="700px" isSubmitting={isSubmitting}>
    <div className="col-sm-12"><InputEditorHtml label="Lineamientos" name={KEYS.lineamientos} value={formValues[KEYS.lineamientos]} onChange={onChange} sectionName={SECTION_NAME} direction='column' minHeight="5rem"/></div>
    {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.fecha) &&
      <div className='displayFlexWrap'>
        <div className="col-sm-6"><InputDate label="Desde" name={KEYS.desde} value={formValues[KEYS.desde]} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
        <div className="col-sm-6"><InputDate label="Hasta" name={KEYS.hasta} value={formValues[KEYS.hasta]} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
      </div>
    }
    {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.cantidadDias) &&
      <div className='displayFlexWrap'>
        <div className="col-sm-12"><InputNumber label="Cantidad de días" name={KEYS.cantidadDias} value={formValues[KEYS.cantidadDias]} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
      </div>
    }
    {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.cargos) &&
      <div className="col-sm-12"><InputMultiSelect label="Cargos" options={handleFind(dropdowns, KEYS.idsCargos)} name={KEYS.idsCargos} value={handleFindDropdownMultiSelected(dropdowns, formValues, KEYS.idsCargos)} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
    }
  </ModalForm>
  )
}

export default EditarCuponAsignado