import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {useForm} from "../../../hooks/useForm/UseForm";
import {toast} from "react-toastify";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {registrarRolAPI} from "../../../consumers/backendApisUrls";

const RegistrarRolModal = ({showModal}) => {
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const seccionName = "addCeseModal";
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const submitRegistrarRol = async () => {
        let payload = {
            "Nombre": formValues["Nombre"],
            "Descripcion": formValues["Descripcion"],
        };

        console.debug("payload ", payload);
        let response = await executePostRequest(registrarRolAPI(), payload);

        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showModal();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["Nombre", "Descripcion"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, seccionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const init = () => {
        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        return {
            "Nombre": "",
            "Descripcion": "",
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"750px"} titulo={"Registrar Rol"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-2 col-form-label modal-label">Nombre<span className="required">*</span></label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="Nombre" onChange={handleChange} value={formValues.Nombre} maxlength="25" placeholder={"Máximo 25 caracteres."}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Nombre-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-2 col-form-label modal-label">Descripción<span className="required"></span></label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" name="Descripcion" value={formValues.Descripcion} onChange={handleChange} maxlength="50" placeholder={"Máximo 50 caracteres."}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Descripcion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-2 col-form-label modal-label">Es Activo<span className="required"></span></label>
                                <div className="col-sm-10">
                                    <input className="input-checkbox-margin-top" type="checkbox" checked={true} disabled={true}></input>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light">
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha-modal" onClick={submitRegistrarRol}>Guardar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default RegistrarRolModal;