import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { initialAllOptionPromises } from '../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { usePropsSelect } from '../../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { AuthContext } from '../../../../hooks/context/authContext';
import InputSelect from '../../../../components/modalComponents/InputSelect'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText';
import { DivInput } from '../../../../components/atoms/DivInput';
import InputEditorHtml from '../../../../components/modalComponents/InputEditorHtml';
import { downloadFile } from '../../../../consumers/backendConsumer';
import GenericUploadFileV2 from '../../../../components/GenericUploadFile/GenericUploadFileV2';
import { getAllCargosAPI, crearPerfilPuestoAPI } from '../../../../consumers/backendApisUrls';
import SlideButton from '../../../../components/atoms/SlideButton';

const CrearPerfilPuesto = ({ toggleModal, refreshPage }) => {

  const SECTION_NAME = "crearPerfilPuesto";
  const KEYS_DROPDOWN = {cargo: "IdCargo"};
  const KEYS = ["NombrePerfilPuesto", "IdCargo", "LinkAccesoPerfil", "Funciones", "File"];

  const [formValues, setFormValues] = useState({});
  const [dropdowns, setDropowns] = useState([]);
  const [buttonState, setButtonState] = useState(false);

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(dropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      getOptions(getAllCargosAPI())
    ]
  }

  const init = async () => {
    initialAllOptionPromises(initialPromises, Object.values(KEYS_DROPDOWN), setDropowns)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = crearPerfilPuestoAPI();
    await submitForm(KEYS, api, formValues, SECTION_NAME, successSubmitCallback);
  }

  const successSubmitCallback = () => {
    toggleModal();
    refreshPage()
  }



  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Crear Perfil" width="750px">
      <InputText isRequired label='Nombre perfil de puesto' {...commomProps('NombrePerfilPuesto')}/>
      <InputSelect isRequired label='Cargo' {...selectProps('IdCargo')}/>
      <InputTwoOptions label={buttonState ? "Archivo" : "Url"} {...commomProps(buttonState ? "File" : "LinkAccesoPerfil", buttonState ? ["LinkAccesoPerfil"] : ["File"])} buttonState={buttonState} setButtonState={setButtonState}/>
      <InputEditorHtml label='Funciones (HTML)' placeholder='Ingresar funciones' {...commomProps('Funciones')} direction='column'/>
    </ModalForm>
  )
}

export default CrearPerfilPuesto

const InputTwoOptions = ({
  label,
  name,
  value,
  onChange,
  sectionName,
  colLabel = 4,
  colInput = 8,
  isIcon,
  placeholder = `Ingresar ${label}`,
  direction = "row",
  buttonState,
  setButtonState
}) => {
  const { state } = useContext(AuthContext);
  const VALID_EXTENSIONS = ["pdf"];

  const setUploadFile = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  }

  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      <label className='pt-1'>
        <SlideButton value={buttonState} onChange={setButtonState} nameOne="Url" nameTwo="Archivo"/>
      </label>
      <div className={`black-color`}>
        {buttonState
          ? <>{value?.Path ? (
            <div className='mt-1'>
              <a
                className="mx-4 hand-pointer blue-standard-color"
                onClick={() => {
                  downloadFile(
                    state.token,
                    value?.Path,
                    value?.OriginalFileName
                  );
                }}
              >
                {isIcon ? 
                  <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload} className="green-innova"/> :
                  <>
                    <FontAwesomeIcon icon="paperclip" transform="left-9" />
                    {value?.OriginalFileName}
                  </>
                }
              </a>
              <FontAwesomeIcon
                icon="trash"
                size="lg"
                title="Eliminar"
                className="hand-pointer blue-standard-color"
                onClick={() => setUploadFile({})}
              />
            </div>
          ) : (
            <div className="">
              <GenericUploadFileV2
                id="UploadFilePerfilPuesto"
                extensionesValidas={VALID_EXTENSIONS}
                uploadFileToS3={setUploadFile}
                textButton="Subir Archivo"
                resetInitialFile={false}
                isIcon={isIcon}
              />
            </div>
          )}
          </>
          : <input
              type="text"
              placeholder={placeholder}
              className="form-control"
              name={name}
              onChange={onChange}
              value={value ?? ""}
            />
        }
        {sectionName && name && (
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-${name}-validation-message`}
          ></p>
        )}
      </div>
    </DivInput>
  )
}