import React, {useContext, useEffect, useState} from 'react';
import {downloadFile} from "../../consumers/backendConsumer";
import {AuthContext} from "../../hooks/context/authContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import {jsonNoEstaVacio, notNullAndNotUndefinded} from "../../helper/utils";
import {ESTADOS_ACTUALIZACION_FICHA} from "../../constants/hardCodedConstants";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getInformacionResidenciaAPI} from "../../consumers/backendApisUrls";

const SeccionViewDatosDeResidencia = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion}) => {
    const {state} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosResidencia, setDatosResidencia] = useState({});
    const [hiddenFields, setHiddenFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [statusUpdateResidencia, setStatusUpdateResidencia] = useState({});
    const [executeGetRequest] = useGetRequest();

    const updateId = () => {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    };

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        mostrarSoloCamposQueNoSeanHidden();
    }, [hiddenFields]);

    useDidMountEffect(() => {
        console.debug("[FichaDatosSeccionTitleMessage] statusUpdate new: ", statusUpdateResidencia);
        if (notNullAndNotUndefinded(statusUpdateResidencia) && jsonNoEstaVacio(statusUpdateResidencia)) {
            manageStatusUpdateResidencia();

        }
    }, [statusUpdateResidencia]);

    const manageStatusUpdateResidencia = function () {
        if (statusUpdateResidencia.IdEstado === ESTADOS_ACTUALIZACION_FICHA.observado.id ||
            statusUpdateResidencia.IdEstado === ESTADOS_ACTUALIZACION_FICHA.pendiente.id) {
            changeViewLabelAndValueColorToRed();
        } else {
            changeViewLabelAndValueColorToDefaultBlack();
        }
    };

    const changeViewLabelAndValueColorToRed = function () {
        console.debug("changeViewLabelAndValueColorToRed");
        document.querySelector(`#frmInfoDatosDeResidencia`).classList.add("change-requested-field");
    };

    const changeViewLabelAndValueColorToDefaultBlack = function () {
        console.debug("changeViewLabelAndValueColorToDefaultBlack");
        document.querySelector(`#frmInfoDatosDeResidencia`).classList.remove("change-requested-field");
    };

    const getStatusUpdateResidencia = function (idEmpleadoo, response) {
        try {
            if (idEmpleadoo) {
                if (responseCode200(response)) {
                    setStatusUpdateResidencia(response.data.validationData);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            } else {
                console.warn("id de empleado no válido: ", idEmpleadoo);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                const infoResidencia = await executeGetRequest(getInformacionResidenciaAPI(idActualizado));
                if (responseCode200(infoResidencia)) {
                    setDatosResidencia(infoResidencia.data.data);
                    setHiddenFields(infoResidencia.data.data.HiddenFields);
                    getStatusUpdateResidencia(idActualizado, infoResidencia);
                } else {
                    mostrarMensajeDeErrorConToast(infoResidencia);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const mostrarSoloCamposQueNoSeanHidden = () => {
        try {
            for (const field of hiddenFields) {
                let elementView = document.getElementById(`ficha-view-datosDeResidencia-${field}`);
                if (elementView) {
                    elementView.setAttribute("hidden", "");
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const actualizar = async () => {
        try {
            setShowViewSeccion(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}

            <form id='frmInfoDatosDeResidencia' hidden={loading}>
                <div className='displayFlexWrap'>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdTipoVivienda">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Tipo de vivienda:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreTipoVivienda}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdTipoVia">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Tipo de vía:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreTipoVia}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-NombreVia">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Nombre de vía:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreVia}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-NumeroCasa">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Número Casa:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NumeroCasa}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-NumeroInterior">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Numero de Interior:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NumeroInterior}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-Manzana">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Manzana:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.Manzana}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-Lote">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Lote:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.Lote}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-Etapa">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Etapa:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.Etapa}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-Block">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Block:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.Block}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdTipoZona">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Tipo de zona:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreTipoZona}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-NombreZona">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Nombre de zona:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreZona}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdDepartamento">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Departamento:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreDepartamento}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdProvincia">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Provincia:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreProvincia}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdDistrito">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Distrito:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.NombreDistrito}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-Ubigeo">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Ubigeo:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.Ubigeo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-DescripcionUbigeo">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            Descripción de Ubigeo:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.DescripcionUbigeo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeResidencia-IdPaisResidencia">
                        <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                            País de residencia:
                        </label>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosResidencia && <p>{datosResidencia.PaisResidencia}</p>}
                        </label>
                    </div>
                </div>

                <div style={{display: 'flex', 'justifyContent': 'flex-end', 'marginTop':'20px'}}>
                    {datosResidencia && datosResidencia.FileViewModel &&
                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'
                            onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, datosResidencia.FileViewModel.Path, datosResidencia.FileViewModel.OriginalFileName);
                            }}>
                            Descargar Declaración Jurada
                        </button>
                    </div>
                    }
                    {datosResidencia && datosResidencia.CanSeeButtonEditar === true &&
                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            onClick={actualizar}
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'>
                            Actualizar
                        </button>
                    </div>
                    }
                </div>
                <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
                    <div style={{'alignSelf': 'flex-start'}}>
                        <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/><p className="ficha-residencia-ver-info-icon-mensaje">La actualización de tu dirección será considerada como una declaración jurada.</p>
                    </div>
                    <div style={{'alignSelf': 'flex-end'}}></div>
                </div>
            </form>
        </>
    );
};

export default SeccionViewDatosDeResidencia;