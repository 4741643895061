import React, { useContext, useEffect, useState } from 'react';
import DirectorioWrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import ColoredCard from '../../components/cards/ColoredCard';
import { listarBeneficiosAPI } from '../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';

const Beneficios = () => {
    const [manageGetRequest] = useManageGetRequest();
    const [managePostRequest] = useManagePostRequest();

    const [page, setPage] = useState(1);
    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");

    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);

    const [allBeneficios, setAllBeneficios] = useState([]);
   
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
          getInitialData();
        }
    
        return () => {
          unmounted = true;
        };
    }, [reload]);

    const getInitialData = async () => {
        setLoading(true);
        try {
          await manageSearch();
        } catch (e) {
          console.error(e);
        }
    

        setLoading(false);
      };

    const manageSearch = async () => {
        setLoading(true);
        const payload = obtenerPayload();
        await executeSearch(payload);
    };
    
    const obtenerPayload = function () {
        let payload = {
          "Criteria": criterioDeBusqueda,
          "Page": page,
        };
        return payload;
      }

    const executeSearch = async (payload) => {

        try {
        setAllBeneficios([]);
          await managePostRequest(listarBeneficiosAPI(), payload, handleResponseSuccess, false);
        } catch (e) {
          console.error(e);
        }
      };

     const handleResponseSuccess = function (response) {
        setAllBeneficios(response.data.Items);
        //setTotalItems(response.data.TotalItems);
        //setFirsItemPage(response.data.FirsItemPage);
        //setLastItemPage(response.data.LastItemPage);
        //setTotalPages(response.data.TotalPages);
      }

    const handleCardClick = (benefits) => {
        window.open(benefits.UrlBeneficios, '_blank');
      }

    return (
        <DirectorioWrapper className='App'>
            <PageHeader title="Beneficios" />
            <div className='container-fluid body-content'>
                <div className="d-flex justify-content-center">
                    <div className='mt-2 mb-4 w-75 d-flex align-content-center justify-content-center flex-wrap'>
                        {allBeneficios.map(benefits => (
                            <ColoredCard
                                key={benefits?.IdBeneficios}
                                title={benefits.NombreBeneficios}
                                color={benefits.Color}
                                height={"14rem"}
                                tooltip="haz click aquí"
                                onClick={() => handleCardClick(benefits)}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </DirectorioWrapper>
    )
}

export default Beneficios;