import React, { useState, useEffect } from 'react'
import TableWebBody from './TableWebBody'
import PageTable from '../../../../components/pageComponents/PageTable'
import PageWrapper from '../../../../components/pageComponents/PageWrapper'
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled'
import SearchContainer from '../../../../components/pageComponents/SearchContainer'
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect'
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest'
import { vacacionesColaboradorIndexHistoricoAPI } from '../../../../consumers/backendApisUrls'

const ListaHistoricoVacaciones = () => {

  const [formValues, setFormValues] = useState({Items: [], ExtraData: {}, filters: [], selectedFilters: {}, EsOrdenFechaInicioAscendente: true});

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({isEnable: false});

  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = vacacionesColaboradorIndexHistoricoAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback)
  }

  const getPayload = () => {
    return {
      EsOrdenFechaInicioAscendente: formValues.EsOrdenFechaInicioAscendente,
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const HEADERS_TABLE = [
    {col: "", label: "Colaborador"},
    {col: "", label: "Cargo"},
    {col: "", label: "Sede"},
    {col: "", label: "Área"},
    {col: "col-sm-1", label: 
      <>
        <div className='flex font-gothan-medium'>Fecha Inicio</div>
        <div className="custom-control custom-switch">
          <input className="custom-control-input hand-pointer" type="checkbox" checked={formValues.EsOrdenFechaInicioAscendente} onChange={({ target }) => setFormValues({...formValues, EsOrdenFechaInicioAscendente: target.checked})} id="admin-solicitud-cambios-fecha-order" />
          <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
              Más reciente
          </label>
        </div>
      </>
    },
    {col: "", label: "Fecha Fin"},
    {col: "", label: "Cant. Días"},
  ]

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters, formValues.EsOrdenFechaInicioAscendente])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} />
      <PageTable headers={HEADERS_TABLE} paginator={paginator} setPaginator={setPaginator}>
        <TableWebBody rows={formValues.Items} />
      </PageTable>
    </PageWrapper>
  )
}

export default ListaHistoricoVacaciones