import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarEtiqueta from './modales/EditarEtiqueta';
import ModalActivarInactivarGeneric from '../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import { activarInactivarEtiquetaAPI } from '../../consumers/backendApisUrls';

const TableWebBody = ({
    filas,
    setModalEnabled,
}) => {
    return(
        <tbody className="listar-tbody">
            {filas.map((fila,i)=> (
                <tr key={i}>
                    <td className="col-4 pl-3 ml-3">{fila?.NombreEtiqueta}</td>
                    <td className="col-2 pl-1 pr-1">{fila?.Estado}</td>
                    <td className="col-1 pl-1 pr-3">
                        <div className="d-flex justify-content-start">
                            <div>
                                <FontAwesomeIcon
                                    icon={fila.IsActive ? 'ban' : 'circle-notch'}
                                    size="sm"
                                    className='hand-pointer'
                                    title={fila.IsActive ? 'Inactivar': 'Activar'}
                                    onClick={() => setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
                                        data: {api: activarInactivarEtiquetaAPI, id: fila.IdEtiqueta, title: "etiqueta", name: fila.NombreEtiqueta, state: fila.IsActive}})}
                                />
                            </div>
                            {fila.IsActive &&
                                <div>
                                    <FontAwesomeIcon 
                                        icon='edit'
                                        size='sm'
                                        className="hand-pointer ml-3"
                                        title="Editar"
                                        onClick={() => setModalEnabled({isEnable: true, component: EditarEtiqueta, 
                                                data: {etiquetaId: fila.IdEtiqueta}})}
                                    />
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableWebBody