import React, {useState} from 'react';
import styled from 'styled-components';

import ModalCenter from '../../components/atoms/ModalCenter';
import {openGmail, openWhatsapp} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDataEmpleadoAPI} from "../../consumers/backendApisUrls";
import {ESTADOS_ROLES} from "../../constants/hardCodedConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const WrapperModal = styled.div`
  margin-left: 50px;
  color: rgb(99, 99, 99);

  .nombre-completo {
    font-size: 22px;
    font-weight: bold;
  }
  
  .area-cargo {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
  }
  
  .tiempo-en-innova {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 15px;
  }
  
  .nombre-empleado-superior {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 5px;
    a {
      color: #7ab6f9;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
  }
`;
const TableWebBody = ({filas, openEditDatosAcademicosModal}) => {
    return (
        <tbody className="listar-tbody">

        {filas.map((user, i) => (
            <tr key={i}>
                <td>
                    <div className="d-flex align-items-center">
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={user.ProfileImageUrl}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{user.NombreCompleto}</div>
                    </div>
                </td>
                <td>{user.NombreDepartamento}</td>
                <td>{user.NombreArea}</td>
                <td>{user.NombreCargo}</td>
                <td>{user.NombreUnidad}</td>
                <td>{user.AvanceFicha}</td>
                <td>{user.TienePolitica}</td>
                <td>{user.EstadoVacunacion}</td>
                <td>{user.EstadoVacunaRefuerzo}</td>
                <td>{user.TieneEncuestaSalud}</td>
                <td>{user.CondicionRiesgo}</td>
                <td>
                    <div className='d-flex mt-2 justify-content-center'>
                        {user.Email && (
                            <div className="d-flex ml-2" onClick={() => openGmail(user.Email)}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20' title='Correo'/>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBody;
