import React, { useState } from "react";
import GenericModal from "../modal/GenericModal";
import { jsonNoEstaVacio } from "../../helper/utils";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";

const ModalActivarInactivarGeneric = ({ toggleModal, refreshPage, api, id = "", payload = {}, title = "", name, state, externalCallback = null }) => {

  const [isSending, setIsSending] = useState(false);
  const [managePost] = useManagePostRequest();

  const handleActivarInactivar = async () => {
    setIsSending(true);
    try {
      if(jsonNoEstaVacio(payload) && !id){
        await managePost(api(), payload, successHandleCallback);
      } else {
        await managePost(api(id), {}, successHandleCallback);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const successHandleCallback = async () => {
    await refreshPage();
    if (externalCallback) await externalCallback();
    toggleModal();
  }

  return (
    <GenericModal
      titulo={`${state ? "Inactivar" : "Activar"} ${title}`}
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {state ? "inactivar" : "activar"} el siguiente item:
          <ul>
            <li>
              <strong>{name}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer justify-content-end">
          <button 
            className="btn btn-primary generic-button-ficha mr-2" 
            disabled={isSending}
            onClick={handleActivarInactivar}>
            {state ? "Inactivar" : "Activar"}
          </button>
          <button 
            className="btn btn-primary generic-button-ficha" 
            onClick={() => {toggleModal()}}>
            Cancelar
          </button>
      </div>
    </GenericModal>
  );
};

export default ModalActivarInactivarGeneric;
