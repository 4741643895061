import React, { useState }  from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import logo from "../../../image/logo_menu.svg";
import InputText from '../../../components/modalComponents/InputText';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import RechazarSolicitud from './RechazarSolicitud';
import { CuponCard, CuponCodigo, CuponImage, CuponLineamiento, CuponName } from './StyledComponents';
import { aprobarSolicitudCuponTiempoLibreAPI } from '../../../consumers/backendApisUrls';

const DetalleAprobarRechazarSolicitud = ({ toggleModal, refreshPage, row, isColaborador = true }) => {

  return (
    <GenericModal titulo="Detalle solicitud" showModal={toggleModal} width="950px" estadoSolicitud={row.EstadoSolicitud}>
      <div className="modal-body py-0 pl-0">
        <div className="displayFlexWrap">
          <Div><InputText label='Solicitante' value={row.NombresCompletos} isDisabled direction='column'/></Div>
          <Div><InputText label='Cargo' value={row.Cargo} isDisabled direction='column'/></Div>
          <Div><InputText label='Sede' value={row.Sede} isDisabled direction='column'/></Div>
          <Div><InputText label='Área' value={row.Area} isDisabled direction='column'/></Div>
          <Div><InputText label='Unidad' value={row.Unidad} isDisabled direction='column'/></Div>
          <Div><InputText label='Departamento' value={row.Departamento} isDisabled direction='column'/></Div>
          <Div><InputText label='Sección' value={row.Seccion} isDisabled direction='column'/></Div>
          <Div><InputText label='Aprobador' value={row.Aprobador} isDisabled direction='column'/></Div>
          <Div><InputText label='Cant. Días' value={row.CantidadDias} isDisabled direction='column'/></Div>
          <Div><InputText label='Desde' value={row.FechaInicio} isDisabled direction='column'/></Div>
          <Div><InputText label='Hasta' value={row.FechaFin} isDisabled direction='column'/></Div>
          <Div><InputText label='Horario' value={row.Horario} isDisabled direction='column'/></Div>
        </div>
        <ImageDiv >
          <Cupon row={row} />
        </ImageDiv>
        {(!isColaborador && (row.PuedeAprobarSolicitud || row.PuedeRechazarSolicitud)) && 
          <ModalActions refreshPage={refreshPage} row={row} toggleModal={toggleModal} toggleModalMain={toggleModal}/>
        }
      </div>
    </GenericModal>
  )
}

export default DetalleAprobarRechazarSolicitud;

const Cupon = ({ row }) => (
  <CuponDetalle>
    <div className='inner-content'>
      <Codigo>{row.CodigoCupon}</Codigo>
      <CuponImage src={row.ImagenCuponTiempoLibreURL}/>
      <div className="cupon_text_overlay">
        <div className='overlay_header'>Ver Lineamientos</div>
        <div className='overlay_body'>
          <CuponLineamiento dangerouslySetInnerHTML={{ __html: row.LineamientosHTML }} />
        </div>
      </div>
    </div>
  </CuponDetalle>
);

const ModalActions = ({ refreshPage, row, toggleModal, toggleModalMain }) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const { PuedeAprobarSolicitud, PuedeRechazarSolicitud, IdSolicitudCuponTiempoLibre, NombreCupon, NombresCompletos } = row;
  
  const toggleAll = () => {
    // toggleModal();
    toggleModalMain();
  }

  return (
    <div className="modal-footer justify-content-end pr-4">
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={refreshPage} />
      <FooterButtons>
        {PuedeAprobarSolicitud &&
          <button
            type="button"
            className={`btn btn-primary generic-button-ficha-modal generic-button-hover-verde`}
            onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { externalCallback: toggleAll, title: "Aprobar", actionLabel: "aprobar", api: aprobarSolicitudCuponTiempoLibreAPI, name: `${NombreCupon} - ${NombresCompletos}`, payload: { IdSolicitudCuponTiempoLibre }}})}
            disabled={isSubmitting}
            >
            Aprobar
          </button>
        }
        {PuedeRechazarSolicitud &&
          <button
            type="button"
            className={`btn btn-primary generic-button-ficha-modal generic-button-hover-rojo`}
            disabled={isSubmitting}
            onClick={() => setModalEnabled({ isEnable: true, component: RechazarSolicitud, data: { row, toggleModalMain } })}
          >
            No Aprobar
          </button>
        }
      </FooterButtons>
    </div>
  );
};

const GenericModal = ({showModal, children, width, titulo, estadoSolicitud}) => {
  const closeModal = () => {
      showModal();
  };

  return (
      <>
          <div className="ficha-modal-solicitar-cambios custom-scroll" style={{'width': `${width}`}}>
              <div className="modalHeader">
                  <div className="title">
                      <p className="font-gothan-bold">{titulo}</p>
                  </div>
                  <EstadoSolicitud><strong>Estado: </strong>{estadoSolicitud}</EstadoSolicitud>
                  <div className="icon">
                      <FontAwesomeIcon icon='times-circle' className="hand-pointer" transform="left-9" onClick={() => closeModal()}/>
                  </div>
              </div>
              <div className="modalContent">
                  {children}
              </div>
          </div>
      </>
  )

};

const CuponDetalle = styled(CuponCard)`
  width: 685px;
`;

const Codigo = styled(CuponCodigo)`
  @media screen and (min-width: 1430px) {
    top: 23px;
    right: 130px;
  }
`

const EstadoSolicitud = styled.div`
  position: absolute;
  top: 9px;
  right: 80px;
  color: white;
`;

const Div = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  &>div {
    margin-bottom: 0;
  }
`;

const FooterButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 15px;
`;