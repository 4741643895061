import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import { onKeyPressEnter,} from '../../helper/utils';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import Paginator from '../directorio/Paginator';
import TableWebBody from './TableWebBody';
import CrearComponente from './modales/CrearComponente';
import { getAllComponentesAPI } from '../../consumers/backendApisUrls';

const GestionComponentes = () => {

    const history = useHistory();

    const [formValues, setFormValues] = useState([]);
    const [modalEnabled, setModalEnabled] = useState({isEnable: false});
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [loading, setLoading] = useState(true);

    const [searchCriteria, setSearchCriteria] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [totalItems, setTotalItems] = useState(10);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();

    const [executePost] = useManagePostRequest();

    const init = async () => {
        setLoading(true);
        try {
            await manageSearch();
        } catch(error){
            console.error(error);
        }
        setLoading(false);
    }

    const manageSearch = async () => {
        const api = getAllComponentesAPI();
        let payload = obtenerPayload();
        await executePost(api, payload, successManageSearchCallback, false)
    }
    
    const obtenerPayload = function () {
        return {
          Page: page,
          Criteria: searchCriteria,
        };
    }

    const successManageSearchCallback = async (response) => {
        setFormValues(response.data.Items);
        setTotalItems(response.data.TotalItems);
        setFirsItemPage(response.data.FirsItemPage);
        setLastItemPage(response.data.LastItemPage);
        setTotalPages(response.data.TotalPages);
    }

    const limpiarBusqueda = () => {
        setSearchCriteria("");
        setSelectedComponents([]);
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };
    
    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    useDidMountEffect(() => {
        setPage(1);
    },[searchCriteria]);

    useDidMountEffect(() => {
        manageSearch();
    },[page]);

    useDidMountEffect(() => {
        manageSearch();
    },[selectedComponents])

    useEffect(()=>{
        init();
    },[])

    if (loading){
        return (
          <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
          </div>
        )
    }

    return (<>
        <div className="message-no-disponible-en-mobile">
            <p>Esta página no está disponible en mobile.</p>
        </div>

        {modalEnabled.isEnable &&
            <div className="modal-contactanos modal-response-ficha-background">
                <modalEnabled.component toggleModal={() => setModalEnabled({isEnable: false})} refreshPage={manageSearch} {...modalEnabled.data}/>
            </div>
        }

        <div className="no-disponible-en-mobile">
            <Wrapper className='App'>
                <PageHeader title={"Administración de Componentes"}/>
                <div className='generic-buscador-container'>
                    <div className="fltros-left-section">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por nombre'
                                value={searchCriteria}
                                onChange={(e) => setSearchCriteria(e.target.value)}
                                onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}/>
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a
                                className='clean-filters'
                                onClick={limpiarBusqueda}
                                >Limpiar búsqueda
                            </a>
                        </div>
                    </div>
                    <div className="filtros-right-section">
                        <button className="generic-button-buscador" onClick={() => history.goBack()}>
                            <FontAwesomeIcon icon='angle-left' size="lg" className="hand-pointer" title="Atras"/> Atrás
                        </button>
                        <button 
                            type='button'
                            className="generic-button-buscador"
                            onClick={() => setModalEnabled({isEnable: true, component: CrearComponente})}
                            >
                            Crear Componente
                        </button>
                    </div>
                </div>
                <div className="container-fluid body-content">
                    <div className="table-responsive form-group-sm pt-3">
                        <table className="table table-hover listar-table-cierre-anio">
                            <thead className="listar-thead">
                                <tr>
                                    <th className="col-8 pr-1">
                                        <span className="font-gothan-medium d-inline">Componente</span>
                                    </th>
                                    <th className="col-3 pr-1">
                                        <span className="font-gothan-medium d-inline">Estado</span>
                                    </th>
                                    <th className="col-1 pr-1">
                                        <span className="font-gothan-medium d-inline">Acciones</span>
                                    </th>
                                </tr>
                            </thead>
                            <TableWebBody
                                filas={formValues}
                                setModalEnabled={setModalEnabled}
                            />
                        </table>
                        {totalPages > 0 && (
                            <Paginator totalItems={totalItems}
                                        firsItemPage={firsItemPage}
                                        lastItemPage={lastItemPage}
                                        totalPages={totalPages}
                                        page={page}
                                        onLeftClic={onLeftClic}
                                        onRightClic={onRightClic}/>
                        )}
                    </div>
                </div>
            </Wrapper>
        </div>
    </>
    )
}

export default GestionComponentes