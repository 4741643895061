import React, { useState } from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { getAllCargosAPI, AsignarGrupoByProcesoAPI, GetGrupoByIdAPI, EditarGrupoByProceso, GetGruposPredefinidosToAsignAPI } from "../../../../consumers/backendApisUrls";
import 'react-quill/dist/quill.snow.css';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../../consumers/httpRequiestsUtils';
import { useEffect } from 'react';
import MultiSelectInput from '../../../../components/MultiSelectInput/MultiSelectInput';
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../../../helper/utils';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import { cleanFormValidationMessage, mostrarMensajesDeValidacionEnCadaInput } from '../../../../validations/FormValidatorUtils';
import AsyncSelectInput from '../../../../components/Select/AsyncSelectInput';

const CrearPlanitlla = ({ toggleModal, refreshTable, IdProcesoCierre, grupo }) => {
  const sectionName = "AsignarGrupos";
  const [executePostRequest] = usePostRequest();
  const [formValues, setFormValues] = useState({"Nombre": ""});
  const [handleChange] = useForm(formValues, setFormValues);
  const [grupoData, setGrupoData] = useState({ Nombre: '', FechaInicioProgramado: '', FechaFinProgramado: '', FechaInicioReal: '', FechaFinReal: '' });

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [allCargos, setAllCargos] = useState([]);
  const [cargosSeleccionados, setCargosSeleccionados] = useState([]);
  const [puedeEditar, setPuedeEditar] = useState(true);
  const [allInputKeys] = ["Nombre", "IdsCargo", "FechaInicioProgramado", "FechaFinProgramado"]


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
      if (grupo.IdMonitorProcesoCierre)
        getDataGrupo()
    }
    return () => {
      unmounted = true;
    };
  }, []);

  const getInitialData = async () => {
    try {
      await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargos);
    } catch (e) {
      console.error(e);
    }
  };

  const getDataGrupo = async () => {
    try {
      await getInputData(executeGetRequestCallback(GetGrupoByIdAPI(grupo.IdMonitorProcesoCierre)));
    } catch (e) {
      console.error(e);
    }
  };

  const getDropdownOptions = async (getFunction, setState) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      let cargos = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
      setState(cargos);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const getInputData = async (getFunction) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      setGrupoData({
        Nombre: response.data.Nombre,
        FechaInicioProgramado: response.data.FechaInicioProgramado,
        FechaFinProgramado: response.data.FechaFinProgramado,
        FechaInicioReal: response.data.FechaInicioReal,
        FechaFinReal: response.data.FechaFinReal
      })
      setCargosSeleccionados(response.data.IdsCargo)
      setPuedeEditar(response.data?.PuedeEditar)
      setFormValues({"Nombre": response.data.Nombre});
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
    const response = await executeGetRequest(GetGrupoByIdAPI(jsonValue.value));
    if (responseCode200(response)) {
      setCargosSeleccionados(response?.data.IdsCargo)
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const submit = async () => {

    cleanFormValidationMessage(`ficha-edit-${sectionName}-Nombre-validation-message`)
    cleanFormValidationMessage(`ficha-edit-${sectionName}-IdsCargo-validation-message`)
    cleanFormValidationMessage(`ficha-edit-${sectionName}-FechaInicioProgramado-validation-message`)
    cleanFormValidationMessage(`ficha-edit-${sectionName}-FechaFinProgramado-validation-message`)
    if (grupo.IdMonitorProcesoCierre) {
      let payload = armarPayloadPUT();
      const response = await executePostRequest(EditarGrupoByProceso(), payload);
      if (responseCode200(response)) {
        toggleModal()
        refreshTable()
      } else if (responseTieneModelState(response)) {
        mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
      } else {
        mostrarMensajeDeErrorConToast(response);
      }
    }
    else {
      let payload = armarPayloadPOST();
      const response = await executePostRequest(AsignarGrupoByProcesoAPI(), payload);
      if (responseCode200(response)) {
        toggleModal()
        refreshTable()
      } else if (responseTieneModelState(response)) {
        mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
      } else {
        mostrarMensajeDeErrorConToast(response);
      }
    }
  };

  const armarPayloadPOST = () => {
    let payload = {
      IdProcesoCierre: IdProcesoCierre,
      Nombre: formValues?.Nombre,
      IdsCargo: cargosSeleccionados,
      FechaInicioProgramado: formValues.FechaInicioProgramado,
      FechaFinProgramado: formValues?.FechaFinProgramado
    }
    return payload;
  }

  const armarPayloadPUT = () => {
    let payload = {
      IdMonitorProcesoCierre: grupo.IdMonitorProcesoCierre,
      IdProcesoCierre: IdProcesoCierre,
      Nombre: formValues?.Nombre,
      IdsCargo: cargosSeleccionados,
      FechaInicioProgramado: formValues.FechaInicioProgramado ?? grupoData.FechaInicioProgramado,
      FechaFinProgramado: formValues.FechaFinProgramado ?? grupoData.FechaFinProgramado
    }
    return payload;
  }

  return (
    <>
      <GenericModal showModal={toggleModal} width={"900px"} titulo={grupo.IdMonitorProcesoCierre ? "Editar asignación de grupo" : "Asignar grupo"}>

        <div className="modal-body pt-0 pl-0">
          <form>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Nombre<span className="required">*</span></label>
              <div className="col-8 ml-3 black-color">
                <input type="text" className="form-control" name="Nombre" onChange={handleChange} value={formValues?.Nombre} disabled={!puedeEditar}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Nombre-validation-message`}></p>
              </div>
            </div>
            {!grupo.IdMonitorProcesoCierre &&
              <div className="form-group row">
                <label className="col-sm-3 col-form-label modal-label">Buscar cargos en predefinido</label>
                <div className="col-8 ml-3 black-color">
                  <AsyncSelectInput api={GetGruposPredefinidosToAsignAPI} placeholder="Buscar por nombre" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal} />
                </div>
              </div>
            }
            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Cargos<span className="required">*</span></label>
              <div className="col-8 ml-3 black-color">
                <MultiSelectInput name="IdsCargo" options={allCargos} selectedValues={cargosSeleccionados}
                  setSelectedValues={setCargosSeleccionados} placeholder={"Seleccionar cargos"} isDisabled={!puedeEditar} />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargo-validation-message`}></p>              
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Fecha Inicio Planificado<span className="required">*</span></label>
              <div className="col-8 ml-3">
                <input type="date" min={new Date().toISOString().split("T")[0]} className="form-control" name="FechaInicioProgramado" value={formValues?.FechaInicioProgramado ?? grupoData.FechaInicioProgramado} onChange={handleChange} disabled={!puedeEditar}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaInicioProgramado-validation-message`}></p>               
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Fecha Fin Planificado<span className="required">*</span></label>
              <div className="col-8 ml-3">
                <input type="date" min={new Date().toISOString().split("T")[0]} className="form-control" name="FechaFinProgramado" value={formValues.FechaFinProgramado ?? grupoData.FechaFinProgramado} onChange={handleChange} disabled={!puedeEditar}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaFinProgramado-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Fecha de Inicio Real</label>
              <div className="col-8 ml-3">
                <input type="text" className="form-control" name="FechaInicioValue" value={formValues?.FechaInicioReal ?? grupoData.FechaInicioReal} disabled></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaValue-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Fecha de Fin Real</label>
              <div className="col-8 ml-3">
                <input type="text" className="form-control" name="FechaFinValue" value={formValues?.FechaFinReal ?? grupoData.FechaFinReal} disabled ></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaValue-validation-message`}></p>
              </div>
            </div>

          </form>
        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          {puedeEditar &&
            <div className="button">
              <button
                className="btn btn-primary generic-button-ficha-modal"
                onClick={submit}>
                Guardar
              </button>
            </div>
          }
          {
            !puedeEditar &&
            <button
              className="btn btn-primary generic-button-ficha-modal"
              onClick={toggleModal}
            >
              Cerrar
            </button>
          }

        </div>
      </GenericModal>
    </>
  )
}

export default CrearPlanitlla;