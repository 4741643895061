import DirectorioWrapper from "../../components/atoms/DirectorioWrapper"
import PageHeader from "../../components/pageHeader/PageHeader"
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import { vaccineConfirmationAPI, validarRegistroVacunaAPI} from "../../consumers/backendApisUrls";
import {getCartilllaVacunacionAPI, geTiposDeVanunasCovidAPI, geTiposDeVanunasCovidTerceraDosisAPI, getMotivosSinVacunacionAPI} from "../../consumers/backendApisUrls";
import { AuthContext } from "../../hooks/context/authContext";
import { useForm } from "../../hooks/useForm/UseForm";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import {
    isNullOrUndefinded,
    jsonEstaVacio,
    jsonNoEstaVacio,
    mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric
} from "../../helper/utils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import SelectInput from "../../components/Select/SelectInput";
import { toast } from "react-toastify";
import ModificarVacunaModal from '../../components/modalVacunas/ModificarVacunaModal';
import DenegacionVacuna from "./modales/DenegacionVacuna";
import AceptarVacuna from "./modales/AceptartVacuna";
import ValidarVacuna from "./modales/ValidarVacuna";

const ConfirmacionDocumento = () => {

    const sectionName = "ConfirmacionDocumento";
    const OTRA_VACUNA_OPTION_ID = 5;
    const VACUNA_JHONSON_AND_JONSON_OPTION_ID = 3;
    const idModivoSinVacunacionOtros = 5;

    const { state } = useContext(AuthContext);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    const { id } = useParams();
    const [managePostRequest] = useManagePostRequest();

    const [soloLectura, setSoloLectura] = useState(true);
    const [soloLecturaBtn, setSoloLecturaBtn] = useState(true);
    const [motivosSinVacunacionOptions, setMotivosSinVacunacionOptions] = useState([]);
    const [jsonSelectedMotivoSinVacunacionOption, setJsonSelectedMotivoSinVacunacionOption] = useState({});
    const [tiposVacunaOptions, setTiposVacunaOptions] = useState([]);
    const [tiposVacunaTerceraDosisOptions, setTiposVacunaTerceraDosisOptions] = useState([]);
    const [jsonSelectedTipoVacunaValue, setJsonSelectedTipoVacunaValue] = useState({});
    const [jsonSelectedTipoVacunaTerceraDosisValue, setJsonSelectedTipoVacunaTerceraDosisValue] = useState({});
    const [cartillaVacunaResponse, setCartillaVacunaResponse] = useState({});

    const [loading, setLoading] = useState(true);
    const [vacunaInicial, setVacunaInicial] = useState();
    const [terceraVacunaInicial, setTerceraVacunaInicial] = useState();
    const [mostrarInputTextMotivoSinVacuna, setMostrarInputTextMotivoSinVacuna] = useState(false);

    const [hideModificarVacunaModal, setHideModificarVacunaModal] = useState(true);
    const [hideModalDenegacionVacuna, setHideModalDenegacionVacuna] = useState(false);
    const [hideModalAceptarVacuna, setHideModalAceptarVacuna] = useState(false);
    const [hideModalValidaVacuna, setHideModalValidaVacuna] = useState(false);

    const toggleModalValidaVacuna= function () {
        setHideModalValidaVacuna(!hideModalValidaVacuna);
    }

    const toggleModalAceptarVacuna= function () {
        setHideModalAceptarVacuna(!hideModalAceptarVacuna);
    }

    const toggleModalDenegacionVacuna= function () {
        setHideModalDenegacionVacuna(!hideModalDenegacionVacuna);
    }

    const toggleModificarVacunaModal = async () => {
        setHideModificarVacunaModal(!hideModificarVacunaModal);
    }
      
    useDidMountEffect(() => {
        if (jsonNoEstaVacio(formValues)) {
            let tempFormValues = { ...formValues };
            if (jsonNoEstaVacio(jsonSelectedTipoVacunaValue) && jsonSelectedTipoVacunaValue?.value !== OTRA_VACUNA_OPTION_ID) {
                tempFormValues.NombreVacuna = "";
            }

            if (jsonSelectedTipoVacunaValue?.value === VACUNA_JHONSON_AND_JONSON_OPTION_ID) {
                tempFormValues.FechaSegundaDosis = "";
            }

            if (!(jsonEstaVacio(jsonSelectedTipoVacunaValue) || isNullOrUndefinded(jsonSelectedTipoVacunaValue?.value))) {
                tempFormValues.TodaviaNoMeVacuno = false;
                tempFormValues.NoHeCompletadoVacunacion = false;
            }
            setFormValues(tempFormValues);
        }

    }, [jsonSelectedTipoVacunaValue]);

    useDidMountEffect(() => {
        if (jsonSelectedMotivoSinVacunacionOption?.value === idModivoSinVacunacionOtros) {
            setMostrarInputTextMotivoSinVacuna(true);
        } else {
            setMostrarInputTextMotivoSinVacuna(false);
        }
    }, [jsonSelectedMotivoSinVacunacionOption])

    useDidMountEffect(async () => {
        /*if (!formValues.TodaviaNoMeVacuno || !formValues.NoHeCompletadoVacunacion) {*/
        if (!formValues.NoHeCompletadoVacunacion) {
            let tiposVacuna = await getTiposDeVacuna();

            let tipoVacunaSelectedObject = tiposVacuna.find(o => o.Value === formValues.IdVacuna);
            if (tipoVacunaSelectedObject) {
                setJsonSelectedTipoVacunaValue({ value: tipoVacunaSelectedObject?.Value, label: tipoVacunaSelectedObject?.Text })
            }
            let tiposVacunaTerceraDosisTemp = await getTiposDeVacunaTerceraDosis();
            let tipoVacunaTerceraDosisSelectedObject = tiposVacunaTerceraDosisTemp.find(o => o.Value === formValues.IdVacunaTerceraDosis);
            if (tipoVacunaTerceraDosisSelectedObject) {
                setJsonSelectedTipoVacunaTerceraDosisValue({ value: tipoVacunaTerceraDosisSelectedObject?.Value, label: tipoVacunaTerceraDosisSelectedObject?.Text })
            }
        } else {
            let motivosSinVacunacion = await getMotivosSinVacunacion();
            let motivosSinVacunacioSelectedObject = motivosSinVacunacion.find(o => o.Value === formValues.IdMotivoSinVacuna);
            if (motivosSinVacunacioSelectedObject) {
                setJsonSelectedMotivoSinVacunacionOption({ value: motivosSinVacunacioSelectedObject?.Value, label: motivosSinVacunacioSelectedObject?.Text });
            }
        }
        
    }, [formValues.TodaviaNoMeVacuno, formValues.NoHeCompletadoVacunacion])

    const getCartilllaVacunacion = async function () {
        let response;
        if(id !== null) response = await executeGetRequest(getCartilllaVacunacionAPI(id));
        else response = await executeGetRequest(getCartilllaVacunacionAPI(state.user.id_empleado));
        if (responseCode200(response)) {
            setCartillaVacunaResponse(response.data);
            if(id) {setSoloLectura(true);}
            else setSoloLectura(response.data.SoloLectura);
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getTiposDeVacuna = async function () {
        const response = await executeGetRequest(geTiposDeVanunasCovidAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getTiposDeVacunaTerceraDosis = async function () {
        const response = await executeGetRequest(geTiposDeVanunasCovidTerceraDosisAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getMotivosSinVacunacion = async function () {
        const response = await executeGetRequest(getMotivosSinVacunacionAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        setLoading(true);
        let tiposVacunaaa = await getTiposDeVacuna();
        let tiposVacunaTerceraDosisTemp = await getTiposDeVacunaTerceraDosis();
        let responseData = await getCartilllaVacunacion();
        let responseMotivosSinVacunacion = await getMotivosSinVacunacion();
        let motivosSinVacunacionOptionsMap = [];
        if (responseMotivosSinVacunacion) {
            motivosSinVacunacionOptionsMap = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(responseMotivosSinVacunacion, "Value", "Text");
            setMotivosSinVacunacionOptions(motivosSinVacunacionOptionsMap);
        }

        if (tiposVacunaTerceraDosisTemp && responseData) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(tiposVacunaTerceraDosisTemp);
            setTiposVacunaTerceraDosisOptions(options);
        }

        if (tiposVacunaaa && responseData) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(tiposVacunaaa);
            setTiposVacunaOptions(options);

            let tipoVacunaSelectedId = responseData?.IdVacuna;
            let tipoVacunaSelectedObject = tiposVacunaaa.find((vacuna) => vacuna.Value === tipoVacunaSelectedId);
            if (tipoVacunaSelectedObject) {
                setJsonSelectedTipoVacunaValue({ value: tipoVacunaSelectedObject?.Value, label: tipoVacunaSelectedObject?.Text })
                setVacunaInicial(responseData?.IdVacuna);
            }

            let tipoVacunaTerceraDosisSelectedId = responseData?.IdVacunaTerceraDosis;
            let tipoVacunaTerceraDosisSelectedObject = tiposVacunaTerceraDosisTemp.find((vacuna) => vacuna.Value === tipoVacunaTerceraDosisSelectedId);
            if (tipoVacunaTerceraDosisSelectedObject) {
                setTerceraVacunaInicial(responseData?.IdVacunaTerceraDosis);
                setJsonSelectedTipoVacunaTerceraDosisValue({ value: tipoVacunaTerceraDosisSelectedObject?.Value, label: tipoVacunaTerceraDosisSelectedObject?.Text })
            }

            let formValuess = {
                "IdVacuna": responseData?.IdVacuna,
                "FechaPrimeraDosis": responseData?.FechaPrimeraDosis,
                "FechaSegundaDosis": responseData?.FechaSegundaDosis,
                "NombreVacuna": responseData?.NombreVacuna,
                "TodaviaNoMeVacuno": responseData?.TodaviaNoMeVacuno ?? false,
                "NoHeCompletadoVacunacion": responseData?.NoHeCompletadoVacunacion ?? false,
                "MotivoSinVacuna": responseData?.IdMotivoSinVacuna === idModivoSinVacunacionOtros ? responseData?.MotivoSinVacuna : "",
                "IdMotivoSinVacuna": responseData?.IdMotivoSinVacuna,
                "IdVacunaTerceraDosis": tipoVacunaTerceraDosisSelectedId,
                "FechaTerceraDosis": responseData?.FechaTerceraDosis,
            }
            setFormValues(formValuess);
            setJsonSelectedMotivoSinVacunacionOption(motivosSinVacunacionOptionsMap.find(o => o.value == responseData?.IdMotivoSinVacuna));
        }
        setLoading(false);
    };

    const EnviarConfirmacion = async () => {
        const payload = {IdEmpleado: id};
        const response = await managePostRequest(vaccineConfirmationAPI(), payload, () => {}, true);
        if(responseCode200(response)){
            toggleModalAceptarVacuna();
        }
    }

    const ValidarRegistroVacuna = async () => {
        const payload = {IdEmpleado: id};
        const response = await managePostRequest(validarRegistroVacunaAPI(), payload, () => {}, true);
        if(responseCode200(response)){
            setSoloLecturaBtn(false);
            init();
        }
        else
        {
            toggleModalValidaVacuna();
        }
    }

    useEffect (() => {
        let usuarioSession = state.user.id_empleado;
        if (id == usuarioSession) {
            ValidarRegistroVacuna();
        }
        else {
            setSoloLecturaBtn(true);
            toggleModalDenegacionVacuna();
            toast.error("Usuario no autorizado a ingresar a esta pantalla.");
        }
    }, []) 

    const Confirmar = async () => {
        EnviarConfirmacion();
    };

    const history = useHistory();

    const ModificarCierre = async () => {
        history.push("/");
    };

    const opcionTodaviaNoMeVacuno = (
        <div className="pt-1 pb-5">
            <hr/>
            <div className="form-group row mb-2 mt-3">
                <label className="col-sm-4 col-form-label modal-label">Motivo por el cual no se vacuna</label>   
                <div className="col-sm-8 black-color">
                    <SelectInput options={motivosSinVacunacionOptions} jsonSelectedValue={jsonSelectedMotivoSinVacunacionOption} setJsonSelectedValue={setJsonSelectedMotivoSinVacunacionOption} isDisabled={soloLectura}
                        placeholder={"Seleccionar un motivo por el cual no se vacuna"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdMotivoSinVacuna-validation-message`}></p>
                </div>
            </div>

            {
                mostrarInputTextMotivoSinVacuna &&
                <div className="form-group row mb-0">
                    <label className="col-sm-4 col-form-label modal-label">Explicar el motivo</label>
                    <div className="col-sm-8 black-color">
                        <textarea className="form-control" rows="3" name="MotivoSinVacuna" onChange={handleChange} value={formValues?.MotivoSinVacuna} disabled={soloLectura} placeholder="Máximo 200 caractares" maxLength="200"></textarea>
                        <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-MotivoSinVacuna-validation-message`}></p>
                    </div>
                </div>
            }
        </div>
    );

    const opcionMeVacune = (
        <>
            <hr className="mt-1 mb-1"/>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Tipo de vacuna</label>
                <div className="col-sm-8 black-color">
                    <SelectInput options={tiposVacunaOptions} jsonSelectedValue={jsonSelectedTipoVacunaValue} setJsonSelectedValue={setJsonSelectedTipoVacunaValue} isDisabled={soloLectura} placeholder={"Seleccionar"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdVacuna-validation-message`}></p>
                </div>


            </div>
            <div className="form-group row mb-0">
            {jsonSelectedTipoVacunaValue?.value === OTRA_VACUNA_OPTION_ID &&
            <>
                <label className="col-sm-4 col-form-label modal-label">Nombre de Vacuna</label>
                <div className="col-sm-8 black-color">
                    <input type="text" className="form-control" name="NombreVacuna" onChange={handleChange} value={formValues?.NombreVacuna} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-NombreVacuna-validation-message`}></p>
                </div>
            </>
            }
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Me vacuné 1ra. dosis el</label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaPrimeraDosis" onChange={handleChange} value={formValues?.FechaPrimeraDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaPrimeraDosis-validation-message`}></p>
                </div>

            </div>

            <div className="form-group row mb-0">
            {jsonSelectedTipoVacunaValue?.value !== VACUNA_JHONSON_AND_JONSON_OPTION_ID &&
            <>
                <label className="col-sm-4 col-form-label modal-label">Me vacuné 2da. dosis el</label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaSegundaDosis" onChange={handleChange} value={formValues?.FechaSegundaDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaSegundaDosis-validation-message`}></p>
                </div>
            </>
            }
            </div>

            <hr className={"mb-1 mt-1"}/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Tipo de vacuna refuerzo</label>
                <div className="col-sm-8 black-color">
                    <SelectInput options={tiposVacunaTerceraDosisOptions} jsonSelectedValue={jsonSelectedTipoVacunaTerceraDosisValue} setJsonSelectedValue={setJsonSelectedTipoVacunaTerceraDosisValue} isDisabled={soloLectura} placeholder={"Seleccionar"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdVacunaTerceraDosis-validation-message`}></p>
                </div>
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Me vacuné dosis de refuerzo el</label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaTerceraDosis" onChange={handleChange} value={formValues?.FechaTerceraDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaTerceraDosis-validation-message`}></p>
                </div>
            </div>
            <hr className={"mb-1 mt-1"}/>
        </>
    );

    return (
            <DirectorioWrapper className='App'>
            {hideModificarVacunaModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
              <ModificarVacunaModal userId={state.user.id_empleado} showModal={toggleModificarVacunaModal} reload={()=> {ModificarCierre()}} />
            </div>
            }

            {hideModalDenegacionVacuna &&
            <div className="modal-contactanos modal-response-ficha-background">
                < DenegacionVacuna toggleModal={toggleModalDenegacionVacuna}/>
            </div>
            }

            {hideModalAceptarVacuna &&
            <div className="modal-contactanos modal-response-ficha-background">
                < AceptarVacuna toggleModal={toggleModalAceptarVacuna}/>
            </div>
            }
            {hideModalValidaVacuna &&
            <div className="modal-contactanos modal-response-ficha-background">
                < ValidarVacuna toggleModal={toggleModalValidaVacuna}/>
            </div>
            }
            <PageHeader title={"Confirmación de Registro de Vacuna"}/>
            <div className='container-fluid body-content'>
                        { opcionTodaviaNoMeVacuno}
                        { opcionMeVacune}
                        <div>
                            <button className="btn btn-primary generic-button-ficha" onClick={Confirmar} disabled={soloLecturaBtn}>Confirmar</button>
                            <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={() => {toggleModificarVacunaModal(state.user.id_empleado)}}  disabled={soloLecturaBtn}>Modificar</button>
                        </div>
            </div>
            </DirectorioWrapper>
    )    
}

export default ConfirmacionDocumento;