import React, { useState, useEffect } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import SelectInput from "../../../components/Select/SelectInput";
import {
  getUnidadesRegistroInvitacionAPI,
  getEditRegistroInvitacionAPI,
  saveEditRegistroInvitacionAPI,
} from "../../../consumers/backendApisUrls";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useForm } from "../../../hooks/useForm/UseForm";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentDate } from "../../../helper/utils";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";

const EditarRegistroInvitacion = ({
  toggleModal,
  refreshTable,
  idRegistroInvitacion,
}) => {
  const sectionName = "EditarRegistroInvitacion";
  const [formValues, setFormValues] = useState({});
  const [dataUnidades, setDataUnidades] = useState([]);
  const [unidadSelected, setUnidadSelected] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [textAreaCount, setTextAreaCount] = useState(0);

  const [getOptions] = useGetOptionsSelect();
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();
  const [executeGetRequest] = useManageGetRequest();

  const getInitialData = async () => {
    setIsLoading(true);
    try {
      let dataOptions = await getOptions(getUnidadesRegistroInvitacionAPI());
      setDataUnidades(dataOptions);
      const api = getEditRegistroInvitacionAPI(idRegistroInvitacion);
      await executeGetRequest(api, (res) =>
        successGetEditarCallBack(res, dataOptions)
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const successGetEditarCallBack = (response, dataOptions) => {
    let datos = response?.data;
    GetFechaHora(datos);
    setUnidadSelected(dataOptions.find((o) => o.value == datos.IdUnidad));
    setTextAreaCount(datos.Observaciones ? datos.Observaciones.length : 0);
    setFormValues(datos);
  };

  const GetFechaHora = (datos) => {
    let fechaData = new Date(datos.DateTime).toISOString().split("T")[0];
    let horaData = datos.DateTime.split("T")[1].slice(0,5);
    datos.FechaValue = fechaData;
    datos.HoraValue = horaData;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const api = saveEditRegistroInvitacionAPI();
      const payload = getPayload();
      const inputKeys = ["IdUnidad", "DateTime", "LinkMeet", "Observaciones"];

      await submitForm(
        inputKeys,
        api,
        payload,
        sectionName,
        successRegisterCallBack
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getPayload = () => {
    return {
      IdRegistroInvitacion: idRegistroInvitacion,
      IdUnidad: unidadSelected.value,
      DateTime: `${formValues.FechaValue} ${formValues.HoraValue}`,
      LinkMeet: formValues.LinkMeet,
      Observaciones: formValues.Observaciones,
    };
  };

  const successRegisterCallBack = () => {
    refreshTable();
    toggleModal();
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleTextArea = (e) => {
    setTextAreaCount(e.target.value.length);
    const name = "Observaciones";
    setFormValues({ ...formValues, [name]: e.target.value });
  };

  const handlDateChange = (date) => {
    let inputValue = date ? new Date(date).toISOString().split("T")[0] : null;
    let inputName = "FechaValue";
    setFormValues({ ...formValues, [inputName]: inputValue });
  };

  const parseYYMMDDtoDate = (stringDate) => {
    try {
      let parts = stringDate?.split("-");
      let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
      return mydate;
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <GenericModal
      showModal={toggleModal}
      width="650px"
      titulo="Editar Sesiones Inducción"
    >
      <div className="modal-body p-0">
        <form className="blue-standard-color" onSubmit={handleSubmit}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label modal-label">
              Grupo<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={dataUnidades}
                placeholder="Seleccionar"
                jsonSelectedValue={unidadSelected}
                setJsonSelectedValue={setUnidadSelected}
                isDisabled={isLoading}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdUnidad-validation-message`}
              ></p>
            </div>
          </div>
          <div className="mb-2">
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Fecha<span className="required">*</span>
              </label>
              <div className="col-sm-4">
                <DatePicker
                  className="form-control"
                  name="FechaValue"
                  onChange={handlDateChange}
                  selected={parseYYMMDDtoDate(formValues.FechaValue)}
                  minDate={new Date(getCurrentDate())}
                  dateFormat="dd/MM/yyyy"
                  filterDate={isWeekday}
                  excludeDates={invalidDates}
                  placeholderText="dd/mm/aaaa"
                  disabled={isLoading}
                />
              </div>

              <label className="col-sm-1 col-form-label modal-label">
                Hora<span className="required">*</span>
              </label>
              <div className="col-sm-4">
                <input
                  type="time"
                  className="form-control"
                  list="times"
                  name="HoraValue"
                  value={formValues.HoraValue ?? ""}
                  min="08:00"
                  max="18:00"
                  step="900"
                  onChange={handleChange}
                  disabled={isLoading}
                />

                <datalist id="times">
                  {hourValues.map((hour) => (
                    <option key={hour} value={hour} />
                  ))}
                </datalist>
              </div>
            </div>
            <div className="form-group row mb-0">
              <div className="col-sm-3"></div>
              <p
                className="ficha-form-input-validation-message pl-3"
                id={`ficha-edit-${sectionName}-DateTime-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label modal-label">
              Link Meet<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <input
                type="text"
                className="form-control"
                name="LinkMeet"
                onChange={handleChange}
                placeholder="Ingresar link google meets"
                value={formValues?.LinkMeet ?? ""}
                disabled={isLoading}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-LinkMeet-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label modal-label">
              Observaciones
            </label>
            <div className="col-sm-9 black-color mb-3">
              <textarea
                type="text"
                className="form-control"
                name="Observaciones"
                placeholder="Máximo 1000 carateres"
                maxLength="1000"
                onChange={handleTextArea}
                value={formValues?.Observaciones ?? ""}
                disabled={isLoading}
              />
              <div className="text-no-hay-novedades">{textAreaCount}/1000</div>
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-Observaciones-validation-message`}
              ></p>
            </div>
          </div>
          <div className="button d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary generic-button-ficha-modal"
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default EditarRegistroInvitacion;

const hourValues = [
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
];

const invalidDates = [
  new Date("2023/01/01"),
  new Date("2023/04/06"),
  new Date("2023/04/07"),
  new Date("2023/05/01"),
  new Date("2023/06/29"),
  new Date("2023/07/28"),
  new Date("2023/07/29"),
  new Date("2023/08/06"),
  new Date("2023/08/30"),
  new Date("2023/10/08"),
  new Date("2023/11/01"),
  new Date("2023/12/08"),
  new Date("2023/12/09"),
  new Date("2023/12/25"),
];
