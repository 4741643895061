import React, { useEffect, useState } from 'react'
import { MATERNIDAD_CONDICION } from '../../../../../constants/hardCodedConstants'
import Gestante from '../../components/Gestante'
import Lactancia from '../../components/Lactancia'
import { DatosMaternidadActions } from '../actions/Actions';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { Div, MESES_GESTACION } from '../../components/Components';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import { jsonEstaVacio } from '../../../../../helper/utils';
import { editarCondicionAPI, getCondicionToEditAPI } from '../../../../../consumers/backendApisUrls';
import InputText from '../../../../../components/modalComponents/InputText';

const KEYS = {
  idEmpleado: "IdEmpleado",
  idTipoCondicion: "IdTipoDeCondicion",
  meses: "MesesGestacion",
  fechaNacimiento: "FechaNacimiento",
  fechaProbable: "FechaProbableDeParto",
  fechaUltMenstruacion: "FechaUltimaMenstruacion",
  nombre: "NombreBebe",
  file: "SustentoFile",
  preExistencia: "PreExistencia",
  horarioLactanciaMaternidad: "HorarioLactanciaMaternidad",
  fechaFinCondicion: "FechaFinCondicion",
  cartaConvenioHorarioLactancia: "CartaConvenioHorarioLactanciaArchivo",
  docPreExistencia: "TieneDocPreExistencia",
  plantillaPreExistencia: "PlantillaPreExistenciaFileViewModel",
  docPreExistenciaFirmado: "DocPreExistenciaFirmadoFileViewModel",
  observacion: "Observacion",
  creadaDesdeGestacion: "CreadaDesdeGestacion",
};
const SECTION_NAME = "DatosMaternidadDetalle";

const DatosMaternidad = ({ idCondicion: IdCondicion, idTipoCondicion, isEditable = false, toggleMainModal, refreshMainPage, isAdmin, row }) => {

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([{ key: KEYS.meses, value: MESES_GESTACION }]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [executePost] = useManagePostRequest();
  const [submitForm] = useSubmitForm();

  const init = async () => {
    setIsLoading(true);
    const { RegistroCondicionSustentoArchivo: SustentoFile, ...rest } = row;
    setFormValues({ SustentoFile, ...rest});
    setIsLoading(false);
  }

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = editarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successEditCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    let { SustentoFile, ...rest } = formValues
    let selectedStates = {};
    
    if (formValues[KEYS.idTipoCondicion] === MATERNIDAD_CONDICION.GESTANTE) {
      const { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, DocPreExistenciaFirmadoFileViewModel } = rest;
      selectedStates = { FechaProbableDeParto, MesesGestacion, FechaUltimaMenstruacion, DocPreExistenciaFirmadoFileViewModel };
    }
    if (formValues[KEYS.idTipoCondicion] === MATERNIDAD_CONDICION.LACTANCIA) {
      const { FechaNacimiento, NombreBebe } = rest;
      selectedStates = { FechaNacimiento, NombreBebe };
    }
    if (jsonEstaVacio(SustentoFile)) SustentoFile = null;
    return {
      IdCondicion,
      SustentoFile,
      ...selectedStates
    }
  }

  const successEditCallback = async () => {
    await refreshMainPage();
    toggleMainModal();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />
    
  const gestantePreExistencia = idTipoCondicion ===
    MATERNIDAD_CONDICION.GESTANTE && (
    <>
      <Div>
        <InputText
          label="Pre-Existencia"
          isDisabled
          {...commonProps(KEYS.preExistencia)}
        />
      </Div>
      {formValues.PuedeDescargarFormatoPreExistencia && (
        <>
          <Div>
            <InputText
              label="Doc. Pre-Existencia"
              isDisabled
              {...commonProps(KEYS.docPreExistencia)}
            />
          </Div>
          <Div>
            <InputUploadFile
              label="Descargar Formato"
              name={KEYS.plantillaPreExistencia}
              uploadFile={formValues[KEYS.plantillaPreExistencia]}
              sectionName={SECTION_NAME}
              isDisabled={true}
            />
          </Div>
          <Div>
            <InputUploadFile
              label="Documento Firmado"
              name={KEYS.docPreExistenciaFirmado}
              uploadFile={formValues[KEYS.docPreExistenciaFirmado]}
              setUploadFile={(res) =>
                setFormValues({
                  ...formValues,
                  [KEYS.docPreExistenciaFirmado]: res,
                })
              }
              sectionName={SECTION_NAME}
              isDisabled={!isEditable}
            />
          </Div>
          <div className="col-md-12">
            <InputText
              label="Observación"
              isDisabled
              {...commonProps(KEYS.observacion)}
            />
          </div>
        </>
      )}
    </>
  );

  const lactanteCartaConvenio = (idTipoCondicion === MATERNIDAD_CONDICION.LACTANCIA && (
    <Div>
      <InputUploadFile
        label="Carta convenio"
        name={KEYS.cartaConvenioHorarioLactancia}
        uploadFile={formValues[KEYS.cartaConvenioHorarioLactancia]}
        sectionName={SECTION_NAME}
        isDisabled={true}
        NoFileMessage={formValues[KEYS.creadaDesdeGestacion] ? "PENDIENTE" : "NO APLICA"}
      />
    </Div>
  ));

  return (
    <form onSubmit={handleEdit}>
      <div className="displayFlexWrap py-4">
        {idTipoCondicion === MATERNIDAD_CONDICION.GESTANTE && (
          <Gestante
            commonProps={commonProps}
            selectProps={selectProps}
            keys={KEYS}
            isDisabled={!isEditable}
          />
        )}
        {idTipoCondicion === MATERNIDAD_CONDICION.LACTANCIA && (
          <Lactancia
            commonProps={commonProps}
            fechaNacimiento={formValues[KEYS.fechaNacimiento]}
            keys={KEYS}
            isDisabled={!isEditable}
          />
        )}
        {Object.values(MATERNIDAD_CONDICION).includes(idTipoCondicion) && (
          <Div>
            <InputUploadFile
              label="Sustento"
              name={KEYS.file}
              uploadFile={formValues[KEYS.file]}
              setUploadFile={(res) =>
                setFormValues({ ...formValues, [KEYS.file]: res })
              }
              sectionName={SECTION_NAME}
              setIsUploading={setIsUploading}
              validExtensions={["pdf", "jpg", "png"]}
              isDisabled={!isEditable}
            />
          </Div>
        )}
        {gestantePreExistencia}
        {lactanteCartaConvenio}
        <div className="col-md-12 text-right">
          {isEditable && (
            <button
              type="submit"
              className="btn btn-primary generic-button-ficha-modal"
              disabled={!isEditable || isSubmitting || isUploading}>
              Guardar
            </button>
          )}
        </div>
      </div>
      {isEditable && isAdmin && (
        <DatosMaternidadActions
          idCondicion={formValues["IdCondicion"]}
          item={`${formValues["NombreEmpleado"]} - ${formValues["TipoDeCondicion"]}`}
          refreshMainPage={refreshMainPage}
          toggleMainModal={toggleMainModal}
          puedeObservar={formValues.PuedeObservar}
          preExistencia={formValues[KEYS.preExistencia]=== 'Sí'}
        />
      )}
    </form>
  );
}

export default DatosMaternidad