import React from "react";
import styled, { css } from "styled-components";

const ModalEnabled = ({
  modalEnabled = { isEnable: false },
  setModalEnabled,
  refreshPage,
}) => {
  return (
    <ModalWrapper isOpen={modalEnabled.isEnable}>
      <div className="modalContainer">
        {(modalEnabled.component && typeof modalEnabled.component !== 'symbol')
          ? <modalEnabled.component
              toggleModal={() => setModalEnabled({ isEnable: false })}
              refreshPage={refreshPage}
              {...modalEnabled.data}
            />
          : <React.Fragment />
        }
      </div>
    </ModalWrapper>
  )
};

export default ModalEnabled;

const ModalWrapper = styled.div`
  z-index: 9999;
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition-property: visibility, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 250ms;

  ${({ isOpen }) => isOpen 
  ? css`
      visibility: visible;
      background-color: #80808069;
    `
  : css`
      visibility: hidden;
    `
  }

  .modalContainer {
    transition-property: transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;

    height: auto;
    ${({ isOpen }) => isOpen 
    ? css`
        /* transform: scaleX(1); */
        opacity: 1;
      `
    : css`
        transform: scale(1.05);
        opacity: 0;
      `
    }
  }
` 