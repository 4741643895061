import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getAllSedesAPI, getAllUbicacionesAPI, getCreateReservaAPI, getDropDownEmpleadoAPI, getMotivoReservaAPI, getReservaAPI, getTiposUbicacionReservaAPI, getUbicacionesDisponiblesAPI, saveReservaAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import GenericModal from "../../../components/modal/GenericModal";
import {focusElement, getCurrentDate, getQueryParams, jsonEstaVacio, jsonNoEstaVacio, mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary, setStateWithCallBackResponse} from '../../../helper/utils';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import SelectInput from "../../../components/Select/SelectInput";
import { useForm } from "../../../hooks/useForm/UseForm";
import AsyncSelectInput from "../../../components/Select/AsyncSelectInput"
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { ESTADOS, RESERVA_MOTIVOS_ENTITY, RESERVA_TIPO_UBICACION_ENTITY, SEDES } from '../../../constants/hardCodedConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../hooks/context/authContext';
import Triaje from './Triaje';
import Ocurrencia from './Ocurrencia';
import ConfirmarReserva from './ConfirmarReserva';
import { downloadFile } from '../../../consumers/backendConsumer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Reserva = ({ showModal, refreshPage, setIdReserva, idReserva, location, isEdit }) => {

    const { state } = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const SECTION_NAME = "ReservaModal";
    const allInputKeys = ["IdSede", "IdResponsable", "FechaReserva", "IdTipoUbicacion", "IdUbicacion", "IdPosicion", "IdsParticipantes", "IdMotivoReserva", "DetalleMotivo"];

    const [tituloModal, setTituloModal] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoadedOptions, setIsLoadedOptions] = useState(false);

    const [createViewModel, setCreateViewModel] = useState({});
    const [sedeOptions, setSedeOptions] = useState([]);
    const [jsonSelectedSede, setJsonSelectedSede] = useState({});
    const [jsonSelectedResponsable, setJsonSelectedResponsable] = useState({});
    const [tipoUbicacionOptions, setTipoUbicacionOptions] = useState([]);
    const [jsonSelectedTipoUbicacion, setJsonSelectedTipoUbicacion] = useState({});
    const [ubicacionesOptions, setUbicacionesOptions] = useState([]);
    const [jsonSelectedUbicacion, setJsonSelectedUbicacion] = useState({});
    const [posicionOptions, setPosicionOptions] = useState([]);
    const [jsonSelectedPosicion, setJsonSelectedPosicion] = useState({});
    const [motivoOptions, setMotivoOptions] = useState([]);
    const [jsonSelectedMotivo, setJsonSelectedMotivo] = useState({});
    const [ubicacionesDisponibles, setUbicacionesDisponibles] = useState([]);
    const [reserva, setReserva] = useState({});
    const [participantes, setParticipantes] = useState([]);
    const [participantesCompletadoTriaje, setParticipantesCompletadoTriaje] = useState(0);

    const [formValues, setFormValues] = useState({ FechaReserva: getCurrentDate() });
    const [handleChange] = useForm(formValues, setFormValues);

    const [showModalTriaje, setShowModalTriaje] = useState(false);
    const [informacionTriaje, setInformacionTriaje] = useState({});

    const [showModalOcurrencia, setShowModalOcurrencia] = useState(false);
    const [informacionOcurrencia, setInformacionOcurrencia] = useState({});

    const [showModalConfirmarReserva, setShowModalConfirmarReserva] = useState(false);
    const [informacionConfirmarReserva, setInformacionConfirmarReserva] = useState({});

    const [enlaceUbicacionOptions, setEnlaceUbicacionOptions] = useState([]);
    const [enlaceUbicacion, setEnlaceUbicacion] = useState("");

     const invalidDates = [
     new Date('2022/04/20'),
     new Date('2022/04/21'),
     new Date('2022/04/22'),
     new Date('2022/04/28'),
     new Date('2022/05/02'),
     new Date('2022/05/05'),
     new Date('2022/05/18'),
     new Date('2022/05/20'),
     new Date('2022/05/23'),
     new Date('2022/06/23'),
     ]

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const verifyDate = (yy, mm, dd) => {
        const today = new Date();
        const lastDay = new Date(yy, mm, dd);
        if(today <= lastDay) return true;
        return false;
    }

    useDidMountEffect(() => {
        if (reserva == null) {
            showModal();
        }
    }, [reserva]);

    useDidMountEffect(() => {
        initLoadForm();
    }, [isLoadedOptions]);

    useDidMountEffect(async () => {
        await setStateWithCallBackResponse(executeGetRequestCallback(getUbicacionesDisponiblesAPI(formValues?.FechaReserva)), setUbicacionesDisponibles);
        if (!idReserva)
            updateUbicacionesDisponibles();
    }, [formValues.FechaReserva, jsonSelectedSede]);

    useDidMountEffect(() => {
        if (!idReserva)
            updateUbicacionesDisponibles();
    }, [jsonSelectedTipoUbicacion, jsonSelectedUbicacion]);

    useDidMountEffect(() => {
        showModalTriajeFromLink();
    }, [participantes]);

    const init = async () => {
        await initLoadOptions();
    };

    const initLoadOptions = async () => {
        setIsLoadedOptions(false);
        await setStateWithCallBackResponse(executeGetRequestCallback(getAllSedesAPI()), setSedeOptions, true);
        await setStateWithCallBackResponse(executeGetRequestCallback(getMotivoReservaAPI()), setMotivoOptions, true);
        await setStateWithCallBackResponse(executeGetRequestCallback(getTiposUbicacionReservaAPI()), setTipoUbicacionOptions, true);
        if (idReserva) {
            await updateAllUbicaciones();
            await setStateReservaResponse(executeGetRequestCallback(getReservaAPI(idReserva)));
        }
        else {
            await setStateWithCallBackResponse(executeGetRequestCallback(getUbicacionesDisponiblesAPI(formValues?.FechaReserva)), setUbicacionesDisponibles);
            await setStateWithCallBackResponse(executeGetRequestCallback(getCreateReservaAPI()), setCreateViewModel);
        }
        setIsLoadedOptions(true);
    }

    const initLoadForm = () => {
        if (jsonNoEstaVacio(reserva)) {
            setTituloModal("Detalle Reserva");
            setIsDisabled(true);
            let sede = sedeOptions.find(o => o.value == reserva.IdSede);
            setJsonSelectedSede(sede);
            let responsable = {
                value: reserva?.IdResponsable,
                label: reserva?.NombreResponsable
            };
            setJsonSelectedResponsable(responsable);
            let tipoUbicacion = tipoUbicacionOptions.find(o => o.value == reserva.IdTipoUbicacion);
            setJsonSelectedTipoUbicacion(tipoUbicacion);
            let ubicacion = ubicacionesOptions.find(o => o.value == reserva.IdUbicacion);
            setJsonSelectedUbicacion(ubicacion);
            let posicion = posicionOptions.find(o => o.value == reserva.IdPosicion);
            setJsonSelectedPosicion(posicion);
            let motivo = motivoOptions.find(o => o.value == reserva.IdMotivoReserva);
            setJsonSelectedMotivo(motivo);
            let formValues = {
                FechaReserva: reserva.FechaReservaValueString,
                NumeroInvitados: reserva?.NumeroInvitados,
                DetalleMotivo: reserva?.DetalleMotivo,
            };
            setFormValues(formValues);
            setParticipantes(reserva?.Participantes);
            setParticipantesCompletadoTriaje(reserva?.Participantes.filter(o => o.IdEstadoTriaje == ESTADOS.COMPLETADA).length);
            setEnlaceUbicacionOptions(ubicacionesDisponibles.EnlacesUbicaciones);
            if(isEdit) {
                setTituloModal("Editar Reserva");
            }
        } else {
            setTituloModal("Crear Reserva");
            setIsDisabled(false);
            loadDefaultSede();
            loadDefaultResponsable();
            setEnlaceUbicacionOptions(ubicacionesDisponibles.EnlacesUbicaciones);
        }
    }

    const loadDefaultSede = () => {
        var defaultSede = sedeOptions.find(o => o.value == SEDES.BACK_OFFICE);
        setJsonSelectedSede(defaultSede);
    };

    const loadDefaultResponsable = () => {
        if (!createViewModel?.TienePermisosAdministrador) {
            let responsable = {
                value: state.user.id_empleado,
                label: state.user.fullName
            };
            setJsonSelectedResponsable(responsable);
            let tempParticipantes = [...participantes];
            let participante = {
                IdParticipante: state.user.id_empleado,
                NombreParticipante: state.user.fullName
            };
            tempParticipantes.push(participante);
            setParticipantes(tempParticipantes);
        }

    };

    const updateUbicacionesDisponibles = () => {
        let enlaceOption = enlaceUbicacionOptions.find(o => o.IdSede == jsonSelectedSede?.value);
        if (jsonSelectedTipoUbicacion && jsonSelectedTipoUbicacion.idEntity === RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL) {
            var dataArea = ubicacionesDisponibles.Ubicaciones.filter(o => o.IdTIpoUbicacion == jsonSelectedTipoUbicacion.value);
            setUbicacionesOptions(mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary(dataArea, "idEntity"))
            updatePosicionesDisponibles();
            setEnlaceUbicacion(enlaceOption.UrlDescargaUbicacionIndividual ?? "");
        } else if (jsonSelectedTipoUbicacion && jsonSelectedTipoUbicacion.idEntity === RESERVA_TIPO_UBICACION_ENTITY.SALA) {
            var data = ubicacionesDisponibles.Ubicaciones.filter(o => o.IdTIpoUbicacion == jsonSelectedTipoUbicacion.value);
            setUbicacionesOptions(mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary(data, "idEntity"))
            setEnlaceUbicacion(enlaceOption.UrlDescargaUbicacionSala ?? "");
        }
    };

    const updatePosicionesDisponibles = () => {
        if (jsonSelectedUbicacion?.value) {
            var dataPosicion = ubicacionesDisponibles.Posiciones.filter(o => o.IdUbicacion == jsonSelectedUbicacion.value);
            setPosicionOptions(mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary(dataPosicion, "idEntity"));
        }
    }

    const updateAllUbicaciones = async () => {
        const response = await executeGetRequest(getAllUbicacionesAPI(idReserva));
        if (responseCode200(response)) {
            setUbicacionesOptions(mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary(response.data.Ubicaciones, "idEntity"));
            setPosicionOptions(mapDropDownOptionsReservaFromBackendToSelectOptionsNeededForLibrary(response.data.Posiciones, "idEntity"));
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const crerReserva = async (event) => {
        try {
            event.preventDefault();
            let payload = obtenerPayload();
            let response = await executePostRequest(saveReservaAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.ValidatorResult.Message);
                if(isEdit){
                    closeForm()
                }
                else {
                    if(response.data?.Reserva?.TieneFichaGuardada) {
                        closeForm();
                    }
                    changeViewFromReserva(response);
                }
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, SECTION_NAME);
            } else {
                mostrarMensajeDeErrorConToast(response);
                closeForm();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const obtenerPayload = () => {
        let payload = {
            "IdReserva": idReserva,
            "IdSede": jsonSelectedSede?.value,
            "IdResponsable": jsonSelectedResponsable?.value,
            "FechaReserva": formValues?.FechaReserva,
            "IdTipoUbicacion": jsonSelectedTipoUbicacion?.value,
            "IdUbicacion": jsonSelectedUbicacion?.value,
            "IdPosicion": jsonSelectedPosicion?.value,
            "IdsParticipantes": participantes.map(o => o.IdParticipante),
            "NumeroInvitados": formValues?.NumeroInvitados,
            "IdMotivoReserva": jsonSelectedMotivo?.value,
            "DetalleMotivo": formValues?.DetalleMotivo,
            "IsEdit": isEdit,
        };
        return payload;
    };

    const closeForm = () => {
        refreshPage();
        showModal();
    }

    const handleResponsable = async (jsonValue) => {
        setJsonSelectedResponsable(jsonValue);
        handleParticipantes(jsonValue);
    }

    const handleParticipantes = async (jsonValue) => {
        let tempParticipantes = [...participantes];
        if (!tempParticipantes.some(o => o.IdParticipante == jsonValue.value)) {
            tempParticipantes.push({ IdParticipante: jsonValue.value, NombreParticipante: jsonValue.label });
        }
        setParticipantes(tempParticipantes);
    }

    const removeParticipante = (idParticipante) => {
        let tempParticipantes = [...participantes];
        tempParticipantes = tempParticipantes.filter(o => o.IdParticipante != idParticipante);
        setParticipantes(tempParticipantes);
    }

    const handleChangeTipoUbicacion = (value) => {
        if (idReserva) return;
        setJsonSelectedUbicacion(null);
        setJsonSelectedPosicion(null);
        setJsonSelectedTipoUbicacion(value)
    };

    const handleChangeUbicacion = (value) => {
        if (idReserva) return;
        setJsonSelectedPosicion(null);
        setJsonSelectedUbicacion(value)
    };

    const handleChangePosicion = (value) => {
        if (idReserva) return;
        setJsonSelectedPosicion(value);
    }

    const toggleTriaje = (idParticipante, newIdReserva) => {
        if (showModalTriaje) {
            setInformacionTriaje({});
        } else {
            let informacion = {
                idReserva: idReserva ?? newIdReserva,
                idParticipante: idParticipante,
                sede: jsonSelectedSede,
                responsable: jsonSelectedResponsable,
                tipoUbicacion: jsonSelectedTipoUbicacion,
                ubicacion: jsonSelectedUbicacion,
                posicion: jsonSelectedPosicion,
                fechaReserva: formValues?.FechaReserva
            };
            setInformacionTriaje(informacion);
        }
        setShowModalTriaje(!showModalTriaje);
    };

    const toggleConfirmarReserva = () => {
        if (showModalConfirmarReserva) {
            setInformacionConfirmarReserva({});
        } else {
            let informacion = {
                idReserva: idReserva,
                sede: jsonSelectedSede,
                responsable: jsonSelectedResponsable,
                tipoUbicacion: jsonSelectedTipoUbicacion,
                ubicacion: jsonSelectedUbicacion,
                posicion: jsonSelectedPosicion,
                fechaReserva: formValues?.FechaReserva,
                motivoReserva: jsonSelectedMotivo,
                participantes: participantes
            };
            setInformacionConfirmarReserva(informacion);
        }
        setShowModalConfirmarReserva(!showModalConfirmarReserva);
    };

    const setStateReservaResponse = async (callbackAPI) => {
        const response = await callbackAPI();
        if (!responseCode200(response)) {
            mostrarMensajeDeErrorConToast(response);
        }
        setReserva(response.data);
    };

    const showModalTriajeFromLink = () => {
        let queryParams = getQueryParams(location.search);
        if (jsonEstaVacio(queryParams)) {
            return;
        } else if (participantes.some(o => o.IdParticipante == queryParams?.idParticipante)) {
            toggleTriaje(queryParams.idParticipante);
        } else {
            showModal();
            toast.error("No existe la encuesta de triaje para esta reserva");
        }
    };

    const puedeResponderTriaje = (participante) => {
        return jsonNoEstaVacio(reserva) && participante.IdParticipante == state.user.id_empleado && participante.IdEstadoTriaje == ESTADOS.PENDIENTE;
    }

    const showModalRegistrarOcurrencia = () => {
        if (showModalOcurrencia) {
            setInformacionOcurrencia({});
        } else {
            let informacion = {
                idReserva: idReserva,
                participantes: participantes
            };
            setInformacionOcurrencia(informacion);
        }
        setShowModalOcurrencia(!showModalOcurrencia);
    };

    const changeViewFromReserva = (response) => {
        if (participantes.some(o => o.IdParticipante == state.user.id_empleado)) {
            let newIdReserva = response.data.Reserva.IdReserva;
            setIdReserva(newIdReserva);
            init();
            toggleTriaje(state.user.id_empleado, newIdReserva);
        } else {
            closeForm();
        }
    }

    const HandlDateChange = (date) =>{
        let inputValue = new Date(date).toISOString().split('T')[0];
        let inputName = "FechaReserva"
        setFormValues({...formValues, [inputName]: inputValue})
    }

    const parseYYMMDDtoDate = (stringDate) => {
        try {
            let parts =stringDate?.split('-');
            let mydate = new Date(parts[0], parts[1] - 1, parts[2]);
            return mydate;
        }catch (e) {
            return null
        }
    }

    return (
        <>
            {showModalTriaje &&
            <Triaje showModal={toggleTriaje} informacionTriaje={informacionTriaje} refreshPage={init} location={location} closeFormParent={closeForm} />
            }

            {showModalOcurrencia &&
            <Ocurrencia showModal={showModalRegistrarOcurrencia} informacionOcurrencia={informacionOcurrencia}></Ocurrencia>
            }

            {showModalConfirmarReserva &&
            <ConfirmarReserva showModal={toggleConfirmarReserva} informacionConfirmarReserva={informacionConfirmarReserva} showModalReserva={showModal}></ConfirmarReserva>
            }

            {!showModalTriaje && !showModalOcurrencia && !showModalConfirmarReserva &&
            <GenericModal showModal={closeForm} width={"1000px"} titulo={tituloModal}>
                <div className="modal-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Sede</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={sedeOptions}
                                    jsonSelectedValue={jsonSelectedSede}
                                    setJsonSelectedValue={setJsonSelectedSede}
                                    isDisabled={true}
                                    placeholder={"Seleccionar"} />
                            </div>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdSede-validation-message`}></p>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Responsable</label>
                            <div className="col-sm-8 black-color">
                                <AsyncSelectInput
                                    api={getDropDownEmpleadoAPI}
                                    placeholder="Buscar por nombre y/o apellidos"
                                    handleSelectInputChange={handleResponsable}
                                    isDisabled={!createViewModel?.TienePermisosAdministrador || isDisabled}
                                    defaultValue={jsonNoEstaVacio(jsonSelectedResponsable) ? jsonSelectedResponsable : null} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdResponsable-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">
                                Fecha Reserva
                            </label>
                            <div className="col-sm-8">
                                <DatePicker className="form-control" name="FechaReserva" onChange={(date) => HandlDateChange(date)} selected={parseYYMMDDtoDate(formValues?.FechaReserva ?? "")}
                                            disabled={isDisabled} minDate={new Date(getCurrentDate())} dateFormat="dd/MM/yyyy" excludeDates={invalidDates}></DatePicker>

                                {/*verifyDate(2022, 8, 2) ? (
                                    <p className="">
                                        <small>
                                            Este día está bloqueado por actividades.
                                        </small>
                                    </p>
                                ): null*/}
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaReserva-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Tipo de Ubicación</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={tipoUbicacionOptions}
                                    jsonSelectedValue={jsonSelectedTipoUbicacion}
                                    setJsonSelectedValue={handleChangeTipoUbicacion}
                                    isDisabled={isDisabled}
                                    placeholder={"Seleccionar"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdTipoUbicacion-validation-message`}></p>
                                {enlaceUbicacion &&
                                <a href="" target="_blank" onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile(state.token, enlaceUbicacion, "Descripcion_Ubicaciones.pdf");
                                }}> Ver mapa de ubicaciones</a>
                                }
                            </div>
                        </div>
                        {jsonSelectedTipoUbicacion?.value &&
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">
                                {jsonSelectedTipoUbicacion.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL && "Área de trabajo"}
                                {jsonSelectedTipoUbicacion.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA && "Área de trabajo"}
                            </label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={ubicacionesOptions}
                                    jsonSelectedValue={jsonSelectedUbicacion}
                                    setJsonSelectedValue={handleChangeUbicacion}
                                    isDisabled={isDisabled}
                                    placeholder={"Seleccionar"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdUbicacion-validation-message`}></p>
                            </div>

                        </div>
                        }
                        {jsonSelectedTipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL &&
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Posición</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={posicionOptions}
                                    jsonSelectedValue={jsonSelectedPosicion}
                                    setJsonSelectedValue={handleChangePosicion}
                                    isDisabled={isDisabled}
                                    placeholder={"Seleccionar"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdPosicion-validation-message`}></p>
                            </div>
                        </div>
                        }

                        {jsonSelectedUbicacion?.aforo > 1 && jsonSelectedTipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA &&
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Número de invitados externos</label>
                            <div className="col-sm-8 flex-row justify-content-center">
                                <input type="number" className="form-control" name="NumeroInvitados" onChange={handleChange} defaultValue={formValues?.NumeroInvitados} disabled={isDisabled} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-NumeroInvitados-validation-message`}></p>
                                <p>Obligatorio: Comparte este <a href="https://docs.google.com/forms/d/e/1FAIpQLSfzzMk80kBdolJxkWypZXfNkadMen7b-KghlNy0rU_hrBOZpw/viewform" target="_blank">link</a> de la ficha sintomatológica con tus invitados externos.</p>
                            </div>
                        </div>
                        }
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element" >Participantes</label>
                            <div className="col-sm-8 flex-row justify-content-center">
                                <table className='table table-hover'>
                                    <thead className="listar-thead">
                                    <tr>
                                        <th className="col-1">
                                            <div className='flex font-gothan-medium'>#</div>
                                        </th>
                                        <th className="col-2">
                                            <div className='flex font-gothan-medium'>Participante</div>
                                        </th>
                                        {jsonNoEstaVacio(reserva) &&
                                        <th className="col-2">
                                            <div className='flex font-gothan-medium'>Estado</div>
                                        </th>
                                        }
                                        <th className="col-1">
                                            <div className='flex font-gothan-medium'>Acciones</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="listar-tbody">
                                    {participantes.map((participante, i) => (
                                        <tr key={i}>
                                            <td className="col-1">{i + 1}</td>
                                            <td className="col-1">{participante?.NombreParticipante}</td>
                                            {jsonNoEstaVacio(reserva) &&
                                            <td className="col-1">{participante?.EstadoTriaje}</td>
                                            }
                                            <td className="col-1">
                                                <div className="d-flex justify-content-start">
                                                    {puedeResponderTriaje(participante) && reserva?.IdEstadoReserva == ESTADOS.PENDIENTE &&
                                                    <div className="col-1">
                                                        <FontAwesomeIcon icon='exclamation-triangle' className="hand-pointer" transform="left-9" onClick={() => { toggleTriaje(participante.IdParticipante) }} />
                                                    </div>
                                                    }
                                                    { participante.IdParticipante != reserva?.IdResponsable && (jsonEstaVacio(reserva) || isEdit) && jsonSelectedTipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA &&
                                                    <div className="col-1">
                                                        <FontAwesomeIcon icon='times' className="hand-pointer" transform="left-9" onClick={() => { removeParticipante(participante.IdParticipante) }} />
                                                    </div>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    { jsonSelectedUbicacion?.aforo > 1 && ((jsonEstaVacio(reserva) || isEdit) && jsonNoEstaVacio(jsonSelectedTipoUbicacion) &&
                                        jsonSelectedTipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA) &&
                                    <tr>
                                        <td className="col-1" colspan={jsonNoEstaVacio(reserva)  ? "4" : "3"}>
                                            <AsyncSelectInput
                                                api={getDropDownEmpleadoAPI}
                                                placeholder="Buscar participante"
                                                handleSelectInputChange={handleParticipantes}
                                                isDisabled={isDisabled && !isEdit}
                                            />
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </table>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdsParticipantes-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row" onClick={focusElement}>
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element" >¿Cual es el motivo de asistencia?</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={motivoOptions}
                                    jsonSelectedValue={jsonSelectedMotivo}
                                    setJsonSelectedValue={setJsonSelectedMotivo}
                                    isDisabled={isDisabled}
                                    placeholder={"Seleccionar"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdMotivoReserva-validation-message`}></p>
                            </div>
                        </div>
                        {
                            jsonSelectedMotivo?.idEntity === RESERVA_MOTIVOS_ENTITY.MOTIVO_PERSONAL &&
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label text-justify" id="focus-element">Detalle del motivo</label>
                                <div className="col-sm-8 black-color">
                                    <textarea className="form-control" rows="3" name="DetalleMotivo" onChange={handleChange} value={formValues?.DetalleMotivo} disabled={isDisabled} placeholder="Máximo 200 caractares" maxLength="200"></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-DetalleMotivo-validation-message`}></p>
                                </div>
                            </div>
                        }

                    </form>
                </div>
                {jsonEstaVacio(reserva) &&
                <div className="modal-footer d-flex justify-content-end" >
                    <div className="button d-flex justify-content-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={crerReserva}>Continuar</button>
                    </div>
                </div>
                }
                {!isEdit && formValues?.FechaReserva <= getCurrentDate() && reserva?.TienePermisosAdministrador && reserva?.IdEstadoReserva == ESTADOS.PENDIENTE
                && (participantesCompletadoTriaje + reserva?.NumeroInvitados) >= reserva?.MinimaCantidadParticipantes &&
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button d-flex justify-content-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={toggleConfirmarReserva}>Confirmar reserva</button>
                    </div>
                </div>
                }

                {isEdit && reserva?.TienePermisosAdministrador  && reserva?.IdEstadoReserva == ESTADOS.PENDIENTE &&
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button d-flex justify-content-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={crerReserva}>Editar reserva</button>
                    </div>
                </div>
                }
            </GenericModal>
            }

        </>
    );
};

export default Reserva;
