import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { AuthContext } from '../../hooks/context/authContext';
import { getExperienciaLaboralAPI } from '../../consumers/backendApisUrls';
import { downloadFile } from "../../consumers/backendConsumer";
import { Spinner } from "react-bootstrap";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import GenericModal from "../../components/modal/GenericModal";
import AgregarExperienciaLaboral from "./modales/AgregarExperienciaLaboralModal";
import EditarExperienciaLaboralModal from "./modales/EditarExperienciaLaboralModal";
import EliminarFormacionProfesional
    from "../datosFormacionAcademica/formacionProfesional/Modales/EliminarFormacionProfesional";
import EliminarExperienciaLaboralModal from "./modales/EliminarExperienciaLaboralModal";
import CustomAlert from "../../components/alerts/CustomAlert";

const SeccionViewExperienciaLaboral = ({ empleadoId, puedoEditar }) => {
    const { state } = useContext(AuthContext);
    const [executeGet] = useGetRequest();

    const [hideModalAgregarExperienciaLaboral, setHideModalAgregarExperienciaLaboral] = useState(false);
    const [hideModalEditarExperienciaLaboral, setHideModalEditarExperienciaLaboral] = useState(false);
    const [hideModalEliminarExperienciaLaboral, setHideModalEliminarExperienciaLaboral] = useState(false);
    const [data, setData] = useState([]);

    const defaultAlertMessage =
        "Para validar tus actualizaciones te pediremos adjuntar un archivo PDF o imagen. ¡Tenlo a mano!";

    const [empleadoExperienciaLaboralToEditOrDelete, setEmpleadoExperienciaLaboralToEditOrDelete] = useState({});

    const getInitialData = async () => {
        try {
            await getExperienciaData();
        } catch (error) {
            console.error(error)
        }
    }

    const getExperienciaData = async () => {
        let response = await executeGet(getExperienciaLaboralAPI(empleadoId));
        if (responseCode200(response)) {
            setData(response.data);
        } else {
            setData([]);
        }
    }

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) getInitialData();

        return () => {
            unmounted = true;
        };
    }, []);

    const toggleAgregarExperienciaModal = () => {
        setHideModalAgregarExperienciaLaboral(!hideModalAgregarExperienciaLaboral);
    }

    const toggleEditarExperienciaLaboralModal = (empleadoExperienciaLaboral) => {

        setHideModalEditarExperienciaLaboral(!hideModalEditarExperienciaLaboral);
        setEmpleadoExperienciaLaboralToEditOrDelete(empleadoExperienciaLaboral);

    }

    const toggleEliminarExperienciaLaboralModal = (empleadoExperienciaLaboral) => {
        if (puedoEditar === true) {
            setHideModalEliminarExperienciaLaboral(!hideModalEliminarExperienciaLaboral);
            setEmpleadoExperienciaLaboralToEditOrDelete(empleadoExperienciaLaboral);
        }
    }
    
    return (
        <>
            {hideModalAgregarExperienciaLaboral &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <AgregarExperienciaLaboral
                        toggleCurrentModal={toggleAgregarExperienciaModal}
                        empleadoId={empleadoId}
                        reFetchList={getInitialData}
                    />
                </div>
            }

            {hideModalEditarExperienciaLaboral &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EditarExperienciaLaboralModal
                        toggleCurrentModal={toggleEditarExperienciaLaboralModal}
                        reFetchList={getInitialData}
                        empleadoExperienciaLaboral={empleadoExperienciaLaboralToEditOrDelete}
                        puedoEditar={puedoEditar}
                    />
                </div>
            }

            {hideModalEliminarExperienciaLaboral &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EliminarExperienciaLaboralModal
                        toggleCurrentModal={toggleEliminarExperienciaLaboralModal}
                        reFetchList={getInitialData}
                        empleadoExperienciaLaboral={empleadoExperienciaLaboralToEditOrDelete}
                    />
                </div>
            }

            <div className='displayFlexWrap'>
                <div className='w-100'>
                    <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Experiencia Laboral</p>

                    <CustomAlert variant={"warning"}>
                        <p className="ficha-secciones-title-text font-gothan-light">
                            {defaultAlertMessage}&nbsp;&nbsp;&nbsp;
                            <FontAwesomeIcon
                                icon="info-circle"
                                transform="left-9"
                                className="hand-pointer"
                            />
                        </p>
                        {!data.some(x => x) &&
                            <p className="ficha-secciones-title-text font-gothan-light">Haz clic en agregar para comenzar a completar tu registro</p>
                        }
                    </CustomAlert>
                </div>
            </div>

            <div className='card-body mr-3 pt-0'>
                <div className='d-flex justify-content-end pt-0 pb-0'>
                    <button
                        type='button'
                        className='btn btn-primary generic-button-buscador'
                        onClick={toggleAgregarExperienciaModal} disabled={!puedoEditar}>
                        Agregar
                    </button>
                </div>
                <div>
                    <table className="table table-borderless">
                        {data.map((row, i) => (
                            <tr key={i + 'listarexperiencialaboralID'} className="row vw-100 ml-4">
                                <td className='col-6'>
                                    <div className='blue-standard-color'>{row?.Cargo}</div>
                                    <div>{row?.NombreEmpresa}, {row?.PaisEmpresa}</div>
                                    <div>{row?.FechaInicio} - {row?.FechaFin}</div>
                                    <div className='text-justify'>
                                        <span>{row?.FuncionesLogrs}</span>
                                    </div>
                                </td>
                                <td className='col-1'></td>
                                <td>
                                    <div>
                                        {row.File &&
                                            <div className='mt-5'>
                                                <FontAwesomeIcon icon='paperclip' size='1x' title='Anexo' className='hand-pointer blue-standard-color' onClick={(e) => {
                                                    e.preventDefault();
                                                    downloadFile(state.token, row.File.FilePath, row.File.OriginalFileName);
                                                }} />
                                            </div>
                                        }
                                    </div>
                                </td>
                                {!puedoEditar &&
                                    <td>
                                        <div className='mt-5'>
                                            <FontAwesomeIcon icon='eye' size='1x' title='Detalle' className={"hand-pointer blue-standard-color"}
                                                onClick={() => {
                                                    if (puedoEditar === false) {
                                                        toggleEditarExperienciaLaboralModal(row)
                                                    }
                                                }} />
                                        </div>
                                    </td>}

                                {puedoEditar &&
                                    <td >
                                        <div className='mt-5'>
                                            <FontAwesomeIcon icon='edit' size='1x' title='Editar'
                                                className={puedoEditar === true ? "hand-pointer blue-standard-color" : "hand-pointer blue-standard-disabled-color"}
                                                onClick={() => {
                                                    if (puedoEditar === true) {
                                                        toggleEditarExperienciaLaboralModal(row)
                                                    }

                                                }} />
                                        </div>
                                    </td>
                                }
                                <td>
                                    <div className='mt-5'>
                                        <FontAwesomeIcon icon='trash' size='1x' title='Eliminar' className={puedoEditar === true ? "hand-pointer blue-standard-color" : "hand-pointer blue-standard-disabled-color"} onClick={() => toggleEliminarExperienciaLaboralModal(row)} />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>
            </div>
        </>
    );
};

export default SeccionViewExperienciaLaboral
