import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { initialAllOptionPromises } from '../../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import InputText from '../../../../../components/modalComponents/InputText';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { KEYS_DATOS_RESIDENCIA_NI, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, PAISES } from '../../../../../constants/hardCodedConstants';
import { getDepartamentosAPI, getDistritosPorProvinciaAPI, getInformacionResidenciaGestionAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, getTiposViaAPI, getTiposViviendaAPI, getTiposZonaAPI, saveInformacionResidenciaAPI } from '../../../../../consumers/backendApisUrls';

const DatosResidencia = ({idNuevosIngresosEmpleado, idParent: ID_PARENT, isColaborador, inputDisabled, sendCurrentStep, setSendCurrentStep, componentStep }) => {

  const SECTION_NAME = "EditarDatosResidenciaFichaNuevosIngresos";
  const KEYS_INIT = {TipoVivienda: "IdTipoVivienda", TipoVia: "IdTipoVia", TipoZona: "IdTipoZona", PaisResidencia: "IdPaisREsidencia"};

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [allIdDepartamento, setAllIdDepartamento] = useState([]);
  const [allIdProvincia, setAllIdProvincia] = useState([]);
  const [allIdDistrito, setAllIdDistrito] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getTiposViviendaAPI()),
      getOptions(getTiposViaAPI()),
      getOptions(getTiposZonaAPI()),
      getOptions(getPaisesAPI()),
    ]
  }

  const init = async () => {
    try {
      let { data } = await manageSearch();
      initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), (res) => successInitCallback(res, data));
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    const api = getInformacionResidenciaGestionAPI(idNuevosIngresosEmpleado);
    return await executeGet(api, (res) => setFormValues(res.data))
  }

  const successInitCallback = (res, initValues) => {
    setAllDropdowns(res);
    initValues?.IdPaisREsidencia === PAISES.PERU && getDepartamentos();
    initValues?.IdDepartamento && getProvinciasPorDepartamento(initValues?.IdDepartamento);
    initValues?.IdProvincia && getDistritosPorProvincia(initValues?.IdProvincia);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSending(true);
    try {
      const api = saveInformacionResidenciaAPI();
      await submitForm(KEYS_DATOS_RESIDENCIA_NI, api, formValues, SECTION_NAME, successSubmitCallback, !isColaborador, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    manageSearch();
    if(isColaborador) setSendCurrentStep(lastData => ({...lastData, isSent: false, isSaved: true}));
  }

  const handleChangeSelect = (res, key) => {
    setFormValues(lastData => ({...lastData, [key]: res.target?.value?.value}));
  }

  const cleanDropdownSelected = (keys) => {
    keys.map(o => setFormValues(lastData => ({...lastData, [o]: ""})));
  }

  const handleCountryChange = () => {
    if (formValues.IdPaisREsidencia === PAISES.PERU) {
      getDepartamentos();
    } else {
      setAllIdDepartamento([]);
      setAllIdProvincia([]);
      setAllIdDistrito([]);
    }
  }

  const getDepartamentos = async () => {
    const departamentos = await getOptions(getDepartamentosAPI());
    setAllIdDepartamento(departamentos);
  }

  const getProvinciasPorDepartamento = async (initialValues = null) => {
    const provincias = await getOptions(getProvinciasPorDepartamentoAPI(initialValues ?? formValues.IdDepartamento))
    setAllIdProvincia(provincias);
  }

  const getDistritosPorProvincia = async (initialValues = null) => {
    const distritos = await getOptions(getDistritosPorProvinciaAPI(initialValues ?? formValues.IdProvincia));
    setAllIdDistrito(distritos);
  }

  useDidMountEffect(() => {
    if(formValues.IdPaisREsidencia && !isLoading) {
      cleanDropdownSelected(["IdDepartamento", "IdProvincia", "IdDistrito"]);
      handleCountryChange();
    };
  }, [formValues.IdPaisREsidencia])

  useDidMountEffect(() => {
    if(formValues.IdDepartamento && !isLoading) {
      cleanDropdownSelected(["IdProvincia", "IdDistrito"]);
      getProvinciasPorDepartamento();
    };
  }, [formValues.IdDepartamento])
  
  useDidMountEffect(() => {
    if(formValues.IdProvincia && !isLoading) {
      cleanDropdownSelected(["IdDistrito"]);
      getDistritosPorProvincia();
    };
  }, [formValues.IdProvincia])

  useDidMountEffect(() => {
    if (isColaborador && sendCurrentStep?.step === componentStep && sendCurrentStep?.isSent){
      handleSubmit();
    }
  }, [sendCurrentStep])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <form onSubmit={handleSubmit} className={`${isColaborador ? "form-colaborador-inputs" : ""}`}>
      <CardAccordion id={OPTIONS.idDatosResidencia} idParent={ID_PARENT} label="3. Datos de Residencia" isShow isShowGeneral={!isColaborador}>
        <div className="displayFlexWrap mt-4">
          <DivCol required><InputSelect label="Tipo de casa" {...commonPropsSelect("IdTipoVivienda")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Tipo de vía" {...commonPropsSelect("IdTipoVia")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Nombre de vía" {...commonProps("NombreVia")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Nro. de casa" {...commonProps("NumeroCasa")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Nro. de interior" {...commonProps("Interior")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Manzana" {...commonProps("Manzana")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Lote" {...commonProps("Lote")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Etapa" {...commonProps("Etapa")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Block" {...commonProps("Block")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputSelect label="Tipo de zona" {...commonPropsSelect("IdTipoZona")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Nombre de zona" {...commonProps("NombreZona")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="País" {...commonPropsSelect("IdPaisREsidencia")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Departamento" name="IdDepartamento" options={allIdDepartamento} value={allIdDepartamento?.find(o => o.value === formValues.IdDepartamento)} onChange={(res) => handleChangeSelect(res, "IdDepartamento")} sectionName={SECTION_NAME} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Provincia" name="IdProvincia" options={allIdProvincia} value={allIdProvincia?.find(o => o.value === formValues.IdProvincia)} onChange={(res) => handleChangeSelect(res, "IdProvincia")} sectionName={SECTION_NAME} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Distrito" name="IdDistrito" options={allIdDistrito} value={allIdDistrito?.find(o => o.value === formValues.IdDistrito)} onChange={(res) => handleChangeSelect(res, "IdDistrito")} sectionName={SECTION_NAME} direction='column' isDisabled={inputDisabled}/></DivCol>
        </div>
        {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosResidencia;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""}`}>
      {children}
    </div>
  )
}