import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {FLUJOS, GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getFechaVencimientoCierreByActividadIdAPI, getObservarActividadAPI, observarActividadAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric} from "../../../helper/utils";
import SelectInput from "../../../components/Select/SelectInput";
import {useForm} from "../../../hooks/useForm/UseForm";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";

const ObservarActividadModal = ({toggleModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "ObservarActividadModal";

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [actividadesOptions, setActividadesOptions] = useState([]);
    const [jsonSelectedActividadOption, setJsonSelectedActividadOption] = useState({});
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [formData, setFormData] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (jsonSelectedActividadOption?.value) {
            updateDefaultValues();
        }
    }, [jsonSelectedActividadOption]);

    const updateDefaultValues = async function () {
        const response = await executeGetRequest(getFechaVencimientoCierreByActividadIdAPI(jsonSelectedActividadOption?.value, formData?.IdEntity));

        if (responseCode200(response)) {
            let newFormValues = {...response.data, formValues}
            setFormValues(newFormValues);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        const response = await executeGetRequest(getObservarActividadAPI(idEmpleadoFlujoActividad));

        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response?.data?.ActividadesList, "Value", "Text");
            setActividadesOptions(options);
            setFormData(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "IdActividad": jsonSelectedActividadOption?.value,
            "Observacion": formValues?.Observacion,
            "Fechavencimiento": formValues?.FechaVencimiento,
            "FechaCierre": formValues?.FechaCierre
        };

        let response = await executePostRequest(observarActividadAPI(), payload);
        if (responseCode200(response)) {
            toggleModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();

        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdActividad", "Observacion", "Fechavencimiento", "FechaCierre"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={toggleModal} width={"750px"} titulo="Observar adjuntos de actividad">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div>
                                {formData && formData?.Data && formData?.Data.map((elemento, index) => {
                                    return (
                                        <div className="form-group row" key={index}>
                                            <label className="col-sm-4 col-form-label modal-label">{elemento.Name}</label>
                                            <label className="col-sm-8 col-form-label modal-label black-color-important">{elemento.Value}</label>
                                        </div>
                                    )
                                })}
                            </div>
                            <hr/>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label modal-label">Actividad<span className="required">*</span></label>
                                <div className="col-sm-7 black-color">
                                    <SelectInput options={actividadesOptions} jsonSelectedValue={jsonSelectedActividadOption} setJsonSelectedValue={setJsonSelectedActividadOption} isDisabled={false} placeholder={"Seleccionar una actividad"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdActividad-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label modal-label">Responsable<span className="required"></span></label>
                                <div className="col-sm-7 black-color">
                                    <input type="text" className="form-control" value={formValues.Responsable} disabled></input>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label modal-label">Fecha Vencimiento<span className="required">*</span></label>
                                <div className="col-sm-5 black-color">
                                    <input type="date" className="form-control" name="FechaVencimiento" onChange={handleChange} value={formValues.FechaVencimiento} disabled={false}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaVencimiento-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label modal-label black-color-important">{formValues?.HoraVencimiento}</label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label modal-label">Fecha Cierre<span className="required">*</span></label>
                                <div className="col-sm-5 black-color">
                                    <input type="date" className="form-control" name="FechaCierre" onChange={handleChange} value={formValues.FechaCierre} disabled={false}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaCierre-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label modal-label black-color-important">{formValues?.HoraCierre}</label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-4 col-form-label modal-label">Observación<span className="required">*</span></label>
                                <div className="col-sm-7 black-color">
                                    <textarea autoFocus data-required="true" rows="6" type="text" className="form-control" name="Observacion" onChange={handleChange} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observacion-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer d-flex justify-content-between">
                    <div className="red-below-modal-message font-gothan-light align-self-start">
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button align-self-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ObservarActividadModal;
