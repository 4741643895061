import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import { convertirStringToArraySeparadoPorComas, getElementByQuerySelector, hideElement, isCurrentDayGreaterThanNumber, removeAttributeToElement, setAttributeToElement, showElement, stringEndsInThisCharacter } from "../../helper/utils";
import { toast } from "react-toastify";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { cancelarSolicitudDeCambioAPI, getDatosEmpresaAPI, getInformacionCeseByIdEmpleadoAPI } from "../../consumers/backendApisUrls";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { MENUS_FICHA_DATOS } from "../../constants/hardCodedConstants";
import SeccionDatosContratacion from './SeccionDatosContratacion';
import { ModalCambioCuentaSueldo } from '../../components/modalCambioCuentaSueldo/ModalCambioCuentaSueldo';

const SeccionViewDatosEmpresa = ({ empleadoId, showUpdateConfirmationModal, setShowViewSeccion, showSolicitarCambiosModal, reloadView }) => {
    const { state } = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosEmpresa, setDatosEmpresa] = useState();
    const [datosCese, setDatosCese] = useState();
    const [dataFields, setDataFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const canChangeCuentaSueldo = !isCurrentDayGreaterThanNumber(20);
    const [modalEnable, setModalEnable] = useState({isEnable: false});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        console.debug("[DatosEmpresa] useDidMountEffect dataFields");
        dataFieldRelojManager();
        updateHideShowElementsInHTMLWhenDataFieldsChange();
        showEditFormSubtitlesOnlyIfItHasInputChildsOrSelectChilds();
    }, [dataFields]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [reloadView]);

    const updateId = () => {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    };


    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                let responseDatosEmpresa = await executeGetRequest(getDatosEmpresaAPI(idActualizado));
                if (responseCode200(responseDatosEmpresa)) {

                    setDatosEmpresa(responseDatosEmpresa.data.data);
                    setDataFields(responseDatosEmpresa.data.data.DataFields);

                    let dropdownSelectedValues = {};
                    dropdownSelectedValues["IdSede"] = responseDatosEmpresa.data.data.IdSede;
                    dropdownSelectedValues["IdClasificacionGdh"] = responseDatosEmpresa.data.data.IdClasificacionGdh;
                    dropdownSelectedValues["IdCentroCosto"] = responseDatosEmpresa.data.data.IdCentroCosto;
                    dropdownSelectedValues["IdUnidad"] = responseDatosEmpresa.data.data.IdUnidad;
                    dropdownSelectedValues["IdDepartamento"] = responseDatosEmpresa.data.data.IdDepartamento;
                    dropdownSelectedValues["IdArea"] = responseDatosEmpresa.data.data.IdArea;
                    dropdownSelectedValues["IdSeccion"] = responseDatosEmpresa.data.data.IdSeccion;
                    dropdownSelectedValues["IdCargo"] = responseDatosEmpresa.data.data.IdCargo;
                    dropdownSelectedValues["IdNivelEducativo"] = responseDatosEmpresa.data.data.IdNivelEducativo;
                    dropdownSelectedValues["IdEspecialidadPrincipal"] = responseDatosEmpresa.data.data.IdEspecialidadPrincipal;
                    dropdownSelectedValues["IdGradoDesarrollo"] = responseDatosEmpresa.data.data.IdGradoDesarrollo;
                    dropdownSelectedValues["IdTipoInstruccion"] = responseDatosEmpresa.data.data.IdTipoInstruccion;
                    dropdownSelectedValues["IdTipoContrato"] = responseDatosEmpresa.data.data.IdTipoContrato;
                    dropdownSelectedValues["IdFormaIngreso"] = responseDatosEmpresa.data.data.IdFormaIngreso;
                    dropdownSelectedValues["IdFondoPension"] = responseDatosEmpresa.data.data.IdFondoPension;
                    dropdownSelectedValues["IdBancoSueldo"] = responseDatosEmpresa.data.data.IdBancoSueldo;
                    dropdownSelectedValues["IdTipoCuentaSueldo"] = responseDatosEmpresa.data.data.IdTipoCuentaSueldo;
                    dropdownSelectedValues["IdMonedaSueldo"] = responseDatosEmpresa.data.data.IdMonedaSueldo;
                    dropdownSelectedValues["IdMonedaCts"] = responseDatosEmpresa.data.data.IdMonedaCts;
                    dropdownSelectedValues["IdBancoCTS"] = responseDatosEmpresa.data.data.IdBancoCTS;
                    dropdownSelectedValues["IdSeguroSalud"] = responseDatosEmpresa.data.data.IdSeguroSalud;
                    let numberArrayValues = convertirStringToArraySeparadoPorComas(responseDatosEmpresa.data.data.IdOtrasEspecialidades);
                    console.debug("numberArrayValues: ", numberArrayValues);
                    dropdownSelectedValues["IdOtrasEspecialidades"] = numberArrayValues;

                    let dropdownss = await obtenerDropdownsCuyoAPInoRecibeParametros(responseDatosEmpresa.data.data.DataFields, dropdownSelectedValues);
                    console.debug("[DatosEmpresa] dropdownss ", dropdownss);
                } else {
                    mostrarMensajeDeErrorConToast(responseDatosEmpresa);
                }
                let respenseDatosCese = await executeGetRequest(getInformacionCeseByIdEmpleadoAPI(idActualizado));
                if (responseCode200(respenseDatosCese)){
                    setDatosCese(respenseDatosCese.data);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    // manage dropdowns ini
    const obtenerDropdownsCuyoAPInoRecibeParametros = async (dataFieldsArray, dropdownsSelectedValuess) => {
        let dropdownss = {};
        for (let dataField of dataFieldsArray) {
            let dataFieldKey = dataField["KeyField"];
            if ((dataField["FieldType"] === "List") || (dataField["FieldType"] === "MultiList")) {
                let dropdownOptions = await getDropdownOptions(dataField, dataFieldsArray, dropdownsSelectedValuess);
                dropdownss[dataFieldKey] = dropdownOptions;
            }
        }
        return dropdownss;
    };

    const getDropdownOptions = async (dataField, dataFieldss, dropdownsSelectedValuess) => {
        let url = dataField["DataRequestUrl"];
        let dropdownOptions = [];
        if (stringEndsInThisCharacter(url, "/")) {
            dropdownOptions = await obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro(dataField, dataFieldss, dropdownsSelectedValuess);
        } else {
            dropdownOptions = await obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro(dataField);
        }
        return dropdownOptions;
    };

    const obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro = async (dataField, dataFieldss, dropdownsSelectedValuess) => {
        console.debug("[DatosEmpresa] obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro, dataField: ", dataField);
        let api = dataField["DataRequestUrl"];
        let dropdownOptions = [];

        let dataFieldPapa = obtenerDataFieldPapa(dataField, dataFieldss);
        console.debug("[DatosEmpresa] dataFieldPapa: ", dataFieldPapa);
        let keyFieldPapa = dataFieldPapa["KeyField"];
        console.debug("[DatosEmpresa] keyFieldPapa: ", keyFieldPapa);
        let valueSeleccionadoDelPapa = dropdownsSelectedValuess[keyFieldPapa];
        console.debug("[DatosEmpresa] valueSeleccionadoDelPapa: ", valueSeleccionadoDelPapa);

        let finalApi = `${api}${valueSeleccionadoDelPapa}`;
        console.debug("[DatosEmpresa] finalApi: ", finalApi);

        let response = await makeRequestObtenerDropDownOptions(finalApi);
        if (response) {
            dropdownOptions = response;
        }
        return dropdownOptions;
    };

    const obtenerDataFieldPapa = (dataFieldHijo, dataFieldss) => {
        console.debug("obtenerDataFieldPapa dataFieldss ", dataFieldss);
        for (let dataField of dataFieldss) {
            if (dataField["IdDependent"] == dataFieldHijo["IdField"]) {
                return dataField;
            }
        }
    };

    const obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro = async (dataField) => {
        console.debug("[DatosEmpresa] obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro, dataField: ", dataField);
        let api = dataField["DataRequestUrl"];
        let dropdownOptions = [];
        let response = await makeRequestObtenerDropDownOptions(api);
        if (response) {
            dropdownOptions = response;
        }
        return dropdownOptions;
    };

    const makeRequestObtenerDropDownOptions = async (api) => {
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            console.debug("[DatosEmpresa] response data: ", response.data);
            return response.data;
        } else {
            console.warn("hubo un error al ejecutar el api: ", api);
        }
    };


    // manage dropdowns fin

    const actualizar = async () => {
        try {
            setShowViewSeccion(false);
        } catch (e) {
            console.error(e);
        }
    };

    const reset = async () => {
        await updateComponentStates();
    };

    ///hidden ini

    const updateHideShowElementsInHTMLWhenDataFieldsChange = () => {
        console.debug("[DatosEmpresa] updateHideShowElementsInHTMLWhenDataFieldsChange dataFields: ", dataFields);
        try {
            for (const field of dataFields) {
                let fieldKey = field["KeyField"];
                if (field["IsHidden"] === true) {
                    console.debug("[DatosEmpresa] esconder fieldKey: ", fieldKey);
                    hideElement(`ficha-view-datosEmpresa-${fieldKey}`);
                    hideElement(`ficha-edit-inputGroup-datosEmpresa-${fieldKey}`);
                }

                if (field["IsHidden"] === false) {
                    console.debug("[DatosEmpresa] mostrar fieldKey: ", fieldKey);
                    showElement(`ficha-view-datosEmpresa-${fieldKey}`);
                    showElement(`ficha-edit-inputGroup-datosEmpresa-${fieldKey}`);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    ///hidden fin
    //readonly ini

    //readonly fin

    function showOrHideSubtitle(containerName) {
        let subtitleId = `#frmEditDatosEmpresa-${containerName}-title`;
        let containerTitleElement = getElementByQuerySelector(subtitleId);
        if (getContainerInputsChilds(`frmEditDatosEmpresa-${containerName}-container`).length == 0) {
            setAttributeToElement(containerTitleElement, "hidden", "");
        } else {
            removeAttributeToElement(containerTitleElement, "hidden");
        }
    }

    const showEditFormSubtitlesOnlyIfItHasInputChildsOrSelectChilds = () => {
        showOrHideSubtitle("datosPlanilla");
        showOrHideSubtitle("datosContratacion");
        showOrHideSubtitle("informacionEconomica");
    };

    const getContainerInputsChilds = (htmlContainerId) => {
        let query = `#${htmlContainerId} [id^='ficha-edit-inputGroup-']:not([hidden=''])`;
        let elements = (document.querySelectorAll(query));
        console.debug(`query: ${query}, resultElements: `, elements);
        return elements;
    };

    //Reloj cancelar solicitud

    const dataFieldRelojManager = async () => {
        console.debug("[DatosEmpresa] dataFieldRelojManager, dataFields: ", dataFields);
        for (let dataField of dataFields) {
            if (dataField.HasRequest === true) {
                agregarRelojYCambiarColorARojo(dataField);
            } else {
                console.debug("[DatosEmpresa] quitar reloj y asterisco en ", dataField.KeyField);
                quitarRelojYCambiarColorARojo(dataField);
            }
        }
    };

    const agregarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosEmpresa] agregarRelojYCambiarColorARojo ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosEmpresa-${keyField} .change-requested-field-clock`).removeAttribute('hidden');
            document.querySelector(`#ficha-view-datosEmpresa-${keyField}`).classList.add("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para agregarle el reloj`, e);
        }
    };

    const quitarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosEmpresa] quitarRelojYCambiarColorANegro ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosEmpresa-${keyField} .change-requested-field-clock`).setAttribute('hidden', '');
            document.querySelector(`#ficha-view-datosEmpresa-${keyField}`).classList.remove("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para quitar el reloj`, e);
        }
    };

    const obtenerFieldIdByKeyFieldName = (keyFieldName) => {
        const dataField = dataFields.filter(field => field.KeyField == keyFieldName);

        if (dataField.length > 0) {
            return dataField[0].IdField;
        } else {
            console.error("no se encontró keyFieldName: ", keyFieldName)
        }

    };

    const manageCancelarSolicitud = async (e) => {
        console.debug("[DatosEmpresa] manage cancelar solicitud de campo ", e);
        e.preventDefault();
        let buttonId = e.target.id;
        let keyFieldString = buttonId.replace("ficha-view-datosEmpresa-tooltip-cancelar-button-", "");
        let fieldId = obtenerFieldIdByKeyFieldName(keyFieldString);

        if (fieldId) {
            let response = await executePostRequest(cancelarSolicitudDeCambioAPI(fieldId), {});

            if (responseCode200(response)) {
                toast.success("Se canceló solicitud de campo con éxito");
                await reset();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const getHTMLContentForCancelarSolicitudDeCampo = (buttonId) => {
        return (
            <div>
                <div><span>¿Quieres cancelar la solicitud de actualización de este dato?</span></div>
                <div>
                    <button id={buttonId} className="change-requested-tooltip-cancel-button" onClick={manageCancelarSolicitud}>Sí cancelar</button>
                </div>
            </div>
        )
    };

    const CuentaSueldoLabel = (
      <div className="col-12 col-sm-6 col-md-4"
        id="ficha-view-datosEmpresa-NroCuentaSueldo">
        <div className="col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label">
          <p data-tip
            data-for="ficha-view-datosEmpresa-tooltip-NroCuentaSueldo"
            className="ficha-info-div-clock-font-awesome">
            <FontAwesomeIcon icon="clock"
              transform="left-9"
              className="hand-pointer change-requested-field-clock"
              hidden={true}
            />
          </p>
          <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons"
            id={"ficha-view-datosEmpresa-tooltip-NroCuentaSueldo"}
            effect="solid"
            place="top"
            getContent={() => {
              return getHTMLContentForCancelarSolicitudDeCampo(
                "ficha-view-datosEmpresa-tooltip-cancelar-button-NroCuentaSueldo");
            }}/>
          <label className="ficha-info-label-width">
            <p data-tip
              data-for="informacion-economica-nro-cuenta-sueldo"
              className="d-inline">
              <FontAwesomeIcon
                icon="info-circle"
                transform="left-9"
                className="hand-pointer ficha-residencia-ver-info-icon"
              />
            </p>
            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons"
              id={"informacion-economica-nro-cuenta-sueldo"}
              effect="solid"
              place="bottom"
              getContent={function () {
                return (
                  <div>
                    <div>
                      <span>
                        Puedes solicitar tu cambio de cuenta sueldo desde el 1
                        al 19 de cada mes.
                      </span>
                    </div>
                    <div>
                      <button disabled={!canChangeCuentaSueldo}
                        className={`tooltip-button ${
                          canChangeCuentaSueldo ? "" : "disabled"
                        }`}
                        onClick={(e) => {
                            e.preventDefault();
                            setModalEnable({
                                isEnable: true, 
                                component: ModalCambioCuentaSueldo})
                            }}>
                        Solicitar
                      </button>
                    </div>
                  </div>
                );
              }}
            />
            Nro Cuenta Sueldo:
          </label>
        </div>
        <label className="col-6 col-sm-6 col-md-6 col-form-label">
          {datosEmpresa && <p>{datosEmpresa.NroCuentaSueldo}</p>}
        </label>
      </div>
    );

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha />
            )}
            {modalEnable.isEnable && 
                <div className="modal-contactanos modal-response-ficha-background">
                    <modalEnable.component 
                        idEmpleado={empleadoId || state.user.id_empleado}
                        showModal={() => setModalEnable({isEnable: false})}/>
                </div>
            }
            <form id='frmInfoDatosEmpresa' hidden={loading}>
                <div className='displayFlexWrap'>
                    <div style={{ 'width': '100%' }}>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos de Perfil</p>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdTrabajadorOfisis">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdTrabajadorOfisis" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdTrabajadorOfisis'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdTrabajadorOfisis")
                                }} />
                            <label className="ficha-info-label-width">
                                Código Trabajador OFISIS:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.IdTrabajadorOfisis}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdUnidad">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdUnidad" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdUnidad'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdUnidad")
                                }} />
                            <label className="ficha-info-label-width">
                                División:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Unidad}</p>}
                        </label>
                    </div>


                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdSede">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdSede" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdSede'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdSede")
                                }} />
                            <label className="ficha-info-label-width">
                                Sede:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Sede}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdClasificacionGdh">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdClasificacionGdh" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdClasificacionGdh'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdClasificacionGdh")
                                }} />
                            <label className="ficha-info-label-width">
                                Clasificación Grupo GDH:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.ClasificaciónGdh}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdDepartamento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdDepartamento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdDepartamento'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdDepartamento")
                                }} />
                            <label className="ficha-info-label-width">
                                Gerencia:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Departamento}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdArea">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdArea" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdArea'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdArea")
                                }} />
                            <label className="ficha-info-label-width">
                                Área:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Area}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdSeccion">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdSeccion" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdSeccion'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdSeccion")
                                }} />
                            <label className="ficha-info-label-width">
                               Sección:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Seccion}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdCargo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdCargo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdCargo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdCargo")
                                }} />
                            <label className="ficha-info-label-width">
                                Cargo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.Cargo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdNivelEducativo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdNivelEducativo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdNivelEducativo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdNivelEducativo")
                                }} />
                            <label className="ficha-info-label-width">
                                Nivel:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.NivelEducativo}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdEspecialidadPrincipal">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdEspecialidadPrincipal" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdEspecialidadPrincipal'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdEspecialidadPrincipal")
                                }} />
                            <label className="ficha-info-label-width">
                                Especialidad Principal:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.EspecialidadPrincipal}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdOtrasEspecialidades">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdOtrasEspecialidades" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdOtrasEspecialidades'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdOtrasEspecialidades")
                                }} />
                            <label className="ficha-info-label-width">
                                Otras Especialidades:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.OtrasEspecialidades}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdGradoDesarrollo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdGradoDesarrollo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdGradoDesarrollo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdGradoDesarrollo")
                                }} />
                            <label className="ficha-info-label-width">
                                Grado de Desarrollo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.GradoDesarrollo}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-Tutoria">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-Tutoria" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-Tutoria'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-Tutoria")
                                }} />
                            <label className="ficha-info-label-width">
                                Tutoria:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{(datosEmpresa.Tutoria) ? "Sí" : "No"}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-CorreoCorporativo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-CorreoCorporativo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-CorreoCorporativo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-CorreoCorporativo")
                                }} />
                            <label className="ficha-info-label-width">
                                Correo Corporativo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.CorreoCorporativo}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-CelularCorporativo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-CelularCorporativo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-CelularCorporativo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-CelularCorporativo")
                                }} />
                            <label className="ficha-info-label-width">
                                Celular Corporativo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.CelularCorporativo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdCentroCosto">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdCentroCosto" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdCentroCosto'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdCentroCosto")
                                }} />
                            <label className="ficha-info-label-width">
                                Centro de Costo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.CentroCosto}</p>}
                        </label>
                    </div>


                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdTipoInstruccion">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdTipoInstruccion" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdTipoInstruccion'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdTipoInstruccion")
                                }} />
                            <label className="ficha-info-label-width">
                                Tipo de Instrucción:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.TipoInstruccion}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdFormaIngreso">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdFormaIngreso" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdFormaIngreso'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdFormaIngreso")
                                }} />
                            <label className="ficha-info-label-width">
                                Forma de Ingreso:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.FormaIngreso}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-FechaIngresoEmpresa">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-FechaIngresoEmpresa" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-FechaIngresoEmpresa'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-FechaIngresoEmpresa")
                                }} />
                            <label className="ficha-info-label-width">
                                Fecha Ingreso Empresa:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.FechaIngresoEmpresa}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-FechaIngresoReal">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-FechaIngresoReal" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-FechaIngresoReal'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-FechaIngresoReal")
                                }} />
                            <label className="ficha-info-label-width">
                                Fecha Ingreso Real:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.FechaIngresoReal}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdEmpleadoSuperior">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdEmpleadoSuperior" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdEmpleadoSuperior'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdEmpleadoSuperior")
                                          }} />
                            <label className="ficha-info-label-width">
                                Líder:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.EmpleadoSuperior}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdEmpleadoMatricialSuperior">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdEmpleadoMatricialSuperior" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdEmpleadoMatricialSuperior'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdEmpleadoMatricialSuperior")
                                          }} />
                            <label className="ficha-info-label-width">
                                Líder Matricial:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.EmpleadoMatricialSuperior}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdTipoEmpleado">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <label className="ficha-info-label-width">
                                Tipo empleado:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.TipoEmpleado}</p>}
                        </label>
                    </div>

                    {datosEmpresa?.PuedeVerDatosContratacion ? (
                        <SeccionDatosContratacion empleadoId={idActualizado} />
                    ) : null}

                <>
                    <div style={{ 'width': '100%', 'marginTop': '25px' }}>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Información económica</p>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdFondoPension">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdFondoPension" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdFondoPension'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdFondoPension")
                                }} />
                            <label className="ficha-info-label-width">
                                <p data-tip data-for="informacion-economica-pensiones" style={{ display: 'inline' }}>
                                    <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon" />
                                </p>
                                <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'informacion-economica-pensiones'} effect="solid" place="bottom"
                                    getContent={function () {
                                        return (
                                            <div>
                                                <div><span>Descarga el formato de solicitud de cambio de sistema pensionario.</span></div>
                                                <div>
                                                    <button className="tooltip-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(state.token, datosEmpresa.FormatoSolicitudFileData.Path, datosEmpresa.FormatoSolicitudFileData.OriginalFileName);
                                                    }
                                                    }>
                                                        Descargar
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }} />
                                Fondo de Pensiones:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.FondoPension}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-FechaAfiliacionAFP">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-FechaAfiliacionAFP" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-FechaAfiliacionAFP'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-FechaAfiliacionAFP")
                                }} />
                            <label className="ficha-info-label-width">
                                Fecha de Afiliación:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.FechaAfiliacionAFP}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-NroCUSPP">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-NroCUSPP" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-NroCUSPP'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-NroCUSPP")
                                }} />
                            <label className="ficha-info-label-width">
                                Numero CUSPP:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.NroCUSPP}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdBancoSueldo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdBancoSueldo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdBancoSueldo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdBancoSueldo")
                                }} />
                            <label className="ficha-info-label-width">
                                Nombre Banco Sueldo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.BancoSueldo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdTipoCuentaSueldo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdTipoCuentaSueldo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdTipoCuentaSueldo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdTipoCuentaSueldo")
                                }} />
                            <label className="ficha-info-label-width">
                                Tipo de Cuenta Sueldo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.TipoCuentaSueldo}</p>}
                        </label>
                    </div>

                    {CuentaSueldoLabel}

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdMonedaSueldo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdMonedaSueldo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdMonedaSueldo'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdMonedaSueldo")
                                }} />
                            <label className="ficha-info-label-width">
                                Moneda Sueldo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.MonedaSueldo}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdBancoCTS">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdBancoCTS" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdBancoCTS'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdBancoCTS")
                                }} />
                            <label className="ficha-info-label-width">
                                <p data-tip data-for="informacion-economica-cts" style={{ display: 'inline' }}>
                                    <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon" />
                                </p>
                                <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'informacion-economica-cts'} effect="solid" place="bottom"
                                    getContent={function () {
                                        return (
                                            <div>
                                                <div><span>Descarga el formato de solicitud CTS.</span></div>
                                                <div>
                                                    <button className="tooltip-button" onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(state.token, datosEmpresa.FormatoSolicitudCTSFileData.Path, datosEmpresa.FormatoSolicitudCTSFileData.OriginalFileName);
                                                    }
                                                    }>
                                                        Descargar
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }} />
                                Nombre Banco CTS:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.BancoCTS}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-NroCuentaCTS">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-NroCuentaCTS" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-NroCuentaCTS'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-NroCuentaCTS")
                                }} />
                            <label className="ficha-info-label-width">
                                Numero Cuenta CTS:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.NroCuentaCTS}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdMonedaCts">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdMonedaCts" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdMonedaCts'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdMonedaCts")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Moneda CTS:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.MonedaCts}</p>}
                        </label>
                    </div>

                    {/* <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-TieneAsignacionFamiliar">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-TieneAsignacionFamiliar" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-TieneAsignacionFamiliar'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-TieneAsignacionFamiliar")
                                }} />
                            <label className="ficha-info-label-width">
                                Tiene asignacion familiar:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{(datosEmpresa.TieneAsignacionFamiliar) ? "Sí" : "No"}</p>}
                        </label>
                    </div> */}

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosEmpresa-IdSeguroSalud">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosEmpresa-tooltip-IdSeguroSalud" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true} />
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosEmpresa-tooltip-IdSeguroSalud'} effect="solid" place="top"
                                getContent={() => {
                                    return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosEmpresa-tooltip-cancelar-button-IdSeguroSalud")
                                }} />
                            <label className="ficha-info-label-width">
                                Seguro:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosEmpresa && <p>{datosEmpresa.SeguroSalud}</p>}
                        </label>
                    </div>
                </>
                {datosCese && (
                    <>
                    <div className='w-100 mt-4'>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos Cese</p>
                    </div>
            
                    <div className='table-responsive form-group-sm mt-2'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th className='text-center font-gothan-medium'>Fecha de Registro</th>
                                    <th className='text-center font-gothan-medium'>Estado</th>
                                    <th className='text-center font-gothan-medium'>Fecha Último día</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>{datosCese?.FechaRegistro}</td>
                                    <td className='text-center'>{datosCese?.Estado}</td>
                                    <td className='text-center'>{datosCese?.FechaCese}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </>
                )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {datosEmpresa && datosEmpresa.CanSeeButtonSolicitarCambios === true &&
                        <button
                            onClick={() => showSolicitarCambiosModal(MENUS_FICHA_DATOS.datosEmpresa.secciones.datosEmpresa.id, updateComponentStates)}
                            type='button'
                            className='btn btn-primary ficha-form-button-SolicitarCambios'>
                            Solicitar cambios
                        </button>
                    }
                    {datosEmpresa && datosEmpresa.CanSeeButtonEditar === true &&
                        <button
                            onClick={actualizar}
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'>
                            Actualizar
                        </button>
                    }
                </div>
            </form>
        </>
    );
};
export default SeccionViewDatosEmpresa;