import React, {useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";

import {withStyles} from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import parse from 'html-react-parser';
import Paginator from "../directorio/Paginator";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getPreguntasFrecuentesAPI} from "../../consumers/backendApisUrls";

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        color: '#3a87c8',
        background: 'rgba(0,0,0,.03);',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        borderRadius: '5px',
        minHeight: 20,
        '&$expanded': {
            minHeight: 16,
        },
    },
    content: {
        '&$expanded': {
            margin: '8px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function PreguntasFrecuentes() {
    const [expanded, setExpanded] = React.useState('panel0');

    const [preguntasFiltradas, setPreguntasFiltradas] = useState([]);
    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [executePostRequest] = usePostRequest();

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda]);

    useEffect(() => {
        manageSearch();
    }, [page]);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("page: ", page);

        let payload = {
            "Criteria": criterioDeBusqueda,
            "Page": page
        };
        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        console.debug("payload: ", payload);
        const response = await executePostRequest(getPreguntasFrecuentesAPI(), payload);
        console.debug("response body: ", response.data);
        if (responseCode200(response)) {
            let responseBody = response.data;
            setPreguntasFiltradas(responseBody.Items);
            setTotalItems(responseBody.TotalItems);
            setFirsItemPage(responseBody.FirsItemPage);
            setLastItemPage(responseBody.LastItemPage);
            setTotalPages(responseBody.TotalPages);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    return (
        <>
            <Wrapper className='App'>
                <PageHeader title={"Zona de Tutoriales"}/>

                <div className='container-fluid body-content'>
                    <div style={{'display': 'flex', 'justify-content': 'space-between'}}>
                        <div style={{'align-self': 'flex-start'}}>
                            <div className='input-group mb-3 buscador'>
                                <input
                                    type='text'
                                    className='input-buscador'
                                    placeholder='Buscar'
                                    value={criterioDeBusqueda}
                                    onChange={(e) => {
                                        setCriterioDeBusqueda(e.target.value);
                                    }}
                                    onKeyPress={handleKeypress}
                                />
                                <button
                                    onClick={manageSearch}
                                    type='button'
                                    className='btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'>
                                    Buscar
                                </button>
                            </div>
                        </div>
                        <div style={{'align-self': 'flex-end'}}>
                        </div>
                    </div>

                    <div className='table-responsive form-group-sm pt-3'>
                        <div>
                            {preguntasFiltradas && preguntasFiltradas.map((item, i) => (
                                <Accordion square expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={"panel-content" + i} id={"panel-header" + i}>
                                        <Typography><p className="font-gothan-medium">{item.Pregunta}</p></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                            <div className="d-flex justify-content-between w-100">
                                                <div className="w-75">
                                                    {parse(`${item.Respuesta}`)}
                                                </div>
                                                <div className="w-25 d-flex align-items-center justify-content-center">
                                                    <div><a target="_blank" className="btn btn-primary ficha-form-button-actualizar" role="button" href={item?.Urls}>Ver más información</a></div>
                                                </div>
                                            </div>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>

                        {totalPages > 1 && (
                            <Paginator totalItems={totalItems}
                                       firsItemPage={firsItemPage}
                                       lastItemPage={lastItemPage}
                                       totalPages={totalPages}
                                       page={page}
                                       onLeftClic={onLeftClic}
                                       onRightClic={onRightClic}/>
                        )}
                    </div>
                </div>
            </Wrapper>
        </>
    );
}
