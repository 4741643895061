import React, {useEffect, useState} from 'react';
import SeccionViewDatosDeNacimiento from "./SeccionViewDatosDeNacimiento";
import SeccionEditDatosDeNacimiento from "./SeccionEditDatosDeNacimiento";
import CustomAlert from "../../components/alerts/CustomAlert";
import {ESTADOS_ACTUALIZACION_FICHA} from "../../constants/hardCodedConstants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {v4 as uuidv4} from 'uuid';
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDatosNacimientoAPI} from "../../consumers/backendApisUrls";

const DatosDeNacimiento = ({empleadoId, showSolicitarCambiosModal, showUpdateConfirmationModal, reloadView, datosNacimientoHasBeenUpdated, setDatosNacimientoHasBeenUpdated, reloadSeccionesDatosPersonales}) => {
    const defaultAlertMessage = "Para validar tus actualizaciones o solicitudes de cambio te pediremos adjuntar PDF o imagen de tu DNI/CE. ¡Tenlo a mano!";
    const [showViewSeccion, setShowViewSeccion] = useState(true);
    const [statusUpdateNacimiento, setStatusUpdateNacimiento] = useState({});
    const [fakeId, setFakeId] = useState("");
    const [executeGetRequest] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setFakeId(uuidv4());
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        getAlertMessage();
    }, [showViewSeccion]);

    const getStatusUpdateNacimiento = function (response) {
        try {
            if (responseCode200(response)) {
                setStatusUpdateNacimiento(response.data.validationData);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getAlertMessage = async function () {
        const response = await executeGetRequest(getDatosNacimientoAPI(empleadoId));
        if (responseCode200(response)) {
            getStatusUpdateNacimiento(response);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <div>
            <CustomAlert variant={"warning"}>
                {statusUpdateNacimiento && (statusUpdateNacimiento.IdEstado === ESTADOS_ACTUALIZACION_FICHA.pendiente.id || statusUpdateNacimiento.IdEstado === ESTADOS_ACTUALIZACION_FICHA.observado.id) &&
                <p className="ficha-secciones-title-text">
                    {statusUpdateNacimiento.Message}
                    {statusUpdateNacimiento.Info &&
                    <>
                        <span data-tip data-for={"ficha-secciones-status-info" + fakeId} style={{display: 'inline'}}>
                            &nbsp;<FontAwesomeIcon icon='info-circle' transform="left-1" className="hand-pointer"/>
                        </span>
                        <ReactTooltip id={"ficha-secciones-status-info" + fakeId} place="right" effect="solid" className="tooltip-style">
                            {statusUpdateNacimiento && <p>{statusUpdateNacimiento.Info}</p>}
                        </ReactTooltip>
                    </>
                    }
                </p>
                }

                {statusUpdateNacimiento && statusUpdateNacimiento.IdEstado !== ESTADOS_ACTUALIZACION_FICHA.pendiente.id && statusUpdateNacimiento.IdEstado !== ESTADOS_ACTUALIZACION_FICHA.observado.id &&
                <p className="ficha-secciones-title-text">
                    {defaultAlertMessage}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer"/>
                </p>
                }
            </CustomAlert>
            <div className='card-body'>
                {showViewSeccion === true &&
                <>
                    <SeccionViewDatosDeNacimiento empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadView={reloadView}/>
                </>
                }
            </div>
            <div>
                {(showViewSeccion === false) &&
                <>
                    <SeccionEditDatosDeNacimiento empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal} datosNacimientoHasBeenUpdated={datosNacimientoHasBeenUpdated} setDatosNacimientoHasBeenUpdated={setDatosNacimientoHasBeenUpdated} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                </>
                }
            </div>
        </div>
    );
};

export default DatosDeNacimiento;