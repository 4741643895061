import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../hooks/context/authContext";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { downloadFile } from "../../consumers/backendConsumer";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { getEmpleadoIdiomasAPI } from "../../consumers/backendApisUrls";
import { responseCode200 } from "../../consumers/httpRequiestsUtils";
import AgregarEmpleadoIdiomaModal from "./modales/AgregarEmpleadoIdiomaModal";
import EditarEmpleadoIdiomaModal from "./modales/EditarEmpleadoIdiomaModal";
import EliminarEmpleadoIdiomaModal from "./modales/EliminarEmpleadoIdiomaModal";
import CustomAlert from "../../components/alerts/CustomAlert";

const ListarEmpleadoIdiomas = ({
    empleadoId, puedoEditar
}) => {
    const SECTION_NAME = 'ListarIdiomasEmpleados';
    const [idActualizado, setId] = useState();

    const { state } = useContext(AuthContext);
    const [executeGet] = useGetRequest();

    const [empleadoIdiomas, setEmpleadoIdiomas] = useState([]);

    const [hideModalAgregarEmpleadoIdioma, setHideModalAgregarEmpleadoIdioma] = useState(false);
    const [hideModalEditarEmpleadoIdioma, setHideModalEditarEmpleadoIdioma] = useState(false);
    const [hideModalEliminarEmpleadoIdioma, setHideModalEliminarEmpleadoIdioma] = useState(false);

    const [empleadoIdiomaLaboralToEditOrDelete, setEmpleadoIdiomaLaboralToEditOrDelete] = useState({});

    const defaultAlertMessage =
        "Para validar tus actualizaciones te pediremos adjuntar un archivo PDF o imagen. ¡Tenlo a mano!";

    function updateId() {
        if (empleadoId)
            setId(empleadoId);
        else
            setId(state.user.id_empleado);
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        init();
    }, [idActualizado]);

    const init = async () => {
        try {
            if (idActualizado) {
                let response = await executeGet(getEmpleadoIdiomasAPI(idActualizado));
                if (responseCode200(response)) {
                    setEmpleadoIdiomas(response?.data);
                } else {
                    setEmpleadoIdiomas([]);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    const toggleAgregarEmpleadoIdiomaModal = () => {
        setHideModalAgregarEmpleadoIdioma(!hideModalAgregarEmpleadoIdioma);
    }

    const toggleEditarEmpleadoIdiomaModal = (empleadoIdioma) => {
        setHideModalEditarEmpleadoIdioma(!hideModalEditarEmpleadoIdioma);
        setEmpleadoIdiomaLaboralToEditOrDelete(empleadoIdioma);
    }

    const toggleEliminarEmpleadoIdiomaModal = (empleadoIdioma) => {
        if (puedoEditar === true) {
            setHideModalEliminarEmpleadoIdioma(!hideModalEliminarEmpleadoIdioma);
            setEmpleadoIdiomaLaboralToEditOrDelete(empleadoIdioma);
        }
    }

    return (
        <>
            {hideModalAgregarEmpleadoIdioma &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <AgregarEmpleadoIdiomaModal
                        toggleCurrentModal={toggleAgregarEmpleadoIdiomaModal}
                        empleadoId={idActualizado}
                        reFetchList={init}
                        boolHasIdioma={empleadoIdiomas?.DeclaraInformacionDeIdiomas}
                    />
                </div>
            }

            {hideModalEditarEmpleadoIdioma &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EditarEmpleadoIdiomaModal
                        toggleCurrentModal={toggleEditarEmpleadoIdiomaModal}
                        reFetchList={init}
                        empleadoIdioma={empleadoIdiomaLaboralToEditOrDelete}
                        puedoEditar={puedoEditar}
                    />
                </div>
            }

            {hideModalEliminarEmpleadoIdioma &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EliminarEmpleadoIdiomaModal
                        toggleCurrentModal={toggleEliminarEmpleadoIdiomaModal}
                        reFetchList={init}
                        empleadoIdioma={empleadoIdiomaLaboralToEditOrDelete}
                    />
                </div>
            }

            <CustomAlert variant={"warning"}>
                <p className="ficha-secciones-title-text font-gothan-light">
                    {defaultAlertMessage}&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon
                        icon="info-circle"
                        transform="left-9"
                        className="hand-pointer"
                    />
                </p>
                {!empleadoIdiomas?.idiomas?.some(x => x) &&
                    <p className="ficha-secciones-title-text font-gothan-light">Haz clic en agregar para comenzar a completar tu registro</p>
                }
            </CustomAlert>

            <div className='card-body mt-0 ml-3'>
                <div className='d-flex justify-content-end'>
                    <button
                        type='button'
                        className='btn btn-primary generic-button-buscador mb-3 mr-3'
                        onClick={toggleAgregarEmpleadoIdiomaModal} disabled={!puedoEditar}>
                        Agregar
                    </button>
                </div>


                <div>
                    {empleadoIdiomas?.DeclaraInformacionDeIdiomas == false 
                    ? <p>No cuento con idiomas.</p> 
                    : empleadoIdiomas?.idiomas?.map((row, i) => (
                        <div className="row" key={SECTION_NAME + i}>
                            <div className="col-md-2">
                                <p className='blue-standard-color'>{row?.IdiomaName}</p>
                            </div>
                            <div className="col-md-2">
                                <p>{row?.NivelIdiomaName}</p>
                            </div>
                            <div className="col-md-5">
                                <p>{row?.CertificacionIdiomaName}</p>
                            </div>
                            <div className="col-md-2 d-flex justify-content-around">
                                {row.File &&
                                    <div className='justify-content-around'>
                                        <FontAwesomeIcon icon='paperclip' size='1x' title='Anexo' className='hand-pointer blue-standard-color' onClick={(e) => {
                                            e.preventDefault();
                                            downloadFile(state.token, row.File.FilePath, row.File.OriginalFileName);
                                        }} />
                                    </div>
                                }



                                <>
                                    {!puedoEditar &&
                                        <div>
                                            <FontAwesomeIcon icon='eye' size='1x' title='Editar' className={"hand-pointer blue-standard-color"}
                                                onClick={() => {
                                                    if (puedoEditar === false) {
                                                        toggleEditarEmpleadoIdiomaModal(row)
                                                    }
                                                }}
                                            />
                                        </div>}

                                    {puedoEditar &&
                                        <div>
                                            <FontAwesomeIcon icon='edit' size='1x' title='Editar' className={puedoEditar === true ? "hand-pointer blue-standard-color" : "hand-pointer blue-standard-disabled-color"}
                                                onClick={() => {
                                                    if (puedoEditar === true) {
                                                        toggleEditarEmpleadoIdiomaModal(row)
                                                    }
                                                }}
                                            />
                                        </div>
                                    }
                                    <div>
                                        <FontAwesomeIcon icon='trash' size='1x' title='Eliminar' className={puedoEditar === true ? "hand-pointer blue-standard-color" : "hand-pointer blue-standard-disabled-color"} onClick={() => toggleEliminarEmpleadoIdiomaModal(row)} />
                                    </div>
                                </>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default ListarEmpleadoIdiomas;
