import React, { useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import { RESERVA_TIPO_UBICACION_ENTITY } from '../../../constants/hardCodedConstants';
import SelectInput from "../../../components/Select/SelectInput";
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { getQueryParams, jsonEstaVacio, jsonNoEstaVacio, setStateWithCallBackResponse } from '../../../helper/utils';
import { getFichaSintomatologicaReservas, getReservaSintomasTriajeAPI, guardarReservaTriajeAPI } from '../../../consumers/backendApisUrls';
import SelectCheckboxInput from '../../../components/Select/SelectCheckboxInput';
import SelectRadioButtonInput from '../../../components/Select/SelectRadioButtonInput';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { toast } from 'react-toastify';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import { useHistory } from 'react-router';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';

const Triaje = ({ showModal, informacionTriaje, refreshPage, location, closeFormParent }) => {

    const SECTION_NAME = "RESERVA_TRIAJE";
    const allInputKeys = ["IdsSintomas", "EstuvoEnContactoCovid", "EstuvoFueraPais", "VisitoEstablecimientoSalud", "TomaMedicamentos", "DetalleMedicamentos"];

    const history = useHistory();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [sintomasOptions, setSintomasOptions] = useState([]);
    const [jsonSelectedSintomas, setJsonSelectedSintomas] = useState();
    const [optionsYesOrNO, setOptionsYesOrNo] = useState([{ value: "true", label: "Si" }, { value: "false", label: "No" }]);
    const [contactoCovid, setContactoCovid] = useState();
    const [fueraPais, setFueraPais] = useState();
    const [visitaEstablecimientoSalud, setVisitaEstablecimientoSalud] = useState();
    const [tomaMedicamentos, setTomaMedicamentos] = useState();
    const [medicamentosDetalle, setMedicamentosDetalle] = useState();

    const [questions, setQuestions] = useState([]);
    const [declaracionJurada, setDeclaracionJurada] = useState(false);
    const [checkedQuestions, setCheckedQuestions] = useState({});
    const [hideModalConfirmacion, setHideModalConfirmacion] = useState(false);

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [manageGetRequest] = useManageGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const callbackSuccessGetQuestions = (response) => {
        const questionsResponse = response.data.Questions;
        setQuestions(questionsResponse);
        const selectedValues = questionsResponse.reduce((obj, item) => {
            return {
                ...obj,
                [item.IdFichaSintomatologicaAsk]: false
            }
        }, {});

        setCheckedQuestions(selectedValues);
    }

    const init = async () => {
        await manageGetRequest(getFichaSintomatologicaReservas(), callbackSuccessGetQuestions, false);
    };

    const guardarTriaje = async (event) => {
        try {
            event.preventDefault();
            let payload = obtenerPayload();
            let response = await executePostRequest(guardarReservaTriajeAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                closeForm();
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, SECTION_NAME);
                toggleModalConfirmacion();
            } else {
                mostrarMensajeDeErrorConToast(response);
                closeForm();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const closeForm = () => {
        let queryParams = getQueryParams(location.search);
        if (jsonNoEstaVacio(queryParams)) {
            history.push("/gestionReservas");
        }
        refreshPage();
        showModal()
        closeFormParent();
    };

    const obtenerPayload = () => {

        const items = Object.keys(checkedQuestions).map(key => ({
            IdFichaSintomatologicaAsk: key,
            Answer: checkedQuestions[key],
            Detail: formValues[key] || ""
        }))

        let payload = {
            IdReserva: informacionTriaje.idReserva ?? null,
            IdEmpleado: informacionTriaje.idParticipante ?? null,
            Items: items
        };

        return payload;
    }

    const clickOnCheckbox = async function (e) {
        let checkedValue = e.target.checked;
        let idAcceso = e.target.dataset.id;

        setCheckedQuestions({
            ...checkedQuestions,
            [idAcceso]: checkedValue
        })
    };

    const hasCheckedBoxes = () =>{
        for (const [key,value] of Object.entries(checkedQuestions)) {
            if(value === true) return true
        }
        return false
    };

    const handleConfirmacionFicha = (event) => {
        if(hasCheckedBoxes()) toggleModalConfirmacion();
        else guardarTriaje(event);
    }

    const toggleModalConfirmacion = () => {
        setHideModalConfirmacion(!hideModalConfirmacion);
    }

    return (
        <>
            <GenericModal showModal={closeForm} width={"1000px"} titulo={"Ficha sintomatológica de la COVID-19 - Declaración jurada"}>
                <div hidden={hideModalConfirmacion}>
                    <div className="modal-body">
                        <form>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Sede</label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput
                                        setJsonSelectedValue={() => { }}
                                        jsonSelectedValue={informacionTriaje?.sede}
                                        isDisabled={true} />
                                </div>
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Fecha Reserva</label>
                                <div className="col-sm-4">
                                    <input type="date" className="form-control" value={informacionTriaje?.fechaReserva} disabled={true} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Responsable</label>
                                <div className="col-sm-4 black-color">
                                    <AsyncSelectInput
                                        isDisabled={true}
                                        defaultValue={informacionTriaje?.responsable} />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Tipo Ubicación</label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput
                                        setJsonSelectedValue={() => { }}
                                        jsonSelectedValue={informacionTriaje?.tipoUbicacion}
                                        isDisabled={true} />
                                </div>
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">
                                    {informacionTriaje?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL && "Área de trabajo"}
                                    {informacionTriaje?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA && "Nombre sala"}
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput
                                        setJsonSelectedValue={() => { }}
                                        jsonSelectedValue={informacionTriaje?.ubicacion}
                                        isDisabled={true} />
                                </div>
                            </div>
                            {informacionTriaje?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL &&
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Posición</label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput
                                        setJsonSelectedValue={() => { }}
                                        jsonSelectedValue={informacionTriaje?.posicion}
                                        isDisabled={true} />
                                </div>
                            </div>
                            }
                            <small>Todos los datos expresados en esta ficha constituyen una declaración jurada de mi parte. He sido informado que de omitir o falsear información puedo perjudicar la salud de mis compañeros/as, y la mía propia, lo cual, de constituir una falta grave a la salud pública, asumo sus consecuencias.</small>
                            <>
                                <div className="modal-body pt-0 pl-2 pr-1 pb-0">
                                    <div className="d-flex justify-content-between mb-4">
                                        <div>En los últimos 7 días calendarios ha tenido alguno de los síntomas siguientes: </div>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="row g-5">
                                            {questions.map(question => (
                                                <div key={question.IdFichaSintomatologicaAsk} className="col-xs-12 col-md-6 px-md-4 px-0">
                                                    <div className="d-flex justify-content-between text-justify">
                                                        <div>
                                                            {question.Ask}
                                                        </div>
                                                        <div>
                                                            <div className="custom-control custom-switch ml-3" style={{ zIndex: 0 }}>
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input hand-pointer"
                                                                    id={question.ValidationCode}
                                                                    data-id={question.IdFichaSintomatologicaAsk}
                                                                    name={question.Nombre} defaultChecked={checkedQuestions[question.IdFichaSintomatologicaAsk]}
                                                                    onChange={clickOnCheckbox}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={question.ValidationCode}
                                                                >
                                                                    {checkedQuestions[question.IdFichaSintomatologicaAsk] ? "Si" : "No"}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {question.NeedDetail && checkedQuestions[question.IdFichaSintomatologicaAsk] ? (
                                                        <div>
                                                            <textarea
                                                                name={question.IdFichaSintomatologicaAsk}
                                                                onChange={handleChange}
                                                                value={formValues[question.IdFichaSintomatologicaAsk]}
                                                                className="form-control"
                                                                id="floatingTextarea"
                                                            ></textarea>
                                                        </div>
                                                    ) : null}
                                                    <div>
                                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${question.ValidationCode}-validation-message`}></p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </>
                        </form>
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                        <div className="button d-flex justify-content-end">
                            <button className="btn btn-primary generic-button-ficha" onClick={handleConfirmacionFicha}>Enviar</button>
                        </div>
                    </div>
                </div>
                {hideModalConfirmacion ? (
                    <>
                        <div class="alert alert-danger" role="alert">
                            Has completado tu ficha con síntoma/s sospechoso/s a COVID - 19, activaremos alertas para el seguimiento de tu caso.
                        </div>
                        <div className="modal-footer d-flex justify-content-end" >
                            <div className="button">
                                <button className="btn btn-primary generic-button-ficha" onClick={toggleModalConfirmacion}>Atrás</button>
                            </div>
                            <div className="button">
                                <button className="btn btn-primary generic-button-ficha" onClick={guardarTriaje}>De acuerdo</button>
                            </div>
                        </div>
                    </>
                ) : null}           
            </GenericModal>
        </>
    );
};

export default Triaje;
