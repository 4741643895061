import React from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { ActivarInactivarProcesoDeCierreAPI } from '../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';


const ActivarInactivarProcesoDeCierre = ({
    toggleModal,
    refreshPage,
    procesoDeCierreTitle,
    procesoDeCierreId,
    procesoDeCierreEstado
}) => {

    const [managePost] = useManagePostRequest();

    const activarInactivarProcesoDeCierre = async () => {
        await managePost(ActivarInactivarProcesoDeCierreAPI(procesoDeCierreId), {}, () => {
            refreshPage();
            toggleModal();
        }, true);
    }

    return(
        <GenericModal titulo={procesoDeCierreEstado ? "Inactivar ProcesoDeCierre" : "Activar ProcesoDeCierre"} showModal={toggleModal} width={"620px"}>
            <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    Se va a {procesoDeCierreEstado ? "inactivar" : "activar"} el siguiente Proceso de cierre:
                    <ul>
                        <li><strong>{procesoDeCierreTitle}</strong></li>
                    </ul>
                    ¿Está seguro de esta acción?
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <div className="button">
                    <button 
                        className="btn btn-primary generic-button-ficha mr-2"
                        onClick={activarInactivarProcesoDeCierre}
                    >
                        {procesoDeCierreEstado ? "Inactivar" : "Activar"}
                    </button>
                    <button
                        className='btn btn-primary generic-button-ficha'
                        onClick={() => {toggleModal()}}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </GenericModal>
    )

}

export default ActivarInactivarProcesoDeCierre