import React, { useContext, useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import GenericModal from '../../../components/modal/GenericModal';
import { downloadCartaCompromisoVacunacionAPI, getCargaCartaCompromisoVacunacionAPI, guardarCartaCompromisoVacunacionAPI } from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const FirmarCartaCompromisoVacunacionModal = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {

    const [detalleActividad, setDetalleActividad] = useState({});
    const [executeGetRequest] = useGetRequest();
    const { state } = useContext(AuthContext);
    const sectionName = "CartaCompromisoVacunacion";
    const CARTA_COMPROMISO_VACUNACION_INPUT_FILE = "CartaCompromisoArchivo";
    const [uploadFile, setUploadFile] = useState();
    const [submitForm] = useSubmitForm();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        if (IdEmpleadoFlujoActividad) {
            const response = await executeGetRequest(getCargaCartaCompromisoVacunacionAPI(IdEmpleadoFlujoActividad));
            if (responseCode200(response)) {
                setDetalleActividad(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const submitEnviarCartaCompromisoVacunacion = async (event) => {
        event.preventDefault();
        let payload = {
            IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
            CartaCompromisoArchivo: uploadFile
        }
        let api = guardarCartaCompromisoVacunacionAPI();
        let keys = ["CartaCompromisoArchivo"];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    }

    const successSubmitCallback = function () {
        manageSearch();
        showModal();
    }

    const downloadCartaCompromisoVacunacion = async (event) => {
        event.preventDefault();
        const response = await executeGetRequest(downloadCartaCompromisoVacunacionAPI(IdEmpleadoFlujoActividad));
            if (responseCode200(response)) {
                downloadFile(state.token, response.data.FilePath, response.data.OriginalFileName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"700px"} titulo={"Firma de compromiso de vacunación"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Colaborador:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreEmpleado}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreSede}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Actividad:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreActividad}</label>
                        </div>
                        <div className="form-group row mt-4 pt-4">
                            <div className="col-sm-4 black-color button">
                                <button className="btn btn-primary generic-button-ficha" onClick={(event) => downloadCartaCompromisoVacunacion(event)}>Descargar</button>
                            </div>
                            <label className="col-sm-8 col-form-label text-justify">
                                1. Descargar el archivo en formato pdf, imprimelo y firmalo.
                            </label>
                        </div>
                        <div className="form-group row mb-0">
                            <div className="col-sm-4 black-color">
                                <GenericUploadFileV2 uploadFileToS3={setUploadFile} id={CARTA_COMPROMISO_VACUNACION_INPUT_FILE} textButton={"Cargar archivo"} FormattedNameLength={15}>
                                    <div className="mt-3"></div>
                                </GenericUploadFileV2>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-CartaCompromisoArchivo-validation-message`}></p>
                            </div>
                            <label className="col-sm-8 col-form-label text-justify">
                                2. Tómale una foto o escanéalo y súbelo con esta opción.
                            </label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-10 col-form-label text-justify">
                                3. Dale click en enviar para completar la actividad.
                            </label>
                            <div className="col-sm-2 button">
                                <button className="btn btn-primary generic-button-ficha" onClick={(event) => submitEnviarCartaCompromisoVacunacion(event)}>Enviar</button>
                            </div>
                        </div>

                    </form>
                </div>
            </GenericModal>
        </>
    );

}

export default FirmarCartaCompromisoVacunacionModal;