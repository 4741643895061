import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { addDays, getCurrentDate, handleFindDropdownSelected, initialAllOptionPromises, jsonEstaVacio, jsonNoEstaVacio, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, toIsoForInput } from '../../../../../helper/utils';
import { estadoFormacion } from '../../../../../constants/states';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../../../../hooks/useGetRequest/useGetRequest';
import InputTextAutoSuggest from '../../../../../components/modalComponents/InputTextAutoSuggest';
import InputSelectCascade from '../../../../../components/modalComponents/InputSelectCascade';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import InputText from '../../../../../components/modalComponents/InputText';
import InputDate from '../../../../../components/modalComponents/InputDate';
import { CARGOS, KEYS_FORMACION_ACADEMICA_NI, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, TIPOS_TRABAJADOR } from '../../../../../constants/hardCodedConstants';
import { getEspecialidadesCarreraAPI, getEstadosFormacionAPI, getFormacionAcademicaGestionAPI, getNivelesEstudioAPI, getPaisesAPI, getRelatedDataTypesAPI, getSectoresInstitucionAPI, getSugerenciasDeNombreInstitucionAPI, getTiposInstitucionAPI, getTiposInstruccionAPI, saveFormacionAcademicaAPI } from '../../../../../consumers/backendApisUrls';

const DatosFormacionAcademica = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, isColaborador, inputDisabled, sendCurrentStep, setSendCurrentStep, commonStates, componentStep }) => {

  const SECTION_NAME = "EditarDatosFormacionAcademicaFichaNuevosIngresos";
  const IDS_TIPO_EMPLEADO_PRACTICANTE = [TIPOS_TRABAJADOR.PRACTICANTE_PRE, TIPOS_TRABAJADOR.PRACTICANTE_PRO];
  const IDS_CARGO_PRACTICANTE = [CARGOS.ASISTENTE_EDUCACION, CARGOS.PRACTICANTE, CARGOS.PRACTICANTE_COMERCIAL, CARGOS.PRACTICANTE_PSICOLOGIA, CARGOS.PRACTICANTE_ATENCIÓN_CLIENTE, CARGOS.PRACTICANTE_EXPERIENCIA_COLABORADOR, CARGOS.PRACTICANTE_SELECCIÓN, CARGOS.PRACTICANTE_COMUNICACIONES, CARGOS.PRACTICANTE_SELECCION, CARGOS.PRACTICANTE_CULTURA, CARGOS.PRACTICANTE_PROCESOS, CARGOS.PRACTICANTE_MARCA_Y_CONTENIDO];
  const KEYS_INIT = { TipoInstruccion: "IdTipoInstruccion", PaisInstitucion: "IdPaisInstitucion", TipoInsitucion: "IdTipoInstitucion", SectorInstitucion: "IdSectorInstitucion", Especialidad: "IdEspecialidad", NivelEstudio: "IdNivelEstudio", EstadoFormacion: "IdEstadoFormacion", GradoObtenido: "IdGradoObtenido" };

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [minDayInput, setMinDayInput] = useState({});
  const [allDropdownsRaw, setAllDropdownsRaw] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [isFinalizado, setIsFinalizado] = useState(true);
  const [isGraduado, setIsGraduado] = useState(false);
  const [isNoCulminado, setIsNoCulminado] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();
  const [executeG] = useGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getTiposInstruccionAPI()),
      getOptions(getPaisesAPI()),
      getOptions(getTiposInstitucionAPI()),
      getOptions(getSectoresInstitucionAPI()),
      getOptions(getEspecialidadesCarreraAPI()),
      getOptions(getNivelesEstudioAPI()),
    ]
  }

  const init = async () => {
    try {
      await manageSearch();
      await initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), successInitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getFormacionAcademicaGestionAPI(idNuevosIngresosEmpleado);
    await executeGet(api, (res) => setFormValues(res.data))
  }

  const successInitCallback = async (res) => {
    const { data } = await executeG(getEstadosFormacionAPI());
    const estadosFiltered = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(data.filter(o => o.CumpleCondicion));
    const IdEstadoFormacion = {key: KEYS_INIT.EstadoFormacion, value: estadosFiltered};
    setAllDropdowns([...res, IdEstadoFormacion]);
    setAllDropdownsRaw({ ...allDropdownsRaw, IdEstadoFormacion: data });
  }

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSending(true);
    try {
      const api = saveFormacionAcademicaAPI();
      const payload = getPayload();
      await submitForm(KEYS_FORMACION_ACADEMICA_NI, api, payload, SECTION_NAME, successSubmitCallback, !isColaborador, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const getPayload = () => {
    return {
      ...formValues,
      IsSetFinalizado: isFinalizado,
      IsGraduado: isGraduado,
      IsNoCulminado: isNoCulminado,
    }
  }

  const successSubmitCallback = () => {
    manageSearch();
    if(isColaborador) setSendCurrentStep(lastData => ({...lastData, isSent: false, isSaved: true}));
  }

  const calculateMinDaysInputDate = () => {
    const TIME_STAMP = "T00:00:00";
    if(formValues.FechaInicio) {
      const newFecha = formValues.FechaInicio.split("T").at(0);
      const minDays = toIsoForInput(addDays(new Date(newFecha+TIME_STAMP), 1));
      setMinDayInput(minDays);
    }
  }

  useDidMountEffect(() => {
    calculateMinDaysInputDate();
  }, [formValues.FechaInicio])

  useDidMountEffect(() => {
    if (!allDropdowns.length || jsonEstaVacio(formValues)) return;

    const estadoFormacionSelected = handleFindDropdownSelected(allDropdowns, formValues, KEYS_INIT.EstadoFormacion)?.label;
    let isFinalizado = estadoFormacionSelected !== estadoFormacion.EnCurso.nombre;
    let isGraduado = estadoFormacionSelected === estadoFormacion.Graduado.nombre;
    let isNoCulminado = estadoFormacionSelected === estadoFormacion.NoCulminado.nombre;
    
    setIsFinalizado(isFinalizado);
    setIsGraduado(isGraduado);
    setIsNoCulminado(isNoCulminado);

    if (isNoCulminado || !isFinalizado){
      setFormValues(prev => {
        const { SustentoFile, ...prevUpdated } = prev;
        return { ...prevUpdated }
      })
    }
  }, [formValues[KEYS_INIT.EstadoFormacion], allDropdowns])

  useDidMountEffect(() => {
    if (isColaborador && sendCurrentStep?.step === componentStep && sendCurrentStep?.isSent){
      handleSubmit();
    }
  }, [sendCurrentStep])

  useDidMountEffect(() => {
    if (!isLoading && IDS_TIPO_EMPLEADO_PRACTICANTE.includes(commonStates?.IdTipoTrabajador) && IDS_CARGO_PRACTICANTE.includes(commonStates?.IdCargo)){
      const updatedDropdowns = allDropdowns.map(o => {
        if (o.key === KEYS_INIT.EstadoFormacion){
          const value = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(allDropdownsRaw[KEYS_INIT.EstadoFormacion]?.filter(o => o.CumpleCondicion || o.Text === "En curso"));
          return {...o, value};
        }
        return o;
      });
      setAllDropdowns(updatedDropdowns);
    }
  }, [commonStates?.IdTipoTrabajador, commonStates?.IdCargo, isLoading, allDropdownsRaw])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <form onSubmit={handleSubmit} className={`${isColaborador ? "form-colaborador-inputs" : ""}`}>
      <CardAccordion id={OPTIONS.idFormacionAcademica} idParent={ID_PARENT} label="4. Formación Académica" isShow isShowGeneral={!isColaborador}>
        <div className="displayFlexWrap mt-4">
          <DivCol required><InputSelect label="Tipo de instrucción" {...commonPropsSelect("IdTipoInstruccion")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputTextAutoSuggest label="Nombre Institución" {...commonProps("NombreInstitucion")} api={getSugerenciasDeNombreInstitucionAPI} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="País Institución" {...commonPropsSelect("IdPaisInstitucion")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Tipo de institución" {...commonPropsSelect("IdTipoInstitucion")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Sector de la institución" {...commonPropsSelect("IdSectorInstitucion")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Nombre Carrera" {...commonProps("NombreCarrera")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol ><InputSelect label="Especialidad" {...commonPropsSelect("IdEspecialidad")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Nivel de Estudio" {...commonPropsSelect("IdNivelEstudio")} direction="column" isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Estado Formación" {...commonPropsSelect("IdEstadoFormacion", ["IdGradoObtenido", "FechaFin"])} direction="column" isDisabled={inputDisabled}/></DivCol>
          {isGraduado && <DivCol required><InputSelectCascade label="Grado Obtenido" dependendOption={formValues[KEYS_INIT.NivelEstudio]} api={getRelatedDataTypesAPI} {...commonProps("IdGradoObtenido")} direction="column" isDisabled={inputDisabled}/></DivCol>}
          <DivCol required><InputDate label="Fecha de Inicio" {...commonProps("FechaInicio")} direction="column" max={toIsoForInput(addDays(new Date(), -1))} isDisabled={inputDisabled}/></DivCol>
          {isFinalizado && 
            <>
              <DivCol required><InputDate label="Fecha de Egreso" {...commonProps("FechaFin")} direction="column" max={toIsoForInput(addDays(new Date(), -1))} isDisabled={inputDisabled}/></DivCol>
              {/* {!isNoCulminado &&
                <DivCol required><InputUploadFile label="Sustento" name="SustentoFile" uploadFile={formValues.SustentoFile} setUploadFile={(res) => setFormValues({...formValues, SustentoFile: res})} sectionName={SECTION_NAME} isUploading={isUploading} setIsUploading={setIsUploading} validExtensions={["pdf"]} direction='column' /></DivCol>
              } */}
            </>
          }
          <div className="col-sm-12 mb-2"><label className='required'>{mensajeFormacionProfesional}</label></div>
          <InputDeclaracionJurada {...commonProps("IsSetDeclaracionJurada")} declaracionJurada={declaracionJurada} isDisabled={inputDisabled}/>
        </div>
        {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending || isUploading}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosFormacionAcademica;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""}`}>
      {children}
    </div>
  )
}

const InputDeclaracionJurada = ({name, value, onChange, sectionName, isDisabled}) => (
  <div className='col-md-12'>
    <div className="form-check mb-0">
      <input
        type="checkbox"
        className="form-check-input"
        name={name}
        id={`admin-input-bool-select-${sectionName}-${name}`}
        checked={value ?? false}
        onChange={onChange}
        disabled={isDisabled}
      ></input>
      <label
        className="form-check-label ficha-declaracion-jurada-message"
        htmlFor={`admin-input-bool-select-${sectionName}-${name}`}
      >
        <strong>
          <p className="mb-0 text-justify">
            {declaracionJurada}
          </p>
        </strong>
      </label>
      <p
        className="ficha-form-input-validation-message"
        id={`ficha-edit-${sectionName}-${name}-validation-message`}
      ></p>
    </div>
  </div>
)

const declaracionJurada = "El presente registro tiene carácter de declaración jurada, certifico que la información brindada es completa y verdadera, caso contrario, la empresa podrá tomar las medidas legales que considere convenientes. Asimismo, autorizo a la empresa a realizar las verificaciones que considere necesarias y solicitarme la documentación que requiera."
const mensajeFormacionProfesional = "Nota: Considerar el último grado académico finalizado y no el que está en proceso."