import React, { useEffect, useState } from 'react'
import { NavDropdown } from 'react-bootstrap'
import TableChatbot from './TableChatbot'
import CrearPreguntaRespuesta from './modales/CrearPreguntaRespuesta'
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageButtons from '../../../components/pageComponents/PageButtons'
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { CHATBOT_FAQ_TYPES } from '../../../constants/hardCodedConstants'
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo'
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils'
import { descargarEstadisticaAdminChatbotFaqAPI, descargarListarAdminChatbotFaqAPI, getAllQuestionsAsDropdownChatbotFaqAPI, listarChatbotFaqAdminAPI } from '../../../consumers/backendApisUrls'

const GestionChatbot = () => {

  const INIT_KEYS = { idsParents: "IdsParents", idsTypes: "IdsTypes" };

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeGet(getAllQuestionsAsDropdownChatbotFaqAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), updateDropdowns);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const updateDropdowns = (array) => {
    const typeValues = Object.keys(CHATBOT_FAQ_TYPES).map(o => ({ Text: o, Value: CHATBOT_FAQ_TYPES[o]}));
    const updatedropdown = [...array, {key: INIT_KEYS.idsTypes, value: typeValues }];
    setAllFilters(updatedropdown);
  }

  const manageSearch = async () => {
    const api = listarChatbotFaqAdminAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleCreateButton = () => {
    setModalEnabled({ isEnable: true, component: CrearPreguntaRespuesta })
  }

  const handleDownloadExcel = async () => {
    const api = descargarListarAdminChatbotFaqAPI();
    let payload = getPayload();
    await downloadAndDelete(api, payload);
  }

  const handleDownloadStatistics = async () => {
    const api = descargarEstadisticaAdminChatbotFaqAPI();
    await downloadAndDelete(api, {});
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const addCleanSearch = () => {
    setFormValues(prev => ({ ...prev, selectedFilters: {}}))
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Listar chatbot" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={addCleanSearch}>
        <PageButtons hasCreateButton handleCreateButton={handleCreateButton}>
          <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Acciones</span>}>
            <NavDropdown.Item onClick={handleDownloadExcel}>Descargar Lista</NavDropdown.Item>
            <NavDropdown.Item onClick={handleDownloadStatistics}>Descargar Estadística</NavDropdown.Item>
          </NavDropdown>
        </PageButtons>
      </SearchContainer>
      <TableChatbot formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS}/>
    </PageWrapper>
  )
}

export default GestionChatbot