import React from 'react';
import GenericModal from '../../../components/modal/GenericModal';

const DetalleOcurrencia = ({ showModal, informacionOcurrencias }) => {

    const closeForm = () => {
        showModal();
    }

    const showDetalle = (detalleOcurrencia) => {
        if(detalleOcurrencia.length) {
            let texto = detalleOcurrencia.split(" -");
            return texto.map( (fila, i) => (
                <p key={i} className="m-0">{i!=0 && "-"}{fila}</p>
            ));
        }
        return detalleOcurrencia;
    }

    return (
        <>
            <GenericModal showModal={closeForm} width={"750px"} titulo={"Ocurrencia"}>
                <div className="modal-body">
                    {informacionOcurrencias.map((ocurrencia, i) => (
                        <div key={i} className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group">
                            <div className="col-sm-1 modal-add-nota-imagen-comtainer">
                                <img className="modal-add-nota-imagen" src={ocurrencia.ProfileImageUrl}></img>
                            </div>
                            <div className="col-sm-10">
                                <div>{ocurrencia.FechaOcurrencia}</div>
                                <div>{ocurrencia.NombreEmpleado}</div>
                                <div className="modal-add-nota-creada">{showDetalle(ocurrencia.DetalleOcurrencia)}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </GenericModal>
        </>
    );
};

export default DetalleOcurrencia;