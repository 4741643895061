import styled from 'styled-components';
import {string} from 'prop-types';

import CircleProgressBarBase from './CircleProgressBarBase';

const Progress = styled(CircleProgressBarBase)`
  max-width: 60%;
  vertical-align: middle;
  height: 100%;
  margin-right: 5px;
  svg {
    height: 100%;
    width: 100%;
  }
  .chart-text {
    fill: ${(props) => props.textColor};
    transform: translateY(0.25em);
  }
  .chart-number {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(0.1em);
  }
  .chart-label {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(0.7em);
  }
  .figure-key [class*='shape-'] {
    margin-right: 8px;
  }
  .figure-key-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .figure-key-list li {
    margin: 5px auto;
  }
  .shape-circle {
    display: inline-block;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${(props) => props.strokeColor};
    text-transform: capitalize;
  }
`;

Progress.propTypes = {
    textColor: string,
    strokeColor: string,
    maxSize: string,
};

Progress.defaultProps = {
    textColor: 'black',
    strokeColor: 'blueviolet',
};

export default Progress;
