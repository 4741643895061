import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {
  getInduccionVirtualActivityAPI,
  getOnboardingInfoActivityAPI,
  saveInduccionVirtualActivityAPI,
} from "../../../consumers/backendApisUrls";
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { downloadFile } from "../../../consumers/backendConsumer";

const InduccionVirtualModal = ({
  showModal,
  IdEmpleadoFlujoActividad,
  manageSearch,
}) => {
  const sectionName = "InduccionVirtual";
  const [value, setValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataInduccionVirtual, setDataInduccionVirtual] = useState([]);
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [handleChange] = useForm(value, setValue);
  const [executeGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();
  const { state } = useContext(AuthContext);

  const getInitialData = async () => {
    const apiInduccionVirtual = getInduccionVirtualActivityAPI(
      IdEmpleadoFlujoActividad
    );
    await executeGetRequest(apiInduccionVirtual, (res) =>
      setDataInduccionVirtual(res?.data)
    );

    const apiEmpleado = getOnboardingInfoActivityAPI(IdEmpleadoFlujoActividad);
    await executeGetRequest(apiEmpleado, (res) => setDataEmpleado(res?.data));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const keys = ["DeclaracionJurada"];
      const api = saveInduccionVirtualActivityAPI();
      let payload = getPayload();
      
      await submitForm(keys, api, payload, sectionName, saveSuccessCallback);
      
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const getPayload = () => {
    return {
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      DeclaracionJurada: value?.DeclaracionJurada
    };
  };

  const saveSuccessCallback = () => {
    showModal();
    manageSearch();
  };

  const handleDownloadFile = (e, data) => {
    e.preventDefault();
    downloadFile(state.token, data.FilePath, data.OriginalFileName);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) getInitialData();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <GenericModal
      showModal={showModal}
      width={"600px"}
      titulo="Inducción Virtual"
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">
              Colaborador
            </label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.NombreEmpleado}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">
              Fecha de Ingreso
            </label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.FechaIngreso}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">Sede</label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.NombreSede}
            </label>
          </div>
          <div className="form-group row mb-1">
            <label className="col-sm-4 col-form-label modal-label">
              Material
            </label>
          </div>
          <div className="mb-2 mt-1">
            <label className="disclaimer-formacion-profesional text-justify ficha-titulo-seccion-color">
              <strong>
              ¡Hola! Como parte importante de tu proceso de inducción, te invitamos a descargar y leer la información adjunta que será valiosa para tu adaptación en Innova. Más adelante, te contaremos más sobre estos temas.
              </strong>
            </label>
          </div>
          {dataInduccionVirtual?.map((data, i) => (
            <div className="form-group mb-2" key={i}>
              <div className="row d-flex justify-content-center">
                <label className="col-form-label col-sm-6">
                  {data.OriginalFileName}
                </label>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-primary generic-button-ficha"
                    onClick={(e) => handleDownloadFile(e, data)}
                  >
                    Descargar
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div className="mb-2 mt-4">
            <label className="disclaimer-formacion-profesional text-justify">
              <strong>
              Es importante que al terminar de leer esta información, marques la casilla de lectura y presiones el botón COMPLETAR para tener en cuenta que culminaste este primer paso.
              </strong>
            </label>
          </div>
          <div className="form-check mb-4">
            <input
              type="checkbox"
              className="form-check-input"
              name="DeclaracionJurada"
              id="AceptaDeclaracionJurada"
              checked={value.DeclaracionJurada ?? false}
              onChange={handleChange}
            ></input>
            <label
              className="form-check-label ficha-declaracion-jurada-message"
              htmlFor="AceptaDeclaracionJurada"
            >
              <strong>
                <p className={"mb-0"}>Certifico haber completado la inducción virtual</p>
              </strong>
            </label>
            <p
              className="ficha-form-input-validation-message"
              id={`ficha-edit-${sectionName}-DeclaracionJurada-validation-message`}
            ></p>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Completar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default InduccionVirtualModal;
