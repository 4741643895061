import React from "react";
import PaginatorV2 from "./PaginatorV2";

const PageTable = ({ headers = [], paginator, setPaginator, children }) => {
  return (
    <div className="container-fluid body-content">
      <div className="table-scroll pt-3">
        <table className="table table-hover">
          <thead className="listar-thead">
            <tr className="font-gothan-medium">
              {headers.length > 0 &&
                headers.map((header, index) => (
                  <th
                    className={`${header.col ?? ""} text-center`}
                    key={`${index}-${header.label}`}
                  >
                    {header.label}
                  </th>
                ))}
            </tr>
          </thead>
          {children}
        </table>
      </div>
      <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
    </div>
  );
};

export default PageTable;