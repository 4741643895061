import React from 'react';

const Paginator = ({totalItems, firsItemPage, lastItemPage, totalPages, page, onLeftClic, onRightClic}) => {
    return (
        <>
            <div className="d-flex justify-content-between w-99 pagination">
                <div>
                    <a>{firsItemPage}–{lastItemPage} de {totalItems}</a>
                </div>
                <div>
                    <a className='previous round page-button' onClick={onLeftClic}>
                        &#8249;
                    </a>
                    <a>
                        {page} de {totalPages}
                    </a>
                    <a className='next round page-button' onClick={onRightClic}>
                        &#8250;
                    </a>
                </div>
            </div>
        </>
    );
};

export default Paginator;
