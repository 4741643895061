import React, { useContext, useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import GenericModal from '../../../components/modal/GenericModal';
import { deleteFileFromS3API, downloadFormatoResultadosEvaluaciones, getCargarResutadosEvaluacion, saveCargarResutadosEvaluacion } from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { jsonEstaVacio, setStateWithCallBackResponse } from '../../../helper/utils';
import { AuthContext } from '../../../hooks/context/authContext';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';

const CargarResultadosEvaluacion = ({ showModal, refreshTable, toggleCargarResultadosEvaluacionSuccess }) => {
    const UPDATE_REGISTROS_EVALUACION_INPUT_FILE = "ficha-edit-registros_evaluacion-file";
    const EXTENSIONES_VALIDAS = ["xls", "xlsx"];
    const sectionName = "CargarResultadosEvaluacion";
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const { state } = useContext(AuthContext);
    const [uploadFileResultadosEvaluacion, setUploadFileResultadosEvaluacion] = useState();
    const [isDisableSubmitButton, setIsDisableSubmitButton] = useState(false);
    const [procesoCierre, setProcesoCierre] = useState({});
    const allInputKeys = ["File"];

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        await setStateWithCallBackResponse(executeGetRequestCallback(getCargarResutadosEvaluacion()), setProcesoCierre);
    };

    const downloadFormato = async () => {
        const response = await executePostRequest(downloadFormatoResultadosEvaluaciones());
        if (responseCode200(response)) {
            let responseFile = await downloadFile(state.token, response.data.FilePath, response.data.OriginalFileName);
            if (responseCode200(responseFile)) {
                console.debug("downloadFormato response code 200")
                await eliminarArchivoDeS3(response.data.FilePath);
            } else {
                console.debug("downloadFormato response code != 200")
                mostrarMensajeDeErrorConToast(responseFile);
            }
        } else {
            console.debug("generar archivo != 200");
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        console.debug("eliminarArchivoDeS3 before")
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        console.debug("eliminarArchivoDeS3 after")
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            console.debug("delete file from s3 != successfully");
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    const submit = async (event) => {
        setIsDisableSubmitButton(true);
        try {
            event.preventDefault();
            let payload = {
                "File": !jsonEstaVacio(uploadFileResultadosEvaluacion) ? uploadFileResultadosEvaluacion : null,
            };
            let response = await executePostRequest(saveCargarResutadosEvaluacion(), payload);
            if (responseCode200(response)) {
                refreshTable();
                showModal();
                toggleCargarResultadosEvaluacionSuccess(response?.data?.Message ?? "");
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            setIsDisableSubmitButton(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"750px"} titulo={"Cargar resultados de evaluación"}>
                <div className="modal-body" style={{ height: "100%" }}>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-justify">
                            Proceso
                        </label>
                        <div className="black-color">
                            <p className="pt-2">{procesoCierre?.NombreProcesoCierre}</p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-justify">
                            Cargar archivo
                        </label>
                        <div className="black-color">
                            <GenericUploadFileV2 uploadFileToS3={setUploadFileResultadosEvaluacion} id={UPDATE_REGISTROS_EVALUACION_INPUT_FILE} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS}/>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-File-validation-message`}></p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={downloadFormato}>Descargar formato</button>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submit} disabled={isDisableSubmitButton}>{isDisableSubmitButton ? "Cargando" : "Enviar"} {isDisableSubmitButton && <>&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>} </button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

export default CargarResultadosEvaluacion;