import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import {usePostRequest} from "../usePostRequest/usePostRequest";

export const useManagePostRequest = () => {
    const [executePostRequest] = usePostRequest();

    const managePostRequest = async (api, payload, callbackSuccess, showToastSuccess = true, callbackFailure = null, showToastError = true) => {
        const response = await executePostRequest(api, payload);
        if (responseCode200(response)) {
            if (showToastSuccess) {
                toast.success(response?.data?.Message);
            }
            callbackSuccess(response);
        } else {
            if(showToastError) mostrarMensajeDeErrorConToast(response);
            if(callbackFailure) callbackFailure(response);
        }
        return response;
    };

    return [managePostRequest]
};