import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import FormSelect from '../../components/atoms/Select';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
    bloquearColaboradorAPI,
    bulkEliminarColaboradoresAPI, desBloquearColaboradorAPI,
    descargarColaboradoresAPI,
    eliminarProcesoCierrColaborador,
    getAllCargosAPI, getAllDepartamentosAPI,
    getAllSedesAPI,
    getAllTiposContratoAPI,
    getCondicionesProcesoCierreAPI,
    getEstadosProcesoCierreAPI,
    getEstadosRegistroCierreAPI,
    listarProcesoCierreAPI, puedeMandarRecordatorioAPI,
    getAllProcesosDeCierreListAPI
} from '../../consumers/backendApisUrls';
import { onKeyPressEnter, jsonEstaVacio, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles, notNullAndNotUndefinded, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../helper/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import Paginator from '../directorio/Paginator';
import TableWebBody from './TableWebBody';
import AgregarEditarColaborador from './modales/AgregarEditarColaborador';
import MonitorDeProcesos from "./modales/MonitorDeProcesos";
import DetalleColaborador from "./modales/DetalleColaborador";
import CargarResultadosEvaluacion from './modales/CargarResultadosEvaluacion';
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import ObservarRegistroModal from "./modales/ObservarRegistroModal";
import RegistrarNotaCierreDeAnioModal from "./modales/RegistrarNotaCierreDeAnioModal";
import { ROLES } from "../../constants/hardCodedConstants";
import { AuthContext } from "../../hooks/context/authContext";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import CargarColaboradores from './modales/CargarColaboradores';
import ActualizacionMasiva from './modales/ActualizacionMasiva';
import CargarResultadosEvaluacionSuccess from './modales/CargarResultadosEvaluacionSuccess';
import { NavDropdown } from 'react-bootstrap';
import CargarEvaluacionApi360 from './modales/CargarEvaluacionApi360';
import CargaMensualColaboradores from './modales/CargaMensualColaboradores'
import SelectInput from '../../components/Select/SelectInput';

const GestionCierreDeAnio = () => {
    const { state } = useContext(AuthContext);
    const [executeGetRequest] = useGetRequest();
    const [managePostRequest] = useManagePostRequest();

    const [dropdownsOptions, setDropdownOptions] = useState([]);
    const [dataFilteredOptions, setDataFilteredOptions] = useState([]);
    const [dataSelected, setDataSelected] = useState({})

    const [idProcesoCierre, setIdProcesoCierre] = useState();
    const [idProcesoCierreEmpleado, setIdProcesoCierreEmpleado] = useState(0);
    const [filas, setFilas] = useState([]);
    const [extraData, setExtraData] = useState([]);
    const [searchCriteria, setSearchCriteria] = useState("");
    const [initialResponse, setInitialResponse] = useState({});
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [hideDetalleModal, setHideDetalleModal] = useState(true);
    const [hideMonitorProcesoModal, setHideMonitorProcesoModal] = useState(true);
    const [hideDetalleColaboradorModal, setHideDetalleColaboradorModal] = useState(true);
    const [hideCargaResultadosEvaluacion, setHideCargaResultadosEvaluacion] = useState(true);
    const [hideCargarResultadosEvaluacionSuccess, setHideCargarResultadosEvaluacionSuccess] = useState(true);
    const [hideCargarResultadosEvaluacionSuccessMessage, setHideCargarResultadosEvaluacionSuccessMessage] = useState("");
    const [hideObservarRegistroModal, setHideObservarRegistroModal] = useState(true);
    const [hideRegistrarNotasModal, setHideRegistrarNotasModal] = useState(true);
    const [hideCargaColaboradores, setHideCargaColaboradores] = useState(true);
    const [hideActualizacionMasiva, setHideActualizacionMasiva] = useState(true);
    const [hideCargarEvaluacionApi360, setHideCargarEvaluacionApi360] = useState(true);
    const [hideCargaMensual, setHideCargaMensual] = useState(true);
    const [hideCargaMensualSuccess, setHideCargaMensualSuccess] = useState(true);

    const [idProcesoCierreEmpleadoParaAgregarEditarColaboradorModal, setIdProcesoCierreEmpleadoParaAgregarEditarColaboradorModal] = useState();
    const [idsFichasColaboradoresConCheck, setIdsFichasColaboradoresConCheck] = useState([]);
    const [idEstadoProceso, setIdEstadoProceso] = useState(null);

    const [loading, setLoading] = useState(true);
    const [multipleSelect, setMultipleSelect] = useState(false);

    const [usuarioLogueadoTieneRolCompensaciones, setUsuarioLogueadoTieneRolCompensaciones] = useState(false);

    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

    const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

    const ID_TYPES = {IdsTiposContrato: "IdsTiposContrato", IdsCargos: "IdsCargos", IdsSedes: "IdsSedes", IdCondiciones: "IdCondiciones", IdsEstadosProceso: "IdsEstadosProceso", IdsEstadosRegistro: "IdsEstadosRegistro", IdsDepartamentos: "IdsDepartamentos", ProcesosDeCierreList: "ProcesosDeCierreList"}

    const getAllDataPromises = () => { 
        return [
            executeGetRequest(getAllTiposContratoAPI()),
            executeGetRequest(getAllCargosAPI()),
            executeGetRequest(getAllSedesAPI()),
            executeGetRequest(getCondicionesProcesoCierreAPI()),
            executeGetRequest(getEstadosProcesoCierreAPI()),
            executeGetRequest(getEstadosRegistroCierreAPI()),
            executeGetRequest(getAllDepartamentosAPI()),
            executeGetRequest(getAllProcesosDeCierreListAPI()),
    ]};

    const getInitialData = async () => {
        try {
            setUsuarioLogueadoTieneRolCompensaciones((usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.Compensaciones.id])));
            await Promise
                    .allSettled(getAllDataPromises())
                    .then(res => initFilters(res));
        } catch (e) {
            console.error(e);
        }
    };

    const initFilters = (allGetData) => {
        loadLastProcesoCierre(allGetData);
        allGetData.map((procesoCierre, index) => {
            if(procesoCierre.status === "fulfilled") {
                let key = Object.keys(ID_TYPES)[index];
                let value = procesoCierre.value?.data;
                setDropdownOptions(lastData => [...lastData, {key, value: (key == ID_TYPES.ProcesosDeCierreList ? mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(value) : value)}]);
            }
        });
    }

    const loadLastProcesoCierre = (allGetData) => {
        const lastProcesoCierre = allGetData?.at(-1).value?.data?.at(-1);
        const setLastToSearch = {lastProcesoCierre: lastProcesoCierre, IdProcesoCierre: lastProcesoCierre.Value, ProcesosDeCierreList: {value: lastProcesoCierre.Value, label: lastProcesoCierre.Text}}
        setDataSelected({...dataSelected, ...setLastToSearch});
    }

    const manageSearch = async () => {
        const api = listarProcesoCierreAPI();
        let payload = getPayload();
        await managePostRequest(api, payload, successManageSearchCallback);
        setLoading(false);
    }

    const getPayload = () => {
        return {
            ...dataSelected,
            "Criteria": searchCriteria,
            "Page": page,
            "EsFechaIngresoDecendente": fechaMasRecientePrimeroBool};
    };

    const successManageSearchCallback = ({ data }) => {
        setFilas(data?.Items);
        setTotalItems(data?.TotalItems);
        setFirsItemPage(data?.FirsItemPage);
        setLastItemPage(data?.LastItemPage);
        setTotalPages(data?.TotalPages);
        jsonEstaVacio(initialResponse) && setInitialResponse(data);
        setIdProcesoCierre(data?.ExtraData.IdProceso);
        setExtraData(data?.ExtraData);
    };

    const updateFiltersWithExtraData = function () {
        let newData = dropdownsOptions.map(o => {
            if(extraData[o.key]){
                let value = o.value.filter(x => extraData[o.key]?.includes(x.Value));
                return {key: o.key, value: value}
            }
            return {key: o.key, value: o.value};
        });
        setDataFilteredOptions(newData);
    };

    const toggleAgregarEditarColaboradorModal = (IdProcesoCierreEmpleado) => {
        setHideDetalleModal(!hideDetalleModal);
        setIdProcesoCierreEmpleadoParaAgregarEditarColaboradorModal(IdProcesoCierreEmpleado);
    };

    const toggleMonitorProcesoModal = () => {
        setHideMonitorProcesoModal(!hideMonitorProcesoModal);
    };

    const toggleCargaAPI360 = () => {
        setHideCargarEvaluacionApi360(!hideCargarEvaluacionApi360);
    }

    const toggleCargarResultadosEvaluacion = () => {
        setHideCargaResultadosEvaluacion(!hideCargaResultadosEvaluacion);
    };

    const toggleCargarResultadosEvaluacionSuccess = (responseMessage) => {
        setHideCargarResultadosEvaluacionSuccess(!hideCargarResultadosEvaluacionSuccess);
        setHideCargarResultadosEvaluacionSuccessMessage(responseMessage);
    };

    const toggleCargaMensualColaboradoresSuccess = (responseMessage) => {
        setHideCargaMensualSuccess(!hideCargaMensualSuccess);
        setHideCargarResultadosEvaluacionSuccessMessage(responseMessage);
    };

    const toggleCargaMensual = () => {
        setHideCargaMensual(!hideCargaMensual);
    }

    const toggleCargarColaboradores = () => {
        setHideCargaColaboradores(!hideCargaColaboradores);
    };

    const toggleActualizacionMasiva = () => {
        setHideActualizacionMasiva(!hideActualizacionMasiva);
    };

    const toggleObservarRegistroModal = (IdProcesoCierreEmpleado) => {
        setHideObservarRegistroModal(!hideObservarRegistroModal);
        IdProcesoCierreEmpleado && setIdProcesoCierreEmpleado(IdProcesoCierreEmpleado)
    };

    const toggleRegistrarNotasModal = (IdProcesoCierreEmpleado) => {
        setHideRegistrarNotasModal(!hideRegistrarNotasModal);
        IdProcesoCierreEmpleado && setIdProcesoCierreEmpleado(IdProcesoCierreEmpleado)
    };

    const toggleDetalleColaboradorModal = async (idProcesoCierreEmpleadoSeleccionado, idEstadoProceso) => {
        if (idProcesoCierreEmpleadoSeleccionado) {
            setIdProcesoCierreEmpleado(idProcesoCierreEmpleadoSeleccionado)
            setIdEstadoProceso(idEstadoProceso)
        }

        setHideDetalleColaboradorModal(!hideDetalleColaboradorModal);
    };

    const manageSearchProxy = (response) => {
        manageSearch();
    }

    const bloquearColaborador = async function (IdProcesoCierreEmpleado) {
        let payload = {
            "IdProcesoCierreEmpleado": IdProcesoCierreEmpleado
        };

        await managePostRequest(bloquearColaboradorAPI(), payload, manageSearchProxy)
    }

    const desBloquearColaborador = async function (IdProcesoCierreEmpleado) {
        let payload = {
            "IdProcesoCierreEmpleado": IdProcesoCierreEmpleado
        };

        await managePostRequest(desBloquearColaboradorAPI(), payload, manageSearchProxy)
    }

    const eliminarColaborador = async function (IdProcesoCierreEmpleado) {
        let payload = {
            "IdProcesoCierreEmpleado": IdProcesoCierreEmpleado
        }

        await managePostRequest(eliminarProcesoCierrColaborador(), payload, manageSearchProxy)
    }

    const multiSelectActionSuccesCallBack = (response) => {
        setIdsFichasColaboradoresConCheck([]);
        setMultipleSelect(false);
        manageSearch();
    }

    const eliminarColaboradores = async function () {
        if (hayFichasSelecionadas()) {
            let payload = {
                "IdsProcesoCierreEmpleado": idsFichasColaboradoresConCheck
            };

            await managePostRequest(bulkEliminarColaboradoresAPI(), payload, multiSelectActionSuccesCallBack)
        }
    }

    const enviarRecordatorio = async function () {
        if (hayFichasSelecionadas()) {
            let payload = {
                "IdsProcesoCierreEmpleado": idsFichasColaboradoresConCheck
            };

            await managePostRequest(puedeMandarRecordatorioAPI(), payload, multiSelectActionSuccesCallBack)
        }
    }

    const hayFichasSelecionadas = () => {
        let hayFichasSelecionadas = idsFichasColaboradoresConCheck.length > 0;
        if (!hayFichasSelecionadas) {
            toast.warn("Seleccionar al menos un colaborador");
        }
        return hayFichasSelecionadas;
    };

    const selectUnselectAllCheckboxes = function (e) {
        let inputType = e.target.type;
        let inputValue = (inputType === "checkbox") ? e.target.checked : e.target.value;
        setMultipleSelect(inputValue);
    }

    const downloadCierreAnio = async () => {
        var payload = getPayload();
        await generarDescargarEliminarArchivo(descargarColaboradoresAPI(), payload);
    }

    const onChangeFechaMasRecientePrimeroBool = (e) => {
        let inputValue = e.target.checked;
        setFechaMasRecientePrimeroBool(inputValue);
    };

    const handleProcesosCierre = (procesoCierre) => {
        setDataSelected({...dataSelected, IdProcesoCierre: procesoCierre.value, ProcesosDeCierreList: procesoCierre})
    }

    const handleSelectedOptions = (key, value) => {
        setDataSelected({...dataSelected, [key]: value})
    }

    const selecconarTodosLosCheckboxes = async function () {
        let filasIds = filas.map((item) => item.IdProcesoCierreEmpleado)
        setIdsFichasColaboradoresConCheck(filasIds);

        await manageSearch()
    }

    const deSelecconarTodosLosCheckboxes = async function () {
        setIdsFichasColaboradoresConCheck([]);
        await manageSearch()
    }

    const limpiarBusqueda = () => {
        setSearchCriteria("");
        const initData = {lastProcesoCierre: dataSelected.lastProcesoCierre, IdProcesoCierre: dataSelected.lastProcesoCierre.Value, ProcesosDeCierreList: {value: dataSelected.lastProcesoCierre.Value, label: dataSelected.lastProcesoCierre.Text}}; 
        setDataSelected(initData);
        setFechaMasRecientePrimeroBool(true);
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    useDidMountEffect(() => {
        setPage(1);
        manageSearch();
    }, [dataSelected]);

    useDidMountEffect(() => {
        manageSearch();
    }, [page, fechaMasRecientePrimeroBool]);

    useDidMountEffect(() => {
        multipleSelect ? selecconarTodosLosCheckboxes() : deSelecconarTodosLosCheckboxes();
    }, [multipleSelect]);

    useDidMountEffect(() => {
        notNullAndNotUndefinded(extraData) && updateFiltersWithExtraData();
    }, [extraData])

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );

    return (<>
        <div className="message-no-disponible-en-mobile">
            <p>Esta página no está disponible en mobile.</p>
        </div>
        <div className="no-disponible-en-mobile">
            {hideDetalleModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <AgregarEditarColaborador showModal={toggleAgregarEditarColaboradorModal} idProcesoCierreEmpleado={idProcesoCierreEmpleadoParaAgregarEditarColaboradorModal} refreshTable={manageSearch} />
                </div>
            }

            {hideMonitorProcesoModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <MonitorDeProcesos toggleModal={toggleMonitorProcesoModal} />
                </div>
            }

            {hideDetalleColaboradorModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <DetalleColaborador toggleModal={toggleDetalleColaboradorModal} idProcesoCierre={idProcesoCierre} idProcesoCierreEmpleado={idProcesoCierreEmpleado} refreshTable={manageSearch} toggleObservarRegistroModal={toggleObservarRegistroModal} idEstadoProceso={idEstadoProceso}/>
                </div>
            }

            {hideCargaResultadosEvaluacion === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargarResultadosEvaluacion showModal={toggleCargarResultadosEvaluacion} refreshTable={manageSearch} toggleCargarResultadosEvaluacionSuccess={toggleCargarResultadosEvaluacionSuccess} />
                </div>
            }

            {hideCargarResultadosEvaluacionSuccess === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargarResultadosEvaluacionSuccess toggleModal={toggleCargarResultadosEvaluacionSuccess} responseMessage={hideCargarResultadosEvaluacionSuccessMessage} />
                </div>
            }

            {hideObservarRegistroModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ObservarRegistroModal toggleModal={toggleObservarRegistroModal} idProcesoCierreEmpleado={idProcesoCierreEmpleado} refreshTable={manageSearch} />
                </div>
            }

            {hideRegistrarNotasModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistrarNotaCierreDeAnioModal toggleModal={toggleRegistrarNotasModal} idProcesoCierreEmpleado={idProcesoCierreEmpleado} refreshTable={manageSearch} />
                </div>
            }

            {hideCargaColaboradores === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargarColaboradores showModal={toggleCargarColaboradores} refreshTable={manageSearch} />
                </div>
            }

            {hideActualizacionMasiva === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ActualizacionMasiva showModal={toggleActualizacionMasiva} refreshTable={manageSearch} />
                </div>
            }

            {!hideCargarEvaluacionApi360 &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargarEvaluacionApi360 showModal={toggleCargaAPI360} refreshTable={manageSearch} idProcesoCierre={idProcesoCierre} />
                </div>
            }

            {!hideCargaMensual &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargaMensualColaboradores
                        showModal={toggleCargaMensual}
                        refreshTable={manageSearch}
                        toggleCargaMensualColaboradoresSuccess={toggleCargaMensualColaboradoresSuccess} />
                </div>
            }

            {!hideCargaMensualSuccess &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CargarResultadosEvaluacionSuccess
                        toggleModal={toggleCargaMensualColaboradoresSuccess}
                        responseMessage={hideCargarResultadosEvaluacionSuccessMessage} />
                </div>
            }

            <Wrapper className='App'>
                <PageHeader title={"Gestión de Contratos - Lista de colaboradores"} />
                <>
                    <div className="ml-2 mt-2">
                        <div className="d-flex justify-content-start">
                            <div className="col-sm-4 black-color ml-1 mt-1">
                                <SelectInput
                                    options={dataFilteredOptions?.find(data => data.key == ID_TYPES.ProcesosDeCierreList)?.value}
                                    jsonSelectedValue={dataSelected[ID_TYPES.ProcesosDeCierreList] ?? []}
                                    setJsonSelectedValue={(res) => handleProcesosCierre(res)}
                                    isDisabled={false}
                                    placeholder={"Seleccionar un proceso de cierre"}
                                    resetSelectedValue={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por nombre'
                                value={searchCriteria}
                                onChange={(e) => setSearchCriteria(e.target.value)}
                                onKeyUp={(e) => onKeyPressEnter(e, manageSearch)}
                            />
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                            {initialResponse?.ExtraData?.PuedeAgregarColaborador === true &&
                                <button
                                    onClick={(e) => toggleAgregarEditarColaboradorModal(null)}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Agregar colaborador
                                </button>
                            }

                            {initialResponse?.ExtraData?.PuedeMonitorProceso === true &&
                                <button
                                    onClick={toggleMonitorProcesoModal}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Monitor proceso
                                </button>
                            }

                            {initialResponse?.ExtraData?.PuedeVerArchivoSoporte && initialResponse?.ExtraData?.UrlArchivoSoporte &&
                                <button
                                    onClick={() => {
                                        window.open(initialResponse?.ExtraData?.UrlArchivoSoporte, '_blank');
                                    }}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Ir al site
                                </button>
                            }

                            {initialResponse?.ExtraData?.PuedeCargarResultadosEvaluacion === true &&
                                <NavDropdown
                                    className='generic-button-dropdown btn-opcion-proceso-cierre d-inline-block'
                                    title={<span className='generic-button-dropdown-title'>Cargar Colaboradores</span>}>
                                    <NavDropdown.Item onClick={toggleCargarResultadosEvaluacion}>Cargar resultados eval.</NavDropdown.Item>
                                    <NavDropdown.Item onClick={toggleCargaMensual}>Carga mensual de colaboradores</NavDropdown.Item>
                                    <NavDropdown.Item onClick={toggleCargaAPI360}>Carga API 360</NavDropdown.Item>
                                </NavDropdown>
                            }

                            {initialResponse.ExtraData?.PuedeDescargar &&
                                <button
                                    onClick={downloadCierreAnio}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Descargar
                                </button>
                            }

                            {initialResponse?.ExtraData?.PuedeCargarColaboradores === true &&
                                <button
                                    onClick={toggleCargarColaboradores}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Cargar colaboradores
                                </button>
                            }
                            {initialResponse?.ExtraData?.PuedeRealizarActualizacionMasiva === true &&
                                <button
                                    onClick={toggleActualizacionMasiva}
                                    type='button'
                                    className='generic-button-buscador btn-opcion-proceso-cierre'>
                                    Actualización masiva
                                </button>
                            }
                        </div>
                    </div>
                    <div className="generic-buscador-container">
                        <div className="row">
                            {initialResponse?.ExtraData?.PuedeEliminarRegistros === true && initialResponse?.ExtraData?.PuedeMandarRecordatorio === true &&
                                <div className="col">
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                                            Acciones
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {initialResponse?.ExtraData?.PuedeEliminarRegistros === true &&
                                                <Dropdown.Item onClick={eliminarColaboradores}>Eliminar</Dropdown.Item>
                                            }
                                            {initialResponse?.ExtraData?.PuedeMandarRecordatorio === true &&
                                                <Dropdown.Item onClick={enviarRecordatorio}>Enviar recordatorio</Dropdown.Item>
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='pt-1'>
                        <div  className="table-scroll">
                            <table className='table table-hover listar-table-cierre-anio'>
                                <thead className="listar-thead">
                                    <tr>
                                        <th className="col-1 pl-1 pr-1">
                                            {initialResponse?.ExtraData?.PuedeAcciones === true &&
                                                <input type="checkbox" checked={multipleSelect} onChange={(e) => selectUnselectAllCheckboxes(e)}></input>
                                            }
                                            <span className='font-gothan-medium d-inline'>Nombre completo</span>
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsCargos)?.value}
                                                description={'Cargo'}
                                                setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsCargos, res)}
                                                selectedOptions={dataSelected[ID_TYPES.IdsCargos] ?? []}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsDepartamentos)?.value}
                                                description={'Región'}
                                                setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsDepartamentos, res)}
                                                selectedOptions={dataSelected[ID_TYPES.IdsDepartamentos] ?? []}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsSedes)?.value}
                                                description={'Sede'}
                                                setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsSedes, res)}
                                                selectedOptions={dataSelected[ID_TYPES.IdsSedes] ?? []}
                                            />
                                        </th>

                                        {usuarioLogueadoTieneRolCompensaciones &&
                                            <th className="col-1 pl-1 pr-1">
                                                <FormSelect
                                                    options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsTiposContrato)?.value}
                                                    description={'Contrato Actual'}
                                                    setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsTiposContrato, res)}
                                                    selectedOptions={dataSelected[ID_TYPES.IdsTiposContrato] ?? []}
                                                />
                                            </th>
                                        }

                                        {usuarioLogueadoTieneRolCompensaciones &&
                                            <th className="col-1 pl-1 pr-1">
                                                <div className='flex font-gothan-medium'>Fin contrato</div>
                                            </th>
                                        }

                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdCondiciones)?.value}
                                                description={'Condición'}
                                                setSelectedOptions={(res) => handleSelectedOptions("IdsCondiciones", res)}
                                                selectedOptions={dataSelected["IdsCondiciones"] ?? []}
                                            />
                                        </th>
                                        <th className="col-1 pl-1 pr-1">
                                            <FormSelect
                                                options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsEstadosProceso)?.value}
                                                description={'Estado Proceso'}
                                                setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsEstadosProceso, res)}
                                                selectedOptions={dataSelected[ID_TYPES.IdsEstadosProceso] ?? []}
                                            />
                                        </th>

                                        {usuarioLogueadoTieneRolCompensaciones &&
                                            <th className="col-1 pl-1 pr-1">
                                                <FormSelect
                                                    options={dataFilteredOptions.find(data => data.key == ID_TYPES.IdsEstadosRegistro)?.value}
                                                    description={'Estado Registro'}
                                                    setSelectedOptions={(res) => handleSelectedOptions(ID_TYPES.IdsEstadosRegistro, res)}
                                                    selectedOptions={dataSelected[ID_TYPES.IdsEstadosRegistro] ?? []}
                                                />
                                            </th>
                                        }

                                        {usuarioLogueadoTieneRolCompensaciones &&
                                            <th className="col-1 pl-1 pr-1">
                                                <div className=' font-gothan-medium'>Fecha de Ingreso</div>
                                                <div className="custom-control custom-switch mt-1">
                                                    <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                                                    <label className="custom-control-label hand-pointer blue-standard-color listar-table-mas-reciente-checkbox" htmlFor="admin-solicitud-cambios-fecha-order">
                                                        Más reciente
                                                    </label>
                                                </div>
                                            </th>
                                        }

                                        {usuarioLogueadoTieneRolCompensaciones &&
                                            <th className="col-1 pl-1 pr-1">
                                                <div className='flex font-gothan-medium'>Nuevo Contrato</div>
                                            </th>
                                        }


                                        <th className="col-1 pl-1 pr-1">
                                            <div className='flex font-gothan-medium'>Acciones</div>
                                        </th>
                                    </tr>
                                </thead>
                                <TableWebBody filas={filas} toggleDetalleModal={toggleAgregarEditarColaboradorModal} eliminar={eliminarColaborador} idsFichasColaboradoresConCheck={idsFichasColaboradoresConCheck}
                                    setIdsFichasColaboradoresConCheck={setIdsFichasColaboradoresConCheck}
                                    toggleDetalleColaboradorModal={toggleDetalleColaboradorModal} bloquearColaborador={bloquearColaborador} desBloquearColaborador={desBloquearColaborador} initialResponse={initialResponse} manageSearchProxy={manageSearchProxy}
                                    toggleObservarRegistroModal={toggleObservarRegistroModal} toggleRegistrarNotasModal={toggleRegistrarNotasModal} usuarioLogueadoTieneRolCompensaciones={usuarioLogueadoTieneRolCompensaciones} />
                            </table>
                    </div>
                            {totalPages > 0 && (
                                <Paginator totalItems={totalItems}
                                    firsItemPage={firsItemPage}
                                    lastItemPage={lastItemPage}
                                    totalPages={totalPages}
                                    page={page}
                                    onLeftClic={onLeftClic}
                                    onRightClic={onRightClic} />
                            )}
    
                    </div>
                </>
            </Wrapper>
        </div>
    </>)

}

export default GestionCierreDeAnio;
