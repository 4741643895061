import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import FormSelect from '../../components/atoms/Select';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
  listarPliticasAdministrador,
  getAllEstadosAPI,
  descargarReporteCompromisoAPI,
  getAllPoliticaCategoriasAPI
} from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { onKeyPressEnter, setStateWithCallBackResponse } from '../../helper/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import TableWebBody from './TableWebBody';
import Paginator from '../directorio/Paginator';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../hooks/context/authContext';
import { updateOptionsDeFiltro } from '../../helper/Listas/ListasUtils';
import CrearNuevaPolitica from './modales/CrearNuevaPolitica';
import EditarPolitica from './modales/EditarPolitica';
import GenericTooltip from '../../components/GenericTooltip/GenericTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { useDescargarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import ConfirmarEliminar from './modales/ConfirmarEliminar';
import MensajeAlerta from './modales/MensajeAlerta';

const GestionPoliticasYReglamentos = () => {
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [managePost] = useManagePostRequest();
  const [descargarArchivo] = useDescargarArchivoDeS3();

  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [filas, setFilas] = useState([]);
  const [extraData, setExtraData] = useState({});

  const [allCategoriasOptions, setAllCategoriasOptions] = useState([]);

  const [categoriasParentOptionsFiltrados, setCategoriasParentOptionsFiltrados] = useState([]);
  const [categoriasChildOptionsFiltrados, setCategoriasChildOptionsFiltrados] = useState([]);

  const [categoriasParentSeleccionadas, setCategoriasParentSeleccionadas] = useState([]);
  const [categoriasChildSeleccionadas, setCategoriasChildSeleccionadas] = useState([]);

  const [allEstadosOptions, setAllEstadosOptions] = useState([]);
  const [estadoOptions, setEstadoOptions] = useState([]);
  const [estadoSeleccionados, setEstadoSeleccionados] = useState([]);

  const [politicaId, setPoliticaId] = useState(null);
  const [politicaTitle, setPoliticaTitle] = useState(null);

  const [showModalReserva, setShowModalReserva] = useState(false);
  const [idReserva, setIdReserva] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const [showModalEditar, setShowModalEditar] = useState(false);
  const [showModalConfirmarEliminar, setShowModalConfirmarEliminar] = useState(false);
  const [showModalMensajeAlerta, setShowModalMensajeAlerta] = useState(false);

  const [informacionOcurrencias, setInformacionOcurrencia] = useState([]);

  const [pasoMensajeAlerta, setPasoMensajeAlerta] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    setPage(1);
  }, [estadoSeleccionados, categoriasParentSeleccionadas, categoriasChildSeleccionadas]);

  useDidMountEffect(() => {
    manageSearch();
  }, [estadoSeleccionados, categoriasParentSeleccionadas,categoriasChildSeleccionadas, page]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      await setStateWithCallBackResponse(executeGetRequestCallback(getAllPoliticaCategoriasAPI()), setAllCategoriasOptions);
      await setStateWithCallBackResponse(executeGetRequestCallback(getAllEstadosAPI()), setAllEstadosOptions);
      await manageSearch();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const manageSearch = async () => {
    var payload = obtenerPayload();
    const response = await executePostRequest(listarPliticasAdministrador(), payload);
    if (responseCode200(response)) {

      setFilas(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setFirsItemPage(response.data.FirsItemPage);
      setLastItemPage(response.data.LastItemPage);
      setTotalPages(response.data.TotalPages);
      setExtraData(response.data.ExtraData);

      await updateOptionsDeFiltros(response.data.ExtraData);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const updateOptionsDeFiltros = async function (lists) {
    await updateOptionsDeFiltro(allEstadosOptions, lists["IdsEstados"], setEstadoOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allCategoriasOptions, lists["IdsCategorias"], setCategoriasParentOptionsFiltrados, executeGetRequestCallback(getAllPoliticaCategoriasAPI()));
    await updateOptionsDeFiltro(allCategoriasOptions, lists["IdsSubCategorias"], setCategoriasChildOptionsFiltrados, executeGetRequestCallback(getAllPoliticaCategoriasAPI()));
    setLoading(false);
  };

  const obtenerPayload = () => {
    let payload = {
      "Criteria": criterioDeBusqueda,
      "IdsCategoriasParent": categoriasParentSeleccionadas,
      "IdsCategoriasChild": categoriasChildSeleccionadas,
      "IdsEstados": estadoSeleccionados,
      "Page": page
    };
    return payload;
  }

  const limpiarBusqueda = () => {
    setCriterioDeBusqueda("");
    setCategoriasParentSeleccionadas([]);
    setCategoriasChildSeleccionadas([]);
    setEstadoSeleccionados([]);
  };

  const toggleCrearNuevoModal = () => {
    setShowModalReserva(!showModalReserva);
  };

  const toggleEditarModal = () => {
    setShowModalEditar(!showModalEditar);
  };

  const toggleConfirmarEliminarModal = () => {
    setShowModalConfirmarEliminar(!showModalConfirmarEliminar);
  };

  const toggleMensajeAlertaModal = () => {
    setPasoMensajeAlerta(false);
    setShowModalMensajeAlerta(prev => (!prev));
  }

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const descargarReporteCompromiso = async (id) => {
    const api = descargarReporteCompromisoAPI(id);
    const payload = {};
    await managePost(api, payload, async (response) => {
      await descargarArchivo(response.data.OriginalFileName, response.data.FilePath);
    }, true);
  }

  if (loading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {showModalReserva &&
          <div className="modal-contactanos modal-response-ficha-background">
            <CrearNuevaPolitica
              toggleMensajeAlertaModal={toggleMensajeAlertaModal}
              pasoMensajeAlerta={pasoMensajeAlerta}
              showModal={toggleCrearNuevoModal}
              refreshPage={manageSearch}
              idReserva={idReserva}
              setIdReserva={setIdReserva}
              isEdit={isEdit}
            />
          </div>
        }

        {showModalEditar &&
          <div className="modal-contactanos modal-response-ficha-background">
            <EditarPolitica
              showModal={toggleEditarModal}
              refreshPage={manageSearch}
              politicaId={politicaId}
              setIdReserva={setIdReserva}
            />
          </div>
        }

        {showModalConfirmarEliminar &&
          <div className="modal-contactanos modal-response-ficha-background">
            <ConfirmarEliminar
              showModal={toggleConfirmarEliminarModal}
              refreshPage={manageSearch}
              politicaId={politicaId}
              politicaTitle={politicaTitle}
            />
          </div>
        }


        {showModalMensajeAlerta &&
          <div className="modal-contactanos modal-response-ficha-background">
            <MensajeAlerta
              showModal={toggleMensajeAlertaModal}
              refreshPage={manageSearch}
              politicaId={politicaId}
              politicaTitle={politicaTitle}
              setPasoMensajeAlerta={setPasoMensajeAlerta}
            />
          </div>
        }

        <Wrapper className='App'>
          <PageHeader title={"Gestión de Políticas y Reglamentos"} />
          <div className="generic-buscador-container">
            <div className="filtros-left-section">
              <input
                type='text'
                className='generic-input-criterio-busqueda'
                placeholder='Buscar por nombre'
                value={criterioDeBusqueda}
                onChange={(e) => setCriterioDeBusqueda(e.target.value)}
                onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}
              />
              <button
                onClick={manageSearch}
                type='button'
                className='generic-button-buscador'>
                Buscar
              </button>
              <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
            </div>
            <div className="filtros-right-section">
              <button
                onClick={descargarReporteCompromiso}
                type='button'
                className='generic-button-buscador'
              >
                Descargar
                <p data-tip data-for={"ReporteCompromiso"} className="d-inline">
                  <FontAwesomeIcon icon='info-circle' className="hand-pointer ml-1" />
                </p>
                <ReactTooltip id={"ReporteCompromiso"} place="top" effect="solid" className="tooltip-style">
                  Descargar el reporte de compromiso
                </ReactTooltip>
              </button>
              <button
                onClick={toggleCrearNuevoModal}
                type='button'
                className='generic-button-buscador'>
                Nuevo
              </button>
            </div>
          </div>
          <div className='pt-2'>
            <div className="table-scroll">
              <table className='table table-hover'>
                <thead className="listar-thead">
                  <tr>
                    <th>
                      <div className='flex font-gothan-medium'>Nombre</div>
                    </th>
                    <th>
                      <FormSelect
                        options={categoriasParentOptionsFiltrados}
                        description={'Categoría'}
                        setSelectedOptions={setCategoriasParentSeleccionadas}
                        selectedOptions={categoriasParentSeleccionadas}
                      />
                    </th>
                    <th>
                      <FormSelect
                          options={categoriasChildOptionsFiltrados}
                          description={'Sub Categoría'}
                          setSelectedOptions={setCategoriasChildSeleccionadas}
                          selectedOptions={categoriasChildSeleccionadas}
                      />
                    </th>
                    <th>
                      <div className='flex font-gothan-medium'>Vigencia</div>
                    </th>
                    <th>
                      <FormSelect
                        options={estadoOptions}
                        description={'Estado'}
                        setSelectedOptions={setEstadoSeleccionados}
                        selectedOptions={estadoSeleccionados}
                      />
                    </th>
                    <th>
                      <div className='flex font-gothan-medium'>Acciones</div>
                    </th>
                  </tr>
                </thead>
                <TableWebBody
                  filas={filas}
                  toggleEditarModal={toggleEditarModal}
                  showModalReserva={toggleCrearNuevoModal}
                  toggleConfirmarEliminarModal={toggleConfirmarEliminarModal}
                  setPoliticaId={setPoliticaId}
                  setPoliticaTitle={setPoliticaTitle}
                  setIdReserva={setIdReserva}
                  setIsEdit={setIsEdit}
                  setInformacionOcurrencia={setInformacionOcurrencia}
                  refreshPage={manageSearch}
                />
              </table>
            </div>
              {totalPages > 1 && (
                <Paginator totalItems={totalItems}
                  firsItemPage={firsItemPage}
                  lastItemPage={lastItemPage}
                  totalPages={totalPages}
                  page={page}
                  onLeftClic={onLeftClic}
                  onRightClic={onRightClic} />
              )}

          </div>
        </Wrapper>
      </div >
    </>
  );
}

export default GestionPoliticasYReglamentos;
