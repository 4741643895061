import React from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { eliminarPoliticaAPI } from '../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';

const ConfirmarEliminar = ({ showModal, refreshPage, politicaId, politicaTitle }) => {
  const [managePost] = useManagePostRequest();

  const eliminarPolitica = async () => {
    await managePost(eliminarPoliticaAPI(politicaId), {}, () => {
      refreshPage();
      showModal();
    }, true);
  }

  return (
    <GenericModal showModal={showModal} width={"620px"} titulo={"Eliminar"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid"> 
          Se va a eliminar el siguiente item:
          <ul>
            <li><strong>{politicaTitle}</strong></li>
          </ul>

          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end" >
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={() => { showModal() }}>
            Cancelar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => { eliminarPolitica() }}>
            Eliminar
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default ConfirmarEliminar