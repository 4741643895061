import React, { useEffect, useState } from "react";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";
import {
    editarExperienciaLaboralAPI,
    getAreasPuestoAPI,
    getExperienciaLaboralToEditAPI,
    getPaisesAPI,
    getSectoresEmpresaAPI,
    getSugerenciasDeCargosParaExperienciaLaboralAPI,
    getSugerenciasDeNombreEmpresaParaExperienciaLaboralAPI,

} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useForm } from "../../../hooks/useForm/UseForm";
import SelectInput from "../../../components/Select/SelectInput";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { Spinner } from "react-bootstrap";
import AutoSuggestTextInput from "../../../components/AutoSuggestTextInput/AutoSuggestTextInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { notNullAndNotUndefinded } from "../../../helper/utils";
import { declaracionJurada } from "../../../constants/messages";

const EditarExperienciaLaboralModal = ({
    toggleCurrentModal,
    reFetchList,
    empleadoExperienciaLaboral,
    puedoEditar
}) => {
    const [getOptions] = useGetOptionsSelect();
    const [manageGetRequest] = useManageGetRequest();

    const sectionName = "EditarExperienciaLaboralModal";
    const [loading, setLoading] = useState(true);

    let allInputKeys = ["IdEmpleado", "DeclaracionJurada", "NombreEmpresa", "IdPaisEmpresa", "IdSector", "Cargo", "IdArea", "FechaInicio", "FechaFin", "FuncionesLogros", "Sustento", "LaborandoActualmente"]
    const [formValues, setFormValues] = useState({ "DeclaracionJurada": false });
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const ID_SUSTENTO_INPUT_FILE = "SustentoExperienciaLaboral";
    const EXTENSIONES_VALIDAS = ["pdf", "png", "jpg"];
    const [sustentoFile, setSustentoFile] = useState({});
    const [subiendoArchivos, setSubiendoArchivos] = useState(false);

    const [paisesOptions, setPaisesOptions] = useState([]);
    const [jsonSelectedPais, setJsonSelectedPais] = useState({});

    const [sectoresEmpresasOptions, setSectoresEmpresasOptions] = useState([]);
    const [jsonSelectedSectorEmpresa, setJsonSelectedSectorEmpresa] = useState({});

    const [areaPuestosOptions, setAreaPuestosOptions] = useState([]);
    const [jsonSelectedAreaPuesto, setJsonSelectedAreaPuesto] = useState({});

    const [nombreEmpresa, setNombreEmpresa] = useState('');

    const [hideFechaFinYsustento, setHideFechaFinYsustento] = useState(false);
    const [disableSubmitButton, setDisableSubmitButton] = useState(false);

    const [cargo, setCargo] = useState('');

    const [showTooltipAlreadySavedFile, setShowTooltipAlreadySavedFile] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (formValues["LaborandoActualmente"] === true) {
            setFormValues({ ...formValues, "FechaFin": '' });
            setSustentoFile({})
            setHideFechaFinYsustento(true);
        } else {
            setHideFechaFinYsustento(false);
        }

    }, [formValues["LaborandoActualmente"]])

    useEffect(() => {
        if (usuarioSubioArchivoNuevo())
            setShowTooltipAlreadySavedFile(false);

    }, [sustentoFile])

    const usuarioSubioArchivoNuevo = () => {
        return (notNullAndNotUndefinded(sustentoFile?.Path))
    }

    const callbackSuccessEditInfo = (response) => {
        let data = response?.data;
        let initFormValues = {
            "FechaInicio": data?.FechaInicioValue,
            "FechaFin": data?.FechaFinValue,
            "FuncionesLogros": data?.FuncionesLogros,
            "LaborandoActualmente": data?.LaborandoActualmente,
        }
        setFormValues({ ...formValues, ...initFormValues });

        setJsonSelectedSectorEmpresa({ 'label': data?.SectorName, 'value': data?.IdSector })
        setJsonSelectedPais({ 'label': data?.PaisEmpresaName, 'value': data?.IdPaisEmpresa })
        setJsonSelectedAreaPuesto({ 'label': data?.AreaName, 'value': data?.IdArea })

        setNombreEmpresa(data?.NombreEmpresa);
        setCargo(data?.Cargo);

        if (data?.LaborandoActualmente === true && data?.Sustento == null)
            setShowTooltipAlreadySavedFile(false);

        if (data?.LaborandoActualmente === false && data?.Sustento != null)
            setShowTooltipAlreadySavedFile(true);

    }

    const init = async () => {
        try {
            setLoading(true);
            await manageGetRequest(getExperienciaLaboralToEditAPI(empleadoExperienciaLaboral?.IdEmpleadoExperienciaLaboral), callbackSuccessEditInfo, false);

            setPaisesOptions(await getOptions(getPaisesAPI()));
            setSectoresEmpresasOptions(await getOptions(getSectoresEmpresaAPI()));
            setAreaPuestosOptions(await getOptions(getAreasPuestoAPI()));
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const successSubmitCallback = () => {
        reFetchList();
        toggleCurrentModal();
    }

    const submit = async () => {
        setDisableSubmitButton(true);
        let payload = {
            "IdEmpleadoExperienciaLaboral": empleadoExperienciaLaboral?.IdEmpleadoExperienciaLaboral,
            "DeclaracionJurada": formValues["DeclaracionJurada"],
            "NombreEmpresa": nombreEmpresa,
            "IdPaisEmpresa": jsonSelectedPais?.value,
            "IdSector": jsonSelectedSectorEmpresa?.value,
            "Cargo": cargo,
            "IdArea": jsonSelectedAreaPuesto?.value,
            "FechaInicio": formValues["FechaInicio"],
            "FechaFin": formValues["FechaFin"],
            "FuncionesLogros": formValues["FuncionesLogros"],
            "LaborandoActualmente": formValues["LaborandoActualmente"],
            "Sustento": sustentoFile
        }

        let api = editarExperienciaLaboralAPI();
        await submitForm(allInputKeys, api, payload, sectionName, successSubmitCallback);
        setDisableSubmitButton(false);
    }

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        );
    }

    return (
        <>
            <GenericModal showModal={toggleCurrentModal} width={"790px"} titulo={puedoEditar ? "Editar Experiencia Laboral" : "Detalle Experiencia Laboral"}>
                <div className="modal-body pt-0 pl-0 pb-0">
                    <form>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Nombre de la empresa<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <AutoSuggestTextInput inputTextValue={nombreEmpresa}
                                    setInputTextValue={setNombreEmpresa}
                                    suggestionsPostApi={getSugerenciasDeNombreEmpresaParaExperienciaLaboralAPI}
                                    placeholder={"Máximo 100 caracteres"}
                                    maxlength={"100"}
                                    disabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-NombreEmpresa-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">País de la empresa<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={paisesOptions}
                                    jsonSelectedValue={jsonSelectedPais}
                                    setJsonSelectedValue={setJsonSelectedPais}
                                    placeholder={"Seleccionar país"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdPaisEmpresa-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Sector de la empresa<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={sectoresEmpresasOptions}
                                    jsonSelectedValue={jsonSelectedSectorEmpresa}
                                    setJsonSelectedValue={setJsonSelectedSectorEmpresa}
                                    placeholder={"Seleccionar Sector de empresa"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdSector-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Nombre del cargo<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <AutoSuggestTextInput inputTextValue={cargo}
                                    setInputTextValue={setCargo}
                                    suggestionsPostApi={getSugerenciasDeCargosParaExperienciaLaboralAPI}
                                    placeholder={"Máximo 100 caracteres"}
                                    maxlength={"100"}
                                    disabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-Cargo-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Área del puesto<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={areaPuestosOptions}
                                    jsonSelectedValue={jsonSelectedAreaPuesto}
                                    setJsonSelectedValue={setJsonSelectedAreaPuesto}
                                    placeholder={"Seleccionar Área del puesto"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdArea-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Fecha inicio<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <input type="date" className="form-control" name="FechaInicio"
                                    value={formValues.FechaInicio} onChange={handleChange}
                                    disabled={!puedoEditar}>
                                </input>
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Fecha finalización<span
                                className='required'>*</span></label>

                            <div className="col-sm-8 black-color">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="LaborandoActualmente"
                                        checked={formValues.LaborandoActualmente} onChange={handleChange} disabled={!puedoEditar}></input>
                                    <label className="form-check-label ficha-declaracion-jurada-message">
                                        <p className="mb-0 black-color">Laborando actualmente</p>
                                    </label>
                                    <p className="ficha-form-input-validation-message"
                                        id={`ficha-edit-${sectionName}-LaborandoActualmente-validation-message`}></p>
                                </div>

                                {!hideFechaFinYsustento &&
                                    <div>
                                        <input type="date" className="form-control" name="FechaFin"
                                            value={formValues.FechaFin} onChange={handleChange} disabled={!puedoEditar}>
                                        </input>
                                        <p className="ficha-form-input-validation-message"
                                            id={`ficha-edit-${sectionName}-FechaFin-validation-message`}></p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Funciones y logros<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <textarea className="form-control" rows="3" name="FuncionesLogros"
                                    onChange={handleChange} value={formValues.FuncionesLogros}
                                    maxLength="1000" placeholder={"Máximo 1000 caracteres."} disabled={!puedoEditar}></textarea>
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-FuncionesLogros-validation-message`}></p>
                            </div>
                        </div>

                        {puedoEditar && <>
                            {!hideFechaFinYsustento &&
                                <div className="form-group row mb-0">
                                    <label className="col-sm-4 col-form-label modal-label">Sustento<span
                                        className='required'>*</span></label>
                                    <div className="col-sm-8 black-color">

                                        <GenericUploadFileV2
                                            id={ID_SUSTENTO_INPUT_FILE}
                                            extensionesValidas={EXTENSIONES_VALIDAS}
                                            uploadFileToS3={setSustentoFile}
                                            textButton={"Subir archivo"}
                                            subiendoArchivoPublicState={subiendoArchivos}
                                            setSubiendoArchivoPublicState={setSubiendoArchivos}
                                        >

                                            {showTooltipAlreadySavedFile &&
                                                <>
                                                    <p
                                                        data-tip
                                                        data-for="informacion-residencia-sustento-already-saved"
                                                        style={{ display: "inline" }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="info-circle"
                                                            transform="left-9"
                                                            className="hand-pointer ficha-residencia-ver-info-icon"
                                                        />
                                                    </p>
                                                    <ReactTooltip
                                                        id="informacion-residencia-sustento-already-saved"
                                                        place="top"
                                                        effect="solid"
                                                        className="tooltip-style tooltip-change-witdh"
                                                    >
                                                        Usted ya tiene un adjunto guardado previamente por lo que
                                                        no es necesario que adjunte un nuevo archivo.
                                                    </ReactTooltip>
                                                </>}
                                        </GenericUploadFileV2>

                                        <p className="ficha-form-input-validation-message"
                                            id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                                    </div>
                                    <div>
                                        <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                                            <strong>* El formato permitido es un .pdf de máximo 8MB.</strong>
                                        </label>
                                    </div>
                                </div>}</>}
                    </form>
                </div>
                {puedoEditar && <>
                    <div className="form-check margin-top-10px">
                        <input className="form-check-input" type="checkbox" name="DeclaracionJurada"
                            checked={formValues.DeclaracionJurada} onChange={handleChange}>
                        </input>
                        <label className="form-check-label ficha-declaracion-jurada-message">
                            <strong>
                                <p className="mb-0">{declaracionJurada}</p>
                            </strong>
                        </label>
                        <p className="ficha-form-input-validation-message"
                            id={`ficha-edit-${sectionName}-DeclaracionJurada-validation-message`}></p>
                    </div>
                    <div className="modal-footer d-flex justify-content-end pr-4">
                        <div className="button">
                            <button
                                className="btn btn-primary generic-button-ficha-modal"
                                onClick={submit}
                                disabled={disableSubmitButton || subiendoArchivos}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </>}
            </GenericModal>
        </>
    )
}

export default EditarExperienciaLaboralModal;
