import React, {useContext, useEffect, useState} from 'react';

import TableWebBody from './TableWebBody';
import FormSelect from '../../components/atoms/Select';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from "../../components/pageHeader/PageHeader";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {
    downloadReporteEncuestaSaludAPI,
    eliminarEncuestaAPI,
    getAllAreasAPI, getAllCargosAPI,
    getAllDepartamentosAPI,
    getAllEstadosAPI,
    getAllSedesAPI,
    getDivisionesAPI,
    getTiposAdjuntosEncuestaSaludAPI,
    listarEncuestasDeSaludAPI
} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import Paginator from "../directorio/Paginator";
import {Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {downloadFile} from "../../consumers/backendConsumer";
import {AuthContext} from "../../hooks/context/authContext";
import {updateOptionsDeFiltro} from "../../helper/Listas/ListasUtils";
import NuevoDetalleEncuestaSaludModal from './modales/NuevoDetalleEncuestaSaludModal';

const EncuestasDeSalud = () => {
    const {state} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [filas, setFilas] = useState([]);
    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
    const [cargosSeleccionadas, setCargosSeleccionadas] = useState([]);
    const [tieneCondicionesDeRiesgoSeleccionadas, setTieneCondicionesDeRiesgoSeleccionadas] = useState([]);
    const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);
    const [gerenciaSeleccionadas, setGerenciaSeleccionadas] = useState([]);
    const [areaSeleccionados, setAreaSeleccionados] = useState([]);
    const [adjuntosSeleccionados, setAdjuntosSeleccionados] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);

    const [sedesFiltradasOptions, setSedesFiltradasOptions] = useState([]);
    const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);
    const [tieneCondicionDeRiesgoFiltradasOptions, setTieneCondicionDeRiesgoFiltradasOptions] = useState([]);
    const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);
    const [gerenciaFiltradasOptions, setGerenciaFiltradasOptions] = useState([]);
    const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
    const [adjuntosFiltradosOptions, setAdjuntosFiltradosOptions] = useState([]);
    const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);

    const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);
    const [allSedesFiltradasOptions, setAllSedesFiltradasOptions] = useState([]);
    const [allTieneCondicionDeRiesgoFiltradasOptions, setAllTieneCondicionDeRiesgoFiltradasOptions] = useState([]);
    const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);
    const [allGerenciaFiltradasOptions, setAllGerenciaFiltradasOptions] = useState([]);
    const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
    const [allAdjuntosFiltradosOptions, setAllAdjuntosFiltradosOptions] = useState([]);
    const [allEstadosFiltradosOptions, setAllEstadosFiltradosOptions] = useState([]);

    const [cleanFilterss, setCleanFilters] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [hideDetalleEncuestaModal, setHideDetalleEncuestaModal] = useState(true);
    const [idEncuesta, setIdEncuesta] = useState();
    const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, adjuntosSeleccionados, tieneCondicionesDeRiesgoSeleccionadas, cargosSeleccionadas, estadosSeleccionados]);

    useDidMountEffect(() => {
        manageSearch();
    }, [sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, adjuntosSeleccionados, tieneCondicionesDeRiesgoSeleccionadas, page, fechaMasRecientePrimeroBool, cargosSeleccionadas, estadosSeleccionados]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getTiposAdjuntosEncuestaSaludAPI()), setAdjuntosFiltradosOptions);

            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setAllSedesFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllGerenciaFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
            let estados = await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllTieneCondicionDeRiesgoFiltradasOptions);
            setAllEstadosFiltradosOptions(estados);

            await manageSearch();

        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const updateOptionsDeFiltros = async function (lists) {
        await updateOptionsDeFiltro(allSedesFiltradasOptions, lists["IdsSedes"], setSedesFiltradasOptions, executeGetRequestCallback(getAllSedesAPI()));
        await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
        await updateOptionsDeFiltro(allTieneCondicionDeRiesgoFiltradasOptions, lists["IdsEstadosYesNo"], setTieneCondicionDeRiesgoFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
        await updateOptionsDeFiltro(allGerenciaFiltradasOptions, lists["IdsGerencias"], setGerenciaFiltradasOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
        await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
        await updateOptionsDeFiltro(allEstadosFiltradosOptions, lists["IdsEstados"], setEstadosFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
            return response.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const obtenerPayload = function () {
        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdsSedes": sedesSeleccionadas,
            "IdsDivisiones": divisionSeleccionados,
            "IdsGerencias": gerenciaSeleccionadas,
            "IdsAreas": areaSeleccionados,
            "IdsCargos": cargosSeleccionadas,
            "IdsTipoAdjuntos": adjuntosSeleccionados,
            "IdsTieneCondicionRiesgo" : tieneCondicionesDeRiesgoSeleccionadas,
            "IdsEstados" : estadosSeleccionados,
            "Page": page,
            "OrdenarReciente":fechaMasRecientePrimeroBool
        };
        return payload;
    }

    const manageSearch = async () => {
        setLoading(true);
        let payload = obtenerPayload();
        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        const response = await executePostRequest(listarEncuestasDeSaludAPI(), payload);
        if (responseCode200(response)) {
            setFilas(response.data.data.Items);
            setTotalItems(response.data.data.TotalItems);
            setFirsItemPage(response.data.data.FirsItemPage);
            setLastItemPage(response.data.data.LastItemPage);
            setTotalPages(response.data.data.TotalPages);

            await updateOptionsDeFiltros(response?.data?.data?.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setSedesSeleccionadas([]);
        setDivisionSeleccionados([]);
        setGerenciaSeleccionadas([]);
        setAreaSeleccionados([]);
        setCargosSeleccionadas([]);
        setAdjuntosSeleccionados([]);
        setEstadosSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setFechaMasRecientePrimeroBool(true);
        setTieneCondicionesDeRiesgoSeleccionadas([]);
    };

    const descargar = async function () {
        let payload = obtenerPayload();
        const response = await executePostRequest(downloadReporteEncuestaSaludAPI(), payload);
        if (responseCode200(response)) {
            await descargarReporte(response);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const descargarReporte = async function (generarReporteResponse) {
        let originalFileName = generarReporteResponse.data.OriginalFileName;
        let filePath = generarReporteResponse.data.FilePath;
        let descargarReporteResponse = await downloadFile(state.token, filePath, originalFileName);
        if (responseCode200(descargarReporteResponse)) {
        } else {
            mostrarMensajeDeErrorConToast(descargarReporteResponse);
        }
    };

    const toggleDetalleEncuestaModal = async (idEncuesta) => {
        setHideDetalleEncuestaModal(!hideDetalleEncuestaModal);
        if (idEncuesta) {
            setIdEncuesta(idEncuesta)
        }
    };

    const eliminarEncuesta = async function (idEncuesta) {
        let payload = {
            "IdEncuesta": idEncuesta
        }

        const response = await executePostRequest(eliminarEncuestaAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            manageSearch();

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const onChangeFechaMasRecientePrimeroBool = (e) => {
        let inputValue = e.target.checked;
        setFechaMasRecientePrimeroBool(inputValue);
    };

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Esta página no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                {/* {hideDetalleEncuestaModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <DetalleEncuestaSaludModal showModal={toggleDetalleEncuestaModal} idEncuesta={idEncuesta}/>
                </div>
                } */}

                {hideDetalleEncuestaModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <NuevoDetalleEncuestaSaludModal showModal={toggleDetalleEncuestaModal} idEncuesta={idEncuesta} />
                    </div>
                }

                <Wrapper className='App'>
                    <PageHeader title={"Gestión de Ficha Médica 2023"}/>

                    {loading===true &&
                    <div className="spinner-cargando-container">
                        <Spinner animation="grow" variant="primary" size="1sm"/>
                    </div>
                    }
                    {loading === false &&
                    <>
                        <div className="generic-buscador-container">
                            <div className="filtros-left-section">
                                <input
                                    type='text'
                                    className='generic-input-criterio-busqueda'
                                    placeholder='Buscar por nombre'
                                    value={criterioDeBusqueda}
                                    onChange={(e) => {
                                        setCriterioDeBusqueda(e.target.value);
                                    }}
                                    onKeyPress={handleKeypress}
                                />
                                <button
                                    onClick={manageSearch}
                                    type='button'
                                    className='generic-button-buscador'>
                                    Buscar
                                </button>
                                <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                            </div>
                            <div className="filtros-right-section">
                                <button
                                    onClick={descargar}
                                    type='button'
                                    className='generic-button-buscador'>
                                    Descargar
                                </button>
                            </div>
                        </div>

                        <div className='pt-1'>
                            <div className="table-scroll">
                                <table className='table table-hover'>
                                    <thead className="listar-thead">
                                    <tr>
                                        <th className="mb-0 pb-0">
                                            <div className='flex font-gothan-medium'>Fecha Registro</div>
                                            <div className="custom-control custom-switch">
                                                <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                                                <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                                                    Más reciente
                                                </label>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='flex font-gothan-medium'>Nombre Completo</div>
                                        </th>
                                        <th>
                                            <FormSelect
                                                options={cargosFiltradasOptions}
                                                description={'Cargo'}
                                                setSelectedOptions={setCargosSeleccionadas}
                                                selectedOptions={cargosSeleccionadas}
                                            />
                                        </th>
                                     {/*   <th>
                                            <FormSelect
                                                options={sedesFiltradasOptions}
                                                description={'Sede'}
                                                setSelectedOptions={setSedesSeleccionadas}
                                                selectedOptions={sedesSeleccionadas}
                                            />
                                        </th>*/}
                                        <th>
                                            <FormSelect
                                                options={divisionFiltradosOptions}
                                                description={'División'}
                                                setSelectedOptions={setDivisionSeleccionados}
                                                selectedOptions={divisionSeleccionados}
                                            />
                                        </th>
                                        <th>
                                            <FormSelect
                                                options={gerenciaFiltradasOptions}
                                                description={'Gerencia'}
                                                setSelectedOptions={setGerenciaSeleccionadas}
                                                selectedOptions={gerenciaSeleccionadas}
                                            />
                                        </th>
                                        <th>
                                            <FormSelect
                                                options={areasFiltradasOptions}
                                                description={'Área'}
                                                setSelectedOptions={setAreaSeleccionados}
                                                selectedOptions={areaSeleccionados}
                                            />
                                        </th>

                                        <th>
                                            <FormSelect
                                                options={tieneCondicionDeRiesgoFiltradasOptions}
                                                description={'Tiene condición de Riesgo'}
                                                setSelectedOptions={setTieneCondicionesDeRiesgoSeleccionadas}
                                                selectedOptions={tieneCondicionesDeRiesgoSeleccionadas}
                                            />
                                        </th>

                                        <th>
                                            <FormSelect
                                                options={adjuntosFiltradosOptions}
                                                description={'Adjuntos'}
                                                setSelectedOptions={setAdjuntosSeleccionados}
                                                selectedOptions={adjuntosSeleccionados}
                                            />
                                        </th>
                                        <th>
                                            <FormSelect
                                                options={estadosFiltradosOptions}
                                                description={'Estado'}
                                                setSelectedOptions={setEstadosSeleccionados}
                                                selectedOptions={estadosSeleccionados}
                                            />
                                        </th>
                                        <th>
                                            <div className='flex font-gothan-medium'>Acciones</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <TableWebBody filas={filas} toggleDetalleEncuestaModal={toggleDetalleEncuestaModal} eliminarEncuesta={eliminarEncuesta}/>
                                </table>
                            </div>
                                {totalPages > 1 && (
                                    <Paginator totalItems={totalItems}
                                               firsItemPage={firsItemPage}
                                               lastItemPage={lastItemPage}
                                               totalPages={totalPages}
                                               page={page}
                                               onLeftClic={onLeftClic}
                                               onRightClic={onRightClic}/>
                                )}
                        </div>
                    </>
                    }
                </Wrapper>
            </div>
        </>
    );
};

export default EncuestasDeSalud;
