import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CompletarSeguimiento from '../modales/CompletarSeguimiento';
import { ESTADOS } from '../../../../../constants/hardCodedConstants';
import ModalEnabled from '../../../../../components/modalComponents/ModalEnabled';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { SeguimientoMaternidadActions } from '../actions/Actions';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { getSeguimientosGestacionByIdCondicionAPI } from '../../../../../consumers/backendApisUrls'

const SeguimientoMaternidad = ({ idCondicion, idTipoDeCondicion, isEditable = false, toggleMainModal, refreshMainPage }) => {

  const [formValues, setFormValues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useManageGetRequest();

  const init = async () => {
    setIsLoading(true);
    const api = getSeguimientosGestacionByIdCondicionAPI(idCondicion);
    try {
      await executeGet(api, ({ data }) => setFormValues(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleCompletar = (row) => {
    setModalEnabled({ isEnable: true, component: CompletarSeguimiento, data: { IdSeguimientoGestacion: row["IdSeguimientoGestacion"] }})
  }

  useEffect(() => {
    init()
  }, [])

  if(isLoading) return <SpinnerLoading />

  return (
    <>
      <Table>
        <ModalEnabled refreshPage={init} modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
        <table className='table table-hover'>
          <thead className='listar-thead'>
            <tr className='font-gothan-medium'>
              <th className='text-center col-sm-1'># Mes</th>
              <th className='text-center col-sm-1'>Estado</th>
              <th className='text-center col-sm-3'>Notas</th>
              <th className='text-center col-sm-2'>Fecha</th>
              <th className='text-center col-sm-3'>Resp.</th>
              <th className='text-center col-sm-2'>Acciones</th>
            </tr>
          </thead>
          <tbody className='listar-tbody'>
            {formValues.length && formValues.map(row => (
              <tr key={row.IdSeguimientoGestacion}>
                <td className='text-center'>{row.NroMes}</td>
                <td className='text-center'>{row.Estado}</td>
                <td className='text-center'>{row.Nota}</td>
                <td className='text-center'>{row.FechaCompletaText}</td>
                <td className='text-center'>{row.EmpleadoResponsable}</td>
                <td className='text-center'>
                  {row.IdEstado === ESTADOS.PENDIENTE &&
                    <button type='button' className='btn btn-primary generic-button-ficha-modal py-0 px-2' onClick={() => handleCompletar(row)} disabled={!isEditable}>Completar</button>
                  }
                  {row.IdEstado === ESTADOS.COMPLETADA &&
                    <FontAwesomeIcon
                      title='Completado'
                      icon="check"
                      size='lg'
                      className='green-innova'
                    />
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Table>
      {/* {isEditable &&
        <SeguimientoMaternidadActions refreshMainPage={refreshMainPage} toggleMainModal={toggleMainModal} idCondicion={idCondicion} idTipoDeCondicion={idTipoDeCondicion}/>
      } */}
    </>
  )
}

export default SeguimientoMaternidad;

const Table = styled.div`
  max-height: 250px;
  min-height: 80px;
  overflow-y: auto;
`