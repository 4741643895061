import React, {useContext, useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {AuthContext} from "../../../hooks/context/authContext";
import {useForm} from "../../../hooks/useForm/UseForm";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric, jsonNoEstaVacio} from "../../../helper/utils";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import MultiSelectInput from "../../../components/MultiSelectInput/MultiSelectInput";
import {toast} from "react-toastify";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {createUsuariosAPI, getRolesActivosAPI, getUsuarioToCreateAPI} from "../../../consumers/backendApisUrls";

const RegistrarUsuarioModal = ({showModal}) => {
    const {state} = useContext(AuthContext);
    const [usuariosOptions, setUsuariosOptions] = useState([]);
    const [selectedUsuariosValues, setSelectedUsuarios] = useState([]);
    const [rolesActivos, setRolesActivos] = useState([]);
    const [rolesCheckboxs, setRolesCheckboxs] = useState([]);
    const [camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues] = useState({});
    const [handleChangeCamposCheckboxFormValues] = useForm(camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues);
    const seccionName = "modal-registrar-usuario";

    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        camposCheckboxJsonFormValuesUseEffect();
    }, [camposCheckboxJsonFormValues]);

    const camposCheckboxJsonFormValuesUseEffect = () => {
        if (jsonNoEstaVacio(camposCheckboxJsonFormValues)) {
            console.debug("useEffect, camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);
            let reporteGeneralCamposCheckboxsTemp = [];

            for (let campoCheckbox of rolesActivos) {
                let key = campoCheckbox["Value"];
                let value = camposCheckboxJsonFormValues[key];
                let tempCampo = {};
                tempCampo = {...campoCheckbox, [key]: value};

                reporteGeneralCamposCheckboxsTemp = [...reporteGeneralCamposCheckboxsTemp, tempCampo];
            }
            setRolesCheckboxs(reporteGeneralCamposCheckboxsTemp);
        }
    };

    const getCheckBoxInitialFormValues = (camposCheckbox) => {
        let formInputValues = {};
        for (let campoCheckbox of camposCheckbox) {
            let Value = campoCheckbox["Value"];
            formInputValues = {...formInputValues, [Value]: false}
        }

        formInputValues = {...formInputValues};
        console.debug("formInputValues", formInputValues);
        return formInputValues;
    };

    const submitRegistrarUsuario = async () => {
        console.debug("camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);
        console.debug("selectedUsuariosValues: ", selectedUsuariosValues);

        let roles = [];

        for (let [key, value] of Object.entries(camposCheckboxJsonFormValues)) {
            if (value === true) {
                roles.push(parseInt(key));
            }
        }

        let payload = {
            "IdsEmpleados": selectedUsuariosValues,
            "IdsRoles": roles
        };

        console.debug("payload: ", payload);

        const response = await executePostRequest(createUsuariosAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showModal();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdsEmpleados", "IdsRoles"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, seccionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const init = async () => {
        setOptionsDropDownIndependiente(executeGetRequestCallback(getUsuarioToCreateAPI()), setUsuariosOptions);
        const response = await executeGetRequest(getRolesActivosAPI());
        if (responseCode200(response)) {
            setRolesActivos(response.data);
            let camposCheckboxFormValues = getCheckBoxInitialFormValues(response.data);
            setCamposCheckboxJsonFormValues(camposCheckboxFormValues);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const updateOptionBasedOnResponse = (apiOptions, setStatee) => {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(apiOptions, "IdEmpleado", "NombreEmpleado");
        setStatee(options);
    };

    const setOptionsDropDownIndependiente = async (getApiFunction, setStatee) => {
        const response = await getApiFunction(state.token);
        if (responseCode200(response)) {
            updateOptionBasedOnResponse(response.data, setStatee);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo={"Nuevo Registro"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-12 col-form-label modal-label">1. Seleccionar al (a los) colaborador(es)<span className="required">*</span></label>
                            </div>

                            <div className="form-group row form-input-modal-contactanos">
                                <div className="col-sm-12 black-color" >
                                    <MultiSelectInput options={usuariosOptions} selectedValues={selectedUsuariosValues} setSelectedValues={setSelectedUsuarios} isDisabled={false} placeholder={"Seleccionar al (a los) colaborador(es)"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdsEmpleados-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-12 col-form-label modal-label">2. Seleccionar el (los) rol(es)<span className="required">*</span></label>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <div className="col-sm-12 custom-scroll">
                                    <div className="gestion-usuarios-roles-checkboxs-group ">
                                        {rolesCheckboxs && rolesCheckboxs.map((campo, index) => {
                                            return (
                                                <div className="gestion-usuarios-roles-checkbox-container" key={index}>
                                                    <input type="checkbox" name={campo["Value"]} checked={campo[campo.Value]} onChange={handleChangeCamposCheckboxFormValues}></input><p className="resportes-checkbox-input-text">{campo.Text}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdsRoles-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light">
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha-modal" onClick={submitRegistrarUsuario}>Guardar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default RegistrarUsuarioModal;