import React, { useState, useEffect } from 'react'
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import InputSelect from '../../../components/modalComponents/InputSelect';
import InputDate from '../../../components/modalComponents/InputDate';
import ModalForm from '../../../components/modalComponents/ModalForm'
import { addDays, getTodayDate, initialAllOptionPromises, toIsoForInput } from '../../../helper/utils';
import { getHijosSolicitudByColaboradorAPI, solicitarAsignacionFamiliarAPI } from '../../../consumers/backendApisUrls';

const SolicitarAsignacionFamiliarModal = ({ toggleModal, refreshPage, empleadoId: IdEmpleado }) => {

  const SECTION_NAME = "solicitarAsignacionFamiliar";
  const KEYS = {Empleado: "IdEmpleado", Hijo: "IdPersonaHijo", Desde: "VigenciaStartDate", Hasta: "VigenciaEndDate", Sustento: "Sustento"};
  const INIT_KEYS = { PersonaHijo: "IdPersonaHijo" };
  const minValueDesdeDate = toIsoForInput(getTodayDate());
  const minValueHastaDate = toIsoForInput(addDays(getTodayDate(), 1));

  const [formValues, setFormValues] = useState({ });
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      getOptions(getHijosSolicitudByColaboradorAPI(IdEmpleado)),
    ]
  }

  const init = async () => {
    initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllDropdowns);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = solicitarAsignacionFamiliarAPI();
    const payload = { IdEmpleado, ...formValues };
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmitCallback = () => {
    toggleModal()
    refreshPage();
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, [KEYS.Sustento]: res}));
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Solicitar Asignación Familiar" width="750px" isSubmitting={isSubmitting}>
      <div className="col-sm-12 text-justify mb-3 card-secundario-header"><label className='required'>{messageModal}</label></div>
      <div className="col-sm-12"><InputSelect label='Seleccionar Hijo' {...selectProps(KEYS.Hijo)}/></div>
      <label className="ml-3"><strong>Vigencia</strong></label>
      <div className="displayFlexWrap">
        <div className="col-sm-6"><InputDate label="Desde" {...commonProps(KEYS.Desde)} min={minValueDesdeDate}/></div>
        <div className="col-sm-6"><InputDate label="Hasta" {...commonProps(KEYS.Hasta)} min={minValueHastaDate}/></div>
      </div>
      <div className="col-sm-12">
        <InputUploadFile label="Cargar Sustento" name="Sustento" uploadFile={formValues[KEYS.Sustento]} setUploadFile={handleUploadFile} sectionName={SECTION_NAME} validExtensions={["pdf", "jpg"]}/>
        <label className='card-secundario-header required'>{messageBoletaModal}</label>
      </div>
    </ModalForm>
  )
}

export default SolicitarAsignacionFamiliarModal;

const messageModal = "AVISO: Este beneficio es importante presentarlo correctamente hasta el 21 del presente mes, posterior a la fecha se estará considerando desde el próximo mes"
const messageBoletaModal = "Adjuntar la constancia/boleta de matrícula del semestre actual emitida por la universidad";