import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm/UseForm';
import GenericModal from '../../../components/modal/GenericModal';

import {getSedeByIdAPI} from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { AuthContext } from '../../../hooks/context/authContext';
import {toast} from "react-toastify";
import ReactTooltip from 'react-tooltip';
import { openGmail, openWhatsapp } from '../../../components/utils';

const DetalleSedeModal = ({ showModal, reload, userId = null }) => {

    const { state } = useContext(AuthContext);
    const [soloLectura, setSoloLectura] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();

    const [detalleSedeResponse, setDetalleSedeResponse] = useState({});

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        setLoading(true);

        let responseData = await getDetalleSede();

        if (responseData) {

            let formValuess = {
                "Nombre": responseData?.Nombre,
                "Direccion" : responseData?.Direccion,
                "Referencia" : responseData?.Referencia,
                "CanSendWhatsApp" : responseData?.CanSendWhatsApp,
                "EmailDocente" : responseData?.EmailDocente,
                "EmailEquipoDirectivo" : responseData?.EmailEquipoDirectivo,
                "NombreAsistente" : responseData?.NombreAsistente,
                "CorreoAsistente" : responseData?.CorreoAsistente,
                "CelularAsistente" : responseData?.CelularAsistente,
                "NombreSupervisorSistemas" : responseData?.NombreSupervisorSistemas,
                "CorreoSupervisorSistemas" : responseData?.CorreoSupervisorSistemas,
                "CelularSupervisorSistemas" : responseData?.CelularSupervisorSistemas,
                "NombreRegional" : responseData?.NombreRegional,
                "CorreoRegional" : responseData?.CorreoRegional,
                "CelularRegional" : responseData?.CelularRegional,
                "NombreLegal" : responseData?.NombreLegal,
                "CorreoLegal" : responseData?.CorreoLegal,
                "CelularLegal" : responseData?.CelularLegal,
                "NombreJefeComercial" : responseData?.NombreJefeComercial,
                "CorreoJefeComercial" : responseData?.CorreoJefeComercial,
                "CelularJefeComercial" : responseData?.CelularJefeComercial,
                "NombreCoordinadorMantenimiento" : responseData?.NombreCoordinadorMantenimiento,
                "CorreoCoordinadorMantenimiento" : responseData?.CorreoCoordinadorMantenimiento,
                "CelularCoordinadorMantenimiento" : responseData?.CelularCoordinadorMantenimiento,
                "NombreDirector" : responseData?.NombreDirector,
                "CorreoDirector" : responseData?.CorreoDirector,
                "CelularDirector" : responseData?.CelularDirector,
                "AnexoDirector" : responseData?.AnexoDirector,
                "NombreCoordinadorAdmisionRenovacion" : responseData?.NombreCoordinadorAdmisionRenovacion,
                "CorreoCoordinadorAdmisionRenovacion" : responseData?.CorreoCoordinadorAdmisionRenovacion,
                "CelularCoordinadorAdmisionRenovacion" : responseData?.CelularCoordinadorAdmisionRenovacion,
                "AnexoCoordinadorAdmisionRenovacion" : responseData?.AnexoCoordinadorAdmisionRenovacion,
                "NombreCoordinadorOperacionAdministracion" : responseData?.NombreCoordinadorOperacionAdministracion,
                "CorreoCoordinadorOperacionAdministracion" : responseData?.CorreoCoordinadorOperacionAdministracion,
                "CelularCoordinadorOperacionAdministracion" : responseData?.CelularCoordinadorOperacionAdministracion,
                "AnexoCoordinadorOperacionAdministracion" : responseData?.AnexoCoordinadorOperacionAdministracion,
                "NombreAsistenteAdministrativo" : responseData?.NombreAsistenteAdministrativo,
                "CorreoAsistenteAdministrativo" : responseData?.CorreoAsistenteAdministrativo,
                "CelularAsistenteAdministrativo" : responseData?.CelularAsistenteAdministrativo,
                "AnexoAsistenteAdministrativo" : responseData?.AnexoAsistenteAdministrativo,
                "NombreEnfermera" : responseData?.NombreEnfermera,
                "CorreoEnfermera" : responseData?.CorreoEnfermera,
                "CelularEnfermera" : responseData?.CelularEnfermera,
                "NombrePsicologa" : responseData?.NombrePsicologa,
                "CorreoPsicologa" : responseData?.CorreoPsicologa,
                "CelularPsicologa" : responseData?.CelularPsicologa,
                "AnexoPsicologa" : responseData?.AnexoPsicologa,
                "NombreCoordinadorAcademico" : responseData?.NombreCoordinadorAcademico,
                "CorreoCoordinadorAcademico" : responseData?.CorreoCoordinadorAcademico,
                "CelularCoordinadorAcademico" : responseData?.CelularCoordinadorAcademico,
                "AnexoCoordinadorAcademico" : responseData?.AnexoCoordinadorAcademico,
                "NombreCoordinadorGdh" : responseData?.NombreCoordinadorGdh,
                "CorreoCoordinadorGdh" : responseData?.CorreoCoordinadorGdh,
                "CelularCoordinadorGdh" : responseData?.CelularCoordinadorGdh,
            }
            setFormValues(formValuess);
        }

        setLoading(false);
    };

    const getDetalleSede = async function () {
        let response;
        if(userId !== null) response = await executeGetRequest(getSedeByIdAPI(userId));
        else response = await executeGetRequest(getSedeByIdAPI(state.user.id_empleado));
        if (responseCode200(response)) {
            setDetalleSedeResponse(response.data);
            if(userId) {setSoloLectura(false);}
            else setSoloLectura(response.data.SoloLectura);
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    function copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
             });
        }
    }

    const detalleFormulario = (
        <>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.Nombre ?? ""}</label>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Dirección:</label>
                <div className="col-sm-8">
                    <textarea  rows="3" className="form-control" name="Direccion" onChange={handleChange} value={formValues?.Direccion ?? ""} readOnly></textarea>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Referencia:</label>
                <div className="col-sm-8">
                    <textarea  rows="4" className="form-control" name="Referencia" onChange={handleChange} value={formValues?.Referencia ?? ""} readOnly></textarea>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label" >Equipo docente:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="EmailDocente" onChange={handleChange} value={formValues?.EmailDocente ?? ""} readOnly></input>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Equipo directivo:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="EmailEquipoDirectivo" onChange={handleChange} value={formValues?.EmailEquipoDirectivo ?? ""} readOnly></input>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Regional:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreRegional ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularRegional && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_Re" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_Re" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularRegional).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularRegional)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoRegional && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_Re" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_Re" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoRegional).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoRegional)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Director colegio:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreDirector ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoDirector && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_Di" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_Di" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoDirector).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoDirector}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularDirector && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_Di" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_Di" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularDirector).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularDirector)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoDirector && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_Di" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_Di" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoDirector).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoDirector)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Coord. académico:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreCoordinadorAcademico ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoCoordinadorAcademico && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_ca" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_ca" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoCoordinadorAcademico).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoCoordinadorAcademico}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularCoordinadorAcademico && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_ca" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_ca" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularCoordinadorAcademico).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularCoordinadorAcademico)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoCoordinadorAcademico && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_ca" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_ca" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoCoordinadorAcademico).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoCoordinadorAcademico)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Psicóloga:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombrePsicologa ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoPsicologa && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_ps" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_ps" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoPsicologa).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoPsicologa}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularPsicologa && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_ps" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_ps" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularPsicologa).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularPsicologa)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoPsicologa && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_ps" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_ps" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoPsicologa).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoPsicologa)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>

            
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">CAR:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreCoordinadorAdmisionRenovacion ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoCoordinadorAdmisionRenovacion && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_car" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_car" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoCoordinadorAdmisionRenovacion).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoCoordinadorAdmisionRenovacion}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularCoordinadorAdmisionRenovacion && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_car" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_car" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularCoordinadorAdmisionRenovacion).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularCoordinadorAdmisionRenovacion)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoCoordinadorAdmisionRenovacion && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_car" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_car" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoCoordinadorAdmisionRenovacion).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoCoordinadorAdmisionRenovacion)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">COA:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreCoordinadorOperacionAdministracion ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoCoordinadorOperacionAdministracion && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_coa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_coa" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoCoordinadorOperacionAdministracion).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoCoordinadorOperacionAdministracion}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularCoordinadorOperacionAdministracion && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_coa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_coa" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularCoordinadorOperacionAdministracion).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularCoordinadorOperacionAdministracion)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoCoordinadorOperacionAdministracion && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_coa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_coa" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoCoordinadorOperacionAdministracion).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoCoordinadorOperacionAdministracion)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Asistente administrativo:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreAsistenteAdministrativo ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.AnexoAsistenteAdministrativo && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonAnexo_aa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonAnexo_aa" effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.AnexoAsistenteAdministrativo).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {formValues?.AnexoAsistenteAdministrativo}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CelularAsistenteAdministrativo && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_aa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_aa" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularAsistenteAdministrativo).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularAsistenteAdministrativo)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoAsistenteAdministrativo && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_aa" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_aa" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoAsistenteAdministrativo).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoAsistenteAdministrativo)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>  
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Técnica Tópico:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreEnfermera ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularEnfermera && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_en" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_en" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularEnfermera).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularEnfermera)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoEnfermera && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_en" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_en" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoEnfermera).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoEnfermera)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Coord. Gdh Regional:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreCoordinadorGdh ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularCoordinadorGdh && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_CGR" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_CGR" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularCoordinadorGdh).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularCoordinadorGdh)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoCoordinadorGdh && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_CGR" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_CGR" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoCoordinadorGdh).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoCoordinadorGdh)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>                      
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Supervisor de sistemas:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreSupervisorSistemas ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularSupervisorSistemas && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_sp" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_sp" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularSupervisorSistemas).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularSupervisorSistemas)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoSupervisorSistemas && (
                            <div>
                             <p data-tip data-for="BottonCorreoDetalle_sp" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_sp" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoSupervisorSistemas).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoSupervisorSistemas)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Asistente de sistemas:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreAsistente ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularAsistente && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_as" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_as" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularAsistente).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularAsistente)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoAsistente && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonCorreoDetalle_as" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_as" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoAsistente).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoAsistente)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>                     

            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Jefe comercial:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreJefeComercial ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularJefeComercial && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_jc" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_jc" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularJefeComercial).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularJefeComercial)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoJefeComercial && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonCorreoDetalle_jc" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_jc" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoJefeComercial).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoJefeComercial)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>   

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Coord. mantenimiento:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreCoordinadorMantenimiento ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularCoordinadorMantenimiento && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_cm" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_cm" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularCoordinadorMantenimiento).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularCoordinadorMantenimiento)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoCoordinadorMantenimiento && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonCorreoDetalle_cm" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_cm" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CorreoCoordinadorMantenimiento).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues?.CorreoCoordinadorMantenimiento)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div>   
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Legal:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.NombreLegal ?? ""}</label>
                    <div style={{'float':'right','display':'flex','margin-top':'1%',}}>
                    {formValues?.CelularLegal && formValues?.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonWhatsAppDetalle_le" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonWhatsAppDetalle_le" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues?.CelularLegal).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(formValues?.CelularLegal)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    {formValues?.CorreoLegal && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for="BottonCorreoDetalle_le" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id="BottonCorreoDetalle_le" effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(formValues.CorreoLegal).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(formValues.CorreoLegal)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                    )}
                    </div>
                    <p className="ficha-form-input-validation-message mb-2"></p>
                </div>
            </div> 
        </>
    );

    return (
        <>
            <GenericModal showModal={showModal} width={"730px"} titulo={"Detalle de Sede"}>
                {loading === true &&
                <div className="spinner-cargando-container">
                    <Spinner animation="grow" variant="primary" size="1sm"/>
                </div>
                }
                {loading === false &&
                    <>
                     <div className="modal-body p-0">
                        <form className="blue-standard-color">
                            {detalleFormulario}
                        </form>
                     </div>
                    </>
                }
            </GenericModal>
        </>
    );
}

export default DetalleSedeModal;
