import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTodayDate, toIsoForInput } from '../../../../helper/utils';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import InputTextArea from '../../../../components/modalComponents/InputTextArea';
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputDate from '../../../../components/modalComponents/InputDate';
import { observarNuevoIngresoAPI } from '../../../../consumers/backendApisUrls';

const ObservarFichaNuevoIngreso = ({ id, toggleModal, manageSearch }) => {

  const SECTION_NAME = "ObservarFichaNuevoIngreso";
  const KEYS = ["FechaLimite", "Observacion"];
  const [formValues, setFormValues] = useState({});
  const history = useHistory();

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const api = observarNuevoIngresoAPI();
      const payload = { IdNuevosIngresosEmpleado: parseInt(id), ...formValues };
      await submitForm(KEYS, api, payload, SECTION_NAME, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
  }

  const successOnSubmitCallback = () => {
    history.push('/adminNuevosIngresos');
  }

  return (
    <ModalForm onSubmit={onSubmit} showModal={toggleModal} titulo="Observar" width="600px">
      <InputTextArea label="Observacion" {...commonProps("Observacion")}/>
      <InputDate label="Fecha límite" {...commonProps("FechaLimite")} min={toIsoForInput(getTodayDate())}/>
    </ModalForm>
  )
}

export default ObservarFichaNuevoIngreso