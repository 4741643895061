import React from 'react';
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, setStateWithCallBackResponse} from '../../helper/utils';
import {useGetRequest} from '../useGetRequest/useGetRequest';
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";

export const useGetOptionsSelect = () => {
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const getOptions = async function (api) {
        const response = await executeGetRequest(api);
        if (responseCode200(response)) {
            return mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    return [getOptions];
}