import React, { Component } from 'react';

import AsyncSelect from 'react-select/async';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../helper/utils';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';

 const AsyncSelectInput = ({api, placeholder, handleSelectInputChange, isDisabled, defaultValue}) => {
    const [executePostRequest] = usePostRequest();
    const defaultEmptyValue = { value: "", label: "" };
    const loadOptions = (inputValue, callback) => {
        if(!inputValue) {
            return callback([]);
        }
        setTimeout(async () => {
            let payload = {
                Criteria: inputValue ?? ""
            }
            let response = await executePostRequest(api(), payload);
            if (responseCode200(response)) {
                let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response?.data);
                callback(options);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }, 1000);
    };

    const handleInputChange = (newValue) => {
        return newValue;
    };

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions
                onInputChange={handleInputChange}
                placeholder={placeholder}
                onChange={handleSelectInputChange}
                isDisabled={isDisabled}
                value={defaultValue}
                menuPosition='fixed' // check
            />
        </>
    );
}

export default AsyncSelectInput;