import React, { useState, useEffect } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import Wrapper from "../../../components/atoms/DirectorioWrapper";
import PageHeader from "../../../components/pageHeader/PageHeader";
import FormSelect from "../../../components/atoms/Select";
import PageButtons from "../../../components/pageComponents/PageButtons";
import PaginatorV2 from "../../../components/pageComponents/PaginatorV2";
import SpinnerLoading from "../../../components/pageComponents/SpinnerLoading";
import SearchContainer from "../../../components/pageComponents/SearchContainer";
import TableWebBody from "./TableWebBody";
import CrearDocumento from "./modales/CrearDocumento";
import { getAllEstadosAPI, getConfiguracionDocumentoIndexAPI, getOrigenDocumentoAPI } from "../../../consumers/backendApisUrls";

const ConfigurarDocumentos = () => {

  const [allOriginOptions, setAllOriginOptions] = useState([]);
  const [allStateOptions, setAllStatesOptions] = useState([]);
  const [filterSelected, setFilterSelected] = useState({});

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false });
  const [loading, setLoading] = useState(true);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1})

  const [executePost] = useManagePostRequest();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    setLoading(true);
    try{
      executeGet(getOrigenDocumentoAPI(), (res) => setAllOriginOptions(res.data));
      executeGet(getAllEstadosAPI(), (res) => setAllStatesOptions(res.data));
      await manageSearch();
    } catch(error){
      console.error(error);
    }
    setLoading(false);
    }   

  const manageSearch = async () => {
    const api = getConfiguracionDocumentoIndexAPI();
    let payload = getPayload();
    await executePost(api, payload, successManageSearchCallback, false);
  }

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      IdsOrigenes: filterSelected?.IdsOrigenes,
      IdsEstados: filterSelected?.IdsEstados,
    };
  };

  const successManageSearchCallback = (response) => {
    setFormValues(response.data.Items);
    setExtraData(response.data.ExtraData);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  const handleSelectedFilter = (res, name) => {
    setFilterSelected(lastData => ({...lastData, [name]: res}));
  };

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, filterSelected]);

  useEffect(()=>{
    init();
  }, []);

  if (loading) return <SpinnerLoading />
  
  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnabled.isEnable && (
          <div className="modal-contactanos modal-response-ficha-background">
            <modalEnabled.component toggleModal={() => setModalEnabled({ isEnable: false })} refreshPage={manageSearch} {...modalEnabled.data} />
          </div>
        )}
        <Wrapper className="app">
          <PageHeader title="Administración de documentos" />
          <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
            <PageButtons handleCreateButton={() => setModalEnabled({ isEnable: true, component: CrearDocumento })} hasCreateButton hasBackButton/>
          </SearchContainer>
          <div className="container-fluid body-content">
            <div className="table-scroll pt-3">
              <table className="table table-hover">
                <thead className="listar-thead">
                  <tr className="font-gothan-medium">
                    <th className="pl-3">Item</th>
                    <th className="col-4">Nombre de documento</th>
                    <th className="col-4">
                      <FormSelect
                        options={allOriginOptions?.filter(o => extraData?.IdsOrigenes?.includes(o.Value))}
                        description="Origen del documento"
                        selectedOptions={filterSelected?.IdsOrigenes}
                        setSelectedOptions={(res) => handleSelectedFilter(res, "IdsOrigenes")}
                      />
                    </th>
                    <th className="col-3">
                      <FormSelect
                        options={allStateOptions.filter(o => extraData?.IdsEstados?.includes(o.Value))}
                        description="Estado"
                        selectedOptions={filterSelected?.IdsEstados}
                        setSelectedOptions={(res) => handleSelectedFilter(res, "IdsEstados")}
                      />
                    </th>
                    <th className="col-1">Acciones</th>
                  </tr>
                </thead>
                <TableWebBody filas={formValues} setModalEnabled={setModalEnabled} />
              </table>
              <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default ConfigurarDocumentos;
