import React, { useState, useEffect } from "react";
import { Spinner } from 'react-bootstrap';
import { getMisVacacionesAPI, getAllAreasAPI, getAllCargosAPI, getAllEstadosAPI } from "../../../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import useDidMountEffect from "../../../../hooks/useDidMountEffect/useDidMountEffect";
import Wrapper from "../../../../components/atoms/DirectorioWrapper";
import Paginator from "../../../directorio/Paginator";
import TableWebBody from "../../TableWebBody";
import FormSelect from "../../../../components/atoms/Select";
import VerSolicitudVacaciones from "../../modales/VerSolicitudVacaciones";
import EliminarSolicitudVacaciones from "../../modales/EliminarSolicitudVacaciones";
import AgregarNotaSolicitudVacaciones from "../../modales/AgregarNotaSolicitudVacaciones";
import { notNullAndNotUndefinded } from "../../../../helper/utils";
import SolicitarVacaciones from "../../modales/solicitarVacaciones/SolicitarVacaciones";

const ListaSolicitudes = () => {
  const [executePost] = useManagePostRequest();
  const [executeGet] = useManageGetRequest();

  const [idSolicitudMovimiento, setIdSolicitudMovimiento] = useState();
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  const [periodoMovimiento, setPeriodoMovimiento] = useState();
  const [estadoMovimiento, setEstadoMovimiento] = useState();
  const [dropdownsAreas, setDropdownsAreas] = useState([])
  const [allAreas, setAllAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [dropdownsCargos, setDropdownsCargos] = useState([])
  const [allCargos, setAllCargos] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [dropdownsEstados, setDropdownsEstados] = useState([])
  const [allEstados, setAllEstados] = useState([]);
  const [selectedEstados, setSelectedEstados] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [filas, setFilas] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [extraData, setExtraData] = useState({});
  
  const [showModalCrearSolicitudVacaciones, setShowModalCrearSolicitudVacaciones] = useState(false);
  const [showModalVerSolicitudVacaciones, setShowModalVerSolicitudVacaciones] = useState(false);
  const [showModalEliminarSolicitudVacaciones, setShowModalEliminarSolicitudVacaciones] = useState(false);
  const [showModalAgregarNotaSolicitudVacaciones, setShowModalAgregarNotaSolicitudVacaciones] = useState(false);

  const init = async () => {
    setLoading(true);
    try {
      getDropDownOptions();
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getDropDownOptions = async () => {
    executeGet(getAllAreasAPI(), (res) =>  setDropdownsAreas(res.data), false);
    executeGet(getAllCargosAPI(), (res) =>  setDropdownsCargos(res.data), false);
    executeGet(getAllEstadosAPI(), (res) =>  setDropdownsEstados(res.data), false);
  }

  const manageSearch = async () => {
    const api = getMisVacacionesAPI();
    const payload = getPayload();
    await executePost(api, payload, successInitCallback);
  }

  const getPayload = () => {
    return {
      IdsAreas: selectedAreas,
      IdsCargos: selectedCargos,
      IdsEstados: selectedEstados,
      page: page,
    };
  };

  const successInitCallback = (response) => {
    let data = response?.data;
    setFilas(data.Items);
    setTotalItems(data.TotalItems);
    setFirsItemPage(data.FirsItemPage);
    setLastItemPage(data.LastItemPage);
    setTotalPages(data.TotalPages);
    setExtraData(data.ExtraData);
  };

  const toggleCrearSolicitudVacaciones = () => {
    setShowModalCrearSolicitudVacaciones(!showModalCrearSolicitudVacaciones);
  };

  const toggleVerSolicitudVacaciones = (IdSolicitudMovimiento) => {
    if (IdSolicitudMovimiento) setIdSolicitudMovimiento(IdSolicitudMovimiento)
    setShowModalVerSolicitudVacaciones(!showModalVerSolicitudVacaciones);
  };

  const toggleEliminarSolicitudVacaciones = (IdSolicitudMovimiento, periodoMovimiento, estadoMovimiento) => {
    if (IdSolicitudMovimiento) setIdSolicitudMovimiento(IdSolicitudMovimiento);
    if (periodoMovimiento) setPeriodoMovimiento(periodoMovimiento);
    if (estadoMovimiento) setEstadoMovimiento(estadoMovimiento);
    setShowModalEliminarSolicitudVacaciones(!showModalEliminarSolicitudVacaciones);
  };

  const toggleAgregarNotaSolicitudVacaciones = (IdSolicitudMovimiento, nombreEmpleado) => {
      if (IdSolicitudMovimiento) setIdSolicitudMovimiento(IdSolicitudMovimiento)
      if (nombreEmpleado) setNombreEmpleado(nombreEmpleado)
      setShowModalAgregarNotaSolicitudVacaciones(!showModalAgregarNotaSolicitudVacaciones)
    }

  const setFilterWithExtraData = () => {
    setAllAreas(dropdownsAreas.filter(area => extraData.IdsAreas.includes(area.Value)));
    setAllCargos(dropdownsCargos.filter(cargo => extraData.IdsCargos.includes(cargo.Value)));
    setAllEstados(dropdownsEstados.filter(estado => extraData.IdsEstados.includes(estado.Value)));
  }

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
      const nextPage = Math.min(page + 1, totalPages);
      setPage(nextPage);
  };

  useEffect(() => {
    notNullAndNotUndefinded(extraData) &&  setFilterWithExtraData();
  },[extraData])

  useDidMountEffect(() => {
    manageSearch();
  },[selectedAreas, selectedCargos, selectedEstados])

  useDidMountEffect(() => {
    manageSearch();
  },[page]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
    );
}

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {showModalCrearSolicitudVacaciones && 
          <div className="modal-contactanos modal-response-ficha-background">
            {/* <CrearSolicitudVacaciones showModal={toggleCrearSolicitudVacaciones} refreshPage={manageSearch}/> */}
            <SolicitarVacaciones showModal={toggleCrearSolicitudVacaciones} refreshPage={manageSearch}/>
          </div>
        }
        {showModalVerSolicitudVacaciones && 
          <div className="modal-contactanos modal-response-ficha-background">
            <VerSolicitudVacaciones showModal={toggleVerSolicitudVacaciones} idSolicitudMovimiento={idSolicitudMovimiento}/>
          </div>
        }
        {showModalEliminarSolicitudVacaciones && 
          <div className="modal-contactanos modal-response-ficha-background">
            <EliminarSolicitudVacaciones showModal={toggleEliminarSolicitudVacaciones} refreshPage={manageSearch} idSolicitudMovimiento={idSolicitudMovimiento} periodoMovimiento={periodoMovimiento} estadoMovimiento={estadoMovimiento}/>
          </div>
        }
        {showModalAgregarNotaSolicitudVacaciones && 
            <div className="modal-contactanos modal-response-ficha-background">
              <AgregarNotaSolicitudVacaciones showModal={toggleAgregarNotaSolicitudVacaciones} refreshPage={manageSearch} idSolicitudMovimiento={idSolicitudMovimiento} nombreEmpleado={nombreEmpleado}/>
            </div>
          }
        <Wrapper className="App">
          <div className="generic-buscador-container">
            <div></div>
            <div className="filtros-right-section">
              {extraData?.PuedeSolicitar &&
                <button
                onClick={toggleCrearSolicitudVacaciones}
                type="button"
                className="generic-button-buscador"
                >
                  Solicitar Vacaciones
                </button>
              }
            </div>
          </div>
          <div className="container-fluid body-content">
            <div className="table-responsive form-group-sm table-scroll pt-2">
              <table className="table table-hover">
                <thead className="listar-thead">
                  <tr>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">
                        Fch. de Registro
                      </div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Empleado</div>
                    </th>
                    <th className="col-2">
                      <FormSelect 
                        options={allAreas}
                        description={"Areas"}
                        selectedOptions={selectedAreas}
                        setSelectedOptions={setSelectedAreas}
                      />
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Tipo Mov.</div>
                    </th>
                    <th className="col-1">
                      <FormSelect 
                        options={allCargos}
                        description={"Cargos"}
                        selectedOptions={selectedCargos}
                        setSelectedOptions={setSelectedCargos}
                      />
                    </th>
                    <th className="col-1">
                      <FormSelect 
                        options={allEstados}
                        description={"Est. Proceso"}
                        selectedOptions={selectedEstados}
                        setSelectedOptions={setSelectedEstados}
                      />
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Est. registro</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Per. Vac.</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Cant.Días</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Días háb.</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Días No háb.</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Nivel Aprob.</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Aprobador</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Acciones</div>
                    </th>
                  </tr>
                </thead>
                <TableWebBody
                  filas={filas}
                  showModalVerSolicitudVacaciones={toggleVerSolicitudVacaciones}
                  showModalEliminarSolicitudVacaciones={toggleEliminarSolicitudVacaciones}
                  showModalAgregarNotaSolicitudVacaciones={toggleAgregarNotaSolicitudVacaciones}
                />
              </table>
              {!extraData?.PuedeSolicitar &&
                <div className='form-check-label ficha-declaracion-jurada-message'>
                  * Recuerda que cuentas con menos de {extraData?.TiempoMensaje} en Colegios Peruanos S.A., debido a ello la opción de solicitud de vacaciones se encuentra inhabilitada. Si tienes dudas, comunícate a compensaciones@innovaschools.edu.pe
                </div>
              }
              {totalPages > 1 && (
                  <Paginator totalItems={totalItems}
                      firsItemPage={firsItemPage}
                      lastItemPage={lastItemPage}
                      totalPages={totalPages}
                      page={page}
                      onLeftClic={onLeftClic}
                      onRightClic={onRightClic} />
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default ListaSolicitudes;