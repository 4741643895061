import styled from "styled-components";

export const DivInput = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  flex-direction: ${props => props.direction};

  > label {
    padding-right: 5px;
    padding-left: 5px;
    font-family: GothamLight;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0063a1;
  }

  ${props => props.direction === "row" && 
    `flex-wrap: wrap;
    
    > label{
      flex: 0 0 ${getColValue(props.colLabel)}%;
      max-width: ${getColValue(props.colLabel)}%;
      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);
      margin-bottom: 0;
      line-height: 1.5;
    }
    
    > div {
      flex: 0 0 ${getColValue(props.colInput)}%;
      max-width: ${getColValue(props.colInput)}%;
      padding-right: 5px;
      padding-left: 5px;
    }
    `
  }
`;

const getColValue = (value) => {
  if (value > 12) value = 12;
  const cols = 12;
  const percent = 100;
  return (value/cols)*percent;
}