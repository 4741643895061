import React, {useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {AuthContext} from '../../hooks/context/authContext';
import logo from '../../image/logo_menu.svg';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HamburgerIcon from '../icons/HamburgerIcon';
import NotificacionModal from "../notificacionModal/NotificacionModal";
import ContactanosModal from "../contactanosModal/ContactanosModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {Link, NavLink, useHistory} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import {tieneAccesoAMenu} from "../../pages/router/permisosUtils";
import { MI_TABLERO_ID_MENU, MENU_MIS_SOLICITUDES, MENU_COVID, MENU_HEADER_ITEMS_MAP, MIS_OPCIONES_ICON, GDH_TE_ESCUCHA_ICON, OTRAS_APLICACIONES_ICON } from "../../constants/hardCodedConstants";
import useClickOutside from "../../hooks/useClickOutside/useClickOutside";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {useManageGetRequest} from '../../hooks/useManageGetRequest/useManageGetRequest';
import {getMenuPrincipalAPI, getNotificacionesAPI, leerNotificationsAPI, getLinksInteresAPI, getConsultarEncuestaSaludPendienteAPI, getIdEncuentaSaludAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import RegistroVacunaModal from "../modalVacunas/RegistroVacunaModal";
import ModalDescargarConstanciaDeTrabajo from "../modalDescargarConstanciaDeTrabajo/ModalDescargarConstanciaDeTrabajo";
import FichaSintomatologicaModal from '../../pages/gestionFichaSintomatologica/modales/FichaSintomatologicaModal';
import RegistroEncuestaSaludModal from '../../components/encuestaSaludModal/RegistroEncuestaSaludModal';
import SolicitudDescuentoPension from '../../pages/misSolicitudes/solicitudDescuentoPension/SolicitudDescuentoPension';
import ModalEnabled from '../modalComponents/ModalEnabled';
import DetalleEncuestaSaludModal from '../../pages/encuestasDeSalud/modales/DetalleEncuestaSaludModal';
import SolicitudDescuentoVale from '../../pages/misSolicitudes/solicitudDescuentoVale/SolicitudDescuentoVale';
import { ModalCambioCuentaSueldo } from '../modalCambioCuentaSueldo/ModalCambioCuentaSueldo';

const MyNav = styled.nav`
  .navbar-brand {
    display: flex;
    align-items: center;
  }

  #userDropdown {
    display: flex;
    align-items: center;
  }
`;

const ContainerIcon = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    padding: 5px 8px;
    height: 38px;
  }
`;

const DropdownBox = styled.div`
    /* @media (max-height: 900px) { */
        overflow-y: scroll;
        max-height: ${({dropdownHeight}) => dropdownHeight}px;
    /* } */
`;

const Header = (props) => {
    const {state, dispatch} = useContext(AuthContext);
    let history = useHistory();

    const [modalEnable, setModalEnable] = useState({isEnable: false, component: React.Fragment});
    const [hideNotificationModal, setHideNotificationModal] = useState(true);
    const [hideRegistroVacunaModal, setHideRegistroVacunaModal] = useState(true);
    const [hideDescargarConstanciaDeTrabajoModal, setHideDescargarConstanciaDeTrabajoModal] = useState(true);
    const [hideRegistroEncuestaSaludModal, setHideRegistroEncuestaSaludModal] = useState(true);
    const [hideDetalleEncuestaSaludModal, setHideDetalleEncuestaSaludModal] = useState(true);
    const [hideCambioCuentaSueldoModal, setHideCambioCuentaSueldoModal] = useState(true);

    const [notificaciones, setNotificaciones] = useState([]);
    const [nuevasNotificaciones, setNuevasNotificaciones] = useState(0);
    const [menusALosQueTieneAcceso, setMenusALosQueTieneAcceso] = useState([]);
    const [linksInteres, setLinksInteres] = useState([]);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [executeManageGetRequest] = useManageGetRequest();

    const [idEncuentaSaludDetalle, setIdEncuentaSaludDetalle] = useState(0);

    const route = window.location.pathname;

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            initHeader();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    useDidMountEffect(() => {
        updateNotificaciones();
    }, [hideNotificationModal]);


    let domNodeModalNotificaciones = useClickOutside(() => {
        setHideNotificationModal(true);
    });

    useDidMountEffect(() => {
        initHeader();
    }, [state.askForNewNotifications]);

    const updateNotificaciones = async () => {
        console.debug("hideNotificationModal: ", hideNotificationModal);
        if (!hideNotificationModal) {
            console.debug("call read notification api");
            let response = await executePostRequest(leerNotificationsAPI(), {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
            await initHeader();
        }
    };

    const calcularNroDeNotificacionesNuevas = (notificacioness) => {
        let n = 0;
        for (let notificacion of notificacioness) {
            if (notificacion.EsNueva) {
                n++;
            }
        }
        setNuevasNotificaciones(n);
    };

    const initHeader = async () => {
        try {
            let response = await executeGetRequest(getNotificacionesAPI());
            if (responseCode200(response)) {
                setNotificaciones(response.data);
                calcularNroDeNotificacionesNuevas(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }

        await executeManageGetRequest(getLinksInteresAPI(), (res) => setLinksInteres(res.data), false);
        await obtenerMenus();
        await obtenerIdEncuenta();
    };

    const obtenerMenus = async () => {
        let response = await executeGetRequest(getMenuPrincipalAPI());
        if (responseCode200(response)) {
            setMenusALosQueTieneAcceso(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const obtenerIdEncuenta  = async () => {
        let response = await executeGetRequest(getIdEncuentaSaludAPI());

        if (responseCode200(response)) {
            setIdEncuentaSaludDetalle(response.data.IdEncuestaSaludDetalle);
        } else {
            setIdEncuentaSaludDetalle(0);
        }
    }

    const logout = () => {
        history.push('/login');
        dispatch({
            type: 'LOGOUT',
        });
    };

    const showNotifications = () => {
        console.debug("showNotifications");
        setHideNotificationModal(!hideNotificationModal);
    };

    const toggleRegistroVacunaModal = () => {
        setHideRegistroVacunaModal(!hideRegistroVacunaModal);
    };

    const toggleDescargarConstanciaDeTrabajoModal = () => {
        setHideDescargarConstanciaDeTrabajoModal(!hideDescargarConstanciaDeTrabajoModal);
    };

    const toggleRegistroEncuestaSaludModal = () => {
        setHideRegistroEncuestaSaludModal(!hideRegistroEncuestaSaludModal);
    };

    const toggleDetalleEncuestaSaludModal= () => {
        setHideDetalleEncuestaSaludModal(!hideDetalleEncuestaSaludModal);
    };

    const toggleCambioCuentaSueldoModal = () => {
        setHideCambioCuentaSueldoModal(!hideCambioCuentaSueldoModal);
    };

    const generateNavItem = ({ route, label, idAcceso }, index) => {
        return (
            tieneAccesoAMenu(menusALosQueTieneAcceso, idAcceso) &&
            <NavDropdown.Item as={NavLink} to={route} key={`${index}-${idAcceso}`}>
                <span className="font-gothan-light">{label}</span>
            </NavDropdown.Item>
        )
    }

    return (
        <>
            <MyNav
                id='app'
                className='navbar-expand-sm navbar-light bg-light menuSuperior'>
                <ContainerIcon onClick={props.openSidebar}>
                    <HamburgerIcon/>
                </ContainerIcon>
                <div className='d-flex justify-content-between navbar-top' id='navbarCollapse'>
                    <div className='navbar-nav'>
                        {tieneAccesoAMenu(menusALosQueTieneAcceso, MI_TABLERO_ID_MENU) &&
                            <div className="gdh-te-escucha">
                                <Link to='/' className={`nav-item  ${route === '/' && 'active'} menuTitulo`}>
                                        <img src={logo} height='25' alt='Innova Schools' className=" pl-3 pr-3"/>
                                </Link>
                            </div>
                        }

                        {MENU_HEADER_ITEMS_MAP.map((item, i) => {
                            if (tieneAccesoAMenu(menusALosQueTieneAcceso, item.idAcceso)){
                                return item.isDropdown 
                                    ? <MENU_OPTION_DROPDOWN windowRoute={route} item={item} generateNavItem={generateNavItem} key={item.idAcceso}/>
                                    : <MENU_OPTION windowRoute={route} item={item} key={item.idAcceso}/>
                            }
                            return null;
                        })}

                        {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.menuId) &&
                        <div className="button-main-menu-drop-down">
                            <NavDropdown className='button-main-menu-drop-down-anchor' id="basic-nav-dropdown" title={<span className={
                                `nav-custom-title-boostrap-my-custom-restyle font-gothan-bold d-flex align-items-center h-100
                                ${route === '/MisRenuncias' && 'white-border-bottom-button-menu'}
                                ${route === '/MisNuevosIngresos' && 'white-border-bottom-button-menu'}
                                ${route === '/gestionReservas' && 'white-border-bottom-button-menu'}
                                ${route === '/politicasYreglamentos' && 'white-border-bottom-button-menu'}
                                ${route === '/MiCierreAnio' && 'white-border-bottom-button-menu'}
                                ${route === '/seguimientoColaboradores' && 'white-border-bottom-button-menu'}
                                ${route === '/misActividadesPendientes' && 'white-border-bottom-button-menu'}
                                ${route === '/misVacaciones' && 'white-border-bottom-button-menu'}
                                ${route === '/misTraslados' && 'white-border-bottom-button-menu'}
                                ${route === '/misCapacitaciones' && 'white-border-bottom-button-menu'}
                                ${route === '/misCuponesTiempoLibre' && 'white-border-bottom-button-menu'}
                                ${route === '/misCuponesTiempoLibre/crearSolicitud' && 'white-border-bottom-button-menu'}
                                ${route === '/miRegistroCondicion' && 'white-border-bottom-button-menu'}
                                `}>
                                    <img src={MIS_OPCIONES_ICON} alt="logoMisOpciones" className='header-img-size' />
                                    Mis Opciones</span>}>
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.vacunas) &&
                                <NavDropdown.Item onClick={toggleRegistroVacunaModal}><span className="font-gothan-light">Registro Vacuna COVID-19</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.renuncias) &&
                                <NavDropdown.Item as={NavLink} to="/MisRenuncias"><span className="font-gothan-light">Gestión de Renuncias</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.descargarConstanciaDeTrabajo) &&
                                <NavDropdown.Item onClick={toggleDescargarConstanciaDeTrabajoModal}><span className="font-gothan-light">Descargar constancia de trabajo</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.misActividades) &&
                                <NavDropdown.Item as={NavLink} to="/misActividadesPendientes"><span className="font-gothan-light">Mis actividades Pendientes</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.misReservas) &&
                                <NavDropdown.Item as={NavLink} to="/gestionReservas"><span className="font-gothan-light">Mis reservas</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.politicasYReglamentos) &&
                                <NavDropdown.Item as={NavLink} to="/politicasYreglamentos"><span className="font-gothan-light">Políticas y reglamentos</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.siteDirectores) &&
                                <NavDropdown.Item as='a' href="https://sites.google.com/colegiosperuanos.edu.pe/paradirectores" target='_blank'><span className="font-gothan-light">Site Directores</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.cierreDeAnio) &&
                                <NavDropdown.Item as={NavLink} to="/MiCierreAnio"><span className="font-gothan-light">Contratos</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.seguimientoColaboradores) &&
                                <NavDropdown.Item as={NavLink} to="/seguimientoColaboradores"><span className="font-gothan-light">Seguimiento colaboradores</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.MisVacaciones) &&
                                <NavDropdown.Item as={NavLink} to="/misVacaciones"><span className="font-gothan-light">Mis Vacaciones</span></NavDropdown.Item>
                                }
                                {/* {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.MisTraslados) &&
                                <NavDropdown.Item as={NavLink} to="/misTraslados"><span className="font-gothan-light">Mis Traslados</span></NavDropdown.Item>
                                } */}
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.solicitarDescuentoPension) &&
                                <NavDropdown.Item onClick={() => setModalEnable({isEnable: true, component: SolicitudDescuentoPension})}><span className="font-gothan-light">Solicitar Descuento Pensión</span></NavDropdown.Item>
                                } 
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.MisValesTaiLoy) &&
                                <NavDropdown.Item onClick={() => setModalEnable({ isEnable: true, component: SolicitudDescuentoVale })}><span className="font-gothan-light">Solicitud vale Tai Loy</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.misCapacitaciones) &&
                                <NavDropdown.Item as={NavLink} to="/misCapacitaciones"><span className="font-gothan-light">Mis capacitaciones</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.miFichamedica) &&
                                <NavDropdown.Item onClick={idEncuentaSaludDetalle > 0 ? toggleDetalleEncuestaSaludModal : toggleRegistroEncuestaSaludModal}><span className="font-gothan-light">Mi Ficha Médica</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.MisCuponesTiempoLibre) &&
                                <NavDropdown.Item as={NavLink} to="/misCuponesTiempoLibre"><span className="font-gothan-light">Mis cupones de tiempo libre</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_MIS_SOLICITUDES.childMenuIds.CambioCuentaSueldo) &&
                                <NavDropdown.Item onClick={toggleCambioCuentaSueldoModal}><span className="font-gothan-light">Cambio de cuenta sueldo</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_MIS_SOLICITUDES.childMenuIds.MisRegistrosMaternidad) &&
                                <NavDropdown.Item as={NavLink} to="/miRegistroCondicion"><span className="font-gothan-light">Mi registro de maternidad</span></NavDropdown.Item>
                                }
                            </NavDropdown>
                        </div>
                        }

                        { /*tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_COVID.menuId) &&
                        <div className="button-main-menu-drop-down">
                            <NavDropdown className='button-main-menu-drop-down-anchor' id="basic-nav-dropdown" title={<span className={
                                `nav-custom-title-boostrap-my-custom-restyle font-gothan-bold d-flex align-items-center h-100                     
                                ${route === '/gestionFichaSintomatologica' && 'white-border-bottom-button-menu'}
                                ${route === '/checkRetornoClases' && 'white-border-bottom-button-menu'}    
                                ${route === '/seguimientoCasosCovid' && 'white-border-bottom-button-menu'}
                                `}>
                                    <img src={COVID_ICON} alt="logoCovid" className='header-img-size' />
                                    Covid </span>}>
                                {tieneAccesoAMenu(menusALosQueTieneAcceso, MENU_COVID.childMenuIds.fichaSintomatologica) &&
                                <NavDropdown.Item onClick={props.toggleFichaSintomatologicoModal}><span className="font-gothan-light">Ficha sintomatológica</span></NavDropdown.Item>
                                }
                                {tieneAccesoAMenu(menusALosQueTieneAcceso,  MENU_COVID.childMenuIds.retornoClases) &&
                                <NavDropdown.Item as={NavLink} to="/checkRetornoClases"><span className="font-gothan-light">Retorno a clases 2023</span></NavDropdown.Item>
                                }
                            </NavDropdown>
                        </div>*/
                        }
                        
                    </div>
                    <div className='navbar-nav menuRightMob d-flex align-items-center'>
                        <div className='button-main-menu-text mr-1'>
                            <div className="header-height-size">
                                <a className='menuTitulo hand-pointer gdh-te-escucha-text px-2' onClick={() => props.toggleContactanosModal()}>
                                    <img src={GDH_TE_ESCUCHA_ICON} alt="logoGDH" className='header-img-size mr-1' />
                                    GDH te escucha
                                </a>
                            </div>
                        </div>
                        <div ref={domNodeModalNotificaciones} className="mr-3 gdh-te-escucha pl-1 pr-1">
                            <a className='menuTitulo hand-pointer' onClick={() => showNotifications()}>
                                <FontAwesomeIcon icon='bell' className="text-white" size="lg"/>
                            </a>
                            <div id="notifications" className="notifications" hidden={hideNotificationModal}>
                                {notificaciones && notificaciones.map((n) => (<NotificacionModal notificacion={n} key={n.IdNotificacion}/>))}
                            </div>
                        </div>

                        {nuevasNotificaciones != 0 &&
                        <p className="notificacion-new-notifications circle-shape">{nuevasNotificaciones}</p>
                        }

                        <a
                            className='dropdown-toggle text-white mr-2'
                            href='#'
                            id='userDropdown'
                            role='button'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'>

                            <img className='img-profile rounded-circle fotoPrincipal' src={state.user.photo}/>
                        </a>

                        <div
                            className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                            aria-labelledby='userDropdown'>
                            <a
                                className='dropdown-item font-gothan-light'
                                href="/tutoriales"
                                target="_blank">
                                <FontAwesomeIcon icon='question-circle'/>
                                &nbsp;&nbsp; Zona de Tutoriales
                            </a>
                            <a
                                onClick={() => logout()}
                                className='dropdown-item font-gothan-light'
                                href='#'
                                data-toggle='modal'
                                data-target='#logoutModal'>
                                <FontAwesomeIcon icon='sign-out-alt'/>
                                &nbsp;&nbsp; Cerrar sesión
                            </a>
                        </div>

                        <div className="header-vertical-line mr-1"></div>
                        <div className='user-enlaces-rapidos'>
                            <a
                                className=''
                                href='#'
                                id='userEnlacesRapidos'
                                role='button'
                                data-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                <img src={OTRAS_APLICACIONES_ICON} alt="logoApps" className='header-img-size'/>
                            </a>
                            <div className='dropdown-menu dropdown-menu-right shadow animated--grow-in'
                                aria-labelledby='userEnlacesRapidos'>
                                {linksInteres && linksInteres?.map(link => (
                                    <a
                                        className='dropdown-item font-gothan-light'
                                        href={link.Value}
                                        target="_blank"
                                        key={link.Key}
                                        >
                                        {link.Key}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </MyNav>

            <div id="modal-contactanos" className="modal-contactanos modal-response-ficha-background " hidden={props.hideContactanosModal}>
                <ContactanosModal showContactanosModal={props.toggleContactanosModal}/>
            </div>

            {hideRegistroVacunaModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
                <RegistroVacunaModal showModal={toggleRegistroVacunaModal}/>
            </div>
            }

            {hideDescargarConstanciaDeTrabajoModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
                <ModalDescargarConstanciaDeTrabajo showModal={toggleDescargarConstanciaDeTrabajoModal}/>
            </div>
            }

            {props.hideFichaSintomatologicaModal === false &&
            <div className="modal-contactanos modal-response-ficha-background">
                <FichaSintomatologicaModal showModal={props.toggleFichaSintomatologicoModal}/>
            </div>
            }

            <ModalEnabled modalEnabled={modalEnable} setModalEnabled={setModalEnable}/>
            
            {hideDetalleEncuestaSaludModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <DetalleEncuestaSaludModal showModal={toggleDetalleEncuestaSaludModal} idEncuesta={idEncuentaSaludDetalle}/>
                </div>
            }

            {hideRegistroEncuestaSaludModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroEncuestaSaludModal showModal={toggleRegistroEncuestaSaludModal} title={"Registro de Ficha Médica"} obtenerIdEncuenta ={obtenerIdEncuenta}/> 
                </div>
            }
            
            {hideCambioCuentaSueldoModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <ModalCambioCuentaSueldo 
                        showModal={toggleCambioCuentaSueldoModal}/>
                </div>
            }
        </>
    );
};

export default Header;

const MENU_OPTION = ({ windowRoute, item }) => {
    const { route, title, icon } = item;

    return (
    <div className="button-main-menu-text">
        <div className="header-height-size">
            <Link to={route} className={`nav-item pl-1 pr-2 ${windowRoute === route && 'active'} menuTitulo`}>
                <img src={icon} alt={`logo${route}`} className='header-img-size' />
                {title}
            </Link>
        </div>
    </div>)
}

const MENU_OPTION_DROPDOWN = ({ windowRoute, item, generateNavItem }) => {
    const { routeMap, title, icon } = item;

    const dropdownBox = useRef(null);
    const [dropdownHeight, setDropdownHeight] = useState(window.innerHeight - 100);

    
    const handleResize = () => {
        setDropdownHeight(window.innerHeight - 100);
    }

    useEffect(() => {   
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
    <div className="button-main-menu-drop-down">
        <NavDropdown ref={dropdownBox} className='button-main-menu-drop-down-anchor' id="basic-nav-dropdown" title={
            <span className={`nav-custom-title-boostrap-my-custom-restyle font-gothan-bold d-flex align-items-center h-100 ${routeMap?.map(o => o.route).includes('/'+windowRoute.split('/')?.at(1)) && 'white-border-bottom-button-menu'}`}>
                <img src={icon} alt={`logo${title}`} className='header-img-size' />
                {title}
            </span>}>
            <DropdownBox dropdownHeight={dropdownHeight}>
                {routeMap?.map(({ route, label, idAcceso }, index) => 
                    generateNavItem({ route, label, idAcceso }, index)
                )}
            </DropdownBox>
        </NavDropdown>
    </div>)
}