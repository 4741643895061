import axios from 'axios';
import { toast } from "react-toastify";
import { obtenerExtensionDeArchivo } from "../helper/utils";
import { mostrarMensajeDeErrorConToast } from "./httpRequiestsUtils";

const urlApiBackend = process.env.REACT_APP_URL_API_BACKEND;
const environment = process.env.REACT_APP_ENVIRONMENT_NAME;

export const validateLogin = (email, photo) => {
    var jsonData = {
        Username: email,
        ProfileImageUrl: photo,
    };
    return axios.post(
        urlApiBackend + 'Home/Login',
        jsonData,
        {
            headers: {
                'content-type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
            }
        }).catch(err => err);
};

const delay = ms => new Promise(res => setTimeout(res, ms));
export const uploadFile = async (fileData, token) => {
    let formData = new FormData();
    formData.append("file", fileData);
    return axios.post(`${urlApiBackend}Home/UploadFile`, formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        },
    });
};

export const uploadMultipleFiles = async (formData, api, token) => {
    return axios.post(`${urlApiBackend}Home${api}`,
        formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        }
    }
    ).then(function () {
        toast.success("El archivo se cargó exitosamente");
    })
        .catch(function () {
            toast.error("Hubo un error al subir el archivo");
        });
}

export const downloadFile = (token, path, fileName, useMock = false) => {
    console.debug(`donwload file, path: ${path} fileName: ${fileName}`);
    toast.info("Descargando archivo");
    if (useMock) {
        downloadResponse(path, fileName);
        return { status: 200, data: { FilePath: path } };
    }
    return axios.request({
        url: `${urlApiBackend}Home/DownloadFile?Path=${path}&OriginalFileName=${fileName}`,
        method: 'GET',
        responseType: 'blob',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
            Authorization: `Bearer ${token}`
        }
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        downloadResponse(url, fileName);
        return response;
    }).catch(async (error) => {
        try {
            let responseObj = JSON.parse(await error.response.data.text());
            toast.error(responseObj?.Message ?? "Hubo un error al descargar el archivo :(");
            return error;
        } catch (e) {
            return error;
        }
    });
};

const downloadResponse = (url, fileName) => {
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);

    link.click();
    link.remove();
}


