import React, { useEffect, useState } from 'react'
import TablePopup from './TablePopup';
import CrearPopup from './modales/CrearPopup';
import PageHeader from '../../components/pageHeader/PageHeader';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import PageButtons from '../../components/pageComponents/PageButtons';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import { gestionPopupIndexAPI } from '../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';


const GestionPopup = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = gestionPopupIndexAPI();;
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Listar Popup" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons hasCreateButton handleCreateButton={() => setModalEnabled({ isEnable: true, component: CrearPopup })} />
      </SearchContainer>
      <TablePopup formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator}/>
    </PageWrapper>
  )
}

export default GestionPopup