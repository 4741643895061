import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const CustomInfoTooTip = ({text}) => {
    return (
        <>
                <p data-tip data-for="info-tool-tip" style={{display: 'inline'}}>
                    <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                </p>
                <ReactTooltip id="info-tool-tip" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                    {text}
                </ReactTooltip>
        </>
    )
};

export default CustomInfoTooTip;