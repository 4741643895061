import React, { useState } from 'react'
import Select from "react-select";
import { INPUT_DIRECTION } from '../../constants/hardCodedConstants';
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../helper/utils';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';

const InputSelectCascadePost = ({
  label,
  value,
  onChange,
  name,
  sectionName,
  dependendOption = {},
  api,
  isRequired,
  isDisabled,
  direction = "row",
  placeholder = "Seleccionar",
}) => {

  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  const [dropdowns, setDropdowns] = useState([]);
  const [prevDependendOption, setPrevDependendOption] = useState({});

  const [executePost] = useManagePostRequest();

  const getDropdowns = async () => {;
    try {
      await executePost(api(), dependendOption, successGetCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const successGetCallback = ({ data }) => {
    setDropdowns(mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(data));
  }

  const areAllObjectValuesSet = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
      return false;
    }
    
    const values = Object.values(obj);
    return values.every((value) => value !== undefined && value !== null);
  };

  const isDependendOptionUnchanged = () => {
    for (const key in dependendOption) {
      if (dependendOption[key] !== prevDependendOption[key]) {
        return false;
      }
    }
    return true;
  };

  useDidMountEffect(() => {
    setPrevDependendOption(dependendOption);
    if (areAllObjectValuesSet(dependendOption) && !isDependendOptionUnchanged()) getDropdowns();
  }, [dependendOption])

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        <Select
          options={dropdowns ?? []}
          value={value ?? ""}
          onChange={handleOnChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition='fixed' // check
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  )
}

export default InputSelectCascadePost