import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericModal from '../../../components/modal/GenericModal';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { AuthContext } from '../../../hooks/context/authContext';
import { AlertErrorMaxPdfFileSizeMessage } from '../../../components/alerts/AlertErrorMaxFileSizeMessage';
import { downloadFile } from '../../../consumers/backendConsumer';
import { getActividadFileAPI, saveEncuestaTeletrabajoAPI } from '../../../consumers/backendApisUrls';
import { clearAllInputValidationMessages } from '../../../validations/FormValidatorUtils';
import styled from 'styled-components';

const EncuestaTeletrabajo = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {

  const { state } = useContext(AuthContext);

  const KEYS = ["File"];
  const SECTION_NAME = "modal_EncuestaTeletrabajo";
  const UPDATE_SUSTENTO_INPUT_FILE = SECTION_NAME + "-file";
  const EXTENSIONES_VALIDAS = ["pdf"];

  const [downloadingFile, setDownloadingFile] = useState(false);
  const [sendingFile, setSendingFile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});

  const [executeGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    setSendingFile(true);
    try {
      let api = saveEncuestaTeletrabajoAPI();
      let payload = getPayload();
      await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setSendingFile(false);
  };

  const getPayload = () => {
    return { IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
             File: s3UploadFileResponse}
  }

  const successSubmitCallback = (res) => {
    manageSearch();
    showModal();
  }

  const handleDownloadFile = async (event) => {
    event.preventDefault();
    setDownloadingFile(true);
    try {
      const api = getActividadFileAPI(IdEmpleadoFlujoActividad);
      await executeGetRequest(api, successGetFileCallback);
    } catch (error) {
      console.error(error);
    }
    setDownloadingFile(false);
  };

  const successGetFileCallback = (res) => {
    downloadFile(
      state.token,
      res.data.KeyFile,
      res.data.NombreOriginal
    );
  }

  useEffect(() => {
    if(s3UploadFileResponse) clearAllInputValidationMessages(KEYS, SECTION_NAME);
  }, [s3UploadFileResponse])

  return (
    <GenericModal showModal={showModal} width={"830px"} titulo="Encuesta teletrabajo">
      <div className="margin-modal-body">
        <form onSubmit={onSubmit}>
         <div className="row ml-1 mr-2 mb-3 blue-standard-color">
            Paso 3 - Completa la Encuesta<br></br>
            En base a la información recibida en los pasos anteriores, evalúa tu espacio de trabajo y completa la información solicitada en la presente encuesta. Finalmente escanea en formato PDF y carga el archivo en la intranet mediante el botón "Subir Archivo". 
           </div>
          <div className="row custom-form-group-modal">
            <label className="col-sm-5 col-form-label modal-label">
              Cargar documento de encuesta<span className="required">*</span>
            </label>
            <div className="col-sm-7 d-flex flex-column justify-content-center">
              <GenericUploadFileV2
                uploadFileToS3={setS3UploadFileResponse}
                id={UPDATE_SUSTENTO_INPUT_FILE}
                textButton={"Subir Archivo"}
                extensionesValidas={EXTENSIONES_VALIDAS}
                ToastArchivoNoValido={AlertErrorMaxPdfFileSizeMessage}
                subiendoArchivoPublicState={uploadingFile}
                setSubiendoArchivoPublicState={setUploadingFile}
              />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-File-validation-message`}/>
            </div>
          </div>
          <div className="row custom-form-group-modal">
            <a className="col-sm-6 text-link-appereance" href="#" onClick={handleDownloadFile} disabled={downloadingFile}>
              <FontAwesomeIcon icon="download" /> Encuesta teletrabajo
            </a>
          </div>
          <div className="modal-footer">
            <div className="message font-gothan-light w-75">
              <strong>
                <p className='mb-0'>* Campos obligatorios</p>
                <p className="mb-0">* El formato permitido es un archivo .pdf de máximo 8MB.</p>
              </strong>
            </div>
            <Button className="generic-button-buscador" type="submit" 
              boolButton={(uploadingFile || sendingFile)}
              disabled={uploadingFile || sendingFile}>
              Guardar 
                {sendingFile && 
                  <> &nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/>
                  </>
                }
            </Button>
          </div>
        </form>
      </div>
    </GenericModal>
  )
}

export default EncuestaTeletrabajo

const Button = styled.button`
  background-color: ${props => props.boolButton ? "#92c0e8" : ""};
  pointer-events: ${props => props.boolButton ? "none" : "auto"};
`;