import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const TableWebBodyRoles = ({roles, showEditarRolModal, showMantenimientoRolModal}) => {
    return (
        <tbody className="listar-tbody">
        {roles.map((rol, i) => (
            <tr key={i}>
                <td>{rol.IdRol}</td>
                <td>{rol.Nombre}</td>
                <td>{rol.Descripcion}</td>
                <td>{rol.Estado}</td>
                <td style={{width: '150px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <div style={{display: 'flex', marginLeft: '5px', marginRight: '25px'}}>
                            <FontAwesomeIcon icon='edit' transform="left-1" className="hand-pointer blue-standard-color" onClick={() => showEditarRolModal(rol.IdRol)} title={"Editar Rol"}/>
                        </div>
                        <div style={{display: 'flex', marginRight: '5px'}}>
                            <FontAwesomeIcon icon='file-alt' transform="left-1" className="hand-pointer blue-standard-color" onClick={() => showMantenimientoRolModal(rol.IdRol, rol.Nombre)} title={"Mantenimiento Rol"}/>
                        </div>
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyRoles;
