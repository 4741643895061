import React, {useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import {useForm} from '../../../hooks/useForm/UseForm';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {getLiquidacionesGetEditarColaboradorAPI, getTiposDocumentoAPI, liquidacionesSaveEditarColaboradorAPI} from "../../../consumers/backendApisUrls";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";
import SelectInput from "../../../components/Select/SelectInput";
import {useGetOptionsSelect} from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";

const EditarLiquidacion = ({toggleModal, refreshTable, idEmpleadoLiquidaciones}) => {
    const sectionName = "EditarLiquidacion";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();
    const [manageGetRequest] = useManageGetRequest();

    const [tiposDocumentos, setTiposDocumentos] = useState([]);
    const [jsonSelectedTipoDocumento, setJsonSelectedTipoDocumento] = useState({});
    const [getOptions] = useGetOptionsSelect();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const successGetEditarProcesoCierre = function (response) {
        setFormValues(response.data);
        setJsonSelectedTipoDocumento({value: response.data.IdTipoDocumento, label: response.data.NombreTipoDocumento});
    }

    const getInitialData = async () => {
        setTiposDocumentos(await getOptions(getTiposDocumentoAPI()));
        await manageGetRequest(getLiquidacionesGetEditarColaboradorAPI(idEmpleadoLiquidaciones), successGetEditarProcesoCierre, false);
    }

    const successSubmitCallback = function () {
        refreshTable();
        toggleModal();
    }

    const submit = async () => {
        let payload = armarPayload();
        let api = liquidacionesSaveEditarColaboradorAPI(idEmpleadoLiquidaciones)
        let keys = ["IdEmpleadoLiquidaciones", "NombreCompleto", "IdTipoDocumento", "NumeroDocumento", "CorreoPersonal"];

        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const armarPayload = () => {
        let payload =
            {
                "IdEmpleadoLiquidaciones": idEmpleadoLiquidaciones,
                "NombreCompleto": formValues?.NombreCompleto,
                "IdTipoDocumento": jsonSelectedTipoDocumento.value ?? null,
                "NumeroDocumento": formValues?.NumeroDocumento,
                "CorreoPersonal": formValues?.CorreoPersonal
            }

        return payload;
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"800px"} titulo={"Editar Liquidación"}>
                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Nombre completo<span className="required">*</span></label>
                            <div className="col-sm-9 black-color">
                                <input type="text" className="form-control" name="NombreCompleto" onChange={handleChange} value={formValues?.NombreCompleto ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NombreCompleto-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Tipo documento<span className="required"></span></label>
                            <div className="col-sm-9 black-color">
                                <SelectInput options={tiposDocumentos} initialValue={formValues?.IdTipoDocumento} jsonSelectedValue={jsonSelectedTipoDocumento} setJsonSelectedValue={setJsonSelectedTipoDocumento} placeholder={"Seleccionar"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdTipoDocumento-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Numero Documento<span className="required">*</span></label>
                            <div className="col-sm-9 black-color">
                                <input type="text" className="form-control" name="NumeroDocumento" onChange={handleChange} value={formValues?.NumeroDocumento ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NumeroDocumento-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Correo Personal<span className="required">*</span></label>
                            <div className="col-sm-9 black-color">
                                <input type="text" className="form-control" name="CorreoPersonal" onChange={handleChange} value={formValues?.CorreoPersonal ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-CorreoPersonal-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default EditarLiquidacion;