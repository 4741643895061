import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toast} from "react-toastify";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {activarUsuarioCallApiAPI, desactivarUsuarioCallApiAPI} from "../../consumers/backendApisUrls";
import {ESTADOS_ROLES} from "../../constants/hardCodedConstants";

const TableWebBodyUsuarios = ({users, showEditarRolModal, manageSearch}) => {
    const [executePostRequest] = usePostRequest();

    const desactivarUsuario = async function (IdUsuario) {
        let payload = {
            "IdUsuario": IdUsuario
        };

        const response = await executePostRequest(desactivarUsuarioCallApiAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            manageSearch();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const activarrUsuario = async function (IdUsuario) {
        let payload = {
            "IdUsuario": IdUsuario
        };

        const response = await executePostRequest(activarUsuarioCallApiAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            manageSearch();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <tbody className="listar-tbody">
        {users.map((user, i) => (
            <tr key={i}>
                <td>{user.IdUsuario}</td>
                <td>{user.Usuario}</td>
                <td>{user.Nombre}</td>
                <td>{user.Sede}</td>
                <td>{user.Departamento}</td>
                <td>{user.Estado}</td>
                <td style={{width: '150px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                        <div style={{display: 'flex', marginLeft: '5px', marginRight: '25px'}}>
                            <FontAwesomeIcon icon='edit' transform="left-1" className="hand-pointer blue-standard-color" title={`Editar Roles de '${user.Nombre}'`} onClick={() => showEditarRolModal(user.IdUsuario, user.Nombre)}/>
                        </div>
                        <div style={{display: 'flex', marginRight: '5px'}}>
                            {user?.IdEstado === ESTADOS_ROLES.Activo.id &&
                            <FontAwesomeIcon icon='user-times' transform="left-1" className="hand-pointer blue-standard-color" title={`Desactivar al usuario de '${user.Nombre}'`} onClick={() => desactivarUsuario(user.IdUsuario)}/>
                            }
                            {user?.IdEstado === ESTADOS_ROLES.Inactivo.id &&
                            <FontAwesomeIcon icon='user-check' transform="left-1" className="hand-pointer blue-standard-color" title={`Activar al usuario de '${user.Nombre}'`} onClick={() => activarrUsuario(user.IdUsuario)}/>
                            }
                        </div>
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyUsuarios;
