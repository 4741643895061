import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { getAllSchedulesAPI } from '../../consumers/backendApisUrls';
import TableWebBody from './TableWebBody';
import PageHeader from '../../components/pageHeader/PageHeader';
import Wrapper from '../../components/atoms/DirectorioWrapper';

const GestionSchedule = () => {
  const [loading, setLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);
  const [modalEnable, setModalEnable] = useState({isEnable: false});

  const [executeGet] = useManageGetRequest()

  const getInitialData = async () => {
    setLoading(true);
    try {
      await executeGet(getAllSchedulesAPI(), (res) => setSchedules(res.data));
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getInitialData()
  }, [])

  if(loading)
      return (<div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
              </div>)

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {modalEnable.isEnable && 
          <div className="modal-contactanos modal-response-ficha-background">
            <modalEnable.component id={modalEnable.id} toggleModal={() => setModalEnable({isEnable: false})} manageSearch={getInitialData} title={modalEnable.title} enable={modalEnable.enable}/>
          </div>
        }
        <Wrapper className='App'>
          <PageHeader title={"Monitor Runner"}/>
          <div className='pt-2'>
            <div className="table-scroll">
              <table className='table table-hover'>
                <thead className="listar-thead">
                  <tr>
                    <th className='font-gothan-medium text-center'>Id</th>
                    <th className='col-sm-2 font-gothan-medium text-center'>ClassName</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Frecuencia</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Día</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Hora</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Día de semana</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Habilitado</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Ejecutando</th>
                    <th className='col-sm-2 font-gothan-medium text-center'>Última ejecución</th>
                    <th className='col-sm-1 font-gothan-medium text-center'>Fecha Fin</th>
                    <th className='col-sm-1 font-gothan-medium'>Opciones</th>
                  </tr>
                </thead>
                <TableWebBody filas={schedules} setModalEnable={setModalEnable}/>
              </table>
            </div>
            </div>
        </Wrapper>
      </div>
    </>
  )
}

export default GestionSchedule