import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { NavDropdown } from 'react-bootstrap';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { AuthContext } from '../../hooks/context/authContext';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader'
import SearchBar from '../../components/pageComponents/SearchBar';
import FormSelect from '../../components/atoms/Select';
import PageButtons from '../../components/pageComponents/PageButtons';
import PaginatorV2 from '../../components/pageComponents/PaginatorV2';
import SpinnerLoading from '../../components/pageComponents/SpinnerLoading';
import TableSelectSearch from '../../components/atoms/TableSelectSearch';
import ImportFileGenericModal from '../../components/modal/ImportFileGenericModal';
import TableWebBody from './TableWebBody';
import { ESTADOS, ROLES } from '../../constants/hardCodedConstants';
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles } from '../../helper/utils';
import { getNuevosIngresosIndexAPI, descargarExcelNuevosIngresosAPI, cargarNuevosIngresosAPI, descargarFormatoCargaNuevosIngresosAPI, getAllEstadosAPI, getAllCargosAPI, getAllSedesAPI, descargarExcelOfisisNuevosIngresosAPI, 
          getNuevosIngresosPrioridadesAPI, cargarFormatoEmpleadosSinCodigoOfisisAPI, descargarFormatoEmpleadosSinCodigoOfisisAPI, descargarExcelSoporteNuevosIngresosAPI } from '../../consumers/backendApisUrls';
import FormDatePicker from '../../components/atoms/DatePicker';
import { toast } from 'react-toastify';
import {v4 as uuidv4} from 'uuid';

const GestionNuevosIngresos = () => {

  const EXTENSIONES_VALIDAS = ["xls","xlsx"];
  const ID_IMPORTAR = "importar-nuevos-ingresos";
  const ID_IMPORTAR_OFISIS = "importar-nuevos-ingresos-ofisis";
  const KEYS_FILTERS = {EstadosFicha: "IdsEstadosFicha", EstadosDocumento: "IdsEstadosDocumento", EstadosEmpleado: "IdsEstadosEmpleado", Cargos: "IdsCargos", Sedes: "IdsSedes", Prioridad: "IdsPrioridades"};

  const { state } = useContext(AuthContext);

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [allFilters, setAllFilters] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);

  const [hasSistemasRol, setHasSistemasRol] = useState(false);
  const [hasAdminRol, setHasAdminRol] = useState(false);
  const [filterType, setFilterType] = useState(null);
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});

  const [fechaIngRealInicio, setFechaIngRealInicio] = useState(null);
  const [fechaIngRealFin, setFechaIngRealFin] = useState(null);

  const [uuidKey, setUuidKey] = useState(uuidv4());

  const [executePost] = useManagePostRequest();
  const [executeGet] = useGetRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const allFiltersPromises = () => {
    return [
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getNuevosIngresosPrioridadesAPI()),
    ]
  }

  const init = async () => {
    setLoading(true);
    const hasSistemasRol = usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.Sistemas.id, ROLES.SoporteSistemas.id]);
    const hasAdminRol = usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.SuperAdmin.id, ROLES.Seleccion.id, ROLES.CoodinadorGDH.id, ROLES.Compensaciones.id]);
    if (hasAdminRol) {
      setHasAdminRol(hasAdminRol)
    } else if (hasSistemasRol){
      setHasSistemasRol(hasSistemasRol);
      setFilterType(sistemasVerTodos);
    }

    try {
      await initialAllOptionPromises(allFiltersPromises, Object.values(KEYS_FILTERS), setAllFilters);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchByFechas = () => {
    if (!(fechaIngRealInicio && fechaIngRealFin)){
      toast.warn("Debe ingresar ambas fechas");
    }
    setSelectedFilters(prev => ({...prev, "FechaInicio": fechaIngRealInicio, "FechaFin": fechaIngRealFin}))

    //manageSearch();
  }

  const manageSearch = async () => {
    const api = getNuevosIngresosIndexAPI();
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria, ...filterType?.data, ...selectedFilters,};
    await executePost(api, payload, sucessManageSearchCallback, false);
  };

  const sucessManageSearchCallback = async (response) => {
    setFormValues(response.data.Items);
    setExtraData(response.data.ExtraData);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  const handleExportarFile = async () => {
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria, ...selectedFilters};
    await downloadAndDelete(descargarExcelNuevosIngresosAPI(), payload);
  }

  const handleExportarFileOfisis = async () => {
    await downloadAndDelete(descargarExcelOfisisNuevosIngresosAPI(), {});
  }

  const handleExportarFileSoporte = async () => {
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria, ...selectedFilters};
    await downloadAndDelete(descargarExcelSoporteNuevosIngresosAPI(), payload);
  }

  const limpiarBusqueda = () => {
    setSearchCriteria("");
    setPaginator(lastData => ({...lastData, CurrentPage: 1}));
    setSelectedFilters({});
    setFechaIngRealFin(null);
    setFechaIngRealInicio(null);
    setUuidKey(uuidv4())
  };

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [filterType, paginator.CurrentPage, selectedFilters]);

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useEffect(() => {
    init();
  }, []);

  if(loading) return <SpinnerLoading/>
  
  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnabled.isEnable &&
          <div className="modal-contactanos modal-response-ficha-background">
          <modalEnabled.component toggleModal={() => setModalEnabled({isEnable: false})} manageSearch={manageSearch} {...modalEnabled.data}/>
          </div>
        }
        <Wrapper className="App">
          <PageHeader title="Administración de nuevos ingresos"/>
          <div className='col-sm-4 mt-2 ml-2'>
            {!filterType && 
              <span className='required'>
                * Seleccione una vista para mostrar la información
              </span>
            }
            <Select
              options={allVistas[hasAdminRol ? "admin" : (hasSistemasRol ? "sistemas" : "colaborador")]}
              value={filterType ?? ""}
              onChange={(res) => setFilterType(res)}
              placeholder="Seleccione una vista de nuevo ingreso"
            />
          </div>
          <div className="generic-buscador-container">
            <SearchBar value={searchCriteria} onChange={setSearchCriteria} cleanSearch={limpiarBusqueda} manageSearch={manageSearch}/>
            {hasAdminRol && 
              <PageButtons >
                <div className="dropdown">
                  <button className="generic-button-buscador dropdown-toggle" type="button" id="dropdownMenuButtonNuevosIngresos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Acciones
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonNuevosIngresos">
                    <button type='button' className='dropdown-item hand-pointer' onClick={handleExportarFile}>Exportar</button>
                    <button type='button' className='dropdown-item hand-pointer' onClick={handleExportarFileOfisis}>Exportar Ofisis</button>
                    <button type='button' className='dropdown-item hand-pointer' onClick={() => setModalEnabled({isEnable: true, component: ImportFileGenericModal, data:{api: cargarNuevosIngresosAPI(), id: ID_IMPORTAR, validExtensions: EXTENSIONES_VALIDAS, hasDownloadFormat: true, apiDownloadFormat: descargarFormatoCargaNuevosIngresosAPI}})}>Importar</button>
                    {(filterType?.data?.CodigoOfisisEsNulo ?? false) && 
                      <button type='button' className='dropdown-item hand-pointer' onClick={() => setModalEnabled({isEnable: true, component: ImportFileGenericModal, data:{api: cargarFormatoEmpleadosSinCodigoOfisisAPI(), id: ID_IMPORTAR_OFISIS, validExtensions: EXTENSIONES_VALIDAS, hasDownloadFormat: true, apiDownloadFormat: descargarFormatoEmpleadosSinCodigoOfisisAPI}})}>Importar Colaboradores sin Ofisis</button>
                    }
                  </div>
                </div>
                <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Configuración</span>}>
                  <Link to="adminNuevosIngresos/configurarDocumentos" className='dropdown-item'>Documentos</Link>
                  <Link to="adminNuevosIngresos/configurarListaDocumentos" className='dropdown-item'>Listas de Documentos</Link>
                </NavDropdown>
                <Link to="adminNuevosIngresos/crearNuevoIngreso" className='btn generic-button-buscador'>Crear Nuevo</Link>
              </PageButtons>
            }
            { hasSistemasRol &&
                                <button
                                    onClick={handleExportarFileSoporte}
                                    type='button'
                                    className='generic-button-buscador'>
                                    Exportar Soporte
                                </button>
            }
          </div>
          <div className="container-fluid body-content">
            <div className="table-scroll pt-3">
              <table className="table table-hover">
                <thead className="listar-thead">
                  <tr className="font-gothan-medium">
                    {filterType?.value === FILTER_TYPE_STEP2 &&
                      <th className='pl-1'>
                        <FormSelect
                          options={handleFind(filters, KEYS_FILTERS.Prioridad)}
                          description="p"
                          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Prioridad)}
                          selectedOptions={selectedFilters[KEYS_FILTERS.Prioridad] ?? []}
                        />
                      </th>
                    }
                    <th className="text-center text-wrap px-1">Fecha Registro</th>
                    <th className="col-2 text-center">
                      <TableSelectSearch
                        width='300px'
                        options={handleFind(filters, KEYS_FILTERS.Cargos)}
                        description='Cargos'
                        setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Cargos)}
                      />
                    </th>
                    <th className="col-3 text-center">
                      <TableSelectSearch
                        width='250px'
                        options={handleFind(filters, KEYS_FILTERS.Sedes)}
                        description='Sedes'
                        setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Sedes)}
                      />
                    </th>
                    <th className="col-4 text-center"><div style={{width: "220px"}}>Nombre Completo</div></th>
                    {!hasSistemasRol && <th className="text-center text-wrap"><label className='m-0' >Fecha Ingreso</label></th>}
                    <th className="col-2 text-center">
                        <FormDatePicker
                          description={<label style={{ fontSize: "12px",color:"#212529" }}>Fecha Ingreso Real</label>}
                          fechaInicio={fechaIngRealInicio}
                          fechaFin={fechaIngRealFin}
                          setFechaInicio={setFechaIngRealInicio}
                          setFechaFin={setFechaIngRealFin}
                          searchByFechas={searchByFechas}
                          key={uuidKey}
                        />
                    </th>
                    <th className="col-2 text-center">Contacto</th>
                    {!hasSistemasRol &&
                      <>
                        <th className='text-center'>
                          <FormSelect
                            options={handleFind(filters, KEYS_FILTERS.EstadosFicha)}
                            description={<label className='text-wrap mb-0' style={{width: "35px"}}>Estado Ficha</label>}
                            setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EstadosFicha)}
                            selectedOptions={selectedFilters[KEYS_FILTERS.EstadosFicha] ?? []}
                          />
                        </th>
                        <th className='text-center text-wrap'>
                          <FormSelect
                            options={handleFind(filters, KEYS_FILTERS.EstadosDocumento)}
                            description={'Estado Doc'}
                            setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EstadosDocumento)}
                            selectedOptions={selectedFilters[KEYS_FILTERS.EstadosDocumento] ?? []}
                          />
                        </th>
                      </>
                    }
                    <th className='text-center'>
                      <FormSelect
                        options={handleFind(filters, KEYS_FILTERS.EstadosEmpleado)}
                        description={<label className='text-wrap mb-0' style={{width: "60px"}}>Estado Empleado</label>}
                        setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EstadosEmpleado)}
                        selectedOptions={selectedFilters[KEYS_FILTERS.EstadosEmpleado] ?? []}
                      />
                    </th>
                    {!hasSistemasRol && 
                      <th className="col-2 text-center">
                        <FormSelect
                          options={IdsPasos.filter(o => extraData.IdsPasos?.includes(o.Value))}
                          description={<label className='ml-3 mb-0'>Paso</label>}
                          setSelectedOptions={(res) => handleFilterSelected(res, "IdsPasos")}
                          selectedOptions={selectedFilters["IdsPasos"] ?? []}
                        />
                      </th>
                    }
                    <th className="col-2 text-center">Responsable</th>
                    <th className="col-2 text-center">
                      <FormSelect
                        options={IdsAsignacionEquipo.filter(o => extraData.IdsAsignacionEquipo?.includes(o.Value))}
                        description={<label className='ml-3 mb-0'>Asig. Equip.</label>}
                        setSelectedOptions={(res) => handleFilterSelected(res, "IdsAsignacionEquipo")}
                        selectedOptions={selectedFilters["IdsAsignacionEquipo"] ?? []}
                      />
                    </th>
                    <th className="col-1 text-center">Acciones</th>
                  </tr>
                </thead>
                <TableWebBody
                  filas={formValues}
                  setModalEnabled={setModalEnabled}
                  manageSearch={manageSearch}
                  isShowPriorityFilter={filterType?.value === FILTER_TYPE_STEP2}
                  hasAdminRol={hasAdminRol}
                  hasSistemasRol={hasSistemasRol}
                />
              </table>
              <PaginatorV2 paginator={paginator} setPaginator={setPaginator}/>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  )
}

export default GestionNuevosIngresos;

const estadoNull = 0;
const pasosCount = 8;
const IdsPasos = Array.from({ length: pasosCount }, (_, index) => ({ Text: `Paso ${index + 1}`, Value: index + 1 }));
const arrayWithoutValidado = [];
for(let i=1; i <= ESTADOS.NUEVO; i++){
  if(i === ESTADOS.VALIDADO) continue;
  arrayWithoutValidado.push(i);
}

const IdsAsignacionEquipo = [
  {Text: "Sí", Value: ESTADOS.SI },
  {Text: "No", Value: ESTADOS.NO },
  {Text: "Sin info", Value: estadoNull },
]

const IdsTipoVistaGenerico = [
  {label: "Ver Todos", value: 1, data: {IdsPasos: [], IdsEstadosFicha: [], IdsEstadosDocumento: [], IdsEstadosEmpleado: [], CodigoOfisisEsNulo: false}},
  {label: "En validación Ingreso", value: 2, data: {IdsPasos: [1, 2, 3], IdsEstadosFicha: [ESTADOS.PENDIENTE, ESTADOS.OBSERVADO, ESTADOS.EN_PROCESO, ESTADOS.REVISION], IdsEstadosDocumento: [], IdsEstadosEmpleado: [ESTADOS.ACTIVO, ESTADOS.NUEVO], CodigoOfisisEsNulo: false}},
  {label: "En validación Doc para Planilla", value: 3, data: {IdsPasos: [4, 5], IdsEstadosFicha: [], IdsEstadosDocumento: [], IdsEstadosEmpleado: [], CodigoOfisisEsNulo: false}},
  {label: "En validación Compensaciones", value: 4, data: {IdsPasos: [6], IdsEstadosFicha: arrayWithoutValidado, IdsEstadosDocumento: [], IdsEstadosEmpleado: [ESTADOS.ACTIVO, ESTADOS.NUEVO], CodigoOfisisEsNulo: false}},
  {label: "Pendiente Código Ofisis", value: 5, data: {IdsPasos: [], IdsEstadosFicha: [], IdsEstadosDocumento: [], IdsEstadosEmpleado: [ESTADOS.ACTIVO], CodigoOfisisEsNulo: true}},
  {label: "Desiste", value: 6, data: {IdsPasos: [], IdsEstadosFicha: [], IdsEstadosDocumento: [], IdsEstadosEmpleado: [ESTADOS.DESISTE], CodigoOfisisEsNulo: false}},
]

const sistemasVerTodos = {label: "Vista General", value: 11, data: {IdsAsignacionEquipo: [], EquiposAsignadosEsNulo: null}};
const IdsTipoVistaSistemas = [
  sistemasVerTodos,
  {label: "Pendiente definir Asignación Equipos", value: 12, data: {IdsAsignacionEquipo: [estadoNull], EquiposAsignadosEsNulo: null}},
  {label: "Pendiente definir Equipos Asignados", value: 13, data: {IdsAsignacionEquipo: [ESTADOS.SI], EquiposAsignadosEsNulo: true}},
]

const allVistas = {
  colaborador: IdsTipoVistaGenerico,
  sistemas: IdsTipoVistaSistemas,
  admin: [...IdsTipoVistaGenerico, ...IdsTipoVistaSistemas],
}

const FILTER_TYPE_STEP2 = 2;