import React, { useState, useEffect } from "react";
import GenericModal from "../../../../components/modal/GenericModal";
import { Spinner } from "react-bootstrap";
import { useForm } from "../../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../../hooks/useSubmitForm/useSubmitForm";
import SelectInput from "../../../../components/Select/SelectInput";
import GenericUploadFileV2 from "../../../../components/GenericUploadFile/GenericUploadFileV2";
import { useGetOptionsSelect } from "../../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import {
  agregarFormacionProfesionalAPI,
  getPaisesAPI,
  getTiposInstitucionAPI,
  getSectoresInstitucionAPI,
  getEspecialidadesCarreraAPI,
  getNivelesEstudioAPI,
  getEstadosFormacionAPI,
  getGradosObtenidosAPI,
  getEditarFormacionProfesionalAPI,
  saveEditarFormacionProfesionalAPI,
  getRelatedDataTypesAPI,
  getSugerenciasDeNombreInstitucionAPI,
  getSugerenciasDeNombreCarreraAPI,
} from "../../../../consumers/backendApisUrls";
import {
  jsonEstaVacio,
  jsonNoEstaVacio,
} from "../../../../helper/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import AutoSuggestTextInput from "../../../../components/AutoSuggestTextInput/AutoSuggestTextInput";
import { estadoFormacion } from "../../../../constants/states";
import useDidMountEffect from "../../../../hooks/useDidMountEffect/useDidMountEffect";
import { declaracionJurada } from "../../../../constants/messages";

function AgregarFormacionProfesional({
  toggleModal,
  empleadoId,
  setSubmitExecute,
  isEditar,
  formacionProfesional,
  setFormacionProfesional,
  puedoEditar = true
}) {
  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();
  const [manageGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const sectionName = "AgregarFormacionProfesional";
  const idUploadArchivo = "AgregarSustento";
  const keys = ["NombreInstitucion", "IdPaisInstitucion", "IdTipoInstitucion", "IdSectorInstitucion", "NombreCarrera", "IdEspecialidad", "IdNivelEstudio", "IdEstadoFormacion", "IdGradoObtenido", "FechaInicio", "FechaFin", "SustentoFile", "IsSetDeclaracionJurada"];

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [entroAlUseEffect, setEntroAlUseEffect] = useState(false);
  const [subiendoArchivos, setSubiendoArchivos] = useState(false);

  const [allPais, setAllPais] = useState([]);
  const [allTiposInstitucion, setAllTiposInstitucion] = useState([]);
  const [allSectorInstitucion, setAllSectorInstitucion] = useState([]);
  const [allEspecialidad, setAllEspecialidad] = useState([]);
  const [allNivelEstudio, setAllNivelEstudio] = useState([]);
  const [allEstadoFormacion, setAllEstadoFormacion] = useState([]);
  const [allGradoObtenido, setAllGradoObtenido] = useState([]);
  const [nombreInstitucionSelected, setNombreInstitucionSelected] = useState('');
  const [paisSelected, setPaisSelected] = useState({});
  const [tipoInstitucionSelected, setTipoInstitucionSelected] = useState({});
  const [sectorInstitucionSelected, setSectorInstitucionSelected] = useState({});
  const [nombreCarreraSelected, setNombreCarreraSelected] = useState('');
  const [especialidadSelected, setEspecialidadSelected] = useState({});
  const [nivelEstudioSelected, setNivelEstudioSelected] = useState({});
  const [estadoFormacionSelected, setEstadoFormacionSelected] = useState({});
  const [gradoObtenidoSelected, setGradoObtenidoSelected] = useState({});
  const [sustentoFile, setSustentoFile] = useState({});
  const [isSustentoFile, setIsSustentoFile] = useState(false);
  const [isFinalizado, setIsFinalizado] = useState(true);
  const [isGraduado, setIsGraduado] = useState(false);
  const [isNoCulminado, setIsNoCulminado] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) init();

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    let boolFechaFin = estadoFormacionSelected?.label == estadoFormacion.EnCurso.nombre
    setIsFinalizado(!boolFechaFin);
    let boolGraduado = estadoFormacionSelected?.label == estadoFormacion.Graduado.nombre;
    setIsGraduado(boolGraduado);
    !boolGraduado && setGradoObtenidoSelected({})
    let boolNoCulminado = estadoFormacionSelected?.label == estadoFormacion.NoCulminado.nombre;
    setIsNoCulminado(boolNoCulminado);
    if (boolNoCulminado) {
      setSustentoFile({}); 
      setIsSustentoFile(false);
    }
  }, [estadoFormacionSelected]);

  useEffect(() => {
    if (!isFinalizado) {
      setSustentoFile({});
      setFormValues({ ...formValues, FechaFin: '' })
    } else {
      setIsSustentoFile(true);
    }
  }, [isFinalizado]);

  useEffect(() => {
    if (sustentoFile?.Path) {
      setIsSustentoFile(true);
    } else {
      setIsSustentoFile(false);
    }
  }, [sustentoFile]);

  useDidMountEffect(() => {
    if (jsonNoEstaVacio(nivelEstudioSelected) && nivelEstudioSelected.value != null) {
      entroAlUseEffect && setGradoObtenidoSelected({ value: "" });
      getRelatedDataTypes(nivelEstudioSelected?.value);
    }
    setEntroAlUseEffect(true);
  }, [nivelEstudioSelected]);

  const init = async () => {
    setIsLoading(true);
    try {
      setAllPais(await getOptions(getPaisesAPI()));
      setAllTiposInstitucion(await getOptions(getTiposInstitucionAPI()));
      setAllSectorInstitucion(await getOptions(getSectoresInstitucionAPI()));
      setAllEspecialidad(await getOptions(getEspecialidadesCarreraAPI()));
      setAllNivelEstudio(await getOptions(getNivelesEstudioAPI()));
      setAllEstadoFormacion(await getOptions(getEstadosFormacionAPI()));
      isEditar &&
        (await manageGet(
          getEditarFormacionProfesionalAPI(
            formacionProfesional.IdEmpleadoFormacionProfesional
          ),
          successGetEditarCallBack
        ));
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const successSubmitCallback = function () {
    setSubmitExecute(true);
    toggleModal();
  };

  const submit = async () => {
    setDisableSubmitButton(true);

    let payload;
    let api;
    if (!isEditar) {
      api = agregarFormacionProfesionalAPI();
      payload = armarPayloadPOST();
      await submitForm(keys, api, payload, sectionName, successSubmitCallback);
    } else {
      api = saveEditarFormacionProfesionalAPI();
      payload = armarPayloadPUT();
      await submitForm(keys, api, payload, sectionName, successSubmitCallback);
    }

    setDisableSubmitButton(false);
  };

  const armarPayloadPOST = () => {
    let payload = {
      IdEmpleado: empleadoId,
      NombreInstitucion: nombreInstitucionSelected,
      IdPaisInstitucion: paisSelected?.value,
      IdTipoInstitucion: tipoInstitucionSelected?.value,
      IdSectorInstitucion: sectorInstitucionSelected?.value,
      NombreCarrera: nombreCarreraSelected,
      IdEspecialidad: especialidadSelected?.value,
      IdNivelEstudio: nivelEstudioSelected?.value,
      IdEstadoFormacion: estadoFormacionSelected?.value,
      IdGradoObtenido: gradoObtenidoSelected?.value,
      FechaInicio: formValues?.FechaInicio,
      FechaFin: formValues?.FechaFin,
      SustentoFile: sustentoFile,
      IsSetDeclaracionJurada: formValues?.DeclaracionJurada ?? false,
      IsSetFinalizado: isFinalizado,
      IsGraduado: isGraduado,
      IsNoCulminado: isNoCulminado,
    };
    return payload;
  };

  const armarPayloadPUT = () => {
    let payload = {
      IdEmpleadoFormacionProfesional:
        formacionProfesional.IdEmpleadoFormacionProfesional,
      NombreInstitucion: nombreInstitucionSelected,
      IdPaisInstitucion: paisSelected?.value,
      IdTipoInstitucion: tipoInstitucionSelected?.value,
      IdSectorInstitucion: sectorInstitucionSelected?.value,
      NombreCarrera: nombreCarreraSelected,
      IdEspecialidad: especialidadSelected?.value,
      IdNivelEstudio: nivelEstudioSelected?.value,
      IdEstadoFormacion: estadoFormacionSelected?.value,
      IdGradoObtenido: gradoObtenidoSelected?.value,
      FechaInicio: formValues?.FechaInicio,
      FechaFin: formValues?.FechaFin,
      SustentoFile: sustentoFile,
      IsSetDeclaracionJurada: formValues?.DeclaracionJurada ?? false,
      IsSetSustentoFile: isSustentoFile,
      IsSetFinalizado: isFinalizado,
      IsGraduado: isGraduado,
      IsNoCulminado: isNoCulminado,
    };
    return payload;
  };

  const successGetEditarCallBack = (response) => {
    let result = response?.data;
    setFormacionProfesional(result);
    setFormValues({
      FechaInicio: result.FechaInicio,
      FechaFin: result.FechaFin,
    });
    setNivelEstudioSelected(result.NivelEstudio);
    setGradoObtenidoSelected(result.GradoObtenido)
    setNombreInstitucionSelected(result.NombreInstitucion);
    setNombreCarreraSelected(result.NombreCarrera);
  };

  const handleJsonSelected = (
    selectedDataType,
    setSelectedDataType,
    getJSON
  ) => {
    if (isEditar && jsonEstaVacio(selectedDataType)) {
      setSelectedDataType(getJSON);
      return getJSON;
    } else {
      return selectedDataType;
    }
  };

  const getRelatedDataTypes = async (idParent) => {
    let api = getRelatedDataTypesAPI(idParent);
    setAllGradoObtenido(await getOptions(api));
  };

  if (isLoading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <GenericModal
      titulo={
        puedoEditar ? (isEditar
          ? "Editar Formación Profesional"
          : "Agregar Formación Profesional") : "Detalle Formación Profesional"
      }
      showModal={toggleModal}
      width={"950px"}
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Nombre Institución<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <AutoSuggestTextInput
                inputTextValue={nombreInstitucionSelected}
                setInputTextValue={setNombreInstitucionSelected}
                suggestionsPostApi={
                  getSugerenciasDeNombreInstitucionAPI
                }
                placeholder={"Máximo 100 caracteres"}
                maxlength={"100"}
                disabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-NombreInstitucion-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              País Institución<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allPais}
                jsonSelectedValue={handleJsonSelected(
                  paisSelected,
                  setPaisSelected,
                  formacionProfesional?.PaisInstitucion
                )}
                setJsonSelectedValue={setPaisSelected}
                placeholder={"Seleccionar"}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdPaisInstitucion-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Tipo de institución<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allTiposInstitucion}
                jsonSelectedValue={handleJsonSelected(
                  tipoInstitucionSelected,
                  setTipoInstitucionSelected,
                  formacionProfesional?.TipoInstitucion
                )}
                setJsonSelectedValue={setTipoInstitucionSelected}
                placeholder={"Seleccionar"}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdTipoInstitucion-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Sector de la institución<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allSectorInstitucion}
                jsonSelectedValue={handleJsonSelected(
                  sectorInstitucionSelected,
                  setSectorInstitucionSelected,
                  formacionProfesional?.SectorInstitucion
                )}
                setJsonSelectedValue={setSectorInstitucionSelected}
                placeholder={"Seleccionar"}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdSectorInstitucion-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Nombre Carrera<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <AutoSuggestTextInput
                inputTextValue={nombreCarreraSelected}
                setInputTextValue={setNombreCarreraSelected}
                suggestionsPostApi={
                  getSugerenciasDeNombreCarreraAPI
                }
                placeholder={"Máximo 100 caracteres"}
                maxlength={"100"}
                disabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-NombreCarrera-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Especialidad
              <FontAwesomeIcon
                icon="info-circle"
                transform="left-9"
                className="hand-pointer ficha-residencia-ver-info-icon"
                data-tip
                data-for="informacion-especialidad"
              />
            </label>
            <ReactTooltip
              id="informacion-especialidad"
              place="top"
              effect="solid"
              className="tooltip-style tooltip-change-witdh">
              Escoja la Especialidad solo si tiene una carrera asociada al sector Educación
            </ReactTooltip>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allEspecialidad}
                jsonSelectedValue={handleJsonSelected(
                  especialidadSelected,
                  setEspecialidadSelected,
                  formacionProfesional?.Especialidad
                )}
                setJsonSelectedValue={setEspecialidadSelected}
                placeholder={"Seleccione solo si tiene una carrera relacionada a Educación"}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdEspecialidad-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Nivel de Estudio<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allNivelEstudio}
                jsonSelectedValue={nivelEstudioSelected}
                setJsonSelectedValue={setNivelEstudioSelected}
                placeholder={"Seleccionar"}
                resetSelectedValue={false}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdNivelEstudio-validation-message`}
              ></p>
            </div>
          </div>

          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Estado Formación<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput
                options={allEstadoFormacion}
                jsonSelectedValue={handleJsonSelected(
                  estadoFormacionSelected,
                  setEstadoFormacionSelected,
                  formacionProfesional?.EstadoFormacion
                )}
                setJsonSelectedValue={setEstadoFormacionSelected}
                placeholder={"Seleccionar"}
                isDisabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdEstadoFormacion-validation-message`}
              ></p>
            </div>
          </div>
          {isGraduado &&
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Grado Obtenido<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <SelectInput
                  options={allGradoObtenido}
                  jsonSelectedValue={gradoObtenidoSelected}
                  setJsonSelectedValue={setGradoObtenidoSelected}
                  placeholder={"Seleccionar"}
                  resetSelectedValue={false}
                  isDisabled={!puedoEditar}
                />
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-IdGradoObtenido-validation-message`}
                ></p>
              </div>
            </div>
          }
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Fecha de Inicio<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <input
                type="date"
                className="form-control"
                name="FechaInicio"
                onChange={handleChange}
                value={formValues?.FechaInicio ?? ""}
                disabled={!puedoEditar}
              ></input>
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}
              ></p>
            </div>
          </div>

          {isFinalizado && (
            <>
              <div className="form-group row mb-0">
                <label className="col-sm-3 col-form-label modal-label">
                  Fecha de Finalización
                  <span className="required">*</span>
                </label>
                <div className="col-sm-9 black-color">
                  <input
                    type="date"
                    className="form-control"
                    name="FechaFin"
                    onChange={handleChange}
                    value={formValues?.FechaFin ?? ""}
                    min={formValues?.FechaInicio ?? ""}
                    disabled={!puedoEditar}
                  ></input>
                  <p
                    className="ficha-form-input-validation-message"
                    id={`ficha-edit-${sectionName}-FechaFin-validation-message`}
                  ></p>
                </div>
              </div>
              {puedoEditar && !isNoCulminado &&
                <div className="form-group row mb-0">
                  <label className="col-sm-3 col-form-label modal-label">
                    Sustento<span className="required">*</span>
                  </label>
                  <div className="col-sm-9 black-color">
                    <GenericUploadFileV2
                      id={idUploadArchivo}
                      extensionesValidas={["pdf", "png", "jpg"]}
                      uploadFileToS3={setSustentoFile}
                      textButton={"Subir archivo"}
                      subiendoArchivoPublicState={subiendoArchivos}
                      setSubiendoArchivoPublicState={setSubiendoArchivos}
                    >
                      {isEditar && formacionProfesional.SustentoFile && !isSustentoFile && (
                        <>
                          <p
                            data-tip
                            data-for="informacion-residencia-sustento-already-saved"
                            style={{ display: "inline" }}
                          >
                            <FontAwesomeIcon
                              icon="info-circle"
                              transform="left-9"
                              className="hand-pointer ficha-residencia-ver-info-icon"
                            />
                          </p>
                          <ReactTooltip
                            id="informacion-residencia-sustento-already-saved"
                            place="top"
                            effect="solid"
                            className="tooltip-style tooltip-change-witdh"
                          >
                            Usted ya tiene un adjunto guardado previamente por lo que
                            no es necesario que adjunte un nuevo archivo.
                          </ReactTooltip>
                        </>
                      )}
                    </GenericUploadFileV2>
                    <p
                      className="ficha-form-input-validation-message"
                      id={`ficha-edit-${sectionName}-SustentoFile-validation-message`}
                    ></p>
                  </div>
                  <div>
                    <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                      <strong>* El formato permitido es un .pdf, .jpg, .png de máximo 8MB.</strong>
                    </label>
                  </div>
                </div>}
            </>
          )}

          {puedoEditar &&
            <>
              <div className="form-check mb-0">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="DeclaracionJurada"
                  id="AceptaDeclaracionJurada"
                  checked={formValues?.DeclaracionJurada ?? false}
                  onChange={handleChange}
                ></input>
                <label
                  className="form-check-label ficha-declaracion-jurada-message"
                  htmlFor="AceptaDeclaracionJurada"
                >
                  <strong>
                    <p className={"mb-0"}>
                      {declaracionJurada}
                    </p>
                  </strong>
                </label>
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-IsSetDeclaracionJurada-validation-message`}
                ></p>
              </div>
            </>}
        </form>
      </div>

      {puedoEditar &&
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button
              className="btn btn-primary generic-button-ficha-modal"
              onClick={submit}
              disabled={disableSubmitButton || subiendoArchivos}
            >
              Guardar
            </button>
          </div>
        </div>
      }

    </GenericModal>
  );
}

export default AgregarFormacionProfesional;
