import React, { useState } from 'react';
import { useForm } from "../../hooks/useForm/UseForm";
import { enviarReporteNuevosIngresosAPI } from "../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from "../../validations/FormValidatorUtils";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import {toast} from "react-toastify";

const ReporteDeNuevosIngresos = () => {
    const seccionName = "ReporteDeNuevosIngresos";

    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({});

    const [handleChange] = useForm(formValues, setFormValues);
    const [executePostRequest] = usePostRequest();

    const descargarReporte = async () => {
        let payload = {
            "FechaInicio": formValues["FechaInicio"],
            "FechaFin": formValues["FechaFin"]
        };
        let allInputKeys = ["FechaInicio", "FechaFin"];
        setLoading(true);
        clearAllInputValidationMessages(allInputKeys, seccionName);

        let responseReporteGenerado = await executePostRequest(enviarReporteNuevosIngresosAPI(), payload);
        if (responseCode200(responseReporteGenerado)) {
            toast.success(responseReporteGenerado?.data?.Message);
        } else if (responseTieneModelState(responseReporteGenerado)) {
            mostrarMensajesDeValidacionEnCadaInput(responseReporteGenerado, allInputKeys, seccionName);
        } else {
            mostrarMensajeDeErrorConToast(responseReporteGenerado);
        }
        setLoading(false);
    }

    return (
        <>
            <div className='card-body'>
                <div id='ReporteNuevosIngresos'>
                    <div>
                        <div className="form-group row form-input-modal-contactanos reportes-label-container">
                            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Inicio<span className="required">*</span></label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control" name="FechaInicio" onChange={handleChange}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-FechaInicio-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row form-input-modal-contactanos reportes-label-container">
                            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Fin<span className="required">*</span></label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control" name="FechaFin" onChange={handleChange}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-FechaFin-validation-message`}></p>
                            </div>
                        </div>
                    </div>

                    <div className="reporte-datos-generales-dato-obligatorio">
                        <p>(<span className="required ">*</span>) Dato obligatorio</p>
                    </div>

                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={descargarReporte} disabled={loading}>{loading ? 'Enviando' : 'Enviar'} Reporte {loading ? '...' : ''}</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ReporteDeNuevosIngresos;