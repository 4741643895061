import React from 'react';

import {eliminarEmpleadoIdiomaAPI} from "../../../consumers/backendApisUrls";
import {useManagePostRequest} from "../../../hooks/useManagePostRequest/useManagePostRequest";
import GenericModal from "../../../components/modal/GenericModal";

const EliminarEmpleadoIdiomaModal = ({toggleCurrentModal, reFetchList, empleadoIdioma}) => {
    const [manageExecutePost] = useManagePostRequest();

    const handleDelete = async () => {
        let api = eliminarEmpleadoIdiomaAPI(empleadoIdioma?.IdEmpleadoIdioma);
        await manageExecutePost(api, {}, successDeleteCallBack)
    }

    const successDeleteCallBack = () => {
        reFetchList();
        toggleCurrentModal();
    }

    return (
        <GenericModal titulo={"Eliminar"} showModal={ toggleCurrentModal } width={"620px"}>
            <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    <p>
                        Se va a eliminar el
                        Idioma <strong>{empleadoIdioma?.IdiomaName} {empleadoIdioma?.NivelIdiomaName} </strong> con la
                        certificación <strong>{empleadoIdioma?.CertificacionIdiomaName}</strong>
                    </p>
                    <p className="mb-0">
                        ¿Está seguro de que desea eliminar este Idioma?
                    </p>
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end pt-0 pb-0">
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha mr-2" onClick={handleDelete}>
                        Eliminar
                    </button>
                    <button className="btn btn-primary generic-button-ficha" onClick={toggleCurrentModal}>
                        Cancelar
                    </button>
                </div>
            </div>
        </GenericModal>
    )
}

export default EliminarEmpleadoIdiomaModal;
