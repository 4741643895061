import React, { useContext } from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RechazoSolicitudModal from './modales/RechazoSolicitudModal'
import { AuthContext } from '../../../hooks/context/authContext'
import { downloadFile } from '../../../consumers/backendConsumer'
import ActionGenericModal from '../../../components/modal/ActionGenericModal'
import { aprobarSolicitudTaiLoyAPI, inactivarActivarSolicitudTaiLoyAPI } from '../../../consumers/backendApisUrls'
import GenericTooltip from '../../../components/GenericTooltip/GenericTooltip'

const TableWebBody = ({ filas, setModalEnabled }) => {

  const {state} = useContext(AuthContext);

  const handleActionRow = ({ fila, api, title, actionLabel }) => {
    const payload = {IdSolicitudTaiLoy: fila.IdSolicitudTaiLoy};
    const data = {api, title, actionLabel, payload, name: `${fila.NombresCompletos} - ${fila.Cargo} - ${fila.Vale}`};
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data });
  }

  return (
    <tbody className='listar-tbody'>
      {filas.map((fila, i) => (
        <tr key={`${fila.NumeroDocumento}-${i}`}>
          <td className='text-center'>{fila.NumeroDocumento}</td>
          <td className=''>{fila.NombresCompletos}</td>
          <td className='small'>{fila.Cargo}</td>
          <td className='small'>{fila.Sede}</td>
          <td className='text-center'>{fila.FechaSolicitud}</td>
          <td className='text-center'>{fila.Vale}</td>
          <td className='text-center'>{fila.EstadoSolicitud}</td>
          <td className=''>
            <div className='d-flex justify-content-center' style={{gap: "10px"}}>
              {fila.MotivoRechazo &&
                <GenericTooltip id={`${fila.IdSolicitudTaiLoy}-${i}`} text={fila.MotivoRechazo}/>
              }
              {fila.PuedeAprobar &&
                <FontAwesomeIcon
                  icon={faCheck}
                  className='blue-standard-color'
                  size='lg'
                  title='Aprobar'
                  onClick={() => handleActionRow({ fila, api: aprobarSolicitudTaiLoyAPI, title: "Aprobar", actionLabel: "aprobar" })}
                />
              }
              {fila.PuedeRechazar &&
                <FontAwesomeIcon
                  icon="times"
                  className='blue-standard-color'
                  size='lg'
                  title='Rechazar'
                  onClick={() => setModalEnabled({ isEnable: true, component: RechazoSolicitudModal, data: { IdSolicitudTaiLoy: fila.IdSolicitudTaiLoy } })}
                />
              }
              <FontAwesomeIcon
                icon={fila.PuedeActivar ? "circle-notch" : "ban"}
                className='blue-standard-color'
                size='lg'
                title={`${fila.PuedeActivar ? "Activar" : "Inactivar"}`}
                onClick={() => handleActionRow({ fila, api: inactivarActivarSolicitudTaiLoyAPI, title: `${fila.PuedeActivar ? "Activar" : "Inactivar"}`, actionLabel: `${fila.PuedeActivar ? "activar" : "inactivar"}` })}
              />
              {fila.AutorizacionFirmada?.Path &&
                <FontAwesomeIcon
                  icon="download"
                  className='blue-standard-color'
                  size='lg'
                  title='Descargar Archivo'
                  onClick={() => downloadFile(state.token, fila.AutorizacionFirmada.Path, fila.AutorizacionFirmada.OriginalFileName)}
                />
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody