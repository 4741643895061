import React from 'react';

const ArrowRight = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            >
            <path
                d='M-2.86102e-05 8.78482L-2.86102e-05 6.81513L12.0465 6.81513L6.52517 1.39846L7.95067 -2.47955e-05L15.9014 7.79998L7.95067 15.6L6.52517 14.2015L12.0465 8.78482L-2.86102e-05 8.78482Z'
                fill='white'
            />
        </svg>
    );
};

export default ArrowRight;
