import React, {useState} from 'react';
import SeccionViewDatosDeResidencia from "./SeccionViewDatosDeResidencia";
import SeccionEditDatosDeResidencia from "./SeccionEditDatosDeResidencia";

const DatosDeResidencia = ({empleadoId, showUpdateConfirmationModal, datosResidenciaHasBeenUpdated, setDatosResidenciaHasBeenUpdated, reloadSeccionesDatosPersonales}) => {
    const [showViewSeccion, setShowViewSeccion] = useState(true);

    return (
        <div>
            <div className='card-body'>
                {showViewSeccion === true &&
                <>
                    <SeccionViewDatosDeResidencia empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion}/>
                </>
                }
            </div>
            <div>
                {(showViewSeccion === false) &&
                <>
                    <SeccionEditDatosDeResidencia empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} datosResidenciaHasBeenUpdated={datosResidenciaHasBeenUpdated} setDatosResidenciaHasBeenUpdated={setDatosResidenciaHasBeenUpdated} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                </>
                }
            </div>
        </div>
    );
};

export default DatosDeResidencia;