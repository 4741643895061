import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GenericModal from '../../../components/modal/GenericModal';
import MultiSelectInput from '../../../components/MultiSelectInput/MultiSelectInput';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import { getColaboradorById, getDropDownColaboradorAPI, registrarColaboradorProgramaPruebaAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';

const AgregarColaborador = ({ showModal }) => {

  const { state } = useContext(AuthContext);
  const [usuariosOptions, setUsuariosOptions] = useState([]);
  const [selectedUsuariosValues, setSelectedUsuarios] = useState([]);
  const [fichaColaborador, setFichaColaborador] = useState({});
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState({});
  const [agregarDeTodasFormas, setAgregarDeTodasFormas] = useState(false);
  const sectionName = "modal-registrar-usuario";

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [executePostRequest] = usePostRequest();

  const [message, setMessage] = useState("");

  useEffect(() => {
    init()
  }, []);

  const agregarColaborador = async () => {
    const api = registrarColaboradorProgramaPruebaAPI();
    const payload = {
      "IdEmpleado": fichaColaborador?.IdEmpleado,
      "AgregarDeTodasManeras": agregarDeTodasFormas
    };

    let response = await executePostRequest(api, payload);
    if (responseCode200(response)) {
      if (!response.data.Success && !agregarDeTodasFormas) {
        setMessage(response.data.Mensaje);
        setAgregarDeTodasFormas(true);
      } else {
        setMessage("");
        setAgregarDeTodasFormas(false);
        toast.success(response.data.Mensaje);
        showModal();
      }
    } else if (responseTieneModelState(response)) {
      mostrarMensajesDeValidacionEnCadaInput(response, ["IdEmpleado"], sectionName);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const init = async () => {
  };


  const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
    clearAllInputValidationMessages(["IdEmpleado"], sectionName);
    setEmpleadoSeleccionado(jsonValue);
    const response = await executeGetRequest(getColaboradorById(jsonValue.value));
    if (responseCode200(response)) {
      setFichaColaborador(response?.data);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  return <>
    <GenericModal showModal={showModal} width={"850px"} titulo={"Añadir colaborador"}>
      <div>
        <div>
          <form className="blue-standard-color">
            <div className="form-group row form-input-modal-contactanos">
              <label className="col-sm-12 col-form-label modal-label">Seleccionar al colaborador<span className="required">*</span></label>
            </div>

            <div className="col-sm-12 black-color">
              <AsyncSelectInput api={getDropDownColaboradorAPI} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal} />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
            </div>
          </form>

          {message !== "" ? (
            <div class="alert alert-warning" role="alert">
              {message}
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="modal-footer mt-2">
          <div className="message font-gothan-light">
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={agregarColaborador}>
              {message !== "" ? "Sí, " : null}
              Añadir
            </button>
          </div>
        </div>
      </div>
    </GenericModal>
  </>;
};

export default AgregarColaborador;
