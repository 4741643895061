import React, { useEffect, useState } from 'react';
import { cleanDropdown, initialAllOptionPromises } from '../../../helper/utils';
import InputDate from '../../../components/modalComponents/InputDate';
import InputText from '../../../components/modalComponents/InputText';
import ModalForm from '../../../components/modalComponents/ModalForm';
import InputSelect from '../../../components/modalComponents/InputSelect';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import InputSearchAsync from '../../../components/modalComponents/InputSearchAsync';
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../components/modalComponents/InputSelectCascadePost';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { CARGOS, FILE_FORMATS, FILE_SIZE_2MB, SEDES, TIPOS_CONTRATO } from '../../../constants/hardCodedConstants';
import { buscarColaboradorAPI, consultarSedeNuevoIngresoGestionAPI, crearRequerimientoPersonalAPI, getAllCargosAPI, getAllJornadasLaboralesAPI, getAllSedesAPI, getAllTiposContratoAPI, getAllTiposTrabajadorAPI, getAreasByGerenciaAPI, getAvanceRequerimientoAPI, getDivisionesAPI, getEspecialidadSeleccionAPI, getGerenciasByDivisionAPI, getPresupuestosAPI, getReemplazoByFiltersRequerimientosAPI, getRelatedDataTypesAPI, getResponsablesNuevoIngresoGestionAPI, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, getTiposVacanteAPI , getDirectorIdSedeAPI} from '../../../consumers/backendApisUrls';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { AlertErrorPDFFile2MBSizeMessage } from '../../../components/alerts/AlertErrorMaxFileSizeMessage';

const CrearRequerimiento = ({ toggleModal, refreshPage }) => {

  const SECTION_NAME = "crearRequerimientoPersonal";
  const KEYS_INIT = { presupuesto: "IdPresupuesto", sedePrincipal: "IdSedePrincipal", unidad: "IdUnidad", cargo: "IdCargo", tipoVacante: "IdTipoVacante", tipoContrato: "IdTipoContrato", Responsable: "IdResponsable", TipoEmpleado: "IdTipoEmpleado", TipoTrabajador: "IdTipoTrabajador", EspecialidadSeleccion: "IdEspecialidadSeleccion", AvanceRequerimiento: "IdAvanceRequerimiento", ArchivoResultadoEvaluacion: "ArchivoResultadoEvaluacion" };
  const KEYS = ["IdPresupuesto", "IdSedePrincipal", "IdSedeSecundaria", "IdUnidad", "IdGerencia", "IdArea", "IdCargo", "IdTipoVacante", "IdEmpleadoReemplazo", "IdSolicitante" ,"Detalle", "IdTipoTrabajador", "IdTipoEmpleado", "IdTipoContrato", "IdRegimenLaboral", "DiasxSemana", "FechaInicio", "FechaFin", "Sueldo", "IdResponsable", "IdEspecialidadSeleccion", "IdAvanceRequerimiento", "ArchivoResultadoEvaluacion"];

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [executeGet] = useGetRequest();

  const initialPromises = () => {
    return [
      getOptions(getPresupuestosAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getDivisionesAPI()),
      getOptions(getAllCargosAPI()),
      getOptions(getTiposVacanteAPI()),
      getOptions(getAllTiposContratoAPI()),
      getOptions(getResponsablesNuevoIngresoGestionAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
      getOptions(getAvanceRequerimientoAPI()),
    ]
  }

  const init = () => {
    initialAllOptionPromises(initialPromises, Object.values(KEYS_INIT), setAllDropdowns);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = crearRequerimientoPersonalAPI();
      await submitForm(KEYS, api, formValues, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    toggleModal();
    refreshPage();
  }

  const handleSedeSecundariaDropdown = (res) => {
    return res.filter(o => o.value !== formValues.IdSedePrincipal );
   
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[KEYS_INIT.TipoTrabajador],
      IdJornadaLaboral: formValues[KEYS_INIT.TipoEmpleado],
    }
  }

  useDidMountEffect( async() => {
    let response = await executeGet(consultarSedeNuevoIngresoGestionAPI(formValues.IdSedePrincipal));
    if(responseCode200(response)){
      let data = response.data;
      if(data.IdSedePrincipal !== SEDES.BACK_OFFICE){
        cleanDropdown(setFormValues, ["IdUnidad","IdSolicitante"]);
        let idsData = {IdUnidad: data.IdDivision, EmpleadoLider: data.EmpleadoLider, IdSolicitante: data.IdEmpleadoLider, ...data}
        setFormValues(lastData => ({...lastData, ...idsData}));

      }
    } else {
      cleanDropdown(setFormValues, ["IdUnidad", "IdGerencia", "IdArea" , "IdSolicitante"]);
    }


  
  }, [formValues.IdSedePrincipal])

  useEffect(() => {

    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Crear Requerimiento" saveButtonDisabled={isSending}>
      <section>
        <div className="displayFlexWrap">
          <DivCol><InputSelect label='Presupuesto' {...commonPropsSelect('IdPresupuesto')}/></DivCol>
          <DivCol><InputSelect label='Sede Principal' {...commonPropsSelect('IdSedePrincipal')}/></DivCol>
          <DivCol><InputSelectCascade label='Sede Secundaria' {...commomProps('IdSedeSecundaria')} dependendOption={formValues.IdSedePrincipal} api={getAllSedesAPI} isIdSearch={false} dropdownFilter={handleSedeSecundariaDropdown}/></DivCol>

          <DivCol><InputSelect label='Unidad' {...commonPropsSelect('IdUnidad', ['IdGerencia', 'IdArea'])}/></DivCol>
          <DivCol><InputSelectCascade label='Gerencia' dependendOption={formValues.IdUnidad} api={getGerenciasByDivisionAPI} {...commomProps('IdGerencia', ['IdArea'])}/></DivCol>
          <DivCol><InputSelectCascade label='Área' dependendOption={formValues.IdGerencia} api={getAreasByGerenciaAPI} {...commomProps('IdArea')}/></DivCol>


          <DivCol><InputSelect label='Cargo' {...commonPropsSelect('IdCargo', ["IdEspecialidadSeleccion"])}/></DivCol>
          <DivCol><InputSelect label='Tipo de Vacante' {...commonPropsSelect('IdTipoVacante')}/></DivCol>
          <DivCol><InputSearchAsync typeGet label='Reemplazo' api={buscarColaboradorAPI} {...commomProps('IdEmpleadoReemplazo')}/></DivCol>
          <DivCol><InputSelect label='Avance' {...commonPropsSelect('IdAvanceRequerimiento')}/></DivCol>

          {(formValues.IdCargo === CARGOS.PROFESOR || formValues.IdCargo === CARGOS.ASISTENTE_EDUCACION) &&
            <DivCol required><InputSelect label="Especialidad Selección Ingreso" {...commonPropsSelect("IdEspecialidadSeleccion")}/></DivCol>
          }
        </div>
      </section>
      <section className='row mt-2'>
        <label className='mb-3 col-sm-12'><strong>Detalle del Requerimiento:</strong></label>
        <DivCol>
          <InputSearchAsync typeGet label='Solicitante'  api={buscarColaboradorAPI } {...commomProps('IdSolicitante')} valueText={formValues.EmpleadoLider}
          />
        </DivCol>
        <DivCol>
          <InputUploadFile 
            label="Archivo Resultado Evaluación" 
            name={KEYS_INIT.ArchivoResultadoEvaluacion} 
            uploadFile={formValues[KEYS_INIT.ArchivoResultadoEvaluacion]} 
            setUploadFile={(res) => 
              setFormValues({...formValues, [KEYS_INIT.ArchivoResultadoEvaluacion]: res})} 
            sectionName={SECTION_NAME} 
            setIsUploading={setIsSending} 
            validExtensions={FILE_FORMATS.PDF_FORMAT}
            maxFileSize={FILE_SIZE_2MB}
            ToastArchivoNoValido={AlertErrorPDFFile2MBSizeMessage}/>
        </DivCol>
        <div className='col-sm-12'><InputTextArea label='Detalle' defaultValue={formValues.EmpleadoLider} {...commomProps('Detalle')} direction='column'/></div>
      </section>
      <section className='mt-2 row'>
        <label className='mb-3 col-sm-12'><strong>Datos de la asignación:</strong></label>
        <div className='displayFlexWrap'>
          <DivCol><InputSelect label="Tipo Trabajador" {...commonPropsSelect("IdTipoTrabajador", ["IdTipoContrato", "IdRegimenLaboral"])}/></DivCol>
          <DivCol><InputSelect label="Jornada Laboral" {...commonPropsSelect("IdTipoEmpleado", ["IdTipoContrato", "IdRegimenLaboral"])}/></DivCol>
          <DivCol><InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonPropsSelect("IdTipoContrato", ["IdRegimenLaboral", "DiasxSemana", "FechaFin"])}/></DivCol>
          <DivCol><InputSelectCascade label="Régimen Laboral" dependendOption={formValues["IdTipoContrato"]} api={getRelatedDataTypesAPI} {...commomProps("IdRegimenLaboral")}/></DivCol>

          {TIPOS_CONTRATO.PART_TIME.includes(formValues.IdTipoContrato) &&
            <DivCol><InputText label='Días por semana' {...commomProps('DiasxSemana')}/></DivCol>
          }

          <DivCol><InputSelect label='Responsable' {...commonPropsSelect('IdResponsable')}/></DivCol>
          {formValues[KEYS_INIT.tipoContrato] === TIPOS_CONTRATO.SUPLENCIA &&
            <>
              <DivCol><InputDate label='Fecha Inicio' {...commomProps('FechaInicio')}/></DivCol>
              <DivCol><InputDate label='Fecha Fin' {...commomProps('FechaFin')}/></DivCol>
            </>
          }
        </div>
      </section>
    </ModalForm>
  )
}

export default CrearRequerimiento;

const DivCol = ({ children }) => (
  <div className='col-sm-6'>
    {children}
  </div>
)








     
          

