import React, { useState, useEffect } from 'react'
import InputDate from '../../../../../../components/modalComponents/InputDate'
import ModalForm from '../../../../../../components/modalComponents/ModalForm'
import InputText from '../../../../../../components/modalComponents/InputText'
import InputSelect from '../../../../../../components/modalComponents/InputSelect'
import InputUploadFile from '../../../../../../components/modalComponents/InputUploadFile'
import { usePropsInputs } from '../../../../../../hooks/useProps/usePropsInput'
import { usePropsSelect } from '../../../../../../hooks/useProps/usePropsSelect'
import { useGetOptionsSelect } from '../../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import { initialAllOptionPromises } from '../../../../../../helper/utils'
import { getDepartamentosAPI, getDistritosPorProvinciaAPI, getGenerosAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, getTiposDocumentoAPI, getTiposVinculosAPI } from '../../../../../../consumers/backendApisUrls'
import { PAISES, TIPOS_VINCULOS } from '../../../../../../constants/hardCodedConstants'
import InputSelectCascade from '../../../../../../components/modalComponents/InputSelectCascade'

const VerDatoFamiliar = ({ toggleModal, familiar = {} }) => {

  const SECTION_NAME = "VerDatoFamiliarNuevosIngresos";
  const KEYS = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", numeroDocumento: "NumeroDocumento", idGenero: "IdGenero", nombres: "Nombres",  apellidoPaterno: "ApellidoPaterno", apellidoMaterno: "ApellidoMaterno", fechaNacimiento: "FechaNacimiento", fileViewModel: "FileViewModel" };
  const KEYS_NACIMIENTO = { idPaisNacimiento: "IdPaisNacimiento", idDepartamento: "IdDepartamento", idProvincia: "IdProvincia", idDistrito: "IdDistrito", ciudadNacimiento: "CiudadNacimiento" };
  const KEY_TYPES = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", idGenero: "IdGenero", idPaisNacimiento: "IdPaisNacimiento" };

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState(familiar);

  const [getOptions] = useGetOptionsSelect();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const initialPromises = () => ([
    getOptions(getTiposVinculosAPI()),
    getOptions(getTiposDocumentoAPI()),
    getOptions(getGenerosAPI()),
    getOptions(getPaisesAPI()),
  ])

  const init = async () => {
    initialAllOptionPromises(initialPromises, Object.values(KEY_TYPES), setAllDropdowns);
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm showModal={toggleModal} titulo="Dato Familiar" hasSaveButton={false}>
      <div className="displayFlexWrap">
        <Div><InputSelect label='Tipo de vínculo' {...commonSelect(KEYS.idVinculo)} isDisabled/></Div>
        <Div><InputSelect label='Tipo de documento' {...commonSelect(KEYS.idTipoDocumento)} isDisabled/></Div>
        <Div><InputText label='N° de documento' {...commonProps(KEYS.numeroDocumento)} isDisabled/></Div>
        <Div><InputSelect label='Género' {...commonSelect(KEYS.idGenero)} isDisabled/></Div>
        <Div><InputText label='Nombres' {...commonProps(KEYS.nombres)} isDisabled/></Div>
        <Div><InputText label='Apellido Paterno' {...commonProps(KEYS.apellidoPaterno)} isDisabled/></Div>
        <Div><InputText label='Apellido Materno' {...commonProps(KEYS.apellidoMaterno)} isDisabled/></Div>
        <Div><InputDate label='Fecha de Nacimiento' {...commonProps(KEYS.fechaNacimiento)} isDisabled/></Div>
        {formValues[KEYS.idVinculo] === TIPOS_VINCULOS.Hijo.id &&
          <>
            <Div><InputSelect label='País de nacimiento' {...commonSelect(KEYS_NACIMIENTO.idPaisNacimiento)} isDisabled/></Div>
            {formValues[KEYS_NACIMIENTO.idPaisNacimiento] === PAISES.PERU ?
              <>
                <Div><InputSelectCascade label='Departamento' dependendOption={formValues[KEYS_NACIMIENTO.idPaisNacimiento]} api={getDepartamentosAPI} {...commonProps(KEYS_NACIMIENTO.idDepartamento, [KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito])} isDisabled isIdSearch={false} isAutoselect={false}/></Div>
                <Div><InputSelectCascade label='Provincia' dependendOption={formValues[KEYS_NACIMIENTO.idDepartamento]} api={getProvinciasPorDepartamentoAPI} {...commonProps(KEYS_NACIMIENTO.idProvincia, [KEYS_NACIMIENTO.idDistrito])} isDisabled isAutoselect={false}/></Div>
                <Div><InputSelectCascade label='Distrito' dependendOption={formValues[KEYS_NACIMIENTO.idProvincia]} api={getDistritosPorProvinciaAPI} {...commonProps(KEYS_NACIMIENTO.idDistrito)} isDisabled/></Div>
              </> :
              <Div><InputText label='Ciudad' {...commonProps(KEYS_NACIMIENTO.ciudadNacimiento)} isDisabled/></Div>
            }
          </>
        }
        <Div col={12}><InputUploadFile label='Adjuntar DNI' uploadFile={formValues.FileViewModel} name="FileViewModel" validExtensions={["jpg", "png"]} sectionName={SECTION_NAME} colLabel={2} colInput={10} isDisabled/></Div>
      </div>
    </ModalForm>
  )
}

export default VerDatoFamiliar;

const Div = ({ children, col = 6 }) => (
  <div className={`col-sm-${col}`}>
    { children }
  </div>
)