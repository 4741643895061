import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Wrapper from '../../components/atoms/DirectorioWrapper'
import FormSelect from '../../components/atoms/Select';
import RegistroVacunaModal from '../../components/modalVacunas/RegistroVacunaModal';
import EditarVacunaModal from '../../components/modalVacunas/EditarVacunaModal';
import PageHeader from '../../components/pageHeader/PageHeader'
import {
  cartillaVacunacionDownloadReporteGestionVacunasAPI,
  eliminarCartillaVacunacionAPI,
  getAllAreasAPI, getAllCargosAPI,
  getAllDepartamentosAPI,
  getAllEstadosAPI,
  getDivisionesAPI,
  listarGestionVacunacionAPI,
  seguimientoColaboradorDescargarReporteAPI
} from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { updateOptionsDeFiltro } from '../../helper/Listas/ListasUtils';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import Paginator from '../directorio/Paginator';
import TablaWebBody from './TablaWebBody'
import {useGenerarDescargarEliminarArchivo} from "../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";

const GestionVacunacion = () => {
  const [loading, setLoading] = useState(true);

  const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
  const [filas, setFilas] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);
  const [cargosSeleccionadas, setCargosSeleccionadas] = useState([]);
  const [gerenciaSeleccionadas, setGerenciaSeleccionadas] = useState([]);
  const [areaSeleccionados, setAreaSeleccionados] = useState([]);
  const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
  const [estadoVacunaRefuerzoSeleccionadas, setEstadoVacunaRefuerzoSeleccionadas] = useState([]);
  const [estadoUsuarioSeleccionadas, setEstadoUsuarioSeleccionadas] = useState([]);

  const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);
  const [gerenciaFiltradasOptions, setGerenciaFiltradasOptions] = useState([]);
  const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
  const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
  const [estadoVacunaRefuerzoFiltradasOptions, setEstadoVacunaRefuerzoFiltradasOptions] = useState([]);
  const [estadoUsuariosFiltradasOptions, setEstadoUsuariosFiltradasOptions] = useState([]);
  const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);

  const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);
  const [allGerenciaFiltradasOptions, setAllGerenciaFiltradasOptions] = useState([]);
  const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
  const [allEstadosOptions, setAllEstadosOptions] = useState([]);
  const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);

  const [hideRegistroVacunaModal, setHideRegistroVacunaModal] = useState(true);
  const [hideEditarVacunaModal, setHideEditarVacunaModal] = useState(true);
  const [userSelected, setUserSelected] = useState({});

  const [cleanFilterss, setCleanFilters] = useState(false);
  const [executePostRequest] = usePostRequest();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();
  const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    setPage(1);
  }, [criterioDeBusqueda, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, estadosSeleccionados, estadoVacunaRefuerzoSeleccionadas,  estadoUsuarioSeleccionadas,cargosSeleccionadas]);

  useDidMountEffect(() => {
    manageSearch();
  }, [divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, estadosSeleccionados, page, fechaMasRecientePrimeroBool, estadoVacunaRefuerzoSeleccionadas, estadoUsuarioSeleccionadas, cargosSeleccionadas]);

  const getDropdownOptions = async (getFunction, setState) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      setState(response.data);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const getInitialData = async () => {
    setLoading(true);
    try {
      await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllGerenciaFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllEstadosOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);

      await manageSearch();

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const obtenerPayload = function () {
    let payload = {
      "Criteria": criterioDeBusqueda,
      "IdsDivisiones": divisionSeleccionados,
      "IdsGerencias": gerenciaSeleccionadas,
      "IdsAreas": areaSeleccionados,
      "IdsEstados": estadosSeleccionados,
      "Page": page,
      "OrdenarReciente": fechaMasRecientePrimeroBool,
      "IdsEstadosRefuerzo": estadoVacunaRefuerzoSeleccionadas,
      "IdsEstadosUsuario": estadoUsuarioSeleccionadas,
      "IdsCargos": cargosSeleccionadas,
    };
    return payload;
  }

  const manageSearch = async () => {
    setLoading(true);
    let payload = obtenerPayload();
    await executeSearch(payload);
  };

  const executeSearch = async (payload) => {
    const response = await executePostRequest(listarGestionVacunacionAPI(), payload);
    if (responseCode200(response)) {
      setFilas(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setFirsItemPage(response.data.FirsItemPage);
      setLastItemPage(response.data.LastItemPage);
      setTotalPages(response.data.TotalPages);

      await updateOptionsDeFiltros(response.data.ExtraData);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const updateOptionsDeFiltros = async function (lists) {
    await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
    await updateOptionsDeFiltro(allGerenciaFiltradasOptions, lists["IdsGerencias"], setGerenciaFiltradasOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
    await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
    await updateOptionsDeFiltro(allEstadosOptions, lists["IdsEstados"], setEstadosFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allEstadosOptions, lists["IdsEstadosRefuerzo"], setEstadoVacunaRefuerzoFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allEstadosOptions, lists["IdsEstadosUsuario"], setEstadoUsuariosFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
    setLoading(false);
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      manageSearch();
    }
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const limpiarBusqueda = () => {
    setDivisionSeleccionados([]);
    setGerenciaSeleccionadas([]);
    setAreaSeleccionados([]);
    setEstadosSeleccionados([]);
    setCriterioDeBusqueda("");
    setCleanFilters(!cleanFilterss);
    setEstadoVacunaRefuerzoSeleccionadas([]);
    setEstadoUsuarioSeleccionadas([]);
    setFechaMasRecientePrimeroBool(true);
    setCargosSeleccionadas([]);
  };

  const eliminarCartilla = async function (id) {
    let payload = {
      "IdVacunacion": id
    }

    const response = await executePostRequest(eliminarCartillaVacunacionAPI(), payload);
    if (responseCode200(response)) {
      toast.success(response.data.Message);
      manageSearch();

    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const toggleRegistroVacunaModal = async (idUser) => {
    setHideRegistroVacunaModal(!hideRegistroVacunaModal);
    if(idUser) {
      setUserSelected(idUser);
    }
  }

  const toggleEditarVacunaModal = async (idUser) => {
    setHideEditarVacunaModal(!hideEditarVacunaModal);
    if(idUser) {
      setUserSelected(idUser);
    }
  }

  const descargar = async function () {
    let payload = obtenerPayload();
    await generarDescargarEliminarArchivo(cartillaVacunacionDownloadReporteGestionVacunasAPI(), payload);
  }

  const onChangeFechaMasRecientePrimeroBool = (e) => {
    let inputValue = e.target.checked;
    setFechaMasRecientePrimeroBool(inputValue);
  };

  return (
    <Wrapper className='App'>
      {hideRegistroVacunaModal === false &&
        <div className="modal-contactanos modal-response-ficha-background">
          <RegistroVacunaModal userId={userSelected} showModal={toggleRegistroVacunaModal} reload={()=> {}} />
        </div>
      }

      {hideEditarVacunaModal=== false &&
        <div className="modal-contactanos modal-response-ficha-background">
          <EditarVacunaModal userId={userSelected} showModal={toggleEditarVacunaModal} reload={()=> {manageSearch()}} />
        </div>
      }
      <PageHeader title={"Gestión de vacunación"} />

      {loading === true &&
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
      }

      {loading === false &&
      <div className='container-fluid body-content'>
        <div className="generic-buscador-container">
          <div className="filtros-left-section">
            <input
                type='text'
                className='generic-input-criterio-busqueda'
                placeholder='Buscar por nombre'
                value={criterioDeBusqueda}
                onChange={(e) => {
                  setCriterioDeBusqueda(e.target.value);
                }}
                onKeyPress={handleKeypress}
            />
            <button
                onClick={manageSearch}
                type='button'
                className='generic-button-buscador'>
              Buscar
            </button>
            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
          </div>
          <div className="filtros-right-section">
            <button
                onClick={descargar}
                type='button'
                className='generic-button-buscador'>
              Descargar
            </button>
          </div>
        </div>

        <div className='pt-2'>
          <div className="table-scroll">
          <table className='table table-hover'>
            <thead className="listar-thead">
            <tr className='bg-cl-gris'>
              <th className="pb-0">
                <div className='flex font-gothan-medium'>Fecha de Registro</div>
                <div className="custom-control custom-switch mt-1">
                  <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                  <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                    Más reciente
                  </label>
                </div>
              </th>
              <th>
                <div className='flex font-gothan-medium'>Nombre Completo</div>
              </th>
              <th>
                <FormSelect
                   options={estadoUsuariosFiltradasOptions}
                   description={'Usuario'}
                   setSelectedOptions={setEstadoUsuarioSeleccionadas} 
                   selectedOptions={estadoUsuarioSeleccionadas}
                />
              </th>
              <th>
                <FormSelect
                    description={'Cargo'}
                    options={cargosFiltradasOptions}
                    setSelectedOptions={setCargosSeleccionadas}
                    selectedOptions={cargosSeleccionadas}
                />  
              </th>
              <th>
                <FormSelect
                    description={'División'}
                    options={divisionFiltradosOptions}
                    setSelectedOptions={setDivisionSeleccionados}
                    selectedOptions={divisionSeleccionados}
                />
              </th>
              <th>
                <FormSelect
                    description={'Gerencia'}
                    options={gerenciaFiltradasOptions}
                    setSelectedOptions={setGerenciaSeleccionadas}
                    selectedOptions={gerenciaSeleccionadas}
                />
              </th>
              <th>
                <FormSelect
                    description={'Área'}
                    options={areasFiltradasOptions}
                    setSelectedOptions={setAreaSeleccionados}
                    selectedOptions={areaSeleccionados}
                />
              </th>
              <th>
                <FormSelect
                    description={'Estado'}
                    options={estadosFiltradosOptions}
                    setSelectedOptions={setEstadosSeleccionados}
                    selectedOptions={estadosSeleccionados}
                />
              </th>
              <th>
                <FormSelect
                    options={estadoVacunaRefuerzoFiltradasOptions}
                    description={'Vacuna Refuerzo'}
                    setSelectedOptions={setEstadoVacunaRefuerzoSeleccionadas}
                    selectedOptions={estadoVacunaRefuerzoSeleccionadas}
                />
              </th>
              <th>
                <div className='flex font-gothan-medium'>Acciones</div>
              </th>
            </tr>
            </thead>
            <TablaWebBody filas={filas} toggleRegistroVacunaModal={toggleRegistroVacunaModal} eliminar={eliminarCartilla} toggleEditarVacunaModal={toggleEditarVacunaModal}/>
          </table>
          </div>
          {totalPages > 0 && (
              <Paginator totalItems={totalItems}
                         firsItemPage={firsItemPage}
                         lastItemPage={lastItemPage}
                         totalPages={totalPages}
                         page={page}
                         onLeftClic={onLeftClic}
                         onRightClic={onRightClic} />
          )}
        </div>
      </div>
      }
    </Wrapper>
  )
}

export default GestionVacunacion
