import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PageButtons = ({
  handleCreateButton,
  hasBackButton,
  hasCreateButton,
  children,
  labelCreate = "Crear Nuevo",
}) => {
  const history = useHistory();

  return (
    <div className="d-flex justify-content-center align-items-center">
      {hasBackButton && <button
        className="generic-button-buscador d-flex align-items-center"
        style={{ gap: "7px" }}
        onClick={() => history.goBack()}
      >
        <FontAwesomeIcon
          icon="angle-left"
          size="lg"
          className="hand-pointer"
          title="Atrás" />Atrás
      </button>}
      {children}
      {hasCreateButton &&
        <button
          type="button"
          className="generic-button-buscador"
          onClick={handleCreateButton}
        >
          {labelCreate}
        </button>
      }
    </div>
  );
};

export default PageButtons;