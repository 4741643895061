import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../../hooks/context/authContext";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from "../../../helper/utils";
import { enviarFichaSintomatologica, getCondicionesMedicasAPI, getDetalleEncuestaAPI, getDetalleFichaSintomatologica, getFichaSintomatologica } from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import GenericModal from "../../../components/modal/GenericModal";
import SelectInput from "../../../components/Select/SelectInput";
import MultiSelectInput from "../../../components/MultiSelectInput/MultiSelectInput";
import { downloadFile } from "../../../consumers/backendConsumer";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useForm } from '../../../hooks/useForm/UseForm';

const DetalleFichaSintomatologicaModal = ({ showModal, idRegistro }) => {
  const { state } = useContext(AuthContext);
  const [executeGetRequest] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const seccionName = "data";

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);


  const [fechaEncuesta, setFechaEncuesta] = useState("");
  const [questions, setQuestions] = useState([]);
  const [declaracionJurada, setDeclaracionJurada] = useState(false);
  const [checkedQuestions, setCheckedQuestions] = useState({});


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);


  const init = async function () {
    const response = await executeGetRequest(getDetalleFichaSintomatologica(idRegistro));
    if (responseCode200(response)) {
      const fecha = response.data.Fecha;
      setFechaEncuesta(fecha);
      const questionsResponse = response.data.Items;
      setQuestions(questionsResponse);
      const selectedValues = questionsResponse.reduce((obj, item) => {
        return {
          ...obj,
          [item.IdFichaSintomatologicaAsk]: item.Answer
        }
      }, {});

      setCheckedQuestions(selectedValues);

      const details = questionsResponse.reduce((obj, item) => {
        return {
          ...obj,
          [item.IdFichaSintomatologicaAsk]: item.Detail
        }
      }, {});

      setFormValues(details);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const clickOnCheckbox = async function (e) {
    let checkedValue = e.target.checked;
    let idAcceso = e.target.dataset.id;

    console.debug("checkedValue: ", checkedValue);
    console.debug("idAcceso", idAcceso);

    setCheckedQuestions({
      ...checkedQuestions,
      [idAcceso]: checkedValue
    })
  };

  const handleDeclaracionJurada = (e) => {
    setDeclaracionJurada(e.target.checked);
  };

  const successSubmitCallback = function () {
    showModal();
  }

  return (
    <>
      <GenericModal showModal={showModal} width={"960px"} titulo={"Ficha sintomatológica"}>
        <div className="modal-body">
          {fechaEncuesta !== "" ? (<div>Fecha: {fechaEncuesta}</div>) : null}
          <div className="d-flex justify-content-between mb-4">
            <div>En los últimos 7 días calendarios ha tenido alguno de los síntomas siguientes: </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {questions.map(question => (
                <div key={question.Question} className="col-xs-10 col-md-6 px-xs-0 px-4">
                  <div className="d-flex justify-content-between">
                    <div>
                      {question.Question}
                    </div>
                    <div>
                      <div className="custom-control custom-switch ml-3" style={{ zIndex: 0 }}>
                        <input
                          type="checkbox"
                          defaultChecked={question.Answer}
                          className="custom-control-input hand-pointer"
                          id={question.Question}
                          data-id={question.Question}
                          name={question.Question}
                          disabled
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={question.Question}
                        >
                          {question.Answer? "Si": "No"}
                        </label>
                      </div>
                    </div>
                  </div>
                  {question.Detail !== "" ? (
                    <div>
                      <textarea
                        value={question.Detail}
                        className="form-control"
                        disabled
                      ></textarea>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </GenericModal>
    </>
  );
};

export default DetalleFichaSintomatologicaModal;
