import React, {useState} from 'react';
import styled from 'styled-components';
import Arrow from '../../components/icons/Arrow';
import {openGmail, openWhatsapp} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDataEmpleadoAPI} from "../../consumers/backendApisUrls";

const Wrapper = styled.div`
  display: none;
  padding: 20px 0;
  background: #ffffff;
  border-radius: 5px;

  .title-body {
    font-size: 0.875rem;
    color: #0063a1;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0 20px;
  }
  .title-body-name {
    color: #595959;
    font-weight: bold;
    font-size: 0.75rem;
  }

  .wrapper {
    /* height: 46px; */
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.5px solid #ebebeb;
  }
  img {
    width: 20px;
    margin-right: 15px;
  }
  .description {
    font-size: 0.75rem;
    font-weight: 400;
    margin-top: 20px;
    div {
      padding: 5px 0;
    }
  }
  @media (max-width: 992px) {
    display: block;
  }
`;

const ArrowCont = styled.div`
  transform: ${(props) => (props.active ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
  display: flex;
  transition: all 0.3s ease 0s;
  align-items: center;
`;

const Card = (props) => {
    const user = props.user;
    const [detail, setDetail] = useState({});
    const [executeGetRequest] = useGetRequest();

    const getData = async (IdEmpleado) => {
        try {
            const response = await executeGetRequest(getDataEmpleadoAPI(IdEmpleado));
            if (responseCode200(response)) {
                setDetail(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className='wrapper'>
            <div style={{width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={user.ProfileImageUrl} alt=''/>
                        <p className='title-body-name '>{user.NombreCompleto}</p>
                    </div>
                    <ArrowCont
                        active={props.id === user.IdEmpleado}
                        onClick={() => {
                            getData(user.IdEmpleado);
                            props.getId(user.IdEmpleado);
                        }}>
                        <Arrow/>
                    </ArrowCont>
                </div>
                {props.id === user.IdEmpleado && (
                    <div className='description'>
                        <div>{detail.Sede}</div>
                        <div>{detail.NombreCargo}</div>
                        <div>{detail.TiempoEnInnova} en Innova Schools</div>
                        {detail.NombreEmpleadoSuperior !== null && (
                            <div>
                                Su Jefe Líder es <a href='#'>{detail.NombreEmpleadoSuperior}</a>
                            </div>
                        )}

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '10px',
                        }}>
                            {user.CanSendWhatsApp && (
                                <div>
                                    <div onClick={() => openWhatsapp(user.Celular)}>
                                        <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                                    </div>
                                </div>
                            )}

                            {user.Email && (
                                <div>
                                    <div onClick={() => openGmail(user.Email)}>
                                        <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const TableMobileBodyMiEquipo = (props) => {
    const users = props.users;
    const [id, setId] = useState();

    return (
        <Wrapper>
            <div className='title-body'>Personas</div>
            {users.map((user, i) => (
                <Card key={i} user={user} getId={(e) => setId(e)} id={id}/>
            ))}
        </Wrapper>
    );
};

export default TableMobileBodyMiEquipo;


