import React, { useEffect, useRef, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import {crearNuevaPoliticaAPI, getAllCargosAPI, getAllColorsAPI, getDivisionesAPI, listarAllCategoriasAPI} from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { convertFromRaw, EditorState, convertToRaw } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SelectInput from '../../../components/Select/SelectInput';
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric, notNullAndNotUndefinded, responseUploadFileSuccess} from '../../../helper/utils';
import { Editor } from 'react-draft-wysiwyg';
import { richTextToolbarOptions } from './richTextToolbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MultiSelectInput from '../../../components/MultiSelectInput/MultiSelectInput';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useContext } from 'react';
import { uploadFile } from '../../../consumers/backendConsumer';
import { toast } from 'react-toastify';
import { estados } from '../../../constants/states';
import { Spinner } from "react-bootstrap";
import CustomInfoTooTip from "../../../components/tooltip/CustomInfoToolTip";

const CrearNuevaPolitica = ({ showModal, politicId, refreshPage, toggleMensajeAlertaModal, pasoMensajeAlerta }) => {
  const { state } = useContext(AuthContext);
  const sectionName = "crearNuevaPolitica";
  const multiselectPlaceholder = "Selecciona una o más opciones";

  const [manageGetRequest] = useManageGetRequest();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

  const [isLoading, setIsLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    MostrarFecha: false
  });
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();

  const [formFileValues, setFormFileValues] = useState({});
  const [handleFormFileChange] = useForm(formFileValues, setFormFileValues);

  const [categoriasParentOptions, setCategoriasParentOptions] = useState([]);
  const [categoriaParentSeleccionado, setCategoriaParentSeleccionado] = useState({});

  const [categoriasChildOptions, setCategoriasChildOptions] = useState([]);
  const [categoriaChildSeleccionado, setCategoriaChildSeleccionado] = useState({});


  const [estadoOptions, setEstadoOptions] = useState([
    { value: estados.Activo.id, label: estados.Activo.nombre },
    { value: estados.Inactivo.id, label: estados.Inactivo.nombre },
  ]);
  const [estadoSeleccionado, setEstadoSEleccionado] = useState({
    value: 1, label: "Activo"
  });

  const [coloresOptions, setColoresOptions] = useState([]);
  const [colorSeleccionado, setColorSeleccionado] = useState(null);

  const [uploadedFile, setUploadedFile] = useState({});
  const INPUT_FILE_ICONO = "INPUT_FILE_ICONO";
  const EXTENSIONES_VALIDAS = ["png", "jpg"];

  const [politicasFiles, setPoliticasFiles] = useState([]);
  const [politicasFilesUploaded, setPoliticasFilesUploaded] = useState([]);
  const hiddenFileInput = useRef(null);

  const [divisionesOptions, setDivisionesOptions] = useState([]);
  const [jsonSelectedDivisionValue, setJsonSelectedDivisionValue] = useState([]);

  const [cargosOptions, setCargosOptions] = useState([]);
  const [jsonSelectedCargosValueInclude, setJsonSelectedCargosValueInclude] = useState([]);
  const [jsonSelectedCargosValueExclude, setJsonSelectedCargosValueExclude] = useState([]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [categoriasParentsConChilds, setCategoriasParentsConChilds] = useState([]);

  const descripcionComoTexto = 0;
  const descripcionComoImagen = 1;
  const textDescriptionMode = {value: descripcionComoTexto, label: "Texto"};
  const imageDescriptionMode = {value: descripcionComoImagen, label: "Imagen"};

  const [descriptionOptions] = useState([textDescriptionMode, imageDescriptionMode]);
  const [descriptionOptionSelected, setDescriptionOptionSelected] = useState(textDescriptionMode);


  const [uploadedFileDescription, setUploadedFileDescription] = useState([]);
  const INPUT_FILE_DESCRIPCION = "INPUT_FILE_DESCRIPCION";
  const EXTENSIONES_VALIDAS_DESCRIPTION = ["png", "jpg"];

  const [uploadedFileMasInfo, setUploadedFileMasInfo] = useState({});
  const INPUT_FILE_MAS_INFO = "INPUT_FILE_MAS_INFO";
  const EXTENSIONES_VALIDAS_MAS_INFO = ["png", "jpg"];

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if(pasoMensajeAlerta){
      manageSubmit(politicasFilesUploaded);
      toggleMensajeAlertaModal();
    }
  },[pasoMensajeAlerta])


  useEffect(() => {
    if (notNullAndNotUndefinded(categoriaParentSeleccionado?.value)) {
      let parent = categoriasParentsConChilds.find(o => o?.IdCategoria == categoriaParentSeleccionado?.value);
      const categoriasChilds = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(parent?.childs, "IdCategoria", "NombreCategoria");
      setCategoriasChildOptions(categoriasChilds);
      if (categoriasChilds.length === 0)
        setCategoriaChildSeleccionado({'value': null})
    }
  }, [categoriaParentSeleccionado]);

  const updateOptionBasedOnResponse = (apiOptions, setStatee) => {
    let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(apiOptions);
    setStatee(options);
  };

  const setOptionsDropDownIndependiente = async (getApiFunction, setStatee) => {
    const response = await getApiFunction(state.token);
    if (responseCode200(response)) {
      updateOptionBasedOnResponse(response.data, setStatee);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const callbackSuccessGetCategorias = function (response) {
    let allCategorias = response?.data;
    let parents = allCategorias.filter((value) => value?.IdParent === null);
    let childs = allCategorias.filter((value) => value?.IdParent !== null);

    let allParentsConChilds = [];
    for (const parent of parents) {
      let childss = childs.filter((o)=> o?.IdParent === parent?.IdCategoria);
      allParentsConChilds = [...allParentsConChilds,
        {
          ...parent, childs: [...childss]
        }]
    }

    setCategoriasParentsConChilds(allParentsConChilds);

    const categoriasParsed = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(parents, "IdCategoria", "NombreCategoria");
    setCategoriasParentOptions(categoriasParsed);
  }

  const callbackSuccessGetColores = function (response) {
    const coloresParsed = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Key");
    const coloresConIcono = coloresParsed.map(item => ({
      ...item,
      label: (
        <>
          <FontAwesomeIcon icon={"square"} color={item.Key}/>{' '}{item.Key}
        </>
      )
    }));
    setColoresOptions(coloresConIcono);
  }

  const getInitialData = async () => {
    try {
      await manageGetRequest(listarAllCategoriasAPI(), callbackSuccessGetCategorias, false);
      await manageGetRequest(getAllColorsAPI(), callbackSuccessGetColores, false);
      await setOptionsDropDownIndependiente(executeGetRequestCallback(getDivisionesAPI()), setDivisionesOptions);
      await setOptionsDropDownIndependiente(executeGetRequestCallback(getAllCargosAPI()), setCargosOptions);
    } catch (e) {
      console.error(e);
    }
  };

  const successSubmitCallback = function () {
    setIsLoading(false);
    refreshPage();
    showModal();
  }

  const uploadFiles = async () => {
    const uploadedPoliticFiles = [];
    for (let i = 0; i < politicasFiles.length; i++) {
      let response = await uploadFile(politicasFiles[i], state.token);
      const politicFile = manageUploadFileResponse(response);
      uploadedPoliticFiles.push({
        File: politicFile,
        Nombre: formFileValues[politicasFiles[i].name]
      });
    }
    
    return uploadedPoliticFiles;
  }

  const manageUploadFileResponse = (response) => {
    if (responseUploadFileSuccess(response)) {
      let s3Response = response.data.file;
      return s3Response;
    } else {
      toast.error("Hubo un error al subir el archivo");
    }
  };

  const manageSubmit = async (filesList) => {
    setIsLoading(true);
    let payload = armarPayload(filesList);
    let api = crearNuevaPoliticaAPI();
    let keys = [
      "Nombre",
      "IdCategoria",
      "Descripcion",
      "IdColor",
      "Icono",
      "IdsDivisiones",
      "Files",
      "IdsCargosIncluir",
      "IdsCargosExcluir",
      "DescriptionFile"
    ];

    await submitForm(keys, api, payload, sectionName, successSubmitCallback, true, () => setIsLoading(false));
  }



  const submit = async () => {
    const filesList = await uploadFiles();
    setPoliticasFilesUploaded(filesList);
    if(filesList.length === 0 || uploadedFile.length === 0) {
      toggleMensajeAlertaModal();
      setIsLoading(false);
    } else {
      manageSubmit(filesList);
    }
    
  };

  const armarPayload = (filesList) => {
    let payload = {
      "Nombre": formValues?.Nombre,
      "IdCategoria": getIdCategoria(),
      "Descripcion": getDescripcion(),
      "DescriptionFile": getDescripcionFile(),
      "MasInfoFile": uploadedFileMasInfo,
      "IdColor": colorSeleccionado?.value,
      "Icono": uploadedFile,
      "IdEstado": estadoSeleccionado.value ?? 1,
      "FechaNuevo": formValues?.FechaNuevo,
      "FechaVigencia": formValues?.FechaVigencia,
      "IdsDivisiones": jsonSelectedDivisionValue,
      "IdsCargosIncluir": jsonSelectedCargosValueInclude,
      "IdsCargosExcluir": jsonSelectedCargosValueExclude,
      "MostrarFechaPublicacion": formValues?.MostrarFecha,
      "Files": filesList,
    }


    return payload;
  }

  const getDescripcionFile = () => (descriptionOptionSelected?.value === descripcionComoImagen) ? uploadedFileDescription :  null

  const getDescripcion = () => {
    let empty = "{\"blocks\":[{\"key\":\"22ad0\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}";
    if (descriptionOptionSelected?.value === descripcionComoImagen)
      return null;

    try{
      return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    }catch (e){
      return empty;
    }

  }

  const getIdCategoria = () => {
    return showCategoriaChildInput() ? categoriaChildSeleccionado?.value : categoriaParentSeleccionado?.value;
  }

  const handleClickSubirPoliticas = event => {
    hiddenFileInput.current.click();
  };

  const handleChengeSubirPoliticas = event => {
    const fileUploaded = event.target.files[0];
    setFormFileValues(prev => ({
      ...prev,
      [fileUploaded.name]: fileUploaded.name,
    }));
    setPoliticasFiles(prev => [...prev, fileUploaded]);
  }

  const handleDeleteFile = fileName => {
    setPoliticasFiles(prev => prev.filter(politica => {
      return fileName !== politica.name;
    }))
  }

  const showCategoriaChildInput = () => notNullAndNotUndefinded(categoriasChildOptions) && categoriasChildOptions?.length > 0

  return (
    <>
      <GenericModal showModal={showModal} width={"1180px"} titulo={"Crear nueva política"}>
        <div className="modal-body pt-0 pl-0">

          <form action="">
            <div className="form-row">
              <div className="form-group mb-0 col-md-9">
                <label className="modal-label mb-0" >Titulo</label>
                <input type="text" className="form-control" name="Nombre" onChange={handleChange} value={formValues?.Nombre ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Nombre-validation-message`}></p>
              </div>
              <div className="form-group mb-0 col-md-3">
                <label className="modal-label mb-0">Estado</label>
                <SelectInput
                    options={estadoOptions}
                    jsonSelectedValue={estadoSeleccionado}
                    setJsonSelectedValue={setEstadoSEleccionado}
                    placeholder={"Seleccionar"}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NONE-validation-message`}></p>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-6">
                <label className="modal-label mb-0" >Categoría</label>
                <SelectInput
                    options={categoriasParentOptions}
                    jsonSelectedValue={categoriaParentSeleccionado}
                    setJsonSelectedValue={setCategoriaParentSeleccionado}
                    placeholder={"Seleccionar"}
                />

                {showCategoriaChildInput() === false &&
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCategoria-validation-message`}></p>
                }
              </div>
              {showCategoriaChildInput() &&
              <div className="form-group mb-0 col-md-6">
                <label className="modal-label mb-0">Sub Categoría</label>
                <SelectInput
                    options={categoriasChildOptions}
                    jsonSelectedValue={categoriaChildSeleccionado}
                    setJsonSelectedValue={setCategoriaChildSeleccionado}
                    placeholder={"Seleccionar"}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCategoria-validation-message`}></p>
              </div>}
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-3">
                <label className="modal-label mb-0" >Formato de descripción</label>
                <SelectInput
                    options={descriptionOptions}
                    jsonSelectedValue={descriptionOptionSelected}
                    setJsonSelectedValue={setDescriptionOptionSelected}
                    placeholder={"Seleccionar"}
                    resetSelectedValue={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NONE-validation-message`}></p>

              </div>
            </div>

            {descriptionOptionSelected?.value === descripcionComoTexto &&
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0" >Descripción</label>
                <div className="w-100">
                  <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper w-100 bg-white"
                      editorClassName="demo-editor"
                      toolbar={richTextToolbarOptions}
                      onEditorStateChange={setEditorState}
                  />
                </div>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Descripcion-validation-message`}></p>
              </div>
            </div>}
            {descriptionOptionSelected?.value === descripcionComoImagen &&
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0">
                  Descripción <CustomInfoTooTip text={"Seleccionar una imagen en formato png, jpg 700x393 de máximo 8MB"}/>
                </label>
                <div className="w-100">
                  <GenericUploadFileV2
                      uploadFileToS3={setUploadedFileDescription}
                      id={INPUT_FILE_DESCRIPCION}
                      textButton={"Seleccionar Imagen"}
                      extensionesValidas={EXTENSIONES_VALIDAS_DESCRIPTION}
                  />
                </div>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-DescriptionFile-validation-message`}></p>
              </div>
            </div>}


            <div className="form-row">
              <div className="form-group mb-0 col-md-3">
                <label className="modal-label mb-0" >Color</label>
                <SelectInput
                    options={coloresOptions}
                    jsonSelectedValue={colorSeleccionado}
                    setJsonSelectedValue={setColorSeleccionado}
                    placeholder={"Seleccionar"}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdColor-validation-message`}></p>

              </div>
              <div className="form-group mb-0 col-md-4">
                <label className="modal-label mb-0" >Ícono</label>
                <div className="w-100 black-color">
                  <GenericUploadFileV2
                      uploadFileToS3={setUploadedFile}
                      id={INPUT_FILE_ICONO}
                      textButton={"Seleccionar Imagen"}
                      extensionesValidas={EXTENSIONES_VALIDAS}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Icono-validation-message`}></p>
                </div>
              </div>
              <div className="form-group mb-0 col-md-4">
                <label className="modal-label mb-0">
                  Más Información
                  <CustomInfoTooTip text={"Seleccionar una imagen en formato png, jpg 350x622 de máximo 8MB"}/>
                </label>
                <div className="w-100 black-color">
                  <GenericUploadFileV2
                      uploadFileToS3={setUploadedFileMasInfo}
                      id={INPUT_FILE_MAS_INFO}
                      textButton={"Seleccionar Imagen"}
                      extensionesValidas={EXTENSIONES_VALIDAS_MAS_INFO}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NONE-validation-message`}></p>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-3">
                <label className="modal-label mb-0" >Vigencia de Nuevo</label>
                <div className="black-color">
                  <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      name="FechaNuevo"
                      value={formValues.FechaNuevo}
                      onChange={handleChange}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaNuevo`}></p>
                </div>
              </div>
              <div className="form-group mb-0 col-md-3">
                <label className="modal-label mb-0" >Fecha de Vigencia</label>
                <div className="w-100 black-color ml-2">
                  <input
                      type="date"
                      min={new Date().toISOString().split("T")[0]}
                      className="form-control"
                      name="FechaVigencia"
                      value={formValues.FechaVigencia}
                      onChange={handleChange}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaVigencia-validation-message`}></p>
                </div>
              </div>
              <div className="form-group mb-0 col-md-6">
                <label className="modal-label mb-0" ></label>
                <div className="form-check ml-3">
                  <input
                      className="form-check-input"
                      type="checkbox"
                      id={"MostrarFecha"}
                      data-id={"MostrarFechaLabel"}
                      name={"MostrarFecha"}
                      defaultChecked={formValues?.MostrarFecha}
                      onClick={handleChange}
                      value={formValues?.MostrarFecha}
                  />
                  <label className="form-check-label" htmlFor={"MostrarFechaLabel"}>
                    Mostrar fecha de publicación
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0" >Cargar políticas</label>
               <div>
                 <div className="w-100 d-flex justify-content-between">
                   <button
                       type="button"
                       className="generic-button-buscador"
                       onClick={handleClickSubirPoliticas}
                   >
                     Subir
                   </button>
                   <input
                       type="file"
                       className="d-none"
                       onChange={handleChengeSubirPoliticas}
                       ref={hiddenFileInput}
                   />
                 </div>
                 <div className="w-100">
                   <table className="table table-bordered">
                     <thead>
                     <tr>
                       <th className="col-9">Nombre</th>
                       <th className="col-3">Acción</th>
                     </tr>
                     </thead>
                     <tbody>
                     {politicasFiles?.map(file => (
                         <tr key={file?.name}>
                           <th>
                             <input
                                 className="w-100"
                                 type="text"
                                 name={file?.name}
                                 value={formFileValues[file.name]}
                                 onChange={handleFormFileChange}
                             />
                           </th>
                           <th>
                             <div className="d-flex">
                               <FontAwesomeIcon
                                   icon='trash'
                                   className="mr-2"
                                   onClick={() => { handleDeleteFile(file?.name) }}
                               />
                             </div>
                           </th>
                         </tr>
                     ))}
                     </tbody>
                   </table>
                   <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Files-validation-message`}></p>
                 </div>
               </div>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0" >División (incluir)</label>
                <MultiSelectInput
                    options={divisionesOptions}
                    selectedValues={jsonSelectedDivisionValue}
                    setSelectedValues={setJsonSelectedDivisionValue}
                    placeholder={multiselectPlaceholder}
                    resetSelectedValues={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsDivisiones-validation-message`}></p>

              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0" >Cargo (incluir)</label>
                <MultiSelectInput
                    options={cargosOptions}
                    selectedValues={jsonSelectedCargosValueInclude}
                    setSelectedValues={setJsonSelectedCargosValueInclude}
                    placeholder={multiselectPlaceholder}
                    resetSelectedValues={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargosIncluir-validation-message`}></p>

              </div>
            </div>
            <div className="form-row">
              <div className="form-group mb-0 col-md-12">
                <label className="modal-label mb-0" >Cargo (excluir)</label>
                <MultiSelectInput
                    options={cargosOptions}
                    selectedValues={jsonSelectedCargosValueExclude}
                    setSelectedValues={setJsonSelectedCargosValueExclude}
                    placeholder={multiselectPlaceholder}
                    resetSelectedValues={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargosExcluir-validation-message`}></p>

              </div>
            </div>
          </form>

        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button
              className="btn btn-primary generic-button-ficha-modal d-flex align-items-center"
              onClick={submit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="grow" variant="alert" size="1sm" /> {" Creando..."}
                </>
              ) : (
                "Crear"
              )}
            </button>
          </div>
        </div>
      </GenericModal>
    </>
  )
}

export default CrearNuevaPolitica;
