import { useGetRequest } from "../useGetRequest/useGetRequest";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";

const useGetOptionsSelectWithRegex = () => {
  const [executeGet] = useGetRequest();
  
  const getOptionsWithRegex = async (api) => {
    const response = await executeGet(api);
    if(responseCode200(response)){
      return response.data.map(o => ({value: o.Value, label: o.Text, regex: o.Regex, message: o.ValidationMessage}))
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  return [getOptionsWithRegex];
}

export default useGetOptionsSelectWithRegex;