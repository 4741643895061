import React from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { activarAnularSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls';

const EliminarSolicitudVacaciones = ({showModal, refreshPage, idSolicitudMovimiento, periodoMovimiento, estadoMovimiento}) => {

  const [managePost] = useManagePostRequest();

  const ActivarAnularSolicitudVacaciones = async() => {
    await managePost(activarAnularSolicitudVacacionesAPI(idSolicitudMovimiento),{}, () => {
        refreshPage();
        showModal();
    })
  }

  return (
    <GenericModal showModal={showModal} width={"620px"} titulo={estadoMovimiento ? "Activar solicitud vacaciones" : "Anular solicitud vacaciones"}>
        <div className="modal-body pt-0 pl-0">
            <div className="container-fluid">
                Se va a {estadoMovimiento ? "Activar" : "Anular"} la siguiente solicitud guardada, con periodo:
                <ul>
                    <li><strong>{periodoMovimiento}</strong></li>
                </ul>
                ¿Está seguro de esta acción?
            </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
            <div className="button">
                <button className="btn btn-primary generic-button-ficha mr-2"
                    onClick={() => {showModal()}}
                >
                    Cancelar
                </button>
                <button className="btn btn-primary generic-button-ficha"
                    onClick={() => {ActivarAnularSolicitudVacaciones()}}>
                    {estadoMovimiento ? "Activar" : "Anular"}
                </button>
            </div>
        </div>
    </GenericModal>
  )
}

export default EliminarSolicitudVacaciones