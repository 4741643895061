import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import IconCheck from '../icons/IconCheck';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useClickOutside from "../../hooks/useClickOutside/useClickOutside";
import {notNullAndNotUndefinded} from "../../helper/utils";
import { getCurrentDate } from '../../helper/utils';
import { useForm } from "../../hooks/useForm/UseForm";


const Select = styled.div`
  font-size: 12px;
  position: relative;
  cursor: pointer;
  margin-top: 0px;
  .description {
    height: 40px;
    width: 100%;
    margin: auto;
    border-radius: 6px;
    padding-top:8px;
    font-family: GothamLight;
    color: #565656;
    font-size: 1rem;
  }


  @media (max-width: 992px) {
    .description {
      border: 1px solid #8b9099;
      font-size: 12px;
    }
    margin-bottom: 10px;
    margin-top: 0px;
  }
`;

export const Li = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 12px 10px;
  height: auto;
  width: 100%;
`;

export const Marker = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  svg {
    opacity: 0;
    fill: #fff;
  }
`;

export const Input = styled.input`
  font-family: GothamLight;
  opacity: 0;
  position: absolute;
  z-index: -1;
  &:checked {
    + ${Marker} {
      background-color: #1890ff;
      border-color: #1890ff;
      svg {
        opacity: 1;
      }
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  color: #595959;
`;

const FormDatePicker = ({description, fechaInicio, fechaFin, setFechaInicio, setFechaFin, searchByFechas}) => {

    const [open, setOpen] = useState(false);
    const [minFechaFin, setMinFechaFin] = useState(null);

    let selectContainer = useClickOutside(() => {
        setOpen(false);
    });

    let title = description;

    useEffect(() => {
        if(fechaInicio) {
            var date = new Date(fechaInicio);
            date.setDate(date.getDate() + 2);
            setMinFechaFin(getCurrentDate(date));
        } else {
            setFechaFin(null);
        }
    }, [fechaInicio]);

    return (
        <div ref={selectContainer}>
            <Select>
                <div className='description font-gothan-medium hand-pointer' onClick={() => setOpen(!open)}>
                    {title} <FontAwesomeIcon size={"xs"} icon={['fas', 'filter']} transform="right-2" className="hand-pointer change-requested-field-clock"/>
                </div>
                <div className="custom-scroll">
                    <div className={(open === true) ? 'filters-modal-open modal-Filtros' : 'filters-modal-closed modal-Filtros'}>
                        <div className="row">
                            <div className="col-sm-12 col-md-12">
                                <div className="custom-select-options p-2">
                                    <Label className="col col-form-label text-justify">Fecha de incio</Label>
                                    <input type="date" className="form-control text-justify" onChange={(e) => {setFechaInicio(e.target.value == "" ? null : e.target.value);}} name="FechaInicio" value={fechaInicio} />
                                </div>
                            </div>
                            { <div className="col-sm-12 col-md-12">
                                <div className="custom-select-options p-2">
                                    <Label className="col col-form-label text-justify">Fecha de fin</Label>
                                    <input type="date" className="form-control text-justify" onChange={(e) => {setFechaFin(e.target.value == "" ? null : e.target.value);}} name="FechaFin" value={fechaFin} min={minFechaFin} />
                                </div>
                            </div>}
                        </div>
                        <div className='listar-aplicar-filtros-button font-gothan-medium' onClick={() => {
                            searchByFechas();
                            setOpen(!open);
                        }}>
                            Aplicar Filtros
                        </div>
                    </div>
                </div>
            </Select>
        </div>
    );
};

export default FormDatePicker;
