import React, { useState, useEffect } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { getAllSedesAPI, getLastCargaEvaluacionApi360API, saveCargarConfiguracionApi360API } from '../../../consumers/backendApisUrls';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";

const CargaAPI360 = ({showModal, refreshTable, idProcesoCierre}) => {
    const sectionName = "CargarEvlauacionApi360";
    const keys = ["IdSede", "Url", "Anio", "Dni"]
    const [submitForm] = useSubmitForm();
    const [executeGetRequest] = useManageGetRequest();
    const [getOptions] = useGetOptionsSelect();

    const [allSedes, setAllSedes] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [fechaDate, setFechaDate] = useState("");
    const [handleChange] = useForm(formValues, setFormValues);

    const [loading, setLoading] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const getInitialData = async () => {
        setLoading(true);
        try {
            setAllSedes(await getOptions(getAllSedesAPI()));
            await executeGetRequest(getLastCargaEvaluacionApi360API(), successCallBack)
        } catch (error) {
            console.error(error)
        }
        setLoading(false);
    }

    const successCallBack = (response) => {
        
        let data = Object.keys(response?.data).length === 0 ? {} : response?.data;
        data.IdProcesoCierre = idProcesoCierre
        let fechaDate = data?.Anio == null ? "" : new Date(`${data.Anio}-01-01T05:00:00Z`);
        setFormValues(data);
        setFechaDate(fechaDate);
    }

    const onSubmit = async () => {
        setButtonDisabled(true);
        await submitForm(keys, saveCargarConfiguracionApi360API(), formValues, sectionName, showModal);
        setButtonDisabled(false);
    }

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, [])

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        )
    }

    return (
        <GenericModal showModal={showModal} width={"600px"} titulo={"Configurar API"}>
            <div className='modal-body'>
                <form>
                    <div className='form-group row'>
                        <label className="col-sm-3 col-form-label modal-label">URL<span className="required">*</span></label>
                        <div className='col-sm-9 black-color'>
                            <input type="text" className="form-control" name="Url" onChange={handleChange} value={formValues?.Url ?? ""}></input>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Url-validation-message`}></p>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className="col-sm-3 col-form-label modal-label">Año<span className="required">*</span></label>
                        <div className='col-sm-9 black-color'>
                            <div className='date-picker-width-adjust'>
                                <DatePicker className='form-control' selected={formValues?.Anio !== null ? fechaDate : ""} onChange={(date) => { setFechaDate(date); setFormValues({ ...formValues, "Anio": date?.toISOString().split("-")[0] }) }} showYearPicker dateFormat="yyyy" minDate={new Date("01-01-1990")} maxDate={new Date("01-01-2030")} />
                            </div>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Anio-validation-message`}></p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label modal-label">Sede<span className="required"></span></label>
                        <div className='col-sm-9'>
                            <SelectInput className="ml-2 mr-2" options={allSedes} selected={formValues} setSelected={setFormValues} placeholder="Seleccionar Sede" />
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdSede-validation-message`}></p>
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className="col-sm-3 col-form-label modal-label">DNI</label>
                        <div className='col-sm-9 black-color'>
                            <input type="text" className="form-control" name="Dni" onChange={handleChange} value={formValues?.Dni ?? ""}></input>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Dni-validation-message`}></p>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha" onClick={onSubmit} disabled={buttonDisabled}>{buttonDisabled ? "Importando" : "Importar"} {buttonDisabled && <>&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner" /></>} </button>
                </div>
            </div>
        </GenericModal>
    )
}

export default CargaAPI360;

const SelectInput = ({ options, selected, setSelected, placeholder }) => {

    const handleChange = (e) => {
        setSelected({ ...selected, "IdSede": e?.value });
    }

    return (
        <div>
            <Select
                placeholder={placeholder}
                value={options.filter(o => o.value == selected.IdSede)}
                options={options}
                onChange={handleChange}
                isClearable
            />
        </div>
    )
}