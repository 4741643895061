import React, { useState, useEffect } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import Wrapper from "../../../components/atoms/DirectorioWrapper";
import PageHeader from "../../../components/pageHeader/PageHeader";
import PageButtons from "../../../components/pageComponents/PageButtons";
import PaginatorV2 from "../../../components/pageComponents/PaginatorV2";
import SpinnerLoading from "../../../components/pageComponents/SpinnerLoading";
import SearchContainer from "../../../components/pageComponents/SearchContainer";
import TableWebBody from "./TableWebBody";
import CrearListaDocumentos from "./modales/CrearListaDocumentos";
import { getConfiguracionListaDocumentosIndexAPI } from "../../../consumers/backendApisUrls";

const ConfigurarListaDocumentos = () => {

  const [formValues, setFormValues] = useState([]);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false });
  const [loading, setLoading] = useState(true);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1})

  const [executePost] = useManagePostRequest();

  const init = async () => {
    setLoading(true);
    try{
      await manageSearch();
    } catch(error){
      console.error(error);
    }
    setLoading(false);
  }   

  const manageSearch = async () => {
    const api = getConfiguracionListaDocumentosIndexAPI();
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria};
    await executePost(api, payload, successManageSearchCallback, false)
  }

  const successManageSearchCallback = async (response) => {
    setFormValues(response.data.Items);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage]);

  useEffect(()=>{
    init();
  },[]);

  if(loading) return <SpinnerLoading/>

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnabled.isEnable && (
          <div className="modal-contactanos modal-response-ficha-background">
            <modalEnabled.component toggleModal={() => setModalEnabled({ isEnable: false })} refreshPage={manageSearch} {...modalEnabled.data}/>
          </div>
        )}
        <Wrapper className="app">
          <PageHeader title={"Administración de lista de documentos"} />
          <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
            <PageButtons handleCreateButton={() => setModalEnabled({ isEnable: true, component: CrearListaDocumentos })} hasCreateButton hasBackButton/>
          </SearchContainer>
          <div className="container-fluid body-content">
            <div className="table-scroll pt-3">
              <table className="table table-hover">
                <thead className="listar-thead">
                  <tr className="font-gothan-medium">
                    <th className="pl-3">Item</th>
                    <th className="col-6">Nombre de lista</th>
                    <th className="col-5">Estado</th>
                    <th className="col-1">Acciones</th>
                  </tr>
                </thead>
                <TableWebBody
                  filas={formValues}
                  setModalEnabled={setModalEnabled}
                />
              </table>
              <PaginatorV2 paginator={paginator} setPaginator={setPaginator}/>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default ConfigurarListaDocumentos;
