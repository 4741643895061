import React, { useState } from 'react'
import { toast } from 'react-toastify';
import {useHistory} from "react-router-dom";
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputSearchAsync from '../../../components/modalComponents/InputSearchAsync';
import { TIPOS_CIERRE_REQUERIMIENTO } from '../../../constants/hardCodedConstants';
import { buscarColaboradorAPI, completarRequerimientoPersonalInternoAPI } from '../../../consumers/backendApisUrls'

const CompletarCerrarModal = ({ toggleModal, toggleParent, refreshPage, id }) => {

  const SECTION_NAME = `CompletarRequerimientoPersonal`;
  const KEYS = ["IdEmpleadoPromocionTraslado"];

  const [formValues, setFormValues] = useState({formaCobertura: "NuevoIngreso"});
  const [isSending, setIsSending] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const history = useHistory();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      if(formValues.formaCobertura === "NuevoIngreso"){
        history.push(`/adminGestionRequerimientos/crearNuevoIngreso/${id}`);
      } else { 
        if (formValues.IdEmpleadoPromocionTraslado === undefined || formValues.IdEmpleadoPromocionTraslado === null){
          return toast.warn("Debe seleccionar un colaborador");
        }
        const api = completarRequerimientoPersonalInternoAPI();
        const payload = { 
          IdRequerimientoPersonal: parseInt(id),
          IdTipoCierreRequerimiento: TIPOS_CIERRE_REQUERIMIENTO.PromocionTraslado,
          IdEmpleadoPromocionTraslado: formValues.IdEmpleadoPromocionTraslado,
        };
        await submitForm(KEYS, api, payload, SECTION_NAME, successOnSubmitCallback)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successOnSubmitCallback = () => {
    toggleParent();
    refreshPage();
  }

  return (
    <ModalForm onSubmit={onSubmit} showModal={toggleModal} titulo="Completar Requerimiento" width="700px" saveButtonDisabled={isSending}>
      <div className=''>
        <label className='modal-label'>Ingresa forma de cobertura de vacante:</label>
        <div className='d-flex flex-wrap flex-column align-content-center text-center'>
          <div className='d-flex' style={{gap: "10px"}}>
            <input type='radio' id='nuevoIngresoId' className='' checked={formValues.formaCobertura === "NuevoIngreso"} name='formaCobertura' onChange={handleChange} value="NuevoIngreso"/>
            <label className='form-check-label' htmlFor="nuevoIngresoId">
              Nuevo Ingreso (Nuevo/Reingreso)
            </label>
          </div>
          <div className='d-flex' style={{gap: "10px"}}>
            <input type='radio' id='MovimientoInternoId' className='' checked={formValues.formaCobertura === "MovimientoInterno"} name='formaCobertura' onChange={handleChange} value="MovimientoInterno"/>
            <label className='form-check-label' htmlFor="MovimientoInternoId">
              Movimiento Interno (Promoción/traslado)
            </label>
          </div>
        </div>
      </div>
      {formValues.formaCobertura === "MovimientoInterno" &&
        <div className='mt-4'>
          <InputSearchAsync typeGet label='Colaborador' api={buscarColaboradorAPI} {...commonProps('IdEmpleadoPromocionTraslado')} />
        </div>
      }
    </ModalForm>
  )
}

export default CompletarCerrarModal;

