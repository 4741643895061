import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import GenericModal from "../../../components/modal/GenericModal";
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { getEditarComponenteAPI, editarComponenteAPI } from '../../../consumers/backendApisUrls';

const EditarComponente = ({ toggleModal, refreshPage, componenteId}) => {

    const SECTION_NAME = "AgregarNuevoComponente";
    const KEYS = {Name: "NombreComponente", Component: "Componente"};
    
    const [formValues, setFormValues] = useState({});
    const [isSending, setIsSending] = useState(false);

    const [handleChange] = useForm(formValues, setFormValues);

    const [submitForm] = useSubmitForm();
    const [manageGetRequest] = useManageGetRequest();

    const init = async () => {
        await manageGetRequest(getEditarComponenteAPI(componenteId), (res) => setFormValues(res.data), false);
    }

    const submit = async () => {
        const api = editarComponenteAPI(componenteId);
        let payload = { ...formValues, IdComponente: componenteId };
        setIsSending(true);
        await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setIsSending(false));
    }

    const successSubmitCallback = () => {
        setIsSending(false);
        refreshPage();
        toggleModal();
    }
    
    useEffect(() => {
        init();
    },[])
    
    return (
        <>
            <GenericModal showModal={toggleModal} width={"1500px"} titulo={"Editar componente"}>
                <div className="modal-edit-card-content-modal">
                    <div className="modal-edit-card-form">
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div>
                                <label className="col-sm-3 col-form-label modal-label required">Nombre</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="Ingresar nombre" name={KEYS.Name} onChange={handleChange} value={formValues[KEYS.Name] ?? ""}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Name}-validation-message`}></p>
                                </div>
                            </div>
                            <div>
                                <label className="col-sm-3 col-form-label modal-label required">
                                    Componente
                                </label>
                                <div className="col-sm-9">
                                    <textarea name={KEYS.Component} id="editComponente" 
                                        cols="57" 
                                        rows="12"
                                        value={formValues[KEYS.Component] ?? ""}
                                        onChange={handleChange}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Component}-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                        <div className='modal-edit-card-preview-container card' style={{background: 'white'}}>
                            <div>
                                <div className="card-body">
                                    {parse(`${formValues[KEYS.Component] ?? ""}`)}
                                </div>
                            </div>
                        </div>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button
                            className='btn btn-primary generic-button-ficha-modal'
                            onClick={submit}
                            disabled={isSending}>
                            Guardar
                        </button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default EditarComponente;