import React from 'react'

const ModalMensajeSuccessfull = ({ modalMensaje, image, toggleModal, manageSearch, hasBackButton = true }) => {

  const closeAll = () => {
    toggleModal();
    manageSearch();
  }

  return (
    <div className="modal-response-ficha-background">
    <div className="modal-response-ficha-content pb-4" style={{width: "500px"}}>
      <div style={{width: "200px", height: "200px"}}>
        <img className="w-100 h-100" src={image} alt="confetti" />
      </div>
      <span className="green-innova font-gothan-bold" style={{fontSize: "20px"}}>¡Excelente!</span>
      <span className='modal-response-ficha-content-message px-5 text-center'>
        {modalMensaje}
      </span>
      {hasBackButton && <button className="modal-response-ficha-content-button" onClick={closeAll}> Volver a actividades </button>}
    </div>
  </div>
  )
}

export default ModalMensajeSuccessfull