import React, { useEffect, useState } from "react";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";
import {
    agregarEmpleadoIdiomaAPI,
    getCertificacionesIdiomaAPI,
    getIdiomasAPI,
    getNivelesIdiomaAPI,
    getRelatedDataTypesAPI
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useForm } from "../../../hooks/useForm/UseForm";
import SelectInput from "../../../components/Select/SelectInput";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { Spinner } from "react-bootstrap";
import { jsonNoEstaVacio } from "../../../helper/utils";
import { declaracionJurada } from "../../../constants/messages";
import { clearAllInputValidationMessages } from "../../../validations/FormValidatorUtils";

const AgregarEmpleadoIdiomaModal = ({
    toggleCurrentModal,
    empleadoId,
    reFetchList,
    boolHasIdioma
}) => {
    const sectionName = "AgregarEmpleadoIdiomaModal";
    const [getOptions] = useGetOptionsSelect();

    let allInputKeys = ["DeclaracionJurada", "IdIdioma", "IdNivelIdioma", "IdCertificacionIdioma", "Sustento"]
    const [formValues, setFormValues] = useState({ "DeclaracionJurada": false });
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const ID_SUSTENTO_INPUT_FILE = "SustentoEmpleadoIdioma";
    const EXTENSIONES_VALIDAS = ["pdf", "png", "jpg"];
    const [sustentoFile, setSustentoFile] = useState({});

    const [idiomaOptions, setIdiomaOptions] = useState([]);
    const [jsonSelectedIdioma, setJsonSelectedIdioma] = useState({});

    const [nivelOptions, setNivelOptions] = useState([]);
    const [jsonSelectedNivel, setJsonSelectedNivel] = useState({});

    const [certificacionOptions, setCertificacionOptions] = useState([]);
    const [jsonSelectedCertificacion, setJsonSelectedCertificacion] = useState({});

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [loading, setLoading] = useState(true);

    const [subiendoArchivos, setSubiendoArchivos] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (jsonNoEstaVacio(jsonSelectedIdioma)) {
            getRelatedDataTypes(jsonSelectedIdioma?.value)
            setJsonSelectedCertificacion({ value: "" });
        }
    }, [jsonSelectedIdioma]);

    useEffect(() => {
        if(formValues?.DeclaraInformacionDeIdiomas == true)
            clearAllInputValidationMessages(allInputKeys, sectionName)
    },[formValues?.DeclaraInformacionDeIdiomas])

    const getRelatedDataTypes = async (idParent) => {
        let api = getRelatedDataTypesAPI(idParent);
        setCertificacionOptions(await getOptions(api));
    };

    const init = async () => {
        try {
            setLoading(true);
            setIdiomaOptions(await getOptions(getIdiomasAPI()));
            setNivelOptions(await getOptions(getNivelesIdiomaAPI()));
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const successSubmitCallback = () => {
        reFetchList();
        toggleCurrentModal();
    }

    const submit = async () => {
        setDisableSubmitButton(true);

        let payload = {
            "IdEmpleado": empleadoId,
            "DeclaracionJurada": formValues["DeclaracionJurada"],
            "IdIdioma": jsonSelectedIdioma?.value,
            "IdNivelIdioma": jsonSelectedNivel?.value,
            "IdCertificacionIdioma": jsonSelectedCertificacion?.value,
            "Sustento": sustentoFile,
            "DeclaraInformacionDeIdiomas": !formValues?.DeclaraInformacionDeIdiomas
        }

        let api = agregarEmpleadoIdiomaAPI();
        await submitForm(allInputKeys, api, payload, sectionName, successSubmitCallback);
        setDisableSubmitButton(false);
    }

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        );
    }

    return (
        <>
            <GenericModal showModal={toggleCurrentModal} width={"790px"} titulo={"Agregar Idioma"}>
                <div className="modal-body pt-0 pl-0 pb-0">
                    <form>
                        <div className="mb-2">
                            <label className="ficha-sub-title-color">
                                <strong>Registra aquí si has seguido un curso de idiomas</strong>
                            </label>
                        </div>
                        {boolHasIdioma == null &&
                        <div className="form-check margin-top-10px mb-2">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="ficha-agregar-agregarEstudio-DeclaraInformacionDeIdiomas"
                              name="DeclaraInformacionDeIdiomas"
                              checked={formValues.DeclaraInformacionDeIdiomas}
                              onChange={handleChange}
                            />
                            <label
                              className="form-check-label ficha-declaracion-jurada-message"
                              htmlFor="ficha-agregar-agregarEstudio-DeclaraInformacionDeIdiomas"
                            >
                              <strong>
                                <p>
                                    No tengo conocimiento de idioma que reportar
                                </p>
                              </strong>
                            </label>
                        </div>}
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Idioma<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={idiomaOptions}
                                    jsonSelectedValue={jsonSelectedIdioma}
                                    setJsonSelectedValue={setJsonSelectedIdioma}
                                    placeholder={"Seleccionar Idioma"} 
                                    isDisabled={formValues.DeclaraInformacionDeIdiomas}/>
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdIdioma-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Nivel<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={nivelOptions}
                                    jsonSelectedValue={jsonSelectedNivel}
                                    setJsonSelectedValue={setJsonSelectedNivel}
                                    placeholder={"Seleccionar Nivel"} 
                                    isDisabled={formValues.DeclaraInformacionDeIdiomas}/>
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdNivelIdioma-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Certificación<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={certificacionOptions}
                                    jsonSelectedValue={jsonSelectedCertificacion}
                                    setJsonSelectedValue={setJsonSelectedCertificacion}
                                    placeholder={"Seleccionar Certificación"} 
                                    isDisabled={formValues.DeclaraInformacionDeIdiomas}/>
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdCertificacionIdioma-validation-message`}></p>
                            </div>
                        </div>
                        {!formValues.DeclaraInformacionDeIdiomas && <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Sustento<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <GenericUploadFileV2
                                    id={ID_SUSTENTO_INPUT_FILE}
                                    extensionesValidas={EXTENSIONES_VALIDAS}
                                    uploadFileToS3={setSustentoFile}
                                    textButton={"Subir archivo"}
                                    subiendoArchivoPublicState={subiendoArchivos}
                                    setSubiendoArchivoPublicState={setSubiendoArchivos}
                                />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                            </div>
                            <div>
                                <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                                    <strong>* El formato permitido es un .pdf, .jpg, .png de máximo 8MB.</strong>
                                </label>
                            </div>
                        </div>}
                    </form>
                </div>
                {!formValues.DeclaraInformacionDeIdiomas && 
                <div className="form-check margin-top-10px">
                    <input className="form-check-input" type="checkbox" name="DeclaracionJurada"
                        checked={formValues.DeclaracionJurada} onChange={handleChange}>
                    </input>
                    <label className="form-check-label ficha-declaracion-jurada-message">
                        <strong>
                            <p className="mb-0">{declaracionJurada}</p>
                        </strong>
                    </label>
                    <p className="ficha-form-input-validation-message"
                        id={`ficha-edit-${sectionName}-DeclaracionJurada-validation-message`}></p>
                </div>}
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button
                            className="btn btn-primary generic-button-ficha-modal"
                            onClick={submit}
                            disabled={disableSubmitButton || subiendoArchivos}>
                            Guardar
                        </button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default AgregarEmpleadoIdiomaModal;
