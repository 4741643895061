import React from 'react'
import CustomAlert from './CustomAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DEFAULT_ALERT_MESSAGE = "Para validar tus actualizaciones te pediremos adjuntar un archivo PDF o imagen. ¡Tenlo a mano!";

const CustomCardAlert = ({ variant, defaultAlertMessage = DEFAULT_ALERT_MESSAGE }) => {
  return (
    <CustomAlert variant={variant}>
      <p className="ficha-secciones-title-text">
          {defaultAlertMessage}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer"/>
      </p>
    </CustomAlert>
  )
}

export default CustomCardAlert