import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import IconCheck from '../icons/IconCheck';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import useClickOutside from '../../hooks/useClickOutside/useClickOutside';

const SelectSearch = styled.div`
  font-size: 12px;
  position: relative;
  cursor: pointer;
  margin-top: 0px;

  .description {
    width: 100%;
    margin: auto;
    border-radius: 6px;
    font-family: GothamMedium;
    cursor: pointer;
    color: #565656;
    font-size: 12px;
  }
`;

export const Li = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 4px 10px;
  height: auto;
  width: 100%;
  margin-bottom: 1px;
`;

export const Marker = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  svg {
    opacity: 0;
    fill: #fff;
  }
`;

export const Input = styled.input`
  font-family: GothamLight;
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked {
    + ${Marker} {
      background-color: #1890ff;
      border-color: #1890ff;

      svg {
        opacity: 1;
      }
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  color: #595959;
  font-size: 11px;
  font-family: GothamLight;
  text-align: justify;
`;

export const InputSearch = styled.input`
  width: 93%;
  padding: 10px 20px;
  margin: 5px;
  border: 1px solid #cccccc;
  border-radius: 50px;
  color: #000000;
  height: 35px;
  width: 93%;
  margin: 5px;
  font-size: 12px;

  ::placeholder{
    color: #707070;
  }
`;

export const Overlay = styled.div`
  width: ${props => props.width ? `${props.width} !important` : "200px !important"};
`;

const TableSelectSearch = ({ width, description, options, selectedOptions, setSelectedOptions }) => {
  const [searchCriteria, setSearchCriteria] = useState("");
  const [optFiltered, setOptFiltered] = useState([]);
  const [open, setOpen] = useState(false);

  let selectContainer = useClickOutside(() => {
    setOpen(false);
  });

  const handlerAplicarFiltro = () => {
    setSearchCriteria("");
    const selected = optFiltered.filter(o => o.isChecked).map(o => o.Value);
    setSelectedOptions(selected);
    setOpen(!open);
  }

  const handlerOptSelected = (e) => {
    let { value, checked } = e.target;
    let filtered = optFiltered.map(o => {
      if (o.Value == value) return {...o, isChecked: checked}
      return o;
    });
    setOptFiltered(filtered);
  }

  useDidMountEffect(() => {
    let filtered = options.filter(o => o.Text.toLowerCase().includes(searchCriteria.toLowerCase()));
    setOptFiltered(lastData => {
      const newFiltered = filtered.map(o => {
        const found = lastData.find(x => x.Value == o.Value);
        if (found) {
          return { ...o, isChecked: found.isChecked }
        }
        return o;
      })
      return newFiltered;
    });
  }, [searchCriteria])
  
  useDidMountEffect(() => {
    if (selectedOptions?.length === 0){
      setOptFiltered(options);
    }
  }, [selectedOptions])

  useEffect(() => {
    let checkeds = options?.map(o => {
      const checked = optFiltered?.find(x => x.Value === o.Value)
      if (checked) return {...o, isChecked: checked.isChecked}
      return o;
    })
    setOptFiltered(checkeds);
  }, [options])

  return (
    <SelectSearch ref={selectContainer}>
      <div className="description" onClick={() => setOpen(!open)}>
        {description} <FontAwesomeIcon size={"xs"} icon={['fas', 'filter']} className="hand-pointer change-requested-field-clock"/>
      </div>
      <div className='custom-scroll'>
        <Overlay className={`filters-modal-${open ? "open" : "closed"} modal-Filtros`} width={width}>
          <div className="custom-select-options text-wrap">
            <InputSearch 
              type='text' 
              className='input-buscador' 
              placeholder='Buscar nombre' 
              onChange={(e) => setSearchCriteria(e.target.value)}
              value={searchCriteria}
            />
            {optFiltered?.map((option, i) => (
              <Li key={i}>
                  <Input
                      checked={option.isChecked ?? false}
                      value={option.Value ?? null}
                      type='checkbox'
                      onChange={handlerOptSelected}
                  />
                  <Marker>
                      <IconCheck/>
                  </Marker>
                  <Label>{option.Text}</Label>
              </Li>
            ))}
          </div>
          <div className='listar-aplicar-filtros-button font-gothan-medium' onClick={() => handlerAplicarFiltro()}>
              Aplicar Filtros
          </div>
        </Overlay>
      </div>
    </SelectSearch>
  )
}

export default TableSelectSearch