import React, { useRef, useEffect, useMemo } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const EditorQuill = ({ value, handleChange, placeholder, minHeight = "12rem", maxHeight = "20rem" }) => {
  const dropdownQuillRef = useRef(null);

  const setContainerStyles = () => {
    document.querySelector(".ql-container").style.minHeight = minHeight;
    document.querySelector(".ql-editor").style.minHeight = minHeight;

    document.querySelector(".ql-container").style.maxHeight = maxHeight;
    document.querySelector(".ql-editor").style.maxHeight = maxHeight;
  };

  const genericDropdownHandler = (value, boolNewLine = false) => {
    if (value && dropdownQuillRef.current) {
      const commaIndex = value.indexOf(",");

      const cursorPosition = dropdownQuillRef.current.getEditor().getSelection().index;
      dropdownQuillRef.current.getEditor().insertText(cursorPosition, value.slice(commaIndex + 1) + (boolNewLine ? "\n\n" : ""));
      dropdownQuillRef.current.getEditor().setSelection(cursorPosition + value.length + (boolNewLine ? 2 : 0));
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"],
        ],
        handlers: {
          componentes: (value) => genericDropdownHandler(value, true),
          tags: (value) => genericDropdownHandler(value),
        },
      },
    }),
    []
  );

  useEffect(() => {
    setContainerStyles();
  }, [])

  return (
    <div className="text-editor" name="ReactQuillEditor" style={{ borderSpacing: "1px", minHeight: "7rem" }}>
      <ReactQuill
        key="uniqueReactQuillEditor"
        theme="snow"
        onChange={handleChange}
        value={value ?? ""}
        modules={modules}
        placeholder={placeholder}
        ref={dropdownQuillRef}
      />
    </div>
  );
}

export default EditorQuill