import React, { useState } from 'react'
import { jsonEstaVacio } from '../../../helper/utils';
import GenericModal from '../../../components/modal/GenericModal';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { cargarTRegistroFirmadoNuevosIngresosAPI, getTRegistroFileSinFirmaByIdEmpleadoFlujoActividadAPI } from '../../../consumers/backendApisUrls';

const FirmaCartaTRegistro = ({ toggleModal, manageSearch, idEmpleadoFlujoActividad }) => {

  const idUploadFile = 'uploadTRegistro';
  const validExtensions = ['pdf'];
  const KEYS = { id: "IdEmpleadoFlujoActividad", file: "FileViewModel" };
  const SECTION_NAME = "CargarFirmaTRegistro";

  const [FileViewModel, setFileViewModel] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloadFile] = useDescargarArchivoDeS3();

  const handleDownload = async () => {
    setIsDownloading(true);
    const api = getTRegistroFileSinFirmaByIdEmpleadoFlujoActividadAPI(idEmpleadoFlujoActividad);
    try {
      const response = await executeGet(api);
      if (!responseCode200(response)) return mostrarMensajeDeErrorConToast(response);

      const {OriginalFileName, FilePath} = response.data;
      await downloadFile(OriginalFileName, FilePath);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = cargarTRegistroFirmadoNuevosIngresosAPI();
    const payload = { IdEmpleadoFlujoActividad: idEmpleadoFlujoActividad, FileViewModel };
    try {
      await executeManagePost(api, payload, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmitCallback = () => {
    manageSearch();
    toggleModal();
  }

  return (
    <GenericModal showModal={toggleModal} titulo='Firmar T-Registro' width='750px'>
      <form className='modal-body pb-0' onSubmit={handleSubmit}>
        <InputUploadFile id={idUploadFile} label='Archivo' name={KEYS.file} uploadFile={FileViewModel} setUploadFile={setFileViewModel} isUploading={isUploading} setIsUploading={setIsUploading} validExtensions={validExtensions} sectionName={SECTION_NAME}/>
        <div className="modal-footer justify-content-end">
          <div className="d-flex justify-content-between" style={{ gap: "15px" }}>
            <button type="button" className="btn generic-button-buscador" disabled={isDownloading || isUploading || isSubmitting} onClick={handleDownload}>
              Descargar Formato
            </button>
            <button type="submit" className="btn generic-button-buscador" disabled={isSubmitting || isUploading || jsonEstaVacio(FileViewModel)}>
              Cargar
            </button>
          </div>
        </div>
      </form>
    </GenericModal>
  )
}

export default FirmaCartaTRegistro