import React, { useEffect, useState } from "react";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";
import {
  agregarEstudioAdicionalAPI,
  getEstadosCursoAPI,
  getPaisesAPI,
  getTiposCursoAPI,
  getSugerenciasDeNombreInstitucionEstudioAdicionalAPI,
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useForm } from "../../../hooks/useForm/UseForm";
import { Spinner } from "react-bootstrap";
import SelectInput from "../../../components/Select/SelectInput";
import { estadoFormacion } from "../../../constants/states";
import AutoSuggestTextInput from "../../../components/AutoSuggestTextInput/AutoSuggestTextInput";
import { declaracionJurada } from "../../../constants/messages";
import { clearAllInputValidationMessages } from "../../../validations/FormValidatorUtils";

const AgregarEstudio = ({ toggleModal, empleadoId, refresh, boolHasEstudio }) => {
  const sectionName = "AgregarEstudio";
  let allInputKeys = ["NombreEstudio", "NombreInstitucion", "IdPaisInstitucion", "IdTipoEstudio", "IdEstado", "FechaFinalizacion", "FechaInicio", "FileViewModel", "AceptaDeclaracionJurada"];

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const ID_INPUT_FILE = "AgregarSustento";
  const EXTENSIONES_VALIDAS = ["pdf", "png", "jpg"];

  const [paises, setPaises] = useState([]);
  const [tiposEstudio, setTiposEstudio] = useState([]);
  const [estados, setEstados] = useState([]);
  const [currentYear] = useState(new Date().getFullYear());

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [nombreInstitucionSelected, setNombreInstitucionSelected] = useState("");
  const [paisSeleccionado, setPaisSeleccionado] = useState({});
  const [tipoSeleccionado, setTipoSeleccionado] = useState({});
  const [estadoSeleccionado, setEstadoSeleccionado] = useState({});
  const [esFinalizado, setEsFinalizado] = useState(false);
  const [esConSustento, setEsConSustento] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const [subiendoArchivos, setSubiendoArchivos] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    setEsFinalizado(estadoSeleccionado.label != estadoFormacion.EnCurso.nombre)
    let boolSustento = estadoSeleccionado.label == estadoFormacion.Finalizado.nombre;
    setEsConSustento(boolSustento);
    !boolSustento && setUploadedFile({});
  }, [estadoSeleccionado])

  useEffect(() => {
    if (esFinalizado === false) {
      setFormValues({ ...formValues, FechaFinalizacion: "" });
      setUploadedFile({});
    }
  }, [esFinalizado]);

  useEffect(() => {
    if (formValues?.DeclaraInformacionDeEstudios == true)
      clearAllInputValidationMessages(allInputKeys, sectionName)
  },[formValues?.DeclaraInformacionDeEstudios])

  const init = async () => {
    try {
      setLoading(true);
      setPaises(await getOptions(getPaisesAPI()));
      setTiposEstudio(await getOptions(getTiposCursoAPI()));
      setEstados(await getOptions(getEstadosCursoAPI()));
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const successSubmitCallback = () => {
    refresh();
    toggleModal();
  };

  const submit = async () => {
    setDisableSubmitButton(true);

    let payload = {
      IdEmpleado: empleadoId,
      NombreEstudio: formValues?.NombreEstudio,
      NombreInstitucion: nombreInstitucionSelected,
      IdPaisInstitucion: paisSeleccionado?.value,
      IdTipoEstudio: tipoSeleccionado?.value,
      IdEstado: estadoSeleccionado?.value,
      FechaInicio: formValues?.FechaInicio,
      FechaFinalizacion: formValues?.FechaFinalizacion,
      AceptaDeclaracionJurada: formValues?.AceptaDeclaracionJurada,
      FileViewModel: uploadedFile,
      EsFinalizado: esFinalizado,
      EsConSustento: esConSustento,
      DeclaraInformacionDeEstudios: !formValues?.DeclaraInformacionDeEstudios
    };

    let api = agregarEstudioAdicionalAPI();
    await submitForm(allInputKeys, api, payload, sectionName, successSubmitCallback);
    setDisableSubmitButton(false);
  };

  if (loading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <>
      <GenericModal
        showModal={toggleModal}
        width={"950px"}
        titulo={"Agregar Estudio Adicional"}
      >
        <div className="modal-body pt-0 pl-0 pb-0">
          <form autoComplete="off">
            <div className="mb-2">
              <label className="ficha-sub-title-color">
                <strong>Registra aquí si has seguido un Seminario, Taller, Curso, Diplomado y/o Certificación</strong>
              </label>
            </div>
            {boolHasEstudio == null && 
            <div className="form-check margin-top-10px mb-2">
              <input
                className="form-check-input"
                type="checkbox"
                id="ficha-agregar-agregarEstudio-DeclaraInformacionDeEstudios"
                name="DeclaraInformacionDeEstudios"
                checked={formValues.DeclaraInformacionDeEstudios}
                onChange={handleChange}
              />
              <label
                className="form-check-label ficha-declaracion-jurada-message"
                htmlFor="ficha-agregar-agregarEstudio-DeclaraInformacionDeEstudios"
              >
                <strong>
                  <p>
                    No tengo curso adicional que reportar
                  </p>
                </strong>
              </label>
            </div>}
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Tipo de estudio<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <SelectInput options={tiposEstudio}
                  jsonSelectedValue={tipoSeleccionado}
                  setJsonSelectedValue={setTipoSeleccionado}
                  placeholder={"Seleccionar tipo de estudio"} 
                  isDisabled={formValues.DeclaraInformacionDeEstudios}/>
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-IdTipoEstudio-validation-message`}
                ></p>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Nombre de estudio<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <input
                  type="text"
                  className="form-control"
                  name="NombreEstudio"
                  onChange={handleChange}
                  value={formValues.NombreEstudio}
                  placeholder={"Máximo 100 caracteres"}
                  maxLength={"100"}
                  disabled={formValues.DeclaraInformacionDeEstudios}
                />
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-NombreEstudio-validation-message`}
                ></p>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Institución/Empresa<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <AutoSuggestTextInput
                  inputTextValue={nombreInstitucionSelected}
                  setInputTextValue={setNombreInstitucionSelected}
                  suggestionsPostApi={
                    getSugerenciasDeNombreInstitucionEstudioAdicionalAPI
                  }
                  placeholder={"Máximo 100 caracteres"}
                  maxLength={"100"}
                  disabled={formValues.DeclaraInformacionDeEstudios}
                />
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-NombreInstitucion-validation-message`}
                ></p>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                País de institución<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <SelectInput options={paises}
                  jsonSelectedValue={paisSeleccionado}
                  setJsonSelectedValue={setPaisSeleccionado}
                  placeholder={"Seleccionar país"} 
                  isDisabled={formValues.DeclaraInformacionDeEstudios}/>
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-IdPaisInstitucion-validation-message`}
                ></p>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Estado del estudio<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <SelectInput options={estados}
                  jsonSelectedValue={estadoSeleccionado}
                  setJsonSelectedValue={setEstadoSeleccionado}
                  placeholder={"Seleccionar estado del estudio"} 
                  isDisabled={formValues.DeclaraInformacionDeEstudios}/>
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-IdEstado-validation-message`}
                ></p>
              </div>
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Fecha inicio<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <input
                  type="date"
                  min={currentYear - 50 + "-01-01"}
                  max={currentYear}
                  value={formValues.FechaInicio}
                  onChange={handleChange}
                  className="form-control"
                  name="FechaInicio"
                  disabled={formValues.DeclaraInformacionDeEstudios}
                />
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}
                ></p>
              </div>
            </div>
            {esFinalizado && (
              <div className="form-group row mb-0">
                <label className="col-sm-3 col-form-label modal-label">
                  Fecha finalización<span className="required">*</span>
                </label>
                <div className="col-sm-9 black-color">
                  <input
                    type="date"
                    min={currentYear - 50 + "-01-01"}
                    max={currentYear}
                    value={formValues.FechaFinalizacion}
                    onChange={handleChange}
                    className="form-control"
                    name="FechaFinalizacion"
                    disabled={formValues.DeclaraInformacionDeEstudios}
                  />
                  <p
                    className="ficha-form-input-validation-message"
                    id={`ficha-edit-${sectionName}-FechaFinalizacion-validation-message`}
                  ></p>
                </div>
              </div>
            )}

            {esFinalizado && esConSustento && (
              <div className="form-group row mb-0">
                <label className="col-sm-3 col-form-label modal-label">
                  Sustento<span className="required">*</span>
                </label>

                <div className="col-sm-9 black-color">
                  <GenericUploadFileV2
                    id={ID_INPUT_FILE}
                    extensionesValidas={EXTENSIONES_VALIDAS}
                    uploadFileToS3={setUploadedFile}
                    textButton={"Subir archivo"}
                    subiendoArchivoPublicState={subiendoArchivos}
                    setSubiendoArchivoPublicState={setSubiendoArchivos}
                  />
                  <p
                    className="ficha-form-input-validation-message"
                    id={`ficha-edit-${sectionName}-FileViewModel-validation-message`}
                  ></p>
                </div>
                <div>
                  <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                    <strong>* El formato permitido es un .pdf, .jpg, .png de máximo 8MB.</strong>
                  </label>
                </div>
              </div>
            )}
          </form>
        </div>
        {!formValues.DeclaraInformacionDeEstudios &&
        <div className="form-check margin-top-10px">
          <input
            className="form-check-input"
            type="checkbox"
            id="ficha-agregar-agregarEstudio-AceptaDeclaracionJurada"
            name="AceptaDeclaracionJurada"
            checked={formValues.AceptaDeclaracionJurada}
            onChange={handleChange}
          ></input>
          <label
            className="form-check-label ficha-declaracion-jurada-message"
            htmlFor="AceptaDeclaracionJurada"
          >
            <strong>
              <p className="mb-0">
                {declaracionJurada}
              </p>
            </strong>
          </label>
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-AceptaDeclaracionJurada-validation-message`}
          ></p>
        </div>}
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button
              className="btn btn-primary generic-button-ficha-modal"
              onClick={submit}
              disabled={disableSubmitButton || subiendoArchivos}
            >
              Guardar
            </button>
          </div>
        </div>
      </GenericModal>
    </>
  );
};

export default AgregarEstudio;
