import React, {useContext} from 'react';
import GenericModal from "../modal/GenericModal";
import {AuthContext} from "../../hooks/context/authContext";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {deleteFileFromS3API, emitirConstanciaDeTrabajoAPI} from "../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {downloadFile} from "../../consumers/backendConsumer";
import {openGmail} from "../utils";


const ModalDescargarConstanciaDeTrabajo = ({showModal}) => {
    const {state} = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
        showModal();
    };

    const downloadConstanciaDeTrabajo = async (e) => {
        e.preventDefault();
        let payload = {
            "IdEmpleado": state.user.id_empleado
        };
        let responseConstanciaGenerada = await executePostRequest(emitirConstanciaDeTrabajoAPI(), payload);
        if (responseCode200(responseConstanciaGenerada)) {
            let descargarReporteResponse = await downloadFile(state.token, responseConstanciaGenerada?.data?.FilePath, responseConstanciaGenerada?.data?.OriginalFileName);
            if (responseCode200(descargarReporteResponse)) {
                await eliminarArchivoDeS3(responseConstanciaGenerada?.data?.FilePath);
            } else {
                mostrarMensajeDeErrorConToast(descargarReporteResponse);
            }
        } else {
            mostrarMensajeDeErrorConToast(responseConstanciaGenerada);
        }
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo={"Información sobre emisión de constancia"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div className="row black-color">
                            <p>El documento descargado de Intranet tendrá una fecha de emisión de 7 días previos a la fecha actual.</p>
                            {/* <p>En caso se requiera contar con el documento con la fecha actual, deben solicitarlo al correo <a onClick={() => openGmail("compensaciones@innovaschools.edu.pe")}
                                                                                                                               className="text-link-appereance hand-pointer">compensaciones@innovaschools.edu.pe</a></p> */}
                        </div>
                    </form>
                </div>
                <div className="d-flex justify-content-end mb-2">
                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={downloadConstanciaDeTrabajo}>Entendido</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalDescargarConstanciaDeTrabajo;
