import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import PageHeader from "../../../components/pageHeader/PageHeader";
import PageButtons from "../../../components/pageComponents/PageButtons";
import TabsHeader from "../../../components/pageComponents/TabsHeader";
import DatosPersonales from "./tabs/DatosPersonales";
import { KEYS_FORM_NUEVOS_INGRESOS as KEYS } from "../../../constants/hardCodedConstants";
import { crearNuevoIngresoAPI } from "../../../consumers/backendApisUrls";

const CrearFichaDatosNuevosIngresos = () => {

  const SECTION_NAME = "NuevaFichaNuevosIngresos";
  const history = useHistory();

  const [isSending, setIsSending] = useState(false);
  const [formValuesCreate, setFormValuesCreate] = useState({EsReingreso: false});

  const [submitForm] = useSubmitForm();

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = crearNuevoIngresoAPI();
      await submitForm(KEYS, api, formValuesCreate, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    history.push('/adminNuevosIngresos');
  }

  const OPTIONS = [{id: "DatosPersonales", title: "Datos Personales", component: <DatosPersonales sectionName={SECTION_NAME} setFormValuesData={setFormValuesCreate}/>}];

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile fixed-page-header">
        <PageHeader title="Nueva Ficha - Datos Colaborador" />
        <div className="generic-buscador-container mb-4">
          <label className="ml-2">(<span className="required">*</span>) Dato Obligatorio</label>
          <PageButtons hasBackButton>
            <button id="CreateNewEntry" type="button" className="btn btn-primary generic-button-ficha-modal" onClick={handleCreate} disabled={isSending}>
              Guardar
            </button>
          </PageButtons>
        </div>
      </div>
      <TabsHeader list={OPTIONS} tabFichaClass="tabFichaClass fixed-page-header first-section-separator"/>
    </div>
  );
};

export default CrearFichaDatosNuevosIngresos;
