import React, { useState, useEffect } from "react";
import {useHistory} from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { getListarVacacionesDeMiEquipoAPI, getListarVacacionesByJefeMatricialAPI, getAllAreasAPI, getAllCargosAPI, getAllEstadosAPI, descargarVacacionesAPI , descargarVacacionesByJefeMatricialAPI } from "../../../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import Wrapper from "../../../../components/atoms/DirectorioWrapper";
import PageHeader from "../../../../components/pageHeader/PageHeader";
import Paginator from "../../../directorio/Paginator";
import TableWebBody from "../../TableWebBody";
import FormSelect from "../../../../components/atoms/Select";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VerSolicitudVacaciones from "../../modales/VerSolicitudVacaciones";
import AprobarRechazarSolicitudVacaciones from "../../modales/AprobarRechazarSolicitudVacaciones";
import { useGenerarDescargarEliminarArchivo } from "../../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import ReasignarSolicitudVacaciones from "../../modales/ReasignarSolicitudVacaciones";
import AgregarNotaSolicitudVacaciones from "../../modales/AgregarNotaSolicitudVacaciones";
import ConfirmarRechazarVacaciones from "../../modales/ConfirmarRechazarVacaciones";
import { notNullAndNotUndefinded } from "../../../../helper/utils";
import useDidMountEffect from "../../../../hooks/useDidMountEffect/useDidMountEffect";
import CrearSolicitudVacacionesAdmin from "../../modales/CrearSolicitudVacacionesAdmin";
import ModalEnabled from "../../../../components/modalComponents/ModalEnabled";
import { TIPOS_LISTAR_VACACIONES } from "../../../../constants/hardCodedConstants";

const ListaSolicitudesVacaciones = ({ dropdownsAreas, dropdownsCargos, filterType  }) => {
  let history = useHistory();

  const [idMovimiento, setIdMovimiento] = useState();
  const [periodoVacaciones, setPeriodoVacaciones] = useState("");
  const [dataReasignar, setDataReasignar] = useState({});
  const [nombreEmpleado, setNombreEmpleado] = useState("");
  // const [dropdownsAreas, setDropdownsAreas] = useState([]);
  const [allAreas, setAllAreas] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  // const [dropdownsCargos, setDropdownsCargos] = useState([]);
  const [allCargos, setAllCargos] = useState([]);
  const [selectedCargos, setSelectedCargos] = useState([]);
  const [dropdownsEstados, setDropdownsEstados] = useState([])
  const [allEstados, setAllEstados] = useState([]);
  const [selectedEstados, setSelectedEstados] = useState([]);
  const [dropdownsNiveles, setDropdownsNiveles] = useState([])
  const [allNiveles, setAllNiveles] = useState([{},{}]);
  const [selectedNiveles, setSelectedNiveles] = useState([]);

  const [loading, setLoading] = useState(true);
  const [filas, setFilas] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [extraData, setExtraData] = useState({});
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [modalEnabled, setModalEnabled] = useState({isEnable: false, component: React.Fragment});
  
  const [showModalVerSolicitudVacaciones, setShowModalVerSolicitudVacaciones] = useState(false);
  const [showModalAprobarRechazarSolicitudVacaciones, setShowModalAprobarRechazarSolicitudVacaciones] = useState(false);
  const [showModalReasignarSolicitudVacaciones, setShowModalReasignarSolicitudVacaciones] = useState(false);
  const [showModalAgregarNotaSolicitudVacaciones, setShowModalAgregarNotaSolicitudVacaciones] = useState(false);

  const [showModalCrearSolicitudVacacionesAdmin, setShowModalCrearSolicitudVacacionesAdmin] = useState(false);

  const [executePost] = useManagePostRequest();
  const [executeGet] = useManageGetRequest();
  const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();



  const [showMensajeConfirmacionRechazar, setShowMensajeConfirmacion] = useState(false);
  const [payloadRechazarVacaciones, setPayloadRechazarVacaciones] = useState({});

  const toggleModalConfirmarRechazarVacaciones = () => {
      setShowMensajeConfirmacion(!showMensajeConfirmacionRechazar);
  }


  const init = async () => {
    setLoading(true);
    try {
      getDropDownOptions();
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getDropDownOptions = async () => {

    executeGet(getAllEstadosAPI(), (res) =>  setDropdownsEstados(res.data), false);
  }

  const manageSearch = async () => {


   const api = filterType != TIPOS_LISTAR_VACACIONES.Matricial.value ? getListarVacacionesDeMiEquipoAPI() : getListarVacacionesByJefeMatricialAPI();


    const payload = getPayload();
    await executePost(api, payload, successInitCallback);
  }

  const getPayload = () => {
    return {
      IdsAreas: selectedAreas,
      IdsCargos: selectedCargos,
      IdsEstados: selectedEstados,
      IdsNivelesAprobacion: selectedNiveles,
      page: page,
      Criteria: criterioDeBusqueda,
    };
  };
  
  const successInitCallback = (response) => {
    setFilas(response.data.Items);
    setTotalItems(response.data.TotalItems);
    setFirsItemPage(response.data.FirsItemPage);
    setLastItemPage(response.data.LastItemPage);
    setTotalPages(response.data.TotalPages);
    setExtraData(response.data.ExtraData);
  };

  const toggleVerSolicitudVacaciones = (idMovimiento) => {
    if (idMovimiento) setIdMovimiento(idMovimiento)
    setShowModalVerSolicitudVacaciones(!showModalVerSolicitudVacaciones);
  };

  const toggleAprobarRechazarSolicitudVacaciones = (idMovimiento, periodoVacaciones) => {
    if (idMovimiento) setIdMovimiento(idMovimiento)
    if (periodoVacaciones) setPeriodoVacaciones(periodoVacaciones);
    setShowModalAprobarRechazarSolicitudVacaciones(!showModalAprobarRechazarSolicitudVacaciones);
  };

  const toggleReasignarSolicitudVacaciones = (IdSolicitudMovimiento, NombreEmpleado, Aprobador, PeriodoVacaciones) => {
    if (IdSolicitudMovimiento) setIdMovimiento(IdSolicitudMovimiento)
    if (NombreEmpleado && Aprobador && PeriodoVacaciones) setDataReasignar({NombreEmpleado, Aprobador, PeriodoVacaciones})
    setShowModalReasignarSolicitudVacaciones(!showModalReasignarSolicitudVacaciones)
  }

  const toggleAgregarNotaSolicitudVacaciones = (IdSolicitudMovimiento, nombreEmpleado) => {
    if (IdSolicitudMovimiento) setIdMovimiento(IdSolicitudMovimiento)
    if (nombreEmpleado) setNombreEmpleado(nombreEmpleado)
    setShowModalAgregarNotaSolicitudVacaciones(!showModalAgregarNotaSolicitudVacaciones)
  }

  const toggleCrearSolicitudVacacionesAdmin = () => {
    setShowModalCrearSolicitudVacacionesAdmin(!showModalCrearSolicitudVacacionesAdmin);
  };

  const handleDescargarVacaciones = async () => {

    const api = filterType != TIPOS_LISTAR_VACACIONES.Matricial.value ? descargarVacacionesAPI() : descargarVacacionesByJefeMatricialAPI();
    const payload = getPayload();
    await generarDescargarEliminarArchivo(api, payload);
  }

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };
  
  const onRightClic = () => {
      const nextPage = Math.min(page + 1, totalPages);
      setPage(nextPage);
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
        manageSearch();
    }
  };

  const limpiarBusqueda = () => {
    setSelectedAreas([]);
    setSelectedCargos([]);
    setSelectedEstados([]);
    setSelectedNiveles([]);
    setCriterioDeBusqueda("");
  };

  const setFilterWithExtraData = () => {
    setAllEstados(dropdownsEstados.filter(estado => extraData.IdsEstados.includes(estado.Value)));
    setAllNiveles(extraData?.IdsNivelesAprobacion?.map(id => ({Text: `Nivel ${id}`, Value: id})));
  }

  useEffect(() => {
    notNullAndNotUndefinded(extraData) &&  setFilterWithExtraData();
  },[extraData])

  useDidMountEffect(() => {
    manageSearch();
  },[selectedAreas, selectedCargos, selectedEstados, selectedNiveles, page , filterType])

  useDidMountEffect(() => {
    setPage(1);
    //manageSearch();
  },[criterioDeBusqueda])

  useDidMountEffect(() => {
    if(dropdownsAreas.length > 0 && extraData.IdsAreas){
      setAllAreas(dropdownsAreas.filter(area => extraData.IdsAreas?.includes(area.Value)));
    }
    if(dropdownsCargos.length > 0 && extraData.IdsCargos){
      setAllCargos(dropdownsCargos.filter(cargo => extraData.IdsCargos?.includes(cargo.Value)));
    }
  }, [dropdownsAreas, dropdownsCargos, extraData.IdsAreas, extraData.IdsCargos])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);
  
  if (loading) {
    return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
    );
  }
  
  return (
    <>
      {showModalVerSolicitudVacaciones && 
        <div className="modal-contactanos modal-response-ficha-background">
          <VerSolicitudVacaciones showModal={toggleVerSolicitudVacaciones} idSolicitudMovimiento={idMovimiento}/>
        </div>
      }
      {showModalAprobarRechazarSolicitudVacaciones && 
        <div className="modal-contactanos modal-response-ficha-background">
          <AprobarRechazarSolicitudVacaciones showModal={toggleAprobarRechazarSolicitudVacaciones} refreshPage={manageSearch} idSolicitudMovimiento={idMovimiento} periodoVacaciones={periodoVacaciones}/>
        </div>
      }
      {showModalReasignarSolicitudVacaciones && 
        <div className="modal-contactanos modal-response-ficha-background">
          <ReasignarSolicitudVacaciones showModal={toggleReasignarSolicitudVacaciones} refreshPage={manageSearch} idSolicitudMovimiento={idMovimiento} dataReasignar={dataReasignar}/>
        </div>
      }
      {showModalAgregarNotaSolicitudVacaciones && 
        <div className="modal-contactanos modal-response-ficha-background">
          <AgregarNotaSolicitudVacaciones showModal={toggleAgregarNotaSolicitudVacaciones} refreshPage={manageSearch} idSolicitudMovimiento={idMovimiento} nombreEmpleado={nombreEmpleado}/>
        </div>
      }
      {showModalCrearSolicitudVacacionesAdmin && 
          <div className="modal-contactanos modal-response-ficha-background">
            <CrearSolicitudVacacionesAdmin showModal={toggleCrearSolicitudVacacionesAdmin} refreshPage={manageSearch}/>
          </div>
      }

      {showMensajeConfirmacionRechazar && 
         <div className="modal-contactanos modal-response-ficha-background">
            <ConfirmarRechazarVacaciones toggleModal={toggleModalConfirmarRechazarVacaciones}  payloadRechazarVacaciones={payloadRechazarVacaciones} refreshPage={manageSearch} setModalEnabled={setModalEnabled}/>
         </div>
      }
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <Wrapper className="App">
        <div className="generic-buscador-container">
          <div className="filtros-left-section">
            <input
                type='text'
                className='generic-input-criterio-busqueda'
                placeholder='Buscar por empleado'
                value={criterioDeBusqueda}
                onChange={(e) => { setCriterioDeBusqueda(e.target.value); }}
                onKeyPress={handleKeypress}
            />
            <button
                onClick={manageSearch}
                type='button'
                className='generic-button-buscador'>
                Buscar
            </button>
            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
          </div>
          <div className="filtros-right-section">
            {extraData.PuedeConfigurar && 
              <button
                onClick={toggleCrearSolicitudVacacionesAdmin}
                type="button"
                className="generic-button-buscador"
              >
                Solicitar Vacaciones
              </button>
            }
            <button
              onClick={handleDescargarVacaciones}
              type="button"
              className="generic-button-buscador"
            >
              Descargar
            </button>
            {extraData.PuedeConfigurar && 
              <span className="align-items-center p-2 ml-2" style={{ backgroundColor: 'gray', borderRadius: '50%' }} 
                  onClick={() => history.push('/adminGestionVacaciones/configurarVacaciones')}
              >
                  <FontAwesomeIcon icon='cog' size="lg" className="hand-pointer white" style={{ color: 'white' }} title="Configurar vacaciones" />
              </span>
            }
          </div>
        </div>
        <div className="container-fluid body-content">
          <div className="table-responsive form-group-sm table-scroll pt-2">
            <table className="table table-hover">
              <thead className="listar-thead">
                <tr>
                  <th className="" style={{width: "20px"}}>
                    <div className="flex font-gothan-medium" style={{width: "20px"}}>
                      Id
                    </div>
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">
                      Fch de Registro
                    </div>
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Empleado</div>
                  </th>
                  <th className="col-2">
                    <FormSelect 
                      options={allAreas}
                      description={"Areas"}
                      selectedOptions={selectedAreas}
                      setSelectedOptions={setSelectedAreas}
                    />
                  </th>
                  <th className="col-1">
                    <FormSelect 
                      options={allCargos}
                      description={"Cargos"}
                      selectedOptions={selectedCargos}
                      setSelectedOptions={setSelectedCargos}
                    />
                  </th>
                  <th className="col-1">
                    <FormSelect 
                      options={allEstados}
                      description={"Est. Progreso"}
                      selectedOptions={selectedEstados}
                      setSelectedOptions={setSelectedEstados}
                    />
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Per. Vac.</div>
                  </th>
                  <th className="">
                    <div className="flex font-gothan-medium">Cant.Días</div>
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Días háb.</div>
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Días No háb.</div>
                  </th>
                  <th className="col-1">
                    <FormSelect 
                      options={allNiveles}
                      description={"Nivel Aprob."}
                      selectedOptions={selectedNiveles}
                      setSelectedOptions={setSelectedNiveles}
                    />
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Aprobador</div>
                  </th>
                  <th className="col-1">
                    <div className="flex font-gothan-medium">Acciones</div>
                  </th>
                </tr>
              </thead>
              <TableWebBody
                filas={filas}
                setIdMovimiento={setIdMovimiento}
                showModalVerSolicitudVacaciones={toggleVerSolicitudVacaciones}
                showModalAprobarRechazarSolicitudVacaciones={toggleAprobarRechazarSolicitudVacaciones}
                showModalReasignarSolicitudVacaciones={toggleReasignarSolicitudVacaciones}
                showModalAgregarNotaSolicitudVacaciones={toggleAgregarNotaSolicitudVacaciones}
                setModalEnabled={setModalEnabled}
                isAdmin={true}
                refreshPage={manageSearch}
                toggleModalConfirmarRechazarVacaciones = {toggleModalConfirmarRechazarVacaciones}
                setPayloadRechazarVacaciones = {setPayloadRechazarVacaciones} 

              />
            </table>
            {totalPages > 1 && (
                <Paginator totalItems={totalItems}
                    firsItemPage={firsItemPage}
                    lastItemPage={lastItemPage}
                    totalPages={totalPages}
                    page={page}
                    onLeftClic={onLeftClic}
                    onRightClic={onRightClic} />
            )}
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default ListaSolicitudesVacaciones