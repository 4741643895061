export const USER_DATA_KEY = 'user';
export const TOKEN_KEY = 'token';
export const IS_AUTH_KEY = 'is_auth';
export const IS_COOKIES = 'is_cookies';
export const SHOW_TERMS = 'show_terms';
export const PROFILE = 'profile';
export const ROLE = 'role';
export const ROLE_IDS = 'roleIds';
export const COOKIES_MESSAGE = 'cookiesMessage';
export const EMAIL = 'email';
export const PHOTO_URL = 'photo';
export const MENUS_ACCESO = 'menus_acceso';
export const ES_INTERNO = 'is_interno';
