import React, {useContext, useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getReasignarActividadAPI, reasignarActividadAPI} from "../../../consumers/backendApisUrls";
import {getColaborador} from "../../../consumers/backendApisUrls";
import {getDropDownColaboradorAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import SelectInput from "../../../components/Select/SelectInput";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric} from "../../../helper/utils";
import { AuthContext } from '../../../hooks/context/authContext';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import {ROLES} from "../../../constants/hardCodedConstants";

const ReasignarActividadModal = ({toggleModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "ReasignarActividadModal";
    const { state: { roleIds } } = useContext(AuthContext);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [colaboradoresOptions, setColaboradoresOptions] = useState([]);
    const [jsonSelectedColaboradorOption, setJsonSelectedColaboradorOption] = useState({});

    const isAdmin =  roleIds.find(element => element ===  ROLES.SuperAdmin.id);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        const response =  await executeGetRequest(getReasignarActividadAPI(idEmpleadoFlujoActividad));

        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response?.data?.EmpleadosList, "Value", "Text");
             setColaboradoresOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
        
    };

    const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
        clearAllInputValidationMessages(["IdEmpleado"], sectionName);
        setJsonSelectedColaboradorOption(jsonValue);
      }


    const onSubmit = async (e) => {
        e.preventDefault();
        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "IdEmpleado": jsonSelectedColaboradorOption?.value
        };

        let response = await executePostRequest(reasignarActividadAPI(), payload);

        if (responseCode200(response)) {
            toggleModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdEmpleado"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };


    return (
        <>
            <GenericModal showModal={toggleModal} width={"700px"} titulo="Reasignar Actividad">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label modal-label">Colaborador<span className="required">*</span></label>
                                { isAdmin ? (
                                    <div className="col-sm-12 black-color">
                                        <AsyncSelectInput api={getDropDownColaboradorAPI} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal} />
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
                                    </div>
                                ):
                                    (
                                        <div className="col-sm-8 black-color">
                                            <SelectInput options={colaboradoresOptions} jsonSelectedValue={jsonSelectedColaboradorOption} setJsonSelectedValue={setJsonSelectedColaboradorOption} isDisabled={false} placeholder={"Seleccionar un colaborador"}/>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleado-validation-message`}></p>
                                        </div>
                                    )    
                                }
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer d-flex justify-content-between">
                    <div className="red-below-modal-message font-gothan-light align-self-start">
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button align-self-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ReasignarActividadModal;

