import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usuarioLogueadoTieneAlgunoDeLosSiguientesRoles } from '../../helper/utils';
import { MENUS_FICHA_DATOS, ROLES } from "../../constants/hardCodedConstants";
import { cargarDocumentoEmpleadoAPI, getArchivosNuevosIngresosFichaAPI, eliminarDocumentoEmpleadoAPI, observarDocumentoEmpleadoNuevosIngresosAPI } from '../../consumers/backendApisUrls';
import { responseCode200 } from '../../consumers/httpRequiestsUtils';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { AuthContext } from '../../hooks/context/authContext';
import CardAccordion from '../../components/pageComponents/CardAccordion';
import GenericUploadFileV2 from '../../components/GenericUploadFile/GenericUploadFileV2';
import ActionGenericModal from "../../components/modal/ActionGenericModal";
import { faDownload, faEye } from '@fortawesome/free-solid-svg-icons';

const CardArchivosNuevoIngreso = ({ idEmpleado, handleDownloadFile, subSeccionesArchivos }) => {

  const { state } = useContext(AuthContext);
  const idMenuFicha = MENUS_FICHA_DATOS.archivos.secciones.documentosIngreso.id;

  const [allData, setAllData] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [modalEnable, setModalEnable] = useState({isEnable: false});

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();

  const init = async () => {
    try {
      setIsAdmin(usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.Compensaciones.id]))
      manageSearch();
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    let response = await executeGet(getArchivosNuevosIngresosFichaAPI(idEmpleado));
    if(responseCode200(response)) setAllData(response.data)
  }

  const handleUploadFile = async (res, IdNuevosIngresosEmpleadoDocumento) => {
    let payload = {IdNuevosIngresosEmpleadoDocumento, File: {...res}};
    await executePost(cargarDocumentoEmpleadoAPI(), payload, () => manageSearch());
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <CardAccordion id="archivos-nuevos-ingresos" idParent="datos-archivos" label={subSeccionesArchivos[idMenuFicha]?.NombreSeccion || "Documentos de Ingreso"}>
      {modalEnable.isEnable &&
        <div className="modal-contactanos modal-response-ficha-background">
          <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} refreshPage={manageSearch} {...modalEnable.data}/>
        </div>
      }
      {allData.Items?.length ? 
      <table className="table table-hover table-bordered mt-3">
        <thead className='listar-thead'>
          <tr>
            <th colSpan={2} className="table-header-bold">Documento Asignado</th>
            {!isAdmin 
              ? <th colSpan={1} className="table-header-bold">Documento Firmado</th>
              : <th colSpan={1} className="table-header-bold">Acciones</th>
            }
          </tr>
          <tr>
            <th className='text-center col-sm-7'>Nombre</th>
            <th className='text-center col-sm-2'>Estado</th>
            {!isAdmin
              ? <th className='text-center col-sm-2'>Descargar</th>
              : <th className='text-center col-sm-1'>Subir/Descargar/Observar</th>
            }
          </tr>
        </thead>
        <tbody className='listar-tbody'>
          {allData.Items?.map((data, i) => (
            <tr key={`Documentos-Nuevo-Ingreso-${data.NombreDocumento}-${i}`}>
              <td className='pl-5'>
                <label className='mb-0'>{data.NombreDocumento}</label>
                {data.EstaObservado && <label className='ml-4 mb-0' style={{border: "1px solid red", borderRadius: "8px", background: "red", color: "white", fontWeight: "bold", padding: "0 5px 0 5px"}}>Observado</label>}
              </td>
              <td className='text-center'>{data.Estado}</td>
              {!isAdmin
                ? <td className='text-center'>
                    {data.EsValidado === false ?
                      <div>-</div> :
                      data.File ?
                      <div className="d-flex justify-content-center" style={{gap: "15px"}}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          size="lg"
                          title="Descargar archivo"
                          className='hand-pointer green-innova'
                          onClick={() => {handleDownloadFile(data.File?.Path, data.File?.OriginalFileName)}}
                        />
                     
                      </div>
                      :<></>
                     
                    }
                  </td>
                : <td className='d-flex justify-content-center' style={{gap: "10px"}}>
                    {data.PuedeObservar && 
                      <FontAwesomeIcon 
                        icon={faEye}
                        size="lg"
                        title="Observar"
                        className='hand-pointer required'
                        onClick={() => setModalEnable({isEnable: true, component: ActionGenericModal, data: {api: observarDocumentoEmpleadoNuevosIngresosAPI, id: data.IdNuevosIngresosEmpleadoDocumento, name: data.NombreDocumento, title: "Observar", actionLabel: "observar"}})}
                      />
                    }

                    {data.File ?
                        <>

                          <FontAwesomeIcon
                            icon={faDownload}
                            size="lg"
                            title="Descargar archivo"
                            className='hand-pointer green-innova'
                            onClick={() => {handleDownloadFile(data.File?.Path, data.File?.OriginalFileName)}}
                          />

                        
                          <FontAwesomeIcon
                            icon="trash"
                            size="lg"
                            title="Eliminar"
                            className="hand-pointer blue-standard-color"
                            onClick={() => setModalEnable({isEnable: true, component: ActionGenericModal, data: {api: eliminarDocumentoEmpleadoAPI, id: data.IdNuevosIngresosEmpleadoDocumento, name: data.File?.OriginalFileName}})}
                           />
                        

                        </>
                      :
                        <GenericUploadFileV2
                        id="SubitArchivoListaDocumentosNuevoIngreso"
                        extensionesValidas={["pdf"]}
                        uploadFileToS3={(res) => handleUploadFile(res, data.IdNuevosIngresosEmpleadoDocumento)}
                        textButton="Subir archivo"
                        resetInitialFile={false}
                        showToastSuccess={false}
                        isIcon
                        iconColor='required'
                        />
                    }
                  </td>
              }
            </tr>
          ))}
        </tbody>
      </table> :
      <p className='ml-5 mt-2 blue-standard-color font-gothan-light'>No hay registros</p>
      }
    </CardAccordion>
  )
}

export default CardArchivosNuevoIngreso