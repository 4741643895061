import styled, { css } from "styled-components";

const CuponWrapper = styled.div`
  display: grid;
  margin: 30px;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
`;

const CuponCard = styled.div`
  display: flex;
  position: relative;
  height: 300px;
  overflow: hidden;
  border-radius: 8px;

  &:hover {
    .icons {
      /* display: flex; */
      visibility: visible;
      opacity: 1;
    }
  }

  .inner-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;

    .card-bloqued {
      position: absolute;
      bottom: 10px;
      left: 15px;
      font-weight: bolder;
      background: transparent;
      color: white;
      text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
    }

    .card-checkbox{
      position: absolute;
      z-index: 1;
      height: 40px;
      width: 40px;
      bottom: 8px;
      left: 8px;
      background: white;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;

      &>svg{
        visibility: ${({ isSelected }) => isSelected ? "visible" : "hidden"};
      }
    }
  }

  .icons {
    /* display: none; */
    visibility: hidden;
    opacity: 0;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    gap: 70px;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);

    svg{
      transition: color 0.3s;
      color: #00659D;

      &:hover{
      color: #3a87c8;
      }
    }
  }

  .requirements{
    display: ${({ isRequirementsVisible  }) => isRequirementsVisible  ? "flex" : "none"};
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .requirements-form{
      max-width: 380px;
      min-width: 350px;
      max-height: 330px;
      border-radius: 8px;
      padding: 10px;
      background: white;
      display: flex;
      flex-direction: column;
      position: relative;
      overflow-y: auto;
    }
  }

  .cupon_text_overlay {
    position: absolute;
    width: 273px;
    height: 300px;
    top: 0;
    right: 0;
    transition: transform 300ms;
    transform: translate3d(0, calc(300px - 3.5rem), 0);
    background: rgb(148, 148, 148);

    .overlay_header{
      color: white;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bolder;
      background: rgb(125 125 125);
    }

    .overlay_body{
      height: 243px;
      overflow-y: auto;
    }

    &:hover {
      transform: translate3d(0, 0, 0);
    }
  }

  @media (min-width: 1430px){
    .cupon_text_overlay{
      width: 40%;
    }
  }

  @media only screen and (max-width: 796px) {
    height: 140px;
    width: 320px;

    .inner-content{
      .card-bloqued{
        font-size: 12px;
      }
      .card-checkbox{
        height: 30px;
        width: 30px;
        border-radius: 4px;
      }
    }

    .cupon_text_overlay{
      font-size: 0.785rem;
      transform: translate3d(0,calc(160px - 3rem), 0);
      width: 128px;

      .overlay_header{
        font-size: 0.6rem;
        padding: 8px;
      }

      .overlay_body{
        height: 90px;
      }
    }
  }
`;

const CuponImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top right;
`;

const CuponTitle = styled.div`
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(0 0 0 / 50%);
  padding: 6px 10px;
  border-radius: 8px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  h1, p {
    font-size: 18px;
    color: white;
    font-weight: bolder;
    font-family: AllertaRegular;
    margin: 0;
    text-align: center;
  }
`;

const CuponName = styled.div`
  width: 40%;
  height: 100%;
  padding: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #FFB71E;
  position: relative;

  h1, p {
    font-size: 30px;
    color: white;
    font-weight: bolder;
    font-family: AllertaRegular;
  }

  img{
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

const CuponCodigo = styled.div`
  width: 135px;
  position: absolute;
  top: 23px;
  right: 130px;
  left: auto;
  background-color: transparent;
  color: white;
  font-weight: bolder;
  padding: 4px 15px;
  border-radius: 8px;
  max-width: 65%;
  overflow: hidden;

  @media screen and (min-width: 1430px) {
    top: calc(23px + 3 * ((100vw - 1430px)/100));
    right: calc(130px + 15 * ((100vw - 1430px)/100));
  }

  @media only screen and (max-width: 796px) {
    font-size: 0.5rem;
    top: 10.5px;
    right: 50px;
    max-width: 20%;
    padding: 0px 8px;
  }
`;

const CuponLineamiento = styled.div`
  width: 100%;
  text-align: justify;
  word-break: break-word;
  color: white;
  overflow: hidden;
  font-size : 13px;
  flex: 1 1;
  padding: 15px 20px 15px 15px;

  p{
    margin-bottom: 0;
  }

  dl, ol, ul {
    padding-left: 20px;
  }

  @media (max-width: 1400px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 796px) {
    font-size: 0.65rem;
    padding: 6px 8px;
    
    dl, ol, ul {
      padding-left: 10px;
    }
  }
`;

const CuponXmark = styled.div`
  position: absolute;
  top: 6px;
  right: 8px;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100%;
  background-color: #00659D;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: 1;

  &:hover {
    background-color: #3a87c8;
  }
`;

const FormXmark = styled.div`
  position: relative;
  top: 0;
  right: 0;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border-radius: 100%;
  background-color: #ff6565;
  font-size: 15px;
  font-weight: bolder;
  padding: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  z-index: 1;

  &:hover {
    background-color: #3a87c8;
  }

  @media screen and (min-width: 796px) {
    position: absolute;
    top: -10px;
    right: -10px
  }
`

const CuponeraWrapper = styled.div`
  display: flex;
  padding: 20px;
  gap: 20px;

  @media screen and (min-width: 796px) {
    .wrapper-responsive {
      display: flex;
      position: fixed;
      z-index: 2;
      inset: 0;
      justify-content: center;
      align-items: center;
      transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

      .cuponera{
        position: relative;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        height: auto;

        .cuponera-form{
          flex: 1 1;
          width: 800px;
          background-color: #D5E2EE;
          padding: 20px;
          display: flex;
          flex-wrap: wrap;
          border-radius: 10px;
          max-height: 82vh;
          overflow-y: auto;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);

          &>div{
            margin-bottom: 0;
            flex: 0 0 50%;
            max-width: 50%;
            padding: 0 10px;
          }
          &>div:nth-child(5), &>div:nth-child(8), &>div:last-child {
            flex: 0 0 100%;
            max-width: 100%;
          }
          &>div:last-child{
            margin: 5px 0 0;
          }
        }
      }
    }

    ${({ isOpen }) => isOpen 
        ? css`
          .wrapper-responsive {
            /* display: flex; */
            visibility: visible;
            background-color: #80808069;
            .cuponera {
              transform: scale(1);
              opacity: 1;
            }
          }
        `
        : css`
          .wrapper-responsive {
            /* display: none; */
            visibility: hidden;

            .cuponera {
              transform: scale(1.05);
              opacity: 0;
            }
          }
        `
      }
  }

  @media only screen and (max-width: 796px) {
    margin-bottom: 70px;

    .wrapper-responsive{
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100vh - 38px);
      padding: 0px 10px 15px;
      z-index: 5;
      background-color: #3a87c8;
      border-radius: 20px 20px 0 0;
      transition: transform 0.5s cubic-bezier(0.4, 0, 0.6, 1);
      transform: ${({ isOpen }) => isOpen ? "translateY(0)" : "translateY(calc(100vh - 100px))" };

      .cuponera{
        display: flex;
        justify-content: center;
        align-items: start;
        overflow-y: scroll;
        height: calc(100vh - 130px);

        .xMark {
          display: none;
        }

        .cuponera-form {
          width: 320px;
          flex: none;
          background-color: #D5E2EE;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 10px;

          &>div{
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const ListaCuponesWrapper = styled.div`
  flex: 1 1;
  min-width: 840px;

  @media only screen and (max-width: 796px) {
    min-width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TextoSeleccionarCupon = styled.div`
  /* padding: 15px 25px 0px; */
  color: #EA2B1F;
  font-size: 18px;
  font-weight: bolder;

  @media only screen and (max-width: 796px) {
    text-align: center;
    font-size: 12px;
  }
`;

const MobileBarWrapper = styled.div`
  display: none;
  padding: 10px 20px;

  .header{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 2px;
    
    label{
      font-weight: bolder;
      font-size: 14px;
      margin: 0;
    }
    p{
      font-size: 13px;
      margin: 0;
    }
  }

  @media (max-width: 796px){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const CuponeraStatistics = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;

  .info {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    color: white;

    p{
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      flex: 1
    }

    span{
      font-size: 20px;
    }
  }

  @media screen and (max-width: 796px) {
    right: 10px;
    top: 10px;

    .info {
      p {
        font-size: 13px;
      }
      span {
        font-size: 14px;
      }
    }
  }
`

const TopOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px 0;
  gap: 10px;

  @media screen and (max-width: 796px){
    flex-direction: column;
  }
`

export { CuponWrapper, CuponCard, CuponImage, CuponTitle, CuponName, CuponCodigo, CuponLineamiento, CuponXmark, CuponeraWrapper, ListaCuponesWrapper, TextoSeleccionarCupon, MobileBarWrapper, CuponeraStatistics, TopOptions, FormXmark };