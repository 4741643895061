import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../consumers/httpRequiestsUtils";
import {agregarTextOfSelectInputType, notNullAndNotUndefinded} from "../../helper/utils";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import {LIST_FIELD_TYPE} from "../../constants/hardCodedConstants";
import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput, obtenerElementoInputDeFicha} from "../../validations/FormValidatorUtils";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getDistritosPorProvinciaAPI, getInformacionResidenciaAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, guardarInformacionResidenciaAPI, validarGuardarInformacionResidenciaAPI} from "../../consumers/backendApisUrls";
import DeclaracionJuradaModal from "./modales/DeclaracionJuradaModal";

const SeccionEditDatosDeResidencia = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, datosResidenciaHasBeenUpdated, setDatosResidenciaHasBeenUpdated, reloadSeccionesDatosPersonales}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosResidencia, setDatosResidencia] = useState({});

    const [editFields, setEditFields] = useState([]);
    const [hiddenFields, setHiddenFields] = useState([]);

    const [tiposDeVivienda, setTiposDeVivienda] = useState([]);
    const [tiposDeVia, setTiposDeVia] = useState([]);
    const [tiposDeZona, setTiposDeZona] = useState([]);
    const [paises, setPaises] = useState([]);

    const firstGeneralJsonOptionSelect = {"Value": 0, "Text": "Seleccionar"};
    const [departamentos, setDepartamentos] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [distritos, setDistritos] = useState([]);

    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState();
    const [provinciaSeleccionado, setProvinciaSeleccionado] = useState();
    const [distritoSeleccionado, setDistritoSeleccionado] = useState();

    const [ubigeo, setUbigeo] = useState();
    const [descripcionDeUbigeo, setDescripcionDeUbigeo] = useState();

    const [loading, setLoading] = useState(false);

    const [dataFields, setDataFields] = useState([]);
    const seccionName = "fichaDatosResidencia";
    let allInputKeys = ["IdTipoVivienda", "IdTipoVia", "NombreVia", "NumeroCasa", "NumeroInterior", "Manzana", "Lote", "Kilometro", "Etapa", "Block", "IdTipoZona", "NombreZona", "IdDepartamento", "IdProvincia", "IdDistrito", "IdPaisResidencia"];

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    const [hideDeclaracionJuradaModal, setHideDeclaracionJuradaModal] = useState(true);

    function updateId() {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        updateProvincias();
    }, [departamentoSeleccionado]);

    useDidMountEffect(() => {
        updateDistritos();
    }, [provinciaSeleccionado]);

    useDidMountEffect(() => {
        hacerEditablesLosCampos();
    }, [editFields]);

    useDidMountEffect(() => {
        mostrarSoloCamposQueNoSeanHidden();
    }, [hiddenFields]);

    useDidMountEffect(() => {
        const distrito = distritos.filter(d => d.Value == distritoSeleccionado);

        if (distrito.length > 0) {
            setDescripcionDeUbigeo(distrito[0].Text);
            setUbigeo(distrito[0].Ubigeo);
        }
    }, [distritoSeleccionado]);

    const agregarAsteriscoALabelsMandatorios = (dataFieldss) => {
        try {
            for (let dataField of dataFieldss) {
                if (dataField?.IsRequired === true) {
                    try {
                        document.querySelector(`#ficha-edit-inputGroup-datosDeResidencia-${dataField?.KeyField} > span.required`).innerHTML = "*";
                    } catch (e) {
                        console.warn(`No se econtró elemento con id #ficha-edit-inputGroup-datosDeResidencia-${dataField?.KeyField}`);
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                const paisess = await executeGetRequest(getPaisesAPI());
                if (responseCode200(paisess)) {
                    setPaises([firstGeneralJsonOptionSelect, ...paisess.data]);
                } else {
                    mostrarMensajeDeErrorConToast(paisess);
                }

                const infoResidencia = await executeGetRequest(getInformacionResidenciaAPI(idActualizado));
                console.debug("response ", infoResidencia);
                if (responseCode200(infoResidencia)) {
                    setDatosResidencia(infoResidencia.data.data);
                    setEditFields(infoResidencia.data.data.EditFields);
                    setHiddenFields(infoResidencia.data.data.HiddenFields);
                    setDataFields(infoResidencia.data.data.DataFields);

                    setTiposDeVivienda([firstGeneralJsonOptionSelect, ...infoResidencia.data.dropdowns.TiposVivienda]);
                    setTiposDeVia([firstGeneralJsonOptionSelect, ...infoResidencia.data.dropdowns.TiposVia]);
                    setTiposDeZona([firstGeneralJsonOptionSelect, ...infoResidencia.data.dropdowns.TiposZona]);
                    setDepartamentos([firstGeneralJsonOptionSelect, ...infoResidencia.data.dropdowns.Departamentos]);

                    if (infoResidencia.data.data.IdDepartamento && infoResidencia.data.data.IdProvincia && infoResidencia.data.data.IdDistrito) {
                        setDepartamentoSeleccionado(infoResidencia.data.data.IdDepartamento);
                        setProvinciaSeleccionado(infoResidencia.data.data.IdProvincia);
                        setDistritoSeleccionado(infoResidencia.data.data.IdDistrito);

                        setUbigeo(infoResidencia.data.data.Ubigeo);
                        setDescripcionDeUbigeo(infoResidencia.data.data.DescripcionUbigeo);
                    }

                    agregarAsteriscoALabelsMandatorios(infoResidencia.data.data.DataFields);

                } else {
                    mostrarMensajeDeErrorConToast(infoResidencia);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const mostrarSoloCamposQueNoSeanHidden = () => {
        try {
            for (const field of hiddenFields) {
                let elementEdit = document.getElementById(`ficha-edit-inputGroup-datosDeResidencia-${field}`);

                if (elementEdit) {
                    elementEdit.setAttribute("hidden", "");
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const hacerEditablesLosCampos = () => {
        console.debug("hacerEditablesLosCampos: editFields: ", editFields);
        try {

            for (let field of editFields) {
                let optionalParams = document.querySelector("#frmEditDatosDeResidencia input[name='" + field + "']");

                if (optionalParams == null) {
                    optionalParams = document.querySelector("#frmEditDatosDeResidencia select[name='" + field + "']");
                }

                if (optionalParams != null) {
                    console.debug("remove disabled field: ", field);
                    optionalParams.removeAttribute('disabled');
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const cancelar = async () => {
        try {
            setShowViewSeccion(true);
        } catch (e) {
            console.error(e);
        }
    };

    const agregarCamposDeFormulario = (payload) => {
        for (let dataField of dataFields) {
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let element = obtenerElementoInputDeFicha(keyField, seccionName);
                if (notNullAndNotUndefinded(element)) {
                    if (element.matches('[type="checkbox"]')) {
                        payload = {...payload, [keyField]: element.checked};
                    } else {
                        payload = {...payload, [keyField]: element.value};
                    }

                    if (dataField["FieldType"] == LIST_FIELD_TYPE) {
                        payload = agregarTextOfSelectInputType(keyField, element, payload);
                    }
                } else {
                    console.warn(`no se va a agregar campo a payload porque no está presente en el documento html fieldKey: "${keyField}"`);
                }
            }
        }
        return payload;
    };


    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            let payload = {
                IdEmpleado: idActualizado,
            };

            payload = agregarCamposDeFormulario(payload);

            let response = await executePostRequest(validarGuardarInformacionResidenciaAPI(), payload);
            if (responseCode200(response)) {
                clearAllInputValidationMessages(allInputKeys, seccionName);
                toggleDeclaracionJuradaModal();
                await reloadSeccionesDatosPersonales(empleadoId);
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, seccionName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }

        } catch (e) {
            console.error(e);
        }
    };

    const guardarCallBackEndAPI = async function () {
        try {
            let payload = {
                IdEmpleado: idActualizado,
            };

            payload = agregarCamposDeFormulario(payload);

            let response = await executePostRequest(guardarInformacionResidenciaAPI(), payload);
            if (responseCode200(response)) {
                showUpdateConfirmationModal(response.data.UrlImage, response.data.Message);
                await updateComponentStates();
                askForNewBellNotifications(dispatch);
                setDatosResidenciaHasBeenUpdated(!datosResidenciaHasBeenUpdated);
                setShowViewSeccion(true);
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, seccionName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleDepartamentoChange = async (event) => {
        setDepartamentoSeleccionado(event.target.value);
        setProvinciaSeleccionado();
        setDistritoSeleccionado();
    };

    const handleProvinciaChange = async (event) => {
        setProvinciaSeleccionado(event.target.value);
        setDistritoSeleccionado();
    };

    const handleDistritoChange = async (event) => {
        setDistritoSeleccionado(event.target.value);
    };

    const handleUbigeoChange = async (event) => {
        setUbigeo(event.target.value);
    };

    const handleDescripcionUbigeoChange = async (event) => {
        setDescripcionDeUbigeo(event.target.value);
    };

    const updateProvincias = async () => {
        if (departamentoSeleccionado) {
            let provinciass = await executeGetRequest(getProvinciasPorDepartamentoAPI(departamentoSeleccionado));
            if (responseCode200(provinciass)) {
                setProvincias([firstGeneralJsonOptionSelect, ...provinciass.data]);
            } else {
                mostrarMensajeDeErrorConToast(provinciass);
            }
        } else {
            setProvincias([firstGeneralJsonOptionSelect]);
            setDistritos([firstGeneralJsonOptionSelect]);
            setUbigeo("");
            setDescripcionDeUbigeo("");
        }
    };

    const updateDistritos = async () => {
        if (provinciaSeleccionado) {
            let distritoss = await executeGetRequest(getDistritosPorProvinciaAPI(provinciaSeleccionado));
            if (responseCode200(distritoss)) {
                setDistritos([firstGeneralJsonOptionSelect, ...distritoss.data]);
            } else {
                mostrarMensajeDeErrorConToast(distritoss);
            }
        } else {
            setDistritos([firstGeneralJsonOptionSelect]);
            setUbigeo("");
            setDescripcionDeUbigeo("");
        }
    };


    const toggleDeclaracionJuradaModal = () => {
        setHideDeclaracionJuradaModal(!hideDeclaracionJuradaModal);
    };

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}

            <div className="modal-contactanos modal-response-ficha-background" hidden={hideDeclaracionJuradaModal}>
                {hideDeclaracionJuradaModal === false &&
                <DeclaracionJuradaModal showModal={toggleDeclaracionJuradaModal} guardarCallBackEndAPI={guardarCallBackEndAPI}/>
                }
            </div>

            <form id='frmEditDatosDeResidencia' className="ficha-edit-form" onSubmit={onSubmit} hidden={loading}>
                <div className="displayFlexWrap">
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdTipoVivienda">
                        <label className="ficha-edit-form-label">Tipo de vivienda</label><span className="required"></span>
                        {datosResidencia &&
                        <select className="form-control" name="IdTipoVivienda" id={`ficha-edit-${seccionName}-IdTipoVivienda`} disabled>
                            tiposDeVivienda && datosResidencia && datosResidencia.IdTipoVivienda
                            {
                                tiposDeVivienda.map((option) => {
                                        {
                                            if (option.Value === datosResidencia.IdTipoVivienda) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdTipoVivienda-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdTipoVia">
                        <label className="ficha-edit-form-label">Tipo de vía</label><span className="required"></span>
                        {datosResidencia &&
                        <select className="form-control" name="IdTipoVia" id={`ficha-edit-${seccionName}-IdTipoVia`} disabled>
                            tiposDeVia && datosResidencia && datosResidencia.IdTipoVia
                            {
                                tiposDeVia.map((option) => {
                                        {
                                            if (option.Value === datosResidencia.IdTipoVia) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdTipoVia-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-NombreVia">
                        <label className="ficha-edit-form-label">Nombre de vía</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.NombreVia} className="form-control" name="NombreVia" id={`ficha-edit-${seccionName}-NombreVia`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-NombreVia-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-NumeroCasa">
                        <label className="ficha-edit-form-label">Número Casa(de edificio o manzana, por ejemplo)</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.NumeroCasa} className="form-control" name="NumeroCasa" id={`ficha-edit-${seccionName}-NumeroCasa`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-NumeroCasa-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-NumeroInterior">
                        <label className="ficha-edit-form-label">Interior (número de departamento o casa, por ejemplo)</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.NumeroInterior} className="form-control" name="NumeroInterior" id={`ficha-edit-${seccionName}-NumeroInterior`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-NumeroInterior-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Manzana">
                        <label className="ficha-edit-form-label">Manzana</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.Manzana} className="form-control" name="Manzana" id={`ficha-edit-${seccionName}-Manzana`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Manzana-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Lote">
                        <label className="ficha-edit-form-label">Lote</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.Lote} className="form-control" name="Lote" id={`ficha-edit-${seccionName}-Lote`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Lote-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Kilometro">
                        <label className="ficha-edit-form-label">Kilómetro</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.Kilometro} className="form-control" name="Kilometro" id={`ficha-edit-${seccionName}-Kilometro`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Kilometro-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Etapa">
                        <label className="ficha-edit-form-label">Etapa</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.Etapa} className="form-control" name="Etapa" id={`ficha-edit-${seccionName}-Etapa`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Etapa-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Block">
                        <label className="ficha-edit-form-label">Block</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.Block} className="form-control" name="Block" id={`ficha-edit-${seccionName}-Block`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Block-validation-message`}></p>
                    </div>
                    <div className="col-md-2 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdTipoZona">
                        <label className="ficha-edit-form-label">Tipo de Zona</label><span className="required"></span>
                        {datosResidencia &&
                        <select className="form-control" name="IdTipoZona" id={`ficha-edit-${seccionName}-IdTipoZona`} disabled>
                            tiposDeZona && datosResidencia && datosResidencia.IdTipoZona
                            {
                                tiposDeZona.map((option) => {
                                        {
                                            if (option.Value === datosResidencia.IdTipoZona) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdTipoZona-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-NombreZona">
                        <label className="ficha-edit-form-label">Nombre de Zona</label><span className="required"></span>
                        <input type="text" defaultValue={datosResidencia && datosResidencia.NombreZona} className="form-control" name="NombreZona" id={`ficha-edit-${seccionName}-NombreZona`} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-NombreZona-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdDepartamento">
                        <label className="ficha-edit-form-label">Departamento</label><span className="required"></span>
                        {datosResidencia &&
                        <select defaultValue={departamentoSeleccionado} value={departamentoSeleccionado} className="form-control" name="IdDepartamento" id={`ficha-edit-${seccionName}-IdDepartamento`} onChange={handleDepartamentoChange} disabled>
                            departamentos &&
                            {
                                departamentos.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdDepartamento-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdProvincia">
                        <label className="ficha-edit-form-label">Provincia</label><span className="required"></span>
                        {datosResidencia &&
                        <select defaultValue={provinciaSeleccionado} value={provinciaSeleccionado} className="form-control" name="IdProvincia" id={`ficha-edit-${seccionName}-IdProvincia`} onChange={handleProvinciaChange} disabled>
                            provincias &&
                            {
                                provincias.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdProvincia-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdDistrito">
                        <label className="ficha-edit-form-label">Distrito</label><span className="required"></span>
                        {datosResidencia &&
                        <select defaultValue={distritoSeleccionado} value={distritoSeleccionado} className="form-control" name="IdDistrito" id={`ficha-edit-${seccionName}-IdDistrito`} onChange={handleDistritoChange} disabled>
                            distritos &&
                            {
                                distritos.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdDistrito-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-Ubigeo">
                        <label className="ficha-edit-form-label">Ubigeo</label><span className="required"></span>
                        <input type="text" value={ubigeo} className="form-control" name="Ubigeo" id={`ficha-edit-${seccionName}-Ubigeo`} onChange={handleUbigeoChange} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Ubigeo-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-DescripcionUbigeo">
                        <label className="ficha-edit-form-label">Descripción Ubigeo</label><span className="required"></span>
                        <p data-tip data-for="informacion-residencia-ubigeo" style={{display: 'inline'}}>
                            <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                        </p>
                        <ReactTooltip id="informacion-residencia-ubigeo" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                            El campo <strong>Ubigeo</strong> y <strong>Descripción Ubigeo</strong> son resultado de la selección de los campos <strong>Departamento, Provincia y Distrito.</strong>
                        </ReactTooltip>
                        <input type="text" defaultValue={descripcionDeUbigeo} value={descripcionDeUbigeo} className="form-control" name="DescripcionUbigeo" id={`ficha-edit-${seccionName}-DescripcionUbigeo`} onChange={handleDescripcionUbigeoChange} disabled></input>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-DescripcionUbigeo-validation-message`}></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeResidencia-IdPaisResidencia">
                        <label className="ficha-edit-form-label">País de Residencia</label><span className="required"></span>
                        {datosResidencia &&
                        <select className="form-control" name="IdPaisResidencia" id={`ficha-edit-${seccionName}-IdPaisResidencia`} disabled>
                            paises && datosResidencia &&
                            {
                                paises.map((option) => {
                                        {
                                            if (option.Value === datosResidencia.IdPaisResidencia) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdPaisResidencia-validation-message`}></p>
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    'justifyContent': 'space-between',
                    'marginTop': '21px'
                }}>
                    <div style={{'alignSelf': 'flex-start'}}>
                    </div>

                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            onClick={cancelar}
                            type='button'
                            className='btn btn-primary ficha-form-button-cancelar'>
                            Cancelar
                        </button>
                        {datosResidencia && datosResidencia.CanSeeButtonEditar === true &&
                        <button
                            type="submit"
                            className='btn btn-primary ficha-form-button-guardar'>
                            Guardar
                        </button>
                        }
                    </div>
                </div>

                <div style={{
                    display: 'flex',
                    'justifyContent': 'space-between',
                    'marginTop': '10px',
                    'marginBottom': '1px'
                }}>
                    <div style={{'alignSelf': 'flex-start'}}>
                        <div className="form-check margin-top-15px">
                        </div>
                        <div>
                            <br/>
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>
                    </div>
                    <div style={{'alignSelf': 'flex-end'}}>
                    </div>
                </div>
            </form>
        </>
    );
};

export default SeccionEditDatosDeResidencia;