import React from 'react';
import GenericModal from "../../../../components/modal/GenericModal";
import { eliminarGrupoAPI } from '../../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';


const EliminarGrupo = ({
    toggleModal,
    refreshTable,
    grupoTitle,
    grupoId
}) => {

    const [managePost] = useManagePostRequest();

    const eliminarGrupo = async() => {
        await managePost(eliminarGrupoAPI(grupoId),{}, () => {
            refreshTable();
            toggleModal();
        })
    }

    return(
        <GenericModal showModal={toggleModal} width={"620px"} titulo={"Eliminar grupo predefinido"}>
            <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    Se va a eliminar el siguiente grupo predefinido:
                    <ul>
                        <li><strong>{grupoTitle}</strong></li>
                    </ul>
                    ¿Está seguro de esta acción?
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha mr-2"
                        onClick={() => {toggleModal()}}
                    >
                        Cancelar
                    </button>
                    <button className="btn btn-primary generic-button-ficha"
                        onClick={() => {eliminarGrupo()}}>
                        Eliminar
                    </button>
                </div>
            </div>
        </GenericModal>
    )
}

export default EliminarGrupo;