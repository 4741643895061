import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EditarComponente from './modales/EditarComponente';
import ModalActivarInactivarGeneric from '../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import { activarDesactivarComponenteAPI } from '../../consumers/backendApisUrls';

const TableWebBody = ({
    filas,
    setModalEnabled,
}) => {
    return(
        <tbody className="listar-tbody">
            {filas.map((fila,i) => (
                <tr key={i}>
                    <td className="col-8 pr-1 ml-3">{fila?.NombreComponente}</td>
                    <td className="col-3 pr-1">{fila?.Estado}</td>
                    <td className="col-1 pr-1">
                        <div className="d-flex justify-content-start">
                            <div>
                                <FontAwesomeIcon
                                    icon={fila.IsActive  ? 'ban' : 'circle-notch'}
                                    size="sm"
                                    className='hand-pointer'
                                    title={fila.IsActive  ? 'Inactivar' : 'Activar'}
                                    onClick={() => setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
                                        data: {api: activarDesactivarComponenteAPI, id: fila.IdComponente, title: "componente", name: fila.NombreComponente, state: fila.IsActive}})}
                                />
                            </div>
                            {fila.IsActive && 
                                <div>
                                    <FontAwesomeIcon 
                                        icon='edit'
                                        size='sm'
                                        className="hand-pointer ml-3"
                                        title="Editar"
                                        onClick={() => setModalEnabled({isEnable: true, component: EditarComponente, 
                                            data: {componenteId: fila.IdComponente}})}
                                    />
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableWebBody