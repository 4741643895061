import React, {useContext, useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS} from '../../constants/hardCodedConstants';
import {AuthContext} from "../../hooks/context/authContext";
import {uploadFile} from '../../consumers/backendConsumer';
import {
    clearInputValue,
    extensionYtamañoDeArchivoEsValido,
    getFormattedName, notNullAndNotUndefinded,
    obtenerArchivoDeEvent, parseExtensionesToString,
    responseUploadFileSuccess
} from "../../helper/utils";
import {toast} from "react-toastify";
import ReactTooltip from 'react-tooltip';
import parse from 'html-react-parser';
import {showToastError} from "../toast/toastGenericError";
import {AlertErrorMaxFileSizeMessage} from "../alerts/AlertErrorMaxFileSizeMessage";

const GenericUploadFileDinamic = ({uploadedFilesResponses, setUploadedFilesResponses, id, textButton, toolTipText, children, extensionesValidas = EXTENSIONES_VALIDAS_DE_ARCHIVOS, ToastArchivoNoValido = AlertErrorMaxFileSizeMessage, setSubiendoArchivoPublicState, className=""}) => {
    const [file, setFileee] = useState();
    const {state} = useContext(AuthContext);
    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            //setUploadedFilesResponses({})
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const handleUploadFileProxy = async function (e) {
        let file = obtenerArchivoDeEvent(e);
        const url = window.URL.createObjectURL(new Blob([file]));
        setFileee(url);

        await handleUploadFile(e);
    };

    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, extensionesValidas)) {
                setSubiendoArchivo(true);
                if (notNullAndNotUndefinded(setSubiendoArchivoPublicState)) {
                    setSubiendoArchivoPublicState(true);
                }

                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                setSubiendoArchivoPublicState && setSubiendoArchivoPublicState(false);
                manageUploadFileResponse(response, file);
            } else {
                resetUploadFile();
                showToastError(ToastArchivoNoValido);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setUploadedFilesResponses({...uploadedFilesResponses, [id]: s3Response});
            setFileName(getFormattedName(file.name, 25));
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const resetUploadFile = () => {
        setFileName("");
        setUploadedFilesResponses({...uploadedFilesResponses, [id]: {}});
        clearInputValue(id);
    };

    const removeUploadedFile = () => {
        resetUploadFile();
    };

    return (
        <>
            <div className={className}>
                <div>
                    <label className="custom-file-upload mb-0 table-detalle-colaborador-upload-file">
                        <input type="file" id={id} onChange={handleUploadFileProxy} accept={parseExtensionesToString(extensionesValidas)} className="hand-pointer"></input>
                        <p data-tip data-for={`data-tool-tip-info-${id}`} className='btn btn-primary mb-0 pt-0 pb-0 pl-2 pr-2 custom-button-adjuntar'>{textButton}</p>
                        {children}
                        &nbsp;&nbsp; <a title="Descargar archivo" href={file} download={fileName}>{fileName}</a> {(subiendoArchivo === true) && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner'
                                                                                                                                                                                                                                  transform="left-9" className="hand-pointer spinner"/></>)}
                        {toolTipText &&
                        <ReactTooltip id={`data-tool-tip-info-${id}`} place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                            <p>{parse(toolTipText)}</p>
                        </ReactTooltip>
                        }
                    </label>
                </div>

                <div>
                    {fileName.length > 0 &&
                    <p data-tip style={{display: 'inline'}} className="hand-pointer" title="Eliminar Archivo">
                        &nbsp;&nbsp; <FontAwesomeIcon icon='eraser' className="hand-pointer" onClick={removeUploadedFile}/>
                    </p>
                    }
                </div>

            </div>
        </>
    );
};

export default GenericUploadFileDinamic;