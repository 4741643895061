import React from 'react'
import styled from 'styled-components'

const SpinnerChat = ({ height = '5px' }) => {
  return (
    <Chat height={height}>
      <div className="loader">
        Loading...
      </div>
    </Chat>
  )
}

export default SpinnerChat

const Chat = styled.div`
  height: 20px !important;
  .loader,
  .loader:before,
  .loader:after {
    background: #3a87c8;
    animation: load1 1s infinite ease-in-out;
    width: 1.3em;
    height: 4em;
    border-radius: 5px;
  }
  .loader {
    color: #3a87c8;
    text-indent: -9999em;
    margin: 0 auto;
    position: relative;
    font-size: ${({ height }) => height};
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  .loader:before,
  .loader:after {
    position: absolute;
    top: 0;
    content: '';
  }
  .loader:before {
    left: -2em;
    animation-delay: -0.32s;
  }
  .loader:after {
    left: 2em;
  }

  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
`;