import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TableWebBody = ({
    filas,
    setFilas,
    toggleEditarGrupoModal,
    toggleEliminarGrupoModal,
    setGrupoTitle,
    setGrupoId
}) => {
    const Myfuncion = (fila) => {
        let filasTemp = [...filas];
        let newArray = filasTemp.map((value) => {
            if (value.IdGrupo === fila.IdGrupo) {
                return { ...fila, mostrarMas: !fila.mostrarMas }
            }
            return value;
        });
        setFilas(newArray);
    }

    return (
        <tbody className="listar-tbody">
            {filas.map((fila, i) => (
                <tr key={i}>
                    <td className="col-2 pr-1">{fila?.NombreGrupo}</td>
                    <td className='col-9 pr-1'>
                        {fila.mostrarMas
                            ? fila?.AllCargos?.map(obj => (<span className='listar-cargos'>{obj}</span>))
                            : fila?.Cargos?.map(obj => (<span className='listar-cargos'>{obj}</span>))}
                        {fila?.RegistrosAdicionales > 0 && <span className='listar-cargos-adicionales' onClick={() => Myfuncion(fila)}>
                            {!fila.mostrarMas ? "ver " + fila?.RegistrosAdicionales + " mas ...": "Ver menos ..."}
                        </span>}
                    </td>
                    <td className="col-1 pr-1">
                        <div className="d-flex justify-content-start">
                            <div>
                                <FontAwesomeIcon
                                    icon='edit'
                                    size='sm'
                                    className='hand-pointer ml-3'
                                    title='Editar'
                                    onClick={() => {
                                        setGrupoId(fila.IdGrupo);
                                        toggleEditarGrupoModal();
                                    }}
                                />
                            </div>
                            <div>
                                <FontAwesomeIcon
                                    icon='trash'
                                    size='sm'
                                    className='hand-pointer ml-3'
                                    title='Eliminar'
                                    onClick={() => {
                                        setGrupoId(fila.IdGrupo);
                                        setGrupoTitle(fila.NombreGrupo);
                                        toggleEliminarGrupoModal();
                                    }}
                                />
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )

}

export default TableWebBody