import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import TableWebBody from './TableWebBody';
import PageTable from '../../../../components/pageComponents/PageTable';
import PageWrapper from '../../../../components/pageComponents/PageWrapper';
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import SearchContainer from '../../../../components/pageComponents/SearchContainer';
import TableSelectSearch from '../../../../components/atoms/TableSelectSearch';
import { AuthContext } from '../../../../hooks/context/authContext';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { useGenerarDescargarEliminarArchivo } from '../../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../../helper/utils';
import { getAllSedesAPI, vacacionesAdminDescargarHistoricoAPI, vacacionesAdminDescargarHistoricoAPIMatricial, vacacionesAdminIndexHistoricoAPI ,vacacionesAdminIndexHistoricoAPIMatricial } from '../../../../consumers/backendApisUrls'
import { TIPOS_LISTAR_VACACIONES } from '../../../../constants/hardCodedConstants';

const ListaHistoricoVacaciones = ({ dropdownsAreas, dropdownsCargos , filterType }) => {

  const {state} = useContext(AuthContext);
  const INIT_KEYS = { Sedes: "IdsSedes" };
  const COMMON_KEYS = { Cargos: "IdsCargos", Areas: "IdsAreas" };
  const BUSCAR_NOMBRE = { value: 1, label: "Buscar por Nombre" };
  const BUSCAR_TODOS = { value: 2, label: "Todos" };
  const IDS_TYPES = [BUSCAR_NOMBRE, BUSCAR_TODOS];
  const initFormValues = {Items: [], ExtraData: {}, filters: [], selectedFilters: {}, EsOrdenFechaInicioAscendente: true};

  const [typeSelected, setTypeSelected] = useState({ ids: IDS_TYPES, selected: null });
  const [formValues, setFormValues] = useState(initFormValues);
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [modalEnabled, setModalEnabled] = useState({isEnable: false});

  const [executeGet] = useGetRequest();
  const [executeManageGet] = useManageGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeGet(getAllSedesAPI()),
      // executeGet(getAllCargosAPI()),
      // executeGet(getAllAreasAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    setIsFetching(true);    
        const apiLider = vacacionesAdminIndexHistoricoAPI();
        const apiMatricial = vacacionesAdminIndexHistoricoAPIMatricial()

        const payload = getPayload();

    try {
      
      if(filterType == TIPOS_LISTAR_VACACIONES.NoMatricial.value ){
        if ((typeSelected.selected?.value !== BUSCAR_NOMBRE.value) || searchCriteria ){
          await executeManagePost(apiLider, payload, successManageSearchCallback);
         }
      }

      if(filterType == TIPOS_LISTAR_VACACIONES.Matricial.value){
        if ((typeSelected.selected?.value !== BUSCAR_NOMBRE.value) || searchCriteria ){
          await executeManagePost(apiMatricial, payload, successManageSearchCallback);
         }
      }
      
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }

  const getPayload = () => {
    const payload = {
      EsOrdenFechaInicioAscendente: formValues.EsOrdenFechaInicioAscendente,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
    if (typeSelected.selected?.value === BUSCAR_NOMBRE.value) {
      payload["Criteria"]= searchCriteria;
    }
    return payload;
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleSelectedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues({...formValues, selectedFilters: updatedSelectedFilters});
  }

  const handleDownloadExcel = async () => {

    const api = filterType != TIPOS_LISTAR_VACACIONES.Matricial.value ? vacacionesAdminDescargarHistoricoAPI() : vacacionesAdminDescargarHistoricoAPIMatricial();

    const payload = getPayload();
    await downloadAndDelete(api, payload);
  }

  const handleClean = () => {
    setFormValues(initFormValues);
    setPaginator({CurrentPage: 1});
  }

  const HEADERS_TABLE = [
    {col: "", label: "Colaborador"},
    {col: "", label: 
      <TableSelectSearch
        width="300px"
        options={handleFind(formValues.filters, COMMON_KEYS.Cargos)}
        description="Cargos"
        setSelectedOptions={(res) => handleFilterSelected(res, COMMON_KEYS.Cargos)}
      />
    },
    {col: "", label: 
      <TableSelectSearch
        width="300px" 
        options={handleFind(formValues.filters, INIT_KEYS.Sedes)}
        description="Sedes"
        setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.Sedes)}
      />
    },
    {col: "", label:
      <TableSelectSearch
        width="300px" 
        options={handleFind(formValues.filters, COMMON_KEYS.Areas)}
        description="Áreas"
        setSelectedOptions={(res) => handleFilterSelected(res, COMMON_KEYS.Areas)}
      />
    },
    {col: "col-sm-1 py-0", label: 
      <>
        <div className='flex font-gothan-medium'>Fecha Inicio</div>
        <div className="custom-control custom-switch">
          <input className="custom-control-input hand-pointer" type="checkbox" checked={formValues.EsOrdenFechaInicioAscendente} onChange={({ target }) => setFormValues({...formValues, EsOrdenFechaInicioAscendente: target.checked})} disabled={!typeSelected.selected} id="admin-solicitud-cambios-fecha-order" />
          <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
              Más reciente
          </label>
        </div>
      </>
    },
    {col: "", label: "Fecha Fin"},
    {col: "", label: "Cant. Días"},
  ]

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters, formValues.EsOrdenFechaInicioAscendente ]); 


  useDidMountEffect(() => {
    if (typeSelected.selected?.value !== BUSCAR_NOMBRE.value){
      setSearchCriteria("");
      manageSearch();
    } else {
      handleClean();
    }
  }, [typeSelected.selected]);

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleSelectedFilters);
  }, [formValues.ExtraData])

  useDidMountEffect(() => {
    const addDropdownToAllFilters = (dropdowns, key) => {
      if (dropdowns.length > 0 && !allFilters.some(o => o.key === key)) {
        setAllFilters(lastData => [...lastData, { key, value: dropdowns }]);
      }
    };
  
    addDropdownToAllFilters(dropdownsAreas, COMMON_KEYS.Areas);
    addDropdownToAllFilters(dropdownsCargos, COMMON_KEYS.Cargos);
  }, [dropdownsAreas, dropdownsCargos])

  useEffect(() => {
    init();
  }, [])

  return ( 
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <div className='d-flex justify-content-between pt-4 mx-2'>
        <SelectType types={typeSelected} setTypes={setTypeSelected} />
        <DownloadExcel handleDownloadExcel={handleDownloadExcel} isDisabled={!typeSelected.selected || (formValues.Items.length === 0)} />
      </div>
      {typeSelected.selected?.value === BUSCAR_NOMBRE.value &&
        <>
          {!searchCriteria && 
            <MensajeNombre>{mensajeSeleccionarNombre}</MensajeNombre>
          }
          <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={handleClean} />
        </>
      }
      <PageTable headers={HEADERS_TABLE} paginator={paginator} setPaginator={setPaginator}>
        {!isFetching &&
          <TableWebBody rows={formValues.Items} />
        }
      </PageTable>
      {isFetching &&
        <div style={{ marginTop: '-120px' }}><SpinnerLoading /></div>
      }
    </PageWrapper>
  )
}

export default ListaHistoricoVacaciones;

const SelectType = ({ types, setTypes }) => (
  <div className="col-sm-4">
    <ReactSelect 
      options = {types.ids}
      value = {types.selected}
      onChange = {res => setTypes(lastData => ({ ...lastData, selected: res }))}
      placeholder = "Seleccionar proceso"
    />
  </div>
)

const DownloadExcel = ({ handleDownloadExcel, isDisabled }) => (
  <div className="d-flex justify-content-center align-items-center">
    <button className="btn generic-button-buscador" type="button" onClick={handleDownloadExcel} disabled={isDisabled} title="Descargar">
        Descargar Excel
    </button>
  </div>
)

const mensajeSeleccionarNombre = "Ingresar nombre para iniciar búsqueda";

const MensajeNombre = styled.div`
    background-color: #ffcbcb;
    color: #856404;
    margin: 10px 20px -19px 20px;
    flex: 0 0 35.3%;
    max-width: 35.3%;
    padding: 5px 15px;
    border-radius: 5px;
`;