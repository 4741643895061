import React from 'react'

const CardAccordion = ({ id, idParent, label, children, isShow, isShowGeneral = true }) => {
  return (
    isShowGeneral ?
    <div className="card">
      <div id={`heading-${id}`} className="card-header">
        <h5 className="mb-0">
          <button
            type="button"
            className="btn btn-link font-gothan-medium ficha-titulo-seccion-color"
            data-toggle="collapse"
            data-target={`#col${id}`}
            aria-expanded="true"
            aria-controls={`col${id}`}
          >
            {label}
          </button>
        </h5>
      </div>
      <div
        id={`col${id}`}
        className={`collapse ${isShow ? "show" : ""}`}
        aria-labelledby={`heading-${id}`}
        data-parent={`#accordion-${idParent}`}
      >
        {children}
      </div>
    </div> :
    <>
      {children}
    </>
  )
}

export default CardAccordion