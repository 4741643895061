import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../hooks/context/authContext';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import CustomCardAlert from '../../components/alerts/CustomCardAlert';
import { downloadFile } from '../../consumers/backendConsumer';
import { getAsignacionFamiliarAPI } from '../../consumers/backendApisUrls';
import SolicitarAsignacionFamiliarModal from './modales/SolicitarAsignacionFamiliarModal';
import { mensajeSeccionCompletada, mensajeSeccionFaltaCompletar } from '../../constants/messages';
import { TIPO_SOLICITUD_ASIGNACION_FAMILIAR } from '../../constants/hardCodedConstants';

const DatosAsignacionFamiliar = ({ empleadoId, seccionAsignacionFamiliar, setModalEnabled, seccionDatosFamiliares }) => {

  const { state } = useContext(AuthContext);

  const [formValues, setFormValues] = useState({PuedeSolicitar: true, SolicitudesAsignacion: [], TieneAsignacionFamiliar: ""});

  const [executeGet] = useManageGetRequest();

  const init = async () => {
    const api = getAsignacionFamiliarAPI(empleadoId);
    await executeGet(api, ({ data }) => setFormValues(data));
  }

  const handleDownloadFile = (row) => {
    downloadFile(
      state.token,
      row.Sustento?.Path,
      row.Sustento?.OriginalFileName
    );
  };

  useDidMountEffect(() => {
    init();
  }, [seccionDatosFamiliares])

  useEffect(() => {
    init();
  }, [])

  return (
    <div className='card'>
      <div id='headingFamiliaAsignacionFamiliar' className={seccionAsignacionFamiliar?.Completado === false ? 'card-header ficha-falta-completar' : 'card-header' } title={seccionAsignacionFamiliar?.Completado === false ? mensajeSeccionFaltaCompletar : mensajeSeccionCompletada }>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className='mb-0'>
            <button
                className='btn btn-link font-gothan-medium ficha-titulo-seccion-color'
                data-toggle='collapse'
                data-target='#colDatosAsignacionFamiliar'
                aria-expanded='true'
                aria-controls='colDatosAsignacionFamiliar'>
                Asignación Familiar
            </button>
          </h5>
          <label className='mb-0'>Cuenta con Asignación Familiar: <strong>{formValues.TieneAsignacionFamiliar}</strong></label>
        </div>
      </div>
      <div
        id='colDatosAsignacionFamiliar'
        className='collapse'
        aria-labelledby='headingFamiliaAsignacionFamiliar'
        data-parent='#accordion-datosFamiliares'>
        {empleadoId &&
          <>
            <CustomCardAlert variant="warning"/>
            <div className='card-body'>
              <div className='d-flex justify-content-between'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center ml-3' style={{gap: '5px'}}>
                    <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer blue_innova_text"/>
                    <label className='ficha-residencia-ver-info-icon-mensaje mb-0'>{CardMessage}</label>
                  </div>
                  <div className='d-flex align-items-center mt-3'>
                    <AlineamientosButton type='button' onClick={() => formValues.UrlLineamientos ? window.open(formValues.UrlLineamientos) : {}}>
                      Consulta Aquí
                    </AlineamientosButton>
                    <label className='ficha-residencia-ver-info-icon-mensaje mb-0 ml-2'>
                      para mayor detalle sobre los lineamientos de asignación familiar.
                    </label>
                  </div>
                </div>
                <div className=''>
                  <button 
                    className='btn btn-primary ficha-form-button-actualizar'
                    disabled={!formValues.PuedeSolicitar}
                    onClick={() => setModalEnabled({ isEnable: true, component: SolicitarAsignacionFamiliarModal, data: { empleadoId, refreshPage: init } })}
                    >
                      Solicitar
                  </button> 
                </div>
              </div>
              <div className='mt-4'>
                {!formValues.length ?
                  <div className='table-scroll'>
                    <table className='table table-hover'>
                      <thead className='listar-thead'>
                        <tr>
                          <th className='text-center'>Fecha de Registro</th>
                          <th className='text-center'>Nombre del Hijo</th>
                          <th className='text-center'>Vigencia</th>
                          <th className='text-center'>Estado Solicitud</th>
                          <th className='text-center'>Estado Aprobación</th>
                          <th className='text-center'>Tipo</th>
                          <th className='text-center'>Descargar Sustento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValues.SolicitudesAsignacion?.map((o, i) => (
                          <tr key={i}>
                            <td className='text-center'>{o.FechaRegistro}</td>
                            <td className=''>{o.NombreHijo}</td>
                            <td className='text-center'>{o.Vigencia}</td>
                            <td className='text-center'>{TIPO_SOLICITUD_ASIGNACION_FAMILIAR.SolAFMayor18años != o.IdTipoSolicitudAsignacionFamiliar ? "No Aplica" : o.EstadoSolicitud}</td>
                            <td className='text-center'>{o.EstadoAprobacion}</td>
                            <td className='text-center'>{o.TipoSolicitudAsignacionFamiliar ?? "-"}</td>
                            <td className=''>
                              <div className='d-flex justify-content-center' style={{gap: "10px"}}>
                                <FontAwesomeIcon
                                  icon="download"
                                  className='blue-standard-color'
                                  size='lg'
                                  title='Descargar'
                                  onClick={() => handleDownloadFile(o)}
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> 
                  </div> :
                  <label className='form-check-label ficha-declaracion-jurada-message'>{TableMessage}</label>
                }
              </div>
            </div>
          </>
        }
      </div>
    </div> 
  )
}

export default DatosAsignacionFamiliar;

const AlineamientosButton = styled.button`
  border: 1px solid #3a87c8;
  border-radius: 12px;
  background-color: #3a87c8;
  color: white;
  padding: 0px 10px;
  font-size: 14px;
`

const CardMessage = "Puedes generar una solicitud de asignación familiar de excepción cuando tienes un hijo mayor de 18 años y que se encuentre siguiendo estudios superiores, hasta los 24 años.";
const TableMessage = "No cuenta con solicitud de asignación familiar registrada.";