import React from "react";
import { useState, useEffect } from "react";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";
import {
  editarEstudioAdicionalAPI,
  getEditarEstudioAdicionalAPI,
} from "../../../consumers/backendApisUrls";
import {
  getEstadosCursoAPI,
  getPaisesAPI,
  getTiposCursoAPI,
  getSugerenciasDeNombreInstitucionEstudioAdicionalAPI
} from "../../../consumers/backendApisUrls";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useForm } from "../../../hooks/useForm/UseForm";
import { Spinner } from "react-bootstrap";
import SelectInput from "../../../components/Select/SelectInput";
import { estadoFormacion } from "../../../constants/states";
import AutoSuggestTextInput from "../../../components/AutoSuggestTextInput/AutoSuggestTextInput";
import { notNullAndNotUndefinded } from "../../../helper/utils";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { declaracionJurada } from "../../../constants/messages";

const EditarEstudio = ({ toggleModal, estudioAdicionalId, refresh, puedoEditar }) => {
  const sectionName = "EditarEstudioAdicional";
  const UPDATE_SUSTENTO_INPUT_FILE = "ficha-edit-sustento-file";
  const EXTENSIONES_VALIDAS = ["pdf", "png", "jpg"];

  let allInputKeys = ["IdEstudioAdicional", "NombreEstudio", "NombreInstitucion", "IdPaisInstitucion", "IdTipoEstudio", "IdEstado", "FechaFinalizacion", "FechaInicio", "FileViewModel", "AceptaDeclaracionJurada"];
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [manageGetRequest] = useManageGetRequest();

  const [paises, setPaises] = useState([]);
  const [tiposEstudio, setTiposEstudio] = useState([]);
  const [estados, setEstados] = useState([]);
  const [currentYear] = useState(new Date().getFullYear());

  const [formValues, setFormValues] = useState({ "DeclaracionJurada": false });
  const [handleChange] = useForm(formValues, setFormValues);
  const [nombreInstitucionSelected, setNombreInstitucionSelected] = useState("");
  const [paisSeleccionado, setPaisSeleccionado] = useState({});
  const [tipoSeleccionado, setTipoSeleccionado] = useState({});
  const [estadoSeleccionado, setEstadoSeleccionado] = useState({});
  const [esFinalizado, setEsFinalizado] = useState(false);
  const [esConSustento, setEsConSustento] = useState(false);
  const [fileSustentoCargado, setFileSustentoCargado] = useState(null);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showTooltipAlreadySavedFile, setShowTooltipAlreadySavedFile] = useState(false);

  const [subiendoArchivos, setSubiendoArchivos] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    setEsFinalizado(estadoSeleccionado?.label != estadoFormacion.EnCurso.nombre)
    let boolSustento = estadoSeleccionado.label == estadoFormacion.Finalizado.nombre;
    setEsConSustento(boolSustento);
    !boolSustento && setFileSustentoCargado({});
  }, [estadoSeleccionado])

  useEffect(() => {
    if (esFinalizado === false) {
      setFormValues({ ...formValues, FechaFinalizacion: "" });
      setFileSustentoCargado({});
    }
  }, [esFinalizado]);

  useEffect(() => {
    if (usuarioSubioArchivoNuevo())
      setShowTooltipAlreadySavedFile(false);
  }, [fileSustentoCargado])

  const usuarioSubioArchivoNuevo = () => {
    return (notNullAndNotUndefinded(fileSustentoCargado?.Path))
  }

  const callbackSuccessEditInfo = (response) => {
    let data = response?.data;
    setFormValues({
      NombreEstudio: data.NombreEstudio,
      FechaInicio: data.FechaInicio,
      FechaFinalizacion: data.FechaFinalizacion,
      AceptaDeclaracionJurada: data.AceptaDeclaracionJurada
    })
    setNombreInstitucionSelected(data.NombreInstitucion)
    setPaisSeleccionado(data.PaisInstitucion);
    setTipoSeleccionado(data.TipoEstudio);
    setEstadoSeleccionado(data.Estado);
    setFileSustentoCargado(data.File);
    if (data.EstadoName != estadoFormacion.EnCurso.nombre) {
      setEsFinalizado(true);
      data?.File != null && setShowTooltipAlreadySavedFile(true);
    } else {
      setEsFinalizado(false);
      data?.File == null && setShowTooltipAlreadySavedFile(false);
    }
  }

  const init = async () => {
    try {
      setLoading(true);
      let api = getEditarEstudioAdicionalAPI(estudioAdicionalId)
      await manageGetRequest(api, callbackSuccessEditInfo, false);

      setPaises(await getOptions(getPaisesAPI()));
      setTiposEstudio(await getOptions(getTiposCursoAPI()));
      setEstados(await getOptions(getEstadosCursoAPI()));

      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const successSubmitCallback = () => {
    refresh();
    toggleModal();
  };

  const submit = async () => {
    setDisableSubmitButton(true);

    let payload = {
      IdEstudioAdicional: estudioAdicionalId,
      NombreEstudio: formValues?.NombreEstudio,
      NombreInstitucion: nombreInstitucionSelected,
      IdPaisInstitucion: paisSeleccionado?.value,
      IdTipoEstudio: tipoSeleccionado?.value,
      IdEstado: estadoSeleccionado?.value,
      FechaInicio: formValues?.FechaInicio,
      FechaFinalizacion: formValues?.FechaFinalizacion,
      AceptaDeclaracionJurada: formValues?.AceptaDeclaracionJurada,
      FileViewModel: fileSustentoCargado,
      EsFinalizado: esFinalizado,
      EsConSustento: esConSustento
    };
    let api = editarEstudioAdicionalAPI();
    await submitForm(
      allInputKeys,
      api,
      payload,
      sectionName,
      successSubmitCallback
    );

    setDisableSubmitButton(false);
  };

  if (loading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <GenericModal
      showModal={toggleModal}
      width={"950px"}
      titulo={puedoEditar ? "Editar Estudio Adicional" : "Detalle Estudio Adicional"}
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form autoComplete="off">
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Tipo de estudio<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput options={tiposEstudio}
                jsonSelectedValue={tipoSeleccionado}
                setJsonSelectedValue={setTipoSeleccionado}
                placeholder={"Seleccionar tipo de estudio"}
                resetSelectedValue={false}
                isDisabled={!puedoEditar} />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdTipoEstudio-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Nombre de estudio<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <input
                type="text"
                className="form-control"
                name="NombreEstudio"
                onChange={handleChange}
                value={formValues.NombreEstudio}
                placeholder={"Máximo 100 caracteres"}
                maxLength={"100"}
                disabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-NombreEstudio-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Institución/Empresa<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <AutoSuggestTextInput
                inputTextValue={nombreInstitucionSelected}
                setInputTextValue={setNombreInstitucionSelected}
                suggestionsPostApi={
                  getSugerenciasDeNombreInstitucionEstudioAdicionalAPI
                }
                placeholder={"Máximo 100 caracteres"}
                maxlength={"100"}
                disabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-NombreInstitucion-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              País de institución<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput options={paises}
                jsonSelectedValue={paisSeleccionado}
                setJsonSelectedValue={setPaisSeleccionado}
                placeholder={"Seleccionar país"}
                resetSelectedValue={false}
                isDisabled={!puedoEditar} />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdPaisInstitucion-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Estado del estudio <span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <SelectInput options={estados}
                jsonSelectedValue={estadoSeleccionado}
                setJsonSelectedValue={setEstadoSeleccionado}
                placeholder={"Seleccionar estado del estudio"}
                resetSelectedValue={false}
                isDisabled={!puedoEditar} />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-IdEstado-validation-message`}
              ></p>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label className="col-sm-3 col-form-label modal-label">
              Fecha inicio<span className="required">*</span>
            </label>
            <div className="col-sm-9 black-color">
              <input
                type="date"
                min={currentYear - 50 + "-01-01"}
                max={currentYear}
                value={formValues.FechaInicio}
                onChange={handleChange}
                className="form-control"
                name="FechaInicio"
                id="ficha-edit-estudioAdicional-FechaInicio"
                disabled={!puedoEditar}
              />
              <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}
              ></p>
            </div>
          </div>
          {esFinalizado && (
            <div className="form-group row mb-0">
              <label className="col-sm-3 col-form-label modal-label">
                Fecha finalización<span className="required">*</span>
              </label>
              <div className="col-sm-9 black-color">
                <input
                  type="date"
                  min={currentYear - 50 + "-01-01"}
                  max={currentYear}
                  value={formValues.FechaFinalizacion}
                  onChange={handleChange}
                  className="form-control"
                  name="FechaFinalizacion"
                  id="ficha-edit-estudioAdicional-FechaFinalizacion"
                  disabled={!puedoEditar}
                />
                <p
                  className="ficha-form-input-validation-message"
                  id={`ficha-edit-${sectionName}-FechaFinalizacion-validation-message`}
                ></p>
              </div>
            </div>
          )}

          {puedoEditar && <>
            {esFinalizado && esConSustento && (
              <div className="form-group row mb-0">
                <label className="col-sm-3 col-form-label modal-label">
                  Sustento<span className="required">*</span>
                </label>
                <div className="col-sm-9 black-color">
                  <GenericUploadFileV2
                    uploadFileToS3={setFileSustentoCargado}
                    id={UPDATE_SUSTENTO_INPUT_FILE}
                    extensionesValidas={EXTENSIONES_VALIDAS}
                    textButton={"Subir archivo"}
                    subiendoArchivoPublicState={subiendoArchivos}
                    setSubiendoArchivoPublicState={setSubiendoArchivos}
                  >
                    {showTooltipAlreadySavedFile &&
                      <>
                        <p
                          data-tip
                          data-for="informacion-residencia-sustento-already-saved"
                          style={{ display: "inline" }}
                        >
                          <FontAwesomeIcon
                            icon="info-circle"
                            transform="left-9"
                            className="hand-pointer ficha-residencia-ver-info-icon"
                          />
                        </p>
                        <ReactTooltip
                          id="informacion-residencia-sustento-already-saved"
                          place="top"
                          effect="solid"
                          className="tooltip-style tooltip-change-witdh"
                        >
                          Usted ya tiene un adjunto guardado previamente por lo que
                          no es necesario que adjunte un nuevo archivo.
                        </ReactTooltip>
                      </>}
                  </GenericUploadFileV2>
                  <p
                    className="ficha-form-input-validation-message"
                    id={`ficha-edit-${sectionName}-FileViewModel-validation-message`}
                  ></p>
                </div>
                <div>
                  <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                    <strong>* El formato permitido es un .pdf, .jpg, .png de máximo 8MB.</strong>
                  </label>
                </div>
              </div>
            )}</>}
        </form>
      </div>

      {puedoEditar &&
        <>
          <div className="form-check margin-top-10px">
            <input
              className="form-check-input"
              type="checkbox"
              id="ficha-agregar-agregarEstudio-AceptaDeclaracionJurada"
              name="AceptaDeclaracionJurada"
              checked={formValues.AceptaDeclaracionJurada}
              onChange={handleChange}
            ></input>
            <label
              className="form-check-label ficha-declaracion-jurada-message"
              htmlFor="AceptaDeclaracionJurada"
            >
              <strong>
                <p className="mb-0">
                  {declaracionJurada}
                </p>
              </strong>
            </label>
            <p
              className="ficha-form-input-validation-message"
              id={`ficha-edit-${sectionName}-AceptaDeclaracionJurada-validation-message`}
            ></p>
          </div>
          <div className="modal-footer d-flex justify-content-end pr-4">
            <div className="button">
              <button
                className="btn btn-primary generic-button-ficha-modal"
                onClick={submit}
                disabled={disableSubmitButton || subiendoArchivos}
              >
                Guardar
              </button>
            </div>
          </div>
        </>
      }
    </GenericModal>
  );
};

export default EditarEstudio;
