import React from 'react';

const NotificacionModal = ({notificacion}) => {

    return (
        <>
            <div className="notification" style={{'background': notificacion.EsNueva ? 'rgba(243, 243, 243, 1)' : ''}}>
                <div className="notification-image">
                    <img alt="img" src={notificacion.Image}></img>
                </div>
                <div className="notification-content">
                    <p className="notification-title">{notificacion.Titulo}</p>
                    <a target="_blank" href={notificacion.NotificacionUrl}><p className="notification-description">{notificacion.Descripcion}</p></a>
                </div>
                <div className="notification-date-container">
                    <p className="notification-date">{notificacion.Date}</p>
                </div>
            </div>
        </>
    )
};

export default NotificacionModal;