import React, { useContext, useState } from "react";
import {
  extensionTamañoDeArchivoValido,
  jsonEstaVacio,
  parseExtensionesToString,
} from "../../../helper/utils";
import { toast } from 'react-toastify';
import { useGenerarDescargarEliminarArchivo } from "../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import GenericModal from "../../../components/modal/GenericModal";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { FILE_FORMATS, FILE_SIZE_2MB } from "../../../constants/hardCodedConstants";
import { uploadFile } from "../../../consumers/backendConsumer";
import { AuthContext } from "../../../hooks/context/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertErrorExcelFile2MBSizeMessage } from "../../../components/alerts/AlertErrorMaxFileSizeMessage";

const SubirArchivoMasivo = ({
  api,
  id,
  additionalPayload = {},
  validExtensions,
  tittle = "Cargar archivo",
  toggleModal,
  refreshPage,
  hasDownloadFormat,
  apiDownloadFormat,
  children,
}) => {
  const { state } = useContext(AuthContext);
  const [uploadFileHelpDesk, setUploadFileHelpDesk] = useState({});
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloadingFormat, setIsDownloadingFormat] = useState(false);

  const [executePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let payload = {
        "ArchivoCierreMasivo": uploadFileHelpDesk,
        "ArchivosResultadoEvaluacion": files,
      };
      await executePost(api, payload, successSubmitCallback);
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const handleDownload = async () => {
    setIsDownloadingFormat(true);
    try {
      await downloadAndDelete(apiDownloadFormat(), {});
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloadingFormat(false);
    }
  };

  const successSubmitCallback = async () => {
    refreshPage && (await refreshPage());
    toggleModal();
  };

  const handleUpload = async (e) => {
    if (!e.target.files.length) {
      return toast.warn("Tiene que cargar al menos un archivo");
    }
    setIsUploading(true);
    try {
      const uploadedFiles = await getUploadedFiles(e);
      setFiles((prev) => [...prev, ...uploadedFiles]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  };

  const getUploadedFiles = async (e) => {
    const files = e.target.files;
    let uploadedFilesPromises = [];
    for (let file of files) {
      if (!extensionTamañoDeArchivoValido(file, FILE_FORMATS.PDF_FORMAT, FILE_SIZE_2MB)) continue;
      uploadedFilesPromises.push(uploadFile(file, state.token));
    }
    const response = await Promise.allSettled(uploadedFilesPromises);
    const uploadedFiles = response.filter(o => o.status === "fulfilled")?.map(o => o.value.data?.file);
    return uploadedFiles;
  }

  return (
    <GenericModal showModal={toggleModal} width={"650px"} titulo={tittle}>
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-4">
            <label className="col-sm-6 col-form-label modal-label">
              Archivo de Cierre Masivo
            </label>
            <div className="col-sm-6 px-0 d-flex">
              <GenericUploadFileV2
                uploadFileToS3={setUploadFileHelpDesk}
                id={id}
                textButton={"Adjuntar archivo"}
                extensionesValidas={validExtensions}
                ToastArchivoNoValido={AlertErrorExcelFile2MBSizeMessage}
                maxFileSize={FILE_SIZE_2MB}
                showToastSuccess={false}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-6 col-form-label modal-label">
              Resultados de Evaluación
            </label>
            <div className="col-sm-6 px-0 d-flex">
              <div className="row">
                <div className="col-sm-12">
                  <label
                    htmlFor="file-ResultadosEvaluacion"
                    className="btn btn-primary custom-button-adjuntar mb-3">
                    {isUploading ? (
                      <FontAwesomeIcon
                        icon="spinner"
                        transform="left-9"
                        className="hand-pointer spinner ml-3"
                      />
                    ) : (
                      "Cargar Archivos"
                    )}
                  </label>
                  <input
                    type="file"
                    id="file-ResultadosEvaluacion"
                    className="d-none"
                    multiple
                    onChange={handleUpload}
                    accept={parseExtensionesToString(FILE_FORMATS.PDF_FORMAT)}
                    disabled={isUploading || isSubmitting}
                  />
                </div>
                <div className="col-sm-12">
                  {files.length > 0 && <p>{files.length} archivos cargados</p>}
                </div>
              </div>
            </div>
          </div>
          <div
            role="alert"
            class="fade alert alert-warning alert-dismissible show text-center w-100">
            <p class="ficha-secciones-title-text font-gothan-light">
              Tener en cuenta que el límite máximo son 40 archivos de 2MB cada uno.
            </p>
          </div>
          <div className="modal-footer justify-content-end">
            <div
              className="d-flex justify-content-between"
              style={{ gap: "15px" }}>
              {children}
              {hasDownloadFormat && (
                <button
                  type="button"
                  className="btn generic-button-buscador"
                  disabled={isDownloadingFormat}
                  onClick={handleDownload}>
                  Descargar Formato
                </button>
              )}
              <button
                type="submit"
                className="btn generic-button-buscador"
                disabled={isSubmitting || jsonEstaVacio(uploadFileHelpDesk)}>
                Cargar
              </button>
            </div>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default SubirArchivoMasivo;
