import {toast} from "react-toastify";
import {notNullAndNotUndefinded} from "../helper/utils";

export const mostrarMensajeDeErrorConToast = (error) => {
    try {
        let message = "";
        if (notNullAndNotUndefinded(error) && notNullAndNotUndefinded(error.response)){
            if (error.response.status === 401) {
                console.warn("Su sesión ha expirado.");
                return;
            }

            if (error.response) {
                let data = error.response.data;
                if (notNullAndNotUndefinded(data)) {
                    console.debug("message ", message);
                    message = data.Message;
                    console.debug("message ", message);
                }
                console.error("error.response.data: ", error.response.data);
                console.error("error.response.status: ", error.response.status);
                console.error("error.response.headers: ", error.response.headers);
            } else if (error.request) {
                console.error("error.request: ", error.request);
            } else {
                console.error("error.message: ", error.message);
            }
            console.error("error.message ", error.message);
            if (error instanceof Error) {
                console.error("error.message ", error.message);
            }

            if (notNullAndNotUndefinded(message) && message != "") {
                toast.error(message);
            } else {
                toast.error("Hubo un error :(");
            }
        }else{
            toast.error("Hubo un error :(");
        }
    } catch (e) {
        console.error(e);
        toast.error("Hubo un error :(");
    }
};

export const responseCode200 = (response) => {
    return (response && response.status && response.status === 200) ? true : false;
};

export const responseTieneModelState = function (response) {
    return notNullAndNotUndefinded(response?.response?.data?.ModelState);
};

