import React from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { eliminarEstudioAdicionalAPI } from "../../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";


const EliminarEstudio = ({
    toggleModal,
    estudioAdicionalId,
    estudioAdicionalNombre,
    refresh
}) => {

    const [managePost] = useManagePostRequest();

    const eliminarEstudioAdicional = async () => {
        await managePost(eliminarEstudioAdicionalAPI(estudioAdicionalId), {}, () => {
            toggleModal();
            refresh();
        },true);
    }

    return(
        <GenericModal titulo={"Eliminar"} showModal={toggleModal} width={"620px"}>
            <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    Se va a eliminar el siguiente Estudio Adicional:
                    <ul>
                        <li><strong>{estudioAdicionalNombre}</strong></li>
                    </ul>
                    ¿Está seguro de que desea eliminar este Estudio Adicional?
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <button
                    className="btn btn-primary generic-button-ficha mr-2"
                    onClick={eliminarEstudioAdicional}>
                    Eliminar
                </button>
                <button
                    className='btn btn-primary generic-button-ficha'
                    onClick={() => toggleModal()}>
                    Cancelar
                </button>
            </div>
        </GenericModal>
    )
};

export default EliminarEstudio;
