import React from 'react';
import PropTypes from 'prop-types';
import { INPUT_DIRECTION } from '../../constants/hardCodedConstants';
import AutoSuggestTextInput from '../AutoSuggestTextInput/AutoSuggestTextInput';

const InputTextAutoSuggest = ({
  label,
  name,
  value,
  onChange,
  sectionName,
  isRequired,
  isDisabled,
  api,
  direction = "row",
  placeholder = `Ingresar ${label}`,
}) => {
  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label d-flex ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color ${value ? "" : "highlight-input"}`}>
        <AutoSuggestTextInput
          inputTextValue={value ?? ""}
          setInputTextValue={handleOnChange}
          suggestionsPostApi={api}
          placeholder={placeholder}
          maxlength="100"
          disabled={isDisabled}
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

InputTextAutoSuggest.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  sectionName: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  api: PropTypes.func.isRequired,
  direction: PropTypes.string,
  placeholder: PropTypes.string,
}

export default InputTextAutoSuggest;