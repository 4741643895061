import React, { useEffect, useState } from "react";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";
import {
    editarEmpleadoIdiomaAPI, getCertificacionesIdiomaAPI, getEmpleadoIdiomaToEditAPI,
    getIdiomasAPI, getNivelesIdiomaAPI, getRelatedDataTypesAPI
} from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useForm } from "../../../hooks/useForm/UseForm";
import SelectInput from "../../../components/Select/SelectInput";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { Spinner } from "react-bootstrap";
import { jsonNoEstaVacio } from "../../../helper/utils";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { declaracionJurada } from "../../../constants/messages";

const EditarEmpleadoIdiomaModal = ({
    toggleCurrentModal,
    reFetchList,
    empleadoIdioma,
    puedoEditar
}) => {
    const [getOptions] = useGetOptionsSelect();
    const [manageGetRequest] = useManageGetRequest();

    const sectionName = "EditarEmpleadoIdiomaModal";

    let allInputKeys = ["DeclaracionJurada", "IdIdioma", "IdNivelIdioma", "IdCertificacionIdioma", "Sustento"]
    const [formValues, setFormValues] = useState({ "DeclaracionJurada": false });
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const ID_SUSTENTO_INPUT_FILE = "SustentoEmpleadoIdioma";
    const EXTENSIONES_VALIDAS = ["pdf", "png", "jpg"];
    const [sustentoFile, setSustentoFile] = useState({});

    const [entroAlUseEffect, setEntroAlUseEffect] = useState(false);

    const [idiomaOptions, setIdiomaOptions] = useState([]);
    const [jsonSelectedIdioma, setJsonSelectedIdioma] = useState({});

    const [nivelOptions, setNivelOptions] = useState([]);
    const [jsonSelectedNivel, setJsonSelectedNivel] = useState({});

    const [certificacionOptions, setCertificacionOptions] = useState([]);
    const [jsonSelectedCertificacion, setJsonSelectedCertificacion] = useState({});

    const [disableSubmitButton, setDisableSubmitButton] = useState(false);
    const [loading, setLoading] = useState(true);

    const [subiendoArchivos, setSubiendoArchivos] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (jsonNoEstaVacio(jsonSelectedIdioma)) {
            entroAlUseEffect && setJsonSelectedCertificacion({ value: "" });
            getRelatedDataTypes(jsonSelectedIdioma?.value)
        }
        setEntroAlUseEffect(true);
    }, [jsonSelectedIdioma]);

    const getRelatedDataTypes = async (idParent) => {
        let api = getRelatedDataTypesAPI(idParent);
        setCertificacionOptions(await getOptions(api));
    };

    const callbackSuccessEditInfo = (response) => {
        let data = response?.data;
        let initFormValues = {}
        setFormValues({ ...formValues, ...initFormValues });

        setJsonSelectedCertificacion({ 'label': data?.CertificacionIdiomaName, 'value': data?.IdCertificacionIdioma })
        setJsonSelectedNivel({ 'label': data?.NivelIdiomaName, 'value': data?.IdNivelIdioma })
        setJsonSelectedIdioma({ 'label': data?.IdiomaName, 'value': data?.IdIdioma })
    }

    const init = async () => {
        try {
            setLoading(true);
            await manageGetRequest(getEmpleadoIdiomaToEditAPI(empleadoIdioma?.IdEmpleadoIdioma), callbackSuccessEditInfo, false);

            setIdiomaOptions(await getOptions(getIdiomasAPI()));
            setNivelOptions(await getOptions(getNivelesIdiomaAPI()));

            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const successSubmitCallback = () => {
        reFetchList();
        toggleCurrentModal();
    }

    const submit = async () => {
        setDisableSubmitButton(true);

        let payload = {
            "IdEmpleadoIdioma": empleadoIdioma?.IdEmpleadoIdioma,
            "DeclaracionJurada": formValues["DeclaracionJurada"],
            "IdIdioma": jsonSelectedIdioma?.value,
            "IdNivelIdioma": jsonSelectedNivel?.value,
            "IdCertificacionIdioma": jsonSelectedCertificacion?.value,
            "Sustento": sustentoFile
        }

        let api = editarEmpleadoIdiomaAPI();
        await submitForm(allInputKeys, api, payload, sectionName, successSubmitCallback);
        setDisableSubmitButton(false);
    }

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        );
    }

    return (
        <>
            <GenericModal showModal={toggleCurrentModal} width={"790px"} titulo={puedoEditar ? "Editar Idioma" : "Detalle Idioma"}>
                <div className="modal-body pt-0 pl-0 pb-0">
                    <form>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Idioma<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={idiomaOptions}
                                    jsonSelectedValue={jsonSelectedIdioma}
                                    setJsonSelectedValue={setJsonSelectedIdioma}
                                    placeholder={"Seleccionar Idioma"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar}
                                />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdIdioma-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Nivel<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={nivelOptions}
                                    jsonSelectedValue={jsonSelectedNivel}
                                    setJsonSelectedValue={setJsonSelectedNivel}
                                    placeholder={"Seleccionar Nivel"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar}
                                />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdNivelIdioma-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Certificación<span
                                className='required'>*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={certificacionOptions}
                                    jsonSelectedValue={jsonSelectedCertificacion}
                                    setJsonSelectedValue={setJsonSelectedCertificacion}
                                    placeholder={"Seleccionar Certificación"}
                                    resetSelectedValue={false}
                                    isDisabled={!puedoEditar} />
                                <p className="ficha-form-input-validation-message"
                                    id={`ficha-edit-${sectionName}-IdCertificacionIdioma-validation-message`}></p>
                            </div>
                        </div>
                        {puedoEditar &&
                            <div className="form-group row mb-0">
                                <label className="col-sm-4 col-form-label modal-label">Sustento<span
                                    className='required'>*</span></label>
                                <div className="col-sm-8 black-color">
                                    <GenericUploadFileV2
                                        id={ID_SUSTENTO_INPUT_FILE}
                                        extensionesValidas={EXTENSIONES_VALIDAS}
                                        uploadFileToS3={setSustentoFile}
                                        textButton={"Subir archivo"}
                                        subiendoArchivoPublicState={subiendoArchivos}
                                        setSubiendoArchivoPublicState={setSubiendoArchivos}
                                    />
                                    <p className="ficha-form-input-validation-message"
                                        id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                                </div>
                                <div>
                                    <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light pl-4">
                                        <strong>* El formato permitido es un .pdf, .jpg, .png de máximo 8MB.</strong>
                                    </label>
                                </div>
                            </div>
                        }

                    </form>
                </div>

                {puedoEditar && <>
                    <div className="form-check margin-top-10px">
                        <input className="form-check-input" type="checkbox" name="DeclaracionJurada"
                            checked={formValues.DeclaracionJurada} onChange={handleChange}>
                        </input>
                        <label className="form-check-label ficha-declaracion-jurada-message">
                            <strong>
                                <p className="mb-0">{declaracionJurada}</p>
                            </strong>
                        </label>
                        <p className="ficha-form-input-validation-message"
                            id={`ficha-edit-${sectionName}-DeclaracionJurada-validation-message`}></p>
                    </div>
                    <div className="modal-footer d-flex justify-content-end pr-4">
                        <div className="button">
                            <button
                                className="btn btn-primary generic-button-ficha-modal"
                                onClick={submit}
                                disabled={disableSubmitButton || subiendoArchivos}>
                                Guardar
                            </button>
                        </div>
                    </div>
                </>}

            </GenericModal>
        </>
    )
}

export default EditarEmpleadoIdiomaModal;
