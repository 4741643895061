import React, { useState } from 'react'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputTextArea from '../../../components/modalComponents/InputTextArea'
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { rechazarSolicitudCuponTiempoLibreAPI } from '../../../consumers/backendApisUrls';

const RechazarSolicitud = ({ toggleModal, toggleModalMain = ()=>{}, refreshPage, row }) => {

  const SECTION_NAME = "MotivoRechazoSolicitud";
  const KEYS = {motivo: "MotivoRechazo"};

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const api = rechazarSolicitudCuponTiempoLibreAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { IdSolicitudCuponTiempoLibre } = row;
    return {
      IdSolicitudCuponTiempoLibre,
      ...formValues,
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    toggleModalMain();
    await refreshPage();
  }

  return (
    <ModalForm titulo="Motivo de No Aprobación de Solicitud" showModal={toggleModal} onSubmit={handleSubmit} isSubmitting={isSubmitting} width="700px">
      <InputTextArea label="Motivo" {...commomProps(KEYS.motivo)} direction='column' maxLength={250} placeholder="Máximo 250 caracteres."/>
    </ModalForm>
  )
}

export default RechazarSolicitud