import React, {useState, useEffect, useRef} from 'react';
import GenericModal from '../../../components/modal/GenericModal'
import SelectInput from '../../../components/Select/SelectInput';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { KEYS_SCHEDULE_RUNNER as KEYS, TYPE_FRECUENCY_SCHEDULE_RUNNER as TYPE_FRECUENCY } from '../../../constants/hardCodedConstants';
import { getEditCommandScheduleAPI, getFrequenciesAPI, getDiasDeSemanaAPI, saveEditCommandScheduleAPI } from '../../../consumers/backendApisUrls';

const EditSchedule = ({ id, toggleModal, manageSearch }) => {
  const SECTION_NAME = "EditarScheduleRunner";
  const isMounted = useRef(true);

  const [formValues, setFormValues] = useState({});
  const [frecuency, setFrecuency] = useState([]);
  const [dropdowns, setDropdowns] = useState({[KEYS.Hora]: hours, [KEYS.Dia]: days, [KEYS.DiaSemana]: []})
  const [optionsAvailable, setOptionsAvailable] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [executeGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const getInitialData = async () => {
    try {
      executeGet(getEditCommandScheduleAPI(id), (res) => setFormValues(res.data))
      executeGet(getFrequenciesAPI(), (res) => setFrecuency(ChangeForSelectInput(res)));
      let allWeekDays = await getOptions(getDiasDeSemanaAPI());
      setDropdowns(last => ({...last, [KEYS.DiaSemana]: allWeekDays}))
    } catch (error) {
      console.error(error);
    }
  }

  const ChangeForSelectInput = (res) => {
    return res?.data?.map(o => ({value: o.Value, label: o.Key}))
  }

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    try {
      const api = saveEditCommandScheduleAPI();
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    let basicStates = {IdCommand: id, [KEYS.Frecuencia]: formValues[KEYS.Frecuencia], FechaFin: formValues.FechaFin};

    if (isTypeFrecuencySelected()){
      let ids = TYPE_FRECUENCY[formValues[KEYS.Frecuencia]].map(({key}) => ({[key]: formValues[key]}));
      let selectedIds = Object.assign({}, ...ids);
      return {...basicStates, ...selectedIds};
    } else {
      return basicStates;
    }
  }

  const successOnSubmitCallback = () => {
    manageSearch();
    toggleModal();
  }

  const isTypeFrecuencySelected = () => {
    return Object.keys(TYPE_FRECUENCY).includes(formValues[KEYS.Frecuencia])
  }

  const handleDate = (res) => {
    let data = res.target.value;
    setFormValues(lastData => ({...lastData, FechaFin: data}))
  }

  useDidMountEffect(() => {
    setOptionsAvailable(isTypeFrecuencySelected() 
      ? TYPE_FRECUENCY[formValues[KEYS.Frecuencia]] 
      : [])
  }, [formValues[KEYS.Frecuencia]])

  useEffect(() => {
    if(isMounted.current) getInitialData();
    return () => {isMounted.current = false};
  }, [])

  return (
    <GenericModal showModal={toggleModal} titulo="Editar Schedule" width="600px">
      <div className="modal-body pt-0 pl-0">
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label required'>
            Nombre
          </label>
          <div className='col-sm-8 black-color'>
            <input className='form-control mb-3' value={formValues?.ClassName ?? ''} type='text' disabled/>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label required'>
            Frecuencia
          </label>
          <div className='col-sm-8 black-color'>
            <SelectInput
              options={frecuency}
              jsonSelectedValue={frecuency.find(o => o.value == formValues[KEYS.Frecuencia])}
              setJsonSelectedValue={(res) => setFormValues(lastData => ({...lastData, [KEYS.Frecuencia]: res.value}))}
              placeholder="Seleccionar"
              resetSelectedValue={false}
            />
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Frecuencia}-validation-message`}></p>
          </div>
        </div>
        <ComponentsSelected optionsAvailable={optionsAvailable} options={dropdowns} formValues={formValues} setFormValues={setFormValues} sectionName={SECTION_NAME} />
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label'>
            Fecha Fin
          </label>
          <div className='col-sm-8 black-color'>
            <input className='form-control mb-3' value={formValues?.FechaFin ?? ''} type="date" onChange={handleDate}/>
          </div>
        </div>
        <div className='modal-footer d-flex justify-content-end'>
          <div className='button'>
            <button className='btn btn-primary generic-button-ficha-modal' type='button' disabled={isSubmitting} onClick={handleOnSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </GenericModal>
  )
}

export default EditSchedule

const ComponentsSelected = ({optionsAvailable, options, formValues, setFormValues, sectionName}) => {
  return (
  <>
  {optionsAvailable?.map((option, i) => (
    <div className='form-group row' key={`${option.label}-${option.key}-${i}`}>
      <label className='col-sm-4 col-form-label modal-label'>
        {option.label}
      </label>
      <div className='col-sm-8 black-color'>
        <SelectInput
          options={options[option.key]}
          jsonSelectedValue={options[option.key]?.find(o => o.value == formValues[option.key])}
          setJsonSelectedValue={(res) => setFormValues(lastData => ({...lastData, [option.key]: res.value}))}
          placeholder="Seleccionar"
          resetSelectedValue={false}
          />
        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${option.key}-validation-message`}></p>
      </div>
    </div>))}
  </>
  )
}

const hours = [];
for (let i=0; i<24 ; i++){
  hours.push({value: i, label: `${i}:00`})
}

const days = [];
for (let i=1; i<31 ; i++){
  days.push({value: i, label: i})
}