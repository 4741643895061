import React, { useContext, useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { programarExamenCovidCaso } from '../../../consumers/backendApisUrls';
import { AuthContext } from '../../../hooks/context/authContext';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import hourValues from '../../gestionProgramacionPruebasCovid/modales/hourValues';

const ProgramarPruebaSubModal = ({ showModal, idRegistro }) => {

  const { state } = useContext(AuthContext);

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();

  const sectionName = "programarPruebaCovid"

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [executePostRequest] = usePostRequest();

  useEffect(() => {
    init();
  }, []);

  const programarPrueba = async () => {

    const api = programarExamenCovidCaso();
    const payload = {
      "IdCasoCovid": idRegistro || null,
      "FechaHora": `${formValues.Date} ${formValues.Hour}` || null,
      "Lugar": formValues.Lugar || null,
      "Direccion": formValues.Direccion || null
    };
    let allInputKeys = ["FechaHora", "Lugar", "Direccion"];


    await submitForm(allInputKeys, api, payload, sectionName, () => { showModal(); });
  };

  const init = async () => {
  };


  return <>
    <GenericModal showModal={showModal} width={"850px"} titulo={"Programar prueba"}>
      <div>
        <div>
          <form className="blue-standard-color">

            <div className="form-group row form-input-modal-contactanos reportes-label-container">
              <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                Fecha<span className="required">*</span>
              </label>
              <div className="col-sm-5">
                <input type="date" min={new Date().toISOString().split("T")[0]} className="form-control" name="Date" onChange={handleChange}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Date-validation-message`}></p>
              </div>


              <label className="col-sm-1 col-form-label modal-label blue-standard-color">
                Hora<span className="required">*</span>
              </label>
              <div className="col-sm-4">
                <input type="time" name="Hour" className="form-control" list="times" min="07:00" max="18:00" step="1800" onChange={handleChange}></input>
                <datalist id="times">
                  {hourValues.map((hour)=> <option key={hour} value={hour} />)}
                </datalist>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Hour-validation-message`}></p>
              </div>

              <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaHora-validation-message`}></p>
            </div>

            <div className="form-group row form-input-modal-contactanos reportes-label-container">
              <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                Lugar<span className="required">*</span>
              </label>
              <div className="col-sm-10">
                <input type="text" className="form-control" name="Lugar" onChange={handleChange}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Lugar-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row form-input-modal-contactanos reportes-label-container">
              <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                Dirección<span className="required">*</span>
              </label>
              <div className="col-sm-10">
                <input type="text" className="form-control" name="Direccion" onChange={handleChange}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Direccion-validation-message`}></p>
              </div>
            </div>


          </form>
        </div>
      </div>
      <div>
        <div className="modal-footer mt-2">
          <div className="message font-gothan-light">
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={programarPrueba}>Programar</button>
          </div>
        </div>
      </div>
    </GenericModal>
  </>;
};

export default ProgramarPruebaSubModal;
