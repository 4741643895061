import React, { useEffect, useState } from 'react'
import ModalForm from '../../../../../../components/modalComponents/ModalForm'
import InputSelect from '../../../../../../components/modalComponents/InputSelect';
import SpinnerLoading from '../../../../../../components/pageComponents/SpinnerLoading';
import { useSubmitForm } from '../../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsSelect } from '../../../../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { initialAllOptionPromises } from '../../../../../../helper/utils';
import { getRequerimientosReasignarAPI, reasignarRequerimientoNuevosIngresosAdminAPI } from '../../../../../../consumers/backendApisUrls';

const ReasignarRequerimiento = ({ toggleModal, refreshPage, IdNuevosIngresosEmpleado }) => {
  
  const SECTION_NAME = "ReasignarNuevoIngresoARequerimiento";
  const KEYS = { idRequerimiento: "IdRequerimientoPersonal", idNuevoIngreso: "IdNuevosIngresosEmpleado" };

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDropdowns, setAllDropdowns] = useState([]);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [commonSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const initialPromises = () => ([
    getOptions(getRequerimientosReasignarAPI()),
  ])

  const init = async () => {
    try {
      await initialAllOptionPromises(initialPromises, Object.values(KEYS), setAllDropdowns);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = reasignarRequerimientoNuevosIngresosAdminAPI();
    const payload = { IdNuevosIngresosEmpleado, ...formValues };
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitForm);
    setIsSubmitting(false);
  }

  const successSubmitForm = async () => {
    toggleModal();
    await refreshPage();
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm titulo="Reasignar Requerimiento" showModal={toggleModal} width="650px" isSubmitting={isSubmitting} saveButtonDisabled={isLoading} onSubmit={handleSubmit}>
      {isLoading
        ? <SpinnerLoading />
        : <InputSelect label='Requerimiento' {...commonSelect(KEYS.idRequerimiento)} isRequired/>
      }
    </ModalForm>
  )
}

export default ReasignarRequerimiento