import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import GenericModal from '../../../components/modal/GenericModal';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { jsonEstaVacio, jsonNoEstaVacio } from '../../../helper/utils';
import { getDataParaGuardarCharlaMamaInnovaAPI, maternidadGuardarCharlaMamaInnovaActivityAPI } from '../../../consumers/backendApisUrls';

const EXTENSIONS = ["pdf", "jpg", "png"];

const KitCharlaMamaMaternidad = ({ toggleModal, manageSearch, idEmpleadoFlujoActividad: IdEmpleadoFlujoActividad }) => {

  const [signedFormat, setSignedFormat] = useState({});
  const [format, setFormat] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloadingFormat, setIsDownloadingFormat] = useState(false);

  const [executePost] = useManagePostRequest();
  const [downloadFile] = useDescargarArchivoDeS3()

  const init = async () => {
    setIsLoading(true);
    const api = getDataParaGuardarCharlaMamaInnovaAPI();
    await executePost(api, {}, ({ data }) => setFormat(data));
    setIsLoading(false);
  }

  const handleDownloadFormat = async () => {
    setIsDownloadingFormat(true);
    if (jsonNoEstaVacio(format)){
      await downloadFile(format.OriginalFileName, format.Path);
    } else {
      toast.warn("No se cuenta con formato");
    }
    setIsDownloadingFormat(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = maternidadGuardarCharlaMamaInnovaActivityAPI();
    let payload = { IdEmpleadoFlujoActividad, ArchivoFirmadoFileViewModel: signedFormat };
    try {
      await executePost(api, payload, successSubmitCallback);
    } catch (error) {
      console.error(error)
    }
    setIsSubmitting(false);
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await manageSearch();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <GenericModal showModal={toggleModal} width="650px" titulo="Asistencia a Charla y Recepción del Kit">
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-4">
            <label className="col-sm-2 col-form-label modal-label">
              Archivo
            </label>
            <div className="col-sm-10 px-0 d-flex justify-content-center">
              <GenericUploadFileV2
                uploadFileToS3={setSignedFormat}
                id="UploadSignedFormat"
                textButton="Adjuntar archivo"
                extensionesValidas={EXTENSIONS}
                showToastSuccess={false}
              />
            </div>
          </div>
          <div className="modal-footer justify-content-end">
            <div className="d-flex justify-content-between" style={{ gap: "15px" }}>
              {jsonNoEstaVacio(format) &&
                <button type="button" className="btn generic-button-buscador" disabled={isDownloadingFormat} onClick={handleDownloadFormat}>
                  Descargar Formato
                </button>
              }
              <button
                type="submit"
                className="btn generic-button-buscador"
                disabled={isSubmitting || jsonEstaVacio(signedFormat)}
              >
                Cargar
              </button>
            </div>
          </div>
        </form>
      </div>
    </GenericModal>
  )
}

export default KitCharlaMamaMaternidad