import React, { useState } from 'react'
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import ModalForm from '../../../components/modalComponents/ModalForm';

const MotivoModal = ({ toggleModal, toggleParent, refreshPage, id, title, api }) => {

  const SECTION_NAME = `MotivoRequerimientoPersonal-${title}`;
  const KEYS = ["Motivo"];

  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);
  
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const apiPayload = api();
      const payload = { IdRequerimientoPersonal: parseInt(id), ...formValues };
      await submitForm(KEYS, apiPayload, payload, SECTION_NAME, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successOnSubmitCallback = () => {
    toggleParent();
    refreshPage();
  }

  return (
    <ModalForm onSubmit={onSubmit} showModal={toggleModal} titulo={title} width="700px" saveButtonDisabled={isSending}>
      <InputTextArea label="Motivo" {...commonProps("Motivo")}/>
    </ModalForm>
  )
}

export default MotivoModal