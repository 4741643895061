import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GenericModal from '../../../components/modal/GenericModal';

const DenegacionVacuna = ({ toggleModal}) => {

    const history = useHistory();

    const Aceptar = async () => {
        history.push("/");
    };

    return (
        <GenericModal showModal={() => {Aceptar(); toggleModal()}} width={"600px"} titulo={"Acceso Denegado"}>
            <div>
                <p>Usted no tiene autorización en ingresar a esta pantalla.</p>
                <button className="btn btn-primary generic-button-ficha" onClick={Aceptar}>Aceptar</button>
            </div>
        </GenericModal>
    )
}

export default DenegacionVacuna;