import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../consumers/httpRequiestsUtils";
import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput} from "../../validations/FormValidatorUtils";
import {
    deleteFileFromS3API,
    downloadReporteProcesoCierre,
    getProcesosDeCierreListAPI,
} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import SelectInput from "../../components/Select/SelectInput";
import {useGetOptionsSelect} from "../../hooks/useGetOptionsSelect/useGetOptionsSelect";

const ReporteCierreDeAnio = () => {
    const {state} = useContext(AuthContext);
    const seccionName = "ReporteCierreDeAnio";

    const [executePostRequest] = usePostRequest();
    const [procesosDeCierreOptions, setProcesosDeCierreOptions] = useState([]);
    const [jsonSelectedProcesoCierre, setJsonSelectedProcesoCierre] = useState({});
    const [getOptions] = useGetOptionsSelect();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    const init = async function () {
        setProcesosDeCierreOptions(await getOptions(getProcesosDeCierreListAPI()));
    };

    const descargarReporte = async () => {
        let payload = {
            "IdProcesoCierre": jsonSelectedProcesoCierre?.value
        };

        let allInputKeys = ["IdProcesoCierre"];
        let responseReporteGenerado = await executePostRequest(downloadReporteProcesoCierre(), payload);
        if (responseCode200(responseReporteGenerado)) {
            clearAllInputValidationMessages(allInputKeys, seccionName);
            let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
            if (responseCode200(descargarReporteResponse)) {
                await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
            } else {
                mostrarMensajeDeErrorConToast(descargarReporteResponse);
            }
        } else if (responseTieneModelState(responseReporteGenerado)) {
            mostrarMensajesDeValidacionEnCadaInput(responseReporteGenerado, allInputKeys, seccionName);
        } else {
            clearAllInputValidationMessages(allInputKeys, seccionName);
            mostrarMensajeDeErrorConToast(responseReporteGenerado);
        }
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    return (
        <>
            <div className='card-body'>
                <div id={seccionName}>
                    <div>
                        <div className="form-group row form-input-modal-contactanos reportes-label-container">
                            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Procesos de cierre<span className="required">*</span></label>
                            <div className="col-sm-4">
                                <SelectInput options={procesosDeCierreOptions} jsonSelectedValue={jsonSelectedProcesoCierre} setJsonSelectedValue={setJsonSelectedProcesoCierre} placeholder={"Seleccionar"} isDisabled={false}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdProcesoCierre-validation-message`}></p>
                            </div>
                        </div>
                    </div>

                    <div className="reporte-datos-generales-dato-obligatorio">
                        <p>(<span className="required ">*</span>) Dato obligatorio</p>
                    </div>

                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={descargarReporte}>Descargar Reporte</button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ReporteCierreDeAnio;