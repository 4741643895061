import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {obtenerArchivoDeEvent} from "../../helper/utils";

const GenericUploadFile = ({handleUploadFile, datosSeccion, fileName, subiendoArchivo, removeUploadedFile, id, textButton, children}) => {
    const [fileee, setFileee] = useState();

    const handleUploadFileProxy = async function (e) {
        let file = obtenerArchivoDeEvent(e);
        const url = window.URL.createObjectURL(new Blob([file]));
        setFileee(url);

        await handleUploadFile(e);
    };

    return (
        <>
            <div>
                <label className="custom-file-upload mb-0">
                    <input type="file" id={id} onChange={handleUploadFileProxy} accept=".pdf,.jpg,.png"></input>
                    <p className='btn btn-primary mb-0 custom-button-adjuntar'>{textButton}</p>
                    {children}
                    &nbsp;&nbsp; <a title="Descargar archivo" href={fileee} download={fileName}>{fileName}</a> {subiendoArchivo && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}
                </label>
                {fileName.length > 0 &&
                <>
                    <p data-tip style={{display: 'inline'}} className="hand-pointer" title="Eliminar Archivo">
                        &nbsp;&nbsp; <FontAwesomeIcon icon='eraser' className="hand-pointer" onClick={removeUploadedFile}/>
                    </p>
                </>
                }
            </div>
        </>
    );
};

export default GenericUploadFile;