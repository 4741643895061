import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

const EditIcon = ({color, size, onClick}) => {

    return (
        <Icon color={color} size={size} viewBox={'0 0 23 20'} onClick={onClick}>
            <path
                d='M15.7148 13.4727L16.9648 12.2227C17.1602 12.0273 17.5 12.1641 17.5 12.4453V18.125C17.5 19.1602 16.6602 20 15.625 20H1.875C0.839844 20 0 19.1602 0 18.125V4.375C0 3.33984 0.839844 2.5 1.875 2.5H12.5586C12.8359 2.5 12.9766 2.83594 12.7812 3.03516L11.5312 4.28516C11.4727 4.34375 11.3945 4.375 11.3086 4.375H1.875V18.125H15.625V13.6914C15.625 13.6094 15.6562 13.5312 15.7148 13.4727ZM21.832 5.58984L11.5742 15.8477L8.04297 16.2383C7.01953 16.3516 6.14844 15.4883 6.26172 14.457L6.65234 10.9258L16.9102 0.667969C17.8047 -0.226563 19.25 -0.226563 20.1406 0.667969L21.8281 2.35547C22.7227 3.25 22.7227 4.69922 21.832 5.58984ZM17.9727 6.79688L15.7031 4.52734L8.44531 11.7891L8.16016 14.3398L10.7109 14.0547L17.9727 6.79688ZM20.5039 3.68359L18.8164 1.99609C18.6562 1.83594 18.3945 1.83594 18.2383 1.99609L17.0312 3.20312L19.3008 5.47266L20.5078 4.26562C20.6641 4.10156 20.6641 3.84375 20.5039 3.68359Z'
                fill='#1890FF'
            />
        </Icon>
    );
};

EditIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

export default EditIcon;
