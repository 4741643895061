import React from 'react'
import confetti from "../../../../image/confetti.svg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from '../../../../validations/FormValidatorUtils';
import { responseCode200, responseTieneModelState } from '../../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import ModalMensajeSuccessfull from '../../../../components/modalMensaje/ModalMensajeSuccessfull';
import GenericModal from '../../../../components/modal/GenericModal';
import { ALL_SECTION_KEYS_NUEVOS_INGRESOS as SECTION_KEYS } from '../../../../constants/hardCodedConstants';

const SendModalValidacion = ({ toggleModal, titulo, actionLabel, api, id, name, setModalEnable, isActividadPendiente: ifActividadPendienteThenHasBackButton, manageSearchActividadesPendientes, toggleParentModal = () => {} }) => {

  const [managePost] = usePostRequest();

  const handleSend = async () => {
    let response = await managePost(api(id), {});
    if (responseCode200(response)){
      SECTION_KEYS.map(o => clearAllInputValidationMessages(o?.KEYS, o?.SECTION));
      successHandleCallback(response);
    } else {
      if(responseTieneModelState(response)){
        SECTION_KEYS.map(o => mostrarMensajesDeValidacionEnCadaInput(response, o?.KEYS, o?.SECTION));
      }
      failureHandleCallBack(response);
    }
  };

  const successHandleCallback = (response) => {
    let message = response.data?.Message;
    toggleModal();
    const toggleModalAll = () => {
      setModalEnable({ isEnable: false });
      toggleParentModal();
    }
    setModalEnable({isEnable: true, component: ModalMensajeSuccessfull, data: { toggleModal: toggleModalAll, modalMensaje: message, image: confetti, hasBackButton: ifActividadPendienteThenHasBackButton ?? null, manageSearch: manageSearchActividadesPendientes}});
  };

  const failureHandleCallBack = (error) => {
    let modelState = error?.response?.data?.ModelState;
    toggleModal();
    setModalEnable({isEnable: true, component: ModalMensajeErrorCampos, data: {hasBackButton: true, modelState }});
  };

  return (
    <GenericModal
      titulo={titulo}
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {actionLabel} el siguiente item:
          <ul>
            <li>
              <strong>{name}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer justify-content-end">
          <button 
            className="btn btn-primary generic-button-ficha mr-2" 
            onClick={handleSend}>
            {titulo}
          </button>
          <button 
            className="btn btn-primary generic-button-ficha" 
            onClick={() => {toggleModal()}}>
            Cancelar
          </button>
      </div>
    </GenericModal>
  );
}

export default SendModalValidacion;

const ModalMensajeErrorCampos = ({toggleModal, hasBackButton, modelState}) => (
  <div className="modal-response-ficha-background">
    <div className="modal-response-ficha-content" style={{width: "800px", height: "400px"}}>
      <div className="text-center font-gothan-bold mb-3" style={{fontSize: "20px"}}>
        Falta completar datos en la ficha:
      </div>
      <hr style={{width: "90%"}}/>
      <div className="d-flex justify-content-start px-5 mr-2 w-100">
        <ul>
          {Object.keys(modelState)?.some(o => o !== "Files") &&
            <li>
              <div>
                <label className="font-gothan-bold">
                  Completa los datos de la Pestaña 1: Datos Personales
                </label>
                <p style={{fontSize: "15px"}}>
                  Se mostrarán con un mensaje en rojo.
                </p>
              </div>
            </li>
          }
          {Object.keys(modelState)?.some(o => o === "Files") &&
            <li>
              <div>
                <label className="font-gothan-bold">
                  Completa los datos de: Lista de Documentos
                </label>
                <div style={{fontSize: "15px"}}>
                  <p className="mb-1">
                    Aquí encontrarás los documentos que debes descargar y completar danto click en: <FontAwesomeIcon className="blue-standard-color ml-2" title="Descargar archivo" size="lg" icon={faDownload}/>
                  </p>
                  <p>
                    y subir el documento firmado haciendo click en: <FontAwesomeIcon className='required ml-2' title="Subir Archivo" size="lg" icon={faCloudUploadAlt}/>
                  </p>
                </div>
              </div>
            </li>
          }
        </ul>
      </div>
      {hasBackButton && 
        <button type="button" className="modal-response-ficha-content-button" onClick={() => toggleModal()}> Volver a ficha </button>
      }
    </div>
  </div>
)