import React, { useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../../components/GenericUploadFile/GenericUploadFileV2';
import { getDatosTercerPasoAPI, getDescargaFormatoDJAPI } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { useDescargarArchivoYEliminarArchivoDeS3 } from '../../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';

const ConstanciaCurso = ({idCursoCapacitacion, setUploadFileDJ, getInitialData }) => {

  const ID_INPUT_FILE = "AgregarDJ"
  const sectionName = "AgregarDJ"

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [descargarArchivoGenerado] = useDescargarArchivoYEliminarArchivoDeS3();

  const [pasoTresDatos, setPasoTresDatos] = useState({});

  const getDatosTercerPaso = async () => {
    const response = await executeGetRequest(getDatosTercerPasoAPI(idCursoCapacitacion));
    if (responseCode200(response)) {
      setPasoTresDatos(response?.data);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  useEffect(() => {
    getInitialData();
    getDatosTercerPaso();
  }, []);


  const downloadFormatoDJ = async () => {
    const response = await executePostRequest(getDescargaFormatoDJAPI(), {TemplateCode: pasoTresDatos?.TemplateCode});
    if (responseCode200(response)) {
      var file = response?.data;
      descargarArchivoGenerado(file.OriginalFileName, file.Path);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  return <div className="mt-3">
    <h6>Para completar tu registro satisfactoriamente, solo necesitas cargar la Declaración Jurada firmada de puño letra o digitalizada en formato PDF.</h6>
    <div className="mt-3">
      <ol>
        <li className="mb-2">
          Descargar el documento aquí
          <button
            type='button'
            className='generic-button-buscador ml-2'
            onClick={downloadFormatoDJ}>
            Descargar DJ
          </button>
        </li>
        <li>
          Firma, escanea y carga el documento aquí

          <div className="black-color">
            <GenericUploadFileV2 uploadFileToS3={setUploadFileDJ} id={ID_INPUT_FILE} textButton={"Subir archivo"} />
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-File-validation-message`}></p>
          </div>
        </li>
      </ol>
      <div className="alert alert-primary" role="alert">
        {pasoTresDatos?.Message} <a target="_blank" href={pasoTresDatos?.DriveLink} className="alert-link">link</a>.
      </div>
    </div>
  </div>;
};

export default ConstanciaCurso;
