import React, {useState} from 'react';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { eliminarContratoAPI } from '../../../consumers/backendApisUrls';
import GenericModal from '../../../components/modal/GenericModal';

const EliminarContrato = ({ toggleModal, idEmpleadoContrato, archivoTitle, manageSearch }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [managePost] = useManagePostRequest();

  const eliminarArchivo = async() => {
    setIsDeleting(true);
    try {
        await managePost(eliminarContratoAPI(idEmpleadoContrato), {}, successEliminarArchivoCallback)
    } catch (error) {
        console.error(error);
    }
    setIsDeleting(false);
  }

  const successEliminarArchivoCallback = async () => {
    await manageSearch();
    toggleModal();
  }

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo="Eliminar Archivo">
        <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    Se va a eliminar el siguiente ítem:
                    <ul>
                        <li><strong>{archivoTitle}</strong></li>
                    </ul>
                    ¿Está seguro de esta acción?
                </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
            <div className="button">
                <button className="btn btn-primary generic-button-ficha mr-2" onClick={() => {toggleModal()}}>
                    Cancelar
                </button>
                <button className="btn btn-primary generic-button-ficha" onClick={() => {eliminarArchivo()}} disabled={isDeleting}>
                    Eliminar
                </button>
            </div>
        </div>
    </GenericModal>
  )
}

export default EliminarContrato