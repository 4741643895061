import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VerDetalleModal from './modales/VerDetalleModal'
import ActionGenericModal from '../../../components/modal/ActionGenericModal'
import { aprobarSolicitudDescuentoPensionAPI, inactivarActivarSolicitudDescuentoPensionAPI, rechazarSolicitudDescuentoPensionAPI } from '../../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled }) => {

  const handleActionRow = ({ fila, api, title, actionLabel }) => {
    const payload = {IdSolicitudDescuentoPension: fila.IdSolicitudDescuentoPension};
    const data = {api, title, actionLabel, payload, name: `${fila.NombreColaborador} - ${fila.Cargo}`};
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data });
  }

  return (
    <tbody className='listar-tbody'>
      {filas.map((fila, i) => (
        <tr key={`${fila.NumeroDocumentoColaborador}-${i}`}>
          <td className='text-center'>{fila.NumeroDocumentoColaborador}</td>
          <td className=''>{fila.NombreColaborador}</td>
          <td className='small'>{fila.Cargo}</td>
          <td className='small'>{fila.Sede}</td>
          <td className='text-center'>{fila.FechaSolicitud}</td>
          <td className='text-center'>{fila.EstadoEmpleado}</td>
          <td className='text-center'>{fila.EstadoSolicitud}</td>
          <td className=''>
            <div className='d-flex justify-content-center' style={{gap: "10px"}}>
              <FontAwesomeIcon
                icon="table"
                className='blue-standard-color'
                size='lg'
                title='ver detalle'
                onClick={() => setModalEnabled({ isEnable: true, component: VerDetalleModal, data:{ IdSolicitudDescuentoPension: fila.IdSolicitudDescuentoPension }})}
              />
              {fila.PuedeAprobar &&
                <FontAwesomeIcon
                  icon={faCheck}
                  className='blue-standard-color'
                  size='lg'
                  title='Aprobar'
                  onClick={() => handleActionRow({ fila, api: aprobarSolicitudDescuentoPensionAPI, title: "Aprobar", actionLabel: "aprobar" })}
                />
              }
              {fila.PuedeRechazar   &&
                <FontAwesomeIcon
                  icon="times"
                  className='blue-standard-color'
                  size='lg'
                  title='Rechazar'
                  onClick={() => handleActionRow({ fila, api: rechazarSolicitudDescuentoPensionAPI, title: "Rechazar", actionLabel: "rechazar" })}
                />
              }
              <FontAwesomeIcon
                icon={fila.PuedeActivar ? "circle-notch" : "ban"}
                className='blue-standard-color'
                size='lg'
                title={`${fila.PuedeActivar ? "Activar" : "Inactivar"}`}
                onClick={() => handleActionRow({ fila, api: inactivarActivarSolicitudDescuentoPensionAPI, title: `${fila.PuedeActivar ? "Activar" : "Inactivar"}`, actionLabel: `${fila.PuedeActivar ? "activar" : "inactivar"}` })}
              />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody