import React from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { eliminarPoliticaAPI } from '../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';

const MensajeAlerta = ({ showModal, refreshPage, politicaId, setPasoMensajeAlerta }) => {
  const [managePost] = useManagePostRequest();

  return (
    <GenericModal showModal={showModal} width={"620px"} titulo={"Eliminar"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se está generando una política sin documentos/ïconos, ¿Desea continuar?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end" >
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={() => { showModal() }}>
            Cancelar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => { setPasoMensajeAlerta(true); }}>
            Continuar
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default MensajeAlerta;