import { TIME_STAMP, dayAdded } from "./HardCodedConstants";
import { addDays, calculateDaysInBetween, jsonNoEstaVacio, toIsoForInput } from "../../../../../helper/utils";

const getddMMYY = (stringData) => {
  let array = stringData.split("-");
  return `${array[2]} / ${array[1]} / ${array[0]}`;
}

const hasFormValuesChanged = (formValuesRaw, formValues, KEYS_FORM) => {
  let hasChanged = false;
  for (let obj of Object.values(KEYS_FORM)){
    const isEqual = formValues[obj] === formValuesRaw[obj]
    if (isEqual) continue;
    return hasChanged = true
  }
  return hasChanged;
}

const areDatesAndFirmaSet = (formValues, firma) => {
  const { FechaDesde, FechaHasta } = formValues;
  return FechaDesde && FechaHasta && jsonNoEstaVacio(firma)
}

const calculateVacationDays = (formValues, setFormValues, setDiasVacacionesCalculado) => {
  if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaHasta >= formValues.FechaDesde){
    let days = calculateDaysInBetween(formValues.FechaDesde, formValues.FechaHasta);
    let countNewDays = 0;
    for (let day of dayAdded){
      const FechaDesde = `FechaDesde${day}`;
      const FechaHasta = `FechaHasta${day}`;
      if (formValues[FechaDesde] === undefined || formValues[FechaDesde] === null) continue;
      countNewDays += calculateDaysInBetween(formValues[FechaDesde], formValues[FechaHasta]);
    }
    setDiasVacacionesCalculado(days+countNewDays);
  } else if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaDesde > formValues.FechaHasta){
    let day = new Date(formValues.FechaDesde+TIME_STAMP);
    let dayISO = toIsoForInput(day);
    setFormValues({...formValues, FechaHasta: dayISO});
  } else {
    setDiasVacacionesCalculado(0);
  }
}

const calculateMinDaysInputDate = (formValues, setMinDiasInput) => {
  let minDays = {
    minStartDate: addDays(new Date(), 1),
    minEndDate: addDays(new Date(), 1),
  }
  if(formValues.FechaDesde) minDays.minEndDate = new Date(formValues.FechaDesde+TIME_STAMP);
  setMinDiasInput(minDays)
}

const getPeriodoText = (formValues) => {
  let periodo = [`${getddMMYY(formValues.FechaDesde)} - ${getddMMYY(formValues.FechaHasta)}`];
  for (let i of dayAdded){
    if (!formValues[`FechaDesde${i}`]) continue;
    periodo.push(`${getddMMYY(formValues[`FechaDesde${i}`])} - ${getddMMYY(formValues[`FechaHasta${i}`])}`);
  }
  
  return periodo;
}

export { getddMMYY, hasFormValuesChanged, areDatesAndFirmaSet, calculateVacationDays, calculateMinDaysInputDate, getPeriodoText }