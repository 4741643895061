import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useContext, useEffect, useState } from "react";
import {
  getEstudiosAdicionalesByIdAPI
} from "../../consumers/backendApisUrls";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import AgregarEstudio from "./Modales/AgregarEstudio";
import { AuthContext } from "../../hooks/context/authContext";
import { downloadFile } from "../../consumers/backendConsumer";
import EliminarEstudio from "./Modales/EliminarEstudio";
import EditarEstudio from "./Modales/EditarEstudio";
import { useManageGetRequest } from "../../hooks/useManageGetRequest/useManageGetRequest";
import CustomAlert from "../../components/alerts/CustomAlert";

const EstudiosAdicionales = ({ empleadoId, puedoEditar }) => {
  const [idActualizado, setId] = useState();

  const { state } = useContext(AuthContext);
  const [executeManageGet] = useManageGetRequest();

  const [estudiosAdicionales, setEstudiosAdicionales] = useState([]);
  const [estudioAdicionalId, setEstudioAdicionalId] = useState(null);
  const [estudioAdicionalNombre, setEstudioAdicionalNombre] = useState(null);

  const [hideModalAgregarEstudioAdicional, setHideModalAgregarEstudioAdicional] = useState(false);
  const [hideModalEliminarEstudioAdicional, setHideModalEliminarEstudioAdicional] = useState(false);
  const [hideModalEditarEstudioAdicional, setHideModalEditarEstudioAdicional] = useState(false);

  const defaultAlertMessage =
    "Para validar tus actualizaciones te pediremos adjuntar un archivo PDF o imagen. ¡Tenlo a mano!";

  const updateId = () => {
    if (empleadoId) {
      setId(empleadoId);
    } else {
      setId(state.user.id_empleado);
    }
  };

  useDidMountEffect(() => {
    updateId();
  }, [empleadoId]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      updateId();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    getInitialData();
  }, [idActualizado]);

  const getInitialData = async () => {
    try {
      if (idActualizado) {
        let api = getEstudiosAdicionalesByIdAPI(idActualizado);
        await executeManageGet(api, successGetEstudiosAdicionales);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const successGetEstudiosAdicionales = (response) => {
    setEstudiosAdicionales(response?.data);
  };

  const toggleAgregarEstudioAdicionalModal = () => {
    setHideModalAgregarEstudioAdicional(!hideModalAgregarEstudioAdicional);
  };

  const toggleEliminarEstudioAdicionalModal = () => {
    setHideModalEliminarEstudioAdicional(!hideModalEliminarEstudioAdicional);
  };

  const toggleEditarEstudioAdicionalModal = () => {
    setHideModalEditarEstudioAdicional(!hideModalEditarEstudioAdicional);
  };

  return (
    <>
      {hideModalAgregarEstudioAdicional && (
        <div className="modal-contactanos modal-response-ficha-background">
          <AgregarEstudio
            toggleModal={toggleAgregarEstudioAdicionalModal}
            empleadoId={idActualizado}
            refresh={getInitialData}
            boolHasEstudio={estudiosAdicionales?.DeclaraInformacionDeEstudios}
          />
        </div>
      )}

      {hideModalEliminarEstudioAdicional && (
        <div className="modal-contactanos modal-response-ficha-background">
          <EliminarEstudio
            toggleModal={toggleEliminarEstudioAdicionalModal}
            estudioAdicionalId={estudioAdicionalId}
            estudioAdicionalNombre={estudioAdicionalNombre}
            refresh={getInitialData}
          />
        </div>
      )}

      {hideModalEditarEstudioAdicional && (
        <div className="modal-contactanos modal-response-ficha-background">
          <EditarEstudio
            toggleModal={toggleEditarEstudioAdicionalModal}
            estudioAdicionalId={estudioAdicionalId}
            refresh={getInitialData}
            puedoEditar={puedoEditar}
          />
        </div>
      )}

      <CustomAlert variant={"warning"}>
        <p className="ficha-secciones-title-text font-gothan-light">
          {defaultAlertMessage}&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon
            icon="info-circle"
            transform="left-9"
            className="hand-pointer"
          />
        </p>
        {!estudiosAdicionales?.estudios?.some(x => x) &&
          <p className="ficha-secciones-title-text font-gothan-light">Haz clic en agregar para comenzar a completar tu registro</p>
        }
      </CustomAlert>


      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-primary generic-button-buscador mb-0 mr-3"
          onClick={toggleAgregarEstudioAdicionalModal}
          disabled={!puedoEditar}
        >
          Agregar
        </button>
      </div>


      <div className="card-body ml-3 mb-3 pt-0">
        <div className="d-flex justify-content-between">
          <table className="table table-borderless">
            {estudiosAdicionales?.DeclaraInformacionDeEstudios == false
            ? <p>No cuento con estudios adicionales.</p> 
            : estudiosAdicionales?.estudios?.map((estudio, i) => (
              <tr key={i} className="row">
                <td className="ml-4 col-6">
                  <div className="blue-standard-color">
                    <p className="mb-0">{estudio?.NombreEstudio}</p>
                  </div>
                  <div className="nombre-institucion">
                    <p className="mb-0">{estudio?.NombreInstitucion},{" "} <span className="pais">{estudio?.PaisInstitucion}</span></p>
                  </div>
                  <div className="estado"><p className="mb-0">{estudio?.Estado}</p></div>
                  <div className="fecha">
                    <p className="mb-0">{estudio?.FechaInicio} - {estudio?.FechaFinalizacion}</p>
                  </div>
                </td>
                <td className="mx-3 col-lg-1 col-xl-2"></td>
                <td>
                  <div className="mt-5">
                    {estudio.File && (
                      <div className="mt-5">
                        <FontAwesomeIcon
                          icon="paperclip"
                          size="1x"
                          title="Anexo"
                          className="hand-pointer blue-standard-color mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadFile(
                              state.token,
                              estudio.File.FilePath,
                              estudio.File.OriginalFileName
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                </td>
                {!puedoEditar &&
                  <td>
                    <div className="mt-5">
                      <FontAwesomeIcon
                        icon="eye"
                        size="1x"
                        title="Detalle"
                        className={"hand-pointer blue-standard-color  mr-3"}
                        onClick={() => {
                          if (puedoEditar === false) {
                            setEstudioAdicionalId(estudio.IdEstudioAdicional);
                            toggleEditarEstudioAdicionalModal();
                          }
                        }}
                      />
                    </div>
                  </td>}

                {puedoEditar &&

                  <td>
                    <div className="mt-5">
                      <FontAwesomeIcon
                        icon="edit"
                        size="1x"
                        title="Editar"
                        className={puedoEditar === true ? "hand-pointer blue-standard-color  mr-3" : "hand-pointer blue-standard-disabled-color  mr-3"}
                        onClick={() => {
                          if (puedoEditar === true) {
                            setEstudioAdicionalId(estudio.IdEstudioAdicional);
                            toggleEditarEstudioAdicionalModal();
                          }
                        }}
                      />
                    </div>
                  </td>

                }

                <td>
                  <div className="mt-5">
                    <FontAwesomeIcon
                      icon="trash"
                      size="1x"
                      title="Eliminar"
                      className={puedoEditar === true ? "hand-pointer blue-standard-color  mr-3" : "hand-pointer blue-standard-disabled-color  mr-3"}
                      onClick={() => {
                        if (puedoEditar === true) {
                          setEstudioAdicionalId(estudio.IdEstudioAdicional);
                          setEstudioAdicionalNombre(estudio.NombreEstudio);
                          toggleEliminarEstudioAdicionalModal();
                        }
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};

export default EstudiosAdicionales;
