import React from 'react'
import PropTypes from "prop-types"
import InputText from '../../../../components/modalComponents/InputText'
import InputDate from '../../../../components/modalComponents/InputDate'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile'
import { MATERNIDAD_MOTIVO_TERMINO } from '../../../../constants/hardCodedConstants'
import { getTodayDate, toIsoForInput } from '../../../../helper/utils'

const TYPES = [MATERNIDAD_MOTIVO_TERMINO.NACIMIENTO_BEBE, MATERNIDAD_MOTIVO_TERMINO.PERDIDA];

const TerminoGestacion = ({ formValues, setFormValues, setIsUploading, commonProps, keys: KEYS, sectionName: SECTION_NAME }) => {
  return (
    <>
      {formValues[KEYS.idMotivo] === MATERNIDAD_MOTIVO_TERMINO.NACIMIENTO_BEBE &&
        <>
          <InputText label='Nombre BB' {...commonProps(KEYS.nombre)} isDisabled={formValues.isDisabled}/>
          <InputDate label="Fec. Nacimiento" {...commonProps(KEYS.nacimiento)} max={toIsoForInput(getTodayDate())} isDisabled={formValues.isDisabled}/>
          <span className='required font-size-search-label-text'>* Adjuntar el documento de Acta de Nacimiento o copia de DNI, este registro solo se toma para fines de registro de lactancia, no significa el pago de asignación familiar</span>
        </>
      }
      {TYPES.includes(formValues[KEYS.idMotivo]) &&
        <InputUploadFile label="Sustento" name={KEYS.file} uploadFile={formValues[KEYS.file]} setUploadFile={(res) => setFormValues({ ...formValues, [KEYS.file]: res })} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={["pdf", "jpg", "png"]} isDisabled={formValues.isDisabled}/>
      }
      {formValues[KEYS.idMotivo] === MATERNIDAD_MOTIVO_TERMINO.PERDIDA &&
        <>
          <InputTextArea label='Comentarios' {...commonProps(KEYS.comentario)} direction='column' isDisabled={formValues.isDisabled}/>
        </>
      }
    </>
  )
}

TerminoGestacion.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.any.isRequired,
  setIsUploading: PropTypes.any.isRequired,
  commonProps: PropTypes.func.isRequired,
  keys: PropTypes.object.isRequired,
  sectionName: PropTypes.string, 
}

export default TerminoGestacion