import React, { useEffect, useState } from 'react'
import GenericUploadFileV2 from '../../../../components/GenericUploadFile/GenericUploadFileV2';
import { cerrarCasoCovid, crearCasoCovid, crearSintomatologiaCasoCovid, getDetallesSintomasCovid, guardarSintomatologiaCasoCovid } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';

const Sintomatologia = ({ idRegistro, idEmpleado, sintomas, permisos, modoCrea, successSubmitCallback, reloadModal, informacionGeneral }) => {
  const SECTION_NAME = "Sintomatologia";
  const EXTENSIONES_VALIDAS = ["jpg", "pdf", "jpeg", "png"];

  const [uploadFilePruebaCovid, setUploadFilePruebaCovid] = useState();
  const UPLOAD_FILE_PRUEBA_COVID = "ficha-carga-prueba-covid-file";

  const [uploadFileDescanso, setUploadFileDescanso] = useState();
  const UPLOAD_FILE_DESCANSO = "ficha-carga-descanso-file";

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);

  const [questions, setQuestions] = useState([]);
  const [checkedQuestions, setCheckedQuestions] = useState({});

  const [resultadoPrueba, setResultadoPrueba] = useState();
  const [descansoMedico, setDescansoMedico] = useState();

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [submitForm] = useSubmitForm();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
      setCheckedQuestions({});
      setQuestions([]);
    };
  }, []);

  const parseSintomas = (sintomas) => {
    setQuestions(sintomas);
    const selectedValues = sintomas.reduce((obj, item) => {
      return {
        ...obj,
        [item.IdPregunta]: item.Value
      }
    }, {});

    setCheckedQuestions(selectedValues);
  }

  useEffect(() => {
    if (sintomas) {
      parseSintomas(sintomas?.Sintomas);
      setResultadoPrueba(sintomas?.TieneResultadoPositivo)
      setDescansoMedico(sintomas?.TieneDescansoMedico)
    }
  }, [sintomas])


  const init = async function () {
    setResultadoPrueba(sintomas?.TieneResultadoPositivo)
    setDescansoMedico(sintomas?.TieneDescansoMedico)
  }

  const clickOnCheckbox = async function (e) {
    let checkedValue = e.target.checked;
    let idAcceso = e.target.dataset.id;

    console.debug("checkedValue: ", checkedValue);
    console.debug("idAcceso", idAcceso);

    setCheckedQuestions({
      ...checkedQuestions,
      [idAcceso]: checkedValue
    })
  };

  const armarPayload = () => {
    const items = Object.keys(checkedQuestions).map((key, i) => {
      if (key != undefined) {
        return {
          IdPregunta: key,
          Text: sintomas?.Sintomas[i]?.Text,
          Value: checkedQuestions[key],
          IsOptional: sintomas?.Sintomas[i]?.IsOptional,
        };
      }
    })

    let payload = {
      "Sintomas": items,
      "TieneResultadoPositivo": resultadoPrueba,
      "TieneDescansoMedico": descansoMedico
    }

    if (modoCrea) {
      payload = {
        IdEmpleado: idEmpleado,
        ...payload
      }
    } else {
      payload = {
        IdCasoCovid: idRegistro,
        ...payload
      }
    }
    return payload
  }

  const guardar = async () => {
    let payload = armarPayload();
    let api = null;
    let keys = ["Sintomas"];
    if (modoCrea) {
      api = crearSintomatologiaCasoCovid();
    } else {
      api = guardarSintomatologiaCasoCovid();
    }

    await submitForm(keys, api, payload, SECTION_NAME, () => {
      setCheckedQuestions({});
      setQuestions([]);
      successSubmitCallback();
    });
  }

  const cerrarCaso = async () => {
    let response = await executePostRequest(cerrarCasoCovid(idRegistro), {});
    if (!responseCode200(response)) {
      mostrarMensajeDeErrorConToast(response);
    } else {
      successSubmitCallback();
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-end">
        {permisos?.PuedeCerrarCaso && !(informacionGeneral?.Estado === "Cerrado")? (
          <div>
            <button className="generic-button-buscador" onClick={cerrarCaso}>
              Cerrar caso
            </button>
          </div>
        ) : null}
        {informacionGeneral?.Estado === "Cerrado" ? null : (
          <div>
            <button
              className="generic-button-buscador"
              onClick={guardar}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
      <hr />
      <div className="row">
        <div className="col-5">
          <div><strong>Sospechoso</strong></div>
          <div className="mt-3">
            {questions.map(question => (
              <div key={question.IdPregunta} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={question.IdPregunta}
                  data-id={question.IdPregunta}
                  name={question.IdPregunta}
                  defaultChecked={checkedQuestions[question.IdPregunta]}
                  onChange={clickOnCheckbox}
                  disabled={!permisos?.PuedeEditarSintomas}
                />
                <label className="form-check-label" htmlFor={question.IdPregunta}>
                  {question.Text}
                </label>
              </div>
            ))}
          </div>
          <div>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Sintomas-validation-message`}></p>
          </div>
        </div>
        <div className="col-7">
          <div><strong>Confirmado</strong></div>
          <div className="mt-3">
            <div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <label className="form-check-label" htmlFor="resultadoPruebaCovid">
                  Tiene resultado positivo de prueba Covid
                </label>
                <div>
                  <input
                    type="checkbox"
                    id="resultadoPruebaCovid"
                    onChange={(e) => setResultadoPrueba(e.target.checked)}
                    defaultChecked={resultadoPrueba}
                    disabled={!permisos?.PuedeEditarSintomas}
                  />
                </div>
              </div>
              {/* <div className="mt-1">
                <GenericUploadFileV2 uploadFileToS3={setUploadFilePruebaCovid} id={UPLOAD_FILE_PRUEBA_COVID} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS} />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-File-Prueba-validation-message`}></p>
              </div> */}
            </div>

            <div>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <label className="form-check-label" htmlFor="descansoMedico">
                  Cuenta con descanso médico
                </label>
                <div>
                  <input
                    type="checkbox"
                    id="descansoMedico"
                    onChange={(e) => setDescansoMedico(e.target.checked)}
                    defaultChecked={descansoMedico}
                    disabled={!permisos?.PuedeEditarSintomas}
                  />
                </div>
              </div>
              {/* <div className="mt-1">
                <GenericUploadFileV2 uploadFileToS3={setUploadFileDescanso} id={UPLOAD_FILE_DESCANSO} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS} />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-File-Descanso-validation-message`}></p>
              </div> */}
              {/* <div>
                <div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Fecha de inicio:
                    </label>
                    <input type="date" className="form-control col-sm-8" name="FechaInicio" onChange={handleChange} value={formValues?.FechaInicio ?? ""} />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaInicio-validation-message`}></p>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label">
                      Fecha fin:
                    </label>
                    <input type="date" className="form-control col-sm-8" name="FechaFin" onChange={handleChange} value={formValues?.FechaFin ?? ""} />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaFin-validation-message`}></p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sintomatologia