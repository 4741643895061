import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary} from "../../../helper/utils";
import SelectInput from "../../../components/Select/SelectInput";
import {resetUploadFile, resetUploadFileV2} from "../../../helper/fileManager";
import {toast} from "react-toastify";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getConfirmarEstadoRecepcionEquiposAPI, getEstadoRecepcionAPI, guardarConfirmarEstadoRecepcionEquiposAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {AlertErrorMaxFileSizeCeses} from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";

const ConfirmarEstadoRecepcionEquiposModal = ({showModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "ConfirmarEstadoRecepcionEquiposModal";
    const [actividadRenuncia, setActividadRenuncia] = useState({});

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [estadosRecepcionOptions, setEstadosRecepcionOptions] = useState([]);
    const [jsonSelectedEstadoRecepcionValue, setJsonSelectedEstadoRecepcionValue] = useState({});

    const [subiendoArchivoEstadoRecepcion, setSubiendoArchivoEstadoRecepcion] = useState(false);
    const UPDATE_SUSTENTOEstadoRecepcion_INPUT_FILE_ID = sectionName + "-sustento-file-recepcion";
    const [s3UploadFileResponseEstadoRecepcion, setS3UploadFileResponseEstadoRecepcion] = useState({});

    const [subiendoArchivoRetencion, setSubiendoArchivoRetencion] = useState(false);
    const UPDATE_SUSTENTORetencion_INPUT_FILE_ID = sectionName + "-sustento-file-retencion";
    const [s3UploadFileResponseRetencion, setS3UploadFileResponseRetencion] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (actividadRenuncia?.IdConformeOption === jsonSelectedEstadoRecepcionValue["value"]) {
            resetRetencionStateValues();
        }
    }, [jsonSelectedEstadoRecepcionValue]);

    const resetRetencionStateValues = function () {
        console.debug("resetRetencionStateValues");
        if (formValues.MontoRetencion !== "") {
            setFormValues({...formValues, "MontoRetencion": ""});
        }
        resetUploadFileV2(setS3UploadFileResponseRetencion, UPDATE_SUSTENTORetencion_INPUT_FILE_ID);
    };

    async function getEstadosRecepcionn() {
        const response = await executeGetRequest(getEstadoRecepcionAPI());
        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
            setEstadosRecepcionOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    async function getConfirmarEstadoRecepcionEquiposs() {
        const response = await executeGetRequest(getConfirmarEstadoRecepcionEquiposAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setActividadRenuncia(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        await getConfirmarEstadoRecepcionEquiposs();
        await getEstadosRecepcionn();
        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        let iniForm = {
            "Monto": "",
            "Observaciones": ""
        };
        return iniForm;
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        console.debug("formValues: ", formValues);

        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "IdEstadoRecepcion": (jsonSelectedEstadoRecepcionValue["value"]) ? jsonSelectedEstadoRecepcionValue["value"] : null,
            "SustentoRecepcion": s3UploadFileResponseEstadoRecepcion,
            "MontoRetencion": formValues.MontoRetencion,
            "SustentoRetencion": s3UploadFileResponseRetencion,
            "Observaciones": formValues.Observaciones,
        };

        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarConfirmarEstadoRecepcionEquiposAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdEstadoRecepcion", "SustentoRecepcion", "MontoRetencion", "SustentoRetencion", "Observaciones"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo="Confirmar Estado de Recepción de Equipos">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label ">Colaborador:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.NombreEmpleado}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.FechaUltimoDia}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.Sede}</label>
                            </div>
                            <hr className="hr-bottom"/>

                            <h6 className="blue-standard-color"><strong>Recepción</strong></h6>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Indicar estado de recepción<span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <SelectInput options={estadosRecepcionOptions} jsonSelectedValue={jsonSelectedEstadoRecepcionValue} setJsonSelectedValue={setJsonSelectedEstadoRecepcionValue} isDisabled={false} placeholder={"Seleccionar una opción"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEstadoRecepcion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Cargar Sustento<span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center ">
                                    <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponseEstadoRecepcion} id={UPDATE_SUSTENTOEstadoRecepcion_INPUT_FILE_ID} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                         subiendoArchivoPublicState={subiendoArchivoEstadoRecepcion} setSubiendoArchivoPublicState={setSubiendoArchivoEstadoRecepcion}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-SustentoRecepcion-validation-message`}></p>
                                </div>
                            </div>
                            {actividadRenuncia && actividadRenuncia?.IdConformeOption !== jsonSelectedEstadoRecepcionValue["value"] &&
                            <>
                                <hr className="hr-bottom"/>
                                < h6 className="blue-standard-color"><strong>Retención</strong></h6>
                                <div className="row custom-form-group-modal">
                                    <label className="col-sm-4 col-form-label modal-label">Indicar Monto a retener<span className="required">*</span></label>
                                    <div className="col-sm-3 flex-row justify-content-center">
                                        <input type="number" className="form-control" name="MontoRetencion" onChange={handleChange} defaultValue={formValues.Monto}/>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-MontoRetencion-validation-message`}></p>
                                    </div>
                                </div>
                                <div className="row custom-form-group-modal">
                                    <label className="col-sm-4 col-form-label modal-label">Cargar Sustento<span className="required">*</span>
                                        <span data-tip data-for="informacion-residencia-sustento-already-saved" style={{display: 'inline'}}>
                                                <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                                            </span>
                                        <ReactTooltip id="informacion-residencia-sustento-already-saved" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                                            Adjuntar la cotización de lo faltante.
                                        </ReactTooltip>
                                    </label>
                                    <div className="col-sm-8 flex-row justify-content-center">
                                        <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponseRetencion} id={UPDATE_SUSTENTORetencion_INPUT_FILE_ID} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                             subiendoArchivoPublicState={subiendoArchivoRetencion} setSubiendoArchivoPublicState={setSubiendoArchivoRetencion}/>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-SustentoRetencion-validation-message`}></p>
                                    </div>
                                </div>
                            </>
                            }
                            <hr/>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Observaciones<span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center ">
                                    <textarea className="form-control" rows="4" name="Observaciones" onChange={handleChange} defaultValue={formValues.Observaciones} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observaciones-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="red-below-modal-message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                            <p>* El formato permitido es un archivo .pdf de máximo 8MB.</p>
                        </strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit} disabled={subiendoArchivoEstadoRecepcion || subiendoArchivoRetencion}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ConfirmarEstadoRecepcionEquiposModal;

