import React from "react";
import { onKeyPressEnter } from "../../helper/utils";
import styled from "styled-components";

const SearchBar = ({
  value,
  onChange,
  cleanSearch,
  manageSearch,
  children,
  placeholder = "Buscar por nombre",
}) => {
  const handleOnChange = (res) => {
    let data = res.target?.value;
    onChange(data);
  };

  return (
    <Wrapper>
      <input
        type="text"
        className="generic-input-criterio-busqueda"
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onKeyUp={(e) => onKeyPressEnter(e, manageSearch)}
      />
      <div className="search-and-clean-buttons">
        <button
          type="button"
          className="generic-button-buscador"
          onClick={manageSearch}
        >
          Buscar
        </button>
        <a className="clean-filters" onClick={cleanSearch}>
          Limpiar búsqueda
        </a>
      </div>
      {children}
    </Wrapper>
  );
};

export default SearchBar;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .search-and-clean-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: 0px 5px;
    }
  }

  @media (max-width: 767px){
    flex-direction: column;

    .search-and-clean-buttons{
      
    }
  }
`;
