import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Dropdown, Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import Paginator from '../directorio/Paginator';
import TableWebBody from './TableWebBody';
import FormSelect from "../../components/atoms/Select";
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import {estados} from "../../constants/states";
import {GENERIC_SUCCESS_TOAST_MESSAGE, HEAD_TABLE_LIQUIDACIONES, ID_TYPES_LIQUIDACIONES} from "../../constants/hardCodedConstants";
import {onKeyPressEnter, esArrayVacio, initialAllOptionPromises, updateFiltersWithExtraData, jsonNoEstaVacio} from '../../helper/utils';
import { aprobarCeseAPI, descargarReporteLiquidacionesAPI, ejecutarDistribucionAPI, eliminarColaboradorAPI, getAllCargosAPI, getAllEstadosAPI, getAllProcesoCierresAPI, getAllSedesAPI, getAllTiposContratoAPI, liquidacionesCargarColaboradoresAPI, liquidacionesGetAllAPI, reprocesarLiquidacionAPI, validarLiquidacionesAPI} from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../consumers/httpRequiestsUtils';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../validations/FormValidatorUtils';

const Liquidaciones = () => {
  const sectionName = "Liquidaciones";
  const history = useHistory();
  const initialStates = {ids: [estados.Error.id, estados.Pendiente.id], nombres: [estados.Pendiente.nombre, estados.Validado.nombre]};

  const [modalEnabled, setModalEnabled] = useState({isEnabled: false});

  const [searchCriteria, setSearchCriteria] = useState("");
  const [allOptions, setAllOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [dataSelected, setDataSelected] = useState({ [HEAD_TABLE_LIQUIDACIONES.Estados.id]: initialStates.ids });

  const [filas, setFilas] = useState([]);
  const [extraData, setExtraData] = useState({})
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [idsRowsConCheck, setIdsRowsConCheck] = useState([]);
  const [multipleSelect, setMultipleSelect] = useState(false);
  const [enableMultiSelectInput, setEnableMultiselectInput] = useState(false);

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [managePostRequest] = useManagePostRequest();
  const [downloadDeleteFile] = useGenerarDescargarEliminarArchivo();

  const getAllOptionsPromises = () => {
    return [
      executeGetRequest(getAllSedesAPI()),
      executeGetRequest(getAllCargosAPI()),
      executeGetRequest(getAllProcesoCierresAPI()),
      executeGetRequest(getAllEstadosAPI()),
      executeGetRequest(getAllTiposContratoAPI()),
    ]
  }

  const getInitialData = async () => {
    try {
      await initialAllOptionPromises(getAllOptionsPromises, Object.values(ID_TYPES_LIQUIDACIONES), setAllOptions);
      await manageSearch();
    } catch (e) {
      console.error(e);
    }
  };

  const manageSearch = async () => {
    const api = liquidacionesGetAllAPI();
    let payload = getPayload();
    await managePostRequest(api, payload, successManageSearchCallback)
    setLoading(false);
  }

  const getPayload = function () {
    return {
      ...dataSelected,
      "Page": page,
      "Criteria": searchCriteria,
    };
  }

  const successManageSearchCallback = async (response) => {
    setFilas(response.data.Items);
    setTotalItems(response.data.TotalItems);
    setFirsItemPage(response.data.FirsItemPage);
    setLastItemPage(response.data.LastItemPage);
    setTotalPages(response.data.TotalPages);
    setExtraData(response.data.ExtraData);
    let uniqueName = [...new Set(response.data.Items.map(o => o.Estado))];
    setEnableMultiselectInput(!(uniqueName.length > 1) && initialStates.nombres.includes(uniqueName.at(0)));
  };

  const handleEjecutarValidar = async () => {
    if (!esArrayVacio(idsRowsConCheck)) {
      let payload = {IdsEmpleadoLiquidacion: idsRowsConCheck};
      await managePostRequest(validarLiquidacionesAPI(), payload, successValidarCallback);
    }
  }

  const successValidarCallback = () => {
    resetRowsConCheck();
    manageSearch();
  }

  const handleEjecutarDistribucion = async () => {
    if (!esArrayVacio(idsRowsConCheck)) {
      let payload = {...getPayload(), IdsEmpleadoLiquidacion: idsRowsConCheck};
      const { data } = await executePostRequest(ejecutarDistribucionAPI(), payload);
      data?.Message ? toast.success(data.Message) : toast.error("Hubo un error al ejecutar la distribución");
    }
  }

  const downloadReport = async () => {
    await downloadDeleteFile(descargarReporteLiquidacionesAPI(), getPayload());
  }

  const eliminar = async function (IdEmpleadoLiquidaciones) {
    await managePostRequest(eliminarColaboradorAPI(), { IdEmpleadoLiquidaciones }, (res) => manageSearch())
  }

  const reprocesar = async function (IdConfiguracionLiquidaciones) {
    let response = await executePostRequest(reprocesarLiquidacionAPI(), { IdConfiguracionLiquidaciones })
    
    if (responseCode200(response))
    {    }
    else if (responseTieneModelState(response))
    {
      const message = response?.response?.data?.ModelState.IdTipoContratoActual[0] ?? "ocurrió un problema";
      toast.error(message);
    }
    else
    {
       mostrarMensajeDeErrorConToast(response)
    }

  }

  const cargarColaboradores = async function () {
    await managePostRequest(liquidacionesCargarColaboradoresAPI(), {}, (res) => manageSearch())
  }

  const selectUnselectAllCheckboxes = function (e) {
    if(e.target.checked) {
      let filasIds = filas.map(fila => fila.IdEmpleadoLiquidaciones);
      setMultipleSelect(true);
      setIdsRowsConCheck(filasIds)
    } else {
      setMultipleSelect(false);
      setIdsRowsConCheck([]);
    }
  }

  const resetRowsConCheck = () => {
    setIdsRowsConCheck([])
    setMultipleSelect(false);
  }

  const limpiarBusqueda = () => {
    setSearchCriteria("");
    setDataSelected({});
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  useDidMountEffect(() => {
    setPage(1);
    resetRowsConCheck();
    manageSearch();
  }, [dataSelected]);

  useDidMountEffect(() => {
    manageSearch();
  }, [page]);

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allOptions, extraData, setFilterOptions);
  }, [extraData])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  return (<>
    <div className="message-no-disponible-en-mobile">
      <p>Esta página no está disponible en mobile.</p>
    </div>
    <div className="no-disponible-en-mobile">
      {modalEnabled.isEnabled && 
        <div className="modal-contactanos modal-response-ficha-background">
          <modalEnabled.component toggleModal={() => setModalEnabled({isEnabled: false})} idEmpleadoLiquidaciones={modalEnabled.idEmpleadoLiquidaciones} refreshTable={manageSearch}/>
        </div>
      }
      <Wrapper className='App'>
        <PageHeader title={"Gestión de liquidaciones"} />
        {loading &&
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
        }

        {!loading && (<>
        <div className="generic-buscador-container">
          <div className="filtros-left-section">
            <input
              type='text'
              className='generic-input-criterio-busqueda'
              placeholder='Buscar por nombre'
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
              onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}
            />
            <button
              onClick={manageSearch}
              type='button'
              className='generic-button-buscador'>
              Buscar
            </button>
            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
          </div>
          <div className="filtros-right-section">
            <button
              onClick={(e) => cargarColaboradores()}
              type='button'
              className=' disabled  btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'
              disabled = {true}>
              Cargar Colaboradores
            </button>
            {extraData?.PuedeDescargarReporte &&
              <button
                onClick={(e) => downloadReport()}
                type='button'
                className='generic-button-buscador'>
                Descargar
              </button>
            }
            <span className="align-items-center p-2 ml-2" style={{ backgroundColor: 'gray', borderRadius: '50%' }} onClick={() => history.push('/liquidaciones/configurarProceso')}>
              <FontAwesomeIcon icon='cog' size="lg" className="hand-pointer white" style={{ color: 'white' }} title="Configurar proceso" />
            </span>
          </div>
        </div>
          <div className="generic-buscador-container">
            <div className="filtros-left-section">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                  Acciones
                </Dropdown.Toggle>
                {enableMultiSelectInput && 
                  <>
                    {dataSelected[HEAD_TABLE_LIQUIDACIONES.Estados.id]?.at(0) == estados.Pendiente.id &&
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleEjecutarValidar()}>Validar</Dropdown.Item>
                      </Dropdown.Menu>}
                    {dataSelected[HEAD_TABLE_LIQUIDACIONES.Estados.id]?.at(0) == estados.Validado.id &&
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleEjecutarDistribucion()}>Distribuir</Dropdown.Item>
                      </Dropdown.Menu>}
                  </>
                }
              </Dropdown>
            </div>
          </div>
        <div  className='pt-1'>
          <div className="table-scroll">
            <table className='table table-hover listar-table-cierre-anio'>
              <TableHeadWithFilters allTitlesArray={Object.values(HEAD_TABLE_LIQUIDACIONES)} filterOptionsArray={filterOptions} dataSelected={dataSelected} setDataSelected={setDataSelected} enableMultiSelectInput={enableMultiSelectInput} multipleSelect={multipleSelect} selectUnselectAllCheckboxes={selectUnselectAllCheckboxes}/>
              <TableWebBody filas={filas} eliminar={eliminar} reprocesar={reprocesar} setModalEnabled={setModalEnabled} setIdsRowsConCheck={setIdsRowsConCheck} idsRowsConCheck={idsRowsConCheck} enableMultiSelectInput={enableMultiSelectInput}/>
            </table>
          </div>
            {totalPages > 0 && (
              <Paginator totalItems={totalItems}
                firsItemPage={firsItemPage}
                lastItemPage={lastItemPage}
                totalPages={totalPages}
                page={page}
                onLeftClic={onLeftClic}
                onRightClic={onRightClic} />
            )}
        </div>
        </>)}
      </Wrapper>
    </div>
  </>)

}

export default Liquidaciones;

const TableHeadWithFilters = ({allTitlesArray, filterOptionsArray, dataSelected, setDataSelected, enableMultiSelectInput, multipleSelect, selectUnselectAllCheckboxes}) => {

  const renderHeader = () => {
    return allTitlesArray?.map((o, index) => {
      let render = filterOptionsArray.find(filter => filter.key == o.id);
      return (
        <th key={index} className="col-1 pl-1 pr-1">
        {render
        ? <div className='d-flex'>
            {o.id == HEAD_TABLE_LIQUIDACIONES.Estados.id && 
              <div>
                <p data-tip data-for="informacion-seleccionar-estado-liquidacion" className='mb-0'>
                  <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                </p>
                <ReactTooltip id="informacion-seleccionar-estado-liquidacion" place="top" effect="solid" className="tooltip-style">
                  Selecciona un único filtro para habilitar una acción
                </ReactTooltip>
              </div>
            }
            <FormSelect
              options={render.value}
              description={o.title}
              setSelectedOptions={(res) => handleSelectedOptions(render?.key, res)}
              selectedOptions={dataSelected[render.key] ?? []} />
          </div>
        : <div className='flex font-gothan-medium'>
            {(o.id == HEAD_TABLE_LIQUIDACIONES.CodigoTrabajo.id && enableMultiSelectInput) && <input type="checkbox" checked={multipleSelect} onChange={(e) => selectUnselectAllCheckboxes(e)}></input>}
            {o.title}
          </div>
          }
        </th>
        )
    })
  }

  const handleSelectedOptions = (key, value) => {
    setDataSelected({...dataSelected, [key]: value})
  }

  return (
    <thead className="listar-thead">
      <tr>
        {renderHeader()}  
      </tr>
    </thead>
  )
}