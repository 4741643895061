import React, { useContext, useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { uploadMultipleFiles } from '../../../consumers/backendConsumer';
import { uploadLiquidacionesFiles } from '../../../consumers/backendApisUrls';
import { TIPO_PLANTILLA } from './modales/EditarPlantilla'
import { AuthContext } from "../../../hooks/context/authContext";

const urlApiBackend = process.env.REACT_APP_URL_API_BACKEND;

const TableWebBody = ({ configuraciones, cargar, editar, eliminar, refreshTable }) => {
  const { state } = useContext(AuthContext);
  const [fileUploaded, setFileUploaded] = useState(false);

  const handleUpload = async (event, idConfiguracion = null) => {
    let files = null;
    if (event.target.files && event.target.files[0] && idConfiguracion !== null) {
      files = event.target.files;
      const formData = new FormData();
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        formData.append("files[" + index + "]", element);
      }
      formData.append("idConfiguracionLiquidaciones", idConfiguracion);

      console.debug('>> formData >> ', formData);
      const response = await uploadMultipleFiles(formData, uploadLiquidacionesFiles(), state.token);
      if (response) { setFileUploaded(true); refreshTable(); }
      else { setFileUploaded(false); refreshTable(); }
    }
  }

  return (
    <tbody className="listar-tbody">
      {configuraciones.map((fila, i) => (
        <tr key={fila.IdConfiguracionLiquidaciones}>
          <td className="col-2 pl-1 pr-1">{fila?.TipoPlantilla}</td>
          <td className="col-2 pl-1 pr-1">{fila?.NombrePlantilla}</td>
          <td className="col-4 pl-1 pr-1">{fila?.NombreArchivoGenerado}</td>
          <td className="col-2 pl-5 pr-1 text-center">
            {fila?.TipoPlantilla === TIPO_PLANTILLA.manual ? (
              <div className='custom-file-upload '>
                <label htmlFor={`file-${fila.IdConfiguracionLiquidaciones}`} className='btn btn-primary generic-button-ficha'>Cargar Archivos</label>
                <p>{fila?.ArchivosCargadosCount} archivos cargados</p>
                <input type="file" id={`file-${fila.IdConfiguracionLiquidaciones}`} multiple onChange={(e) => handleUpload(e, fila.IdConfiguracionLiquidaciones)} />
              </div>
            ) : null}
          </td>
          <td className="col-1 pl-1 pr-1">
            <div className="d-flex justify-content-start">
              <div>
                <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer" title="Editar" onClick={() => {
                  editar(fila)
                }} />
              </div>
              <div>
                <FontAwesomeIcon icon='times' size="sm" className="hand-pointer ml-3" title="Eliminar" onClick={() => {
                  eliminar(fila?.IdConfiguracionLiquidaciones)
                }} />
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody
