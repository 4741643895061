import React, { useContext, useEffect, useState } from 'react';

import TableWebBodyCapacitacion from './TableWebBodyCapacitacion';
import FormSelect from '../../components/atoms/Select';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from "../../components/pageHeader/PageHeader";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import {
  descargarListaCapacitacion,
  getAllAreasAPI,
  getAllDepartamentosAPI,
  getAllEstadosAPI,
  getAllSedesAPI,
  getCursosCapacitacionAPI,
  getDivisionesAPI,
  getListaCapacitacion,
} from "../../consumers/backendApisUrls";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import Paginator from "../directorio/Paginator";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";
import { updateOptionsDeFiltro } from "../../helper/Listas/ListasUtils";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import SelectInput from '../../components/Select/SelectInput';
import { isEmpty, jsonNoEstaVacio, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../helper/utils';

const GestionCapacitacion = () => {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [filas, setFilas] = useState([]);
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [PuedeVerInactivos, setPuedeVerInactivos] = useState(false);

  const [descargarArchivoDeS3] = useGenerarDescargarEliminarArchivo();

  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
  const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);
  const [gerenciaSeleccionadas, setGerenciaSeleccionadas] = useState([]);
  const [areaSeleccionados, setAreaSeleccionados] = useState([]);
  const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
  const [estadosRegistroSeleccionados, setEstadosRegistroSeleccionados] = useState([]);
  const [modeladosSeleccionados, setModeladosSeleccionados] = useState([]);

  const [sedesFiltradasOptions, setSedesFiltradasOptions] = useState([]);
  const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);
  const [gerenciaFiltradasOptions, setGerenciaFiltradasOptions] = useState([]);
  const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
  const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
  const [estadosRegistroFiltradosOptions, setEstadosRegistroFiltradosOptions] = useState([]);
  const [modeladoFiltradosOptions, setModeladoFiltradosOptions] = useState([]);

  const [allSedesFiltradasOptions, setAllSedesFiltradasOptions] = useState([]);
  const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);
  const [allGerenciaFiltradasOptions, setAllGerenciaFiltradasOptions] = useState([]);
  const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
  const [allEstadosFiltradosOptions, setAllEstadosFiltradosOptions] = useState([]);
  const [allEstadosRegistroFiltradosOptions, setAllEstadosRegistroFiltradosOptions] = useState([]);
  const [allModeladoFiltradosOptions, setAllModeladoFiltradosOptions] = useState([]);

  const [cleanFilterss, setCleanFilters] = useState(false);
  const [executePostRequest] = usePostRequest();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

  const [flujosOptions, setFlujosOptions] = useState([]);
  const [jsonSelectedFlujoValue, setJsonSelectedFlujoValue] = useState({});

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    setPage(1);
  }, [criterioDeBusqueda, sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, estadosSeleccionados, estadosRegistroSeleccionados, modeladosSeleccionados]);

  useDidMountEffect(() => {
    manageSearch();
  }, [sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, page, fechaMasRecientePrimeroBool, estadosSeleccionados, estadosRegistroSeleccionados, modeladosSeleccionados]);

  const listar = async () => {
    setLoading(true);
    try {

      await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setAllSedesFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllGerenciaFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);

      await manageSearch();

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const getCursosCapacitacion = async function () {
    const response = await executeGetRequest(getCursosCapacitacionAPI());
    if (responseCode200(response)) {
      return response?.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const getInitialData = async () => {
    let cursos = await getCursosCapacitacion();

    if (cursos) {
      let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(cursos);
      setFlujosOptions(options);
    }
  };

  useEffect(() => {
    if (jsonNoEstaVacio(jsonSelectedFlujoValue)) {
      limpiarBusqueda();
      listar();
    }
  }, [jsonSelectedFlujoValue]);

  const updateOptionsDeFiltros = async function (lists) {
    await updateOptionsDeFiltro(allSedesFiltradasOptions, lists["IdsSedes"], setSedesFiltradasOptions, executeGetRequestCallback(getAllSedesAPI()));
    await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
    await updateOptionsDeFiltro(allGerenciaFiltradasOptions, lists["IdsGerencias"], setGerenciaFiltradasOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
    await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
    await updateOptionsDeFiltro(allEstadosFiltradosOptions, lists["IdsCompletoCapacitacion"], setEstadosFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allEstadosRegistroFiltradosOptions, lists["IdsEstadosRegistro"], setEstadosRegistroFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allModeladoFiltradosOptions, lists["IdsModeladosCapacitacion"], setModeladoFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    setLoading(false);
  };

  const getDropdownOptions = async (getFunction, setState) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      setState(response.data);
      return response.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      manageSearch();
    }
  };

  const obtenerPayload = function () {
    let payload = {
      "Criteria": criterioDeBusqueda,
      "IdsSedes": sedesSeleccionadas,
      "IdsDivisiones": divisionSeleccionados,
      "IdsGerencias": gerenciaSeleccionadas,
      "IdsAreas": areaSeleccionados,
      "IdsCompletoCapacitacion": estadosSeleccionados,
      "IdsEstadosRegistro": estadosRegistroSeleccionados,
      "IdsModeladosCapacitacion": modeladosSeleccionados,
      "Page": page,
      "OrdenarRecientePrimero": fechaMasRecientePrimeroBool,
      "IdCursoCapacitacion": jsonSelectedFlujoValue?.value
    };
    return payload;
  }

  const manageSearch = async () => {
    setLoading(true);
    let payload = obtenerPayload();
    await executeSearch(payload);
  };

  const executeSearch = async (payload) => {
    const response = await executePostRequest(getListaCapacitacion(), payload);
    if (responseCode200(response)) {
      setFilas(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setFirsItemPage(response.data.FirsItemPage);
      setLastItemPage(response.data.LastItemPage);
      setTotalPages(response.data.TotalPages);

      setPuedeVerInactivos(response.data.ExtraData.PuedeVerInactivos);
      await updateOptionsDeFiltros(response?.data?.ExtraData);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const limpiarBusqueda = () => {
    setSedesSeleccionadas([]);
    setDivisionSeleccionados([]);
    setGerenciaSeleccionadas([]);
    setAreaSeleccionados([]);
    setEstadosSeleccionados([]);
    setEstadosRegistroSeleccionados([]);
    setModeladosSeleccionados([]);
    setCriterioDeBusqueda("");
    setCleanFilters(!cleanFilterss);
    setFechaMasRecientePrimeroBool(true);
  };

  const descargar = async function () {

    const payload = obtenerPayload();
    await descargarArchivoDeS3(descargarListaCapacitacion(), payload);
  }

  const onChangeFechaMasRecientePrimeroBool = (e) => {
    let inputValue = e.target.checked;
    setFechaMasRecientePrimeroBool(inputValue);
  };

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        <Wrapper className='App'>
          <PageHeader title={"Gestión de capacitación"} />
          <div className="generic-buscador-container">
            <div className="filtros-left-section col-sm-4 black-color p-0 mt-1">
              <SelectInput
                options={flujosOptions}
                jsonSelectedValue={jsonSelectedFlujoValue}
                setJsonSelectedValue={setJsonSelectedFlujoValue}
                isDisabled={false}
                placeholder={"Seleccionar un curso"}
              />
            </div>
          </div>
          {loading === true &&
            <div className="spinner-cargando-container">
              <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
          }
          {loading === false &&
            <>
              <div className="generic-buscador-container">
                <div className="filtros-left-section">
                  <input
                    type='text'
                    className='generic-input-criterio-busqueda'
                    placeholder='Buscar por nombre'
                    value={criterioDeBusqueda}
                    onChange={(e) => {
                      setCriterioDeBusqueda(e.target.value);
                    }}
                    onKeyPress={handleKeypress}
                  />
                  <button
                    onClick={manageSearch}
                    type='button'
                    className='generic-button-buscador'>
                    Buscar
                  </button>
                  <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                  <button
                    onClick={descargar}
                    type='button'
                    className='generic-button-buscador'>
                    Descargar
                  </button>
                </div>
              </div>

              <div className='pt-2'>
                <div className="table-scroll">
                  <table className='table table-hover'>
                    <thead className="listar-thead">
                      <tr>
                        <th>
                          <div className='flex font-gothan-medium'>Nombre Completo</div>
                        </th>
                        <th className="mb-0 pb-0">
                          <div className='flex font-gothan-medium'>Fecha Registro</div>
                          <div className="custom-control custom-switch">
                            <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                            <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                              Más reciente
                            </label>
                          </div>
                        </th>

                        <th>
                          <FormSelect
                            options={sedesFiltradasOptions}
                            description={'Sede'}
                            setSelectedOptions={setSedesSeleccionadas}
                            selectedOptions={sedesSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={divisionFiltradosOptions}
                            description={'División'}
                            setSelectedOptions={setDivisionSeleccionados}
                            selectedOptions={divisionSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={gerenciaFiltradasOptions}
                            description={'Gerencia'}
                            setSelectedOptions={setGerenciaSeleccionadas}
                            selectedOptions={gerenciaSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={areasFiltradasOptions}
                            description={'Área'}
                            setSelectedOptions={setAreaSeleccionados}
                            selectedOptions={areaSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={estadosFiltradosOptions}
                            description={'Completó la capacitación'}
                            setSelectedOptions={setEstadosSeleccionados}
                            selectedOptions={estadosSeleccionados}
                          />
                        </th>
                        {PuedeVerInactivos ? (
                          <th>
                            <FormSelect
                              options={estadosRegistroFiltradosOptions}
                              description={'Estado registro'}
                              setSelectedOptions={setEstadosRegistroSeleccionados}
                              selectedOptions={estadosRegistroSeleccionados}
                            />
                          </th>
                        ) : null}
                        <th>
                          <FormSelect
                            options={modeladoFiltradosOptions}
                            description={'Modelado capacitación'}
                            setSelectedOptions={setModeladosSeleccionados}
                            selectedOptions={modeladosSeleccionados}
                          />
                        </th>
                        <th>
                          <div className='flex font-gothan-medium'>Acciones</div>
                        </th>
                      </tr>
                    </thead>
                    <TableWebBodyCapacitacion PuedeVerInactivos={PuedeVerInactivos} filas={filas} manageSearch={manageSearch} />
                  </table>
                </div>
                  {totalPages > 0 && (
                    <Paginator
                      totalItems={totalItems}
                      firsItemPage={firsItemPage}
                      lastItemPage={lastItemPage}
                      totalPages={totalPages}
                      page={page}
                      onLeftClic={onLeftClic}
                      onRightClic={onRightClic}
                    />
                  )}

              </div>
            </>
          }
        </Wrapper>
      </div>
    </>
  );
};

export default GestionCapacitacion;
