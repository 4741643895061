import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../hooks/context/authContext";
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, stringSeEncuentraEnArray} from "../../helper/utils";
import {downloadFile} from "../../consumers/backendConsumer";
import {ESTADOS_RENUCIA} from "../../constants/hardCodedConstants";
import ReactTooltip from "react-tooltip";

const TableWebBody = ({
    puedeEditar,
    cesesFiltradas, 
    idsFichaCamposCesesConCheck, 
    setIdsFichaCamposCesesConCheck, 
    showRegistrarNotaModal, 
    showRechazarCeseModal, 
    permisosBotones, 
    toggleDetalleRenuncia,
    showEditarCeseModal
}) => {

    const {state} = useContext(AuthContext);

    const checkboxClic = (e, solicitud) => {
        let value = e.target.checked;
        let idFichaCampoSolicitud = solicitud["IdCese"];
        let tempSolicitudes = idsFichaCamposCesesConCheck;
        if (value === true && !stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = agregarStringAArraydDeStringsSiNoExiste(idFichaCampoSolicitud, tempSolicitudes);
            setIdsFichaCamposCesesConCheck(tempSolicitudes);
        } else if (value === false && stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = eliminarStringDeArraydDeStrings(idFichaCampoSolicitud, tempSolicitudes);
            setIdsFichaCamposCesesConCheck(tempSolicitudes);
        }
    };

    return (
        <tbody className="listar-tbody">
        {cesesFiltradas.map((cese, i) => (
            <tr key={i}>
                <td>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" onClick={(e) => checkboxClic(e, cese)}></input>
                        &nbsp;
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={cese.UrlImagenPerfil}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{cese.NombreCompleto}</div>
                    </div>
                </td>  
                <td>{cese?.Cargo}</td>
                <td>{cese?.Sede}</td>
                <td>{cese?.NombreEstado}</td>
                <td>{cese?.FechaRegistro}</td>
                <td>{cese?.FechaCese}</td>
                <td>{cese?.Notificacion}</td>
                <td>
                    <div>
                        {cese?.RecomiendaReingreso}
                        {cese?.MotivoRecomendacion &&
                        <>
                            <p data-tip data-for={"ceses-RecomiendaReingreso" + i} style={{display: 'inline'}}>
                                <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                            </p>
                            <ReactTooltip id={"ceses-RecomiendaReingreso" + i} place="right" effect="solid" className="tooltip-style">
                                {<p>{cese?.MotivoRecomendacion}</p>}
                            </ReactTooltip>
                        </>
                        }
                    </div>
                </td>
                <td>{cese?.TipoCese}</td>
                <td>{cese?.MotivoRenuncia}</td>
                <td>
                    <div className="ceses-listar-columna-opciones">
                        {cese?.Path && cese?.OriginalFileName &&
                        <div>
                            <FontAwesomeIcon icon='paperclip' transform="left-8" className="hand-pointer blue-standard-color" title="Descargar Carta de Renuncia" onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, cese.Path, cese.OriginalFileName);
                            }
                            }/>
                        </div>
                        }
                        

                        <div onClick={() => showRegistrarNotaModal(cese["IdCese"])} style={{display: 'flex'}}>
                            <span className="badge badge-success badge-number-ceses">{cese.CantidadNotas}</span>
                            <FontAwesomeIcon icon='comment' transform="left-8" size="lg" className="hand-pointer  blue-standard-color" title="Registrar una Nota"/>
                        </div>
                      
                        {cese.IdEstado !== ESTADOS_RENUCIA.rechazado.id && cese.IdEstado !== ESTADOS_RENUCIA.aprobado.id && permisosBotones?.PuedeRegistrarAcciones &&
                        <div className="d-flex">
                            <FontAwesomeIcon icon='times' transform="left-4" size="sm" className="hand-pointer" title="Anular Registro de Carta de Renuncia" onClick={() => showRechazarCeseModal(cese["IdCese"], cese["NombreCompleto"])}/>
                        </div>
                        }

                        <div className="d-flex">
                            <FontAwesomeIcon icon='table' transform="" size="sm" className="hand-pointer" title="Ver detalle" onClick={() => toggleDetalleRenuncia(cese?.IdCese)}/>
                        </div>

                        {puedeEditar? (
                            <div className="d-flex ml-1">
                             <FontAwesomeIcon icon='edit' transform="" size="sm" className="hand-pointer" title="Editar registro" onClick={() => showEditarCeseModal(cese?.IdCese, cese?.NombreEstado)}/>
                            </div>
                        ): null}
                    </div>
                </td>


            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBody;
