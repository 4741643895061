import React, {useLayoutEffect, useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, notNullAndNotUndefinded} from "../../../helper/utils";
import {toast} from "react-toastify";
import {TIPOS_ACCESO} from "../../../constants/hardCodedConstants";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import SelectInput from "../../../components/Select/SelectInput";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {getAccesosRolAPI, getTiposAccesoAPI, seguridadAgregarAccesoRolAPI, seguridadEliminarAccesoRolAPI} from "../../../consumers/backendApisUrls";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";

const MantenimientoRolModal = ({showModal, idRol, nombreRol}) => {
    const ID_PERMISO_VER = "1";
    const ID_PERMISO_EDITAR = "2";
    const ID_PERMISO_NONE = "-1";

    const seccionName = "mantenimientoRolModal";
    const [scroll, setScroll] = useState();

    const [tiposAccesoOptions, setTiposAccesoOptions] = useState([]);
    const [jsonSelectedTipoAccesoValue, setJsonSelectedTipoAccesoValue] = useState({});
    const [accesosRol, setAccesosRol] = useState([]);

    const [reloadTableRows, setReloadTableRows] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        reloadTable();
    }, [jsonSelectedTipoAccesoValue]);

    useEffect(() => {
        setAccesosRol([]);
    }, [reloadTableRows]);

    useEffect(() => {
        if (accesosRol && accesosRol.length === 0) {
            updateAccesosRolState();
        }
    }, [accesosRol]);

    useLayoutEffect(() => {
        if (notNullAndNotUndefinded(accesosRol) && accesosRol.length > 12) {
            let scrollToo = (scroll === undefined ? 0 : scroll);
            document.getElementById("tbodyPermisos").scrollTo(0, scrollToo);
        }
    }, [accesosRol]);

    const updateAccesosRolState = async function () {
        try {
            let tipoAccesoValueSelected = jsonSelectedTipoAccesoValue["value"];
            if (tipoAccesoValueSelected) {
                const response = await executeGetRequest(getAccesosRolAPI(idRol, tipoAccesoValueSelected));
                if (responseCode200(response)) {
                    setAccesosRol([...response.data]);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const init = async () => {
        try {
            const response = await executeGetRequest(getTiposAccesoAPI());
            if (responseCode200(response)) {
                let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
                setTiposAccesoOptions(options);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const clickOnCheckbox = async function (e) {
        let checkedValue = e.target.checked;
        let idAcceso = e.target.dataset.id;

        console.debug("checkedValue: ", checkedValue);
        console.debug("idAcceso", idAcceso);

        if (checkedValue === true) {
            await agregarAccesoRol(idAcceso);
        }

        if (checkedValue === false) {
            await eliminarAccesoRol(idAcceso);
        }
    };

    const agregarAccesoRol = async function (idAcceso, idSecurityLevel) {
        console.debug("agregar acceso rol");
        let payload = {
            "IdRol": idRol,
            "IdAcceso": idAcceso
        };

        if (jsonSelectedTipoAccesoValue["value"] === TIPOS_ACCESO.campos.id && idSecurityLevel) {
            console.debug("ha seleccionado tipo de acceso campos por lo que se considera radio button para permiso ver o editar");
            payload = {...payload, "IdSecurityLevel": idSecurityLevel}
        }
        console.debug("payload ", payload);
        await agregarAccesoRolHTTPRequest(payload);
    };

    const eliminarAccesoRol = async function (idAcceso) {
        console.debug("eliminar acceso rol");
        let payload = {
            "IdRol": idRol,
            "IdAcceso": idAcceso
        };
        console.debug("payload ", payload);
        await eliminarAccesoRolHTTPRequest(payload);
    };

    const agregarAccesoRolHTTPRequest = async function (payload) {
        const response = await executePostRequest(seguridadAgregarAccesoRolAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
        reloadTable()
    };

    const eliminarAccesoRolHTTPRequest = async function (payload) {
        const response = await executePostRequest(seguridadEliminarAccesoRolAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
        reloadTable()
    };

    const reloadTable = function () {
        setReloadTableRows(!reloadTableRows);
    };

    const onChangeSecurityLevelLecturaOEscritura = async function (e) {
        let scroll = document.getElementById("tbodyPermisos").scrollTop;
        console.debug("saving this new scroll: ", scroll);
        setScroll(scroll);

        let accion = e.target.value;
        let idAcceso = e.target.dataset.id;
        console.debug("IdAcceso: ", idAcceso);

        if (accion === ID_PERMISO_VER) {
            console.debug("agregar permiso ver");
            await agregarAccesoRol(idAcceso, ID_PERMISO_VER);
        }

        if (accion === ID_PERMISO_EDITAR) {
            console.debug("agregar permiso editar");
            await agregarAccesoRol(idAcceso, ID_PERMISO_EDITAR);
        }

        if (accion === ID_PERMISO_NONE) {
            console.debug("eliminar permiso");
            await eliminarAccesoRol(idAcceso);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"780px"} titulo={"Mantenimiento Rol"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-3 col-form-label modal-label">Nombre de Rol<span className="required"></span></label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" value={nombreRol} disabled></input>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-3 col-form-label modal-label">Privilegios<span className="required"></span></label>
                                <div className="col-sm-9 black-color">
                                    <SelectInput options={tiposAccesoOptions} jsonSelectedValue={jsonSelectedTipoAccesoValue} setJsonSelectedValue={setJsonSelectedTipoAccesoValue} isDisabled={false} placeholder={"Seleccionar una opción"}/>
                                </div>
                            </div>
                            <div className="gestion-roles-table-accessos-div">
                                <table className='table table-hover' id="gestion-roles-table-accessos">
                                    <thead className="listar-thead">
                                    <tr>
                                        <th>Funcionalidades</th>
                                        <th>Acceso</th>
                                    </tr>
                                    </thead>
                                    <tbody id="tbodyPermisos" className="listar-tbody">
                                    <div>
                                        {accesosRol && accesosRol.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item.Nombre}</td>
                                                    <td>
                                                        {jsonSelectedTipoAccesoValue["value"] !== TIPOS_ACCESO.campos.id &&
                                                        <>
                                                            <div className="custom-control custom-switch" style={{zIndex: 0}}>
                                                                <input type="checkbox" className="custom-control-input hand-pointer" id={"ficha-edit-" + seccionName + "-" + item.IdAcceso} data-id={item.IdAcceso} name={item.Nombre} defaultChecked={item.EsActivo} onChange={clickOnCheckbox}></input>
                                                                <label className="custom-control-label" htmlFor={"ficha-edit-" + seccionName + "-" + item.IdAcceso}></label>
                                                            </div>
                                                        </>
                                                        }

                                                        {jsonSelectedTipoAccesoValue["value"] === TIPOS_ACCESO.campos.id &&
                                                        <div className="form-check custom-control" style={{zIndex: 0}}>
                                                            <input className="mantenimiento-rol-modal-radio-button-permiso-input" type="radio" name={"ficha-edit-" + seccionName + "-idsecuritylevel-" + item.IdAcceso} value={ID_PERMISO_VER} data-id={item.IdAcceso} defaultChecked={item.IdSecurityLevel == ID_PERMISO_VER} onChange={onChangeSecurityLevelLecturaOEscritura}></input>
                                                            <label className="mantenimiento-rol-modal-radio-button-permiso-label">Ver</label>
                                                            <input className="mantenimiento-rol-modal-radio-button-permiso-input" type="radio" name={"ficha-edit-" + seccionName + "-idsecuritylevel-" + item.IdAcceso} value={ID_PERMISO_EDITAR} data-id={item.IdAcceso} defaultChecked={item.IdSecurityLevel == ID_PERMISO_EDITAR} onChange={onChangeSecurityLevelLecturaOEscritura}></input>
                                                            <label className="mantenimiento-rol-modal-radio-button-permiso-label">Editar</label>
                                                            <input className="mantenimiento-rol-modal-radio-button-permiso-input" type="radio" name={"ficha-edit-" + seccionName + "-idsecuritylevel-" + item.IdAcceso} value={ID_PERMISO_NONE} data-id={item.IdAcceso} defaultChecked={item.IdSecurityLevel == null} onChange={onChangeSecurityLevelLecturaOEscritura}></input>
                                                            <label className="mantenimiento-rol-modal-radio-button-permiso-label">Sin Permiso</label>
                                                        </div>
                                                        }


                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </div>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light">
                            <strong>
                            </strong>
                        </div>
                        <div className="button">
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default MantenimientoRolModal;