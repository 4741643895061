import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from "react-router-dom";
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';

import Wrapper from '../../../components/atoms/DirectorioWrapper';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { getGruposByProcesoAPI } from '../../../consumers/backendApisUrls';
import { jsonEstaVacio } from '../../../helper/utils';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import Paginator from '../../directorio/Paginator';

import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import TableWebBody from './TableWebBody';
import CrearPlantilla from './modales/CrearPlantilla'
import EliminarGrupoAsignadoComponente from './modales/EliminarGrupoAsignadoComponente';

const AsignarGruposIndex = () => {
    const { state: statePC } = useLocation()

    const [move, setMove] = useState(false)

    const [executePostRequest] = usePostRequest();

    const [hideModalCrearPlantilla, setHideModalCrearPlantilla] = useState(false);
    const [page, setPage] = useState(1);
    const [initialResponse, setInitialResponse] = useState({});
    const [filas, setFilas] = useState([]);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [totalItems, setTotalItems] = useState(10);
    const [BotonAsignarGrupo, setBotonAsignarGrupo] = useState(true);
    const [showEliminarGrupoAsignadoComponenteComponente, setShowEliminarGrupoAsignadoComponenteComponente] = useState(false);
    const [grupoName, setGrupoName] = useState("");
    const [grupoId, setGrupoId] = useState(0);
    const [grupo, setGrupo] = useState({});

    const routeChange = () => {
        setMove(true)
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const getInitialData = async () => {
        try {
            await manageSearch();
        } catch (e) {
            console.error(e);
        }
    };

    const manageSearch = async (rerenderTable = false) => {
        let payload = obtenerPayload();
        await executeSearch(payload, rerenderTable);
    }

    const obtenerPayload = function () {
        let payload = {
            "Page": page,
            "IdProceso": statePC.procesoCierre.IdProcesoCierre,
        };
        return payload;
    }

    const executeSearch = async (payload, rerenderTable) => {
        rerenderTable === true && setFilas([]);
        const response = await executePostRequest(getGruposByProcesoAPI(), payload);
        if (responseCode200(response)) {
            setFilas(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
            jsonEstaVacio(initialResponse) && setInitialResponse(response.data);
            setBotonAsignarGrupo(response.data.ExtraData.puedeAsignar)

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const toggleCrearPlantillaModal = (grupo) => {
        setHideModalCrearPlantilla(!hideModalCrearPlantilla);
        setGrupo(grupo)
    }

    const toggleEliminarAsignacionGrupoModal = (grupoId, componenteName) => {
        setGrupoName(componenteName);
        setGrupoId(grupoId)
        setShowEliminarGrupoAsignadoComponenteComponente(!showEliminarGrupoAsignadoComponenteComponente);
    }

    useDidMountEffect(() => {
        manageSearch();
    }, [page]);

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };


    if (move) {
        return <Redirect to={{ pathname: '/procesosDeCierre/' }} />;
    }

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Esta página no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile"></div>

            {hideModalCrearPlantilla &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CrearPlantilla toggleModal={toggleCrearPlantillaModal} refreshTable={manageSearch} IdProcesoCierre={statePC.procesoCierre.IdProcesoCierre} grupo = {grupo}/>
                </div>
            }
            {
                showEliminarGrupoAsignadoComponenteComponente &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <EliminarGrupoAsignadoComponente
                    toggleModal = {toggleEliminarAsignacionGrupoModal}
                    refreshPage = {manageSearch}
                    componenteName = {grupoName}
                    componenteId = {grupoId}
                    />
                </div>
            }

            <Wrapper className='App'>
                <PageHeader title={statePC.procesoCierre.Nombre + ' - Asignación de grupos'} />
                <div className="generic-buscador-container">
                    <div className="filtros-left-section">
                        <div className="filtros-left-section">
                        </div>
                    </div>

                    <div className="filtros-right-section">
                        <button className='generic-button-buscador' onClick={() => { routeChange() }}>
                            <FontAwesomeIcon icon='angle-left' size="lg" className="hand-pointer" title="Atras" /> Atrás
                        </button>
                        {
                            BotonAsignarGrupo == true ?
                                <button
                                    onClick={toggleCrearPlantillaModal}
                                    type='button'
                                    className='generic-button-buscador'>
                                    Asignar grupo
                                </button> : null
                        }
                    </div>




                </div>
                <div className='container-fluid body-content'>
                    <div className='table-responsive form-group-sm pt-3'>
                        <table className='table table-hover listar-table-cierre-anio'>
                            <thead className="listar-thead">
                                <tr>
                                    <th className="col-4 pr-1">
                                        <span className='font-gothan-medium d-inline'>Grupo</span>
                                    </th>
                                    <th className="col-2 pr-1">
                                        <span className='font-gothan-medium d-inline'>Estado</span>
                                    </th>
                                    <th className="col-2 pr-1">
                                        <span className='font-gothan-medium d-inline'>Acciones</span>
                                    </th>
                                </tr>
                            </thead>
                            <TableWebBody filas={filas} manageSearch={manageSearch} toogleEditar = {toggleCrearPlantillaModal} toogleEliminar={toggleEliminarAsignacionGrupoModal} />
                        </table>
                        {totalPages > 0 && (
                            <Paginator totalItems={totalItems}
                                firsItemPage={firsItemPage}
                                lastItemPage={lastItemPage}
                                totalPages={totalPages}
                                page={page}
                                onLeftClic={onLeftClic}
                                onRightClic={onRightClic} />
                        )}


                    </div>
                </div>
            </Wrapper>
        </>
    );
}

export default AsignarGruposIndex;