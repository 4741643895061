import React, { useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import SelectInput from '../../../components/Select/SelectInput';
import { actualizacionMasivaAPI, actualizacionMasivaMensajeConfirmacionAPI, getAllCargosAPI, getAllSedesAPI, getDropDownColaboradorAPI, getProcesoCierreCondicionesAPI, getProcesoCierreTiposContratoAPI } from '../../../consumers/backendApisUrls';
import { getCurrentYear } from '../../../helper/utils';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const ActualizacionMasiva = ({ showModal, refreshTable }) => {

    const SECTION_NAME = "ACTUALIZACION_MASIVA"
    const allInputKeys = ["FechaInicio", "FechaFin"];
    const [getOptions] = useGetOptionsSelect();

    const [jsonSelectedSede, setJsonSelectedSede] = useState({});
    const [jsonSelectedSedeCampo, setJsonSelectedSedeCampo] = useState({});
    const [jsonSelectedTipoContrato, setJsonSelectedTipoContrato] = useState({});
    const [jsonSelectedTipoContratoCampo, setJsonSelectedTipoContratoCampo] = useState({});
    const [jsonSelectedCargo, setJsonSelectedCargo] = useState({});
    const [jsonSelectedCargoCampo, setJsonSelectedCargoCampo] = useState({});
    const [jsonSelectedCondicion, setJsonSelectedCondicion] = useState({});
    const [jsonSelectedResponsable, setJsonSelectedResponsable] = useState({});

    const [sedesOptions, setSedesOptions] = useState([]);
    const [tiposContratosOptions, setTiposContratosOptions] = useState([]);
    const [cargosOptions, setCargosOptions] = useState([]);
    const [condicionesOptions, setCondicionesOptions] = useState([]);

    const [disableInputs, setDisableInputs] = useState(false);

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
    const [mensajeConfirmacion, setMensajeConfirmacion] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        setSedesOptions(await getOptions(getAllSedesAPI()));
        setTiposContratosOptions(await getOptions(getProcesoCierreTiposContratoAPI()));
        setCargosOptions(await getOptions(getAllCargosAPI()));
        setCondicionesOptions(await getOptions(getProcesoCierreCondicionesAPI()));
    };

    const handleChangeBuscarColaboradorResponsable = async (jsonValue) => {
        setJsonSelectedResponsable(jsonValue)
    }

    const submitConfirmacion = async (event) => {
        event.preventDefault();
        let payload = obtenerPayload();
        await submitForm(allInputKeys, actualizacionMasivaMensajeConfirmacionAPI(), payload, SECTION_NAME, successSubmitConfirmacionCallback, false );
    };

    const successSubmitConfirmacionCallback = (response) => {
        setMensajeConfirmacion(response?.data?.Message);
        setShowModalConfirmacion(true);
    }

    const submit = async (event) => {
        event.preventDefault();
        let payload = obtenerPayload();
        await submitForm(allInputKeys, actualizacionMasivaAPI(), payload, SECTION_NAME, successSubmitCallback);
    };

    const obtenerPayload = () => {
        return {
            "Filters": {
                "IdSede": jsonSelectedSede.value ?? null,
                "IdTipoContrato": jsonSelectedTipoContrato.value ?? null,
                "IdCargo": jsonSelectedCargo.value ?? null,
                "FechaIngreso": formValues.FechaIngreso ?? null
            },
            "Campos": {
                "IdCondicion": jsonSelectedCondicion.value ?? null,
                "IdTipoContrato": jsonSelectedTipoContratoCampo.value ?? null,
                "FechaInicio": formValues.FechaInicio ?? null,
                "FechaFin": formValues.FechaFin ?? null,
                "IdCargo": jsonSelectedCargoCampo.value ?? null,
                "Remuneracion": formValues.Remuneracion ?? null,
                "IdSede": jsonSelectedSedeCampo.value ?? null,
                "IdEmpleadoResponsable": jsonSelectedResponsable.value ?? null
            }
        };
    }

    const successSubmitCallback = (response) => {
        refreshTable();
        showModal();
    }

    return (
        <>
            {
                showModalConfirmacion &&
                <GenericModal showModal={showModal} width={"500px"} titulo={"Actualización masiva"}>
                    <div className="modal-body" style={{ height: "100%" }}>
                        <div className="form-group row mb-2">
                            <label className="col-sm-12 col-form-label text-center">
                                <strong>{mensajeConfirmacion}</strong>
                            </label>
                        </div>
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={() => setShowModalConfirmacion(!showModalConfirmacion)}>Cancelar</button>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={submit}>Confirmar</button>
                        </div>
                    </div>
                </GenericModal>
            }
            {
                !showModalConfirmacion &&
                <GenericModal showModal={showModal} width={"1050px"} titulo={"Actualización masiva"}>
                    <div className="modal-body" style={{ height: "100%" }}>
                        <form>
                            <div className="form-group row mb-2">
                                <label className="col-sm-12 col-form-label text-justify">
                                    <strong>Criterio de actualización</strong>
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Sede
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={sedesOptions} jsonSelectedValue={jsonSelectedSede} setJsonSelectedValue={setJsonSelectedSede} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdSede-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                    Contrato
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={tiposContratosOptions} jsonSelectedValue={jsonSelectedTipoContrato} setJsonSelectedValue={setJsonSelectedTipoContrato} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdTipoContrato-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Cargo
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={cargosOptions} jsonSelectedValue={jsonSelectedCargo} setJsonSelectedValue={setJsonSelectedCargo} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdCargo-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                    Fecha de ingreso
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="date" className="form-control" name="FechaIngreso" onChange={handleChange} value={formValues?.FechaIngreso ?? ""} disabled={disableInputs} min={(getCurrentYear() + 1) + '-01-01'} value={formValues?.FechaIngreso}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaIngreso-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-sm-12 col-form-label text-justify">
                                    <strong>Campo a actualizar</strong>
                                </label>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Condición
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={condicionesOptions} jsonSelectedValue={jsonSelectedCondicion} setJsonSelectedValue={setJsonSelectedCondicion} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdCondicion-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nuevo contrato
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={tiposContratosOptions} jsonSelectedValue={jsonSelectedTipoContratoCampo} setJsonSelectedValue={setJsonSelectedTipoContratoCampo} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdTipoContrato-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row pt-3">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Especificar periodo
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="date" className="form-control" name="FechaInicio" onChange={handleChange} min={(getCurrentYear() + 1) + '-01-01'} isDisabled={disableInputs} value={formValues?.FechaInicio}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaInicio-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="date" className="form-control" name="FechaFin" onChange={handleChange} min={(getCurrentYear() + 1) + '-01-01'} isDisabled={disableInputs} value={formValues?.FechaFin}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaFin-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nuevo cargo
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={cargosOptions} jsonSelectedValue={jsonSelectedCargoCampo} setJsonSelectedValue={setJsonSelectedCargoCampo} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdCargo-validation-message`}></p>
                                </div>
                                <label className="col-sm-2 col-form-label text-justify">
                                    Especificar sueldo
                                </label>
                                <div className="col-sm-4 black-color">
                                    <input type="text" className="form-control" name="Remuneracion" onChange={handleChange} disabled={disableInputs} value={formValues?.Remuneracion}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Remuneracion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row  mb-0">
                                <label className="col-sm-2 col-form-label text-justify">
                                    Nueva sede
                                </label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput options={sedesOptions} jsonSelectedValue={jsonSelectedSedeCampo} setJsonSelectedValue={setJsonSelectedSedeCampo} isDisabled={disableInputs} placeholder={"Seleccionar"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdSede-validation-message`}></p>
                                </div>

                                <label className="col-sm-2 col-form-label text-justify">
                                    Responsable
                                </label>

                                <div className="col-sm-4 black-color">
                                    <AsyncSelectInput api={getDropDownColaboradorAPI} placeholder="Buscar por nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorResponsable} isDisabled={disableInputs}
                                        defaultValue={jsonSelectedResponsable} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdEmpleadoResponsable-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer d-flex justify-content-end">
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={submitConfirmacion}>Enviar</button>
                        </div>
                    </div>
                </GenericModal>
            }

        </>
    );
};

export default ActualizacionMasiva;