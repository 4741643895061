import React, {useContext, useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/core";
import MuiAccordion from "@material-ui/core/Accordion/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getDetalleActividadesAPI} from "../../../consumers/backendApisUrls";
import {ACTIVIDADES_PENDIENTES_MODALES} from "../../../constants/hardCodedConstants";
import parse from 'html-react-parser';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadFile} from "../../../consumers/backendConsumer";
import {AuthContext} from "../../../hooks/context/authContext";
import { getFormattedName } from '../../../helper/utils';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        color: '#3a87c8',
        background: 'rgba(0,0,0,.03);',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: 0,
        borderRadius: '5px',
        height: 'auto',
        minHeight: 'auto',
        '&$expanded': {
            minHeight: 'auto',
            height: 'auto',
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}))(MuiAccordionDetails);

const DetalleDePasosDeActividades = ({
                                         showModal, idEmpleadoFlujoActividad, showDetalleRegistro, showVerificarERPendienteModal,
                                         showGenerarLiquidacionModal, showConfirmarPrestamoModal,
                                         showCoordinarDevolucionModal, showConfirmarEstadoRecepcionEquiposModal,
                                         showActualizarLiquidacionModal
                                     }) => {
    const [expanded, setExpanded] = React.useState();
    const [detalleActividades, setDetalleActividadesDeIdEmpleadoFlujoActividad] = useState({});
    const [executeGetRequest] = useGetRequest();
    const {state} = useContext(AuthContext);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (detalleActividades?.Actividades) {
            setExpanded("panel" + (detalleActividades.Actividades.length - 1));
        }
    }, [detalleActividades]);

    const init = async function () {
        const response = await executeGetRequest(getDetalleActividadesAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setDetalleActividadesDeIdEmpleadoFlujoActividad(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const masDetalle = (e) => {
        if (e.target.classList.contains('more')) {
            e.target.classList.remove('more');
            e.target.classList.add('single-line-text');
        } else {
            e.target.classList.add('more');
            e.target.classList.remove('single-line-text');
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"90vw"} titulo={detalleActividades && detalleActividades?.NombreFlujo && detalleActividades.NombreFlujo}>
                <div className="modal-body pt-0" style={{minHeight: '200px'}}>
                    <div className="d-flex justify-content-end">
                        <div></div>
                        <div>
                            <label className="nuevos-ingresos-detalle-actividad-label black-color-important modal-label "><strong>Estado de Flujo:</strong> {detalleActividades.EstadoFlujo}</label>
                        </div>
                    </div>
                    <div>
                        <div className="form-group row form-input-modal-contactanos">
                            <div className="col-sm-12 custom-scroll">
                                <div className="nuevos-ingresos-detalle-actividad-labels-group">
                                    {detalleActividades && detalleActividades?.DataList && detalleActividades.DataList.map((elemento, index) => {
                                        return (
                                            <div className="nuevos-ingresos-detalle-actividad-label-container" key={index}>
                                                <label className="nuevos-ingresos-detalle-actividad-label black-color-important modal-label"><strong>{elemento.Name}:</strong> {elemento.Value}</label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="custom-scroll">
                            <div className="nuevos-ingresos-detalle-actividad-acordion-container">
                                {detalleActividades && detalleActividades?.Actividades && detalleActividades.Actividades.map((item, i) => (
                                    <Accordion square expanded={expanded === 'panel' + i} key={i} onChange={handleChange('panel' + i)}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={"panel-content" + i} id={"panel-header" + i}>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm">
                                                        <label className="col-form-label modal-label ">{item.NombreActividad}</label>
                                                    </div>
                                                    <div className="col-sm">
                                                        <label className="col-form-label modal-label ">Ini: {item.FechaCrea}</label>
                                                    </div>
                                                    <div className="col-sm">
                                                        <label className="col-form-label modal-label ">Fin: {item.FechaEjecuta}</label>
                                                    </div>
                                                    <div className="col-sm">
                                                        <label className="col-form-label modal-label ">{item.Estado}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{minWidth: 250, maxWidth: '90vw'}} className="single-line-text" onClick={(e) => masDetalle(e)}>
                                                {item.Mensaje} &nbsp;

                                                {item?.Data && item?.Data.map((row, i) => {
                                                    if (row.Type == "html") {
                                                        return (
                                                            <div className="row custom-form-group-modal" key={i}>
                                                                <label className="col-sm-5"><strong>{row.Name}:</strong></label>
                                                                <div className="col-sm-7 black-color">{parse(row.Value)}</div>
                                                            </div>
                                                        )
                                                    }
                                                    if (row.Type == "file") {
                                                        return (
                                                            <div className="row custom-form-group-modal" key={i}>
                                                                <label className="col-sm-5"><strong>{row.Name}:</strong></label>
                                                                <div className="col-sm-7 black-color">
                                                                    {row.Value &&
                                                                    <div className="hand-pointer blue-standard-color" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        downloadFile(state.token, row?.Value?.Path, row?.Value?.OriginalFileName);
                                                                    }}>
                                                                    <FontAwesomeIcon icon='paperclip'  size="lg" className="hand-pointer blue-standard-color" title="Descargar" />
                                                                    {row?.Value?.OriginalFileName && row.Value.OriginalFileName.length > 0? getFormattedName(row.Value.OriginalFileName, 25): null}
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>

                </div>
            </GenericModal>
        </>
    );
};

export default DetalleDePasosDeActividades;