import React, {useContext, useState, useEffect, useRef} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { AuthContext } from '../../../hooks/context/authContext';
import { getEditarSolicitudVacacionesAPI, addNotaSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls';

const AgregarNotaSolicitudVacaciones = ({ showModal, refreshPage, idSolicitudMovimiento, nombreEmpleado }) => {
    const SECTION_NAME = "AgregarNotasSolicitudVacaciones";
    const KEYS = ["Nota"];

    const refContainer = useRef(null);

    const {state} = useContext(AuthContext);

    const [formValues, setFormValues] = useState({});
    const [datosEmpleado, setDatosEmpleado] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const [handleChange] = useForm(formValues, setFormValues);

    const [executeGet] = useManageGetRequest();
    const [submitForm] = useSubmitForm();

    const init = async () => {
        setIsLoading(true);
        try {
            manageSearch();
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const manageSearch = async () => {
        let api = getEditarSolicitudVacacionesAPI(idSolicitudMovimiento);
        await executeGet(api, (res) => setDatosEmpleado(res.data?.Notas));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            let api = addNotaSolicitudVacacionesAPI();
            let payload = getPayload();
            await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback)
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false);
    }

    const getPayload = () => {
        return {
            ...formValues, 
            IdSolicitudMovimiento: idSolicitudMovimiento
        }
    }

    const successSubmitCallback = () => {
        manageSearch();
        refreshPage();
        setFormValues({Nota: ""});
    }

    useEffect(() => {
        let unmount = false;
        if(!unmount)
            init();

        return () => {
            unmount = true
        }
    }, [])

  return (
    <GenericModal showModal={showModal} width={"900px"} titulo={"Registrar Nota"}>
        <div>
            <div>
                <form ref={refContainer} className="blue-standard-color">
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-4 col-form-label modal-label">Datos colaborador</label>
                        <div className="col-sm-6 col-form-label modal-label ">
                            <label className="add-nota-cese-colaborador">{nombreEmpleado}</label>
                        </div>
                    </div>
                    <div className="form-group row form-input-modal-contactanos">
                        <label className="col-sm-4 col-form-label modal-label">Notas del caso <span className="required">*</span></label>
                    </div>
                    <div className="contenedor-scroll-notas scroll-awesome" id="contenedor-notas">
                        {datosEmpleado && datosEmpleado.map((nota, index) => {
                            return (
                                <div key={index} className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group">
                                    <div className="col-sm-1 modal-add-nota-imagen-comtainer">
                                        <img className="modal-add-nota-imagen" src={nota.FotoPerfilUrl}></img>
                                    </div>
                                    <div className="col-sm-10">
                                        <div>{nota.FechaNota}</div>
                                        <div>{nota.NombreEmpleado} - {nota.NombreCargo}</div>
                                        <div className="modal-add-nota-creada">{nota.Nota}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group py-3">
                        <div className="col-sm-1 modal-add-nota-imagen-comtainer">
                            <img className="modal-add-nota-imagen-input-new-nota" src={state.user.photo}></img>
                        </div>
                        <div className="col-sm-10">
                            <textarea autoFocus data-required="true" className="form-control" rows="5" id="Nota" name="Nota" onChange={handleChange}
                                        value={formValues.Nota} maxLength="2000"
                                        placeholder={"Máximo 2000 caracteres."}></textarea>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Nota-validation-message`}></p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div>
            <div className="modal-footer" style={{'marginTop': '15px'}}>
                <div className="message font-gothan-light" style={{'width': '500px'}}>
                    <strong>
                        <p>* Campos obligatorios</p>
                    </strong>
                </div>
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha" onClick={handleSubmit} disabled={isLoading}>Registrar Nota</button>
                </div>
            </div>
        </div>
    </GenericModal>
  )
}

export default AgregarNotaSolicitudVacaciones