import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {resetUploadFileV2} from "../../../helper/fileManager";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getActividadesRenunciasAPI, guardarVerificarErPendienteAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import {AlertErrorMaxFileSizeCeses} from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";

const VerificarERPendienteModal = ({showModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "VerificarERPendienteModal";
    const [actividadRenuncia, setActividadRenuncia] = useState({});

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const UPDATE_SUSTENTO_INPUT_FILE = sectionName + "-sustento-file";
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (formValues?.TieneErPendiente === "false" || formValues?.TieneErPendiente === false) {
            resetMontoAndSustento();
        }
    }, [formValues]);

    const resetMontoAndSustento = function () {
        if (formValues.Monto !== "") {
            setFormValues({...formValues, "Monto": ""});
        }
        resetUploadFileV2(setS3UploadFileResponse, UPDATE_SUSTENTO_INPUT_FILE);
    };

    const init = async function () {
        const response = await executeGetRequest(getActividadesRenunciasAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setActividadRenuncia(response.data);
            let formInputValues = getInitialFormValues();
            setFormValues(formInputValues);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const getInitialFormValues = () => {
        return {
            "TieneErPendiente": "false",
            "Monto": "",
            "Observaciones": ""
        };
    };

    const checkIfTieneErPendienteeIsFalse = function (tieneErPendientee) {
        return (tieneErPendientee === "false" ? false : tieneErPendientee)
    };

    const getTieneErPendiente = function () {
        let tieneErPendientee = formValues.TieneErPendiente;
        return (tieneErPendientee === "true" ? true : checkIfTieneErPendienteeIsFalse(tieneErPendientee))
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        console.debug("formValues: ", formValues);
        let tieneErPendientee = getTieneErPendiente();
        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "TieneErPendiente": tieneErPendientee,
            "Sustento": s3UploadFileResponse,
            "Monto": formValues.Monto,
            "Observaciones": formValues.Observaciones
        };

        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarVerificarErPendienteAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["TieneErPendiente", "Sustento", "Monto", "Observaciones"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"800px"} titulo="Confirmar E/R Pendiente">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label ">Colaborador:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.NombreEmpleado}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.FechaUltimoDia}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.Sede}</label>
                            </div>
                            <hr/>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Tiene E/R pendiente?<span className="required">*</span></label>
                                <div className="col-sm-6 flex-row justify-content-center ">
                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="TieneErPendiente" value="true" checked={formValues.TieneErPendiente == "true"} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">Sí</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="TieneErPendiente" value="false" checked={formValues.TieneErPendiente == "false"} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">No</label>
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-TieneErPendiente-validation-message`}></p>
                                </div>
                            </div>
                            {formValues && formValues?.TieneErPendiente === true || formValues?.TieneErPendiente === "true" &&
                            <>
                                <div className="row custom-form-group-modal">
                                    <label className="col-sm-4 col-form-label modal-label">Cargar Sustento<span className="required"></span></label>
                                    <div className="col-sm-8 flex-row justify-content-center ">
                                        <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponse} id={UPDATE_SUSTENTO_INPUT_FILE} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                             subiendoArchivoPublicState={subiendoArchivo} setSubiendoArchivoPublicState={setSubiendoArchivo}/>

                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                                    </div>
                                </div>

                                < div className="row custom-form-group-modal">
                                    <label className="col-sm-4 col-form-label modal-label">Indicar Monto<span className="required">*</span></label>
                                    <div className="col-sm-3 flex-row justify-content-center">
                                        <input type="number" className="form-control" name="Monto" onChange={handleChange} defaultValue={formValues.Monto}/>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Monto-validation-message`}></p>
                                    </div>
                                </div>
                            </>
                            }
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Observaciones<span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center ">
                                    <textarea className="form-control" rows="5" name="Observaciones" onChange={handleChange} defaultValue={formValues.Observaciones} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observaciones-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="red-below-modal-message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                            <p>* El formato permitido es .pdf de máximo 8MB.</p>
                        </strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit} disabled={subiendoArchivo}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default VerificarERPendienteModal;

