import React, { useContext, useEffect, useState } from 'react'
import { Container } from '../modales/components/Components';
import HorarioLactancia from '../modales/components/HorarioLactancia';
import confetti from "../../../image/confetti.svg"
import PageHeader from '../../../components/pageHeader/PageHeader';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import FooterModal from '../../../components/modalComponents/FooterModal';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import ModalMensajeSuccessfull from '../../../components/modalMensaje/ModalMensajeSuccessfull';
import { AuthContext } from '../../../hooks/context/authContext';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { initialAllOptionPromises } from '../../../helper/utils';
import { responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { getBaseGuardarHorarioLactanciaAPI, getHorariosLactanciaMaternidadAPI, guardarHorarioLactanciaAPI } from '../../../consumers/backendApisUrls';
import ModalMensaje from '../../../components/modalMensaje/ModalMensaje';

const IMAGE_LOCK = "https://intranet.innovaschools.edu.pe/Content/image/lock_1.png";
const MENSAJE_EXPIRO = "El link de acceso para completar los datos ha caducado.";
const SECTION_NAME = "RegistrarHorarioLactancia";
const message = "Sus datos han sido guardados.";
const KEYS = { idLactancia: "IdHorarioLactanciaMaternidad" };

const HorarioLactanciaPublic = (props) => {
  const { state, dispatch } = useContext(AuthContext);
  const searchParams = new URLSearchParams(props.location.search);
  const token = searchParams.get('token');
  
  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [hasPermission, setHasPermission] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const setTokenAuth = () => {
    if (token) {
      dispatch({
        type: 'PUBLIC_TOKEN',
        payload: {
          token: token,
        }
      });
      setIsPageLoaded(true);
    }
  }

  const getPermissions = async () => {
    const api = getBaseGuardarHorarioLactanciaAPI(0);
    
    try {
      let result = await executeGet(api);
      if (responseCode200(result)){
        const {PuedeRegistrarHorarioLactancia} = result.data;
        setHasPermission(PuedeRegistrarHorarioLactancia);
        if (PuedeRegistrarHorarioLactancia)
          return;
      }
      showMessage(MENSAJE_EXPIRO);
    } catch (error) {
      console.error(error);
      showMessage("Ha ocurrido un error");
    }    
  }

  const showMessage = (message) => {
    setModalEnabled({isEnable: true, component: ModalMensaje, data: {modalMensaje: message, modalImage: IMAGE_LOCK, styleFicha: {color: "red"}, hasBackButton: false}});
  }

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getHorariosLactanciaMaternidadAPI())
    ]
    await initialAllOptionPromises( dropdownsPromises, Object.values(KEYS), setAllDropdowns );
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = guardarHorarioLactanciaAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleSuccessSubmit = () => {
    setModalEnabled({ isEnable: true, component: ModalMensajeSuccessfull, data: { modalMensaje: message, image: confetti, hasBackButton: false } });
  }

  useDidMountEffect(() => {
    getPermissions();
  }, [isPageLoaded])

  useDidMountEffect(() => {
    if (hasPermission) getDropdowns()
  }, [hasPermission])

  useEffect(() => {
    setTokenAuth();
  }, [token])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} />
      <PageHeader title='Registrar horario lactancia' />
      <Container>
        <form onSubmit={handleSubmit}>
          <HorarioLactancia selectProps={selectProps} keys={KEYS} isEditable={hasPermission}/>
          <FooterModal label='Enviar' isDisabled={isLoading} isSubmitting={isSubmitting} />
        </form>
      </Container>
    </PageWrapper>
  )
}

export default HorarioLactanciaPublic