import React, { useState, useEffect } from 'react'
import InputText from '../../../components/modalComponents/InputText'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputDate from '../../../components/modalComponents/InputDate'
import InputNumber from '../../../components/modalComponents/InputNumber'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest'
import { getPeriodoPlanillaByIdSolicitudMovimientoAPI } from '../../../consumers/backendApisUrls'
import styled from 'styled-components'

const DetallePeriodoPlanilla = ({ toggleModal, idSolicitudMovimiento }) => {

  const KEYS = { fechaRegistro: "FechaCreacion", dias: "CantidadDias", creador: "Creador", periodos: "PeriodosPlanilla"};

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [executeMangGet] = useManageGetRequest();


  const init = async () => {
    setIsLoading(true);
    const api = getPeriodoPlanillaByIdSolicitudMovimientoAPI(idSolicitudMovimiento);
    try {
      await executeMangGet(api, ({ data }) => setFormValues(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm titulo="Detalle del Periodo de Planilla" showModal={toggleModal} hasSaveButton={false} width="650px">
      <div className="col-sm-12">
        <div className="displayFlexWrap">
          <div className="col-sm-6">
            <InputDate label={KEYS.fechaRegistro} value={formValues[KEYS.fechaRegistro]} isDisabled direction='column'/>
          </div>
          <div className="col-sm-6">
            <InputNumber label={KEYS.dias}  value={formValues[KEYS.dias]} isDisabled direction='column'/>
          </div>
        </div>
      </div>
      <div className="col-sm-12">
        <div className='px-3'>
          <InputText label={KEYS.creador} value={formValues[KEYS.creador] ?? "-"} isDisabled direction='column'/>
        </div>
      </div>
      <div>
        <DivDetalle className='blue-standard-color'>Detalle</DivDetalle>
        <div className="container-fluid body-content">
          <div className="table-scroll">
            <table className='table table-hover'>
              <thead className='listar-thead'>
                <th className='text-center font-gothan-medium'>Periodo</th>
                <th className='text-center font-gothan-medium'>Cantidad Días</th>
              </thead>
              <tbody>
                {formValues[KEYS.periodos]?.map(o => (
                  <tr>
                    <td className='text-center'>{o.Periodo}</td>
                    <td className='text-center'>{o.CantidadDias}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

export default DetallePeriodoPlanilla

const DivDetalle = styled.div`
  border-bottom: 0.5px solid #3a87c8;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
`