export const PALABRAS_INICA_UPPER_CASE_ESPACIOS_OPCIONALES = /^[A-ZÀ-Ú][A-ZÀ-Úa-zá-ú\s]*$/
export const SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS = /^[A-ZÀ-Ú][a-zá-úñ]*('[a-zá-úñ])?[a-zá-úñ]*$/
export const SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS_O_PUNTO = /(^[A-ZÀ-Ú][a-zá-úñ]*('[a-zá-úñ])?[a-zá-úñ]*$)|(^[\.]{1}$)/
export const SOLO_LETRAS = /^[A-Za-zñÑ]+$/
export const SOLO_LETRAS_O_ESPACIOS = /^[áéíóúñÑÁÉÍÓÚa-zA-Z\s]*$/
export const SOLO_LETRAS_O_ESPACIOS_O_NUMEROS = /^[áéíóúñÑÁÉÍÓÚa-zA-Z0-9\s]*$/
export const SOLO_LETRAS_O_ESPACIOS_O_NUMEROS_VOCALES_CON_TILDE = /^[áéíóúñÑÁÉÍÓÚa-zA-Z0-9\s]*$/
export const SOLO_LETRAS_O_ESPACIOS_O_NUMEROS_O_GUIONES = /^[áéíóúñÑÁÉÍÓÚa-zA-Z0-9-\s]*$/
export const SOLO_LETRAS_O_ESPACIOS_O_NUMEROS_LENGHT_DIEZ = /^[áéíóúñÑÁÉÍÓÚa-zA-Z0-9\s]{0,10}$/
export const SOLO_NUMEROS = /^[0-9]*$/
export const IDS_LISTA_DESPLEGABLE_OBLIGATORIO = /^[1-9][0-9]*$/
export const IDS_LISTA_DESPLEGABLE_NO_OBLIGATORIO = /^[0-9]*$/
export const CORREO_ELECTRONICO_GENERICO_Y_NOINNOVA = /^([a-zA-Z0-9_\-\.]+)@(?!colegiosperuanos\.edu\.pe|innovaschools\.edu\.pe|innovaencasa\.edu\.pe)([A-Za-z0-9]+\.(com|edu\.pe))$/
export const CORREO_ELECTRONICO_INNOVA = /^([a-zA-Z0-9_\-\.]+)@(?!colegiosperuanos\.edu\.pe|innovaschools\.edu\.pe|innovaencasa\.edu\.pe)([A-Za-z0-9]+\.[a-zA-Z]{2,7}$)$/
export const SOLO_NUEVE_NUMEROS = /^[0-9]{9,9}$/
export const SOLO_SIETE_O_MENOS_NUMEROS = /^[0-9]{1,7}$/
export const CHECKBOX_TRUE = /true/
export const PALABRA_GUION_PALABRA = /[A-Za-z]+-[A-Za-z]+$/
export const ANYTHING = /.{0,50}/
export const ANY = 'any'
export const GENERIC_CORREO_ELECTRONICO = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/
export const SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_CON_ESPACIOS_O_PUNTO_O_GUION = /^[A-ZÀ-Ú][a-zá-úñ]*('[a-zá-úñ])?[a-zá-úñ]*(([\s\-\.][A-ZÀ-Ú][a-zá-úñ]*('[a-zá-úñ])?[a-zá-úñ]*)?)*$/
