import React from "react";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const PaginatorV2 = ({ paginator, setPaginator, manageSearch }) => {

  let {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = paginator;

  const onLeftClic = () => {
    const beforePage = Math.max(CurrentPage - 1, 1);
    setPaginator(lastData => ({...lastData, CurrentPage: beforePage}));
  };

  const onRightClic = () => {
    const nextPage = Math.min(CurrentPage + 1, TotalPages);
    setPaginator(lastData => ({...lastData, CurrentPage: nextPage}));
  };

  useDidMountEffect(() => {
    if (manageSearch) manageSearch();
  }, [paginator.CurrentPage]);

  return (
    TotalPages > 0 &&
      <div className="d-flex justify-content-between w-100 pagination mt-1">
        <div>
          <a>
            {FirsItemPage}–{LastItemPage} de {TotalItems}
          </a>
        </div>
        <div>
          <a className="previous round page-button" onClick={onLeftClic}>
            &#8249;
          </a>
          <a>
            {CurrentPage} de {TotalPages}
          </a>
          <a className="next round page-button" onClick={onRightClic}>
            &#8250;
          </a>
        </div>
      </div>
  );
};

export default PaginatorV2;
