import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {obtenerElementoDeFichaMedicaPorId, validarCampo} from "../../validations/SeccionInformacionMedicaValidator";
import {agregarTextOfSelectInputType, notNullAndNotUndefinded, resetForm} from "../../helper/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {LIST_FIELD_TYPE} from "../../constants/hardCodedConstants";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getContactoDeEmergenciaAPI, guardarContactoDeEmergenciaAPI} from "../../consumers/backendApisUrls";

const InformacionContactoEmergencia = ({empleadoId, showModal, reloadSeccionesDatosPersonales}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [informacionMedica, setInformacionMedica] = useState({});
    const [vinculos, setVinculos] = useState([]);
    const [declaracionJurada, setDeclaracionJurada] = useState(false);
    const [editFields, setEditFields] = useState([]);
    const [dataFields, setDataFields] = useState([]);

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    function updateId() {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        init();
    }, [idActualizado]);

    const init = async () => {
        await updateComponentStates();
    };

    const updateComponentStates = async () => {
        try {
            if (idActualizado) {
                const response = await executeGetRequest(getContactoDeEmergenciaAPI(idActualizado));
                if (responseCode200(response)) {
                    setInformacionMedica(response.data.data);
                    setDataFields(response.data.data.DataFields);
                    setVinculos(response.data.dropdowns.Vinculos);
                    let editFieldss = response.data.data.EditFields;
                    setEditFields(editFieldss);
                    setDeclaracionJurada(false);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const reset = async () => {
        await updateComponentStates();
    };

    const actualizar = async () => {
        try {
            document.getElementById("frmInfoDatosMedicos").hidden = true;
            document.getElementById("frmEditDatosMedicos").hidden = false;
        } catch (e) {
            console.error(e);
        }
    };

    const cancelar = async () => {
        try {
            resetForm("frmEditDatosMedicos");
            await reset();
            document.getElementById("frmInfoDatosMedicos").hidden = false;
            document.getElementById("frmEditDatosMedicos").hidden = true;
        } catch (e) {
            console.error(e);
        }
    };

    function validarFormulario() {
        let formularioInvalido = false;

        let fieldsToValidate = [...editFields, "AceptaDeclaracionJurada"];


        fieldsToValidate.forEach((fieldId) => {
            let campoInvalido = validarCampo(fieldId);
            if (campoInvalido) {
                formularioInvalido = true;
            }
        });

        return formularioInvalido;
    }

    const agregarCamposDeFormulario = (payload) => {
        for (let dataField of dataFields) {
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let element = obtenerElementoDeFichaMedicaPorId(keyField);
                if (notNullAndNotUndefinded(element)) {
                    if (element.matches('[type="checkbox"]')) {
                        payload = {...payload, [keyField]: element.checked};
                    } else {
                        payload = {...payload, [keyField]: element.value};
                    }

                    if (dataField["FieldType"] == LIST_FIELD_TYPE) {
                        payload = agregarTextOfSelectInputType(keyField, element, payload);
                    }
                } else {
                    console.warn(`no se encontró campo con el id "${keyField}", por lo que no se agregará al payload, `);
                }
            }
        }
        return payload;
    };

    const onSubmit = async (event) => {
        try {
            event.preventDefault();
            let formularioInvalido = validarFormulario();

            if (!formularioInvalido) {
                let payload = {
                    AceptaDeclaracionJurada: event.target.AceptaDeclaracionJurada.checked,
                    IdEmpleado: idActualizado,
                };

                payload = agregarCamposDeFormulario(payload);

                let response = await executePostRequest(guardarContactoDeEmergenciaAPI(), payload);
                if (responseCode200(response)) {
                    showModal(response.data.UrlImage, response.data.Message);

                    await updateComponentStates();
                    askForNewBellNotifications(dispatch);

                    document.getElementById("frmInfoDatosMedicos").hidden = false;
                    document.getElementById("frmEditDatosMedicos").hidden = true;
                    await reloadSeccionesDatosPersonales(empleadoId);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleDeclaracionJurada = (e) => {
        setDeclaracionJurada(e.target.checked);
    };

    return (
        <div>
            <div className='card-body'>
                <form id='frmInfoDatosMedicos'>
                    <div className='form-group row'>
                        <label className='col-sm-12 col-form-label ficha-sub-title-color '>
                            <p className="font-gothan-medium">Contacto de emergencia</p>
                        </label>
                    </div>
                    <div className='displayFlexWrap'>
                        <div className='col-12 col-sm-6 col-md-4'>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                                Relación:
                            </label>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                                {informacionMedica && <p>{informacionMedica.Vinculo}</p>}
                            </label>
                        </div>
                        <div className='col-12 col-sm-6 col-md-4'>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                                Nombres y apellidos:
                            </label>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                                {informacionMedica && <p>{informacionMedica.NombreContacto}</p>}
                            </label>
                        </div>
                        <div className='col-12 col-sm-6 col-md-4'>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label text-right'>
                                Número de contacto:
                            </label>
                            <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                                {informacionMedica && <p>{informacionMedica.NumeroContacto}</p>}
                            </label>
                        </div>
                    </div>
                    <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
                        <div style={{'alignSelf': 'flex-start'}}>
                        </div>
                        <div style={{'alignSelf': 'flex-end'}}>
                            {informacionMedica && informacionMedica.CanSeeButtonEditar === true &&
                            <button
                                onClick={actualizar}
                                type='button'
                                className='btn btn-primary ficha-form-button-actualizar'>
                                Actualizar
                            </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <form id='frmEditDatosMedicos' className="ficha-edit-form" hidden={true} onSubmit={onSubmit}>
                    <div className='form-group row'>
                        <label className='col-sm-12 col-form-label ficha-sub-title-color'>
                            <p className="font-gothan-medium">Contacto de emergencia</p>
                        </label>
                    </div>
                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label className="ficha-edit-form-label">Relación</label>
                            {informacionMedica &&
                            <select className="form-control" name="IdVinculo" id="ficha-informacion-medica-IdVinculo">
                                vinculos && informacionMedica && informacionMedica.IdVinculo
                                {
                                    vinculos.map((option) => {
                                            {
                                                if (option.Value === informacionMedica.IdVinculo) {
                                                    return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                                } else {
                                                    return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                                }
                                            }
                                        }
                                    )
                                }
                            </select>
                            }
                            <p className="ficha-form-input-validation-message" id="ficha-informacion-medica-IdVinculo-validation-message"></p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="ficha-edit-form-label">Nombres y apellidos</label>
                            <input type="text" defaultValue={informacionMedica && informacionMedica.NombreContacto} className="form-control" name="NombreContacto" id="ficha-informacion-medica-NombreContacto"></input>
                            <p className="ficha-form-input-validation-message" id="ficha-informacion-medica-NombreContacto-validation-message"></p>
                        </div>
                        <div className="col-md-4 mb-3">
                            <label className="ficha-edit-form-label">Número de contacto</label>
                            <input type="text" defaultValue={informacionMedica && informacionMedica.NumeroContacto} className="form-control" name="NumeroContacto" id="ficha-informacion-medica-NumeroContacto"></input>
                            <p className="ficha-form-input-validation-message" id="ficha-informacion-medica-NumeroContacto-validation-message"></p>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        'justifyContent': 'space-between',
                        'marginTop': '21px'
                    }}>
                        <div style={{'alignSelf': 'flex-start'}}>
                            <div className="form-check margin-top-15px">
                                <input className="form-check-input" type="checkbox" id="ficha-informacion-medica-AceptaDeclaracionJurada" name="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
                                <label className="form-check-label" htmlFor="AceptaDeclaracionJurada" style={{'fontSize': '14px', 'color': '#0063a1', 'fontWeight': '600'}}>
                                    <strong></strong>{informacionMedica && informacionMedica.DJMessage}
                                </label>
                                <p className="ficha-form-input-validation-message" id="ficha-informacion-medica-AceptaDeclaracionJurada-validation-message"></p>
                            </div>
                        </div>

                        <div style={{'alignSelf': 'flex-end'}}>
                            <button
                                onClick={cancelar}
                                type='button'
                                className='btn btn-primary ficha-form-button-cancelar'>
                                Cancelar
                            </button>
                            {informacionMedica && informacionMedica.CanSeeButtonEditar === true &&
                            <button
                                type="submit"
                                className='btn btn-primary ficha-form-button-guardar'>
                                Guardar
                            </button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default InformacionContactoEmergencia;