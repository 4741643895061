import React, { useState, useEffect, useRef } from 'react';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useForm } from '../../../hooks/useForm/UseForm';
import SelectInput from '../../../components/Select/SelectInput';
import InputSelect from '../../../components/modalComponents/InputSelect';
import GenericModal from '../../../components/modal/GenericModal';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../components/modalComponents/InputSelectCascadePost';
import { addDays, initialAllOptionPromises, toIsoForInput } from '../../../helper/utils';
import { getAllJornadasLaboralesAPI, getAllTiposContratoAPI, getAllTiposTrabajadorAPI, getEditarContratoAPI, getRelatedDataTypesAPI, getTiposContrato, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, saveEditarContratoAPI } from '../../../consumers/backendApisUrls';

const EditarContrato = ({ toggleModal, idEmpleadoContrato, manageSearch }) => {
  const isMounted = useRef(true);
  
  const SECTION_NAME = "EDITAR_CONTRATO_DATOS_EMPRESAS";
  const KEYS_INIT = {TipoEmpleado: "IdTipoEmpleado", TipoTrabajador: "IdTipoTrabajador", Tipo: "IdTipoContrato"};
  const KEYS = {TipoEmpleado: "IdTipoEmpleado", TipoTrabajador: "IdTipoTrabajador", Tipo: "IdTipoContrato", RegimenLaboral: "IdRegimenLaboral", FechaInicio: "FechaInicioContrato", FechaVencimiento: "FechaVencimientoContrato", Remuneracion: "Remuneracion", Estado: "EsActivo"};
  const TIME_STAMP = "T00:00:00";

  const [formValues, setFormValues] = useState({});
  const [minDiasInput, setMinDiasInput] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const initialPromises = () => {
    return [
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getTiposContrato()),
    ]
  }

  const initialData = async () => {
    setIsLoading(true);
    try {
      await executeGet(getEditarContratoAPI(idEmpleadoContrato), ({ data }) => setFormValues(data));
      initialAllOptionPromises(initialPromises, Object.values(KEYS_INIT), setAllDropdowns);
    } catch (error) {
      console.error();
    }
    setIsLoading(false);
  }

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    try {
      const api = saveEditarContratoAPI();
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
      ...formValues,
      IdEmpleadoContrato: idEmpleadoContrato,
    }
  }

  const successOnSubmitCallback = async () => {
    await manageSearch();
    toggleModal();
  }

  const calculateMinDaysInputDate = () => {
    let minDays = toIsoForInput(addDays(new Date(), 1));
    if(formValues.FechaInicioContrato) minDays = toIsoForInput(new Date(formValues.FechaInicioContrato+TIME_STAMP));
    setMinDiasInput(minDays)
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[KEYS_INIT.TipoTrabajador],
      IdJornadaLaboral: formValues[KEYS_INIT.TipoEmpleado],
    }
  }

  useDidMountEffect(() => {
    calculateMinDaysInputDate();
  }, [formValues])

  useEffect(() => {
    if (isMounted.current) initialData();
    return () => {isMounted.current = false};
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo="Editar Contrato">
      <div className="modal-body pt-0 pl-0">
        <InputSelect label="Tipo Trabajador" {...commonPropsSelect("IdTipoTrabajador", ["IdTipoContrato", "IdRegimenLaboral"])}/>
        <InputSelect label="Jornada Laboral" {...commonPropsSelect("IdTipoEmpleado", ["IdTipoContrato", "IdRegimenLaboral"])}/>
        <InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonPropsSelect("IdTipoContrato", ["IdRegimenLaboral"])}/>
        <InputSelectCascade label="Régimen Laboral" dependendOption={formValues[KEYS_INIT.Tipo]} api={getRelatedDataTypesAPI} {...commonProps("IdRegimenLaboral")}/>
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label'>
          Fecha Inicio de Contrato
          </label>
          <div className='col-sm-8 black-color'>
            <input className='form-control' value={formValues?.FechaInicioContrato ?? ''} type='date' name={KEYS.FechaInicio} onChange={handleChange}/>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.FechaInicio}-validation-message`}></p>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label'>
            Fecha Vencimiento de Contrato
          </label>
          <div className='col-sm-8 black-color'>
            <input className='form-control' value={formValues?.FechaVencimientoContrato ?? ''} type='date' name={KEYS.FechaVencimiento} onChange={handleChange} min={minDiasInput}/>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.FechaVencimiento}-validation-message`}></p>
          </div>
        </div>
        <div className='form-group row'>
          <label className='col-sm-4 col-form-label modal-label'>
            Remuneración (S/.)
          </label>
          <div className='col-sm-8 black-color'>
            <input className='form-control' value={formValues?.Remuneracion ?? ''} name={KEYS.Remuneracion} onChange={handleChange}/>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Remuneracion}-validation-message`}></p>
          </div>
        </div>
        <div className='form-group row' style={{'visibility':'hidden'}}>
          <label className='col-sm-4 col-form-label modal-label'>
            Estado
          </label>
          <div className='col-sm-8 black-color'>
            <div className='custom-control custom-switch pt-1' style={{zIndex: 0}}>
              <input className='custom-control-input hand-pointer' type='checkbox' checked={formValues?.EsActivo ?? false} name={KEYS.Estado} onChange={handleChange} id='edit-contrato-active-gestion-ficha'/>
              <label className='custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer' htmlFor='edit-contrato-active-gestion-ficha'>
                {formValues?.EsActivo ? "Activo" : "Inactivo"}
              </label>
            </div>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Estado}-validation-message`}></p>
          </div>
        </div>
        <div className='modal-footer d-flex justify-content-end'>
          <div className='button'>
            <button className='btn btn-primary generic-button-ficha-modal' type='button' disabled={isSubmitting} onClick={handleOnSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </GenericModal>
  )
}

export default EditarContrato