import React, { useContext, useEffect, useState } from 'react';

import TableWebBodyProgramacionPruebas from './TableWebBodyProgramacionPruebas';
import FormSelect from '../../components/atoms/Select';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from "../../components/pageHeader/PageHeader";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import {
  descargarListaProgramaPrueabasAPI,
  getAllAreasAPI,
  getAllCargosAPI,
  getAllDepartamentosAPI,
  getAllEstadosAPI,
  getAllSedesAPI,
  getDivisionesAPI,
  listarProgramaPruebasAPI,
} from "../../consumers/backendApisUrls";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import Paginator from "../directorio/Paginator";
import { Dropdown, Spinner } from "react-bootstrap";
import { AuthContext } from "../../hooks/context/authContext";
import { updateOptionsDeFiltro } from "../../helper/Listas/ListasUtils";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import AgregarColaborador from './modales/AgregarColaborador';
import CargarResultados from './modales/CargarResultados';
import ProgramarPrueba from './modales/ProgramarPrueba';
import EditarRegistro from './modales/EditarRegistro';

const GestionProgramacionPruebasCovid = () => {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const [filas, setFilas] = useState([]);
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [descargarArchivoDeS3] = useGenerarDescargarEliminarArchivo();

  const [selectedListUsers, setSelectedListUsers] = useState([]);
  let selectedUsers = [];

  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
  const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);
  const [gerenciaSeleccionadas, setGerenciaSeleccionadas] = useState([]);
  const [areaSeleccionados, setAreaSeleccionados] = useState([]);
  const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
  const [cargosSeleccionadas, setCargosSeleccionadas] = useState([]);
  const [estadosResultadoSeleccionados, setEstadosResultadoSeleccionados] = useState([]);

  const [sedesFiltradasOptions, setSedesFiltradasOptions] = useState([]);
  const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);
  const [gerenciaFiltradasOptions, setGerenciaFiltradasOptions] = useState([]);
  const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
  const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
  const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);
  const [resultadosFiltradasOptions, setResultadosFiltradasOptions] = useState([]);

  const [allSedesFiltradasOptions, setAllSedesFiltradasOptions] = useState([]);
  const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);
  const [allGerenciaFiltradasOptions, setAllGerenciaFiltradasOptions] = useState([]);
  const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
  const [allEstadosFiltradosOptions, setAllEstadosFiltradosOptions] = useState([]);
  const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);
  const [allEstadosResultadoFiltradosOptions, setAllEstadosResultadoFiltradosOptions] = useState([]);

  const [cleanFilterss, setCleanFilters] = useState(false);
  const [executePostRequest] = usePostRequest();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

  const [hideAgregarColaborador, setHideAgregarColaborador] = useState(true);
  const [hideAgregarResultados, setHideAgregarResultados] = useState(true);
  const [hideProgramarPrueba, setHideProgramarPrueba] = useState(true);
  const [hideEditarProgramarPrueba, setHideEditarProgramarPrueba] = useState(true);

  const [idRegistroSeleccionado, setIdRegistroSeleccionado] = useState(1);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    setPage(1);
  }, [criterioDeBusqueda, sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, estadosSeleccionados, estadosResultadoSeleccionados]);

  useDidMountEffect(() => {
    manageSearch();
  }, [sedesSeleccionadas, divisionSeleccionados, gerenciaSeleccionadas, areaSeleccionados, page, fechaMasRecientePrimeroBool, estadosSeleccionados, estadosResultadoSeleccionados]);

  const getInitialData = async () => {
    setLoading(true);
    try {

      await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setAllSedesFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllGerenciaFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);

      await manageSearch();

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const updateOptionsDeFiltros = async function (lists) {
    await updateOptionsDeFiltro(allEstadosResultadoFiltradosOptions, lists["IdsResultados"], setResultadosFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allSedesFiltradasOptions, lists["IdsSedes"], setSedesFiltradasOptions, executeGetRequestCallback(getAllSedesAPI()));
    await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
    await updateOptionsDeFiltro(allGerenciaFiltradasOptions, lists["IdsGerencias"], setGerenciaFiltradasOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
    await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
    await updateOptionsDeFiltro(allEstadosFiltradosOptions, lists["IdsEstados"], setEstadosFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));

    setLoading(false);
  };

  const getDropdownOptions = async (getFunction, setState) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      setState(response.data);
      return response.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      manageSearch();
    }
  };

  const obtenerPayload = function () {
    let payload = {
      "Criteria": criterioDeBusqueda,
      "IdsSedes": sedesSeleccionadas,
      "IdsDivisiones": divisionSeleccionados,
      "IdsGerencias": gerenciaSeleccionadas,
      "IdsAreas": areaSeleccionados,
      "IdsEstados": estadosSeleccionados,
      "IdsResultados": estadosResultadoSeleccionados,
      "Page": page,
      "OrdenarRecientePrimero": fechaMasRecientePrimeroBool
    };
    return payload;
  }

  const manageSearch = async () => {
    setLoading(true);
    let payload = obtenerPayload();
    await executeSearch(payload);
  };

  const executeSearch = async (payload) => {
    const response = await executePostRequest(listarProgramaPruebasAPI(), payload);
    if (responseCode200(response)) {
      setFilas(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setFirsItemPage(response.data.FirsItemPage);
      setLastItemPage(response.data.LastItemPage);
      setTotalPages(response.data.TotalPages);

      await updateOptionsDeFiltros(response?.data?.ExtraData);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const limpiarBusqueda = () => {
    setSedesSeleccionadas([]);
    setDivisionSeleccionados([]);
    setGerenciaSeleccionadas([]);
    setAreaSeleccionados([]);
    setEstadosSeleccionados([]);
    setCriterioDeBusqueda("");
    setCleanFilters(!cleanFilterss);
    setFechaMasRecientePrimeroBool(true);
    setEstadosResultadoSeleccionados([]);
  };

  const showAgregarColaborador = async () => {
    setHideAgregarColaborador(!hideAgregarColaborador);
    await getInitialData();
  };

  const showAgregarResultado = async () => {
    setHideAgregarResultados(!hideAgregarResultados);
    await getInitialData();
  };

  const showProgramarPrueba = async () => {
    setHideProgramarPrueba(!hideProgramarPrueba);
    await getInitialData();
  };

  const showEditarProgramarPrueba = async (idRegistro) => {
    setIdRegistroSeleccionado(idRegistro);
    setHideEditarProgramarPrueba(!hideEditarProgramarPrueba);
    await getInitialData();
  };

  const descargar = async function () {

    const payload = obtenerPayload();
    await descargarArchivoDeS3(descargarListaProgramaPrueabasAPI(), payload);
  }

  const onChangeFechaMasRecientePrimeroBool = (e) => {
    let inputValue = e.target.checked;
    setFechaMasRecientePrimeroBool(inputValue);
  };

  const handleChangeCheck = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedListUsers([...selectedListUsers, value]);
    } else {
      setSelectedListUsers(prev => {
        return prev.filter(user => user != value);
      });
    }
  }

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">

        {hideAgregarColaborador === false &&
          <div className="modal-contactanos modal-response-ficha-background" hidden={hideAgregarColaborador}>
            <AgregarColaborador showModal={showAgregarColaborador} />
          </div>
        }

        {hideAgregarResultados === false &&
          <div className="modal-contactanos modal-response-ficha-background" hidden={hideAgregarResultados}>
            <CargarResultados showModal={showAgregarResultado} />
          </div>
        }

        {hideProgramarPrueba === false &&
          <div className="modal-contactanos modal-response-ficha-background" hidden={hideProgramarPrueba}>
            <ProgramarPrueba showModal={showProgramarPrueba} selectedListUsers={selectedListUsers}  cleanSelectedUsers={() => setSelectedListUsers([])}/>
          </div>
        }

        {hideEditarProgramarPrueba === false &&
          <div className="modal-contactanos modal-response-ficha-background" hidden={hideEditarProgramarPrueba}>
            <EditarRegistro showModal={showEditarProgramarPrueba} idRegistro={idRegistroSeleccionado} />
          </div>
        }


        <Wrapper className='App'>
          <PageHeader title={"Gestión de programación de pruebas Covid"} />

          {loading === true &&
            <div className="spinner-cargando-container">
              <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
          }
          {loading === false &&
            <>
              <div className="generic-buscador-container">
                <div className="filtros-left-section">
                  <input
                    type='text'
                    className='generic-input-criterio-busqueda'
                    placeholder='Buscar por nombre'
                    value={criterioDeBusqueda}
                    onChange={(e) => {
                      setCriterioDeBusqueda(e.target.value);
                    }}
                    onKeyPress={handleKeypress}
                  />
                  <button
                    onClick={manageSearch}
                    type='button'
                    className='generic-button-buscador'>
                    Buscar
                  </button>
                  <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                  <button
                    onClick={() => setHideAgregarColaborador(false)}
                    type='button'
                    className='generic-button-buscador'>
                    Añadir Colab.
                  </button>
                  <button
                    onClick={() => setHideAgregarResultados(false)}
                    type='button'
                    className='generic-button-buscador'>
                    Cargar Resultado
                  </button>
                  <button
                    onClick={descargar}
                    type='button'
                    className='generic-button-buscador'>
                    Descargar
                  </button>
                </div>
              </div>
              <div className="generic-buscador-container">
                <div className="filtros-left-section">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                      Acciones
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => setHideProgramarPrueba(false)}>Programar examen Covid</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <div className='pt-2'>
                <div className="table-scroll">
                  <table className='table table-hover'>
                    <thead className="listar-thead">
                      <tr>
                        <th></th>
                        <th>
                          <div className='flex font-gothan-medium'>Nombre Completo</div>
                        </th>

                        <th>
                          <FormSelect
                            description={'Cargo'}
                            options={cargosFiltradasOptions}
                            setSelectedOptions={setCargosSeleccionadas}
                            selectedOptions={cargosSeleccionadas}
                          />
                        </th>

                        <th className="mb-0 pb-0">
                          <div className='flex font-gothan-medium'>Fecha Registro</div>
                          <div className="custom-control custom-switch">
                            <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                            <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                              Más reciente
                            </label>
                          </div>
                        </th>

                        <th>
                          <FormSelect
                            options={sedesFiltradasOptions}
                            description={'Sede'}
                            setSelectedOptions={setSedesSeleccionadas}
                            selectedOptions={sedesSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={divisionFiltradosOptions}
                            description={'División'}
                            setSelectedOptions={setDivisionSeleccionados}
                            selectedOptions={divisionSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={gerenciaFiltradasOptions}
                            description={'Gerencia'}
                            setSelectedOptions={setGerenciaSeleccionadas}
                            selectedOptions={gerenciaSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={areasFiltradasOptions}
                            description={'Área'}
                            setSelectedOptions={setAreaSeleccionados}
                            selectedOptions={areaSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={estadosFiltradosOptions}
                            description={'Estado'}
                            setSelectedOptions={setEstadosSeleccionados}
                            selectedOptions={estadosSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={resultadosFiltradasOptions}
                            description={'Resultado'}
                            setSelectedOptions={setEstadosResultadoSeleccionados}
                            selectedOptions={estadosResultadoSeleccionados}
                          />
                        </th>
                        <th>
                          <div className='flex font-gothan-medium'>Acciones</div>
                        </th>
                      </tr>
                    </thead>
                    <TableWebBodyProgramacionPruebas filas={filas} handleChangeCheck={handleChangeCheck} manageSearch={manageSearch} showEditar={showEditarProgramarPrueba} />
                  </table>
                </div>
                  {totalPages > 0 && (
                    <Paginator totalItems={totalItems}
                      firsItemPage={firsItemPage}
                      lastItemPage={lastItemPage}
                      totalPages={totalPages}
                      page={page}
                      onLeftClic={onLeftClic}
                      onRightClic={onRightClic} />
                  )}

              </div>
            </>
          }
        </Wrapper>
      </div>
    </>
  );
};

export default GestionProgramacionPruebasCovid;
