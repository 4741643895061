import React, { useState } from 'react'
import { rechazarSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls'
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import GenericModal from "../../../components/modal/GenericModal";

const ConfirmarRechazarVacaciones = ({ toggleModal, payloadRechazarVacaciones, refreshPage, setModalEnabled }) => {
  const SECTION_NAME = "RechazarVacaciones";
  const KEYS = ["MotivoDeRechazo"];
  const [submitForm] = useSubmitForm();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const api = rechazarSolicitudVacacionesAPI();
      await submitForm(KEYS, api, payloadRechazarVacaciones, SECTION_NAME, successOnSubmitCallback, true, callbackFailure)
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const  successOnSubmitCallback = async () => {
    await refreshPage();
    toggleModal();
    setModalEnabled(false);
  }

  const callbackFailure = async ()=> {
    toggleModal();
  }


  return (
    <GenericModal showModal={toggleModal} width="500px" titulo={`Confirmación`}>
    <div className="modal-body pt-0 pl-0">
      <div className="container-fluid">
        ¿Está seguro de esta acción?
      </div>
    </div>
    <div className="modal-footer d-flex justify-content-end">
      <div className="button">
        <button
          className="btn btn-primary generic-button-ficha mr-2"
          onClick={toggleModal}
          disabled={isLoading}
        >
          Cancelar
        </button>
        <button
          className="btn btn-primary generic-button-ficha"
          onClick={onSubmit}
          disabled={isLoading}
        >
          Confirmar
        </button>
      </div>
    </div>
  </GenericModal>
  )
}

export default ConfirmarRechazarVacaciones
