import React, { useEffect, useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText'
import InputSelect from '../../../../components/modalComponents/InputSelect'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading'
import InputSelectCascade from '../../../../components/modalComponents/InputSelectCascade'
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsSelect } from '../../../../hooks/useProps/usePropsSelect'
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput'
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import { CHATBOT_FAQ_TYPES } from '../../../../constants/hardCodedConstants'
import { initialAllOptionPromises } from '../../../../helper/utils'
import { crearChatbotFaqAdminAPI, getQuestionsForEditAndCreateAsDropdownChatbotFaqAPI, getTiposChatbotFaqAPI } from '../../../../consumers/backendApisUrls'

const CrearPreguntaRespuesta = ({ toggleModal, refreshPage }) => {

  const KEYS = { idParent: "IdParent", idType: "IdType", text: "Text", url: "Url" };
  const SECTION_NAME = "CrearPreguntaRespuestaChatbotFaq";

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();

  const initialPromises = () => {
    return [
      getOptions(getQuestionsForEditAndCreateAsDropdownChatbotFaqAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(KEYS), setAllDropdowns);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearChatbotFaqAdminAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { IdParent, ...all } = formValues;
    return IdParent ? { IdParent, ...all } : all;
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm titulo="Crear nuevo" showModal={toggleModal} width="650px" isSubmitting={isSubmitting} onSubmit={handleSubmit}>
      <InputSelect label='Padre' direction='column' {...selectProps(KEYS.idParent, [KEYS.idType])} isRequired/>
      <InputSelectCascade label='Tipo' dependendOption={formValues[KEYS.idParent]} api={getTiposChatbotFaqAPI} direction='column' {...commonProps(KEYS.idType)} isRequired/>
      <InputTextArea label='Texto' direction='column' {...commonProps(KEYS.text)} isRequired/>
      {(formValues[KEYS.idType] === CHATBOT_FAQ_TYPES.respuesta) &&
        <InputText label='Url' direction='column' {...commonProps(KEYS.url)}/>
      }
    </ModalForm>
  )
}

export default CrearPreguntaRespuesta