import styled from 'styled-components';

const DirectorioWrapper = styled.div`
  @media (max-width: 992px) {
    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      padding: 10px;
    }
    .container-fluid .body-content {
      margin-top: 10px;
    }
    .header-table-mob {
      display: block !important;
      background: #ffffff;
      padding: 20px;
      border-radius: 5px;
      margin: 15px 0;
      .title-body {
        font-size: 0.875rem;
        color: #0063a1;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
  .header-table-mob {
    display: none;
  }
 
  .container-fluid .body-content {
    margin-top: 25px;
  }
  .flex {
    display: flex;
    align-items: center;
    height: 100%;
    color: #565656;
  }
  input:disabled{
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
`;

export default DirectorioWrapper;
