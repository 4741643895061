import React, { useState } from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { testProcesarLiquidacionAPI } from '../../../consumers/backendApisUrls';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const TestEmail = ({ toggleModal, idEmpleadoLiquidaciones }) => {
  const sectionName = "modalTestEmail";
  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();

  const successSubmitCallback = function () {
    toggleModal();
  }

  const armarPayload = () => {
    return {
      "CorreoPrueba": formValues.CorreoPrueba,
      "IdConfiguracionLiquidaciones": idEmpleadoLiquidaciones
    }
  }

  const submit = async () => {
    let payload = armarPayload();
    let api = testProcesarLiquidacionAPI();
    let keys = ["CorreoPrueba", "IdConfiguracionLiquidaciones"];

    await submitForm(keys, api, payload, sectionName, successSubmitCallback)
  };

  return (
    <>
      <GenericModal showModal={toggleModal} width={"800px"} titulo={"Correo de prueba"}>
        <div className="modal-body pt-0 pl-0">
          <form>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Correo<span className="required">*</span></label>
              <div className="col-sm-9 black-color">
                <input type="text" className="form-control" name="CorreoPrueba" onChange={handleChange} value={formValues?.CorreoPrueba ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-CorreoPrueba-validation-message`}></p>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Enviar</button>
          </div>
        </div>
      </GenericModal>
    </>
  )
}

export default TestEmail;
