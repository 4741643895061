import React, { useState } from 'react'
import styled from 'styled-components';
import DotLoading from '../../components/atoms/DotLoading';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { enviarReporteRankmiAPI } from '../../consumers/backendApisUrls';

const ReporteRankmi = () => {

  const id = "SeccionReportesRankmiColaboradoresActivos"
  const message = "Este reporte contiene a los Colaboradores activos de Innova Schools"

  const [isLoading, setIsLoading] = useState(false);
  const [executePost] = useManagePostRequest();

  const handleDownload = async () => {
    setIsLoading(true);
    const api = enviarReporteRankmiAPI();
    try {
      await executePost(api, {}, ()=>{});
    } catch (error) {
      console.error(error)  ;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='card-body' id={id}>
      <Message>{message}</Message>
      <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={handleDownload} disabled={isLoading}>
        {isLoading
          ? <Loading>Enviando Reporte <DotLoading/></Loading>
          : <Loading>Enviar Reporte</Loading>
        }
      </button>
    </div>
  )
}

export default ReporteRankmi;

const Message = styled.p`
  font-size: 14px;
  color: #5b5b5b;
  font-family: "GothamLight", system-iu, sans-serif;
`

const Loading = styled.div`
  display: flex;
  align-items: center;
`