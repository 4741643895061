import React, {useState, useContext} from 'react';
import { useEffect } from 'react';
import { getTiposInstruccionAPI } from '../../../consumers/backendApisUrls';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { Spinner } from "react-bootstrap";
import { agregarTipoInstruccionAPI, getDataEmpleadoAPI } from '../../../consumers/backendApisUrls';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { AuthContext } from '../../../hooks/context/authContext';

const TipoInstruccion = ({empleadoId, detalleEmpleado, puedoEditar}) => {
    const { state } = useContext(AuthContext);
    const [idActualizado, setId] = useState();

    const [allTipoInstruccion, setAllTipoIntruccion] = useState([]);
    const [getOptions] = useGetOptionsSelect();
    const sectionName = "FormacionAcademica";
    
    const [tipoInstruccionSeleccionado, setTipoInstruccionSeleccionado] = useState();
    const [submitForm] = useSubmitForm();
    const [executeGet] = useGetRequest();

    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    function updateId() {
        if (empleadoId) setId(empleadoId);
        else setId(state.user.id_empleado);
      }

    const getInitialData = async () => {
        setLoading(true);
        try {
            if(idActualizado){
                await getDropdownOptions();
            }
        } catch (error) {
            console.error(error)
        }
        setLoading(false);
    }

    const getDropdownOptions = async () => {
        setAllTipoIntruccion(await getOptions(getTiposInstruccionAPI()));
    }

    const successCallback = async () => {
        let response = await executeGet(getDataEmpleadoAPI(empleadoId));
        setTipoInstruccionSeleccionado(response.data.IdTipoInstruccion);
    }

    const onSubmit = async (event) => {
        try {
            event.preventDefault();
            setButtonDisabled(true);
            let payload = armarPayload();
            let api = agregarTipoInstruccionAPI();
            let keys = ["TipoInstruccion"];
            await submitForm(keys, api, payload, sectionName, successCallback);

            setButtonDisabled(false);
        } catch (error) {
            console.error(error);
        }
    }

    const armarPayload = () => {
        return {
            IdEmpleado: empleadoId,
            IdTipoInstruccion: tipoInstruccionSeleccionado
        }
    }

    const changeIseditable = () => {
        setButtonDisabled(!buttonDisabled);
    }

    useDidMountEffect(() => {
        updateId();
      }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        getInitialData();
      }, [idActualizado]);

    useEffect(() => {
        setTipoInstruccionSeleccionado(detalleEmpleado?.IdTipoInstruccion)
    }, [detalleEmpleado])

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        )
    }

  return (
    <div className="card-body">
        <form id='frmEditTipoInstruccion' onSubmit={onSubmit}>
            <div className='d-flex justify-content-between'>
                <div className="d-flex" id='ficha-edit-inputGroup-tipoInstruccion'>
                    <div className='col-5 mt-2'>
                        <label className='ficha-edit-form-label'>Tipo de Instrucción</label>
                    </div>
                    <div className='col-8 d-flex flex-column justify-content-center'>
                        <select defaultValue={tipoInstruccionSeleccionado} value={tipoInstruccionSeleccionado} 
                                onChange={e => setTipoInstruccionSeleccionado(parseInt(e.target.value))} 
                                className='form-control' name='IdTipoInstruccion' id='ficha-edit-tipoInstruccion'
                                disabled={!buttonDisabled || !puedoEditar}>
                            <option selected disabled>Seleccionar</option>
                            {
                                allTipoInstruccion.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))
                            }
                        </select>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-TipoInstruccion-validation-message`}></p>
                    </div>
                </div>


                <div>
                    {!buttonDisabled &&
                        <button type='button' onClick={changeIseditable} className="btn btn-primary generic-button-buscador mb-3 mr-3">
                            Editar
                        </button>
                    }
                    <button
                        type="submit"
                        className='btn btn-primary generic-button-buscador mb-3 mr-3'
                        disabled={!buttonDisabled || !puedoEditar}
                    >
                        Guardar
                    </button>
                </div>
                

            </div>
        </form>
    </div>
  )
}

export default TipoInstruccion
