import React, { useEffect, useState } from 'react'
import { handleFind, jsonEstaVacio } from '../../helper/utils';
import InputDate from '../../components/modalComponents/InputDate';
import InputSelect from '../../components/modalComponents/InputSelect';
import InputSearchAsync from '../../components/modalComponents/InputSearchAsync';
import { useForm } from '../../hooks/useForm/UseForm';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../hooks/useProps/usePropsInput';
import { useGetOptionsSelect } from '../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useDescargarArchivoYEliminarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3';
import { buscarColaboradorAPI, downloadReportePeriodoPlanillaAPI, getPeriodosPlanillaActivosAPI } from '../../consumers/backendApisUrls';

const ReporteVacacionesPlanilla = () => {
  
  const SECTION_NAME = "ReporteDeVacacionesPorPeriodoPlanilla";
  const KEYS = {colaborador: "IdColaborador", desde: "FechaInicio", hasta: "FechaFin", periodoPlanilla: "PeriodoPlanilla" };

  const [typeSelected, setTypeSelected] = useState({});
  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [downloadAndDeleteFile] = useDescargarArchivoYEliminarArchivoDeS3();
  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleTypeSelect = ({ target }) => {
    setTypeSelected(target.value);
    setFormValues({});
  }

  const handleDownloadReport = async () => {
    setIsDownloading(true);
    try {
      const api = downloadReportePeriodoPlanillaAPI();
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successDownload);
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  }

  const getPayload = () => {
    const name = typeSelected?.filter;
    const payload = {...formValues};
    if (name !== undefined || name !== null){
      payload[name] = true;
    }
    return payload;
  }

  const successDownload = async ({ data }) => {
    const { OriginalFileName, FilePath } = data;
    await downloadAndDeleteFile(OriginalFileName, FilePath);
  }

  const getDropdowns = async () => {
    const api = getPeriodosPlanillaActivosAPI();
    const allPlantillas = await getOptions(api);
    setAllDropdowns(allPlantillas);
  }

  useDidMountEffect(() => {
    if (typeSelected?.value === option3.value) {
      getDropdowns();
    }
  }, [typeSelected?.value])

  return (
    <div className='card-body'>
      <div id={SECTION_NAME}>
        <div className="col-sm-5">
          <InputSelect label='Selecciona un criterio de búsqueda' name='typeSelected' options={TYPES} direction='column' value={jsonEstaVacio(typeSelected) ? null : typeSelected} onChange={handleTypeSelect} sectionName={SECTION_NAME}/>  
        </div>
        {typeSelected?.value === option1.value &&
          <DIV>
            <InputSearchAsync isRequired typeGet label="Nombre" api={buscarColaboradorAPI} {...commonProps(KEYS.colaborador)}/>
          </DIV>
        }
        {typeSelected?.value === option2.value &&
          <DIV>
            <InputDate isRequired label="Desde" {...commonProps(KEYS.desde)}/>
            <InputDate isRequired label="Hasta" {...commonProps(KEYS.hasta)}/>
          </DIV>
        }
        {typeSelected?.value === option3.value &&
          <DIV>
            <InputSelect isRequired label="Periodo" options={allDropdowns} name={KEYS.periodoPlanilla} value={allDropdowns.find(o => o.value === formValues[KEYS.periodoPlanilla])} onChange={({ target }) => setFormValues(lastData => ({...lastData, [target.name]: target.value?.value}))} sectionName={SECTION_NAME} />
          </DIV>
        }
        <div className='col-sm-5'>
          <button className="btn btn-primary generic-button-ficha" onClick={handleDownloadReport} disabled={isDownloading}>
            {isDownloading ? 'Descargando Reporte ...' : 'Descargar Reporte'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReporteVacacionesPlanilla;

const option1 = {value: 1, label: "Por colaborador", filter: "EsColaboradorFiltro"};
const option2 = {value: 2, label: "Por rango de fechas de aprobación solicitud", filter: "EsFechasFiltro"};
const option3 = {value: 3, label: "Por periodo de planillas", filter: "EsPeriodoPlanillaFiltro"};

const TYPES = [option1, option2, option3]

const DIV = ({ children }) => (
  <div className='col-sm-5'>
    {children}
  </div>
)