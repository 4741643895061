import React, { useState }  from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput'
import { rechazarSolicitudTaiLoyAPI } from '../../../../consumers/backendApisUrls'

const RechazoSolicitudModal = ({ toggleModal, refreshPage, IdSolicitudTaiLoy }) => {

  const KEYS = {motivo: "MotivoRechazo"}
  const SECTION_NAME = "RechazarSolicitudDescuentoVale";

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = rechazarSolicitudTaiLoyAPI();
    const payload = { IdSolicitudTaiLoy, ...formValues };
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmit)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmit = async () => {
    await refreshPage();
    toggleModal();
  }

  return (
    <ModalForm onSubmit={handleSubmit} titulo='Rechazar Solicitud' showModal={toggleModal} label='Rechazar' isSubmitting={isSubmitting} width='600px'>
      <InputTextArea label='Motivo Rechazo' {...commonProps(KEYS.motivo)} direction='column' maxLength={250}/>
    </ModalForm>
  )
}

export default RechazoSolicitudModal