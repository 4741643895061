import React, { useState } from "react";
import ModalForm from "../../../../components/modalComponents/ModalForm";
import PrePostNatalComponent from "../components/PrePostNatal";
import { guardarPrePostNatalAPI } from "../../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../../hooks/useSubmitForm/useSubmitForm";
import { usePropsInputs } from "../../../../hooks/useProps/usePropsInput";

const PrePostNatal = ({ IdCondicion, toggleModal, refreshPage }) => {
  const SECTION_NAME = "RegistrarPrePostNatal";
  const KEYS = {
    fechaInicio: "FechaInicioPrePostNatal",
    fechaFin: "FechaFinPrePostNatal",
    sustento: "PrePostNatalFileViewModel",
  };
  const [formValues, setFormValues] = useState({ IdCondicion: IdCondicion});
  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = guardarPrePostNatalAPI();
    try {
      await submitForm(
        Object.values(KEYS),
        api,
        formValues,
        SECTION_NAME,
        handleSuccessSubmit
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSuccessSubmit = () => {
    refreshPage();
    toggleModal();
  };

  return (
    <ModalForm
      titulo="Pre/Post Natal"
      width="850px"
      showModal={toggleModal}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}>
      <PrePostNatalComponent
        formValues={formValues}
        setFormValues={setFormValues}
        isUploading={isSubmitting}
        setIsUploading={setIsSubmitting}
        sectionName={SECTION_NAME}
        commomProps={commomProps}
        keys={KEYS}
        isEditable={true}
      />
    </ModalForm>
  );
};

export default PrePostNatal;