import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditarPerfilPuesto from './modales/EditarPerfilPuesto'
import ModalActivarInactivarGeneric from '../../../components/modalActivarInactivar/ModalActivarInactivarGeneric'
import { activarIncativarPerfilPuestoAPI } from '../../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled, manageSearch }) => {

  const handleActivarInactivar = (fila) => {
    setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
      data: {
        api: activarIncativarPerfilPuestoAPI, 
        id: fila.IdPerfilPuesto, 
        title: "Perfil de puesto", 
        name: fila.NombrePerfilPuesto, 
        state: fila.EsActivo
      }
    })
  }

  return (
    <tbody className="listar-tbody">
      {filas?.map((fila,i)=> (
        <tr key={`${i}-${fila.IdPerfilPuesto}`}>
          <td className="">{fila?.NombrePerfilPuesto}</td>
          <td className="">{fila?.Cargo}</td>
          <td className="text-center">{fila?.Estado}</td>
          <td className="d-flex justify-content-center">
            <div className="d-flex justify-content-start">
              <div>
                <FontAwesomeIcon
                  icon={fila.EsActivo ? 'ban' : 'circle-notch'}
                  className="hand-pointer ml-3"
                  title={fila.EsActivo ? 'Inactivar': 'Activar'}
                  onClick={() => handleActivarInactivar(fila)}
                />
              </div>
              {fila.EsActivo &&
                <div>
                  <FontAwesomeIcon 
                    icon='edit'
                    size='sm'
                    className="hand-pointer ml-3"
                    title="Editar"
                    onClick={() => setModalEnabled({isEnable: true, component: EditarPerfilPuesto, 
                            data: {idPerfilPuesto: fila.IdPerfilPuesto}})}
                  />
                </div>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody