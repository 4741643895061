import React, { useState, useEffect, useRef } from 'react';
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputText from '../../../../components/modalComponents/InputText';
import InputSelect from '../../../../components/modalComponents/InputSelect';
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { getEditarConfiguracionDocumentoAPI, saveEditarConfiguracionDocumentoAPI, getOrigenDocumentoAPI, getPlantillasAPI, getNuevosIngresosPasosObligatoriosOptionsAPI } from '../../../../consumers/backendApisUrls';

const EditarDocumento = ({ toggleModal, refreshPage, documentoId }) => {
  const isMounted = useRef(true);
  const SECTION_NAME = "EditarNuevoDocumento";
  const KEYS = { DocumentName: "NombreDocumento", Origin: "IdOrigen", Template: "IdPlantilla", File: "File", PasosObligatorios: "IdNuevosIngresosPasoObligatorio"};
  const SHOW_OPTIONS = {Plantilla: {id: 1, label: KEYS.Template, component: InputSelect}, Formato: {id: 2, label: KEYS.File, component: InputUploadFile}}
  
  const [formValues, setFormValues] = useState({});
  const [allOrigins, setAllOrigins] = useState([]);
  const [allTemplates, setAllTemplates] = useState([]);
  const [allPasos, setAllPasos] = useState([]);
  const [componentsSelected, setComponentsSelected] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    try {
      let options = await getOptions(getOrigenDocumentoAPI());
      setAllOrigins(options);
      let pasos = await getOptions(getNuevosIngresosPasosObligatoriosOptionsAPI());
      setAllPasos(pasos);
      executeGet(getEditarConfiguracionDocumentoAPI(documentoId), (res) => successInitCallback(res.data, options, pasos));
    } catch (error) {
      console.error(error);
    }
  }

  const successInitCallback = (res, options, pasos) => {
    setFormValues({
      ...res,
      [KEYS.Origin]: options.find(o => o.value === res[KEYS.Origin]),
      [KEYS.PasosObligatorios]: pasos.find(o => o.value === res[KEYS.PasosObligatorios]),
      [KEYS.Template]: {value: res[KEYS.Template]}
    });
  }

  const submit = async (e) => {
    e.preventDefault();
    const api = saveEditarConfiguracionDocumentoAPI()
    let payload = getPayload();
    setButtonDisabled(true);
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setButtonDisabled(false))
  };

  const getPayload = () => {
    let basicStates = {
      IdNuevosIngresosDocumentos: documentoId,
      [KEYS.DocumentName]: formValues[KEYS.DocumentName],
      [KEYS.Origin]: formValues[KEYS.Origin].value,
      [KEYS.PasosObligatorios]: formValues[KEYS.PasosObligatorios]?.value
    };
    
    if (componentsSelected.label){
      let newStates = {[componentsSelected.label]: 
        componentsSelected.label === KEYS.Template ? formValues[componentsSelected.label]?.value ?? "" : formValues[componentsSelected.label]}
      return {...basicStates, ...newStates}
    }
    return basicStates;
  }

  const successSubmitCallback = function () {
    setButtonDisabled(false);
    refreshPage();
    toggleModal();
  }

  const getSelectedData = async () => {
    if ( formValues[KEYS.Origin]?.value === SHOW_OPTIONS.Plantilla.id){
      let templates = !allTemplates.length ? await getOptions(getPlantillasAPI()) : allTemplates;
      setAllTemplates(templates);
      return {label: "Plantilla", options: templates, value: templates.find(o => o.value == formValues[KEYS.Template]?.value), onChange: handleChange, name: KEYS.Template, sectionName: SECTION_NAME, isRequired: true}
    } else if (formValues[KEYS.Origin].value == SHOW_OPTIONS.Formato.id) {
      return {label: "Archivo", uploadFile: formValues[KEYS.File], setUploadFile: (res) => setFormValues(lastData => ({...lastData, [KEYS.File]: res})), id:"agregarFormatoTemplate", validExtensions:["pdf"], sectionName: SECTION_NAME, name: KEYS.File, isRequired: true}
    }
    return {};
  }

  const findComponent = () => {
    return Object.values(SHOW_OPTIONS).find(o => o.id === formValues[KEYS.Origin]?.value)
  }
  
  useDidMountEffect( async () => {
    let data = await getSelectedData();
    let componentSelected = findComponent();
    setComponentsSelected({...componentSelected, data});
  }, [formValues[KEYS.Origin], formValues[KEYS.File], formValues[KEYS.Template]])

  useEffect(() => {
    if (isMounted) init();

    return () => {
      isMounted.current=false;
    }
  }, [])

  return (
    <ModalForm onSubmit={submit} showModal={toggleModal} width="700px" titulo="Nuevo documento" saveButtonDisabled={buttonDisabled}>
      <InputText label="Nombre de documento" value={formValues[KEYS.DocumentName]} onChange={handleChange} name={KEYS.DocumentName} sectionName={SECTION_NAME} isRequired/>
      <InputSelect label="Origen del documento" options={allOrigins} value={formValues[KEYS.Origin]} onChange={handleChange} name={KEYS.Origin} sectionName={SECTION_NAME} isRequired/>
      <InputSelect label="Paso Obligatorio" options={allPasos} value={formValues[KEYS.PasosObligatorios]} onChange={handleChange} name={KEYS.PasosObligatorios} sectionName={SECTION_NAME} isRequired/>
      {componentsSelected?.id && 
        <componentsSelected.component key={componentsSelected.data.label} {...componentsSelected.data}/>
      }
    </ModalForm>
  )
}

export default EditarDocumento