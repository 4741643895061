import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';

const MultiSelectInput = ({ options, selectedValues, setSelectedValues, isDisabled, placeholder, isMulti = false, filas, id, name, array = false }) => {

    const handleSelectInputChange = (e) => {
        const newInputFields = filas.map(fila => {
            if (id == fila.IdProcesoCierrePlantillasConfig) {
                if (isMulti) {
                    fila[name] = e.map(v => ({
                        "IdProcesoCierrePlantillasConfig": fila.IdProcesoCierrePlantillasConfig,
                        [`Id${name}`]: v.value
                    }))
                } else {
                    if (array) {
                        fila[name] = !e ? []
                            : [{
                                "IdProcesoCierrePlantillasConfig": fila.IdProcesoCierrePlantillasConfig,
                                [`Id${name}`]: e?.value
                            }]
                    } else {
                        fila[name] = e?.value;
                    }
                }
            }
            return fila;
        });
        setSelectedValues(newInputFields);
    };
    let filaActual = filas.find(({ IdProcesoCierrePlantillasConfig }) => IdProcesoCierrePlantillasConfig === id);
    if (isMulti && filaActual[name]?.map(x => x[`Id${name}`]).find(x => x == 9999) != undefined) {
        options = options.filter(o => o.value == 9999);
    }
    return (
        <>
            <div style={{ width: "260px" }}>
                <Select
                    placeholder={placeholder}
                    value={Array.isArray(selectedValues) ? options.filter(obj => selectedValues.includes(obj.value)) : options.filter(obj => obj.value == selectedValues)}
                    options={options}
                    onChange={handleSelectInputChange}
                    isMulti={isMulti}
                    isClearable
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
}

const TableWebBody = ({
    filas,
    setFilas,
    editar,
    setIdEliminar,
    toggleEliminarPlantillaModal,
    allCargos,
    allTiposContrato,
    allCondiciones,
    allTiposEmpleado,
    allPlantillas,
    handleChangeInput,
    onSubmit,
    cancelarSubmit
}) => {
    return (
        <tbody className="listar-tbody">
            {filas && filas.map((fila) => (
                <tr key={fila.IdProcesoCierrePlantillasConfig} style={{ 'marginBottom': '30px' }}>
                    {!fila.EstadoEditar ?
                        <td className="column mr-1">
                            <div style={{ width: "200px" }}>
                                {fila.Plantilla}
                            </div>
                        </td> :
                        <td className="column mr-1">
                            <MultiSelectInput options={allPlantillas} selectedValues={fila.IdPlantilla} isDisabled={!fila.EstadoEditar}
                                setSelectedValues={setFilas} placeholder={"Seleccionar"} filas={filas} id={fila.IdProcesoCierrePlantillasConfig} name={"IdPlantilla"}
                            />
                            <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-IdPlantilla-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                        </td>
                    }
                    <td className="column mr-1" >
                        <MultiSelectInput options={allCargos} selectedValues={fila.Cargo?.map(cargo => cargo.IdCargo)} isMulti={true} isDisabled={!fila.EstadoEditar}
                            setSelectedValues={setFilas} placeholder={"Seleccionar"} filas={filas} id={fila.IdProcesoCierrePlantillasConfig} name={"Cargo"}
                        />
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-Cargo-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <MultiSelectInput options={allTiposContrato} selectedValues={fila.TipoContrato?.map(tipoContrato => tipoContrato.IdTipoContrato)} isMulti={true} isDisabled={!fila.EstadoEditar}
                            setSelectedValues={setFilas} placeholder={"Seleccionar"} filas={filas} id={fila.IdProcesoCierrePlantillasConfig} name={"TipoContrato"}
                        />
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-TipoContrato-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <MultiSelectInput options={allCondiciones} selectedValues={fila.Condicion?.map(condicion => condicion.IdCondicion)} isMulti={true} isDisabled={!fila.EstadoEditar}
                            setSelectedValues={setFilas} placeholder={"Seleccionar"} filas={filas} id={fila.IdProcesoCierrePlantillasConfig} name={"Condicion"}
                        />
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-Condicion-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <MultiSelectInput options={allTiposEmpleado} selectedValues={fila.TipoEmpleado?.map(tipoEmpleado => tipoEmpleado.IdTipoEmpleado)} isMulti={true} isDisabled={!fila.EstadoEditar}
                            setSelectedValues={setFilas} placeholder={"Seleccionar"} filas={filas} id={fila.IdProcesoCierrePlantillasConfig} name={"TipoEmpleado"}
                        />
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-TipoEmpleado-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <div className='d-flex justify-content-center'>
                            <input className="form-control" disabled value={fila.VacacionesDiasCount} name="VacacionesDias" style={{ width: "100px" }}></input>
                        </div>
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-VacacionesDias-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <div className="d-flex flex-column justify-content-center">
                            <div className='mr-1'>
                                <input type="date" disabled={!fila.EstadoEditar ? "disabled" : ""} className="form-control" value={fila.VacacionesPeriodoInicio} name="VacacionesPeriodoInicio" onChange={event => handleChangeInput(event, fila.IdProcesoCierrePlantillasConfig, setFilas, filas)} style={{ width: "150px", fontSize: "14px" }}></input>
                                <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-VacacionesPeriodoInicio-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                            </div>
                            <div>
                                <input type="date" disabled={!fila.EstadoEditar ? "disabled" : ""} className="form-control" value={fila.VacacionesPeriodoFin} name="VacacionesPeriodoFin" onChange={event => handleChangeInput(event, fila.IdProcesoCierrePlantillasConfig, setFilas, filas)} style={{ width: "150px", fontSize: "14px" }}></input>
                                <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-VacacionesPeriodoFin-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                            </div>
                        </div>
                    </td>
                    <td className="column mr-1">
                        <div className='d-flex justify-content-center'>
                            <input className="form-control" disabled value={fila.CompensacionesDiasCount} name="CompensacionDias" style={{ width: "100px" }}></input>
                        </div>
                        <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-CompensacionDias-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                    </td>
                    <td className="column mr-1">
                        <div className="d-flex flex-column justify-content-center">
                            <div className='mr-1'>
                                <input type="date" disabled={!fila.EstadoEditar ? "disabled" : ""} className="form-control" value={fila.CompensacionesPeriodoInicio} name="CompensacionesPeriodoInicio" onChange={event => handleChangeInput(event, fila.IdProcesoCierrePlantillasConfig, setFilas, filas)} style={{ width: "150px", fontSize: "14px" }}></input>
                                <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-CompensacionesPeriodoInicio-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                            </div>
                            <div>
                                <input type="date" disabled={!fila.EstadoEditar ? "disabled" : ""} className="form-control" value={fila.CompensacionesPeriodoFin} name="CompensacionesPeriodoFin" onChange={event => handleChangeInput(event, fila.IdProcesoCierrePlantillasConfig, setFilas, filas)} style={{ width: "150px", fontSize: "14px" }}></input>
                                <p className="ficha-form-input-validation-message" id={"asignar-proceso-validation-message-CompensacionesPeriodoFin-row-" + fila.IdProcesoCierrePlantillasConfig}></p>
                            </div>
                        </div>
                    </td>
                    <td className="column mr-1">
                        <div className="d-flex justify-content-center">
                            {!fila.EstadoEditar ?
                                <>
                                    <FontAwesomeIcon icon="edit" size="sm" className="hand-pointer mr-3" title="Editar" onClick={() => editar(fila)} />
                                    <FontAwesomeIcon icon="trash" size="sm" className="hand-pointer" title="Eliminar" onClick={() => { setIdEliminar({ "id": fila.IdProcesoCierrePlantillasConfig, "title": fila.Plantilla }); toggleEliminarPlantillaModal() }} />
                                </> :
                                <>
                                    <FontAwesomeIcon icon="save" size="sm" className="hand-pointer mr-3" title="Guardar" onClick={(e) => onSubmit(fila.IdProcesoCierrePlantillasConfig)} />
                                    <FontAwesomeIcon icon="times" size="sm" className="hand-pointer" title="Cancelar" onClick={() => cancelarSubmit(fila.IdProcesoCierrePlantillasConfig)} />
                                </>

                            }
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableWebBody