import React from 'react';
import GenericModal from "../../../components/modal/GenericModal";

const ConsultarModificarFechaCumpleanios = ({showModal, respuestaConsultar}) => {
    const submitModalRechazarModal = async (siModificar) => {
        respuestaConsultar(siModificar);
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"900px"} titulo={"Modificar fecha de cumpleaños"}>
                <div className="modal-body text-center">
                    <div className="row text-center justify-content-center align-items-center">
                        <img width={150} height={150} src={require('../../../image/calendar.png')}/>
                    </div>
                    <label className="ficha-info-label-width py-5"> ¿Desea que su fecha de nacimiento sea la misma que la fecha de cumpleaños?</label>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary ficha-form-button-cancelar" onClick={() => submitModalRechazarModal(false)}>No, Cancelar</button>
                        </div>
                        <div className="col">
                            <button className="btn btn-primary generic-button-ficha" onClick={() => submitModalRechazarModal(true)}>Aceptar</button>
                        </div>
                    </div>
                </div>

            </GenericModal>
        </>
    );
};

export default ConsultarModificarFechaCumpleanios;

