import React, {useContext, useEffect, useState} from 'react';
import Select from 'react-select'
import styled from 'styled-components';
import {toast} from "react-toastify";
import ReactTooltip from "react-tooltip";
import {v4 as uuidv4} from 'uuid';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {downloadFile,  uploadFile} from "../../consumers/backendConsumer";
import {showToastErrorMaxFileSize} from "../../components/alerts/AlertErrorMaxFileSize";
import CustomAlert from "../../components/alerts/CustomAlert";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {AuthContext} from "../../hooks/context/authContext";
import { clearValidationMessage } from '../../validations/FormValidatorUtils';
import { declaracionJuradaAsignacionFamiliar } from '../../constants/messages';
import {getDatosFamiliaresAPI, getDepartamentosAPI, getDistritosPorProvinciaAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, guardarDatosFamiliaresAPI} from "../../consumers/backendApisUrls";
import {DIACRITIC_MAP, ESTADOS_YES_NO, EXTENSIONES_VALIDAS_DE_ARCHIVOS, KEYS_DATOS_FAMILIARES, PAISES, TIPOS_VINCULOS} from "../../constants/hardCodedConstants";
import {extensionYtamañoDeArchivoEsValido, getFormattedName, jsonEstaVacio, jsonNoEstaVacio, obtenerArchivoDeEvent, resetForm, responseUploadFileSuccess, scrollToElement} from "../../helper/utils";

const SECTION_NAME = "FichaDatosFamiliares";
const KEYS = {declaraInfoFamiliar: "DeclaraInformacionFamiliar", aceptaDJ: "AceptaDeclaracionJurada"};
const KEYS_TABLE = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", numeroDocumento: "NumeroDocumento", idGenero: "IdGenero", nombres: "Nombres",  apellidoPaterno: "ApellidoPaterno", apellidoMaterno: "ApellidoMaterno", fechaNacimiento: "FechaNacimiento", fileViewModel: "FileViewModel" };
const KEYS_NACIMIENTO = { idPaisNacimiento: "IdPaisNacimiento", idDepartamento: "IdDepartamento", idProvincia: "IdProvincia", idDistrito: "IdDistrito", ciudadNacimiento: "CiudadNacimiento" };

const DatosFamiliares = ({empleadoId, showModal, showSolicitarCambiosModal, reloadSeccionesDatosFamiliares}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [familiares, setFamiliares] = useState([]);
    const [aceptaDJ, setAceptaDJ] = useState(false);

    const [declaraInformacionFamiliar, setDeclaraInformacionFamiliar] = useState(false);

    const [vinculos, setVinculos] = useState([]);
    const [tiposDocumento, setTiposDocumento] = useState([]);
    const [genero, setGenero] = useState([]);
    const [location, setLocation] = useState({ paises: [], departamentos: [] });
    const [filas, setFilas] = useState([]);

    const firstGeneralJsonOptionSelect = {"Value": 0, "Text": "Seleccionar"};
    const APELLIDOS_KEY = ["ApellidoPaterno", "ApellidoMaterno"];
    const IDS_NAME_KEY = ["IdPersona", "IdVinculo", "IdTipoDocumento", "IdGenero", "IdPaisNacimiento", "IdDepartamento", "IdProvincia", "IdDistrito"];
    const MessageTilde = "Para poder completar el registro, los apellidos deben estar sin tilde.";

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);

    const [submitForm] = useSubmitForm();
    const [executeGetRequest] = useGetRequest();
    const [executeManageGetRequest] = useManageGetRequest();

    function updateId() {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (filas.length === 0) {
            addFilaToList();
        }
    }, [filas]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        if (!location.paises.length && filas.some(x => x.IdVinculo === TIPOS_VINCULOS.Hijo.id)){
            handleGetPaises();
        }
        if (!location.departamentos.length && location.paises.length){
            handleGetDepartamentos();
        }
    }, [filas, location.paises])

    const handleGetPaises = async () => {
        await executeManageGetRequest(getPaisesAPI(), ({ data }) => setLocation({...location, paises: data}));
    }

    const handleGetDepartamentos = async () => {
        await executeManageGetRequest(getDepartamentosAPI(), ({ data }) => setLocation({...location, departamentos: data}));
    }

    const updateComponentStates = async () => {
        try {
            if (idActualizado) {
                const response = await executeGetRequest(getDatosFamiliaresAPI(idActualizado));
                if (responseCode200(response)) {
                    setFamiliares(response.data.data.Familiares);
                    // dropdowns
                    setVinculos([firstGeneralJsonOptionSelect, ...response.data.dropdowns.Vinculos]);
                    setTiposDocumento([firstGeneralJsonOptionSelect, ...response.data.dropdowns.TiposDocumento]);
                    setGenero([firstGeneralJsonOptionSelect, ...response.data.dropdowns.Genero]);

                    let familiares = response.data.data.Familiares;
                    let filas = familiares.map((fila) => {
                        handleTiposVinculosLogic(fila, fila.IdVinculo);
                        return { ...fila, hasPrevFile: true };
                    });
                    setFilas(filas);
                    setDeclaraInformacionFamiliar(response.data.data.DeclaraInformacionFamiliar);
                    setAceptaDJ(false);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const actualizar = () => {
        try {
            document.getElementById("frmInfoDatosFamiliares").hidden = true;
            document.getElementById("frmEditDatosFamiliares").hidden = false;
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (declaraInformacionFamiliar === null || declaraInformacionFamiliar === undefined){
            return toast.warn("Debe seleccionar si tiene familiares que declarar")
        }
        
        cleanErrorMessages();
        // const familiaresFaltanArchivo = familiaresSinArchivo();
        // if (familiaresFaltanArchivo.length) {
        //     return toast.warn(`Falta adjuntar archivos`);
        // }

        const api = guardarDatosFamiliaresAPI();
        const payload = getPayload();

        try {
            await submitForm(KEYS_DATOS_FAMILIARES, api, payload, SECTION_NAME, successSubmitCallback);
        } catch (e) {
            console.error(e);
        }
    };

    const familiaresSinArchivo = () => {
        let familiaresSinArchivo = [];
        for (let fila of filas){
            if (jsonEstaVacio(fila.fileViewModel)) {
                familiaresSinArchivo.push(fila.Nombres)
            }
        }
        return familiaresSinArchivo;
    }

    const cleanErrorMessages = () => {
        filas.forEach(o => {
            Object.values(KEYS_TABLE).forEach(x => {
                clearValidationMessage(`${o.IdPersonaFamiliar}|${x}`, SECTION_NAME);
            })
        })
    }

    const getPayload = () => {
        const basePayload = {
            IdEmpleado: empleadoId,
            DeclaraInformacionFamiliar: declaraInformacionFamiliar,
            AceptaDeclaracionJurada: aceptaDJ,
        }
        if (!declaraInformacionFamiliar){
            return {...basePayload, Familiares: []}
        }

        let familiaresUpdated = filas.map(({ hasPrevFile, ...familiar }) => {
            const EsEditado = detectEditedFamiliar(familiar);
            return {
                    ...familiar,
                    EsEditado,
                    HasPreviousAttachment: Boolean(hasPrevFile),
                }
            });
        return {...basePayload, Familiares: familiaresUpdated}
    }

    const detectEditedFamiliar = (familiar) => {
        if (typeof familiar.IdPersonaFamiliar !== 'number') {
            return null;
        }
        
        const originalFamiliar = familiares.find(originalFamiliar => originalFamiliar.IdPersonaFamiliar === familiar.IdPersonaFamiliar);
        if (!originalFamiliar) {
            return false;
        }

        //return Object.values(KEYS_TABLE).some(key => originalFamiliar[key] !== familiar[key])
        return Object.values(KEYS_TABLE).some(key => {
            if (key === "FileViewModel" ){
                return originalFamiliar[key].Path !== familiar[key].Path
            }
            return originalFamiliar[key] !== familiar[key]
        })
    }

    const successSubmitCallback = async ({ data }) => {
        showModal(data.UrlImage, data.Message);
        await updateComponentStates();
        askForNewBellNotifications(dispatch);
        document.getElementById("frmInfoDatosFamiliares").hidden = false;
        document.getElementById("frmEditDatosFamiliares").hidden = true;
        await reloadSeccionesDatosFamiliares(empleadoId);
    }

    const reset = async () => {
        await updateComponentStates();
    };

    const cancelar = async () => {
        try {
            resetForm("frmEditDatosFamiliares");
            await reset();

            document.getElementById("frmInfoDatosFamiliares").hidden = false;
            document.getElementById("frmEditDatosFamiliares").hidden = true;
        } catch (e) {
            console.error(e);
        }
    };

    const removeFromList = (id) => {
        setFilas(prevFilas => 
            prevFilas.filter(fila => fila.IdPersonaFamiliar !== id)
        )
    };

    const addFilaToList = (e) => {
        if (e) e.preventDefault();

        let emptyFila = {
            "IdPersonaFamiliar": uuidv4(),
            "IdPersona": 0,
            "IdVinculo": 0,
            "NombreVinculo": "",
            "IdTipoDocumento": 0,
            "NombreTipoDocumento": "",
            "NumeroDocumento": "",
            "IdGenero" : 0,
            "Nombres": "",
            "ApellidoPaterno": "",
            "ApellidoMaterno": "",
            "FechaNacimiento": "",
            /*"ApellidoCasado": "",*/
            "NombreCompleto": "",
            "DocumentoUrl": null,
            "FileViewModel": {},
            "nombreDocumentoAdjuntar": "Adjuntar DNI",
            "fileName": "",
            "fileDownloadTempUrl": "",
            /*"hideInputApellidoCasada": true,*/
        };

        let newFilas = [...filas, emptyFila];
        setFilas(newFilas);
        scrollToElement("#tabla-editar-datosFamiliares tr:last-child");
    };

    const updateS3FileUploadResponse = (id, s3Response, fileName, fileDownloadTempUrl) => {
        let newFilas = filas.map(fila => {
            if (id === fila.IdPersonaFamiliar) {
                fila["FileViewModel"] = s3Response;
                fila["fileName"] = getFormattedName(fileName);
                fila["fileDownloadTempUrl"] = fileDownloadTempUrl;
            }
            return fila;
        });
        setFilas(newFilas);
    };

    const manageUploadFileResponse = (response, id, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            let fileDownloadTempUrl = window.URL.createObjectURL(new Blob([file]));

            updateS3FileUploadResponse(id, s3Response, file.name, fileDownloadTempUrl);
            toast.success("El archivo se cargó exitosamente");
        } else {
            updateS3FileUploadResponse(id, {}, "", "");
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFile = async (id, event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivo(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                manageUploadFileResponse(response, id, file);
            } else {
                showToastErrorMaxFileSize();
                updateS3FileUploadResponse(id, {});
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleTiposVinculosLogic = function (fila, IdVinculoSeleccionado) {
        switch (String(IdVinculoSeleccionado)) {
            case String(TIPOS_VINCULOS.Concubino.id): {
                fila["nombreDocumentoAdjuntar"] = "Adj. Unión de Hecho";
                /*fila["hideInputApellidoCasada"] = true;*/
                break;
            }
            case String(TIPOS_VINCULOS.Conyuge.id): {
                fila["nombreDocumentoAdjuntar"] = "Adj. Acta de Matrimonio";
                /*fila["hideInputApellidoCasada"] = false;*/
                break;
            }
            case String(TIPOS_VINCULOS.Hijo.id): {
                fila["nombreDocumentoAdjuntar"] = "Adjuntar DNI";
                /*fila["hideInputApellidoCasada"] = true;*/
                break;
            }
            default: {
                fila["nombreDocumentoAdjuntar"] = "Adjuntar DNI";
                /*fila["hideInputApellidoCasada"] = true;*/
                break;
            }
        }
    };

    const manageHideOrUnhideApellidoCasadaInput = function (fila, id, event) {
        let inputValue = event?.target?.value;

        if (inputValue == TIPOS_VINCULOS.Conyuge.id) {
            console.debug("se ha seleccionado el tipo de vínculo conyuge por lo que se mostrará el campo 'apellido de casada' en este registro ", id);
            fila["hideInputApellidoCasada"] = false;
        } else {
            console.debug("Esconder el campo 'apellido de casada' y se reseteará su valor");
            fila["hideInputApellidoCasada"] = true;
            fila["ApellidoCasado"] = "";
        }
    };

    const handleChangeInput = (id, event) => {
        const name = event.target.name;
        const rawValue = event.target.value;
        const value = IDS_NAME_KEY.includes(name) ? parseInt(rawValue) : rawValue;

        const newInputFields = filas.map(fila => {
            if (id === fila.IdPersonaFamiliar) {
                if (APELLIDOS_KEY.includes(name)){
                    fila[name] = getNormalizedStr(value);
                } else {
                    fila[name] = value;
                }
                if (name === "IdVinculo") {
                    handleTiposVinculosLogic(fila, value);
                    //manageHideOrUnhideApellidoCasadaInput(fila, id, event);
                }
            }
            return fila;
        });
        setFilas(newInputFields);
    };

    const getNormalizedStr = (value) => {
        const normalizedStr = value.replace(/[^ñ]/g, (match) => {
            const replacement = DIACRITIC_MAP[match] || match;
            if (replacement !== match){
                toast.warn(MessageTilde);
            }
            return replacement;
        });
        return normalizedStr.replace(/´/g, '');
    }

    const cleanValues = (idRow = "", keys = []) => {
        setFilas(prev => prev.map(o => {
                if (o.IdPersonaFamiliar === idRow){
                    const updatedValues = {...o}
                    for (let key of keys){
                        updatedValues[key] = "";
                    }
                    return {...updatedValues} 
                }
                return o;
            })    
        ) 
    }

    const removeUploadedFile = function (filaId) {
        setFilas(prevFilas => 
            prevFilas.map(fila => 
                fila.IdPersonaFamiliar === filaId
                    ? { ...fila, fileName: "", FileViewModel: {} }
                    : fila
            )
        );
    };

    return (
        <div>
            <Alert />
            <div className='card-body'>
                <form id='frmInfoDatosFamiliares'>
                    <div className='displayFlexWrap'>
                        {declaraInformacionFamiliar === false &&
                        <p>No tengo información sobre familiares que declarar.</p>
                        }
                        {(declaraInformacionFamiliar === null || declaraInformacionFamiliar === undefined) &&
                        <p>Tienes pendiente indicar los datos de tus familiares.</p>
                        }

                        <table className="">
                            <tbody>
                            {familiares && familiares.map((familiar) => (
                                <tr key={familiar.IdPersonaFamiliar}>
                                    <td className="td-datos-familiares"><p><strong>Vinculo:</strong> {familiar.NombreVinculo}</p> </td>
                                    <td className="td-datos-familiares">
                                        <p>{familiar && familiar.FileViewModel &&
                                        <a><FontAwesomeIcon icon='paperclip' transform="left-9" className="hand-pointer blue-standard-color" onClick={(e) => {
                                            e.preventDefault();
                                            downloadFile(state.token, familiar.FileViewModel.Path, familiar.FileViewModel.OriginalFileName);
                                        }
                                        }/></a>
                                        }<strong>{familiar.NombreTipoDocumento}:</strong> {familiar.NumeroDocumento}</p>
                                    </td>
                                    <td className="td-datos-familiares">
                                        <p><strong>Genero: </strong>{familiar.NombreGenero}</p>
                                    </td>
                                    <td className="td-datos-familiares">
                                        <p><strong>Nombre completo: </strong>{familiar.NombreCompleto}</p>
                                    </td>
                                    <td className="td-datos-familiares">
                                        <p><strong>Fecha de Nacimiento: </strong>{familiar.FechaNacimientoText}</p>
                                    </td>
                                    <td className="td-datos-familiares">
                                        <p><strong>Estado del Familiar: </strong>{familiar.EsFamiliarValidado === null ? "Pendiente" : familiar.EsFamiliarValidado ? "Válido" : "No Válido"}</p>
                                    </td>
                                    <td className="td-datos-familiares">
                                        <p><strong>País de Nacimiento: </strong>{familiar.PaisNacimiento}</p>
                                    </td>
                                </tr>
                            ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className='d-flex justify-content-between mt-4'>
                        <label className="form-check-label ficha-declaracion-jurada-message">
                            <p className="datos-familiares-sumilla-editar">Son derechohabientes siempre y cuando se pueda acreditar con documentación. DNI en el caso de hijos menores de edad y en caso de Convivencia (Unión de hecho) y de cónyuge (acta de matrimonio)</p>
                            <p className="datos-familiares-sumilla-editar">Los datos que se declaren son considerados como una base de datos de consulta y referencia para la empresa. En caso desees acceder al aseguramiento (EsSalud/EPS) de tus derechohabientes te debes contactar con bienestar@innovaschools.edu.pe</p>
                        </label>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <button
                            onClick={actualizar}
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'>
                            Actualizar
                        </button>
                    </div>
                </form>
            </div>
            <div>
                <form id='frmEditDatosFamiliares' className="ficha-edit-form" hidden={true} onSubmit={onSubmit}>
                    <div className="margin-top-15px">
                        <div className='d-flex justify-content-between'>
                            <InputHijos label='Tengo familiares que declarar' options={ESTADOS_YES_NO} name={[KEYS.declaraInfoFamiliar]} value={ESTADOS_YES_NO.find(o => o.value === declaraInformacionFamiliar)} onChange={({ target }) => setDeclaraInformacionFamiliar(target.value?.value)} sectionName={"EditAsignacionFamiliar"} />
                            <div>
                                {declaraInformacionFamiliar && 
                                    <button className="btn btn-primary hand-pointer generic-button-ficha agregar-familiar-button" onClick={addFilaToList}>Agregar Familiar</button>
                                }
                            </div>
                        </div> 
                    </div>  
                    <div className="displayFlexWrap">
                        {(subiendoArchivo === true) && (
                            <SpinnerCargandoSeccionFicha/>
                        )}
                        {(subiendoArchivo === false && declaraInformacionFamiliar) && (
                            <table id={"tabla-editar-datosFamiliares"}>
                                <tbody>
                                {filas && filas.map((fila) => (
                                    <tr className="displayFlexWrap" key={fila.IdPersonaFamiliar} style={{'marginBottom': '30px'}}>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Tipo de vínculo*</label>
                                            <select className="form-control" name="IdVinculo" value={fila.IdVinculo} onChange={event => {handleChangeInput(fila.IdPersonaFamiliar, event); cleanValues(fila.IdPersonaFamiliar, [KEYS_NACIMIENTO.idPaisNacimiento, KEYS_NACIMIENTO.idDepartamento, KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito, KEYS_NACIMIENTO.ciudadNacimiento])}} disabled={!declaraInformacionFamiliar}>
                                                {vinculos && vinculos.map((option) =>
                                                    (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                                )}
                                            </select>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.idVinculo}-validation-message`} />
                                        </td>
                                        <td className="column  mr-1">
                                            <label className="ficha-edit-form-label">Tipo de documento*</label>
                                            <select className="form-control" name="IdTipoDocumento" value={fila.IdTipoDocumento} onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}>
                                                {tiposDocumento && tiposDocumento.map((option) => (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                                )}
                                            </select>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.idTipoDocumento}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Nº de documento*</label>
                                            <input className="form-control" value={fila.NumeroDocumento} name="NumeroDocumento" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.numeroDocumento}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Género*</label>
                                            <select className="form-control" name="IdGenero" value={fila.IdGenero} onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}>
                                                {genero && genero.map((option) =>
                                                    (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                                )}
                                            </select>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.idGenero}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Nombres*</label>
                                            <input className="form-control" value={fila.Nombres} name="Nombres" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.nombres}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Apellido Paterno*</label>
                                            <input className="form-control" value={fila.ApellidoPaterno} name="ApellidoPaterno" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.apellidoPaterno}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Apellido Materno*</label>
                                            <input className="form-control" value={fila.ApellidoMaterno} name="ApellidoMaterno" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.apellidoMaterno}-validation-message`} />
                                        </td>
                                        <td className="column mr-1">
                                            <label className="ficha-edit-form-label">Fecha de Nacimiento*</label>
                                            <input type="date" className="form-control" value={fila.FechaNacimiento} name="FechaNacimiento" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar}></input>
                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_TABLE.fechaNacimiento}-validation-message`} />
                                        </td>
                                        {/* <td className="column mr-1" hidden={fila.hideInputApellidoCasada}>
                                            <label className="ficha-edit-form-label">Apellido de Casada</label>
                                            <input className="form-control" defaultValue={fila.ApellidoCasado} name="ApellidoCasado" onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)}></input>
                                            <p className="ficha-form-input-validation-message" id={"datos-familiares-validation-message-ApellidoCasado-row-" + fila.IdPersonaFamiliar}></p>
                                        </td>*/}
                                        {fila[KEYS_TABLE.idVinculo] === TIPOS_VINCULOS.Hijo.id &&
                                            <>
                                                <InputSelect label='País Nacimiento' name={KEYS_NACIMIENTO.idPaisNacimiento} options={location.paises} value={fila[KEYS_NACIMIENTO.idPaisNacimiento]} onChange={event => {handleChangeInput(fila.IdPersonaFamiliar, event); cleanValues(fila.IdPersonaFamiliar, [KEYS_NACIMIENTO.idDepartamento, KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito, KEYS_NACIMIENTO.ciudadNacimiento])}} idRow={fila.IdPersonaFamiliar} isDisabled={!declaraInformacionFamiliar}/>
                                                {fila[KEYS_NACIMIENTO.idPaisNacimiento] === PAISES.PERU ?
                                                    <>
                                                        <InputSelect label='Departamento' name={KEYS_NACIMIENTO.idDepartamento} options={location.departamentos} value={fila[KEYS_NACIMIENTO.idDepartamento]} onChange={event => {handleChangeInput(fila.IdPersonaFamiliar, event); cleanValues(fila.IdPersonaFamiliar, [KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito])}} idRow={fila.IdPersonaFamiliar} isDisabled={!declaraInformacionFamiliar}/>
                                                        <InputSelectCascada label='Provincia' name={KEYS_NACIMIENTO.idProvincia} dependendOption={fila[KEYS_NACIMIENTO.idDepartamento]} api={getProvinciasPorDepartamentoAPI} value={fila[KEYS_NACIMIENTO.idProvincia]} onChange={event => {handleChangeInput(fila.IdPersonaFamiliar, event); cleanValues(fila.IdPersonaFamiliar, [KEYS_NACIMIENTO.idDistrito])}} idRow={fila.IdPersonaFamiliar} isDisabled={!declaraInformacionFamiliar}/>
                                                        <InputSelectCascada label='Distrito' name={KEYS_NACIMIENTO.idDistrito} dependendOption={fila[KEYS_NACIMIENTO.idProvincia]} api={getDistritosPorProvinciaAPI} value={fila[KEYS_NACIMIENTO.idDistrito]} onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} idRow={fila.IdPersonaFamiliar} isDisabled={!declaraInformacionFamiliar}/>
                                                    </> :
                                                    <td className="column mr-1">
                                                        <label className="ficha-edit-form-label">Ciudad</label>
                                                        <input type="text" className="form-control" value={fila[KEYS_NACIMIENTO.ciudadNacimiento]} name={KEYS_NACIMIENTO.ciudadNacimiento} onChange={event => handleChangeInput(fila.IdPersonaFamiliar, event)} disabled={!declaraInformacionFamiliar} />
                                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${fila.IdPersonaFamiliar}|${KEYS_NACIMIENTO.ciudadNacimiento}-validation-message`} />
                                                    </td>
                                                }
                                            </>
                                        }
                                        <td style={{paddingTop: '31px'}} className="ml-1 ">
                                            <label className="custom-file-upload">
                                                <input type="file" accept=".pdf,.jpg,.png"  onChange={(e) => handleUploadFile(fila.IdPersonaFamiliar, e)}></input>
                                                <p data-tip data-for={"informacion-familiar-uploadFile" + fila.IdPersonaFamiliar} style={{display: 'inline'}}>
                                                    {fila.FileViewModel != null && jsonNoEstaVacio(fila.FileViewModel) && <span className="hand-pointer remove-icon btn btn-primary uploaded-dni-file-datos-familiares">{fila.nombreDocumentoAdjuntar}</span>}
                                                    {(fila.FileViewModel == null || jsonEstaVacio(fila.FileViewModel)) && <span className="hand-pointer remove-icon btn btn-primary">{fila.nombreDocumentoAdjuntar}</span>}
                                                </p>
                                                <ReactTooltip id={"informacion-familiar-uploadFile" + fila.IdPersonaFamiliar} place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                                                    <p>El formato permitido es una imagen .jpg, .png o .pdf de máximo 8MB.</p>
                                                </ReactTooltip>
                                            </label>
                                            {fila?.fileName && fila.fileName.length > 0 &&
                                            <p data-tip style={{display: 'inline'}} className="hand-pointer" title="Eliminar Archivo">
                                                &nbsp;&nbsp; <a title="Descargar archivo" href={fila.fileDownloadTempUrl} download={fila.fileName}>{fila.fileName}</a>&nbsp;<FontAwesomeIcon icon='eraser' className="hand-pointer" onClick={() => removeUploadedFile(fila.IdPersonaFamiliar)}/>
                                            </p>
                                            }
                                        </td>
                                        <td style={{paddingTop: '31px'}}>
                                            <FontAwesomeIcon icon='trash-alt' transform="left+18" className="hand-pointer remove-icon" title="Eliminar Familiar" size="2x" onClick={() => {
                                                removeFromList(fila.IdPersonaFamiliar);
                                            }}/>
                                        </td>
                                    </tr>
                                ))
                                }
                                </tbody>
                            </table>
                        )}
                    </div>
                    <div className='d-flex flex-column justify-content-center mt-3 mb-3'>
                        <div className="form-check">
                            {declaraInformacionFamiliar && 
                                <label className="form-check-label ficha-declaracion-jurada-message">
                                    <p className="datos-familiares-sumilla-editar" style={{color: 'red'}}>* Campos obligatorios</p>
                                </label>
                            }
                        </div>
                        <div>
                            {!(declaraInformacionFamiliar === null || declaraInformacionFamiliar === undefined) &&
                                <DeclaracionJurada keyId={KEYS.aceptaDJ} aceptaDJ={aceptaDJ} handleChange={({ target }) => setAceptaDJ(target.checked)}/>
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-center'>
                        <button onClick={cancelar} type='button' className='btn btn-primary ficha-form-button-cancelar'>
                            Cancelar
                        </button>
                        <button type="submit" className='btn btn-primary ficha-form-button-guardar'>
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default DatosFamiliares;

const Alert = () => {
    const defaultAlertMessage = "Para validar tus actualizaciones te pediremos adjuntar PDF o imagen del DNI de tu/s hijos/as, acta de matrimonio o unión de hecho. ¡Tenlo a mano!";

    return (
        <CustomAlert variant={"warning"}>
            <p className="ficha-secciones-title-text">
                {defaultAlertMessage}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer"/>
            </p>
        </CustomAlert>
    )
}

const DeclaracionJurada = ({ keyId, aceptaDJ, handleChange, inputDisabled, PuedeAceptarDj, IdTipoEmpleado }) => {
    return (
        <div className='col-sm-12 pt-2 px-5 d-flex flex-column'>
            <div>
                <input
                    type="checkbox"
                    className="form-check-input"
                    name={keyId}
                    id={keyId}
                    checked={aceptaDJ ?? false}
                    onChange={handleChange}
                    disabled={inputDisabled}
                />
                <label
                    className="form-check-label ficha-declaracion-jurada-message"
                    htmlFor={keyId}
                >
                    <strong>
                        <p className="mb-0 text-justify">
                            {declaracionJuradaAsignacionFamiliar}
                        </p>
                    </strong>
                </label>
            </div>
            <p
                className="ficha-form-input-validation-message"
                id={`ficha-edit-${SECTION_NAME}-${keyId}-validation-message`}
            />
        </div>
    )
}

const InputSelect = ({ label = "", name = "", options = [], value, onChange, idRow, isDisabled }) => (
    <td className="column mr-1">
        <label className="ficha-edit-form-label">{label}</label>
        <select className="form-control" name={name} value={value} onChange={onChange} disabled={isDisabled}>
            <option value="" disabled selected>Seleccionar</option>
            {options.map((option) =>
                (<option key={option.Value} value={option.Value}>{option.Text}</option>)
            )}
        </select>
        <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${idRow}|${name}-validation-message`} />
    </td>
)

const InputSelectCascada = ({ label = "", name = "", dependendOption, api, value, onChange, idRow, isDisabled }) => {
    const [dropdowns, setDropdowns] = useState([]);
    const [executeManageGetRequest] = useManageGetRequest();

    const getDropdowns = async () => {
        await executeManageGetRequest(api(dependendOption), ({ data }) => setDropdowns(data));
    }

    useEffect(() => {
        if (dependendOption) getDropdowns();
    }, [dependendOption])

    return (
        <td className="column mr-1">
            <label className="ficha-edit-form-label">{label}</label>
            <select className="form-control" name={name} value={value} onChange={onChange} disabled={isDisabled}>
                <option value="" disabled selected>Seleccionar</option>
                {dropdowns.map((option) =>
                    (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                )}
            </select>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${idRow}|${name}-validation-message`} />
        </td>
    )
}

const InputHijos = ({
    label,
    options,
    value,
    onChange,
    name,
    sectionName,
    isRequired,
    isDisabled,
    placeholder = "Seleccionar",
  }) => {
    const handleOnChange = (res) => {
      let data = {
        target: {
          value: res,
          name: name,
        },
      };
      onChange(data);
    };
    return (
      <InputHijosWrap>
        <label className={`modal-label col-sm-8 ${isRequired ? "required" : ""}`}>
          {label}
        </label>
        <div className={`black-color col-sm-4 ${value ? "" : "highlight-input"}`}>
          <Select
            options={options ?? []}
            value={value ?? ""}
            onChange={handleOnChange}
            placeholder={placeholder}
            isDisabled={isDisabled}
            menuPosition='fixed' // check
          />
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-${name}-validation-message`}
          ></p>
        </div>
      </InputHijosWrap>
    )
  }

const InputHijosWrap = styled.div`
  width: 380px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  &>label {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    line-height: 1.5;
    font-family: GothamLight;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0063a1;
  }

  &>div {
    padding-right: 5px;
    padding-left: 5px;
  }
`;