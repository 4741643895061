import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputTextArea from '../../../../components/modalComponents/InputTextArea';
import { desistirNuevoIngresoAPI } from '../../../../consumers/backendApisUrls';

const DesistirFichaNuevoIngreso = ({ id, toggleModal, manageSearch }) => {

  const SECTION_NAME = "DesistirFichaNuevoIngreso";
  const KEYS = ["Motivo"];
  const [formValues, setFormValues] = useState({});
  const history = useHistory();

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const onSubmit = async (e) => {
    e.preventDefault();
    const api = desistirNuevoIngresoAPI();
    const payload = { IdNuevosIngresosEmpleado: parseInt(id), ...formValues };
    try {
      await submitForm(KEYS, api, payload, SECTION_NAME, successOnSubmitCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const successOnSubmitCallback = () => {
    history.push('/adminNuevosIngresos');
  }

  return (
    <ModalForm onSubmit={onSubmit} showModal={toggleModal} titulo="Desistir" width="600px">
      <InputTextArea label="Motivo" {...commonProps("Motivo")}/>
    </ModalForm>
  )
}

export default DesistirFichaNuevoIngreso 