import React, {useContext, useEffect, useState} from 'react';
import {getDatosYearEndAPI, getArchivosActividadesAPI} from "../../consumers/backendApisUrls";
import {downloadFile} from "../../consumers/backendConsumer";
import {AuthContext} from "../../hooks/context/authContext";
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { initialAllOptionPromises } from '../../helper/utils';
import { CardActividadesPendientes, CardContrato } from './CardsSeccionCierreDeAnio';
import CardArchivosVarios from './CardArchivosVarios';
import CardArchivosNuevoIngreso from './CardArchivosNuevoIngreso';
import { MENUS_FICHA_DATOS } from '../../constants/hardCodedConstants';
import CardAccordion from '../../components/pageComponents/CardAccordion';

const SeccionCierreDeAnio = ({ empleadoId, seccionesArchivos, subSeccionesArchivos, tienePermiso }) => {
    const {state} = useContext(AuthContext);
    const ID_TYPES = { 
        datosYearEnd: { title: "Contratos", component: CardContrato, id: MENUS_FICHA_DATOS.archivos.secciones.contratos.id, api: getDatosYearEndAPI(empleadoId) },
        archivosActividadesPendientes: { title: "Actividades Pendientes", component: CardActividadesPendientes, id: MENUS_FICHA_DATOS.archivos.secciones.actividadesPendientes.id, api: getArchivosActividadesAPI(empleadoId) }
    };
    const [allData, setAllData] = useState([]);
    const [getRequest] = useGetRequest();

    const init = async function () {
        const [allInitPromises, idTypePermissions] = getDataToRender();

        try {
            await initialAllOptionPromises(allInitPromises, idTypePermissions, setAllData)
        } catch (error) {
            console.error(error);
        }
    }

    const getDataToRender = () => {
        let allInitPromises = [];
        let idTypePermissions = [];
        for (let type in ID_TYPES){
            if (tienePermiso(seccionesArchivos, ID_TYPES[type].id)){
                allInitPromises.push(getRequest(ID_TYPES[type].api));
                idTypePermissions.push(type.toString());
            }
        }

        return [() => allInitPromises, idTypePermissions];
    }

    const handleDownloadFile = (Path, OriginalFileName) => {
        downloadFile(state.token, Path, OriginalFileName);
    }

    useEffect(() => {
        init();
    }, [seccionesArchivos, subSeccionesArchivos]);

    return (
        <div
            className='tab-pane fade show active'
            id='divcierreDeAño'
            role='tabpanel'
            aria-labelledby='home-tab'>
            <div id='accordion-datos-archivos'>
                {allData?.map((data, index) => {
                    let title = ID_TYPES[data.key].title;
                    let Component = ID_TYPES[data.key].component;
                    const id = ID_TYPES[data.key].id;

                    return (
                        <CardAccordion key={data.key} id={data.key} idParent="datos-archivos" label={subSeccionesArchivos[id]?.NombreSeccion || title} isShow={index === 0}>
                            <Component allData={data.value} handleDownloadFile={handleDownloadFile}/>
                        </CardAccordion>
                    )
                })}
                {tienePermiso(seccionesArchivos, MENUS_FICHA_DATOS.archivos.secciones.archivosVarios.id) && <CardArchivosVarios idEmpleado={empleadoId} handleDownloadFile={handleDownloadFile} subSeccionesArchivos={subSeccionesArchivos}/>}
                {tienePermiso(seccionesArchivos, MENUS_FICHA_DATOS.archivos.secciones.documentosIngreso.id) && <CardArchivosNuevoIngreso idEmpleado={empleadoId} handleDownloadFile={handleDownloadFile} subSeccionesArchivos={subSeccionesArchivos}/>}
            </div>
        </div>
    )
};

export default SeccionCierreDeAnio;