import React, {useState} from 'react';

const TableWebBodyGrupo = ({filas}) => {

    return (
        <tbody className="listar-tbody">
        {filas.map((user, i) => (
            <tr key={i}>
                <td>{user.IdGrupoCorreo}</td>
                <td>{user.Categoria}</td>
                <td>{user.Cargo}</td>
                <td>{user.Correo}</td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyGrupo;
