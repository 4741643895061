import React, { useEffect, useState } from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core"
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import VideoCurso from './VideoCurso';
import EvaluacionCurso from './EvaluacionCurso';
import ConstanciaCurso from './ConstanciaCurso';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { getPrimerPasoCapacitacion, guargarTercerPaso } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import {useSubmitForm} from "../../../../hooks/useSubmitForm/useSubmitForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const innerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#0064a7",
    },
  },
});

const CapacitacionModal = ({ showModal, setReload, idCursoCapacitacion }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);

  const [cursoPrimeraParte, setCursoPrimeraParte] = useState({});

  const [isDisableBack, setIsDisableBack] = useState(false);
  const steps = getSteps();

  const [uploadFileDJ, setUploadFileDJ] = useState({});

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();
  const [submitForm] = useSubmitForm();

  const getInitialData = async () => {
    const response = await executeGetRequest(getPrimerPasoCapacitacion(idCursoCapacitacion));
    if (responseCode200(response)) {
      setCursoPrimeraParte(response?.data);
      if (response?.data?.EsAprobado) {
        setActiveStep(2);
        setIsDisableBack(true);
      }
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  const enableContinue = () => {
    setCursoPrimeraParte({
      ...cursoPrimeraParte,
      EsCapacitacionCompletada: true
    })
  }

  const goBackToVideo = () => {
    setActiveStep(0);
  }

  function getSteps() {
    return [`Video ${cursoPrimeraParte?.NombreCapacitacion}`, 'Evaluación del curso', 'Constancia del curso'];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <VideoCurso embedId={cursoPrimeraParte?.KeyVideo} />;
      case 1:
        return <EvaluacionCurso
          enableContinue={enableContinue}
          EsCapacitacionCompletada={cursoPrimeraParte.EsCapacitacionCompletada}
          setIsDisableBack={setIsDisableBack}
          goBackToVideo={goBackToVideo}
          idCursoCapacitacion={idCursoCapacitacion}
        />;
      case 2:
        return <ConstanciaCurso idCursoCapacitacion={idCursoCapacitacion}/>;
      default:
        return 'Unknown step';
    }
  }


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const successSubmit = function (response) {
    setReload();
    showModal();
  }

  const handleEnd = async () => {
    const payload = {
      IdCapacitacionEmpleado: cursoPrimeraParte?.IdCapacitacionEmpleado,
      File: uploadFileDJ
    }
    const api = guargarTercerPaso();

    let keys = ["File"];
    await submitForm(keys, api, payload, "AgregarDJ", successSubmit)
  };

  return <>
    <GenericModal showModal={showModal} width={"1570"} titulo={cursoPrimeraParte?.NombreCapacitacion || "Mis cursos"}>
      <ThemeProvider theme={innerTheme}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length - 1 ? (
              <div className="my-3">
                <ConstanciaCurso setUploadFileDJ={setUploadFileDJ} getInitialData={getInitialData} idCursoCapacitacion={idCursoCapacitacion}/>
                <div className="mb-2 d-flex justify-content-end">
                  {!isDisableBack ? (
                    <button
                      type='button'
                      className='generic-button-buscador'
                      disabled={activeStep === 0}
                      onClick={handleBack}>
                      Atrás
                    </button>
                  ) : null}

                  <button
                    type='button'
                    className='generic-button-buscador'
                    onClick={handleEnd}>
                    Enviar
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className={classes.instructions}>
                  {getStepContent(activeStep)}
                </div>
                <hr />
                <div className="mb-2 d-flex justify-content-end">
                  {activeStep === 0 || isDisableBack ? null : (
                    <button
                      type='button'
                      className='generic-button-buscador'
                      disabled={activeStep === 0}
                      onClick={handleBack}>
                      Atrás
                    </button>
                  )}

                  {
                    activeStep === 1 && !cursoPrimeraParte.EsCapacitacionCompletada ? null : (
                      <button
                        type='button'
                        className='generic-button-buscador'
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 2 ? 'Siguiente' : 'Siguiente'}
                      </button>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </ThemeProvider>
    </GenericModal>
  </>;
};

export default CapacitacionModal;
