import React, { useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faClipboardList, faDownload } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import styled from 'styled-components';
import { toast } from 'react-toastify';
import VerDatoFamiliar from './modales/VerDatoFamiliar';
import EditarDatoFamiliar from './modales/EditarDatoFamiliar';
import AgregarDatoFamiliar from './modales/AgregarDatoFamiliar';
import EliminarDatoFamiliar from './modales/EliminarDatoFamiliar';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import { AuthContext } from '../../../../../hooks/context/authContext';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import ModalEnabled from '../../../../../components/modalComponents/ModalEnabled';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { downloadFile } from '../../../../../consumers/backendConsumer';
import { formatStringDate } from '../../../../../helper/utils';
import { clearValidationMessage } from '../../../../../validations/FormValidatorUtils';
import { declaracionJuradaAsignacionFamiliar } from '../../../../../constants/messages';
import { ESTADOS_YES_NO, KEYS_DATOS_FAMILIARES, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS } from '../../../../../constants/hardCodedConstants';
import { getDatosFamiliaresNuevosIngresosAPI, guardarDatosFamiliaresNuevosIngresosAdminAPI, validarDatosFamiliaresNuevosIngresosAdminAPI } from '../../../../../consumers/backendApisUrls';

const SECTION_NAME = "EditarDatosFamiliaresNuevosIngresos";
const KEYS_TABLE = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", numeroDocumento: "NumeroDocumento", idGenero: "IdGenero", nombres: "Nombres",  apellidoPaterno: "ApellidoPaterno", apellidoMaterno: "ApellidoMaterno", fechaNacimiento: "FechaNacimiento", fileViewModel: "FileViewModel" };
const STEP_5_FICHA = 5;

const DatosFamiliares = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, isColaborador, inputDisabled, sendCurrentStep, setSendCurrentStep, componentStep, fichaStep = 1 }) => {

  const KEYS = {familiares: "Familiares", declaraInfoFamiliar: "DeclaraInformacionFamiliar", aceptaDJ: "AceptaDeclaracionJurada"};
  const [formValues, setFormValues] = useState({ [KEYS.familiares]: [], [KEYS.declaraInfoFamiliar]: true, [KEYS.aceptaDJ]: null });
  const [modalEnabled, setModalEnabled] = useState({isEnable: false, component: React.Fragment});

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [submitForm] = useSubmitForm();
  const [executeManageGet] = useManageGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [handleChange] = useForm(formValues, setFormValues);

  const init = async () => {
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  
  const manageSearch = async () => {
    const api = getDatosFamiliaresNuevosIngresosAPI(idNuevosIngresosEmpleado);
    await executeManageGet(api, successSearchCallback);
  }

  const successSearchCallback = ({ data }) => {
    const { Familiares, ...dataTemp } = data;
    const familiaresUpdated = Familiares.map(o => ({ ...o, hasPrevFile: true }));
    setFormValues({...dataTemp, [KEYS.familiares]: familiaresUpdated});
  }

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSending(true);
    cleanErrorMessages();
    try {
      const api = guardarDatosFamiliaresNuevosIngresosAdminAPI();
      const payload = getPayload();
      await submitForm(KEYS_DATOS_FAMILIARES, api, payload, SECTION_NAME, successSubmitCallback, !isColaborador, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const getPayload = () => {
    const { PuedeValidar, DeclaraInformacionFamiliar, TieneAsignacionFamiliar, Familiares, ...formValuesSelected  } = formValues;

    let familiaresUpdated = Familiares.map(({ hasPrevFile, ...familiar }) => ({
      ...familiar,
      HasPreviousAttachment: Boolean(hasPrevFile),
    }));
    return { ...formValuesSelected, [KEYS.familiares]: familiaresUpdated, DeclaraInformacionFamiliar: true }
  }

  const successSubmitCallback = () => {
    manageSearch();
    if(isColaborador) setSendCurrentStep(lastData => ({...lastData, isSent: false, isSaved: true}));
  }

  const cleanErrorMessages = () => {
    formValues[KEYS.familiares].forEach(o => {
      Object.values(KEYS_TABLE).forEach(x => {
        clearValidationMessage(`${o.IdPersonaFamiliar}|${x}`, SECTION_NAME);
      })
    })
  }

  const handleActionsObligatorio = async (IdPersonaFamiliar) => {
    const api = validarDatosFamiliaresNuevosIngresosAdminAPI();
    const payload = { IdPersonaFamiliar };
    await executeManagePost(api, payload, () => manageSearch());
  }

  useDidMountEffect(() => {
    if (isColaborador && sendCurrentStep?.step === componentStep && sendCurrentStep?.isSent){
      handleSubmit();
    }
  }, [sendCurrentStep])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <>
    <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} />
    <form onSubmit={handleSubmit} className={`${isColaborador ? "form-colaborador-inputs" : ""}`}>
      <CardAccordion id={OPTIONS.idDatosFamiliares} idParent={ID_PARENT} label="6. Datos Familiares" isShow isShowGeneral={!isColaborador}>
        <div className='d-flex justify-content-end px-3 mt-4'>
          <button type='button' className='btn generic-button-buscador' onClick={() => setModalEnabled({ isEnable: true, component: AgregarDatoFamiliar, data: { setFormValues }})} disabled={inputDisabled || !isColaborador}>Agregar</button>
        </div>
        <Table familiares={formValues[KEYS.familiares]} setFormValues={setFormValues} setModalEnabled={setModalEnabled} isColaborador={isColaborador} puedeValidar={formValues.PuedeValidar} inputDisabled={inputDisabled} handleActionsObligatorio={handleActionsObligatorio} fichaStep={fichaStep}/>
        <AsignacionFamiliarSiNo TieneAsignacionFamiliar={formValues.TieneAsignacionFamiliar} />
        <DeclaracionJurada keyId={KEYS.aceptaDJ} aceptaDJ={formValues[KEYS.aceptaDJ]} handleChange={handleChange} inputDisabled={inputDisabled || !isColaborador}/>
        {/* {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending}/>} */}
      </CardAccordion>
    </form>
    </>
  )
}

export default DatosFamiliares;

const Table = ({ familiares, setFormValues, setModalEnabled, isColaborador, puedeValidar, inputDisabled, handleActionsObligatorio, fichaStep }) => {

  const { state } = useContext(AuthContext);

  return (
    <div className="container-fluid">
      <div className="table-scroll pt-3" style={{ maxHeight: "60vh" }}>
        <table className="table table-hover">
          <thead className="listar-thead">
            <tr className="font-gothan-medium">
              <th className='text-center col-sm-2'>Tipo de vínculo</th>
              <th className='text-center col-sm-2'>N° de documento</th>
              <th className='text-center col-sm-3'>Nombre Completo</th>
              <th className='text-center col-sm-1'>Género</th>
              <th className='text-center col-sm-2'>Fecha de Nacimiento</th>
              <th className='text-center col-sm-2'>País de Nacimiento</th>
              {(!isColaborador && fichaStep >= STEP_5_FICHA) && <th className='text-center col-sm-1'>Validar</th>}
              {isColaborador && <th className='text-center col-sm-1'>Validado</th>}
              <th className='text-center col-sm-2'>Acciones</th>
            </tr>
          </thead>
          <tbody className='listar-tbody'>
            {familiares?.map((row, i) => (
              <tr key={i}>
                <td className='text-center'>
                  <div>
                    {row.NombreVinculo}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.idVinculo}-validation-message`} />
                  </div>
                </td>
                <td className='text-center'>
                  <div>
                    {row.NombreTipoDocumento} - {row.NumeroDocumento}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.idTipoDocumento}-validation-message`} />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.numeroDocumento}-validation-message`} />
                  </div>
                </td>
                <td className='text-center'>
                  <div>
                    {row.Nombres} {row.ApellidoPaterno} {row.ApellidoMaterno}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.nombres}-validation-message`} />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.apellidoPaterno}-validation-message`} />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.apellidoMaterno}-validation-message`} />
                  </div>
                </td>
                <td className='text-center'>
                  <div>
                    {row.NombreGenero}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.idGenero}-validation-message`} />
                  </div>
                </td>
                <td className='text-center'>
                  <div>
                    {formatStringDate(row.FechaNacimiento)}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.fechaNacimiento}-validation-message`} />
                  </div>
                </td>
                <td className='text-center'>
                  <div>
                    {row.PaisNacimiento || "-"}
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${row.IdPersonaFamiliar}|${KEYS_TABLE.IdPaisNacimiento}-validation-message`} />
                  </div>
                </td>
                {(!isColaborador && fichaStep >= STEP_5_FICHA) && 
                  <td className="text-center">
                    <ValidateFile row={row} puedeValidar={puedeValidar} handleValidarAsignacionFamiliar={handleActionsObligatorio}/>
                  </td>
                }
                {isColaborador && 
                  <td className='text-center'>
                    {row.EsFamiliarValidado === null ? "Sin Valor" : row.EsFamiliarValidado ? "Sí" : "No"}
                  </td>
                }
                <td className='text-center'>
                  <div className='d-flex justify-content-center' style={{gap: "15px"}}>
                    <FontAwesomeIcon 
                      icon={faDownload}
                      className="green-innova"
                      title="Descargar archivo"
                      size="lg"
                      onClick={() => downloadFile(state.token, row.FileViewModel?.Path, row.FileViewModel?.OriginalFileName)}
                    />
                    <FontAwesomeIcon 
                      icon="eye"
                      className="hand-pointer blue-standard-color"
                      title="Ver"
                      size='lg'
                      onClick={() => setModalEnabled({isEnable: true, component: VerDatoFamiliar, data: { familiar: row }})}
                    />
                    {isColaborador &&
                      <>
                        <FontAwesomeIcon
                          icon="edit"
                          className="hand-pointer blue-standard-color"
                          title="Editar"
                          size='lg'
                          onClick={() => setModalEnabled({isEnable: true, component: EditarDatoFamiliar, data: { familiar: row, setFormValues }})}
                        />
                        <FontAwesomeIcon 
                          icon="trash"
                          className="hand-pointer blue-standard-color"
                          title="Eliminar"
                          size='lg'
                          onClick={() => setModalEnabled({isEnable: true, component: EliminarDatoFamiliar, data: { familiar: row, setFormValues }})}
                        />
                      </>
                    }
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const DeclaracionJurada = ({ keyId, aceptaDJ, handleChange, inputDisabled, PuedeAceptarDj, IdTipoEmpleado }) => {
  return (
    <div className='col-sm-12 pt-2 px-5 d-flex flex-column'>
      <div>
        <input
          type="checkbox"
          className="form-check-input"
          name={keyId}
          id={keyId}
          checked={aceptaDJ ?? false}
          onChange={handleChange}
          disabled={inputDisabled}
        ></input>
        <label
          className="form-check-label ficha-declaracion-jurada-message"
          htmlFor={keyId}
        >
          <strong>
            <p className="mb-0 text-justify">
              {declaracionJuradaAsignacionFamiliar}
            </p>
          </strong>
        </label>
      </div>
      <p
        className="ficha-form-input-validation-message"
        id={`ficha-edit-${SECTION_NAME}-${keyId}-validation-message`}
      ></p>
    </div>
  )
}

const AsignacionFamiliarSiNo = ({ TieneAsignacionFamiliar = null}) => {
  let result; 

  if (TieneAsignacionFamiliar === null) {
    result = "sin valor";
  } else {
    result = TieneAsignacionFamiliar ? "Sí" : "No";
  }

  return(
    <div className='col-sm-12 px-4'>
      <label>Asignación Familiar: <strong>{result}</strong></label>
    </div>
  )
}

const InputHijos = ({
  label,
  options,
  value,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
  placeholder = "Seleccionar",
}) => {
  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };
  return (
    <InputHijosWrap>
      <label className={`modal-label col-sm-6 ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color col-sm-6 ${value ? "" : "highlight-input"}`}>
        <Select
          options={options ?? []}
          value={value ?? ""}
          onChange={handleOnChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition='fixed' // check
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </InputHijosWrap>
  )
}

const ValidateFile = ({ row, puedeValidar = false, handleValidarAsignacionFamiliar }) => (
  puedeValidar &&
    <FontAwesomeIcon
      icon={row.EsFamiliarValidado ? faClipboardCheck : faClipboardList}
      size="lg"
      title={`${row.EsFamiliarValidado ? "Invalidar" : "Validar"}`}
      className={`hand-pointer ${row.EsFamiliarValidado ? "green-innova" : "blue-standard-color"}`}
      onClick={() => handleValidarAsignacionFamiliar(row.IdPersonaFamiliar)}
    />
)

const InputHijosWrap = styled.div`
  width: 380px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;

  &>label {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    line-height: 1.5;
    font-family: GothamLight;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0063a1;
  }

  &>div {
    padding-right: 5px;
    padding-left: 5px;
  }
`;