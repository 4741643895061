import React, { useState, useEffect } from "react";
import AsyncSelectInput from "../Select/AsyncSelectInput";
import AsyncSelectInputGET from "../Select/AsyncSelectInputGET";
import { INPUT_DIRECTION } from "../../constants/hardCodedConstants";
import PropTypes from 'prop-types';

const InputSearchAsync = ({
  label,
  api,
  name,
  onChange,
  sectionName,
  value,
  valueText,
  typeGet = false,
  isRequired,
  isDisabled,
  direction = "row",
  placeholder = "Buscar por nombre",
}) => {

  const [valueSelected, setValueSelected] = useState(null);

  const handleOnChange = (res) => {
    setValueSelected(res);
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  useEffect(() => {
    if (valueText) {
      setValueSelected({ label: valueText, value: value });
    } else {
      setValueSelected(null)
    }
  }, [valueText])

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        {typeGet 
        ? <AsyncSelectInputGET
            api={api}
            placeholder={placeholder}
            handleSelectInputChange={handleOnChange}
            isDisabled={isDisabled}
            defaultValue={valueSelected}
          />
        :  <AsyncSelectInput
            api={api}
            placeholder={placeholder}
            handleSelectInputChange={handleOnChange}
            isDisabled={isDisabled}
            defaultValue={valueSelected}
          />
        }
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

export default InputSearchAsync;
