import React from 'react'
import ReactTooltip from 'react-tooltip';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarCuponera from './configuracionCuponera/modales/EditarCuponera';
import PageTable from '../../../components/pageComponents/PageTable'
import ActionsIcons from '../../../components/atoms/ActionsIcons';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import { inactivarActivarCuponeraTiempoLibreAPI } from '../../../consumers/backendApisUrls';

const Table = ({ rows, paginator, setPaginator, setModalEnabled }) => {

  const history = useHistory();

  const HEADERS = [
    {col: "", label: "Nombre"},
    {col: "", label: "Prefijo"},
    {col: "", label: "Fecha Inicio"},
    {col: "", label: "Fecha Fin"},
    {col: "", label: "Nro Cupones"},
    {col: "", label: "Acciones"},
  ];

  const handleActivarInactivar = (row) => {
    const api = inactivarActivarCuponeraTiempoLibreAPI;
    const payload = { IdCuponeraTiempoLibre: row.IdCuponeraTiempoLibre };
    const data = {
      api,
      payload,
      name: row.Nombre,
      title: row.EstadoRegistro ? "Inactivar" : "Activar",
      actionLabel:  row.EstadoRegistro ? "inactivar" : "activar",
    }
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data});
  }

  return (
    <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
      <tbody className='listar-tbody'>
        {rows.map((row, i) => (
          <tr key={`${i}-${row.IdCuponeraTiempoLibre}`}>
            <TableTd row={row}>
              {VigenteSinCuponToolTip(row)}
              {row.Nombre}
            </TableTd>
            <TableTd className="text-center" row={row}>{row.Prefijo}</TableTd>
            <TableTd className="text-center" row={row}>{row.FechaInicioText}</TableTd>
            <TableTd className="text-center" row={row}>{row.FechaFinText}</TableTd>
            <TableTd className="text-center" row={row}>{row.NroCupones}</TableTd>
            <TableTd className="text-center" row={row}>
              <ActionsIcons>
                {row.EstadoRegistro &&
                  <>
                    <FontAwesomeIcon 
                      icon="edit"
                      title='Editar Cuponera'
                      size='lg'
                      onClick={() => setModalEnabled({ isEnable: true, component: EditarCuponera, data: { cuponera: row }})}
                    />
                    <FontAwesomeIcon
                      icon="file-invoice"
                      title='Ver Cupones Asignados'
                      size='lg'
                      onClick={() => history.push(`/adminCuponeraTiempoLibre/verCuponesAsignadosByCuponera?search=${row.IdCuponeraTiempoLibre}&from=${row.FechaInicio}&to=${row.FechaFin}`)}
                    />
                  </>
                }
                <FontAwesomeIcon 
                  icon={row.EstadoRegistro  ? 'ban' : 'circle-notch'}
                  title={row.EstadoRegistro  ? 'Inactivar' : 'Activar'}
                  size='lg'
                  onClick={() => handleActivarInactivar(row)}
                />
              </ActionsIcons>
            </TableTd>
          </tr>
        ))}
      </tbody>
    </PageTable>
  )
}

export default Table;

const TableTd = ({ className, row, children }) => (
  <td className={`${className} ${(row.EsVigente && row.NroCupones === 0) ? "ficha-falta-completar" : ""}`}>
    {children}
  </td>
);

const VigenteSinCuponToolTip = (row) => (
  (row.EsVigente && row.NroCupones === 0) &&
    <>
      <p data-tip data-for="VigenteSinCupones" className="d-inline mr-1" style={{color: "#b3a11a"}}>
        <FontAwesomeIcon icon='exclamation-triangle' />
      </p>
      <ReactTooltip id="VigenteSinCupones" place="top" effect="solid" className="tooltip-style">
        Cuponera es vigente y no cuenta con cupones asignados.
      </ReactTooltip>
    </>
);