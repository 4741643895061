import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { getTiposArchivoFichaDatosAPI, createArchivosFichaDatosAPI } from '../../../consumers/backendApisUrls';
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from '../../../components/Select/SelectInput';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { clearAllInputValidationMessages } from '../../../validations/FormValidatorUtils';

const NuevoArchivo = ({ toggleModal, idEmpleado, manageSearch }) => {
  const isMounted = useRef(true);

  const SECTION_NAME = "NUEVO_ARCHIVO_CARD_CIERRE_ANIO";
  const KEYS = {Empleado: "IdEmpleado", TipoArchivo: "IdTipoArchivo", File: "File"}

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allData, setAllData] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();

  const initialData = async () => {
    setIsLoading(true);
    try {
      setAllData(await getOptions(getTiposArchivoFichaDatosAPI()));
    } catch (error) {
      console.error();
    }
    setIsLoading(false);
  }

  const handleChange = (id, res) => {
    setFormValues(lastData => ({...lastData, [id]: res}))
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = createArchivosFichaDatosAPI();
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successOnSubmitCallback);
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
      ...formValues,
      [KEYS.Empleado]: idEmpleado,
      [KEYS.TipoArchivo]: formValues[KEYS.TipoArchivo]?.value,
    }
  }

  const successOnSubmitCallback = () => {
    manageSearch();
    toggleModal();
  }

  useDidMountEffect(() => {
    if (formValues.File?.Path) clearAllInputValidationMessages([KEYS.File], SECTION_NAME);
    if (formValues.IdTipoArchivo) clearAllInputValidationMessages([KEYS.TipoArchivo], SECTION_NAME);
  }, [formValues])

  useEffect(() => {
    initialData();
    return () => {isMounted.current = false;}
  }, [])

  return (
    isLoading ? 
      <div className="spinner-cargando-container">
          <Spinner animation="grow" variant="primary" size="1sm" />
      </div> :
      <GenericModal showModal={toggleModal} width="700px" titulo="Nuevo Archivo">
        <div className="modal-body pt-0 pl-0">
          <form onSubmit={handleOnSubmit}>
            <div className='form-group row'>
              <label className='col-sm-3 col-form-label modal-label'>
                Tipo
              </label>
              <div className='col-sm-9 black-color'>
                <SelectInput
                  options={allData}
                  jsonSelectedValue={formValues[KEYS.TipoArchivo]}
                  setJsonSelectedValue={(res) => handleChange(KEYS.TipoArchivo, res)}
                  placeholder="Seleccionar"
                  resetSelectedValue={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.TipoArchivo}-validation-message`}></p>
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-sm-3 col-form-label modal-label'>
                Archivo
              </label>
              <div className='col-sm-9 black-color'>
                <GenericUploadFileV2 
                  id="ARCHIVO_CARD_SECCION_CIERRE_ANIO"
                  extensionesValidas={["pdf"]}
                  uploadFileToS3={(res) => handleChange(KEYS.File, res)}
                  textButton="Subir Archivo"
                  subiendoArchivoPublicState={isUploading}
                  setSubiendoArchivoPublicState={setIsUploading}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.File}-validation-message`}></p>
              </div>
            </div>
            <div className='modal-footer d-flex justify-content-end'>
              <div className='button'>
                <button className='btn btn-primary generic-button-ficha-modal' type='submit' disabled={isUploading || isSubmitting}>
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </GenericModal>
  )
}

export default NuevoArchivo