import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary} from "../../../helper/utils";
import {toast} from "react-toastify";
import SelectInput from "../../../components/Select/SelectInput";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getActividadesRenunciasAPI, getEstadosAsignacionAPI, guardarConfirmarPrestamoAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {AlertErrorMaxFileSizeCeses} from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";

const ConfirmarPrestamoModal = ({showModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const {state} = useContext(AuthContext);
    const sectionName = "modalConfirmarPrestamoModal";
    const [actividadRenuncia, setActividadRenuncia] = useState({});
    const [estadosAsignacionOptions, setEstadosAsignacionOptions] = useState([]);
    const [jsonSelectedEstadoAsignacionValue, setJsonSelectedEstadoAsignacionValue] = useState({});

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const UPDATE_SUSTENTO_INPUT_FILE = sectionName + "-sustento-file";
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    async function getActividadesRenunciass() {
        const response = await executeGetRequest(getActividadesRenunciasAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setActividadRenuncia(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    async function getEstadosAsignacionn() {
        const response = await executeGetRequest(getEstadosAsignacionAPI());
        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
            setEstadosAsignacionOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        await getActividadesRenunciass();
        await getEstadosAsignacionn();

        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        return {
            "Observaciones": "",
            "Monto": "",
        };
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        console.debug("formValues: ", formValues);

        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "IdEstadoAsignacion": (jsonSelectedEstadoAsignacionValue["value"]) ? jsonSelectedEstadoAsignacionValue["value"] : null,
            "Sustento": s3UploadFileResponse,
            "Observaciones": formValues.Observaciones,
            "Monto": formValues.Monto,
        };

        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarConfirmarPrestamoAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdEstadoAsignacion", "Sustento", "Observaciones", "Monto"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"890px"} titulo="Confirmar asignación de equipo">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label ">Colaborador:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.NombreEmpleado}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.FechaUltimoDia}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.Sede}</label>
                            </div>
                            <hr/>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Indicar estado de asignación<span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <SelectInput options={estadosAsignacionOptions} jsonSelectedValue={jsonSelectedEstadoAsignacionValue} setJsonSelectedValue={setJsonSelectedEstadoAsignacionValue} isDisabled={false} placeholder={"Seleccionar una opción"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEstadoAsignacion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Cargar Sustento<span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center ">
                                    <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponse} id={UPDATE_SUSTENTO_INPUT_FILE} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                         subiendoArchivoPublicState={subiendoArchivo} setSubiendoArchivoPublicState={setSubiendoArchivo}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Monto<span className="required">*</span></label>
                                <div className="col-sm-3 flex-row justify-content-center">
                                    <input type="number" className="form-control" name="Monto" onChange={handleChange} defaultValue={formValues.Monto}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Monto-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Observaciones<span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center ">
                                    <textarea className="form-control" rows="3" name="Observaciones" onChange={handleChange} defaultValue={formValues.Observaciones} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observaciones-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                            <p>* El formato permitido es un archivo .pdf de máximo 8MB.</p>
                        </strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit} disabled={subiendoArchivo}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ConfirmarPrestamoModal;