import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import ModalForm from '../../../components/modalComponents/ModalForm';
import InputDate from '../../../components/modalComponents/InputDate';
import InputText from '../../../components/modalComponents/InputText';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { calculateDaysInBetween, toIsoForInput } from '../../../helper/utils';
import { crearProcesoCierreSolicitudVacacionesAPI, getProcesoCierreInfoVacacionesAPI } from '../../../consumers/backendApisUrls';

const DivUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${props => props.displayFormat ? "none" : ""};
  
  p{
    font-size: inherit !important;
    width: 135px;
    height: 54px;
    padding-top: 2px;
    padding-bottom: 0;
  }

  a, svg{
    display: none;
  }
`;

const SolicitudVacacionesProcesoCierre = ({ toggleModal, refreshPage, idProcesoCierre: IdProcesoCierre, idEempleado, idPlantillaConfig, idProcesoCierreEmpleado }) => {

  const TIME_STAMP = "T00:00:00";
  const SECTION_NAME = "CrearSolicitudVacacionesProcesoCierre"
  const ID_UPLOAD_FORMAT = "AgregarFirmarFormato";

  const KEYS = ["Comentarios", "FileViewModel"];

  const [formValues, setFormValues] = useState({});
  const [textAreaCount, setTextAreaCount] = useState(0);
  const [diasVacacionesCalculado, setDiasVacacionesCalculado] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [executeManageGet] = useManageGetRequest();

  const init = async () => {
    const api = getProcesoCierreInfoVacacionesAPI(idEempleado, idPlantillaConfig);
    try {
      await executeManageGet(api, ({ data }) => setFormValues(data));
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearProcesoCierreSolicitudVacacionesAPI();
    const payload = getPayload();
    try {
      await submitForm(KEYS, api, payload, SECTION_NAME, successSubmit )
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdSolicitante: idEempleado,
      IdProcesoCierreEmpleado: idProcesoCierreEmpleado,
      IdProcesoCierrePlantillasConfig: idPlantillaConfig,
      StartDate: formValues.StartDate,
      EndDate: formValues.EndDate,
      IdJefe: formValues.IdAprobador,
      Comentarios: formValues.Comentarios,
      FileViewModel: formValues.FileViewModel,
      IdProcesoCierre
    }
  }

  const successSubmit = async () => {
    if (refreshPage) await refreshPage();
    toggleModal();
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, FileViewModel: res}))
  }

  const calculateVacationDays = () => {
    if(formValues.StartDate && formValues.EndDate && formValues.EndDate >= formValues.StartDate){
      let days = calculateDaysInBetween(formValues.StartDate, formValues.EndDate);
      setDiasVacacionesCalculado(days);
    } else if(formValues.StartDate && formValues.EndDate && formValues.StartDate > formValues.EndDate){
      let day = new Date(formValues.StartDate+TIME_STAMP);
      let dayISO = toIsoForInput(day);
      setFormValues({...formValues, EndDate: dayISO});
    } else {
      setDiasVacacionesCalculado(0);
    }
  }

  useDidMountEffect(() => {
    calculateVacationDays();
  },[formValues])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Crear Solicitud Vacaciones" width="750px" isSubmitting={isSubmitting} hasSaveButton={formValues.PuedeGuardarSolicitud}>
      <div className='mb-3 d-flex flex-column' style={{ borderBottom: "1px solid #dee2e6" }}>
          <label className='text-justify black-color mb-0'>Nota:</label>
          <label className='text-justify change-requested-field' style={{fontSize: "0.9rem"}}>{mensajeModal}</label>
      </div>
      <div className="form-row">
        <Div><InputText label='Solicitante' {...commonProps('Solicitante')} direction='column' isDisabled/></Div>
        <Div><InputText label='email' {...commonProps('Email')} direction='column' isDisabled/></Div>
      </div>
      <div className="form-row">
        <Div><InputText label='Área' {...commonProps('Area')} direction='column' isDisabled/></Div>
        <Div><InputText label='Aprobador' {...commonProps('Aprobador')} direction='column' isDisabled/></Div>
      </div>
      <div className="form-row">
        <Div col={3}><InputText label='Días disponibles' {...commonProps('DiasDisponibles')} placeholder='-' direction='column' isDisabled/></Div>
        <Div col={3}><InputText label='Días Seleccionados' value={diasVacacionesCalculado ?? "-"} placeholder='-' direction='column' isDisabled/></Div>
        {/* <div className="col-sm-6 d-flex align-items-center justify-content-center">
          <a className="btn generic-button-buscador" style={{width: "135px"}} target="_blank" href={formValues.LinkPolitica ?? ""}>
            Mira aquí la política
          </a>
        </div> */}
      </div>
      <div className="form-row">
        <Div><InputDate label='Desde' {...commonProps('StartDate')} direction='column' isDisabled={!formValues.PuedeEditarFechas}/></Div>
        <Div><InputDate label='Hasta' {...commonProps('EndDate')} direction='column' isDisabled={!formValues.PuedeEditarFechas}/></Div>
      </div>
      <div className="form-row">
        <Div col={12}><InputTextArea label='Observaciones' {...commonProps('Comentarios')} max={250} direction='column'/></Div>
      </div>
      <div className="form-row">
        <Div col={12}><InputUploadFile label="Cargar Firma" uploadFile={formValues.FileViewModel} setUploadFile={handleUploadFile} name="FileViewModel" validExtensions={["jpg", "png"]} sectionName={SECTION_NAME} /></Div>
      </div>
    </ModalForm>
  )
}

export default SolicitudVacacionesProcesoCierre;

const Div = ({ children, col = 6 }) => (
  <div className={`col-sm-${col}`}>
    {children}
  </div>
)

const dayAdded = [
  "Uno",
  "Dos",
  "Tres"
]

const mensajeModal = "El presente formulario permitirá que registres tus vacaciones con el período previamente definido, sólo deberás firmar y enviar el documento."