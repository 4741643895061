import React, {useEffect, useContext, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../consumers/httpRequiestsUtils";
import {useForm} from "../../hooks/useForm/UseForm";
import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput} from "../../validations/FormValidatorUtils";
import {
    deleteFileFromS3API, downloadReporteCumpleaniosAPI,
} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import SelectInput from "../../components/Select/SelectInput";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const ReporteDeOnomasticos = () => {

        const {state} = useContext(AuthContext);
        const seccionName = "ReporteDeOnomasticos";

        const [loading, setLoading] = useState(true);
        const [executePostRequest] = usePostRequest();

        const [formValues, setFormValues] = useState({});
        const [handleChange] = useForm(formValues, setFormValues);

        const [selectedMesInicio, setSelectedMesInicio] = useState({})
        const [selectedMesFin, setSelectedMesFin] = useState({})
        const [selectedDiaInicio, setSelectedDiaInicio] = useState({})
        const [selectedDiaFin, setSelectedDiaFin] = useState({})
        const [mesDiaJson] = useState({
            1: {id: 1, value: 'Enero', dias: 31},
            2: {id: 2, value: 'Febrero', dias: 29},
            3: {id: 3, value: 'Marzo', dias: 31},
            4: {id: 4, value: 'Abril', dias: 30},
            5: {id: 5, value: 'Mayo', dias: 31},
            6: {id: 6, value: 'Junio', dias: 30},
            7: {id: 7, value: 'Julio', dias: 31},
            8: {id: 8, value: 'Agosto', dias: 31},
            9: {id: 9, value: 'Septiembre', dias: 30},
            10: {id: 10, value: 'Octubre', dias: 31},
            11: {id: 11, value: 'Noviembre', dias: 30},
            12: {id: 12, value: 'Diciembre', dias: 31},
        })
        const [mesDiaOptions, setMesDiaOptions] = useState([])
        const [diaInicioOptions, setDiaInicioOptions] = useState([])
        const [diaFinOptions, setDiaFinOptions] = useState([])

        useEffect(() => {
            let unmounted = false;
            if (!unmounted) {
                init();
            }

            return () => {
                unmounted = true;
            };
        }, []);

        useDidMountEffect(() => {
            if (selectedMesInicio?.value){
                updateDiasOptionsByMes(selectedMesInicio?.value, setDiaInicioOptions, setSelectedDiaInicio);
            }
        }, [selectedMesInicio]);

        useDidMountEffect(() => {
        if (selectedMesFin?.value){
            updateDiasOptionsByMes(selectedMesFin?.value, setDiaFinOptions, setSelectedDiaFin);
        }
        }, [selectedMesFin]);

        const init = async () => {
            setLoading(true);
            parseToArray();
            setLoading(false);
        };

        const updateDiasOptionsByMes = function (mesId, setDiasFunction, setSelectedDia) {
            let nroDias = mesDiaJson[mesId]['dias'];
            let array = Array.from({length: nroDias}, (v, i) => {return { value: i+1, label: i+1}})
            setDiasFunction(array);
            setSelectedDia({ value: null, label: null });
        }

        const parseToArray = function () {
            const mesDiaArray = []

            for (let key of Object.keys(mesDiaJson)) {
                mesDiaArray.push({ value: key, label: mesDiaJson[key]['value']});
            }

            setMesDiaOptions(mesDiaArray);
        }

        const descargarReporte = async () => {
            let payload = {
                "StartMonth": selectedMesInicio?.value ?? null,
                "StartDay": selectedDiaInicio?.value ?? null,
                "EndMonth": selectedMesFin?.value ?? null,
                "EndDay": selectedDiaFin?.value ?? null,
            };

            let allInputKeys = ["fechaInicio", "StartMonth", "StartDay", "EndMonth", "EndDay"];
            let responseReporteGenerado = await executePostRequest(downloadReporteCumpleaniosAPI(), payload);
            if (responseCode200(responseReporteGenerado)) {
                clearAllInputValidationMessages(allInputKeys, seccionName);
                let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
                if (responseCode200(descargarReporteResponse)) {
                    await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
                } else {
                    mostrarMensajeDeErrorConToast(descargarReporteResponse);
                }
            } else if (responseTieneModelState(responseReporteGenerado)) {
                mostrarMensajesDeValidacionEnCadaInput(responseReporteGenerado, allInputKeys, seccionName);
            } else {
                clearAllInputValidationMessages(allInputKeys, seccionName);
                mostrarMensajeDeErrorConToast(responseReporteGenerado);
            }
        };

        const eliminarArchivoDeS3 = async function (filePath) {
            let deleteFileFromS3Payload = {
                "Path": filePath
            };
            let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
            if (responseCode200(deleteFileFromS3Response)) {
                console.debug("delete file from s3 successfully");
            } else {
                mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
            }
        };

        if (loading) return (<SpinnerCargandoSeccionFicha/>);
        return (
            <>
                <div className='card-body'>
                    <div id='ReporteDeOnomasticos'>
                        <div>
                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Inicio<span className="required">*</span></label>
                                <div className="col-sm-2">
                                    <SelectInput options={mesDiaOptions} jsonSelectedValue={selectedMesInicio} setJsonSelectedValue={setSelectedMesInicio} placeholder={"Seleccionar Mes"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-StartMonth-validation-message`}></p>
                                </div>
                                <div className="col-sm-2">
                                    <SelectInput options={diaInicioOptions} jsonSelectedValue={selectedDiaInicio} setJsonSelectedValue={setSelectedDiaInicio} placeholder={"Seleccionar Día"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-StartDay-validation-message`}></p>
                                </div>
                            </div>

                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label"></label>
                                <div className="col-sm-4">
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-fechaInicio-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos reportes-label-container">
                                <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Fin<span className="required">*</span></label>
                                <div className="col-sm-2">
                                    <SelectInput options={mesDiaOptions} jsonSelectedValue={selectedMesFin} setJsonSelectedValue={setSelectedMesFin} placeholder={"Seleccionar Mes"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-EndMonth-validation-message`}></p>
                                </div>
                                <div className="col-sm-2">
                                    <SelectInput options={diaFinOptions} jsonSelectedValue={selectedDiaFin} setJsonSelectedValue={setSelectedDiaFin} placeholder={"Seleccionar Día"} />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-EndDay-validation-message`}></p>
                                </div>
                            </div>
                        </div>

                        <div className="reporte-datos-generales-dato-obligatorio">
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>

                        <div>
                            <button className="btn btn-primary generic-button-ficha" onClick={descargarReporte}>Descargar Reporte</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;

export default ReporteDeOnomasticos;