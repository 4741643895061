import React from 'react'
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageTable from '../../../components/pageComponents/PageTable';
import FormSelect from '../../../components/atoms/Select';
import ActionsIcons from '../../../components/atoms/ActionsIcons';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import { handleFind } from '../../../helper/utils';
import CardTableMobile from './CardTableMobile';
import ReasignarSolicitud from '../adminSolicitudesCupon/modales/ReasignarSolicitud';
import DetalleAprobarRechazarSolicitud from './DetalleAprobarRechazarSolicitud';
import { inactivarActivarSolicitudCuponTiempoLibreAPI, reasignarAprobadorSolicitudCuponTiempoLibreAPI } from '../../../consumers/backendApisUrls';

const TableSolicitudes = ({ formValues, setFormValues, setModalEnabled, paginator, setPaginator, keys: KEYS, isColaborador = false }) => {
  const rows = formValues.Items;

  const HEADERS = [
    {col: "", label: 
      <div style={{ width: "130px" }}>
        Fecha Registro
      </div>
    },
    {col: "", label: "Código Cupón"},
    !isColaborador && {col: "", label: "Colaborador"},
    {col: "", label: 
      <div className='d-flex justify-content-center'>    
        <FormSelect
          options={handleFind(formValues.filters, KEYS.Cargos)}
          description="Cargo"
          selectedOptions={formValues.selectedFilters?.[KEYS.Cargos] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.Cargos)}
        />
      </div>
    },
    {col: "", label: 
      <div className='d-flex justify-content-center'>    
        <FormSelect
          options={handleFind(formValues.filters, KEYS.Sedes)}
          description="Sede"
          selectedOptions={formValues.selectedFilters?.[KEYS.Sedes] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.Sedes)}
        />
      </div>
    },
    {col: "", label: 
      <div className='d-flex justify-content-center'>    
        <FormSelect
          options={handleFind(formValues.filters, KEYS.Areas)}
          description="Área"
          selectedOptions={formValues.selectedFilters?.[KEYS.Areas] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.Areas)}
        />
      </div>
    },
    {col: "", label: 
      <div className='d-flex justify-content-center'>    
        <FormSelect
          options={handleFind(formValues.filters, KEYS.EstadosRegistro)}
          description={<label className='text-wrap mb-0' style={{width: "50px"}}>Estado Registro</label>}
          selectedOptions={formValues.selectedFilters?.[KEYS.EstadosRegistro] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.EstadosRegistro)}
        />
      </div>
    },
    {col: "", label: 
      <div className='d-flex justify-content-center'>    
        <FormSelect
          options={handleFind(formValues.filters, KEYS.EstadosSolicitud)}
          description={<label className='text-wrap mb-0' style={{width: "55px"}}>Estado Solicitud</label>}
          selectedOptions={formValues.selectedFilters?.[KEYS.EstadosSolicitud] ?? []}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS.EstadosSolicitud)}
        />
      </div>
    },
    {col: "", label: "Periodo Permiso"},
    {col: "", label: "Horario"},
    {col: "", label: 
      <div className='text-wrap' style={{ width: "65px" }}>
        Nivel Aprobación
      </div>
    },
    {col: "", label: "Aprobador"},
    {col: "", label: "Acciones"},
  ].filter(Boolean);

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues(lastData => ({...lastData, selectedFilters: updatedSelectedFilters}));
  }

  return (
    <Wrapper>
      <div className='mobile'>
        <CardTableMobile formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} isColaborador={isColaborador}/>
      </div>
      <div className='no-mobile'>
        <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
          <tbody className='listar-tbody'>
            {rows.map((row, i) => (
              <tr key={`${i}-${row.IdCuponeraTiempoLibre}`}>
                <td className="text-center">{row.FechaRegistro}</td>
                <td className="text-center">{row.CodigoCupon}</td>
                {!isColaborador && <td className="text-center">{row.NombresCompletos}</td>}
                <td className="">{row.Cargo}</td>
                <td className="">{row.Sede}</td>
                <td className="">{row.Area}</td>
                <td className="text-center" >{row.EstadoRegistro ? "Activo" : "Inactivo"}</td>
                <td className="text-center" >{row.EstadoSolicitud}</td>
                <td className="text-center" >
                  <div className='d-flex flex-column'>
                    <label>{row.FechaInicio}</label>
                    <label>{row.FechaFin}</label>
                  </div>
                </td>
                <td className="text-center" >{row.Horario ?? "-"}</td>
                {/* Esto se tiene que cambiar, se está poniendo hardcodeado por mientras datafake */}
                <td className="text-center" >Nivel 1/1</td>
                <td className="" >{row.Aprobador}</td>
                <td className="text-center" >
                  <ActionsIcons>
                    {row.MotivoRechazo &&
                      <ToolTip id={`MotivoRechazo${row.IdSolicitudCuponTiempoLibre}`} text={row.MotivoRechazo}/>
                    }
                    <FontAwesomeIcon
                      icon='table'
                      title='Ver Detalle'
                      size='lg'
                      onClick={() => setModalEnabled({ isEnable: true, component: DetalleAprobarRechazarSolicitud, data: { row, isColaborador }})}
                    />
                    {(!isColaborador && row.PuedeReasignar) &&
                      <FontAwesomeIcon 
                        icon='male'
                        title='Reasignar'
                        size='lg'
                        onClick={() => setModalEnabled({ isEnable: true, component: ReasignarSolicitud, data: { row, api: reasignarAprobadorSolicitudCuponTiempoLibreAPI }})}
                      />
                    }
                    {(!isColaborador && row.PuedeActivarInactivar) &&
                      <FontAwesomeIcon
                        icon={row.EsActivo ? 'ban' : 'circle-notch'}
                        title={row.EsActivo  ? 'Inactivar' : 'Activar'}
                        size='lg'
                        onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { title: row.EsActivo ? "Inactivar" : "Activar", actionLabel: row.EsActivo ? "inactivar" : "activar", api: inactivarActivarSolicitudCuponTiempoLibreAPI, name: `${row.CodigoCupon} - ${row.NombresCompletos}`, payload: {IdSolicitudCuponTiempoLibre: row.IdSolicitudCuponTiempoLibre}}})}
                      />
                    }
                  </ActionsIcons>
                </td>
              </tr>
            ))}
          </tbody>
        </PageTable>
      </div>
    </Wrapper>
  )
}

export default TableSolicitudes;

const ToolTip = ({ id, text }) => (
  <div>
    <FontAwesomeIcon data-tip data-for={id} icon='info-circle' title='Motivo de No Aprobación' size='lg' style={{color: "#b3a11a"}} />
    <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
        Motivo de No Aprobación: {text}
    </ReactTooltip>
  </div>
)

const Wrapper = styled.div`
  .mobile{
    display: none;
  }
  @media (max-width: 992px) {
    .mobile {
      display: block;
    }
    .no-mobile{
      display: none;
    }  
  }
`;