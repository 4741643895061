import React from 'react'
import { DivInput } from '../atoms/DivInput';
import EditorQuill from '../atoms/EditorQuill';

const InputEditorHtml = ({
  label,
  name,
  value,
  onChange,
  sectionName,
  isRequired,
  colLabel = 4,
  colInput = 8,
  placeholder = `Ingresar ${label}`,
  direction = "row",
  minHeight = "12rem",
  maxHeight = "20rem"
}) => {
  const handleOnChange = (res) => {
    const textHtml = (res === undefined || res === "<p><br></p>") ? null : res;
    let data = {
      target: {
        value: textHtml,
        name: name,
      },
    };
    onChange(data);
  };

  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color`}>
        <EditorQuill
          placeholder={placeholder}
          handleChange={handleOnChange}
          value={value}
          minHeight={minHeight}
          maxHeight={maxHeight}
        />
        {sectionName && name && (
          <p
            className="ficha-form-input-validation-message"
            id={`ficha-edit-${sectionName}-${name}-validation-message`}
          ></p>
        )}
      </div>
    </DivInput>
  )
}

export default InputEditorHtml