import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import TableSolicitudes from '../components/TableSolicitudes'
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageButtons from '../../../components/pageComponents/PageButtons'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import { AuthContext } from '../../../hooks/context/authContext'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo'
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils'
import { getAllAreasAPI, getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, listarSolicitudesCuponTiempoLibreAPI, listarSolicitudesCuponTiempoLibreDescargarExcelAPI } from '../../../consumers/backendApisUrls'

const AdminSolicitudesCupon = () => {

  const INIT_KEYS = { Cargos: "IdsCargos", Sedes: "IdsSedes", Areas: "IdsAreas", EstadosSolicitud: "IdsEstadosSolicitud", EstadosRegistro: "IdsEstadosRegistro" };

  const {state} = useContext(AuthContext);
  const IdEmpleado = state.user.id_empleado;

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllAreasAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = listarSolicitudesCuponTiempoLibreAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      IdEmpleado,
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleDownloadExcel = async () => {
    const api = listarSolicitudesCuponTiempoLibreDescargarExcelAPI();
    let payload = getPayload();
    await downloadAndDelete(api, payload);
  }

  const handleCleanSearch = () => {
    setFormValues({...formValues, selectedFilters: null});
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Gestión de Solicitudes de Cupones de tiempo libre" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={handleCleanSearch}>
        <ButtonsWrapper>
          <PageButtons hasCreateButton labelCreate='Descargar' handleCreateButton={handleDownloadExcel} >
           {formValues.ExtraData.PuedeConfigurar && 
            <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Acciones</span>}>
              <Link to="adminCuponeraTiempoLibre/listaCuponera" className='dropdown-item'>Lista Cuponera</Link>
              <Link to="adminCuponeraTiempoLibre/listaCupones" className='dropdown-item'>Lista Cupones</Link>
            </NavDropdown>
            }
          </PageButtons>
        </ButtonsWrapper>
      </SearchContainer>
      <TableSolicitudes formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS} />
    </>
  )
}

export default AdminSolicitudesCupon;

const ButtonsWrapper = styled.div`
  display: block;
  @media (max-width: 796px){
    display: none;
  }
`;