import React from 'react';

const TableWebBodyVacacionesDisponibles = ({filas, showModalMensajeConfirmar, }) => {
  return (
    <tbody className="listar-tbody">
      {filas?.map((fila, i) => (
            <tr key={i}>
              <td className="col-3">{fila?.NombreCompleto}</td>
              <td className="col-2">{fila?.Area}</td>
              <td className="col-2">{fila?.Cargo}</td>
              <td className="text-center">{fila?.FechaIngreso}</td>
              <td className="col-1 text-center">{fila?.VacacionesDisponibles}</td>
              <td className="col-1 text-center">{fila?.VacacionesProgramadas}</td>
              <td className="col-1 text-center">
                {fila?.PuedeVerCheck &&
                  <input type='checkbox' name="permitirVacaciones" onChange={() => showModalMensajeConfirmar(fila?.IdEmpleado, fila?.NombreCompleto, fila?.PuedeSolicitar)} checked={fila?.PuedeSolicitar ?? false}/>
                }
              </td>
            </tr>
      ))}
    </tbody>
  )
}

export default TableWebBodyVacacionesDisponibles