import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import FormSelect from "../../components/atoms/Select";
import Paginator from "../directorio/Paginator";
import TableWebBodyUsuarios from "./TableWebBodyUsuarios";
import RegistrarUsuarioModal from "./modales/RegistrarUsuarioModal";
import EditarUsuarioModal from "./modales/EditarUsuarioModal";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getAllDepartamentosAPI, getAllSedesAPI, getRolesEstadosAPI, seguridadListarUsuariosAPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Spinner} from "react-bootstrap";

const GestionDeUsuarios = () => {
    const {state} = useContext(AuthContext);

    const [hideRegistrarUsuarioModal, setHideRegistrarUsuarioModal] = useState(true);
    const [hideEditarRolModal, setHideEditarRolModal] = useState(true);
    const [loading, setLoading] = useState(true);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const [idUsuarioEditar, setIdUsuarioEditar] = useState();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [sedesSeleccionados, setSedesSeleccionados] = useState([]);
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [sedesFiltradosOptions, setSedesFiltradosOptions] = useState([]);
    const [departamentosFiltradosOptions, setDepartamentosFiltradosOptions] = useState([]);
    const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
    const [cleanFilterss, setCleanFilters] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, sedesSeleccionados, departamentosSeleccionados, estadosSeleccionados]);

    useEffect(() => {
        manageSearch();
    }, [sedesSeleccionados, page, departamentosSeleccionados, estadosSeleccionados]);


    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setSedesFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setDepartamentosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getRolesEstadosAPI()), setEstadosFiltradosOptions);
            manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("sedesSeleccionados: ", sedesSeleccionados);
        console.debug("departamentosSeleccionados: ", departamentosSeleccionados);
        console.debug("estadosSeleccionados: ", estadosSeleccionados);
        console.debug("page: ", page);
        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdSedes": sedesSeleccionados,
            "IdDepartamentos": departamentosSeleccionados,
            "IdEstados": estadosSeleccionados,
            "Page": page
        };

        console.debug("payload: ", payload);

        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        const response = await executePostRequest(seguridadListarUsuariosAPI(), payload);
        if (responseCode200(response)) {
            setUsuariosFiltrados(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setSedesSeleccionados([]);
        setDepartamentosSeleccionados([]);
        setEstadosSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
    };

    const showRegistrarUsuarioModal = async () => {
        setHideRegistrarUsuarioModal(!hideRegistrarUsuarioModal);
        await getInitialData();
    };

    const showEditarUsuarioModal = async (IdUsuario) => {
        if (IdUsuario) {
            setIdUsuarioEditar(IdUsuario);
        }

        setHideEditarRolModal(!hideEditarRolModal);
        await getInitialData();
    };

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Gestión de Usuarios no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                <div className="modal-contactanos modal-response-ficha-background" hidden={hideRegistrarUsuarioModal}>
                    {hideRegistrarUsuarioModal === false &&
                    <RegistrarUsuarioModal showModal={showRegistrarUsuarioModal}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideEditarRolModal}>
                    {hideEditarRolModal === false &&
                    <EditarUsuarioModal showModal={showEditarUsuarioModal} idUsuarioEditar={idUsuarioEditar} manageSearch={manageSearch}/>
                    }
                </div>

                <Wrapper className='App'>
                    <PageHeader title={"Gestión de Usuarios"}/>
                    {loading === true &&
                    <div className="spinner-cargando-container">
                        <Spinner animation="grow" variant="primary" size="1sm" />
                    </div>
                    }

                    {loading !== true && (<>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por Nombre'
                                value={criterioDeBusqueda}
                                onChange={(e) => {
                                    setCriterioDeBusqueda(e.target.value);
                                }}
                                onKeyPress={handleKeypress}
                            />
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                            <button
                                onClick={showRegistrarUsuarioModal}
                                type='button'
                                className='generic-button-buscador'>
                                Nuevo Registro
                            </button>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className="table-scroll">
                            <table className='table table-hover'>
                                <thead className="listar-thead">
                                <tr>
                                    <th>
                                        <div className='flex font-gothan-medium'>Id</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Usuario</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Nombre</div>
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={sedesFiltradosOptions}
                                            description={'Sede'}
                                            setSelectedOptions={setSedesSeleccionados}
                                            selectedOptions={sedesSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={departamentosFiltradosOptions}
                                            description={'Departamento'}
                                            setSelectedOptions={setDepartamentosSeleccionados}
                                            selectedOptions={departamentosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={estadosFiltradosOptions}
                                            description={'Estado'}
                                            setSelectedOptions={setEstadosSeleccionados}
                                            selectedOptions={estadosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Acciones</div>
                                    </th>
                                </tr>
                                </thead>
                                <TableWebBodyUsuarios users={usuariosFiltrados} showEditarRolModal={showEditarUsuarioModal} manageSearch={manageSearch}/>
                            </table>
                        </div>

                            {totalPages > 1 && (
                                <Paginator totalItems={totalItems}
                                           firsItemPage={firsItemPage}
                                           lastItemPage={lastItemPage}
                                           totalPages={totalPages}
                                           page={page}
                                           onLeftClic={onLeftClic}
                                           onRightClic={onRightClic}/>
                            )}

                    </div>
                    </>)}
                </Wrapper>
            </div>
        </>
    );
};

export default GestionDeUsuarios;
