import React from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { guardarLecturaPoliticaAPI } from '../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';

const CompromisoLecturaModal = ({ toggleModal, datosCompromisoLectura }) => {
  const [managePost] = useManagePostRequest();

  const guardarCompromisoLectura = async (id) => {
    await managePost(guardarLecturaPoliticaAPI(), {}, () => {
      toggleModal();
    }, true);
  }

  return (
    <GenericModal showModal={toggleModal} width={"1180px"} titulo={"Detalle"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          <p>
            {datosCompromisoLectura}
          </p>
        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button
              className="btn btn-primary generic-button-ficha-modal"
              onClick={() => guardarCompromisoLectura()}>
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </GenericModal>
  )
}

export default CompromisoLecturaModal