import React, { useState, useEffect } from "react";
import PageTable from "../../../../components/pageComponents/PageTable";
import PageWrapper from "../../../../components/pageComponents/PageWrapper";
import SearchContainer from "../../../../components/pageComponents/SearchContainer";
import TableSelectSearch from "../../../../components/atoms/TableSelectSearch";
import useDidMountEffect from "../../../../hooks/useDidMountEffect/useDidMountEffect";
import { useGetRequest } from "../../../../hooks/useGetRequest/useGetRequest";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";
import MensajeConfirmacionModal from "../modales/MensajeConfirmacionModal";
import TableWebBodyVacacionesDisponibles from "./TableWebBodyVacacionesDisponibles";
import { getAllAreasAPI, getAllCargosAPI, listarVacacionesDisponiblesAPI, listarVacacionesDisponiblesAPIMatricial, descargarVacacionesDisponiblesAPI, descargarVacacionesDisponiblesAPIMatricial } from "../../../../consumers/backendApisUrls";
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from "../../../../helper/utils";
import { useGenerarDescargarEliminarArchivo } from "../../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import { TIPOS_LISTAR_VACACIONES } from "../../../../constants/hardCodedConstants";

const VacacionesDisponibles = ({filterType}) => {

  const INIT_KEYS = { Areas: "IdsAreas", Cargos: "IdsCargos" };
  const initFormValues = {Items: [], ExtraData: {}, filters: [], selectedFilters: {}, EsOrdenFechaInicioAscendente: true};

  const [empleado, setEmpleado] = useState({});
  const [formValues, setFormValues] = useState(initFormValues);
  const [allFilters, setAllFilters] = useState([]);
  
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [showModalMensajeConfirmar, setShowModalMensajeConfirmar] = useState(false);

  const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

  const [executePost] = useManagePostRequest();
  const [executeGet] = useGetRequest();

  const initialPromises = () => {
    return [
      executeGet(getAllAreasAPI()),
      executeGet(getAllCargosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      manageSearch();
    } catch (error) {
        console.error(error);
    }
    setIsLoading(false);
  };

  const manageSearch = async () => {

    const api = filterType != TIPOS_LISTAR_VACACIONES.Matricial.value ? listarVacacionesDisponiblesAPI() :     listarVacacionesDisponiblesAPIMatricial();
    const payload = getPayload();
    await executePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    const { EsOrdenFechaInicioAscendente, selectedFilters } = formValues;
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      EsOrdenFechaInicioAscendente, 
      ...selectedFilters
    };
  };

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  };

  const handleSelectedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues({...formValues, selectedFilters: updatedSelectedFilters});
  }

  const addedCleanSearch = () => {
    setFormValues({...formValues, selectedFilters: null});
  }

  const toggleMensajeConfirmar = (IdEmpleado, NombreCompleto, PuedeSolicitar) => {
    if (IdEmpleado && NombreCompleto) setEmpleado({IdEmpleado, NombreCompleto, PuedeSolicitar});
    setShowModalMensajeConfirmar(!showModalMensajeConfirmar);
  };

  const handleDescargarVacacionesDisponible = async () => {

    const api = filterType != TIPOS_LISTAR_VACACIONES.Matricial.value ? descargarVacacionesDisponiblesAPI() :     descargarVacacionesDisponiblesAPIMatricial();

    const payload = getPayload();
    await generarDescargarEliminarArchivo(api, payload);
  }

  const HEADERS_TABLE = [
    {col: "", label: "Empleado"},
    {col: "", label:
      <TableSelectSearch
        width="300px" 
        options={handleFind(formValues.filters, INIT_KEYS.Areas)}
        description="Áreas"
        setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.Areas)}
      />
    },
    {col: "", label:
      <TableSelectSearch
        width="300px" 
        options={handleFind(formValues.filters, INIT_KEYS.Cargos)}
        description="Cargos"
        setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.Cargos)}
      />
    },
    {col: "col-sm-1 py-0", label: 
      <>
        <div className='flex font-gothan-medium'>Fecha Inicio</div>
        <div className="custom-control custom-switch">
          <input className="custom-control-input hand-pointer" type="checkbox" checked={formValues.EsOrdenFechaInicioAscendente} onChange={({ target }) => {setFormValues({...formValues, EsOrdenFechaInicioAscendente: target.checked})}} id="admin-vacaciones-disponibles-fecha" />
          <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-vacaciones-disponibles-fecha">
              Más reciente
          </label>
        </div>
      </>
    },
    {col: "", label: "Vac. Disponibles"},
    {col: "", label: "Vac. Programadas"},
    {col: "", label: "Acciones"},
  ]

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters, formValues.EsOrdenFechaInicioAscendente , filterType]);

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleSelectedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {showModalMensajeConfirmar &&
        <div className="modal-contactanos modal-response-ficha-background">
          <MensajeConfirmacionModal showModal={toggleMensajeConfirmar} empleado={empleado} manageSearch={manageSearch}/>
        </div>
        
      }

      <PageWrapper isLoading={isLoading}>
        <div style={{"padding-top": "5px"}}>
            <div style={{"float": "right", "margin-top":"20px"}}> 
              <button className="btn generic-button-buscador" type="button" title="Descargar" onClick={handleDescargarVacacionesDisponible}>
                Descargar Excel
              </button>
            </div>
        <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} addCleanSearch={addedCleanSearch} setPaginator={setPaginator} manageSearch={manageSearch}/>
       
        <PageTable headers={HEADERS_TABLE} paginator={paginator} setPaginator={setPaginator}>
          <TableWebBodyVacacionesDisponibles 
            filas={formValues.Items}
            showModalMensajeConfirmar={toggleMensajeConfirmar}
          />
        </PageTable>
        </div>
      </PageWrapper>
    </>
  )
}

export default VacacionesDisponibles