import React from 'react';
import Icon from './Icon';

const EquisCircleIcon = ({color, size}) => {
    return (
        <Icon viewBox='0 0 24 24' color={color} size={size}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z'
                fill='white'
                fillOpacity='0.87'
            />
        </Icon>
    );
};

export default EquisCircleIcon;
