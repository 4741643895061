import React, { useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from '../../../components/Select/SelectInput';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useForm } from '../../../hooks/useForm/UseForm';
import { getTiposTemplateAPI, crearEtiquetaAPI } from '../../../consumers/backendApisUrls';

const CrearEtiqueta = ({ toggleModal, refreshPage }) => {

    const SECTION_NAME = "AgregarNuevaEtiqueta";
    const KEYS = {Name: "NombreEtiqueta", TagType: "TipoEtiqueta", Tag: "Etiqueta", TemplateType: "IdTemplateType"}

    const [formValues, setFormValues] = useState({[KEYS.TagType]: 'Text'});
    const [templateTypes, setTemplateTypes] = useState([]);
    const [isSending, setIsSending] = useState(false);

    const [handleChange] = useForm(formValues, setFormValues);

    const [submitForm] = useSubmitForm();
    const [getOptions] = useGetOptionsSelect();

    const init = async () => {
        setTemplateTypes(await getOptions(getTiposTemplateAPI()));
    }

    const submit = async() => {
        const api = crearEtiquetaAPI();
        let payload = armarPayload();
        setIsSending(true);
        await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setIsSending(false));
    }

    const armarPayload = () => {
        return {
            ...formValues, 
            [KEYS.TemplateType]: formValues[KEYS.TemplateType]?.value
        }
    }

    const successSubmitCallback = () => {
        setIsSending(false);
        refreshPage();
        toggleModal();
    }

    useEffect(() => {
        init();
    }, [])

    return(
        <>
            <GenericModal showModal={toggleModal} width={"900px"} titulo={"Agregar nueva etiqueta"}>
                <div className="modal-body pt-0 pl-0">
                    <form onSubmit={(e) => e.preventDefault()}>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label required">Nombre</label>
                            <div className="col-8 ml-3 black-color">
                                <input type="text" className="form-control" placeholder='Ingresar nombre' name={KEYS.Name} onChange={handleChange} value={formValues[KEYS.Name] ?? ""}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Name}-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label required">Tipo de Plantilla</label>
                            <div className="col-8 ml-3 black-color">
                                <SelectInput 
                                    options={templateTypes}
                                    jsonSelectedValue={formValues[KEYS.TemplateType] ?? ""}
                                    setJsonSelectedValue={(res) => setFormValues(lastData => ({...lastData, [KEYS.TemplateType]: res}))}
                                    placeholder="Seleccionar"
                                    resetSelectedValue={false}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.TemplateType}-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label required">Tipo de Etiqueta</label>
                            <div className="col-8 ml-3 mb-3 black-color">
                                <div className="form-check">
                                    <input id='TipoEtiquetaText' className="form-check-input modal-ceses-input-radio-button" type="radio" checked={formValues[KEYS.TagType] == "Text"} onChange={handleChange} name={KEYS.TagType} value="Text"></input>
                                        <label className="form-check-label modal-ceses-label-radio-button" htmlFor='TipoEtiquetaText'>
                                            Text
                                        </label>
                                </div>
                                <div className="form-check">
                                    <input id='TipoEtiquetaDate' className="form-check-input modal-ceses-input-radio-button" type="radio" checked={formValues[KEYS.TagType] == "Date"} onChange={handleChange} name={KEYS.TagType} value="Date"></input>
                                        <label className="form-check-label modal-ceses-label-radio-button" htmlFor='TipoEtiquetaDate'>
                                            Date
                                        </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label required">Consulta SQL</label>
                            <div className="col-8 ml-3 black-color">
                                <textarea name={KEYS.Tag} id='crearEtiqueta'
                                    cols="57"
                                    rows="6"
                                    value={formValues[KEYS.Tag] ?? ""}
                                    onChange={handleChange}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Tag}-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button 
                            className="btn btn-primary generic-button-ficha-modal"
                            onClick={submit}
                            disabled={isSending}>
                            Guardar
                        </button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default CrearEtiqueta