import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CardContrato = ({allData, handleDownloadFile}) => (
    allData.length ?
    <div>
        <br/>
        <table className="table table-hover">
            <thead className="">
                <tr>
                    <th className="text-center col-sm-4 font-gothan-medium">Proceso</th>
                    <th className="text-center col-sm-4 font-gothan-medium">Nombre de documento</th>
                    <th className="text-center col-sm-3 font-gothan-medium">Fecha Generacion</th>
                    <th className="text-center col-sm-1 font-gothan-medium">Archivo</th>
                </tr>
            </thead>
            <tbody>
                {allData?.map((data, index) => 
                <>
                    {data?.Documentos.map((item, i) => (
                        <tr className="" key={`contrato-${index}-${i}`}>
                            {i == 0 && <td className="p-2 pl-4 align-middle col-sm-4" rowSpan={data?.Documentos.length}>{data?.NombreProcesoCierre}</td>}
                            <td className="p-2 col-sm-4">{item?.NombreDocumento}</td>
                            <td className="p-2 text-center col-sm-3">{item?.FechaGeneracion}</td>
                            {item?.FileViewModel &&
                            <td className="p-2 text-center col-sm-1">
                                <FontAwesomeIcon icon='paperclip' className="hand-pointer blue-standard-color" title="Descargar" 
                                    onClick={() => {handleDownloadFile(item?.FileViewModel?.Path, item?.FileViewModel?.OriginalFileName)}}
                                />
                            </td>
                            }
                        </tr>
                    ))}  
                </>
                )}
            </tbody>
        </table>
    </div> :
    <p className='ml-5 mt-2 blue-standard-color font-gothan-light'>No hay registros</p>
)

export const CardActividadesPendientes = ({allData, handleDownloadFile}) => (
    allData.length ?
    <div>
        <br/>
        <table className="table table-detalle-colaborador">
            <thead className="thead-light">
            <tr>
                <th className="text-center col-sm-4 font-gothan-medium">Nombre de Flujo</th>
                <th className="text-center col-sm-4 font-gothan-medium">Nombre de documento</th>
                <th className="text-center col-sm-3 font-gothan-medium">Fecha de Modificación</th>
                <th className="text-center col-sm-1 font-gothan-medium">Archivo</th>
            </tr>
            </thead>
            <tbody>
            {allData?.map((data, index) => (
                <tr className="" key={`actividadPendiente-${index}`}>
                    <td className="p-2 pl-4 col-sm-4">{data?.NombreFlujo}</td>
                    <td className="p-2 col-sm-4">{data?.NombreDocumento}</td>
                    <td className="p-2 text-center col-sm-3">{data?.FechaModificacion}</td>
                    <td className="p-2 text-center col-sm-1">
                        <FontAwesomeIcon icon='paperclip' className="hand-pointer blue-standard-color" title="Descargar"
                            onClick={() => {handleDownloadFile(data?.FileViewModel?.Path, data?.FileViewModel?.OriginalFileName);
                        }
                        }/>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div> :
    <p className='ml-5 mt-2 blue-standard-color font-gothan-light'>No hay registros</p>
)