import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/atoms/DirectorioWrapper";
import FormSelect from "../../components/atoms/Select";
import Paginator from "../../components/atoms/Paginator";
import TableWebBody from "./TableWebBody";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import PageHeader from "../../components/pageHeader/PageHeader";
import {criterioDeBusquedaVacio, obtenerPalabrasDeCriterioDeBusqueda, obtenerUltimaPagina} from "../../helper/Listas/ListasUtils";
import {toast} from "react-toastify";
import {Dropdown, Spinner} from "react-bootstrap";
import RechazarSolicitudModal from "./RechazarSolicitudModal";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {aprobarSolicitudAPI, getAllSolicitudesAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";

const AdminSolicitudCambios = () => {
    const [loading, setLoading] = useState(true);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [cleanFilterss, setCleanFilters] = useState(false);
    const filasPorPagina = 20;

    const [allSolicitudes, setAllSolicitudes] = useState([]);
    const [solicitudesFiltradas, setSolicitudesFiltradas] = useState([]);

    //"Filtros header table" start
    const [allSeccionesOptions, setAllSeccionesOptions] = useState([]);
    const [seccionesFiltradosOptions, setSeccionesFiltradosOptions] = useState([]);
    const [seccionesSeleccionados, setSeccionesSeleccionados] = useState([]);


    const [allEstadosOptions, setAllEstadosOptions] = useState([]);
    const [estadosFiltradosOptions, setEstadosFiltradosOptions] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);

    const [allCamposOptions, setAllCamposOptions] = useState([]);
    const [camposFiltradosOptions, setCamposFiltradosOptions] = useState([]);
    const [camposSeleccionados, setCamposSeleccionados] = useState([]);
    //"Filtros header table" fin

    const [fromIndex, setFromIndex] = useState();
    const [toIndex, setToIndex] = useState();
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState();
    const [totalDeRegistrosFiltrados, setTotalDeRegistrosFiltrados] = useState([]);

    const [idsFichaCamposSolicitudesConCheck, setIdsFichaCamposSolicitudesConCheck] = useState([]);
    const [hideRechazarSolicitudModal, setHideRechazarSolicitudModal] = useState(true);

    const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        let finalFiltered = obtenerRegistrosDePagina(allSolicitudes);
        setSolicitudesFiltradas(finalFiltered);
    }, [allSolicitudes]);

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, seccionesSeleccionados, estadosSeleccionados, camposSeleccionados]);

    useDidMountEffect(() => {
        manageSearch();
    }, [criterioDeBusqueda, seccionesSeleccionados, estadosSeleccionados, camposSeleccionados, page]);

    useDidMountEffect(() => {
        console.debug("fechaMasRecientePrimeroBool ", fechaMasRecientePrimeroBool);
        if (solicitudesFiltradas) {
            let sortedSolicitudes = ordenarSolicitudesPorFecha(solicitudesFiltradas, fechaMasRecientePrimeroBool);
            setSolicitudesFiltradas(sortedSolicitudes);
        }
    }, [fechaMasRecientePrimeroBool]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            const response = await executeGetRequest(getAllSolicitudesAPI());
            if (responseCode200(response)) {

                let solitudess = response.data.data;
                let sortedSolicitudes = ordenarSolicitudesPorFecha(solitudess, fechaMasRecientePrimeroBool);
                setAllSolicitudes(sortedSolicitudes);

                let lastPaged = obtenerUltimaPagina(response.data.data, filasPorPagina);
                setLastPage(lastPaged);
                setTotalDeRegistrosFiltrados(response.data.data.length);

                setAllSeccionesOptions(response.data.dropdowns.secciones);
                setSeccionesFiltradosOptions(response.data.dropdowns.secciones);
                
                setAllEstadosOptions(response.data.dropdowns.estados);
                setEstadosFiltradosOptions(response.data.dropdowns.estados);

                setAllCamposOptions(response.data.dropdowns.campos);
                setCamposFiltradosOptions(response.data.dropdowns.campos);

                setIdsFichaCamposSolicitudesConCheck([]);

            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
        }
    };

    const fechaMasRecienteAlFinal = (a, b) => {
        return new Date(a.FechaSolicitudValue).getTime() - new Date(b.FechaSolicitudValue).getTime();
    };

    const fechaMasRecientePrimero = (a, b) => {
        return new Date(b.FechaSolicitudValue).getTime() - new Date(a.FechaSolicitudValue).getTime();
    };

    const ordenarSolicitudesPorFecha = (solitudess, fechaMasRecientePrimeroBooll) => {
        let tempSolicitudes = [...solitudess];
        if (fechaMasRecientePrimeroBooll === true) {
            tempSolicitudes.sort(fechaMasRecientePrimero);
        }
        if (fechaMasRecientePrimeroBooll === false) {
            tempSolicitudes.sort(fechaMasRecienteAlFinal);
        }
        return tempSolicitudes;
    };

    const filtrarPorCriterioDeBusqueda = (solicitudes) => {
        let palabras = obtenerPalabrasDeCriterioDeBusqueda(criterioDeBusqueda);

        return  solicitudes.filter((solicitud) => {
            for (let palabra of palabras) {
                if (solicitud.NombreCompleto.toLowerCase().includes(palabra)) {
                    return solicitud;
                }
            }
        });
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const todosLosDropdownsNoTienenElementosSeleccionados = () => {
        return (
            (seccionesSeleccionados && seccionesSeleccionados.length == 0) &&
            (estadosSeleccionados && estadosSeleccionados.length == 0) &&
            (camposSeleccionados && camposSeleccionados.length == 0)
        )
    };

    const obtenerRegistrosDePagina = (solicitudes) => {
        let fromIndexx = (page === 1) ? 0 : filasPorPagina * (page - 1);
        let toIndexx = page * filasPorPagina - 1;

        setFromIndex(fromIndexx);
        setToIndex(toIndexx);

        return solicitudes.slice(fromIndexx, toIndexx + 1);
    };

    const actualizarOptionsSegunCriterioDeBusqueda = (solicitudesFiltered) => {
        let newSeccionesOptions = [];
        let newEstadosOptions = [];
        let newCamposOptions = [];

        for (let solicitud of solicitudesFiltered) {
            for (let seccionOption of allSeccionesOptions) {
                if (solicitud.IdSeccion == seccionOption.Value && (!newSeccionesOptions.some(option => option.Value === seccionOption.Value))) {
                    newSeccionesOptions.push(seccionOption);
                }
            }

            for (let estadoOption of allEstadosOptions) {
                if (solicitud.IdEstado == estadoOption.Value && (!newEstadosOptions.some(option => option.Value === estadoOption.Value))) {
                    newEstadosOptions.push(estadoOption);
                }
            }

            for (let camposOption of allCamposOptions){
                if (solicitud.IdNombreCampo == camposOption.Value && (!newCamposOptions.some(option => option.value === camposOption.value))){
                    newCamposOptions.push(camposOption);
                }
            }
            
        }

        setSeccionesFiltradosOptions(newSeccionesOptions);
        setEstadosFiltradosOptions(newEstadosOptions);
        setCamposFiltradosOptions(newCamposOptions);
    };

    const resetOptions = () => {
        setSeccionesFiltradosOptions(allSeccionesOptions);
        setEstadosFiltradosOptions(allEstadosOptions);
        setCamposFiltradosOptions(allCamposOptions);
    };

    const aplicarFiltrosYCriterioDeBusqueda = (solicitudesFiltered) => {
        let temp = allSolicitudes;

        temp = (seccionesSeleccionados && seccionesSeleccionados.length > 0) ? temp.filter((solicitud) => seccionesSeleccionados.includes(solicitud.IdSeccion)) : temp;
        temp = (estadosSeleccionados && estadosSeleccionados.length > 0) ? temp.filter((solicitud) => estadosSeleccionados.includes(solicitud.IdEstado)) : temp;
        temp = (camposSeleccionados && camposSeleccionados.length > 0) ? temp.filter((solicitud) => camposSeleccionados.includes(solicitud.IdNombreCampo)) : temp;

        if (!criterioDeBusquedaVacio(criterioDeBusqueda)) {
            temp = filtrarPorCriterioDeBusqueda(temp);
        }

        solicitudesFiltered = temp;
        return solicitudesFiltered;
    };

    const manageSearch = () => {
        let solicitudesFiltered = [];

        if (criterioDeBusquedaVacio(criterioDeBusqueda) && todosLosDropdownsNoTienenElementosSeleccionados()) {
            solicitudesFiltered = allSolicitudes;
            resetOptions();
        } else {
            solicitudesFiltered = aplicarFiltrosYCriterioDeBusqueda(solicitudesFiltered);
        }

        setTotalDeRegistrosFiltrados(solicitudesFiltered.length);

        let pageNumber = obtenerUltimaPagina(solicitudesFiltered, filasPorPagina);
        setLastPage(pageNumber);

        let registros = obtenerRegistrosDePagina(solicitudesFiltered);

        let sortedSolicitudes = ordenarSolicitudesPorFecha(registros, fechaMasRecientePrimeroBool);
        setSolicitudesFiltradas(sortedSolicitudes);

        if (!criterioDeBusquedaVacio(criterioDeBusqueda) && todosLosDropdownsNoTienenElementosSeleccionados()) {
            actualizarOptionsSegunCriterioDeBusqueda(solicitudesFiltered);
        }
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, lastPage);
        setPage(nextPage);
    };


    const limpiarBusqueda = () => {
        setSeccionesSeleccionados([]);
        setEstadosSeleccionados([]);
        setCamposSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setFechaMasRecientePrimeroBool(true);
    };

    const onChangeCriterioBusqueda = (e) => {
        setCriterioDeBusqueda(e.target.value);
    };

    const haySolicitudesSelecionadas = () => {
        let haySolicitudesSeleccionadass = (idsFichaCamposSolicitudesConCheck.length > 0) ? true : false;
        if (!haySolicitudesSeleccionadass) {
            toast.warn("Seleccionar al menos una solicitud");
        }
        return haySolicitudesSeleccionadass;
    };

    const aprobarSolicitudes = async () => {
        console.debug("aprobarSolicitudes");
        console.debug("idsFichaCamposSolicitudesConCheck ", idsFichaCamposSolicitudesConCheck);
        if (haySolicitudesSelecionadas()) {
            for (let idFichaCampoSolicitud of idsFichaCamposSolicitudesConCheck) {
                let response = await executePostRequest(aprobarSolicitudAPI(idFichaCampoSolicitud), {});
                if (responseCode200(response)) {
                    toast.success("Se aprobó solicitud con éxito");
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
            await getInitialData();
        }

    };

    const rechazarSolicitudes = async () => {
        console.debug("rechazarSolicitudes");
        console.debug("idsFichaCamposSolicitudesConCheck ", idsFichaCamposSolicitudesConCheck);

        if (haySolicitudesSelecionadas()) {
            showRechazarSolicitudModal();
        }
    };

    const showRechazarSolicitudModal = () => {
        let currentState = hideRechazarSolicitudModal;
        setHideRechazarSolicitudModal(!currentState);
    };

    const onChangeFechaMasRecientePrimeroBool = (e) => {
        let inputValue = e.target.checked;
        setFechaMasRecientePrimeroBool(inputValue);
    };

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    return (
        <>
            <div id="modal-contactanos" className="modal-contactanos modal-response-ficha-background " hidden={hideRechazarSolicitudModal}>
                {hideRechazarSolicitudModal === false && <RechazarSolicitudModal showRechazarSolicitudModal={showRechazarSolicitudModal} idsFichaCamposSolicitudesConCheck={idsFichaCamposSolicitudesConCheck} allSolicitudes={allSolicitudes} getInitialData={getInitialData}/>}
            </div>
            <Wrapper className='App'>
                <PageHeader title={"Gestión de Solicitudes de cambio"}/>
                <div className='container-fluid body-content'>
                    <div className='input-group mb-3 buscador'>
                        <input type='text' className='input-buscador' placeholder='Buscar por nombre y/o apellidos' value={criterioDeBusqueda} onChange={onChangeCriterioBusqueda} onKeyPress={handleKeypress}/>
                        <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                                    Acciones
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={aprobarSolicitudes}>Aprobar Solicitud</Dropdown.Item>
                                    <Dropdown.Item onClick={rechazarSolicitudes}>Rechazar Solicitud</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                    <div className='pt-1'>
                        <div className="table-scroll">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                            <tr className='bg-cl-gris'>
                                <th>
                                    <div className='flex font-gothan-medium'>Nombre Completo</div>
                                </th>
                                <th>
                                    <FormSelect
                                        options={seccionesFiltradosOptions}
                                        description={'Sección'}
                                        setSelectedOptions={setSeccionesSeleccionados}
                                        selectedOptions={seccionesSeleccionados}
                                    />
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Fecha</div>

                                    <div className="custom-control custom-switch">
                                        <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                                        <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                                            Más reciente
                                        </label>
                                    </div>

                                </th>
                                <th>
                                    <FormSelect
                                        options={camposFiltradosOptions}
                                        description={'Campo a actualizar'}
                                        setSelectedOptions={setCamposSeleccionados}
                                        selectedOptions={camposSeleccionados}
                                    />
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Dato actual</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Reemplazar por</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Evidencia</div>
                                </th>
                                <th style={{'width': '210px'}}>
                                    <FormSelect
                                        options={estadosFiltradosOptions}
                                        description={'Estado'}
                                        setSelectedOptions={setEstadosSeleccionados}
                                        selectedOptions={estadosSeleccionados}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <TableWebBody solicitudesFiltradas={solicitudesFiltradas} idsFichaCamposSolicitudesConCheck={idsFichaCamposSolicitudesConCheck} setIdsFichaCamposSolicitudesConCheck={setIdsFichaCamposSolicitudesConCheck}/>
                        </table>
                        </div>


                        {lastPage > 1 && (
                            <Paginator onLeftClic={onLeftClic}
                                       onRightClic={onRightClic}
                                       page={page}
                                       totalPages={lastPage}
                                       fromIndex={fromIndex}
                                       toIndex={toIndex}
                                       totalDeRegistrosFiltrados={totalDeRegistrosFiltrados}/>
                        )}
                    </div>
                </div>
            </Wrapper>
        </>
    )
};

export default AdminSolicitudCambios;