import React, { useState } from 'react'
import ModalForm from '../../../../../../components/modalComponents/ModalForm'
import { useSubmitForm } from '../../../../../../hooks/useSubmitForm/useSubmitForm';
import { reprogramarNuevoIngreso } from '../../../../../../consumers/backendApisUrls';
import InputDate from '../../../../../../components/modalComponents/InputDate';
import { usePropsInputs } from '../../../../../../hooks/useProps/usePropsInput';
import { getTomorrowsDate, toIsoForInput } from '../../../../../../helper/utils';

const ReprogramarIngreso = ({ toggleModal, refreshPage, IdNuevosIngresosEmpleado }) => {
  const SECTION_NAME = "ReprogramarNuevoIngreso";
  const currentDay = toIsoForInput(getTomorrowsDate());
  const KEYS = { IdNuevosIngresosEmpleado: "IdNuevosIngresosEmpleado", FechaIngresoReal: "FechaIngresoReal", FechaIngresoEmpresa: "FechaIngresoEmpresa" };

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = reprogramarNuevoIngreso();
    const payload = { IdNuevosIngresosEmpleado, ...formValues };
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitForm);
    setIsSubmitting(false);
  }

  const successSubmitForm = async () => {
    toggleModal();
    await refreshPage();
  }

  return (
    <ModalForm
      titulo="Reprogramar Ingreso"
      showModal={toggleModal}
      width="650px"
      isSubmitting={isSubmitting}
      saveButtonDisabled={isSubmitting}
      onSubmit={handleSubmit}>
      <InputDate
        label="Fecha de ingreso real"
        direction="column"
        min={currentDay}
        {...commonProps(KEYS.FechaIngresoReal)}
      />
      <InputDate
        label="Fecha de ingreso empresa"
        direction="column"
        min={currentDay}
        {...commonProps(KEYS.FechaIngresoEmpresa)}
      />
    </ModalForm>
  );
}

export default ReprogramarIngreso