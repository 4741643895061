import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import FadeAnimation from './FadeAnimation';
import EquisCircleIcon from '../icons/EquisCircleIcon';

const Wrapper = styled.div`
  position: fixed;
 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #80808069;;
  
  display: ${(props) => (props.active ? 'grid' : 'none')};
  justify-content: center;
  align-content: center;
  
  animation: ${FadeAnimation} 0.4s ease-in;
  z-index: ${(props) => (props.active ? 222222 : 0)};
  
`;

const ModalContent = styled.div`
  
  width: 700px;
  height: 430px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${(props) => (props.isMobile ? 0 : 5)}px;
  background: #f7f8fa;
  
  @media screen and (max-width:1018px) {
      width: 730px;
  }
`;

const ModalHeader = styled.div`
  padding: 9px 20px;
  padding-left: 20px;
  height: 50px;
  font-size: 15px;
  position: relative;
  display: flex;
  justify-content: ${(props) =>
    props.isMobile ? 'flex-end' : 'space-between'};
  align-items: center;
  background-color: #0063a1;
  height: 50px;
  border-radius: 5px 5px 0px 0;
  flex-direction: ${(props) => (props.isMobile ? 'row-reverse' : '')};

  h1 {
    font-size: 1rem;
    color: #ffffff;
    font-weight: bold;
  }
  .flex {
    cursor: pointer;
  }
`;

const ModalBody = styled.div`
  border-top: solid 1px #d8dce3;
  padding: 100px 60px;
  ${(props) => {
    if (props.isMobile)
        return css`
        background: #ffffff;
        display: block;
        height: 100%;
        position: fixed;
        right: ${(props) => (props.showModal ? '0' : '-200px')};
        top: 50px;
        transition: 0.5s;
        transition: all 0.2s ease-out;
        width: 100%;
      `;
}};

  .img {
    border-radius: 50%;
    height: 150px;
    width: 150px;
  }
`;

const ModalCenter = (props) => {
    return (
        <Wrapper active={props.active}>
            <ModalContent isMobile={props.isMobile}>
                <ModalHeader>
                    <h1>{props.title}</h1>
                    <div className='flex' onClick={props.onClose}>
                        <EquisCircleIcon/>
                    </div>
                </ModalHeader>
                <ModalBody>{props.children}</ModalBody>
            </ModalContent>
        </Wrapper>
    );
};

ModalCenter.propTypes = {
    active: PropTypes.bool,
    onCancel: PropTypes.func,
    isMobile: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
        .isRequired,
};

export default ModalCenter;
