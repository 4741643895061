import React from 'react'
import InputDate from '../../../../components/modalComponents/InputDate'
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile'

const VALID_EXTENSIONS = ["pdf", "jpg", "png"];

const PrePostNatal = ({ formValues, setFormValues, isUploading, setIsUploading, sectionName: SECTION_NAME, commomProps, keys: KEYS, isEditable = false}) => {
  return (
    <>
      <InputDate
        label="Fecha Inicio"
        {...commomProps(KEYS.fechaInicio)}
        isDisabled={!isEditable}
      />
      <InputDate
        label="Fecha Fin"
        {...commomProps(KEYS.fechaFin)}
        isDisabled={!isEditable}
      />
      <InputUploadFile
        label="Sustento"
        name={KEYS.sustento}
        uploadFile={formValues[KEYS.sustento]}
        setUploadFile={(data) =>
          setFormValues((prev) => ({ ...prev, [KEYS.sustento]: data }))
        }
        isUploading={isUploading}
        setIsUploading={setIsUploading}
        validExtensions={VALID_EXTENSIONS}
        sectionName={SECTION_NAME}
        isDisabled={!isEditable}
      />
    </>
  );
}

export default PrePostNatal