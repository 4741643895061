import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {addDays, getCurrentDay, getCurrentMonth, getCurrentYear, getTodayDate, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary} from "../../../helper/utils";
import {toast} from "react-toastify";
import SelectInput from "../../../components/Select/SelectInput";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getActividadesRenunciasAPI, getModalidadesDevolucionAPI, guardarCoordinarDevolucionAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";

const CoordinarDevolucionModal = ({showModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "modalCoordinarDevolucion";
    const [actividadRenuncia, setActividadRenuncia] = useState({});
    const [modalidadesDevolucionOptions, setModalidadesDevolucionOptions] = useState([]);
    const [jsonSelectedModalidadDevolucionValue, setJsonSelectedModalidadDevolucionValue] = useState({});

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [currentYear] = useState(getCurrentYear());
    const [currentMonth] = useState(getCurrentMonth());
    const [currentDay] = useState(getCurrentDay());
    const next30Days = addDays(getTodayDate(), 29);
    const [nextYear] = useState(getCurrentYear(next30Days));
    const [nextMonth] = useState(getCurrentMonth(next30Days));
    const [nextDay] = useState(getCurrentDay(next30Days));
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    async function getActividadesRenunciass() {
        const response = await executeGetRequest(getActividadesRenunciasAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setActividadRenuncia(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    async function getModalidadesDevolucionn() {
        const response = await executeGetRequest(getModalidadesDevolucionAPI());
        if (responseCode200(response)) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
            setModalidadesDevolucionOptions(options);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        await getActividadesRenunciass();
        await getModalidadesDevolucionn();

        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        return {
            "Observaciones": ""
        };
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        console.debug("formValues: ", formValues);

        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "IdModalidadDevolucion": (jsonSelectedModalidadDevolucionValue["value"]) ? jsonSelectedModalidadDevolucionValue["value"] : null,
            "FechaDevolucion": (formValues.FechaDevolucion === undefined) ? null : formValues.FechaDevolucion,
            "Observaciones": formValues.Observaciones
        };

        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarCoordinarDevolucionAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdModalidadDevolucion", "FechaDevolucion", "Observaciones"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"990px"} titulo="Coordinar Devolución">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label ">Colaborador:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.NombreEmpleado}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.FechaUltimoDia}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.Sede}</label>
                            </div>
                            <hr/>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Indicar modalidad de devolución<span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <SelectInput options={modalidadesDevolucionOptions} jsonSelectedValue={jsonSelectedModalidadDevolucionValue} setJsonSelectedValue={setJsonSelectedModalidadDevolucionValue} isDisabled={false} placeholder={"Seleccionar una opción"}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdModalidadDevolucion-validation-message`}></p>
                                </div>
                            </div>
                            < div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Indicar Fecha<span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <input type="date" min={currentYear + '-' + currentMonth + '-' + (Number(currentDay))} max={nextYear + '-' + nextMonth + '-' + nextDay} className="form-control" name="FechaDevolucion" onChange={handleChange} defaultValue={formValues.Fecha}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaDevolucion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Observaciones (indicar domicilio, referencia y teléfono)<span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" rows="4" name="Observaciones" onChange={handleChange} defaultValue={formValues.Observaciones} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observaciones-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default CoordinarDevolucionModal;