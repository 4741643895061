import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import DirectorioWrapper from '../../components/atoms/DirectorioWrapper';
import ColoredCard from '../../components/cards/ColoredCard';
import PageHeader from '../../components/pageHeader/PageHeader';
import SelectInput from '../../components/Select/SelectInput';
import { getModosLecturaAPI, listarCategoriasDePoliticasQuePuedoVerAPI, listarPoliticasAPI } from "../../consumers/backendApisUrls";
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../helper/utils';
import { AuthContext } from "../../hooks/context/authContext";
import useQuery from '../../hooks/query/useQuery';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import Paginator from '../directorio/Paginator';
import DetallePoliticaYReglamento from './modales/DetallePoliticaYReglamento';

const PoliticasYReglamentos = () => {
  const { state } = useContext(AuthContext);

  const [manageGetRequest] = useManageGetRequest();
  const [managePostRequest] = useManagePostRequest();

  const [filtroLectura, setFiltroLectura] = useState([]);
  const [filtroLecturaSeleccionada, setFiltroLecturaSeleccionada] = useState(null);
  const [politics, setPolitics] = useState([]);
  const [politicIdSelected, setPoliticIdSelected] = useState(null);

  const [hideDetalleModal, setHideDetalleModal] = useState(true);

  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const history = useHistory();
  const query = useQuery();
  const categoryName = query.get("name");
  const { id: categoryId } = useParams();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, [reload]);


  useDidMountEffect(() => {
    setPage(1);
  }, [filtroLecturaSeleccionada]);

  useDidMountEffect(() => {
    manageSearch();
  }, [filtroLecturaSeleccionada, page]);

  const obtenerPayload = function () {
    let payload = {
      "IdCategoria": categoryId,
      "IdFiltroLectura": filtroLecturaSeleccionada?.value,
      "Criteria": criterioDeBusqueda,
      "Page": page,
    };
    return payload;
  }

  const handleResponseSuccess = function (response) {
    setPolitics(response.data.Items);
    setTotalItems(response.data.TotalItems);
    setFirsItemPage(response.data.FirsItemPage);
    setLastItemPage(response.data.LastItemPage);
    setTotalPages(response.data.TotalPages);
  }

  const executeSearch = async (payload) => {

    try {
      setPolitics([]);
      await managePostRequest(listarPoliticasAPI(), payload, handleResponseSuccess, false);
    } catch (e) {
      console.error(e);
    }
  };

  const manageSearch = async () => {
    setLoading(true);
    const payload = obtenerPayload();
    await executeSearch(payload);
  };

  const handleGetFiltroSuccess = (reponse) => {
    let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(reponse.data);
    setFiltroLectura(options);
  }

  const getInitialData = async () => {
    setLoading(true);
    try {
      await manageGetRequest(getModosLecturaAPI(), handleGetFiltroSuccess, false);
      await manageSearch();
    } catch (e) {
      console.error(e);
    }

    /* try {
      await manageGetRequest(listarCategoriasAPI(), handleResponseSuccess, false);
    } catch (e) {
      console.error(e);
    } */
    setLoading(false);
  };

  const handleCardClick = (politicId) => {
    setPoliticIdSelected(politicId);
    setHideDetalleModal(false);
  }

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      manageSearch();
    }
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const limpiarBusqueda = () => {
    setFiltroLecturaSeleccionada([]);
    setCriterioDeBusqueda("");
  };

  const toggleDetalleModal = () => {
    setHideDetalleModal(prev => !prev);
  }

  const goBack = () => {
    history.push(`/politicasYreglamentos?categoriaId=${categoryId}`);
  }

  return (
    <DirectorioWrapper className='App'>
      {hideDetalleModal === false &&
        <div className="modal-contactanos modal-response-ficha-background">
          <DetallePoliticaYReglamento
            toggleModal={toggleDetalleModal}
            setReload={setReload}
            politicId={politicIdSelected}
          />
        </div>
      }

      <PageHeader title={categoryName} />
      <div className='container-fluid body-content'>
        <div className="generic-buscador-container w-100">
          <div className="filtros-left-section d-flex w-100 flex-wrap">
            <button className='generic-button-buscador' onClick={goBack}>
              <FontAwesomeIcon
                icon='angle-left'
                size="lg"
                className="hand-pointer"
                title="Atras"
              /> Atrás
            </button>
            <input
              type='text'
              className='generic-input-criterio-busqueda'
              placeholder='Buscar por nombre'
              value={criterioDeBusqueda}
              onChange={(e) => {
                setCriterioDeBusqueda(e.target.value);
              }}
              onKeyPress={handleKeypress}
            />
            <div className='w-25 mx-2'>
              <SelectInput
                options={filtroLectura}
                jsonSelectedValue={filtroLecturaSeleccionada}
                setJsonSelectedValue={setFiltroLecturaSeleccionada}
                placeholder={"Todos"}
              />
            </div>
            <button
              onClick={manageSearch}
              type='button'
              className='generic-button-buscador'>
              Buscar
            </button>
            <a
              className="clean-filters"
              onClick={limpiarBusqueda}
            >
              Limpiar búsqueda
            </a>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <div className='mt-2 mb-4 w-75 d-flex align-content-center justify-content-center flex-wrap'>
            {politics.map(politic => (
              <ColoredCard
                key={politic?.IdPolitica}
                title={politic.NombrePolitica}
                publishedDate={politic?.FechaPublicacion}
                isNew={politic?.EsNuevo}
                color={politic.Color}
                icon={
                  <img src={politic.Icono} width="100%" className='d-block m-auto p-5' />
                }
                onClick={() => handleCardClick(politic.IdPolitica)}
              />
            ))}
          </div>
        </div>
        {totalPages > 0 && (
          <Paginator totalItems={totalItems}
            firsItemPage={firsItemPage}
            lastItemPage={lastItemPage}
            totalPages={totalPages}
            page={page}
            onLeftClic={onLeftClic}
            onRightClic={onRightClic} />
        )}
      </div>
    </DirectorioWrapper>
  )
}

export default PoliticasYReglamentos;
