import React, { useContext, useEffect, useState } from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {v4 as uuidv4} from 'uuid';
import {
    descargarPlantilaAPI,
    getDetalleColaboradorEjecutarProcesoAPI,
    getDetalleContratoEjecutarProcesoAPI, saveDocumentosEjecutarProcesoAPI, saveEjecutarProcesoEmpleadoAPI
} from "../../../consumers/backendApisUrls";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../../consumers/backendConsumer";
import { AuthContext } from "../../../hooks/context/authContext";
import { AlertErrorMaxFileSizeCeses } from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import GenericUploadFileDinamic from "../../../components/GenericUploadFile/GenericUploadFileDinamic";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useGenerarDescargarEliminarArchivo } from "../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import { CONDICIONES_CIERRE_DE_ANIO, ESTADOS, ROLES } from "../../../constants/hardCodedConstants";
import { isNullOrUndefinded, notNullAndNotUndefinded, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles } from "../../../helper/utils";
import EliminarNuevoRegistro from './EliminarNuevoRegistro';
import { ETAPAS_CIERRE_DE_ANIO } from '../../../constants/hardCodedConstants';
import { clearAllInputValidationMessages } from '../../../validations/FormValidatorUtils';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SolicitudVacacionesProcesoCierre from './SolicitudVacacionesProcesoCierre';

const DetalleColaborador = ({ toggleModal, idProcesoCierre, idProcesoCierreEmpleado, refreshTable, toggleObservarRegistroModal, idEstadoProceso }) => {
    const { state } = useContext(AuthContext);

    const sectionName = "DetalleColaborador"
    const [detalleColaborador, setDetalleColaborador] = useState({});
    const [datosContrato, setDatosContrato] = useState({});
    const [condicionId, setCondicionId] = useState(false);
    const [idFiles, setIdFiles] = useState([]);
    const [idFilesPersonalizados, setIdFilesPersonalizados] = useState([]);

    const [uploadedPlantillasFilesResponses, setUploadedPlantillasFilesResponses] = useState({})
    const [uploadedPlantillasPersonalizadasFilesResponses, setUploadedPlantillasPersonalizadasFilesResponses] = useState({})
    const [uploadedDocumentosFilesResponses, setUploadedDocumentosFilesResponses] = useState({})
    const [uploadedDocumentosPersonalizadosFilesResponses, setUploadedDocumentosPersonalizadosFilesResponses] = useState({})
    const [subiendoArchivos, setSubiendoArchivos] = useState(false);
    const [submitForm] = useSubmitForm();
    const [manageGetRequest] = useManageGetRequest();
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

    const rolesQuePuedenVerCiertosCampos = [ROLES.Compensaciones.id];
    const [mostrarCiertosCampos, setMostrarCiertosCampos] = useState(false);
    const [colSpanDocumentosAsignados, setColSpanDocumentosAsignados] = useState(0);
    const [colSpanDocumentosFirmados, setColSpanDocumentosFirmados] = useState(0);

    const [newDocumentCount, setNewDocumentCount] = useState(0);
    const [hideModalEliminarRegistro, setHideModalEliminarRegistro] = useState(false);
    const [registroEliminar, setRegistroEliminar] = useState({});

    const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (condicionId !== CONDICIONES_CIERRE_DE_ANIO.NoRenueva.id && condicionId !== CONDICIONES_CIERRE_DE_ANIO.Vacaciones.id) {
            getDatosContrato(idProcesoCierreEmpleado);
        }
    }, [condicionId]);

    const getDatosContrato = async function (idProcesoCierreEmpleado) {
        await manageGetRequest(getDetalleContratoEjecutarProcesoAPI(idProcesoCierreEmpleado), (response) => {
            setDatosContrato(response?.data);
        })
    }

    const callbackSuccessGetDetalleColaborador = function (response) {
        setDetalleColaborador(response?.data);
        setCondicionId(response?.data?.IdCondicion);
        if (response?.data?.Files) {
            let idFilesTemp = response?.data?.Files.map((file) => file?.IdPlantillaConfig);
            setIdFiles(idFilesTemp);
        }
        if (response?.data?.FilesPersonalizados){
            setIdFilesPersonalizados([...response?.data?.FilesPersonalizados.keys()])
        }
        updateColSpans(response?.data);
    }

    const updateColSpans = function (responseData) {
        let colSpanDocumentosAsignadosTemp = 1;
        responseData?.PuedeSubirPlantilla === true && ++colSpanDocumentosAsignadosTemp
        responseData?.PuedeDescargarPlantilla === true && ++colSpanDocumentosAsignadosTemp
        setColSpanDocumentosAsignados(colSpanDocumentosAsignadosTemp);

        let colSpanDocumentosFirmadosTemp = 0;
        responseData?.PuedeSubirDocumento === true && ++colSpanDocumentosFirmadosTemp
        responseData?.PuedeDescargarDocumento === true && ++colSpanDocumentosFirmadosTemp
        setColSpanDocumentosFirmados(colSpanDocumentosFirmadosTemp);
    }

    const init = async function () {
        setMostrarCiertosCampos((usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, rolesQuePuedenVerCiertosCampos)))
        await manageGetRequest(getDetalleColaboradorEjecutarProcesoAPI(idProcesoCierreEmpleado), callbackSuccessGetDetalleColaborador)
    };

    const toggleModalProxy = function () {
        refreshTable();
        toggleModal();
    }

    const obtenerPayloadParaBotonGuardarConPlantillas = function () {
        let payloadFiles = [];
        if (detalleColaborador && detalleColaborador?.Files) {
            for (let file of detalleColaborador?.Files) {
                let payloadFile = {
                    "IdPlantilla": file?.IdPlantilla,
                    "IdPlantillaConfig": file?.IdPlantillaConfig,
                    "File": uploadedPlantillasFilesResponses?.[file.IdPlantillaConfig]
                }
                payloadFiles = [...payloadFiles, payloadFile];
            }
        }

        let payload = { "IdProcesoCierreEmpleado": idProcesoCierreEmpleado }

        if (payloadFiles.length > 0) {
            payload = { ...payload, "Files": payloadFiles }
        }
        return payload;
    }

    const obtenerPayloadPlantillasPersonalizadas = () => {
        let payloadPlantillasPersonalizadas = [];
        if (detalleColaborador && detalleColaborador?.FilesPersonalizados) {
            for (let [i,file] of detalleColaborador?.FilesPersonalizados.entries()) {
                let payloadFile = {
                    "IdProcesoCierreEmpleadoArchivo": file?.IdProcesoCierreEmpleadoArchivo,
                    "File": uploadedPlantillasPersonalizadasFilesResponses?.[i]
                }
                payloadPlantillasPersonalizadas = [...payloadPlantillasPersonalizadas, payloadFile];
            }
        }
        return payloadPlantillasPersonalizadas;
    }

    const obtenerPayloadParaBotonGuardarConDocumentos = function () {
        let payloadFiles = [];
        if (detalleColaborador && detalleColaborador?.Files) {
            for (let file of detalleColaborador?.Files) {
                let payloadFile = {
                    "IdPlantilla": file?.IdPlantilla,
                    "IdPlantillaConfig": file?.IdPlantillaConfig,
                    "File": uploadedDocumentosFilesResponses?.[file.IdPlantillaConfig]
                }
                payloadFiles = [...payloadFiles, payloadFile];
            }
        }

        let payload = { "IdProcesoCierreEmpleado": idProcesoCierreEmpleado }

        if (payloadFiles.length > 0) {
            payload = { ...payload, "Files": payloadFiles }
        }
        return payload;
    }

    const obtenerPayloadDocumentosPersonalizados = () => {
        let payloadDocumentosPersonalizados = [];
        if (detalleColaborador && detalleColaborador?.FilesPersonalizados) {
            for (let [i,file] of detalleColaborador?.FilesPersonalizados.entries()) {
                let payloadFile = {
                    "IdProcesoCierreEmpleadoArchivo": file?.IdProcesoCierreEmpleadoArchivo,
                    "File": uploadedDocumentosPersonalizadosFilesResponses?.[i]
                }
                payloadDocumentosPersonalizados = [...payloadDocumentosPersonalizados, payloadFile];
            }
        }
        return payloadDocumentosPersonalizados;
    }

    const saveDocumentoEjecutarProceso = async function () {
        let payload = obtenerPayloadParaBotonGuardarConPlantillas();
        let payloadPlantillasPersonalizadas = obtenerPayloadPlantillasPersonalizadas();
        let allPayload = {...payload, "FilesPersonalizados": payloadPlantillasPersonalizadas};
        let ids = [...idFiles, ...idFilesPersonalizados];
        setSubiendoArchivos(true);
        await submitForm(ids, saveDocumentosEjecutarProcesoAPI(), allPayload, sectionName, toggleModalProxy);
        setSubiendoArchivos(false);
    }

    const saveEjecutarProcesoEmpleado = async function () {
        let payload = obtenerPayloadParaBotonGuardarConDocumentos();
        let payloadDocumentosPersonalizados = obtenerPayloadDocumentosPersonalizados();
        let allPayload = {...payload, "FilesPersonalizados": payloadDocumentosPersonalizados};
        let ids = [...idFiles, ...idFilesPersonalizados];
        setSubiendoArchivos(true);
        await submitForm(ids, saveEjecutarProcesoEmpleadoAPI(), allPayload, sectionName, toggleModalProxy);
        setSubiendoArchivos(false);
    }

    const observar = function () {
        toggleModal();
        toggleObservarRegistroModal();
    }

    const manageDescargarArchivoPlantilla = async function (Path, OriginalFileName, IdPlantilla, IdPlantillaConfig) {
        if (notNullAndNotUndefinded(OriginalFileName)) {
            downloadFile(state.token, Path, OriginalFileName);
        }

        if (isNullOrUndefinded(OriginalFileName)) {
            let generarArchivoPayload = {
                "IdProcesoCierreEmpleado": idProcesoCierreEmpleado,
                "IdPlantilla": IdPlantilla,
                "IdPlantillaConfig": IdPlantillaConfig,
            }
            await generarDescargarEliminarArchivo(descargarPlantilaAPI(), generarArchivoPayload);
        }
    }

    const downloadDocumento = async function (Path, OriginalFileName) {
        if (notNullAndNotUndefinded(OriginalFileName)) {
            downloadFile(state.token, Path, OriginalFileName);
        }
    }

    const addPlantilla = (e) => {
        e.preventDefault();
        let count = newDocumentCount + 1;
        let newRow = {
            PlantillaArchivo: null,
            ColaboradorArchivo: null,
            IdProcesoCierreEmpleadoArchivo: null,
            Text: `Documento personalizado ${count}`,
            key: uuidv4()
        };
        let filesPersonalizados = {...detalleColaborador, "FilesPersonalizados": [...detalleColaborador.FilesPersonalizados, newRow]};
        setDetalleColaborador(filesPersonalizados);
        setNewDocumentCount(count);
    }

    const toggleModalEliminarRegistro = (title,id,i) => {
        setHideModalEliminarRegistro(!hideModalEliminarRegistro);
        setRegistroEliminar({"title": title, "IdProcesoCierreEmpleadoArchivo": id, "index": i});
    }

    const openMisActividadPendientesPage = () => {
        window.open('/misActividadesPendientes', '_blank');
    }

    useEffect(()=>{
        let keys = [];
        let oldKeys = [...idFiles, ...idFilesPersonalizados];
        let uploadedDocuments = {...uploadedDocumentosFilesResponses, ...uploadedDocumentosPersonalizadosFilesResponses};

        Object.keys(uploadedDocuments).forEach((k,i) => {
            if (uploadedDocuments[k] !== null){
                let newKey = oldKeys.filter(key => key == k)[0]
                keys = [...keys, newKey]
            }
        });
        clearAllInputValidationMessages(keys, sectionName)
    }, [uploadedDocumentosFilesResponses, uploadedDocumentosPersonalizadosFilesResponses])

    return (
        <>
            {hideModalEliminarRegistro && 
                <div className="modal-contactanos modal-response-ficha-background">
                    <EliminarNuevoRegistro
                        toggleModal = {toggleModalEliminarRegistro}
                        row = {registroEliminar}
                        detalleColaborador = {detalleColaborador}
                        setDetalleColaborador = {setDetalleColaborador}
                        uploadedDocumentosPersonalizadosFilesResponses = {uploadedDocumentosPersonalizadosFilesResponses}
                        setUploadedDocumentosPersonalizadosFilesResponses = {setUploadedDocumentosPersonalizadosFilesResponses}
                    />
                </div>
            }
            <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={init}/>
            <GenericModal showModal={toggleModal} width={"1200px"} titulo={"Detalle Colaborador"}>
                <div className="modal-body pb-0 pt-0">
                    <form className="blue-standard-color">
                        <div>
                            <div className="d-flex justify-content-between ">
                                <div>
                                    <h6 className="mb-0"><strong>Datos del Colaborador</strong></h6>
                                </div>
                                <div>
                                    <p className="black-color mb-0"><strong>Estado de Proceso</strong>: {detalleColaborador?.EstadoProceso}</p>
                                </div>
                            </div>

                            <table className="table table-borderless">
                                <tbody>
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Nombre</th>
                                        <td className="p-0 col-8">{detalleColaborador?.Nombre}</td>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Cargo</th>
                                        <td className="p-0 col-8">{detalleColaborador?.Cargo}</td>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Sede</th>
                                        <td className="p-0 col-8">{detalleColaborador?.Sede}</td>
                                    </tr>
                                    {mostrarCiertosCampos &&
                                        <tr className="d-flex">
                                            <th className="p-0 col-4">Tipo de contrato</th>
                                            <td className="p-0 col-8">{detalleColaborador?.TipoContrato}</td>
                                        </tr>
                                    }
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Fecha Fin</th>
                                        <td className="p-0 col-8">{detalleColaborador?.FechaFin}</td>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Condición</th>
                                        <td className="p-0 col-8">{detalleColaborador?.Condicion ?? ""}</td>
                                    </tr>
                                    {mostrarCiertosCampos &&
                                        <>
                                            <tr className="d-flex">
                                                <th className="p-0 col-4">Especialidad</th>
                                                <td className="p-0 col-8">{detalleColaborador?.Especialidad}</td>
                                            </tr>
                                            <tr className="d-flex">
                                                <th className="p-0 col-4">Grupo</th>
                                                <td className="p-0 col-8">{detalleColaborador?.Grupo}</td>
                                            </tr>
                                        </>
                                    }
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Responsable</th>
                                        <td className="p-0 col-8">{detalleColaborador?.EmpleadoResponsable}</td>
                                    </tr>
                                    <tr className="d-flex">
                                        <th className="p-0 col-4">Sueldo (*)</th>
                                        <td className="p-0 col-8">{detalleColaborador?.Sueldo}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        {condicionId !== CONDICIONES_CIERRE_DE_ANIO.NoRenueva.id && condicionId !== CONDICIONES_CIERRE_DE_ANIO.Vacaciones.id && datosContrato &&
                            <div>
                                <h6><strong>Datos del Contrato</strong></h6>
                                <table className="table table-borderless">
                                    <tbody>
                                        {mostrarCiertosCampos &&
                                            <tr className="d-flex">
                                                <th className="p-0 col-4">Tipo de Contrato</th>
                                                <td className="p-0 col-8">{datosContrato?.TipoContrato}</td>
                                            </tr>
                                        }
                                        <tr className="d-flex">
                                            <th className="p-0 col-4">Cargo</th>
                                            <td className="p-0 col-8">{datosContrato?.Cargo}</td>
                                        </tr>
                                        <tr className="d-flex">
                                            <th className="p-0 col-4">Sede</th>
                                            <td className="p-0 col-8">{datosContrato?.Sede}</td>
                                        </tr>
                                        <tr className="d-flex">
                                            <th className="p-0 col-4">Periodo</th>
                                            <td className="p-0 col-8">{datosContrato?.Periodo}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        <div>
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th className="p-0 col-4">Comentario</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="d-flex">
                                        <td className="p-0 col-8">{detalleColaborador?.Comentario}</td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        {(detalleColaborador?.Files || detalleColaborador?.FilesPersonalizados) && (detalleColaborador?.Files.length > 0 || detalleColaborador?.FilesPersonalizados.length > 0) &&
                            <div className='position-relative'>
                                <h6><strong>Documentos</strong></h6>
                                { detalleColaborador?.PuedeSubirPlantilla && mostrarCiertosCampos &&
                                (detalleColaborador?.EstadoProceso == ETAPAS_CIERRE_DE_ANIO.CREADO ||
                                    detalleColaborador?.EstadoProceso == ETAPAS_CIERRE_DE_ANIO.PENDIENTE) &&
                                    <button className='generic-button-buscador button-document-absolute' onClick={addPlantilla}>Añadir documento personalizado</button>
                                }
                                <table className="table table-detalle-colaborador">
                                    <thead className="thead-light">
                                        <tr>
                                            <th colSpan={colSpanDocumentosAsignados} className="pt-0 pb-0 text-center">Documento Asignado</th>
                                            {(detalleColaborador?.PuedeSubirDocumento === true || detalleColaborador?.PuedeDescargarDocumento === true) &&
                                                <th colSpan={colSpanDocumentosFirmados} className="pt-0 pb-0 text-center">Documento Firmado</th>
                                            }
                                        </tr>
                                        <tr>
                                            <th className="p-0 text-center">Nombre</th>
                                            {detalleColaborador?.PuedeSubirPlantilla === true &&
                                                <th className="p-0 text-center">Subir</th>
                                            }

                                            {detalleColaborador?.PuedeDescargarPlantilla === true &&
                                                <th className="p-0 text-center">Descargar</th>
                                            }

                                            {detalleColaborador?.PuedeSubirDocumento === true &&
                                                <th className="p-0 text-center">Subir</th>
                                            }

                                            {detalleColaborador?.PuedeDescargarDocumento === true &&
                                                <th className="p-0 text-center">Descargar</th>
                                            }
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {detalleColaborador?.Files && detalleColaborador?.Files.map((file, i) => (
                                            <tr className="" key={i}>
                                                <td className="p-0">{file.Text}</td>
                                                {detalleColaborador?.PuedeSubirPlantilla &&
                                                    <td className="p-0 text-center">
                                                        {file.EsVacaciones ?
                                                            <label className='mb-0 blue-standard-color'>-</label> :
                                                        <>
                                                            <GenericUploadFileDinamic uploadedFilesResponses={uploadedPlantillasFilesResponses} setUploadedFilesResponses={setUploadedPlantillasFilesResponses} id={file?.IdPlantillaConfig} textButton={"Subir archivo"}
                                                            extensionesValidas={["pdf"]}
                                                            ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                            subiendoArchivoPublicState={subiendoArchivos} setSubiendoArchivoPublicState={setSubiendoArchivos} className={"d-flex justify-content-center"} />
                                                            <p className="ficha-form-input-validation-message mb-0" id={`ficha-edit-${sectionName}-${file?.IdPlantillaConfig}-validation-message`} ></p>
                                                        </>
                                                        }
                                                    </td>
                                                }
                                                {detalleColaborador?.PuedeDescargarPlantilla &&
                                                <>{file.EsVacaciones ? 
                                                    <td className='p-0 text-center'>
                                                        <>
                                                            {file.EsPendienteVacaciones &&  idEstadoProceso !== ESTADOS.VALIDADO ?
                                                                <button type='button' className='btn btn-primary mb-0 py-0 px-2 custom-button-adjuntar' onClick={() => setModalEnabled({ isEnable: true, component: SolicitudVacacionesProcesoCierre, data: { idEempleado: detalleColaborador.IdEmpleado, idPlantillaConfig: file.IdPlantillaConfig, idProcesoCierreEmpleado: detalleColaborador.IdProcesoCierreEmpleado, idProcesoCierre }})}>
                                                                    Abrir
                                                                </button> :
                                                                <label className='mb-0 blue-standard-color'>-</label>
                                                            }
                                                        </>
                                                    </td> :
                                                    <td className="p-0 text-center">
                                                        {(file?.IdPlantilla != 99  || file?.PlantillaArchivo !== null) &&
                                                        <div>
                                                            <FontAwesomeIcon icon='paperclip' size="lg" title="Descargar" className="hand-pointer blue-standard-color" 
                                                            onClick={async (e) => {
                                                                e.preventDefault();
                                                                await manageDescargarArchivoPlantilla(file?.PlantillaArchivo?.Path, file?.PlantillaArchivo?.OriginalFileName, file.IdPlantilla, file.IdPlantillaConfig);
                                                            }} />
                                                        </div>
                                                        }
                                                    </td>
                                                }</>
                                                }
                                                {detalleColaborador?.PuedeSubirDocumento === true &&
                                                <>
                                                {file.EsVacaciones ?
                                                    <td className="p-0 text-center">
                                                        {file.EsPendienteVacaciones ?
                                                            <button type='button' className='btn btn-primary mb-0 py-0 px-2 custom-button-adjuntar' onClick={() => setModalEnabled({ isEnable: true, component: SolicitudVacacionesProcesoCierre, data: { idEempleado: detalleColaborador.IdEmpleado, idPlantillaConfig: file.IdPlantillaConfig, idProcesoCierreEmpleado: detalleColaborador.IdProcesoCierreEmpleado, idProcesoCierre }})}>
                                                                Abrir
                                                            </button>:
                                                            <label className='mb-0 blue-standard-color'>Documento Cargado</label>
                                                        }
                                                    </td> :
                                                    <td className="p-0 ">
                                                        <GenericUploadFileDinamic uploadedFilesResponses={uploadedDocumentosFilesResponses} setUploadedFilesResponses={setUploadedDocumentosFilesResponses} id={file?.IdPlantillaConfig} textButton={"Subir archivo"}
                                                            extensionesValidas={["pdf"]}
                                                            ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                            subiendoArchivoPublicState={subiendoArchivos} setSubiendoArchivoPublicState={setSubiendoArchivos} className={"d-flex justify-content-center"} />
                                                            <p className="ficha-form-input-validation-message mb-0" id={`ficha-edit-${sectionName}-${file?.IdPlantillaConfig}-validation-message`}></p>
                                                    </td>
                                                } </>
                                                }
                                                {detalleColaborador?.PuedeDescargarDocumento === true &&
                                                    <td className="p-0 text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon='paperclip' size="lg" title="Descargar" className="hand-pointer blue-standard-color" onClick={async (e) => {
                                                                e.preventDefault();
                                                                await downloadDocumento(file?.ColaboradorArchivo?.Path, file?.ColaboradorArchivo?.OriginalFileName);
                                                            }} />
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        ))
                                        }
                                        {detalleColaborador?.FilesPersonalizados && detalleColaborador?.FilesPersonalizados.map((file, i) => (
                                             <tr className="" key={i}> 
                                                { file.ColaboradorArchivo != null 
                                                    ? <td className="p-0">{file?.Text}</td>  
                                                    : <td className="p-0"><FontAwesomeIcon icon='trash' size="lg" title="Eliminar" className="hand-pointer mr-2 ml-2" onClick={() => toggleModalEliminarRegistro(file?.Text,file?.IdProcesoCierreEmpleadoArchivo,i)}/>{file?.Text}</td> 
                                                }
                                                {detalleColaborador?.PuedeSubirPlantilla === true &&
                                                    <td className="p-0 ">
                                                        <GenericUploadFileDinamic uploadedFilesResponses={uploadedPlantillasPersonalizadasFilesResponses} setUploadedFilesResponses={setUploadedPlantillasPersonalizadasFilesResponses} id={i} textButton={"Subir archivo"}
                                                            extensionesValidas={["pdf"]}
                                                            ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                            subiendoArchivoPublicState={subiendoArchivos} setSubiendoArchivoPublicState={setSubiendoArchivos} className={"d-flex justify-content-center"} />
                                                        <p className="ficha-form-input-validation-message mb-0" id={`ficha-edit-${sectionName}-${i}-validation-message`} ></p>
                                                    </td>
                                                }
                                                {detalleColaborador?.PuedeDescargarPlantilla === true &&
                                                    <td className="p-0 text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon='paperclip' size="lg" title="Descargar" className="hand-pointer blue-standard-color" onClick={async (e) => {
                                                                e.preventDefault();
                                                                await manageDescargarArchivoPlantilla(file?.PlantillaArchivo?.Path, file?.PlantillaArchivo?.OriginalFileName, file.IdPlantilla, file.IdPlantillaConfig);
                                                            }} />
                                                        </div>
                                                    </td>
                                                } 
                                                {detalleColaborador?.PuedeSubirDocumento === true &&
                                                    <td className="p-0 ">
                                                        <GenericUploadFileDinamic uploadedFilesResponses={uploadedDocumentosPersonalizadosFilesResponses} setUploadedFilesResponses={setUploadedDocumentosPersonalizadosFilesResponses} id={i} textButton={"Subir archivo"}
                                                            extensionesValidas={["pdf"]}
                                                            ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                            subiendoArchivoPublicState={subiendoArchivos} setSubiendoArchivoPublicState={setSubiendoArchivos} className={"d-flex justify-content-center"} />
                                                        <p className="ficha-form-input-validation-message mb-0" id={`ficha-edit-${sectionName}-${i}-validation-message`}></p>
                                                    </td>
                                                }
                                                {detalleColaborador?.PuedeDescargarDocumento === true &&
                                                    <td className="p-0 text-center">
                                                        <div>
                                                            <FontAwesomeIcon icon='paperclip' size="lg" title="Descargar" className="hand-pointer blue-standard-color" onClick={async (e) => {
                                                                e.preventDefault();
                                                                await downloadDocumento(file?.ColaboradorArchivo?.Path, file?.ColaboradorArchivo?.OriginalFileName);
                                                            }} />
                                                        </div>
                                                    </td>
                                                } 
                                            </tr> 
                                        ))} 
                                    </tbody>
                                </table>
                            </div>
                        }
                    </form>
                </div>

                <div className="modal-footer d-flex  pt-0 pb-0">

                    <div className="red-below-modal-message font-gothan-light align-self-start">
                        <strong>
                            <p className="mb-0" style={{'font-size':'15px'}}> (*) En caso el sueldo sea diferente al actual, este nuevo sueldo entrará en vigencia en Feb 2024, se comunicará con una carta que podrás visualizar desde esta ruta:  
                            <a onClick={openMisActividadPendientesPage} className='card-link hand-pointer'> Mis Opciones / Mis Actividades Pendientes</a>
                            </p>
                        </strong>
                    </div>
                    
                </div>

                <div  style={{'text-align': 'right'}}>
                        {detalleColaborador?.MostrarBotonObservar === true &&
                            <button className="btn btn-primary generic-button-ficha mr-1" onClick={observar}>Observar</button>
                        }

                        {detalleColaborador?.PuedeSubirPlantilla === true && (detalleColaborador?.Files || detalleColaborador?.FilesPersonalizados) && (detalleColaborador?.Files.length > 0 || detalleColaborador?.FilesPersonalizados.length > 0) &&
                            <button className="btn btn-primary generic-button-ficha mr-1" onClick={saveDocumentoEjecutarProceso} disabled={subiendoArchivos}>Guardar</button>
                        }

                        {detalleColaborador?.TextoBoton &&
                            <button className="btn btn-primary generic-button-ficha" onClick={saveEjecutarProcesoEmpleado} disabled={subiendoArchivos}>{detalleColaborador?.TextoBoton}</button>
                        }
                    </div>
            </GenericModal>
        </>
    )
}

export default DetalleColaborador;