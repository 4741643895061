export const updateValidationMessage = (fieldId, message) => {
    console.debug(`updateValidationMessage, fieldId: "${fieldId}",  message: ${message}`);
    let element = document.getElementById(`ficha-edit-datosDeNacimiento-${fieldId}-validation-message`);
    if (element){
        element.innerHTML = message;
    }else{
        console.warn(`elemento no existe, id: "ficha-edit-datosDeNacimiento-${fieldId}-validation-message"`);
    }
};

export const clearValidationMessage = (fieldId) => {
    console.debug(`clearValidationMessage, fieldId: "${fieldId}"`);
    let element = document.getElementById(`ficha-edit-datosDeNacimiento-${fieldId}-validation-message`);
    if (element){
        element.innerHTML = "";
    }else{
        console.warn(`elemento no existe, id: "ficha-edit-datosDeNacimiento-${fieldId}-validation-message"`);
    }
};

export const obtenerElementoInputDeFichaDatosNacimientoPorId = (id) => {
    let element = document.getElementById(`ficha-edit-datosDeNacimiento-${id}`);
    if (element) {
        return element;
    } else {
        console.warn(`id de elemento no existe, "ficha-edit-datosDeNacimiento-${id}"`);
    }
};

export function obtenerValorDeInputDeFichaDatosNacimientoPorId(id) {
    let element = obtenerElementoInputDeFichaDatosNacimientoPorId(id);
    if (element){
        return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
    }
}