import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "../../../hooks/useForm/UseForm";
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {isEmpty} from "../../../helper/utils";
import {updateValidationMessage} from "../../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {aprobarActividadNuevosIngresosAPI, getDetalleValidacionNuevosIngresosAPI, rechazarActividadNuevosIngresosAPI} from "../../../consumers/backendApisUrls";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {downloadFile} from "../../../consumers/backendConsumer";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";

const DetalleRegistroModal = ({showModal, idActividad, manageSearch}) => {
    const sectionName = "modalDetalleNuevosIngresos";

    const {state} = useContext(AuthContext);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [detalleActividad, setDetalleActividad] = useState({});
    const [motivoDeRechazoInputValue, setMotivoDeRechazoInputValue] = useState("");
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        console.debug("formValues changed ", formValues);
        if (formValues.Validacion === "true") {
            setMotivoDeRechazoInputValue("");
        }
    }, [formValues]);

    const init = async function () {
        if (idActividad) {
            console.debug("nuevos ingresos. idActividad: ", idActividad);
            const response = await executeGetRequest(getDetalleValidacionNuevosIngresosAPI(idActividad));
            if (responseCode200(response)) {
                setDetalleActividad(response.data);
            } else mostrarMensajeDeErrorConToast(response);
        }
    };

    const validateMandatoryField = function (keyField, inputValue, nroCamposInvalidos) {
        if (isEmpty(inputValue)) {
            updateValidationMessage(keyField, "Este campo es obligatorio", sectionName);
            nroCamposInvalidos++;
        } else {
            updateValidationMessage(keyField, "", sectionName);
        }
        return nroCamposInvalidos;
    }

    const formularioEsValido = function () {
        let nroCamposInvalidos = 0;
        let validacionValue = formValues["Validacion"];
        nroCamposInvalidos = validateMandatoryField("Validacion", validacionValue, nroCamposInvalidos);

        if (validacionValue === "false") {
            nroCamposInvalidos = validateMandatoryField("MotivoRechazo", motivoDeRechazoInputValue, nroCamposInvalidos);
        }
        return nroCamposInvalidos === 0;
    };

    const aprobarActividad = async function (idActividaddd) {
        let payload = {
            "IdEmpleadoFlujoActividad": idActividaddd
        };
        const response = await executePostRequest(aprobarActividadNuevosIngresosAPI(), payload);
        if (responseCode200(response)) {
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            showModal();
            await manageSearch();
        } else mostrarMensajeDeErrorConToast(response);
    };

    const rechazarActividad = async function (idActividadd, motivoRechazo) {
        let payload = {
            "IdEmpleadoFlujoActividad": idActividadd,
            "MotivoRechazo": motivoRechazo
        };

        const response = await executePostRequest(rechazarActividadNuevosIngresosAPI(), payload);
        if (responseCode200(response)) {
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            showModal();
            await manageSearch();
        } else mostrarMensajeDeErrorConToast(response);
    };

    const submitModalDetalleRegistro = async () => {
        let fomularioValido = formularioEsValido();

        console.debug("fomularioValido: ", fomularioValido);
        let validacionInputValue = formValues["Validacion"];

        const APROBAR = "true";
        const RECHAZAR = "false";

        if (fomularioValido === true) {
            if (validacionInputValue === APROBAR) {
                console.debug("call api aprobar");
                aprobarActividad(idActividad);
            } else if (validacionInputValue === RECHAZAR) {
                console.debug("call api rechazar");
                rechazarActividad(idActividad, motivoDeRechazoInputValue);
            }
        }
    };

    const onHandleMotivoRechazoChange = (e) => {
        let value = e.target.value;
        setMotivoDeRechazoInputValue(value);
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"700px"} titulo={"Detalle de Registro"}>
                <div className="margin-modal-body">
                    <form className="blue-standard-color">
                        <div className="row custom-center">
                            <label className="col-sm-4 col-form-label modal-label">Colaborador:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreEmpleado}</label>
                        </div>
                        <div className="row custom-center">
                            <label className="col-sm-4 col-form-label modal-label">Sección:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreSeccion}</label>
                        </div>
                        <div className="form-group row form-input-modal-contactanos">
                            <label className="col-sm-4 col-form-label modal-label">Datos actualizados sección:</label>
                            <div className="col-sm-8 col-form-label modal-label nuevos-ingresos-detalle-actividad-datos-atualizados-container">
                                <label className="add-nota-cese-colaborador">
                                    <ul>
                                        {detalleActividad && detalleActividad.DatosActualizados &&
                                        detalleActividad.DatosActualizados.map((actividad, index) => {
                                            return (<li key={index}><b>{actividad.Key}:</b> {actividad.Value}</li>)
                                        })
                                        }
                                    </ul>
                                </label>
                            </div>
                        </div>
                        <hr/>
                        <div className="form-group row form-input-modal-contactanos">
                            <label className="col-sm-4 col-form-label modal-label">Validación<span className="required">*</span></label>
                            <div className="col-sm-4">
                                <div className="form-check">
                                    <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="Validacion" value="true" checked={formValues.Validacion == "true"} onChange={handleChange}></input>
                                    <label className="form-check-label modal-ceses-label-radio-button">
                                        Aprobar
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="Validacion" value="false" checked={formValues.Validacion == "false"} onChange={handleChange}></input>
                                    <label className="form-check-label modal-ceses-label-radio-button">
                                        Observar
                                    </label>
                                </div>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Validacion-validation-message`}></p>
                            </div>
                            <div>
                                <label className="custom-file-upload upload-file-sustento-solicitud-cambio">
                                    <button
                                        type='button'
                                        className='btn btn-primary ficha-form-button-actualizar'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            downloadFile(state.token, detalleActividad.FileViewModel.Path, detalleActividad.FileViewModel.OriginalFileName);
                                        }}>
                                        Ver sustento
                                    </button>
                                </label>
                            </div>
                        </div>
                        {formValues.Validacion == "false" && <div className="form-group row form-input-modal-contactanos">
                            <label className="col-sm-4 col-form-label modal-label">Motivo de rechazo<span className="required">*</span></label>
                            <div className="col-sm-8 col-form-label modal-label ">

                                <textarea autoFocus data-required="true" type="text" className="form-control" id="MotivoRechazo" name="MotivoRechazo" onChange={onHandleMotivoRechazoChange} placeholder="Máximo 250 caractares" maxlength="250"></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-MotivoRechazo-validation-message`}></p>
                            </div>
                        </div>}
                    </form>
                </div>
                <div className="modal-footer" style={{'marginTop': '35px'}}>
                    <div className="message font-gothan-light" style={{'width': '500px'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submitModalDetalleRegistro}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default DetalleRegistroModal;

