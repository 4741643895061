import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import {responseCode200} from "../../consumers/httpRequiestsUtils";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";

const AutoSuggestTextInput = ({inputTextValue, setInputTextValue, suggestionsPostApi, placeholder="", maxlength="100", disabled= false}) => {
    const [executePost] = usePostRequest();
    const [suggestions, setSuggestions] = useState([]);

    function getSuggestionValue(suggestion) {
        return suggestion;
    }

    function renderSuggestion(suggestion) {
        return (
            <span>{suggestion}</span>
        );
    }

    const onChange = (event, {newValue, method}) => {
        setInputTextValue(newValue);
    };

    const onSuggestionsFetchRequested = async ({value}) => {
        try {
            let payload = {
                "Text": value ?? ""
            }

            let response = await executePost(suggestionsPostApi(), payload);

            if (responseCode200(response)) {
                setSuggestions(response.data);
            } else {
                setSuggestions([]);
            }

        } catch (e) {
            console.error(e);
        }
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([])
    };

    const inputProps = {
        placeholder: placeholder,
        value: inputTextValue,
        onChange: onChange,
        maxLength : maxlength,
        disabled: disabled
    }

    return (
        <div>
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}/>
        </div>
    );
};

export default AutoSuggestTextInput;
