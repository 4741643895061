import React, {useState, useEffect, useContext} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import MensajeConfirmacionModal from './MensajeConfirmacionModal';
import { clearAllInputValidationMessages, updateValidationMessage } from '../../../validations/FormValidatorUtils';
import { getEditarSolicitudVacacionesAPI, saveEditarSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls';
import { AuthContext } from '../../../hooks/context/authContext';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { aprobarSolicitudVacacionesAPI, rechazarSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import PreviewFormato from './PreviewFormato';
import { addDays, calculateDaysInBetween, toIsoForInput } from '../../../helper/utils';

const AprobarRechazarSolicitudVacaciones = ({showModal, refreshPage, idSolicitudMovimiento, periodoVacaciones, isApproved = false }) => {
    const SECTION_NAME = "RechazarSolicitudVacaciones";
    const TIME_STAMP = "T00:00:00";
    const KEYS = ["StartDate", "EndDate","Comentarios","MotivoDeRechazo"];
    const ESTADOS = {estado1: "aprobar", estado2: "rechazar"}

    const [formValues, setFormValues] = useState({});
    const [textAreaCount, setTextAreaCount] = useState(0);
    const [textAreaCountRechazo, setTextAreaCountRechazo] = useState(0);
    const [showMensajeModal, setShowMensajeModal] = useState(false);
    const [dataToModal, setDataTomodal] = useState({});
    const [isSending, setIsSending] = useState(false);
    const [diasVacacionesCalculado, setDiasVacacionesCalculado] = useState(0);
    const [minDiasInput, setMinDiasInput] = useState({});
    const [isDisabledAprobarRechazar, setIsDisabledAprobarRechazar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isInit, setIsInit] = useState(false);

    const [showModalPreviewFormato, setShowModalPreviewFormato] = useState(false);
    
    const [handleChange] = useForm(formValues, setFormValues);
    const {state} = useContext(AuthContext);

    const [executeGet] = useManageGetRequest();
    const [executePost] = useManagePostRequest();
    const [submitForm] = useSubmitForm();
  
    const init = async () => {
      await manageSearch();
    }

    const manageSearch = async () => {
      setIsInit(true);
      try {
        let api = getEditarSolicitudVacacionesAPI(idSolicitudMovimiento);
        await executeGet(api, successManageSearchCallback, false);
      } catch (error) {
        console.error(error)
      }
      setIsInit(false);
    }

    const successManageSearchCallback = (res) => {
      let data = res.data;
      setFormValues(data)
    }

    const handleGuardarSolicitud = async () => {
      setIsLoading(true);
      try {
        let api = saveEditarSolicitudVacacionesAPI();
        let payload = getPayloadEditarSolicitud();
        await submitForm(KEYS, api, payload, SECTION_NAME, successGuardarSolicitudCallback)
      } catch (error) {
        console.error(error)
      }
      setIsLoading(false);
    }

    const getPayloadEditarSolicitud = () => {
      return {
        IdSolicitudMovimiento: idSolicitudMovimiento,
        StartDate: formValues.FechaDesde,
        EndDate: formValues.FechaHasta,
        Comentarios: formValues.Comentarios
      }
    }

    const successGuardarSolicitudCallback = async () => {
      if (isApproved) {
        await refreshPage();
        return showModal();
      }
      manageSearch();
      setIsDisabledAprobarRechazar(false);
    }

    const handleRechazarSolicitud = async (e) => {
        e.preventDefault();
        setIsSending(true);
        try {
          let api = rechazarSolicitudVacacionesAPI();
          let payload= {IdSolicitudMovimiento: idSolicitudMovimiento, Comentarios: formValues.Comentarios, MotivoDeRechazo: formValues.MotivoDeRechazo};
          await submitForm(KEYS, api, payload, SECTION_NAME, successAprobarRechazarCallback, true, toggleMensajeConfirmacionRechazar);
        } catch (error) {
          console.error(error);
        }
        setIsSending(false);
    }

    const handleAprobarSolicitud = async (e) => {
        e.preventDefault();
        setIsSending(true);
        try {
          let api = aprobarSolicitudVacacionesAPI();
          let payload= {IdSolicitudMovimiento: idSolicitudMovimiento};
          await executePost(api, payload, successAprobarRechazarCallback)
        } catch (error) {
          console.error(error);
        }
        setIsSending(false);
    }

    const successAprobarRechazarCallback = () => {
      showModal();
      refreshPage();
  }

    const handleTextArea = (e) => {
      setIsDisabledAprobarRechazar(true);
      setTextAreaCount(e.target.value.length);
      handleChange(e);
    }

    const handleTextAreaRechazo = (e) => {
      setTextAreaCountRechazo(e.target.value.length)
      handleChange(e)
    }

    const handleClick = () => {
      setIsDisabledAprobarRechazar(true);
    }

    const handleDescargarFormato = () => {
      downloadFile(state.token, formValues.Formato?.FilePath, formValues.Formato?.OriginalFileName);
    }

    const togglePreviewFormato = () => {
      setShowModalPreviewFormato(!showModalPreviewFormato);
    }

    const toggleMensajeConfirmacionAprobar = () => {
      const periodoFecha= getPeriodoText()
      setDataTomodal({estado: "aprobar", periodo: periodoFecha});
      setShowMensajeModal(!showMensajeModal);
    }

    const toggleMensajeConfirmacionRechazar = () => {
      if (textAreaCountRechazo < 15){
        updateValidationMessage("MotivoDeRechazo", "Campo Obligatorio", SECTION_NAME)
      } else {
        const periodoFecha= getPeriodoText()
        setDataTomodal({estado: "rechazar", periodo: periodoFecha});
        setShowMensajeModal(!showMensajeModal);
      }
    }

    const calculateVacationDays = () => {
      if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaHasta >= formValues.FechaDesde){
        let days = calculateDaysInBetween(formValues.FechaDesde, formValues.FechaHasta);
        let countNewDays = 0;
        for (let day of dayAdded){
          const FechaDesde = `FechaDesde${day}`;
          const FechaHasta = `FechaHasta${day}`;
          if (formValues[FechaDesde] === undefined || formValues[FechaDesde] === null) continue;
          countNewDays += calculateDaysInBetween(formValues[FechaDesde], formValues[FechaHasta]);
        }
        setDiasVacacionesCalculado(days+countNewDays);
      } else if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaDesde > formValues.FechaHasta){
        let day = new Date(formValues.FechaDesde+TIME_STAMP);
        let dayISO = toIsoForInput(day);
        setFormValues({...formValues, FechaHasta: dayISO});
      } else {
        setDiasVacacionesCalculado(0);
      }
    }
  
    const calculateMinDaysInputDate = () => {
      let minDays = {
        minStartDate: toIsoForInput(addDays(new Date(), 1)),
        minEndDate: toIsoForInput(addDays(new Date(), 1))
      }
  
      if(formValues.FechaDesde)
        minDays.minEndDate = toIsoForInput(new Date(formValues.FechaDesde+TIME_STAMP), 1);
  
      setMinDiasInput(minDays)
    }

    const getPeriodoText = () => {
      let periodo = [`${getddMMYY(formValues.FechaDesde)} - ${getddMMYY(formValues.FechaHasta)}`];
      return periodo;
    }

    const getddMMYY = (stringData) => {
      let array = stringData.split("-");
      return `${array[2]} / ${array[1]} / ${array[0]}`
    }

    useEffect(() => {
      calculateVacationDays();
      calculateMinDaysInputDate();
    },[formValues.FechaDesde, formValues.FechaHasta])
  
    useEffect(() => {
      let unmounted = false;
      if(!unmounted) {
        init();
      }
  
      return () => {
        unmounted = true;
      }
    },[])
  
    return (
      <>
      {showMensajeModal && 
        <div className="modal-contactanos modal-response-ficha-background">
          <MensajeConfirmacionModal 
            showModal={dataToModal.estado == ESTADOS.estado1 ? toggleMensajeConfirmacionAprobar : toggleMensajeConfirmacionRechazar}
            handleSend={dataToModal.estado == ESTADOS.estado1 ? handleAprobarSolicitud : handleRechazarSolicitud} 
            dataToModal={dataToModal}
            isSending={isSending}/>
        </div>
      }
      {showModalPreviewFormato && 
        <div className="modal-contactanos modal-response-ficha-background">
          <PreviewFormato showModal={togglePreviewFormato} idSolicitudMovimiento={idSolicitudMovimiento}/>
        </div>
      }
      <GenericModal
        showModal={showModal}
        titulo="Solicitud de Vacaciones"
        width="700px"
      >
        <div className="modal-body">
          <form>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Solicitante</label>
                <input type="text" className="form-control" value={formValues.Solicitante ?? ""} disabled/>
              </div>
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">email</label>
                <input type="text" className="form-control" value={formValues.Email ?? ""} disabled/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Área</label>
                <input type="text" className="form-control" value={formValues.Area ?? ""} disabled/>
              </div>
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Aprobador</label>
                <input type="text" className="form-control" value={formValues.Aprobador ?? ""} disabled/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-3">
                <label className="modal-label mb-0">Días disponibles</label>
                <input type="text" className="form-control" value={formValues.DiasDisponibles ?? 0} disabled/>
              </div>
              <div className="form-group col-sm-3">
                <label className="modal-label mb-0">Días Seleccionados</label>
                <input type="text" className="form-control" value={diasVacacionesCalculado ?? "-"} disabled/>
              </div>
              <div className="col-sm-6 d-flex align-items-center justify-content-center">
                <a className="btn generic-button-buscador" style={{width: "250px"}} target="_blank" href={formValues.LinkManual ?? ""}>
                  Manual de Aprobación y Rechazo de Vacaciones
                </a>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6 mb-0">
                <label className="modal-label mb-0">Desde</label>
                <input type="date" className="form-control" name="FechaDesde" value={formValues.FechaDesde ?? ""} onClick={handleClick} onChange={handleChange} disabled={isApproved ? !isApproved : !(formValues?.PuedeAprobar && formValues?.PuedeEditar)} />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-StartDate-validation-message`}></p>
              </div>
              <div className="form-group col-sm-6 mb-0">
                <label className="modal-label mb-0">Hasta</label>
                <input type="date" className="form-control" name="FechaHasta" value={formValues.FechaHasta ?? ""} onClick={handleClick} onChange={handleChange} disabled={isApproved ? !isApproved : !(formValues?.PuedeAprobar && formValues?.PuedeEditar)}/>
              </div>
            </div>
            <div className='mb-3'>
              {formValues.FechaDesdeUno &&
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaDesdeUno" value={formValues.FechaDesdeUno ?? ""} disabled/>
                  </div>
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaHastaUno" value={formValues.FechaHastaUno ?? ""} disabled/>
                  </div>
                </div>
              }
              {formValues.FechaDesdeDos &&
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaDesdeDos" value={formValues.FechaDesdeDos ?? ""} disabled/>
                  </div>
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaHastaDos" value={formValues.FechaHastaDos ?? ""} disabled/>
                  </div>
                </div>
              }
              {formValues.FechaDesdeTres &&
                <div className="form-row">
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaDesdeTres" value={formValues.FechaDesdeTres ?? ""} disabled/>
                  </div>
                  <div className="form-group col-sm-6">
                    <input type="date" className="form-control" name="FechaHastaTres" value={formValues.FechaHastaTres ?? ""} disabled/>
                  </div>
                </div>
              }
            </div>
            <div className="form-row">
              <div className="form-group col-sm-12 mb-0">
                <label className="modal-label mb-0">Observaciones</label>
                <textarea className="form-control" name="Comentarios" placeholder={"Completa en caso necesites dar más detalle de la solicitud"} maxLength={500} value={formValues.Comentarios ?? (formValues.PuedeAprobar ? "" : "-")} onClick={handleClick} onChange={handleTextArea} disabled={!formValues.PuedeAprobar}/>
                <div className="text-no-hay-novedades">{textAreaCount}/500</div>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Comentarios-validation-message`}></p>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-12">
                <label className="modal-label mb-0">Motivo de rechazo</label>
                <textarea className="form-control" name="MotivoDeRechazo" placeholder={"Este campo es obligatorio en caso de rechazo de la solicitud, ingresar un texto mayor a 15 caracteres"} minLength="15" maxLength={500} value={formValues.MotivoDeRechazo ?? (formValues.PuedeAprobar ? "" : "-")} onChange={handleTextAreaRechazo} disabled={!formValues.PuedeAprobar}/>
                <div className="text-no-hay-novedades">{textAreaCountRechazo}/500</div>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-MotivoDeRechazo-validation-message`}></p>
              </div>
            </div>
            <div className="modal-footer pl-0">
                <div>
                  {formValues?.Firma &&
                    <button type='button' className="generic-button-buscador ml-0" style={{width: "135px", backgroundColor: isDisabledAprobarRechazar ? '#92c0e8' : "", pointerEvents: isDisabledAprobarRechazar ? "none" : "auto"}} onClick={togglePreviewFormato} disabled={isInit || isDisabledAprobarRechazar}>
                        Preview Formato
                    </button>
                  }
                  {formValues?.Formato && 
                    <button type='button' className='generic-button-buscador ml-0' style={{width: '135px'}} onClick={handleDescargarFormato}>
                      Descargar Formato
                    </button>
                  }
                </div>
                <div className='d-flex'>
                  {(isApproved || isDisabledAprobarRechazar) && 
                    <div className='d-flex align-items-center mr-2'>
                      <button type="button" className="btn generic-button-buscador" onClick={handleGuardarSolicitud} disabled={isApproved ? !isApproved : isLoading || !formValues.PuedeAprobar}>
                        Guardar
                      </button>
                    </div>
                  }
                  {formValues.PuedeAprobar && 
                    <div className='d-flex flex-column'>
                      <button type='button' className='generic-button-buscador generic-button-hover-rojo' onClick={toggleMensajeConfirmacionRechazar} style={{backgroundColor: isDisabledAprobarRechazar ? '#e9babc' : "#CE6854", pointerEvents: isDisabledAprobarRechazar ? "none" : "auto"}} disabled={isDisabledAprobarRechazar}>
                          Rechazar
                      </button>
                      <button type='button' className='generic-button-buscador generic-button-hover-verde mt-2' onClick={toggleMensajeConfirmacionAprobar} style={{backgroundColor: isDisabledAprobarRechazar ? '#b7e4c7' : "#5bba6f", pointerEvents: isDisabledAprobarRechazar ? "none" : "auto"}} disabled={isDisabledAprobarRechazar}>
                          Aprobar
                      </button>
                    </div>
                  }
                </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha registo</label>
                <label className="">{formValues.FechaRegistro ?? "-"}</label>
              </div>
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha modificación</label>
                <label className="">{formValues.FechaModificacion ?? "-"}</label>
              </div>
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha aprobación.</label>
                <label className="">{formValues.FechaAprobacion ?? "-"}</label>
              </div>
            </div>
          </form>
        </div>
      </GenericModal>
      </>
    )
  }  

export default AprobarRechazarSolicitudVacaciones;

const dayAdded = [
  "Uno",
  "Dos",
  "Tres"
]