import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFind } from '../../../helper/utils';
import PageTable from '../../../components/pageComponents/PageTable'
import FormSelect from '../../../components/atoms/Select';
import ActionsIcons from '../../../components/atoms/ActionsIcons';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { eliminarAsistenciaLactarioAPI } from '../../../consumers/backendApisUrls';

const TableAsistenciaLactario = ({ formValues, setFormValues, setModalEnabled, paginator, setPaginator, keys: KEYS }) => {
  const rows = formValues.Items;

  const [downloadFormat] = useDescargarArchivoDeS3();

  const HEADERS = [
    { col: "col-sm-2", label: "Fecha de Registro" },
    {
      col: "col-sm-3", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.sedes)}
            description="Sede"
            selectedOptions={formValues.selectedFilters?.[KEYS.sedes] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.sedes)}
          />
        </div>
    },
    {
      col: "col-sm-2", label:
        <div className='d-flex justify-content-center'>
          <FormSelect
            options={handleFind(formValues.filters, KEYS.periodos)}
            description="Periodo"
            selectedOptions={formValues.selectedFilters?.[KEYS.periodos] ?? []}
            setSelectedOptions={(res) => handleFilterSelected(res, KEYS.periodos)}
          />
        </div>
    },
    { col: "col-sm-3", label: "Usuario" },
    { col: "col-sm-2", label: "Acciones" },
  ]

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = { ...formValues.selectedFilters, [key]: res };
    setFormValues(lastData => ({ ...lastData, selectedFilters: updatedSelectedFilters }));
  }

  const handleDownloadFormat = async ({ OriginalFileName, Path }) => {
    await downloadFormat(OriginalFileName, Path)
  }

  const handleDelete = (IdAsistenciaLactario, Empleado, Periodo) => {
    const name = `${Empleado} - ${Periodo}`;
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { api: eliminarAsistenciaLactarioAPI, id: IdAsistenciaLactario, name } });
  }

  return (
    <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
      <tbody className='listar-tbody'>
        {rows.map(row => (
          <tr key={row.IdAsistenciaLactario}>
            <td className='text-center'>{row.FechaRegistro}</td>
            <td className='text-center'>{row.Sede}</td>
            <td className='text-center'>{row.Periodo}</td>
            <td className='text-center'>{row.Empleado}</td>
            <td className='text-center'>
              <ActionsIcons>
                <FontAwesomeIcon
                  icon="paperclip"
                  title='Descargar Documento'
                  size='lg'
                  onClick={() => handleDownloadFormat(row.AsistenciaLactarioFormatoCompletadoFileViewModel)}
                />
                <FontAwesomeIcon
                  icon="times"
                  title='Descargar Documento'
                  size='lg'
                  onClick={() => handleDelete(row.IdAsistenciaLactario, row.Empleado, row.Periodo)}
                />
              </ActionsIcons>
            </td>
          </tr>
        ))}
      </tbody>
    </PageTable>
  )
}

export default TableAsistenciaLactario