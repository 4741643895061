import React, {useState, useRef, useEffect} from 'react';
import {useForm} from "../../../hooks/useForm/UseForm";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {rechazarCeseAPI} from "../../../consumers/backendApisUrls";

const RechazarCeseModal = ({showModal, idCese, nombreColaborador, manageSearch}) => {
    const sectionName = "RechazarCeseModal";
    const refContainer = useRef(null);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        return {
            "MotivoRechazo": ""
        };
    };

    const submitModalRechazarModal = async () => {
        console.debug("formValues ", formValues);

        let payload = formValues;
        payload["IdCese"] = idCese;
        console.debug("payload ", payload);
        let response = await executePostRequest(rechazarCeseAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["MotivoRechazo"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"900px"} titulo={"Rechazar Renuncia"}>
                <div className="modal-body">
                    <form ref={refContainer} className="blue-standard-color">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Datos colaborador</label>
                            <div className="col-sm-8 col-form-label ">
                                <label className="add-nota-cese-colaborador">{nombreColaborador}</label>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label ">Motivo de rechazo<span className="required">*</span></label>
                            <div className="col-sm-8 col-form-label ">
                                <textarea autoFocus data-required="true" rows="5" type="text" className="form-control" name="MotivoRechazo" onChange={handleChange} maxLength="2000" placeholder={"Máximo 2000 caracteres."}></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-MotivoRechazo-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <div className="message font-gothan-light" style={{'width': '500px'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submitModalRechazarModal}>Rechazar renuncia</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default RechazarCeseModal;

