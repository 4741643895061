import React, {useEffect, useState} from 'react';

import TableWebBody from './TableWebBody';
import FormSelect from '../../components/atoms/Select';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from "../../components/pageHeader/PageHeader";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {updateOptionsDeFiltro} from "../../helper/Listas/ListasUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {
    getAllAreasAPI,
    getAllCargosAPI,
    getAllDepartamentosAPI,
    getAllEstadosAPI,
    getAllModalidadesEscolaresAPI,
    getAllSedesAPI, getDivisionesAPI,
    seguimientoColaboradorDescargarReporteAPI,
    seguimientoColaboradorListarColaboradoresAPI,
} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest";
import {Spinner} from "react-bootstrap";
import Paginator from "../directorio/Paginator";
import {useManageGetRequest} from "../../hooks/useManageGetRequest/useManageGetRequest";
import {useGenerarDescargarEliminarArchivo} from "../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import {isNullOrUndefinded, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric} from "../../helper/utils";
import EditarLiquidacion from "../liquidaciones/modales/EditarLiquidacion";
import EditarDatosAcademicosModal from "./modales/EditarDatosAcademicosModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import ConfirmacionZipModal from './modales/ConfirmacionZipModal';
import { toast } from 'react-toastify';

const SeguimientoColaborador = () => {
    const custom = {
        fontSize: '12px'
    }
    const [loading, setLoading] = useState(true);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState([]);
    const [areasSeleccionadas, setAreasSeleccionadas] = useState([]);
    const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
    const [estadosVacunacionSeleccionadas, setEstadosVacunacionSeleccionadas] = useState([]);
    const [estadoVacunaRefuerzoSeleccionadas, setEstadoVacunaRefuerzoSeleccionadas] = useState([]);
    const [condicionesDeRiesgoSeleccionadas, setCondicionesDeRiesgoSeleccionadas] = useState([]);
    const [tieneEncuestasSaludSeleccionadas, setTieneEncuestasSaludSeleccionadas] = useState([]);
    const [tienePoliticaSeleccionadas, setTienePoliticaSeleccionadas] = useState([]);
    const [cartaCompromisoSeleccionadas, setCartaCompromisoSeleccionadas] = useState([]);
    const [cartaCapacitacionSeleccionadas, setCapacitacionoSeleccionadas] = useState([]);
    const [resultadoLaboratorioSeleccionadas, setResultadoLaboratorioSeleccionadas] = useState([]);
    const [pruebaCovidSeleccionadas, setPruebaCovidSeleccionadas] = useState([]);
    const [modeladosSeleccionados, setModeladosSeleccionados] = useState([]);
    const [modalidadesSeleccionadas, setModalidadesSeleccionadas] = useState([]);
    const [motivoNoVacunacionSeleccionadas, setMotivoNoVacunacionSeleccionadas] = useState([]);
    const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);

    const [nivelesSeleccionados, setNivelesSeleccionados] = useState([]);
    const [especialidadesSeleccionadas, setEspecialidadesSeleccionadas] = useState([]);
    const [cargosSeleccionados, setCargosSeleccionados] = useState([]);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [enviovacunazip, setEnvioVacunaZip] = useState(false);

    const [departamentosFiltradosOptions, setDepartamentosFiltradosOptions] = useState([]);
    const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
    const [sedesFiltradasOptions, setSedesFiltradasOptions] = useState([]);
    const [estadosVacunacionFiltradasOptions, setEstadosVacunacionFiltradasOptions] = useState([]);
    const [estadoVacunaRefuerzoFiltradasOptions, setEstadoVacunaRefuerzoFiltradasOptions] = useState([]);
    const [condicionDeRiesgoFiltradasOptions, setCondicionDeRiesgoFiltradasOptions] = useState([]);
    const [tieneEncuestasSaludFiltradasOptions, setTieneEncuestasSaludFiltradasOptions] = useState([]);
    const [tienePoliticaFiltradasOptions, setTienePoliticaFiltradasOptions] = useState([]);
    const [cartaCompromisoFiltradasOptions, setCartaCompromisoFiltradasOptions] = useState([]);
    const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);
    const [modalidadesFiltradasOptions, setModalidadesFiltradasOptions] = useState([]);
    const [pruebasCovidFiltradasOptions, setPruebasCovidFiltradasOptions] = useState([]);
    const [resultadoLaboratorioFiltradasOptions, setResultadoLaboratorioFiltradasOptions] = useState([]);
    const [capacitacionFiltradasOptions, setCapacitacionFiltradasOptions] = useState([]);
    const [modeladosFiltradasOptions, setModeladosFiltradasOptions] = useState([]);
    const [motivoNoVacunacionFiltradasOptions, setMotivoNoVacunacionFiltradasOptions] = useState([]);
    const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);

    const [allDepartamentosFiltradosOptions, setAllDepartamentosFiltradosOptions] = useState([]);
    const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
    const [allSedesFiltradasOptions, setAllSedesFiltradasOptions] = useState([]);
    const [allEstadosVacunacionFiltradasOptions, setAllEstadosVacunacionFiltradasOptions] = useState([]);
    const [allEstadosVacunasRefuerzoFiltradasOptions, setAllEstadosVacunasRefuerzoFiltradasOptions] = useState([]);
    const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);
    const [allTieneEncuestasSaludFiltradasOptions, setAllTieneEncuestasSaludFiltradasOptions] = useState([]);
    const [allTienePoliticaFiltradasOptions, setAllTienePoliticaFiltradasOptions] = useState([]);
    const [allCondicionesRiesgoOptions, setAllCondicionesRiesgoOptions] = useState([]);
    const [allCartaCompromisoFiltradasOptions, setAllCartaCompromisoFiltradasOptions] = useState([]);
    const [allCapacitacionFiltradasOptions, setAllCapacitacionFiltradasOptions] = useState([]);
    const [allResultadosLaboratorioFiltradosOptions, setAllResultadosLaboratorioFiltradosOptions] = useState([]);
    const [allPruebasCovidFiltradosOptions, setAllPruebasCovidsFiltradosOptions] = useState([]);
    const [allModeladosFiltradosOptions, setAllModeladosFiltradosOptions] = useState([]);
    const [allModalidadesFiltradosOptions, setAllModalidadesFiltradosOptions] = useState([]);
    const [allMotivoNoVacunacionFiltradosOptions, setAllMotivoNoVacunacionFiltradosOptions] = useState([]);
    const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);

    const [cleanFilterss, setCleanFilters] = useState(false);

    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const [manageGetRequest] = useManageGetRequest();
    const [managePostRequest] = useManagePostRequest();
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

    const [allUnidadesOptions, setAllUnidadesOptions] = useState([])
    const [allDepartamentosOptions, setAllDepartamentosOptions] = useState([])
    const [allAreasOptions, setAllAreasOptions] = useState([])

    const [filtroUnidadDepartamentoAreaConfig, setFiltroUnidadDepartamentoAreaConfig] = useState({})

    const [selectedUnidad, setSelectedUnidad] = useState({})
    const [selectedDepartamento, setSelectedDepartamento] = useState({})
    const [selectedArea, setSelectedArea] = useState({})

    const [unidadesOptions, setUnidadesOptions] = useState([])
    const [departamentosOptions, setDepartamentosOptions] = useState([])
    const [areasOptions, setAreasOptions] = useState([])

    const [hideModalEditarDatosAcademicos, setHideModalEditarDatosAcademicos] = useState(false);
    const [hideModalConfirmacionZip, setHideModalConfirmacionZip] = useState(false);
    const [idEmpleadoAcciones, setIdEmpleadoAcciones] = useState();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, departamentosSeleccionados, areasSeleccionadas, sedesSeleccionadas, nivelesSeleccionados, especialidadesSeleccionadas,
        cargosSeleccionados, estadosVacunacionSeleccionadas, estadoVacunaRefuerzoSeleccionadas, tieneEncuestasSaludSeleccionadas, tienePoliticaSeleccionadas,
        condicionesDeRiesgoSeleccionadas, modalidadesSeleccionadas,
        divisionSeleccionados]);

    useDidMountEffect(() => {
        console.debug("options de filtros por columnas seleccionados");
        manageSearch();
    }, [departamentosSeleccionados, areasSeleccionadas, sedesSeleccionadas, cargosSeleccionados, nivelesSeleccionados, especialidadesSeleccionadas,
        page, estadosVacunacionSeleccionadas, estadoVacunaRefuerzoSeleccionadas, tieneEncuestasSaludSeleccionadas, tienePoliticaSeleccionadas,
        condicionesDeRiesgoSeleccionadas, modalidadesSeleccionadas,
        divisionSeleccionados]);

    useDidMountEffect(() => {
        if (selectedUnidad?.value && filtroUnidadDepartamentoAreaConfig?.disableSelectUnidad !== true) {
            console.debug("selectedUnidad changed");
            manageSearch();
            resetSelectedDepartamento();
            resetSelectedArea();
        }
    }, [selectedUnidad]);

    useDidMountEffect(() => {
        if (selectedDepartamento?.value && filtroUnidadDepartamentoAreaConfig?.disableSelectDepartamento !== true) {
            console.debug("selectedDepartamento changed");
            manageSearch();
            resetSelectedArea();
        }
    }, [selectedDepartamento]);

    useDidMountEffect(() => {
        if (selectedArea?.value && filtroUnidadDepartamentoAreaConfig?.disableSelectArea !== true) {
            console.debug("selectedArea changed");
            manageSearch();
        }
    }, [selectedArea]);
    
    const getInitialData = async () => {
        setLoading(true);
        console.time("ListarseguimientoColaboradores");
        try {
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllDepartamentosFiltradosOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllModalidadesEscolaresAPI()), setAllModalidadesFiltradosOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllPruebasCovidsFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setAllSedesFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllResultadosLaboratorioFiltradosOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllCapacitacionFiltradasOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllModeladosFiltradosOptions);
            //await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllMotivoNoVacunacionFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
            await manageGetRequest(getAllEstadosAPI(), setEstados, false);
            await manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };
    
    const setUnidadesOptionss =  (response) => {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
        setAllUnidadesOptions(options);
    };
    
    const setDepartamentosOptionss =  (response) => {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
        setAllDepartamentosOptions(options);
    };
    
    const setAreasOptionss =  (response)  => {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
        setAllAreasOptions(options);
    };

    const setEstados = (response) => {
        setAllEstadosVacunacionFiltradasOptions(response.data)
        setAllEstadosVacunasRefuerzoFiltradasOptions(response.data)
        setAllTieneEncuestasSaludFiltradasOptions(response.data)
        setAllTienePoliticaFiltradasOptions(response.data)
        //setAllCartaCompromisoFiltradasOptions(response.data)
        setAllCondicionesRiesgoOptions(response.data);
    }

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const obtenerPayload = () => {
        let payload =  {
            "Criteria": criterioDeBusqueda,
            "IdsDepartamentos": departamentosSeleccionados,
            "IdsAreas": areasSeleccionadas,
            "IdsSedes": sedesSeleccionadas,
            "IdsEstadoVacunas": estadosVacunacionSeleccionadas,
            "IdsEstadoVacunaRefuerzo": estadoVacunaRefuerzoSeleccionadas,
            "IdsTieneEncuestaSalud": tieneEncuestasSaludSeleccionadas,
            "IdsTienePolitica" : tienePoliticaSeleccionadas,
            //"IdsEstadosCartaCompromiso": cartaCompromisoSeleccionadas,
            "IdsNiveles": nivelesSeleccionados,
            "IdsEspecialidades": especialidadesSeleccionadas,
            "IdsCargos": cargosSeleccionados,
            //"IdsEstadosCapacitacion": cartaCapacitacionSeleccionadas,
            "Page": page,
            "IdDepartamento": null,
            "IdUnidad": null,
            "IdArea": null,
            "IdsCondicionDeRiesgo": condicionesDeRiesgoSeleccionadas,
            //"IdsResultadosCovid": resultadoLaboratorioSeleccionadas,
            //"IdsPruebaCovid": pruebaCovidSeleccionadas,
            //"IdsModeladosCapacitacion": modeladosSeleccionados,
            "IdsModalidades": modalidadesSeleccionadas,
            //"IdsMotivosNoVacunacion": motivoNoVacunacionSeleccionadas,
            "IdsDivisiones": divisionSeleccionados,
        };

        return payload;
    }

    const manageSearch = async () => {
        setLoading(true);
        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = obtenerPayload();
        await executeSearch(payload);
    };

    const updateOptionsDeFiltros = async function (lists) {
        await updateOptionsDeFiltro(allDepartamentosFiltradosOptions, lists["IdsDepartamentos"], setDepartamentosFiltradosOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
        await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
        await updateOptionsDeFiltro(allSedesFiltradasOptions, lists["IdsSedes"], setSedesFiltradasOptions, executeGetRequestCallback(getAllSedesAPI()));
        await updateOptionsDeFiltro(allEstadosVacunacionFiltradasOptions, lists["IdsEstadoVacunas"], setEstadosVacunacionFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allEstadosVacunasRefuerzoFiltradasOptions, lists["IdsEstadoVacunaRefuerzo"], setEstadoVacunaRefuerzoFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allTieneEncuestasSaludFiltradasOptions, lists["IdsTieneEncuestaSalud"], setTieneEncuestasSaludFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allTienePoliticaFiltradasOptions, lists["IdsTienePolitica"], setTienePoliticaFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allCondicionesRiesgoOptions, lists["IdsCondicionDeRiesgo"], setCondicionDeRiesgoFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        //await updateOptionsDeFiltro(allCartaCompromisoFiltradasOptions, lists["IdsEstadosCartaCompromiso"], setCartaCompromisoFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
        //await updateOptionsDeFiltro(allCapacitacionFiltradasOptions, lists["IdsEstadosCapacitacion"], setCapacitacionFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        //await updateOptionsDeFiltro(allResultadosLaboratorioFiltradosOptions, lists["IdsResultadosCovid"], setResultadoLaboratorioFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        //await updateOptionsDeFiltro(allPruebasCovidFiltradosOptions, lists["IdsPruebaCovid"], setPruebasCovidFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        //await updateOptionsDeFiltro(allModeladosFiltradosOptions, lists["IdsModeladosCapacitacion"], setModeladosFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        //await updateOptionsDeFiltro(allMotivoNoVacunacionFiltradosOptions, lists["IdsMotivosNoVacunacion"], setMotivoNoVacunacionFiltradasOptions, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
        setFiltroUnidadDepartamentoAreaConfig(lists?.FiltroUnidadDepartamentoAreaConfig);
        await updateOptions(lists?.FiltroUnidadDepartamentoAreaConfig);
    };

    const updateOptions = async (FiltroUnidadDepartamentoAreaConfig) => {
        let allUNIDADES = allUnidadesOptions;
        let allDEPARTAMENTOS = allDepartamentosOptions;
        let allAREAS = allAreasOptions;

        if (allUNIDADES.length === 0 || isNullOrUndefinded(allUNIDADES)){
            let response = await manageGetRequest(getDivisionesAPI(), setUnidadesOptionss, false);
            allUNIDADES = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
        }

       if (allDEPARTAMENTOS.length === 0 || isNullOrUndefinded(allDEPARTAMENTOS)){
           let response =  await manageGetRequest(getAllDepartamentosAPI(), setDepartamentosOptionss, false);
           allDEPARTAMENTOS = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
       }

       if (allAREAS.length===0 || isNullOrUndefinded(allAREAS)){
           let response = await manageGetRequest(getAllAreasAPI(), setAreasOptionss, false);
           allAREAS = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data, "Value", "Text");
       }

        let unidadesFinal = allUNIDADES.filter((item, i) => FiltroUnidadDepartamentoAreaConfig?.IdsUnidades.includes(item?.value));
        setUnidadesOptions(unidadesFinal);

        let departamentosFinal = allDEPARTAMENTOS.filter((item, i) => FiltroUnidadDepartamentoAreaConfig?.IdsDepartamentos.includes(item?.value));
        setDepartamentosOptions(departamentosFinal);

        let areasFinal = allAREAS.filter((item, i) => FiltroUnidadDepartamentoAreaConfig?.IdsAreas.includes(item?.value));
        setAreasOptions(areasFinal);


        if (unidadesFinal && unidadesFinal.length === 1 && selectedUnidad?.value !== unidadesFinal[0]?.value) {
            setSelectedUnidad({value: unidadesFinal[0]?.value, label: unidadesFinal[0]?.label})
        }
        if (departamentosFinal && departamentosFinal.length === 1 && selectedDepartamento?.value !== departamentosFinal[0]?.value) {
            setSelectedDepartamento({value: departamentosFinal[0]?.value, label: departamentosFinal[0]?.label})
        }
        if (areasFinal && areasFinal.length === 1 && selectedArea?.value !== areasFinal[0]?.value) {
            setSelectedArea({value: areasFinal[0]?.value, label: areasFinal[0]?.label})
        }


        setLoading(false);
        console.timeEnd("ListarseguimientoColaboradores");
    }

    const executeSearch = async (payload) => {
        const response = await executePostRequest(seguimientoColaboradorListarColaboradoresAPI(), payload);
        if (responseCode200(response)) {
            setUsuariosFiltrados(response.data.data.Items);
            setTotalItems(response.data.data.TotalItems);
            setFirsItemPage(response.data.data.FirsItemPage);
            setLastItemPage(response.data.data.LastItemPage);
            setTotalPages(response.data.data.TotalPages);
            setEnvioVacunaZip(response.data.data.ExtraData.EnvioVacunaZip);
            await updateOptionsDeFiltros(response.data.data.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setDepartamentosSeleccionados([]);
        setAreasSeleccionadas([]);
        setSedesSeleccionadas([]);
        setEstadosVacunacionSeleccionadas([]);
        setEstadoVacunaRefuerzoSeleccionadas([]);
        setTieneEncuestasSaludSeleccionadas([]);
        setTienePoliticaSeleccionadas([]);
        setCondicionesDeRiesgoSeleccionadas([]);
        //setCartaCompromisoSeleccionadas([]);
        setNivelesSeleccionados([]);
        setEspecialidadesSeleccionadas([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setCargosSeleccionados([]);
        resetSelectedUnidad();
        resetSelectedDepartamento();
        resetSelectedArea();
        //setCapacitacionoSeleccionadas([]);
        //setResultadoLaboratorioSeleccionadas([]);
        //setPruebaCovidSeleccionadas([]);
        //setModeladosSeleccionados([]);
        setModalidadesSeleccionadas([]);
        //setMotivoNoVacunacionSeleccionadas([]);
        setDivisionSeleccionados([]);
    };

    const resetSelectedUnidad = function () {
        setSelectedUnidad({ value: null, label: null})
    }

    const resetSelectedDepartamento = function () {
        setSelectedDepartamento({ value: null, label: null})
    }

    const resetSelectedArea = function () {
        setSelectedArea({ value: null, label: null})
    }

    const descargar = async function () {
        let payload = obtenerPayload();
        //await generarDescargarEliminarArchivo(seguimientoColaboradorDescargarReporteAPI(), payload);

        let response = await executePostRequest(seguimientoColaboradorDescargarReporteAPI(), payload);

        if (responseCode200(response)) {
            toast.success(response.data.Message);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    
    const allvacunazip  = async ()=> {
        let payload = obtenerPayload();
        toggleModalConfirmacionZip();
    };

    const editarDatosAcademicos = function () {
        console.debug("submit")
    }
    
    const toggleModalEditarDatosAcademicos = function () {
        setHideModalEditarDatosAcademicos(!hideModalEditarDatosAcademicos);
    }

    const toggleModalConfirmacionZip = function () {
        setHideModalConfirmacionZip(!hideModalConfirmacionZip);
    }

    const openEditDatosAcademicosModal = function (idEmpleado) {
        toggleModalEditarDatosAcademicos();
        setIdEmpleadoAcciones(idEmpleado);
    }

    return (
        <Wrapper className='App'>
            <PageHeader title={"Seguimiento Colaboradores"}/>

            {loading === true &&
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
            </div>
            }

            {hideModalEditarDatosAcademicos &&
            <div className="modal-contactanos modal-response-ficha-background">
                <EditarDatosAcademicosModal toggleModal={toggleModalEditarDatosAcademicos} refreshTable={manageSearch} idEmpleadoAcciones={idEmpleadoAcciones}/>
            </div>
            }

            {hideModalConfirmacionZip &&
            <div className="modal-contactanos modal-response-ficha-background">
                <ConfirmacionZipModal toggleModal={toggleModalConfirmacionZip} obtenerPayload={obtenerPayload}/>
            </div>
            }

            <div hidden={loading}>
                <div className="generic-buscador-container">
                    <div className="filtros-left-section">
                        <input
                            type='text'
                            className='generic-input-criterio-busqueda'
                            placeholder='Buscar por nombre'
                            value={criterioDeBusqueda}
                            onChange={(e) => {
                                setCriterioDeBusqueda(e.target.value);
                            }}
                            onKeyPress={handleKeypress}
                        />
                        <button
                            onClick={manageSearch}
                            type='button'
                            className='generic-button-buscador'>
                            Buscar
                        </button>

                        <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                    </div>
                    <div className="filtros-right-section">
                        {
                            enviovacunazip ? (
                                    <button
                                        onClick={allvacunazip}
                                        type='button'
                                        className='generic-button-buscador'>
                                        Descargar Carnet { }
                                        <p data-tip data-for="DescargarCarnet" className="ficha-info-div-clock-font-awesome">
                                            <FontAwesomeIcon icon='info-circle' className="hand-pointer change-requested-field-clock"/>
                                        </p>
                                        <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'DescargarCarnet'} effect="solid" place="top">
                                            Descargara todos los carnet de vacuna en .Zip
                                        </ReactTooltip>
                                    </button>
                            ):null
                        }
                        <button
                            onClick={descargar}
                            type='button'
                            className='generic-button-buscador'>
                            Descargar
                        </button>
                    </div>
                </div>
                <div className='pt-3'>
                        <div className="table-scroll">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                            <tr>
                                <th>
                                    <div className='flex font-gothan-medium' style={custom}>Nombre Completo</div>
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={departamentosFiltradosOptions}
                                        description={'Departamento'}
                                        setSelectedOptions={setDepartamentosSeleccionados}
                                        selectedOptions={departamentosSeleccionados}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={areasFiltradasOptions}
                                        description={'Área'}
                                        setSelectedOptions={setAreasSeleccionadas}
                                        selectedOptions={areasSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={cargosFiltradasOptions}
                                        description={'Cargo'}
                                        setSelectedOptions={setCargosSeleccionados}
                                        selectedOptions={cargosSeleccionados}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        description={'División'}
                                        options={divisionFiltradosOptions}
                                        setSelectedOptions={setDivisionSeleccionados}
                                        selectedOptions={divisionSeleccionados}
                                    />
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium' style={custom}>% Avance Ficha</div>
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={tienePoliticaFiltradasOptions}
                                        description={'Firma de políticas'}
                                        setSelectedOptions={setTienePoliticaSeleccionadas}
                                        selectedOptions={tienePoliticaSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={estadosVacunacionFiltradasOptions}
                                        description={'Vacunación'}
                                        setSelectedOptions={setEstadosVacunacionSeleccionadas}
                                        selectedOptions={estadosVacunacionSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={estadoVacunaRefuerzoFiltradasOptions}
                                        description={'Vacuna Refuerzo'}
                                        setSelectedOptions={setEstadoVacunaRefuerzoSeleccionadas}
                                        selectedOptions={estadoVacunaRefuerzoSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={tieneEncuestasSaludFiltradasOptions}
                                        description={'Completo Ficha Médica'}
                                        setSelectedOptions={setTieneEncuestasSaludSeleccionadas}
                                        selectedOptions={tieneEncuestasSaludSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        custom={custom}
                                        options={condicionDeRiesgoFiltradasOptions}
                                        description={'Condición de Riesgo'}
                                        setSelectedOptions={setCondicionesDeRiesgoSeleccionadas}
                                        selectedOptions={condicionesDeRiesgoSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium' style={custom}>Acciones</div>
                                </th>
                                
                            </tr>
                            </thead>
                            <TableWebBody filas={usuariosFiltrados} openEditDatosAcademicosModal={openEditDatosAcademicosModal}/>
                        </table>
                        </div>

                        {totalPages > 0 && (
                            <Paginator totalItems={totalItems}
                                       firsItemPage={firsItemPage}
                                       lastItemPage={lastItemPage}
                                       totalPages={totalPages}
                                       page={page}
                                       onLeftClic={onLeftClic}
                                       onRightClic={onRightClic}/>
                        )}
                </div>
            </div>
        </Wrapper>
    );
};

export default SeguimientoColaborador;
