import React from "react";
import DatePickerReact from "react-datepicker";

const Div = ({ col = 6, className = "", children }) => (
  <div className={`col-sm-${col} px-1 ${className}`}>
    {children}
  </div>
)

const InputDates = ({ label, name, value, onChange, sectionName, nameValidation, minDate, excludeDates, isDisabled }) => (
  <>
    <label className="modal-label mb-0">{label}</label>
    <DatePicker name={name} value={value} onChange={onChange} sectionName={sectionName} nameValidation={nameValidation} minDate={minDate} excludeDates={excludeDates} isDisabled={isDisabled}/>
  </>
)

const MiraPolitica = ({ linkPolitica }) => (
  <a className="btn generic-button-buscador" rel='noopener noreferrer' style={{width: "135px"}} target="_blank" href={linkPolitica ?? ""}>
    Mira aquí la política
  </a>
)

const DatePicker = ({ name, value, onChange, sectionName, nameValidation, minDate, excludeDates, isDisabled }) => {
  const parseYYMMDDtoDate = (stringDate) => {
    if(stringDate){
      let parts =stringDate?.split('-');
      return new Date(parts[0], parts[1] - 1, parts[2]);
    }
  }

  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  return(
    <>
      <DatePickerReact className="form-control" 
        name={name}
        selected={parseYYMMDDtoDate(value) ?? ""}
        onChange={(date) => handleOnChange(new Date(date).toISOString().split("T")[0])}
        minDate={minDate}
        excludeDates={excludeDates}
        dateFormat="dd/MM/yyyy"
        placeholderText="dd/mm/aaaa"
        disabled={isDisabled}
      />
      <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${nameValidation}-validation-message`}></p>
    </>
  )
}

export { Div, InputDates, MiraPolitica, DatePicker }