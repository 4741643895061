import React from 'react'
import { cleanDropdown, handleChangeSelect, handleFind, handleFindDropdownMultiSelected, handleFindDropdownSelected } from '../../helper/utils';

export const usePropsSelect = (allDropdowns, formValues, setFormValues, sectionName) => {
  const propsSelectFromPromises = (key, cleanValues = [], isMulti = false) => {

    return {
        name: key,
        options: handleFind(allDropdowns, key),
        value: isMulti 
                ? handleFindDropdownMultiSelected(allDropdowns, formValues, key) 
                : handleFindDropdownSelected(allDropdowns, formValues, key),
        onChange: (res) => {
          handleChangeSelect(setFormValues, res, key);
          if(cleanValues.length) cleanDropdown(setFormValues, cleanValues);
        },
        sectionName
    }
  }
  return [propsSelectFromPromises];
}