import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../../hooks/context/authContext";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import { getDetalleSeguimientoCasoCovid, getDetallesParaCrearCasoCovid } from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import GenericModal from "../../../components/modal/GenericModal";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useForm } from '../../../hooks/useForm/UseForm';
import ScrollTabsPanel from './ScrollTabsPanel/Index';
import ScrollDetailsPanel from './ScrollDetailsPanel/Index';


const SeguimientoCovidModal = ({
  showModal,
  idRegistro,
  idEmpleado,
  toggleDetalleEncuestaSaludModal,
  toggleDetalleVacunaModal,
  togglDatosContactosModal,
  manageSearch,
  showProgramarPrueba,
  modoCrear
}) => {
  const { state } = useContext(AuthContext);
  const [executeGetRequest] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const seccionName = "data";

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);


  const [fechaEncuesta, setFechaEncuesta] = useState("");
  const [detalles, setDetalles] = useState({});


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);


  const init = async function () {

    if (!modoCrear) {
      if (idRegistro) {
        const response = await executeGetRequest(getDetalleSeguimientoCasoCovid(idRegistro));
        if (responseCode200(response)) {
          const detail = response.data;
          setDetalles(detail)
        } else {
          mostrarMensajeDeErrorConToast(response);
        }
      }
    }

    if (modoCrear) {
      if (idEmpleado !== null) {
        const response = await executeGetRequest(getDetallesParaCrearCasoCovid(idEmpleado));
        if (responseCode200(response)) {
          const detail = response.data;
          setDetalles(detail)
        } else {
          mostrarMensajeDeErrorConToast(response);
        }
      }
    }
  }

  const reload = () => {
    init();
  }

  const successSubmitCallback = function () {
    showModal();
    manageSearch();
  }

  return (
    <>
      <GenericModal showModal={showModal} width={"1230px"} titulo={"Seguimiento Covid"}>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row mt-4">
              <ScrollDetailsPanel reload={reload} idRegistro={idRegistro} informacionGeneral={detalles?.Data} permisos={detalles?.Permissions} toggleDetalleEncuestaSaludModal={toggleDetalleEncuestaSaludModal} toggleDetalleVacunaModal={toggleDetalleVacunaModal} togglDatosContactosModal={togglDatosContactosModal} />
            </div>
            <div className="row mt-4">
              <ScrollTabsPanel
                idRegistro={idRegistro}
                idEmpleado={idEmpleado}
                sintomas={detalles?.SintomasData}
                permisos={detalles?.Permissions}
                modoCrea={detalles?.Data?.EsModoCrea}
                successSubmitCallback={successSubmitCallback}
                showProgramarPrueba={() => showProgramarPrueba()}
                informacionGeneral={detalles?.Data}
                reloadModal={init}
              />
            </div>
          </div>
        </div>
      </GenericModal>
    </>
  );
};

export default SeguimientoCovidModal;
