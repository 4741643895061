import React, { useContext, useEffect, useState } from 'react';
import GenericModal from "../modal/GenericModal";
import { AuthContext } from "../../hooks/context/authContext";
import { useForm } from "../../hooks/useForm/UseForm";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import SelectInput from "../Select/SelectInput";
import { getCondicionesMedicasAPI, guardarEncuestaSaludAPI } from "../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import { toast } from "react-toastify";
import {
    clearInputValue,
    extensionYtamañoDeArchivoEsValido,
    getFormattedName,
    jsonEstaVacio,
    mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary,
    obtenerArchivoDeEvent,
    responseUploadFileSuccess
} from "../../helper/utils";
import MultiSelectInput from "../MultiSelectInput/MultiSelectInput";
import GenericUploadFile from "../GenericUploadFile/GenericUploadFile";
import { uploadFile } from '../../consumers/backendConsumer';
import { EXTENSIONES_VALIDAS_DE_ARCHIVOS } from '../../constants/hardCodedConstants';
import { showToastErrorMaxFileSize } from '../alerts/AlertErrorMaxFileSize';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../validations/FormValidatorUtils';
import GenericUploadFileV2 from '../GenericUploadFile/GenericUploadFileV2';
import ExtraInfoToolTip from '../GenericUploadFile/ExtraInfoToolTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import CustomInfoTooTip from '../tooltip/CustomInfoToolTip';


const RegistroEncuestaSaludModal = ({ showModal, title, obtenerIdEncuenta, setReload }) => {

    const sectionName = "EncuestaSaludFormulario";
    const UPDATE_CERTIFICADO_MEDICO_INPUT_FILE = "ficha-edit-certificado-medico-file";
    const UPDATE_PERSONA_A_CARGO_CERTIFICADO_MEDICO_INPUT_FILE = "ficha-edit-responsable-certificado-medico-file";
    const UPDATE_PERSONA_A_CARGO_SUSTENTO_INPUT_FILE = "ficha-edit-responsable-sustento-file";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const EMBARAZO_ID = 993;
    const LACTANTE_ID = 994;

    const { state } = useContext(AuthContext);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [showFormularioSalud, setFormularioSalud] = useState(false);
    const [jsonSelectedPresentaCondicionMedica, setJsonSelectedPresentaCondicionMedica] = useState({});
    const [multiSelectedCondicionMedica, setMultiSelectedCondicionMedica] = useState([]);
    const [jsonSelectedConviveConPersonaCondicionMedica, setJsonSelectedConviveConPersonaCondicionMedica] = useState({});
    const [jsonSelectedEsResponsableDePersonaCondicionMedica, setJsonSelectedEsResponsableDePersonaCondicionMedica] = useState({});
    const [YesOrNoOptions, setYesOrNoOptions] = useState([{ value: false, label: "No" }, { value: true, label: "Si" }]);
    const [condicionesMedicasOptions, setCondicionesMedicasOptions] = useState([]);
    const [uploadFileCertificadoMedico, setUploadFileCertificadoMedico] = useState({});
    const [uploadFilePersonaACargoCertificadoMedico, setUploadFilePersonaACargoCertificadoMedico] = useState({});
    const [uploadFilePersonaACargoSustento, setUploadFilePersonaACargoSustento] = useState({});
    const allInputKeys = ["PresentaCondicionMedica", "IdsCondicionesMedicas", "CertificadoCondicionMedica", "ConviveConPersonaCondicionMedica", "EsResponsableDePersonaCondicionMedica", "PersonaACargoCertificadoCondicionMedica", "SustentoPersonaACargoCondicionMedica", "AceptaDeclaracionJurada", "FechaEmbarazoLactante"];
    const [declaracionJurada, setDeclaracionJurada] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {

    }, []);

    useDidMountEffect(() => {

    }, [])

    const init = async function () {

        let condicionesMedicasData = await getCondicionesMedicas();

        if (condicionesMedicasData && condicionesMedicasData.length > 0) {
            let condicionesMedicas = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(condicionesMedicasData);
            setCondicionesMedicasOptions(condicionesMedicas);
        }
    }

    const getCondicionesMedicas = async function () {
        const response = await executeGetRequest(getCondicionesMedicasAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const toggleFormularioSaludModal = () => {
        setFormularioSalud(!showFormularioSalud);
    };

    const submit = async (event) => {
        try {
            event.preventDefault();
            let payload = {
                "PresentaCondicionMedica": jsonSelectedPresentaCondicionMedica.value ?? null,
                "IdsCondicionesMedicas": jsonSelectedPresentaCondicionMedica.value ? multiSelectedCondicionMedica : null,
                "CertificadoCondicionMedica": jsonSelectedPresentaCondicionMedica.value && !jsonEstaVacio(uploadFileCertificadoMedico) ? uploadFileCertificadoMedico : null,
                "ConviveConPersonaCondicionMedica": jsonSelectedConviveConPersonaCondicionMedica.value ?? null,
                "EsResponsableDePersonaCondicionMedica": jsonSelectedConviveConPersonaCondicionMedica.value ? jsonSelectedEsResponsableDePersonaCondicionMedica.value ?? null : null,
                "PersonaACargoCertificadoCondicionMedica": jsonSelectedConviveConPersonaCondicionMedica.value && !jsonEstaVacio(uploadFilePersonaACargoCertificadoMedico) ? uploadFilePersonaACargoCertificadoMedico : null,
                "SustentoPersonaACargoCondicionMedica": jsonSelectedConviveConPersonaCondicionMedica.value && !jsonEstaVacio(uploadFilePersonaACargoSustento) ? uploadFilePersonaACargoSustento : null,
                "AceptaDeclaracionJurada": declaracionJurada,
                "FechaEmbarazoLactante" : formValues["FechaEmbarazoLactante"]
            };
            let response = await executePostRequest(guardarEncuestaSaludAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                if (setReload) setReload((reload) => !reload);
                closeForm();
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }

            obtenerIdEncuenta();
            
        } catch (e) {
            console.error(e);
        }
    };

    const closeForm = () => showModal();

    const showAllOptions = (event) => {
        let element = document.getElementById("focus-element");
        element.scrollIntoView({ block: "start", behavior: "smooth" });
    }

    const handleDeclaracionJurada = (e) => {
        setDeclaracionJurada(e.target.checked);
    };

    return (
        <>
            {!showFormularioSalud &&
                <GenericModal showModal={showModal} width={"850px"} titulo={title}>
                    <div className="modal-body">
                        <strong>¡Queremos saber sobre tu salud!</strong>
                        <br />
                        <br />
                        <p>Nos preparamos para el retorno a las escuelas y desde el área de Seguridad y Salud en el Trabajo es indispensable tener la información sobre las condiciones de salud de nuestros trabajadores.

                        </p>
                        <p>
                            Antes que completes esta ficha es importante que realices el cálculo de tu IMC (Índice de Masa Corporal) en el siguiente link:  &nbsp;
                            <a href="https://www.cdc.gov/healthyweight/spanish/assessing/bmi/adult_bmi/metric_bmi_calculator/bmi_calculator.html" target="_blank">
                                IMC Calculator</a>
                        </p>
                        <p>
                            Recuerda que el presente documento tiene validez de declaración jurada, es obligatoria y de carácter confidencial. Toda información sobre tu salud será manejada de manera confidencial y solo será accesible a nuestro médico ocupacional.
                            En caso marques "Sí" a alguna de las condiciones médicas del listado, es obligatorio  que subas el informe médico o certificado médico que lo acrediten.
                        </p>
                    </div>
                    <div className="modal-footer" style={{ 'display': 'flex', 'justifyContent': 'flex-end' }}>
                        <div className="button" style={{ 'alignSelf': 'flex-end' }}>
                            <button className="btn btn-primary generic-button-ficha" onClick={toggleFormularioSaludModal}>Ingresar</button>
                        </div>
                    </div>
                </GenericModal>
            }
            {showFormularioSalud &&
                <GenericModal showModal={showModal} width={"1300px"} titulo={title}>
                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <p className="text-justify">
                                    Nos preparamos para el retorno a las escuelas y desde el área de Seguridad y Salud en el Trabajo es indispensable tener la información sobre las condiciones de salud de nuestros trabajadores.
                                    Antes que completes esta ficha es importante que realices el cálculo de tu IMC (Índice de Masa Corporal) en el siguiente link: &nbsp;
                                    <a href="https://www.cdc.gov/healthyweight/spanish/assessing/bmi/adult_bmi/metric_bmi_calculator/bmi_calculator.html" target="_blank">
                                        IMC Calculator</a>
                                    <br />
                                    Recuerda que el presente documento tiene validez de declaración jurada, es obligatoria y de carácter confidencial. Toda información sobre tu salud será manejada de manera confidencial y solo será accesible a nuestro médico ocupacional.
                                    En caso marques "Sí" a alguna de las condiciones médicas del listado, es obligatorio  que subas el informe médico o certificado médico que lo acrediten.
                                </p>
                            </div>
                            <div className="row">
                                <h5 className="text-dark mb-0"><strong>Sección 1: Sobre tu salud</strong></h5>
                                <ol>
                                    <li>
                                        <div className="form-group row">
                                            <label className="col-sm-7 col-form-label text-justify" >
                                                ¿Presentas alguna de las siguientes condiciones consideradas de riesgo ante el contagio de Covid-19?
                                                Cáncer, enfermedad renal crónica, enfermedad pulmonar crónica: EPOC (enfermedad pulmonar obstructiva crónica); fibrosis quística; hipertensión pulmonar;  asma grave o no controlada, afecciones cardiacas tales como: insuficiencia cardiaca; enfermedad de las arterias coronarias o miocardiopatías, diabetes mellitus tipo 1 y tipo 2, obesidad con IMC de 30 kg/m2 o más, es paciente inmunodeprimido (sistema inmunológico debilitado) con inmunodeficiencias primarias, uso prolongado de corticosteroides u otros medicamentos inmunosupresores, es receptor de trasplante de órganos sólidos o células madre sanguíneas, enfermedad cerebrovascular (infarto o hemorragia cerebral), hipertensión arterial, síndrome de down, infección por VIH, embarazo, madre lactante con bebés menores de 6 meses de edad, edad mayor a 65 años.
                                                <span className="required">*</span>
                                            </label>
                                            <div className="col-sm-5 black-color">
                                                <SelectInput options={YesOrNoOptions} jsonSelectedValue={jsonSelectedPresentaCondicionMedica} setJsonSelectedValue={setJsonSelectedPresentaCondicionMedica} placeholder={"Seleccionar"} />
                                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-PresentaCondicionMedica-validation-message`}></p>
                                            </div>
                                        </div>
                                    </li>
                                    {
                                        jsonSelectedPresentaCondicionMedica.value &&
                                        <li>
                                            <div className="form-group row">
                                                <label className="col-sm-7 col-form-label text-justify" id="focus-element">
                                                    ¿Cuál de estas condiciones médicas presentas? Dependiendo de tu condición, puedes elegir más de una.
                                                    <span className="required">*</span>
                                                </label>
                                                <div className="col-sm-5 black-color" onClick={showAllOptions}>
                                                    <MultiSelectInput options={condicionesMedicasOptions} selectedValues={multiSelectedCondicionMedica} 
                                                    setSelectedValues={setMultiSelectedCondicionMedica} placeholder={"Seleccionar condición médica"} />
                                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCondicionesMedicas-validation-message`}></p>
                                                </div>
                                            </div>
                                        </li>
                                    }
                                    
                                    {jsonSelectedPresentaCondicionMedica.value &&
                                        <li>
                                            <div className="form-group row">
                                                <label className="col-sm-7 col-form-label text-justify">
                                                    Adjunta un documento emitido por tu médico tratante, certificando la condición médica que presentas (informe médico o certificado médico). El documento debe contener datos personales, diagnóstico, fecha de emisión (debe ser reciente), firma y sello del médico. Este documento será de acceso exclusivo al médico ocupacional de Colegios Peruanos S.A.
                                                    <span className="required">*</span>
                                                </label>
                                                <div className="col-sm-5 black-color">
                                                    <table className="table">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <GenericUploadFileV2 uploadFileToS3={setUploadFileCertificadoMedico} id={UPDATE_CERTIFICADO_MEDICO_INPUT_FILE} textButton={"Subir archivo"} />
                                                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-CertificadoCondicionMedica-validation-message`}></p>
                                                            
                                                                    { !(multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID).length > 0  &&
                                                                      multiSelectedCondicionMedica.filter(e => e === LACTANTE_ID).length > 0) &&
                                                                        <div>
                                                                        {multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID).length > 0 && <span className="required">*Adjunte tarjeta de control</span>}
                                                                        {multiSelectedCondicionMedica.filter(e => e === LACTANTE_ID).length > 0 && <span className="required">*Adjunte acta de nacimiento</span>}
                                                                        </div>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    { !(multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID).length > 0  &&
                                                                        multiSelectedCondicionMedica.filter(e => e === LACTANTE_ID).length > 0) &&
                                                                        <div>
                                                                        {multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID || e === LACTANTE_ID).length > 0 &&
                                                                             <div>
                                                                            <input type="date" className="form-control" name="FechaEmbarazoLactante" onChange={handleChange} value={formValues?.FechaEmbarazoLactante ?? ""}></input>
                                                                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaEmbarazoLactante-validation-message`}></p>
                                                                            </div>
                                                                        }
                                                                        {multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID).length > 0 && <span className="required">*Indique fecha probable de parto</span>}
                                                                        {multiSelectedCondicionMedica.filter(e => e === LACTANTE_ID).length > 0 && <span className="required">*Indique fecha de nacimiento</span>}
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                              
                                            </div>
                                            <p className="modal-label text-justify mb-0">* Colegios Peruanos S.A. puede solicitar información/documentación adicional en caso sea necesario.</p>
                                            <p className="modal-label text-justify mb-0">* Formato permitido imagen o .pdf de máximo 8MB.</p>
                                        </li>
                                    }

                                </ol>

                            </div>
                            <div className="form-check margin-top-15px">
                                <input className="form-check-input" type="checkbox" id="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
                                <label className="form-check-label ficha-declaracion-jurada-message" htmlFor="AceptaDeclaracionJurada">
                                    <strong><p className={"mb-0"}>Todos los datos y documentos ingresados en el presente Registro constituyen una Declaración Jurada de mi parte. He sido informado que de proporcionar información falsa puedo perjudicar la salud de mis compañeros/as, estudiantes, familias y la mía propia, lo cual constituye una falta grave contra la salud pública ante la cual la empresa podrá adoptar las acciones de índole laboral y legal correspondientes.</p></strong>
                                </label>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-AceptaDeclaracionJurada-validation-message`}></p>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer d-flex justify-content-end" >
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={submit}>Enviar</button>
                        </div>
                    </div>
                </GenericModal>
            }
        </>
    );
};

export default RegistroEncuestaSaludModal;