import React, { useEffect, useState } from 'react'
import GenericModal from '../../../../components/modal/GenericModal'
import { useForm } from '../../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import Select from "react-select";
import { formNames } from '../formNames';
import { setStateWithCallBackResponse } from '../../../../helper/utils';
import { getCorreosTemplatesAPI, getEditarConfiguracionLiquidacion, getPdfTemplatesAPI, getTiposPlantillaAPI, liquidacionesAgregarConfiguracion, liquidacionesGuardarEditarConfiguracion } from '../../../../consumers/backendApisUrls';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';

export const TIPO_PLANTILLA = {
  automatico: "Automática",
  correo: "Correo",
  manual: "Manual"
}

const EditarPlantilla = ({ toggleModal, refreshTable, fila = null }) => {
  const sectionName = "EditarPlantilla";
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [loading, setLoading] = useState(true);

  const [tiposPlantilla, setTiposPlantilla] = useState([]);
  const [correosTemplates, setCorreosTemplates] = useState([]);
  const [pdfTemplates, setPdfTemplates] = useState([]);
  const [editData, setEditData] = useState(null);

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();


  const [selectTipoPlantilla, setSelectTipoPlantilla] = useState(null);
  const [selectPlantilla, setSelectPlantilla] = useState(null);
  const [isManual, setIsManual] = useState(false);

  const getInitialData = async () => {

    setLoading(true)
    try {
      await setStateWithCallBackResponse(executeGetRequestCallback(getTiposPlantillaAPI()), data => setTiposPlantilla(data.map(item => ({ id: item.IdEntity, value: item.Value, label: item.Text }))));
      await setStateWithCallBackResponse(executeGetRequestCallback(getCorreosTemplatesAPI()), data => setCorreosTemplates(data.map(item => ({ value: item.Value, label: item.Text }))));
      await setStateWithCallBackResponse(executeGetRequestCallback(getPdfTemplatesAPI()), data => setPdfTemplates(data.map(item => ({ value: item.Value, label: item.Text }))));

      if (fila) setStateWithCallBackResponse(executeGetRequestCallback(getEditarConfiguracionLiquidacion(fila.IdConfiguracionLiquidaciones)), setEditData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getInitialData();
  }, [])

  useEffect(() => {
    if (!loading) {
      if (fila && fila?.TipoPlantilla) setSelectTipoPlantilla(() => tiposPlantilla.filter((item) => item.label == fila.TipoPlantilla)[0]);
      if (fila && fila?.NombreArchivoGenerado) setFormValues((prev) => ({ ...prev, NombreArchivo: fila.NombreArchivoGenerado }))
    }
  }, [loading])

  useEffect(() => {
    setSelectPlantilla({ value: -1, label: "" });
    if (selectTipoPlantilla !== null && selectTipoPlantilla !== undefined) {
      if (selectTipoPlantilla.label === TIPO_PLANTILLA.manual) setIsManual(true);
      else { setIsManual(false) }

      if (fila && fila?.TipoPlantilla === TIPO_PLANTILLA.automatico) setSelectPlantilla(pdfTemplates.filter((item) => item.label == fila.NombrePlantilla)[0]);
      if (fila && fila?.TipoPlantilla === TIPO_PLANTILLA.correo) setSelectPlantilla(correosTemplates.filter((item) => item.label == fila.NombrePlantilla)[0]);
      if (fila && fila?.TipoPlantilla === TIPO_PLANTILLA.manual) setFormValues(prev => ({ ...prev, NombrePlantilla: fila.NombrePlantilla }))
    }
  }, [selectTipoPlantilla]);

  const armarPayload = () => {
    const payload = {
      IdTipoPlantilla: selectTipoPlantilla?.value,
      IdPlantilla: selectTipoPlantilla.label !== TIPO_PLANTILLA.manual ? selectPlantilla?.value : null,
      NombreArchivoGenerado: selectTipoPlantilla.label !== TIPO_PLANTILLA.correo ? formValues.NombreArchivo : null,
      NombreDocumento: selectTipoPlantilla.label === TIPO_PLANTILLA.manual ? formValues?.NombrePlantilla : null
    };

    return payload;
  }

  const armarPayloadEditar = () => {
    const payload = {
      IdConfiguracionLiquidaciones: fila.IdConfiguracionLiquidaciones,
      IdTipoPlantilla: selectTipoPlantilla?.value,
      IdPlantilla: selectTipoPlantilla.label !== TIPO_PLANTILLA.manual ? selectPlantilla?.value : null,
      NombreArchivoGenerado: selectTipoPlantilla.label !== TIPO_PLANTILLA.correo ? formValues.NombreArchivo : null,
      NombreDocumento: selectTipoPlantilla.label === TIPO_PLANTILLA.manual ? formValues?.NombrePlantilla : null
    };

    return payload;
  }

  const successSubmitCallback = function () {
    refreshTable();
    toggleModal();
  }

  const handleSubmit = async () => {
    let payload;
    let api;
    let keys;

    if (fila) {
      payload = armarPayloadEditar();
      api = liquidacionesGuardarEditarConfiguracion();
      keys = ["IdConfiguracionLiquidaciones", "IdTipoPlantilla", "IdPlantilla", "NombreArchivoGenerado", "NombreDocumento"];
    } else {
      payload = armarPayload();
      api = liquidacionesAgregarConfiguracion();
      keys = ["IdTipoPlantilla", "IdPlantilla", "NombreArchivoGenerado", "NombreDocumento"];
    }

    await submitForm(keys, api, payload, sectionName, successSubmitCallback);
  }


  return (
    <GenericModal showModal={toggleModal} width={"800px"} titulo={"Editar Liquidación"}>
      <div className="modal-body pt-0 pl-0 mb-5">
        <form>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label modal-label">Tipo de Plantilla<span className="required">*</span></label>
            <div className="col-sm-9 black-color">
              <Select
                value={selectTipoPlantilla}
                onChange={setSelectTipoPlantilla}
                options={tiposPlantilla}
                placeholder="Seleccione un tipo de plantilla"
              />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${formNames.TipoPlantilla}-validation-message`}></p>
            </div>
            {!isManual ? (
              <>
                <label className="col-sm-3 col-form-label modal-label">Seleccionar plantilla<span className="required">*</span></label>
                <div className="col-sm-9 black-color">
                  <Select
                    value={selectPlantilla}
                    onChange={setSelectPlantilla}
                    options={
                      selectTipoPlantilla && selectTipoPlantilla.label === TIPO_PLANTILLA.automatico ?
                        pdfTemplates
                        : selectTipoPlantilla && selectTipoPlantilla.label === TIPO_PLANTILLA.correo ?
                          correosTemplates
                          : []
                    }
                    placeholder="Seleccione una plantilla"
                    isDisabled={selectTipoPlantilla === null}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${formNames.PlantillaSeleccionada}-validation-message`}></p>
                </div>
              </>
            )
              : null}
            {isManual ? (
              <>
                <label className="col-sm-3 col-form-label modal-label">Nombre plantilla<span className="required">*</span></label>
                <div className="col-sm-9 black-color">
                  <input type="text" className="form-control" name={formNames.NombrePlantilla} onChange={handleChange} value={formValues?.NombrePlantilla ?? ""}></input>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${formNames.NombrePlantilla}-validation-message`}></p>
                </div>
              </>
            ) : null}
            {selectTipoPlantilla?.label !== TIPO_PLANTILLA.correo ?
              <>
                <label className="col-sm-3 col-form-label modal-label">Nombre archivo<span className="required">*</span></label>
                <div className="col-sm-9 black-color">
                  <input type="text" className="form-control" name={formNames.NombreArchivo} onChange={handleChange} value={formValues?.NombreArchivo ?? ""}></input>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${formNames.NombreArchivo}-validation-message`}></p>
                </div>
              </> : null
            }

          </div>
        </form>
      </div>
      <div className="modal-footer d-flex justify-content-end pr-4 mt-5">
        <div className="button">
          <button className="btn btn-primary generic-button-ficha-modal" onClick={handleSubmit}>
            {fila ? "Guardar" : "Agregar"}
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default EditarPlantilla
