    import {toast} from "react-toastify";
    import React from "react";

    const ToastErrorMaxFileSize = () => (
        <div className="toast-error-max-file-size">
            <p>Solo son válidos archivos jpg, png o pdf de máximo 8MB.</p>
            <p>Le recomendamos comprimir el archivo en la siguiente página web: <a href="https://www.compress2go.com/es" target="_blank" className="toast-error-custom-link">Link</a></p>
        </div>
    );

    export const showToastErrorMaxFileSize = function () {
        toast.error(<ToastErrorMaxFileSize/>, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
        });
    };