import React from 'react'
import PropTypes from "prop-types"
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile'
import { MATERNIDAD_MOTIVO_TERMINO } from '../../../../constants/hardCodedConstants'

const TYPES = [MATERNIDAD_MOTIVO_TERMINO.FALLECIMIENTO_BEBE, MATERNIDAD_MOTIVO_TERMINO.CESE];

const TerminoLactancia = ({ formValues, setFormValues, setIsUploading, commonProps, keys: KEYS, sectionName: SECTION_NAME }) => {

  return (
    <>
      {TYPES.includes(formValues[KEYS.idMotivo]) &&
        <>
          <InputUploadFile label="Sustento" name={KEYS.file} uploadFile={formValues[KEYS.file]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.file]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={["pdf", "jpg", "png"]} isDisabled={formValues.isDisabled}/>
          <InputTextArea label='Comentarios' {...commonProps(KEYS.comentario)} direction='column' isDisabled={formValues.isDisabled}/>
        </>
      }
    </>
  )
}

TerminoLactancia.propTypes = {
  formValues: PropTypes.object.isRequired,
  setFormValues: PropTypes.any.isRequired,
  setIsUploading: PropTypes.any.isRequired,
  commonProps: PropTypes.func.isRequired,
  keys: PropTypes.object,
  sectionName: PropTypes.string, 
}

export default TerminoLactancia