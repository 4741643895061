
export const useIndexValues = ( setFormValues, setPaginator, setExtraData = null, externalSet = null ) => {
  const useIndex = (response) => {
    setFormValues(response.data.Items);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
    if (setExtraData) setExtraData(response.data.ExtraData);
    if (externalSet) externalSet(response);
  }

  return [useIndex];
};