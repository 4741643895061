import React, {useContext} from 'react';
import {Route, Redirect, useLocation} from 'react-router-dom';

import {AuthContext} from '../../hooks/context/authContext';

const PrivateRoute = ({component: Component, ...rest}) => {
    const {state} = useContext(AuthContext);
    const location = useLocation();
    return (
        <Route
            {...rest}
            render={(props) =>
                state.isLoggedIn ? <Component {...props} /> : <Redirect to={{pathname: '/login', state: {from: location?.pathname, search: location?.search}}}/>
            }
        />
    );
};

export default PrivateRoute;
