import React, { useContext, useEffect, useState } from 'react';

import TableWebBody from './TableWebBody';
import FormSelect from '../../components/atoms/Select';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from "../../components/pageHeader/PageHeader";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import {
  descargarListadoFichaSintomatologicaAPI,
  descargarListaSeguimientoCasosCovid,
  downloadReporteEncuestaSaludAPI,
  elimiinarRegistroAPI,
  eliminarRegistoSeguimientoCasosCovid,
  eliminarRegistroFichaSintomatologica,
  getAllAreasAPI, getAllCargosAPI,
  getAllDepartamentosAPI,
  getAllEstadosAPI,
  getAllOcurrenciasAPI,
  getAllSedesAPI,
  getDivisionesAPI,
  getTiposAdjuntosEncuestaSaludAPI,
  listarEncuestasDeSaludAPI,
  listarFichaSintomatologicaAPI,
  ListarSeguimientoCasosCovid,
  reabrirCasoCovid
} from "../../consumers/backendApisUrls";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import Paginator from "../directorio/Paginator";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";
import { updateOptionsDeFiltro } from "../../helper/Listas/ListasUtils";
import { useForm } from '../../hooks/useForm/UseForm';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import SeguimientoCovidModal from './modales/SeguimientoCovidModal';
import DetalleEncuestaSaludModal from "../encuestasDeSalud/modales/DetalleEncuestaSaludModal";
import BuscarColaborador from './modales/BuscarColaborador';
import RegistroVacunaModal from "../../components/modalVacunas/RegistroVacunaModal";
import DatosContactosModal from "./modales/DatosContactosModal";
import ProgramarPrueba from '../gestionProgramacionPruebasCovid/modales/ProgramarPrueba';
import ProgramarPruebaSubModal from './modales/ProgramarPruebaSubModal';

function calcTime(offset) {
  var d = new Date();
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  var nd = new Date(utc + (3600000 * offset));

  return nd;
}

const getDate = () => {
  const date1 = calcTime('-5.0');
  const date2 = calcTime('-5.0');

  const currentHour = date1.getHours();
  const currentMinutes = date1.getMinutes();
  const currentTime = currentHour * 60 + currentMinutes;

  if (currentTime < 12 * 60) {
    return date1.toISOString().substring(0, 10)
  } else {
    const tomorrow = new Date(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate() + 1
    );
    return tomorrow.toISOString().substring(0, 10);
  }
}

const SeguimientoCasosCovid = () => {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [filas, setFilas] = useState([]);
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [descargarArchivoDeS3] = useGenerarDescargarEliminarArchivo();

  const [formValues, setFormValues] = useState({
    StartDate: "",
    EndDate: ""
  });
  const [handleChange] = useForm(formValues, setFormValues);
  const seccionName = "Fechas";

  const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
  const [cargosSeleccionadas, setCargosSeleccionadas] = useState([]);
  const [ocurrenciaSeleccionados, setOcurrenciaSeleccionados] = useState([]);
  const [divisionSeleccionados, setDivisionSeleccionados] = useState([]);
  const [gerenciaSeleccionadas, setGerenciaSeleccionadas] = useState([]);
  const [areaSeleccionados, setAreaSeleccionados] = useState([]);
  const [adjuntosSeleccionados, setAdjuntosSeleccionados] = useState([]);
  const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
  const [fechaSeleccionado, setFechaSeleccionado] = useState(getDate());

  const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);
  const [ocurrenciasOptions, setOcurrenciasOptions] = useState([]);
  const [divisionFiltradosOptions, setDivisionFiltradosOptions] = useState([]);
  const [gerenciaFiltradasOptions, setGerenciaFiltradasOptions] = useState([]);
  const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
  const [estadosAlertaFiltradosOptions, setEstadosAlertaFiltradosOptions] = useState([]);

  const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);
  const [allOcurrenciasFiltradasOptions, setAllOcurrenciasFiltradasOptions] = useState([]);
  const [allDivisionFiltradosOptions, setAllDivisionFiltradosOptions] = useState([]);
  const [allGerenciaFiltradasOptions, setAllGerenciaFiltradasOptions] = useState([]);
  const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
  const [allEstadosAlertaFiltradosOptions, setAllEstadosAlertaFiltradosOptions] = useState([]);

  const [cleanFilterss, setCleanFilters] = useState(false);
  const [executePostRequest] = usePostRequest();
  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [hideDetalleEncuestaModal, setHideDetalleEncuestaModal] = useState(true);
  const [hideBuscarColaboradorModal, setHideBuscarColaboradorModal] = useState(true);
  const [idRegistro, setIdRegistro] = useState(null);
  const [idEmpleado, setIdEmpleado] = useState(null);
  const [fechaMasRecientePrimeroBool, setFechaMasRecientePrimeroBool] = useState(true);
  const [hideDetalleEncuestaSaludModal, setHideDetalleEncuestaSaludDetail] = useState(true);

  const [idEncuesta, setIdEncuesta] = useState();
  const [hideDetalleVacunaModal, setHideDetalleVacunaModal] = useState(true);
  const [hideDatosContactoModal, setHideDatosContactoModal] = useState(true);

  const [hideProgramarPrueba, setHideProgramarPrueba] = useState(true);
  const [selectedListUsers, setSelectedListUsers] = useState([]);

  const [modoCrear, setModoCrear] = useState(false);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    setPage(1);
  }, [criterioDeBusqueda,
    sedesSeleccionadas,
    divisionSeleccionados,
    gerenciaSeleccionadas,
    areaSeleccionados,
    adjuntosSeleccionados,
    ocurrenciaSeleccionados,
    cargosSeleccionadas,
    estadosSeleccionados,
    fechaSeleccionado,
    formValues
  ]);

  useDidMountEffect(() => {
    manageSearch();
  }, [sedesSeleccionadas,
    divisionSeleccionados,
    gerenciaSeleccionadas,
    areaSeleccionados,
    adjuntosSeleccionados,
    ocurrenciaSeleccionados,
    page,
    fechaMasRecientePrimeroBool,
    cargosSeleccionadas,
    estadosSeleccionados,
  ]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllDivisionFiltradosOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllGerenciaFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllOcurrenciasAPI()), setAllOcurrenciasFiltradasOptions);
      await getDropdownOptions(executeGetRequestCallback(getAllEstadosAPI()), setAllEstadosAlertaFiltradosOptions);

      await manageSearch();

    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const updateOptionsDeFiltros = async function (lists) {
    await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
    await updateOptionsDeFiltro(allDivisionFiltradosOptions, lists["IdsDivisiones"], setDivisionFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
    await updateOptionsDeFiltro(allGerenciaFiltradasOptions, lists["IdsGerencias"], setGerenciaFiltradasOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
    await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
    await updateOptionsDeFiltro(allOcurrenciasFiltradasOptions, lists["IdsOcurrencias"], setOcurrenciasOptions, executeGetRequestCallback(getAllOcurrenciasAPI()));
    await updateOptionsDeFiltro(allEstadosAlertaFiltradosOptions, lists["IdsEstados"], setEstadosAlertaFiltradosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    setLoading(false);
  };

  const getDropdownOptions = async (getFunction, setState) => {
    const response = await getFunction();
    if (responseCode200(response)) {
      setState(response.data);
      return response.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const handleKeypress = (e) => {
    if (e.key === 'Enter') {
      manageSearch();
    }
  };

  const obtenerPayload = function () {
    let payload = {
      "Criteria": criterioDeBusqueda,
      "IdsDivisiones": divisionSeleccionados,
      "IdsGerencias": gerenciaSeleccionadas,
      "IdsAreas": areaSeleccionados,
      "IdsCargos": cargosSeleccionadas,
      "IdsOcurrencias": ocurrenciaSeleccionados,
      "IdsEstados": estadosSeleccionados,
      "Page": page,
      "VerMasReciente": fechaMasRecientePrimeroBool,
    };
    return payload;
  }

  const manageSearch = async () => {
    setLoading(true);
    /* setIdEmpleado(null);
    setIdRegistro(null); */
    let payload = obtenerPayload();
    await executeSearch(payload);
  };

  const executeSearch = async (payload) => {
    const response = await executePostRequest(ListarSeguimientoCasosCovid(), payload);
    if (responseCode200(response)) {
      setFilas(response.data.Items);
      setTotalItems(response.data.TotalItems);
      setFirsItemPage(response.data.FirsItemPage);
      setLastItemPage(response.data.LastItemPage);
      setTotalPages(response.data.TotalPages);

      if (response?.data?.ExtraData)
        await updateOptionsDeFiltros(response?.data?.ExtraData);
      else setLoading(false);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  const limpiarBusqueda = () => {
    setDivisionSeleccionados([]);
    setGerenciaSeleccionadas([]);
    setAreaSeleccionados([]);
    setCargosSeleccionadas([]);
    setEstadosSeleccionados([]);
    setCriterioDeBusqueda("");
    setCleanFilters(!cleanFilterss);
    setFechaMasRecientePrimeroBool(true);
    setOcurrenciaSeleccionados([]);
    setFechaSeleccionado(getDate());
    setFormValues({
      StartDate: "",
      EndDate: "",
    })
  };

  const descargar = async function () {
    const payload = obtenerPayload();
    await descargarArchivoDeS3(descargarListaSeguimientoCasosCovid(), payload);
  }

  const descargarReporte = async function (generarReporteResponse) {
    let originalFileName = generarReporteResponse.data.OriginalFileName;
    let filePath = generarReporteResponse.data.FilePath;
    let descargarReporteResponse = await downloadFile(state.token, filePath, originalFileName);
    if (responseCode200(descargarReporteResponse)) {
    } else {
      mostrarMensajeDeErrorConToast(descargarReporteResponse);
    }
  };

  const toggleDetalleModal = async (idRegistro) => {
    if (modoCrear) {
      /* setIdRegistro(null); */
    } else {
      if (idRegistro) {
        setIdRegistro(idRegistro)
        setIdEmpleado(null);
      }
    }
    setHideDetalleEncuestaModal(!hideDetalleEncuestaModal);
  };

  const toggleBuscarColaborador = async () => {
    setHideBuscarColaboradorModal(!hideBuscarColaboradorModal);
  };

  const elimiinarRegistro = async function (idRegistro) {
    const response = await executePostRequest(eliminarRegistoSeguimientoCasosCovid(idRegistro), {});
    if (responseCode200(response)) {
      toast.success(response.data.Message);
      manageSearch();

    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const reabrirRegistro = async function (idRegistro) {
    const response = await executePostRequest(reabrirCasoCovid(idRegistro), {});
    if (responseCode200(response)) {
      toast.success(response.data.Message);
      manageSearch();

    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const onChangeFechaMasRecientePrimeroBool = (e) => {
    let inputValue = e.target.checked;
    setFechaMasRecientePrimeroBool(inputValue);
  };

  const toggleDetalleEncuestaSaludModal = (idEncuesta = null) => {
    setHideDetalleEncuestaSaludDetail(!hideDetalleEncuestaSaludModal);
    setIdEncuesta(idEncuesta);
    setSelectedListUsers(idEncuesta);
  };

  const toggleDetalleVacunaModal = (idEmpleado = null) => {
    setHideDetalleVacunaModal(!hideDetalleVacunaModal);
    if (idEmpleado) setIdEmpleado(idEmpleado);
  };

  const togglDatosContactosModal = (idEmpleado = null) => {
    setHideDatosContactoModal(!hideDatosContactoModal);
    if (idEmpleado) setIdEmpleado(idEmpleado);
  };

  const showProgramarPrueba = async () => {
    setHideProgramarPrueba(!hideProgramarPrueba);
  };

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">


        {hideDetalleEncuestaModal === false &&
          <div className="modal-contactanos modal-response-ficha-background">
            <SeguimientoCovidModal
              showModal={toggleDetalleModal}
              idRegistro={idRegistro}
              idEmpleado={idEmpleado}
              toggleDetalleEncuestaSaludModal={toggleDetalleEncuestaSaludModal}
              toggleDetalleVacunaModal={toggleDetalleVacunaModal}
              togglDatosContactosModal={togglDatosContactosModal}
              manageSearch={manageSearch}
              showProgramarPrueba={showProgramarPrueba}
              modoCrear={modoCrear}
            />
          </div>
        }
        {hideBuscarColaboradorModal === false &&
          <div className="modal-contactanos modal-response-ficha-background">
            <BuscarColaborador showModal={toggleBuscarColaborador} setIdEmplead={setIdEmpleado} showSecondModal={toggleDetalleModal} />
          </div>
        }


        {hideDetalleEncuestaSaludModal === false &&
          <div className="modal-contactanos modal-response-ficha-background">
            <DetalleEncuestaSaludModal showModal={toggleDetalleEncuestaSaludModal} idEncuesta={idEncuesta} />
          </div>
        }

        {hideDetalleVacunaModal === false &&
          <div className="modal-contactanos modal-response-ficha-background">
            <RegistroVacunaModal
              userId={idEmpleado}
              showModal={toggleDetalleVacunaModal}
              reload={() => { }} />
          </div>
        }

        {hideDatosContactoModal === false &&
          <div className="modal-contactanos modal-response-ficha-background">
            <DatosContactosModal
              idEmpleado={idEmpleado}
              toggleModal={togglDatosContactosModal} />
          </div>
        }

        {hideProgramarPrueba === false &&
          <div className="modal-contactanos modal-response-ficha-background" hidden={hideProgramarPrueba}>
            <ProgramarPruebaSubModal showModal={showProgramarPrueba} idRegistro={idRegistro} />
          </div>
        }

        <Wrapper className='App'>
          <PageHeader title={"Seguimiento de Casos Covid"} />

          {loading === true &&
            <div className="spinner-cargando-container">
              <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
          }
          {loading === false &&
            <>
              <div className="generic-buscador-container">
                <div className="filtros-left-section">
                  <input
                    type='text'
                    className='generic-input-criterio-busqueda'
                    placeholder='Buscar por nombre'
                    value={criterioDeBusqueda}
                    onChange={(e) => {
                      setCriterioDeBusqueda(e.target.value);
                    }}
                    onKeyPress={handleKeypress}
                  />
                  <button
                    onClick={manageSearch}
                    type='button'
                    className='generic-button-buscador'>
                    Buscar
                  </button>
                  <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                  <button
                    onClick={() => { setModoCrear(true); toggleBuscarColaborador(); }}
                    type='button'
                    className='generic-button-buscador'>
                    Añadir
                  </button>
                  <button
                    onClick={descargar}
                    type='button'
                    className='generic-button-buscador'>
                    Descargar
                  </button>
                </div>
              </div>
              {/* <div className="generic-buscador-container">
                <div className="filtros-left-section">
                  <div className="d-flex justify-content-start align-items-center">
                    <div className="w-5 description flex font-gothan-medium">
                      Fecha de vigencia
                    </div>
                    <div className="d-flex justify-content-start align-items-center ml-4">
                      <input type="date" value={fechaSeleccionado} className="form-control m-0 mx-1" name="EndDate" onChange={(event) => setFechaSeleccionado(event.target.value)}></input>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className='pt-2'>
                <div className="table-scroll">
                  <table className='table table-hover'>
                    <thead className="listar-thead">
                      <tr>
                        <th>
                          <div className='flex description font-gothan-medium hand-pointer'>Fecha Registro</div>
                          <div className="custom-control custom-switch">
                            <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                            <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                              Más reciente
                            </label>
                          </div>
                        </th>
                        <th>
                          <div className='flex description font-gothan-medium hand-pointer'>Nombre Completo</div>
                        </th>
                        <th>
                          <FormSelect
                            options={cargosFiltradasOptions}
                            description={'Cargo'}
                            setSelectedOptions={setCargosSeleccionadas}
                            selectedOptions={cargosSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={divisionFiltradosOptions}
                            description={'División'}
                            setSelectedOptions={setDivisionSeleccionados}
                            selectedOptions={divisionSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={gerenciaFiltradasOptions}
                            description={'Gerencia'}
                            setSelectedOptions={setGerenciaSeleccionadas}
                            selectedOptions={gerenciaSeleccionadas}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={areasFiltradasOptions}
                            description={'Área'}
                            setSelectedOptions={setAreaSeleccionados}
                            selectedOptions={areaSeleccionados}
                          />
                        </th>
                        <th>
                          <div className='flex description font-gothan-medium hand-pointer'>Fecha Termino</div>
                          {/*
                          <div className="custom-control custom-switch">
                            <input className="custom-control-input hand-pointer" type="checkbox" checked={fechaMasRecientePrimeroBool} onChange={onChangeFechaMasRecientePrimeroBool} id="admin-solicitud-cambios-fecha-order"></input>
                            <label className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer" htmlFor="admin-solicitud-cambios-fecha-order">
                              Más reciente
                            </label>
                          </div>
                          */}
                        </th>

                        <th>
                          <FormSelect
                            options={ocurrenciasOptions}
                            description={'Ocurrencia'}
                            setSelectedOptions={setOcurrenciaSeleccionados}
                            selectedOptions={ocurrenciaSeleccionados}
                          />
                        </th>
                        <th>
                          <FormSelect
                            options={estadosAlertaFiltradosOptions}
                            description={'Estado'}
                            setSelectedOptions={setEstadosSeleccionados}
                            selectedOptions={estadosSeleccionados}
                          />
                        </th>
                        <th>
                          <div className='flex font-gothan-medium'>Acciones</div>
                        </th>
                      </tr>
                    </thead>
                    <TableWebBody filas={filas} toggleDetalleModal={toggleDetalleModal} elimiinarRegistro={elimiinarRegistro} reabrirRegistro={reabrirRegistro} setModoCrear={setModoCrear} setIdRegistro={setIdRegistro} />
                  </table>
                </div>
                  {totalPages > 1 && (
                    <Paginator totalItems={totalItems}
                      firsItemPage={firsItemPage}
                      lastItemPage={lastItemPage}
                      totalPages={totalPages}
                      page={page}
                      onLeftClic={onLeftClic}
                      onRightClic={onRightClic} />
                  )}

              </div>
            </>
          }
        </Wrapper>
      </div>
    </>
  );
};

export default SeguimientoCasosCovid;
