import React, {useContext, useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS, MAX_FILE_SIZE} from '../../constants/hardCodedConstants';
import {AuthContext} from "../../hooks/context/authContext";
import {uploadFile} from '../../consumers/backendConsumer';
import {
    clearInputValue,
    extensionTamañoDeArchivoValido,
    getFormattedName, notNullAndNotUndefinded,
    obtenerArchivoDeEvent, parseExtensionesToString,
    responseUploadFileSuccess
} from "../../helper/utils";
import {toast} from "react-toastify";
import ReactTooltip from 'react-tooltip';
import parse from 'html-react-parser';
import {showToastError} from "../toast/toastGenericError";
import {AlertErrorMaxFileSizeMessage} from "../alerts/AlertErrorMaxFileSizeMessage";
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

const GenericUploadFileV2 = ({uploadFileToS3, id, textButton, toolTipText, children, extensionesValidas = EXTENSIONES_VALIDAS_DE_ARCHIVOS, ToastArchivoNoValido = AlertErrorMaxFileSizeMessage, subiendoArchivoPublicState, setSubiendoArchivoPublicState, FormattedNameLength = 25, resetInitialFile=true, showToastSuccess=true, isIcon, icon, iconColor = "blue-standard-color", isDisabled = false, maxFileSize = MAX_FILE_SIZE}) => {
    const [file, setFileee] = useState();
    const {state} = useContext(AuthContext);
    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            if (resetInitialFile)
                uploadFileToS3({});
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const handleUploadFileProxy = async function (e) {
        let file = obtenerArchivoDeEvent(e);
        const url = window.URL.createObjectURL(new Blob([file]));
        setFileee(url);

        await handleUploadFile(e);
    };

    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionTamañoDeArchivoValido(file, extensionesValidas, maxFileSize)) {
                setSubiendoArchivo(true);
                if (notNullAndNotUndefinded(setSubiendoArchivoPublicState)){
                    setSubiendoArchivoPublicState(true);
                }

                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                setSubiendoArchivoPublicState && setSubiendoArchivoPublicState(false);
                manageUploadFileResponse(response, file);
            } else {
                resetUploadFile();
                showToastError(ToastArchivoNoValido);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            uploadFileToS3(s3Response);
            setFileName(getFormattedName(file.name, FormattedNameLength));
            if(showToastSuccess) toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const resetUploadFile = () => {
        setFileName("");
        uploadFileToS3({});
        clearInputValue(id);
    };

    const removeUploadedFile = () => {
        resetUploadFile();
    };

    return (
        <>
            <div>
                <label className="custom-file-upload mb-0">
                    <input type="file" id={id} onChange={handleUploadFileProxy} accept={parseExtensionesToString(extensionesValidas)} className="hand-pointer" disabled={isDisabled}></input>
                    {isIcon ? 
                        <>
                            <FontAwesomeIcon icon={icon ?? faCloudUploadAlt} size="lg" title="Subir Archivo" data-tip data-for={`data-tool-tip-info-${id}`} className={`${iconColor} ${isDisabled ? "input-disabled" : "hand-pointer"}`}/>
                            {(subiendoArchivo || subiendoArchivoPublicState === true) && <FontAwesomeIcon icon='spinner' transform="left-9" className="ml-3 spinner"/>}
                        </>:
                        <>
                            <p data-tip data-for={`data-tool-tip-info-${id}`} className={`btn btn-primary mb-0 custom-button-adjuntar ${isDisabled ? "input-disabled" : ""}`}>{textButton}</p> &nbsp;&nbsp; 
                            <a title="Descargar archivo" href={file} download={fileName}>{fileName}</a> 
                            {(subiendoArchivo || subiendoArchivoPublicState === true) && 
                                (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="spinner"/></>)
                            }
                        </>
                    }
                    {children}
                    {toolTipText &&
                        <ReactTooltip id={`data-tool-tip-info-${id}`} place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                            <p>{parse(toolTipText)}</p>
                        </ReactTooltip>
                    }
                </label>

                {(fileName.length > 0 && !isIcon) &&
                <>
                    <p data-tip style={{display: 'inline'}} className="hand-pointer" title="Eliminar Archivo">
                        &nbsp;&nbsp; <FontAwesomeIcon icon='eraser' className="hand-pointer" onClick={removeUploadedFile}/>
                    </p>
                </>
                }
            </div>
        </>
    );
};

export default GenericUploadFileV2;
