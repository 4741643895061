import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {cancelarSolicitudDeCambioAPI, getDatosIdentificacionAPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {MENUS_FICHA_DATOS} from "../../constants/hardCodedConstants";

const SeccionViewDatosPersonales = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, showSolicitarCambiosModal, reloadView}) => {
    const {state} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosPersonales, setDatosPersonales] = useState({});
    const [hiddenFields, setHiddenFields] = useState([]);
    const [dataFields, setDataFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    function updateId() {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        mostrarSoloCamposQueNoSeanHidden();
    }, [hiddenFields]);

    useDidMountEffect(() => {
        dataFieldManager();
    }, [dataFields]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [reloadView]);

    const dataFieldManager = async () => {
        console.debug("[DatosPersonales] dataFields ha sido actualizado", dataFields);
        for (let dataField of dataFields) {
            if (dataField.HasRequest === true) {
                agregarRelojYCambiarColorARojo(dataField);
            } else {
                console.debug("[DatosPersonales] quitar reloj y asterisco en ", dataField.KeyField);
                quitarRelojYCambiarColorARojo(dataField);
            }
        }
    };

    const agregarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosPersonales] agregarRelojYCambiarColorARojo ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosPersonales-${keyField} .change-requested-field-clock`).removeAttribute('hidden');
            document.querySelector(`#ficha-view-datosPersonales-${keyField}`).classList.add("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para agregarle el reloj`, e);
        }
    };

    const quitarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosPersonales] quitarRelojYCambiarColorANegro ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosPersonales-${keyField} .change-requested-field-clock`).setAttribute('hidden', '');
            document.querySelector(`#ficha-view-datosPersonales-${keyField}`).classList.remove("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para quitar el reloj`, e);
        }
    };

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                const response = await executeGetRequest(getDatosIdentificacionAPI(idActualizado));
                if (responseCode200(response)) {
                    console.debug("[DatosPersonales] response ", response);

                    setDatosPersonales(response.data.data);
                    setHiddenFields(response.data.data.HiddenFields);
                    setDataFields(response.data.data.DataFields);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const reset = async () => {
        await updateComponentStates();
    };

    const mostrarSoloCamposQueNoSeanHidden = () => {
        try {
            for (const field of hiddenFields) {
                let elementView = document.getElementById(`ficha-view-datosPersonales-${field}`);

                if (elementView) {
                    elementView.setAttribute("hidden", "");
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const actualizar = async () => {
        try {
            setShowViewSeccion(false);
        } catch (e) {
            console.error(e);
        }
    };

    const obtenerFieldIdByKeyFieldName = (keyFieldName) => {
        const dataField = dataFields.filter(field => field.KeyField == keyFieldName);

        if (dataField.length > 0) {
            return dataField[0].IdField;
        } else {
            console.error("no se encontró keyFieldName: ", keyFieldName)
        }
    };

    const manageCancelarSolicitud = async (e) => {
        console.debug("[DatosPersonales] manage cancelar solicitud de campo ", e);
        e.preventDefault();
        let buttonId = e.target.id;
        let keyFieldString = buttonId.replace("ficha-view-datosPersonales-tooltip-cancelar-button-", "");
        let fieldId = obtenerFieldIdByKeyFieldName(keyFieldString);

        if (fieldId) {
            let response = await executePostRequest(cancelarSolicitudDeCambioAPI(fieldId), {});
            if (responseCode200(response)) {
                toast.success("Se canceló solicitud de campo con éxito");
                await reset();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const getHTMLContentForCancelarSolicitudDeCampo = (buttonId) => {
        return (
            <div>
                <div><span>¿Quieres cancelar la solicitud de actualización de este dato?</span></div>
                <div>
                    <button id={buttonId} className="change-requested-tooltip-cancel-button" onClick={manageCancelarSolicitud}>Sí cancelar</button>
                </div>
            </div>
        )
    };
    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}
            <form id='frmInfoDatosPersonales' hidden={loading}>
                <div className='displayFlexWrap'>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-Nombres">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-Nombres" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-Nombres'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-Nombres")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Nombres:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.Nombres}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-NombreCorto">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-NombreCorto" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-NombreCorto'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-NombreCorto")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Me conocen como:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.NombreCorto}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-ApellidoPaterno">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-ApellidoPaterno" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-ApellidoPaterno'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-ApellidoPaterno")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Apellido paterno:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.ApellidoPaterno}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-ApellidoMaterno">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-ApellidoMaterno" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-ApellidoMaterno'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-ApellidoMaterno")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Apellido materno:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.ApellidoMaterno}</p>}
                        </label>
                    </div>
                   {/* <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-ApellidoCasado">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-ApellidoCasado" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-ApellidoCasado'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-ApellidoCasado")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Apellido de casada:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.ApellidoCasado}</p>}
                        </label>
                    </div>*/}
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-IdTipoDocumento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-IdTipoDocumento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-IdTipoDocumento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-IdTipoDocumento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Tipo de documento:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.TipoDocumento}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-NumeroDocumento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-NumeroDocumento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-NumeroDocumento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-NumeroDocumento")
                                          }}/>
                            <label className="ficha-info-label-width">

                                N° de documento:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.NumeroDocumento}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-IdGenero">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-IdGenero" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-IdGenero'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-IdGenero")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Sexo:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.Genero}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-IdEstadoCivil">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-IdEstadoCivil" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-IdEstadoCivil'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-IdEstadoCivil")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Estado civil:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.EstadoCivil}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-Email">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-Email" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-Email'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-Email")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Correo electrónico:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.Email}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-Celular">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-Celular" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-Celular'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-Celular")
                                          }}/>
                            <label className="ficha-info-label-width">
                                {datosPersonales && datosPersonales.MostrarCelular == false &&
                                <FontAwesomeIcon icon='eye-slash' transform="left-9"/>}
                                {datosPersonales && datosPersonales.MostrarCelular == true &&
                                <FontAwesomeIcon icon='eye' transform="left-9"/>}
                                Celular Personal:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.Celular}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosPersonales-Telefono">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosPersonales-tooltip-Telefono" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosPersonales-tooltip-Telefono'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosPersonales-tooltip-cancelar-button-Telefono")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Teléfono:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosPersonales && <p>{datosPersonales.Telefono}</p>}
                        </label>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    {datosPersonales && datosPersonales.FileViewModel &&
                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'
                            onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, datosPersonales.FileViewModel.Path, datosPersonales.FileViewModel.OriginalFileName);
                            }}>
                            Descargar DNI/CE
                        </button>
                    </div>
                    }

                    {datosPersonales && datosPersonales.CanSeeButtonSolicitarCambios === true &&
                    <button
                        onClick={() => showSolicitarCambiosModal(MENUS_FICHA_DATOS.datosPersonales.secciones.DatosIdentificacion.id)}
                        type='button'
                        className='btn btn-primary ficha-form-button-SolicitarCambios'>
                        Solicitar cambios
                    </button>
                    }
                    {datosPersonales && datosPersonales.CanSeeButtonEditar === true &&
                    <button
                        onClick={actualizar}
                        type='button'
                        className='btn btn-primary ficha-form-button-actualizar'>
                        Actualizar
                    </button>
                    }
                </div>
            </form>
        </>
    );
};

export default SeccionViewDatosPersonales;