import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TableWebBody = ({ filas, toggleActivarInactivarInvitacionModal, toggleEditarInvitacionModal }) => {

  return (
    <tbody className="listar-tbody">
      {filas.map((fila) => (
        <tr key={fila?.IdRegistroInvitacion}>
          <td className="col-sm-3">{fila?.FechaRegistro}</td>
          <td className="col-sm-3">{fila?.Unidad}</td>
          <td className="col-sm-3">{fila?.Fecha}</td>
          <td className="col-sm-2">{fila?.Hora}</td>
          <td className="col-sm-1">
            <div className="d-flex justify-content-start">
              <div>
                <FontAwesomeIcon
                  icon={fila?.EsActivo ? "ban" : "circle-notch"}
                  className="hand-pointer blue-standard-color"
                  title={fila?.EsActivo ? "Inactivar" : "Activar"}
                  onClick={() => toggleActivarInactivarInvitacionModal(fila)}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon="edit"
                  className="hand-pointer ml-3 blue-standard-color"
                  title="Editar"
                  onClick={() => toggleEditarInvitacionModal(fila?.IdRegistroInvitacion)}
                />
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableWebBody;
