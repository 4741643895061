import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { Div, getDateOnFormat } from '../components/Components';
import DatosMaternidad from './tabs/DatosMaternidad';
import TerminoMaternidad from '../TerminoMaternidad';
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputDate from '../../../../components/modalComponents/InputDate';
import InputText from '../../../../components/modalComponents/InputText';
import TabsHeader from '../../../../components/pageComponents/TabsHeader';
import SeguimientoMaternidad from './tabs/SeguimientoMaternidad';
import {SeguimientoMaternidadActions} from './actions/Actions'
import PrePostNatalMaternidad from './tabs/PrePostNatalMaternidad';
import { ESTADOS, MATERNIDAD_CONDICION } from '../../../../constants/hardCodedConstants';
import { getCondicionToEditAPI } from '../../../../consumers/backendApisUrls';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import { AuthContext } from '../../../../hooks/context/authContext';

const KEYS = {
  fechaRegistro: "FechaRegistro",
  estadoAprobacion: "EstadoAprobacion",
  estadoProceso: "EstadoProceso",
  nombre: "NombreEmpleado",
  cargo: "Cargo",
  sede: "Sede",
  tipoCondicion: "TipoDeCondicion",
  fechaIngreso: "FechaIngreso",
  idEstadoAprobacion: "IdEstadoAprobacion",
  idEstadoProceso: "IdEstadoProceso",
  idTipoDeCondicion: "IdTipoDeCondicion",
  idCondicion: "IdCondicion",
  fechasPrePostNatalSonValidas: "FechasPrePostNatalSonValidas",
  puedeTerminarSeguimiento: "PuedeTerminarSeguimiento",
  idEmpleado: "IdEmpleado"
};

const VerDetalle = ({ toggleModal, refreshPage, row = {}, isAdmin = false, idCondicion = null }) => {
  const {state} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [formValues, setFormValues] = useState(row);
  const [executePost] = useManagePostRequest();

  const init = async () => {
    const api = getCondicionToEditAPI();
    const payload = { IdCondicion: idCondicion ?? row[KEYS.idCondicion] };
    const result = await executePost(api, payload, () => {});
    setFormValues(result.data);
    setIsLoading(false);
  }
  
  useEffect(() => {
    init();
  }, [idCondicion])

  const isDatosEditable = (isAdmin && (
    formValues[KEYS.idEstadoAprobacion] === ESTADOS.PENDIENTE || 
    formValues[KEYS.idEstadoAprobacion] === ESTADOS.OBSERVADO)) ||
    (formValues[KEYS.idEstadoAprobacion] === ESTADOS.OBSERVADO && 
      formValues[KEYS.idEmpleado] == state.user.id_empleado);

  let isPerPostNatalEditable = false;
  
  const isSeguimientoEditable = isAdmin && formValues[KEYS.idEstadoProceso] === ESTADOS.SEGUIMIENTO;

  if(formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE)
    isPerPostNatalEditable = isAdmin && !formValues[KEYS.fechasPrePostNatalSonValidas] && formValues[KEYS.idEstadoProceso] !== ESTADOS.TERMINADO;

  if(formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.LACTANCIA)
    isPerPostNatalEditable = isAdmin && formValues[KEYS.idEstadoAprobacion] !== ESTADOS.APROBADO;

  const getOptions = () => {
    if (isLoading) return;

    let options = [];
    options.push({
      id: "DatosMaternidad",
      title: "Datos",
      component: (
        <DatosMaternidad
          idCondicion={formValues[KEYS.idCondicion]}
          idTipoCondicion={formValues[KEYS.idTipoDeCondicion]}
          row={formValues}
          isEditable={isDatosEditable}
          toggleMainModal={toggleModal}
          refreshMainPage={refreshPage}
          isAdmin={isAdmin}
        />
      ),
    });

    if (
      formValues[KEYS.idTipoDeCondicion] === MATERNIDAD_CONDICION.GESTANTE &&
      formValues[KEYS.idEstadoAprobacion] === ESTADOS.APROBADO
    )
      options.push({
        id: "SeguimientoMaternidad",
        title: "Seguimiento",
        component: (
          <SeguimientoMaternidad
            idCondicion={formValues[KEYS.idCondicion]}
            idTipoDeCondicion={formValues[KEYS.idTipoDeCondicion]}
            isEditable={isSeguimientoEditable}
            toggleMainModal={toggleModal}
            refreshMainPage={refreshPage}
          />
        ),
      });

    if (
      formValues["FechaInicioPrePostNatal"] &&
      formValues["FechaFinPrePostNatal"]
    )
      options.push({
        id: "PrePostNatal",
        title: "Pre/Post Natal",
        component: (
          <PrePostNatalMaternidad
            idCondicion={formValues[KEYS.idCondicion]}
            showValidar={isAdmin}
            isEditable={isPerPostNatalEditable}
            refreshMainPage={refreshPage}
            toggleMainModal={toggleModal}
          />
        ),
      });

    if (formValues[KEYS.idEstadoProceso] === ESTADOS.TERMINADO)
      options.push({
        id: "TerminoMaternidad",
        title: "Término",
        component: (
          <TerminoMaternidad
            idCondicion={formValues[KEYS.idCondicion]}
            idTipoDeCondicion={formValues[KEYS.idTipoDeCondicion]}
            row={formValues}
            isModalShowed={false}
          />
        ),
      });

    return options;
  };

  if(isLoading){
    return <SpinnerLoading />
  }else{
    return (
      <ModalForm
        titulo="Ver Detalle"
        width="850px"
        showModal={toggleModal}
        hasSaveButton={false}>
        <div className="displayFlexWrap">
          <Div>
            <InputDate
              label="Fec. Reg."
              name={KEYS.fechaRegistro}
              value={getDateOnFormat(formValues[KEYS.fechaRegistro])}
              isDisabled
            />
          </Div>
          <Div>
            <InputText
              name={KEYS.estadoAprobacion}
              label="Estado aprobación"
              value={formValues[KEYS.estadoAprobacion]}
              isDisabled
            />
          </Div>
        </div>
        <div className="col-md-12">
          <InputText
            label="Nombre"
            name={KEYS.nombre}
            value={formValues[KEYS.nombre]}
            colLabel={2}
            colInput={10}
            isDisabled
          />
        </div>
        <div className="displayFlexWrap">
          <Div>
            <InputText
              label="Cargo"
              name={KEYS.cargo}
              value={formValues[KEYS.cargo]}
              isDisabled
            />
          </Div>
          <Div>
            <InputText
              label="Sede"
              name={KEYS.sede}
              value={formValues[KEYS.sede]}
              isDisabled
            />
          </Div>
          <Div>
            <InputText
              label="Condición"
              name={KEYS.tipoCondicion}
              value={formValues[KEYS.tipoCondicion]}
              isDisabled
            />
          </Div>
          <Div>
            <InputText
              label="Fecha Ingreso"
              value={formValues[KEYS.fechaIngreso]}
              isDisabled
            />
          </Div>
        </div>
        <NavsTab>
          <TabsHeader list={getOptions()} />
        </NavsTab>

        {formValues[KEYS.puedeTerminarSeguimiento] && (
          <SeguimientoMaternidadActions
            refreshMainPage={refreshPage}
            toggleMainModal={toggleModal}
            idCondicion={formValues[KEYS.idCondicion]}
            idTipoDeCondicion={formValues[KEYS.idTipoDeCondicion]}
          />
        )}
      </ModalForm>
    );
  }
}

export default VerDetalle;

const NavsTab = styled.div`
  >div {
    margin-bottom: 4px;
  }
`;