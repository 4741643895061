import React, {useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { enableDisableCommandScheduleAPI } from '../../../consumers/backendApisUrls';

const ActivarInactivarSchedule = ({ toggleModal, id, title, enable, manageSearch }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [managePost] = useManagePostRequest();

  const activarInactivar = async() => {
    setIsDeleting(true);
    try {
        await managePost(enableDisableCommandScheduleAPI(id), {}, successActivarInactivarCallback)
    } catch (error) {
        console.error(error);
    }
    setIsDeleting(false);
  }

  const successActivarInactivarCallback = async () => {
    manageSearch();
    toggleModal();
  }

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo={enable ? "Inactivar" : "Activar"}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {enable ? "inactivar" : "activar"} el estado al siguiente ítem:
          <ul>
              <li><strong>{title}</strong></li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button className="btn btn-primary generic-button-ficha mr-2" onClick={() => {toggleModal()}}>
              Cancelar
          </button>
          <button className="btn btn-primary generic-button-ficha" onClick={() => {activarInactivar()}} disabled={isDeleting}>
            {enable ? "Inactivar" : "Activar"}
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default ActivarInactivarSchedule