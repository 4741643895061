import React, { useState, useEffect } from 'react';
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputText from '../../../../components/modalComponents/InputText';
import InputDate from '../../../../components/modalComponents/InputDate';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { saveEditPresupuestoAPI, getEditPresupuestoAPI } from '../../../../consumers/backendApisUrls';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';

const EditarPresupuesto = ({ toggleModal, refreshPage, idPresupuesto }) => {

  const SECTION_NAME = "editarPresupuesto";
  const KEYS = ["Nombre", "FechaInicio", "FechaFin"];

  const [formValues, setFormValues] = useState({});

  const [handleChange] = useForm(formValues, setFormValues);
  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    manageSearch();
  }

  const manageSearch = async () => {
    const api = getEditPresupuestoAPI(idPresupuesto);
    await executeGet(api, (res) => setFormValues(res.data));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = saveEditPresupuestoAPI();
    await submitForm(KEYS, api, formValues, SECTION_NAME, successSubmitCallback);
  }

  const successSubmitCallback = () => {
    toggleModal();
    refreshPage()
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Editar Presupuesto" width="700px">
      <InputText label='Nombre de presupuesto' {...commomProps('Nombre')}/>
      <InputDate label='Fecha Inicio' name='FechaInicio' value={formValues.FechaInicio?.split("T")[0]} onChange={handleChange} sectionName={SECTION_NAME}/>
      <InputDate label='Fecha Fin' name='FechaFin' value={formValues.FechaFin?.split("T")[0]} onChange={handleChange} sectionName={SECTION_NAME}/>
  </ModalForm>
  )
}

export default EditarPresupuesto