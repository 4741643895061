import React, { useState, useEffect } from 'react'
import TableCupones from './TableCupones'
import CrearCupon from './modales/CrearCupon';
import EditarCupon from './modales/EditarCupon';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import PageWrapper from '../../../../components/pageComponents/PageWrapper';
import PageButtons from '../../../../components/pageComponents/PageButtons';
import PaginatorV2 from '../../../../components/pageComponents/PaginatorV2';
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../../../components/pageComponents/SearchContainer';
import ActionGenericModal from '../../../../components/modal/ActionGenericModal';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { inactivarActivarPlantillaCuponCuponeraTiempoLibreAPI, listarPlantillasCuponCuponeraTiempoLibreAPI } from '../../../../consumers/backendApisUrls';

const ListaCupones = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    setIsFetching(true);
    const api = listarPlantillasCuponCuponeraTiempoLibreAPI();
    const payload = getPayload();
    try {
      await executeManagePost(api, payload, successManageSearchCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    const updatedItems = Items.map(o => {
      const { IdPlantillaCuponTiempoLibre, ImagenCuponTiempoLibreURL } = o;
      return {...o, Id: IdPlantillaCuponTiempoLibre, ImagenUrl: ImagenCuponTiempoLibreURL};
    })
    setFormValues(lastData => ({ ...lastData, Items: updatedItems, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleEditCupon = (row) => {
    setModalEnabled({ isEnable: true, component: EditarCupon, data: { row } })
  }

  const handleActiveInactiveCupon = (row) => {
    const api = inactivarActivarPlantillaCuponCuponeraTiempoLibreAPI;
    const payload = {IdPlantillaCuponTiempoLibre: row.IdPlantillaCuponTiempoLibre};
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { title: row.Activo ? "Inactivar" : "Activar", actionLabel:  row.Activo ? "inactivar" : "activar", api, payload, name: row.Nombre}})
  }

  useDidMountEffect(() => {
    if (!isLoading) manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Lista de Plantillas de Cupones" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons hasBackButton hasCreateButton handleCreateButton={() => setModalEnabled({ isEnable: true, component: CrearCupon })}/>
      </SearchContainer>
      <TableCupones rows={formValues.Items} handleEditCupon={handleEditCupon} handleActiveInactiveCupon={handleActiveInactiveCupon} isFetching={isFetching}/>
      <div className='px-5 mb-4'><PaginatorV2 paginator={paginator} setPaginator={setPaginator} manageSearch={manageSearch}/></div>
    </PageWrapper>
  )
}

export default ListaCupones