import React, { useEffect, useState } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { ThemeProvider } from "@material-ui/core/styles";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { useForm } from "../../../hooks/useForm/UseForm";
import {
  getTestActivityAPI,
  saveTestActivityAPI,
} from "../../../consumers/backendApisUrls";
import ModalMessageTest from "./ModalMessageTest";
import ModalMensaje from "../../../components/modalMensaje/ModalMensaje";

const TestInduccionModal = ({
  showModal,
  IdEmpleadoFlujoActividad,
  manageSearch,
  nombreUser,
  isAdmin,
}) => {
  const [dataTestInduccion, setDataTestInduccion] = useState({});
  const [value, setValue] = useState({});
  const [responseMessage, setResponseMessage] = useState(null);
  const [showTest, setShowTest] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleChange] = useForm(value, setValue);
  const [executeGetRequest] = useManageGetRequest();
  const [executePostRequest] = useManagePostRequest();
  const [personalName, setPersonalName] = useState(nombreUser.split(' ')[0])

  const getInitialData = async () => {
    const api = getTestActivityAPI(IdEmpleadoFlujoActividad);
    await executeGetRequest(api, successInitialDataCallBack);
  };

  const successInitialDataCallBack = (response) => {
    setDataTestInduccion(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = saveTestActivityAPI();
      let payload = setPayload();

      await executePostRequest(api, payload, successTestCallBack, false);

    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const setPayload = () => {
    let result = Object.keys(value).map((key) => ({
      IdPregunta: parseInt(key.substring(4)),
      IdAlternativa: value[key],
    }));

    let payload = {
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      IdTest: dataTestInduccion.IdTest,
      Items: result,
    };
    return payload;
  };

  const successTestCallBack = (response) => {
    let data = response?.data;
    if(data?.MostrarCuadro){
      setResponseMessage(data);
      setShowTest(false);
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return showTest ? (
    <GenericModal showModal={showModal} width={"850px"} titulo={dataTestInduccion.NombreTest}>
      <div className="w-100">
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <div className="w-100 mb-3 font-weight-bold blue-standard-color">
            <div>¡Hola {personalName}!</div>
            <div className="text-justify">Luego de tu proceso de Inducción virtual y cultural, es muy importante que completes este breve Test de Cultura que nos ayudará a comprender cuánto has aprendido sobre Innova durante tus primeros días. Al final de la encuesta, podrás ver tu resultado.</div>
          </div>
          {dataTestInduccion.Questions?.map((question) => (
            <div key={question?.IdQuestion} className="mb-5">
              <label className="mb-2">{question?.Question}</label>
              {question.Alternatives.map((alternative) => (
                <div key={alternative?.IdAlternativa} className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`quiz${question?.IdQuestion}`}
                    id={`quiz${question?.IdQuestion}${alternative?.IdAlternativa}`}
                    checked={
                      value[`quiz${question?.IdQuestion}`] ==
                      alternative.IdAlternativa
                    }
                    value={alternative.IdAlternativa}
                    onChange={handleChange}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`quiz${question?.IdQuestion}${alternative?.IdAlternativa}`}
                  >
                    {alternative.Alternativa}
                  </label>
                </div>
              ))}
            </div>
          ))}
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  ) : (
    <ModalMensaje
      toggleModal={showModal}
      manageSearch={manageSearch}
      modalMensaje={responseMessage.Message}
      modalImage={responseMessage.Image}
    />
  );
};

export default TestInduccionModal;
