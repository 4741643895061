import React, { useState, useEffect, useContext } from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from '../../../hooks/context/authContext';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import ModalForm from '../../../components/modalComponents/ModalForm';
import { cargarDocumentoEmpleadoAPI, descargarDocumentoEmpleadoFromPlantillaAPI, eliminarDocumentoEmpleadoAPI, getNuevosIngresosDocumentoObservadoAPI, guardarNuevosIngresosDocumentoObservadoAPI } from '../../../consumers/backendApisUrls';

const DocumentoObservadoModal = ({ toggleModal, manageSearch, idEmpleadoFlujoActividad }) => {
  const ID_FILE = "UPLOAD_OBSERVED_FILE";
  const EXTENSIONES_VALIDAS = ["pdf"];
  const SECTION_NAME = "documento-observado";
  const KEYS = ["FileViewModel"];

  const {state} = useContext(AuthContext);

  const [formValues, setFormValues] = useState();
  const [modalEnable, setModalEnable] = useState({isEnable: false});
  const [Files, setFiles] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [executePost] = useManagePostRequest();
  const [submitForm] = useSubmitForm();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    try {
      await executeGet(getNuevosIngresosDocumentoObservadoAPI(idEmpleadoFlujoActividad), (res) => setFiles(res.data));
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        const api = guardarNuevosIngresosDocumentoObservadoAPI();
        let payload = {IdEmpleado: state.user.id_empleado, IdEmpleadoFlujoActividad: idEmpleadoFlujoActividad, ...formValues};
        await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
        console.error(error)
    }
    setIsSubmitting(false);
  }

  const successSubmitCallback = () => {
    toggleModal();
    manageSearch();
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, FileViewModel: res}));
  }

  const handleDownloadPlantilla = async (idPlantilla) => {
    const api = descargarDocumentoEmpleadoFromPlantillaAPI(idPlantilla);
    await executePost(api, {}, successDownloadNombrePlantilla)
  }

  const successDownloadNombrePlantilla = (res) => {
    let { FilePath } = res?.data;
    window.open(FilePath)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Observar" width="650px" saveButtonDisabled={isSubmitting}>
      {modalEnable.isEnable && 
        <div className="modal-contactanos modal-response-ficha-background">
          <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} manageSearch={manageSearch} {...modalEnable.data}/>
        </div>
      }
      <div className='row align-items-center mb-2'>
        <label className='col-form-label modal-label col-sm-3'>Documento</label>
        <div className='col-sm-9'>
          <DownloadFile list={Files} handleDownloadPlantilla={handleDownloadPlantilla}/>
        </div>
      </div>
      <div className='row align-items-center mb-2'>
        <label className='col-form-label modal-label col-sm-3'>Subir Archivo</label>
        <div className='col-sm-9'>
          <UploadDownloadFile list={Files} setModalEnable={setModalEnable} handleUploadFile={handleUploadFile} id={ID_FILE} extensions={EXTENSIONES_VALIDAS}/>
          <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FileViewModel-validation-message`}></p>
        </div>
      </div>
    </ModalForm>
  );
};

export default DocumentoObservadoModal;

const DownloadFile = ({ list, handleDownloadPlantilla }) => (
  list?.FormatoUrl ? 
    <div><a className="mb-0 hand-pointer blue-standard-color d-flex" style={{gap: "15px"}}
      onClick={() => window.open(list.FormatoUrl)}>
      {list?.NombreDocumento}
      <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
    </a></div> :
    list?.IdPlantilla ?
      <div><a className="mb-0 hand-pointer blue-standard-color d-flex" style={{gap: "15px"}}
        onClick={() => {
          handleDownloadPlantilla(
            list.IdNuevosIngresosEmpleadoDocumento
          )}}>
        {list?.NombreDocumento}
        <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
      </a></div> :
      <label className='mb-0 hand-pointer blue-standard-color'>
        {list?.NombreDocumento}
      </label>
)

const UploadDownloadFile = ({ list, setModalEnable, handleUploadFile, id, extensions }) => (
  list.FileUrl 
  ? <div className="d-flex justify-content-center" style={{gap: "15px"}}>
      <label className="hand-pointer green-innova mb-0" onClick={() => window.open(list.FileUrl)}>
        <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
      </label>
      {list.PuedeEliminar && 
        <FontAwesomeIcon
          icon="trash"
          size="lg"
          title="Eliminar"
          className="hand-pointer blue-standard-color"
          onClick={() => setModalEnable({isEnable: true, component: ActionGenericModal, data: {api: eliminarDocumentoEmpleadoAPI, id: list.IdNuevosIngresosEmpleadoDocumento, name: list.FileViewModel?.OriginalFileName}})}
        />
      }
    </div>
  : <GenericUploadFileV2
      id={id}
      extensionesValidas={extensions}
      uploadFileToS3={(res) => handleUploadFile(res)}
      textButton="Subir archivo"
      resetInitialFile={false}
      showToastSuccess={false}
    />
)