import React from 'react'
import { handleFind } from '../../../helper/utils'
import FormSelect from '../../../components/atoms/Select'
import PaginatorV2 from '../../../components/pageComponents/PaginatorV2'

const TableWeb = ({ paginator, setPaginator, keys: KEYS, filters = [], selectedFilters = {}, setFormValues, children }) => {

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...selectedFilters, [key]: res};
    setFormValues(lastData => ({...lastData, selectedFilters: updatedSelectedFilters}));
  }

  return (
    <div className="container-fluid body-content">
      <div className="table-scroll pt-3">
        <table className="table table-hover">
          <thead className="listar-thead">
            <tr className="font-gothan-medium">
              <th className='text-center col-sm-1'>DNI</th>
              <th className='text-center col-sm-3'>Nombre</th>
              <th className='text-center col-sm-3'>
                <div className='d-flex justify-content-center'>    
                  <FormSelect 
                    options={handleFind(filters, KEYS.Cargos)}
                    description="Cargos"
                    selectedOptions={selectedFilters[KEYS.Cargos] ?? []}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS.Cargos)}
                  />
                </div>
              </th>
              <th className='text-center col-sm-3'>
                <div className='d-flex justify-content-center'>
                  <FormSelect 
                    options={handleFind(filters, KEYS.Sedes)}
                    description="Sedes"
                    selectedOptions={selectedFilters[KEYS.Sedes] ?? []}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS.Sedes)}
                  />
                </div>
              </th>
              <th className='text-center col-sm-2'>Fecha Solicitud</th>
              <th className='text-center'>
                <div className='d-flex justify-content-center'>
                  <FormSelect 
                    options={handleFind(filters, KEYS.EstadoEmpleado)}
                    description="Estado Empleado"
                    selectedOptions={selectedFilters[KEYS.EstadoEmpleado] ?? []}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS.EstadoEmpleado)}
                  />
                </div>
              </th>
              <th className='text-center'>
                <div className='d-flex justify-content-center'>
                  <FormSelect 
                    options={handleFind(filters, KEYS.EstadoSolicitud)}
                    description="Estado Solicitud"
                    selectedOptions={selectedFilters[KEYS.EstadoSolicitud] ?? []}
                    setSelectedOptions={(res) => handleFilterSelected(res, KEYS.EstadoSolicitud)}
                  />
                </div>
              </th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          {children}
        </table>
        <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
      </div>
    </div>
  )
}

export default TableWeb