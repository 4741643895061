import React, { useState } from 'react'
import { jsonEstaVacio } from '../../helper/utils';
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../hooks/useProps/usePropsInput';
import InputDate from '../../components/modalComponents/InputDate';
import InputSelect from '../../components/modalComponents/InputSelect';
import { enviarReporteSeguimientoNuevosIngresosAPI } from '../../consumers/backendApisUrls';

const SECTION_NAME = "EnviarReporteSeguimientoNuevosIngresos";

const ReporteSeguimientoNuevosIngresos = () => {

  const KEYS = {tipo: "Tipo", desde: "FechaInicio", hasta: "FechaFin"};

  const [typeSelected, setTypeSelected] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleTypeSelect = ({ target }) => {
    setTypeSelected(target.value);
  }

  const handleSendReport = async () => {
    setIsSending(true);
    const api = enviarReporteSeguimientoNuevosIngresosAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, () => {});
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const getPayload = () => {
    const name = typeSelected?.filter;
    const payload = {...formValues};
    if (name){
      payload[KEYS.tipo] = name;
    }
    return payload;
  }

  return (
    <CardBody>
      <DIV>
        <InputSelect label='Selecciona un criterio para Reporte' name={KEYS.tipo} options={TYPES} direction='column' value={jsonEstaVacio(typeSelected) ? null : typeSelected} onChange={handleTypeSelect} sectionName={SECTION_NAME}/>
      </DIV>
      <DIV>
        <InputDate isRequired label="Desde" {...commonProps(KEYS.desde)} isDisabled={jsonEstaVacio(typeSelected)}/>
        <InputDate isRequired label="Hasta" {...commonProps(KEYS.hasta)} isDisabled={jsonEstaVacio(typeSelected)}/>
      </DIV>
      <InfoDatoObligatorio />
      <Button handleSendReport={handleSendReport} isSending={isSending} />
    </CardBody>
  )
}

export default ReporteSeguimientoNuevosIngresos

const option1 = {value: 1, label: "Por Fecha Registro", filter: "FechaRegistro"};
const option2 = {value: 2, label: "Por Fecha Ingreso Real", filter: "FechaIngresoReal"};

const TYPES = [ option1, option2 ]

const CardBody = ({ children }) => (
  <div className='card-body'>
    <div id={SECTION_NAME}>
      {children}
    </div>
  </div>
)

const Button = ({ handleSendReport, isSending }) => (
  <div className='col-sm-5'>
    <button type='button' className="btn btn-primary generic-button-ficha" onClick={handleSendReport} disabled={isSending}>
      {isSending ? 'Enviar Reporte ...' : 'Enviar Reporte'}
    </button>
  </div>
)

const InfoDatoObligatorio = () => (
  <DIV>
    <p>(<span className="modal-label required"/>) Dato obligatorio</p>
  </DIV>
)

const DIV = ({ children }) => (
  <div className='col-sm-5'>
    {children}
  </div>
)

