import React, {useContext} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {downloadFile} from "../../consumers/backendConsumer";
import {AuthContext} from "../../hooks/context/authContext";
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, stringSeEncuentraEnArray} from "../../helper/utils";
import ReactTooltip from "react-tooltip";

const TableWebBody = ({solicitudesFiltradas, idsFichaCamposSolicitudesConCheck, setIdsFichaCamposSolicitudesConCheck}) => {
    const {state} = useContext(AuthContext);

    const checkboxClic = (e, solicitud) => {
        console.debug("checkboxClic ");
        let value = e.target.checked;
        console.debug("value: ", value);

        let idFichaCampoSolicitud = solicitud["IdFichaCampoSolicitud"];

        console.debug("idsFichaCamposSolicitudesConCheck ini ", idsFichaCamposSolicitudesConCheck);
        let tempSolicitudes = idsFichaCamposSolicitudesConCheck;

        if (value === true && !stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = agregarStringAArraydDeStringsSiNoExiste(idFichaCampoSolicitud, tempSolicitudes);
            console.debug("new IdsFichaCamposSolicitudesConCheck: ", tempSolicitudes);
            setIdsFichaCamposSolicitudesConCheck(tempSolicitudes);
        } else if (value === false && stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = eliminarStringDeArraydDeStrings(idFichaCampoSolicitud, tempSolicitudes);
            console.debug("new IdsFichaCamposSolicitudesConCheck: ", tempSolicitudes);
            setIdsFichaCamposSolicitudesConCheck(tempSolicitudes);
        }
    };

    return (
        <tbody className="listar-tbody">
        {solicitudesFiltradas.map((solicitud, i) => (
            <tr key={i}>
                <td>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="checkbox" onClick={(e) => checkboxClic(e, solicitud)}></input>
                        &nbsp;
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={solicitud.UrlFotoPerfil}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{solicitud.NombreCompleto}</div>
                    </div>
                </td>
                <td>{solicitud.NombreSeccion}</td>
                <td>{solicitud.FechaSolicitud}</td>
                <td>{solicitud.NombreCampo}</td>
                <td>{solicitud.ValorActual}</td>
                <td>{solicitud.ActualizarPor}</td>
                <td>
                    {solicitud.attachment &&
                    <p>{solicitud.EvidenciaTipo} &nbsp;&nbsp; <FontAwesomeIcon icon='paperclip' transform="left-9" className="hand-pointer blue-standard-color" onClick={
                        (e) => {
                            e.preventDefault();
                            downloadFile(state.token, solicitud.attachment.Path, solicitud.attachment.OriginalFileName);
                        }
                    }/></p>
                    }
                </td>
                <td>
                    {solicitud.SolicitudCambioObservacion != null &&
                    <>
                        <p data-tip data-for={"tooltip-modal-rechazar-solicitud-" + solicitud.IdFichaCampoSolicitud} style={{display: 'inline'}}>
                            {solicitud.NombreEstado}
                        </p>
                        <ReactTooltip id={"tooltip-modal-rechazar-solicitud-" + solicitud.IdFichaCampoSolicitud} place="left" effect="solid" className="tooltip-style tooltip-change-witdh">
                            <div>
                                <p><strong>Observación:</strong></p>
                                {(solicitud.SolicitudCambioObservacion["MotivosObservacion"]).map((motivo, ind) => (<p key={ind}>{motivo}</p>))}
                                <br/>
                                <strong>Comentario:</strong>
                                <p>{solicitud.SolicitudCambioObservacion["Comentario"]}</p>
                            </div>
                        </ReactTooltip>
                    </>
                    }
                    {solicitud.SolicitudCambioObservacion == null && <p>{solicitud.NombreEstado}</p>}

                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBody;
