import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDescargarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { eliminarRegistroCapacitacionAPI, eliminarRegistroPruebaLaboratorioAPI } from '../../consumers/backendApisUrls';
import { toast } from 'react-toastify';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';

const TableWebBodyProgramacionPruebas = ({ filas = [], manageSearch, showEditar, handleChangeCheck }) => {
  const [managePostRequest] = useManagePostRequest();

  const [descargarArchivoDeS3] = useDescargarArchivoDeS3();

  const download = async (user) => {
    if (user && user.File !== null) {
      descargarArchivoDeS3(user.File.OriginalFileName, user.File.Path);
    } else {
      toast.error("El usuario no tiene un archivo adjunto");
    }
  };

  const eliminarRegistro = async (user) => {
    const payload = {
      IdRegistroResultado: user.IdRegistroResultado
    }
    const api = eliminarRegistroPruebaLaboratorioAPI();
    const response = await managePostRequest(api, payload, () => manageSearch())
  }

  return (
    <tbody className="listar-tbody">
      {filas.map((user, i) => (
        <tr key={i}>
          <td>
            {user.Estado === "Pendiente" ? (
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={user.IdRegistroResultado}
                  id={`check-${user.IdRegistroResultado}`}
                  onChange={handleChangeCheck}
                />
              </div>
            ) : null}
          </td>
          <td>{user.NombreCompleto}</td>
          <td>{user.Cargo}</td>
          <td>{user.FechaRegistro}</td>
          <td>{user.Sede}</td>
          <td>{user.Division}</td>
          <td>{user.Gerencia}</td>
          <td>{user.Area}</td>
          <td>{user.Estado}</td>
          <td>{user.Resultado}</td>
          <td className="w-7">
            <div
              className="row d-flex flex-row justify-content-end align-items-center mr-3"
            >
              <div className="col-xs-4">
                {user.File !== null ? (
                  <div className="d-flex mx-2">
                    <FontAwesomeIcon icon='paperclip' className="hand-pointer blue-standard-color" title={`Adjunto`} onClick={() => download(user.File)} />
                  </div>
                ) : null}
              </div>
              <div className="col-xs-4">
                {user.PuedeEditar ? (
                  <div className="d-flex mx-2">
                    <FontAwesomeIcon icon='edit' className="hand-pointer blue-standard-color" title={`Editar`} onClick={() => showEditar(user.IdRegistroResultado)} />
                  </div>
                ) : null}
              </div>
              <div className="col-xs-4">
                {user.PuedeEliminar ? (
                  <div className="d-flex mx-2">
                    <FontAwesomeIcon icon='times' className="hand-pointer blue-standard-color" title={`Eliminar`} onClick={() => eliminarRegistro(user)} />
                  </div>
                ) : null}
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableWebBodyProgramacionPruebas;
