import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import FormSelect from "../../components/atoms/Select";
import Paginator from "../directorio/Paginator";
import TableWebBodyDirectorio from "./TableWebBodyDirectorio";
import EditarSedeModal from "./modales/EditarSedeModal";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getAllDepartamentosRegionesAPI, listarGestionDirectorioAPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Spinner} from "react-bootstrap";

const GestionDeDirectorio = () => {
    const {state} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState([]);

    const [departamentosFiltradosOptions, setDepartamentosFiltradosOptions] = useState([]);

    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [cleanFilterss, setCleanFilters] = useState(false);

    const [hideEditarSedeModal, setHideEditarSedeModal] = useState(true);

    const [userSelected, setUserSelected] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, departamentosSeleccionados]);

    useEffect(() => {
        manageSearch();
    }, [page, departamentosSeleccionados]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosRegionesAPI()), setDepartamentosFiltradosOptions);
            manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("departamentosSeleccionados: ", departamentosSeleccionados);
        console.debug("page: ", page);

        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdsDepartamentos": departamentosSeleccionados,
            "Page": page
        }

        console.debug("payload: ", payload);

        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        const response = await executePostRequest(listarGestionDirectorioAPI(), payload);
        if (responseCode200(response)) {
            setUsuariosFiltrados(response.data.data.Items);
            setTotalItems(response.data.data.TotalItems);
            setFirsItemPage(response.data.data.FirsItemPage);
            setLastItemPage(response.data.data.LastItemPage);
            setTotalPages(response.data.data.TotalPages);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setDepartamentosSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const toggleEditarSedeModal = async (idUser) => {
        setHideEditarSedeModal(!hideEditarSedeModal);
        if(idUser) {
          setUserSelected(idUser);
        }
    }


    return (
        <>
         <div className="message-no-disponible-en-mobile">
            <p>Esta página no está disponible en mobile.</p>
         </div>
         <div className="no-disponible-en-mobile">
            {hideEditarSedeModal=== false &&
                <div className="modal-contactanos modal-response-ficha-background">
                <EditarSedeModal userId={userSelected} showModal={toggleEditarSedeModal} reload={()=> {manageSearch()}} />
                </div>
            }

            <Wrapper className='App'>
                <PageHeader title={"Gestión de Directorio"} />
                {loading === true &&
                    <div className="spinner-cargando-container">
                        <Spinner animation="grow" variant="primary" size="1sm" />
                    </div>
                }

                {loading !== true && (<>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                        <input
                            type='text'
                            className='generic-input-criterio-busqueda'
                            placeholder='Buscar por sede'
                            value={criterioDeBusqueda}
                            onChange={(e) => { setCriterioDeBusqueda(e.target.value); }}
                            onKeyPress={handleKeypress}
                        />
                        <button
                            onClick={manageSearch}
                            type='button'
                            className='generic-button-buscador'>
                            Buscar
                        </button>
                        <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className="table-scroll">
                            <table className='table table-hover'>
                                <thead className="listar-thead">
                                    <tr>
                                            <th>
                                                <div className='flex font-gothan-medium'>Sede</div>
                                            </th>
                                            <th>
                                            <FormSelect
                                                options={departamentosFiltradosOptions}
                                                description={'Departamento'}
                                                setSelectedOptions={setDepartamentosSeleccionados}
                                                selectedOptions={departamentosSeleccionados}
                                                />
                                            </th>
                                            <th>
                                                <div className='flex font-gothan-medium'>Teléfono</div>
                                            </th>
                                            <th>
                                                <div className='flex font-gothan-medium'>Correo</div>
                                            </th>
                                            <th>
                                                <div className='flex font-gothan-medium'>Ubicación</div>
                                            </th>
                                            <th>
                                                <div className='flex font-gothan-medium'>Acciones</div>
                                            </th>
                                   </tr>
                                </thead>
                                <TableWebBodyDirectorio filas={usuariosFiltrados} toggleEditarSedeModal={toggleEditarSedeModal}/>
                            </table>
                        </div>   
                        {totalPages > 1 && (
                                <Paginator totalItems={totalItems}
                                           firsItemPage={firsItemPage}
                                           lastItemPage={lastItemPage}
                                           totalPages={totalPages}
                                           page={page}
                                           onLeftClic={onLeftClic}
                                           onRightClic={onRightClic}/>
                            )}
                     </div>
                </>)}
            </Wrapper>
         </div>
        </>
    );
};

export default GestionDeDirectorio;