import React, { useState, useEffect, useContext } from 'react'
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { AuthContext } from '../../../hooks/context/authContext';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import InputSelect from '../../../components/modalComponents/InputSelect';
import ModalForm from '../../../components/modalComponents/ModalForm';
import { downloadFile } from '../../../consumers/backendConsumer';
import { handleChangeSelect } from '../../../helper/utils';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { createSolicitudTaiLoyAPI, generarFormatoAutorizacionDescuentoPlantillaAPI, getValesTaiLoyToCreateAPI } from '../../../consumers/backendApisUrls';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';

const SolicitudDescuentoVale = ({ toggleModal,  }) => {

  const { state } = useContext(AuthContext);
  const idEmpleado = state.user.id_empleado;
  const SECTION_NAME = "SolicitudValeDescuentoTaiLoy";

  const STEPS = {One: 1, Two: 2}
  const KEYS_INIT = { vales: "ValesTailoy", file: "LineamientosFile" };
  const KEYS_SEND = { idVale: "IdValeTaiLoy", file: "FileViewModel" };

  const [formValues, setFormValues] = useState({});
  const [initValues, setInitValues] = useState({ [KEYS_INIT.vales]: [], [KEYS_INIT.file]: "", original: [] });
  const [step, setStep] = useState(STEPS.One);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [executeGet] = useGetRequest();
  const [submitForm] = useSubmitForm();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const init = async () => {
    const api = getValesTaiLoyToCreateAPI(idEmpleado);
    const response = await executeGet(api);
    if (responseCode200(response)){
      successInitCallback(response);
    } else {
      mostrarMensajeDeErrorConToast(response);
      toggleModal();
    }
    setIsLoading(false);
  }

  const successInitCallback = ({ data }) => {
    const toValueLabel = data[KEYS_INIT.vales].map(o => ({value: o.IdValeTaiLoy, label: o.Nombre}));
    const IdProcesoSolicitudTaiLoy = data[KEYS_INIT.vales].at(0)?.IdProcesoSolicitudTaiLoy;
    setInitValues(lastData => ({ ...lastData, ...data, [KEYS_INIT.vales]: toValueLabel, [KEYS_INIT.file]: data[KEYS_INIT.file], IdProcesoSolicitudTaiLoy }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (step === STEPS.One){
      setStep(STEPS.Two);
      return;
    }
    
    if (step === STEPS.Two){
      setIsSubmitting(true);
      const api = createSolicitudTaiLoyAPI();
      const payload = getPayload();
      try {
        await submitForm(Object.values(KEYS_SEND), api, payload, SECTION_NAME, successSubmitCallback)
      } catch (error) {
        console.error(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  const getPayload = () => {
    return {
      IdProcesoSolicitudTaiLoy: initValues.IdProcesoSolicitudTaiLoy,
      IdEmpleado: idEmpleado,
      ...formValues,
    }
  }

  const successSubmitCallback = () => {
    toggleModal();
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, [KEYS_SEND.file]: res}));
  }

  const handleDownloadFile = async (e) => {
    e.preventDefault();
    setIsDownloadingFile(true);
    const api = generarFormatoAutorizacionDescuentoPlantillaAPI();
    const payload = {
      IdEmpleado: idEmpleado,
      IdValeTailoy: formValues[KEYS_SEND.idVale],
    }
    try {
      await downloadAndDelete(api, payload);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloadingFile(false);
    }
  }

  const handleChangeFromSelect = (res) => {
    handleChangeSelect(setFormValues, res, KEYS_SEND.idVale);
    setFormValues(lastData => ({ ...lastData, valeTayLoy: res.target.value }))
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo='Solicitud Descuento Vales Tai Loy' width='600px' label={step === STEPS.One ? "Siguiente" : "Guardar"} saveButtonDisabled={ isUploading || !formValues[KEYS_SEND.idVale] } isSubmitting={isSubmitting}>
      {step === STEPS.One &&
      <>
        <InputSelect label='Vale' options={initValues[KEYS_INIT.vales]} value={initValues[KEYS_INIT.vales].find(o => o.value === formValues[KEYS_SEND.idVale])} onChange={handleChangeFromSelect} name={KEYS_SEND.idVale} sectionName={SECTION_NAME} />
        {initValues[KEYS_INIT.file] && 
          <div className='d-flex flex-column ml-1'>
            <a href={initValues[KEYS_INIT.file]} target='_blank' rel='noopener noreferrer'>Mira los lineamientos aquí</a>
          </div>
        }
      </>
      }
      {step === STEPS.Two && 
        <div>
          <div className="d-flex justify-content-between">
            <div className='d-flex flex-column ml-1'>
              <label>{initValues.NombreCompleto}</label>
              <label>{initValues.Cargo}</label>
              <label>{initValues.Sede}</label>
              <label className='ficha-edit-form-label ml-0'>{formValues.valeTayLoy?.label ?? ""}</label>
            </div>
            <div className='col-sm-4'>
              <div className='d-flex flex-column' style={{ gap: "10px" }}>
                <button type='button' className='btn btn-primary custom-button-adjuntar' onClick={handleDownloadFile} disabled={isDownloadingFile}>
                  Descargar Formato Autorización
                </button>
              </div>
            </div>
          </div>
          <div className='mt-3 pt-2' style={{ borderTop: "1px solid #dee2e6" }}>
            <InputUploadFile label="Cargar Formato Autorización" name={KEYS_SEND.file} uploadFile={formValues[KEYS_SEND.file]} setUploadFile={handleUploadFile} isUploading={isUploading} setIsUploading={setIsUploading} id="uploadFileSolicitudDescuentoVale" validExtensions={["pdf"]} sectionName={SECTION_NAME} direction='column'/>
          </div>
        </div>
      }
    </ModalForm>
  )
}

export default SolicitudDescuentoVale;

const ButtonUploadFle = ({ name, uploadFile, setUploadFile, isUploading, setIsUploading,id, validExtensions, sectionName, icon, textButton = "Cargar Formato Autorización", isDisabled = false, isIcon = false,}) => {
  const { state } = useContext(AuthContext);
  
  return (
    <div className="black-color">
      {!isDisabled &&
      <>{uploadFile?.Path ? (
        <div>
          <a
            className="mx-4 hand-pointer blue-standard-color"
            onClick={() => {
              downloadFile(
                state.token,
                uploadFile?.Path,
                uploadFile?.OriginalFileName
              );
            }}
          >
            {isIcon ? 
              <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload} className="green-innova"/> :
              <>
                <FontAwesomeIcon icon="paperclip" transform="left-9" />
                {uploadFile?.OriginalFileName}
              </>
            }
          </a>
          <FontAwesomeIcon
            icon="trash"
            size="lg"
            title="Eliminar"
            className="hand-pointer blue-standard-color"
            onClick={() => setUploadFile({})}
          />
        </div>
      ) : (
        <div className="">
          <GenericUploadFileV2
            id={id}
            extensionesValidas={validExtensions}
            uploadFileToS3={setUploadFile}
            textButton={textButton}
            subiendoArchivoPublicState={isUploading}
            setSubiendoArchivoPublicState={setIsUploading}
            resetInitialFile={false}
            isIcon={isIcon}
            icon={icon}
          />
        </div>
      )}
      </>}
      <p
        className="ficha-form-input-validation-message"
        id={`ficha-edit-${sectionName}-${name}-validation-message`}
      ></p>
    </div>)
}