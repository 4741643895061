import React, {useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import {useForm} from '../../../hooks/useForm/UseForm';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {encuestaSaludRegistrarEstadoAPI, getDetalleFichaMedicaAPI, registrarAdjuntarSustentoAPI} from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";

const AgregarSustento = ({toggleModal, idEncuestaDetalle, setReload}) => {
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();
    const [uploadFileCertificadoMedico, setUploadFileCertificadoMedico] = useState({});
    const ID_INPUT_FILE = "AgregarSustentoFile"
    const sectionName = "AgregarSustento"
    const [manageGetRequest] = useManageGetRequest();
    const [detalleEncuestaData, setDetalleEncuestaData] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const callbackSuccessGetDetalleFichaMedica = function (response) {
        setDetalleEncuestaData(response.data);
    }

    const getInitialData = async () => {
        try {
            await manageGetRequest(getDetalleFichaMedicaAPI(idEncuestaDetalle),callbackSuccessGetDetalleFichaMedica, false);
        } catch (e) {
            console.error(e);
        }
    };

    const successSubmitCallback = function () {
        setReload((reload) => !reload);
        toggleModal();
    }

    const submit = async () => {
        let payload = armarPayload();
        let api = registrarAdjuntarSustentoAPI();
        let keys = ["IdEncuestaSaludDetalle", "IdEstadoFicha", "PresentaCondicionMedica", "IdResultado", "Descripcion", "Sustento"];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const armarPayload = () => {
        let payload = {
            "IdEncuestaSaludDetalle": detalleEncuestaData?.IdEncuestaSaludDetalle,
            /* "IdEstadoFicha": detalleEncuestaData?.IdEstadoFicha,
            "PresentaCondicionMedica": detalleEncuestaData?.PresentaCondicionMedica,
            "IdResultado": detalleEncuestaData?.IdResultado, */
            "Descripcion": formValues?.Descripcion,
            "Sustento": uploadFileCertificadoMedico
        }

        return payload;
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"700px"} titulo={"Agregar Sustento"}>
                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label modal-label">Detalle<span className="required">*</span></label>
                            <div className="col-sm-10 black-color">
                                <textarea autoFocus data-required="true" rows="4" type="text" className="form-control" name="Descripcion" onChange={handleChange} maxLength="250" placeholder={"Máximo 250 caracteres."}></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Descripcion-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label modal-label">Sustento<span className="required">*</span></label>
                            <div className="col-sm-10 black-color">
                                <GenericUploadFileV2 uploadFileToS3={setUploadFileCertificadoMedico} id={ID_INPUT_FILE} textButton={"Subir archivo"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Sustento-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default AgregarSustento;