import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import styled from 'styled-components';

const YesNoModal = ({ toggleModal, resolve }) => {
  const Message = "Si regresas a esta pregunta, todas las respuestas se perderán";

  const handleYesClick = () => {
    resolve(true);
    toggleModal();
  }

  return (
    <ModalWrapper>
      <div className="wrapper">
        <div className="container">
          <div className="text">{Message}</div>
          <div className="buttons">
            <button type='button' onClick={handleYesClick}>
              <FontAwesomeIcon
                title='Aceptar'
                icon="check"
              />
            </button>
            <button type='button' onClick={toggleModal}>
              <FontAwesomeIcon
                title='Cancelar'
                icon="times"
              />
            </button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default YesNoModal;

const ModalWrapper = styled.div`
.wrapper{
  margin: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 222222;
  background-color: #80808069;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  
  .container{
    position: absolute;
    top: 40%;
    padding: 25px 20px;
    background: white;
    margin: 0 15px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    z-index: 2;
    text-align: center;
    box-shadow: 0 0 4px rgba(0,0,0,.1), 0 2px 4px rgba(0,0,0,.2);

    .buttons {
      display: flex;
      justify-content: space-around;
      width: 100%;

      button {
        padding: 5px 50px;
        border: 1px solid #3a87c8;
        border-radius: 15px;
        transition: background-color .2s ease-in-out;
        
        &:first-child{
          color: white;
          background-color: #3a87c8;
        }

        &:last-child{
          color: #3a87c8;
        }

        &:hover{
          filter: opacity(0.9);
        }
      }
    }
  }
}
`