import React, { useState } from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import popUpContent from '../../../image/becas.png';
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";

const ModalBecas = ({ showModal }) => {
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);
    const [executePostRequest] = usePostRequest();

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            const response = await executePostRequest({}, {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const closeModal = async function () {
        //await submit();
        showModal();
    };

    return (
        <>
            <GenericModal showModal={closeModal} width={"600px"} titulo={"Becas familiares y amigos"}>
                <div className="modal-body" style={{ 'padding': '0' }}>
                    <a href="https://procesodeadmision.innovaschools.edu.pe/BecasFamiliaresyAmigos?utm_source=landing&utm_medium=lpg_soc&utm_campaign=is_bcs_social_nac_prf_cpt_lpg_53_595&utm_content=is_social_nac_pop_popup-becas-colaboradores&utm_term=is_social_nac_org_soc_becas-colaboradores2024&utm_id=595" target="_blank">
                        <img src={popUpContent} alt='' width="100%" />
                    </a>
                </div>
                <div className="modal-footer" style={{ 'display': 'flex', 'justify-content': 'space-between' }}>
                    <div className="message font-gothan-light" style={{ 'align-self': 'flex-start' }}>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalBecas;