import React, {useState} from 'react';
import GenericModal from "../modal/GenericModal";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {ocultarModalInformativoVacunacionAPI} from "../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import popUpContent from "../../image/registraTuEstadoDeVacunacion.jpeg";

const InformativoVacunaModal = ({toggleInformativoVacunaModal, toggleRegistroVacunaModal}) => {
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);
    const [executePostRequest] = usePostRequest();

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            const response = await executePostRequest(ocultarModalInformativoVacunacionAPI(), {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const closeModal = async function () {
        await submit();
        await toggleInformativoVacunaModal();
    };

    const clickOnImage = async function () {
        await closeModal();
        await toggleRegistroVacunaModal();
    }

    return (
        <>
            <GenericModal showModal={closeModal} width={"750px"} titulo={"Registra tu estado de vacunación COVID-19"}>
                <div className="modal-body" style={{'padding': '0'}}>
                    <a onClick={clickOnImage}><img src={popUpContent} alt='' width="100%" style={{'margin': '-5px -5px -5px -5px'}}/></a>
                </div>
                <div className="modal-footer" style={{'display': 'flex', 'justify-content': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'align-self': 'flex-start'}}>
                    </div>
                    <div className="button" style={{'align-self': 'flex-end'}}>
                        <input type="checkbox" checked={checkNoVolverAMostrarModal} onClick={clickOnCheck}/> No mostrarme esta ventana otra vez&nbsp;&nbsp;
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default InformativoVacunaModal;