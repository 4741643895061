import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Redirect} from "react-router-dom";

const TableWebBody = ({
    filas, 
    editar, 
    eliminar, 
    asignarPlantilla, 
    toggleAsignarTipoContrato,
    SaveProcesoCierre,
    toggleActivarInactivarProcesoDeCierreModal,
    setProcesoDeCierreTitle,
    setProcesoDeCierreId,
    setProcesoDeCierreEstado
}) => {

const [move, setMove] = useState(false)
const [procesoCierre,setProcesoCierre] = useState({})

const routeChange = (ProcesoCierre) => {
    setMove(true)
    setProcesoCierre(ProcesoCierre)  
};

const executeFunctions = (procesoCierre) => {
    toggleAsignarTipoContrato()
    SaveProcesoCierre(procesoCierre)
};

if (move) {
    return <Redirect  to={{pathname: '/procesosDeCierre/AsignarGruposIndex/'+ procesoCierre.IdProcesoCierre, state: {procesoCierre: procesoCierre }}}/>;   
}
    return (
        <tbody className="listar-tbody">
        {filas.map((fila, i) => (
            <tr key={i}>
                <td className="col-3 pl-3 ml-3">{fila?.Nombre}</td>
                <td className="col-3 pl-1 pr-1">{fila?.Descripcion}</td>
                <td className="col-1 pr-3">{fila?.FechaInicio}</td>
                <td className="col-1 pr-3">{fila?.FechaFin}</td>
                <td className="col-1 pl-1 pr-1">{fila?.Estado}</td>
                <td className="col-1 pl-1 pr-1">{fila?.IsActive ? 'Activo' : 'Inactivo'}</td>
                <td className="col-1 pl-1 pr-2">                   
                    <div className="d-flex justify-content-start">
                        <div>
                            <FontAwesomeIcon icon='user-friends' size="sm" className="hand-pointer mr-3" title="Asignar grupo" onClick={()=>{routeChange(fila)}} />
                        </div>
                        <div>
                            <FontAwesomeIcon icon="file-invoice" size="sm" className='hand-pointer mr-3' title="Asignar plantilla" onClick={() => asignarPlantilla(fila?.IdProcesoCierre)}/>
                        </div>
                            <div>
                                <FontAwesomeIcon icon="signature" size="sm" className='hand-pointer mr-3' title="Asignar contrato" onClick={() => executeFunctions(fila) }/>
                        </div>
                        {fila?.PuedeEditar &&
                        <div>
                            <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer mr-3" title="Editar" onClick={() => {
                                editar(fila?.IdProcesoCierre)
                            }}/>
                        </div>
                        }
                        {fila?.PuedeEliminar &&
                        <div>
                                <FontAwesomeIcon
                                    icon={fila.IsActive  ? 'ban' : 'circle-notch'}
                                    size="sm"
                                    className='hand-pointer'
                                    title={fila.IsActive  ? 'Inactivar' : 'Activar'}
                                    onClick={() => {
                                        setProcesoDeCierreId(fila.IdProcesoCierre);
                                        setProcesoDeCierreTitle(fila.Nombre);
                                        setProcesoDeCierreEstado(fila.IsActive);
                                        toggleActivarInactivarProcesoDeCierreModal();
                                    }}
                                />
                        </div>
                        }
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
}

export default TableWebBody;