import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { calificarCapacitacionAPI, getSegundoPasoCapacitacion } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';

const EvaluacionCurso = ({ preguntas = [], enableContinue, EsCapacitacionCompletada, setIsDisableBack, goBackToVideo, idCursoCapacitacion}) => {
  const [value, setValue] = React.useState({});
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [cursoSegundaParte, setCursoSegundaParte] = React.useState([]);
  const [executePostRequest] = usePostRequest();
  const [executeGetRequest] = useGetRequest();

  const init = async () => {
    const preguntasResponse = await executeGetRequest(getSegundoPasoCapacitacion(idCursoCapacitacion));
    setCursoSegundaParte(preguntasResponse?.data);

    let defaultValues = {};
    preguntasResponse.data.forEach(pregunta => {
      defaultValues[`quiz${pregunta?.IdPregunta}`] = -1;
    });
    setValue(defaultValues);
    if (EsCapacitacionCompletada) enableContinue();
  }

  useEffect(() => {
    init();
  }, []);


  const handleRadioChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
    if (responseMessage !== null) setResponseMessage(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    var result = Object.keys(value).map((key) => ({
      IdPregunta: parseInt(key.substring(4)),
      IdAlternativa: value[key]
    }));


    let payload = { Items: result,IdCursoCapacitacion: idCursoCapacitacion}
    let api = calificarCapacitacionAPI();

    let response = await executePostRequest(api, payload);
    if (responseCode200(response)) {
      setResponseMessage(response?.data);
      if (response?.data?.EsAprobado) {
        enableContinue();
        setIsDisableBack(true);
        toast.success(response?.data.Mensaje);
      }
      else {
        goBackToVideo();
        toast.error(response?.data.Mensaje);
      };
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  };

  return <div>
    <form onSubmit={handleSubmit} className="d-flex flex-column">
      {
        cursoSegundaParte.map(pregunta => (
          <div key={pregunta?.IdPregunta} className="mb-5">
            <label>{pregunta?.TextoPregunta}</label>
            <br />
            {
              pregunta.Alternativas.map(alternativa => (
                <div key={alternativa.IdAlternativa} className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`quiz${pregunta?.IdPregunta}`}
                    id={`quiz${pregunta?.IdPregunta}`}
                    checked={value[`quiz${pregunta?.IdPregunta}`] == alternativa.IdAlternativa}
                    value={alternativa.IdAlternativa}
                    onChange={handleRadioChange}
                  />
                  <label className="form-check-label" htmlFor={`quiz${pregunta?.IdPregunta}`}>
                    {alternativa.TextoAlternativa}
                  </label>
                </div>
              ))
            }
          </div>
        ))
      }
      {(responseMessage !== null && !responseMessage?.EsAprobado) || !EsCapacitacionCompletada ? (
        <div className="d-flex justify-content-end">
          <button className='generic-button-buscador mb-2' style={{ width: '18%' }} type="submit">
            Enviar
          </button>
        </div>
      ) : (
        <div className="alert alert-primary mt-3" role="alert">
          La evaluación fue respondida con &eacute;xito, porfavor continue con la siguiente sección.
        </div>
      )}

    </form>
  </div>;
};

export default EvaluacionCurso;
