import React, { useEffect, useState } from "react";
import GenericModal from '../../../components/modal/GenericModal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { set } from "store";
import {useGenerarDescargarEliminarArchivo} from "../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";
import {
    seguimientoColaboradorEnvioCarnetVacunaZipAPI
} from "../../../consumers/backendApisUrls";

const ConfirmacionZipModal = ({ toggleModal,obtenerPayload}) => {
    const [Enviando, setEnviando] = useState(true)
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();
    const initCall = async ()=> {
        await generarDescargarEliminarArchivo(seguimientoColaboradorEnvioCarnetVacunaZipAPI(), obtenerPayload(), ()=>{toggleModal()});
      }
    const [countOfProgess, setCountOfProgess] = React.useState(0);

    useEffect(() => {
        initCall();
    }, [])

    React.useEffect(() => {
        const timer = setInterval(() => {
          setCountOfProgess((oldProgress) => {
            if (100 == oldProgress) return 100;
            return Math.min(oldProgress + Math.random() * 10, 100);
          });
        }, 499);
     
        return () => {
          clearInterval(timer);
        };
      }, []);

    return (
        <GenericModal showModal={toggleModal} width={"700px"} titulo={"Confirmación de Zip"}>
            <div>
                <p>Se está procesando el archivo en formato zip con todos los carnets de vacunación, del listado mostrado.</p>
                { countOfProgess == 100 && (
                            <p>Esta pantalla se cerrará cuando se haya completado la descarga.</p>
                            )
                }
                <div></div>
                {
                    Enviando ? (<div>
                        <p>Procesando.</p>
                        <ProgressBar animated now={countOfProgess} />
                    </div>) : null
                }
            </div>
        </GenericModal>
    )
}
export default ConfirmacionZipModal;