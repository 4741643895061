import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDescargarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { activarModelarCapacitacionAPI, activarRegistroCapacitacionAPI, desactivarModeladoCapacitacionAPI, eliminarRegistroCapacitacionAPI, inactivarRegistroCapacitacionAPI } from '../../consumers/backendApisUrls';
import { toast } from 'react-toastify';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';

const TableWebBodyCapacitacion = ({ filas = [], manageSearch, PuedeVerInactivos }) => {
  const [managePostRequest] = useManagePostRequest();

  const [descargarArchivoDeS3] = useDescargarArchivoDeS3();

  const download = async (user) => {
    if (user && user.File !== null) {
      descargarArchivoDeS3(user.File.OriginalFileName, user.File.Path);
    } else {
      toast.error("El usuario no tiene un archivo adjunto");
    }
  };

  const eliminarRegistro = async (user) => {
    const payload = {
      IdCapacitacionEmpleado: user.IdCapacitacionEmpleado
    }
    const api = eliminarRegistroCapacitacionAPI();
    const response = await managePostRequest(api, payload, () => manageSearch())
    console.debug(response);
  }

  const activarRegistro = async (user) => {
    const api = activarRegistroCapacitacionAPI(user.IdCapacitacionEmpleado);
    const response = await managePostRequest(api, {}, () => manageSearch())
    console.debug(response);
  }

  const inactivarRegistro = async (user) => {
    const api = inactivarRegistroCapacitacionAPI(user.IdCapacitacionEmpleado);
    const response = await managePostRequest(api, {}, () => manageSearch())
    console.debug(response);
  }

  const handleChangeCheck = async (event, index) => {
    let payload = {
      "IdCapacitacionEmpleado": filas[index].IdCapacitacionEmpleado
    };
    if (filas[index].ModeladoCapacitacion === false) {
      await managePostRequest(activarModelarCapacitacionAPI(), payload, manageSearch)
    }
    if (filas[index].ModeladoCapacitacion === true) {
      await managePostRequest(desactivarModeladoCapacitacionAPI(), payload, manageSearch)
    }
  }

  return (
    <tbody className="listar-tbody">
      {filas.map((user, i) => (
        <tr key={i}>
          <td>{user.Empleado}</td>
          <td>{user.FechaRegistro}</td>
          <td>{user.Sede}</td>
          <td>{user.Division}</td>
          <td>{user.Gerencia}</td>
          <td>{user.Area}</td>
          <td>{user.CompletoCapacitacion}</td>
          {PuedeVerInactivos ? (
            <td>{user.EstadoRegistro}</td>
          ) : null}
          <td>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={user.ModeladoCapacitacion}
                onChange={(e) => handleChangeCheck(e, i)}
              />
            </div>
          </td>
          <td style={{ width: '150px' }}>
            <div
              className="d-flex flex-row justify-content-center align-items-center"
            >
              {user.File !== null ? (
                <div className="d-flex mx-2">
                  <FontAwesomeIcon icon='paperclip' className="hand-pointer blue-standard-color" title={`Adjunto`} onClick={() => download(user)} />
                </div>
              ) : null}
              {user.PuedeInactivar &&
                <FontAwesomeIcon icon='user-times' transform="left-1" className="hand-pointer blue-standard-color" title={`Desactivar el registro`} onClick={() => inactivarRegistro(user)} />
              }
              {user?.PuedeActivar &&
                <FontAwesomeIcon icon='user-check' transform="left-1" className="hand-pointer blue-standard-color" title={`Activar el registro`} onClick={() => activarRegistro(user)} />
              }
              {user?.PuedeReiniciar &&
                <div className="d-flex mx-2">
                  <FontAwesomeIcon icon='retweet' className="hand-pointer blue-standard-color" title={`Reiniciar registro`} onClick={() => eliminarRegistro(user)} />
                </div>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableWebBodyCapacitacion;
