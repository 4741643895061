import GenericModal from "../../../components/modal/GenericModal";
import React, {useEffect, useState} from "react";
import {getDatosContactoAPI, geTiposDeVanunasCovidAPI} from "../../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";

const DatosContactosModal = function ({toggleModal, idEmpleado}) {
    const [executeGetRequest] = useGetRequest();
    const [data, setData] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function  () {
        const response = await executeGetRequest(getDatosContactoAPI(idEmpleado));
        if (responseCode200(response)) {
             setData(response?.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    return (
        <GenericModal showModal={toggleModal} width={"500px"} titulo={"Datos de contacto"}>
            <div className="modal-body p-0">
               <p className="pb-0"><strong>Dirección</strong></p>
                <p className="pb-0 mb-0">{data?.Direccion? data?.Direccion: "No registrado"}</p>
                <p className="pb-0">{data?.DireccionUbigeo}</p>

                <p className="pb-0"><strong>Teléfonos</strong></p>
                <p className="pb-0">{data?.Telefonos}</p>

                <p className="pb-0"><strong>Contacto Emergencia</strong></p>
                <p className="pb-0">{data?.ContactoEmergencia}</p>
            </div>
        </GenericModal>
    )
}

export default DatosContactosModal;
