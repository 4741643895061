import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import { CuponCard, CuponImage, CuponWrapper } from '../../components/StyledComponents';

const TableCupones = ({ rows, handleEditCupon, handleActiveInactiveCupon, isFetching }) => {

  if (isFetching) return <div className='d-flex' style={{ height: "60vh" }}><SpinnerLoading /></div>

  return (
    <CuponWrapper>
      {rows.map((row, i) => (
        <div key={`${row.Id}-${i}`} className="position-relative">
          <Card isActive={row.Activo ?? true}>
            <div className='inner-content'>
              <CuponImage src={row.ImagenUrl} loading='lazy'/>
            </div>
            <div className="icons">
              {row.Activo &&
                <FontAwesomeIcon icon="edit" title='Editar Cupón' size='4x' className='hand-pointer' onClick={() => handleEditCupon(row)}/>
              }
              <FontAwesomeIcon icon={row.Activo ? 'ban' : 'circle-notch'} title={row.Activo  ? 'Inactivar' : 'Activar'} size='4x'  className='hand-pointer' onClick={() => handleActiveInactiveCupon(row)}/>
            </div>
          </Card>
        </div>
      ))}
    </CuponWrapper>
  )
}

export default TableCupones;

const Card = styled(CuponCard)`
  &:hover {
    .inner-content {
      transform: scale(1.05);
      filter: ${({isActive}) => isActive ? "grayscale(70%)" : ""};
    }
  }

  .inner-content {
    filter: ${({isActive}) => isActive ? "" : "grayscale(100%)"};
  }
`;