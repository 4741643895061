import store from 'store';
import {
    USER_DATA_KEY,
    TOKEN_KEY,
    IS_AUTH_KEY,
    PROFILE,
    IS_COOKIES,
    SHOW_TERMS,
    ROLE,
    COOKIES_MESSAGE, 
    ROLE_IDS,
    ES_INTERNO,
    MENUS_ACCESO,
} from '../../constants/index';

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            store.set(USER_DATA_KEY, action.payload.user);
            store.set(TOKEN_KEY, action.payload.token);
            store.set(IS_AUTH_KEY, true);
            store.set(IS_COOKIES, action.payload.cookies);
            store.set(SHOW_TERMS, action.payload.terms);
            store.set(ROLE, action.payload.role);
            store.set(COOKIES_MESSAGE, action.payload.cookiesMessage);
            store.set(ROLE_IDS, action.payload.roleIds);
            store.set(ES_INTERNO, action.payload.isInterno)
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
                isLoggedIn: true,
                cookies: action.payload.cookies,
                terms: action.payload.terms,
                cookiesMessage: action.payload.cookiesMessage,
                role: action.payload.role,
                roleIds: action.payload.roleIds,
                isInterno: action.payload.isInterno
            };
        case 'PUBLIC_TOKEN':
            store.set(TOKEN_KEY, action.payload.token);
            return {
                ...state,
                token: action.payload.token,
            }
        case 'COOKIES':
            store.set(IS_COOKIES, action.payload.cookies);
            return {...state, cookies: action.payload.cookies};
        case 'TERMS':
            store.set(SHOW_TERMS, action.payload.terms);
            return {...state, terms: action.payload.terms};
        case 'LOGOUT':
            store.remove(USER_DATA_KEY);
            store.remove(TOKEN_KEY);
            store.remove(PROFILE);
            store.set(IS_AUTH_KEY, false);
            store.remove(IS_COOKIES);
            store.remove(SHOW_TERMS);
            store.remove(ROLE);
            store.remove(ROLE_IDS);
            store.remove(ES_INTERNO);
            store.remove(MENUS_ACCESO);
            return {
                ...state,
                user: null,
                isLoggedIn: false,
                token: null,
                profile: null,
                role: null,
                roleIds: null,
                isInterno: null,
            };
        case 'MENUS_ACCESO':
            store.set(MENUS_ACCESO, action.payload.menusAcceso);
            return {...state, menusAcceso: action.payload.menusAcceso};
        case 'SHOW_MESSAGE_EXPIRED_SESSION':
            return {
                ...state,
                notificationMessage : 'La sesión ha expirado, por favor iniciar sesión nuevamente',
            };
        case 'UPDATE_USER':
            store.set(USER_DATA_KEY, action.payload.user);
            return {
                ...state,
                user: action.payload.user,
            };
        case 'ASK_FOR_NEW_NOTIFICATIONS':
            let currentValue = state.askForNewNotifications;
            return {
                ...state,
                askForNewNotifications: !currentValue,
            };
        case 'SHOW_BOT_TRUE':{
            return {
                ...state,
                showBot: true
            };
        }
        case 'SHOW_BOT_FALSE':{
            return {
                ...state,
                showBot: false
            };
        }

        default:
            return state;
    }
};
