import React, { useEffect, useState } from 'react'
import InputWeekDay from '../components/InputWeekDay'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputText from '../../../components/modalComponents/InputText'
import InputDate from '../../../components/modalComponents/InputDate'
import InputTextArea from '../../../components/modalComponents/InputTextArea'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import InputUploadFile from '../../../components/modalComponents/InputUploadFile'
import { WEEK_DAYS } from '../../../constants/hardCodedConstants'
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest'
import { editarPopupAPI, getEditarPopupByIdAPI } from '../../../consumers/backendApisUrls'

const EditarPopup = ({ toggleModal, refreshPage, popup }) => {

  const KEYS = { title: "Titulo", image: "ImagenPopupFileViewModel", startDate: "FechaInicio", endDate: "FechaFin", dias: "Dias", link: "LinkRedirigir", desc: "Descripcion" };
  const SECTION_NAME = "EditarPopUpGenerico";
  const VALID_EXTENSIONS = ["jpg", "png"];

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();
  const [executeManageGet] = useManageGetRequest();

  const init = async () => {
    setIsLoading(true);
    const api = getEditarPopupByIdAPI(popup.IdPopup);
    await executeManageGet(api, handleSuccessInitCallback);
    setIsLoading(false);
  }

  const handleSuccessInitCallback = ({ data }) => {
    const { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday, ...dataRaw } = data;
    const DiasRaw = { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday };
    const Dias = [];
    for (const dia in DiasRaw) {
      if (!DiasRaw[dia]) continue;
      Dias.push(WEEK_DAYS[dia].number);
    }
    if (!Dias.length) return setFormValues(dataRaw);
    return setFormValues({ ...dataRaw, Dias });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = editarPopupAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { Dias, ...formValuesRaw } = formValues;
    if (!Dias || !Dias.length) return formValuesRaw;
    let diasObjects = {};
    for (const dia in WEEK_DAYS) {
      if (!Dias.includes(WEEK_DAYS[dia].number)) continue;
      diasObjects[dia] = true;
    }
    return { ...formValuesRaw, ...diasObjects };
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm titulo="Editar Popup" showModal={toggleModal} width="650px" isSubmitting={isSubmitting} saveButtonDisabled={isUploading || isSubmitting} onSubmit={handleSubmit}>
      <InputText label='Título' {...commonProps(KEYS.title)} isRequired/> 
      <div className='disclaimer-formacion-profesional mb-2'>La imagen debe tener el formato png y el tamaño de 700x700 px, en caso contrario la imagen se redimensionará automáticamente.</div>
      <InputUploadFile label='Imagen' name={KEYS.image} uploadFile={formValues[KEYS.image]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.image]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={VALID_EXTENSIONS} isRequired/>
      <InputDate label='Fecha Inicio' {...commonProps(KEYS.startDate)} isRequired/>
      <InputDate label='Fecha Fin' {...commonProps(KEYS.endDate)} isRequired/>
      <InputWeekDay label='Fecha Semana' options={Object.values(WEEK_DAYS).map(o => ({value: o.number, label: o.smallName}))} {...commonProps(KEYS.dias)} isRequired/>
      <InputText label='Link a redirigir' {...commonProps(KEYS.link)} />
      <InputTextArea label='Descripción' {...commonProps(KEYS.desc)} />
    </ModalForm>
  )
}

export default EditarPopup