import React, { useState, useEffect, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from '../../../components/Select/SelectInput';
import Editor from '../componentes/CustomEditor';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { getTiposTemplateAPI, crearTemplateAPI, previsualizarTemplateAPI } from "../../../consumers/backendApisUrls";

const CrearPlanitlla = ({ toggleModal, refreshPage }) => {
  const isMounted = useRef(true);
  const SECTION_NAME = "AgregarNuevaPlantilla";
  const KEYS = {Name: "Nombre", Type: "IdTemplateType", FileName: "NombreArchivo", Template: "Plantilla"};

  const [formValues, setFormValues] = useState({});
  const [allTemplateTypes, setAllTemplateTypes] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoadingEditor, setIsLoadingEditor] = useState(true);

  const [handleChange] = useForm(formValues, setFormValues);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [managePostRequest] = useManagePostRequest();

  const init = async () => {
    try {
      setAllTemplateTypes(await getOptions(getTiposTemplateAPI()));
    } catch (error) {
      console.error(error);
    }
  }

  const submit = async () => {
    const api = crearTemplateAPI()
    let payload = armarSubmitPayload();
    setButtonDisabled(true);
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setButtonDisabled(false))
  };

  const armarSubmitPayload = () => {
    return { 
      ...formValues,
      [KEYS.Type]: formValues[KEYS.Type]?.value 
    }
  }

  const successSubmitCallback = function () {
    setButtonDisabled(false);
    refreshPage();
    toggleModal();
  }

  const plantillaPreview = async () => {
    const api = previsualizarTemplateAPI();
    let payload = { Plantilla: formValues[KEYS.Template] };
    setButtonDisabled(true);
    await managePostRequest(api, payload, successPreviewCallback, false, () => setButtonDisabled(false)); 
  }

  const successPreviewCallback = (response) => {
    setButtonDisabled(false);
    window.open(response.data.FileTempUrl);
  }
  
  const handleChangeEditor = (res) => {
    const data = res;
    setFormValues(lastData => ({...lastData, [KEYS.Template]: data}))
  }

  useEffect(() => {
    if (isMounted) init();

    return () => {
      isMounted.current=false;
    }
  }, [])

  return (
    <>
      <GenericModal showModal={toggleModal} width={"900px"} titulo={"Agregar nueva template"}>
        <div className="modal-body pt-0 pl-0">
          <form>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label modal-label required">Nombre</label>
              <div className="col-sm-8 black-color">
                <input type="text" placeholder='Ingresar nombre' className="form-control" name={KEYS.Name} onChange={handleChange} value={formValues[KEYS.Name] ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Name}-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-4 col-form-label modal-label required">Nombre del archivo (*.pdf)</label>
              <div className="col-sm-8 black-color">
                <input type="text" placeholder='Ingresar nombre de archivo' className="form-control" name={KEYS.FileName} onChange={handleChange} value={formValues[KEYS.FileName] ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.FileName}-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-4 col-form-label modal-label required">Tipo</label>
              <div className="col-sm-8 black-color">
                <SelectInput
                  options={allTemplateTypes}
                  jsonSelectedValue={formValues[KEYS.Type] ?? ""}
                  setJsonSelectedValue={(res) => setFormValues(lastData => ({...lastData, [KEYS.Type]: res}))}
                  placeholder="Seleccionar"
                  resetSelectedValue={false}
                  isDisabled={!(formValues[KEYS.Template] == undefined || formValues[KEYS.Template] == "<p><br></p>")}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Type}-validation-message`}></p>
              </div>
            </div>

            <div className="">
              <label className="modal-label required">
                Plantilla {isLoadingEditor && <FontAwesomeIcon icon="info-circle" transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon" data-tip data-for="informacion-tipo-plantilla"/>}
              </label>
              <div style={{backgroundColor: 'white'}}>
                <Editor 
                  value={formValues[KEYS.Template] ?? ""}
                  handleChange={handleChangeEditor}
                  idTemplateType={formValues[KEYS.Type]?.value ?? ""}
                  loading={isLoadingEditor}
                  setLoading={setIsLoadingEditor}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Template}-validation-message`}></p>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button 
              className="btn btn-primary generic-button-ficha-modal" 
              onClick={plantillaPreview}
              disabled={buttonDisabled}
            >
              PDF - vista previa {buttonDisabled && (<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>)}
            </button>
          </div>
          <div className="button">
            <button 
              className="btn btn-primary generic-button-ficha-modal" 
              onClick={submit}
              disabled={buttonDisabled}
            >
              Guardar
            </button>
          </div>
        </div>
      </GenericModal>
    </>
  )
}

export default CrearPlanitlla;