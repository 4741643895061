import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText';
import InputDate from '../../../../components/modalComponents/InputDate';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { crearProcesoSolicitudTaiLoyAPI } from '../../../../consumers/backendApisUrls';

const CrearProcesoModal = ({ toggleModal, refreshPage, getProcesos }) => {

  const SECTION_NAME = "crearProcesoSolicitudDescuentoVale"
  const KEYS = { nombre: "Nombre", concepto: "ConceptoDescuento", periodo: "PeriodoDescuento", desde: "FechaInicio", hasta: "FechaFin" };

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearProcesoSolicitudTaiLoyAPI();

    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleSuccessSubmit = () => {
    toggleModal();
    getProcesos({});
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo='Crear Proceso de solicitud' width='700px' isSubmitting={isSubmitting}>
      <Div col={12}><InputText label='Nombre' {...commonProps(KEYS.nombre)} direction='column'/></Div>
      <div className='displayFlexWrap'>
        <Div col={6}><InputText label='Concepto Descuento' {...commonProps(KEYS.concepto)} direction='column'/></Div>
        <Div col={6}><InputText label='Periodo Descuento' {...commonProps(KEYS.periodo)} direction='column'/></Div>
      </div>
      <label className='ml-3 mb-3 reportes-label'>Vigencia</label>
      <div className='displayFlexWrap'>
        <Div col={6}><InputDate label='Desde' {...commonProps(KEYS.desde)}/></Div>
        <Div col={6}><InputDate label='Hasta' {...commonProps(KEYS.hasta)}/></Div>
      </div>
    </ModalForm>
  )
}

export default CrearProcesoModal;

const Div = ({ col, children }) => (
  <div className={`col-sm-${col}`}>
    {children}
  </div>
)