import React, {useState, useEffect} from 'react'
import Table from './Table'
import CrearCuponera from './configuracionCuponera/modales/CrearCuponera'
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import PageButtons from '../../../components/pageComponents/PageButtons'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { listarCuponeraTiempoLibreAPI } from '../../../consumers/backendApisUrls'

const GestionCuponeraTiempoLibre = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false });

  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = listarCuponeraTiempoLibreAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback)
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Lista de Cuponeras de Tiempo Libre" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons hasBackButton hasCreateButton handleCreateButton={() => setModalEnabled({ isEnable: true, component: CrearCuponera})} />
      </SearchContainer>
      <Table rows={formValues.Items} paginator={paginator} setPaginator={setPaginator} setModalEnabled={setModalEnabled}/>
    </PageWrapper>
  )
}

export default GestionCuponeraTiempoLibre