import {notNullAndNotUndefinded} from "../helper/utils";

export const updateFormValidationMessage = (htmlElementId, message) => {
    console.debug(`updateValidationMessage, htmlElementId: "${htmlElementId}",  message: ${message}`);
    let element = document.getElementById(htmlElementId);
    if (notNullAndNotUndefinded(element)) {
        element.innerHTML = message;
    } else {
        console.warn(`updateFormValidationMessage. Elemento no existe, id: "${htmlElementId}", por lo que no se agregará mensaje de validación`);
    }
};

export const cleanFormValidationMessage = (htmlElementId) => {
    console.debug(`updateValidationMessage, htmlElementId: "${htmlElementId}"`);
    let element = document.getElementById(htmlElementId);
    if (notNullAndNotUndefinded(element)) {
        element.innerHTML = "";
    } else {
        console.warn(`updateFormValidationMessage. Elemento no existe, id: "${htmlElementId}", por lo que no ejecutó bien cleanFormValidationMessage`);
    }
};

export const updateValidationMessage = (keyField, message, seccionName) => {
    console.debug(`updateValidationMessage, keyField: "${keyField}",  message: ${message}`);
    let htmlElementId = `ficha-edit-${seccionName}-${keyField}-validation-message`;
    updateFormValidationMessage(htmlElementId, message);
};

export const clearValidationMessage = (keyField, seccionName) => {
    console.debug(`clearValidationMessage, keyField: "${keyField}"`);
    let htmlElementId = `ficha-edit-${seccionName}-${keyField}-validation-message`;
    updateFormValidationMessage(htmlElementId, "");
};

export const obtenerElementoInputDeFicha = (keyField, seccionName) => {
    let element = document.getElementById(`ficha-edit-${seccionName}-${keyField}`);
    if (notNullAndNotUndefinded(element)) {
        return element;
    } else {
        console.warn(`id de elemento no existe, "ficha-edit-${seccionName}-${keyField}"`);
    }
};

export const obtenerValorDeInputDeFicha = (keyField, seccionName) => {
    let element = obtenerElementoInputDeFicha(keyField, seccionName);
    if (notNullAndNotUndefinded(element)) {
        return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
    }
};

export const validaRegex = (regexString, keyField, inputValue, validationMessage, seccionName) => {
    let campoInvalido = false;
    if (regexString) {
        console.debug(`validar fieldKey: "${keyField}", regexString: "${regexString}", value: "${inputValue}", validationMessage: "${validationMessage}"`);
        let regex = new RegExp(regexString);
        if (regex.test(inputValue)) {
            clearValidationMessage(keyField, seccionName);
        } else {
            updateValidationMessage(keyField, validationMessage, seccionName);
            campoInvalido = true;
            console.debug("campoInvalido: ", campoInvalido);
        }
    } else {
        console.warn(`no se va a validar porque no tiene regex fieldKey: "${keyField}", regexString: "${regexString}", value: ${inputValue}`);
        clearValidationMessage(keyField, seccionName);
    }
    return campoInvalido;
};

export const mostrarMensajesDeValidacionEnCadaInput = function (response, allInputKeys, seccionName) {
    let modelState = response?.response?.data?.ModelState;
    console.debug("modelState: ", modelState);
    clearAllInputValidationMessages(allInputKeys, seccionName);

    for (let [inputKey, inputValidationMessages] of Object.entries(modelState)) {
        console.debug("inputKey: ", inputKey);
        console.debug("inputValidationMessages: ", inputValidationMessages);
        console.debug("inputValidationMessage: ", inputValidationMessages[0]);
        updateValidationMessage(inputKey, inputValidationMessages[0], seccionName)
    }
};

export const clearAllInputValidationMessages = function (allInputKeys, seccionName) {
    for (let inputKey of allInputKeys) {
        clearValidationMessage(inputKey, seccionName);
    }
};