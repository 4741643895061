import React, { useState, useEffect } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { getDominiosInnovaAPI, saveCreaCorreoActivityAPI, getOnboardingInfoActivityAPI} from "../../../consumers/backendApisUrls";
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import SelectInput from "../../../components/Select/SelectInput";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";

const ActualizarCorreoCorporativoModal = ({
  toggleModal: showModal,
  idEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
  manageSearch,
}) => {

  const sectionName = "ActualizarCorreoCorporativo";

  const [value, setValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dominios, setDominios] = useState();
  const [dominioSelected, setDominioSelected] = useState();
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [skipDuplicatedMailValidation, setSkipDuplicatedMailValidation] = useState(false);
  const [modalEnable, setModalEnable] = useState({isEnable: false});

  const [handleChange] = useForm(value, setValue);
  const [getOptions] = useGetOptionsSelect();
  const [executeGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const getInitialData = async () => {
    let data = await getOptions(getDominiosInnovaAPI())
    let dataSplit = data.map(o => ({...o, label: o.label.split("@")[1]}))
    setDominios(dataSplit);
    await executeGetRequest(getOnboardingInfoActivityAPI(IdEmpleadoFlujoActividad), ({ data }) => successInitialDataCallback(data, dataSplit), false)
  }

  const successInitialDataCallback = (data, dataSplit) => {
    let { CorreoActual } = data;
    const email = CorreoActual.split('@')[0];
    const dominio = CorreoActual.split('@')[1];

    setValue(lastData => ({...lastData, email }));
    setDominioSelected(dataSplit.find(o => o.label === dominio));
    setDataEmpleado(data)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        const api = saveCreaCorreoActivityAPI();
        let payload = getPayload();
        let keys = ["Correo"]
        await submitForm(keys, api, payload, sectionName, saveSuccessCallback)
    } catch (error) {
        console.error(error);
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
        IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
        Correo: `${value.email ?? ""}@${dominioSelected.label ?? ""}`,
        SkipDuplicatedMailValidation: skipDuplicatedMailValidation,
    }
  }

  const saveSuccessCallback = (res) => {
    const { MailAlreadyRegistered } = res.data;
    if(MailAlreadyRegistered){
      setModalEnable({ isEnable: true, component: ConfirmacionCorreoModal, data: { setSkipDuplicatedMailValidation } });
    } else {
      showModal();
      manageSearch();
    }
  }

  useDidMountEffect(() => {
    if (skipDuplicatedMailValidation) {
      handleSubmit();
    }
  }, [skipDuplicatedMailValidation])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) getInitialData();

    return () => {
        unmounted = true;
    }
  },[])

  return (
    <>
      {modalEnable.isEnable && 
        <div className="modal-contactanos modal-response-ficha-background">
          <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} manageSearch={manageSearch} {...modalEnable.data}/>
        </div>
      }
      <GenericModal
        showModal={showModal}
        width={"800px"}
        titulo="Actualizar correo corporativo"
      >
        <div className="modal-body pt-0 pl-0 pb-0">
          <form onSubmit={handleSubmit}>
            <div className="form-group row mb-2">
              <label className="col-sm-3 col-form-label modal-label">
                Colaborador
              </label>
              <label className="col-sm-9 col-form-label black-color">
                {dataEmpleado?.NombreEmpleado}
              </label>
            </div>
            <div className="form-group row mb-2">
              <label className="col-sm-3 col-form-label modal-label">
                Fecha de Ingreso
              </label>
              <label className="col-sm-9 col-form-label black-color">
                {dataEmpleado?.FechaIngreso}
              </label>
            </div>
            <div className="form-group row mb-2">
              <label className="col-sm-3 col-form-label modal-label">Sede</label>
              <label className="col-sm-9 col-form-label black-color">
                {dataEmpleado?.NombreSede}
              </label>
            </div>
            <div className="form-group row mb-4">
              <label className="col-sm-3 col-form-label modal-label">Ingresar Email</label>
              <div className="col-sm-9">
                <div className="row">
                  <input className="col-sm-5 form-control" type="text" name="email" onChange={handleChange} value={value.email} placeholder="Ingresar correo"/>
                  <p className="col-form-label pl-3">@</p>
                  <div className="col-sm-6">
                    <SelectInput options={dominios} jsonSelectedValue={dominioSelected} setJsonSelectedValue={setDominioSelected} placeholder="Seleccionar"/>
                  </div>
                </div>
                <p className="ficha-form-input-validation-message mt-1" id={`ficha-edit-${sectionName}-Correo-validation-message`}></p>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="generic-button-buscador"
                disabled={isSubmitting}
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </GenericModal>
    </>
  );
};

export default ActualizarCorreoCorporativoModal;

const ConfirmacionCorreoModal = ({ toggleModal, setSkipDuplicatedMailValidation }) => {

  return ( <GenericModal
     showModal={toggleModal}
     width={"600px"}
     titulo="Actualizar correo corporativo"
     >
     <div className="modal-body pt-0 pl-0">
       <div className="container-fluid">
         <label>Este correo ya está registrado por un empleado activo, nuevo o cesado.</label>
         <label>¿Está seguro de esta acción?</label>
       </div>
       <div className="modal-footer justify-content-end">
           <button 
             className="btn btn-primary generic-button-ficha mr-2" 
             onClick={() => setSkipDuplicatedMailValidation(true)}>
             Continuar
           </button>
           <button 
             className="btn btn-primary generic-button-ficha" 
             onClick={() => {toggleModal()}}>
             Cancelar
           </button>
       </div>
     </div>
     </GenericModal>
   )
 }