import React, {useContext, useEffect, useState} from 'react';
import SelectInputDatosEmpresa from "./SelectInputDatosEmpresa";
import MultiSelectInputDatosEmpresa from "./MultiSelectInputDatosEmpresa";
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {agregarTextOfSelectInputType, convertirArrayToStringSeparadoPorComas, convertirStringToArraySeparadoPorComas, getElementByQuerySelector, hideElement, isNullOrUndefinded, notNullAndNotUndefinded, removeAttributeToElement, setAttributeToElement, showElement, stringEndsInThisCharacter} from "../../helper/utils";
import {toast} from "react-toastify";
import {obtenerElementoInputDeFicha, obtenerValorDeInputDeFicha, updateValidationMessage, validaRegex} from "../../validations/FormValidatorUtils";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import {BANCOS, IDFIELDS, LIST_FIELD_TYPE, ROLES, TIPO_DE_CONTRATO_INDEFINIDO_ID} from "../../constants/hardCodedConstants";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getColaborador, getDatosEmpresaAPI, getInformacionCeseByIdEmpleadoAPI, guardarDatosEmpresaAPI} from "../../consumers/backendApisUrls";
import AsyncSelectInputGET from "../../components/Select/AsyncSelectInputGET";

const SeccionEditDatosEmpresa = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, showSolicitarCambiosModal}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosEmpresa, setDatosEmpresa] = useState({});
    const [datosCese, setDatosCese] = useState();
    const [dataFields, setDataFields] = useState([]);
    const [dropdowns, setDropdowns] = useState({});
    const [dropdownsSelectedValues, setDropdownsSelectedValues] = useState({});
    const [idOtrasEspecialidadesIsDisabled, setIdOtrasEspecialidadesIsDisabled] = useState(false);
    const nombreSeccion = "datosEmpresa";
    const [loading, setLoading] = useState(false);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [liderSeleccionado, setLiderSeleccionado] = useState({});
    //const [liderMatricialSeleccionado, setLiderMatricialSeleccionado] = useState({});
    const [liderDisabled, setLiderDisabled] = useState(true);

    const [empleadoMatricialSuperior, setEmpleadoMatricialSuperior] = useState("-");

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        console.debug("[DatosEmpresa] useDidMountEffect dataFields");
        updateHideShowElementsInHTMLWhenDataFieldsChange();
        updateEditAndReadonlyElementsInHTMLWhenDataFieldsChange();
        showEditFormSubtitlesOnlyIfItHasInputChildsOrSelectChilds();
    }, [dataFields]);

    const updateId = () => {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    };

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                let responseDatosEmpresa = await executeGetRequest(getDatosEmpresaAPI(idActualizado));
                if (responseCode200(responseDatosEmpresa)) {

                    setDatosEmpresa(responseDatosEmpresa.data.data);
                    setDataFields(responseDatosEmpresa.data.data.DataFields);

                    let dropdownSelectedValues = {};
                    dropdownSelectedValues["IdSede"] = responseDatosEmpresa.data.data.IdSede;
                    dropdownSelectedValues["IdClasificacionGdh"] = responseDatosEmpresa.data.data.IdClasificacionGdh;
                    dropdownSelectedValues["IdCentroCosto"] = responseDatosEmpresa.data.data.IdCentroCosto;
                    dropdownSelectedValues["IdUnidad"] = responseDatosEmpresa.data.data.IdUnidad;
                    dropdownSelectedValues["IdDepartamento"] = responseDatosEmpresa.data.data.IdDepartamento;
                    dropdownSelectedValues["IdArea"] = responseDatosEmpresa.data.data.IdArea;
                    dropdownSelectedValues["IdSeccion"] = responseDatosEmpresa.data.data.IdSeccion;
                    dropdownSelectedValues["IdCargo"] = responseDatosEmpresa.data.data.IdCargo;
                    dropdownSelectedValues["IdNivelEducativo"] = responseDatosEmpresa.data.data.IdNivelEducativo;
                    dropdownSelectedValues["IdEspecialidadPrincipal"] = responseDatosEmpresa.data.data.IdEspecialidadPrincipal;
                    dropdownSelectedValues["IdGradoDesarrollo"] = responseDatosEmpresa.data.data.IdGradoDesarrollo;
                    dropdownSelectedValues["IdTipoInstruccion"] = responseDatosEmpresa.data.data.IdTipoInstruccion;
                    dropdownSelectedValues["IdTipoContrato"] = responseDatosEmpresa.data.data.IdTipoContrato;
                    dropdownSelectedValues["IdFormaIngreso"] = responseDatosEmpresa.data.data.IdFormaIngreso;
                    dropdownSelectedValues["IdFondoPension"] = responseDatosEmpresa.data.data.IdFondoPension;
                    dropdownSelectedValues["IdBancoSueldo"] = responseDatosEmpresa.data.data.IdBancoSueldo;
                    dropdownSelectedValues["IdTipoCuentaSueldo"] = responseDatosEmpresa.data.data.IdTipoCuentaSueldo;
                    dropdownSelectedValues["IdMonedaSueldo"] = responseDatosEmpresa.data.data.IdMonedaSueldo;
                    dropdownSelectedValues["IdMonedaCts"] = responseDatosEmpresa.data.data.IdMonedaCts;
                    dropdownSelectedValues["IdBancoCTS"] = responseDatosEmpresa.data.data.IdBancoCTS;
                    dropdownSelectedValues["IdSeguroSalud"] = responseDatosEmpresa.data.data.IdSeguroSalud;
                    dropdownSelectedValues["IdTipoEmpleado"] = responseDatosEmpresa.data.data.IdTipoEmpleado;
                    dropdownSelectedValues["EmpleadoMatricialSuperior"] = responseDatosEmpresa.data.data.EmpleadoMatricialSuperior;
                    //setEmpleadoMatricialSuperior(responseDatosEmpresa.data.data.EmpleadoMatricialSuperior)

                    let numberArrayValues = convertirStringToArraySeparadoPorComas(responseDatosEmpresa.data.data.IdOtrasEspecialidades);
                    console.debug("numberArrayValues: ", numberArrayValues);
                    dropdownSelectedValues["IdOtrasEspecialidades"] = numberArrayValues;

                    setDropdownsSelectedValues(dropdownSelectedValues);

                    let dropdownss = await obtenerDropdownsCuyoAPInoRecibeParametros(responseDatosEmpresa.data.data.DataFields, dropdownSelectedValues);
                    console.debug("[DatosEmpresa] dropdownss ", dropdownss);
                    setDropdowns(dropdownss);

                    setLiderSeleccionado({value:responseDatosEmpresa.data.data.IdEmpleadoSuperior,label:responseDatosEmpresa.data.data.EmpleadoSuperior})
                    //setLiderMatricialSeleccionado({value:responseDatosEmpresa.data.data.IdEmpleadoMatricialSuperior,label:responseDatosEmpresa.data.data.EmpleadoMatricialSuperior})
                } else {
                    mostrarMensajeDeErrorConToast(responseDatosEmpresa);
                }
                let respenseDatosCese = await executeGetRequest(getInformacionCeseByIdEmpleadoAPI(idActualizado));
                if (responseCode200(respenseDatosCese)){
                    setDatosCese(respenseDatosCese.data);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    // manage dropdowns ini
    const obtenerDropdownsCuyoAPInoRecibeParametros = async (dataFieldsArray, dropdownsSelectedValuess) => {
        let dropdownss = {};
        for (let dataField of dataFieldsArray) {
            let dataFieldKey = dataField["KeyField"];
            if ((dataField["FieldType"] === "List") || (dataField["FieldType"] === "MultiList")) {
                let dropdownOptions = await getDropdownOptions(dataField, dataFieldsArray, dropdownsSelectedValuess);
                dropdownss[dataFieldKey] = dropdownOptions;
            }
        }
        return dropdownss;
    };

    const getDropdownOptions = async (dataField, dataFieldss, dropdownsSelectedValuess) => {
        let url = dataField["DataRequestUrl"];
        let dropdownOptions = [];
        if (stringEndsInThisCharacter(url, "/")) {
            dropdownOptions = await obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro(dataField, dataFieldss, dropdownsSelectedValuess);
        } else {
            dropdownOptions = await obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro(dataField);
        }
        return dropdownOptions;
    };

    const obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro = async (dataField, dataFieldss, dropdownsSelectedValuess) => {
        console.debug("[DatosEmpresa] obtenerDropDownOptionsDeDataFieldCuyoApiRecibeParametro, dataField: ", dataField);
        let api = dataField["DataRequestUrl"];
        let dropdownOptions = [];

        let dataFieldPapa = obtenerDataFieldPapa(dataField, dataFieldss);
        console.debug("[DatosEmpresa] dataFieldPapa: ", dataFieldPapa);
        let keyFieldPapa = dataFieldPapa["KeyField"];
        console.debug("[DatosEmpresa] keyFieldPapa: ", keyFieldPapa);
        let valueSeleccionadoDelPapa = dropdownsSelectedValuess[keyFieldPapa];
        console.debug("[DatosEmpresa] valueSeleccionadoDelPapa: ", valueSeleccionadoDelPapa);

        let finalApi = `${api}${valueSeleccionadoDelPapa}`;
        console.debug("[DatosEmpresa] finalApi: ", finalApi);

        let response = await makeRequestObtenerDropDownOptions(finalApi);
        if (response) {
            dropdownOptions = response;
        }
        return dropdownOptions;
    };

    const obtenerDataFieldPapa = (dataFieldHijo, dataFieldss) => {
        console.debug("obtenerDataFieldPapa dataFieldss: ", dataFieldss);
        console.debug("obtenerDataFieldPapa dataFieldHijo.IdField ", dataFieldHijo["IdField"]);
        for (let dataField of dataFieldss) {
            if (dataField["IdDependent"] == dataFieldHijo["IdField"]) {
                return dataField;
            }
        }
    };

    const obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro = async (dataField) => {
        console.debug("[DatosEmpresa] obtenerDropDownOptionsDeDataFieldCuyoApiNoRecibeParametro, dataField: ", dataField);
        let api = dataField["DataRequestUrl"];
        let dropdownOptions = [];
        let response = await makeRequestObtenerDropDownOptions(api);
       
        if(dataField["IdField"] == IDFIELDS.IDFIELDBANCOSUELDO) {
            response = response.filter( item =>item.Value !== BANCOS.NOAPLICA)
        }


        if(!(state.roleIds.includes(ROLES.SuperAdmin.id))){
            if(dataField["IdField"] == IDFIELDS.IDFIELDBANCOCTS) {
                response = response.filter( item =>item.Value !== BANCOS.NOAPLICA)
            } 
        }

        if (response) {
            dropdownOptions = response;
        }
        return dropdownOptions;
    };

    const makeRequestObtenerDropDownOptions = async (api) => {
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            console.debug("[DatosEmpresa] response data: ", response.data);
            return response.data;
        } else {
            console.warn("hubo un error al ejecutar el api: ", api);
        }
    };


    // manage dropdowns fin

    const cancelar = async () => {
        try {
            setShowViewSeccion(true);
        } catch (e) {
            console.error(e);
        }
    };

    const onSubmit = async (event) => {
        try {
            console.debug("[DatosEmpresa] submit");
            event.preventDefault();
            let formularioInvalido = formularioInvaldo();
            console.debug("[DatosEmpresa] formularioInvalido ", formularioInvalido);
            if (!formularioInvalido) {
                let payload = {
                    IdEmpleado: idActualizado,
                };

                payload = agregarCamposDeFormulario(payload);
                console.debug("[DatosEmpresa] payload:", payload);
                let response = await executePostRequest(guardarDatosEmpresaAPI(), payload);
                console.debug("[DatosEmpresa] response ", response);
                if (responseCode200(response)) {
                    showUpdateConfirmationModal(response.data.UrlImage, response.data.Message);

                    await updateComponentStates();
                    askForNewBellNotifications(dispatch);
                    setShowViewSeccion(true);

                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            } else {
                toast.warn("Los datos ingresados no son válidos.");
            }


        } catch (e) {
            console.error(e);
        }
    };

    const agregarCamposDeFormulario = (payload) => {
        for (let dataField of dataFields) {
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let valorDeInput;
                if (dataField["FieldType"] == "MultiList") {
                    valorDeInput = convertirArrayToStringSeparadoPorComas(dropdownsSelectedValues[keyField])
                } else if (dataField["FieldType"] == "TypeAhead") {
                    if (keyField === "IdEmpleadoSuperior") valorDeInput = liderSeleccionado?.value ?? null;
                    // if (keyField === "IdEmpleadoMatricialSuperior") valorDeInput = liderMatricialSeleccionado?.value ?? null;
                }else{
                    valorDeInput = obtenerValorDeInputDeFicha(keyField, nombreSeccion);
                }

                if (notNullAndNotUndefinded(valorDeInput)) {
                    payload = {...payload, [keyField]: valorDeInput};
                    if (dataField["FieldType"] == LIST_FIELD_TYPE) {
                        let element = obtenerElementoInputDeFicha(keyField, nombreSeccion);
                        payload = agregarTextOfSelectInputType(keyField, element, payload);
                    }

                } else {
                    console.warn(`no se encontró campo con el id "${keyField}", por lo que no se agregará al payload`);
                }
            }
        }
        return payload;
    };

    const formularioInvaldo = () => {
        let nroCamposInvalidos = 0;

        for (let dataField of dataFields) {
            let campoInvalido = false;
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let regexString = dataField["ValidationString"];
                let validationMessage = dataField["ValidationMessage"];
                let isRequired = fieldIsRequired(dataField);
                let fieldType = dataField["FieldType"];
                let inputValue;

                if (dataField["FieldType"] == "MultiList") {
                    inputValue = convertirArrayToStringSeparadoPorComas(dropdownsSelectedValues[keyField])
                } else if (dataField["FieldType"] == "TypeAhead") {
                    if (keyField === "IdEmpleadoSuperior") inputValue = liderSeleccionado?.value ?? null;
                    // if (keyField === "IdEmpleadoMatricialSuperior") inputValue = liderMatricialSeleccionado?.value ?? null;
                }else{
                    inputValue = obtenerValorDeInputDeFicha(keyField, nombreSeccion);
                }

                console.debug(`[DatosEmpresa] keyField: '${keyField}', inputValue:'${inputValue}'`);
                if (isRequired === true) {
                    if (((fieldType == "Text" || fieldType == "Calendar" || fieldType == "MultiList" || fieldType == "TypeAhead") && inputValue == "") || (fieldType == "List" && inputValue == 0)) {
                        updateValidationMessage(keyField, "Este campo es obligatorio", nombreSeccion);
                        campoInvalido = true;
                    } else {
                        campoInvalido = validaRegex(regexString, keyField, inputValue, validationMessage, nombreSeccion);
                    }
                } else {
                    if (inputValue != "") {
                        campoInvalido = validaRegex(regexString, keyField, inputValue, validationMessage, nombreSeccion);
                    }
                }

                nroCamposInvalidos = (campoInvalido) ? nroCamposInvalidos + 1 : nroCamposInvalidos;
            }
        }

        return (nroCamposInvalidos > 0) ? true : false;
    };

    const fieldIsRequired = (dataField) => {
        let keyFiledd = dataField["KeyField"];
        let isRequired;
        switch (keyFiledd) {
            case "VencimientoContrato" : {
                let selectedTipoDeContratoValue = obtenerValorDeInputDeFicha("IdTipoContrato", nombreSeccion);
                isRequired = (TIPO_DE_CONTRATO_INDEFINIDO_ID == selectedTipoDeContratoValue) ? false : true;
                break;
            }
            default: {
                isRequired = dataField["IsRequired"];
                break;
            }
        }

        return isRequired;
    };

    ///hidden ini

    const updateHideShowElementsInHTMLWhenDataFieldsChange = () => {
        console.debug("[DatosEmpresa] updateHideShowElementsInHTMLWhenDataFieldsChange dataFields: ", dataFields);
        try {
            for (const field of dataFields) {
                let fieldKey = field["KeyField"];
                if (field["IsHidden"] === true) {
                    console.debug("[DatosEmpresa] esconder fieldKey: ", fieldKey);
                    hideElement(`ficha-view-datosEmpresa-${fieldKey}`);
                    hideElement(`ficha-edit-inputGroup-datosEmpresa-${fieldKey}`);
                }

                if (field["IsHidden"] === false) {
                    console.debug("[DatosEmpresa] mostrar fieldKey: ", fieldKey);
                    showElement(`ficha-view-datosEmpresa-${fieldKey}`);
                    showElement(`ficha-edit-inputGroup-datosEmpresa-${fieldKey}`);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    ///hidden fin
    //readonly ini

    const updateEditAndReadonlyElementsInHTMLWhenDataFieldsChange = () => {
        try {
            for (const field of dataFields) {
                let fieldKey = field["KeyField"];

                let element = getElementByQuerySelector("#frmEditDatosEmpresa input[name='" + fieldKey + "']");

                if (isNullOrUndefinded(element)) {
                    element = getElementByQuerySelector("#frmEditDatosEmpresa select[name='" + fieldKey + "']");
                }


                if (notNullAndNotUndefinded(element)) {
                    let canEdit = field["CanEdit"];
                    if (canEdit === true) {
                        console.debug("[DatosEmpresa] remove disabled: ", fieldKey);
                        removeAttributeToElement(element, "disabled");
                    }

                    if (canEdit === false) {
                        console.debug("[DatosEmpresa] add disabled: ", fieldKey);
                        setAttributeToElement(element, "disabled", "");
                    }
                } else {
                    console.warn("[DatosEmpresa] No se encontró html element with name: ", fieldKey);
                    if (field["FieldType"] === "MultiList" && fieldKey === "IdOtrasEspecialidades") {
                        let canEdit = field["CanEdit"];
                        if (canEdit === true) {
                            console.debug("[DatosEmpresa] IdOtrasEspecialidades remove disabled: ", fieldKey);
                            setIdOtrasEspecialidadesIsDisabled(false);
                        }

                        if (canEdit === false) {
                            console.debug("[DatosEmpresa] IdOtrasEspecialidades add disabled: ", fieldKey);
                            setIdOtrasEspecialidadesIsDisabled(true);
                        }
                    }

                    if (field["FieldType"] === "TypeAhead" && fieldKey === "IdEmpleadoSuperior") {
                        let canEdit = field["CanEdit"];
                        if (canEdit === true) {
                            console.debug("[DatosEmpresa] IdEmpleadoSuperior remove disabled: ", fieldKey);
                            setLiderDisabled(false);
                        }

                        if (canEdit === false) {
                            console.debug("[DatosEmpresa] IdEmpleadoSuperior add disabled: ", fieldKey);
                            setLiderDisabled(true);
                        }
                    }

                    // if (field["FieldType"] === "TypeAhead" && fieldKey === "IdEmpleadoMatricialSuperior") {
                    //     let canEdit = field["CanEdit"];
                    //     if (canEdit === true) {
                    //         console.debug("[DatosEmpresa] IdEmpleadoMatricialSuperior remove disabled: ", fieldKey);
                    //         setLiderMatricialDisabled(false);
                    //     }

                    //     if (canEdit === false) {
                    //         console.debug("[DatosEmpresa] IdEmpleadoMatricialSuperior add disabled: ", fieldKey);
                    //         setLiderMatricialDisabled(true);
                    //     }
                    // }


                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    //readonly fin

    function showOrHideSubtitle(containerName) {
        let subtitleId = `#frmEditDatosEmpresa-${containerName}-title`;
        let containerTitleElement = getElementByQuerySelector(subtitleId);
        if (getContainerInputsChilds(`frmEditDatosEmpresa-${containerName}-container`).length == 0) {
            setAttributeToElement(containerTitleElement, "hidden", "");
        } else {
            removeAttributeToElement(containerTitleElement, "hidden");
        }
    }

    const showEditFormSubtitlesOnlyIfItHasInputChildsOrSelectChilds = () => {
        showOrHideSubtitle("datosPlanilla");
        showOrHideSubtitle("datosContratacion");
        showOrHideSubtitle("informacionEconomica");
    };

    const getContainerInputsChilds = (htmlContainerId) => {
        let query = `#${htmlContainerId} [id^='ficha-edit-inputGroup-']:not([hidden=''])`;
        let elements = (document.querySelectorAll(query));
        console.debug(`query: ${query}, resultElements: `, elements);
        return elements;
    };

    const handleChangeBuscarColaboradorPrincipal = async (jsonValue) => {
        setLiderSeleccionado(jsonValue);
    }

    //Reloj cancelar solicitud

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}
            <form id='frmEditDatosEmpresa' className="ficha-edit-form" onSubmit={onSubmit} hidden={loading}>
                <div className="displayFlexWrap" id={"frmEditDatosEmpresa-datosPlanilla-container"}>
                    <div style={{'width': '100%'}} id={"frmEditDatosEmpresa-datosPlanilla-title"}>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos Planilla</p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdTrabajadorOfisis">
                        <label className="ficha-edit-form-label">Código de trabajador OFISIS</label><span className="required"></span>
                        <input type="text" className="form-control" name="IdTrabajadorOfisis" defaultValue={datosEmpresa && datosEmpresa.IdTrabajadorOfisis} id="ficha-edit-datosEmpresa-IdTrabajadorOfisis"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdTrabajadorOfisis-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdUnidad">
                        <label className="ficha-edit-form-label">División</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdUnidad" idSelect="ficha-edit-datosEmpresa-IdUnidad" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdUnidad-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdTipoEmpleado">
                        <label className="ficha-edit-form-label">Tipo de empleado</label><span className="required"></span>
                        <SelectInputDatosEmpresa dropdowns={dropdowns} setDropdowns={setDropdowns} jsonKeyy="IdTipoEmpleado" idSelect="ficha-edit-datosEmpresa-IdTipoEmpleado" dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdTipoEmpleado-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdSede">
                        <label className="ficha-edit-form-label">Sede</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdSede" idSelect="ficha-edit-datosEmpresa-IdSede" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdSede-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdClasificacionGdh">
                        <label className="ficha-edit-form-label">Clasificación Grupo GDH</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdClasificacionGdh" idSelect="ficha-edit-datosEmpresa-IdClasificacionGdh" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdClasificacionGdh-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdDepartamento">
                        <label className="ficha-edit-form-label">Gerencia</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdDepartamento" idSelect="ficha-edit-datosEmpresa-IdDepartamento" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdDepartamento-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdArea">
                        <label className="ficha-edit-form-label">Área</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdArea" idSelect="ficha-edit-datosEmpresa-IdArea" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdArea-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdSeccion">
                        <label className="ficha-edit-form-label">Sección</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdSeccion" idSelect="ficha-edit-datosEmpresa-IdSeccion" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdSeccion-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdCargo">
                        <label className="ficha-edit-form-label">Cargo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdCargo" idSelect="ficha-edit-datosEmpresa-IdCargo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdCargo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdNivelEducativo">
                        <label className="ficha-edit-form-label">Nivel</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdNivelEducativo" idSelect="ficha-edit-datosEmpresa-IdNivelEducativo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdNivelEducativo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdEspecialidadPrincipal">
                        <label className="ficha-edit-form-label">Especialidad Principal</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdEspecialidadPrincipal" idSelect="ficha-edit-datosEmpresa-IdEspecialidadPrincipal" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues}
                                                 setDropdownsSelectedValues={setDropdownsSelectedValues} dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdEspecialidadPrincipal-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdOtrasEspecialidades">
                        <label className="ficha-edit-form-label">Otras Especialidades</label><span className="required"></span>
                        <MultiSelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdOtrasEspecialidades" idSelect="ficha-edit-datosEmpresa-IdOtrasEspecialidades" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                      isDisabled={idOtrasEspecialidadesIsDisabled}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdOtrasEspecialidades-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdGradoDesarrollo">
                        <label className="ficha-edit-form-label">Grado de desarrollo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdGradoDesarrollo" idSelect="ficha-edit-datosEmpresa-IdGradoDesarrollo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdGradoDesarrollo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-Tutoria">
                        <label className="ficha-edit-form-label">Tutoría</label><span className="required"></span>
                        <input type="checkbox" className="ficha-datos-empresa-edit-checkbox" name="Tutoria" defaultChecked={datosEmpresa && datosEmpresa.Tutoria} id="ficha-edit-datosEmpresa-Tutoria"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-Tutoria-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-CorreoCorporativo">
                        <label className="ficha-edit-form-label">Correo corporativo</label><span className="required"></span>
                        <input type="text" className="form-control" name="CorreoCorporativo" defaultValue={datosEmpresa && datosEmpresa.CorreoCorporativo} id="ficha-edit-datosEmpresa-CorreoCorporativo"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-CorreoCorporativo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-CelularCorporativo">
                        <label className="ficha-edit-form-label">Celular corporativo</label><span className="required"></span>
                        <input type="text" className="form-control" name="CelularCorporativo" defaultValue={datosEmpresa && datosEmpresa.CelularCorporativo} id="ficha-edit-datosEmpresa-CelularCorporativo"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-CelularCorporativo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdCentroCosto">
                        <label className="ficha-edit-form-label">Centro de Costo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdCentroCosto" idSelect="ficha-edit-datosEmpresa-IdCentroCosto" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdCentroCosto-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdTipoInstruccion">
                        <label className="ficha-edit-form-label">Tipo de instrucción</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdTipoInstruccion" idSelect="ficha-edit-datosEmpresa-IdTipoInstruccion" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdTipoInstruccion-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdFormaIngreso">
                        <label className="ficha-edit-form-label">Forma de ingreso</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdFormaIngreso" idSelect="ficha-edit-datosEmpresa-IdFormaIngreso" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdFormaIngreso-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-FechaIngresoEmpresa">
                        <label className="ficha-edit-form-label">Fecha Ingreso Empresa</label><span className="required"></span>
                        <input type="date" className="form-control" name="FechaIngresoEmpresa" defaultValue={datosEmpresa && datosEmpresa.FechaIngresoEmpresaValue} id="ficha-edit-datosEmpresa-FechaIngresoEmpresa"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-FechaIngresoEmpresa-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-FechaIngresoReal">
                        <label className="ficha-edit-form-label">Fecha de Ingreso Real</label><span className="required"></span>
                        <input type="date" className="form-control" name="FechaIngresoReal" defaultValue={datosEmpresa && datosEmpresa.FechaIngresoRealValue} id="ficha-edit-datosEmpresa-FechaIngresoReal"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-FechaIngresoReal-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdEmpleadoSuperior">
                        <label className="ficha-edit-form-label">Líder</label><span className="required"></span>
                        <AsyncSelectInputGET api={getColaborador} placeholder="Nombre y/o apellidos" handleSelectInputChange={handleChangeBuscarColaboradorPrincipal} defaultValue={liderSeleccionado} isDisabled={liderDisabled}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdEmpleadoSuperior-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdEmpleadoSuperior">
                        <label className="ficha-edit-form-label">Líder Matricial</label><span className="required"></span>
                        {/* <AsyncSelectInputGET api={getColaborador} placeholder="Nombre y/o apellidos" handleSelectInputChange={(res) => setLiderMatricialSeleccionado(res)} defaultValue={liderMatricialSeleccionado} isDisabled={true}/> */}
                        <input type="text" className="form-control" name="EmpleadoMatricialSuperior" defaultValue={datosEmpresa && datosEmpresa.EmpleadoMatricialSuperior} id="ficha-edit-datosEmpresa-EmpleadoMatricialSuperior" disabled={true} ></input>
                        {/* <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-EmpleadoMatricialSuperior-validation-message"></p> */}
                    </div>
                </div>
                {/*<div className="displayFlexWrap" id={"frmEditDatosEmpresa-datosContratacion-container"}>
                    <div style={{'width': '100%', 'marginTop': '25px'}} id={"frmEditDatosEmpresa-datosContratacion-title"}>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos Contratación</p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdTipoContrato">
                        <label className="ficha-edit-form-label">Tipo de contrato</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdTipoContrato" idSelect="ficha-edit-datosEmpresa-IdTipoContrato" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdTipoContrato-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-Remuneracion">
                        <label className="ficha-edit-form-label">Remuneración</label><span className="required"></span>
                        <input type="text" className="form-control" name="Remuneracion" defaultValue={datosEmpresa && datosEmpresa.Remuneracion} id="ficha-edit-datosEmpresa-Remuneracion"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-Remuneracion-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-VencimientoContrato">
                        <label className="ficha-edit-form-label">Vencimiento de contrato</label><span className="required"></span>
                        <input type="date" className="form-control" name="VencimientoContrato" defaultValue={datosEmpresa && datosEmpresa.VencimientoContratoValue} id="ficha-edit-datosEmpresa-VencimientoContrato"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-VencimientoContrato-validation-message"></p>
                    </div>
                </div>*/}
                <>
                <div className="displayFlexWrap" id={"frmEditDatosEmpresa-informacionEconomica-container"}>
                    <div style={{'width': '100%', 'marginTop': '25px'}} id={"frmEditDatosEmpresa-informacionEconomica-title"}>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Información económica</p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdFondoPension">
                        <label className="ficha-edit-form-label">Fondo de pensiones</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdFondoPension" idSelect="ficha-edit-datosEmpresa-IdFondoPension" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdFondoPension-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-FechaAfiliacionAFP">
                        <label className="ficha-edit-form-label">Fecha de Afiliación</label><span className="required"></span>
                        <input type="date" className="form-control" name="FechaAfiliacionAFP" defaultValue={datosEmpresa && datosEmpresa.FechaAfiliacionAFPValue} id="ficha-edit-datosEmpresa-FechaAfiliacionAFP"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-FechaAfiliacionAFP-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-NroCUSPP">
                        <label className="ficha-edit-form-label">Número CUSPP</label><span className="required"></span>
                        <input type="text" className="form-control" name="NroCUSPP" defaultValue={datosEmpresa && datosEmpresa.NroCUSPP} id="ficha-edit-datosEmpresa-NroCUSPP"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-NroCUSPP-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdBancoSueldo">
                        <label className="ficha-edit-form-label">Nombre Banco Sueldo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdBancoSueldo" idSelect="ficha-edit-datosEmpresa-IdBancoSueldo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdBancoSueldo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdTipoCuentaSueldo">
                        <label className="ficha-edit-form-label">Tipo de cuenta sueldo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdTipoCuentaSueldo" idSelect="ficha-edit-datosEmpresa-IdTipoCuentaSueldo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdTipoCuentaSueldo-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-NroCuentaSueldo">
                        <label className="ficha-edit-form-label">Número de cuenta sueldo</label><span className="required"></span>
                        <input type="text" className="form-control" name="NroCuentaSueldo" defaultValue={datosEmpresa && datosEmpresa.NroCuentaSueldo} id="ficha-edit-datosEmpresa-NroCuentaSueldo"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-NroCuentaSueldo-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdMonedaSueldo">
                        <label className="ficha-edit-form-label">Moneda Sueldo</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdMonedaSueldo" idSelect="ficha-edit-datosEmpresa-IdMonedaSueldo" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdMonedaSueldo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdBancoCTS">
                        <label className="ficha-edit-form-label">Nombre Banco CTS</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdBancoCTS" idSelect="ficha-edit-datosEmpresa-IdBancoCTS" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdBancoCTS-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-NroCuentaCTS">
                        <label className="ficha-edit-form-label">Número de cuenta CTS</label><span className="required"></span>
                        <input type="text" className="form-control" name="NroCuentaCTS" defaultValue={datosEmpresa && datosEmpresa.NroCuentaCTS} id="ficha-edit-datosEmpresa-NroCuentaCTS"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-NroCuentaCTS-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdMonedaCts">
                        <label className="ficha-edit-form-label">Moneda CTS</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdMonedaCts" idSelect="ficha-edit-datosEmpresa-IdMonedaCts" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdMonedaCts-validation-message"></p>
                    </div>

                    {/* <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-TieneAsignacionFamiliar">
                        <label className="ficha-edit-form-label">Tiene asignacion familiar</label><span className="required"></span>
                        <input type="checkbox" className="ficha-datos-empresa-edit-checkbox" name="TieneAsignacionFamiliar" defaultChecked={datosEmpresa && datosEmpresa.TieneAsignacionFamiliar} id="ficha-edit-datosEmpresa-TieneAsignacionFamiliar"></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-TieneAsignacionFamiliar-validation-message"></p>
                    </div> */}
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosEmpresa-IdSeguroSalud">
                        <label className="ficha-edit-form-label">Seguro</label><span className="required"></span>
                        <SelectInputDatosEmpresa setDropdowns={setDropdowns} jsonKeyy="IdSeguroSalud" idSelect="ficha-edit-datosEmpresa-IdSeguroSalud" dropdowns={dropdowns} dropdownsSelectedValues={dropdownsSelectedValues} setDropdownsSelectedValues={setDropdownsSelectedValues}
                                                 dataFields={dataFields}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosEmpresa-IdSeguroSalud-validation-message"></p>
                    </div>

                </div>
                </>
                {datosCese && (
                    <>
                    <div className='w-100 mt-4'>
                        <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos Cese</p>
                    </div>
            
                    <div className='table-responsive form-group-sm mt-2'>
                        <table className='table table-hover'>
                            <thead>
                                <tr>
                                    <th className='text-center font-gothan-medium'>Fecha de Registro</th>
                                    <th className='text-center font-gothan-medium'>Estado</th>
                                    <th className='text-center font-gothan-medium'>Fecha Último día</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>{datosCese?.FechaRegistro}</td>
                                    <td className='text-center'>{datosCese?.Estado}</td>
                                    <td className='text-center'>{datosCese?.FechaCese}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </>
                )}

                <div style={{
                    display: 'flex',
                    'justifyContent': 'space-between',
                    'marginTop': '31.9px',
                    'marginBottom': '15px'
                }}>
                    <div style={{'alignSelf': 'flex-start'}}>
                        <div>
                            <br/>
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>
                    </div>

                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            onClick={cancelar}
                            type='button'
                            className='btn btn-primary ficha-form-button-cancelar'>
                            Cancelar
                        </button>
                        {datosEmpresa && datosEmpresa.CanSeeButtonEditar === true &&
                        <button
                            type="submit"
                            className='btn btn-primary ficha-form-button-guardar'>
                            Guardar
                        </button>
                        }
                    </div>
                </div>
            </form>
        </>
    );
};
export default SeccionEditDatosEmpresa;