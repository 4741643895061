import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const GenericTooltip = ({id, text}) => {
    return (
        <>
            <p data-tip data-for={id} className="d-inline">
                <FontAwesomeIcon icon='info-circle'className="hand-pointer blue-standard-color ml-1 mr-1"/>
            </p>
            <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style">
                {text}
            </ReactTooltip>
        </>
    )
}

export default GenericTooltip;