import React, { useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { GetTipoContratoAPI, EditarTipoContratoAPI } from "../../../consumers/backendApisUrls";
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric } from '../../../helper/utils';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import {toast} from "react-toastify";

const AsignarTipoContrato = ({ toggleModal, procesoCierre }) => {
    const [contratos, setContratos] = useState([]);
    const [canEdit, setCanEdit] = useState(true);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const getInitialData = async () => {
        await getDropdownOptions(executeGetRequestCallback(GetTipoContratoAPI(procesoCierre.IdProcesoCierre)));
    }

    const getDropdownOptions = async (getFunction) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            var listaContratos = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data.Contratos, "IdTipoContrato", "Nombre");
            setContratos(listaContratos);
            setCanEdit(response.data.PuedeEditar);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const Submit = async () => {
        let payload = armarPayloadPUT();
        const response = await executePostRequest(EditarTipoContratoAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response?.data?.Message);
            toggleModal()
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const armarPayloadPUT = () => {
        let IdsContratos = contratos.filter(x => x.Ischecked).map((x) => x.value)
        let payload = {
            IdsTiposContrato: IdsContratos,
            IdProcesoCierre: procesoCierre.IdProcesoCierre
        }
        return payload;
    }

    const updateContratos = (e) => {
        let array = [...contratos]
        array.forEach((option) => {
            if (option.value == e.target.value) {
                option.Ischecked = e.target.checked;
                return;
            }
        });
        setContratos(array)
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"500px"} titulo={"Asignar tipos de contrato"}>

                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Proceso</label>
                            <label className="col-sm-9 col-form-label black-color">{procesoCierre.Nombre}</label>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label modal-label">Tipos de contrato</label>
                        </div>
                        <div className="form-group row">
                            <div className="col-sm-12 black-color scrollBarAsignarTipoContrato">
                                {
                                    contratos.map((option) => (
                                        <div key={option.value}>
                                            {canEdit
                                                ? <input type="checkbox" name="Contratos" value={option.value} checked={option.Ischecked} onChange={(e) => updateContratos(e)}></input>
                                                : <input type="checkbox" name="Contratos" value={option.value} checked={option.Ischecked} onChange={(e) => updateContratos(e)} disabled></input>
                                            }
                                            <span className="black-color font-gothan-light ">{option.label}</span>
                                        </div>
                                    )
                                    )
                                }
                            </div>
                        </div>

                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        {canEdit
                            ? <button className="btn btn-primary generic-button-ficha-modal" onClick={Submit}>Guardar</button>
                            : <button className="btn btn-primary generic-button-ficha-modal" onClick={toggleModal}>Cerrar</button>
                        }

                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default AsignarTipoContrato;