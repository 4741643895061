import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import TableWebBody from './TableWebBody';
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageTable from '../../../components/pageComponents/PageTable';
import PageButtons from '../../../components/pageComponents/PageButtons';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../../components/pageComponents/SearchContainer';
import CrearPerfilPuesto from './modales/CrearPerfilPuesto';
import { perfilPuestoIndexGestionRequerimientoAPI } from '../../../consumers/backendApisUrls';

const ConfigurarPerfilPuesto = () => {

  const [formValues, setFormValues] = useState([]);
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});

  const [executePost] = useManagePostRequest();

  const init = async () => {
    setLoading(true);
    try {
      manageSearch()
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const manageSearch = async () => {
    const api = perfilPuestoIndexGestionRequerimientoAPI();
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria};
    await executePost(api, payload, successManageSearchCallback, false);
  };

  const successManageSearchCallback = async (response) => {
    setFormValues(response.data.Items);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  const handleCreatePerfil = () => {
    setModalEnabled({isEnable: true, component: CrearPerfilPuesto});
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <PageWrapper isLoading={loading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Configuración de Perfil de Puesto"/>

      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons hasBackButton hasCreateButton handleCreateButton={handleCreatePerfil} />
      </SearchContainer>

      <PageTable headers={tableHeaders} paginator={paginator} setPaginator={setPaginator}>
        <TableWebBody filas={formValues} setModalEnabled={setModalEnabled} manageSearch={manageSearch} />
      </PageTable>
    </PageWrapper>
  )
}

export default ConfigurarPerfilPuesto;

const tableHeaders = [
  {label: "Nombre Perfil de Puesto", col: "col-4"},
  // {label: "Perfil", col: "col-2"},
  {label: "Cargo", col: "col-4"},
  {label: "Estado", col: "col-1"},
  {label: "Acciones", col: "col-1"},
]