import React, {useContext, useEffect} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";

const DownloadReport = (props) => {
    const {state} = useContext(AuthContext);
    const {match: {params}} = props;

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        await downloadFile(state.token, params.path, params.fileName)
    }


    return (
        <>
        </>
    );
};

export default DownloadReport;