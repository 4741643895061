import React, {createContext, useReducer} from 'react';
import store from 'store';

import {authReducer} from '../reducers/authReducer';
import {
    TOKEN_KEY,
    IS_AUTH_KEY,
    USER_DATA_KEY,
    IS_COOKIES,
    SHOW_TERMS,
    ROLE,
    COOKIES_MESSAGE, ROLE_IDS, ES_INTERNO,
} from '../../constants/index';

export const AuthContext = createContext();

const initialState = {
    isLoggedIn: store.get(IS_AUTH_KEY),
    user: store.get(USER_DATA_KEY),
    token: store.get(TOKEN_KEY),
    terms: store.get(SHOW_TERMS),
    cookies: store.get(IS_COOKIES),
    role: store.get(ROLE),
    roleIds: store.get(ROLE_IDS),
    cookiesMessage: store.get(COOKIES_MESSAGE),
    notificationMessage : '',
    askForNewNotifications : false,
    showBot: true,
    isInterno: store.get(ES_INTERNO)
};

export const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(authReducer, initialState);
    const value = {state, dispatch};
    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    );
};
