import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";

const TableWebBody = ({ filas, toggleDetalleEncuestaModal, eliminarEncuesta, generarCasoCovid }) => {
  const [filass, setFilass] = useState([]);
  const { state } = useContext(AuthContext);

  useEffect(() => {
    setFilass(filas)
  }, [filas]);

  const descargarArchivo = (e, archivo) => {
    e.preventDefault()
    downloadFile(state.token, archivo.Path, archivo.OriginalFileName);
  }

  return (
    <tbody className="listar-tbody">
      {filass.map((fila, i) => (
        <tr key={i}>
          <td>{fila?.FechaRegistro}</td>
          <td>
            <div className="d-flex align-items-center" >
              <img
                style={{ borderRadius: '50%', width: '25px' }}
                src={fila?.UrlFotoPerfil}
                alt=''
              />
              <div style={{ margin: '0 10px' }}>{fila.NombreCompleto}</div>
            </div>
          </td>
          <td>{fila?.Cargo}</td>
          <td>{fila?.Division}</td>
          <td>{fila?.Gerencia}</td>
          <td>{fila?.Area}</td>
          <td>{fila?.CompletoFicha}</td>
          <td>
            {fila?.Alerta}
          </td>
          <td>
            <div className="d-flex justify-content-start">
              {fila?.PuedeGenerarCasoCovid ? (
                <div>
                  <FontAwesomeIcon icon='file-alt' size="lg" className="hand-pointer ml-3" title="Generar caso covid" onClick={() => generarCasoCovid(fila?.IdFichaSintomatologica)} />
                </div>
              ) : null}
              {fila?.IdFichaSintomatologica !== 0 ? (
                <>
                  <div>
                    <FontAwesomeIcon icon='table' size="lg" className="hand-pointer ml-3" title="Ver detalle" onClick={() => toggleDetalleEncuestaModal(fila?.IdFichaSintomatologica)} />
                  </div>
                  <div>
                    <FontAwesomeIcon icon='times' size="lg" className="hand-pointer ml-3" title="Eliminar" onClick={() => eliminarEncuesta(fila?.IdFichaSintomatologica)} />
                  </div>
                </>
              ) : null}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableWebBody;
