import React, { useEffect, useContext, useState } from 'react';

import { AuthContext } from '../../hooks/context/authContext';

import Wrapper from '../../components/atoms/DirectorioWrapper';
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import PageHeader from "../../components/pageHeader/PageHeader";
import FormSelect from "../../components/atoms/Select";
import Paginator from "../directorio/Paginator";
import TableWebBodyMiEquipo from "./TableWebBodyMiEquipo";
import TableMobileBodyMiEquipo from "./TableMobileBodyMiEquipo";
import RegistrarCeseModal from "../Ceses/modales/RegistrarCeseModal";
import { updateOptionsDeFiltro } from "../../helper/Listas/ListasUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { getAllAreasAPI, getAllCargosAPI, getAllDepartamentosAPI, listarDirectorioMiEquipoAPI, descargarReporteDirectorioMiEquipoAPI } from "../../consumers/backendApisUrls";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { Spinner } from "react-bootstrap";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';

const MiEquipo = () => {
    const { state } = useContext(AuthContext);

    const [hideRegistrarCeseModal, setHideRegistrarCeseModal] = useState(true);
    const [loading, setLoading] = useState(true);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState([]);
    const [areasSeleccionadas, setAreasSeleccionadas] = useState([]);
    const [cargosSeleccionados, setCargosSeleccionados] = useState([]);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [allDepartamentosFiltradosOptions, setAllDepartamentosFiltradosOptions] = useState([]);
    const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
    const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);

    const [departamentosFiltradosOptions, setDepartamentosFiltradosOptions] = useState([]);
    const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
    const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);

    const [cleanFilterss, setCleanFilters] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();
    const [puededescargar, setPuedeDescargar] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, departamentosSeleccionados, areasSeleccionadas, cargosSeleccionados]);

    useEffect(() => {
        manageSearch();
    }, [departamentosSeleccionados, areasSeleccionadas, cargosSeleccionados, page]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllDepartamentosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("departamentosSeleccionados: ", departamentosSeleccionados);
        console.debug("areasSeleccionadas: ", areasSeleccionadas);
        console.debug("cargosSeleccionados: ", cargosSeleccionados);
        console.debug("page: ", page);
        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = obtenerPayload();
        await executeSearch(payload);
    };

    const obtenerPayload = () => {
        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdsDepartamentos": departamentosSeleccionados,
            "IdsAreas": areasSeleccionadas,
            "IdsCargos": cargosSeleccionados,
            "Page": page
        };

        return payload;
    }

    const updateOptionsDeFiltros = async function (lists) {
        await updateOptionsDeFiltro(allDepartamentosFiltradosOptions, lists["IdsDepartamentos"], setDepartamentosFiltradosOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
        await updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
        await updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
    };

    const executeSearch = async (payload) => {
        const responseListarDirectorio = await executePostRequest(listarDirectorioMiEquipoAPI(), payload);
        if (responseCode200(responseListarDirectorio)) {
            setUsuariosFiltrados(responseListarDirectorio.data.data.Items);
            setTotalItems(responseListarDirectorio.data.data.TotalItems);
            setFirsItemPage(responseListarDirectorio.data.data.FirsItemPage);
            setLastItemPage(responseListarDirectorio.data.data.LastItemPage);
            setTotalPages(responseListarDirectorio.data.data.TotalPages);
            setPuedeDescargar(responseListarDirectorio.data.data.ExtraData.PuedeDescarga);
            await updateOptionsDeFiltros(responseListarDirectorio.data.data.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(responseListarDirectorio);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setDepartamentosSeleccionados([]);
        setAreasSeleccionadas([]);
        setCargosSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
    };

    const showRegistrarCeseModal = async () => {
        let currentState = hideRegistrarCeseModal;
        setHideRegistrarCeseModal(!currentState);
        await getInitialData();
    };

    const descargar = async ()=> {
        let payload = obtenerPayload();
        await generarDescargarEliminarArchivo(descargarReporteDirectorioMiEquipoAPI(), payload);
    }

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
    );
    return (
        <>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideRegistrarCeseModal}>
                {hideRegistrarCeseModal === false &&
                    <RegistrarCeseModal showModal={showRegistrarCeseModal} />
                }
            </div>
            <Wrapper className='App'>
                <PageHeader title={"Mi Equipo"} />
                <div className='container-fluid body-content'>
                    <div className="d-flex my-2">
                        <div className="generic-buscador-container">
                            <div className='filtros-left-section'>
                                <input
                                    type='text'
                                    className='generic-input-criterio-busqueda'
                                    placeholder='Buscar por nombre y/o apellidos'
                                    value={criterioDeBusqueda}
                                    onChange={(e) => {
                                        setCriterioDeBusqueda(e.target.value);
                                    }}
                                    onKeyPress={handleKeypress}
                                />
                                <button
                                    onClick={manageSearch}
                                    type='button'
                                    className='generic-button-buscador'>
                                    Buscar
                                </button>
                                <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                            </div>
                            <div className="filtros-right-section">
                                {   
                                    puededescargar ? (
                                    <button
                                        onClick={descargar}
                                        type='button'
                                        className='generic-button-buscador'>
                                        Descargar
                                    </button>
                                    ):null
                                }
                            </div>
                        </div>
                    </div>

                    <div className='pt-1'>
                        <div className="table-scroll">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                            <tr>
                                <th>
                                    <div className='flex font-gothan-medium'>Nombre Completo</div>
                                </th>
                                <th>
                                    <FormSelect
                                        options={departamentosFiltradosOptions}
                                        description={'Departamento'}
                                        setSelectedOptions={setDepartamentosSeleccionados}
                                        selectedOptions={departamentosSeleccionados}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        options={areasFiltradasOptions}
                                        description={'Área'}
                                        setSelectedOptions={setAreasSeleccionadas}
                                        selectedOptions={areasSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        options={cargosFiltradasOptions}
                                        description={'Cargo'}
                                        setSelectedOptions={setCargosSeleccionados}
                                        selectedOptions={cargosSeleccionados}
                                    />
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Cumpleaños</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Acciones</div>
                                </th>
                            </tr>
                            </thead>
                            <TableWebBodyMiEquipo users={usuariosFiltrados} />
                        </table>
                        </div>

                        <div className='header-table-mob'>
                            <div className='title-body'>Filtros</div>
                            <FormSelect
                                options={departamentosFiltradosOptions}
                                description={'Selecciona Departamento'}
                                setSelectedOptions={setDepartamentosSeleccionados}
                                selectedOptions={departamentosSeleccionados}
                            />
                            <FormSelect
                                options={areasFiltradasOptions}
                                description={'Seleccion Área'}
                                setSelectedOptions={setAreasSeleccionadas}
                                selectedOptions={areasSeleccionadas}
                            />
                            <FormSelect
                                options={cargosFiltradasOptions}
                                description={'Selecciona Cargo'}
                                setSelectedOptions={setCargosSeleccionados}
                                selectedOptions={cargosSeleccionados}
                            />
                        </div>
                        <TableMobileBodyMiEquipo users={usuariosFiltrados} />

                        {totalPages > 1 && (
                            <Paginator totalItems={totalItems}
                                firsItemPage={firsItemPage}
                                lastItemPage={lastItemPage}
                                totalPages={totalPages}
                                page={page}
                                onLeftClic={onLeftClic}
                                onRightClic={onRightClic} />
                        )}
                    </div>
                </div>
            </Wrapper>
        </>
    );
};

export default MiEquipo;
