import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const GenericModal = ({showModal, children, width, titulo}) => {
    const closeModal = () => {
        showModal();
    };

    return (
        <>
            <div className="ficha-modal-solicitar-cambios custom-scroll" style={{'width': `${width}`}}>
                <div className="modalHeader">
                    <div className="title">
                        <p className="font-gothan-bold">{titulo}</p>
                    </div>
                    <div className="icon">
                        <FontAwesomeIcon icon='times-circle' className="hand-pointer" transform="left-9" onClick={() => closeModal()}/>
                    </div>
                </div>
                <div className="modalContent">
                    {children}
                </div>
            </div>
        </>
    )

};

export default GenericModal;