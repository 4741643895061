import React, { useContext, useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { aprobarCartaCompromisoVacunacionAPI, getValidarCartaCompromisoVacunacionAPI, rechazarCartaCompromisoVacunacionAPI } from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { isEmpty } from '../../../helper/utils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { updateValidationMessage } from '../../../validations/FormValidatorUtils';

const ValidarCartaCompromisoVacunacionFirmadaModal = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {

    const APROBAR = "true";
    const OBSERVAR = "false";
    const [detalleActividad, setDetalleActividad] = useState({});
    const [executeGetRequest] = useGetRequest();
    const { state } = useContext(AuthContext);
    const sectionName = "CartaCompromisoVacunacion";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        if (IdEmpleadoFlujoActividad) {
            const response = await executeGetRequest(getValidarCartaCompromisoVacunacionAPI(IdEmpleadoFlujoActividad));
            if (responseCode200(response)) {
                setDetalleActividad(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const downloadCartaCompromisoVacunacion = (event) => {
        event.preventDefault();
        downloadFile(state.token, detalleActividad.Archivo.Path, detalleActividad.Archivo.OriginalFileName);
    }

    const submitEnviarCartaCompromisoVacunacion = async (event) => {
        event.preventDefault();
        let fomularioValido = formularioEsValido();
        let validacionInputValue = formValues["Validacion"];
        if (fomularioValido === true) {
            if (validacionInputValue === APROBAR) {
                aprobarActividad(IdEmpleadoFlujoActividad);
            } else if (validacionInputValue === OBSERVAR) {
                rechazarActividad(IdEmpleadoFlujoActividad, formValues?.MotivoRechazo);
            }
        }
    };

    const formularioEsValido = function () {
        let nroCamposInvalidos = 0;
        nroCamposInvalidos = validateMandatoryField("Validacion", formValues?.Validacion, nroCamposInvalidos);
        if (formValues?.Validacion === "false") {
            nroCamposInvalidos = validateMandatoryField("MotivoRechazo", formValues?.MotivoRechazo, nroCamposInvalidos);
        }
        return nroCamposInvalidos === 0;
    };

    const validateMandatoryField = function (keyField, inputValue, nroCamposInvalidos) {
        if (isEmpty(inputValue)) {
            updateValidationMessage(keyField, "Este campo es obligatorio", sectionName);
            nroCamposInvalidos++;
        } else {
            updateValidationMessage(keyField, "", sectionName);
        }
        return nroCamposInvalidos;
    }

    const aprobarActividad = async function () {
        let payload = {
            IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad
        }
        let api = aprobarCartaCompromisoVacunacionAPI();
        let keys = [];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const rechazarActividad = async function (IdEmpleadoFlujoActividad, MotivoRechazo) {
        let payload = {
            IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
            MotivoRechazo: MotivoRechazo
        };
        let api = rechazarCartaCompromisoVacunacionAPI();
        let keys = [];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const successSubmitCallback = function () {
        manageSearch();
        showModal();
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"700px"} titulo={"Firma de compromiso de vacunación"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Colaborador:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreEmpleado}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreSede}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Actividad:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreActividad}</label>
                        </div>
                        <div className="form-group row mt-5">
                            <label className="col-sm-4">Validación:<span className="required">*</span></label>
                            <div className="col-sm-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="Validacion" value="true" checked={formValues.Validacion == "true"} onChange={handleChange}></input>
                                    <label className="form-check-label modal-ceses-label-radio-button">
                                        Aprobar
                                    </label>
                                </div>
                                <div className="form-check col-form-label modal-label">
                                    <input className="form-check-input" type="radio" name="Validacion" value="false" checked={formValues.Validacion == "false"} onChange={handleChange}></input>
                                    <label className="form-check-label modal-ceses-label-radio-button">
                                        Observar
                                    </label>
                                </div>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Validacion-validation-message`}></p>
                            </div>
                            <div className="col-sm-4">
                                <button
                                    type='button'
                                    className='btn btn-primary ficha-form-button-actualizar mr-4'
                                    onClick={(e) => downloadCartaCompromisoVacunacion(e)}>
                                    Descargar documento
                                </button>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-12 col-form-label text-justify">
                                Mensaje
                            </label>
                            <div className="col-sm-12 black-color">
                                <textarea className="form-control" rows="3" name="MotivoRechazo" onChange={handleChange} value={formValues?.MotivoRechazo}></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-MotivoRechazo-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row d-flex justify-content-end">
                            <div className="col-sm-2 button">
                                <button className="btn btn-primary generic-button-ficha" onClick={(event) => submitEnviarCartaCompromisoVacunacion(event)}>Enviar</button>
                            </div>
                        </div>

                    </form>
                </div>
            </GenericModal>
        </>
    );

}

export default ValidarCartaCompromisoVacunacionFirmadaModal;