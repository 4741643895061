import React, {useState, useEffect} from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { getAllTiposContratoAPI, confirmarIngresoActivityAPI, getConfirmarIngresoActivityAPI } from "../../../consumers/backendApisUrls";
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import SelectInput from "../../../components/Select/SelectInput";

const ConfirmarIngresoColaboradorNuevo = ({showModal, IdEmpleadoFlujoActividad, datoItem, manageSearch}) => {

  const SECTION_NAME = "ConfirmarIngresoColaboradorNuevo";
  const KEYS = ["FechaIngresoEmpresa","FechaIngresoReal","IdTipoContrato","FechaInicioContrato","FechaFinContrato","EnviarCorreoBienvenida"];

  const [formValues, setFormValues] = useState({EnviarCorreoBienvenida: false});
  const [allContratos, setAllContratos] = useState([]);
  const [contratoSelected, setContratoSelected] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleChange] = useForm(formValues, setFormValues);

  const [executeGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();

  const init = async () => {
    try {
        await getDropdowns();
        await getInitData();
    } catch (error) {
        console.error(error)
    }
  };

  const getDropdowns = async () => {
    let api = getAllTiposContratoAPI();
    setAllContratos(await getOptions(api));
  }

  const getInitData = async () => {
    let api = getConfirmarIngresoActivityAPI(IdEmpleadoFlujoActividad);
    await executeGet(api, successInitDataCallback)
  }

  const successInitDataCallback = (res) => {
    let data = res.data;
    setFormValues(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = confirmarIngresoActivityAPI();
      let payload = getPayload();
      await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback, true);

    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const getPayload = () => {
    return {
      ...formValues,
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      IdTipoContrato: contratoSelected.value
    };
  };

  const successSubmitCallback = () => {
      showModal();
      manageSearch();
  };

  useEffect(() => {
    if(formValues.IdTipoContrato){
      let contratoSelected = allContratos.filter(o => o.value == formValues.IdTipoContrato)[0];
      setContratoSelected(contratoSelected);
    }
  }, [formValues.IdTipoContrato])

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) init();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <GenericModal
      showModal={showModal}
      width={"600px"}
      titulo="Confirmar ingreso colaborador"
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Colaborador
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {datoItem?.nombreEmpleado}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Sede
            </label>
            <label className="col-sm-7 col-form-label black-color">
              {datoItem?.Sede}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Fecha Ingreso Empresa
            </label>
            <div className="col-sm-7 px-0">
              <input type="date" className="form-control" name="FechaIngresoEmpresa" value={formValues?.FechaIngresoEmpresa ?? ""} onChange={handleChange}/>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaIngresoEmpresa-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Fecha Ingreso real
            </label>
            <div className="col-sm-7 px-0">
              <input type="date" className="form-control" name="FechaIngresoReal" value={formValues?.FechaIngresoReal ?? ""} onChange={handleChange}/>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaIngresoReal-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Tipo de Contrato
            </label>
            <div className="col-sm-7 px-0">
              <SelectInput
                options={allContratos}
                jsonSelectedValue={contratoSelected}
                setJsonSelectedValue={setContratoSelected}
                placeholder="Seleccionar tipo de contrato"
              />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdTipoContrato-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
              Fecha Inicio Contrato
            </label>
            <div className="col-sm-7 px-0">
              <input type="date" className="form-control" name="FechaInicioContrato" value={formValues?.FechaInicioContrato ?? ""} onChange={handleChange}/>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaInicioContrato-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-5 col-form-label modal-label">
                Fecha Fin Contrato
            </label>
            <div className="col-sm-7 px-0">
              <input type="date" className="form-control" name="FechaFinContrato" value={formValues?.FechaFinContrato ?? ""} onChange={handleChange}/>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaFinContrato-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row mb-4">
            <label className="col-sm-5 col-form-label modal-label">
              Enviar Correo de Bienvenida
            </label>
            <div className="col-sm-7 d-flex flex-column justify-content-center align-items-start" style={{marginTop: "10px"}}>
              <input
                type="checkbox"
                id="EnviarCorreoBienvenida"
                name="EnviarCorreoBienvenida"
                className="ingreso-input-checkbox"
                onChange={handleChange}
                checked={formValues?.EnviarCorreoBienvenida}
              />  
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-EnviarCorreoBienvenida-validation-message`}></p>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Completar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  )
}

export default ConfirmarIngresoColaboradorNuevo