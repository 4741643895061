export const estados = {
    'Null': {'id': 0, 'nombre': 'Null'},
    'Activo': {'id': 1, 'nombre': 'Activo'},
    'Inactivo': {'id': 2, 'nombre': 'Inactivo'},
    'Creado': {'id': 3, 'nombre': 'Creado'},
    'Ok': {'id': 4, 'nombre': 'Ok'},
    'Cesado': {'id': 5, 'nombre': 'Cesado'},
    'Actualizado': {'id': 6, 'nombre': 'Actualizado'},
    'Cerrado': {'id': 7, 'nombre': 'Cerrado'},
    'Validado': {'id': 8, 'nombre': 'Validado'},
    'Completada': {'id': 9, 'nombre': 'Completada'},
    'Pendiente': {'id': 10, 'nombre': 'Pendiente'},
    'Vencido': {'id': 11, 'nombre': 'Vencido'},
    'Anulado': {'id': 12, 'nombre': 'Anulado'},
    'Observado': {'id': 13, 'nombre': 'Observado'},
    'Aprobado': {'id': 14, 'nombre': 'Aprobado'},
    'Rechazado': {'id': 15, 'nombre': 'Rechazado'},
    'Proceso': {'id': 16, 'nombre': 'Proceso'},
    'Error': {'id': 17, 'nombre': 'Error'},
    'Enviado': {'id': 18, 'nombre': 'Enviado'},
    'Finalizado': {'id': 19, 'nombre': 'Finalizado'},
    'SinVacuna': {'id': 20, 'nombre': 'SinVacuna'},
    'PrimeraDosis': {'id': 21, 'nombre': 'PrimeraDosis'},
    'SinRegistro': {'id': 22, 'nombre': 'SinRegistro'},
    'Abierto': {'id': 23, 'nombre': 'Abierto'},
    'SegundaDosis': {'id': 24, 'nombre': 'Segunda dosis'},
    'Cancelado': {'id': 25, 'nombre': 'Cancelado'},
    'EnEspera': {'id': 26, 'nombre': 'En espera'},
    'Yes': {'id': 27, 'nombre': 'Yes'},
    'No': {'id': 28, 'nombre': 'No'},
    'NoAplica': {'id': 29, 'nombre': 'No aplica'},
    'Registrado': {'id': 30, 'nombre': 'Registrado'},
    'Conforme': {'id': 31, 'nombre': 'Conforme'},
    'NoConforme': {'id': 32, 'nombre': 'No conforme'},
    'EnRevision': {'id': 33, 'nombre': 'En revision'},
    'ConformePresencialidad': {'id': 34, 'nombre': 'Conforme presencialidad'},
    'EnValidacion': {'id': 35, 'nombre': 'En validacion'},
    'SiPuedeRealizarLaboresPresenciales': {'id': 36, 'nombre': 'Sí puede eealizar labores presenciales'},
    'Desaprobado': {'id': 37, 'nombre': 'Desaprobado'},
    'Programado': {'id': 38, 'nombre': 'Programado'},
    'Resultado': {'id': 39, 'nombre': 'Resultado'},
}

export const estadoFormacion = {
    'Null': {'id': 0, 'nombre': 'Null'},
    'EnCurso': {'nombre': 'En curso'},
    'NoCulminado': {'nombre': 'No culminado'},
    'Egresado': {'nombre': 'Egresado, únicamente cuento con constancia de egresado'},
    'Graduado': {'nombre': 'Graduado, soy Bachiller, Titulado o Licenciado'},
    'Finalizado': {'nombre': 'Finalizado'},
}
