import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditSchedule from './modales/EditSchedule';
import ActivarInactivarSchedule from './modales/ActivarInactivarSchedule';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import CleanSchedule from './modales/CleanSchedule';

const TableWebBody = ({ filas, setModalEnable }) => {

  const handleModal = (component, id, title = null, enable = null) => {
    setModalEnable({isEnable: true, component, id, title, enable});
  }

  return (
    <tbody className="listar-tbody">
    {filas.map((fila, i) => (
        <tr key={i}>
            <td className='text-center'>{fila.IdCommand}</td>
            <td className='pl-4'>{fila.ClassName}</td>
            <td className='pl-5'>{fila.Frecuency}</td>
            <td className='text-center'>{fila.Day ?? "-"}</td>
            <td className='text-center'>{fila.Hour ?? "-"}</td>
            <td className='text-center'>{fila.DayOfweek}</td>
            <td className='text-center'>{fila.Enable ? "Habilitado" : "Deshabilitado"}</td>
            <td className='text-center'>{fila.IsExecuting ? "Ejecutando" : "-"}</td>
            <td className='text-center'>{fila.LastRunTime}</td>
            <td className='text-center'>{fila.FechaFin}</td>
            <td className='row justify-content-start'>
              <FontAwesomeIcon icon='edit' className="hand-pointer blue-standard-color mr-3" title={"Editar"} onClick={() => handleModal(EditSchedule, fila.IdCommand)}/>
              <FontAwesomeIcon icon={fila.Enable ? 'ban' : 'circle-notch'} className="hand-pointer blue-standard-color mr-3" title={fila.Enable ? 'Inactivar' : 'Activar'} onClick={() => handleModal(ActivarInactivarSchedule, fila.IdCommand, fila.ClassName, fila.Enable)}/>
              {fila.IsExecuting &&
                <FontAwesomeIcon icon={faHourglassHalf} className="hand-pointer blue-standard-color" title="Limpiar ejecución" onClick={() => handleModal(CleanSchedule, fila.IdCommand, fila.ClassName)}/>
              }
            </td>
        </tr>
    ))}
    </tbody>
  )
}

export default TableWebBody