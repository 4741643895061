import React from 'react';

const Arrow = () => {
    return (
        <svg
            width='12'
            height='9'
            viewBox='0 0 9 6'
            fill='none'
            >
            <path d='M8 1L4.5 5L1 1' stroke='#CCCCCC' strokeLinecap='round'/>
        </svg>
    );
};

export default Arrow;
