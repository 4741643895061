import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarPreguntaRespuesta from './modales/EditarPreguntaRespuesta';
import { CHATBOT_FAQ_TYPES } from '../../../constants/hardCodedConstants';
import PageTable from '../../../components/pageComponents/PageTable';
import ActionsIcons from '../../../components/atoms/ActionsIcons';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import ModalActivarInactivarGeneric from '../../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { activarInactivarChatbotFaqAPI, chatbotFaqAdminAPI, eliminarChatbotFaqAdminAPI } from '../../../consumers/backendApisUrls';

const TableChatbot = ({ formValues, setFormValues, setModalEnabled, paginator, setPaginator, keys: KEYS }) => {
  const rows = formValues.Items;

  const HEADERS = [
    {col: "col-sm-1", label: "Id"},
    {col: "col-sm-2", label: "Fecha Registro"},
    {col: "col-sm-1", label: "Tipo"},
    {col: "col-sm-4", label: "Texto"},
    {col: "col-sm-3", label: "Url"},
    {col: "col-sm-1", label: "Estado"},
    {col: "", label: "Acciones"},
  ]

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues(lastData => ({...lastData, selectedFilters: updatedSelectedFilters}));
  }

  return (
    <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
      <tbody className='listar-tbody'>
      {rows.map((row, i) => (
        <Childs key={row.IdChatbotFaq} row={row} setModalEnabled={setModalEnabled} />
      ))}
      </tbody>
    </PageTable>
  )
}

export default TableChatbot;

const PreguntaSinRespuestaFinalToolTip = (row) => (
  ((!row.TieneHijos && row.IdType !== CHATBOT_FAQ_TYPES.respuesta && row.EsActivo) && 
    <>
      <p data-tip data-for={`PreguntaSinRespuestaFinal${row.IdChatbotFaq}`} className="d-inline mb-0" style={{color: "#b3a11a"}}>
        <FontAwesomeIcon icon='exclamation-triangle' />
      </p>
      <ReactTooltip id={`PreguntaSinRespuestaFinal${row.IdChatbotFaq}`} place="top" effect="solid" className="tooltip-style">
        Esta pregunta no tiene hijos, no se mostrará en el chatbot
      </ReactTooltip>
    </>  
  )
);

const Childs = ({ row, setModalEnabled, marginLeft = 5, handleGetChildsParent = ()=>{} }) => {

  const messageEmptyChild = "la opción no cuenta con hijos";
  const marginLeftupdated = marginLeft + 10;
  const [isActive, setIsActive] = useState(row.EsActivo);
  const [isExpanded, setIsExpanded] = useState(false);
  const [childs, setChilds] = useState([]);
  const [executePost] = useManagePostRequest();

  const handleClick = async () => {
    if (isRespuesta()) return;
    if (!childs.length){
      const newChilds = await handleGetChilds(row.IdChatbotFaq);
      if (!newChilds.length) {
        toast.warn(messageEmptyChild);
        return setIsExpanded(false);
      }
    }
    setIsExpanded(prev => !prev);
  }

  const handleGetChilds = async (IdParent) => {
    const api = chatbotFaqAdminAPI();
    const payload = { IdParent };
    let getChilds = [];
    await executePost(api, payload, ({ data }) => { setChilds(data); getChilds = data }, false);
    return getChilds;
  }

  const handleActivarInactivar = (e) => {
    e.stopPropagation();
    setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
      data: {
        api: activarInactivarChatbotFaqAPI,
        payload: { IdChatbotFaq: row.IdChatbotFaq },
        name: `${row.TypeText} - ${row.Text}`,
        state: row.EsActivo,
        externalCallback: () => {
          handleGetChildsParent(row.IdParent);
        }
      }
    })
  }

  const handleEditar = (e) => {
    e.stopPropagation();
    setModalEnabled({ isEnable: true, component: EditarPreguntaRespuesta,
      data: {
        row,
        externalCallback: () => {
          handleGetChildsParent(row.IdParent);
        }
      }
    })
  }

  const handleEliminar = (e) => {
    e.stopPropagation();
    setModalEnabled({ isEnable: true, component: ActionGenericModal,
      data: {
        api: eliminarChatbotFaqAdminAPI,
        id: row.IdChatbotFaq,
        name: `${row.TypeText} - ${row.Text}`,
        externalCallback: () => {
          handleGetChildsParent(row.IdParent);
        }
      }
    })
  }

  const isFichaIncompleta = () => {
    return !row.TieneHijos && row.IdType !== CHATBOT_FAQ_TYPES.respuesta && row.EsActivo;
  }

  const isPregunta = () => {
    return row.IdType === CHATBOT_FAQ_TYPES.pregunta
  }

  const isRespuesta = () => {
    return row.IdType === CHATBOT_FAQ_TYPES.respuesta
  }

  useDidMountEffect(() => {
    if (childs.length && isActive !== row.EsActivo){
      handleGetChilds(row.IdChatbotFaq);
    }
    setIsActive(row.EsActivo)
  }, [row])

  return (
  <>
    <tr className={isFichaIncompleta() ? "ficha-falta-completar" : ""} onClick={() => handleClick()}>
      <td className='text-center'>
        <DivArrow isRespuesta={isRespuesta()} isExpanded={isExpanded} marginLeft={marginLeft}>
          {isPregunta() && 
            <FontAwesomeIcon
              icon="angle-right"
              title='Ver hijos'
              size='2x'
            />
          }
          {row.IdChatbotFaq}
        </DivArrow>
      </td>
      <td className='text-center'>{row.FechaRegistro}</td>
      <td className='text-center'>{row.TypeText}</td>
      <td className=''>{row.Text}</td>
      <td className=''>{row.Url ?? "-"}</td>
      <td className='text-center'>{row.EsActivo ? "Activo" : "Inactivo"}</td>
      <td className=''>
        <ActionsIcons>
          {PreguntaSinRespuestaFinalToolTip(row)}
          {row.PuedeActivarInactivar &&
            <FontAwesomeIcon
              icon={row.EsActivo ? "ban" : "circle-notch"}
              title={row.EsActivo ? "Inactivar" : "Activar"}
              size='lg'
              onClick={handleActivarInactivar}
            />
          }
          {(row.EsActivo && row.PuedeEditar) &&
            <FontAwesomeIcon
              icon='edit'
              title='Editar'
              size='lg'
              onClick={handleEditar}
            />
          }
          {/* {row.PuedeEliminar &&
            <FontAwesomeIcon
              icon='trash'
              title='Eliminar'
              size='lg'
              onClick={handleEliminar}
            />
          } */}
        </ActionsIcons>
      </td>
    </tr>
    {(childs.length > 0 && isExpanded) && 
      childs.map(row => (
        <Childs key={row.IdChatbotFaq} row={row} setModalEnabled={setModalEnabled} marginLeft={marginLeftupdated} handleGetChildsParent={handleGetChilds}/>
      ))
    }
  </>
  )
}

const DivArrow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: ${({ isRespuesta, marginLeft }) => isRespuesta ? (`${marginLeft*2}px`) : (`${marginLeft}px`)};
  & svg {
    color: #3a87c8;
    transition: all 0.4s cubic-bezier(.4,1.88,.7,1);
    transform: rotate(${({ isExpanded }) => isExpanded ? "90deg" : "0"});
  }
`