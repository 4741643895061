import React, {useEffect, useContext, useState} from 'react';

import {AuthContext} from '../../hooks/context/authContext';

import TableWebBody from './TableWebBody';
import TableWebBodyGrupo from './TableWebBodyGrupo';
import TableWebBodySede from './TableWebBodySede';
import FormSelect from '../../components/atoms/Select';
import FormSelectSearch from '../../components/atoms/SelectSearch';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import TableMobileBody from "./TableMobileBody";
import TableMobileBodyGrupo from "./TableMobileBodyGrupo";
import TableMobileBodySede from "./TableMobileBodySede";
import PageHeader from "../../components/pageHeader/PageHeader";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import Paginator from "./Paginator";
import PaginatorGrupo from "./PaginatorGrupo";
import PaginatorSede from "./PaginatorSede";
import {updateOptionsDeFiltro} from "../../helper/Listas/ListasUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getAllAreasAPI, getAllCargosAPI, getAllDepartamentosAPI, getAllDepartamentosRegionesAPI, getAllNivelesAPI, getAllSedesAPI, getEspecialidadesAPI, listarDirectorioAPI, listarDatosGrupoAPI, listarSedesAPI, descargarReporteDirectorioAPI, descargarReporteDirectorioGrupoAPI, descargarReporteDirectorioSedeAPI, getDivisionesAPI, getPermisoDirectorioPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Spinner} from "react-bootstrap";
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import DetalleSedeModal from './modales/DetalleSedeModal';
import SelectCheckboxInput from '../../components/Select/SelectCheckboxInput';

const Directorio = () => {
    const {state} = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]);
    const [usuariosFiltradosGrupo, setUsuariosFiltradosGrupo] = useState([]);
    const [usuariosFiltradosSede, setUsuariosFiltradosSede] = useState([]);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [criterioDeBusquedaGrupo, setCriterioDeBusquedaGrupo] = useState("");
    const [criterioDeBusquedaSede, setCriterioDeBusquedaSede] = useState("");

    const [unidadSeleccionados, setUnidadSeleccionados] = useState([])
    const [departamentosSeleccionados, setDepartamentosSeleccionados] = useState([]);
    const [departamentosSeleccionadosSede, setDepartamentosSeleccionadosSede] = useState([]);
    const [areasSeleccionadas, setAreasSeleccionadas] = useState([]);
    const [sedesSeleccionadas, setSedesSeleccionadas] = useState([]);
    const [nivelesSeleccionados, setNivelesSeleccionados] = useState([]);
    const [especialidadesSeleccionadas, setEspecialidadesSeleccionadas] = useState([]);
    const [cargosSeleccionados, setCargosSeleccionados] = useState([]);

    const [page, setPage] = useState(1);
    const [pageGrupo, setPageGrupo] = useState(1);
    const [pageSede, setPageSede] = useState(1);

    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [totalItemsGrupo, setTotalItemsGrupo] = useState();
    const [firsItemPageGrupo, setFirsItemPageGrupo] = useState();
    const [lastItemPageGrupo, setLastItemPageGrupo] = useState();
    const [totalPagesGrupo, setTotalPagesGrupo] = useState();

    const [totalItemsSede, setTotalItemsSede] = useState();
    const [firsItemPageSede, setFirsItemPageSede] = useState();
    const [lastItemPageSede, setLastItemPageSede] = useState();
    const [totalPagesSede, setTotalPagesSede] = useState();

    const [unidadFiltradosOptions, setUnidadFiltradosOptions] = useState([]);
    const [departamentosFiltradosOptions, setDepartamentosFiltradosOptions] = useState([]);
    const [departamentosFiltradosOptionsSede, setDepartamentosFiltradosOptionsSede] = useState([]);
    const [areasFiltradasOptions, setAreasFiltradasOptions] = useState([]);
    const [sedesFiltradasOptions, setSedesFiltradasOptions] = useState([]);
    const [cargosFiltradasOptions, setCargosFiltradasOptions] = useState([]);
    const [nivelesFiltradosOptions, setNivelesFiltradosOptions] = useState([]);
    const [especialidadesFiltradasOptions, setEspecialidadesFiltradassOptions] = useState([]);

    const [allUnidadFiltradosOptions, setAllUnidadFiltradosOptions] = useState([]);
    const [allDepartamentosFiltradosOptions, setAllDepartamentosFiltradosOptions] = useState([]);
    const [allDepartamentosFiltradosOptionsSede, setAllDepartamentosFiltradosOptionsSede] = useState([]);
    const [allAreasFiltradasOptions, setAllAreasFiltradasOptions] = useState([]);
    const [allSedesFiltradasOptions, setAllSedesFiltradasOptions] = useState([]);
    const [allCargosFiltradasOptions, setAllCargosFiltradasOptions] = useState([]);

    const [cleanFilterss, setCleanFilters] = useState(false);
    const [cleanFilterssGrupo, setCleanFiltersGrupo] = useState(false);
    const [cleanFilterssSede, setCleanFiltersSede] = useState(false);

    const [renderizarNivelYEspecialidad] = useState(false);

    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const [puededescargar, setPuedeDescargar] = useState(false);
    const [puededescargarSede, setPuedeDescargarSede] = useState(false);
    const [puededescargarGrupo, setPuedeDescargarGrupo] = useState(false);
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

    const [hideDetalleSedeModal, setHideDetalleSedeModal] = useState(true);
    const [userSelected, setUserSelected] = useState({});

    const [puedeverDatosGenerales, setPuedeverDatosGenerales] = useState(false);
    const [puedeverDatosSedes, setPuedeverDatosSedes] = useState(false);
    const [puedeverDatosGrupos, setPuedeverDatosGrupos] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, unidadSeleccionados,  departamentosSeleccionados, areasSeleccionadas, sedesSeleccionadas
        , nivelesSeleccionados, especialidadesSeleccionadas, cargosSeleccionados]);

    useDidMountEffect(() => {
            setPageGrupo(1);
        }, [criterioDeBusquedaGrupo]);        

    useDidMountEffect(() => {
            setPageSede(1);
        }, [criterioDeBusquedaSede, departamentosSeleccionadosSede])
            
    useEffect(() => {
        manageSearch();
    }, [unidadSeleccionados, departamentosSeleccionados, areasSeleccionadas, sedesSeleccionadas, cargosSeleccionados
        , nivelesSeleccionados, especialidadesSeleccionadas, page]);

    useEffect(() => {
        manageSearchGrupo();
    }, [criterioDeBusquedaGrupo, pageGrupo]);

    useEffect(() => {
        manageSearchSede();
    }, [departamentosSeleccionadosSede, pageSede]);
   
    const getInitialData = async () => {
        setLoading(true);
        try {
            await obtenerPermisoDirectorio();

            await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setAllUnidadFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setAllDepartamentosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosRegionesAPI()), setAllDepartamentosFiltradosOptionsSede);
            await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAllAreasFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setAllSedesFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargosFiltradasOptions);

            await getDropdownOptions(executeGetRequestCallback(getDivisionesAPI()), setUnidadFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosAPI()), setDepartamentosFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllDepartamentosRegionesAPI()), setDepartamentosFiltradosOptionsSede);
            await getDropdownOptions(executeGetRequestCallback(getAllAreasAPI()), setAreasFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllSedesAPI()), setSedesFiltradasOptions);
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setCargosFiltradasOptions);

            await getDropdownOptions(executeGetRequestCallback(getAllNivelesAPI()), setNivelesFiltradosOptions);
            await getDropdownOptions(executeGetRequestCallback(getEspecialidadesAPI()), setEspecialidadesFiltradassOptions);
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const handleKeypressGrupo = (e) => {
        if (e.key === 'Enter') {
            manageSearchGrupo();
        }
    };

    const handleKeypressSede = (e) => {
        if (e.key === 'Enter') {
            manageSearchSede();
        }
    };

    const obtenerPayload = () => {
        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdsUnidades": unidadSeleccionados,
            "IdsDepartamentos": departamentosSeleccionados,
            "IdsAreas": areasSeleccionadas,
            "IdsSedes": sedesSeleccionadas,
            "IdsNiveles": nivelesSeleccionados,
            "IdsEspecialidades": especialidadesSeleccionadas,
            "IdsCargos": cargosSeleccionados,
            "Page": page
        };

        return payload;
    }

    const obtenerPayloadGrupo = () => {
        let payload = {
            "Criteria": criterioDeBusquedaGrupo,
            "Page": pageGrupo
        };

        return payload;
    }

    const obtenerPayloadSede = () => {
        let payload = {
            "Criteria": criterioDeBusquedaSede,
            "IdsDepartamentos": departamentosSeleccionadosSede,
            "Page": pageSede
        };

        return payload;
    }


    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("unidadSeleccionados: ", unidadSeleccionados);
        console.debug("departamentosSeleccionados: ", departamentosSeleccionados);
        console.debug("areasSeleccionadas: ", areasSeleccionadas);
        console.debug("sedesSeleccionadas: ", sedesSeleccionadas);
        console.debug("nivelesSeleccionados: ", nivelesSeleccionados);
        console.debug("especialidadesSeleccionadas: ", especialidadesSeleccionadas);
        console.debug("cargosSeleccionados: ", cargosSeleccionados);
        console.debug("page: ", page);
        let usersFiltered = usuariosFiltrados;
        setUsuariosFiltrados(usersFiltered);

        let payload = obtenerPayload();
        await executeSearch(payload);
    };

    const manageSearchGrupo = async () => {
        console.debug("manage search grupo");
        console.debug("criterioDeBusquedaGrupo: ", criterioDeBusquedaGrupo);
        console.debug("pageGrupo: ", pageGrupo);
        let usersFiltered = usuariosFiltradosGrupo;
        setUsuariosFiltradosGrupo(usersFiltered);

        let payload = obtenerPayloadGrupo();
        await executeSearchGrupo(payload);
    };

    const manageSearchSede = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusquedaSede);
        console.debug("departamentosSeleccionados: ", departamentosSeleccionadosSede);
        console.debug("page: ", pageSede);
        let usersFiltered = usuariosFiltradosSede;
        setUsuariosFiltradosSede(usersFiltered);

        let payload = obtenerPayloadSede();
        await executeSearchSede(payload);
    };

    const updateOptionsDeFiltros = function (lists) {
        updateOptionsDeFiltro(allUnidadFiltradosOptions, lists["IdsUnidades"], setUnidadFiltradosOptions, executeGetRequestCallback(getDivisionesAPI()));
        updateOptionsDeFiltro(allDepartamentosFiltradosOptions, lists["IdsDepartamentos"], setDepartamentosFiltradosOptions, executeGetRequestCallback(getAllDepartamentosAPI()));
        updateOptionsDeFiltro(allAreasFiltradasOptions, lists["IdsAreas"], setAreasFiltradasOptions, executeGetRequestCallback(getAllAreasAPI()));
        updateOptionsDeFiltro(allSedesFiltradasOptions, lists["IdsSedes"], setSedesFiltradasOptions, executeGetRequestCallback(getAllSedesAPI()));
        updateOptionsDeFiltro(allCargosFiltradasOptions, lists["IdsCargos"], setCargosFiltradasOptions, executeGetRequestCallback(getAllCargosAPI()));
    };

    const updateOptionsDeFiltrosSede = function (lists) {
        updateOptionsDeFiltro(allDepartamentosFiltradosOptionsSede, lists["IdsDepartamentos"], setDepartamentosFiltradosOptionsSede, executeGetRequestCallback(getAllDepartamentosRegionesAPI()));
    };

    const executeSearch = async (payload) => {
        const responseListarDirectorio = await executePostRequest(listarDirectorioAPI(), payload);
        if (responseCode200(responseListarDirectorio)) {
            setUsuariosFiltrados(responseListarDirectorio.data.data.Items);
            setTotalItems(responseListarDirectorio.data.data.TotalItems);
            setFirsItemPage(responseListarDirectorio.data.data.FirsItemPage);
            setLastItemPage(responseListarDirectorio.data.data.LastItemPage);
            setTotalPages(responseListarDirectorio.data.data.TotalPages);
            setPuedeDescargar(responseListarDirectorio.data.data.ExtraData.PuedeDescarga);
            updateOptionsDeFiltros(responseListarDirectorio.data.data.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(responseListarDirectorio);
        }
    };

    const executeSearchGrupo = async (payload) => {
        const responseListarGrupo = await executePostRequest(listarDatosGrupoAPI(), payload);
        if (responseCode200(responseListarGrupo)) {
            setUsuariosFiltradosGrupo(responseListarGrupo.data.data.Items);
            setTotalItemsGrupo(responseListarGrupo.data.data.TotalItems);
            setFirsItemPageGrupo(responseListarGrupo.data.data.FirsItemPage);
            setLastItemPageGrupo(responseListarGrupo.data.data.LastItemPage);
            setTotalPagesGrupo(responseListarGrupo.data.data.TotalPages);
            setPuedeDescargarGrupo(responseListarGrupo.data.data.ExtraData.PuedeDescargaGrupo);
        } else {
            mostrarMensajeDeErrorConToast(responseListarGrupo);
        }
    };

    const executeSearchSede = async (payload) => {
        const responseListarSede = await executePostRequest(listarSedesAPI(), payload);
        if (responseCode200(responseListarSede)) {
            setUsuariosFiltradosSede(responseListarSede.data.data.Items);
            setTotalItemsSede(responseListarSede.data.data.TotalItems);
            setFirsItemPageSede(responseListarSede.data.data.FirsItemPage);
            setLastItemPageSede(responseListarSede.data.data.LastItemPage);
            setTotalPagesSede(responseListarSede.data.data.TotalPages);
            setPuedeDescargarSede(responseListarSede.data.data.ExtraData.PuedeDescargaSede);
            updateOptionsDeFiltrosSede(responseListarSede.data.data.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(responseListarSede);
        }
    };

    const obtenerPermisoDirectorio  = async () => {
        let response = await executeGetRequest(getPermisoDirectorioPI());

        if (responseCode200(response)) {
            setPuedeverDatosGenerales(response.data.PuedeVerDatosGenerales);
            setPuedeverDatosSedes(response.data.PuedeVerDatosSedes);
            setPuedeverDatosGrupos(response.data.PuedeVerDatosGrupos);
        } else {
            setPuedeverDatosGenerales(false);
            setPuedeverDatosSedes(false);
            setPuedeverDatosGrupos(false);
        }
    }

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const onLeftClicGrupo = () => {
        console.debug("onLeftClicGrupo");
        const nextPage = Math.max(pageGrupo - 1, 1);
        setPageGrupo(nextPage);
    };

    const onRightClicGrupo = () => {
        console.debug("onRightClicGrupo");
        const nextPage = Math.min(pageGrupo + 1, totalPagesGrupo);
        setPageGrupo(nextPage);
    };

    const onLeftClicSede = () => {
        console.debug("onLeftClicSede");
        const nextPage = Math.max(pageSede - 1, 1);
        setPageSede(nextPage);
    };

    const onRightClicSede = () => {
        console.debug("onRightClicSede");
        const nextPage = Math.min(pageSede + 1, totalPagesSede);
        setPageSede(nextPage);
    };


    const limpiarBusqueda = () => {
        setUnidadSeleccionados([])
        setDepartamentosSeleccionados([]);
        setAreasSeleccionadas([]);
        setSedesSeleccionadas([]);
        setNivelesSeleccionados([]);
        setEspecialidadesSeleccionadas([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
        setCargosSeleccionados([]);
    };

    const limpiarBusquedaGrupo = () => {
        setCriterioDeBusquedaGrupo("");
        setCleanFiltersGrupo(!cleanFilterssGrupo);
    }

    const limpiarBusquedaSede = () => {
        setDepartamentosSeleccionadosSede([]);
        setCriterioDeBusquedaSede("");
        setCleanFiltersSede(!setCleanFiltersSede);
    };

    const descargar = async ()=> {
        let payload = obtenerPayload();
        await generarDescargarEliminarArchivo(descargarReporteDirectorioAPI(), payload);
    }

    const descargarGrupo = async ()=> {
        let payload = obtenerPayloadGrupo();
        await generarDescargarEliminarArchivo(descargarReporteDirectorioGrupoAPI(), payload);
    }

    const descargarSede = async ()=> {
        let payload = obtenerPayloadSede();
        await generarDescargarEliminarArchivo(descargarReporteDirectorioSedeAPI(), payload);
    }

    const toggleDetalleSedeModal = async (idUser) => {
        setHideDetalleSedeModal(!hideDetalleSedeModal);
        if(idUser) {
          setUserSelected(idUser);
        }
    }

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    
    const TabDatosGenerales = (
        <div style={{'background-color': '#f0f2f5'}}>
            <div className="generic-buscador-container">
                <div className='filtros-left-section'>
                    <input
                        type='text'
                        className='generic-input-criterio-busqueda'
                        placeholder='Buscar por nombre y/o apellidos'
                        value={criterioDeBusqueda}
                        onChange={(e) => {
                            setCriterioDeBusqueda(e.target.value);
                        }}
                        onKeyPress={handleKeypress}
                    />
                    <button
                        onClick={manageSearch}
                        type='button'
                        className='generic-button-buscador'>
                        Buscar
                    </button>
                    <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                    {   
                        puededescargar ? (
                            <button
                                onClick={descargar}
                                type='button'
                                className='generic-button-buscador'>
                                Descargar
                            </button>
                        ):null
                    }
                </div>
            </div>
            <div className='pt-1'>
                <div>
                    <div className="table-scroll d-none d-xl-block">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                                <tr className='bg-cl-gris'>
                                    <th>
                                        <div className='flex font-gothan-medium'>Nombre Completo</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Vacac.</div>
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={unidadFiltradosOptions}
                                            description={'Divisón'}
                                            setSelectedOptions={setUnidadSeleccionados}
                                            selectedOptions={unidadSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={departamentosFiltradosOptions}
                                            description={'Gerencia'}
                                            setSelectedOptions={setDepartamentosSeleccionados}
                                            selectedOptions={departamentosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={areasFiltradasOptions}
                                            description={'Área'}
                                            setSelectedOptions={setAreasSeleccionadas}
                                            selectedOptions={areasSeleccionadas}
                                        />
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Sección</div>
                                    </th>
                                    <th>
                                        <FormSelectSearch
                                            options={cargosFiltradasOptions}
                                            description={'Cargo'}
                                            setSelectedOptions={setCargosSeleccionados}
                                            selectedOptions={cargosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={sedesFiltradasOptions}
                                            description={'Sede'}
                                            setSelectedOptions={setSedesSeleccionadas}
                                            selectedOptions={sedesSeleccionadas}
                                        />
                                    </th>
                                    {renderizarNivelYEspecialidad &&
                                    <>
                                        <th>
                                            <FormSelect
                                                options={nivelesFiltradosOptions}
                                                description={'Nivel'}
                                                setSelectedOptions={setNivelesSeleccionados}
                                                selectedOptions={nivelesSeleccionados}
                                            />
                                        </th>
                                        <th>
                                            <FormSelect
                                                options={especialidadesFiltradasOptions}
                                                description={'Especialidad'}
                                                setSelectedOptions={setEspecialidadesSeleccionadas}
                                                selectedOptions={especialidadesSeleccionadas}
                                            />
                                        </th>
                                    </>
                                    }
                                    <th>
                                        <div className='flex font-gothan-medium'>Acciones</div>
                                    </th>
                                </tr>
                            </thead>
                            <TableWebBody filas={usuariosFiltrados}/>
                        </table>

                        {totalPages > 1 && (
                            <Paginator totalItems={totalItems}
                                    firsItemPage={firsItemPage}
                                    lastItemPage={lastItemPage}
                                    totalPages={totalPages}
                                    page={page}
                                    onLeftClic={onLeftClic}
                                    onRightClic={onRightClic}/>
                        )}

                    </div>

                    <div className='header-table-mob'>
                        <div className='title-body'>Filtros</div>
                        <FormSelect
                            options={departamentosFiltradosOptions}
                            description={'Selecciona Departamento'}
                            setSelectedOptions={setDepartamentosSeleccionados}
                            selectedOptions={departamentosSeleccionados}
                        />
                        <FormSelect
                            options={areasFiltradasOptions}
                            description={'Seleccion Área'}
                            setSelectedOptions={setAreasSeleccionadas}
                            selectedOptions={areasSeleccionadas}
                        />
                        <FormSelect
                            options={cargosFiltradasOptions}
                            description={'Selecciona Cargo'}
                            setSelectedOptions={setCargosSeleccionados}
                            selectedOptions={cargosSeleccionados}
                        />
                        <FormSelect
                            options={sedesFiltradasOptions}
                            description={'Selecciona Sede'}
                            setSelectedOptions={setSedesSeleccionadas}
                            selectedOptions={sedesSeleccionadas}
                        />
                        {renderizarNivelYEspecialidad &&
                        <>
                            <FormSelect
                                options={nivelesFiltradosOptions}
                                description={'Selecciona Nivel'}
                                setSelectedOptions={setNivelesSeleccionados}
                                selectedOptions={nivelesSeleccionados}
                            />
                            <FormSelect
                                options={especialidadesFiltradasOptions}
                                description={'Selecciona Especialidad'}
                                setSelectedOptions={setEspecialidadesSeleccionadas}
                                selectedOptions={especialidadesSeleccionadas}
                            />
                        </>
                        }
                    </div>

                    <TableMobileBody users={usuariosFiltrados}/>

                </div>
            </div>
        </div>
    );

    const TabDatosSede =
    (
        <div style={{'background-color': '#f0f2f5'}}>
            <div className="generic-buscador-container">
                <div className='filtros-left-section'>
                    <input
                        type='text'
                        className='generic-input-criterio-busqueda'
                        placeholder='Buscar por Sede'
                        value={criterioDeBusquedaSede}
                        onChange={(e) => {
                                setCriterioDeBusquedaSede(e.target.value);
                        }}
                        onKeyPress={handleKeypressSede}
                    />
                    <button
                        onClick={manageSearchSede}
                        type='button'
                        className='generic-button-buscador'>
                        Buscar
                    </button>
                    <a className="clean-filters " onClick={limpiarBusquedaSede}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                    {   
                        puededescargarSede ? (
                            <button
                                onClick={descargarSede}
                                type='button'
                                className='generic-button-buscador'>
                                Descargar
                            </button>
                        ):null
                    }
                </div>
            </div>
            <div className='pt-1'>
                <div>
                    <div className="table-scroll d-none d-xl-block">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                                <tr className='bg-cl-gris'>  
                                    <th>
                                        <div className='flex font-gothan-medium'>Sede</div>
                                    </th>
                                    <th>
                                            <FormSelect
                                                options={departamentosFiltradosOptionsSede}
                                                description={'Departamento'}
                                                setSelectedOptions={setDepartamentosSeleccionadosSede}
                                                selectedOptions={departamentosSeleccionadosSede}
                                            />
                                        </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Teléfono</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Correo</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Ubicación</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Site</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Info Sede</div>
                                    </th>
                                </tr>   
                            </thead>
                            <TableWebBodySede filas={usuariosFiltradosSede} toggleDetalleSedeModal={toggleDetalleSedeModal}/>
                        </table>   

                        {totalPagesSede > 1 && (
                                <PaginatorSede totalItems={totalItemsSede}
                                        firsItemPage={firsItemPageSede}
                                        lastItemPage={lastItemPageSede}
                                        totalPages={totalPagesSede}
                                        page={pageSede}
                                        onLeftClic={onLeftClicSede}
                                        onRightClic={onRightClicSede}/>
                        )}
                    </div>

                    <div className='header-table-mob'>
                            <div className='title-body'>Filtros</div>
                            <FormSelect
                                options={departamentosFiltradosOptionsSede}
                                description={'Selecciona Departamento'}
                                setSelectedOptions={setDepartamentosSeleccionadosSede}
                                selectedOptions={departamentosSeleccionadosSede}
                            />
                    </div>

                    <TableMobileBodySede users={usuariosFiltradosSede} toggleDetalleSedeModal={toggleDetalleSedeModal}/>
                   
                </div>
            </div>
        </div>
    )

    const TabDatosGrupo =
    (
        <div style={{'background-color': '#f0f2f5'}}>
            <div className="generic-buscador-container">
                <div className='filtros-left-section'>
                    <input
                        type='text'
                        className='generic-input-criterio-busqueda'
                        placeholder='Buscar por cargo'
                        value={criterioDeBusquedaGrupo}
                        onChange={(e) => {
                            setCriterioDeBusquedaGrupo(e.target.value);
                        }}
                        onKeyPress={handleKeypressGrupo}
                    />
                    <button
                        onClick={manageSearchGrupo}
                        type='button'
                        className='generic-button-buscador'>
                        Buscar
                    </button>
                    <a className="clean-filters " onClick={limpiarBusquedaGrupo}>Limpiar búsqueda</a>
                </div>
                <div className="filtros-right-section">
                    {   
                        puededescargarGrupo ? (
                            <button
                                onClick={descargarGrupo}
                                type='button'
                                className='generic-button-buscador'>
                                Descargar
                            </button>
                        ):null
                    }
                </div>
            </div>
            <div className='pt-1'>
                <div>
                    <div className="table-scroll d-none d-xl-block">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                                <tr className='bg-cl-gris'>  
                                    <th>
                                        <div className='flex font-gothan-medium'>IdGrupo</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Categoría</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Cargo</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Correo</div>
                                    </th>
                                </tr>   
                            </thead>
                            <TableWebBodyGrupo filas={usuariosFiltradosGrupo}/>
                        </table>   

                        {totalPagesGrupo > 1 && (
                                <PaginatorGrupo totalItems={totalItemsGrupo}
                                        firsItemPage={firsItemPageGrupo}
                                        lastItemPage={lastItemPageGrupo}
                                        totalPages={totalPagesGrupo}
                                        page={pageGrupo}
                                        onLeftClic={onLeftClicGrupo}
                                        onRightClic={onRightClicGrupo}/>
                        )}  
                    </div>
                     
                </div> 

                <div className='header-table-mob'>  
                </div>

                <TableMobileBodyGrupo users={usuariosFiltradosGrupo}/>

            </div>
        </div>
    )

    return (
        <Wrapper className='App'>

            {hideDetalleSedeModal=== false &&
                <div className="modal-contactanos modal-response-ficha-background">
                <DetalleSedeModal userId={userSelected} showModal={toggleDetalleSedeModal} reload={()=> {}} />
                </div>
            }

            <PageHeader title={"Directorio"}/>
            <div className='container-fluid body-content' style={{'borderTop': '0.5px solid #eae5e5'}}>
                <ul
                    className='nav nav-tabs nav-groupy'
                    id='tabFicha'
                    role='tablist'
                    style={{
                        display: 'flex !important',
                        justifyContent: 'flex-start',
                        flexWrap: 'inherit',
                    }}>
                        {puedeverDatosGenerales && 
                        <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link active font-gothan-medium'
                                        id='tabDirectorioGeneral'
                                        data-toggle='tab'
                                        href='#divDirectorioGeneral'
                                        role='tab'
                                        aria-controls='home'
                                        aria-selected='true'>
                                        Directorio General
                                    </a>
                        </li>
                        }

                        {puedeverDatosSedes &&
                        <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDatosSede'
                                        data-toggle='tab'
                                        href='#divDatosSede'
                                        role='tab'
                                        aria-controls='contact'
                                        aria-selected='false'>
                                        Datos Sede
                                    </a>
                        </li>
                        }

                        {puedeverDatosGrupos &&
                        <li className='nav-item nav-link-groupy' role='presentation'>
                                    <a
                                        className='nav-link nav-link-a font-gothan-medium'
                                        id='tabDdatosGrupo'
                                        data-toggle='tab'
                                        href='#divDdatosGrupo'
                                        role='tab'
                                        aria-controls='profile'
                                        aria-selected='false'>
                                        Datos Grupo
                                    </a>
                        </li>
                        }
                </ul>
                <div className='tab-content bg-white'>
                        {puedeverDatosGenerales && 
                        <div 
                            className='tab-pane fade show active'
                            id='divDirectorioGeneral'
                            role='tabpanel'
                            aria-labelledby='home-tab'>
                              <div>{TabDatosGenerales}</div>
                        </div>
                        }

                        {puedeverDatosSedes &&
                        <div
                            className='tab-pane fade'
                            id='divDatosSede'
                            role='tabpanel'
                            aria-labelledby='contact-tab'>
                               <div>{TabDatosSede}</div>
                        </div>
                        }

                        {puedeverDatosGrupos &&
                        <div
                            className='tab-pane fade'
                            id='divDdatosGrupo'
                            role='tabpanel'
                            aria-labelledby='profile-tab'>
                               <div>{TabDatosGrupo}</div>
                        </div>
                        }

                </div>
            </div>
        </Wrapper>
    );  
};

export default Directorio;
