import React, { useEffect, useState } from 'react'
import { getDetalleSeguimientoCasoCovid } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';

const DetalleInformacion = ({ idRegistro, permisos, informacionGeneral = null, toggleDetalleEncuestaSaludModal, toggleDetalleVacunaModal, togglDatosContactosModal}) => {
  const [detalles, setDetalles] = useState({});
  const [executeGetRequest] = useGetRequest();

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useEffect(() => {
    if(informacionGeneral) {
      setDetalles(informacionGeneral);
    }
  },[informacionGeneral])


  const init = async function () {
  }

  return (
    <div className="container-fluid">
      <div className="row">

        <div className="col-2"><strong>{detalles?.TipoDocumento}</strong></div>
        <div className="col-4">{detalles?.NroDocumento}</div>
        <div className="col-2"><strong>Caso</strong></div>
        <div className="col-4">{detalles.NumeroCaso}</div>


        <div className="col-2"><strong>Colaborador</strong></div>
        <div className="col-4">{detalles.NombreCompleto}</div>
        <div className="col-2"><strong>Sede</strong></div>
        <div className="col-4">{detalles.Sede}</div>
        <div className="col-2"><strong>Fecha</strong></div>
        <div className="col-4">{detalles.FechaRegistro}</div>

        <div className="col-2"><strong>Cargo</strong></div>
        <div className="col-4">{detalles.Cargo}</div>

        {/* <div className="col-2"><strong>Condición de riesgo</strong></div>
        <div className="col-4">{detalles.Riesgo}</div>

        <div className="col-2"><strong>Condiciones médicas</strong></div>
        <div className="col-4">{detalles.CondicionMedica}</div> */}
      </div>
      <hr />
      <div className="row">
        <div className="col-4">
          <span>Datos Contacto</span>
          <button className="ml-2 btn btn-primary btn-sm rounded-pill" onClick={()=>togglDatosContactosModal(detalles?.IdEmpleado)} disabled={!permisos?.PuedeVerDatosContacto}>
            Ver
          </button>
        </div>
        <div className="col-4">
          <span>Vacunación</span>
          <button className="ml-2 btn btn-primary btn-sm rounded-pill" onClick={()=>toggleDetalleVacunaModal(detalles?.IdEmpleado)} disabled={!permisos?.PuedeVerDatosVacunacion}>
            Ver
          </button>
        </div>
        <div className="col-4">
          <span>Ficha Médica</span>
          <button className="ml-2 btn btn-primary btn-sm rounded-pill" onClick={()=>toggleDetalleEncuestaSaludModal(detalles?.IdEncuestaSalud)} disabled={!permisos?.PuedeVerDatosFichaMedica}>
            Ver
          </button>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-4"><strong>Ocurrencia</strong></div>
        <div className="col-4"><strong>Estado caso</strong></div>
        <div className="col-4"><strong>Registrado por</strong></div>

        <div className="col-4"><span>{detalles.Ocurrencia}</span></div>
        <div className="col-4"><span>{detalles?.Estado?.toUpperCase()}</span></div>
        <div className="col-4"><span>{detalles.RegistradoPor}</span></div>
      </div>
    </div>
  )
}

export default DetalleInformacion
