import React, { useContext, useEffect, useRef, useState } from 'react'
import {v4 as uuidv4} from 'uuid';
import styled, { css } from 'styled-components'
import HTMLReactParser from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Bot from "../../image/bot.jpg";
import Bot_Nova from "../../image/Bot_Nova.png";
import Bot_Nova_Saludo from "../../image/Bot_Nova_Saludo.png";
import Bot_Nova_Nombre from "../../image/Bot_Nova_Nombre.png";
import Bot_Nova_Nombre2 from "../../image/Bot_Nova_Nombre2.png";
import Bot_Nova_Mensaje from "../../image/Bot_Nova_Mensaje.png";
import YesNoModal from './modales/YesNoModal';
import SpinnerChat from '../../components/atoms/SpinnerChat';
import { CHATBOT_FAQ_TYPES } from '../../constants/hardCodedConstants';
import { AuthContext } from '../../hooks/context/authContext';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { chatbotFaqAPI, registraInicioSesionChatbotFaqAPI, registrarCierreSesionChatbotFapAPI, registrarRespuestaLeidaChatbotFaqAPI, registrarUrlVisitadaChatbotFaqAPI } from '../../consumers/backendApisUrls';

const greetingMessage = "Hola! soy Nova, tu asistente virtual";
const initialMessage = "Por favor, selecciona una opción";
const lastMessage = "¿Te puedo ayudar en algo más?";
const closeButtonMessage = "Ok, gracias por visistarnos hoy!";
const defaultMessage = "Upss! por ahora no tenemos una respuesta para esta pregunta, puedes ingresarla dando click en el siguiente botón"
const KEYS = { idFaq: "IdSesionChatbotFaq", idAudit: "IdChatbotFaqAudit" };
const CHATBOT_FINAL = 999;

const ChatBot = ({ toggleContactanosModal }) => {
  const { state } = useContext(AuthContext);
  const bottomRef = useRef(null);
  const location = useLocation();
  const [firstChatbotOpen, setFirstChatbotOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [responses, setResponses] = useState([]);
  const [session, setSession] = useState({});
  const [isUrlVisited, setIsUrlVisited] = useState(false);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false });
  const [executeManagePost] = useManagePostRequest();
  const [executePost] = usePostRequest();

  const init = async () => {
    setResponses([{Id: uuidv4(), Res: greetingMessage}]);
    await manageSearch();
    await registerLogin();
  }

  const manageSearch = async (payload = {}) => {
    setIsFetching(true);
    scrollToBottom();
    const api = chatbotFaqAPI();
    await executeManagePost(api, payload, ({ data }) => handleSearchCallback(data, payload));
  }

  const handleSearchCallback = async (data, payload) => {
    let response = [];
    const IdType = getIdType(data);
    if (IdType === CHATBOT_FAQ_TYPES.pregunta) response.push({Id: uuidv4(), Res: initialMessage});
    response.push({Id: uuidv4(), IdType, Res: data, IdParent: payload.IdParent});
    if (IdType === CHATBOT_FAQ_TYPES.respuesta) {
      await registerReadResponse(payload.IdParent);
      response.push({Id: uuidv4(), IdType: CHATBOT_FINAL, Res: lastMessage});
    }
    setTimeout(() => {
      setIsFetching(false);
      setResponses(prev => [...prev, ...response]);
      scrollToBottom();
    }, 1000);
  }

  const getIdType = (data) =>{
    return data.reduce((result, item) => {
      if (item.IdType === CHATBOT_FAQ_TYPES.pregunta){
        return CHATBOT_FAQ_TYPES.pregunta;
      }
      if (item.IdType === CHATBOT_FAQ_TYPES.respuesta){
        return CHATBOT_FAQ_TYPES.respuesta;
      }
      return result;
    }, uuidv4());
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 150)
  };

  const getUserInput = () => {
    return new Promise((resolve) => {
      setModalEnabled({ isEnable: true, component: YesNoModal, data: { resolve } });
    })
  }

  const closeChatbot = async () => {
    setIsOpen(!isOpen);
    await registerLogout();
  }

  const removeChatbot = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsRemoved(true);
  }

  const registerLogin = async () => {
    const api = registraInicioSesionChatbotFaqAPI();
    const payload = { IdEmpleado: state.user.id_empleado };
    await executeManagePost(api, payload, ({ data }) => setSession(prev => ({...prev, ...data})));
  }

  const registerLogout = async () => {
    const api = registrarCierreSesionChatbotFapAPI();
    const payload = { IdSesionChatbotFaq: session[KEYS.idFaq] };
    await executePost(api, payload);
  }

  const registerReadResponse = async (IdPregunta) => {
    const api = registrarRespuestaLeidaChatbotFaqAPI();
    const payload = { IdSesionChatbotFaq: session[KEYS.idFaq], IdPregunta };
    await executeManagePost(api, payload, ({ data }) => setSession(prev => ({...prev, ...data})));
  }

  const registerVisitedURL = async () => {
    if (!isUrlVisited) {
      const api = registrarUrlVisitadaChatbotFaqAPI();
      const payload = { IdChatbotFaqAudit: session[KEYS.idAudit] };
      await executePost(api, payload);
      setIsUrlVisited(true);
    }
  }

  const showChatbotAtStart = () => {
    const firstTimer = setTimeout(() => {
      setFirstChatbotOpen(true);
    }, 3000);    
    const secondTimer = setTimeout(() => {
      setFirstChatbotOpen(false);
    }, 6000)

    return () => {
      clearTimeout(firstTimer);
      clearTimeout(secondTimer);
    };
  }

  const showChatbotAtHome = () => {
    if (location.pathname === "/" && isRemoved){
      setIsRemoved(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        init();
      }, 1000);
    }

    return () => {
      setResponses([])
    }
  }, [isOpen])

  useEffect(() => {
    showChatbotAtStart();
  }, [])

  useEffect(() => {
    showChatbotAtHome();
  }, [location])

  return (
    <Chatbot isChatbotRemoved={isRemoved} isContainerOpen={isOpen}>
      <div className={`chatbotButton${firstChatbotOpen ? ' visible' : ""}`} onClick={() => {setIsOpen(!isOpen); setFirstChatbotOpen(false);}}>
        <div className='chatbotPopup'>
          <p>¿Tienes alguna consulta?</p>
          <button type='button'>
            Chatea con <img src={Bot_Nova_Nombre2} alt="nova_logo" />
          </button>
        </div>
        <button type='button' className="removeChatbot" onClick={removeChatbot}>X</button>
      </div>
      <div className="chatbotContainer custom-scroll">
        <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
        <div className="close">
          <button type='button' onClick={closeChatbot}>
            <FontAwesomeIcon 
              title='Cerrar'
              icon="times"
            />
          </button>
        </div>
        <div className="chatbotBody">
          {responses?.map((response, index) => (
            <Response key={response.Id}
              index={index}
              response={response}
              setResponses={setResponses}
              manageSearch={manageSearch}
              scrollToBottom={scrollToBottom}
              setIsOpen={setIsOpen}
              getUserInput={getUserInput}
              registerLogout={registerLogout}
              registerVisitedURL={registerVisitedURL}
              toggleContactanosModal={toggleContactanosModal}
            />
          ))}
          <div className='chatbotFetchLoading' ref={bottomRef}>
            {isFetching
              ? <ChatbotCard>
                  <div className="chatbotImage"/>
                  <div className='chatbotResponse'>
                    <SpinnerChat/>
                  </div>
                </ChatbotCard>
              : <></>
            }
          </div>
        </div>
        <div className='chatbotFooter'>
          Ingresa <button type='button' onClick={toggleContactanosModal}>aquí</button> si tienes otra consulta
        </div>
      </div>
    </Chatbot>
  )
}

export default ChatBot

const Response = ({ index, response, setResponses, manageSearch, scrollToBottom, setIsOpen, getUserInput, registerLogout, registerVisitedURL, toggleContactanosModal }) => {

  const handleClick = async (IdResponse, IdChatbotFaq) => {
    setResponses(prev => {
      return prev.map(o => {
        if (o.Id === IdResponse){
          return {
            ...o,
            IsBlocked: true,
            Res: o.Res.map(x => x.IdChatbotFaq === IdChatbotFaq ? {...x, IsSelected: true} : x)
          }
        }
        return o;
      })
    });
    await manageSearch({ IdParent: IdChatbotFaq });
  }

  const handleClickDefault = (Id) => {
    setResponses(prev => {
      const updatedResponse = prev.map(o => o.Id === Id ? {...o, IsBlocked: true} : o);
      const lastResponse = {Id: uuidv4(), IdType: CHATBOT_FINAL, Res: lastMessage};
      return [...updatedResponse, lastResponse];
    });
    scrollToBottom();
  }

  const handleClickYes = async (IdResponse) => {
    setResponses(prev => prev.map(o => o.Id === IdResponse ? {...o, IsBlocked: true} : o));
    await manageSearch();
  }

  const handleClickClose = async () => {
    setResponses(prev => [...prev, {Id: uuidv4(), Res: closeButtonMessage}]);
    scrollToBottom();
    setTimeout(() => {
      setIsOpen(prev => !prev);
    }, 2000);
    await registerLogout();
  }

  const filterResponsesByQuestion = async (IdResponse, IdChatbotFaq) => {
    const response = await getUserInput();
    if (response){
      setResponses(prev => {
        const index = prev.findIndex(item => item.Id === IdResponse);
        return prev.slice(0, index +1);
      });
      await manageSearch({ IdParent: IdChatbotFaq });
    }
  }

  const showImage = () => {
    if (!(response.IdType === CHATBOT_FAQ_TYPES.pregunta || response.IdType === CHATBOT_FAQ_TYPES.respuesta)){
      return <div className="chatbotImage"/>
    }
  }

  const showQuestionOrAnswer = () => {
    const { Id, IdType, Res } = response;
    if (IdType === CHATBOT_FAQ_TYPES.pregunta){
      return (
        <div className='chatbotAllQuestions'>
          {Res.map(o => (
            <div className={`chatbotQuestion ${o.IsSelected ? "questionSelected" : ""}`} key={o.IdChatbotFaq} onClick={() => handleClick(Id, o.IdChatbotFaq)}>
              <FontAwesomeIcon
                className='questionCheck'
                title='Check'
                icon="check"
              />
              <div className="questionText">
                <span>
                  <p>{o.Text}</p>
                </span>
              </div>
            </div>
          ))}
        </div>
      )
    }
    if (IdType === CHATBOT_FAQ_TYPES.respuesta){
      const formatedUrl = (url) => {
        return String(url).startsWith('http') ? url : `https://${url}`
      }

      return (
        Res.map(o => (
          <div className="chatbotResponse" key={o.IdChatbotFaq}>
            {HTMLReactParser(o.Text)}
            {(o.Url !== undefined && o.Url !== null && o.Url.length > 0) &&
              <a href={formatedUrl(o.Url)} target='_blank' rel='noopener noreferrer' onClick={() => registerVisitedURL()}>
                <FontAwesomeIcon
                  className='responseLink'
                  title='open link'
                  icon={faExternalLinkAlt}
                />
              </a>
            }
          </div>
        ))
      )
    }
    if (IdType === CHATBOT_FINAL){
      return (
        <div className='chatbotResponse text-center'>
          {Res}
          <div className='responseFinal'>
            <button type='button' onClick={() => handleClickYes(Id)}>Sí</button>
            <button type='button' onClick={handleClickClose}>No</button>
          </div>
        </div>
      )
    }

    return (
      <div className='chatbotResponse'>
        {Res.length
          ? Res
          : <div className="chatbotResponse">
              <p>{defaultMessage}</p>
              <button type='button' className='gdhButton' onClick={() => {toggleContactanosModal(); handleClickDefault(Id)}}>GDH te escucha</button>
            </div>
        }
      </div>
    )
  }

  const showIcons = () => {
    return (
      response.IdType === CHATBOT_FAQ_TYPES.pregunta && (
        <div className='chatbotIcons'>
          <div className="iconCheck">
            <FontAwesomeIcon
              title='Seleccionado'
              icon='check'
              size='2x'
            />
          </div>
          <div className="iconRestart" onClick={() => filterResponsesByQuestion(response.Id, response.IdParent)}>
            <FontAwesomeIcon
              title="Reiniciar"
              icon="redo"
              size='lg'
            />
          </div>
        </div>
    ))
  }

  return (
    <ChatbotCard index={index} IsBlocked={response.IsBlocked} isQuestionType={response.IdType === CHATBOT_FAQ_TYPES.pregunta}>
      {showIcons()}
      {showImage()}
      {showQuestionOrAnswer()}
    </ChatbotCard>
  )
}

const ModalEnabled = ({
  modalEnabled = { isEnable: false },
  setModalEnabled,
}) => {
  return (
    modalEnabled.isEnable && (
      <modalEnabled.component
        toggleModal={() => setModalEnabled({ isEnable: false })}
        {...modalEnabled.data}
      />
    )
  );
};

const Chatbot = styled.div`
  display: ${({ isChatbotRemoved }) => isChatbotRemoved ? 'none' : 'block' };
  & * {
    background: transparent;
    position: static;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    float: none;
    opacity: 1;
    visibility: visible;
    z-index: auto;
    zoom: 1;
    transform: none;
    transition: none;
    text-transform: none;
    outline: none;
    letter-spacing: normal;
  }

  .chatbotButton{
    &::before{
      content: "";
      /* background-image: url(https://media.botsrv2.com/control/img/100x100/06/b5e3f70e7f4adfac6500b2a00f9a6e/quriobot.webp); */
      background-image: url(${Bot_Nova_Saludo});
      height: 60px;
      width: 60px;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      left: 0;
      top: 50%;
      transform: translate3d(5px, -50%, 0);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
      box-shadow: 0 0 4px rgba(0,0,0,.1),0 2px 4px rgba(0,0,0,.2);
    }
    padding: 0;
    margin: 0;
    height: 68px;
    width: 75px;
    position: fixed;
    z-index: 2;
    right: 0;
    bottom: 60px;
    display: block;
    transition: all .3s;
    transition-timing-function: cubic-bezier(.1,1.69,.67,.86), ease;
    background-color: #FFB71B;
    border-radius: 50% 0 0 50%;
    cursor: pointer;
    transform: ${({ isContainerOpen }) => isContainerOpen ? "translate3d(100%,0,0)" : "translate3d(0,0,0)"};
    pointer-events: ${({ isContainerOpen }) => isContainerOpen ? "none" : "all"};

    &:hover,
    &.visible{
      .chatbotPopup{
        background-color: #ffffff;
        border-radius: 5px 0 0 28px;
        max-width: 380px;
        max-height: 200px;
        padding: 10px 40px 10px 10px;
        visibility: visible;
        transition: all .3s cubic-bezier(.4,1.88,.7,1);
        box-shadow: 0 3px 12px rgba(0,0,0,.2),0 6px 20px rgba(0,0,0,.1);

        p {
          visibility: visible;
          opacity: 1;
          transition: all .3s ease;
          transition-delay: .2s, 0s;
        }

        button {
          visibility: visible;
          opacity: 1;
          transition: all .3s ease;
          transition-delay: .2s, 0s;
        }
      }
      .removeChatbot{
        visibility: visible;
      }
    }

    .chatbotPopup{
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 14px;
      cursor: pointer;
      border-radius: 28px 0 0 28px;
      max-width: 100%;
      max-height: 100%;
      min-height: 58px;
      visibility: hidden;
      transition: all .3s ease;
      transform-origin: 50% 50%;

      p {
        line-height: normal;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition-delay: 0s, 0s;
        margin-bottom: 10px;
        padding: 0 10px;
        text-align: start;
      }

      button {
        padding: 8px 40px 8px 20px;
        color: #fff;
        font-weight: bolder;
        background-color: #FFB71B;
        border-radius: 100px;
        line-height: 1.4em;
        cursor: pointer;
        position: relative;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition-delay: 0s, 0s;

        img {
          height: 17px;
          margin-left: 5px;
          margin-bottom: 2px;
        }
      }
    }

    .removeChatbot {
      position: absolute;
      right: 4px;
      top: 2px;
      z-index: 2;
      border: transparent;
      background: #ff6576;
      color: white;
      font-weight: bolder;
      font-size: 12px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: hidden;
    }
  }

  .chatbotContainer {
    transform: ${({ isContainerOpen }) => isContainerOpen ? "translate3d(0,0,0)" : "translate3d(110%,0,0)"};
    opacity: ${({ isContainerOpen }) => isContainerOpen ? 1 : 0};
    visibility: ${({ isContainerOpen }) => isContainerOpen ? "visible" : "hidden"};
    background: ${({ isContainerOpen }) => isContainerOpen ? "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))" : "transparent"};
    position: fixed;
    right: 20px;
    top: 20px;
    width: 100%;
    max-width: 380px;
    height: calc(100% - 40px);
    display: block;
    border-radius: 22px;
    box-shadow: 0 17px 50px 0 rgba(0,0,0,.14),0 12px 15px 0 rgba(0,0,0,.12);
    z-index: 2;
    transition: all .75s ease-in;
    overflow: hidden;

    .close {
      top: 10px;
      right: 10px;
      z-index: 5;
      opacity: .8;
      position: fixed;
      transition: all .2s ease;
      
      &:hover {
        opacity: 1;
      }

      button {
        background-color: #ff6576;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        color: white;
      }
    }

    .chatbotBody {
      height: 100%;
      width: 100%;
      padding: 40px 40px 60px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
      /* scrollbar-width: none; */
      align-items: center;

      .chatbotFetchLoading{
        width: 100%;
      }
    }

    .chatbotFooter {
      position: absolute;
      bottom: 0;
      padding: 10px;
      width: 100%;
      text-align: center;
      background-color: #FFB71B;
      color: white;
      font-size: 13px;
      font-weight: bolder;
      z-index: 2;

      button {
        background-color: #8DBA38;
        padding: 1px 10px;
        border-radius: 8px;
        color: white;
        font-weight: bolder;
        border: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: #38b000;
        }
      }
    }
  }
`;

const ChatbotCard = styled.div`
  width: 100%;
  position: relative;
  box-shadow:0 0 2px rgba(0,0,0,.1),0 2px 10px rgba(0,0,0,.2);
  border-radius: 10px;
  ${({ isQuestionType }) => isQuestionType && css`
    width: 110%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  `}

  .chatbotIcons {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    opacity: ${({ IsBlocked }) => IsBlocked ? 1 : 0};
    visibility: ${({ IsBlocked }) => IsBlocked ? "visible" : "hidden"};
    transform: ${({ IsBlocked }) => IsBlocked ? "translate3d(0, 0, 0);" : "translate3d(0, 30px, 0);"};
    transition: all .25s ease-in;
    z-index: 2;

    .iconCheck, .iconRestart{
      padding: 30px;
      height: 42px;
      width: 42px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      z-index: 2;

      svg {
        height: 42px;
      }
    }
    .iconCheck{
      border: 1px solid #00659D;
      background: #00659D;
      box-shadow: 0 0 4px rgba(0,0,0,.1), 0 4px 10px rgba(0,0,0,.2);
    }
    .iconRestart{
      cursor: pointer;
      border: 1px solid #ff6840;
      padding: 20px;
      background: #ff6840;
      filter: grayscale(60%);
      transition-delay: .15s;
      transition: all .2s ease-in;

      &:hover{
        filter: grayscale(0);
        transform: rotate(180deg);
      }
    }
  }

  .chatbotImage {
    position: absolute;
    top: 0;
    /* background-image: url(https://media.botsrv2.com/control/img/100x100/06/b5e3f70e7f4adfac6500b2a00f9a6e/quriobot.webp); */
    background-image: ${({ index }) => index === 0 ? `url(${Bot_Nova_Saludo})` : `url(${Bot_Nova})`};
    height: 50px;
    width: 50px;
    border-radius: 50%;
    left: 0;
    background-size: cover;
    transform: translate3d(-25px, -12px, 0);
  }

  .chatbotResponse {
    padding: 20px 30px;
    font-size: 0.9rem;
    word-wrap: break-word;
    text-align: justify;

    .gdhButton{
      border-radius: 15px;
      color: #ffffff;
      background: #3a87c8;
      padding: 3px 25px 3px 25px;
      border: none;
      outline: none !important;
      margin: 10px 5px;
      font-family: "GothamLight", system-iu, sans-serif;

      &:hover {
        color: #fff;
        background-color: #0069d9 !important;
        border-color: #0062cc !important;
      }
    }
    
    .responseLink {
      margin: 0 10px;
      cursor: pointer;
      color: #8DBA38;
      transition: all 0.4s ease;

      &:hover{
        transform: scale(1.12);
      }
    }

    .responseFinal{
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 15px;
      font-weight: bold;
      
      button{
        padding: 5px 40px;
        border: 1px solid #3a87c8;
        color: #3a87c8;
        border-radius: 15px;
        transition: background-color .2s ease-in-out;

        &:hover{
          color: white;
          background-color: #3a87c8;
        }
      }
    }
  }

  .chatbotResponse, .chatbotAllQuestions{
    ${({ IsBlocked }) => IsBlocked && css`
      opacity: 0.5;
      pointer-events: none;
    `};
  }

  .chatbotAllQuestions {
    transition: opacity 0.5s ease;
    & > div:not(:last-child){
      border-bottom: 1px solid rgba(0, 0, 0, .15);
    }

    .chatbotQuestion {
      display: flex;
      padding: 12px 10px 12px 15px;
      font-size: 13px;
      gap: 10px;
      transition: all .15s ease;
      cursor: pointer;

      &:hover {
        .questionCheck{
          color: #3a87c8;
        }
        .questionText{
          transform: translate3d(8px, 0, 0);
          color: #3a87c8;
        }  
      }

      .questionCheck{
        color: #8DBA38;
      }

      .questionText{
        transition: all .15s ease;
      }
    }

    .chatbotQuestion.questionSelected {
      .questionCheck, .questionText{
        color: #3a87c8;
        font-weight: bolder;
      }
    }
  }
`;