import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";

const TableWebBody = ({ filas, toggleDetalleModal, elimiinarRegistro, reabrirRegistro, setModoCrear, setIdRegistro }) => {
  const [filass, setFilass] = useState([]);
  const { state } = useContext(AuthContext);

  useEffect(() => {
    setFilass(filas)
  }, [filas]);

  const descargarArchivo = (e, archivo) => {
    e.preventDefault()
    downloadFile(state.token, archivo.Path, archivo.OriginalFileName);
  }

  return (
    <tbody className="listar-tbody">
      {filass.map((fila, i) => (
        <tr key={i}>
          <td>{fila?.FechaRegistro}</td>
          <td>
            <div className="d-flex align-items-center" >
              {fila.Nombre}
            </div>
          </td>
          <td>{fila?.Cargo}</td>
          <td>{fila?.Division}</td>
          <td>{fila?.Gerencia}</td>
          <td>{fila?.Area}</td>
          <td>{fila?.FechaTermino}</td>
          <td>{fila?.Ocurrencia}</td>
          <td>
            {fila?.Estado}
          </td>
          <td>
            <div className="d-flex justify-content-start">
              {fila?.IdCasoCovid !== 0 ? (
                <>
                  <div>
                    <FontAwesomeIcon icon='table' size="lg" className="hand-pointer ml-3" title="Ver detalle" onClick={async () => {setModoCrear(false); setIdRegistro(fila?.IdCasoCovid); await toggleDetalleModal(fila?.IdCasoCovid)}} />
                  </div>
                </>
              ) : null}
              {fila?.PuedeReabrir ? (
                <div>
                  <FontAwesomeIcon icon='folder-open' size="lg" className="hand-pointer ml-3" title="Abrir caso" onClick={() => reabrirRegistro(fila?.IdCasoCovid)} />
                </div>
              ) : null}
              {fila?.PuedeEliminar ? (
                <div>
                  <FontAwesomeIcon icon='times' size="lg" className="hand-pointer ml-3" title="Eliminar" onClick={() => elimiinarRegistro(fila?.IdCasoCovid)} />
                </div>
              ): null}
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableWebBody;
