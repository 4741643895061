import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import FormSelect from "../../components/atoms/Select";
import Paginator from "../directorio/Paginator";
import TableWebBodyRoles from "./TableWebBodyRoles";
import RegistrarRolModal from "./modales/RegistrarRolModal";
import EditarRolModal from "./modales/EditarRolModal";
import MantenimientoRolModal from "./modales/MantenimientoRolModal";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getRolesEstadosAPI, seguridadListarRolesAPI} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Spinner} from "react-bootstrap";

const GestionDeRoles = () => {
    const {state} = useContext(AuthContext);

    const [hideRegistrarRolModal, setHideRegistrarRolModal] = useState(true);
    const [hideEditarRolModal, setHideEditarRolModal] = useState(true);
    const [hideMantenimientoRolModal, setHideMantenimientoRolModal] = useState(true);
    const [loading, setLoading] = useState(true);
    const [rolesFiltrados, setRolesFiltrados] = useState([]);

    const [idRolEditar, setIdRolEditar] = useState();
    const [idRolMantenimiento, setIdRolMantenimiento] = useState();
    const [nombreRol, setNombreRol] = useState();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [estadosFiltradosOptions, setRolesFiltradosOptions] = useState([]);
    const [cleanFilterss, setCleanFilters] = useState(false);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda, estadosSeleccionados]);

    useEffect(() => {
        manageSearch();
    }, [estadosSeleccionados, page]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getDropdownOptions(executeGetRequestCallback(getRolesEstadosAPI()), setRolesFiltradosOptions);
            manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
            setState(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const manageSearch = async () => {
        console.debug("manage search");
        console.debug("criterioDeBusqueda: ", criterioDeBusqueda);
        console.debug("estadosSeleccionados: ", estadosSeleccionados);
        console.debug("page: ", page);
        let usersFiltered = rolesFiltrados;
        setRolesFiltrados(usersFiltered);

        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdEstados": estadosSeleccionados,
            "Page": page
        };

        console.debug("payload: ", payload);

        await executeSearch(payload);
    };

    const executeSearch = async (payload) => {
        const response = await executePostRequest(seguridadListarRolesAPI(), payload);
        if (responseCode200(response)) {
            setRolesFiltrados(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onLeftClic = () => {
        console.debug("onLeftClic");
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        console.debug("onRightClic");
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setEstadosSeleccionados([]);
        setCriterioDeBusqueda("");
        setCleanFilters(!cleanFilterss);
    };

    const showRegistrarRolModal = async () => {
        setHideRegistrarRolModal(!hideRegistrarRolModal);
        await getInitialData();
    };

    const showEditarRolModal = async (idRol) => {
        if (idRol) {
            setIdRolEditar(idRol);
        }
        setHideEditarRolModal(!hideEditarRolModal);
        await getInitialData();
    };

    const showMantenimientoRolModal = async (idRol, nombreRol) => {
        if (idRol) {
            setIdRolMantenimiento(idRol);
        }

        if (nombreRol) {
            setNombreRol(nombreRol);
        }

        setHideMantenimientoRolModal(!hideMantenimientoRolModal);
        await getInitialData();
    };

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Gestión de Roles no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                <div className="modal-contactanos modal-response-ficha-background" hidden={hideRegistrarRolModal}>
                    {hideRegistrarRolModal === false &&
                    <RegistrarRolModal showModal={showRegistrarRolModal}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideEditarRolModal}>
                    {hideEditarRolModal === false &&
                    <EditarRolModal showModal={showEditarRolModal} idRolEditar={idRolEditar}/>
                    }
                </div>

                <div className="modal-contactanos modal-response-ficha-background" hidden={hideMantenimientoRolModal}>
                    {hideMantenimientoRolModal === false &&
                    <MantenimientoRolModal showModal={showMantenimientoRolModal} idRol={idRolMantenimiento} nombreRol={nombreRol}/>
                    }
                </div>

                <Wrapper className='App'>
                    <PageHeader title={"Gestión de Roles"}/>
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por Nombre'
                                value={criterioDeBusqueda}
                                onChange={(e) => {
                                    setCriterioDeBusqueda(e.target.value);
                                }}
                                onKeyPress={handleKeypress}
                            />
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                            <button
                                onClick={showRegistrarRolModal}
                                type='button'
                                className='generic-button-buscador'>
                                Registrar Rol
                            </button>
                        </div>
                    </div>
                    <div className='pt-2'>
                        <div className="table-scroll">
                            <table className='table table-hover'>
                                <thead className="listar-thead">
                                <tr className='bg-cl-gris'>
                                    <th>
                                        <div className='flex font-gothan-medium'>Id</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Nombre</div>
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Descripción</div>
                                    </th>
                                    <th>
                                        <FormSelect
                                            options={estadosFiltradosOptions}
                                            description={'Estado'}
                                            setSelectedOptions={setEstadosSeleccionados}
                                            selectedOptions={estadosSeleccionados}
                                        />
                                    </th>
                                    <th>
                                        <div className='flex font-gothan-medium'>Acciones</div>
                                    </th>
                                </tr>
                                </thead>
                                <TableWebBodyRoles roles={rolesFiltrados} showEditarRolModal={showEditarRolModal} showMantenimientoRolModal={showMantenimientoRolModal}/>
                            </table>
                        </div>

                            {totalPages > 1 && (
                                <Paginator totalItems={totalItems}
                                           firsItemPage={firsItemPage}
                                           lastItemPage={lastItemPage}
                                           totalPages={totalPages}
                                           page={page}
                                           onLeftClic={onLeftClic}
                                           onRightClic={onRightClic}/>
                            )}

                    </div>
                </Wrapper>
            </div>
        </>
    );
};

export default GestionDeRoles;
