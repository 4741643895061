import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useForm } from '../../../hooks/useForm/UseForm';
import GenericModal from '../../../components/modal/GenericModal';

import {getEditarSedeByIdAPI, getDropDownEmpleadoAPI, editarSedeAPI} from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { AuthContext } from '../../../hooks/context/authContext';
import {toast} from "react-toastify";
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import { jsonNoEstaVacio } from '../../../helper/utils';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';

const EditarSedeModal = ({ showModal, reload, userId = null }) => {
    const sectionName = "EditarSedeModal";

    const { state } = useContext(AuthContext);
    const [soloLectura, setSoloLectura] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    const [detalleSedeResponse, setDetalleSedeResponse] = useState({});

    const [loading, setLoading] = useState(true);

    const [jsonSelectedRegional, setJsonSelectedRegional] = useState({});
    const [jsonSelectedLegal, setJsonSelectedLegal] = useState({});
    const [jsonSelectedJefeComercial, setJsonSelectedJefeComercial] = useState({});
    const [jsonSelectedSupervisorSistemas, setJsonSelectedSupervisorSistemas] = useState({});
    const [jsonSelectedAsistente, setJsonSelectedAsistente] = useState({});
    const [jsonSelectedCoordinadorMantenimiento, setJsonSelectedCoordinadorMantenimiento] = useState({});
    const [jsonSelectedCoordinadorGdh, setJsonSelectedCoordinadorGdh] = useState({});

    const [puedeEditar, setPuedeEditar] = useState(false);
    const [puedeEditarOtros, setPuedeEditarOtros] = useState(false);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        setLoading(true);

        let responseData = await getDetalleSede();

        if (responseData) {

            setPuedeEditar(responseData?.PuedeEditar);

            if(responseData?.PuedeEditar) 
                {setPuedeEditarOtros(false);}
            else
                {setPuedeEditarOtros(true);}

            let formValuess = {
                "Nombre": responseData?.Nombre,
                "CorreoSede" : responseData?.CorreoSede,
                "EmailDocente" : responseData?.EmailDocente,
                "EmailEquipoDirectivo" : responseData?.EmailEquipoDirectivo,
                "Ubicacion" :responseData?.Ubicacion,
                "Telefono" : responseData?.Telefono,
                "Site" : responseData?.Site,
                "IdAsistente" : responseData?.IdoAsistente,
                "NombreAsistente" : responseData?.NombreAsistente,
            }

            let Regional = {
                value: responseData?.IdRegional,
                label: responseData?.NombreRegional
            };
            setJsonSelectedRegional(Regional);

            let Legal = {
                value: responseData?.IdLegal,
                label: responseData?.NombreLegal
            };
            setJsonSelectedLegal(Legal);

            let JefeComercial = {
                value: responseData?.IdJefeComercial,
                label: responseData?.NombreJefeComercial
            };
            setJsonSelectedJefeComercial(JefeComercial);

            let Asistente = {
                value: responseData?.IdAsistente,
                label: responseData?.NombreAsistente
            };
            setJsonSelectedAsistente(Asistente);

            let SupervisorSistemas = {
                value: responseData?.IdSupervisorSistemas,
                label: responseData?.NombreSupervisorSistemas
            };
            setJsonSelectedSupervisorSistemas(SupervisorSistemas);

            let CoordinadorMantenimiento = {
                value: responseData?.IdCoordinadorMantenimiento,
                label: responseData?.NombreCoordinadorMantenimiento
            };
            setJsonSelectedCoordinadorMantenimiento(CoordinadorMantenimiento);

            let CoordinadorGdh = {
                value: responseData?.IdCoordinadorGdh,
                label: responseData?.NombreCoordinadorGdh
            };
            setJsonSelectedCoordinadorGdh(CoordinadorGdh);

            setFormValues(formValuess);
        }

        setLoading(false);
    };

    const getDetalleSede = async function () {
        let response;
        if(userId !== null) response = await executeGetRequest(getEditarSedeByIdAPI(userId));
        else response = await executeGetRequest(getEditarSedeByIdAPI(state.user.id_empleado));
        if (responseCode200(response)) {
            setDetalleSedeResponse(response.data);
            if(userId) {setSoloLectura(false);}
            else setSoloLectura(response.data.SoloLectura);
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const handleRegional = async (jsonValue) => {
        setJsonSelectedRegional(jsonValue);
    }

    const handleLegal = async (jsonValue) => {
        setJsonSelectedLegal(jsonValue);
    }

    const handleJefeComercial = async (jsonValue) => {
        setJsonSelectedJefeComercial(jsonValue);
    }

    const handleSupervisorSistemas= async (jsonValue) => {
        setJsonSelectedSupervisorSistemas(jsonValue);
    }

    const handleAsistente = async (jsonValue) => {
        setJsonSelectedAsistente(jsonValue);
    }

    const handleCoordinadorMantenimiento= async (jsonValue) => {
        setJsonSelectedCoordinadorMantenimiento(jsonValue);
    }

    const handleCoordinadorGdh = async (jsonValue) => {
        setJsonSelectedCoordinadorGdh(jsonValue);
    }

    const submit = async () => {
        let payload = armarPayload();
        await executeSaveVacunaForm(payload);
    };

    const armarPayload = function () {
        let payload = {
            "IdSede" : userId,
            "Email" : formValues["CorreoSede"],
            "EmailDocente" : formValues["EmailDocente"],
            "EmailEquipoDirectivo" : formValues["EmailEquipoDirectivo"],
            "Ubicacion" : formValues["Ubicacion"],
            "Telefono" : formValues["Telefono"],
            "EnlaceSite" : formValues["Site"],
            "IdAsistenteSistemas" : jsonSelectedAsistente?.value,
            "IdSupervisorSistemas" :jsonSelectedSupervisorSistemas?.value,
            "IdRegional" : jsonSelectedRegional?.value,
            "IdLegal" : jsonSelectedLegal?.value,
            "IdJefeComercial" : jsonSelectedJefeComercial.value,
            "IdCoordinadorMantimiento" : jsonSelectedCoordinadorMantenimiento?.value,
            "IdCoordinadorGdh" : jsonSelectedCoordinadorGdh?.value,
        }
       
        return payload;
    };

    const executeSaveVacunaForm = async (payload) => {
        console.debug("payload: ", payload);

        const response = await executePostRequest(editarSedeAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showModal();
            reload();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["CorreoSede", "EmailDocente", "EmailEquipoDirectivo", "Ubicacion", "Site ", "IdRegional", "IdLegal", "IdJefeComercial", "IdAsistente", "IdCoordinadorMantenimiento"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const detalleFormulario = (
        <>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                <div className="col-sm-8">
                    <label className="col-form-label black-color">{formValues?.Nombre ?? ""}</label>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label" >Correo Sede:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="CorreoSede" onChange={handleChange} value={formValues?.CorreoSede ?? ""} disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-CorreoSede-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label" >Equipo docente:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="EmailDocente" onChange={handleChange} value={formValues?.EmailDocente ?? ""} disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-EmailDocente-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Equipo directivo:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="EmailEquipoDirectivo" onChange={handleChange} value={formValues?.EmailEquipoDirectivo ?? ""} disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-EmailEquipoDirectivo-validation-message`}></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>
            
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label" >Ubicación:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="Ubicacion" onChange={handleChange} value={formValues?.Ubicacion ?? ""} disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-Ubicacion-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label" >Teléfono:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="Telefono" onChange={handleChange} value={formValues?.Telefono ?? ""} disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-Telefono-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Site:</label>
                <div className="col-sm-8">
                    <input type="text" className="form-control" name="Site" onChange={handleChange} value={formValues?.Site ?? ""}  disabled={puedeEditarOtros}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-Site-validation-message`}></p>
                </div>
            </div>


            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Regional:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleRegional}
                        isDisabled={puedeEditarOtros}
                        defaultValue={jsonNoEstaVacio(jsonSelectedRegional) ? jsonSelectedRegional : null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdRegional-validation-message`}></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Legal:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleLegal}
                        defaultValue={jsonNoEstaVacio(jsonSelectedLegal) ? jsonSelectedLegal : null
                    } 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdLegal-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Jefe comercial:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleJefeComercial}
                        defaultValue={jsonNoEstaVacio(jsonSelectedJefeComercial) ? jsonSelectedJefeComercial : null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdJefeComercial-validation-message`}></p>
                </div>
            </div>

            <hr className="mt-1 mb-1"/>
            
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Coord. Gdh Regional:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleCoordinadorGdh}
                        defaultValue={jsonNoEstaVacio(jsonSelectedCoordinadorGdh) ? jsonSelectedCoordinadorGdh: null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdSupervisorSistemas-validation-message`}></p>
                </div>
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Supervisor de sistemas:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleSupervisorSistemas}
                        isDisabled={puedeEditarOtros}
                        defaultValue={jsonNoEstaVacio(jsonSelectedSupervisorSistemas) ? jsonSelectedSupervisorSistemas : null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdSupervisorSistemas-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Asistente de sistemas:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleAsistente}
                        isDisabled={puedeEditarOtros}
                        defaultValue={jsonNoEstaVacio(jsonSelectedAsistente) ? jsonSelectedAsistente : null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdAsistente-validation-message`}></p>
                </div>
            </div>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Coordinador de mant.:</label>
                <div className="col-sm-8">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleCoordinadorMantenimiento}
                        defaultValue={jsonNoEstaVacio(jsonSelectedCoordinadorMantenimiento) ? jsonSelectedCoordinadorMantenimiento : null} 
                        />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdCoordinadorMantenimiento-validation-message`}></p>
                </div>
            </div>
        </>
    );

    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo={"Editar detalle de Sede"}>
                {loading === true &&
                <div className="spinner-cargando-container">
                    <Spinner animation="grow" variant="primary" size="1sm"/>
                </div>
                }
                {loading === false &&
                    <>
                        <div className="modal-body p-0">
                            <form className="blue-standard-color">
                                {detalleFormulario}
                            </form>
                        </div>
                        <div className="modal-footer d-flex justify-content-end">
                            <div className="button d-flex justify-content-end">
                                <button className="btn btn-primary generic-button-ficha" onClick={submit}>Guardar</button>
                            </div>
                        </div>
                    </>
                }
            </GenericModal>
        </>
    );
}

export default EditarSedeModal;
