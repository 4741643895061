import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FooterModal = ({
  isDisabled,
  isSubmitting,
  label = "Guardar",
  extraMessage = "",
  children
}) => (
  <FooterWrapper>
    <div className="modalMessage">
      {extraMessage}
    </div>
    <div className="modalContainer">
      {children}
      <button
        type="submit"
        className={`btn btn-primary generic-button-ficha-modal ${isSubmitting ? "pr-2" : ""}`}
        disabled={isDisabled || isSubmitting}
      >
        {label} {isSubmitting && (<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>)}
      </button>
    </div>
  </FooterWrapper>
);

export default FooterModal;

const FooterWrapper = styled.div`
  border-top: 1px solid #dee2e6;
  padding: 1.2rem 0 0;
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .modalMessage{
    font-size: 14px;
  }

  .modalContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }
`