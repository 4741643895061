import React, {  useEffect } from 'react';
import GenericModal from '../../../components/modal/GenericModal';

const CargarResultadosEvaluacionSuccess = ({ toggleModal, responseMessage }) => {

    return (
        <>
            <GenericModal showModal={toggleModal} width={"750px"} titulo={"Se ha cargado temporalmente"}>
                <div className="modal-body" style={{ height: "100%" }}>
                    <div className="form-group row">
                        <div className="black-color">
                            <p>{responseMessage}</p>
                        </div>
                        <div>
                            <p className="pt-2">Los registros se procesan internamente, una vez finalizado el procesamiento de la información cargada</p>
                            <ul>
                                <li>Podrá ver los nuevos registros al actualizar la página</li>
                                <li>Si algún registro no pasa las validaciones, usted recibirá un correo indicando que validaciones no pasaron, para su corrección</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={toggleModal}>Aceptar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

export default CargarResultadosEvaluacionSuccess;