import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import DirectorioWrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import { AuthContext } from "../../hooks/context/authContext";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { listarCategoriasDePoliticasQuePuedoVerAPI } from '../../consumers/backendApisUrls';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import ColoredCard from '../../components/cards/ColoredCard';
import CompromisoLecturaModal from './modales/CompromisoLecturaModal';
import SelectInput from "../../components/Select/SelectInput";
import useQuery from "../../hooks/query/useQuery";
import {isNullOrUndefinded} from "../../helper/utils";

const Categorias = () => {
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);
  const [managePostRequest] = useManagePostRequest();
  const [manageGetRequest] = useManageGetRequest();
  const [categoriasParentsConChilds, setCategoriasParentsConChilds] = useState([]);
  const [categoriasToShow, setCategoriasToShow] = useState([]);
  const [showCategoriasParents, setShowCategoriasParents] = useState(true);

  const [executeGetRequest] = useGetRequest();
  const [reload, setReload] = useState(false);

  const [hideCompromisoLectura, setHideCompromisoLectura] = useState(false);
  const [datosCompromisoLectura, setDatosCompromisoLectura] = useState(null);



  const [idItem, setIdItem] = useState(0);
  const history = useHistory();

  const query = useQuery();
  const categoriaId = query.get("categoriaId");

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, [reload]);

  const getInitialData = async () => {
    setLoading(true);
    try {
      await manageGetRequest(listarCategoriasDePoliticasQuePuedoVerAPI(), handleResponseSuccess, false);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const handleResponseSuccess = function (response) {
    let allCategorias = response?.data?.Items;
    let parents = allCategorias.filter((value) => value?.IdParent === null);
    let childs = allCategorias.filter((value) => value?.IdParent !== null);

    let allParentsConChilds = [];
    for (const parent of parents) {
      let childss = childs.filter((o)=> o?.IdParent === parent?.IdCategoria);
      allParentsConChilds = [...allParentsConChilds,
        {
          ...parent, childs: [...childss]
        }]
    }

    setCategoriasParentsConChilds(allParentsConChilds);

    if (categoriaId == null || isCategoriaParent(categoriaId, allParentsConChilds)) {
      console.debug("mostrar parents")
      setCategoriasToShow(allParentsConChilds);
      setShowCategoriasParents(true);
    } else {
      console.debug("mostrar childs")
      let child = childs.find(o => o.IdCategoria == categoriaId);
      if (child) {
        let parent = allParentsConChilds.find((o) => o?.IdCategoria == child?.IdParent);
        setCategoriasToShow(parent?.childs);
        setShowCategoriasParents(false);
      } else {
        setCategoriasToShow(allParentsConChilds);
        setShowCategoriasParents(true);
      }
    }


    setHideCompromisoLectura(response.data?.MostrarCompromiso);
    setDatosCompromisoLectura(response.data?.TextoCompromiso);
  }

  const isCategoriaParent = (categoriaId, allParentsConChilds) => {
    let parent = allParentsConChilds.find((o)=>o?.IdCategoria == categoriaId);
    return isNullOrUndefinded(parent) ? false : true;
  }

  const handleCardClick = (category) => {
    if (category?.childs && category?.childs?.length>0){
      setCategoriasToShow(category.childs);
      setShowCategoriasParents(false);
    }else{
      history.push(`/politicasYreglamentos/categoria/${category.IdCategoria}?name=${category.NombreCategoria}`);
    }
  }

  const toggleCompromisoLectura = () => {
    setHideCompromisoLectura(prev => !prev);
  }

  const toggleView = () => {
    setShowCategoriasParents(true);
    setCategoriasToShow(categoriasParentsConChilds);
  }

  return (
    <DirectorioWrapper className='App'>
      {hideCompromisoLectura ? (
        <div className="modal-contactanos modal-response-ficha-background">
          <CompromisoLecturaModal
            toggleModal={toggleCompromisoLectura}
            datosCompromisoLectura={datosCompromisoLectura}
          />
        </div>
      ) : null}
      <PageHeader title="Políticas y reglamentos" />
      <div className='container-fluid body-content'>

        {!showCategoriasParents &&
        <div className="generic-buscador-container w-100">
          <div className="filtros-left-section d-flex w-100 flex-wrap">
            <button className='generic-button-buscador' onClick={toggleView}>
              <FontAwesomeIcon
                  icon='angle-left'
                  size="lg"
                  className="hand-pointer"
                  title="Atras"
              /> Ver Categorías
            </button>
          </div>
        </div>
        }

        <div className='mt-2 d-flex align-content-center justify-content-center flex-wrap'>
          <div>
            <h2 className='mt-4'>{showCategoriasParents ?  'Categorías': 'Sub Categorías' }</h2>
          </div>
        </div>
        <div className='mt-2 d-flex align-content-center justify-content-center flex-wrap'>
          {categoriasToShow.map(category => (
            <ColoredCard
              key={category?.IdCategoria}
              title={category.NombreCategoria}
              color={category.Color}
              height={"14rem"}
              tooltip="Para conocer más sobre las políticas, haz click aquí"
              onClick={() => handleCardClick(category)}
            />
          ))}
        </div>
      </div>
    </DirectorioWrapper>
  )
}

export default Categorias;
