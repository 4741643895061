import React, {useState} from 'react';
import styled from 'styled-components';
import ModalCenter from '../../components/atoms/ModalCenter';
import {openGmail, openWhatsapp} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDataEmpleadoAPI} from "../../consumers/backendApisUrls";
import ReactTooltip from 'react-tooltip';
import {toast} from "react-toastify";

const WrapperModal = styled.div`
  margin-left: 50px;
  color: rgb(99, 99, 99);

  .nombre-completo {
    font-size: 22px;
    font-weight: bold;
  }
  
  .area-cargo {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
  }
  
  .tiempo-en-innova {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 15px;
  }
  
  .nombre-empleado-superior {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 5px;
    a {
      color: #7ab6f9;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
  }
`;

function copyToClipboard(textToCopy) {
    // navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard api method'
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // text area method
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
            // here the magic happens
            document.execCommand('copy') ? res() : rej();
            textArea.remove();
         });
    }
}

const TableWebBody = ({filas}) => {
    const [close, setClose] = useState(false);
    const [detail, setDetail] = useState({});
    const [idDeEmpleadoSuperior, setIdDeEmpleadoSuperior] = useState();
    const [renderizarNivelYEspecialidad] = useState(false);
    const [executeGetRequest] = useGetRequest();

    const getDetail = async (IdEmpleado) => {
        try {
            const response = await executeGetRequest(getDataEmpleadoAPI(IdEmpleado));
            if (responseCode200(response)) {
                setDetail(response.data);
                setIdDeEmpleadoSuperior(response.data.IdEmpleadoSuperior);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
        setClose(true);
    };

    const mostrarDetalleDeEmpleadoSuperior = () => {
        getDetail(idDeEmpleadoSuperior);
    };

    return (
        <tbody className="listar-tbody">
        <ModalCenter
            active={close}
            width={'100%'}
            title={'Información de contacto'}
            onClose={() => setClose(!close)}>
            <div style={{display: 'flex'}}>
                <div>
                    <img className='img' src={detail.ProfileImageUrl} alt=''/>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                        }}>
                        {detail.CanSendWhatsApp && (
                            <div>
                             <p data-tip data-for="BottonWhatsAppModal" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20' style={{'marginRight': '15px'}}/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id={'BottonWhatsAppModal'} effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(detail.Celular).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(detail.Celular)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                        )}
                        {detail.Email && (
                            <div>
                             <p data-tip data-for="BottonCorreoModal" style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id={"BottonCorreoModal"} effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png'  onClick={() => copyToClipboard(detail.Email).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(detail.Email)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                        )}
                    </div>
                </div>
                <WrapperModal className='wrapper'>
                    <div className='nombre-completo'>{detail.NombreCompleto}</div>
                    <div className='area-cargo'>{detail.Sede}</div>
                    <div className='area-cargo'>{detail.NombreCargo}</div>
                    <div className='tiempo-en-innova'>{detail.TiempoEnInnova} en Innova Schools</div>
                    {detail.NombreEmpleadoSuperior &&
                    <div className='nombre-empleado-superior'>
                        Su Jefe Líder es <a onClick={mostrarDetalleDeEmpleadoSuperior}>{detail.NombreEmpleadoSuperior}</a>
                    </div>
                    }
                </WrapperModal>
            </div>
        </ModalCenter>

        {filas.map((user, i) => (
            <tr key={i}>
                <td onClick={() => {
                    getDetail(user.IdEmpleado);
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}} className="text-link-appereance font-gothan-light">
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={user.ProfileImageUrl}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{user.NombreCompleto}</div>
                    </div>
                </td>
                <td>
                    {user.ConVacaciones &&
                    <div>
                          <p data-tip data-for={"VerVaciones" + i} style={{ display: 'inline' }}>
                         <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/vacaciones.png' alt='' width='20'/>
                          </p>
                            <ReactTooltip className="tooltip-style tooltip-with-buttons" id={"VerVaciones" + i} effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    {user.PeriodoVacaciones}
                                            </div>
                                        )
                                    }}
                            />
                    </div>
                    }
                </td>
                <td>{user.NombreUnidad}</td>
                <td>{user.NombreDepartamento}</td>
                <td>{user.NombreArea}</td>
                <td>{user.NombreSeccion}</td>
                <td>{user.NombreCargo}</td>
                <td>{user.NombreSede}</td>
                {renderizarNivelYEspecialidad &&
                <>
                    <td>{user.NombreNivel}</td>
                    <td>{user.NombreEspecialidad}</td>
                </>
                }

                <td style={{width: '100px'}}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}>
                        {user.Anexo && user.CanSendWhatsApp   && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for={"BottonAnexo" + i} style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/anexo.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id={"BottonAnexo" + i} effect="solid" place="bottom" title
                                    getContent={function () {
                                    return (<div>
                                                    Anexo: <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(user.Anexo).then(() => toast.success("Anexo copiado")).catch(() => console.error('error'))}/>
                                                    <br></br>
                                                    {user.Anexo}
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                        )}
                        {user.CanSendWhatsApp && (
                            <div style={{'marginRight': '3px'}}>
                             <p data-tip data-for={"BottonWhatsAppDetalle" + i} style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id={'BottonWhatsAppDetalle' + i} effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(user.Celular).then(() => toast.success("Celular copiado")).catch(() => console.error('error'))} />
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/MensajeWhatsApp.png' onClick={() => openWhatsapp(user.Celular)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                        )}
                        {user.Email && (
                            <div>
                             <p data-tip data-for={"BottonCorreoDetalle" + i} style={{ display: 'inline' }}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                             </p>
                               <ReactTooltip className="tooltip-style tooltip-with-buttons" id={"BottonCorreoDetalle" + i} effect="solid" place="bottom"
                                    getContent={function () {
                                    return (<div>
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/AccionCopiar.png' onClick={() => copyToClipboard(user.Email).then(() => toast.success("Correo copiado")).catch(() => console.error('error'))}/>
                                                &nbsp;|&nbsp;
                                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Icons/EnviarCorreo.png' onClick={() => openGmail(user.Email)}/>
                                            </div>
                                        )
                                    }}
                                    />
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBody;
