import React, { useEffect, useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText'
import InputSelect from '../../../../components/modalComponents/InputSelect'
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading'
import InputSelectCascade from '../../../../components/modalComponents/InputSelectCascade'
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput'
import { usePropsSelect } from '../../../../hooks/useProps/usePropsSelect'
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import { CHATBOT_FAQ_TYPES } from '../../../../constants/hardCodedConstants'
import { handleFindDropdownSelected, initialAllOptionPromises } from '../../../../helper/utils'
import { editarChatbotFaqAdminAPI, getQuestionsForEditAndCreateAsDropdownChatbotFaqAPI, getTiposChatbotFaqAPI } from '../../../../consumers/backendApisUrls'

const EditarPreguntaRespuesta = ({ toggleModal, refreshPage, row, externalCallback = null }) => {
  const { IdChatbotFaq, IdType, Text, IdParent, ParentText, Url } = row;

  const KEYS = { idParent: "IdParent", idType: "IdType", text: "Text", url: "Url" };
  const SECTION_NAME = "EditarPreguntaRespuestaChatbotFaq";

  const initValues = { IdChatbotFaq, IdType, Text, IdParent: IdParent ?? 0, Url };
  const [formValues, setFormValues] = useState(initValues);
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();

  const initialPromises = () => {
    return [
      getOptions(getQuestionsForEditAndCreateAsDropdownChatbotFaqAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      await initialAllOptionPromises(initialPromises, Object.values(KEYS), setAllDropdowns);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = editarChatbotFaqAdminAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { IdParent: IdParentRaw, ...formValuesRest } = formValues;
    const IdParent = IdParentRaw || null;
    return { ...formValuesRest, IdParent }
  }

  const successSubmitCallback = async () => {
    await refreshPage();
    if(externalCallback) await externalCallback();
    toggleModal();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm titulo="Editar" showModal={toggleModal} width="650px" isSubmitting={isSubmitting} onSubmit={handleSubmit}>
      <InputSelect label='Padre' direction='column' {...selectProps(KEYS.idParent, [KEYS.idType])} value={handleFindDropdownSelected(allDropdowns, formValues, KEYS.idParent) ?? {value: IdParent, label: ParentText}} isRequired/>
      <InputSelectCascade label='Tipo' dependendOption={formValues[KEYS.idParent]} api={getTiposChatbotFaqAPI} direction='column' {...commonProps(KEYS.idType)} isRequired/>
      <InputTextArea label='Texto' direction='column' {...commonProps(KEYS.text)} isRequired/>
      {(formValues[KEYS.idType] === CHATBOT_FAQ_TYPES.respuesta) &&
        <InputText label='Url' direction='column' {...commonProps(KEYS.url)}/>
      }
    </ModalForm>
  )
}

export default EditarPreguntaRespuesta