import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarPopup from './modales/EditarPopup';
import PageTable from '../../components/pageComponents/PageTable';
import ActionsIcons from '../../components/atoms/ActionsIcons';
import ModalActivarInactivarGeneric from '../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import { activarInactivarPopupAPI } from '../../consumers/backendApisUrls';

const TablePopup = ({ formValues, setFormValues, setModalEnabled, paginator, setPaginator}) => {
  const rows = formValues.Items;

  const HEADERS = [
    {col: "col-sm-1", label: "Id"},
    {col: "col-sm-2", label: "Título"},
    {col: "col-sm-1", label: "Fecha Inicio"},
    {col: "col-sm-1", label: "Fecha Fin"},
    {col: "col-sm-1", label: "Estado"},
    {col: "col-sm-3", label: "Descripción"},
    {col: "col-sm-1", label: "Acciones"},
  ]
  
  return (
    <PageTable headers={HEADERS} paginator={paginator} setPaginator={setPaginator}>
      <tbody className='listar-tbody'>
        {rows.map(row => (
          <tr key={row.IdPopup}>
            <td className='text-center'>{row.IdPopup}</td>
            <td className='text-center'>{row.Titulo}</td>
            <td className='text-center'>{row.FechaInicio}</td>
            <td className='text-center'>{row.FechaFin}</td>
            <td className='text-center'>{row.Estado}</td>
            <td className='text-center'>{row.Descripcion}</td>
            <td className='text-center'>
              <ActionsIcons>
                <FontAwesomeIcon
                  icon="edit"
                  title='Editar Popup'
                  size='lg'
                  onClick={() => setModalEnabled({ isEnable: true, component: EditarPopup, data: { popup: row }})}
                />
                <FontAwesomeIcon
                  icon={row.EsActivo ? "ban" : "circle-notch"}
                  title={row.EsActivo ? "Inactivar" : "Activar"}
                  size='lg'
                  onClick={() => setModalEnabled({ isEnable: true, component: ModalActivarInactivarGeneric, data: { api: activarInactivarPopupAPI, payload: { IdPopup: row.IdPopup }, name: row.Titulo, state: row.EsActivo }})}
                />
              </ActionsIcons>
            </td>
          </tr>
        ))}
      </tbody>
    </PageTable>
  )
}

export default TablePopup