import React, { useContext, useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import GenericModal from '../../../components/modal/GenericModal';
import { cargarResultadosPruebaLaboratorioAPI, descargarFormatoResultadoPruebaLaboratorio } from '../../../consumers/backendApisUrls';
import { useGenerarDescargarEliminarArchivoGET } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivoGET';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const CargarResultados = ({ showModal }) => {

  const [uploadedFile, setUploadedFile] = useState([]);
  const ID_INPUT_FILE = "AgregarArchivo"
  const sectionName = "AgregarArchivo"
  const EXTENSIONES_VALIDAS = ["xls", "xlsx"];

  const [managePostRequest] = useManagePostRequest();
  const [submitForm] = useSubmitForm();
  const [descargarArchivoGenerado] = useGenerarDescargarEliminarArchivoGET();

  const enviarArchivo = async () => {
    const api = cargarResultadosPruebaLaboratorioAPI();
    const payload = {
      File: uploadedFile
    };
    let allInputKeys = ["File"];

    await submitForm(allInputKeys, api, payload, sectionName, () => { showModal() });
  };

  const downloadFormato = async () => {
    await descargarArchivoGenerado(descargarFormatoResultadoPruebaLaboratorio());
  };

  return <>
    <GenericModal showModal={showModal} width={"850px"} titulo={"Cargar Resultados"}>
      <div>
        <div>
          <form className="blue-standard-color">
            <div>
              Seleccionar plantilla con datos a cargar

              <div className="black-color">
                <GenericUploadFileV2 uploadFileToS3={setUploadedFile} id={ID_INPUT_FILE} textButton={"Cargar archivo"} extensionesValidas={EXTENSIONES_VALIDAS} />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-File-validation-message`}></p>
              </div>
            </div>
            <div>
              Puedes descargar la plantilla aquí
              <button
                type='button'
                className='generic-button-buscador ml-2'
                onClick={downloadFormato}>
                Descargar
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="modal-footer mt-2">
          <div className="message font-gothan-light">
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={enviarArchivo}>Enviar</button>
          </div>
        </div>
      </div>
    </GenericModal>
  </>;
};

export default CargarResultados;
