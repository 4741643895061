import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {useForm} from "../../../hooks/useForm/UseForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    getCurrentDay,
    getCurrentMonth,
    getCurrentYear,
    isEmpty,
    formatNumber,
} from "../../../helper/utils";
import {toast} from "react-toastify";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import SelectInput from "../../../components/Select/SelectInput";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {buscarColaboradorAPI, guardarVacacionesAPI} from "../../../consumers/backendApisUrls";

const RegistrarVacacionesModal = ({toggleModal, manageSearch}) => {
    const sectionName = "RegistrarVacacionesModal";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [colaboradoresFiltradosOptions, setColaboradoresFiltradosOptions] = useState([]);
    const [jsonSelectedColaboradorValue, setJsonSelectedColaboradorValue] = useState({});
    const [cargandoColaboradores, setCargandoColaboradores] = useState(false);
    const [selectInputColaboresPlaceholder, setSelectInputColaboresPlaceholder] = useState("");
    const [currentYear] = useState(getCurrentYear());
    const [currentMonth] = useState(getCurrentMonth());
    const [currentDay] = useState(getCurrentDay());

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const submit = async () => {
        let payload = {
            "IdEmpleadoReemplazo": jsonSelectedColaboradorValue["value"],
            "FechaFin": formValues["FechaFin"],
            "FechaInicio": formValues["FechaInicio"]
        };

        let response = await executePostRequest(guardarVacacionesAPI(), payload);

        if (responseCode200(response)) {
            toggleModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdEmpleadoReemplazo", "FechaFin", "FechaInicio"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

    };

    const init = async () => {
        let formInputValues = getInitialFormValues();
        setFormValues(formInputValues);
    };

    const getInitialFormValues = () => {
        return {
            "IdEmpleadoReemplazo": null,
            "FechaFin": "",
            "FechaInicio": ""
        };
    };

    const buscarColaborador = async () => {
        if (isEmpty(criterioDeBusqueda)) {
            toast.warn("Ingresar un criterio de búsqueda.");
            return;
        }

        setCargandoColaboradores(true);
        setSelectInputColaboresPlaceholder("");
        let response = await executeGetRequest(buscarColaboradorAPI(criterioDeBusqueda));

        if (responseCode200(response)) {
            let options = response.data.map((option) => ({value: option["IdEmpleado"], label: option["FullName"]}));
            setColaboradoresFiltradosOptions(options);
            setSelectInputColaboresPlaceholder("Seleccionar colaborador");
        } else {
            setColaboradoresFiltradosOptions([]);
            setSelectInputColaboresPlaceholder("");
            mostrarMensajeDeErrorConToast(response);
        }
        setCargandoColaboradores(false);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            buscarColaborador();
        }
    };

    return (
        <>
            <GenericModal showModal={toggleModal} width={"900px"} titulo={"Registrar Vacaciones y Asignar Responsable de Actividades"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <hr/>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Buscar colaborador</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control modal-addCese-input-buscador" placeholder='Nombre, Apellidos, DNI, Correo' name="datosColaborador" value={criterioDeBusqueda}
                                           onChange={(e) => {
                                               setCriterioDeBusqueda(e.target.value);
                                           }}
                                           onKeyPress={handleKeypress}></input>
                                    <button
                                        onClick={buscarColaborador}
                                        type='button'
                                        className='btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'>
                                        Buscar
                                    </button>
                                </div>
                            </div>

                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Seleccionar Colaborador<span className="required">*</span></label>
                                <div className="col-sm-6 modal-ceses-input-select-colaboradores-filtrados">
                                    <SelectInput options={colaboradoresFiltradosOptions} jsonSelectedValue={jsonSelectedColaboradorValue} setJsonSelectedValue={setJsonSelectedColaboradorValue} isDisabled={false} placeholder={selectInputColaboresPlaceholder}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEmpleadoReemplazo-validation-message`}></p>
                                </div>
                                {cargandoColaboradores && (<><FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner modal-ceses-input-select-spinner-cargando-colaboradores"/></>)}
                            </div>
                            <hr/>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Fecha Inicio<span className="required">*</span></label>
                                <div className="col-sm-6">
                                    <input type="date" min={currentYear + '-' + formatNumber(currentMonth) + '-' + formatNumber(Number(currentDay) + 1)} className="form-control" name="FechaInicio"
                                           value={formValues.FechaInicio} onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Fecha Fin<span className="required">*</span></label>
                                <div className="col-sm-6">
                                    <input type="date" min={currentYear + '-' + formatNumber(currentMonth) + '-' + formatNumber(Number(currentDay) + 1)} className="form-control" name="FechaFin"
                                           value={formValues.FechaFin} onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaFin-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <hr/>
                <div>
                    <div className="d-flex justify-content-around mb-2">
                        <div className="messageCeses font-gothan-light align-self-start">
                            <strong>
                                <p className="mb-0">* Campos obligatorios</p>
                                <p className="mb-0">* Este registro permitirá reasignar las actividades al colaborador que se indique durante el periodo de vacaciones del titular responsable.</p>
                            </strong>
                        </div>
                        <div className="align-self-end">
                            <button className="btn btn-primary generic-button-ficha-modal font-gothan-light mr-1" onClick={submit}>Enviar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default RegistrarVacacionesModal;