import React, {useContext, useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {downloadFile} from "../../../consumers/backendConsumer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../../hooks/context/authContext";
import {getDetailsByIdCeseAPI} from "../../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";

const DetalleRenunciaModal = ({showModal, idCese}) => {
    const {state} = useContext(AuthContext);
    const [cese, setCese] = useState({});
    const [executeGetRequest] = useGetRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        const response = await executeGetRequest(getDetailsByIdCeseAPI(idCese));
        if (responseCode200(response)) {
            setCese(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"600px"} titulo={"Detalle de Renuncia"}>
                <div className="modal-body" style={{paddingTop: '0px'}}>
                    <div className="blue-standard-color">
                        <hr className="hr-bottom"/>

                        <h6 className="blue-standard-color"><strong>Entrega a Rendir</strong></h6>
                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Tiene Asignación<span className="required"></span></label>
                            <div className="col-sm-7 black-color">{cese?.ErTieneAsignacionValue}</div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Acta<span className="required"></span></label>
                            <div className="col-sm-7 black-color"> {cese.ErFile &&
                            <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer blue-standard-color" title="Descargar" onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, cese?.ErFile?.Path, cese?.ErFile?.OriginalFileName);
                            }}/>
                            }</div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Monto<span className="required"></span></label>
                            <div className="col-sm-7 black-color">{cese?.ErMonto}</div>
                        </div>

                        <hr className="hr-bottom"/>
                        <h6 className="blue-standard-color"><strong>Asignación de equipos</strong></h6>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Tiene equipo asignado?<span className="required"></span></label>
                            <div className="col-sm-7 black-color">{cese?.AeTieneEquipoAsignadoValue}</div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Acta<span className="required"></span></label>
                            <div className="col-sm-7 black-color">
                                {cese.AeActaFile &&
                            <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer blue-standard-color" title="Descargar" onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, cese?.AeActaFile?.Path, cese?.AeActaFile?.OriginalFileName);
                            }}/>
                            }</div>
                        </div>


                        <hr className="hr-bottom"/>
                        <h6 className="blue-standard-color"><strong>Devolución de equipos</strong></h6>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Devolución Equipos<span className="required"></span></label>
                            <div className="col-sm-7 black-color">{cese.DeHaDevueltoEquipoValue}</div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Acta<span className="required"></span></label>
                            <div className="col-sm-7 black-color">
                                {cese.DeActaFile &&
                                <FontAwesomeIcon icon='paperclip'  size="lg" className="hand-pointer blue-standard-color" title="Descargar" onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile(state.token, cese?.DeActaFile?.Path, cese?.DeActaFile?.OriginalFileName);
                                }}/>
                                }
                            </div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Monto<span className="required"></span></label>
                            <div className="col-sm-7 black-color">{cese?.DeMonto}</div>
                        </div>

                        <div className="row custom-form-group-modal">
                            <label className="col-sm-5 modal-label">Cotización<span className="required"></span></label>
                            <div className="col-sm-7 black-color">
                                {cese.DeCotizacionFile &&
                                <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer blue-standard-color" title="Descargar" onClick={(e) => {
                                    e.preventDefault();
                                    downloadFile(state.token, cese?.DeCotizacionFile?.Path, cese?.DeCotizacionFile?.OriginalFileName);
                                }}/>
                                }
                            </div>
                        </div>
                        <hr className="hr-bottom"/>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default DetalleRenunciaModal;

