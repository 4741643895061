import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../../hooks/context/authContext";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericUploadFileV2 from "../../components/GenericUploadFile/GenericUploadFileV2";
import { AlertErrorMaxPdfFileSizeMessage } from "../../components/alerts/AlertErrorMaxFileSizeMessage";
import { isNullOrUndefinded, jsonEstaVacio, obtenerElementPorId } from '../../helper/utils';
import { downloadFile } from "../../consumers/backendConsumer";
import { getCurriculumFileAPI, guardarCurriculumFileAPI, eliminarCurriculumFileAPI } from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';

const SeccionViewCurriculum = ({ empleadoId, puedoEditar }) => {
    const { state, dispatch } = useContext(AuthContext);
    const sectionName = "ExperienciaLaboral";
    const UPDATE_CURRICULUM_INPUT_FILE = "ficha-edit-curriculum-file";
    const EXTENSIONES_VALIDAS = ["pdf"];
    const [filedata, setFileData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [loadButton, setLoadButton] = useState(true);
    const [uploadFileCurriculum, setUploadFileCurriculum] = useState();
    const [executeGet] = useGetRequest();
    const [executePost] = usePostRequest();
    const [submitForm] = useSubmitForm();

    const getInitialData = async () => {
        setLoading(true);
        try {
            await getFileData();
        } catch (error) {
            console.error(error)
        }
        setLoading(false);
    }
    
    useEffect(() => {
        let unmounted = false;
        if (!unmounted){ 
            getInitialData();
            setDisabledBasedOnPermissions();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        if (uploadFileCurriculum?.Path) onSubmit();
    }, [uploadFileCurriculum]);

    const setDisabledBasedOnPermissions = () => {
        if (buttonDisabled || !puedoEditar){
            let elemento = obtenerElementPorId(UPDATE_CURRICULUM_INPUT_FILE);
            if (elemento){
                elemento.disabled = true;
                elemento.nextSibling.style.cursor = "default";
            }
        }
    }

    const getFileData = async () => {
        let response = await executeGet(getCurriculumFileAPI(empleadoId));
        if (responseCode200(response)) {
            setFileData(response.data);
        } else {
            setFileData(null);
        }
    }

    const onSubmit = async () => {
        setButtonDisabled(true);
        let payload = armarPayload();
        let api = guardarCurriculumFileAPI();
        let keys = ["File"];
        await submitForm(keys, api, payload, sectionName, successCallback);
    }

    const deleteCurriculum = async () => {
        if (puedoEditar === true) {
            try {
                let response = await executePost(eliminarCurriculumFileAPI(empleadoId));
                if (responseCode200(response)) {
                    toast.success(response.data.Message);
                    await successCallback();
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            } catch (e) {
                console.error(e);
            }
        }

    }

    const successCallback = async () => {
        setLoadButton(false);
        await getFileData();
        setUploadFileCurriculum(null);
        setButtonDisabled(false);
        setLoadButton(true);
    }


    const armarPayload = () => {
        return {
            IdEmpleado: empleadoId,
            File: uploadFileCurriculum
        }
    }

    return (
        <>
            <div className='displayFlexWrap'>
                <div className='w-100'>
                    <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Curriculum Vitae</p>
                </div>
            </div>
            {loading &&
                <div className="spinner-cargando-container">
                    <Spinner animation="grow" variant="primary" size="1sm" />
                </div>
            }
            {!loading &&
                <div className='card-body'>
                    <form id='frmEditCurriculum' className='ficha-edit-form'>
                        <div className='d-flex justify-content-between'>
                            <div className="d-flex w-100" id='ficha-edit-inputGroup-curriculum'>
                                <div className='col-2 mt-2'>
                                    <label className='ficha-edit-form-label'>Documento CV + Grado Académico</label>
                                </div>
                                {filedata &&
                                    <div className='col-5'>
                                        <div className='mt-2'>
                                            <a className='mx-4 hand-pointer blue-standard-color' onClick={(e) => {
                                                e.preventDefault();
                                                downloadFile(state.token, filedata.FilePath, filedata.OriginalFileName);
                                            }}><FontAwesomeIcon icon='paperclip' transform="left-9" /> {filedata.OriginalFileName} </a>


                                            <FontAwesomeIcon icon='trash' size='lg' title='Eliminar' className={puedoEditar === true ? "hand-pointer blue-standard-color" : "hand-pointer blue-standard-disabled-color"} onClick={deleteCurriculum} />

                                        </div>
                                    </div>
                                }
                                {puedoEditar &&
                                    <div className='col-7 d-flex flex-column justify-content-center'>
                                        {loadButton &&
                                            <div>
                                                <GenericUploadFileV2
                                                    uploadFileToS3={setUploadFileCurriculum}
                                                    id={UPDATE_CURRICULUM_INPUT_FILE}
                                                    textButton={"Subir archivo"}
                                                    extensionesValidas={EXTENSIONES_VALIDAS}
                                                    ToastArchivoNoValido={AlertErrorMaxPdfFileSizeMessage} 
                                                    showToastSuccess={false}/>
                                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-File-validation-message`}></p>
                                            </div>
                                        }
                                        <div>
                                            <label className="upload-file-sustento-solicitud-cambio-message font-gothan-light">
                                                <strong>* El formato permitido es un .pdf de máximo 8MB.</strong>
                                            </label>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </form>
                </div>
            }

        </>
    );
};

export default SeccionViewCurriculum
