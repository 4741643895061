import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputText from '../../../../components/modalComponents/InputText';
import InputSearchAsync from '../../../../components/modalComponents/InputSearchAsync';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { getResponsableEmpleadoAPI } from '../../../../consumers/backendApisUrls';

const ReasignarSolicitud = ({ toggleModal, refreshPage, row, api }) => {

  const KEYS = {aprobador: "IdAprobador"};
  const SECTION_NAME = ["ReasignarAprobadorSolicitud"];

  const [selectedAprobador, setSelectedAprobador] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [commonProps] = usePropsInputs(selectedAprobador, setSelectedAprobador, SECTION_NAME);
  const [submitForm] = useSubmitForm()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api(), payload, SECTION_NAME, successReasignarVacacionesCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { IdSolicitudCuponTiempoLibre } = row
    return {
      IdSolicitudCuponTiempoLibre,
      ...selectedAprobador
    }
  }

  const successReasignarVacacionesCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  return (
    <ModalForm showModal={toggleModal} titulo="Reasignar solicitud" width="600px" onSubmit={handleSubmit} isSubmitting={isSubmitting}>
      <InputText label='Solicitante' value={row.NombresCompletos} isDisabled />
      <InputText label='Aprobador' value={row.Aprobador} isDisabled />
      <InputText label='Periodo' value={`${row.FechaInicio} - ${row.FechaFin}`} isDisabled />
      <InputSearchAsync label='Nuevo Aprobador' api={getResponsableEmpleadoAPI} {...commonProps(KEYS.aprobador)} />
    </ModalForm>
  )
}

export default ReasignarSolicitud