import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DatosPersonales from '../../gestionNuevosIngresos/ficha/tabs/DatosPersonales';
import TabsHeader from '../../../components/pageComponents/TabsHeader';
import PageHeader from '../../../components/pageHeader/PageHeader';
import PageButtons from '../../../components/pageComponents/PageButtons';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { KEYS_FORM_NUEVOS_INGRESOS as KEYS, TIPOS_CIERRE_REQUERIMIENTO, TIPOS_CONTRATO } from '../../../constants/hardCodedConstants';
import { completarRequerimientoPersonalInternoAPI, crearNuevoIngresoAPI, getEditRequerimientoPersonalAPI } from '../../../consumers/backendApisUrls';

const CrearNuevoIngreso = () => {

  const SECTION_NAME = "NuevaFichaByGestionRequerimiento";
  const history = useHistory();
  const { id } = useParams();

  const [isSending, setIsSending] = useState(false);
  const [formValuesCreate, setFormValuesCreate] = useState({ IdGerencia: "", IdArea: "", IdSeccion: "", EsReingreso: false });
  const [initialData, setInitialData] = useState({ IdGerencia: "", IdArea: "", IdSeccion: "", EsReingreso: false });

  const [submitForm] = useSubmitForm();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    await executeGet(getEditRequerimientoPersonalAPI(id), ({ data }) => successInitCallback(data));
  }

  const successInitCallback = ({ IdCargo, IdSedePrincipal, IdSedeSecundaria, IdTipoTrabajador, IdTipoEmpleado, IdSolicitante, Solicitante, IdTipoContrato, IdRegimenLaboral, IdResponsable, FechaFin, IdEspecialidadSeleccion, IdEmpleadoReemplazo, EmpleadoReemplazo }) => {
    let newData = {
      IdCargo,
      IdSede: IdSedePrincipal,
      IdSedeSecundaria,
      IdTipoTrabajador,
      IdTipoEmpleado,
      IdTipoContrato,
      IdRegimenLaboral,
      IdResponsableProceso: IdResponsable,
      FechaVencimientoContrato: FechaFin,
      IdEmpleadoLider: IdSolicitante,
      EmpleadoLiderText: Solicitante,
      IdEspecialidadSeleccion
    }
    if (IdTipoContrato === TIPOS_CONTRATO.SUPLENCIA){
      newData = { ...newData, IdColaboradorSuplencia: IdEmpleadoReemplazo, ColaboradorSuplenciaText: EmpleadoReemplazo }
    }
    setInitialData(lastData => ({...lastData, ...newData}));
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = crearNuevoIngresoAPI();
      await submitForm(KEYS, api, formValuesCreate, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successSubmitCallback = async ({ data }) => {
    const { IdNuevosIngresosEmpleado: IdNuevoIngreso } = data;
    const api = completarRequerimientoPersonalInternoAPI();
    const payload = { IdRequerimientoPersonal: parseInt(id), IdNuevoIngreso, IdTipoCierreRequerimiento: TIPOS_CIERRE_REQUERIMIENTO.NuevoIngreso };
    await submitForm(KEYS, api, payload, SECTION_NAME, () => history.push('/adminGestionRequerimientos'));
  }

  const OPTIONS = [{id: "DatosPersonales", title: "Datos Personales", component: <DatosPersonales sectionName={SECTION_NAME} setFormValuesData={setFormValuesCreate} initialData={initialData}/>}];

  useEffect(() => {
    init();
  }, [])

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile fixed-page-header">
        <PageHeader title="Nueva Ficha - Datos Colaborador" />
        <div className="generic-buscador-container mb-4">
          <label className="ml-2">(<span className="required">*</span>) Dato Obligatorio</label>
          <PageButtons hasBackButton>
            <button id="CreateNewEntry" type="button" className="btn btn-primary generic-button-ficha-modal" onClick={handleCreate} disabled={isSending}>
              Guardar
            </button>
          </PageButtons>
        </div>
      </div>
      <TabsHeader list={OPTIONS} tabFichaClass="tabFichaClass fixed-page-header first-section-separator"/>
    </div>
  );
};

export default CrearNuevoIngreso