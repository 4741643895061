import React, {} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, stringSeEncuentraEnArray} from "../../helper/utils";

const TableWebBody = ({refreshTabla, datos, idsItemsConCheck, setIdsItemsConCheck, showEditarItemModal}) => {
    const checkboxClic = (e, item) => {
        let value = e.target.checked;
        let idItem = item["IdItem"];
        let tempItems = idsItemsConCheck;
        if (value === true && !stringSeEncuentraEnArray(idItem, tempItems)) {
            tempItems = agregarStringAArraydDeStringsSiNoExiste(idItem, tempItems);
            setIdsItemsConCheck(tempItems);
        } else if (value === false && stringSeEncuentraEnArray(idItem, tempItems)) {
            tempItems = eliminarStringDeArraydDeStrings(idItem, tempItems);
            setIdsItemsConCheck(tempItems);
        }
    };

    return (
        <>
            <tbody className="listar-tbody">
            {!refreshTabla && datos.map((dato, i) => (
                <tr key={i}>
                    <td>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <input type="checkbox" onClick={(e) => checkboxClic(e, dato)} value={true}></input>
                            <div style={{margin: '0 10px'}}>{dato.NombreItem}</div>
                        </div>
                    </td>
                    <td>{dato.Descripcion}</td>
                    <td>{dato.Estado}</td>
                    <td>{dato.Codigo01}</td>
                    <td>{dato.Codigo02}</td>
                    <td>
                        <div className="row text-center">
                            <div className="col">
                                <FontAwesomeIcon icon='edit' transform="left-8" className="hand-pointer  blue-standard-color" onClick={() => showEditarItemModal(dato.IdItem)}/>
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
            </tbody>
        </>
    );
};

export default TableWebBody;