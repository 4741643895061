import React, {useState} from 'react';
import styled from 'styled-components';

import ModalCenter from '../../components/atoms/ModalCenter';
import {openGmail, openWhatsapp} from "../../components/utils";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {FROM_MI_EQUIPO} from "../../constants/hardCodedConstants";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDataEmpleadoAPI} from "../../consumers/backendApisUrls";

const WrapperModal = styled.div`
  margin-left: 50px;
  color: rgb(99, 99, 99);

  .nombre-completo {
    font-size: 22px;
    font-weight: bold;
  }
  
  .area-cargo {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 5px;
  }
  
  .tiempo-en-innova {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 15px;
  }
  
  .nombre-empleado-superior {
    font-size: 1rem;
    font-weight: 300;
    margin-top: 5px;
    a {
      color: #7ab6f9;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
  }
`;

const TableWebBodyMiEquipo = ({users}) => {
    const [close, setClose] = useState(false);
    const [detail, setDetail] = useState({});
    const [idDeEmpleadoSuperior, setIdDeEmpleadoSuperior] = useState();
    const [executeGetRequest] = useGetRequest();

    const showModalOrRedirectToFichaDeEmpleado = async (IdEmpleado, soySuJefe) => {
        if (soySuJefe === true) {
            console.debug("es lider de equipo");
            window.open(`/fichadedatos/${FROM_MI_EQUIPO}/${IdEmpleado}`, '_self');
        } else {
            console.debug("no es lider de equipo");
            await mostrarModalConDetalleEmpleado(IdEmpleado)
        }
    };

    const mostrarModalConDetalleEmpleado = async (IdEmpleado) => {
        try {
            const response = await executeGetRequest(getDataEmpleadoAPI(IdEmpleado));
            if (responseCode200(response)) {
                setDetail(response.data);
                setIdDeEmpleadoSuperior(response.data.IdEmpleadoSuperior);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
        setClose(true);
    };

    const mostrarDetalleDeEmpleadoSuperior = async () => {
        await mostrarModalConDetalleEmpleado(idDeEmpleadoSuperior);
    };

    return (
        <tbody className="listar-tbody">
        <ModalCenter
            active={close}
            width={'100%'}
            title={'Información de contacto'}
            onClose={() => setClose(!close)}>
            <div style={{display: 'flex'}}>
                <div>
                    <img className='img' src={detail.ProfileImageUrl} alt=''/>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px',
                        }}>
                        {detail.CanSendWhatsApp && (
                            <div
                                style={{display: 'flex'}}
                                onClick={() => openWhatsapp(detail.Celular)}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20' style={{'marginRight': '15px', cursor: 'pointer'}}/>
                            </div>
                        )}

                        {detail.Email && (
                            <div
                                style={{display: 'flex'}}
                                onClick={() => openGmail(detail.Email)}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20' style={{cursor: 'pointer'}}/>
                            </div>
                        )}
                    </div>
                </div>
                <WrapperModal className='wrapper'>
                    <div className='nombre-completo'>{detail.NombreCompleto}</div>
                    <div className='area-cargo'>{detail.Sede}</div>
                    <div className='area-cargo'>{detail.NombreCargo}</div>
                    <div className='tiempo-en-innova'>{detail.TiempoEnInnova} en Innova Schools</div>
                    {detail.NombreEmpleadoSuperior &&
                    <div className='nombre-empleado-superior'>
                        Su Jefe Líder es <a onClick={mostrarDetalleDeEmpleadoSuperior}>{detail.NombreEmpleadoSuperior}</a>
                    </div>
                    }
                </WrapperModal>
            </div>
        </ModalCenter>

        {users.map((user, i) => (
            <tr key={i}>
                <td onClick={async () => {
                    await showModalOrRedirectToFichaDeEmpleado(user["IdEmpleado"], user["SoySuJefe"]);
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}} className="text-link-appereance font-gothan-light">
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={user.ProfileImageUrl}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{user.NombreCompleto}</div>
                    </div>
                </td>
                <td>{user.Departamento}</td>
                <td>{user.Area}</td>
                <td>{user.Cargo}</td>
                <td>{user.FechaCumpleaños}</td>
                <td>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                        }}>
                        {user.CanSendWhatsApp && (
                            <div
                                style={{display: 'flex'}}
                                onClick={() => openWhatsapp(user.Celular)}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/whatsapp.png' alt='' width='20'/>
                            </div>
                        )}

                        {user.Email && (
                            <div
                                style={{display: 'flex'}}
                                onClick={() => openGmail(user.Email)}>
                                <img src='https://intranetinnova.github.io/IntranetFileRepository/Notificaciones/gmail.png' alt='' width='20'/>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyMiEquipo;
