import React from 'react';
import GenericModal from "../../../components/modal/GenericModal";

const ModalInformativoRenuncias = ({toggleModal, showRegistrarCeseModal}) => {
    const mostrarModalInformarRenuncia = function () {
        toggleModal();
        showRegistrarCeseModal();
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"750px"} titulo={"Importante"}>
                <div className="modal-body">
                    <div className="font-gothan-light">
                        <p>Antes de informar la renuncia del colaborador en el sistema, se recomienda validar los siguientes requisitos formales de la carta:</p>
                        <ul>
                            <li>DNI</li>
                            <li>Nombres Completos</li>
                            <li>Indicar último día de labores</li>
                            <li> El documento debe estar firmado por el colaborador</li>
                        </ul>
                        <a className='card-link hand-pointer' target="_blank" href="https://drive.google.com/file/d/115QHtnbs413SI7kI8Z0-P4oPYMGc_J79/view?usp=sharing"><strong>CARTILLA DE LIQUIDACIÓN DE BBSS</strong></a>
                    </div>
                </div>
                <div className="modal-footer d-flex  justify-content-end">
                    <button type="button" className="btn btn-primary generic-button-ficha-modal font-gothan-light" onClick={mostrarModalInformarRenuncia}>Entendido</button>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalInformativoRenuncias;

