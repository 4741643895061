import React, {useState} from 'react';
import {ESTADOS_ACTUALIZACION_FICHA} from "../../constants/hardCodedConstants";
import {jsonNoEstaVacio, notNullAndNotUndefinded} from "../../helper/utils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const FichaDatosSeccionTitleMessage = ({statusUpdate}) => {
    const [color, setColor] = useState("");
    const [statusFichaSeccionUpdate, setStatusFichaSeccionUpdate] = useState({});

    useDidMountEffect(() => {
        console.debug("[FichaDatosSeccionTitleMessage] statusUpdate new: ", statusUpdate);
        if (notNullAndNotUndefinded(statusUpdate) && jsonNoEstaVacio(statusUpdate)) {
            setStatusFichaSeccionUpdate(statusUpdate);
        }
    }, [statusUpdate]);

    useDidMountEffect(() => {
        if (statusUpdate) {
            console.debug("[FichaDatosSeccionTitleMessage] statusFichaSeccionUpdate: ", statusFichaSeccionUpdate);
            switch (statusFichaSeccionUpdate.IdEstado) {
                case ESTADOS_ACTUALIZACION_FICHA.pendiente.id : {
                    setColor("#ff0000");
                    console.debug("[FichaDatosSeccionTitleMessage] pendiente");
                    break;
                }
                case ESTADOS_ACTUALIZACION_FICHA.observado.id : {
                    setColor("#ff0000");
                    console.debug("[FichaDatosSeccionTitleMessage] observado");
                    break;
                }
                case ESTADOS_ACTUALIZACION_FICHA.aprobado.id : {
                    setColor("#565656");
                    console.debug("[FichaDatosSeccionTitleMessage] aprobado");
                    break;
                }
                default: {
                    console.warn("status id no es válido: ", statusFichaSeccionUpdate);
                }
            }
        }
    }, [statusFichaSeccionUpdate]);

    return (
        <>
            <div>
                {statusFichaSeccionUpdate.Estado &&
                <>
                    <p>
                        <span className="font-gothan-light blue-standard-color"><strong>Estado de Registro: &nbsp;&nbsp;&nbsp;</strong></span>
                        <span style={{"color": color}}> {statusFichaSeccionUpdate.Estado}</span>
                    </p>
                </>
                }
            </div>
        </>
    )
};

export default FichaDatosSeccionTitleMessage;