import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import Wrapper from '../../../components/atoms/DirectorioWrapper';
import PageHeader from "../../../components/pageHeader/PageHeader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {onKeyPressEnter} from '../../../helper/utils';
import TableWebBody from "./TableWebBody";
import Paginator from "../../directorio/Paginator";
import { getTemplatesByProcesoCierreAPI, getAllCargosWithTodosOptionAPI, getAllTiposContratoWithTodosOptionAPI, getAllCondicionesWithTodosOptionAPI, getAllJornadasLaboralesWithTodosOption, getTemplatesProcesoCierre, asignarTemplateToProcesoCierreAPI, editarAsignacionTemplateByProcesoCierreAPI } from "../../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import {v4 as uuidv4} from 'uuid';
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { toast } from "react-toastify";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import { notNullAndNotUndefinded } from "../../../helper/utils";
import EliminarPlantilla from "./modales/EliminarPlantilla";

const AsignarPlantillas = () => {
  const { id: idProcesoCierre } = useParams();
  const keys = ["IdPlantilla","VacacionesPeriodoInicio","VacacionesPeriodoFin","CompensacionesPeriodoInicio","CompensacionesPeriodoFin","Cargo", "TipoContrato", "Condicion", "TipoEmpleado"];

  const [managePostRequest] = useManagePostRequest();
  const [executePostRequest] = usePostRequest();
  const [getOptions] = useGetOptionsSelect();
  
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [templatesSeleccionados, setTemplatesSeleccionados] = useState([])
  const [allCargos, setAllCargos] = useState([]);
  const [allTiposContrato, setAllTiposContrato] = useState([]);
  const [allCondiciones, setAllCondiciones] = useState([]);
  const [allPlantillas, setAllPlantillas] = useState([]);
  const [allTiposEmpleado, setAllTiposEmpleado] = useState([]);

  const [filas, setFilas] = useState([]);
  const [filasEditar, setFilasEditar] = useState([]);
  const [puedeAsignar, setPuedeAsignar] = useState(false);
  const [nameProceso, setNameProceso] = useState("");
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  
  const [loading, setLoading] = useState(true);
  const [hideModalEliminarPlantilla, setHideModalEliminarPlantilla] = useState(false);
  const [idEliminar, setIdEliminar] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const history = useHistory();

  const getInitialData = async () => {
    setLoading(true);
    try {
      await manageSearch();
      await getDropDownsOptions();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const manageSearch = async () => {
    let payload = obtenerPayload();
    await managePostRequest(getTemplatesByProcesoCierreAPI(), payload, successGetAllCallBack, false);
  };

  const getDropDownsOptions = async () => {
    setAllCargos(await getOptions(getAllCargosWithTodosOptionAPI()));
    setAllTiposContrato(await getOptions(getAllTiposContratoWithTodosOptionAPI()));
    setAllCondiciones(await getOptions(getAllCondicionesWithTodosOptionAPI()));
    setAllPlantillas(await getOptions(getTemplatesProcesoCierre()));
    setAllTiposEmpleado(await getOptions(getAllJornadasLaboralesWithTodosOption()));
  };

  const obtenerPayload = () => {
    let payload = {
      "Page": page,
      "Criteria": criterioDeBusqueda,
      "IdProcesoCierre": idProcesoCierre,
      "IdsSeleccionados" : templatesSeleccionados
    };
    return payload;
  };

  const successGetAllCallBack = (response) => {
    setFilas(response.data?.result);
    setPuedeAsignar(response.data?.procesoCierre?.PuedeAsignar);
    setNameProceso(response.data?.procesoCierre?.Nombre)
  };

  const limpiarBusqueda = () => {
    setCriterioDeBusqueda("");
    setTemplatesSeleccionados([]);
  }

  const onSubmit = async (id) => {
    const dataToSend = filas.filter(fila => fila.IdProcesoCierrePlantillasConfig === id )[0];
    let response = "";
    if (typeof id != 'string'){
      response = await executePostRequest(editarAsignacionTemplateByProcesoCierreAPI(), dataToSend)
    } else{
      response = await executePostRequest(asignarTemplateToProcesoCierreAPI(), dataToSend)
    }
    if(responseCode200(response)){
      toast.success(response?.data?.Message);
      clearAllInputValidations(keys, id);
      manageSearch();
      setButtonDisabled(false);
    } else if(responseTieneModelState(response)) {
      FailOnSubmitCallBack(response, keys, id);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const cancelarSubmit = (id) => {
    if(typeof id != 'string'){ 
      const filaOriginal = filasEditar.filter(filaEditar => filaEditar.IdProcesoCierrePlantillasConfig === id)[0];
      const insertFilaOriginal = filas.map(fila => {
        if(fila.IdProcesoCierrePlantillasConfig === id){
          fila = filaOriginal;
        }
        return fila;
      })
      setFilas(insertFilaOriginal);
      const clearFilaOriginal = filasEditar.filter(filaEditar => filaEditar.IdProcesoCierrePlantillasConfig != id);
      setFilasEditar(clearFilaOriginal);
    } else{
      const clearFilaOriginal = filas.filter(fila => fila.IdProcesoCierrePlantillasConfig != id);
      setFilas(clearFilaOriginal);
      setButtonDisabled(false);
    }
    clearAllInputValidations(keys, id);
  }

  const FailOnSubmitCallBack = (response, keys, id) => {
    let modelState = response?.response?.data?.ModelState;
    clearAllInputValidations(keys, id);

    for (let [key, inputValidationMessage] of Object.entries(modelState)){
      let elementId = `asignar-proceso-validation-message-${key}-row-${id}`;
      updateValidationsMessage(elementId, inputValidationMessage[0])
    }
  }

  const clearAllInputValidations = (keys,id) => {
    for(let key of keys){
      let elementId = `asignar-proceso-validation-message-${key}-row-${id}`;
      updateValidationsMessage(elementId, "");
    }
  }

  const updateValidationsMessage = (elementId, message) => {
    let element = document.getElementById(elementId);
    if(notNullAndNotUndefinded(element, "")){
      element.innerHTML = message;
    } else{
      console.warn(`updateValidationMessage. Elemento no existe.`)
    }
  }

  const editar = (obj) => {
    const filaOriginal = {...obj};
    setFilasEditar([...filasEditar, filaOriginal]);
    const estadoEditar = filas.map(fila => {
      if(fila.IdProcesoCierrePlantillasConfig === obj.IdProcesoCierrePlantillasConfig){
          fila.EstadoEditar = true;
      } 
      return fila;
    });
    setFilas(estadoEditar);
  }

  const handleChangeInput = (e, id) => {
    const newInputFields = filas.map(fila => {
      if (id === fila.IdProcesoCierrePlantillasConfig){
        fila[e.target.name] = e.target.value;
      }
      return fila;
    });
    setFilas(newInputFields)
  }

  const addFilaToList = (e) => {
    if (e) e.preventDefault();
    setButtonDisabled(true);

    let emptyFila = {
      "IdProcesoCierrePlantillasConfig": uuidv4(),
      "IdPlantilla": null,
      "VacacionesPeriodoInicio": null,
      "VacacionesPeriodoFin": null,
      "CompensacionesPeriodoInicio": null,
      "CompensacionesPeriodoFin": null,
      "IdProcesoCierre": idProcesoCierre,
      "Cargo": [],
      "TipoContrato": [],
      "Condicion": [],
      "TipoEmpleado" : [],
      "EstadoEditar" : true,
      "VacacionesDiasCount" : 0,
      "CompensacionesDiasCount" : 0,
    }

    let newFilas = [emptyFila, ...filas];
    setFilas(newFilas);
  }

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
      const nextPage = Math.min(page + 1, totalPages);
      setPage(nextPage);
  };

  const toggleEliminarPlantillaModal = () => {
    setHideModalEliminarPlantilla(!hideModalEliminarPlantilla);
  }

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    setPage(1);
  },[criterioDeBusqueda]);

  useEffect(() => {
    manageSearch();
  },[templatesSeleccionados])

  useEffect(() => {
    manageSearch();
  },[page]);

  if (loading) {
    return (
      <div className="spinner-cargando-container">
        <Spinner animation="grow" variant="primary" size="1sm" />
      </div>
    );
  }

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">

        {hideModalEliminarPlantilla && 
          <div className="modal-contactanos modal-response-ficha-background">
            <EliminarPlantilla
              toggleModal={toggleEliminarPlantillaModal}
              refreshTable={manageSearch}
              title={idEliminar.title}
              idprocesoCierreConfig={idEliminar.id}
            />
          </div>
        }

        <Wrapper className="App">
          <PageHeader title={`${nameProceso} - Asignación de plantilla`} />
          <div className="generic-buscador-container">
                  <div className="filtros-left-section">
                    <div className="filtros-left-section">
                      <input
                            type='text'
                            className='generic-input-criterio-busqueda'
                            placeholder='Buscar plantilla'
                            value={criterioDeBusqueda}
                            onChange={(e) => setCriterioDeBusqueda(e.target.value)}
                            onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}
                        />
                      <button
                            onClick={manageSearch}
                            type='button'
                            className='generic-button-buscador'>
                            Buscar
                      </button> 
                      <a className="clean-filters " 
                      onClick={limpiarBusqueda}
                      >Limpiar búsqueda</a>
                    </div>

                  </div>
                  <div className="filtros-right-section">
                    <button className='generic-button-buscador' onClick={() => history.goBack()}>
                      <FontAwesomeIcon icon='angle-left' size="lg" className="hand-pointer" title="Atras" /> Atrás
                    </button>
                    {
                      puedeAsignar && !buttonDisabled &&
                        <button
                            onClick={addFilaToList}
                            type='button'
                            className='generic-button-buscador'
                            disabled={buttonDisabled ? "disabled" : ""}>
                            Crear Registro
                        </button>
                    }
                  </div>
                </div>
                <div className='container-fluid body-content'>
                  <div className='table-responsive form-group-sm pt-3'>
                    <table className='table table-hover listar-table-cierre-anio'>
                        <thead className="listar-thead">
                            <tr>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Plantilla</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Cargo</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Tipo Contrato</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Condición</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Tipo Empleado</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Vacaciones Días</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Vacaciones Períodos</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Compensación Días</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Compensación Períodos</span>
                                </th>
                                <th className="col-1 text-center">
                                    <span className='font-gothan-medium d-inline'>Acciones</span>
                                </th>
                            </tr>
                        </thead>
                        <TableWebBody filas={filas} setFilas={setFilas} editar={editar} setIdEliminar={setIdEliminar} toggleEliminarPlantillaModal={toggleEliminarPlantillaModal} 
                         allCargos={allCargos} allTiposContrato={allTiposContrato} allCondiciones={allCondiciones} allTiposEmpleado={allTiposEmpleado} allPlantillas={allPlantillas}
                         handleChangeInput={handleChangeInput} onSubmit={onSubmit} cancelarSubmit={cancelarSubmit}/>
                    </table>
                    {totalPages > 0 && (
                            <Paginator 
                                      totalItems={totalItems}
                                      firsItemPage={firsItemPage}
                                      lastItemPage={lastItemPage}
                                      totalPages={totalPages}
                                      page={page}
                                      onLeftClic={onLeftClic}
                                      onRightClic={onRightClic}
                                      />
                        )}
                  </div>
                </div>
        </Wrapper>
      </div>
    </>
  )
}

export default AsignarPlantillas;
