import React, { useContext, useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import GenericModal from '../../../components/modal/GenericModal';
import {
    deleteFileFromS3API,
    downloadFormatoCargaMensualAPI,
    getCargarResutadosEvaluacion,
    saveCargaMensualAPI
} from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { jsonEstaVacio, setStateWithCallBackResponse } from '../../../helper/utils';
import { AuthContext } from '../../../hooks/context/authContext';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import {AlertErrorMaxExcelFileSizeMessage} from "../../../components/alerts/AlertErrorMaxFileSizeMessage";

const CargaMensualColaboradores = ({ showModal, refreshTable, toggleCargaMensualColaboradoresSuccess }) => {
    const UPDATE_REGISTRO_MENSUAL_INPUT_FILE = "ficha-edit-registro_mensual-file";
    const EXTENSIONES_VALIDAS = ["xls", "xlsx"];
    const sectionName = "CargaMensualColaboradores";
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const { state } = useContext(AuthContext);
    const [uploadFileCargaMensual, setUploadFileCargaMensual] = useState();
    const [isDisableSubmitButton, setIsDisableSubmitButton] = useState(false);
    const [isDisableDownloadButton, setIsDisableDownloadButton] = useState(false);
    const [procesoCierre, setProcesoCierre] = useState({});
    const allInputKeys = ["File"];

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        await setStateWithCallBackResponse(executeGetRequestCallback(getCargarResutadosEvaluacion()), setProcesoCierre);
    };

    const downloadFormato = async () => {
        setIsDisableDownloadButton(true)
        const response = await executePostRequest(downloadFormatoCargaMensualAPI());
        if (responseCode200(response)) {
            let responseFile = await downloadFile(state.token, response.data.FilePath, response.data.OriginalFileName);
            if (responseCode200(responseFile)) {
                console.debug("downloadFormato response code 200")
                await eliminarArchivoDeS3(response.data.FilePath);
            } else {
                console.debug("downloadFormato response code != 200")
                mostrarMensajeDeErrorConToast(responseFile);
            }
        } else {
            console.debug("generar archivo != 200");
            mostrarMensajeDeErrorConToast(response);
        }
        setIsDisableDownloadButton(false)
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        console.debug("eliminarArchivoDeS3 before")
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        console.debug("eliminarArchivoDeS3 after")
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            console.debug("delete file from s3 != successfully");
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    const submit = async (event) => {
        setIsDisableSubmitButton(true);
        try {
            event.preventDefault();
            let payload = {
                "File": !jsonEstaVacio(uploadFileCargaMensual) ? uploadFileCargaMensual : null,
            };
            let response = await executePostRequest(saveCargaMensualAPI(), payload);
            if (responseCode200(response)) {
                refreshTable();
                toggleCargaMensualColaboradoresSuccess(response?.data?.Message ?? "");
                showModal();
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            setIsDisableSubmitButton(false);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <GenericModal showModal={showModal} width={"750px"} titulo={"Carga mensual de colaboradores"}>
            <div className="modal-body" style={{ height: "100%" }}>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-justify">
                        Proceso
                    </label>
                    <div className="black-color">
                        <p className="pt-2">{procesoCierre?.NombreProcesoCierre}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-3 col-form-label text-justify">
                        Cargar archivo
                    </label>
                    <div className="black-color">
                        <GenericUploadFileV2
                            uploadFileToS3={setUploadFileCargaMensual}
                            id={UPDATE_REGISTRO_MENSUAL_INPUT_FILE}
                            textButton={"Subir archivo"}
                            extensionesValidas={EXTENSIONES_VALIDAS} 
                            ToastArchivoNoValido={AlertErrorMaxExcelFileSizeMessage}/>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-File-validation-message`}></p>
                    </div>
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha" onClick={downloadFormato} disabled={isDisableDownloadButton}>
                        {isDisableDownloadButton ? "Descargando" : "Descargar formato"}
                        {isDisableDownloadButton && <>&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner" /></>}
                    </button>
                </div>
                <div className="button">
                    <button className="btn btn-primary generic-button-ficha" onClick={submit} disabled={isDisableSubmitButton}>
                        {isDisableSubmitButton ? "Cargando" : "Enviar"}
                        {isDisableSubmitButton && <>&nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner" /></>}
                    </button>
                </div>
            </div>
        </GenericModal>
    );
}

export default CargaMensualColaboradores;