import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { initialAllOptionPromises } from '../../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import InputText from '../../../../../components/modalComponents/InputText';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { KEYS_DATOS_IDENTIFICACION_NI, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, TIPOS_DOCUMENTO_IDENTIDAD } from '../../../../../constants/hardCodedConstants';
import { getEstadosCivilesAPI, getGenerosAPI, getTiposDocumentoAPI, getDatosIdentificacionGestionAPI, saveDatosIdentificacionAPI } from '../../../../../consumers/backendApisUrls';

const DatosIdentificacion = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, inputDisabled, isColaborador, manageSearchListaDocumentos, sendCurrentStep, setSendCurrentStep, componentStep }) => {

  const SECTION_NAME = "EditarDatosIdentificacionFichaNuevosIngresos";
  const KEYS_INIT = {TipoDocumento: "IdTipoDocumento", Genero: "IdGenero", EstadoCivil: "IdEstadoCivil"};

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  
  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isEditingForm, setIsEditingForm] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonSelectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getTiposDocumentoAPI()),
      getOptions(getGenerosAPI()),
      getOptions(getEstadosCivilesAPI()),
    ]
  }

  const init = async () => {
    try {
      await manageSearch();
      initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), successInitCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    const api = getDatosIdentificacionGestionAPI(idNuevosIngresosEmpleado);
    await executeGet(api, (res) => setFormValues(res.data));
  }

  const successInitCallback = async (res) => {
    setAllDropdowns(res);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSending(true);
    try {
      const api = saveDatosIdentificacionAPI();
      await submitForm(KEYS_DATOS_IDENTIFICACION_NI, api, formValues, SECTION_NAME, successSubmitCallback, !isColaborador, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    manageSearch();
    manageSearchListaDocumentos();
    if(isColaborador) setSendCurrentStep(lastData => ({...lastData, isSent: false, isSaved: true}));
  }

  useDidMountEffect(() => {
    if (!isLoading && !isSending) setIsEditingForm(true);
  }, [formValues])

  useDidMountEffect(() => {
    if (isColaborador && sendCurrentStep?.step === componentStep && sendCurrentStep?.isSent){
      handleSubmit();
    }
  }, [sendCurrentStep])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <form onSubmit={handleSubmit} className={`${isColaborador ? "form-colaborador-inputs" : ""}`}>
      <CardAccordion id={OPTIONS.idDatosIdentificacion} idParent={ID_PARENT} label="1. Datos de Identificación" isShow isShowGeneral={!isColaborador}>
        <div className="displayFlexWrap mt-4">
          <DivCol required><InputSelect label="Tipo de documento" {...commonSelectProps("IdTipoDocumento", ["NumeroDocumento", "NumeroPasaporte"])} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="N° de documento" {...commonProps("NumeroDocumento")} direction='column' isDisabled={inputDisabled}/></DivCol>
          {formValues?.IdTipoDocumento === TIPOS_DOCUMENTO_IDENTIDAD.CEX &&
              <DivCol required><InputText label="Pasaporte" {...commonProps("NumeroPasaporte")} direction='column' isDisabled={inputDisabled}/></DivCol>
            }
          <DivCol required><InputText label="Nombres" {...commonProps("Nombres")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Apellido Paterno" {...commonProps("ApellidoPaterno")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Apellido Materno" {...commonProps("ApellidoMaterno")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Sexo" {...commonSelectProps("IdGenero")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputSelect label="Estado Civil" {...commonSelectProps("IdEstadoCivil")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Correo Personal" {...commonProps("Correo")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol><InputText label="Teléfono Fijo" {...commonProps("Telefono")} direction='column' isDisabled={inputDisabled}/></DivCol>
          <DivCol required><InputText label="Celular Personal" {...commonProps("Celular")} direction='column' isDisabled={inputDisabled}/></DivCol>
        </div>
        {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending || !isEditingForm}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosIdentificacion;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""}`}>
      {children}
    </div>
  )
}