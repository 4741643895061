import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {descargarCartaAutorizacionDatosPersonales, guardarCartaAutorizacionFirmada} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {AlertErrorMaxFileSizeCeses} from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import { useDescargarArchivoYEliminarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3';
import { GENERIC_SUCCESS_TOAST_MESSAGE } from '../../../constants/hardCodedConstants';


const FirmarCartaAutorizacionModal = ({showModal, idEmpleado, idEmpleadoFlujoActividad, manageSearch}) => {

    const {state} = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();
    const [validation, setValidation] = useState({});
    const [descargarArchivoGenerado] = useDescargarArchivoYEliminarArchivoDeS3();

    const sectionName = "modalFirmarCartaAutorizacionModal";
    const UPDATE_SUSTENTO_INPUT_FILE = sectionName + "-sustento-file";

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
       
    };

    useEffect(() => {
        setValidation({});
    }, [s3UploadFileResponse]);

    const onSubmit = async (e) => {
        let payload = {
            IdEmpleado: idEmpleado,
            IdEmpleadoFlujoActividad: idEmpleadoFlujoActividad,
            FileViewModel: s3UploadFileResponse
        };
        const response = await executePostRequest(guardarCartaAutorizacionFirmada(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            setValidation(response?.response?.data.ModelState);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
     };

    const downloadCartaAutorizacion = async (idEmpleado) => { 
        let payload = {
            "IdEmpleado": idEmpleado
        };
        const response = await executePostRequest(descargarCartaAutorizacionDatosPersonales(), payload);
        if (responseCode200(response)) {
            var file= response?.data?.fileViewModel;
            descargarArchivoGenerado(file.OriginalFileName, file.Path);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"890px"} titulo="Firmar carta de autorización de datos personales">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-3 col-form-label modal-label">Cargar Sustento<span className="required">*</span></label>
                                <div className="col-sm-9 flex-row justify-content-center ">
                                    <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponse} id={UPDATE_SUSTENTO_INPUT_FILE} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                                                         subiendoArchivoPublicState={subiendoArchivo} setSubiendoArchivoPublicState={setSubiendoArchivo}/>
                                    {validation["CartaAutorizacionFirmada"] && validation["CartaAutorizacionFirmada"].length > 0  && <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Sustento-validation-message`}>{validation["CartaAutorizacionFirmada"]}</p>}
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <u>
                                    <a className="text-link-appereance" href="#" onClick={() => downloadCartaAutorizacion(idEmpleado)}><FontAwesomeIcon icon='download'/> Carta de autorización de datos personales</a>
                                </u>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                            <p>* El formato permitido es un archivo .pdf de máximo 8MB.</p>
                        </strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit} disabled={subiendoArchivo}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default FirmarCartaAutorizacionModal;