import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { AlertErrorMaxFileSizeCeses } from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../../consumers/httpRequiestsUtils";
import { toast } from "react-toastify";
import { GENERIC_SUCCESS_TOAST_MESSAGE } from '../../../constants/hardCodedConstants';
import { downloadFile } from '../../../consumers/backendConsumer';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { downloadCartaObjetivo, guardarCartaObjetivoFirmada } from '../../../consumers/backendApisUrls';

const FirmaCartaObjetivoModal = ({ showModal, idEmpleado, idEmpleadoFlujoActividad, manageSearch, nombreActividad }) => {
    const { state } = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest] = useGetRequest();
    const [validation, setValidation] = useState({});

    const sectionName = "modalFirmaCartaObjetivoModal";
    const UPDATE_SUSTENTO_INPUT_FILE = sectionName + "-sustento-file";

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {

    };

    const onSubmit = async (e) => {
        let payload = {
            IdEmpleado: idEmpleado,
            IdEmpleadoFlujoActividad: idEmpleadoFlujoActividad,
            FileViewModel: s3UploadFileResponse
        };
        const response = await executePostRequest(guardarCartaObjetivoFirmada(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            setValidation(response?.response?.data.ModelState);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const downloadCartaBonoObjetivo= async (event) => { 
        event.preventDefault();
        const response = await executeGetRequest(downloadCartaObjetivo(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            downloadFile(state.token, response.data.FilePath, response.data.OriginalFileName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
      <>
        <GenericModal
          showModal={showModal}
          width={"890px"}
          titulo={nombreActividad}>
          <div className="margin-modal-body">
            <div>
              <form className="black-color-important">
                <div className="row custom-form-group-modal">
                  <label className="col-sm-3 col-form-label modal-label">
                    Cargar Carta<span className="required">*</span>
                  </label>
                  <div className="col-sm-9 flex-row justify-content-center ">
                    <GenericUploadFileV2
                      uploadFileToS3={setS3UploadFileResponse}
                      id={UPDATE_SUSTENTO_INPUT_FILE}
                      textButton={"Subir Archivo"}
                      extensionesValidas={["pdf"]}
                      ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                      subiendoArchivoPublicState={subiendoArchivo}
                      setSubiendoArchivoPublicState={setSubiendoArchivo}
                    />
                    {validation["CartaObjetivoFirmada"] &&
                      validation["CartaObjetivoFirmada"].length > 0 && (
                        <p
                          className="ficha-form-input-validation-message"
                          id={`ficha-edit-${sectionName}-Sustento-validation-message`}>
                          {validation["CartaObjetivoFirmada"]}
                        </p>
                      )}
                  </div>
                </div>
                <div className="row custom-form-group-modal">
                  <u>
                    <a
                      className="text-link-appereance"
                      href="#"
                      onClick={(event) => downloadCartaBonoObjetivo(event)}>
                      <FontAwesomeIcon icon="download" /> Carta de bono por objetivo
                    </a>
                  </u>
                </div>
              </form>
            </div>
          </div>

          <div
            className="modal-footer"
            style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              className="message font-gothan-light"
              style={{ alignSelf: "flex-start" }}>
              <strong>
                <p>* Campos obligatorios</p>
                <p>* El formato permitido es un archivo .pdf de máximo 8MB.</p>
              </strong>
            </div>
            <div className="button" style={{ alignSelf: "flex-end" }}>
              <button
                className="btn btn-primary generic-button-ficha"
                onClick={onSubmit}
                disabled={subiendoArchivo}>
                Guardar
              </button>
            </div>
          </div>
        </GenericModal>
      </>
    );
}

export default FirmaCartaObjetivoModal;