import React, { useState } from 'react'
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { enviarReporteTxtONPAPI } from '../../consumers/backendApisUrls';

const SECTION_NAME = "ReporteTxtEmpleadosONP";

const ReporteTxtEmpleadosONP = () => {
  const [isSending, setIsSending] = useState(false);
  const [submitForm] = useSubmitForm();

  const handleSendReport = async () => {
    setIsSending(true);
    const api = enviarReporteTxtONPAPI();
    const payload = {};
    try {
      await submitForm([], api, payload, SECTION_NAME, () => {});
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <CardBody>
      <Button handleSendReport={handleSendReport} isSending={isSending} />
    </CardBody>
  )
}

export default ReporteTxtEmpleadosONP

const CardBody = ({ children }) => (
  <div className='card-body'>
    <div id={SECTION_NAME}>
      {children}
    </div>
  </div>
)

const Button = ({ handleSendReport, isSending }) => (
  <div className='col-sm-5'>
    <button type='button' className="btn btn-primary generic-button-ficha" onClick={handleSendReport} disabled={isSending}>
      {isSending ? 'Enviar Reporte ...' : 'Enviar Reporte'}
    </button>
  </div>
)

const DIV = ({ children }) => (
  <div className='col-sm-5'>
    {children}
  </div>
)
