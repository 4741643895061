import React, {useEffect} from "react";
import Select from "react-select";

const MultiSelectInput = ({options, selectedValues, setSelectedValues, isDisabled, placeholder, resetSelectedValues = true}) => {

    useEffect(() => {
        let unmounted = false;
        if (!unmounted && resetSelectedValues === true) {
            setSelectedValues([])
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const handleSelectInputChange = (e) => {
        setSelectedValues(Array.isArray(e) ? e.map(x => x.value) : []);
    };

    return (
        <>
            <div>
                <Select
                    placeholder={placeholder}
                    value={options.filter(obj => selectedValues.includes(obj.value))}
                    options={options}
                    onChange={handleSelectInputChange}
                    isMulti
                    isClearable
                    isDisabled={isDisabled}
                />
            </div>
        </>
    )
};

export default MultiSelectInput;