import {useContext} from "react";
import {AuthContext} from "../context/authContext";
import {deleteFileFromS3API} from "../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {downloadFile} from "../../consumers/backendConsumer";
import {usePostRequest} from "../usePostRequest/usePostRequest";

export const useDescargarArchivoYEliminarArchivoDeS3 = () => {
    const [executePostRequest] = usePostRequest();
    const {state} = useContext(AuthContext);

    const descargarArchivoYEliminarArchivoDeS3 = async function (originalFileName, filePath) {
        let descargarArchivoResponse = await downloadFile(state.token, filePath, originalFileName);
        if (responseCode200(descargarArchivoResponse)) {
            await eliminarArchivoDeS3(filePath);
        } else {
            mostrarMensajeDeErrorConToast(descargarArchivoResponse);
        }
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };
    return [descargarArchivoYEliminarArchivoDeS3]
};