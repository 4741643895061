import React, { useEffect, useState } from 'react'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputText from '../../../components/modalComponents/InputText';
import InputSelect from '../../../components/modalComponents/InputSelect'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile'
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { initialAllOptionPromises } from '../../../helper/utils';
import { asistenciaLactarioGetPeriodosDisponiblesBySedeAPI, getAllSedesAPI, registrarAsistenciaLactarioAPI } from '../../../consumers/backendApisUrls';

const KEYS = { sede: "IdSede", periodo: "Periodo", file: "AsistenciaLactarioFormatoCompletadoFileViewModel" };
const KEYS_DROPDOWN = ["IdSede"];
const SECTION_NAME = "CrearRegistroAsistenciaLactario";

const CrearRegistroLactario = ({ toggleModal, refreshPage, extraData }) => {
  const { PuedeSeleccionarSede, SedeUsuarioLogueado, IdSedeUsuarioLogueado } = extraData;

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const handlePuedeSeleccionarSede = async () => {
    if (!PuedeSeleccionarSede)
      return setFormValues(prev => ({ ...prev, [KEYS.sede]: IdSedeUsuarioLogueado }));
    await getDropdowns()
  }

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getAllSedesAPI())
    ]
    await initialAllOptionPromises(dropdownsPromises, KEYS_DROPDOWN, setAllDropdowns);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = registrarAsistenciaLactarioAPI();
    const payload = formValues;
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    await refreshPage();
  }

  useEffect(() => {
    handlePuedeSeleccionarSede();
  }, [PuedeSeleccionarSede])

  if (isLoading) return <SpinnerLoading />

  const footarMessage = (
    <div className="text-danger">
      <strong>
        <p>Esta tarea está a cargo de la encargada de tópico y la realiza una vez al mes</p>
      </strong>
    </div>
  );

  return (
    <ModalForm
      titulo="Nuevo registro de asistencia lactario"
      width="650px"
      showModal={toggleModal}
      isSubmitting={isSubmitting}
      saveButtonDisabled={isUploading || isSubmitting}
      onSubmit={handleSubmit}
      footerExtraMessage={footarMessage}
      >
      {PuedeSeleccionarSede ? (
        <InputSelect label="Sede" {...selectProps(KEYS.sede)} />
      ) : (
        <InputText label="Sede" value={SedeUsuarioLogueado} isDisabled />
      )}
      <InputSelectCascade
        label="Periodo"
        dependendOption={formValues[KEYS.sede]}
        api={asistenciaLactarioGetPeriodosDisponiblesBySedeAPI}
        {...commonProps(KEYS.periodo)}
      />
      <InputUploadFile
        label="Sustento"
        name={KEYS.file}
        uploadFile={formValues[KEYS.file]}
        setUploadFile={(res) =>
          setFormValues({ ...formValues, [KEYS.file]: res })
        }
        sectionName={SECTION_NAME}
        setIsUploading={setIsUploading}
        validExtensions={["pdf"]}
      />
    </ModalForm>
  );
}

export default CrearRegistroLactario