import React from 'react';

function IconCheck(props) {
    return (
        <svg

            width='24'
            height='24'
            viewBox='0 0 24 24'
            {...props}>
            <path d='M0 0h24v24H0z' fill='none'/>
            <path d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/>
        </svg>
    );
}

export default IconCheck;
