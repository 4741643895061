import React, {useState} from 'react';
import {jsonNoEstaVacio, notNullAndNotUndefinded, stringEndsInThisCharacter} from "../../helper/utils";
import {responseCode200} from "../../consumers/httpRequiestsUtils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";

import {obtenerElementoInputDeFicha} from "../../validations/FormValidatorUtils";
import { getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI } from '../../consumers/backendApisUrls';


import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';


const SelectInputDatosEmpresa = ({dropdowns, setDropdowns, jsonKeyy, idSelect, dropdownsSelectedValues, setDropdownsSelectedValues, dataFields}) => {
    const firstGeneralJsonOptionSelect = {"Value": 0, "Text": "Seleccionar"};
    const [options, setOptions] = useState([firstGeneralJsonOptionSelect]);
    const [selectedValue, setSelectedValue] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePost] = useManagePostRequest();

    useDidMountEffect(() => {
        initOptions();
    }, [dropdowns]);

    useDidMountEffect(() => {
        if (dropdownsSelectedValues) {
            initSelectedValue();
        }
    }, [dropdownsSelectedValues]);

    const initOptions = () => {
        if (dropdowns && jsonNoEstaVacio(dropdowns)) {
            console.debug("[SelectInputDatosEmpresa] initOptions ", {jsonKeyy, dropdowns});
            if (dropdowns.hasOwnProperty(jsonKeyy)){
                let optionss = dropdowns[jsonKeyy];
                setOptions([firstGeneralJsonOptionSelect, ...optionss]);
            }else{
                console.warn(`no existe jsonKey: '${jsonKeyy}' en dropdowns`, dropdowns);
                setOptions([firstGeneralJsonOptionSelect]);
            }
        }
    };

    const initSelectedValue = () => {
        if (dropdownsSelectedValues && jsonNoEstaVacio(dropdownsSelectedValues)) {
            console.debug("[SelectInputDatosEmpresa] initSelectedValue ", {dropdownsSelectedValues});
            let value = dropdownsSelectedValues[jsonKeyy];
            setSelectedValue(value);
        }
    };

    const handleSelectInputChange = async (event) => {
        let selectedValuee = event.target.value;
        let dataFieldKey = event.target.name;

        
        console.debug("[SelectInputDatosEmpresa] handleSelectInputChange ", {selectedValue: selectedValuee, dataFieldKey});

        let newSelectedValues = {...dropdownsSelectedValues, [jsonKeyy]: selectedValuee};
        await validarSiTieneListasHijoParaActualizarSusOptions(selectedValuee, dataFieldKey, newSelectedValues);

        if (dataFieldKey === 'IdDepartamento' ||
            dataFieldKey === 'IdArea' ||
            dataFieldKey === 'IdCargo' 
         ){
            getJefeMatricial();
         }
    };

    const getSelectValue = (keyField) => {
        const nombreSeccion = "datosEmpresa";
        let element = obtenerElementoInputDeFicha(keyField, nombreSeccion);

        try {
            return element.options[element.selectedIndex].value;
        } catch (e) {
            console.warn("no se pudo obtener el value del select input ", element);
            return 0;
        }
    }

    const getJefeMatricial = async () => {
        
        var idDepartamento = getSelectValue('IdDepartamento');
        var idArea = getSelectValue('IdArea');
        var idCargo = getSelectValue('IdCargo');
       
        

        const api = getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI();
    
        const payload = {
          "IdArea": idArea,
          "IdCargo": idCargo,
          "IdDepartamento": idDepartamento
        }
        
        return await executePost(api, payload, successJefeMatricialCallback)
    }
    
    const successJefeMatricialCallback = ({ data }) => {
        /*if (formValues?.EmpleadoMatricialSuperior !== data?.EmpleadoMatricialSuperior){
          setFormValues({ ...formValues, EmpleadoMatricialSuperior: (data?.EmpleadoMatricialSuperior ?? "-")});
        }*/

          //console.log('new value ', data?.EmpleadoMatricialSuperior)
          document.getElementById("ficha-edit-datosEmpresa-EmpleadoMatricialSuperior").value = data?.EmpleadoMatricialSuperior ?? "-";

    }

    const validarSiTieneListasHijoParaActualizarSusOptions = async (selectedValuee, dataFieldKey, newSelectedValues) => {
        let dataFieldHijo = obtenerHijoEnCasoTenga(dataFieldKey);
        if (notNullAndNotUndefinded(dataFieldHijo)) {
            console.debug("[SelectInputDatosEmpresa] dataFieldHijo: ", dataFieldHijo);
            let newDropdowns = dropdowns;
            if (dataFieldHijo["FieldType"] === "List") {
                newDropdowns = await updateOptionsDePrimerHijo(dataFieldHijo, selectedValuee, newDropdowns);
            }
            newDropdowns = setEmptyOptionsToHijosQueVanDespuesDelPrimerHijoSoloSiDataRequestURLRecibeParametro(dataFieldHijo, newDropdowns);

            setDropdowns(newDropdowns);

            newSelectedValues = resetSelectedValuesFromListChilds(dataFieldHijo, newSelectedValues);
            setDropdownsSelectedValues(newSelectedValues);
        } else {
            setDropdownsSelectedValues(newSelectedValues);
        }
    };

    const obtenerHijoEnCasoTenga = (dataFieldKey) => {
        let idFieldDeHijo = obtenerIdFieldDeHijo(dataFieldKey);
        if (notNullAndNotUndefinded(idFieldDeHijo)) {
            console.debug(`[SelectInputDatosEmpresa] dataFieldKey:'${dataFieldKey}' sí tiene hijo, idFieldDeHijo:'${idFieldDeHijo}'`)
            return obtenerDataFieldPorId(idFieldDeHijo);
        } else {
            console.debug(`[SelectInputDatosEmpresa] dataFieldKey:'${dataFieldKey}' no tiene hijo`)
        }
    };

    const obtenerDataFieldPorId = (idField) => {
        for (let field of dataFields) {
            if (field["IdField"] == idField) {
                return field;
            }
        }
    };

    const obtenerIdFieldDeHijo = (dataFieldKey) => {
        console.debug(`[SelectInputDatosEmpresa] inputNameTieneHijos, dataFieldKey: ${dataFieldKey}`);
        for (let dataField of dataFields) {
            if (dataField["KeyField"] == dataFieldKey && dataField["IdDependent"] != null) {
                return dataField["IdDependent"];
            }
        }
    };

    const updateOptionsDePrimerHijo = async (dataFieldHijo, selectedValuePadre, newDropDowns) => {
        console.debug("[SelectInputDatosEmpresa] dropdowns: ", newDropDowns);

        let newOptionsDeHijo = await obtenerOptionsDeNewHijo(dataFieldHijo, selectedValuePadre);
        if (notNullAndNotUndefinded(newOptionsDeHijo)) {
            console.debug("[SelectInputDatosEmpresa] set new options to hijo ", {dataFieldHijo, newOptionsDeHijo});
            newDropDowns = {...newDropDowns, [dataFieldHijo["KeyField"]]: newOptionsDeHijo};
        }

        return newDropDowns;
    };

    const obtenerOptionsDeNewHijo = async (dataFieldHijo, selectedValuePadre) => {
        let api = dataFieldHijo["DataRequestUrl"];
        let finalApi = `${api}${selectedValuePadre}`;
        console.debug("[SelectInputDatosEmpresa] finalApi: ", finalApi);
        let newOptions = await makeRequestObtenerDropDownOptions(finalApi);
        console.debug("newOptions: ", newOptions);
        return newOptions;
    };

    const makeRequestObtenerDropDownOptions = async (api) => {
        let response = await executeGetRequest(api);
        if (responseCode200(response)) {
            console.debug("[SelectInputDatosEmpresa] response data: ", response.data);
            return response.data;
        } else {
            console.warn("[SelectInputDatosEmpresa] hubo un error al ejecutar el api: ", api);
        }
    };

    const resetSelectedValuesFromListChilds = (dataFieldHijo, newSelectedValues) => {
        let tempDataFieldHijo = dataFieldHijo;
        do {
            console.debug("[SelectInputDatosEmpresa] newSelectedValues ini ", newSelectedValues);
            if (tempDataFieldHijo["FieldType"] === "List") {
                console.debug("[SelectInputDatosEmpresa] reset selected value to cero to datafield: ", tempDataFieldHijo);
                newSelectedValues = {...newSelectedValues, [tempDataFieldHijo["KeyField"]]: 0};
            }
            console.debug("[SelectInputDatosEmpresa] newSelectedValues fin ", newSelectedValues);
            tempDataFieldHijo = obtenerHijoEnCasoTenga(tempDataFieldHijo["KeyField"]);
        } while (notNullAndNotUndefinded(tempDataFieldHijo));

        return newSelectedValues;
    };

    const setEmptyOptionsToHijosQueVanDespuesDelPrimerHijoSoloSiDataRequestURLRecibeParametro = (dataFieldHijo, newDropdowns) => {
        let tempDataFieldHijo = dataFieldHijo;
        tempDataFieldHijo = obtenerHijoEnCasoTenga(tempDataFieldHijo["KeyField"]);

        while (notNullAndNotUndefinded(tempDataFieldHijo)) {
            let keyDeHijo = tempDataFieldHijo["KeyField"];
            if (stringEndsInThisCharacter(tempDataFieldHijo["DataRequestUrl"], "/")) {
                newDropdowns = {...newDropdowns, [keyDeHijo]: []};
            }
            tempDataFieldHijo = obtenerHijoEnCasoTenga(tempDataFieldHijo["KeyField"]);
        }

        return newDropdowns;
    };

    return (
        <select className="form-control" name={jsonKeyy} id={idSelect} onChange={handleSelectInputChange}>
            {options && options.map((option) => {
                    {
                        if (option.Value == selectedValue) {
                            return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                        } else {
                            return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                        }
                    }
                }
            )}
        </select>
    )
};
export default SelectInputDatosEmpresa;