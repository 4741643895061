import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wrapper from "../../../components/atoms/DirectorioWrapper";
import PageHeader from "../../../components/pageHeader/PageHeader";
import Paginator from "../../directorio/Paginator";
import TableWebBody from "./TableWebBody";
import EliminarConfiguracionVacaciones from "./modales/EliminarConfiguracionVacaciones";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import {v4 as uuidv4} from 'uuid';
import { getAllConfiguracionesMovimientosAPI, getTiposMovimientoAPI, getResponsableEmpleadoAPI, crearConfiguracionVacacionesAPI, editarConfiguracionVacacionesAPI } from "../../../consumers/backendApisUrls";
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from "../../../helper/utils";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";

const ConfigurarVacaciones = () => {
  const SECTION_NAME = "CrearConfiguracionVacaciones";
  const KEYS = ["IdTipoMovimiento" ,"IdNivelAprobacion", "IdAprobador", "IdAprobadorAlternativo"]

  const [loading, setLoading] = useState(true);
  const [filas, setFilas] = useState([]);
  const [filasEditar, setFilasEditar] = useState([]);
  const [counterId, setCounterId] = useState(0);
  const [idConfiguracion, setIdConfiguracion] = useState();
  const [idnivelAprobacion, setIdnivelAprobacion] = useState();
  const [allMovimientos, setAllMovimientos] = useState([]);
  const [allAprobadores, setAllAprobadores] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [firsItemPage, setFirsItemPage] = useState({});
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();
  const [extraData, setExtraData] = useState({});

  const [showModalEliminarConfiguracionVacaciones, setShowModalEliminarConfiguracionVacaciones] = useState(false);

  const [managePostRequest] = useManagePostRequest();
  const [manageGetRequest] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const history = useHistory();

  const init = async () => {
    setLoading(true);
    try {
      await getDropDownsOptions();
      await getAprobadores();
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getDropDownsOptions = async () => {
    setAllMovimientos(await getOptions(getTiposMovimientoAPI()));
  }

  const getAprobadores = async () => {
    let api = getResponsableEmpleadoAPI()
    let payload = {Criteria: ""}
    await managePostRequest(api, payload, successGetAprobadoresCallback, false);
  }

  const successGetAprobadoresCallback = (res) => {
    let data = res.data;
    let valueText = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(data);
    setAllAprobadores(valueText)
  }

  const manageSearch = async () => {
    const api = getAllConfiguracionesMovimientosAPI()
    await manageGetRequest(api, successGetAllCallBack, false);
  }

  const successGetAllCallBack = (res) => {
    let data = res.data;
    setFilas(data);
    if (data.length > 0) {
      let counter = data[data.length - 1].IdNivelAprobacion;
      setCounterId(counter);
    }
  }

  const onSubmit = async (obj) => {
    let api = "";
    obj.EstadoCrear 
      ? (api = crearConfiguracionVacacionesAPI())
      : (api = editarConfiguracionVacacionesAPI())
    await submitForm(KEYS, api, obj, SECTION_NAME, successSubmitCallback);
  }

  const successSubmitCallback = () => {
    manageSearch();
    setButtonDisabled(false);
  }

  const cancelarSubmit = (id) => {
    if(typeof id != 'string'){ 
      const filaOriginal = filasEditar.filter(o => o.IdConfiguracionMovimientos == id)[0];
      const insertFilaOriginal = filas.map(fila => {
        if(fila.IdConfiguracionMovimientos == id){
          fila = filaOriginal;
        }
        return fila;
      });
      setFilas(insertFilaOriginal);
      const clearFilaOriginal = filasEditar.filter(filaEditar => filaEditar.IdConfiguracionMovimientos != id);
      setFilasEditar(clearFilaOriginal);
    } else {
      const clearFilaOriginal = filas.filter(fila => fila.IdConfiguracionMovimientos != id);
      setFilas(clearFilaOriginal);
      setButtonDisabled(false);
    }
  }

  const toggleEliminarConfiguracionVacaciones = (idConfiguracion, idnivelAprobacion) => {
    if (idConfiguracion) setIdConfiguracion(idConfiguracion);
    if (idnivelAprobacion) setIdnivelAprobacion(idnivelAprobacion);
    setShowModalEliminarConfiguracionVacaciones(!showModalEliminarConfiguracionVacaciones);
  };

  const addFilaToList = (e) => {
    if (e) e.preventDefault();
    setButtonDisabled(true);

    let emptyFila = {
      IdConfiguracionMovimientos: uuidv4(),
      IdTipoMovimiento: null,
      IdNivelAprobacion: counterId + 1,
      IdAprobador: null,
      IdAprobadorAlternativo: null,
      EstadoCrear: true,
      EstadoEditar: true,
    }

    let newFilas = [emptyFila, ...filas];
    setFilas(newFilas);
  }

  const editarFila = (obj) => {
    const filaOriginal = {...obj};
    setFilasEditar([...filasEditar, filaOriginal]);
    const estadoEditar = filas.map(fila => {
      if (fila.IdConfiguracionMovimientos === obj.IdConfiguracionMovimientos){
        fila.EstadoEditar = true;
      }
      return fila;
    });
    setFilas(estadoEditar)
  }

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
      const nextPage = Math.min(page + 1, totalPages);
      setPage(nextPage);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  if (loading) {
    return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm" />
        </div>
    );
  }
    
  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {showModalEliminarConfiguracionVacaciones && 
          <div className="modal-contactanos modal-response-ficha-background">
            <EliminarConfiguracionVacaciones showModal={toggleEliminarConfiguracionVacaciones} refreshPage={manageSearch} idConfiguracion={idConfiguracion} idnivelAprobacion={idnivelAprobacion}/>
          </div>
        }
        <Wrapper className="App">
          <PageHeader title="Configuración de Vacaciones" />
          <div className="generic-buscador-container mt-1">
            <div></div>
            <div className="filtros-right-section">
              <button className='generic-button-buscador' onClick={() => history.goBack()}>
                <FontAwesomeIcon icon='angle-left' size="lg" className="hand-pointer" title="Atras" /> Atrás
              </button>
              <button
                onClick={addFilaToList}
                type="button"
                className="generic-button-buscador"
                disabled={buttonDisabled}
              >
                Nueva Configuración
              </button>
            </div>
          </div>
          <div className="container-fluid body-content">
            <div className="table-responsive form-group-sm pt-2">
              <table className="table table-hover">
                <thead className="listar-thead">
                  <tr>
                    <th className="col-3">
                      <div className="flex font-gothan-medium">
                        Tipo Movimiento
                      </div>
                    </th>
                    <th className="col-2">
                      <div className="flex font-gothan-medium">Nivel de aprobación</div>
                    </th>
                    <th className="col-3">
                      <div className="flex font-gothan-medium">Aprobador</div>
                    </th>
                    <th className="col-3">
                      <div className="flex font-gothan-medium">Aprobador alternativo</div>
                    </th>
                    <th className="col-1">
                      <div className="flex font-gothan-medium">Acciones</div>
                    </th>
                  </tr>
                </thead>
                <TableWebBody
                  filas={filas}
                  setFilas={setFilas}
                  editarFila={editarFila}
                  showModalEliminarConfiguracionVacaciones={toggleEliminarConfiguracionVacaciones}
                  allMovimientos={allMovimientos}
                  allAprobadores={allAprobadores}
                  onSubmit={onSubmit}
                  cancelarSubmit={cancelarSubmit}
                  SECTION_NAME={SECTION_NAME}
                />
              </table>
              {totalPages > 1 && (
                  <Paginator totalItems={totalItems}
                      firsItemPage={firsItemPage}
                      lastItemPage={lastItemPage}
                      totalPages={totalPages}
                      page={page}
                      onLeftClic={onLeftClic}
                      onRightClic={onRightClic} />
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    </>
  )
}

export default ConfigurarVacaciones