import styled from "styled-components";

const Form = styled.form`
  label {
    margin-bottom: 0 !important;
  }
`

const DivUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${props => props.displayFormat ? "none" : ""};
  
  p{
    font-size: inherit !important;
    width: 135px;
    height: 54px;
    padding-top: 2px;
    padding-bottom: 0;
  }

  a, svg{
    display: none;
  }
`;

export { Form, DivUpload }