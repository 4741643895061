import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import InputText from '../../../../components/modalComponents/InputText'
import { DivUpload, Form } from './components/Styles';
import GenericModal from '../../../../components/modal/GenericModal';
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled';
import InputTextArea from '../../../../components/modalComponents/InputTextArea'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import MensajeDiasAdicionalesModal from './components/MensajeDiasAdicionalesModal';
import GenericUploadFileV2 from '../../../../components/GenericUploadFile/GenericUploadFileV2';
import { DatePicker, Div, InputDates, MiraPolitica } from './components/Components';
import { ESTADO, ID_UPLOAD_FORMAT, KEYS, KEYS_FORM, SECTION_NAME } from './components/HardCodedConstants';
import { areDatesAndFirmaSet, calculateMinDaysInputDate, calculateVacationDays, getPeriodoText, hasFormValuesChanged } from './components/Utils';
import { AuthContext } from '../../../../hooks/context/authContext'
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput'
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest'
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import MensajeConfirmacionModal from '../MensajeConfirmacionModal';
import { generateDateArray, jsonNoEstaVacio } from '../../../../helper/utils';
import { crearSolicitudVacacionesAPI, eliminarFirmaColaboradorAPI, eliminarSolicitudVacacionesCrearAPI, enviarSolicitudVacacionesAPI, getEditarSolicitudVacacionesAPI, getInfoVacacionesAPI, getSolicitudVacacionesAPI, saveEditarSolicitudVacacionesAPI, subirFirmaColaboradorAPI } from '../../../../consumers/backendApisUrls';

const SolicitarVacaciones = ({ showModal, refreshPage }) => {
  
  const {state} = useContext(AuthContext);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false })

  const [formValues, setFormValues] = useState({});
  const [formValuesRaw, setFormValuesRaw] = useState({});
  const [tempFirma, setTempFirma] = useState({});
  const [firma, setFirma] = useState(null);
  const [diasVacacionesCalculado, setDiasVacacionesCalculado] = useState(0);
  const [minDiasInput, setMinDiasInput] = useState({});
  
  const [addedDates, setAddedDates] = useState({});
  const [arrayDisabledDates, setArrayDisabledDates] = useState([]);
  const [idSolicitudMovimiento, setIdSolicitudMovimiento] = useState(null);

  const [isFirst, setIsFirst] = useState(true);
  const [isManageSearching, setIsManageSearching] = useState(false);
  const [isInfoAlreadyCalled, setIsInfoAlreadyCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isFirstStepSolicitud, setIsFirstStepSolicitud] = useState(false);
  const [isAcceptedAdditionalDays, setIsAcceptedAdditionalDays] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(uuidv4());

  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();
  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const init = async () => {
    setIsLoading(true)
    try {
      await manageSearch({});
    } catch (error) {
      console.error(error);  
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async ({ isSet = true, idSolicitudMovimientoTemp = idSolicitudMovimiento }) => {
    setIsManageSearching(true);
    if (!isInfoAlreadyCalled){
      const api = getInfoVacacionesAPI(state.user.id_empleado);
      await executeGet(api, (res) => successManageSearchCallback(res, isSet), false);
      setIsManageSearching(false);
      return setIsInfoAlreadyCalled(true);
    }
    let api = getEditarSolicitudVacacionesAPI(idSolicitudMovimientoTemp);
    await executeGet(api, (res) => successManageSearchCallback(res, isSet), false);
    setIsManageSearching(false);
  }

  const successManageSearchCallback = ({ data }, isSet) => {
    const { FechaInicioBloqueo, FechaFinBloqueo } = data;
    if (isSet) setFormValues(data);
    if (data.Firma) setFirma(data.Firma);
    if (FechaInicioBloqueo && FechaFinBloqueo) {
      const blockedDays = generateDateArray(FechaInicioBloqueo, FechaFinBloqueo);
      setArrayDisabledDates(blockedDays);
    }
  }

  const makeSolicitud = async () => {
    setIsFirstStepSolicitud(true);
    let idSolicitudMovimientoTemp = idSolicitudMovimiento;
    if (isFirst){
      idSolicitudMovimientoTemp = await handleFirstStepSolicitud();
      if (idSolicitudMovimientoTemp) setIsFirst(prev => !prev);
      setFormValuesRaw(formValues);
    }
    await handleUploadFirma(idSolicitudMovimientoTemp);
    setIsFirstStepSolicitud(false);
  }

  const handleFirstStepSolicitud = async () => {
    setIsSubmitting(true);
    try {
      let api = crearSolicitudVacacionesAPI();
      let payload = {...getEditPayload(), IdSolicitante: state.user.id_empleado}
      let idSolicitudMovimiento = null;
      await submitForm(KEYS, api, payload, SECTION_NAME, ({ data }) => {idSolicitudMovimiento = data.IdSolicitudMovimiento; setIdSolicitudMovimiento(data.IdSolicitudMovimiento)}, true)
      return idSolicitudMovimiento;
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if(!areDatesAndFirmaSet(formValues, firma)) return toast.warn("Debe ingresar todos los inputs obligatorios")
      if(hasFormValuesChanged(formValuesRaw, formValues, KEYS_FORM)) await handleEditSolicitud();
      const api = getSolicitudVacacionesAPI(idSolicitudMovimiento);
      await executeGet(api, successGetDataCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleEditSolicitud = async () => {
    const api = saveEditarSolicitudVacacionesAPI();
    const payload = getEditPayload();
    await submitForm(KEYS, api, payload, SECTION_NAME, async () => { setFormValuesRaw(formValues); await manageSearch({})}, false)
  }

  const getEditPayload = () => {
    return {
      IdSolicitudMovimiento: idSolicitudMovimiento,
      StartDate: formValues.FechaDesde,
      EndDate: addedDates.FechaHasta ?? formValues.FechaHasta,
      Comentarios: formValues.Comentarios,
      StartDateOne: formValues.FechaDesdeUno ?? addedDates.FechaDesdeUno,
      EndDateOne: formValues.FechaHastaUno ?? addedDates.FechaHastaUno,
      StartDateTwo: formValues.FechaDesdeDos ?? addedDates.FechaDesdeDos,
      EndDateTwo: formValues.FechaHastaDos ?? addedDates.FechaHastaDos,
      StartDateThree: formValues.FechaDesdeTres ?? addedDates.FechaDesdeTres,
      EndDateThree: formValues.FechaHastaTres ?? addedDates.FechaHastaTres,
    }
  }

  const successGetDataCallback = async ({ data }) => {
    const { ShowModal, MostrarMensaje, FechasAdicionales } = data;
    if (!ShowModal || isAcceptedAdditionalDays){
      const dataToModal = {estado: ESTADO, periodo: getPeriodoText(formValues)};
      setModalEnabled({ isEnable: true, component: MensajeConfirmacionModal, data: { handleSend: handleSaveSolicitudVacaciones, dataToModal }});
      return;
    }
    setModalEnabled({ isEnable: true, component: MensajeDiasAdicionalesModal, data: { formValues, setAddedDates, FechasAdicionales, MostrarMensaje, setIsAcceptedAdditionalDays, isLoading, idSolicitudMovimiento }});
  }

  const handleSaveSolicitudVacaciones = async () => {
    let api = enviarSolicitudVacacionesAPI(idSolicitudMovimiento);
    await executePost(api, {}, successSendCallback, true, () => setModalEnabled({isEnable: false}));
  }

  const successSendCallback = () => {
    setModalEnabled({isEnable: false});
    showModal();
    refreshPage();
  }

  const handleUploadFirma = async (idSolicitudMovimiento) => {
    setIsUploading(true);
    try {
      let api = subirFirmaColaboradorAPI();
      let payload = {IdSolicitudMovimiento: idSolicitudMovimiento, FirmaFile: tempFirma}
      await executePost(api, payload, (res) => successFirmarFormatoCallback(res, idSolicitudMovimiento), false, () => setTempFirma({}), false)
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }

  const successFirmarFormatoCallback = (res, idSolicitudMovimiento) => {
    setTempFirma({});
    manageSearch({ isSet: false, idSolicitudMovimientoTemp: idSolicitudMovimiento });
  }

  const handleDeleteFileFirma = async (e) => {
    e.preventDefault();
    try {
      const api = eliminarFirmaColaboradorAPI();
      let payload = {IdSolicitudMovimiento: formValues.idSolicitudMovimiento, FirmaPath: firma.FilePath}
      await executePost(api, payload, successDeleteFirmaCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const successDeleteFirmaCallback = (res) => {
    setFirma(null);
  }

  const handleClose = () => {
    handleEliminarSolicitudCreada();
    showModal();
  }

  const handleEliminarSolicitudCreada = () => {
    if (firma){
      const api = eliminarSolicitudVacacionesCrearAPI(idSolicitudMovimiento);
      executePost(api, {}, ()=>{}, false, null, false);
    }
  }

  useDidMountEffect(() => {
    handleEditSolicitud();
  }, [addedDates])

  useDidMountEffect(() => {
    if (jsonNoEstaVacio(tempFirma)){
      setForceUpdate(uuidv4());
      makeSolicitud();
    }
  }, [tempFirma])

  useDidMountEffect(() => {
    if (!isFirst && firma){
      window.addEventListener('beforeunload', handleEliminarSolicitudCreada);
      return () => {
        window.removeEventListener('beforeunload', handleEliminarSolicitudCreada);
      }
    }
  }, [isFirst, firma])

  useEffect(() => {
    calculateVacationDays(formValues, setFormValues, setDiasVacacionesCalculado);
    calculateMinDaysInputDate(formValues, setMinDiasInput);
  },[formValues.FechaDesde, formValues.FechaHasta, formValues.FechaDesdeUno, formValues.FechaDesdeDos, formValues.FechaDesdeTres])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
      <GenericModal titulo="Crear solicitud de Vacaciones" showModal={handleClose} width='750px'>
        <div className="modal-body">
          <Form>
            <div className="displayFlexWrap">
              <Div><InputText label='Solicitante' value={formValues.Solicitante ?? ""} direction='column' isDisabled/></Div>
              <Div><InputText label='Email' value={formValues.Email ?? ""} direction='column' isDisabled/></Div>
              <Div><InputText label='Área' value={formValues.Area ?? ""} direction='column' isDisabled/></Div>
              <Div><InputText label='Aprobador' value={formValues.Aprobador ?? ""} direction='column' isDisabled/></Div>
              <Div col={3}><InputText label='Días disponibles' value={formValues.DiasDisponibles ?? ""} direction='column' isDisabled/></Div>
              <Div col={3}><InputText label='Días Seleccionados' value={diasVacacionesCalculado} direction='column' isDisabled/></Div> 
              <Div className="d-flex align-items-center justify-content-center"><MiraPolitica linkPolitica={formValues.LinkPolitica ?? ""}/></Div>
              <Div><InputDates label='Desde' {...commonProps("FechaDesde")} nameValidation="StartDate" minDate={minDiasInput.minStartDate} excludeDates={arrayDisabledDates} direction='column'/></Div>
              <Div><InputDates label='Hasta' {...commonProps("FechaHasta")} nameValidation="EndDate" minDate={minDiasInput.minEndDate} excludeDates={arrayDisabledDates} direction='column'/></Div>
              {formValues.FechaDesdeUno &&
                <>
                  <Div><DatePicker {...commonProps("FechaDesdeUno")} nameValidation="StartDateOne" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                  <Div><DatePicker {...commonProps("FechaHastaUno")} nameValidation="EndDateOne" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                </>
              }
              {formValues.FechaDesdeDos &&
                <>
                  <Div><DatePicker {...commonProps("FechaDesdeDos")} nameValidation="StartDateTwo" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                  <Div><DatePicker {...commonProps("FechaHastaDos")} nameValidation="EndDateTwo" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                </>
              }
              {formValues.FechaDesdeTres &&
                <>
                  <Div><DatePicker {...commonProps("FechaDesdeTres")} nameValidation="StartDateThree" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                  <Div><DatePicker {...commonProps("FechaHastaTres")} nameValidation="EndDateThree" excludeDates={arrayDisabledDates} isDisabled={!formValues.PuedeAprobar} direction='column'/></Div>
                </>
              }
              <Div col={12}><InputTextArea label='Observaciones' {...commonProps("Comentarios")} direction='column'/></Div>
            </div>
            <div className="modal-footer pl-0">
              <div className='d-flex'>
                {firma
                  ? <div className='ml-2 font-size-search-label-text'>
                      {firma?.OriginalFileName.length > 20 
                        ? `${firma?.OriginalFileName.slice(0,20)} ...`
                        : firma?.OriginalFileName
                      }
                      <FontAwesomeIcon icon='trash' size='lg' title='Eliminar' className="hand-pointer blue-standard-color ml-2" onClick={handleDeleteFileFirma} disabled={true}/>
                    </div>
                  : <div>
                      <DivUpload displayFormat={isUploading || isFirstStepSolicitud || isManageSearching || typeof tempFirma.Path == "string"} onClick={() => !(formValues.FechaDesde && formValues.FechaHasta) ? toast.warn("Debe ingresar primero las fechas") : {}}>
                        <GenericUploadFileV2
                          key={forceUpdate}
                          id={ID_UPLOAD_FORMAT}
                          extensionesValidas={["png", "jpg"]}
                          uploadFileToS3={setTempFirma}
                          textButton={"Subir imagen de tu firma"}
                          subiendoArchivoPublicState={isUploading}
                          setSubiendoArchivoPublicState={setIsUploading}
                          showToastSuccess={false}
                          resetInitialFile={false}
                          isDisabled={!(formValues.FechaDesde && formValues.FechaHasta)}
                        />
                      </DivUpload>
                      <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FileFirma-validation-message`}></p>
                      {(isUploading || isFirstStepSolicitud || isManageSearching || typeof tempFirma.Path == "string") && 
                        <>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>
                      }
                    </div>
                }
              </div>
              <div>
                <button type="button" onClick={firma && handleSubmit} className="btn generic-button-buscador" disabled={isSubmitting || isManageSearching || !firma}>
                  Enviar
                </button>
              </div>
            </div>
            <div className='form-check-label ficha-declaracion-jurada-message mt-1'>
            * Ésta firma servirá para generar tu papeleta digital y se eliminará automáticamente después de aprobarse la solicitud
          </div>
          {!firma && 
            <div className='form-check-label ficha-declaracion-jurada-message'>
            * Es importante subir tu firma para continuar con el proceso
            </div>
          }
          <div className='mb-4'></div>
          <div className="form-row">
            <div className="col-sm-4 d-flex flex-column align-items-center">
              <label className="modal-label mb-1">Fecha registo</label>
              <label className="">{formValues.FechaRegistro ?? "-"}</label>
            </div>
            <div className="col-sm-4 d-flex flex-column align-items-center">
              <label className="modal-label mb-1">Fecha modificación</label>
              <label className="">{formValues.FechaModificacion ?? "-"}</label>
            </div>
            <div className="col-sm-4 d-flex flex-column align-items-center">
              <label className="modal-label mb-1">Fecha aprobación.</label>
              <label className="">{formValues.FechaAprobacion ?? "-"}</label>
            </div>
          </div>
          </Form>
        </div>
      </GenericModal>
    </>
  )
}

export default SolicitarVacaciones;