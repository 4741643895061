import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { toast } from 'react-toastify';
import {v4 as uuidv4} from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import ActionGenericModal from '../../../../components/modal/ActionGenericModal';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled';
import ModalForm from '../../../../components/modalComponents/ModalForm'
import { ID_TODOS_OPTION_ON_DROPDOWN, OPERADORES_COMPARACION } from '../../../../constants/hardCodedConstants';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../../consumers/httpRequiestsUtils';
import { handleFind, handleFindDropdownMultiSelected, handleFindDropdownSelected, initialAllOptionPromises, notNullAndNotUndefinded } from '../../../../helper/utils';
import { crearConfiguracionProcesoTaiLoyAPI, crearValeTaiLoyAPI, editarConfiguracionProcesoTaiLoyAPI, editarValeTaiLoyAPI, eliminarConfiguracionProcesoTaiLoyAPI, eliminarValeTaiLoyAPI, getAllCargosWithTodosOptionAPI, getAllJornadasLaboralesAPI, getConfiguracionesProcesoTaiLoyByIdProcesoAPI, getDivisionesWithTodosOptionAPI, getValesTaiLoyAPI } from '../../../../consumers/backendApisUrls';

const ConfigurarProcesoModal = ({ toggleModal, refreshPage, IdProcesoSolicitudTaiLoy }) => {

  const TABLE = { vale: "descuentoVale", asignacion: "descuentoAsignacion" };
  const KEYS_VALE = { idValeTaiLoy: "IdValeTaiLoy", nombre: "Nombre", monto: "Monto", isNotDisable: "isNotDisable"};
  const KEYS_ASIGNACION = { divisiones: "IdsDivisiones", cargos: "IdsCargos", tipoEmpleado: "IdTipoEmpleado", idConfiguracionProcesoTaiLoy: "IdConfiguracionProcesoTaiLoy", signoComparador: "SignoComparador", FechaIngreso: "FechaIngreso", Monto: "Monto", isNotDisable: "isNotDisable" };

  const MODEL_STATE_BY_KEY = {
    [TABLE.vale]: KEYS_VALE,
    [TABLE.asignacion] : KEYS_ASIGNACION
  }

  const [formValues, setFormValues] = useState({ [TABLE.vale]: [], [TABLE.asignacion]: [] });
  const [formValuesToEdit, setFormValuesToEdit] = useState({ [TABLE.vale]: [], [TABLE.asignacion]: [] });
  const [allDropdowns, setAlldropdowns] = useState([]);
  const [buttonDisabled, setButtonDisabled ] = useState({ [TABLE.vale]: false, [TABLE.asignacion]: false });
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });
  const [isLoading, setIsLoading] = useState(true);

  const [executeManageGet] = useManageGetRequest();
  const [executePost] = usePostRequest();
  const [getOptions] = useGetOptionsSelect();

  const initialPromises = () => {
    return [
      getOptions(getDivisionesWithTodosOptionAPI()),
      getOptions(getAllCargosWithTodosOptionAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
    ]
  }
  
  const INITIAL_VALUES_BY_KEY = {
    [TABLE.vale]: {
      IdValeTaiLoy: uuidv4(),
      IdProcesoSolicitudTaiLoy,
      Nombre: null,
      Monto: null,
      isNotDisable: true,
    },
    [TABLE.asignacion]: {
      IdConfiguracionProcesoTaiLoy: uuidv4(),
      IdProcesoSolicitudTaiLoy,
      IdTipoEmpleado: null,
      SignoComparador: null,
      IdsDivisiones: [],
      IdsCargos: [],
      FechaIngreso: null,
      Monto: null,
      isNotDisable: true,
    }
  }

  const SAVE_API_BY_KEY = {
    [TABLE.vale]: crearValeTaiLoyAPI,
    [TABLE.asignacion]: crearConfiguracionProcesoTaiLoyAPI,
  }

  const EDIT_API_BY_KEY = {
    [TABLE.vale]: editarValeTaiLoyAPI,
    [TABLE.asignacion]: editarConfiguracionProcesoTaiLoyAPI,
  }

  const init = () => {
    setIsLoading(true);
    try {
      getDescuentoValeConfig();
      getDescuentoAsignacionConfig();
      initialAllOptionPromises(initialPromises, Object.values(KEYS_ASIGNACION), setAlldropdowns);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getDescuentoValeConfig = async () => {
    const api = getValesTaiLoyAPI(IdProcesoSolicitudTaiLoy);
    const tableKey = TABLE.vale;
    await executeManageGet(api, ({ data }) => updateFormValues(tableKey, data))
  }

  const getDescuentoAsignacionConfig = async () => {
    const api = getConfiguracionesProcesoTaiLoyByIdProcesoAPI(IdProcesoSolicitudTaiLoy);
    const tableKey = TABLE.asignacion;
    await executeManageGet(api, ({ data }) => updateFormValues(tableKey, data))
  }

  const handleAdd = (tableKey) => {
    setButtonDisabled(lastData => ({ ...lastData, [tableKey]: true }));
    const emptyRow = INITIAL_VALUES_BY_KEY[tableKey];
    let updatedValues = [...formValues[tableKey], emptyRow];
    updateFormValues(tableKey, updatedValues);
  }

  const handleChange = ({ target }, row, idKey, tableKey) => {
    const { value, name } = target;
    const id = row[idKey];
    const updatedValues = formValues[tableKey].map(item =>{
      if (item[idKey] === id){
        item[name] = value;
      }
      return item;
    })
    updateFormValues(tableKey, updatedValues);
  }

  const handleSelectChange = (res, row, nameKey, idKey, tableKey) => {
    let idsSelected = null;
    if (Array.isArray(res)){
      const resMapped = res.map(o => o.value);
      idsSelected = resMapped.find(o => o === ID_TODOS_OPTION_ON_DROPDOWN) !== undefined ? resMapped.filter(o => o === ID_TODOS_OPTION_ON_DROPDOWN) : resMapped;
    } else {
      idsSelected = res?.value ?? null;
    } 
    const id = row[idKey];
    const updatedValues = formValues[tableKey].map(item => {
      if (item[idKey] === id ){
        item[nameKey] = idsSelected;
      }
      return item;
    });
    updateFormValues(tableKey, updatedValues);
  }

  const handleCancel = (id, idKey, tableKey) => {
    const keys = Object.values(MODEL_STATE_BY_KEY[tableKey]);
    if (typeof id !== 'string') {
      const originalRow = formValuesToEdit[tableKey].find(row => row[idKey] === id);
      const originalRowInValues = formValues[tableKey].map(row => {
        if (row[idKey] === id){
          row = originalRow;
        }
        return row;
      })
      updateFormValues(tableKey, originalRowInValues);
      const valuesToEditUpdated = formValuesToEdit[tableKey].filter(row => row[idKey] !== id);
      setFormValuesToEdit(lastData => ({ ...lastData, [tableKey]: valuesToEditUpdated }));
    } else {
      const updatedValues = formValues[tableKey].filter(row => row[idKey] !== id);
      updateFormValues(tableKey, updatedValues);
      setButtonDisabled(lastData => ({ ...lastData, [tableKey]: false }));
    }
    clearAllInputValidations(keys, id);
  }

  const handleEdit = (item, idKey, tableKey) => {
    const originalRow = {...item};
    setFormValuesToEdit(lastData => ({ ...lastData, [tableKey]: [...formValuesToEdit[tableKey], originalRow] }));
    const updatedValues = formValues[tableKey].map(row => {
      if (row[idKey] === item[idKey]){
        row.isNotDisable = true;
      }
      return row;
    });
    updateFormValues(tableKey, updatedValues);
  }

  const handleSave = async (item, idKey, tableKey, manageSearch) => {
    const keys = Object.values(MODEL_STATE_BY_KEY[tableKey]);
    const id = item[idKey];
    const api = typeof id !== 'string' ? EDIT_API_BY_KEY[tableKey] : SAVE_API_BY_KEY[tableKey];
    const response = await executePost(api(), { ...item });

    if (responseCode200(response)) {
      toast.success(response.data.Message);
      clearAllInputValidations(keys, id);
      manageSearch();
      setButtonDisabled(lastData => ({ ...lastData, [tableKey]: false }));
      const valuesToEditUpdated = formValuesToEdit[tableKey].filter(row => row[idKey] !== id);
      setFormValuesToEdit(lastData => ({ ...lastData, [tableKey]: valuesToEditUpdated }));
    } else if (responseTieneModelState(response)) {
      failOnSaveRow(response, keys, id);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const failOnSaveRow = ({ response }, keys, id) => {
    const modelState = response.data?.ModelState;
    clearAllInputValidations(keys, id);

    for (let [key, inputValidationMessage] of Object.entries(modelState)){
      let elementId = `solicitud-descuento-validation-message-${key}-row-${id}`;
      updateValidationMessage(elementId, inputValidationMessage[0]);
    }
  }

  const clearAllInputValidations = (keys, id) => {
    for(let key of keys){
      let elementId = `solicitud-descuento-validation-message-${key}-row-${id}`;
      updateValidationMessage(elementId, "");
    }
  }

  const updateValidationMessage = (elementId, message) => {
    let element = document.getElementById(elementId);
    if(notNullAndNotUndefinded(element, "")){
      element.innerHTML = message;
    } else{
      console.warn(`updateValidationMessage. Elemento no existe.`)
    }
  }

  const updateFormValues = (tableKey, data) => {
    setFormValues(lastData => ({ ...lastData, [tableKey]: data }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo='Configurar Proceso de solicitud' width='1200px' hasSaveButton={false}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
      <div>
        <div className='d-flex justify-content-between'>
          <label>Vale</label>
          <button 
            type='button' 
            className='btn generic-button-buscador' 
            disabled={buttonDisabled[TABLE.vale]}
            onClick={() => handleAdd(TABLE.vale)}>
              Agregar
          </button>
        </div>
        <div className="container-fluid">
          <div className="table-scroll pt-3 w-75" style={{ maxHeight: "60vh" }}>
            <table className="table table-hover">
              <thead className="listar-thead">
                <tr className="font-gothan-medium">
                  <th className='text-center col-sm-6'>Nombre</th>
                  <th className='text-center col-sm-6'>Monto</th>
                  <th className='text-center col-sm-1'>Acciones</th>
                </tr>
              </thead>
              <tbody className='listar-tbody'>
                {formValues[TABLE.vale].map((item, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="text"
                        placeholder='Ingresar Nombre'
                        className="form-control font-size-search-label-text"
                        name={KEYS_VALE.nombre}
                        onChange={(e) => handleChange(e, item, KEYS_VALE.idValeTaiLoy, TABLE.vale)}
                        value={item[KEYS_VALE.nombre] ?? ""}
                        disabled={!item[KEYS_VALE.isNotDisable]}
                      />
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_VALE.nombre}-row-${item[KEYS_VALE.idValeTaiLoy]}`}></p>
                    </td>
                    <td>
                      <input
                        type="text"
                        placeholder='Ingresar Monto'
                        className="form-control font-size-search-label-text"
                        name={KEYS_VALE.monto}
                        onChange={(e) => handleChange(e, item, KEYS_VALE.idValeTaiLoy, TABLE.vale)}
                        value={item[KEYS_VALE.monto] ?? ""}
                        disabled={!item[KEYS_VALE.isNotDisable]}
                      />
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_VALE.monto}-row-${item[KEYS_VALE.idValeTaiLoy]}`}></p>
                    </td>
                    <td>
                      <div className='d-flex justify-content-center blue-standard-color pt-2' style={{ gap: "15px" }}>
                        {(item[KEYS_VALE.isNotDisable] === null || item[KEYS_VALE.isNotDisable] === undefined)  ?
                          <>
                            <FontAwesomeIcon icon="edit" size="lg" title="Editar" onClick={() => handleEdit(item, KEYS_VALE.idValeTaiLoy, TABLE.vale, getDescuentoValeConfig)}/>
                            <FontAwesomeIcon icon="trash" size="lg" title="Eliminar" onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { refreshPage: getDescuentoValeConfig, api: eliminarValeTaiLoyAPI, name: item[KEYS_VALE.nombre], payload: { [KEYS_VALE.idValeTaiLoy]: item[KEYS_VALE.idValeTaiLoy] }}})}/>
                          </> :
                          <>
                            <FontAwesomeIcon icon="save" size="lg" title="Guardar" onClick={() => handleSave(item, KEYS_VALE.idValeTaiLoy, TABLE.vale, getDescuentoValeConfig)}/>
                            <FontAwesomeIcon icon="times" size="lg" title="Cancelar" onClick={() => handleCancel(item[KEYS_VALE.idValeTaiLoy], [KEYS_VALE.idValeTaiLoy], TABLE.vale)} />
                          </>
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-between'>
          <label>Asignación</label>
          <button 
            type='button'
            className='btn generic-button-buscador'
            disabled={buttonDisabled[TABLE.asignacion]}
            onClick={() => handleAdd(TABLE.asignacion)}>
              Agregar
          </button>
        </div>
        <div className="container-fluid body-content px-0">
          <div className="table-scroll pt-3" style={{ maxHeight: "60vh", minHeight: "150px" }}>
            <table className="table table-hover">
              <thead className="listar-thead">
                <tr className="font-gothan-medium">
                  <th className='text-center col-sm-3'>División</th>
                  <th className='text-center col-sm-3'>Cargo</th>
                  <th className='text-center col-sm-2'>Tipo Empleado</th>
                  <th className='text-center col-sm-2'>Operador</th>
                  <th className='text-center col-sm-1'>Fecha Ingreso</th>
                  <th className='text-center col-sm-1'>Monto</th>
                  <th className='text-center'>Acciones</th>
                </tr>
              </thead>
              <tbody className='listar-tbody'>
                {formValues[TABLE.asignacion].map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div style={{ width: "230px" }}>
                        <Select
                          placeholder="Seleccionar"
                          className="font-size-search-label-text"
                          options={item[KEYS_ASIGNACION.divisiones].find(o => o === ID_TODOS_OPTION_ON_DROPDOWN) !== undefined ? allDropdowns.filter(o => o.value === ID_TODOS_OPTION_ON_DROPDOWN) : handleFind(allDropdowns, KEYS_ASIGNACION.divisiones)}
                          onChange={(res) => handleSelectChange(res, item, KEYS_ASIGNACION.divisiones, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                          value={handleFindDropdownMultiSelected(allDropdowns, item, KEYS_ASIGNACION.divisiones)}
                          isMulti
                          isClearable
                          isDisabled={!item[KEYS_ASIGNACION.isNotDisable]}
                          menuPosition='fixed'
                        />
                      </div>
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.divisiones}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <div style={{ width: "230px" }}>
                        <Select
                          placeholder="Seleccionar"
                          className="font-size-search-label-text"
                          options={item[KEYS_ASIGNACION.cargos].find(o => o === ID_TODOS_OPTION_ON_DROPDOWN) !== undefined ? allDropdowns.filter(o => o.value === ID_TODOS_OPTION_ON_DROPDOWN) : handleFind(allDropdowns, KEYS_ASIGNACION.cargos)}
                          onChange={(res) => handleSelectChange(res, item, KEYS_ASIGNACION.cargos, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                          value={handleFindDropdownMultiSelected(allDropdowns, item, KEYS_ASIGNACION.cargos)}
                          isMulti
                          isClearable
                          isDisabled={!item[KEYS_ASIGNACION.isNotDisable]}
                          menuPosition='fixed'
                        />
                      </div>
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.cargos}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <div style={{ width: "150px" }}>
                        <Select
                          placeholder="Seleccionar"
                          className="font-size-search-label-text"
                          options={handleFind(allDropdowns, KEYS_ASIGNACION.tipoEmpleado)}
                          onChange={(res) => handleSelectChange(res, item, KEYS_ASIGNACION.tipoEmpleado, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                          value={handleFindDropdownSelected(allDropdowns, item, KEYS_ASIGNACION.tipoEmpleado)}
                          isClearable
                          isDisabled={!item[KEYS_ASIGNACION.isNotDisable]}
                          menuPosition='fixed'
                        />
                      </div>
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.tipoEmpleado}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <div style={{ width: "100px" }}>
                        <Select
                          placeholder=""
                          className="font-size-search-label-text"
                          options={OPERADORES_COMPARACION}
                          onChange={(res) => handleSelectChange(res, item, KEYS_ASIGNACION.signoComparador, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                          value={OPERADORES_COMPARACION.find(o => o.value === item[KEYS_ASIGNACION.signoComparador])}
                          isClearable
                          isDisabled={!item[KEYS_ASIGNACION.isNotDisable]}
                          menuPosition='fixed'
                        />
                      </div>
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.signoComparador}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <input
                        type='date' 
                        className="form-control font-size-search-label-text" 
                        name={KEYS_ASIGNACION.FechaIngreso} 
                        onChange={(e) => handleChange(e, item, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                        value={item[KEYS_ASIGNACION.FechaIngreso] ?? ""}
                        disabled={!item[KEYS_ASIGNACION.isNotDisable]}
                      />
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.FechaIngreso}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <div style={{ width: "80px" }}>
                        <input
                          placeholder='S/'
                          type='text'
                          className="form-control font-size-search-label-text" 
                          name={KEYS_ASIGNACION.Monto}
                          onChange={(e) => handleChange(e, item, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)}
                          value={item[KEYS_ASIGNACION.Monto] ?? ""}
                          disabled={!item[KEYS_ASIGNACION.isNotDisable]}
                        />
                      </div>
                      <p className="ficha-form-input-validation-message" id={`solicitud-descuento-validation-message-${KEYS_ASIGNACION.Monto}-row-${item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]}`} />
                    </td>
                    <td>
                      <div className='d-flex justify-content-center blue-standard-color pt-2' style={{ gap: "15px" }}>
                        {(item[KEYS_ASIGNACION.isNotDisable] === null || item[KEYS_ASIGNACION.isNotDisable] === undefined)  ?
                          <>
                            <FontAwesomeIcon icon="edit" size="lg" title="Editar" onClick={() => handleEdit(item, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion, getDescuentoAsignacionConfig)} />
                            <FontAwesomeIcon icon="trash" size="lg" title="Eliminar" onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { refreshPage: getDescuentoAsignacionConfig, api: eliminarConfiguracionProcesoTaiLoyAPI, name: `Asignación ${item[KEYS_ASIGNACION.Monto]}`, payload: { [KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy]: item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy] }}})} />
                          </> :
                          <>
                            <FontAwesomeIcon icon="save" size="lg" title="Guardar" onClick={() => handleSave(item, KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion, getDescuentoAsignacionConfig)}/>
                            <FontAwesomeIcon icon="times" size="lg" title="Cancelar" onClick={() => handleCancel(item[KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy], KEYS_ASIGNACION.idConfiguracionProcesoTaiLoy, TABLE.asignacion)} />
                          </>
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </ModalForm>
  )
}

export default ConfigurarProcesoModal;