import React from 'react'
import { toast } from 'react-toastify';
import { reprocesarGrupos } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';

const GruposDeContacto = ({reload, idRegistro, informacionGeneral }) => {
  const [executePostRequest] = usePostRequest();


  const reprocesar = async () => {
    let response = await executePostRequest(reprocesarGrupos(idRegistro), {});
    if (responseCode200(response)) {
      toast.success(response.data.Message);
      reload();
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div><strong>Grupos de contacto</strong></div>
      </div>
      <div>
        {informacionGeneral?.GrupoContacto?.map(item => (
          <div key={item} className="row">
            {item}
          </div>
        ))}
      </div>
      <div>
        {informacionGeneral?.ReprocesarGrupoContacto ? (
          <button className="generic-button-buscador" onClick={reprocesar}>
            Reprocesar
          </button>
        ) : null}
      </div>
    </div>
  )
}

export default GruposDeContacto