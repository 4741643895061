import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { ESTADOS } from '../../constants/hardCodedConstants';
import { activarPoliticaAPI, cancelarReservaAPI, desactivarPoliticaAPI, descargarInformeLecturaAPI, eliminarPoliticaAPI } from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';

const TableWebBody = ({
  filas,
  toggleEditarModal,
  showModalReserva,
  toggleConfirmarEliminarModal,
  setIdReserva,
  setPoliticaTitle,
  setIsEdit,
  setPoliticaId,
  showModalOcurrencias,
  setInformacionOcurrencia,
  refreshPage
}) => {

  const [executePostRequest] = usePostRequest();
  const [managePost] = useManagePostRequest();
  const [descargarArchivo] = useGenerarDescargarEliminarArchivo();

  const showModalReservaByID = (idReserva, isEdit = false) => {
    setIdReserva(idReserva)
    setIsEdit(isEdit);
    showModalReserva()
  };

  const desactivarPolitica = async (id) => {
    await managePost(desactivarPoliticaAPI(id), {}, () => {
      refreshPage();
    }, true);
  }

  const activarPolitica = async (id) => {
    await managePost(activarPoliticaAPI(id), {}, () => {
      refreshPage();
    }, true);
  }

  const eliminarPolitica = async (id) => {
    await managePost(eliminarPoliticaAPI(id), {}, () => {
      refreshPage();
    }, true);
  }

  const descargarInformeLectura = async (id) => {
    const api = descargarInformeLecturaAPI(id);
    const payload = {};
    await descargarArchivo(api, payload);
  }

  return (
    <>
      <tbody className="listar-tbody">
        {filas.map((fila, i) => (
          <tr key={fila.IdPolitica}>
            <td>{fila?.NombrePolitica}</td>
            <td>{fila?.NombreCategoria}</td>
            <td>{fila?.NombreSubCategoria}</td>
            <td>{fila?.Vigencia}</td>
            <td>{fila?.Estado}</td>
            <td>
              <div className="d-flex justify-content-start">
                <div>
                  <FontAwesomeIcon
                    icon='edit'
                    size="lg"
                    className="hand-pointer blue-standard-color mr-2"
                    title="Editar"
                    onClick={() => {
                      setPoliticaId(fila.IdPolitica);
                      toggleEditarModal();
                    }}
                  />
                </div>
                <div>
                  {fila?.PuedeInactivar ?
                    (<FontAwesomeIcon
                      icon='user-times'
                      transform="left-1"
                      className="hand-pointer blue-standard-color mr-2"
                      title={`Desactivar`}
                      onClick={() => desactivarPolitica(fila.IdPolitica)}
                    />) : null
                  }
                </div>
                <div>
                  {fila?.PuedeActivar ?
                    (<FontAwesomeIcon
                      icon='user-check'
                      transform="left-1"
                      className="hand-pointer blue-standard-color mr-2"
                      title={`Activar`}
                      onClick={() => activarPolitica(fila.IdPolitica)}
                    />) : null
                  }
                </div>
                <div>
                  {
                    <FontAwesomeIcon
                      icon='download'
                      transform="left-1"
                      className="hand-pointer blue-standard-color mr-2"
                      title="Descargar informe de lectura"
                      onClick={() => descargarInformeLectura(fila.IdPolitica)}
                    />
                  }
                </div>
                <div>
                  {
                    <FontAwesomeIcon
                      icon='times'
                      transform="left-1"
                      className="hand-pointer blue-standard-color mr-2"
                      title={`Eliminar`}
                      onClick={() => {
                        setPoliticaId(fila.IdPolitica);
                        setPoliticaTitle(fila.NombrePolitica);
                        toggleConfirmarEliminarModal();
                      }}
                    />
                  }
                </div>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default TableWebBody;
