import React, {useState} from "react";
import Select from "react-select";
import {jsonNoEstaVacio} from "../../helper/utils";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const MultiSelectInputDatosEmpresa = ({dropdowns, setDropdowns, jsonKeyy, idSelect, dropdownsSelectedValues, setDropdownsSelectedValues, isDisabled}) => {
    const [options, setOptions] = useState([]);
    const [selectedValueMultiSelect, setSelectedValueMultiSelect] = useState([]);

    useDidMountEffect(() => {
        initOptions();
    }, [dropdowns]);

    useDidMountEffect(() => {
        if (dropdownsSelectedValues) {
            initSelectedValue();
        }
    }, [dropdownsSelectedValues]);

    const initSelectedValue = () => {
        if (dropdownsSelectedValues && jsonNoEstaVacio(dropdownsSelectedValues)) {
            console.debug("[SelectInputDatosEmpresa] initSelectedValue ", {dropdownsSelectedValues});
            let selectedValues = dropdownsSelectedValues[jsonKeyy];
            setSelectedValueMultiSelect(selectedValues);
        }
    };

    const handleSelectInputChange = (e) => {
        let selectedValues = Array.isArray(e) ? e.map(x => x.value) : [];
        let newSelectedValues = {...dropdownsSelectedValues, [jsonKeyy]: selectedValues};
        setDropdownsSelectedValues(newSelectedValues);
    };

    const initOptions = () => {
        if (dropdowns && jsonNoEstaVacio(dropdowns)) {
            console.debug("[MultiSelectInputDatosEmpresa] initOptions ", {jsonKeyy, dropdowns});
            if (dropdowns.hasOwnProperty(jsonKeyy)){
                let optionss = dropdowns[jsonKeyy];
                let optionsInNeededFormat = optionss.map((option) => {return {"value": option["Value"], "label": option["Text"]}});

                setOptions([...optionsInNeededFormat]);
            }else{
                console.warn(`no existe jsonKey: '${jsonKeyy}' en dropdowns`, dropdowns);
                setOptions([]);
            }
        }
    };

    return <>
        <div>
            <Select
                placeholder="Seleccionar"
                value={options.filter(obj => selectedValueMultiSelect.includes(obj.value))}
                options={options}
                onChange={handleSelectInputChange}
                isMulti
                isClearable
                isDisabled={isDisabled}
            />
        </div>
    </>
};

export default MultiSelectInputDatosEmpresa;