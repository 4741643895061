import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import {useGetRequest} from "../useGetRequest/useGetRequest";

export const useManageGetRequest = () => {
    const [executeGetRequest] = useGetRequest();

    const manageGetRequest = async (api, callbackSuccess, showToastSuccess = true) => {
        const response = await executeGetRequest(api);
        if (responseCode200(response)) {
            if (showToastSuccess === true){
                toast.success(response?.data?.Message);
            }

            callbackSuccess(response);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
        return response;
    };

    return [manageGetRequest]
};