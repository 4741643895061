import React, {useEffect, useState, useRef} from 'react';
import {useForm} from "../../../hooks/useForm/UseForm";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import { validarFormulario} from "../../../helper/utils";
import {toast} from "react-toastify";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {crearItemTablaGeneralAPI} from "../../../consumers/backendApisUrls";

const RegistrarItemModal = ({showModal, idTabla, nombreTabla}) => {
    const refContainer = useRef(null);
    const [validator, setValidator] = useState({});
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            let item = {
                IdTabla: idTabla,
                NombreTabla: nombreTabla
            };
            setFormValues(item);
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const submitModalRegistrarItem = async () => {
        if(validarFormulario(".form-control[data-required='true']", refContainer, setValidator)) {
            let payload = formValues;
            let response = await executePostRequest(crearItemTablaGeneralAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                showModal();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } 
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"900px"} titulo={"Mantenimiento de tablas"}>
                <div>
                    <div>
                        <form ref={refContainer} className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group pb-3 mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Tabla</label>
                                <div className="col-sm-9">
                                    <input disabled autoFocus data-required="true" type="text" className="form-control" value={formValues.NombreTabla} onChange={handleChange}></input>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group pb-3 mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Nombre<span className="required">*</span></label>
                                <div className="col-sm-9">
                                    <input autoFocus data-required="true" type="text" className="form-control" id="NombreItem" name="NombreItem" value={formValues.NombreItem} onChange={handleChange} maxLength={100} placeholder={"Máximo 100 caracteres"}></input>
                                    { validator.NombreItem && validator.NombreItem.length != 0 && <p className="ficha-form-input-validation-message mb-0">{validator.NombreItem[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group pb-3 mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Descripción<span className="required">*</span></label>
                                <div className="col-sm-9">
                                    <textarea data-required="true" type="text" className="form-control" id="Descripcion" name="Descripcion" value={formValues.Descripcion} onChange={handleChange} maxLength={200} placeholder={"Máximo 200 caracteres"} rows="4" ></textarea>
                                    { validator.Descripcion && validator.Descripcion.length != 0 && <p className="ficha-form-input-validation-message mb-0">{validator.Descripcion[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group pb-3 mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Código 01</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="Codigo01" name="Codigo01" value={formValues.Codigo01} onChange={handleChange} maxLength={15} placeholder={"Máximo 15 caracteres"}></input>
                                    { validator.Codigo01 && validator.Codigo01.length != 0 && <p className="ficha-form-input-validation-message mb-0">{validator.Codigo01[0]}</p>}
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group pb-3 mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Código 02</label>
                                <div className="col-sm-9">
                                    <input type="text" className="form-control" id="Codigo02" name="Codigo02" value={formValues.Codigo02} onChange={handleChange}  maxLength={15} placeholder={"Máximo 15 caracteres"}></input>
                                    { validator.Codigo02 && validator.Codigo02.length != 0 && <p className="ficha-form-input-validation-message mb-0">{validator.Codigo02[0]}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light" style={{'width': '500px'}}>
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={submitModalRegistrarItem}>Guardar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default RegistrarItemModal;

