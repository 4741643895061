import React, { useState, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import RechazarSolicitudModal from './modales/RechazarSolicitudModal'
import VerDetalleModal from './modales/VerDetalleModal'
import TableWebBody from './TableWebBody'
import FormSelect from '../../components/atoms/Select'
import PageHeader from '../../components/pageHeader/PageHeader'
import PageWrapper from '../../components/pageComponents/PageWrapper'
import PageButtons from '../../components/pageComponents/PageButtons'
import PaginatorV2 from '../../components/pageComponents/PaginatorV2'
import ModalEnabled from '../../components/modalComponents/ModalEnabled'
import SearchContainer from '../../components/pageComponents/SearchContainer'
import ActionGenericModal from '../../components/modal/ActionGenericModal'
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect'
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest'
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest'
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo'
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../helper/utils'
import { asignacionFamiliarAdminAprobarSolicitudAPI, asignacionFamiliarAdminDescargarExcelAPI, asignacionFamiliarAdminIndexAPI, asignacionFamiliarAdminRechazarSolicitudAPI, getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getTipoSolicitudAPI } from '../../consumers/backendApisUrls'

const AdminSolicitudesAsignacionFamiliar = () => {

  const INIT_KEYS = { Cargos: "IdsCargos", Sedes: "IdsSedes", EstadosSolicitud: "IdsEstadosSolicitud", TipoSolicitudAsignacionFamiliar: "IdsTipoSolicitudAsignacionFamiliar"};
  const KEY_ALL = { allChecked: "All" };

  const [formValues, setFormValues] = useState({Items: [], ExtraData: {}, filters: [], selectedFilters: {}});
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getTipoSolicitudAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = asignacionFamiliarAdminIndexAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback)
  }

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const updateFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues(lastData => ({...lastData, selectedFilters: updatedSelectedFilters}));
  }

  const updateAllFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const showDetailModal = (IdSolicitudAsignacionFamiliar, PuedeEditar) => {
    setModalEnabled({ isEnable: true, component: VerDetalleModal, data:{ IdSolicitudAsignacionFamiliar, PuedeEditar }});
  }

  const handleActionRow = ({ fila, api, title, actionLabel, isMotivo = false }) => {
    const payload = {IdsSolicitudAsignacionFamiliar: [fila.IdSolicitudAsignacionFamiliar]};
    const data = isMotivo ? {...payload} : {api, title, actionLabel, payload, name: `[${fila.TipoDocumentoHijo} - ${fila.NumeroDocumentoHijo}] - ${fila.NombreHijo}`};
    setModalEnabled({ isEnable: true, component: isMotivo ? RechazarSolicitudModal : ActionGenericModal, data });
  }

  const handleActionMultipleRow = async (api) => {
    const IdsSolicitudAsignacionFamiliar = formValues.Items.filter(o => o.isChecked).map(o => o.IdSolicitudAsignacionFamiliar);

    try {
      await executeManagePost(api(), { IdsSolicitudAsignacionFamiliar }, () => manageSearch());
    } catch (error) {
      console.error(error);
    }
  }

  const handleActionMultipleRowRejection = async () => {
    const IdsSolicitudAsignacionFamiliar = formValues.Items.filter(o => o.isChecked).map(o => o.IdSolicitudAsignacionFamiliar);
    setModalEnabled({ isEnable: true, component: RechazarSolicitudModal, data: { IdsSolicitudAsignacionFamiliar }});
  }

  const handleSelectRow = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    const Items = formValues.Items.map(item => {
      if (value === KEY_ALL.allChecked || item.IdSolicitudAsignacionFamiliar === Number(value)){
        return {...item, isChecked}
      }
      return item;
    })
    setFormValues(lastData => ({ ...lastData, Items }));
  }

  const handleDescargarExcel = async () => {
    const api = asignacionFamiliarAdminDescargarExcelAPI();
    const payload = getPayload();
    await generarDescargarEliminarArchivo(api, payload);
  }

  const addedCleanSearch = () => {
    setFormValues({...formValues, selectedFilters: null});
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [ paginator.CurrentPage, formValues.selectedFilters ])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, updateAllFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title='Gestión de Solicitudes de Asignación Familiar'/>
      <SearchContainer manageSearch={manageSearch} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} addCleanSearch={addedCleanSearch} >
        <PageButtons>
          <button type='button' className='generic-button-buscador' onClick={handleDescargarExcel}>Descargar Excel</button>
        </PageButtons>
      </SearchContainer>
      <div className="d-flex justify-content-start ml-3 mt-3">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic" className="blue-background-color" disabled={!formValues.Items.some(o => o.isChecked)}>
            Acciones
          </Dropdown.Toggle>
          {formValues.Items.some(o => o.isChecked) &&
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleActionMultipleRow(asignacionFamiliarAdminAprobarSolicitudAPI)}>Aprobar</Dropdown.Item>
              <Dropdown.Item onClick={() => handleActionMultipleRowRejection()}>Rechazar</Dropdown.Item>
            </Dropdown.Menu>
          }
        </Dropdown>
      </div>
      <div className="container-fluid body-content">
        <div className="table-scroll pt-3">
          <table className="table table-hover">
            <thead className="listar-thead">
              <tr className='font-gothan-medium'>
                <th></th>
                <th colSpan={4} className='text-center'>
                  Colaborador
                </th>
                <th colSpan={3} className='text-center'>
                  Hijo
                </th>
              </tr>
              <tr className="font-gothan-medium">
                <th className='text-center'>
                  <div className='d-flex flex-column'>
                    <label htmlFor='selectAllAsigFamiliar'>Todos</label>
                    <input type='checkbox' id='selectAllAsigFamiliar' value={KEY_ALL.allChecked} 
                    checked={formValues.Items.every(o => o.isChecked)}
                    onChange={handleSelectRow}/>
                  </div>
                </th>
                <th className='text-center col-sm-1'>Documento</th>
                <th className='text-center col-sm-3'>Nombre</th>
                <th className='text-center col-sm-2'>
                  <div className='d-flex justify-content-center'>    
                    <FormSelect
                      options={handleFind(formValues.filters, INIT_KEYS.Cargos)}
                      description="Cargo"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.Cargos] ?? []}
                      setSelectedOptions={(res) => updateFilterSelected(res, INIT_KEYS.Cargos)}
                    />
                  </div>
                </th>
                <th className='text-center col-sm-2'>
                  <div className='d-flex justify-content-center'>    
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.Sedes)}
                      description="Sede"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.Sedes] ?? []}
                      setSelectedOptions={(res) => updateFilterSelected(res, INIT_KEYS.Sedes)}
                    />
                  </div>
                </th>
                <th className='text-center'>Fecha de Registro</th>
                <th className='text-center col-sm-1'>Documento</th>
                <th className='text-center col-sm-2'>Nombre</th>
                <th className='text-center'>
                  <div className='d-flex justify-content-center'>    
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.EstadosSolicitud)}
                      description="Estado Aprobación"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.EstadosSolicitud] ?? []}
                      setSelectedOptions={(res) => updateFilterSelected(res, INIT_KEYS.EstadosSolicitud)}
                    />
                  </div>
                </th>
                <th className='text-center col-sm-2'>Fecha Vigencia</th>
                <th className='text-center col-sm-2'>
                  <div className='d-flex justify-content-center'>    
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.TipoSolicitudAsignacionFamiliar)}
                      description="Tipo"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.TipoSolicitudAsignacionFamiliar] ?? []}
                      setSelectedOptions={(res) => updateFilterSelected(res, INIT_KEYS.TipoSolicitudAsignacionFamiliar)}
                    />
                  </div>
                </th>
                <th className='text-center'>Acciones</th>
              </tr>
            </thead>
            <TableWebBody filas={formValues.Items} showDetailModal={showDetailModal} handleSelectRow={handleSelectRow} handleActionRow={handleActionRow}/>
          </table>
          <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default AdminSolicitudesAsignacionFamiliar