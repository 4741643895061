import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {AuthContext} from "../../hooks/context/authContext";
import {uploadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {obtenerElementoDeFichaDatosPersonalesPorId, obtenerValorDeElementoDeFichaDatosPersonalesPorId, VALIDACIONES, validarCampo} from "../../validations/SeccionDatosPersonalesValidator";
import {agregarTextOfSelectInputType, clearInputValue, extensionYtamañoDeArchivoEsValido, getFormattedName, jsonEstaVacio, notNullAndNotUndefinded, obtenerArchivoDeEvent, responseUploadFileSuccess, stringSeEncuentraEnArray} from "../../helper/utils";
import {toast} from "react-toastify";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS, LIST_FIELD_TYPE} from "../../constants/hardCodedConstants";
import {showToastErrorMaxFileSize} from "../../components/alerts/AlertErrorMaxFileSize";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import GenericUploadFile from "../../components/GenericUploadFile/GenericUploadFile";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getDatosIdentificacionAPI, guardarDatosIdentificacionAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";

const SeccionEditDatosPersonales = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, reloadSeccionesDatosPersonales}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosPersonales, setDatosPersonales] = useState({});
    const [dataFields, setDataFields] = useState([]);
    const [editFields, setEditFields] = useState([]);
    const [hiddenFields, setHiddenFields] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [estadosCiviles, setEstadosCiviles] = useState([]);
    const [tiposDeDocumento, setTiposDeDocumento] = useState([]);

    const [declaracionJurada, setDeclaracionJurada] = useState(false);
    const [hideDeclaracionJurada, setHideDeclaracionJurada] = useState(true);
    const [fileName, setFileName] = useState("");
    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});
    const [loading, setLoading] = useState(false);
    const [adjuntoSustentoEsObligatorio, setAdjuntoSustentoEsObligatorio] = useState(false);

    const UPDATE_SUSTENTO_INPUT_FILE = "ficha-edit-datosPersonales-sustento-file";
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    function updateId() {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    }

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        hacerEditablesLosCampos();
    }, [editFields]);

    useDidMountEffect(() => {
        mostrarSoloCamposQueNoSeanHidden();
    }, [hiddenFields]);

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                const response = await executeGetRequest(getDatosIdentificacionAPI(idActualizado));
                if (responseCode200(response)) {
                    console.debug("[DatosPersonales] response ", response);

                    setDatosPersonales(response.data.data);
                    setEditFields(response.data.data.EditFields);
                    setHiddenFields(response.data.data.HiddenFields);

                    setGeneros(response.data.dropdowns.Generos);
                    setEstadosCiviles(response.data.dropdowns.EstadosCiviles);
                    setTiposDeDocumento(response.data.dropdowns.TiposDocumento);

                    setDataFields(response.data.data.DataFields);

                    agregarAsteriscoALabelsMandatorios();

                    setDeclaracionJurada(false);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const agregarAsteriscoALabelsMandatorios = () => {
        try {
            for (var fieldName in VALIDACIONES) {
                if (VALIDACIONES.hasOwnProperty(fieldName)) {
                    if (VALIDACIONES[fieldName]["obligatorio"]) {
                        try {
                            document.querySelector(`#ficha-edit-inputGroup-datosPersonales-${fieldName} > span.required`).innerHTML = "*";
                        } catch (e) {
                            console.warn(`No se econtró elemento con id #ficha-edit-inputGroup-datosPersonales-${fieldName}`);
                        }
                    }
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const mostrarSoloCamposQueNoSeanHidden = () => {
        try {
            for (const field of hiddenFields) {
                let elementEdit = document.getElementById(`ficha-edit-inputGroup-datosPersonales-${field}`);

                if (elementEdit) {
                    elementEdit.setAttribute("hidden", "");

                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const hacerEditablesLosCampos = () => {
        try {
            for (const field of editFields) {
                let optionalParams = document.querySelector("#frmEditDatosPersonales input[name='" + field + "']");

                if (optionalParams == null) {
                    optionalParams = document.querySelector("#frmEditDatosPersonales select[name='" + field + "']");
                }

                if (optionalParams != null) {
                    optionalParams.removeAttribute('disabled');
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const cancelar = async () => {
        try {
            setShowViewSeccion(true);
        } catch (e) {
            console.error(e);
        }
    };

    const validarFormulario = () => {
        console.debug("validarFormulario");
        let formularioInvalido = false;

        let fieldsToValidate;
        if (hideDeclaracionJurada) {
            fieldsToValidate = [...editFields];
        } else {
            fieldsToValidate = [...editFields, "AceptaDeclaracionJurada"];
        }

        console.debug("fieldsToValidate", fieldsToValidate);
        fieldsToValidate.forEach((fieldId) => {
            let campoInvalido = validarCampo(fieldId, state.token, idActualizado, dispatch, tiposDeDocumento);
            console.debug(`campo: '${fieldId}', campoInvalido: ${campoInvalido}`);
            if (campoInvalido) {
                formularioInvalido = true;
            }
        });

        return formularioInvalido;
    };

    const agregarCamposDeFormulario = (payload) => {
        for (let dataField of dataFields) {
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let element = obtenerElementoDeFichaDatosPersonalesPorId(keyField);
                if (notNullAndNotUndefinded(element)) {
                    if (element.matches('[type="checkbox"]')) {
                        payload = {...payload, [keyField]: element.checked};
                    } else {
                        payload = {...payload, [keyField]: element.value};
                    }

                    if (dataField["FieldType"] == LIST_FIELD_TYPE) {
                        payload = agregarTextOfSelectInputType(keyField, element, payload);
                    }
                } else {
                    console.warn(`no se encontró campo con el id "${keyField}", por lo que no se agregará al payload, `);
                }
            }
        }

        return payload;
    };

    const agregarFileViewModelEnCasoExista = (payload) => {
        if (!(s3UploadFileResponse && Object.keys(s3UploadFileResponse).length === 0 && s3UploadFileResponse.constructor === Object)) {
            payload = {...payload, FileViewModel: s3UploadFileResponse};
        }
        return payload;
    };

    const onSubmit = async (event) => {
        console.debug("onSubmit");
        try {
            event.preventDefault();

            let formularioInvalido = validarFormulario();
            console.warn("formularioInvalido: ", formularioInvalido);

            if (jsonEstaVacio(s3UploadFileResponse) && adjuntoSustentoEsObligatorio) {
                toast.error("Adjunta PDF o imagen de tu DNI");
                return;
            }

            if (!formularioInvalido) {
                let payload = {
                    AceptaDeclaracionJurada: event.target.AceptaDeclaracionJurada.checked,
                    IdEmpleado: idActualizado,
                };

                payload = agregarCamposDeFormulario(payload);
                payload = agregarFileViewModelEnCasoExista(payload);

                console.debug("payload: ", payload);

                let response = await executePostRequest(guardarDatosIdentificacionAPI(), payload);

                if (responseCode200(response)) {
                    showUpdateConfirmationModal(response.data.UrlImage, response.data.Message);

                    await dispatch({
                        type: 'UPDATE_USER',
                        payload: {
                            user: {...state.user, name: payload.NombreCorto}
                        },
                    });

                    askForNewBellNotifications(dispatch);

                    await updateComponentStates();

                    setShowViewSeccion(true);
                    await reloadSeccionesDatosPersonales(empleadoId);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const resetUploadFile = () => {
        setFileName("");
        setS3UploadFileResponse({});
        clearInputValue(UPDATE_SUSTENTO_INPUT_FILE);
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponse(s3Response);
            setFileName(getFormattedName(file.name));
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivo(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                console.debug("[DatosPersonales] response ", response);
                manageUploadFileResponse(response, file);
            } else {
                resetUploadFile();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleDeclaracionJurada = (e) => {
        setDeclaracionJurada(e.target.checked);
    };

    const handleChangeInputForDeclaracionJuradaMandatory = () => {
        let emailInputValue = obtenerValorDeElementoDeFichaDatosPersonalesPorId("Email");
        let celularInputValue = obtenerValorDeElementoDeFichaDatosPersonalesPorId("Celular");

        let originalEmailValue = datosPersonales.Email;
        let originalCelularValue = datosPersonales.Celular;

        if ((originalEmailValue != emailInputValue) || (originalCelularValue != celularInputValue)) {
            setHideDeclaracionJurada(false);
        } else {
            setHideDeclaracionJurada(true);
            setDeclaracionJurada(false);
        }
    };

    const handleChangeInputForAttachmentMandatory = () => {
        if (cambioAlMenosUnInputQueRequiereAdjunto()) {
            setAdjuntoSustentoEsObligatorio(true);
        } else {
            setAdjuntoSustentoEsObligatorio(false);
            resetUploadFile();
        }
    };

    const cambioAlMenosUnInputQueRequiereAdjunto = () => {
        let seRequiereAdjunto = false;
        if (
            inputValueEsDiferenteDeValorOriginal("Nombres") ||
            inputValueEsDiferenteDeValorOriginal("ApellidoPaterno") ||
            inputValueEsDiferenteDeValorOriginal("ApellidoMaterno") ||
            /*inputValueEsDiferenteDeValorOriginal("ApellidoCasado") ||*/
            inputValueEsDiferenteDeValorOriginal("NumeroDocumento") ||
            inputValueEsDiferenteDeValorOriginal("IdTipoDocumento") ||
            inputValueEsDiferenteDeValorOriginal("IdGenero") ||
            inputValueEsDiferenteDeValorOriginal("IdEstadoCivil")
        ) {
            seRequiereAdjunto = true;
        }
        console.debug({seRequiereAdjunto});
        return seRequiereAdjunto;
    };

    const inputValueEsDiferenteDeValorOriginal = (inputIdKey) => {
        if (inputEsEditable(inputIdKey)) {
            let inputValue = obtenerValorDeElementoDeFichaDatosPersonalesPorId(inputIdKey);
            let originalValue = datosPersonales[inputIdKey];
            let sonDiferentess = sonDiferentes(originalValue, inputValue);
            console.debug("inputIdKey: ", inputIdKey, {originalValue, inputValue, sonDiferentess});
            return sonDiferentess;
        } else {
            console.warn("no se valida si valor original es diferente de input value, porque no es editable. inputIdKey: ", inputIdKey);
            return false;
        }
    };

    const inputEsEditable = (inputIdKey) => {
        let esEditable = (stringSeEncuentraEnArray(inputIdKey, editFields));
        console.debug("inputIdKey: ", inputIdKey, {esEditable});
        return esEditable;
    };

    const sonDiferentes = (originalValue, inputValue) => {
        return (originalValue != inputValue);
    };

    const removeUploadedFile = function () {
        resetUploadFile();
    };

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}
            <form id='frmEditDatosPersonales' className="ficha-edit-form" onSubmit={onSubmit} hidden={loading}>
                <div className="displayFlexWrap">
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-Nombres">
                        <label className="ficha-edit-form-label">Nombres</label><span className="required"></span>
                        <input type="text" defaultValue={datosPersonales && datosPersonales.Nombres} className="form-control" name="Nombres" id="ficha-edit-datosPersonales-Nombres" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-Nombres-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-ApellidoPaterno">
                        <label className="ficha-edit-form-label">Apellido Paterno</label><span className="required"></span>
                        <input type="text" className="form-control" name="ApellidoPaterno" defaultValue={datosPersonales && datosPersonales.ApellidoPaterno} id="ficha-edit-datosPersonales-ApellidoPaterno" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-ApellidoPaterno-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-ApellidoMaterno">
                        <label className="ficha-edit-form-label">Apellido Materno</label><span className="required"></span>
                        <input type="text" className="form-control" name="ApellidoMaterno" defaultValue={datosPersonales && datosPersonales.ApellidoMaterno} id="ficha-edit-datosPersonales-ApellidoMaterno" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-ApellidoMaterno-validation-message"></p>
                    </div>
                    {/*<div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-ApellidoCasado">
                        <label className="ficha-edit-form-label">Apellido de Casada</label><span className="required"></span>
                        <input type="text" className="form-control" name="ApellidoCasado" defaultValue={datosPersonales && datosPersonales.ApellidoCasado} id="ficha-edit-datosPersonales-ApellidoCasado" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-ApellidoCasado-validation-message"></p>
                    </div>*/}
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-NombreCorto">
                        <label className="ficha-edit-form-label">Me conocen como</label><span className="required"></span>
                        <input type="text" className="form-control" name="NombreCorto" defaultValue={datosPersonales && datosPersonales.NombreCorto} id="ficha-edit-datosPersonales-NombreCorto" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-NombreCorto-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-IdTipoDocumento">
                        <label className="ficha-edit-form-label">Tipo de Documento</label><span className="required"></span>
                        {datosPersonales &&
                        <select className="form-control" name="IdTipoDocumento" id="ficha-edit-datosPersonales-IdTipoDocumento" onChange={handleChangeInputForAttachmentMandatory} disabled>
                            tiposDeDocumento && datosPersonales && datosPersonales.IdTipoDocumento
                            {
                                tiposDeDocumento.map((option) => {
                                        {
                                            if (option.Value === datosPersonales.IdTipoDocumento) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-IdTipoDocumento-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-NumeroDocumento">
                        <label className="ficha-edit-form-label">Número de Documento</label><span className="required"></span>
                        <input type="text" className="form-control" name="NumeroDocumento" defaultValue={datosPersonales && datosPersonales.NumeroDocumento} id="ficha-edit-datosPersonales-NumeroDocumento" onChange={handleChangeInputForAttachmentMandatory} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-NumeroDocumento-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-IdGenero">
                        <label className="ficha-edit-form-label">Sexo</label><span className="required"></span>
                        {datosPersonales &&
                        <select className="form-control" disabled name="IdGenero" id="ficha-edit-datosPersonales-IdGenero" onChange={handleChangeInputForAttachmentMandatory}>
                            generos &&
                            {
                                generos.map((option) => {
                                        {
                                            if (option.Value === datosPersonales.IdGenero) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-IdGenero-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-IdEstadoCivil">
                        <label className="ficha-edit-form-label">Estado Civil</label><span className="required"></span>
                        {datosPersonales &&
                        <select name="IdEstadoCivil" className="form-control" id="ficha-edit-datosPersonales-IdEstadoCivil" disabled onChange={handleChangeInputForAttachmentMandatory}>
                            estadosCiviles &&
                            {
                                estadosCiviles.map((option) => {
                                        {
                                            if (option.Value === datosPersonales.IdEstadoCivil) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-IdEstadoCivil-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-Email">
                        <label className="ficha-edit-form-label">Correo electrónico</label><span className="required"></span>
                        <input type="text" className="form-control" name="Email" defaultValue={datosPersonales && datosPersonales.Email} id="ficha-edit-datosPersonales-Email" disabled onChange={() => {
                            handleChangeInputForDeclaracionJuradaMandatory();
                            handleChangeInputForAttachmentMandatory();
                        }}></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-Email-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-Celular">
                        <label className="ficha-edit-form-label">Celular Personal</label><span className="required"></span>
                        <div style={{display: 'flex'}}>
                            <input type="text" className="form-control ficha-form-input-celular" name="Celular" id="ficha-edit-datosPersonales-Celular" defaultValue={datosPersonales && datosPersonales.Celular} disabled onChange={() => {
                                handleChangeInputForDeclaracionJuradaMandatory();
                                handleChangeInputForAttachmentMandatory();
                            }}></input>
                            <div className="custom-control custom-switch" style={{"paddingTop": '6px'}}>
                                <input type="checkbox" className="custom-control-input hand-pointer" id="ficha-edit-datosPersonales-MostrarCelular" name="MostrarCelular" defaultChecked={datosPersonales && datosPersonales.MostrarCelular} disabled onChange={handleChangeInputForAttachmentMandatory}></input>
                                <label className="custom-control-label ficha-edit-form-label-checkbox hand-pointer" htmlFor="ficha-edit-datosPersonales-MostrarCelular">Mostrar celular en el directorio

                                    <p data-tip data-for="informacion-datos-personales-celular" style={{display: 'inline'}}>
                                        <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                                    </p>
                                    <ReactTooltip id="informacion-datos-personales-celular" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                                    Al habilitar tu celular, será visible en el Directorio a tus compañeros de área/sede, ED's y BackOffice, caso contrario solo será visible para GDH.
                                    </ReactTooltip>
                                </label>
                                <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-MostrarCelular-validation-message"></p>
                            </div>
                        </div>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-Celular-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosPersonales-Telefono">
                        <label className="ficha-edit-form-label">Teléfono fijo</label><span className="required"></span>
                        <p data-tip data-for="informacion-datos-personales-telefono" style={{display: 'inline'}}>
                            <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                        </p>
                        <ReactTooltip id="informacion-datos-personales-telefono" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                            Este dato es importante para casos de emergencia. Debes ingresar el # sin código de Ciudad
                        </ReactTooltip>
                        <input type="text" className="form-control" name="Telefono" id="ficha-edit-datosPersonales-Telefono" defaultValue={datosPersonales && datosPersonales.Telefono} disabled onChange={handleChangeInputForAttachmentMandatory}></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-Telefono-validation-message"></p>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    'justifyContent': 'space-between',
                    'marginTop': '31.9px',
                    'marginBottom': '15px'
                }}>
                    <div style={{'alignSelf': 'flex-start'}}>
                        {adjuntoSustentoEsObligatorio === true &&
                        <GenericUploadFile handleUploadFile={handleUploadFile} datosSeccion={datosPersonales} fileName={fileName} subiendoArchivo={subiendoArchivo} removeUploadedFile={removeUploadedFile} id={UPDATE_SUSTENTO_INPUT_FILE} textButton={"Adjunta PDF o imagen de tu DNI"}>
                            <></>
                        </GenericUploadFile>
                        }
                        <div className="form-check margin-top-15px" hidden={hideDeclaracionJurada}>
                            <input className="form-check-input" type="checkbox" id="ficha-edit-datosPersonales-AceptaDeclaracionJurada" name="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
                            <label className="form-check-label" htmlFor="AceptaDeclaracionJurada">
                                <p>{datosPersonales && datosPersonales.DJMessage}</p>
                                <p>Los datos que se están consignando deben ser lo que se especifica en el documento de identidad.</p>
                            </label>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-datosPersonales-AceptaDeclaracionJurada-validation-message"></p>
                        </div>
                        <div>
                            <br/>
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>
                    </div>

                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            onClick={cancelar}
                            type='button'
                            className='btn btn-primary ficha-form-button-cancelar'>
                            Cancelar
                        </button>

                        {datosPersonales && datosPersonales.CanSeeButtonEditar === true &&
                        <button
                            type="submit"
                            className='btn btn-primary ficha-form-button-guardar'
                            disabled={subiendoArchivo}>
                            Guardar
                        </button>
                        }
                    </div>
                </div>
            </form>
        </>
    );
};

export default SeccionEditDatosPersonales;