import GenericModal from "../../../components/modal/GenericModal";
import React, { useContext, useEffect, useState } from 'react';
import recordatorioVacunacion from '../../../image/recordatorioVacunacion.jpeg';
const RecordatorioVacunacion =  ({ toggleModal, setHideRegistroVacunaModal}) => {

    const showRegistroVacunaModal = function () {
        toggleModal();
        setHideRegistroVacunaModal(false);
    }

    return (
        <GenericModal showModal={toggleModal} width={"900px"} titulo={"Recordatorio Vacunación"}>
         
                <div className="modal-body pt-0 pl-2 pr-1 pb-0">
                    <img src={recordatorioVacunacion} alt='' width="100%" style={{'margin': '-5px -5px -5px -5px'}} onClick={showRegistroVacunaModal}/>
                </div>

        </GenericModal>
    )
};

export default RecordatorioVacunacion;
