import React, {useEffect, useState} from 'react';
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import Select from "react-select";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {Dropdown, Spinner} from "react-bootstrap";
import {toast} from "react-toastify";
import {esArrayVacio, jsonEstaVacio} from "../../helper/utils";
import RegistrarItemModal from './modals/RegistrarItemModal';
import EditarItemModal from './modals/EditarItemModal';
import Paginator from "../directorio/Paginator";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {activarItemTablaGeneralAPI, anularItemTablaGeneralAPI, getDatosTablasGeneralesAPI, getListaTablasGeneralesAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import TableWebBody from "./TableWebBody";

const AdminTablasGenerales = () => {
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [loading, setLoading] = useState(true);
    const [refreshTabla, setRefreshTabla] = useState(false);
    const [options, setOptions] = useState([]);
    const [idItem, setIdItem] = useState();
    const [tablaSeleccionada, setTablaSeleccionada] = useState({});
    const [datos, setDatos] = useState([]);
    const [idsItemsConCheck, setIdsItemsConCheck] = useState([]);
    const [hideRegistrarItemModal, setHideRegistrarItemModal] = useState(true);
    const [hideEditarItemModal, setHideEditarItemModal] = useState(true);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const getInitialData = async () => {
        setLoading(true);
        try {
            const response = await executeGetRequest(getListaTablasGeneralesAPI());
            if (responseCode200(response)) {
                let dropdown = response.data.map((option) => {
                    return {"value": option["Value"], "label": option["Text"]}
                });
                setOptions(dropdown);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    };

    const onLeftClic = async () => {
        setRefreshTabla(true);
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
        await obtenerDatosSegunTabla(tablaSeleccionada.label, nextPage);
    };

    const onRightClic = async () => {
        setRefreshTabla(true);
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
        await obtenerDatosSegunTabla(tablaSeleccionada.label, nextPage);
    };

    const registrarDato = async () => {
        if (jsonEstaVacio(tablaSeleccionada)) {
            toast.warning("Seleccionar el tipo de tablaSeleccionada");
            return;
        }
        setHideRegistrarItemModal(!hideRegistrarItemModal);
        await obtenerDatosSegunTabla(tablaSeleccionada.label);
    };

    const editarDato = async () => {
        setHideEditarItemModal(!hideEditarItemModal);
        await obtenerDatosSegunTabla(tablaSeleccionada.label);
    };

    const showEditarItemModal = async (idItemm) => {
        setIdItem(idItemm);
        setHideEditarItemModal(!hideEditarItemModal);
        await obtenerDatosSegunTabla(tablaSeleccionada.label, page);
    };

    const handleSelectInputChange = (tabla) => {
        setRefreshTabla(true);
        setPage(1);
        setTablaSeleccionada(tabla);
        setIdsItemsConCheck([]);
        obtenerDatosSegunTabla(tabla.label, 1);
    };

    const obtenerDatosSegunTabla = async (tabla, currentPage = 1) => {
        try {
            setDatos([]);
            const response = await executeGetRequest(getDatosTablasGeneralesAPI(tabla, currentPage));
            if (responseCode200(response)) {
                setDatos(response.data.Items);
                setTotalItems(response.data.TotalItems);
                setFirsItemPage(response.data.FirsItemPage);
                setLastItemPage(response.data.LastItemPage);
                setTotalPages(response.data.TotalPages);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setRefreshTabla(false);
        }
    };

    const activarItems = async () => {
        if (!esArrayVacio(idsItemsConCheck)) {
            for (let idItemm of idsItemsConCheck) {
                let response = await executePostRequest(activarItemTablaGeneralAPI(idItemm), {});
                if (responseCode200(response)) {
                    toast.success(response.data.Message);

                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
            setIdsItemsConCheck([]);
            await obtenerDatosSegunTabla(tablaSeleccionada.label);
        }
    };

    const anularItems = async () => {
        if (!esArrayVacio(idsItemsConCheck)) {
            for (let idItemm of idsItemsConCheck) {
                let response = await executePostRequest(anularItemTablaGeneralAPI(idItemm), {});
                if (responseCode200(response)) {
                    toast.success(response.data.Message);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
            setIdsItemsConCheck([]);
            await obtenerDatosSegunTabla(tablaSeleccionada.label);
        }
    };

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );
    return (
        <>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideRegistrarItemModal}>
                {hideRegistrarItemModal === false &&
                <RegistrarItemModal showModal={registrarDato} idTabla={tablaSeleccionada.value} nombreTabla={tablaSeleccionada.label}/>
                }
            </div>
            <div className="modal-contactanos modal-response-ficha-background" hidden={hideEditarItemModal}>
                {hideEditarItemModal === false &&
                <EditarItemModal showModal={editarDato} idItem={idItem} nombreTabla={tablaSeleccionada.label}/>
                }
            </div>
            <Wrapper className='App'>
                <PageHeader title={"Tablas Generales"}/>
                <div className='container-fluid body-content'>
                    <div className="d-flex mx-3 align-items-center">
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="input-group mb-3 buscador align-items-center row col-md-12">
                                <label className="col-md-2 d-none d-md-block ficha-info-label-width text-center">Tipo de tabla</label>
                                <Select
                                    className="px-3 col-sm-10 d-none d-md-block"
                                    placeholder="Seleccionar"
                                    options={options}
                                    onChange={handleSelectInputChange}
                                    isDisabled={false}/>
                            </div>
                        </div>
                        <div className="action-in-form ml-auto d-flex flex-row justify-content-center align-items-center">
                            <button
                                onClick={registrarDato}
                                type='button'
                                className={(jsonEstaVacio(tablaSeleccionada) ? "disabled " : "") + "btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light"}>
                                Nuevo
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className="blue-background-color">
                                    Acciones
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => activarItems()}>Activar</Dropdown.Item>
                                    <Dropdown.Item onClick={() => anularItems()}>Anular</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                    </div>
                    <div className='pt-1'>
                        <div className="table-scroll">
                        <table className='table table-hover'>
                            <thead className="listar-thead">
                            <tr className='bg-cl-gris'>
                                <th>
                                    <div className='flex font-gothan-medium'>Nombre</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Descripción</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Estado</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Código 01</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Código 02</div>
                                </th>
                                <th>
                                    <div className='flex font-gothan-medium'>Opciones</div>
                                </th>
                            </tr>
                            </thead>
                            <TableWebBody refreshTabla={refreshTabla} datos={datos} idsItemsConCheck={idsItemsConCheck} setIdsItemsConCheck={setIdsItemsConCheck} showEditarItemModal={showEditarItemModal}/>

                        </table>
                        </div>
                        {!refreshTabla && !loading && totalPages > 1 && (
                            <Paginator
                                totalItems={totalItems}
                                firsItemPage={firsItemPage}
                                lastItemPage={lastItemPage}
                                totalPages={totalPages}
                                page={page}
                                onLeftClic={onLeftClic}
                                onRightClic={onRightClic}/>
                        )}
                        {refreshTabla &&
                        <div className="spinner-cargando-container text-center">
                            <Spinner animation="grow" variant="primary" size="1sm"/>
                        </div>
                        }

                    </div>
                </div>
            </Wrapper>
        </>
    );

};

export default AdminTablasGenerales;
