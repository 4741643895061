import React, { useContext, useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { getDetallePruebaLaboratorio, getEstadosLaboratorioAPI, getEstadosResultadoPruebaLaboratorio, guardarPruebaLaboratorioAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import Select from "react-select";
import hourValues from './hourValues';
import { estados } from '../../../constants/states';

const EditarRegistro = ({ showModal, idRegistro }) => {

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);

  const sectionName = "editarProgramarPruebaCovid"

  const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const [data, setData] = useState({});
  const [estadosProgramacion, setEstadosProgramacion] = useState([]);
  const [estadosProgramacionSeleccionado, setEstadosProgramacionSeleccionado] = useState([]);
  
  const [estadosResultado, setEstadoResultado] = useState([]);
  const [estadoResultadoSeleccionado, setEstadoResultadoSeleccionado] = useState();

  useEffect(() => {
    init()
  }, []);

  const programarPrueba = async () => {
    const api = guardarPruebaLaboratorioAPI();
    const payload = {
      "IdRegistroResultado": idRegistro,
      "FechaHora": estadosProgramacionSeleccionado.value == estados.Resultado.id? `${data.FechaValue} ${data.HoraValue}` : `${formValues.FechaValue} ${formValues.HoraValue}` || null,
      "Lugar": estadosProgramacionSeleccionado.value == estados.Resultado.id? data?.Lugar : formValues.Lugar,
      "Direccion": estadosProgramacionSeleccionado.value == estados.Resultado.id? data?.Direccion : formValues.Direccion,
      "IdEstadoResultado": estadosProgramacionSeleccionado.value == estados.Programado.id? null: estadoResultadoSeleccionado.value,
      "IdEstado": estadosProgramacionSeleccionado.value
    };
    let allInputKeys = ["FechaHora", "Lugar", "Direccion", "IdEstadoResultado"];


    await submitForm(allInputKeys, api, payload, sectionName, () => { showModal() });
  };

  const init = async () => {
    const estados = await executeGetRequest(getEstadosResultadoPruebaLaboratorio());
    if (responseCode200(estados)) {
      setEstadoResultado(estados?.data.map(item => ({
        value: item.Value, label: item.Text
      })));
    } else {
      mostrarMensajeDeErrorConToast(estados);
    }

    const estadosLab = await executeGetRequest(getEstadosLaboratorioAPI());
    if (responseCode200(estadosLab)) {
      setEstadosProgramacion(estadosLab?.data.map(item => ({
        value: item.Value, label: item.Text
      })));
    } else {
      mostrarMensajeDeErrorConToast(estados);
    }

    const response = await executeGetRequest(getDetallePruebaLaboratorio(idRegistro));
    if (responseCode200(response)) {
      setData(response.data);
      setFormValues({
        FechaValue: response.data.FechaValue,
        HoraValue: response.data.HoraValue,
        Lugar: response.data.Lugar,
        Direccion: response.data.Direccion,
        Resultado: response.data.Resultado,
        FechaRegistro: response.data.FechaRegistro,
        UsuarioRegistro: response.data.UsuarioRegistro
      })
    } else {
      mostrarMensajeDeErrorConToast(response);
    }

    setEstadoResultadoSeleccionado({
      value: response.data.IdEstadoResultado,
      label: response.data.EstadoResultado
    })

    setEstadosProgramacionSeleccionado({
      value: response.data.IdEstado,
      label: response.data.Estado
    })
  };


  return <>
    <GenericModal showModal={showModal} width={"850px"} titulo={"Cargar Resultados"}>
      <div>
        <div>
          <div className="row mb-4">
            <div className="col-sm-6">{data?.NombreColaborador}</div>
            <div className="col-sm-6">{data?.Sede}</div>
          </div>
          <div className="form-group row form-input-modal-contactanos reportes-label-container">
            <label className="col-sm-2 col-form-label modal-label">
              Estado
            </label>
            <div className="col-sm-10">
              <Select
                options={estadosProgramacion}
                onChange={setEstadosProgramacionSeleccionado}
                value={estadosProgramacionSeleccionado}
              />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsEstado-validation-message`}></p>
            </div>
          </div>
          <form className="blue-standard-color">
            {estadosProgramacionSeleccionado.value == estados.Programado.id ? (
              <>
                Programación
                <hr />
                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                  <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                    Fecha<span className="required">*</span>
                  </label>
                  <div className="col-sm-5">
                    <input type="date" min={new Date().toISOString().split("T")[0]} className="form-control" name="FechaValue" value={formValues.FechaValue} onChange={handleChange}></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaValue-validation-message`}></p>
                  </div>


                  <label className="col-sm-1 col-form-label modal-label blue-standard-color">
                    Hora<span className="required">*</span>
                  </label>
                  <div className="col-sm-4">
                    <input type="time" className="form-control" list="times" name="HoraValue" value={formValues.HoraValue} min="07:00" max="18:00" step={60 * 30} onChange={handleChange}></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-HoraValue-validation-message`}></p>

                    <datalist id="times">
                      {hourValues.map((hour) => <option key={hour} value={hour} />)}
                    </datalist>
                  </div>
                </div>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaHora-validation-message`}></p>

                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                  <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                    Lugar<span className="required">*</span>
                  </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" name="Lugar" value={formValues.Lugar} onChange={handleChange}></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Lugar-validation-message`}></p>
                  </div>
                </div>

                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                  <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                    Dirección<span className="required">*</span>
                  </label>
                  <div className="col-sm-10">
                    <input type="text" className="form-control" name="Direccion" value={formValues.Direccion} onChange={handleChange}></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Direccion-validation-message`}></p>
                  </div>
                </div>
              </>
            ) : null}

            {estadosProgramacionSeleccionado.value == estados.Resultado.id ? (
              <>
                Resultado
                <hr />
                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                  <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                    Resultado<span className="required">*</span>
                  </label>
                  <div className="col-sm-10">
                    <Select
                      options={estadosResultado}
                      onChange={setEstadoResultadoSeleccionado}
                      value={estadoResultadoSeleccionado}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdEstadoResultado-validation-message`}></p>
                  </div>
                </div>

                <div className="form-group row form-input-modal-contactanos reportes-label-container">
                  <label className="col-sm-2 col-form-label modal-label blue-standard-color">
                    Fecha de registro
                  </label>
                  <div className="col-sm-3">
                    <input type="text" className="form-control" name="FechaRegistro" value={formValues.FechaRegistro} onChange={handleChange} disabled></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaRegistro-validation-message`}></p>
                  </div>


                  <label className="col-sm-1 col-form-label modal-label blue-standard-color">
                    Usuario
                  </label>
                  <div className="col-sm-6">
                    <input type="text" className="form-control" name="UsuarioRegistro" value={formValues.UsuarioRegistro} onChange={handleChange} disabled></input>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-UsuarioRegistro-validation-message`}></p>
                  </div>
                </div>
              </>
            ) : null}

          </form>
        </div>
      </div>
      <div>
        <div className="modal-footer mt-2">
          <div className="message font-gothan-light">
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button">
            <button className="btn btn-primary generic-button-ficha-modal" onClick={programarPrueba}>Guardar</button>
          </div>
        </div>
      </div>
    </GenericModal>
  </>;
};

export default EditarRegistro;
