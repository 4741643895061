import React, { useContext, useEffect, useState } from "react";
import { generarCartaConvenioHorarioLactanciaAPI, getHorarioLactanciaByIdEmpleadoFlujoActividadAPI, getTiposHorariosLactanciaMaternidadAPI, guardarCartaConvenioHorarioLactanciaAPI } from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { usePropsSelect } from "../../../hooks/useProps/usePropsSelect";
import { initialAllOptionPromises } from "../../../helper/utils";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { usePropsInputs } from "../../../hooks/useProps/usePropsInput";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import ConvenioHorarioLactancia from "./ConvenioHorarioLactancia";
import { downloadFile } from "../../../consumers/backendConsumer";
import { AuthContext } from "../../../hooks/context/authContext";

const KEYS_DROPDOWN = ["IdTipoHorarioLactancia"];
const SECTION_NAME = "FirmarRecepcionHorarioLactancia";
const KEYS = {
  idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  idTipoHorarioLactancia: "IdTipoHorarioLactancia",
  idHorarioLactancia: "IdHorarioLactancia",
  cartaConvenioHorarioLactancia: "CartaConvenioHorarioLactanciaFileViewModel",
};

const FirmarRecepcionHorarioLactancia = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {
  const { state } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad
  });
  const [template, setTemplate] = useState({Path: "just in case"});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [executePostRequest] = usePostRequest();
  const [isGeneratingTemplate, setIsGeneratingTemplate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [executeGet] = useGetRequest();

  useEffect(() => {
    init();
    getDropdowns();
  }, []);

  const init = async () => {
    if (IdEmpleadoFlujoActividad != null){
      const api = getHorarioLactanciaByIdEmpleadoFlujoActividadAPI(IdEmpleadoFlujoActividad);
      const result = await executeGet(api);
      setFormValues({
        ...formValues,
        ...result.data,
      });
    }
    setIsLoading(false);
  }

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTiposHorariosLactanciaMaternidadAPI())
    ]
    await initialAllOptionPromises(dropdownsPromises, KEYS_DROPDOWN, setAllDropdowns);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = guardarCartaConvenioHorarioLactanciaAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  }

  const download = (file) => {
    downloadFile(
      state.token,
      file?.Path,
      file?.OriginalFileName
    );
  }

  const getTemplate = async (form) => {
    if(template.OriginalFileName === undefined) {
      setIsGeneratingTemplate(true);
      setTemplate(null);
      const api = generarCartaConvenioHorarioLactanciaAPI();
      const payload = {
        IdHorarioLactancia: form[KEYS.idHorarioLactancia],
        IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad
      }
      try {
        const response = await executePostRequest(api, payload);
        setTemplate(response.data[KEYS.cartaConvenioHorarioLactancia]);
        download(response.data[KEYS.cartaConvenioHorarioLactancia]);
      } catch (error) {
        console.error(error);
      } finally {
        setIsGeneratingTemplate(false);
      }
    }else{
      download(template);
    }
  }

  return (
    <ConvenioHorarioLactancia
      KEYS = {KEYS}
      SECTION_NAME = {SECTION_NAME}
      showModal = {showModal}
      isLoading = {isLoading || isGeneratingTemplate}
      handleSubmit = {handleSubmit}
      label = "Enviar"
      formValues = {formValues}
      setFormValues = {setFormValues}
      selectProps = {selectProps}
      commonProps = {commonProps}
      template = {template}
      getTemplate = {getTemplate}
      puedeEditarHorarios = {false}
      puedeVerArchivos = {true}
    />
  )
};

export default FirmarRecepcionHorarioLactancia;
