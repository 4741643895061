import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import TableSolicitudes from '../../components/TableSolicitudes'
import PageHeader from '../../../../components/pageHeader/PageHeader'
import PageButtons from '../../../../components/pageComponents/PageButtons'
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading'
import SearchContainer from '../../../../components/pageComponents/SearchContainer'
import { AuthContext } from '../../../../hooks/context/authContext'
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect'
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest'
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest'
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../../helper/utils'
import { getAllAreasAPI, getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, listarMisSolicitudesCuponTiempoLibreAPI } from '../../../../consumers/backendApisUrls'

const MisSolicitudesCupones = () => {

  const INIT_KEYS = { Cargos: "IdsCargos", Sedes: "IdsSedes", Areas: "IdsAreas", EstadosSolicitud: "IdsEstadosSolicitud", EstadosRegistro: "IdsEstadosRegistro" };

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const IdCuponeraTiempoLibre = searchParams.get("search")
  
  const {state} = useContext(AuthContext);
  const IdEmpleado = state.user.id_empleado;

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();

  const initialPromises = () => {
    return [
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllAreasAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = listarMisSolicitudesCuponTiempoLibreAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      IdEmpleado,
      IdCuponeraTiempoLibre,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleCleanSearch = () => {
    setFormValues({...formValues, selectedFilters: null});
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  if(isLoading) return <SpinnerLoading/>

  return (
    <>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Mis solicitudes de Cupones de tiempo libre" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={handleCleanSearch}>
        <PageButtons hasBackButton/>
      </SearchContainer>
      <TableSolicitudes formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS} isColaborador/>
    </>
  )
}

export default MisSolicitudesCupones;