import React, { useState } from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import popUpContent from '../../../image/concursoInnovacion.png';
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { aceptarOcultarModalConcursoInnovacionAPI } from "../../../consumers/backendApisUrls";

const ModalConcursoInnovacion = ({ showModal }) => {
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);
    const [executePostRequest] = usePostRequest();

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            const response = await executePostRequest(aceptarOcultarModalConcursoInnovacionAPI(), {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const closeModal = async function () {
        await submit();
        showModal();
    };

    return (
        <>
            <GenericModal showModal={closeModal} width={"560px"} titulo={"II Concurso Innovación Docente 2024"}>
                <div className="modal-body" style={{ 'padding': '0' }}>
                    <a href="https://drive.google.com/file/d/1qZ3H76S8cJDcClHTO5pMNqVtiog2Gr-9/view?usp=sharing" target="_blank">
                        <img src={popUpContent} alt='' width="100%" />
                    </a>
                </div>
                <div className="modal-footer" style={{ 'display': 'flex', 'justify-content': 'space-between' }}>
                    <div className="message font-gothan-light" style={{ 'align-self': 'flex-start' }}>
                    </div>
                    <div className="button" style={{ 'align-self': 'flex-end' }}>
                        <input type="checkbox" checked={checkNoVolverAMostrarModal} onClick={clickOnCheck} /> No mostrarme esta ventana otra vez&nbsp;&nbsp;
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalConcursoInnovacion;