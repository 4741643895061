import React from 'react'
import { DivInput } from '../atoms/DivInput';
import { INPUT_DIRECTION } from '../../constants/hardCodedConstants';

const InputNumber = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  sectionName,
  isRequired,
  isDisabled,
  colLabel=4,
  colInput=8,
  direction = "row",
  placeholder = `Ingresar ${label}`,
}) => {
  return (
    <DivInput direction={direction} colLabel={colLabel} colInput={colInput} className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label d-flex ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color ${value ? "" : "highlight-input"}`}>
        <input
          type="number"
          placeholder={placeholder}
          className="form-control"
          name={name}
          onChange={onChange}
          value={value ?? ""}
          disabled={isDisabled}
          onBlur={onBlur}
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </DivInput>
  );
};

export default InputNumber;