import React, { useState, useEffect, useContext } from "react";
import Select from 'react-select';
import { useManageGetRequest } from "../../hooks/useManageGetRequest/useManageGetRequest";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import PageHeader from "../../components/pageHeader/PageHeader";
import TabsHeader from "../../components/pageComponents/TabsHeader";
import VacacionesDisponibles from "./gestionDeVacaciones/disponibles/VacacionesDisponibles";
import ListaHistoricoVacaciones from "./gestionDeVacaciones/historico/ListaHistoricoVacaciones";
import ListaSolicitudesVacaciones from "./gestionDeVacaciones/solicitudes/ListaSolicitudesVacaciones";

import { getAllAreasAPI, getAllCargosAPI, getPuedeVerCargosMatricialesAPI } from "../../consumers/backendApisUrls";
import { ROLES, TIPOS_LISTAR_VACACIONES } from "../../constants/hardCodedConstants";
import { AuthContext } from "../../hooks/context/authContext";

const GestionDeVacaciones = () => {
  const {state, dispatch} = useContext(AuthContext);
  const [dropdownsAreas, setDropdownsAreas] = useState([]);
  const [dropdownsCargos, setDropdownsCargos] = useState([]);
  const [executeGet] = useManageGetRequest();
  const [puedeVerCargosMatriciales, setPuedeVerCargosMatriciales] = useState(false);
  const [filterType, setFilterType] = useState(TIPOS_LISTAR_VACACIONES.NoMatricial.value);

  const init = async () => {
    try {
      getDropDownOptions();
      executeGet(getPuedeVerCargosMatricialesAPI(), (res) =>  setPuedeVerCargosMatriciales(res.data.PuedeVerCargosMatriciales), false);
    } catch (error) {
      console.error(error);
    }
  };

  const getDropDownOptions = async () => {
    executeGet(getAllAreasAPI(), (res) =>  setDropdownsAreas(res.data), false);
    executeGet(getAllCargosAPI(), (res) =>  setDropdownsCargos(res.data), false);
  }

  const selectOptions = [
    { value: TIPOS_LISTAR_VACACIONES.NoMatricial.value, label: TIPOS_LISTAR_VACACIONES.NoMatricial.text }
  ];

       if((state.roleIds.includes(ROLES.LiderMatricial.id))){
        selectOptions.push({ value: TIPOS_LISTAR_VACACIONES.Matricial.value, label: TIPOS_LISTAR_VACACIONES.Matricial.text });
        }



  const handleSelectChange = (selectedOption) => {
    setFilterType(selectedOption.value);
   
  };

  const getOptions = () => {
    const components = []
   
      components.push({id: "tabListaVacaciones", title: "Lista de solicitudes", component: <ListaSolicitudesVacaciones dropdownsAreas={dropdownsAreas} dropdownsCargos={dropdownsCargos} filterType={filterType} />} )
      components.push({id: "tabListaHistoricoVacaciones", title: "Historico de vacaciones", component: <ListaHistoricoVacaciones dropdownsAreas={dropdownsAreas} dropdownsCargos={dropdownsCargos} filterType={filterType}
      />} );
      components.push({id: "tabVacacionesDisponibles", title: "Vacaciones disponibles", component: <VacacionesDisponibles filterType={filterType}/>});
 
 
  return components
  }

  useEffect(() => {
    init();
  }, [])
  
    return (
      <>
        <div className="message-no-disponible-en-mobile">
          <p>Esta página no está disponible en mobile.</p>
        </div>
        <div className="no-disponible-en-mobile">
          <Wrapper className="App">
            <PageHeader title="Gestión de Vacaciones" />
            <div className='col-sm-4 mt-2 ml-2'>

            <Select
            options={selectOptions}
            value={selectOptions.find(option => option.value === filterType)}
            onChange={handleSelectChange}
            placeholder=""
          />
          </div>
            <TabsHeader list={getOptions()}/>
          </Wrapper>
        </div>
      </>
    );
}

export default GestionDeVacaciones