
import React, { useContext, useEffect, useState } from 'react';
import { shorterName } from '../../helper/utils';
import { ContainerIcon } from '../../pages/principal/Principal';
import NewItemIcon from '../icons/NewItemIcon';
const ColoredCard = ({ color, title, icon, publishedDate, isNew, onClick, height, tooltip = null }) => {
  const [hover, setHover] = useState(false);

  const customStyle = {
    minWidth: "14rem",
    maxWidth: "14rem",
    height: height ? height : "24rem",
    backgroundColor: color ? color : "#8dba38",
  };

  return (
    <div
      className={`position-relative mx-2 mt-3 ${hover ? 'shadow-lg' : ''}`}
      style={{
        transform: hover ? 'scale(1.05)' : 'scale(1)'
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      title={tooltip}
    >
      {isNew ? (
        <ContainerIcon style={{
          left: '0',
          right: 'auto',
          padding: '0',
          top: '0',
          cursor: 'default'
        }}>
          <NewItemIcon white />
        </ContainerIcon>
      ) : null}
      <div
        style={customStyle}
        className="d-flex flex-column justify-content-center align-items-center"
        onClick={onClick}
      >
        <div>
          {icon}
        </div>
        <div className="px-3 mt-1">
          <p className="text-white text-wrap font-weight-bold h5 text-center">
            {shorterName(title ?? "", 55)}
          </p>
        </div>
        <div className="px-3">
          <p className="text-white text-wrap text-center font-weight-light">
            <small>{publishedDate}</small>
          </p>
        </div>
      </div>
    </div>

  )
}

export default ColoredCard;
