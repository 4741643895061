import React, {useState, useEffect} from 'react';
import {string, number, bool} from 'prop-types';
import parse from "html-react-parser";

const INITIAL_OFFSET = 25;
const circleConfig = {
    viewBox: '0 0 38 38',
    x: '19',
    y: '19',
    radio: '15.91549430918954',
};

const CircleProgressBarVacunas = ({
                                      className,
                                      strokeColor,
                                      strokeWidth,
                                      innerText,
                                      legendText,
                                      percentage,
                                      trailStrokeWidth,
                                      trailStrokeColor,
                                      trailSpaced,
                                      speed,
                                      stateName
                                  }) => {
    const [progressBar, setProgressBar] = useState(0);
    const pace = percentage / speed;
    const updatePercentage = () => {
        setTimeout(() => {
            setProgressBar(progressBar + 1);
        }, pace);
    };

    useEffect(() => {
        if (percentage > 0) updatePercentage();
    }, [percentage]);

    useEffect(() => {
        if (progressBar < percentage) updatePercentage();
    }, [progressBar]);

    return (
        <div className={className}>
            <svg viewBox={circleConfig.viewBox}>
                <circle
                    className='donut-ring'
                    cx={circleConfig.x}
                    cy={circleConfig.y}
                    r={circleConfig.radio}
                    fill='transparent'
                    stroke={trailStrokeColor}
                    strokeWidth={trailStrokeWidth}
                    strokeDasharray={trailSpaced ? 1 : 0}
                />

                <circle
                    className='donut-segment'
                    cx={circleConfig.x}
                    cy={circleConfig.y}
                    r={circleConfig.radio}
                    fill='transparent'
                    stroke={strokeColor}
                    strokeWidth={strokeWidth}
                    strokeDasharray={`${progressBar} ${100 - progressBar}`}
                    strokeDashoffset={INITIAL_OFFSET}
                />

                <g className='chart-text'>
                    <foreignObject x="16%" y="16%" width="25" height="20">
                      <div className="area">
                        <div className="bubble">
                            <p>{parse(`${stateName??""}`)}</p>
                        </div>
                      </div>
                    </foreignObject>
                </g>
            </svg>
            {legendText && (
                <figcaption className='figure-key'>
                    <ul
                        className='figure-key-list'
                        aria-hidden='true'
                        role='presentation'>
                        <li>
                            <span className='shape-circle'/>
                            <span>{legendText}</span>
                        </li>
                    </ul>
                </figcaption>
            )}
        </div>
    );
};

CircleProgressBarVacunas.propTypes = {
    className: string.isRequired,
    strokeColor: string,
    strokeWidth: number,
    innerText: string,
    legendText: string,
    percentage: number,
    trailStrokeWidth: number,
    trailStrokeColor: string,
    trailSpaced: bool,
    speed: number,
};

CircleProgressBarVacunas.defaultProps = {
    strokeColor: 'blueviolet',
    strokeWidth: 2,
    innerText: 'Completed',
    legendText: '',
    percentage: 0,
    trailStrokeWidth: 2,
    trailStrokeColor: '#d2d3d4',
    trailSpaced: false,
    speed: 0,
};

export default CircleProgressBarVacunas;
