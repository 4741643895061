import React, { useContext } from 'react';
import { AuthContext } from '../../hooks/context/authContext';
import GenericModal from '../modal/GenericModal';

const RegistroVacunaModal = ({ showModal, setHideRegistroVacunaModal }) => {

    const {state, dispatch} = useContext(AuthContext);

    const onClickIngresar = () => {
        showModal();
        setHideRegistroVacunaModal(false);
    }
    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo={"Actualiza tu registro de vacunación COVID-19"}>
                <div className="modal-body">
                    <p>Estimado {state.user.name}</p>
                    <p>
                        Hemos verificado que a la fecha tu estado de vacunación se mantiene en la opción que marcaste informándonos 
                        que aún no te vacunas. Ya que a la fecha todos y todas nos podemos vacunar por trabajar en el sector Educación, 
                        es importante que nos indiques los motivos por los cuales aún no te vacunas y por ello te invitamos a completar 
                        las siguientes alternativas.
                    </p>
                    <p>Atte;</p>
                    <p>Equipo GDH</p>
                </div>
                <div className="modal-footer" style={{ 'display': 'flex', 'justifyContent': 'flex-end' }}>
                    <div className="button" style={{ 'alignSelf': 'flex-end' }}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onClickIngresar}>Ingresar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default RegistroVacunaModal;