import React, { useState, useEffect } from "react";
import {toast} from "react-toastify";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useForm } from "../../../hooks/useForm/UseForm";
import GenericModal from "../../../components/modal/GenericModal";
import AsyncSelectInput from "../../../components/Select/AsyncSelectInput";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { AlertErrorMaxFileSizeCeses } from "../../Ceses/alerts/AlertErrorMaxFileSizeCeses";
import { addDays, calculateDaysInBetween, isEmpty, toIsoForInput } from "../../../helper/utils";
import { getInfoVacacionesAPI, crearSolicitudVacacionesAdminAPI, getDropDownEmpleadoAPI } from "../../../consumers/backendApisUrls";

const CrearSolicitudVacacionesAdmin = ({ showModal, refreshPage }) => {
  const SECTION_NAME = "CrearSolicitudVacaciones";
  const TIME_STAMP = "T00:00:00";
  const KEYS = ["StartDate", "EndDate", "SolicitudVacacionesFirmada"];

  const [datosEmpleado, setDatosEmpleado] = useState({});
  const [formValues, setFormValues] = useState({});
  const [diasVacacionesCalculado, setDiasVacacionesCalculado] = useState(0);
  const [minDiasInput, setMinDiasInput] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const [executeGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();
  const [handleChange] = useForm(formValues, setFormValues);

  const [jsonSelectedIdBuscarEmpleado, setJsonSelectedIdBuscarEmpleado] = useState({});
  const [idempleadoAprobacion, setIdempleadoAprobacion] = useState(0);

  const [subiendoArchivo, setSubiendoArchivo] = useState(false);
  const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});

  const UPDATE_SUSTENTO_INPUT_FILE = SECTION_NAME + "-sustento-file";

  const manageSearch = async () => {
    if (isEmpty(jsonSelectedIdBuscarEmpleado?.value)) {
        toast.warn("Ingresar un criterio de búsqueda.");
        return;
    }
    const api = getInfoVacacionesAPI(jsonSelectedIdBuscarEmpleado?.value);
    const response = await executeGet(api, ()=> {});
    if (responseCode200(response)) {
      let empleadoFormacion = response.data;
      setDatosEmpleado(empleadoFormacion);
      setIdempleadoAprobacion(empleadoFormacion.IdAprobador)
    }
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    setIsLoading(true);
    try {
      let api = crearSolicitudVacacionesAdminAPI();
      let payload = {...formValues, IdSolicitante : jsonSelectedIdBuscarEmpleado?.value, IdJefe: idempleadoAprobacion, FileViewModel: s3UploadFileResponse}
      await submitForm(KEYS, api, payload, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const successSubmitCallback = () => {
    showModal();
    refreshPage();
  }

  const calculateVacationDays = () => {
    if(formValues.StartDate && formValues.EndDate && formValues.EndDate >= formValues.StartDate){
      let days = calculateDaysInBetween(formValues.StartDate, formValues.EndDate);
      setDiasVacacionesCalculado(days);
    } else if(formValues.StartDate && formValues.EndDate && formValues.StartDate > formValues.EndDate){
      let day = new Date(formValues.StartDate+TIME_STAMP);
      let dayISO = toIsoForInput(day);
      setFormValues({...formValues, EndDate: dayISO});
    }
    else {
      setDiasVacacionesCalculado(0);
    }
  }

  const calculateMinDaysInputDate = () => {
    let minDays = {
      //minStartDate: toIsoForInput(addDays(new Date(), 1)),
      minEndDate: toIsoForInput(addDays(new Date(), 1))
    }

    if(formValues.StartDate) 
      minDays.minEndDate = toIsoForInput(new Date(formValues.StartDate+TIME_STAMP));

    setMinDiasInput(minDays)
  }

  const handleBuscarEmpleado = async (jsonValue) => {
    setJsonSelectedIdBuscarEmpleado(jsonValue);
  }

  useEffect(() => {
    calculateVacationDays();
    calculateMinDaysInputDate();
  },[formValues])

  return (
    <GenericModal
      showModal={showModal}
      titulo="Crear solicitud de Vacaciones"
      width="750px"
    >
      <div className="modal-body">
        <form>
          <div className="form-row">
                <label className="col-sm-3 col-form-label modal-label">Buscar colaborador</label>
                <div className="col-sm-7">
                    <AsyncSelectInput
                        api={getDropDownEmpleadoAPI}
                        placeholder="Buscar por nombre y/o apellidos"
                        handleSelectInputChange={handleBuscarEmpleado}
                    />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${SECTION_NAME}-IdBuscarEmpleado-validation-message`}></p>
                </div>
                <div className="col-sm-2">
                    <button
                        onClick={manageSearch}
                        type='button'
                        className='btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'>
                        Buscar
                    </button>
                </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-5">
              <label className="modal-label mb-0">Solicitante</label>
              <input type="text" className="form-control" value={datosEmpleado.Solicitante ?? ""} disabled/>
            </div>
            <div className="form-group col-sm-7">
              <label className="modal-label mb-0">email</label>
              <input type="text" className="form-control" value={datosEmpleado.Email ?? ""} disabled/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-5">
              <label className="modal-label mb-0">Área</label>
              <input type="text" className="form-control" value={datosEmpleado.Area ?? ""} disabled/>
            </div>
            <div className="form-group col-sm-7">
              <label className="modal-label mb-0">Aprobador</label>
              <input type="text" className="form-control" value={datosEmpleado.Aprobador ?? ""} disabled/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-5">
              <label className="modal-label mb-0">Días disponibles</label>
              <input type="text" className="form-control" value={datosEmpleado.DiasDisponibles ?? ""} disabled/>
            </div>
            <div className="form-group col-sm-7 m-auto">
              <div className="d-flex justify-content-end mr-5">
                <>
                  { datosEmpleado?.PuederVerPolitica ? 
                    (
                    <a className="btn generic-button-buscador" style={{width: "135px"}} target="_blank" href={datosEmpleado.LinkPolitica ?? ""}>
                      Mira aquí la política
                    </a>
                    ):null
                  }
                </>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-4">
              <label className="modal-label mb-0">Desde</label>
              <input type="date" className="form-control" name="StartDate" value={formValues.StartDate ?? ""} onChange={handleChange} />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-StartDate-validation-message`}></p>
            </div>
            <div className="form-group col-sm-4">
              <label className="modal-label mb-0">Hasta</label>
              <input type="date" className="form-control" name="EndDate" value={formValues.EndDate ?? ""} onChange={handleChange} min={minDiasInput.minEndDate}/>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-EndDate-validation-message`}></p>
            </div>
            <div className="form-group col-sm-4">
              <label className="modal-label mb-0">Días</label>
              <input type="text" className="form-control" value={diasVacacionesCalculado} disabled/>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-sm-12">
              <label className="modal-label mb-0">Observaciones</label>
              <textarea className="form-control" placeholder="Completa en caso necesites dar más detalle de la solicitud" onChange={handleChange} name="Comentarios" value={formValues.Comentarios ?? ""}/>
            </div>
          </div>
          <div className="form-row">
            <label className="col-sm-3 col-form-label modal-label">Cargar formato<span className="required">*</span></label>
              <div className="col-sm-9 flex-row justify-content-center ">
                <GenericUploadFileV2 uploadFileToS3={setS3UploadFileResponse} id={UPDATE_SUSTENTO_INPUT_FILE} textButton={"Subir Archivo"} extensionesValidas={["pdf"]} ToastArchivoNoValido={AlertErrorMaxFileSizeCeses}
                  subiendoArchivoPublicState={subiendoArchivo} setSubiendoArchivoPublicState={setSubiendoArchivo}/>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-SolicitudVacacionesFirmada-validation-message`}></p>
            </div>

          </div>
          <div className="form-row">
              <p></p>
          </div>
          <div className="modal-footer pl-0">
            <div>
            </div>
            <div>
              <button type="button" onClick={handleSubmit} className="generic-button-buscador" disabled={isLoading}>
                Guardar
              </button>
            </div>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default CrearSolicitudVacacionesAdmin;