import React, { useState } from 'react'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputBool from '../../../components/modalComponents/InputBool'
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import { indicarAmonestacionesActivityAPI } from '../../../consumers/backendApisUrls';

const IndicarAmonestacionesModal = ({ toggleModal, manageSearch, idEmpleadoFlujoActividad: IdEmpleadoFlujoActividad }) => {
  
  const SECTION_NAME = "documento-observado";
  const KEYS = ["TieneAmonestaciones", "Comentario"];

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [inputProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = indicarAmonestacionesActivityAPI();
      const payload = {...formValues, IdEmpleadoFlujoActividad};
      await submitForm(KEYS, api, payload, SECTION_NAME, handleSubmitCallback, true, () => setIsSubmitting(false))
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitCallback = () => {
    setIsSubmitting(false);
    toggleModal();
    manageSearch();
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Indicar Amonestaciones" width="800px" saveButtonDisabled={isSubmitting}>
      <InputBool label="Usuario Tiene Amonestaciones" {...inputProps("TieneAmonestaciones")}/>
      <InputTextArea label="Comentario" {...inputProps("Comentario")}/>
    </ModalForm>
  )
}

export default IndicarAmonestacionesModal