import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../hooks/context/authContext";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import GenericModal from "../../../components/modal/GenericModal";
import {
  mostrarMensajeDeErrorConToast,
  responseCode200,
} from "../../../consumers/httpRequiestsUtils";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import {
  deleteFileFromS3API,
  descargarArchivosZipAPI,
  getAllSedesAPI,
} from "../../../consumers/backendApisUrls";
import { downloadFile } from "../../../consumers/backendConsumer";
import InputSelect from "../../../components/modalComponents/InputSelect";
import { usePropsSelect } from "../../../hooks/useProps/usePropsSelect";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { handleFind, initialAllOptionPromises } from "../../../helper/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DescargarZipModal = ({ showModal, idActividad }) => {
  const SECTION_NAME = "modalDescargarZip";
  const INIT_KEYS = { Sedes: "IdSede" };

  const { state } = useContext(AuthContext);

  const [formValues, setFormValues] = useState({
    IdFlujo: idActividad,
  });
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [executePostRequest] = usePostRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [selectProps] = usePropsSelect(
    allDropdowns,
    formValues,
    setFormValues,
    SECTION_NAME
  );

  const initialPromises = () => {
    return [getOptions(getAllSedesAPI())];
  };

  const init = async () => {
    await initialAllOptionPromises(
      initialPromises,
      Object.values(INIT_KEYS),
      updateFormValues
    );
  };

  const updateFormValues = (data) => {
    setAllDropdowns(data);
    const targetSedes = handleFind(data, INIT_KEYS.Sedes);
    if (targetSedes?.length === 1) {
      const targetSede = targetSedes[0].value;
      setFormValues({ [INIT_KEYS.Sedes]: targetSede });
    }
  };

  const descargarArchivosZip = async () => {
    setLoading(true);
    try {
      const api = descargarArchivosZipAPI();
      let payload = formValues;
      await submitForm(
        Object.values(INIT_KEYS),
        api,
        payload,
        SECTION_NAME,
        successDescargarReporteCallback
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const successDescargarReporteCallback = async (res) => {
    let data = res.data;
    let reporte = await downloadFile(
      state.token,
      data?.FilePath,
      data?.OriginalFileName
    );
    if (responseCode200(reporte)) {
      await eliminarArchivoDeS3(data?.FilePath);
    } else {
      mostrarMensajeDeErrorConToast(reporte);
    }
  };

  const eliminarArchivoDeS3 = async function (filePath) {
    let deleteFileFromS3Payload = {
      Path: filePath,
    };
    let deleteFileFromS3Response = await executePostRequest(
      deleteFileFromS3API(),
      deleteFileFromS3Payload
    );
    if (responseCode200(deleteFileFromS3Response)) {
      console.debug("delete file from s3 successfully");
    } else {
      mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <GenericModal
        showModal={showModal}
        width={"500px"}
        titulo={"Descargar documentos zip"}>
        <div className="modal-body">
          <InputSelect
            label="Sede"
            isRequired
            {...selectProps(INIT_KEYS.Sedes)}
          />
        </div>
        <div
          className="modal-footer"
          style={{ display: "flex", "justify-content": "space-between" }}>
          <div
            className="message font-gothan-light"
            style={{ "align-self": "flex-start" }}>
            <strong>
              <p>* Campos obligatorios</p>
            </strong>
          </div>
          <div className="button" style={{ "align-self": "flex-end" }}>
            {loading ? (
              <div className="btn btn-primary generic-button-ficha disabled">
                <FontAwesomeIcon
                  icon="spinner"
                  transform="left-9"
                  className="hand-pointer spinner ml-3"
                />
              </div>
            ) : (
              <button
                className="btn btn-primary generic-button-ficha"
                onClick={descargarArchivosZip}>
                Descargar
              </button>
            )}
          </div>
        </div>
      </GenericModal>
    </>
  );
};
