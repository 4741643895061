import React, { useState } from "react";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { cargarHelpDeskAPI } from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import GenericModal from "../../../components/modal/GenericModal";

const CargarHelpDesk = ({ showModal, manageSearch }) => {
  const CARGA_HELP_DESK = "carga-helpdesk";
  const EXTENSIONES_VALIDAS = ["xls", "xlsx"];

  const [uploadFileHelpDesk, setUploadFileHelpDesk] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [executePost] = useManagePostRequest();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
        const api = cargarHelpDeskAPI();
        let payload = getPayload();
        await executePost(api, payload, successSubmitCallback);
    } catch (error) {
        console.error(error)
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
        FilePath: uploadFileHelpDesk.Path,
        OriginalFileName: uploadFileHelpDesk.OriginalFileName
    }
  }

  const successSubmitCallback = () => {
    showModal();
    manageSearch();
  }

  return (
    <GenericModal
      showModal={showModal}
      width={"650px"}
      titulo="Cargar Helpdesk"
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
            <div className="form-group row mb-4">
                <label className="col-sm-2 col-form-label modal-label">
                Plantilla
                </label>
                <div className="col-sm-10 px-0 d-flex justify-content-center">
                    <GenericUploadFileV2
                        uploadFileToS3={setUploadFileHelpDesk}
                        id={CARGA_HELP_DESK}
                        textButton={"Adjuntar archivo"}
                        extensionesValidas={EXTENSIONES_VALIDAS}
                        showToastSuccess={false}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <button
                    type="submit"
                    className="generic-button-buscador"
                    disabled={isSubmitting}
                    >
                    Cargar
                </button>
            </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default CargarHelpDesk;
