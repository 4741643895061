import React, {useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {updateValidationMessage} from "../../../validations/FormValidatorUtils";

const DeclaracionJuradaModal = ({showModal, guardarCallBackEndAPI}) => {
    const sectionName = "DeclaracionJuradaModal";
    const checkboxInputKey = "declaracionJurada";
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            updateValidationMessage(checkboxInputKey, "", sectionName);
            await guardarCallBackEndAPI();
        } else {
            updateValidationMessage(checkboxInputKey, "Debe aceptar la Declaración Jurada", sectionName);
        }
    };

    const closeModal = async function () {
        showModal();
    };

    return (
        <>
            <GenericModal showModal={closeModal} width={"700px"} titulo={"Declaración Jurada de Residencia"}>
                <div className="modal-body">
                    <div className="d-flex flex-row">
                        <div>
                            <input type="checkbox" checked={checkNoVolverAMostrarModal} onClick={clickOnCheck}/>
                        </div>
                        <div>
                            <p>Declaro bajo juramento que el domicilio indicado es el real y en el cual resido actualmente.</p>
                            <p>Los datos que se indican son para acreditar mi residencia en el procedimiento administrativo de certificación domiciliaria que sigo ante la empresa COLEGIOS PERUANOS SA.</p>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-${checkboxInputKey}-validation-message`}></p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer" style={{'display': 'flex', 'justify-content': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'align-self': 'flex-start'}}>
                    </div>
                    <div className="button" style={{'align-self': 'flex-end'}}>
                        <button className="btn btn-primary ficha-form-button-guardar" onClick={submit}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default DeclaracionJuradaModal;

