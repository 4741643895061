import React, { useEffect, useState } from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { encuestaSaludRegistrarEstadoAPI, getDetalleFichaMedicaAPI, getDetallePoliticaAPI, guardarDescargaArchivoPolitica, guardarLecturaPoliticaAPI, solicitarRevisionFichaSaludAPI } from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { ALL_ESTADOS } from "../../../constants/hardCodedConstants";
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';

const content = '{"blocks":[{"key":"b3374","text":"test test","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

const DetallePoliticaYReglamento = ({ toggleModal, politicId, setReload }) => {
  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();
  const [uploadFileCertificadoMedico, setUploadFileCertificadoMedico] = useState({});
  const ID_INPUT_FILE = "SolicitoRevisarCasoFile"
  const sectionName = "SolicitoRevisarCaso"
  const [manageGetRequest] = useManageGetRequest();
  const [managePost] = useManagePostRequest();
  const [detalle, setDetalle] = useState({});
  const [mostrarCompromiso, setMostrarCompromiso] = useState(true);
  const [storedText, setStoredText] = useState();
  const [descargarArchivoS3] = useDescargarArchivoDeS3()

  const [showDescriptionText, setShowDescriptionText] = useState(false);
  const [descriptionImageUrl, setDescriptionImageUrl] = useState("");
  const [moreInfoImageUrl, setMoreInfoImageUrl] = useState("");


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  const callbackSuccessGetDetalle = function (response) {
    setDetalle(response.data);
    setMostrarCompromiso(response.data?.MostrarCompromiso);

    setMoreInfoImageUrl(response?.data?.UrlImageMoreInfo);

    if (isDescriptionImageMode(response?.data?.HtmlDescripcion)){
      setShowDescriptionText(false)
      setDescriptionImageUrl(response?.data?.UrlImageDescription);


    }else{
      const parsedContent = convertFromRaw(JSON.parse(response.data.HtmlDescripcion));
      //const editorStateContent = EditorState.createWithContent(parsedContent);
      setStoredText(() => EditorState.createWithContent(parsedContent))
      setShowDescriptionText(true)
    }

  }

  const isDescriptionImageMode = (descriptionTextAlreadySaved) => descriptionTextAlreadySaved==null;


  const getInitialData = async () => {
    try {
      await manageGetRequest(getDetallePoliticaAPI(politicId), callbackSuccessGetDetalle, false);
    } catch (e) {
      console.error(e);
    }
  };

  const successSubmitCallback = function () {
    setReload((reload) => !reload);
    toggleModal();
  }

  const submit = async () => {
    let payload = armarPayload();
    let api = solicitarRevisionFichaSaludAPI();
    let keys = ["IdEncuestaSaludDetalle", "IdEstadoFicha", "PresentaCondicionMedica", "IdResultado", "Descripcion", "Sustento"];
    await submitForm(keys, api, payload, sectionName, successSubmitCallback);
  };

  const armarPayload = () => {
    let payload = {
      "IdEncuestaSaludDetalle": detalle?.IdEncuestaSaludDetalle,
      "Descripcion": formValues?.Descripcion,
      "Sustento": uploadFileCertificadoMedico
    }

    return payload;
  }

  const guardarCompromisoLectura = async (id) => {
    await managePost(guardarLecturaPoliticaAPI(id), {}, () => {
      setMostrarCompromiso(false);
    }, true);
  }

  const handleDescargarArchivo = async (file) => {
    await managePost(guardarDescargaArchivoPolitica(file?.IdArchivo), {}, () => {}, false);
  }


  return (
    <>
      <GenericModal showModal={toggleModal} width={"1180px"} titulo={"Detalle"}>
        <div className="modal-body pt-0 pl-0">

          <p className="h5 font-weight-bold">{detalle.NombrePolitica}</p>

          <div className="container-fluid">
            <div className="row">
              <div className="col col-12 col-md-8 p-0 pr-md-5">
                {showDescriptionText ? <Editor
                        editorState={storedText}
                        readOnly
                        toolbarHidden
                    /> :
                    <div className="d-flex align-items-center justify-content-center mb-1">
                      <img src={descriptionImageUrl} alt="" width="700"/>
                    </div>
                }

                {detalle?.Files?.length > 0 ?
                  (<div className="table-responsive mt-3">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th className="col-9">Nombre</th>
                          <th className="col-3">Abrir PDF aquí</th>
                        </tr>
                      </thead>
                      <tbody>
                        {detalle?.Files?.map(file => (
                          <tr key={file?.IdArchivo}>
                            <th>
                              {file?.OriginalFileName}
                            </th>
                            <th>
                              <a
                                href={file.Path}
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  icon='download'
                                  onClick={() => handleDescargarArchivo(file)}
                                />
                              </a>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>) : null
                }

              </div>

              <div className="col col-12 col-md-4 p-0">
                <img src={moreInfoImageUrl} alt="" width="350"/>
                {/*}
                {detalle?.UltimasPublicaciones?.length > 0 ? (
                  <p
                    className="h6 font-weight-bold"
                    style={{ color: "#EA8215" }}
                  >
                    Últimas publicaciones:
                  </p>
                ) : null}
                {detalle?.UltimasPublicaciones?.map(publicacion => (
                  <div className="mb-2">
                    <a><strong>{publicacion.NombrePolitica}</strong></a>
                    <br />
                    <small>{publicacion.FechaPublicacion}</small>
                    {publicacion.FechaPublicacion? <br /> : null}
                  </div>
                ))}*/}
              </div>
            </div>
          </div>

        </div>
      </GenericModal>
    </>
  )


}

export default DetallePoliticaYReglamento;
