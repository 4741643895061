import React, { useEffect, useState } from 'react'
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageButtons from '../../../components/pageComponents/PageButtons'
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { useDescargarArchivoDeS3 } from '../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3'
import CrearRegistroLactario from '../modales/CrearRegistroLactario'
import TableAsistenciaLactario from './TableAsistenciaLactario'
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils'
import { asistenciaLactarioGetAllPeriodosRegistradosAPI, getAllSedesAPI, listarAsistenciaLactarioAPI } from '../../../consumers/backendApisUrls'

const INIT_KEYS = { sedes: "IdsSedes", periodos: "IdsPeriodos" }

const AsistenciaLactario = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [downloadFormat] = useDescargarArchivoDeS3();
  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    setIsLoading(true);
    const initialPromises = () => [
      executeGet(getAllSedesAPI()),
      executeGet(asistenciaLactarioGetAllPeriodosRegistradosAPI())
    ]
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = listarAsistenciaLactarioAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({ ...lastData, filters: res }))
  }

  const handleCleanSearch = () => {
    setFormValues({ ...formValues, selectedFilters: null });
  }

  const handleCreate = () => {
    setModalEnabled({ isEnable: true, component: CrearRegistroLactario, data: { extraData: formValues.ExtraData } })
  }

  const handleDescargarFormato = async () => {
    if (formValues.ExtraData?.FormatoAsistenciaLactarioFileViewModel){
      const { OriginalFileName, Path } = formValues.ExtraData.FormatoAsistenciaLactarioFileViewModel;
      await downloadFormat(OriginalFileName, Path);
    }
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={init} />
      <PageHeader title="Listado de control de asistencia lactario" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={handleCleanSearch}>
        <PageButtons hasBackButton hasCreateButton={formValues?.ExtraData?.PuedeCrearNuevo} handleCreateButton={handleCreate}>
          <button type='button' className='generic-button-buscador' onClick={handleDescargarFormato}>Descargar Formato</button>
        </PageButtons>
      </SearchContainer>
      <TableAsistenciaLactario formValues={formValues} setFormValues={setFormValues} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS} />
    </PageWrapper>
  )
}

export default AsistenciaLactario