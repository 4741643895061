import React from "react";
import Select from "react-select";
import PropTypes from 'prop-types';
import { INPUT_DIRECTION } from "../../constants/hardCodedConstants";

const InputMultiSelect = ({
  label,
  options,
  value,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
  direction = "row",
  placeholder = "Seleccionar",
}) => {
  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res.map(o => o.value) || [],
        name: name,
      },
    };
    onChange(data);
  };

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        <Select
          isMulti
          isClearable
          options={options ?? []}
          value={value ?? []}
          onChange={handleOnChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition='fixed' // check
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

export default InputMultiSelect;
