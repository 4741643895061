import React from "react"
import EncuestaTeletrabajo from "../modals/EncuestaTeletrabajo"
import InstructivoEncuestaTeletrabajo from "../modals/InstructivoEncuestaTeletrabajo"
import LinkCapacitacionEncuestaTeletrabajo from "../modals/LinkCapacitacionEncuestaTeletrabajo"
import { ACTIVIDADES_PENDIENTES_MODALES } from "../../../constants/hardCodedConstants"

export const ACTIONS = {
    IS_MODAL_ENABLED: "is_modal_enabled",
    IS_MODAL_LOADED: "is_modal_loaded"
}

export const initialState = {
    isModalEnabled: false,
    idEmpleado: null,
    idEmpleadoFlujoActividad: null,
    component: <></>
}

export const components = [
    { id: ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Link_Capacitacion.id, component: LinkCapacitacionEncuestaTeletrabajo },
    { id: ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Instructivo.id, component: InstructivoEncuestaTeletrabajo },
    { id: ACTIVIDADES_PENDIENTES_MODALES.Modal_EncuestaTeletrabajo_Encuesta.id, component: EncuestaTeletrabajo },
]

export const ActividadesPendientesreducer = (state, action) => {
    switch (action.type){
        case ACTIONS.IS_MODAL_ENABLED:
            return { ...state, isModalEnabled: false }
        case ACTIONS.IS_MODAL_LOADED:
            return { ...state, 
                        isModalEnabled: true, 
                        component: components.filter(o => o.id == action.payload)[0]?.component,
                        idEmpleadoFlujoActividad: action.idEmpleadoFlujoActividad }
        default:
            throw new Error();
    }
}