import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useGetOptionsSelect } from '../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useIndexValues } from '../../hooks/useIndexValues/useIndexValues';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import TableWebBody from './TableWebBody';
import PageTable from '../../components/pageComponents/PageTable';
import PageHeader from '../../components/pageHeader/PageHeader';
import FormSelect from '../../components/atoms/Select';
import SelectInput from '../../components/Select/SelectInput';
import PageButtons from '../../components/pageComponents/PageButtons';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import TableSelectSearch from '../../components/atoms/TableSelectSearch';
import ImportFileGenericModal from '../../components/modal/ImportFileGenericModal';
import CrearRequerimiento from './modales/CrearRequerimiento';
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../helper/utils';
import { getAllJornadasLaboralesAPI, cargarNuevosIngresosFromRequerimientosAPI, descargarRequerimientosToCreateNuevosIngresosAPI, exportarRequerimientoPersonalAPI, getAllAreasAPI, getAllCargosAPI, getAllDepartamentosAPI, getAllEstadosAPI, getAllSedesAPI, getDivisionesAPI, getPresupuestosAPI, getResponsablesNuevoIngresoGestionAPI, puedeCrearConfigurarGestionRequerimientoAPI, requerimientoPersonalIndexAPI, toggleEsControlRequerimientosAPI } from '../../consumers/backendApisUrls';
import SubirArchivoMasivo from './modales/SubirArchivoMasivo';
import { FILE_FORMATS } from '../../constants/hardCodedConstants';
import SpinnerLoading from '../../components/pageComponents/SpinnerLoading';

const GestionRequerimientos = () => {

  const ID_IMPORTAR = "importar-requerimientos";
  const KEYS_FILTERS = {TiposEmpleados: "IdsTiposEmpleados", Sedes: "IdsSedes", Divisiones: "IdsUnidades", Gerencias: "IdsDepartamentos", Areas: "IdsAreas", Cargos: "IdsCargos", EstadoAprobacion: "IdsEstadoAprobacion", EstadoProceso: "IdsEstadoProceso", Responsables: "IdsResponsables", EsControl: "EsControl"};

  const [flags, setFlags] = useState({});
  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const [allFilters, setAllFilters] = useState();
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});

  const [searchCriteria, setSearchCriteria] = useState("");
  const [dropdowns, setDropdowns] = useState([]);
  const [selectedDropdown, setSelectedDropdown] = useState({});
  const [paginator, setPaginator] = useState({CurrentPage: 1});

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();
  const [getOptions] = useGetOptionsSelect();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();
  const [searchIndex] = useIndexValues(setFormValues, setPaginator, setExtraData);

  const initialPromises = () => {
    return [
      executeGet(getAllJornadasLaboralesAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getDivisionesAPI()),
      executeGet(getAllDepartamentosAPI()),
      executeGet(getAllAreasAPI()),
      executeGet(getAllCargosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getResponsablesNuevoIngresoGestionAPI()),
      executeGet(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setLoading(true);
    const apiInit = puedeCrearConfigurarGestionRequerimientoAPI();
    try {
      await executePost(apiInit, {}, ({ data }) => setFlags(data));
      setDropdowns(await getOptions(getPresupuestosAPI()));
      await initialAllOptionPromises(initialPromises, Object.values(KEYS_FILTERS), setAllFilters)
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const manageSearch = async () => {
    const api = requerimientoPersonalIndexAPI();
    let payload = getPayload();
    setIsSearching(true);
    await executePost(api, payload, searchIndex, false);
    setIsSearching(false);
  };

  const getPayload = () => {
    return {
      IdPresupuesto: selectedDropdown.value,
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      ...selectedFilters
    }
  }

  const handleCreatePerfil = () => {
    setModalEnabled({isEnable: true, component: CrearRequerimiento});
  }

  const handleExportarFile = async () => {
    const payload = getPayload();
    await downloadAndDelete(exportarRequerimientoPersonalAPI(), payload);
  }

  const handleFilterSelected = (res, key) => {
    setSelectedFilters(lastData => ({...lastData, [key]: res }));
  }

  const handleToggleEsControl = async (IdRequerimientoPersonal) => {
    const api = toggleEsControlRequerimientosAPI();
    const payload = { IdRequerimientoPersonal };
    await executePost(api, payload, async () => await manageSearch());
  }
  
  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage])

  useDidMountEffect(() => {
    if(jsonNoEstaVacio(selectedFilters)) manageSearch();
  }, [selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(extraData) && updateFiltersWithExtraData(allFilters, extraData, setFilters);
  }, [extraData])

  useDidMountEffect(() => {
    if (selectedDropdown.value) manageSearch();
  }, [selectedDropdown])

  useEffect(() => {
    init();
  }, []);

  const tableHeaders = [
    {label: "Id"},
    {label: "Fecha de Registro"},
    {label: "Fecha de Aprobación"},
    {label: "Fecha Completado"},
    {label: "tpo att / tpo abie"},
    {label: 
      <DCenter>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Sedes)}
          description={<label className='text-wrap mb-0'>Sede</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Sedes)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Sedes] ?? []}
        />
      </DCenter>,
      col: "col-3"
    },
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.Divisiones)}
          description={<label className='text-wrap mb-0'>División</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Divisiones)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Divisiones] ?? []}
        />
      </DCenter>,
      col: "col-3"
    },
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.Gerencias)}
          description={<label className='text-wrap mb-0'>Gerencia</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Gerencias)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Gerencias] ?? []}
        />
      </DCenter>,
      col: "col-3"
    },
    {label: 
      <DCenter>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Areas)}
          description={<label className='text-wrap mb-0'>Área</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Areas)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Areas] ?? []}
        />
      </DCenter>,
      col: "col-3"
    },
    {label: 
      <DCenter>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Cargos)}
          description={<label className='text-wrap mb-0'>Cargo</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Cargos)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Cargos] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: 
      <label className='upload-file-sustento-solicitud-cambio-message mb-0'>
        Especialidad
      </label>
    },
    {label: 
      <DCenter>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.TiposEmpleados)}
          description={<label className='text-wrap mb-0'>Jornada Laboral</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.TiposEmpleados)}
          selectedOptions={selectedFilters[KEYS_FILTERS.TiposEmpleados] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: 
      <label className='upload-file-sustento-solicitud-cambio-message mb-0'>
        Avance
      </label>
    },
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.EstadoAprobacion)}
          description={<label className='text-wrap mb-0'>Est. Aprob.</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EstadoAprobacion)}
          selectedOptions={selectedFilters[KEYS_FILTERS.EstadoAprobacion] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.EstadoProceso)}
          description={<label className='text-wrap mb-0'>Est. Proceso</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EstadoProceso)}
          selectedOptions={selectedFilters[KEYS_FILTERS.EstadoProceso] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: 
      <DCenter>
        <TableSelectSearch
          options={handleFind(filters, KEYS_FILTERS.Responsables)}
          description={<label className='text-wrap mb-0'>Responsable</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.Responsables)}
          selectedOptions={selectedFilters[KEYS_FILTERS.Responsables] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: 
      <DCenter>
        <FormSelect
          options={handleFind(filters, KEYS_FILTERS.EsControl)}
          description={<label className='text-wrap mb-0'>Control</label>}
          setSelectedOptions={(res) => handleFilterSelected(res, KEYS_FILTERS.EsControl)}
          selectedOptions={selectedFilters[KEYS_FILTERS.EsControl] ?? []}
        />
      </DCenter>,
      col: "col-1"
    },
    {label: "Acciones", col: "col-1"},
  ];

  return (
    <PageWrapper isLoading={loading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Gestión de Requerimientos"/>
      <SelectPresupuesto dropdowns={dropdowns} selectedDropdown={selectedDropdown} setSelectedDropdown={setSelectedDropdown} />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={() => setSelectedFilters({})}>
        <PageButtons hasCreateButton={flags.PuedeCrearConfigurar} handleCreateButton={handleCreatePerfil}>
          <div className="dropdown">
            <button className="generic-button-buscador dropdown-toggle" type="button" id="dropdownMenuButtonNuevosIngresos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Acciones
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonNuevosIngresos">
              <button 
                type='button' 
                className='dropdown-item hand-pointer' 
                onClick={() => 
                  setModalEnabled({ 
                    isEnable: true, 
                    component: SubirArchivoMasivo, 
                    data: { 
                      api: cargarNuevosIngresosFromRequerimientosAPI(), 
                      id: ID_IMPORTAR, 
                      validExtensions: FILE_FORMATS.EXCEL_FORMATS, 
                      hasDownloadFormat: true, 
                      apiDownloadFormat: descargarRequerimientosToCreateNuevosIngresosAPI }})}>
                        Cierre Masivo
              </button>
            </div>
          </div>
          {flags.PuedeCrearConfigurar ?
            <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Configuración</span>}>
              <Link to="/adminGestionRequerimientos/configurarPerfilPuesto" className='dropdown-item'>Perfil de puesto</Link>
              <Link to="/adminGestionRequerimientos/configurarPresupuesto" className='dropdown-item'>Presupuesto</Link>
            </NavDropdown>
            : null
          }
          {(selectedDropdown.value && formValues.length > 0) && 
            <button type='button' className='generic-button-buscador' onClick={handleExportarFile}>Exportar</button>
          }
        </PageButtons>
      </SearchContainer>

      {isSearching 
        ? <div className='w-100'>
            <SpinnerLoading/> 
          </div>
        : <PageTable headers={tableHeaders} paginator={paginator} setPaginator={setPaginator}>
           <TableWebBody filas={formValues} setModalEnabled={setModalEnabled} manageSearch={manageSearch} handleToggleEsControl={handleToggleEsControl}/>
          </PageTable>
      }
    </PageWrapper>
  )
}

export default GestionRequerimientos;

const SelectPresupuesto = ({ dropdowns, selectedDropdown, setSelectedDropdown }) => {

  return (
    <div className="col-sm-4 black-color ml-2 mt-3">
      <SelectInput
        options={dropdowns} 
        jsonSelectedValue={selectedDropdown}
        setJsonSelectedValue={setSelectedDropdown}
        placeholder="Seleccionar Presupuesto"
      />
    </div>
  )
}

const DCenter = ({ children }) => (
  <div className='d-flex justify-content-center'>
    {children}
  </div>
)