import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericModal from '../../../../../components/modal/GenericModal'
import InputTextArea from '../../../../../components/modalComponents/InputTextArea';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { completarSeguimientoGestacionAPI } from '../../../../../consumers/backendApisUrls';

const KEYS = {idSeguimiento: "IdSeguimientoGestacion" , nota: "Nota"};
const SECTION_NAME = "CompletarSeguimientoMaternidad";

const CompletarSeguimiento = ({ toggleModal, refreshPage, IdSeguimientoGestacion }) => {
  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const handleCompletar = async () => {
    setIsSending(true);
    const api = completarSeguimientoGestacionAPI()
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const getPayload = () => {
    
    return {
      IdSeguimientoGestacion,
      ...formValues
    }
  }

  const handleSuccessSubmit = async () => {
    toggleModal();
    await refreshPage();
  }

  return (
    <GenericModal titulo="Completar Seguimiento" showModal={toggleModal} width="600px">
      <div className="modal-body pt-0 pl-0">
        <InputTextArea label="Nota" {...commonProps(KEYS.nota)} direction='column'/>
      </div>
      <div className="modal-footer justify-content-end">
        <button
          className="btn btn-primary generic-button-ficha-outlined mr-2"
          onClick={() => { toggleModal() }}>
          Cancelar
        </button>
        <button
          type='button'
          className="btn btn-primary generic-button-ficha"
          disabled={isSending}
          onClick={handleCompletar}>
          Completar {isSending && (<FontAwesomeIcon icon='spinner' transform="left-9" className="spinner ml-3" />)}
        </button>
      </div>
    </GenericModal>
  )
}

export default CompletarSeguimiento