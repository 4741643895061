import React, { useState } from 'react'
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../hooks/useProps/usePropsInput';
import InputDate from '../../components/modalComponents/InputDate';
import { enviarReporteDatosHijosTxtsAPI } from '../../consumers/backendApisUrls';

const SECTION_NAME = "EnviarReporteDatosHijos";

const ReporteDatosHijos = () => {

  const KEYS = {desde: "FechaInicio", hasta: "FechaFin"};
  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleSendReport = async () => {
    setIsSending(true);
    const api = enviarReporteDatosHijosTxtsAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, () => {});
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <CardBody>
      <Div>
        <InputDate isRequired label="Fecha Inicio - Validación Hijo" {...commonProps(KEYS.desde)}/>
        <InputDate isRequired label="Fecha Fin - Validación Hijo" {...commonProps(KEYS.hasta)}/>
      </Div>
      <Div>
        <Button handleSendReport={handleSendReport} isSending={isSending} />
      </Div>
    </CardBody>
  )
}

export default ReporteDatosHijos;

const CardBody = ({ children }) => (
  <div className='card-body'>
    <div id={SECTION_NAME}>
      {children}
    </div>
  </div>
)

const Div = ({ children }) => (
  <div className='col-sm-6'>
    {children}
  </div>
)

const Button = ({ handleSendReport, isSending }) => (
  <button type='button' className="btn btn-primary generic-button-ficha" onClick={handleSendReport} disabled={isSending}>
    {isSending
      ? 'Enviando Reporte ...'
      : 'Enviar Reporte'
    }
  </button>
)
