import React, {useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import { reasignarSolicitudVacacionesAPI, getResponsableEmpleadoAPI } from '../../../consumers/backendApisUrls';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const ReasignarSolicitudVacaciones = ({showModal, refreshPage, idSolicitudMovimiento, dataReasignar}) => {
    const KEYS = ["IdNuevoAprobador"];
    const SECTION_NAME = ["ReasignarAprobadorVacaciones"]

    const [selectedAprobador, setSelectedAprobador] = useState();
    
    const [submitForm] = useSubmitForm()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let api = reasignarSolicitudVacacionesAPI();
            let payload = getPayload();
            await submitForm(KEYS, api, payload, SECTION_NAME, successReasignarVacacionesCallback)
        } catch (error) {
            console.error(error);
        }
    }

    const getPayload = () => {
        return {
            IdSolicitudMovimiento: idSolicitudMovimiento,
            IdNuevoAprobador: selectedAprobador?.value
        }
    }

    const successReasignarVacacionesCallback = () => {
        showModal();
        refreshPage();
    }

  return (
    <GenericModal showModal={showModal} titulo="Reasignar solicitud vacaciones" width="600px">
        <div className="modal-body">
            <form>
                <div className="form-row mb-1">
                    <div className="form-group col-sm-12">
                        <label className="modal-label mb-0">Solicitante</label>
                        <input type="text" className="form-control" value={dataReasignar.NombreEmpleado ?? ""} disabled/>
                    </div>
                </div>
                <div className="form-row mb-1">
                    <div className="form-group col-sm-12">
                        <label className="modal-label mb-0">Aprobador</label>
                        <input type="text" className="form-control" value={dataReasignar.Aprobador ?? ""} disabled/>
                    </div>
                </div>
                <div className="form-row mb-1">
                    <div className="form-group col-sm-12">
                        <label className="modal-label mb-0">Periodo Vacaciones</label>
                        <input type="text" className="form-control" value={dataReasignar.PeriodoVacaciones ?? ""} disabled/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-sm-12">
                        <label className="modal-label mb-0">Nuevo Aprobador</label>
                        <AsyncSelectInput api={getResponsableEmpleadoAPI} placeholder="Buscar nuevo aprobador" handleSelectInputChange={(res) => setSelectedAprobador(res)}/>
                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdNuevoAprobador-validation-message`}></p>
                    </div>
                </div>
                <div className="modal-footer pl-0">
                    <div>
                    </div>
                    <div>
                    <button type="button" onClick={handleSubmit} className="generic-button-buscador">
                        Guardar
                    </button>
                    </div>
                </div>
            </form>
        </div>
    </GenericModal>
  )
}

export default ReasignarSolicitudVacaciones