import {useContext} from "react";
import {AuthContext} from "../context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {downloadFile} from "../../consumers/backendConsumer";

export const useDescargarArchivoDeS3 = () => {
    const {state} = useContext(AuthContext);

    const descargarArchivoDeS3 = async function (originalFileName, filePath) {
        let descargarArchivoResponse = await downloadFile(state.token, filePath, originalFileName);
        if (!responseCode200(descargarArchivoResponse)) {
            mostrarMensajeDeErrorConToast(descargarArchivoResponse);
        }
        return descargarArchivoResponse;
    };

    return [descargarArchivoDeS3]
};