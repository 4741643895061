import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GenericModal from '../../../components/modal/GenericModal';

const AceptarVacuna = ({ toggleModal}) => {

    const history = useHistory();

    const Continuar = async () => {
        history.push("/");
    };

    return (
        <GenericModal showModal={() => {Continuar(); toggleModal()}} width={"600px"} titulo={"Vacuna Confirmada"}>
            <div>
                <p>Registro de Vacunación Confirmado.</p>
                <button className="btn btn-primary generic-button-ficha" onClick={Continuar}>Continuar</button>
            </div>
        </GenericModal>
    )
}

export default AceptarVacuna;