import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import { getResponsableEmpleadoAPI } from "../../../consumers/backendApisUrls";
import { isNullOrUndefinded, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from "../../../helper/utils";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";

const TableWebBody = ({
  filas,
  setFilas,
  editarFila,
  showModalEliminarConfiguracionVacaciones,
  allMovimientos,
  allAprobadores,
  onSubmit,
  cancelarSubmit,
  SECTION_NAME
}) => {

  return(
  <>
    <tbody className="listar-tbody">
        {filas.map((fila, i) => (
            <tr key={i}>
                <td className="col-3">
                    <SelectInputOnMap options={allMovimientos} selectedValue={fila.IdTipoMovimiento} filas={filas} setFilas={setFilas} id={fila.IdConfiguracionMovimientos} name="IdTipoMovimiento" placeholder="Seleccionar" isDisabled={!fila.EstadoEditar}/>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdTipoMovimiento-validation-message`}></p>
                </td>
                <td className="col-2">
                    <div className="mt-2 ml-3">
                        {fila?.IdNivelAprobacion}
                    </div>
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdNivelAprobacion-validation-message`}></p>
                </td>
                <td className="col-3">
                    <AsyncSelectInputOnMap 
                        api={getResponsableEmpleadoAPI}
                        placeholder="Buscar o seleccionar aprobador"
                        defaultOptions={allAprobadores}
                        filas={filas}
                        setFilas={setFilas}
                        id={fila.IdConfiguracionMovimientos}
                        name="IdAprobador"
                        nameD="Aprobador"
                        value={fila.IdAprobador ? {value: fila.IdAprobador, label: fila.Aprobador} : ""}
                        isDisabled={!fila.EstadoEditar}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdAprobador-validation-message`}></p>
                </td>
                <td className="col-3">
                    <AsyncSelectInputOnMap 
                        api={getResponsableEmpleadoAPI}
                        placeholder="Buscar o seleccionar aprob. Alterno"
                        defaultOptions={allAprobadores}
                        filas={filas}
                        setFilas={setFilas}
                        id={fila.IdConfiguracionMovimientos}
                        name="IdAprobadorAlternativo"
                        nameD="AprobadorAlternativo"
                        value={fila.IdAprobadorAlternativo ? {value: fila.IdAprobadorAlternativo, label: fila.AprobadorAlternativo} : ""}
                        isDisabled={!fila.EstadoEditar}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdAprobadorAlternativo-validation-message`}></p>
                </td>
                <td className="col-1">
                    <div className="d-flex justify-content-start mt-2 ml-2">
                        {!fila.EstadoEditar ?
                            <>
                                <FontAwesomeIcon icon='edit' size="lg" className="hand-pointer mr-3" title="Editar configuración" onClick={() => editarFila(fila)}/>
                                <FontAwesomeIcon icon='trash' size="lg" className="hand-pointer" title="Eliminar configuración" onClick={() => showModalEliminarConfiguracionVacaciones(fila.IdConfiguracionMovimientos, fila.IdNivelAprobacion)}/>
                            </> :
                            <>
                                <FontAwesomeIcon icon='save' size="lg" className="hand-pointer mr-3" title="Guardar" onClick={() => onSubmit(fila)}/>
                                <FontAwesomeIcon icon='times' size="lg" className="hand-pointer" title="Cancelar" onClick={() => cancelarSubmit(fila.IdConfiguracionMovimientos)}/>
                            </>
                        }
                    </div>
                </td>
            </tr>
        ))}
    </tbody>
  </>)
};

export default TableWebBody;

const SelectInputOnMap = ({options, selectedValue, filas, setFilas, id, name, isDisabled, placeholder}) => {

    const handleSelectInputChange = (res) => {
        const newInputFields = filas.map(fila => {
            if (fila.IdConfiguracionMovimientos == id){
                fila[name] = res?.value;
            }
            return fila;
        });
        setFilas(newInputFields);
    }

    return (
        <div>
            <Select 
                placeholder={placeholder}
                value={options.filter(o => o.value == selectedValue)}
                options={options}
                onChange={handleSelectInputChange}
                isClearable
                isDisabled={isDisabled}
            />
        </div>
    )
}

const AsyncSelectInputOnMap = ({api, placeholder, isDisabled, defaultOptions, filas, setFilas, id, name, nameD, value}) => {
    const [executePost] = useManagePostRequest();

    const loadOptions = async (inputValue, callback) => {

        if(!inputValue) {
            return callback([]);
        }
        let payload = {Criteria: inputValue ?? ""}
        await executePost(api(), payload, (res) => successCallback(res, callback), false)
    };

    const successCallback = (res, callback) => {
        let data = res?.data;
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(data);
        callback(options);
    }

    const handleOnChange = (res) => {
        const newInputFields = filas.map(fila => {
            if (fila.IdConfiguracionMovimientos == id){
                fila[name] = res?.value;
                fila[nameD] = res?.label;
            }
            return fila;
        });
        setFilas(newInputFields);
    }

    return (
        <>
            <AsyncSelect
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={defaultOptions}
                placeholder={placeholder}
                onChange={handleOnChange}
                isDisabled={isDisabled}
                value={value}
            />
        </>
    );
}