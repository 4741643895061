import React from 'react';

const TabsHeader = ({ list, tabFichaClass }) => {
  return (
    <div className="container-fluid body-content">
      <ul className={`nav nav-tabs nav-groupy ${tabFichaClass}`} id="tabFicha" role="tablist" style={{flexWrap: "inherit"}}>
        {list?.map((o, i) => 
          <li key={`header-${o.title}-${o.id}-${i}`} className="nav-item nav-link-groupy" role="presentation">
            <a
              className={`nav-link font-gothan-medium ${i == 0 ? "active" : "nav-link-a"}`}
              id={`tab${o.id}`}
              data-toggle="tab"
              href={`#div${o.id}`}
              role="tab"
              aria-controls={`${o.id}`}
              aria-selected={i == 0}
            >
              {o.title}
            </a>
          </li>
        )}
      </ul>
      <div className="tab-content bg-white">
        {list?.map((o, i) => 
          <div key={`content-${o.title}-${o.id}-${i}`} className={`tab-pane fade ${i == 0 ? "show active" : ""}`} id={`div${o.id}`} role="tabpanel" aria-labelledby={`${o.id}-tab`}>
            {o.component}
          </div>
        )}
      </div>
    </div>
  )
}

export default TabsHeader