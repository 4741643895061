import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { handleFind } from '../../../helper/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputSelect from '../../../components/modalComponents/InputSelect';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { createSolicitudDescuentoPensionAPI, getSolicitudDescuentoPensionToCreateAPI } from '../../../consumers/backendApisUrls';

const SolicitudDescuentoPension = ({ toggleModal }) => {

  const {state} = useContext(AuthContext);
  const MESSAGE_TOAST = "Ya tiene una solicitud de descuento de pensión aprobada";
  const IMAGE_LOCK = "https://intranet.innovaschools.edu.pe/Content/image/lock_1.png";
  
  const KEYS = {hijosMatriculados: "HijoMatriculados", politicas: "Politicas", hijosSeleccionados: "HijosSeleccionados", LineamientosFile: "LineamientosFile"};
  const KEYS_SUBMIT = {IdsHijos: "NrosDocumentoHijos"};
  const SECTION_NAME = "solicitudDescuentoPension";
  const idEmpleado = state.user.id_empleado;

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isFetching, setIsFetching] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executePost] = useManagePostRequest();

  const init = async () => {
    const api = getSolicitudDescuentoPensionToCreateAPI(idEmpleado);
    const response = await executeGet(api);
    if (responseCode200(response)){
      successInitCallback(response);
    } else{
      const modalMensaje = response.response.data.Message;
      setModalEnabled({ isEnable: true, component: ModalMensaje, data: { toggleModal, modalMensaje, modalImage: IMAGE_LOCK }});
    }
    setIsFetching(false);
  }
  
  const successInitCallback = ({ data }) => {
    setFormValues(data);
    setAllDropdowns([updateDropdowns(KEYS_SUBMIT.IdsHijos, data[KEYS.hijosMatriculados])]);
  }

  const handleChangeSelect = ({ target }) => {
    const { name: key, value: obj } = target;

    if (obj.TieneSolicitudCreada){
      return toast.warning(MESSAGE_TOAST);
    }

    const currentValues = formValues[key] || [];
    const selectedValues = [...new Set([...currentValues, obj.value])];

    const selectedNames = formValues[KEYS.hijosMatriculados]?.filter(o => selectedValues.includes(o.NumeroDocumento));
    updateSelectedHijos(selectedValues, selectedNames);
    updateStateOnDropdownToShow(key, obj.value, true);
  }

  const handleDeleteHijo = (IdHijoMatriculado) => {
    const selectedValues = formValues[KEYS_SUBMIT.IdsHijos].filter(o => o !== IdHijoMatriculado);
    const selectedNames = formValues[KEYS.hijosSeleccionados].filter(o => o.NumeroDocumento !== IdHijoMatriculado);
    updateSelectedHijos(selectedValues, selectedNames);
    updateStateOnDropdownToShow(KEYS_SUBMIT.IdsHijos, IdHijoMatriculado);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = createSolicitudDescuentoPensionAPI();
    const payload = getPayload();
    try {
      await executePost(api, payload, () => toggleModal());
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdEmpleado: idEmpleado,
      [KEYS_SUBMIT.IdsHijos]: formValues[KEYS_SUBMIT.IdsHijos]
    }
  }
  
  const getValueOnSelect = () => {
    const valueSearched = formValues[KEYS_SUBMIT.IdsHijos]?.at(-1);
    if (!valueSearched) return null;
    const valueFinded = formValues[KEYS.hijosMatriculados]?.find(o => o.NumeroDocumento === valueSearched);
    if (!valueFinded) return null;
    return {value: valueFinded.NumeroDocumento, label: valueFinded.NombresCompletos};
  }

  const updateDropdowns = (key, data, isSelected = false) => {
    return {key, value: getValueAndLabelFromObj(data, isSelected)}
  }

  const getValueAndLabelFromObj = (data, isSelected) => {
    return data.map(o => ({label: o.NombresCompletos, value: o.NumeroDocumento, isSelected, TieneSolicitudCreada: o.TieneSolicitudCreada}));
  }

  const updateSelectedHijos = (selectedValues, selectedNames) => {
    setFormValues(lastData => ({...lastData, [KEYS_SUBMIT.IdsHijos]: selectedValues, [KEYS.hijosSeleccionados]: selectedNames}));
  }

  const updateStateOnDropdownToShow = (key, value, state = false) => {
    const dropdown = allDropdowns.find(o => o.key === key);
    if (dropdown){
      const updateDropdown = dropdown.value.map(o => {
        if (o.value  === value){
          return {...o, isSelected: state};
        }
        return o;
      })
      const updatedAllDropdowns = allDropdowns.map(o => {
        if (o.key === key){
          return {...o, value: updateDropdown};
        }
        return o;
      })
      setAllDropdowns(updatedAllDropdowns);
    }
  }

  useEffect(() => {
    init();
  }, [])

  if (isFetching) return <SpinnerLoading />

  return (
    <>
    <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} width='700px' titulo='Solicitud de descuento en la pensión escolar' isSubmitting={isSubmitting}>
      <div className='col-md-12'><InputSelect label='Hijos Matriculados' name={KEYS_SUBMIT.IdsHijos} options={handleFind(allDropdowns, KEYS_SUBMIT.IdsHijos)?.filter(o => !o.isSelected)} value={getValueOnSelect()} onChange={handleChangeSelect} sectionName={SECTION_NAME} /></div>
      {!formValues[KEYS.hijosSeleccionados]?.length ?
      <div className='col-sm-12 mb-4'>
        <span className='required'>* Debe seleccionar un hijo</span>
      </div> :
      <div className='table-scroll' style={{minHeight: "50px"}}>
        <table className='table table-hover'>
          <thead className='listar-thead'>
            <tr>
              <th className='text-center'>Nombre</th>
              <th className='text-center'>DNI</th>
              <th className='text-center'>Colegio Matriculado</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {formValues[KEYS.hijosSeleccionados]?.map((hijo, index) => 
              (<tr key={`${index}-${hijo.NumeroDocumento}`}>
                <td>{hijo.NombresCompletos}</td>
                <td className='text-center'>{hijo.NumeroDocumento}</td>
                <td>{hijo.Colegio}</td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <FontAwesomeIcon 
                      icon='trash'
                      size='lg'
                      className='blue_innova_text'
                      onClick={() => handleDeleteHijo(hijo.NumeroDocumento)}
                    />
                  </div>
                </td>
              </tr>))
            }
          </tbody>
        </table>
      </div>}
      <div className="form-row mx-3 pt-3" style={{borderTop: "1px solid #dee2e6"}}>
        <ul className='d-flex flex-column'>
          <li className=''>Mira el instructivo <a className="ficha-residencia-ver-info-icon-mensaje" target="_blank" rel="noopener noreferrer" href={formValues[KEYS.LineamientosFile] ?? ""}>Aquí</a></li>
          <li className=''>Mira aquí la política <a className="ficha-residencia-ver-info-icon-mensaje" target="_blank" rel="noopener noreferrer" href={formValues[KEYS.politicas]?.at(0)?.Path ?? ""}>Aquí</a></li>
        </ul>
      </div>
    </ModalForm>
    </>
  )
}

export default SolicitudDescuentoPension

const ModalMensaje = ({toggleModal, manageSearch, modalMensaje, modalImage, styleFicha = {}, hasBackButton = true}) => {

  const closeAll = () => {
    toggleModal();
    if (manageSearch) manageSearch();
  }

  return (
    <div className="modal-response-ficha-background">
      <div className="modal-response-ficha-content" style={{ width: "700px" }}>
        {modalImage &&
            <img src={modalImage} />
        }
        <p className="modal-response-ficha-content-message px-5 text-center" style={styleFicha}>
          {modalMensaje}
        </p>
        {hasBackButton &&
          <button className="modal-response-ficha-content-button" onClick={closeAll}> Volver </button>
        }
      </div>
    </div>
  )
}