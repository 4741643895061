import React, { useState } from 'react'
import ModalForm from '../../../../../components/modalComponents/ModalForm';
import InputText from '../../../../../components/modalComponents/InputText';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';
import InputMultiCheckboxAndRadioCard from '../../../components/InputMultiCheckboxAndRadioCard';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { crearPlantillaCuponCuponeraTiempoLibreAPI } from '../../../../../consumers/backendApisUrls';

const CrearCupon = ({ toggleModal, refreshPage }) => {

  const SECTION_NAME = "CrearNuevoCupon";
  const VALID_TEXTENSION_IMAGE = ["jpg", "png"];
  const KEYS = { Nombre: "Nombre", Requerimientos: "Requerimientos", ImagenCupon: "ImagenCuponTiempoLibreFileViewModel" };

  const [formValues, setFormValues] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearPlantillaCuponCuponeraTiempoLibreAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { CantidadDiasYFecha, ...formValuesRaw } = formValues;
    let requerimientosArray = formValuesRaw[KEYS.Requerimientos]?.length > 0 
      ? [...formValuesRaw[KEYS.Requerimientos]]
      : [];

    if (CantidadDiasYFecha){
      requerimientosArray.push(CantidadDiasYFecha);
    }
    return {
      ...formValuesRaw,
      [KEYS.Requerimientos]: requerimientosArray.length
        ? requerimientosArray.join(',') 
        : "",
    }
  }

  const successSubmit = async () => {
    toggleModal();
    await refreshPage();
  }
  
  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} width="700px" titulo="Nuevo Cupón" saveButtonDisabled={isUploading} isSubmitting={isSubmitting}>
      <InputText label='Nombre' name={KEYS.Nombre} value={formValues[KEYS.Nombre]} onChange={handleChange} sectionName={SECTION_NAME} />
      <InputUploadFile label="Imagen" name={KEYS.ImagenCupon} uploadFile={formValues[KEYS.ImagenCupon]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.ImagenCupon]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={VALID_TEXTENSION_IMAGE}/>
      <InputMultiCheckboxAndRadioCard label="Requerimientos" name={KEYS.Requerimientos} radioValue={formValues.CantidadDiasYFecha} values={formValues[KEYS.Requerimientos]} onChange={handleChange} sectionName={SECTION_NAME}/>
    </ModalForm>
  )
}

export default CrearCupon;