import React, { useState } from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import popUpContent from '../../../image/hostigamiento.png';
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { aceptarOcultarModalHostigamientoAPI } from "../../../consumers/backendApisUrls";

const ModalHostigamiento = ({ showModal }) => {
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);
    const [executePostRequest] = usePostRequest();

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            const response = await executePostRequest(aceptarOcultarModalHostigamientoAPI(), {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const closeModal = async function () {
        await submit();
        showModal();
    };

    return (
        <>
            <GenericModal showModal={closeModal} width={"500px"} titulo={"Prevención de Hostigamiento Sexual Laboral 2023"}>
                <div className="modal-body" style={{ 'padding': '0' }}>
                    <a href="https://innovaschools.mrooms.net/login/index.php" target="_blank">
                        <img src={popUpContent} alt='' width="100%" style={{ 'margin': '-5px -5px -5px -5px' }} />
                    </a>
                </div>
                <div className="modal-footer" style={{ 'display': 'flex', 'justify-content': 'space-between' }}>
                    <div className="message font-gothan-light" style={{ 'align-self': 'flex-start' }}>
                    </div>
                    <div className="button" style={{ 'align-self': 'flex-end' }}>
                        <input type="checkbox" checked={checkNoVolverAMostrarModal} onClick={clickOnCheck} /> No volver a mostrar
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalHostigamiento;