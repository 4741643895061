import React, {useEffect, useContext, useState} from 'react';
import GenericModal from "../../components/modal/GenericModal";
import {uploadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {AuthContext} from "../../hooks/context/authContext";
import {useForm} from "../../hooks/useForm/UseForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {clearInputValue, extensionYtamañoDeArchivoEsValido, obtenerArchivoDeEvent, responseUploadFileSuccess} from "../../helper/utils";
import {toast} from "react-toastify";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS} from "../../constants/hardCodedConstants";
import {showToastErrorMaxFileSize} from "../../components/alerts/AlertErrorMaxFileSize";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getTarjetaByIdAPI, guardarTarjetaPaginaPrincipalAPI} from "../../consumers/backendApisUrls";
import { getCurrentDate } from '../../helper/utils';

const EditarCardModal = ({showEditCardModal, idTarjeta, initPaginaPrincipal}) => {
    const {state} = useContext(AuthContext);

    const [jsonFormValues, setJsonFormValues] = useState({});
    const [handleChangeJsonFormValues] = useForm(jsonFormValues, setJsonFormValues);

    const [fileName, setFileName] = useState("");
    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        console.debug("init");
        console.debug("idTarjeta: ", idTarjeta);

        let response = await executeGetRequest(getTarjetaByIdAPI(idTarjeta));
        if (responseCode200(response)) {
            setJsonFormValues(response.data.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const submitModalEnviarReporteViaGmailProxy = async () => {
        console.debug("submitModalEnviarReporteViaGmailProxy");
        let payload = {
            "IdTarjetaTablero": idTarjeta,
            "Titulo": jsonFormValues["Titulo"],
            "Descripcion": jsonFormValues["Descripcion"],
            "UrlEnlace": jsonFormValues["UrlEnlace"],
            "TextoEnlace": jsonFormValues["TextoEnlace"],
            "FechaFin": jsonFormValues.EsNuevo? jsonFormValues.FechaFin: null,
            "FileViewModel": s3UploadFileResponse
        };
        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarTarjetaPaginaPrincipalAPI(), payload);
        if (responseCode200(response)) {
            toast.success("Card Actualizado");
            showEditCardModal();
            await initPaginaPrincipal();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

    };

    const resetUploadFile = () => {
        setFileName("");
        setS3UploadFileResponse({});
        clearInputValue("editCardModalInputFile");
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponse(s3Response);
            setFileName(file.name);
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };


    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivo(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);


                let temp = jsonFormValues;
                let newUrlImagen = URL.createObjectURL(file);
                temp = {...temp, "UrlImagen": newUrlImagen};
                setJsonFormValues(temp);
                console.debug("response ", response);
                manageUploadFileResponse(response, file);
            } else {
                resetUploadFile();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <GenericModal showModal={showEditCardModal} width={"1100px"} titulo={"Actualizar contenido"}>
                <div className="modal-edit-card-content-modal">
                    <div className="modal-edit-card-form">
                        <form className="blue-standard-color enviar-reporte-via-gmail-form">
                            <div>
                                <label className="modal-edit-card-form-label font-gothan-medium">Título</label>
                                <input type="text" value={jsonFormValues && jsonFormValues.Titulo} name="Titulo" onChange={handleChangeJsonFormValues} placeholder="Título (Máximo 25 caracteres)" className="form-control edit-card-form-text-input" maxlength="25"/>
                            </div>
                            <div>
                                <label className="modal-edit-card-form-label font-gothan-medium">Descripción</label>
                                <input type="text" value={jsonFormValues && jsonFormValues.Descripcion} name="Descripcion" onChange={handleChangeJsonFormValues} placeholder="Breve descripción (máximo 50 caractares)" className="form-control edit-card-form-text-input" maxlength="50"/>
                            </div>
                            <div>
                                <label className="modal-edit-card-form-label font-gothan-medium">Texto del enlace</label>
                                <input type="text" value={jsonFormValues && jsonFormValues.TextoEnlace} name="TextoEnlace" onChange={handleChangeJsonFormValues} placeholder="Texto a hipervincular o call to action" className="form-control edit-card-form-text-input"/>
                            </div>
                            <div>
                                <label className="modal-edit-card-form-label font-gothan-medium">Url del enlace</label>
                                <input type="text" value={jsonFormValues && jsonFormValues.UrlEnlace} name="UrlEnlace" onChange={handleChangeJsonFormValues} placeholder="Enlace" className="form-control edit-card-form-text-input"/>
                            </div>
                            <div className="form-check mb-3">
                                <input 
                                    type="checkbox" 
                                    id="EsNuevo" 
                                    value={jsonFormValues && jsonFormValues.EsNuevo} 
                                    name="EsNuevo" 
                                    onChange={handleChangeJsonFormValues} 
                                    className="form-check-input"
                                    checked={jsonFormValues && jsonFormValues.EsNuevo}
                                />
                                <label htmlFor="EsNuevo" className="form-check-label">Es nuevo</label>
                            </div>
                            {jsonFormValues && jsonFormValues.EsNuevo? (
                                <div>
                                    <label className="modal-edit-card-form-label font-gothan-medium">Fecha fin de la etiqueta</label>
                                    <input type="date" value={jsonFormValues?.FechaFin && jsonFormValues?.FechaFin.split("T")[0]} name="FechaFin" min={getCurrentDate()}
                                        onChange={handleChangeJsonFormValues} className="form-control edit-card-form-text-input"
                                        format/>
                                </div>
                            ): null}
                            <div className="modal-edit-card-preview-file-input-group mt-4">
                                <label className="custom-file-upload">
                                    <input type="file" id="editCardModalInputFile" name="UrlImagen" onChange={handleUploadFile} accept=".pdf,.jpg,.png"></input>
                                    <p className='btn btn-primary generic-button-ficha'>Adjuntar imagen que aparecerá como marca de agua</p>
                                    <p className="modal-edit-card-input-fileName-status"><p>{fileName}</p> {subiendoArchivo && (<>&nbsp;&nbsp; El archivo se está subiendo...&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner"/></>)}</p>
                                    <p className="modal-edit-card-input-fileName-status">{({subiendoArchivo} == false) && <p>{fileName}</p>} </p>
                                </label>
                            </div>

                        </form>
                    </div>
                    <div className="modal-edit-card-preview-container">
                        <div className='card second modal-edit-card-preview justify-content-between align-items-center'>
                            <div className='secondary-card-text'>
                                <h6 className='card-title text-center'>
                                    {jsonFormValues && jsonFormValues.Titulo}
                                </h6>
                                <h3 className='card-text text-center'>
                                    {jsonFormValues && jsonFormValues.Descripcion}
                                </h3>
                            </div>
                            <a className='card-link' href={jsonFormValues && jsonFormValues.UrlEnlace} target="_blank">
                                {jsonFormValues && jsonFormValues.TextoEnlace}
                            </a>
                            <div className='secondary-card-image'>
                                <img src={jsonFormValues && jsonFormValues.UrlImagen}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-edit-card-footer">
                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={submitModalEnviarReporteViaGmailProxy} disabled={subiendoArchivo}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default EditarCardModal;