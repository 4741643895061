import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';

const HamburgerIcon = (props) => {
    const {size} = props;
    return (
        <Icon color={'#fff'} size={size} viewBox={'0 0 20 15'}>
            <g transform='translate(-170 -18)'>
                <rect width='20' height='2.5' rx='1' transform='translate(170 18)'/>
                <rect width='20' height='2.5' rx='1' transform='translate(170 24.25)'/>
                <rect width='20' height='2.5' rx='1' transform='translate(170 30.5)'/>
            </g>
        </Icon>
    );
};

HamburgerIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

export default HamburgerIcon;
