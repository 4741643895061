import React from "react"
import styled from "styled-components";

export const Div = ({ children, col = 6 }) => (
  <div className={`col-md-${col}`}>
    {children}
  </div>
)

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 40%;
    padding: 40px 40px 1.2rem;
    border: 1px solid white;
    border-radius: 15px;
    margin: 40px;
    background: white;
    box-shadow: 5px 5px 20px 0 rgba(0,0,0,0.2);
  }
`;

export const getDateOnFormat = (date) => {
  if (!date) return;
  const dateRaw = date.split("/");
  return `${dateRaw[2]}-${dateRaw[1]}-${dateRaw[0]}`;
}

export const MESES_GESTACION = [
  {value: 1, label: "1 Mes"},
  {value: 2, label: "2 Meses"},
  {value: 3, label: "3 Meses"},
  {value: 4, label: "4 Meses"},
  {value: 5, label: "5 Meses"},
  {value: 6, label: "6 Meses"},
  {value: 7, label: "7 Meses"},
  {value: 8, label: "8 Meses"},
  {value: 9, label: "9 Meses"},
]