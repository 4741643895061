import React, {useEffect} from "react";
import Select from "react-select";
import {jsonNoEstaVacio} from "../../helper/utils";

const SelectInput = ({options, jsonSelectedValue, setJsonSelectedValue, isDisabled, placeholder, preFunction, initialValue, resetSelectedValue = true}) => {

    useEffect(() => {
        let unmounted = false;
        if (!unmounted && resetSelectedValue === true) {
            setJsonSelectedValue({})
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const handleSelectInputChange = (newValue) => {
        if(preFunction)
            preFunction(newValue);
        setJsonSelectedValue(newValue);
    };

    if (jsonNoEstaVacio(jsonSelectedValue)) {
        return (
            <>
                <div>
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onChange={handleSelectInputChange}
                        isDisabled={isDisabled}
                        value={jsonSelectedValue || initialValue}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <div>
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onChange={handleSelectInputChange}
                        isDisabled={isDisabled}
                    />
                </div>
            </>
        )
    }
};

export default SelectInput;