import { toast } from "react-toastify";
import { handleFind, jsonEstaVacio } from "../../../../../../helper/utils";

const validateFormData = (keys = [], formValues = {}, keys_regex = {}, allDropdowns = []) => {

  if (!keys.length && jsonEstaVacio(formValues)){
    return toast.warn("Keys y data del formulario son obligatorios");
  }

  let emptyInputs = [];
  for (let key of keys){
    if(!formValues.hasOwnProperty(key) || formValues[key] === null || formValues[key] === undefined || jsonEstaVacio(formValues[key])) {
      emptyInputs.push({ key });
      continue;
    }

    const hasRegexValidation = validateRegexForField(key, keys_regex, formValues, allDropdowns);
    if (hasRegexValidation){
      emptyInputs.push(hasRegexValidation);
    }
  }
  return emptyInputs;
}

const validateRegexForField = (key, keys_regex, formValues, allDropdowns) => {
  if (!keys_regex[key]) return;

  const dropdownId = keys_regex[key].regex;
  const dataTarget = formValues[dropdownId];
  const allRegex = handleFind(allDropdowns, dropdownId);
  const regexTarget = allRegex?.find(o => o.value === dataTarget);

  if (regexTarget){
    const regex = new RegExp(regexTarget.regex);
    if (!regex.test(formValues[key])){
      return { key, message: regexTarget.message };
    }
  }
}

export default validateFormData;