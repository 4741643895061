import React, {useContext, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import EnviarReporteViaGmailModal from "./EnviarReporteViaGmailModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {deleteFileFromS3API, downloadReporteClimaLaboralAPI, enviarReporteClimaLaboralAPI} from "../../consumers/backendApisUrls";
import {downloadFile} from "../../consumers/backendConsumer";


const ReporteDeEncuestaDeClimaLaboral = () => {
    const {state} = useContext(AuthContext);
    const [hideEnviarReportesViaGmailModal, setHideEnviarReportesViaGmailModal] = useState(true);
    const [executePostRequest] = usePostRequest();

    const descargarReporte = async () => {
        let responseReporteGenerado = await executePostRequest(downloadReporteClimaLaboralAPI(), {});
        if (responseCode200(responseReporteGenerado)) {
            let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
            if (responseCode200(descargarReporteResponse)) {
                await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
            } else {
                mostrarMensajeDeErrorConToast(descargarReporteResponse);
            }
        } else {
            mostrarMensajeDeErrorConToast(responseReporteGenerado);
        }
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    const showEnviarReportesViaGmailModal = () => {
        let currentState = hideEnviarReportesViaGmailModal;
        setHideEnviarReportesViaGmailModal(!currentState);
    };

    const submitModalEnviarReporteViaGmail = async (emails) => {
        console.debug("emails: ", emails);

        let payload = {
            "Correos": emails
        };

        console.debug("payload ", payload);

        let response = await executePostRequest(enviarReporteClimaLaboralAPI(), payload);

        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showEnviarReportesViaGmailModal();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <div id="modal-contactanos" className="modal-contactanos modal-response-ficha-background " hidden={hideEnviarReportesViaGmailModal}>
                {hideEnviarReportesViaGmailModal === false &&
                <EnviarReporteViaGmailModal showEnviarReportesViaGmailModal={showEnviarReportesViaGmailModal} submitModalEnviarReporteViaGmail={submitModalEnviarReporteViaGmail}/>
                }
            </div>
            <div className='card-body'>
                <div id='seccionReportesDeEncuestaClimaLaboral'>
                    <p className="reporte-encuesta-clima-laboral-text">Este reporte contiene los siguientes datos: nombres, apellido paterno, apellido materno, género, fecha de nacimiento, edad, antigüedad en Innova, cargo y área o sede. Este reporte solo considera colaboradores activos que puedan tomar la encuesta.</p>
                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={showEnviarReportesViaGmailModal}>Enviar via Gmail</button>
                        <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={descargarReporte}>Descargar Reporte</button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ReporteDeEncuestaDeClimaLaboral;