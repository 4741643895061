import React, {useContext, useEffect, useState} from "react";
import GenericModal from "../../components/modal/GenericModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AuthContext} from "../../hooks/context/authContext";
import {v4 as uuidv4} from 'uuid';
import {getElementByQuerySelector} from "../../helper/utils";
import {GENERIC_CORREO_ELECTRONICO} from "../../validations/Regex";
import {cleanFormValidationMessage, updateFormValidationMessage} from "../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const EnviarReporteViaGmailModal = ({showEnviarReportesViaGmailModal, submitModalEnviarReporteViaGmail}) => {
    const {state} = useContext(AuthContext);
    const [filas, setFilas] = useState([]);
    const currentUserFilaId = "currentUserFilaId";
    const inputMailValidationMessagePrefixId = "reportes-modal-validation-message-correo-";

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (filas.length > 0) {
            disableFirstRow();
        }

    }, [filas]);

    const disableFirstRow = () => {
        let element = getElementByQuerySelector(`.enviar-reporte-via-gmail-form div:first-child input[type="email"]`);
        element.disabled = true;
    };

    const init = () => {
        let firstFila = {
            "id": currentUserFilaId,
            "email": state.user.email
        };
        let newFilas = [...filas, firstFila];
        setFilas(newFilas);
    };

    const submitModalEnviarReporteViaGmailProxy = async (event) => {
        event.preventDefault();
        console.debug("submitModalEnviarReporteViaGmailProxy");

        if (camposSonValidos()) {
            let correosAgregados = filas.map(fila => fila["email"]);
            submitModalEnviarReporteViaGmail(correosAgregados);
        } else {
            toast.error("Corregir correos no validos.");
        }
    };

    const camposSonValidos = () => {
        let camposValidoResponse = [];
        for (let fila of filas) {
            let campoValido = validateCorreoValido(fila);
            console.debug({fila, campoValido});
            camposValidoResponse = [...camposValidoResponse, campoValido]
        }

        let cantidadDeCamposValidos = (camposValidoResponse.filter((valido) => valido === true)).length;

        return (cantidadDeCamposValidos === filas.length) ? true : false;
    };

    const validateCorreoValido = (fila) => {
        let campoValido = true;
        let elementHtmlId = `${inputMailValidationMessagePrefixId}${fila["id"]}`;
        if (fila["email"].match(GENERIC_CORREO_ELECTRONICO)) {
            cleanFormValidationMessage(elementHtmlId);
        } else {
            updateFormValidationMessage(elementHtmlId, "Correo no válido");
            campoValido = false;
        }
        return campoValido;
    };

    const añadirDestinatarioToList = (e) => {
        e.preventDefault();
        console.debug("añadirDestinatarioToList");
        let emptyFila = {
            "id": uuidv4(),
            "email": ""
        };

        let newFilas = [...filas, emptyFila];
        setFilas(newFilas);
    };

    const handleChangeInput = (id, event) => {
        console.debug("handleChangeInput", id);
        const newInputFields = filas.map(fila => {
            if (id === fila.id) {
                fila[event.target.name] = event.target.value;
            }
            return fila;
        });
        setFilas(newInputFields);
    };


    return (
        <>
            <GenericModal showModal={showEnviarReportesViaGmailModal} width={"750px"} titulo={"Enviar Reporte via Gmail"}>
                <form className="blue-standard-color enviar-reporte-via-gmail-form">
                    {filas && filas.map((fila) => (
                        <div className="enviarReporteViaGmailModal-form-group-input">
                            <FontAwesomeIcon icon='user-check' transform="left-15" className="hand-pointer"/>
                            <input type="email" className="form-control enviarReporteViaGmailModal-form-input" defaultValue={fila.email} name="email" onChange={event => handleChangeInput(fila.id, event)}/>
                            <p className="ficha-form-input-validation-message-reportes-modal" id={inputMailValidationMessagePrefixId + fila["id"]}></p>
                        </div>
                    ))}

                    <div className="enviar-reporte-via-gmail-añadir-destinatario-container">
                        <p className="hand-pointer enviar-reporte-via-gmail-añadir-destinatario-p" onClick={añadirDestinatarioToList}>
                            + Añadir destinatario
                        </p>
                    </div>
                </form>
                <div className="modal-footer" style={{'marginTop': '15px'}}>
                    <div>
                        <button className="btn btn-primary generic-button-ficha" onClick={submitModalEnviarReporteViaGmailProxy}>Enviar vía Gmail</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )

};

export default EnviarReporteViaGmailModal;