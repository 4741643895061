import React, {useContext, useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import {downloadCartaCompromisoVacunacionAPI, getCargaCartaCompromisoVacunacionAPI, nuevosIngresosConfirmarPlantillaCartaCompromisoVacunacionIsOkAPI, rechazarCartaCompromisoVacunacionAPI} from '../../../consumers/backendApisUrls';
import {downloadFile} from '../../../consumers/backendConsumer';
import {mostrarMensajeDeErrorConToast, responseCode200} from '../../../consumers/httpRequiestsUtils';
import {AuthContext} from '../../../hooks/context/authContext';
import {useForm} from '../../../hooks/useForm/UseForm';
import {useGetRequest} from '../../../hooks/useGetRequest/useGetRequest';
import {useSubmitForm} from '../../../hooks/useSubmitForm/useSubmitForm';

const ValidarPlantillaCartaCompromisoVacunacionModal = ({showModal, IdEmpleadoFlujoActividad, manageSearch}) => {
    const [detalleActividad, setDetalleActividad] = useState({});
    const [executeGetRequest] = useGetRequest();
    const {state} = useContext(AuthContext);
    const sectionName = "ValidarPlantillaCartaCompromisoVacunacionModal";
    const [formValues, setFormValues] = useState({Mensaje: "Ok"});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        if (IdEmpleadoFlujoActividad) {
            const response = await executeGetRequest(getCargaCartaCompromisoVacunacionAPI(IdEmpleadoFlujoActividad));
            if (responseCode200(response)) {
                setDetalleActividad(response.data);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const downloadCartaCompromisoVacunacion = async (event) => {
        event.preventDefault();
        const response = await executeGetRequest(downloadCartaCompromisoVacunacionAPI(IdEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            downloadFile(state.token, response.data.FilePath, response.data.OriginalFileName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const submit = async (event) => {
        event.preventDefault();
        let payload = {
            IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
            Mensaje: formValues?.Mensaje
        };
        let api = nuevosIngresosConfirmarPlantillaCartaCompromisoVacunacionIsOkAPI();
        let keys = ["Mensaje"];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const successSubmitCallback = function () {
        manageSearch();
        showModal();
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"700px"} titulo={"Validar plantilla Carta Compromiso Vacunación"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Colaborador:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreEmpleado}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreSede}</label>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Actividad:</label>
                            <label className="col-sm-6 col-form-label modal-label black-color-important">{detalleActividad && detalleActividad.NombreActividad}</label>
                        </div>
                        <div className="form-group row mt-5">
                            <div className="col-sm-8">
                                <button
                                    type='button'
                                    className='btn btn-primary ficha-form-button-actualizar mr-4'
                                    onClick={(e) => downloadCartaCompromisoVacunacion(e)}>
                                    Descargar documento
                                </button>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-12 col-form-label text-justify">
                                Mensaje
                            </label>
                            <div className="col-sm-12 black-color">
                                <textarea className="form-control" rows="3" name="Mensaje" onChange={handleChange} value={formValues?.Mensaje}></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Mensaje-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row d-flex justify-content-end">
                            <div className="col-sm-2 button">
                                <button className="btn btn-primary generic-button-ficha" onClick={(event) => submit(event)}>Enviar</button>
                            </div>
                        </div>

                    </form>
                </div>
            </GenericModal>
        </>
    );

}

export default ValidarPlantillaCartaCompromisoVacunacionModal;