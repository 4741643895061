import {ANY, CHECKBOX_TRUE, CORREO_ELECTRONICO_GENERICO_Y_NOINNOVA, PALABRA_GUION_PALABRA, PALABRAS_INICA_UPPER_CASE_ESPACIOS_OPCIONALES, SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS, SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS_O_PUNTO, SOLO_NUEVE_NUMEROS, SOLO_NUMEROS, SOLO_SIETE_O_MENOS_NUMEROS, SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_CON_ESPACIOS_O_PUNTO_O_GUION} from "./Regex";

export const INGRESAR_SOLO_LETRAS = "Ingresar solo letras sin espacios";
export const INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_SIN_ESPACIOS = "Ingresar solo letras sin espacios, primera letra mayúscula";
export const INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_PERMITE_ESPACIOS = "Ingresar solo letras, primera letra mayúscula";
export const INGRESAR_SOLO_LETRAS_O_PALABRA_GUION_PALABRA = "Ingresar solo letras o palabra guión palabra";
export const INGRESAR_SOLO_LETRAS_O_PALABRA_GUION_PALABRA_O_PUNTO = "Ingresar solo letras o palabra guión palabra o punto";
export const INGRESAR_SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_CON_ESPACIOS_O_PUNTO_O_GUION = "Ingresar solo palabras separadas por guión, punto o espacio y que inicien con mayúscula.";
export const INGRESAR_SOLO_LETRAS_O_ESPACIOS = "Ingresar solo letras o espacios";
export const INGRESAR_SOLO_NUMEROS = "Ingresar solo números";
export const SELECCIONAR_UNA_OPCION = "Seleccionar una opción";
export const INGRESAR_UN_CORREO_VALIDO = "Ingresar un correo válido o que no sea de los siguientes dominios: @colegiosperuanos.edu.pe, @innovaschools.edu.pe  o @innovaencasa.edu.pe";
export const INGRESAR_SOLO_NUEVE_NUMEROS = "Ingresar solo nueve números";
export const INGRESAR_SOLO_SIETE_O_MENOS_NUMEROS = "Ingresar solo siete o menos números";
export const NONE = ":(";
export const DECLARACION_JURADA = "Debe aceptar la declaración Jurada";

export const VALIDACIONES = {
    Nombres: {regexs: [PALABRAS_INICA_UPPER_CASE_ESPACIOS_OPCIONALES], obligatorio: true, message: INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_PERMITE_ESPACIOS},
    NombreCorto: {regexs: [SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS], obligatorio: true, message: INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_SIN_ESPACIOS},
    ApellidoPaterno: {regexs: [PALABRAS_INICA_UPPER_CASE_ESPACIOS_OPCIONALES], obligatorio: false, message: INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_PERMITE_ESPACIOS},
    ApellidoMaterno: {regexs: [PALABRAS_INICA_UPPER_CASE_ESPACIOS_OPCIONALES], obligatorio: false, message: INGRESAR_SOLO_LETRAS_PRIMERA_LETRA_MAYUSCULA_PERMITE_ESPACIOS},
    ApellidoCasado: {regexs: [SOLO_LETRAS_TILDES_APOSTROFE_INICIA_CON_UPPERCASE_SIN_ESPACIOS, PALABRA_GUION_PALABRA], obligatorio: false, message: INGRESAR_SOLO_LETRAS_O_PALABRA_GUION_PALABRA},
    IdTipoDocumento: {regexs: [SOLO_NUMEROS], obligatorio: true, message: SELECCIONAR_UNA_OPCION},
    NumeroDocumento: {regexs: [SOLO_NUMEROS], obligatorio: true, message: INGRESAR_SOLO_NUMEROS},
    IdGenero: {regexs: [SOLO_NUMEROS], obligatorio: true, message: SELECCIONAR_UNA_OPCION},
    IdEstadoCivil: {regexs: [SOLO_NUMEROS], obligatorio: true, message: SELECCIONAR_UNA_OPCION},
    Email: {regexs: [CORREO_ELECTRONICO_GENERICO_Y_NOINNOVA], obligatorio: true, message: INGRESAR_UN_CORREO_VALIDO},
    Celular: {regexs: [SOLO_NUEVE_NUMEROS], obligatorio: true, message: INGRESAR_SOLO_NUEVE_NUMEROS},
    MostrarCelular: {regexs: [ANY], obligatorio: true, message: NONE},
    Telefono: {regexs: [SOLO_SIETE_O_MENOS_NUMEROS], obligatorio: false, message: INGRESAR_SOLO_SIETE_O_MENOS_NUMEROS},
    AceptaDeclaracionJurada: {regexs: [CHECKBOX_TRUE], obligatorio: true, message: DECLARACION_JURADA}
};

export const updateValidationMessage = (fieldId, message) => {
    let element = document.getElementById(`ficha-edit-datosPersonales-${fieldId}-validation-message`);
    element.innerHTML = message;
};

const apellidoInvaliddo = (fieldId) => {
    let campoInvalido = false;

    let ApellidoPaternoElementId = "ApellidoPaterno";
    let ApellidoMaternoElementId = "ApellidoMaterno";

    let ApellidoPaternoElement = obtenerElementoDeFichaDatosPersonalesPorId(ApellidoPaternoElementId);
    let ApellidoMaternoElement = obtenerElementoDeFichaDatosPersonalesPorId(ApellidoMaternoElementId);

    let apellidoPaternoValue = obtenerValorDeElemento(ApellidoPaternoElement);
    let apellidoMaternoValue = obtenerValorDeElemento(ApellidoMaternoElement);

    let posibleEmptyValues = ["", "."];

    let apellidoPaternoEsEmpty = posibleEmptyValues.includes(apellidoPaternoValue);
    let apellidoMaternoEsEmpty = posibleEmptyValues.includes(apellidoMaternoValue);

    if (apellidoPaternoEsEmpty && apellidoMaternoEsEmpty) {
        updateValidationMessage(ApellidoPaternoElementId, "Este campo es obligatorio cuando no se ingresa apellido materno");
        updateValidationMessage(ApellidoMaternoElementId, "Este campo es obligatorio cuando no se ingresa apellido paterno");
        campoInvalido = true;
    } else if (apellidoPaternoEsEmpty && !apellidoMaternoEsEmpty) {
        campoInvalido = elCampoEsInvalido(ApellidoMaternoElementId);
        updateValidationMessage(ApellidoPaternoElementId, "");
    } else if (!apellidoPaternoEsEmpty && apellidoMaternoEsEmpty) {
        campoInvalido = elCampoEsInvalido(ApellidoPaternoElementId);
        updateValidationMessage(ApellidoMaternoElementId, "");
    } else {
        campoInvalido = elCampoEsInvalido(fieldId);
    }

    return campoInvalido;
};

export const validarCampo = (fieldId, token, idEmpleado, dispatch, tiposDeDocumento) => {
    switch (fieldId) {
        case 'ApellidoPaterno' : {
            return apellidoInvaliddo(fieldId);
        }
        case 'ApellidoMaterno': {
            return apellidoInvaliddo(fieldId);
        }

        case 'NumeroDocumento':
            let campoInvalido = false;
            let idTipoDocumento = obtenerValorDeElementoDeFichaDatosPersonalesPorId('IdTipoDocumento');
            let numeroDocumento = obtenerValorDeElementoDeFichaDatosPersonalesPorId('NumeroDocumento');

            const tipoDocumento = tiposDeDocumento.filter(t => t.Value == idTipoDocumento)[0];
            let regex = tipoDocumento["Regex"];
            let validationMessage = tipoDocumento["ValidationMessage"];

            if (numeroDocumento.match(regex)) {
                updateValidationMessage(fieldId, "");
            } else {
                campoInvalido = true;
                updateValidationMessage(fieldId, validationMessage);
            }

            return campoInvalido;

        default: {
            return elCampoEsInvalido(fieldId);
        }
    }
};

export const obtenerElementoDeFichaDatosPersonalesPorId = (id) => {
    return document.getElementById(`ficha-edit-datosPersonales-${id}`);
};

const obtenerValorDeElemento = (element) => {
    return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
};

export function obtenerValorDeElementoDeFichaDatosPersonalesPorId(id) {
    let element = obtenerElementoDeFichaDatosPersonalesPorId(id);
    return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
}

const elCampoEsInvalido = (fieldId) => {
    let inputValue = obtenerValorDeElementoDeFichaDatosPersonalesPorId(fieldId);
    let campoInvalido = false;

    updateValidationMessage(fieldId, "");

    let validator = VALIDACIONES[fieldId];

    let esValidoConAlgunRegex = false;

    if (validator.regexs[0] != 'any') {
        if (inputValue === "") {
            if (validator.obligatorio) {
                updateValidationMessage(fieldId, "Este campo es obligatorio");
                campoInvalido = true;
            }
        } else {
            inputValue = (fieldId ==="Email") ? inputValue.toLowerCase() : inputValue;
            validator.regexs.forEach((regex) => {
                if (!(regex.test(inputValue))) {
                    campoInvalido = true;
                } else {
                    esValidoConAlgunRegex = true;
                }
            });

            campoInvalido = (esValidoConAlgunRegex) ? false : campoInvalido;
            if (campoInvalido) {
                updateValidationMessage(fieldId, validator.message);
            }
        }
    }
    return campoInvalido;
};