import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText'
import InputDate from '../../../../components/modalComponents/InputDate'
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput'
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm'
import { crearPresupuestoAPI } from '../../../../consumers/backendApisUrls'
import { useForm } from '../../../../hooks/useForm/UseForm'

const CrearPresupuesto = ({ toggleModal, refreshPage }) => {

  const SECTION_NAME = "crearPresupuesto";
  const KEYS = ["Nombre", "FechaInicio", "FechaFin"];

  const [formValues, setFormValues] = useState({});

  const [handleChange] = useForm(formValues, setFormValues);
  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = crearPresupuestoAPI();
    await submitForm(KEYS, api, formValues, SECTION_NAME, successSubmitCallback);
  }

  const successSubmitCallback = () => {
    toggleModal();
    refreshPage();
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Crear Presupuesto" width="700px">
      <InputText label='Nombre de presupuesto' {...commomProps('Nombre')}/>
      <InputDate label='Fecha Inicio' name='FechaInicio' value={formValues.FechaInicio?.split("T")[0]} onChange={handleChange} sectionName={SECTION_NAME}/>
      <InputDate label='Fecha Fin' name='FechaFin' value={formValues.FechaFin?.split("T")[0]} onChange={handleChange} sectionName={SECTION_NAME}/>
    </ModalForm>
  )
}

export default CrearPresupuesto