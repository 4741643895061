import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const TablaWebBody = ({ filas = [], toggleRegistroVacunaModal, eliminar, toggleEditarVacunaModal}) => {
  return (
    <tbody className="listar-tbody">
      {filas.map((fila, i) => (
        <tr key={fila?.IdVacunacion}>
          <td>{fila?.FechaRegistro}</td>
          <td>{fila?.NombreCompleto}</td>
          <td>{fila?.EstadoUsuario}</td>
          <td>{fila?.Cargo}</td>
          <td>{fila?.Division}</td>
          <td>{fila?.Gerencia}</td>
          <td>{fila?.Area}</td>
          <td>{fila?.Estado}</td>
          <td>{fila?.EstadoRefuerzo}</td>
          <td>
            <div className="d-flex justify-content-start">
              <div>
                <FontAwesomeIcon icon='id-card' className="hand-pointer ml-3" title="Ver detalle" onClick={() => toggleRegistroVacunaModal(fila?.IdEmpleado)} />
              </div>
              <div>
                <FontAwesomeIcon icon='times' className="hand-pointer ml-3" title="Eliminar" onClick={() => eliminar(fila.IdVacunacion)} />
              </div>
              {fila?.EditarVacuna === true &&
                                <div>
                                    <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer ml-3" title="Editar" onClick={() => {
                                        toggleEditarVacunaModal(fila?.IdEmpleado)
                                    }} />
                                </div>
                            }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TablaWebBody
