import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck, faPaste, faDownload, faClipboardList, faFileSignature, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { initialAllOptionPromises } from "../../../../helper/utils";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import { useGetOptionsSelect } from "../../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { usePropsSelect } from "../../../../hooks/useProps/usePropsSelect";
import GenericUploadFileV2 from "../../../../components/GenericUploadFile/GenericUploadFileV2";
import ActionGenericModal from "../../../../components/modal/ActionGenericModal";
import InputMultiSelect from "../../../../components/modalComponents/InputMultiSelect";
import GenericTooltip from "../../../../components/GenericTooltip/GenericTooltip";
import { cargarDocumentoEmpleadoAPI, validarDocumentoEmpleadoAPI, eliminarDocumentoEmpleadoAPI, descargarDocumentoEmpleadoFromPlantillaAPI, updateListaDocumentosEmpleadoNuevosIngresosAPI, getListaDocumentosEmpleadoNuevoIngresoGestionAPI, getListaDocumentosAPI, reemplazarDocumentoEmpleadoNuevosIngresosAPI, cambiarPasoObligatorioDocumentoEmpleadoAPI, getNuevosIngresosPasosObligatoriosOptionsByIdNuevosIngresosEmpleado, firmarDocumentoEmpleadoAPI, subirFirmaDocumentosIngresoAPI, eliminarFirmaDocumentosIngresoAPI } from "../../../../consumers/backendApisUrls";
import { FILE_FORMATS } from "../../../../constants/hardCodedConstants";
import InputUploadFile from "../../../../components/modalComponents/InputUploadFile";

const ListaDocumentos = ({ listDocuments, idNuevosIngresosEmpleado, manageSearchListaDocumentos, inputDisabled, isColaborador, signature, puedeGestionarFirma }) => {  
  const SECTION_NAME = "EditarListasDocumentos";
  const NO_OBLIGATORIO_STEP = 0;
  const KEYS_INIT = {ListaDocumentos: "IdsListasDocumentos", PasosObligatorios: "IdsPasosObligatorios", FirmaFileViewModel: "FirmaFileViewModel"};
  const MESSAGE_DNI = "En el caso de los DNI's Colaborador, Conyuge o HIjos, se requiere considerar el Anverso y Reverso del documento en una misma hoja.";

  const [modalEnable, setModalEnable] = useState({isEnable: false});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [firmaColaborador, setFirmaColaborador] = useState({
    idNuevosIngresosEmpleado: idNuevosIngresosEmpleado
  });
  const [fileSigned, setFileSigned] = useState(0);

  const [executePost] = useManagePostRequest();
  const [executeGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const initPromises = () => {
    return [
      getOptions(getListaDocumentosAPI()),
      getOptions(getNuevosIngresosPasosObligatoriosOptionsByIdNuevosIngresosEmpleado(idNuevosIngresosEmpleado)),
    ]
  }

  const init = async () => {
    try {
      if (!isColaborador) initialAllOptionPromises(initPromises, Object.values(KEYS_INIT), (res) => setAllDropdowns(res));
      manageSearch();
    } catch (error) {
      console.error(error);
    }
  }

  const manageSearch = async () => {
    const api = getListaDocumentosEmpleadoNuevoIngresoGestionAPI(idNuevosIngresosEmpleado);
    return await executeGet(api, (res) => setFormValues({[KEYS_INIT.ListaDocumentos]: res.data}));
  }

  const updateListaDocumentos = () => {
    const api = updateListaDocumentosEmpleadoNuevosIngresosAPI();
    let payload = {IdNuevosIngresosEmpleado: idNuevosIngresosEmpleado, ...formValues};
    executePost(api, payload, () => {manageSearch(); manageSearchListaDocumentos()});
  }

  const handleUploadFile = async (res, api, IdNuevosIngresosEmpleadoDocumento) => {
    let payload = {IdNuevosIngresosEmpleadoDocumento, File: {...res}};
    await executePost(api(), payload, () => manageSearchListaDocumentos());
  }

  const handleDownloadPlantilla = async (idPlantilla) => {
    const api = descargarDocumentoEmpleadoFromPlantillaAPI(idPlantilla);
    await executePost(api, {}, successDownloadNombrePlantilla)
  }

  const handleSignFile = async (idNuevosIngresosEmpleadoDocumento) => {
    if(fileSigned !== 0) return;
    setFileSigned(idNuevosIngresosEmpleadoDocumento);
    const api = firmarDocumentoEmpleadoAPI(idNuevosIngresosEmpleadoDocumento);
    await executePost(api, {}, () => manageSearchListaDocumentos());
    setFileSigned(0);
  }

  const successDownloadNombrePlantilla = (res) => {
    let { FilePath } = res?.data;
    window.open(FilePath)
  }

  const handleActionsObligatorio = async (api, IdNuevosIngresosEmpleadoDocumento) => {
    await executePost(api(IdNuevosIngresosEmpleadoDocumento), {}, () => manageSearchListaDocumentos());
  }

  const handlePasoObligatorio = async (IdNuevosIngresosEmpleadoDocumento, PasoObligatorio) => {
    const api = cambiarPasoObligatorioDocumentoEmpleadoAPI();
    const payload = {IdNuevosIngresosEmpleadoDocumento, PasoObligatorio};
    await executePost(api, payload, () => manageSearchListaDocumentos());
  }

  const chooseAPIForDocumentSiganture = (res) => {
    return Object.keys(res).length === 0
      ? eliminarFirmaDocumentosIngresoAPI()
      : subirFirmaDocumentosIngresoAPI();
  };

  const handleUploadSignature = async (res) => {
    const api = chooseAPIForDocumentSiganture(res);
    const updatedFirmaColaborador = {
      ...firmaColaborador,
      [KEYS_INIT.FirmaFileViewModel]: res,
    };
    setFirmaColaborador(updatedFirmaColaborador);
    await executePost(api, updatedFirmaColaborador, () => manageSearchListaDocumentos());
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    setFirmaColaborador({...firmaColaborador, FirmaFileViewModel: signature});
  }, [signature])

  return (
    <div className="container-fluid body-content pt-4 pb-3">
      {modalEnable.isEnable &&
        <div className="modal-contactanos modal-response-ficha-background">
          <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} refreshPage={manageSearchListaDocumentos} {...modalEnable.data}/>
        </div>
      }
      <div className="row justify-content-center">
        {!isColaborador && 
          <>
            <div className="col-sm-6">
              <InputMultiSelect label="Listas de Documentos" {...commonPropsSelect("IdsListasDocumentos", [], true)} isDisabled={inputDisabled}/>
            </div>
            <div className="col-sm-3 pt-1 d-flex justify-content-center align-items-start">
              <button type="button" className="btn btn-primary generic-button-ficha-modal" onClick={updateListaDocumentos} disabled={inputDisabled}>Guardar</button>
            </div>
          </>
        }
      </div>
      {isColaborador &&
        <div className="col-sm-12">
          <p className="required mb-0">{MESSAGE_DNI}</p>
        </div>
      }
      {puedeGestionarFirma &&
        <div className="row pt-3">
          <div className="col-sm-9"></div>
          <div className="col-sm-3 text-end">
            <InputUploadFile
              label="Firma para documentos" 
              direction="column"
              name={KEYS_INIT.FirmaFileViewModel} 
              uploadFile={firmaColaborador[KEYS_INIT.FirmaFileViewModel]} 
              setUploadFile={handleUploadSignature} 
              sectionName={SECTION_NAME} 
              showToastSuccess={false}
              validExtensions={FILE_FORMATS.ALLOWED_IMAGE_FORMATS}/>
          </div>
        </div>
      }
      <div className="d-flex justify-content-center">
        <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Files-validation-message`} />
      </div>
      <table className="table table-hover table-bordered">
        <thead className="listar-thead">
          <tr>
            <th colSpan={isColaborador ? 3 : 4} className="table-header-bold">Documento Asignado</th>
            <th colSpan={isColaborador ? 2 : 1} className="table-header-bold">Documento Firmado</th>
            {!isColaborador &&
              <th colSpan={2} className="table-header-bold">Acciones</th>
            }
          </tr>
          <tr className="">
            <th className="col-6 text-center">Nombre</th>
            <th className="col-1 text-center">Firmar</th>
            <th className="col-1 text-center">Descargar formato</th>
            {!isColaborador && <th className="col-1 text-center">Reemplazar</th>}
            <th className="col-1 text-center">
              <label>
                Subir / Descargar
                <GenericTooltip id="tooltipSubirDescargar" text="Solo son válidos archivos jpg, png o pdf de máximo 8MB." />
              </label>
            </th>
            {isColaborador && <th className="col-1 text-center">Es Obligatorio</th>}
            {!isColaborador &&
              <>
                <th className="col-1 text-center">Validar</th>
                <th className="col-2 text-center">Obligatorio</th>
              </>
            }
          </tr>
        </thead>
        <tbody className="listar-tbody">
          {listDocuments?.map((list, i) => (
            <tr key={`${i}-${list.value}`}>
              <td>{list?.NombreDocumento}</td>
              <td className="text-center">
                <SignFile list={list} 
                  handleSignFile={handleSignFile} 
                  fileSigned={fileSigned}/>
              </td>
              <td className="text-center">
                <DownloadFile list={list} handleDownloadPlantilla={handleDownloadPlantilla}/>
              </td>
              {!isColaborador && 
                <td className="text-center">
                  <UploadReplacementFile list={list} handleUploadFile={handleUploadFile}/>
                </td>
              }
              <td className="text-center upload-file-p-small">
                <UploadDownloadFile list={list} setModalEnable={setModalEnable} handleUploadFile={handleUploadFile}/>
              </td>
              {isColaborador && 
                <td className="text-center">
                  <input type="checkbox" checked={list?.EsObligatorio} disabled />
                </td>
              }
              {!isColaborador &&
                <>
                  <td className="text-center">
                    <ValidateFile list={list} handleActionsObligatorio={handleActionsObligatorio}/>
                  </td>
                  <td className="text-center">
                    <Select
                      isDisabled={list.EsValidado}
                      options = {allDropdowns.find(o => o.key === KEYS_INIT.PasosObligatorios)?.value.filter(o => o.value === NO_OBLIGATORIO_STEP || o.value >= list?.PasoActual)}
                      value = {allDropdowns.find(o => o.key === KEYS_INIT.PasosObligatorios)?.value?.find(o => o.value === list?.PasoObligatorio)}
                      onChange = {(e) => handlePasoObligatorio(list.IdNuevosIngresosEmpleadoDocumento, e.value) }
                      placeholder = "Seleccionar"
                    />
                  </td>
                </>
              }
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ListaDocumentos;

const DownloadFile = ({ list, handleDownloadPlantilla }) => (
  list?.FormatoUrl ? 
    <label className="mx-4 mb-0 hand-pointer blue-standard-color"
      onClick={() => window.open(list.FormatoUrl)}>
      <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
    </label> : 
    list?.IdPlantilla ? 
      <label className="mx-4 mb-0 hand-pointer blue-standard-color"
        onClick={() => {
          handleDownloadPlantilla(
            list.IdNuevosIngresosEmpleadoDocumento
          )}}>
        <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
      </label> :
      <div>-</div>
)

const SignFile = ({ list, handleSignFile, fileSigned }) =>
  list?.PuedeFirmar ? (
    <label
      className={`mx-4 mb-0 hand-pointer blue-standard-color`}
      onClick={() => {
        handleSignFile(list?.IdNuevosIngresosEmpleadoDocumento);
      }}>
      {list?.IdNuevosIngresosEmpleadoDocumento !== fileSigned ? (
        <FontAwesomeIcon
          title="Firmar archivo"
          size="lg"
          icon={faFileSignature}
        />
      ) : (
        <FontAwesomeIcon
          title="Cargando..."
          size="lg"
          spin={true}
          icon={faSpinner}
        />
      )}
    </label>
  ) : (
    <div>-</div>
  );

const UploadReplacementFile = ({ list, handleUploadFile }) => (
  (list.PuedeReemplazar && !(list.Formato === null && list.IdPlantilla === null)) && !list.FileUrl &&
    <GenericUploadFileV2
      id="SubirReemplazoArchivoListaDocumentos"
      extensionesValidas={["pdf", "png", "jpg"]}
      uploadFileToS3={(res) => handleUploadFile(res, reemplazarDocumentoEmpleadoNuevosIngresosAPI, list.IdNuevosIngresosEmpleadoDocumento)}
      textButton="Subir archivo"
      resetInitialFile={false}
      showToastSuccess={false}
      isIcon
      icon={faPaste}
    />
)

const UploadDownloadFile = ({ list, setModalEnable, handleUploadFile }) => (
  <div className="d-flex justify-content-center" style={{gap: "15px"}}>
    {list.PuedeSubir && 
      <GenericUploadFileV2
        id="SubitArchivoListaDocumentos"
        extensionesValidas={["pdf", "png", "jpg"]}
        uploadFileToS3={(res) => handleUploadFile(res, cargarDocumentoEmpleadoAPI, list.IdNuevosIngresosEmpleadoDocumento)}
        textButton="Subir archivo"
        resetInitialFile={false}
        showToastSuccess={false}
        isIcon
        iconColor="required"
      />
    }
    {list.PuedeDescargar &&
      <label className="hand-pointer green-innova mb-0" onClick={() => window.open(list.FileUrl)}>
        <FontAwesomeIcon title="Descargar archivo" size="lg" icon={faDownload}/>
      </label>
    }
    {list.PuedeEliminar &&
      <FontAwesomeIcon
        icon="trash"
        size="lg"
        title="Eliminar"
        className="blue-standard-color hand-pointer"
        onClick={() => setModalEnable({isEnable: true, component: ActionGenericModal, data: {api: eliminarDocumentoEmpleadoAPI, id: list.IdNuevosIngresosEmpleadoDocumento, name: list.File?.OriginalFileName}})}
      />
    }
  </div>
)

const ValidateFile = ({ list, handleActionsObligatorio }) => (
  list.PuedeValidar &&
    <FontAwesomeIcon
      icon={list.EsValidado ? faClipboardCheck : faClipboardList}
      size="lg"
      title={`${list.EsValidado ? "Invalidar" : "Validar"}`}
      className={`hand-pointer ${list.EsValidado ? "green-innova" : "blue-standard-color"}`}
      onClick={() => handleActionsObligatorio(validarDocumentoEmpleadoAPI, list.IdNuevosIngresosEmpleadoDocumento)}
    />
)