import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { ESTADOS } from '../../constants/hardCodedConstants';
import { cancelarReservaAPI } from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';

const TableWebBody = ({ filas, showModalReserva, setIdReserva, setIsEdit, showModalOcurrencias, setInformacionOcurrencia, refreshPage }) => {

    const [executePostRequest] = usePostRequest();

    const showModalReservaByID = (idReserva, isEdit = false) => {
        setIdReserva(idReserva)
        setIsEdit(isEdit);
        showModalReserva()
    };

    const ocurrenciasLink = (ocurrencias) => {
        if (ocurrencias?.length > 0) {
            return <a href="#" onClick={(event) => { handleClick(event, ocurrencias) }}> SI </a>
        }
        return "NO";
    }

    const handleClick = (event, ocurrencias) => {
        event.preventDefault();
        setInformacionOcurrencia(ocurrencias);
        showModalOcurrencias();
    }

    const cancelarReserva = async (idReserva) => {
        try {
            let payload = {
                IdReserva: idReserva
            };

            let response = await executePostRequest(cancelarReservaAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                refreshPage();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <>
            <tbody className="listar-tbody">
                {filas.map((fila, i) => (
                    <tr key={i}>
                        <td className="col-1">{fila?.Responsable}</td>
                        <td className="col-1">{fila?.FechaReserva}</td>
                        <td className="col-1">{fila?.TipoUbicacion}</td>
                        <td className="col-1">{fila?.Ubicacion}</td>
                        <td className="col-1">{fila?.MotivoAsistencia}</td>
                        <td className="col-1">{fila?.Estado}</td>
                        <td className="col-1">{ocurrenciasLink(fila?.Ocurrencia)}</td>
                        <td className="col-1">
                            <div className="d-flex justify-content-start">
                                {fila?.TienePermisosAdministrador && !fila?.EsIndividual && fila?.IdEstado == ESTADOS.PENDIENTE &&
                                    < div >
                                        <FontAwesomeIcon icon='edit' size="lg" className="hand-pointer ml-3" title="Editar participantes" onClick={() => {
                                            showModalReservaByID(fila?.IdReserva, true);
                                        }} />
                                    </div>
                                }
                                <div>
                                    <FontAwesomeIcon icon='table' size="lg" className="hand-pointer ml-3" title="Ver detalle" onClick={() => {
                                        showModalReservaByID(fila?.IdReserva);
                                    }} />
                                </div>
                                {fila?.IdEstado == ESTADOS.PENDIENTE &&
                                    < div >
                                        <FontAwesomeIcon icon='times' size="lg" className="hand-pointer ml-3" title="Cancelar" onClick={() => {
                                            cancelarReserva(fila?.IdReserva);
                                        }} />
                                    </div>
                                }
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    );
};

export default TableWebBody;