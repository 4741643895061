import React from 'react';

const NoTienePermisos = () => {

    return (
        <>
            <p></p>
        </>
    );
};

export default NoTienePermisos;
