import React, {useState} from "react";
import Alert from "react-bootstrap/Alert";

const CustomAlert = ({children, variant, isDismissible = true}) => {
    const [show, setShow] = useState(true);

    if (show) {
        return (
            <Alert variant={variant} onClose={() => setShow(false)} dismissible={isDismissible}>
                {children}
            </Alert>
        );
    }
    return <></>;
};

export default CustomAlert;