import React, {useState, useEffect, useRef} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { getArchivosFichaDatosAPI } from '../../consumers/backendApisUrls';
import NuevoArchivo from './modales/NuevoArchivo';
import EditarArchivo from './modales/EditarArchivo';
import EliminarArchivo from './modales/EliminarArchivo';
import { MENUS_FICHA_DATOS } from '../../constants/hardCodedConstants';

const CardArchivosVarios = ({ idEmpleado, handleDownloadFile, subSeccionesArchivos }) => {
    const isMounted = useRef(true);
    const idMenuFicha = MENUS_FICHA_DATOS.archivos.secciones.archivosVarios.id;

    const [allData, setAllData] = useState({});
    const [modalEnable, setModalEnable] = useState({isEnable: false});

    const [executeGet] = useManageGetRequest();

    const initialData = async () => {
        try {
            await executeGet(getArchivosFichaDatosAPI(idEmpleado), (res) => setAllData(res.data))
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        initialData();
        return () => {isMounted.current = false;}
    }, [])

    return (
    <>
        {modalEnable.isEnable && 
            <div className="modal-contactanos modal-response-ficha-background">
                <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} idEmpleado={idEmpleado} idEmpleadoArchivoFichaDatos={modalEnable.idEmpleadoArchivoFichaDatos} archivoTitle={modalEnable.archivoTitle} manageSearch={initialData}/>
            </div>
        }
        <div className='card'>
            <div className='card-header' id={`heading-archivos-varios`}>
                <button
                    className='btn btn-link font-gothan-medium ficha-titulo-seccion-color'
                    data-toggle='collapse'
                    data-target={`#target-archivos-varios`}
                    aria-expanded='true'
                    aria-controls={`target-archivos-varios`}>
                    {subSeccionesArchivos[idMenuFicha]?.NombreSeccion || "Archivos Varios"}
                </button>
            </div>
            <div id={`target-archivos-varios`} className={`collapse`} aria-labelledby={`heading-archivos-varios`} data-parent='#accordion-datos-archivos'>
                <div>
                    <div className="generic-buscador-container mt-1 mb-2">
                        <div></div>
                        <div className="filtros-right-section py-2">
                        {allData.PuedeAgregar && 
                            <button type="button" className="generic-button-buscador" onClick={() => setModalEnable({isEnable: true, component: NuevoArchivo})}>
                                Nuevo Archivo
                            </button>
                        }
                        </div>
                    </div>
                    {allData.Items?.length ?
                    <table className="table table-hover">
                        <thead className="">
                        <tr>
                            <th className="text-center col-sm-3 font-gothan-medium">Fecha de registro</th>
                            <th className="text-center col-sm-3 font-gothan-medium">Tipo de documento</th>
                            <th className="text-center col-sm-3 font-gothan-medium">Nombre del archivo</th>
                            <th className="text-center col-sm-1 font-gothan-medium">Archivo</th>
                            <th className="text-center col-sm-2 font-gothan-medium">Opciones</th>
                        </tr>
                        </thead>
                        <tbody>
                        {allData.Items?.map((data, index) => (
                            <tr className="" key={`archivosVarios-${index}`}>
                                <td className="p-2 text-center col-sm-3">{data?.FechaRegistro}</td>
                                <td className="p-2 text-center col-sm-3">{data?.TipoDocumento}</td>
                                <td className="p-2 pl-4 col-sm-3">{data?.NombreArchivo}</td>
                                <td className="p-2 text-center col-sm-1">
                                    <FontAwesomeIcon icon='paperclip' className="hand-pointer blue-standard-color" title="Descargar"
                                        onClick={() => {handleDownloadFile(data?.File?.FilePath, data?.File?.OriginalFileName)}}
                                    />
                                </td>
                                <td className="p-2 text-center col-sm-2">
                                    <div className="d-flex justify-content-center">
                                        <div className="mr-3">
                                            <FontAwesomeIcon icon='edit' className="hand-pointer blue-standard-color" title="Editar" onClick={() => setModalEnable({isEnable: true, component: EditarArchivo, idEmpleadoArchivoFichaDatos: data?.IdEmpleadoArchivoFichaDatos})}/>
                                        </div>
                                        <div className="">
                                            <FontAwesomeIcon icon='trash' className="hand-pointer blue-standard-color" title="Eliminar" onClick={() => setModalEnable({isEnable: true, component: EliminarArchivo, idEmpleadoArchivoFichaDatos: data?.IdEmpleadoArchivoFichaDatos, archivoTitle: data?.NombreArchivo})}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table> :
                    <p className='ml-5 mt-2 blue-standard-color font-gothan-light'>No hay registros</p>}
                </div>
            </div>
        </div>
    </>
  )
}

export default CardArchivosVarios;