import React, {useState} from 'react';
import SeccionViewDatosEmpresa from "./SeccionViewDatosEmpresa";
import SeccionEditDatosEmpresa from "./SeccionEditDatosEmpresa";

const DatosEmpresa = ({empleadoId, showUpdateConfirmationModal, showSolicitarCambiosModal, reloadView}) => {
    const [showViewSeccion, setShowViewSeccion] = useState(true);

    return (
        <div>
            <div className='card-body'>
                {showViewSeccion === true &&
                <SeccionViewDatosEmpresa empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadView={reloadView}/>
                }
            </div>
            <div>
                {(showViewSeccion === false) &&
                <SeccionEditDatosEmpresa empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal}/>
                }
            </div>
        </div>
    );
};

export default DatosEmpresa;
