import React from 'react'
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RechazarSolicitud from './RechazarSolicitud';
import ActionGenericModal from '../../../components/modal/ActionGenericModal';
import { aprobarSolicitudCuponTiempoLibreAPI } from '../../../consumers/backendApisUrls';
import { CuponCard, CuponCodigo, CuponImage, CuponLineamiento } from './StyledComponents';

const CardTableMobile = ({ formValues, setFormValues, setModalEnabled, paginator, setPaginator, keys: KEYS, isColaborador = false }) => {
  const rows = formValues.Items;

  const handleShowData = ({ IdSolicitudCuponTiempoLibre }, key) => {
    setFormValues(lastData => {
      const Items = lastData.Items?.map(item => {
        if (item.IdSolicitudCuponTiempoLibre === IdSolicitudCuponTiempoLibre){
          return {...item, [key]: !item[key]};
        }
        return item;
      })
      return {...lastData, Items };
    })
  }

  return (
    <Wrapper>
      {rows.map(row => (
        <Card key={row.IdSolicitudCuponTiempoLibre} showData={row.showData ?? false} showCupon={row.showCupon ?? false} state={row.EstadoSolicitud}>
          <section className="header" onClick={() => handleShowData(row, "showData")}>
            <div className='header_info'>
              <label>Fecha Registro</label>
              <p>{row.FechaRegistro}</p>
            </div>
            <div className='header_show_data'>
              <div className='header_info'>
                <label>Estado Solicitud</label>
                <p className="header_info_estado_solicitud">
                  {row.EstadoSolicitud}
                </p>
              </div>
              <FontAwesomeIcon 
                icon="angle-down"
                size='lg'
                title='Mostrar más'
                className='blue-standard-color'
              />
            </div>
          </section>
          <div className='body_wrapper'>
            <div className='body'>
              <section className="body_section">
                <div className='section_info'>
                  <label>Código Cupón</label>
                  <p>{row.CodigoCupon}</p>
                </div>
                {!row.isColaborador && 
                  <div className='section_info'>
                    <label>Colaborador</label>
                    <p>{row.NombresCompletos}</p>
                  </div>
                }
                <div className='section_info'>
                  <label>Cargo</label>
                  <p>{row.Cargo}</p>
                </div>
                <div className='section_info'>
                  <label>Sede</label>
                  <p>{row.Sede}</p>
                </div>
                <div className='section_info'>
                  <label>Área</label>
                  <p>{row.Area}</p>
                </div>
                <div className='section_info'>
                  <label>Periodo Permiso</label>
                  <div>
                    <p>{row.FechaInicio}</p>
                    <p>{row.FechaFin}</p>
                  </div>
                </div>
                <div className='section_info'>
                  <label>Horario</label>
                  <p>{row.Horario}</p>
                </div>
                {/* Esto se tiene que cambiar, se está poniendo hardcodeado por mientras datafake */}
                <div className='section_info'>
                  <label>Nivel Aprobación</label>
                  <p>Nivel 1/1</p>
                </div>
                <div className='section_info'>
                  <label>Aprobador</label>
                  <p>{row.Aprobador}</p>
                </div>
                {row.MotivoRechazo && 
                  <div className='body_info_rechazo'>
                    <label>Motivo de No Aprobación</label>
                    <p>{row.MotivoRechazo}</p>
                  </div>
                }
              </section>
              <div className='cupon_wrap'>
                <Cupon row={row}/>
              </div>
              <div className='button_wrap'>
                <button className='button_detail' type='button' onClick={() => handleShowData(row, "showCupon")}>
                  {row.showCupon ? "Ocultar Cupón" : "Ver Cupón"}
                </button>
                {(!isColaborador && (row.PuedeAprobarSolicitud || row.PuedeRechazarSolicitud)) && 
                  <div className='button_approve_decline'>
                    {(!row.isColaborador && row.PuedeAprobarSolicitud) &&
                      <button
                        type="button"
                        className={`btn btn-primary generic-button-ficha-modal generic-button-hover-verde`}
                        onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { title: "Aprobar", actionLabel: "aprobar", api: aprobarSolicitudCuponTiempoLibreAPI, name: `${row.NombreCupon} - ${row.NombresCompletos}`, payload: { IdSolicitudCuponTiempoLibre: row.IdSolicitudCuponTiempoLibre }}})}
                        >
                        Aprobar
                      </button>
                    }
                    {(!row.isColaborador && row.PuedeRechazarSolicitud) &&
                    <button
                      type="button"
                      className={`btn btn-primary generic-button-ficha-modal generic-button-hover-rojo`}
                      onClick={() => setModalEnabled({ isEnable: true, component: RechazarSolicitud, data: { row } })}
                    >
                      No Aprobar
                    </button>
                    }
                  </div>
                }
              </div>
            </div>
          </div>
        </Card>
      ))}
    </Wrapper>
  )
}

export default CardTableMobile;

// Cupón Detalle

const Cupon = ({ row }) => (
  <CuponCard>
    <div className='inner-content'>
      <CuponCodigo>{row.CodigoCupon}</CuponCodigo>
      <CuponImage src={row.ImagenCuponTiempoLibreURL}/>
      <div className="cupon_text_overlay">
        <div className='overlay_header'>Ver Lineamientos</div>
        <div className='overlay_body'>
          <CuponLineamiento dangerouslySetInnerHTML={{ __html: row.LineamientosHTML }} />
        </div>
      </div>
    </div>
  </CuponCard>
);

// Lista de Cupones

const Wrapper = styled.div`
  display: grid;
  gap: 15px;
  padding: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`;

const Card = styled.div`
  background: white;
  border-radius: 10px;
  box-shadow: 0px 8px 10px 5px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;

  .header {
    flex: 1;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;

    .header_show_data{
      display: flex;
      align-items: center;
      gap: 15px;

      svg {
        transform: ${({ showData }) => showData ? "rotate(180deg)" : "" };
        transition: transform 1s ease-in-out;
      }
    }

    .header_info{
      text-align: center;
      display: flex;
      flex-direction: column;

      .header_info_estado_solicitud {
        padding: 0px 8px;
        border-radius: 6px;
        ${({ state }) => 
        state === 'No Aprobado' ? css`
          border: 1px solid #ff6565;
          color: white;
          background: #ff6565;
        ` :
        state === "Aprobado" ? css`
          border: 1px solid #8DBA38;
          color: white;
          background: #8DBA38;
        ` :
        css`
          border: 1px solid #F1B634;
          color: white;
          background: #F1B634;
        `
      }}

      label{
        font-size: 10px;
        margin: 0;
      }
      p{
        margin: 0;
        font-size: 14px;
        font-weight: bolder;
        max-width: 120px;
      }
    }
  }

  .body_wrapper{
    display: grid;
    transition: grid-template-rows 1s ease-in-out;
    grid-template-rows: ${({ showData }) => showData ? "1fr" : "0fr" };
    
    .body{
      display: flex;
      flex-direction: column;
      border-top: ${({ showData }) => showData ? "2px solid #3a87c8" : "none" };
      overflow: hidden;
  
      .body_section {
        flex: 1;
        display: grid;
        grid-template-columns: 1fr;
        padding: 15px 20px 5px;
  
        &>div {
          border-bottom: 1px solid #bdddf7;
  
          label {
            margin: 0;
            font-family: 'GothamBold';
            font-size: 13px
          }
  
          p{
            margin: 0;
          }
        }
  
        .section_info{
          display: grid;
          grid-template-columns: minmax(80px, 130px) 1fr;
          align-items: center;
  
          p {
            text-align: right;
          }
        }
  
        .body_info_rechazo{
          p {
            text-align: justify;
          }
        }
      }
  
      .cupon_wrap {
        display: ${({ showCupon }) => showCupon ? "flex" : "none" };
        padding: 15px 20px 0px;
        justify-content: center;
        align-items: center;
      }
  
      .button_wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
        margin: auto;
        gap: 15px;
  
        .button_detail {
          border: 1px solid #3a87c8;
          width: 200px;
          border-radius: 8px;
          background: transparent;
          color: #3a87c8;
          cursor: pointer;
        }

        .button_approve_decline{
          display: flex;
          gap: 15px;
        }
      }
    }
  }

  @media (max-width: 796px){
    .body_wrapper .body .cupon_wrap{
      padding: 15px 8px 0px;
    }
  }
`;