import React, { useContext, useEffect, useState } from 'react';
import GenericModal from "../modal/GenericModal";
import { AuthContext } from "../../hooks/context/authContext";
import { useForm } from "../../hooks/useForm/UseForm";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import {
    clearInputValue,
    extensionYtamañoDeArchivoEsValido,
    getFormattedName,
    isNullOrUndefinded,
    jsonEstaVacio,
    jsonNoEstaVacio,
    mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric,
    obtenerArchivoDeEvent,
    responseUploadFileSuccess
} from "../../helper/utils";
import { mostrarMensajesDeValidacionEnCadaInput } from "../../validations/FormValidatorUtils";
import { toast } from "react-toastify";
import {EditarcartillaVacunacionAPI, getCartilllaVacunacionAPI, geTiposDeVanunasCovidAPI, geTiposDeVanunasCovidTerceraDosisAPI, getMotivosSinVacunacionAPI} from "../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from "../../consumers/httpRequiestsUtils";
import { downloadFile, uploadFile } from "../../consumers/backendConsumer";
import SelectInput from "../Select/SelectInput";
import GenericUploadFile from "../GenericUploadFile/GenericUploadFile";
import { ESTADOS_VACUNACION, EXTENSIONES_VALIDAS_DE_ARCHIVOS } from "../../constants/hardCodedConstants";
import { showToastErrorMaxFileSize } from "../alerts/AlertErrorMaxFileSize";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "react-bootstrap";

const EditarVacunaModal = ({ showModal, reload, userId = null }) => {
    const sectionName = "EditarVacunaModal";
    const UPDATE_SUSTENTO_INPUT_FILE = "ficha-edit-vacuna-sustento-file";
    const OTRA_VACUNA_OPTION_ID = 5;
    const VACUNA_JHONSON_AND_JONSON_OPTION_ID = 3;
    const idModivoSinVacunacionOtros = 5;

    const { state } = useContext(AuthContext);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    const [tiposVacunaOptions, setTiposVacunaOptions] = useState([]);
    const [tiposVacunaTerceraDosisOptions, setTiposVacunaTerceraDosisOptions] = useState([]);
    const [jsonSelectedTipoVacunaValue, setJsonSelectedTipoVacunaValue] = useState({});
    const [jsonSelectedTipoVacunaTerceraDosisValue, setJsonSelectedTipoVacunaTerceraDosisValue] = useState({});
    const [fileName, setFileName] = useState("");
    const [subiendoArchivo, setSubiendoArchivo] = useState(false);
    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});
    const [hideAdjuntar, setHideAdjuntar] = useState(false);
    const [cartillaVacunaResponse, setCartillaVacunaResponse] = useState({});
    const [soloLectura, setSoloLectura] = useState(true);

    const [motivosSinVacunacionOptions, setMotivosSinVacunacionOptions] = useState([]);
    const [jsonSelectedMotivoSinVacunacionOption, setJsonSelectedMotivoSinVacunacionOption] = useState({});
    const [mostrarInputTextMotivoSinVacuna, setMostrarInputTextMotivoSinVacuna] = useState(false);
    const [declaracionJurada, setDeclaracionJurada] = useState(false);

    const [vacunaInicial, setVacunaInicial] = useState();
    const [terceraVacunaInicial, setTerceraVacunaInicial] = useState();

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        if (jsonNoEstaVacio(formValues)) {
            let tempFormValues = { ...formValues };
            if (jsonNoEstaVacio(jsonSelectedTipoVacunaValue) && jsonSelectedTipoVacunaValue?.value !== OTRA_VACUNA_OPTION_ID) {
                tempFormValues.NombreVacuna = "";
            }

            if (jsonSelectedTipoVacunaValue?.value === VACUNA_JHONSON_AND_JONSON_OPTION_ID) {
                tempFormValues.FechaSegundaDosis = "";
            }

            if (!(jsonEstaVacio(jsonSelectedTipoVacunaValue) || isNullOrUndefinded(jsonSelectedTipoVacunaValue?.value))) {
                tempFormValues.TodaviaNoMeVacuno = false;
                tempFormValues.NoHeCompletadoVacunacion = false;
            }
            setFormValues(tempFormValues);
        }

    }, [jsonSelectedTipoVacunaValue]);

    useDidMountEffect(() => {
        if (jsonSelectedMotivoSinVacunacionOption?.value === idModivoSinVacunacionOtros) {
            setMostrarInputTextMotivoSinVacuna(true);
        } else {
            setMostrarInputTextMotivoSinVacuna(false);
        }
    }, [jsonSelectedMotivoSinVacunacionOption])

    useDidMountEffect(async () => {
        /*if (!formValues.TodaviaNoMeVacuno || !formValues.NoHeCompletadoVacunacion) {*/
        if (!formValues.NoHeCompletadoVacunacion) {
            let tiposVacuna = await getTiposDeVacuna();

            let tipoVacunaSelectedObject = tiposVacuna.find(o => o.Value === formValues.IdVacuna);
            if (tipoVacunaSelectedObject) {
                setJsonSelectedTipoVacunaValue({ value: tipoVacunaSelectedObject?.Value, label: tipoVacunaSelectedObject?.Text })
            }
            let tiposVacunaTerceraDosisTemp = await getTiposDeVacunaTerceraDosis();
            let tipoVacunaTerceraDosisSelectedObject = tiposVacunaTerceraDosisTemp.find(o => o.Value === formValues.IdVacunaTerceraDosis);
            if (tipoVacunaTerceraDosisSelectedObject) {
                setJsonSelectedTipoVacunaTerceraDosisValue({ value: tipoVacunaTerceraDosisSelectedObject?.Value, label: tipoVacunaTerceraDosisSelectedObject?.Text })
            }
        } else {
            let motivosSinVacunacion = await getMotivosSinVacunacion();
            let motivosSinVacunacioSelectedObject = motivosSinVacunacion.find(o => o.Value === formValues.IdMotivoSinVacuna);
            if (motivosSinVacunacioSelectedObject) {
                setJsonSelectedMotivoSinVacunacionOption({ value: motivosSinVacunacioSelectedObject?.Value, label: motivosSinVacunacioSelectedObject?.Text });
            }
        }
        
    }, [formValues.TodaviaNoMeVacuno, formValues.NoHeCompletadoVacunacion])

    useEffect(() => {
        if (jsonSelectedTipoVacunaTerceraDosisValue) {
            setFormValues((prev) => ({
                ...prev,
                NoHeCompletadoVacunacion: false
            }));
        }
    }, [jsonSelectedTipoVacunaTerceraDosisValue])

    const getTiposDeVacuna = async function () {
        const response = await executeGetRequest(geTiposDeVanunasCovidAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getTiposDeVacunaTerceraDosis = async function () {
        const response = await executeGetRequest(geTiposDeVanunasCovidTerceraDosisAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getCartilllaVacunacion = async function () {
        let response;
        if(userId !== null) response = await executeGetRequest(getCartilllaVacunacionAPI(userId));
        else response = await executeGetRequest(getCartilllaVacunacionAPI(state.user.id_empleado));
        if (responseCode200(response)) {
            setCartillaVacunaResponse(response.data);
            if(userId) {setSoloLectura(false);}
            else setSoloLectura(response.data.SoloLectura);
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getMotivosSinVacunacion = async function () {
        const response = await executeGetRequest(getMotivosSinVacunacionAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        setLoading(true);
        let tiposVacunaaa = await getTiposDeVacuna();
        let tiposVacunaTerceraDosisTemp = await getTiposDeVacunaTerceraDosis();
        let responseData = await getCartilllaVacunacion();
        let responseMotivosSinVacunacion = await getMotivosSinVacunacion();
        let motivosSinVacunacionOptionsMap = [];
        if (responseMotivosSinVacunacion) {
            motivosSinVacunacionOptionsMap = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(responseMotivosSinVacunacion, "Value", "Text");
            setMotivosSinVacunacionOptions(motivosSinVacunacionOptionsMap);
        }

        if (tiposVacunaTerceraDosisTemp && responseData) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(tiposVacunaTerceraDosisTemp);
            setTiposVacunaTerceraDosisOptions(options);
        }

        if (tiposVacunaaa && responseData) {
            let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(tiposVacunaaa);
            setTiposVacunaOptions(options);

            let tipoVacunaSelectedId = responseData?.IdVacuna;
            let tipoVacunaSelectedObject = tiposVacunaaa.find((vacuna) => vacuna.Value === tipoVacunaSelectedId);
            if (tipoVacunaSelectedObject) {
                setJsonSelectedTipoVacunaValue({ value: tipoVacunaSelectedObject?.Value, label: tipoVacunaSelectedObject?.Text })
                setVacunaInicial(responseData?.IdVacuna);
            }

            let tipoVacunaTerceraDosisSelectedId = responseData?.IdVacunaTerceraDosis;
            let tipoVacunaTerceraDosisSelectedObject = tiposVacunaTerceraDosisTemp.find((vacuna) => vacuna.Value === tipoVacunaTerceraDosisSelectedId);
            if (tipoVacunaTerceraDosisSelectedObject) {
                setTerceraVacunaInicial(responseData?.IdVacunaTerceraDosis);
                setJsonSelectedTipoVacunaTerceraDosisValue({ value: tipoVacunaTerceraDosisSelectedObject?.Value, label: tipoVacunaTerceraDosisSelectedObject?.Text })
            }

            let formValuess = {
                "IdVacuna": responseData?.IdVacuna,
                "FechaPrimeraDosis": responseData?.FechaPrimeraDosis,
                "FechaSegundaDosis": responseData?.FechaSegundaDosis,
                "NombreVacuna": responseData?.NombreVacuna,
                "TodaviaNoMeVacuno": responseData?.TodaviaNoMeVacuno ?? false,
                "NoHeCompletadoVacunacion": responseData?.NoHeCompletadoVacunacion ?? false,
                "MotivoSinVacuna": responseData?.IdMotivoSinVacuna === idModivoSinVacunacionOtros ? responseData?.MotivoSinVacuna : "",
                "IdMotivoSinVacuna": responseData?.IdMotivoSinVacuna,
                "IdVacunaTerceraDosis": tipoVacunaTerceraDosisSelectedId,
                "FechaTerceraDosis": responseData?.FechaTerceraDosis,
            }
            setFormValues(formValuess);
            setJsonSelectedMotivoSinVacunacionOption(motivosSinVacunacionOptionsMap.find(o => o.value == responseData?.IdMotivoSinVacuna));
        }
        setLoading(false);
    };

    const armarPayload = function () {
        let payload = {
            "IdVacuna": (jsonSelectedTipoVacunaValue["value"]) ? jsonSelectedTipoVacunaValue["value"] : vacunaInicial,
            "IdVacunaTerceraDosis": (jsonSelectedTipoVacunaTerceraDosisValue["value"]) ? jsonSelectedTipoVacunaTerceraDosisValue["value"] : terceraVacunaInicial,
            "FechaPrimeraDosis": formValues["FechaPrimeraDosis"],
            "FechaSegundaDosis": formValues["FechaSegundaDosis"],
            "FechaTerceraDosis": formValues["FechaTerceraDosis"],
            "NombreVacuna": formValues["NombreVacuna"],
            "CartillaLink": jsonEstaVacio(s3UploadFileResponse) ? null : s3UploadFileResponse,
            "TodaviaNoMeVacuno": formValues["TodaviaNoMeVacuno"],
            "NoHeCompletadoVacunacion": formValues["NoHeCompletadoVacunacion"],
            "IdMotivoSinVacuna": jsonSelectedMotivoSinVacunacionOption?.value ?? null,
            "MotivoSinVacuna": jsonSelectedMotivoSinVacunacionOption?.value === idModivoSinVacunacionOtros ? formValues?.MotivoSinVacuna : jsonSelectedMotivoSinVacunacionOption?.label,
            "AceptaDeclaracionJurada": declaracionJurada,
            "IdEmpleado" : userId
        };

        return payload;
    }

    const submit = async () => {
        let payload = armarPayload();
        await executeSaveVacunaForm(payload);
    };

    const executeSaveVacunaForm = async (payload) => {
        console.debug("payload: ", payload);

        const response = await executePostRequest(EditarcartillaVacunacionAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showModal();
            reload();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdVacuna", "FechaPrimeraDosis", "FechaSegundaDosis", "TodaviaNoMeVacuno", "NoHeCompletadoVacunacion ", "NombreVacuna", "CartillaLink", "IdMotivoSinVacuna", "MotivoSinVacuna", "FechaTerceraDosis", "IdVacunaTerceraDosis", "AceptaDeclaracionJurada"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const resetUploadFile = () => {
        setFileName("");
        setS3UploadFileResponse({});
        clearInputValue(UPDATE_SUSTENTO_INPUT_FILE);
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponse(s3Response);
            setFileName(getFormattedName(file.name));
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivo(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                console.debug("response ", response);
                manageUploadFileResponse(response, file);
            } else {
                resetUploadFile();
                showToastErrorMaxFileSize();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const removeUploadedFile = function () {
        resetUploadFile();
    };

    const handleDeclaracionJurada = (e) => {
        setDeclaracionJurada(e.target.checked);
    };

    const opcionTodaviaNoMeVacuno = (
        <div className="pt-1 pb-5">
            <hr/>
            <div className="form-group row mb-2 mt-3">
                <label className="col-sm-4 col-form-label modal-label">Motivo por el cual no se vacuna<span className="required">*</span></label>   
                <div className="col-sm-8 black-color">
                    <SelectInput options={motivosSinVacunacionOptions} jsonSelectedValue={jsonSelectedMotivoSinVacunacionOption} setJsonSelectedValue={setJsonSelectedMotivoSinVacunacionOption} isDisabled={soloLectura || (formValues.NoHeCompletadoVacunacion === false)}
                        placeholder={"Seleccionar un motivo por el cual no se vacuna"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdMotivoSinVacuna-validation-message`}></p>
                </div>
            </div>

            {
                mostrarInputTextMotivoSinVacuna &&
                <div className="form-group row mb-0">
                    <label className="col-sm-4 col-form-label modal-label">Explicar el motivo<span className="required">*</span></label>
                    <div className="col-sm-8 black-color">
                        <textarea className="form-control" rows="3" name="MotivoSinVacuna" onChange={handleChange} value={formValues?.MotivoSinVacuna} disabled={soloLectura || (formValues.NoHeCompletadoVacunacion === false)} placeholder="Máximo 200 caractares" maxLength="200"></textarea>
                        <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-MotivoSinVacuna-validation-message`}></p>
                    </div>
                </div>
            }
        </div>
    );

    const opcionMeVacune = (
        <>
            <hr className="mt-1 mb-1"/>
            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Tipo de vacuna<span className="required">*</span></label>
                <div className="col-sm-8 black-color">
                    <SelectInput options={tiposVacunaOptions} jsonSelectedValue={jsonSelectedTipoVacunaValue} setJsonSelectedValue={setJsonSelectedTipoVacunaValue} isDisabled={soloLectura} placeholder={"Seleccionar"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdVacuna-validation-message`}></p>
                </div>


            </div>
            <div className="form-group row mb-0">
            {jsonSelectedTipoVacunaValue?.value === OTRA_VACUNA_OPTION_ID &&
            <>
                <label className="col-sm-4 col-form-label modal-label">Nombre de Vacuna<span className="required">*</span></label>
                <div className="col-sm-8 black-color">
                    <input type="text" className="form-control" name="NombreVacuna" onChange={handleChange} value={formValues?.NombreVacuna} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-NombreVacuna-validation-message`}></p>
                </div>
            </>
            }
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Me vacuné 1ra. dosis el<span className="required">*</span></label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaPrimeraDosis" onChange={handleChange} value={formValues?.FechaPrimeraDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaPrimeraDosis-validation-message`}></p>
                </div>

            </div>

            <div className="form-group row mb-0">
            {jsonSelectedTipoVacunaValue?.value !== VACUNA_JHONSON_AND_JONSON_OPTION_ID &&
            <>
                <label className="col-sm-4 col-form-label modal-label">Me vacuné 2da. dosis el<span className="required">*</span></label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaSegundaDosis" onChange={handleChange} value={formValues?.FechaSegundaDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaSegundaDosis-validation-message`}></p>
                </div>
            </>
            }
            </div>

            <hr className={"mb-1 mt-1"}/>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Tipo de vacuna refuerzo<span className="required">*</span></label>
                <div className="col-sm-8 black-color">
                    <SelectInput options={tiposVacunaTerceraDosisOptions} jsonSelectedValue={jsonSelectedTipoVacunaTerceraDosisValue} setJsonSelectedValue={setJsonSelectedTipoVacunaTerceraDosisValue} isDisabled={soloLectura} placeholder={"Seleccionar"} />
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-IdVacunaTerceraDosis-validation-message`}></p>
                </div>
            </div>

            <div className="form-group row mb-0">
                <label className="col-sm-4 col-form-label modal-label">Me vacuné dosis de refuerzo el<span className="required">*</span></label>
                <div className="col-sm-8">
                    <input type="date" className="form-control" name="FechaTerceraDosis" onChange={handleChange} value={formValues?.FechaTerceraDosis ?? ""} disabled={soloLectura}></input>
                    <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-FechaTerceraDosis-validation-message`}></p>
                </div>
            </div>
            <hr className={"mb-1 mt-1"}/>
            {hideAdjuntar === false &&
                <div className="form-group row mb-0">
                    <label className="col-sm-4 col-form-label modal-label">Adjuntar Certificado de Vacunación<span className="required">*</span></label>
                    <div className="col-sm-8">
                        <GenericUploadFile handleUploadFile={handleUploadFile} datosSeccion={null} fileName={fileName} subiendoArchivo={subiendoArchivo} removeUploadedFile={removeUploadedFile} id={UPDATE_SUSTENTO_INPUT_FILE}
                            textButton={"Adjunta PDF o imagen"}>
                            <></>
                        </GenericUploadFile>
                        <p className="ficha-form-input-validation-message mb-2" id={`ficha-edit-${sectionName}-CartillaLink-validation-message`}></p>
                    </div>
                </div>
            }

            {cartillaVacunaResponse && cartillaVacunaResponse.CartillaLink &&
                <div className="form-group row mb-0">
                    <label className="col-sm-4 col-form-label modal-label">Certificado de Vacunación</label>
                    <div className="col-sm-8">
                        <button
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'
                            onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, cartillaVacunaResponse.CartillaLink.Path, cartillaVacunaResponse.CartillaLink.OriginalFileName);
                            }}>
                            Descargar
                        </button>
                    </div>
                </div>
            }
        </>
    );

    const tieneEstadoSinVacunacion = cartillaVacunaResponse && (cartillaVacunaResponse?.EstadoVacuna === null || cartillaVacunaResponse?.IdEstadoVacuna === ESTADOS_VACUNACION.SinVacuna.id);
    const tieneEstadoDiferenteSinVacunacion = cartillaVacunaResponse?.EstadoVacuna !== null && cartillaVacunaResponse?.EstadoVacuna !== ESTADOS_VACUNACION.SinVacuna.id;
    return (
        <>
            <GenericModal showModal={showModal} width={"830px"} titulo={"Editar Vacuna COVID-19"}>
                {loading === true &&
                <div className="spinner-cargando-container">
                    <Spinner animation="grow" variant="primary" size="1sm"/>
                </div>
                }
                {loading === false &&
                <>
                    <div className="modal-body p-0">
                        <form className="blue-standard-color">
                            {cartillaVacunaResponse && cartillaVacunaResponse.EstadoVacuna &&
                            <div className="d-flex justify-content-end">
                                <p className="black-color mb-0 pb-1"><strong>Estado:</strong> {cartillaVacunaResponse && cartillaVacunaResponse.EstadoVacuna}</p>
                            </div>
                            }
                            <div className="d-flex justify-content-between">
                                {(cartillaVacunaResponse?.IdEstadoVacuna === ESTADOS_VACUNACION.Completado.id) ? null : (
                                    <>
                                        <div className="col-sm-6 align-self-start d-none">
                                            <input type="checkbox" name="TodaviaNoMeVacuno" onChange={handleChange} checked={formValues.TodaviaNoMeVacuno ?? false} disabled={userId !== null}></input> <span
                                            className="black-color font-gothan-light ">Todavía no me vacuno</span>
                                        </div>
                                        <div className="col-sm-6 align-self-start">
                                            <input 
                                                type="checkbox" 
                                                name="NoHeCompletadoVacunacion" 
                                                onChange={handleChange} 
                                                checked={formValues.NoHeCompletadoVacunacion ?? false} 
                                                disabled={userId !== null}>
                                            </input> 
                                            <span
                                                className="black-color font-gothan-light "
                                            >
                                                No he completado mi vacunación
                                            </span>
                                        </div>
                                        <div className="alert alert-danger mt-0 mb-0 col-sm-6 align-self-end pt-1 pb-1 pl-2 pr-2" role="alert">
                                            <div className="d-flex justify-content-between">
                                                <div className="align-self-start">
                                                    <FontAwesomeIcon icon='exclamation-circle' size="sm"/>
                                                </div>
                                                <div className="align-self-end">
                                                    <p className="mb-0 text-right"><strong>No has completado tu vacunación</strong></p>
                                                    <p className="mb-0 text-right"><strong>Necesitamos que nos indiques el motivo</strong></p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            {/*(formValues.TodaviaNoMeVacuno === true || formValues.NoHeCompletadoVacunacion === true) && opcionTodaviaNoMeVacuno*/}
                            {/*(formValues.TodaviaNoMeVacuno === false && formValues.NoHeCompletadoVacunacion === false) && opcionMeVacune*/}

                            { opcionTodaviaNoMeVacuno}
                            { opcionMeVacune}
                            {soloLectura === false &&
                            <p className="black-color mb-2 mt-2">Recuerda volver cuando te vacunes para informarnos tu nuevo estado.</p>
                            }

                            {/* userId == null && */}
                            <div className="form-check margin-top-15px">
                                <input className="form-check-input" type="checkbox" id="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
                                <label className="form-check-label ficha-declaracion-jurada-message" htmlFor="AceptaDeclaracionJurada">
                                    <strong><p className={"mb-0"}>Declaro bajo juramento que todos los datos y documentos presentados son VERDADEROS. En caso LA EMPRESA lo quiera, podrá verificar 
                                    la documentación presentada y, en caso de detectar que sea FALSA, podrá adoptar las acciones de índole laboral y legal correspondientes. Manifiesto de forma 
                                    expresa mi conformidad para el almacenamiento de la información registrada en la INTRANET. Asimismo, tengo conocimiento que LA EMPRESA podrá informar sobre el 
                                    registro de la documentación enviada, en caso sea requerida por alguna autoridad nacional.</p></strong>
                                </label>
                                <p className="ficha-form-input-validation-message"  id={`ficha-edit-${sectionName}-AceptaDeclaracionJurada-validation-message`}></p>
                            </div>
                            
                        </form>
                    </div>
                    { /* userId === null && */}
                    <div className="modal-footer p-0 d-flex justify-content-between">
                        <div className="message font-gothan-light align-self-start" style={{ 'width': '85%' }}>
                            <strong>
                                <p className="mb-1">* Campos obligatorios</p>
                                <p className="mb-1">Puedes ingresar a este link para descargar tu certificado de Vacunación <a href="https://gis.minsa.gob.pe/CarneVacunacion/" target="_blank">https://gis.minsa.gob.pe/CarneVacunacion/</a></p>
                            </strong>
                        </div>
                        <div className="button align-self-end">
                            <button className="btn btn-primary generic-button-ficha" onClick={submit}>Enviar</button>
                        </div>
                    </div>
                </>
                }
            </GenericModal>
        </>
    );
};

export default EditarVacunaModal;
