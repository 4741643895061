import React, { useEffect, useState } from "react";
import { getHorarioLactanciaByIdEmpleadoFlujoActividadAPI, getTiposHorariosLactanciaMaternidadAPI, guardarActualizarHorarioLactanciaAPI } from "../../../consumers/backendApisUrls";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { usePropsSelect } from "../../../hooks/useProps/usePropsSelect";
import { initialAllOptionPromises } from "../../../helper/utils";
import { usePropsInputs } from "../../../hooks/useProps/usePropsInput";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import ConvenioHorarioLactancia from "./ConvenioHorarioLactancia";

const KEYS_DROPDOWN = ["IdTipoHorarioLactancia"];
const SECTION_NAME = "AprobarHorarioLactancia";
const KEYS = {
  idEmpleadoFlujoActividad: "IdEmpleadoFlujoActividad",
  idTipoHorarioLactancia: "IdTipoHorarioLactancia",
  idHorarioLactancia: "IdHorarioLactancia"
};

const AprobarHorarioLactancia = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {
  const [formValues, setFormValues] = useState({
    [KEYS.idEmpleadoFlujoActividad]: IdEmpleadoFlujoActividad
  });
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [getOption] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [isLoading, setIsLoading] = useState(false);
  const [executeGet] = useGetRequest();

  useEffect(() => {
    init();
    getDropdowns();
  }, []);

  const init = async () => {
    if (IdEmpleadoFlujoActividad != null){
      const api = getHorarioLactanciaByIdEmpleadoFlujoActividadAPI(IdEmpleadoFlujoActividad);
      const result = await executeGet(api);
      setFormValues({
        ...formValues,
        ...result.data,
      });
    }
    setIsLoading(false);
  }

  const getDropdowns = async () => {
    setIsLoading(true);
    const dropdownsPromises = () => [
      getOption(getTiposHorariosLactanciaMaternidadAPI())
    ]
    await initialAllOptionPromises(dropdownsPromises, KEYS_DROPDOWN, setAllDropdowns);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const api = guardarActualizarHorarioLactanciaAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const successSubmitCallback = async () => {
    showModal();
    await manageSearch();
  }

  return (
    <ConvenioHorarioLactancia
      KEYS = {KEYS}
      SECTION_NAME = {SECTION_NAME}
      showModal = {showModal}
      isLoading = {isLoading}
      handleSubmit = {handleSubmit}
      label = "Aprobar"
      formValues = {formValues}
      setFormValues = {setFormValues}
      selectProps = {selectProps}
      commonProps = {commonProps}
      puedeEditarHorarios = {true}
      puedeVerArchivos = {false}
    />
  )
};

export default AprobarHorarioLactancia;
