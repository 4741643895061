import React from 'react'
import Wrapper from '../atoms/DirectorioWrapper'
import SpinnerLoading from './SpinnerLoading'

const PageWrapper = ({ isLoading = false, children }) => {

  if(isLoading) return <SpinnerLoading/>

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        <Wrapper className="App">
          {children}
        </Wrapper>
      </div>
    </div>
  )
}

export default PageWrapper;