import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {ESTADOS_ACTUALIZACION_FICHA, MENUS_FICHA_DATOS} from "../../constants/hardCodedConstants";
import {AuthContext} from "../../hooks/context/authContext";
import {downloadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, hideElement, jsonNoEstaVacio, notNullAndNotUndefinded, obtenerPaisPeru, showElement, stringSeEncuentraEnArray} from "../../helper/utils";
import {toast} from "react-toastify";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {cancelarSolicitudDeCambioAPI, getDatosNacimientoAPI, getPaisesAPI, guardarDatosCumpleAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest"

const SeccionViewDatosDeNacimiento = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, showSolicitarCambiosModal, reloadView}) => {
    const {state} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosDeNacimiento, setDatosNacimiento] = useState({});
    const [mostrarFechaCumple, setMostrarFechaCumple] = useState(false);
    const [dataFields, setDataFields] = useState([]);
    const [dataFieldsTemp, setDataFieldsTemp] = useState([]);
    const [loading, setLoading] = useState(true);
    const [statusUpdateNacimiento, setStatusUpdateNacimiento] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [managePost] = useManagePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        console.debug("[DatosDeNacimiento] useDidMountEffect dataFieldsTemp");
        updateHideShowElementsInHTMLWhenDataFieldsTempChange();

    }, [dataFieldsTemp]);

    useDidMountEffect(() => {
        console.debug("[DatosDeNacimiento] useDidMountEffect dataFields");
        dataFieldRelojManager();

    }, [dataFields]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [reloadView]);

    const updateId = () => {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    };

    useDidMountEffect(() => {
        console.debug("[FichaDatosSeccionTitleMessage] statusUpdate new: ", statusUpdateNacimiento);
        if (notNullAndNotUndefinded(statusUpdateNacimiento) && jsonNoEstaVacio(statusUpdateNacimiento)) {
            manageStatusUpdateResidencia();

        }
    }, [statusUpdateNacimiento]);

    const manageStatusUpdateResidencia = function () {
        if (statusUpdateNacimiento.IdEstado === ESTADOS_ACTUALIZACION_FICHA.observado.id ||
            statusUpdateNacimiento.IdEstado === ESTADOS_ACTUALIZACION_FICHA.pendiente.id) {
            changeViewLabelAndValueColorToRed();
        } else {
            changeViewLabelAndValueColorToDefaultBlack();
        }
    };

    const changeViewLabelAndValueColorToRed = function () {
        console.debug("changeViewLabelAndValueColorToRed");
        document.querySelector(`#frmInfoDatosDeNacimiento`).classList.add("change-requested-field");
    };

    const changeViewLabelAndValueColorToDefaultBlack = function () {
        console.debug("changeViewLabelAndValueColorToDefaultBlack");
        document.querySelector(`#frmInfoDatosDeNacimiento`).classList.remove("change-requested-field");
    };

    const getStatusUpdateNacimiento = function (idEmpleadoo, response) {
        try {
            if (idEmpleadoo) {
                if (responseCode200(response)) {
                    setStatusUpdateNacimiento(response.data.validationData);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            } else {
                console.warn("id de empleado no válido: ", idEmpleadoo);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const updateComponentStates = async () => {
        try {
            if (idActualizado) {
                let datosNacimientoResponse = await executeGetRequest(getDatosNacimientoAPI(idActualizado));
                if (responseCode200(datosNacimientoResponse)) {
                    setMostrarFechaCumple(datosNacimientoResponse.data.data.MostrarCumpleanios)
                    setDatosNacimiento(datosNacimientoResponse.data.data);
                    setDataFields(datosNacimientoResponse.data.data.DataFields);
                    setDataFieldsTemp(datosNacimientoResponse.data.data.DataFields);
                    await mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange(datosNacimientoResponse.data.data.IdPaisNacimiento, datosNacimientoResponse.data.data.DataFields);
                    getStatusUpdateNacimiento(idActualizado, datosNacimientoResponse);
                } else {
                    mostrarMensajeDeErrorConToast(datosNacimientoResponse);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const actualizar = async () => {
        try {
            setShowViewSeccion(false);
        } catch (e) {
            console.error(e);
        }
    };

    const reset = async () => {
        await updateComponentStates();
    };

    //Reloj cancelar solicitud

    const dataFieldRelojManager = async () => {
        console.debug("[DatosDeNacimiento] dataFieldRelojManager, dataFields: ", dataFields);
        for (let dataField of dataFields) {
            if (dataField.HasRequest === true) {
                agregarRelojYCambiarColorARojo(dataField);
            } else {
                console.debug("[DatosDeNacimiento] quitar reloj y asterisco en ", dataField.KeyField);
                quitarRelojYCambiarColorARojo(dataField);
            }
        }
    };

    const agregarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosDeNacimiento] agregarRelojYCambiarColorARojo ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosDeNacimiento-${keyField} .change-requested-field-clock`).removeAttribute('hidden');
            document.querySelector(`#ficha-view-datosDeNacimiento-${keyField}`).classList.add("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para agregarle el reloj`, e);
        }
    };

    const quitarRelojYCambiarColorARojo = (dataField) => {
        try {
            console.debug("[DatosDeNacimiento] quitarRelojYCambiarColorANegro ", dataField.KeyField);
            let keyField = dataField.KeyField;
            document.querySelector(`#ficha-view-datosDeNacimiento-${keyField} .change-requested-field-clock`).setAttribute('hidden', '');
            document.querySelector(`#ficha-view-datosDeNacimiento-${keyField}`).classList.remove("change-requested-field");
        } catch (e) {
            console.warn(`no se encontró ${dataField.KeyField} para quitar el reloj`, e);
        }
    };

    const manageCancelarSolicitud = async (e) => {
        console.debug("[DatosDeNacimiento] manage cancelar solicitud de campo ", e);
        e.preventDefault();
        let buttonId = e.target.id;
        let keyFieldString = buttonId.replace("ficha-view-datosDeNacimiento-tooltip-cancelar-button-", "");
        let fieldId = obtenerFieldIdByKeyFieldName(keyFieldString);

        if (fieldId) {
            let response = await executePostRequest(cancelarSolicitudDeCambioAPI(fieldId), {});
            if (responseCode200(response)) {
                toast.success("Se canceló solicitud de campo con éxito");
                await reset();
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const getHTMLContentForCancelarSolicitudDeCampo = (buttonId) => {
        return (
            <div>
                <div><span>¿Quieres cancelar la solicitud de actualización de este dato?</span></div>
                <div>
                    <button id={buttonId} className="change-requested-tooltip-cancel-button" onClick={manageCancelarSolicitud}>Sí cancelar</button>
                </div>
            </div>
        )
    };

    const obtenerFieldIdByKeyFieldName = (keyFieldName) => {
        const dataField = dataFields.filter(field => field.KeyField == keyFieldName);

        if (dataField.length > 0) {
            return dataField[0].IdField;
        } else {
            console.error("no se encontró keyFieldName: ", keyFieldName)
        }
    };


    //handles

    //upload file manager


    //hide show logic

    const updateHideShowElementsInHTMLWhenDataFieldsTempChange = () => {
        console.debug("[DatosDeNacimiento] updateHideShowElementsInHTMLWhenDataFieldsTempChange dataFieldsTemp: ", dataFieldsTemp);
        setLoading(true);
        try {
            for (const field of dataFieldsTemp) {
                let fieldKey = field["KeyField"];
                if (field["IsHidden"] === true) {
                    console.debug("[DatosDeNacimiento] esconder fieldKey: ", fieldKey);
                    hideElement(`ficha-view-datosDeNacimiento-${fieldKey}`);
                }

                if (field["IsHidden"] === false) {
                    console.debug("[DatosDeNacimiento] mostrar fieldKey: ", fieldKey);
                    showElement(`ficha-view-datosDeNacimiento-${fieldKey}`);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };


    //hide show logic

    const mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange = async (idPaisSeleccionado, dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange idPaisSeleccionado: ", {idPaisSeleccionado, dataFieldsTemp: dataFieldsTempp});

        let paisesResponse = await executeGetRequest(getPaisesAPI());
        let idPaisPeru = obtenerPaisPeru(paisesResponse);
        console.debug({idPaisPeru});
        if (idPaisSeleccionado == idPaisPeru) {
            mostrarDepartamentoProvinciaDistritoEsconderCiudad(dataFieldsTempp);
        } else {
            mostrarCiudadEsconderDepartamentoProvinciaDistrito(dataFieldsTempp);
        }
    };

    const mostrarDepartamentoProvinciaDistritoEsconderCiudad = (dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] se ha seleccionado Perú, por lo que se mostrará departamento, provincia y distrito y se esconderá ciudad");
        let hiddenFields = obtenerHiddenFields(dataFieldsTempp);
        console.debug("[DatosDeNacimiento] current hidden fields: ", hiddenFields);

        let newHiddenFields = hiddenFields;
        let inputsKeysToNotBeHidden = ["IdDepartamento", "IdProvincia", "IdDistrito"];
        let inputsKeysToBeHidden = ["CiudadNacimiento"];
        newHiddenFields = getNewHiddenFields(inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden);

        console.debug("[DatosDeNacimiento] new hidden fields final: ", newHiddenFields);
        updateDataFieldTemp(newHiddenFields, dataFieldsTempp);
    };

    const mostrarCiudadEsconderDepartamentoProvinciaDistrito = (dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] se ha seleccionado País diferente a Perú, por lo que se mostrará ciudad y se esconderá departamento, provincia y distritos");
        let hiddenFields = obtenerHiddenFields(dataFieldsTempp);
        console.debug("[DatosDeNacimiento] current hidden fields: ", hiddenFields);

        let newHiddenFields = hiddenFields;
        let inputsKeysToNotBeHidden = ["CiudadNacimiento"];
        let inputsKeysToBeHidden = ["IdDepartamento", "IdProvincia", "IdDistrito"];

        newHiddenFields = getNewHiddenFields(inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden);

        console.debug("[DatosDeNacimiento] new hidden fields final: ", newHiddenFields);
        updateDataFieldTemp(newHiddenFields, dataFieldsTempp);
    };

    const obtenerHiddenFields = (dataFieldssss) => {
        let hiddenFields = [];
        for (let dataField of dataFieldssss) {
            if (dataField["IsHidden"] === true) {
                hiddenFields = [...hiddenFields, dataField["KeyField"]];
            }
        }
        return hiddenFields;
    };

    const getNewHiddenFields = (inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden) => {
        for (let inputKey of inputsKeysToNotBeHidden) {
            newHiddenFields = eliminarStringDeArraydDeStrings(inputKey, newHiddenFields);
        }

        for (let inputKey of inputsKeysToBeHidden) {
            newHiddenFields = agregarStringAArraydDeStringsSiNoExiste(inputKey, newHiddenFields);
        }
        return newHiddenFields;
    };

    const updateDataFieldTemp = (newHiddenFieldsArray, dataFieldss) => {
        console.debug("[DatosDeNacimiento] updateDataFieldTemp newHiddenFieldsArray:", newHiddenFieldsArray);
        let newDataFieldsTemp = [];
        console.debug("[DatosDeNacimiento] dataFieldss: ------ ", dataFieldss);
        for (let dataField of dataFieldss) {
            let dataFieldTemp = dataField;
            if (stringSeEncuentraEnArray(dataFieldTemp["KeyField"], newHiddenFieldsArray)) {
                dataFieldTemp = {...dataFieldTemp, "IsHidden": true};
            } else {
                dataFieldTemp = {...dataFieldTemp, "IsHidden": false};
            }
            newDataFieldsTemp = [...newDataFieldsTemp, dataFieldTemp];
        }
        console.debug("[DatosDeNacimiento] updateDataFieldTemp result newDataFieldsTemp ", newDataFieldsTemp);
        setDataFieldsTemp(newDataFieldsTemp);
    };

    const toggleVerCumple = async () => {
        const payload = {
            IdEmpleado: empleadoId,
            MostrarCumpleanios: !mostrarFechaCumple
        };
        const api = guardarDatosCumpleAPI();
        await managePost(api, payload, () => {}, true);
        setMostrarFechaCumple(prev => !prev);
    }
    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}
            <form id='frmInfoDatosDeNacimiento' hidden={loading}>
                <div className='displayFlexWrap'>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-FechaNacimiento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-FechaNacimiento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-FechaNacimiento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-FechaNacimiento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Fecha de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.FechaNacimiento}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-Edad">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-Edad" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-Edad'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-Edad")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Edad:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.Edad}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-FechaCumpleanios">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <label className="ficha-info-label-width">
                                <span>  
                                    <p 
                                        data-tip data-for="informacion-datosDeNacimiento-fechacumpleanios"
                                        className="d-inline mr-2"
                                    >
                                        <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                                    </p>
                                    <ReactTooltip id="informacion-datosDeNacimiento-fechacumpleanios" place="bottom" effect="solid" className="tooltip-style tooltip-change-witdh">
                                        Si desactivas el botón de cumpleaños, no enviaremos un correo con tu saludo a toda la Familia Innova
                                    </ReactTooltip>
                                </span>
                                {/* datosDeNacimiento && datosDeNacimiento.MostrarCumpleanios == false &&
                                <FontAwesomeIcon icon='eye-slash' transform="left-9"/> */}
                                {
                                <FontAwesomeIcon 
                                    icon={mostrarFechaCumple? 'eye': 'eye-slash'} 
                                    transform="left-9"
                                    className="hand-pointer blue-standard-color"
                                    onClick={() => toggleVerCumple()}
                                />
                                }
                                Fecha de cumpleaños:
                            </label>
                        </div>

                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.FechaCumpleanios}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-IdPaisNacimiento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-IdPaisNacimiento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-IdPaisNacimiento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-IdPaisNacimiento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                País de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.PaisNacimiento}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-CiudadNacimiento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-CiudadNacimiento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-CiudadNacimiento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-CiudadNacimiento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Ciudad de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.CiudadNacimiento}</p>}
                        </label>
                    </div>

                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-IdDepartamento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-IdDepartamento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-IdDepartamento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-IdDepartamento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Departamento de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.Departamento}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-IdProvincia">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-IdProvincia" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-IdProvincia'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-IdProvincia")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Provincia de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.Provincia}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-IdDistrito">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-IdDistrito" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-IdDistrito'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-IdDistrito")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Distrito de nacimiento:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.Distrito}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-IdUbigeoNacimiento">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-IdUbigeoNacimiento" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-IdUbigeoNacimiento'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-IdUbigeoNacimiento")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Ubigeo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.Ubigeo}</p>}
                        </label>
                    </div>
                    <div className='col-12 col-sm-6 col-md-4' id="ficha-view-datosDeNacimiento-DescripcionUbigeo">
                        <div className='col-6 col-sm-6 col-md-6 col-form-label text-right ficha-info-div-label'>
                            <p data-tip data-for="ficha-view-datosDeNacimiento-tooltip-DescripcionUbigeo" className="ficha-info-div-clock-font-awesome">
                                <FontAwesomeIcon icon='clock' transform="left-9" className="hand-pointer change-requested-field-clock" hidden={true}/>
                            </p>
                            <ReactTooltip className="tooltip-style tooltip-change-witdh tooltip-with-buttons" id={'ficha-view-datosDeNacimiento-tooltip-DescripcionUbigeo'} effect="solid" place="top"
                                          getContent={() => {
                                              return getHTMLContentForCancelarSolicitudDeCampo("ficha-view-datosDeNacimiento-tooltip-cancelar-button-DescripcionUbigeo")
                                          }}/>
                            <label className="ficha-info-label-width">
                                Descripción de Ubigeo:
                            </label>
                        </div>
                        <label className='col-6 col-sm-6 col-md-6 col-form-label'>
                            {datosDeNacimiento && <p>{datosDeNacimiento.DescripcionUbigeo}</p>}
                        </label>
                    </div>
                </div>
                <div style={{display: 'flex', 'justifyContent': 'flex-end', 'marginTop':'20px'}}>
                    <div style={{'alignSelf': 'flex-end'}}>
                        {datosDeNacimiento && datosDeNacimiento.FileViewModel &&
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                downloadFile(state.token, datosDeNacimiento.FileViewModel.Path, datosDeNacimiento.FileViewModel.OriginalFileName);
                            }}
                            type='button'
                            className='btn btn-primary generic-button-ficha'>
                            Descargar DNI/CE
                        </button>
                        }
                        {datosDeNacimiento && datosDeNacimiento.CanSeeButtonSolicitarCambios === true &&
                        <button
                            onClick={() => showSolicitarCambiosModal(MENUS_FICHA_DATOS.datosPersonales.secciones.DatosDeNacimiento.id)}
                            type='button'
                            className='btn btn-primary ficha-form-button-SolicitarCambios'>
                            Solicitar cambios
                        </button>
                        }
                        {datosDeNacimiento && datosDeNacimiento.CanSeeButtonEditar === true &&
                        <button
                            onClick={actualizar}
                            type='button'
                            className='btn btn-primary ficha-form-button-actualizar'>
                            Actualizar
                        </button>
                        }
                    </div>
                </div>
            </form>
        </>
    );
};

export default SeccionViewDatosDeNacimiento;