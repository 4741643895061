import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "../../../hooks/useForm/UseForm";
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {isEmpty, parseDateInputStringValueToDate} from "../../../helper/utils";
import {updateValidationMessage} from "../../../validations/FormValidatorUtils";
import {toast} from "react-toastify";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {deleteFileFromS3API, generarOfisisFileAPI} from "../../../consumers/backendApisUrls";
import {downloadFile} from "../../../consumers/backendConsumer";

const DescargarOfisisModal = ({showModal}) => {
    const sectionName = "modalDescargarOfisis";

    const {state} = useContext(AuthContext);
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const validateMandatoryField = function (keyField, inputValue, nroCamposInvalidos) {
        if (isEmpty(inputValue)) {
            updateValidationMessage(keyField, "Este campo es obligatorio", sectionName);
            nroCamposInvalidos++;
        } else {
            updateValidationMessage(keyField, "", sectionName);
        }
        return nroCamposInvalidos;
    };

    const formularioEsValido = function () {
        let nroCamposInvalidos = 0;
        let fechaDesdeTextValue = formValues["fechaDesde"];
        let fechaHastaTextValue = formValues["fechaHasta"];
        nroCamposInvalidos = validateMandatoryField("fechaDesde", fechaDesdeTextValue, nroCamposInvalidos);
        nroCamposInvalidos = validateMandatoryField("fechaHasta", fechaHastaTextValue, nroCamposInvalidos);

        if (fechaDesdeTextValue && fechaHastaTextValue) {
            let fechaDesdeDateValue = parseDateInputStringValueToDate(fechaDesdeTextValue);
            let fechaHastaDateValue = parseDateInputStringValueToDate(fechaHastaTextValue);

            if (fechaDesdeDateValue > fechaHastaDateValue) {
                toast.error("'Fecha Desde' no puede ser mayor a 'Fecha Hasta'");
                nroCamposInvalidos++;
            }
        }

        return nroCamposInvalidos === 0;
    };


    const descargarOfisis = async () => {
        let fomularioValido = formularioEsValido();

        console.debug("fomularioValido: ", fomularioValido);

        if (fomularioValido === true) {
            let payload = {
                "FechaDesde": formValues["fechaDesde"],
                "FechaHasta": formValues["fechaHasta"]
            };
            console.debug("payload: ", payload);

            await executeDownloadOfisis(payload);
            showModal();
        }
    };

    const executeDownloadOfisis = async (payload) => {
        console.debug("executeDownloadOfisis");
        let generarReporteResponse = await executePostRequest(generarOfisisFileAPI(), payload);
        if (responseCode200(generarReporteResponse)) {
            await descargarReporteYEliminarArchivoDeS3(generarReporteResponse);
        } else {
            mostrarMensajeDeErrorConToast(generarReporteResponse);
        }
    };

    const descargarReporteYEliminarArchivoDeS3 = async function (generarReporteResponse) {
        let originalFileName = generarReporteResponse.data.OriginalFileName;
        let filePath = generarReporteResponse.data.FilePath;
        let descargarReporteResponse = await downloadFile(state.token, filePath, originalFileName);
        if (responseCode200(descargarReporteResponse)) {
            await eliminarArchivoDeS3(filePath);
        } else {
            mostrarMensajeDeErrorConToast(descargarReporteResponse);
        }
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };


    return (
        <>
            <GenericModal showModal={showModal} width={"500px"} titulo={"Descargar Ofisis"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Fecha Desde<span className="required">*</span></label>
                            <div className="col-sm-8">
                                <input type="date" className="form-control" name="fechaDesde" onChange={handleChange}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-fechaDesde-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label modal-label">Fecha Hasta<span className="required">*</span></label>
                            <div className="col-sm-8">
                                <input type="date" className="form-control" name="fechaHasta" onChange={handleChange}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-fechaHasta-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer" style={{'display': 'flex', 'justify-content': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'align-self': 'flex-start'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button" style={{'align-self': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={descargarOfisis}>Descargar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default DescargarOfisisModal;

