import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import FormSelect from '../../components/atoms/Select';
import FormDatePicker from '../../components/atoms/DatePicker';
import PageHeader from '../../components/pageHeader/PageHeader';
import { deleteFileFromS3API, getAllReservasAPI, getEstadoReservaAPI, getMotivoReservaAPI, getTiposUbicacionReservaAPI, getUbicacionReservaAPI, DescargarReservasAPI } from '../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../consumers/httpRequiestsUtils';
import { getQueryParams, onKeyPressEnter, setStateWithCallBackResponse, getCurrentDate } from '../../helper/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../hooks/usePostRequest/usePostRequest';
import TableWebBody from './TableWebBody';
import Paginator from '../directorio/Paginator';
import Reserva from './modales/Reserva';
import { useHistory } from "react-router-dom";
import { ESTADOS } from '../../constants/hardCodedConstants';
import DetalleOcurrencia from './modales/DetalleOcurrencia';
import { downloadFile } from '../../consumers/backendConsumer';
import { AuthContext } from '../../hooks/context/authContext';
import { useGenerarDescargarEliminarArchivo } from "../ ../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo";

const GestionReservas = ({ location }) => {

    const { state } = useContext(AuthContext);

    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();

    const history = useHistory();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [filas, setFilas] = useState([]);
    const [extraData, setExtraData] = useState({});

    const [fechaReservaInicio, setFechaReservaInicio] = useState(null);
    const [fechaReservaFin, setFechaReservaFin] = useState(null);
    const [tipoUbicacionOptions, setTipoUbicacionOptions] = useState([]);
    const [tipoUbicacionSeleccionadas, setTipoUbicacionSeleccionadas] = useState([]);
    const [ubicacionOptions, setUbicacionOptions] = useState([]);
    const [ubicacionSeleccionadas, setUbicacionSeleccionadas] = useState([]);
    const [motivoOptions, setMotivoOptions] = useState([]);
    const [motivoSeleccionados, setMotivoSeleccionados] = useState([]);
    const [estadoOptions, setEstadoOptions] = useState([]);
    const [estadoSeleccionados, setEstadoSeleccionados] = useState([ESTADOS.PENDIENTE]);

    const [showModalReserva, setShowModalReserva] = useState(false);
    const [idReserva, setIdReserva] = useState();
    const [isEdit, setIsEdit] = useState(false);

    const [showModalOcurrencias, setShowModalOcurrencias] = useState(false);
    const [informacionOcurrencias, setInformacionOcurrencia] = useState([]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
            showModalTriajeFromLink();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        manageSearch();
    }, [tipoUbicacionSeleccionadas, ubicacionSeleccionadas, motivoSeleccionados, estadoSeleccionados, page]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await setStateWithCallBackResponse(executeGetRequestCallback(getTiposUbicacionReservaAPI()), setTipoUbicacionOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getUbicacionReservaAPI()), setUbicacionOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getMotivoReservaAPI()), setMotivoOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getEstadoReservaAPI()), setEstadoOptions);
            await manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const showModalTriajeFromLink = () => {
        let queryParams = getQueryParams(location.search);
        if (queryParams?.idReserva) {
            setIdReserva(queryParams.idReserva);
            toggleReserva();
        }
    };

    const manageSearch = async () => {
        var payload = obtenerPayload();
        const response = await executePostRequest(getAllReservasAPI(), payload);
        if (responseCode200(response)) {
            setFilas(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
            setExtraData(response.data.ExtraData);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const obtenerPayload = () => {
        let payload = {
            "FechaInicio": fechaReservaInicio,
            "FechaFin": fechaReservaFin,
            "Criteria": criterioDeBusqueda,
            "IdsTipoUbicacion": tipoUbicacionSeleccionadas,
            "IdsUbicacion": ubicacionSeleccionadas,
            "IdsMotivoAsistencia": motivoSeleccionados,
            "IdsEstado": estadoSeleccionados,
            "Page": page
        };
        return payload;
    }

    const limpiarBusqueda = () => {
        setCriterioDeBusqueda("");
        setTipoUbicacionSeleccionadas([]);
        setUbicacionSeleccionadas([]);
        setMotivoSeleccionados([]);
        setEstadoSeleccionados([]);
    };

    const toggleReserva = () => {
        if (showModalReserva) {
            setIdReserva(null);
        }
        if (showModalReserva && getQueryParams(location.search)?.idReserva) {
            history.push("/gestionReservas");
        }
        setShowModalReserva(!showModalReserva);
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const toggleModalOcurrencias = () => {
        setShowModalOcurrencias(!showModalOcurrencias);
    }

    const searchByFechas = () => {
        manageSearch();
    }

    const downloadReservas = async () => {
        var payload = obtenerPayload();
        await generarDescargarEliminarArchivo(DescargarReservasAPI(), payload);
    }

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
        );
    }

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Esta página no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">
                {showModalReserva &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <Reserva showModal={toggleReserva} refreshPage={manageSearch} idReserva={idReserva} setIdReserva={setIdReserva} location={location} isEdit={isEdit} />
                    </div>
                }
                {showModalOcurrencias &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <DetalleOcurrencia
                            showModal={toggleModalOcurrencias}
                            informacionOcurrencias={informacionOcurrencias} />
                    </div>
                }
                <Wrapper className='App'>
                    <PageHeader title={"Gestión de Reservas"} />
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <input
                                type='text'
                                className='generic-input-criterio-busqueda'
                                placeholder='Buscar por nombre'
                                value={criterioDeBusqueda}
                                onChange={(e) => setCriterioDeBusqueda(e.target.value)}
                                onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}
                            />
                            <button
                                onClick={manageSearch}
                                type='button'
                                className='generic-button-buscador'>
                                Buscar
                            </button>
                            <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                        </div>
                        <div className="filtros-right-section">
                            <button
                                onClick={toggleReserva}
                                type='button'
                                className='generic-button-buscador'>
                                Nueva Reserva
                            </button>
                            {extraData && extraData.TienePermisosAdministrador && <button
                                onClick={downloadReservas}
                                type='button'
                                className='generic-button-buscador'>
                                Descargar Reservas
                            </button>}
                        </div>
                    </div>
                    <div className='container-fluid body-content'>
                        <div className='table-responsive form-group-sm pt-3'>
                            <table className='table table-hover'>
                                <thead className="listar-thead">
                                    <tr>
                                        <th className="col-1">
                                            <div className='flex font-gothan-medium'>Responsable</div>
                                        </th>
                                        <th className="col-1">
                                            <FormDatePicker
                                                description={'Fecha de reserva'}
                                                fechaInicio={fechaReservaInicio}
                                                fechaFin={fechaReservaFin}
                                                setFechaInicio={setFechaReservaInicio}
                                                setFechaFin={setFechaReservaFin}
                                                searchByFechas={searchByFechas}
                                            />
                                        </th>
                                        <th className="col-1">
                                            <FormSelect
                                                options={tipoUbicacionOptions}
                                                description={'Tipo ubicación'}
                                                setSelectedOptions={setTipoUbicacionSeleccionadas}
                                                selectedOptions={tipoUbicacionSeleccionadas}
                                            />
                                        </th>
                                        <th className="col-1">
                                            <FormSelect
                                                options={ubicacionOptions}
                                                description={'Ubicación'}
                                                setSelectedOptions={setUbicacionSeleccionadas}
                                                selectedOptions={ubicacionSeleccionadas}
                                            />
                                        </th>
                                        <th className="col-1">
                                            <FormSelect
                                                options={motivoOptions}
                                                description={'Motivo'}
                                                setSelectedOptions={setMotivoSeleccionados}
                                                selectedOptions={motivoSeleccionados}
                                            />
                                        </th>
                                        <th className="col-1">
                                            <FormSelect
                                                options={estadoOptions}
                                                description={'Estado'}
                                                setSelectedOptions={setEstadoSeleccionados}
                                                selectedOptions={estadoSeleccionados}
                                            />
                                        </th>
                                        <th className="col-1">
                                            <div className='flex font-gothan-medium'>Ocurrencia</div>
                                        </th>
                                        <th className="col-1">
                                            <div className='flex font-gothan-medium'>Acciones</div>
                                        </th>
                                    </tr>
                                </thead>
                                <TableWebBody filas={filas}
                                    showModalReserva={toggleReserva} setIdReserva={setIdReserva} setIsEdit={setIsEdit}
                                    showModalOcurrencias={toggleModalOcurrencias} setInformacionOcurrencia={setInformacionOcurrencia}
                                    refreshPage={manageSearch} />
                            </table>
                            {totalPages > 1 && (
                                <Paginator totalItems={totalItems}
                                    firsItemPage={firsItemPage}
                                    lastItemPage={lastItemPage}
                                    totalPages={totalPages}
                                    page={page}
                                    onLeftClic={onLeftClic}
                                    onRightClic={onRightClic} />
                            )}
                        </div>
                    </div>
                </Wrapper>
            </div >
        </>
    );
}

export default GestionReservas;