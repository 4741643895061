import React from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { eliminarConfiguracionVacacionesAPI } from '../../../../consumers/backendApisUrls';

const EliminarConfiguracionVacaciones = ({showModal, refreshPage, idConfiguracion, idnivelAprobacion}) => {

  const [managePost] = useManagePostRequest();

  const handleEliminarConfiguracion = async() => {
    await managePost(eliminarConfiguracionVacacionesAPI(idConfiguracion), {}, () => {
        refreshPage();
        showModal();
    })
  }

  return (
    <GenericModal showModal={showModal} width={"620px"} titulo={"Eliminar configuración vacaciones"}>
        <div className="modal-body pt-0 pl-0">
            <div className="container-fluid">
                Se va a eliminar la siguiente configuración con nivel de aprobación:
                <ul>
                    <li><strong>{idnivelAprobacion}</strong></li>
                </ul>
                ¿Está seguro de esta acción?
            </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
            <div className="button">
                <button className="btn btn-primary generic-button-ficha mr-2"
                    onClick={() => {showModal()}}
                >
                    Cancelar
                </button>
                <button className="btn btn-primary generic-button-ficha"
                    onClick={() => {handleEliminarConfiguracion()}}>
                    Eliminar
                </button>
            </div>
        </div>
    </GenericModal>
  )
}

export default EliminarConfiguracionVacaciones