import React from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { EliminarGrupoAsignadoCallAPI } from '../../../../consumers/backendApisUrls';

const EliminarGrupoAsignadoComponente = ({
    toggleModal,
    refreshPage,
    componenteName,
    componenteId
}) => {
    const [managePost] = useManagePostRequest();

    const EliminarGrupoAsignadoComponente = async () => {
        await managePost(EliminarGrupoAsignadoCallAPI(componenteId), {}, () => {
            refreshPage();
            toggleModal();
        }, true);
    }

    return (
        <GenericModal titulo={"Eliminar grupo asginado"} showModal={toggleModal} width={"620px"}>
            <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    ¿Seguro que deseas eliminar el grupo {componenteName}?
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-end">
                <div className="button">
                    <button 
                        className="btn btn-primary generic-button-ficha mr-2"
                        onClick={EliminarGrupoAsignadoComponente}>
                        Eliminar
                    </button>
                    <button
                        className='btn btn-primary generic-button-ficha'
                        onClick={() => {toggleModal()}} >
                        Cancelar
                    </button>
                </div>
            </div>
        </GenericModal>
    )
}

export default EliminarGrupoAsignadoComponente