import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditarListaDocumentos from './modales/EditarListaDocumentos'
import ModalActivarInactivarGeneric from '../../../components/modalActivarInactivar/ModalActivarInactivarGeneric'
import { activarInactivarConfiguracionListaDocumentosAPI } from '../../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled }) => {
  return (
    <tbody className="listar-tbody">
      {filas.map((fila,i)=> (
        <tr key={i}>
          <td className="pl-3 ml-3">{fila?.IdNuevosIngresosLista}</td>
          <td className="pl-1 ml-3">{fila?.NombreLista}</td>
          <td className="pl-1 pr-1">{fila?.Estado}</td>
          <td className="pl-1 pr-3">
            {!fila.IsBlocked &&
              <div className="d-flex justify-content-start">
                <div>
                  <FontAwesomeIcon
                    icon={fila.EsActivo ? 'ban' : 'circle-notch'}
                    size="sm"
                    className='hand-pointer'
                    title={fila.EsActivo ? 'Inactivar': 'Activar'}
                    onClick={() => setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
                        data: {api: activarInactivarConfiguracionListaDocumentosAPI, id: fila.IdNuevosIngresosLista, title: "documento", name: fila.NombreLista, state: fila.EsActivo}})}
                  />
                </div>
                {fila.EsActivo &&
                  <div>
                    <FontAwesomeIcon 
                      icon='edit'
                      size='sm'
                      className="hand-pointer ml-3"
                      title="Editar"
                      onClick={() => setModalEnabled({isEnable: true, component: EditarListaDocumentos, 
                              data: {listaId: fila.IdNuevosIngresosLista}})}
                    />
                  </div>
                }
              </div>
            }
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody