import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DirectorioWrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import { AuthContext } from "../../hooks/context/authContext";
import { getAllEstadosAPI, listarCapacitacionesAPI } from "../../consumers/backendApisUrls";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import { useManageGetRequest } from "../../hooks/useManageGetRequest/useManageGetRequest";
import { useGetRequest } from "../../hooks/useGetRequest/useGetRequest";
import CapacitacionModal from './modales/CapacitacionModal/CapacitacionModal';
import Paginator from '../directorio/Paginator';
import { updateOptionsDeFiltro } from '../../helper/Listas/ListasUtils';
import FormSelect from '../../components/atoms/Select';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { downloadFile } from '../../consumers/backendConsumer';

const MisCapacitaciones = () => {
    const [loading, setLoading] = useState(true);
    const { state } = useContext(AuthContext);
    const [managePostRequest] = useManagePostRequest();
    const [manageGetRequest] = useManageGetRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();


    const [filas, setFilas] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);
    const [resultadosSeleccionadas, setResultadosSeleccionadas] = useState([]);

    const [allEstados, setAllEstados] = useState([]);
    const [allResultados, setAllResultados] = useState([]);

    const [allEstadosFiltrados, setAllEstadosFiltrados] = useState([]);
    const [allResultadosFiltrados, setAllResultadosFiltrados] = useState([]);

    const [listarData, setListarData] = useState([]);
    const [hideCapacitacionModal, setHideCapacitacionModal] = useState(true);
    const [reload, setReload] = useState(false);

    const [idItem, setIdItem] = useState(0);
    const history = useHistory();

    useDidMountEffect(() => {
        manageSearch();
    }, [
        estadosSeleccionados,
        resultadosSeleccionadas,
        criterioDeBusqueda
    ]);

    useDidMountEffect(() => {
        setPage(1);
    }, [
        estadosSeleccionados,
        resultadosSeleccionadas,
        criterioDeBusqueda
    ]);

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, [reload]);

    const getInitialData = async () => {
        await manageSearch();
    };

    const obtenerPayload = function () {
        let payload = {
            "Criteria": criterioDeBusqueda,
            "IdsEstados": estadosSeleccionados,
            "IdsEstadosAprobado": resultadosSeleccionadas,
            "Page": page,
        };
        return payload;
    }

    const executeSearch = async (payload) => {
        try {
            await managePostRequest(listarCapacitacionesAPI(), payload, listarSucessCallback, false);
        } catch (e) {
            console.error(e);
        }
    };

    const manageSearch = async () => {
        setLoading(true);
        let payload = obtenerPayload();
        await executeSearch(payload);
    };

    const updateOptionsDeFiltros = async function (lists) {
        await updateOptionsDeFiltro(allEstados, lists["IdsEstados"], setAllEstadosFiltrados, executeGetRequestCallback(getAllEstadosAPI()));
        await updateOptionsDeFiltro(allResultados, lists["IdsEstadosAprobado"], setAllResultadosFiltrados, executeGetRequestCallback(getAllEstadosAPI()));
        setLoading(false);
    };

    const listarSucessCallback = async function (response) {
        setListarData(response.data);
        setFilas(response.data.Items);
        setTotalItems(response.data.TotalItems);
        setFirsItemPage(response.data.FirsItemPage);
        setLastItemPage(response.data.LastItemPage);
        setTotalPages(response.data.TotalPages);
        await updateOptionsDeFiltros(response.data.ExtraData);
    }


    const toggleCapacitacionModal = (idItem) => {
        setHideCapacitacionModal(!hideCapacitacionModal);
        setIdItem(idItem);
    };

    const callbackSuccess = () => {
        setReload(!reload);
    };

    const limpiarBusqueda = () => {
        setEstadosSeleccionados([]);
        setResultadosSeleccionadas([]);
    };

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    return (
        <DirectorioWrapper className='App'>
            <PageHeader title={"Mis Capacitaciones"} />
            <div className='container-fluid body-content'>

                {hideCapacitacionModal === false &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <CapacitacionModal showModal={toggleCapacitacionModal} setReload={callbackSuccess} idCursoCapacitacion={idItem} />
                    </div>
                }

                <div className="generic-buscador-container">
                    <div className="filtros-left-section">
                        <input
                            type='text'
                            className='generic-input-criterio-busqueda'
                            placeholder='Buscar por nombre'
                            value={criterioDeBusqueda}
                            onChange={(e) => {
                                setCriterioDeBusqueda(e.target.value);
                            }}
                            onKeyPress={handleKeypress}
                        />
                        <button
                            onClick={manageSearch}
                            type='button'
                            className='generic-button-buscador'>
                            Buscar
                        </button>
                        <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                    </div>
                </div>
                <div className='pt-1'>
                    <div className="table-scroll">
                    <table className='table table-hover listar-table-cierre-anio'>
                        <thead className="listar-thead">
                            <tr>
                                <th>
                                    <span className='font-gothan-medium d-inline'>Nombre del curso</span>
                                </th>
                                <th>
                                    <span className='font-gothan-medium d-inline'>Fecha Fin</span>
                                </th>
                                <th>
                                    <FormSelect
                                        description={'Estado'}
                                        options={allEstadosFiltrados}
                                        setSelectedOptions={setEstadosSeleccionados}
                                        selectedOptions={estadosSeleccionados}
                                    />
                                </th>
                                <th>
                                    <FormSelect
                                        description={'Resultado'}
                                        options={allResultadosFiltrados}
                                        setSelectedOptions={setResultadosSeleccionadas}
                                        selectedOptions={resultadosSeleccionadas}
                                    />
                                </th>
                                <th>
                                    <span className='font-gothan-medium d-inline'>Calificación</span>
                                </th>
                                <th>
                                    <span className='font-gothan-medium d-inline'>Acciones</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="listar-tbody">
                            {
                                filas && filas.map(
                                    (item, i) => (
                                        <tr key={item.IdCursoCapacitacion}>
                                            <td>{item?.NombreCurso}</td>
                                            <td>{item?.EndDate}</td>
                                            <td>{item?.Estado}</td>
                                            <td>{item?.EstadoAprobado}</td>
                                            <td>{item?.Calificacion}</td>
                                            <td>
                                                <div className="d-flex justify-content-start">

                                                    {
                                                        (item?.File) &&
                                                        <FontAwesomeIcon icon='paperclip' className="hand-pointer ml-3" title="Descargar" onClick={(e) => {
                                                            e.preventDefault();
                                                            downloadFile(state.token, item?.File?.Path, item?.File?.OriginalFileName);
                                                        }}
                                                        />
                                                    }

                                                    {
                                                        item?.Puedejecutar &&
                                                        <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Ver Curso" onClick={() => toggleCapacitacionModal(item?.IdCursoCapacitacion)} />
                                                    }

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )
                            }

                        </tbody>
                    </table>
                    </div>
                    {totalPages > 0 && (
                        <Paginator totalItems={totalItems}
                            firsItemPage={firsItemPage}
                            lastItemPage={lastItemPage}
                            totalPages={totalPages}
                            page={page}
                            onLeftClic={onLeftClic}
                            onRightClic={onRightClic} />
                    )}
                </div>
            </div>
        </DirectorioWrapper>
    )
}

export default MisCapacitaciones;
