import React, { useState, useEffect, useContext } from "react";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import CustomAlert from "../../../components/alerts/CustomAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import AgregarFormacionProfesional from "./Modales/AgregarFormacionProfesional";
import EliminarFormacionProfesional from "./Modales/EliminarFormacionProfesional";
import {
  getAllFormacionProfesionalAPI,
  editarMayorGradoInstruccionAPI,
} from "../../../consumers/backendApisUrls";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { responseCode200 } from "../../../consumers/httpRequiestsUtils";
import { downloadFile } from "../../../consumers/backendConsumer";
import { AuthContext } from "../../../hooks/context/authContext";
import ReactTooltip from "react-tooltip";

const FormacionProfesional = ({ empleadoId, puedoEditar }) => {
  const { state } = useContext(AuthContext);
  const [idActualizado, setId] = useState();
  const defaultAlertMessage =
    "Para validar tus actualizaciones te pediremos adjuntar un archivo PDF o imagen. ¡Tenlo a mano!";
  const [executeGet] = useGetRequest();
  const [submitExecute, setSubmitExecute] = useState(false);
  const [executeManagePost] = useManagePostRequest();

  const [allFormacionProfesional, setAllFormacionProfesional] = useState([]);
  const [hideModalAgregar, setHideModalAgregar] = useState(false);
  const [hideModalEditar, setModalEditar] = useState(false);
  const [hideModalDetalle, setHideModalDetalle] = useState(false);
  const [hideModalEliminar, setHideModalEliminar] = useState(false);
  const [formacionProfesional, setFormacionProfesional] = useState({});
  const [isEditar, setIsEditar] = useState(false);

  function updateId() {
    if (empleadoId) setId(empleadoId);
    else setId(state.user.id_empleado);
  }

  const init = async () => {
    try {
      if (idActualizado) {
        await getInitialData();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getInitialData = async () => {
    const api = getAllFormacionProfesionalAPI(empleadoId);
    const response = await executeGet(api);
    if (responseCode200(response)) {
      let empleadoFormacion = response?.data;
      setAllFormacionProfesional(empleadoFormacion);
    }
  };

  const toggleAgregarFormacionProfesionalModal = () => {
    setHideModalAgregar(!hideModalAgregar);
    setIsEditar(false);
  };

  const toggleEditarFormacionProfesionalModal = (row) => {
    setModalEditar(!hideModalEditar);
    setFormacionProfesional(row);
    setIsEditar(true);
  };

  const toggleDetalleFormacionProfesionalModal = (row) => {
    setHideModalDetalle(!hideModalDetalle);
    setFormacionProfesional(row);
    setIsEditar(true);
  };

  const toggleEliminarFormacionProfesionalModal = (row) => {
    setHideModalEliminar(!hideModalEliminar);
    setFormacionProfesional(row);
  };

  const handleDownloadFile = (e, row) => {
    e.preventDefault();
    downloadFile(
      state.token,
      row.SustentoFile?.FilePath,
      row.SustentoFile?.OriginalFileName
    );
  };

  const handleMayorGradoInstruccion = async (e, row) => {
    e.preventDefault();
    let api = editarMayorGradoInstruccionAPI(
      row.IdEmpleadoFormacionProfesional
    );
    await executeManagePost(api, {}, () => {
      init();
    });
  };

  useDidMountEffect(() => {
    updateId();
  }, [empleadoId]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      updateId();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    init();
  }, [idActualizado]);

  useEffect(() => {
    if (submitExecute) {
      init();
      setSubmitExecute(false);
    }
  }, [submitExecute]);

  return (
    <>
      {hideModalAgregar && (
        <div className="modal-contactanos modal-response-ficha-background">
          <AgregarFormacionProfesional
            toggleModal={toggleAgregarFormacionProfesionalModal}
            empleadoId={idActualizado}
            setSubmitExecute={setSubmitExecute}
          />
        </div>
      )}
      {hideModalEditar && (
        <div className="modal-contactanos modal-response-ficha-background">
          <AgregarFormacionProfesional
            toggleModal={toggleEditarFormacionProfesionalModal}
            empleadoId={idActualizado}
            setSubmitExecute={setSubmitExecute}
            isEditar={isEditar}
            formacionProfesional={formacionProfesional}
            setFormacionProfesional={setFormacionProfesional}
          />
        </div>
      )}
      {hideModalEliminar && (
        <div className="modal-contactanos modal-response-ficha-background">
          <EliminarFormacionProfesional
            toggleModal={toggleEliminarFormacionProfesionalModal}
            setSubmitExecute={setSubmitExecute}
            formacionProfesional={formacionProfesional}
            setFormacionProfesional={setFormacionProfesional}
          />
        </div>
      )}

      {hideModalDetalle && (
        <div className="modal-contactanos modal-response-ficha-background">
          <AgregarFormacionProfesional
            toggleModal={toggleDetalleFormacionProfesionalModal}
            empleadoId={idActualizado}
            setSubmitExecute={setSubmitExecute}
            isEditar={isEditar}
            formacionProfesional={formacionProfesional}
            setFormacionProfesional={setFormacionProfesional}
            puedoEditar={puedoEditar}
          />
        </div>
      )}


      <div>
        <CustomAlert variant={"warning"}>
          <p className="ficha-secciones-title-text font-gothan-light">
            {defaultAlertMessage}&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon
              icon="info-circle"
              transform="left-9"
              className="hand-pointer"
            />
          </p>
          {!allFormacionProfesional.some(x => x) &&
            <p className="ficha-secciones-title-text font-gothan-light">Haz clic en agregar para comenzar a completar tu registro</p>
          }
        </CustomAlert>
        <div className="card-body mt-n3">

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary generic-button-buscador mb-3 mr-3"
              onClick={toggleAgregarFormacionProfesionalModal}
              disabled={!puedoEditar}
            >
              Agregar
            </button>
          </div>

          <div className="d-flex justify-content-start flex-column">
            {allFormacionProfesional &&
              allFormacionProfesional.map((o, index) => (
                <div className="d-flex mb-5" key={index + "FormacionProfesional"}>
                  <div className={`d-flex align-items-center justify-content-center mx-4 ${o.CanSelect ? "blue-standard-color" : "blue-standard-disabled-color"}`}>
                    <p
                      data-tip
                      data-for="informacion-mayor-grado-instruccion"
                      style={{ display: "inline" }}
                    >
                      <FontAwesomeIcon
                        icon={o.IsSelected ? ["fas", "star"] : faStar}
                        size="2x"
                        className={o.CanSelect ? "hand-pointer" : ""}
                        onClick={o.CanSelect ? (e) => handleMayorGradoInstruccion(e, o) : ""}
                      />
                    </p>
                    <ReactTooltip
                      id="informacion-mayor-grado-instruccion"
                      place="top"
                      effect="solid"
                      className="tooltip-style tooltip-change-witdh"
                    >
                      Seleccione la estrella en el mayor grado de instrucción
                    </ReactTooltip>
                  </div>
                  <div className="col-6 ml-2 d-flex flex-column datos-formacion-profesional">
                    <p className="mb-0 blue-standard-color">
                      {o.GradoObtenido
                        ? <>{o.GradoObtenido} en {o.NombreCarrera}</>
                        : o.NombreCarrera}
                    </p>
                    <p className="mb-0">
                      {o.NombreInstitucion}, {o.PaisInstitucion}
                    </p>
                    <p className="mb-0">
                      {o.NivelEstudio}, {o.EstadoFormacion}
                    </p>
                    <p className="mb-0">
                      {o.FechaInicio} - {o.FechaFin}
                    </p>
                  </div>
                  <div className="col-lg-1 col-xl-2"></div>
                  <div className="d-flex align-items-center blue-standard-color">
                    {o.SustentoFile && (
                      <FontAwesomeIcon
                        icon="paperclip"
                        size="1x"
                        className="hand-pointer mr-5"
                        title="Descargar"
                        onClick={(e) => {
                          handleDownloadFile(e, o);
                        }}
                      />
                    )}
                    <>

                      {!puedoEditar &&
                        <FontAwesomeIcon icon='eye'
                          size="1x"

                          className={"hand-pointer mr-5"}
                          title="Detalle"
                          onClick={() => {
                            if (puedoEditar === false)
                              toggleDetalleFormacionProfesionalModal(o);
                          }}
                        />
                      }

                      {puedoEditar &&
                        <FontAwesomeIcon
                          icon="edit"
                          size="1x"

                          className={puedoEditar === true ? "hand-pointer mr-5" : "blue-standard-disabled-color hand-pointer mr-5"}
                          title="Editar"
                          onClick={() => {
                            if (puedoEditar === true)
                              toggleEditarFormacionProfesionalModal(o);
                          }}
                        />}

                      <FontAwesomeIcon
                        icon="trash"
                        size="1x"
                        className={puedoEditar === true ? "hand-pointer" : "hand-pointer blue-standard-disabled-color"}
                        title="Eliminar"
                        onClick={() => {
                          if (puedoEditar === true)
                            toggleEliminarFormacionProfesionalModal(o);
                        }}
                      />
                    </>

                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FormacionProfesional;
