import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import ListaCuponesActivos from './ListaCuponesActivos';
import ModalForm from '../../../../../components/modalComponents/ModalForm';
import PaginatorV2 from '../../../../../components/pageComponents/PaginatorV2';
import ModalEnabled from '../../../../../components/modalComponents/ModalEnabled';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import SearchContainer from '../../../../../components/pageComponents/SearchContainer';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import { initialAllOptionPromises } from '../../../../../helper/utils';
import { crearCuponTiempoLibreAPI, getAllCargosAPI, getPlantillasCuponParaAsignarAPI } from '../../../../../consumers/backendApisUrls';

const AsignarCupones = ({ toggleModal: toggleMainModal, refreshPage: refreshMainPage, IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera }) => {

  const SECTION_NAME = "asignarPlantillasCupon";
  const INIT_KEYS = {Cargos: "IdsCargos"};
  const KEYS = {lineamientos: "LineamientosHTML", desde: "FechaInicio", hasta: "FechaFin", idsCargos: "IdsCargos"};

  const [cupones, setCupones] = useState([]);
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [cuponesSelected, setCuponesSelected] = useState([]);
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [searchCriteria, setSearchCriteria] = useState("");
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFirst, setIsFirst] = useState(true);

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeManagePost] = useManagePostRequest();

  const getInitial = () => {
    return [
      getOptions(getAllCargosAPI()),
    ]
  }

  const init = async () => {
    try {
      initialAllOptionPromises(getInitial, Object.values(INIT_KEYS), setAllDropdowns);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    setIsFetching(true);
    const api = getPlantillasCuponParaAsignarAPI();
    const payload = getPayload();
    
    try {
      await executeManagePost(api, payload, successManageSearchCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }

  const getPayload = () => {
    return {
      IdCuponeraTiempoLibre,
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage, Items} = data;
    if (Items.length === 0 && isFirst) {
      toast.warn("No hay ningún cupón activo para asignar");
      return toggleMainModal();
    }
    setIsFirst(false);
    const ItemsUpdated = Items.map(o => {
      const { Requerimientos } = o;
      const RequerimientosArray = Requerimientos.split(",");
      return {...o, Requerimientos: RequerimientosArray};
    })
    setCupones(ItemsUpdated);
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  const handleSelectedCupon = (idKey, { target }) => {
    const { name, value } = target;
    setCupones(lastData => {
      const updatedRows = lastData.map(o => {
        if (o.IdPlantillaCuponTiempoLibre === idKey){
          return {...o, [name]: value}
        }
        return o;
      });
      return updatedRows;
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearCuponTiempoLibreAPI();
    let cuponSended = 0;
    const cuponesSelected = cupones.filter(o => o.isRequirementsVisible);
    
    try {
      if (cuponesSelected.length === 0) return toast.warn("Debe seleccionar al menos un cupón");
      const allSubmitted = await Promise.allSettled(
        cuponesSelected.map(async o => {          
          const payload = mapCuponToPayload(o);
          await submitForm(Object.values(KEYS), api, payload, `${SECTION_NAME}-${o.IdPlantillaCuponTiempoLibre}`, () => {cuponSended += 1});
        })
      );
      if (allSubmitted.every(o => o.status === "fulfilled" && cuponSended > 0)){
        await refreshMainPage();
        toggleMainModal();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const mapCuponToPayload = (cupon) => {
    const { IdPlantillaCuponTiempoLibre, LineamientosHTML, FechaInicio, FechaFin, IdsCargos } = cupon;
    return { IdCuponeraTiempoLibre, IdPlantillaCuponTiempoLibre, LineamientosHTML, FechaInicio, FechaFin, IdsCargos };
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <ModalForm hasSaveButton={false} titulo="Asignar cupones" showModal={toggleMainModal} width="1200px" isSubmitting={isSubmitting}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={refreshMainPage} />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} />
      <ListaCuponesActivos rows={cupones} setRows={setCupones} dropdowns={allDropdowns} handleSelectedCupon={handleSelectedCupon} setModalEnabled={setModalEnabled} toggleModal={toggleMainModal} IdCuponeraTiempoLibre={IdCuponeraTiempoLibre} fechaInicioCuponera={fechaInicioCuponera} fechaFinCuponera={fechaFinCuponera} isFetching={isFetching}/>
      <div className='px-5 mb-4'><PaginatorV2 paginator={paginator} setPaginator={setPaginator} manageSearch={manageSearch}/></div>
    </ModalForm>
  )
}

export default AsignarCupones