import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { saveProcesoSolicitudDescuentoPensionAPI } from '../../../../consumers/backendApisUrls';

const NuevaConfiguracion = ({ toggleModal, refreshPage, setProcesosSolicitud, getProcesos }) => {

  const SECTION_NAME = "nuevaConfiguracionSolcitudDEscuento"
  const KEYS = { nombre: "Nombre", desde: "FechaInicio", hasta: "FechaFin", archivo: "FileViewModel" };

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = saveProcesoSolicitudDescuentoPensionAPI();

    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
    }
  }

  const handleSuccessSubmit = () => {
    setTimeout(() => {
      toggleModal();
      getProcesos({ isNew: false });
    }, 1000);
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, [KEYS.archivo]: res}));
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo='Nueva Configuración de Solicitud' width='750px' saveButtonDisabled={isUploading} isSubmitting={isSubmitting}>
      <Div col={12}><InputText label='Nombre' {...commonProps(KEYS.nombre)} direction='column'/></Div>
      <label className='ml-3 mb-3'>Vigencia</label>
      <div className='displayFlexWrap'>
        <Div col={6}><InputDate label='Desde' {...commonProps(KEYS.desde)}/></Div>
        <Div col={6}><InputDate label='Hasta' {...commonProps(KEYS.hasta)}/></Div>
      </div>
      <Div col={12}><InputUploadFile label='Lista de Matriculados' uploadFile={formValues[KEYS.archivo]} setUploadFile={handleUploadFile} id="uploadNuevaConfiguracionSolcitudDEscuento" validExtensions={["xls", "xlsx"]} sectionName={SECTION_NAME} name={KEYS.archivo} isUploading={isUploading} setIsUploading={setIsUploading}/></Div>
    </ModalForm>
  )
}

export default NuevaConfiguracion

const Div = ({ col, children }) => (
  <div className={`col-sm-${col}`}>
    {children}
  </div>
)