import React, { useState, useRef, useEffect } from 'react'

const Stepper = ({ steps, currentStepNumber, setCurrentStepNumber, isObservated }) => {
	const [stepperSteps, setStep] = useState([]);
	const stepsStateRef = useRef();

	useEffect(() => {
		const stepsState = steps.map((step, index) => {
			const stepObj = {};
			stepObj.description = step;
			stepObj.completed = false;
			stepObj.highlighted = index === 0 ? true : false;
			stepObj.selected = index === 0 ? true : false;
			return stepObj;
		});
		stepsStateRef.current = stepsState;
		const currentSteps = updateStep(currentStepNumber - 1, stepsState)
		setStep(currentSteps)
	}, []);

	useEffect(() => {
		const currentSteps = updateStep(currentStepNumber - 1, stepsStateRef.current)
		setStep(currentSteps)
	}, [currentStepNumber]);

	function updateStep(stepNumber, steps) {
		const newSteps = [...steps];
		let stepCounter = 0;
		while (stepCounter < newSteps.length) {
			if (stepCounter === stepNumber) {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: true,
					selected: true,
					completed: false
				};
				stepCounter++;
			}
			else if (stepCounter < stepNumber) {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: false,
					selected: true,
					completed: true
				};
				stepCounter++;
			}
			else {
				newSteps[stepCounter] = {
					...newSteps[stepCounter],
					highlighted: false,
					selected: false,
					completed: false
				}
				stepCounter++;
			}
		}
		return newSteps
	}

	const handleClickStep = (step, index) => {
		if(isObservated) setCurrentStepNumber(index + 1);
	}

	const stepsDisplay = stepperSteps.map((step, index) => {
		return (
			<div key={index}
				className={`d-flex align-items-center ${index !== stepperSteps.length - 1 ? "w-100 " : ""}`} >
				<div className={`position-relative d-flex flex-column align-items-center ${isObservated ? "hand-pointer" : ""}`} style={{color: "#8DBA38"}} onClick={() => handleClickStep(step, index)}>
					<div className={`rounded-circle d-flex align-items-center justify-content-center py-3 ${step.selected ? "green-innova-background text-white font-weight-bold" : ""}`} style={{border: "2px solid #8DBA38", height: "48px", width: "48px"}}>
						{/* {step.completed ? <span className="text-white font-weight-bold">✓</span> : index + 1} */}
						{ index + 1 }
					</div>
					<div className={`position-absolute top-0 text-center mt-5 ${step.highlighted ? "font-weight-bold" : "font-weight-normal"}`} style={{fontSize: "14px"}}>{step.description}</div>
				</div>
				<div className="" style={{flex: "auto", borderTop: "2px solid gray", transition: "500", transitionTimingFunction: "ease-in-out"}}> </div>
			</div>
		)
	})

	return (
		<div className="mx-4 mb-5 p-4 d-flex justify-content-between align-items-center">
			{stepsDisplay}
		</div>
	)
}

export default Stepper