import React from 'react'

const TableWebBody = ({ rows }) => {
  return (
    <tbody className='listar-tbody'>
      {rows.map((row, i) => (
        <tr key={`${row.IdSolicitud}-${i}`}>
          <td className=''>
            <div className='d-flex flex-column'>
              <label className='mb-0'>{row.NombreColaborador}</label>
              <label className='mb-0'>{row.TipoDocumento}</label>
              <label className='mb-0'>{row.NumeroDocumento}</label>
            </div>
          </td>
          <td className=''>{row.Cargo}</td>
          <td className=''>{row.Sede}</td>
          <td className=''>{row.Area}</td>
          <td className='text-center'>{row.FechaInicio}</td>
          <td className='text-center'>{row.FechaFin}</td>
          <td className='text-center'>{row.CantDias}</td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody