import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, stringSeEncuentraEnArray} from '../../helper/utils';
import {saveEjecutarProcesoEmpleadoAPI} from "../../consumers/backendApisUrls";
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest";
import GenericTooltip from "../../components/GenericTooltip/GenericTooltip";

const TableWebBody = ({
                          filas,
                          toggleDetalleModal,
                          eliminar,
                          idsFichasColaboradoresConCheck,
                          setIdsFichasColaboradoresConCheck,
                          toggleDetalleColaboradorModal,
                          bloquearColaborador,
                          desBloquearColaborador,
                          initialResponse,
                          manageSearchProxy,
                          toggleObservarRegistroModal,
                          toggleRegistrarNotasModal,
                          usuarioLogueadoTieneRolCompensaciones
                      }) => {

    const [managePostRequest] = useManagePostRequest();
    const [filasLocal, setFilasLocal] = useState([]);

    useEffect(() => {
        mapFilasLocal();
    }, [filas, idsFichasColaboradoresConCheck]);

    const checkboxClic = (e, IdProcesoCierreEmpleado) => {
        let value = e.target.checked;
        let tempFichas = [...idsFichasColaboradoresConCheck];

        if (value === true && !stringSeEncuentraEnArray(IdProcesoCierreEmpleado, tempFichas)) {
            tempFichas = agregarStringAArraydDeStringsSiNoExiste(IdProcesoCierreEmpleado, tempFichas);
            setIdsFichasColaboradoresConCheck(tempFichas);
        } else if (value === false && stringSeEncuentraEnArray(IdProcesoCierreEmpleado, tempFichas)) {
            tempFichas = eliminarStringDeArraydDeStrings(IdProcesoCierreEmpleado, tempFichas);
            setIdsFichasColaboradoresConCheck(tempFichas);
        }
    };

    const saveEjecutarProcesoEmpleado = async function (idProcesoCierreEmpleado) {
        let payload = {
            "IdProcesoCierreEmpleado": idProcesoCierreEmpleado,
        }
        await managePostRequest(saveEjecutarProcesoEmpleadoAPI(), payload, manageSearchProxy);
    }

    const mapFilasLocal = function () {
        let tempArray = filas.map((item) => {
            let itemFound = idsFichasColaboradoresConCheck.find((idSelected, index, array) => idSelected === item.IdProcesoCierreEmpleado);

            if (itemFound === undefined) {
                return {...item, 'checked': false};
            } else {
                return {...item, 'checked': true};
            }
        })

        setFilasLocal(tempArray)
    }

    return (
        <tbody className="listar-tbody">
        {filasLocal.map((fila, i) => (
            <tr key={i}>
                <td className="col-1 pl-1 pr-1">
                    {initialResponse.ExtraData?.PuedeAcciones === true &&
                    <input type="checkbox" defaultChecked={fila.checked} onClick={(e) => checkboxClic(e, fila?.IdProcesoCierreEmpleado)}></input>
                    }
                    {fila?.Empleado}
                </td>
                <td className="col-1 pl-1 pr-1">{fila?.Cargo}</td>
                {<td className="col-1 pl-1 pr-1">{fila?.Departamento}</td>}
                <td className="col-1 pl-1 pr-1">{fila?.Sede}</td>
                {usuarioLogueadoTieneRolCompensaciones && <td className="col-1 pl-1 pr-1">{fila?.OldTipoContrato}</td>}

                {usuarioLogueadoTieneRolCompensaciones && <td className="col-1 pl-1 pr-1">{fila?.FechaFinContrato.replaceAll(" ", "")}</td>}
                <td className="col-1 pl-1 pr-1">{fila?.Condicion}</td>
                <td className="col-1 pl-1 pr-1">
                    {fila?.EstadoProceso}
                    {fila?.Observacion &&
                    <GenericTooltip id={"observacion" + fila?.IdProcesoCierreEmpleado} text={fila?.Observacion}/>
                    }
                </td>
                {usuarioLogueadoTieneRolCompensaciones &&<td className="col-1 pl-1 pr-1 ">{fila?.EstadoRegistro}</td>}
                {usuarioLogueadoTieneRolCompensaciones &&<td className="col-1 pl-1 pr-1">{fila?.FechaIngreso}</td>}
                {usuarioLogueadoTieneRolCompensaciones && <td className="col-1 pl-1 pr-1">{fila?.NewTipoContrato}</td>}
                <td className="col-1 pl-1 pr-1">
                    <div className="d-flex justify-content-start">
                        {fila?.PuedeEditar === true &&
                        <div>
                            <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer" title="Editar" onClick={() => {
                                toggleDetalleModal(fila?.IdProcesoCierreEmpleado)
                            }}/>
                        </div>
                        }
                        {fila?.PuedeEliminar === true &&
                        <div>
                            <FontAwesomeIcon icon='times' size="sm" className="hand-pointer ml-3" title="Eliminar" onClick={() => {
                                eliminar(fila?.IdProcesoCierreEmpleado)
                            }}/>
                        </div>
                        }

                        {fila?.PuedeDesbloquear === true &&
                        <div>
                            <FontAwesomeIcon icon='lock' size="sm" className="hand-pointer ml-3" title="Desbloquear" onClick={() => desBloquearColaborador(fila?.IdProcesoCierreEmpleado)}/>
                        </div>
                        }

                        {fila?.PuedeBloquear === true &&
                        <div>
                            <FontAwesomeIcon icon='lock-open' size="sm" className="hand-pointer ml-3" title="Bloquear" 
                            onClick={fila.PuedeInteractuarBloqueo && (() => bloquearColaborador(fila?.IdProcesoCierreEmpleado))} color={`${!fila.PuedeInteractuarBloqueo && "#808080"}`}/>
                        </div>
                        }

                        {fila?.PuedeObservar === true &&
                        <div>
                            <FontAwesomeIcon icon='exclamation-triangle' size="sm" className="hand-pointer ml-3" title="Observar" onClick={() => toggleObservarRegistroModal(fila?.IdProcesoCierreEmpleado)}/>
                        </div>
                        }

                        {fila?.PuedeVerDetalle === true &&
                        <div>
                            <FontAwesomeIcon icon='table' size="sm" className="hand-pointer ml-3" title="Ver detalle" onClick={() => toggleDetalleColaboradorModal(fila?.IdProcesoCierreEmpleado, fila?.IdEstadoProceso)}/>
                        </div>
                        }
                        {fila?.PuedeRegistrarNotas === true &&
                        <div onClick={() => toggleRegistrarNotasModal(fila?.IdProcesoCierreEmpleado)} className="d-flex hand-pointer ml-3">
                            <span className="badge badge-success badge-number-ceses">{fila?.Notas}</span>
                            <FontAwesomeIcon icon='comment' transform="left-8" size="lg" className="hand-pointer  blue-standard-color" title="Registrar una Nota"/>
                        </div>
                        }
                    </div>

                    {fila?.Accion &&
                    <div className="d-flex justify-content-start">
                        <button className="btn btn-primary generic-button-ficha pt-0 pb-0 ml-0 " onClick={() => saveEjecutarProcesoEmpleado(fila?.IdProcesoCierreEmpleado)}>
                            {fila?.Accion}
                        </button>
                    </div>
                    }

                </td>
            </tr>
        ))}
        </tbody>
    );
}

export default TableWebBody;