import React, { useState, useContext, useEffect }  from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ListaCuponesDisponibles from './ListaCuponesDisponibles';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputText from '../../../../components/modalComponents/InputText';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import { AuthContext } from '../../../../hooks/context/authContext';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { clearAllInputValidationMessages } from '../../../../validations/FormValidatorUtils';
import { crearSolicitudCuponTiempoLibreAPI, getCuponesDisponiblesByIdEmpleadoAPI } from '../../../../consumers/backendApisUrls';
import { addDays, calculateDaysInBetween, getTodayDate, jsonNoEstaVacio, toIsoForInput } from '../../../../helper/utils';
import { CuponeraWrapper, FormXmark, MobileBarWrapper, TextoSeleccionarCupon, TopOptions } from '../../components/StyledComponents';

const CrearSolicitudCupon = () => {

  const SECTION_NAME = "CrearCuponera";
  const DESDE_MIN_INPUT = toIsoForInput(addDays(getTodayDate(), 3));
  const KEYS = { nombre: "NombresCompletos", cargo: "Cargo", sede: "Sede", area: "Area", aprobador: "Aprobador", fechaInicio: "FechaInicio", fechaFin: "FechaFin", horario: "Horario", cantDias: "CantidadDias", cuponSeleccionado: "CuponSeleccionado" };

  const { state } = useContext(AuthContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search)
  const IdCuponeraTiempoLibre = searchParams.get("search")

  const history = useHistory();

  const [formValues, setFormValues] = useState({ Cupones: [] });
  const [cuponSelected, setCuponSelected] = useState({});
  const [requerimientos, setRequerimientos] = useState([]);
  const [maxDate, setMaxDate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitForm] = useSubmitForm();
  const [executeManagePost] = useManagePostRequest();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const init = async () => {
    setIsLoading(true);
    const api = getCuponesDisponiblesByIdEmpleadoAPI();
    const payload = {IdEmpleado: state.user.id_empleado, IdCuponeraTiempoLibre};
    try {
      await executeManagePost(api, payload, successInitCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const successInitCallback = ({ data }) => {
    setFormValues(data);
    setIsLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearSolicitudCuponTiempoLibreAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const { IdEmpleado , FechaInicio, FechaFin, IdAprobador, Horario } = formValues;
    const payload = { IdEmpleado , FechaInicio, FechaFin, IdAprobador, Horario };
    if (jsonNoEstaVacio(cuponSelected)) {
      const { IdCuponTiempoLibre } = cuponSelected;
      payload["IdCuponTiempoLibre"] = IdCuponTiempoLibre;
    }
    return payload;
  }

  const successSubmit = async () => {
    await init();
    handleClose();
  }

  const checkAndCalcDates = (startDate, endDate) => {
    const hasDates = startDate && endDate;
    setFormValues(lastData => ({
      ...lastData,
      CantidadDias: hasDates ? calculateDaysInBetween(startDate, endDate) : null
    }));
  }

  const handleClose = () => {
    setCuponSelected({});
    setFormValues(lastData => {
      const updatedRows = lastData.Cupones?.map(o => ({...o, isSelected: false}))
      return {...lastData, Cupones: updatedRows};
    });
    clearAllInputValidationMessages(Object.values(KEYS), SECTION_NAME);
  }

  useDidMountEffect(() => {
    checkAndCalcDates(formValues[KEYS.fechaInicio], formValues[KEYS.fechaFin]);
  }, [formValues[KEYS.fechaInicio], formValues[KEYS.Fin]])

  useDidMountEffect(() => {
    if (cuponSelected[KEYS.fechaInicio] && cuponSelected[KEYS.fechaFin]){
      const { FechaInicio, FechaFin } = cuponSelected;
      setFormValues(lastData => ({ ...lastData, FechaInicio, FechaFin }));
    } else {
      setFormValues(lastData => ({ ...lastData, FechaInicio: null, FechaFin: null }));
    }
  }, [cuponSelected[KEYS.fechaInicio], cuponSelected[KEYS.fechaFin]])

  useDidMountEffect(() => {
    if (cuponSelected[KEYS.cantDias] && formValues[KEYS.fechaInicio]){
      const standardCantDiasDay = cuponSelected[KEYS.cantDias] - 1;
      const TIME_STAMP = "T00:00:00";
      const initDay = new Date(formValues[KEYS.fechaInicio]+TIME_STAMP);
      const maxDate = toIsoForInput(addDays(initDay, standardCantDiasDay));
      checkAndCalcDates(formValues[KEYS.fechaInicio], maxDate);
      setMaxDate(maxDate);
      setFormValues(lastData => ({...lastData, [KEYS.fechaFin]: maxDate}));
    }
  }, [cuponSelected[KEYS.cantDias], formValues[KEYS.fechaInicio]])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>

  return (
    <>
      <PageHeader title='Solicitud de cupón de tiempo libre'/>
      <TopOptions>
        {!formValues.Cupones.length
          ? <div>-</div>
          : <TextoSeleccionarCupon>Selecciona un cupón para iniciar tu solicitud</TextoSeleccionarCupon>
        }
        <div className='d-flex' style={{ gap: "10px" }}>
          <button className='btn btn-primary generic-button-ficha-modal' onClick={() => window.open(formValues.UrlLineamientosCuponera)}>
            Lineamientos
          </button>
          <button className='btn btn-primary generic-button-ficha-modal' onClick={() => history.push(`/misCuponesTiempoLibre/misSolicitudes?search=${IdCuponeraTiempoLibre}`)}>
            Mis Solicitudes
          </button>
        </div>
        <button className="btn btn-primary generic-button-ficha-modal" onClick={() => history.goBack()}>
          <FontAwesomeIcon
            icon="angle-left"
            size="lg"
            className="hand-pointer"
            title="Atrás"/>Atrás
        </button>
      </TopOptions>
      <CuponeraWrapper className='wrapper' isOpen={jsonNoEstaVacio(cuponSelected)}>
        <div className='wrapper-responsive'>
          <MobileBar cuponSelected={cuponSelected} handleClose={handleClose} />
          <div className="cuponera custom-scroll">
            <FormXmark className='xMark' onClick={handleClose}>X</FormXmark>
            <div className='cuponera-form'>
              <InputText label='Nombre' {...commonProps(KEYS.nombre)} direction='column' isDisabled/>
              <InputText label='Cargo' {...commonProps(KEYS.cargo)} direction='column' isDisabled/>
              <InputText label='Sede' {...commonProps(KEYS.sede)} direction='column' isDisabled/>
              <InputText label='Área' {...commonProps(KEYS.area)} direction='column' isDisabled/>
              <InputText label='Aprobador' {...commonProps(KEYS.aprobador)} direction='column' isDisabled/>
              {formValues?.IdAprobador === 0 && <p className='required mx-3'  >No tienes un aprobador asignado para tu solicitud, porque tu jefe y el jefe de tu jefe están ausentes.</p> }
              <InputDate label='Desde' {...commonProps(KEYS.fechaInicio)} direction='column' min={DESDE_MIN_INPUT} isDisabled={cuponSelected[KEYS.fechaInicio] && cuponSelected[KEYS.fechaFin]}/>
              <InputDate label='Hasta' {...commonProps(KEYS.fechaFin)} direction='column' min={formValues[KEYS.fechaInicio] ?? null} max={maxDate} isDisabled={(cuponSelected[KEYS.fechaInicio] && cuponSelected[KEYS.fechaFin]) || cuponSelected[KEYS.cantDias]}/>
              {cuponSelected.PlantillaCuponRequerimientos?.split(",").includes(KEYS.horario) &&
                <InputText label='Horario' {...commonProps(KEYS.horario)} maxLength={60} direction='column' placeholder='Ingresar Horario (máx. 60 caracteres)'/>
              }
              <InputText label='Cant. Días' value={formValues[KEYS.cantDias] ?? "-"} direction='column' isDisabled/>
              <InputText label='Cupón Seleccionado' value={cuponSelected.CodigoCupon ?? "-"} direction='column' isDisabled/>
              <div className='d-flex justify-content-center align-items-center' style={{ gap: "30px" }}>
                <button type="submit" onClick={handleSubmit} disabled={isSubmitting || formValues?.IdAprobador === 0 } className={`btn btn-primary generic-button-ficha-modal ${isSubmitting ? "pr-2" : ""}`}>
                  Guardar {isSubmitting && (<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>)}
                </button>
                <button type='button' onClick={handleClose} className='btn btn-primary generic-button-ficha-modal'>
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
        <ListaCuponesDisponibles cuponesDisponibles={formValues.Cupones} setFormValues={setFormValues} cuponSelected={cuponSelected} setCuponSelected={setCuponSelected} />
      </CuponeraWrapper>
    </>
  )
}

export default CrearSolicitudCupon;

const MobileBar = ({ cuponSelected, handleClose }) => {
  const cuponSelectedTitle = cuponSelected.PlantillaCuponNombre ?? "-";

  return (
    <MobileBarWrapper>
      <div className='header'>
        <label>Cupón Seleccionado:</label>
        <p>{cuponSelectedTitle}</p>
      </div>
      {jsonNoEstaVacio(cuponSelected) &&
        <FormXmark onClick={handleClose} >X</FormXmark>
      }
    </MobileBarWrapper>
  )
}