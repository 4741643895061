import React, { useState } from "react";
import InputDate from "../../components/modalComponents/InputDate";
import { useSubmitForm } from "../../hooks/useSubmitForm/useSubmitForm";
import { usePropsInputs } from "../../hooks/useProps/usePropsInput";
import { enviarReporteNuevosIngresosPasosCompletadosAPI } from "../../consumers/backendApisUrls";

const SECTION_NAME = "EnviarReporteNuevosIngresosPasosCompletados";

const ReporteNuevosIngresosPasosCompletados = () => {
  const KEYS = { desde: "FechaDeIngresoRealInicio", hasta: "FechaDeIngresoRealFin" };

  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleSendReport = async () => {
    setIsSending(true);
    const api = enviarReporteNuevosIngresosPasosCompletadosAPI();
    const payload = { ...formValues };
    try {
      await submitForm(
        Object.values(KEYS),
        api,
        payload,
        SECTION_NAME,
        () => {}
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <CardBody>
      <DIV>
        <InputDate
          isRequired
          label="Fecha Inicio Real"
          {...commonProps(KEYS.desde)}
        />
        <InputDate
          isRequired
          label="Fecha Fin Real"
          {...commonProps(KEYS.hasta)}
        />
      </DIV>
      <Button handleSendReport={handleSendReport} isSending={isSending} />
    </CardBody>
  );
};

export default ReporteNuevosIngresosPasosCompletados;

const CardBody = ({ children }) => (
  <div className="card-body">
    <div id={SECTION_NAME}>{children}</div>
  </div>
);

const Button = ({ handleSendReport, isSending }) => (
  <div className="col-sm-5">
    <button
      type="button"
      className="btn btn-primary generic-button-ficha"
      onClick={handleSendReport}
      disabled={isSending}>
      {isSending ? "Enviar Reporte ..." : "Enviar Reporte"}
    </button>
  </div>
);

const DIV = ({ children }) => <div className="col-sm-5">{children}</div>;
