import React, {useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import {useForm} from '../../../hooks/useForm/UseForm';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {getActiveModalidadesEscolaresAPI, getAllAreasEscolaresAPI, getAllGradosEscolaresAPI, getEditarAtributosAcademicosAPI, SeguimientoColaboradorGuardarAtributosAcademicosAPI} from "../../../consumers/backendApisUrls";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";
import SelectInput from "../../../components/Select/SelectInput";
import MultiSelectInput from "../../../components/MultiSelectInput/MultiSelectInput";
import {useGetOptionsSelect} from "../../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import Select from "react-select";

const EditarDatosAcademicosModal = ({toggleModal, refreshTable, idEmpleadoAcciones}) => {
    const sectionName = "EditarDatosAcademicos";
    const [submitForm] = useSubmitForm();
    const [manageGetRequest] = useManageGetRequest();

    const [responseInitData, setResponseInitData] = useState({});

    const [areaPrincipalOptions, setAreaPrincipalOptions] = useState([]);
    const [jsonSelectedAreaPrincipal, setJsonSelectedAreaPrincipal] = useState({});

    const [areasSecundariasOptions, setAreasSecundariasOptions] = useState([]);
    const [selectedAreasSecundariasValues, setSelectedAreasSecundariasValues] = useState([]);

    const [gradoPrincipalOptions, setGradoPrincipalOptions] = useState([]);
    const [jsonSelectedGradoPrincipal, setJsonSelectedGradoPrincipal] = useState({});

    const [gradosSecundariasOptions, setGradosSecundariasOptions] = useState([]);
    const [selectedGradosSecundariasValues, setSelectedGradosSecundariasValues] = useState([]);

    const [calcularModalidadCheck, setCalcularModalidadCheck] = useState(false);

    const [allModalidades, setAllModalidades] = useState([]);
    const [selectedModalidad, setSelectedModalidad] = useState([]);

    const [getOptions] = useGetOptionsSelect();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() =>{
        if(selectedModalidad === false) {
            setSelectedModalidad({
                value: responseInitData.IdModalidadDictado || 0,
                label: responseInitData.ModalidadDictado
            })
        }
    },[selectedModalidad])

    const successGetEditarProcesoCierre = function (response) {
        setResponseInitData(response.data);
        setSelectedModalidad({
            value: response.data.IdModalidadDictado || 0,
            label: response.data.ModalidadDictado
        })
        response?.data?.IdAreaEscolarPrincipal && response?.data?.AreaEscolarPrincipal && setJsonSelectedAreaPrincipal({value: response?.data?.IdAreaEscolarPrincipal, label: response?.data?.AreaEscolarPrincipal})
        response?.data?.IdGradoEscolarPrincipal && response?.data?.GradoEscolarPrincipal && setJsonSelectedGradoPrincipal({value: response?.data?.IdGradoEscolarPrincipal, label: response?.data?.GradoEscolarPrincipal})
        response?.data?.IdsAreaEscolarSecundarios && setSelectedAreasSecundariasValues(response?.data?.IdsAreaEscolarSecundarios);
        response?.data?.IdsGradoEscolarSecundarios && setSelectedGradosSecundariasValues(response?.data?.IdsGradoEscolarSecundarios);

        setCalcularModalidadCheck(response?.data?.CalcularModalidadDictado ?? true);
    }

    const getInitialData = async () => {
        setAreaPrincipalOptions(await getOptions(getAllAreasEscolaresAPI()));
        setAreasSecundariasOptions(await getOptions(getAllAreasEscolaresAPI()));

        setGradoPrincipalOptions(await getOptions(getAllGradosEscolaresAPI()));
        setGradosSecundariasOptions(await getOptions(getAllGradosEscolaresAPI()));

        setAllModalidades(await getOptions(getActiveModalidadesEscolaresAPI()));

        await manageGetRequest(getEditarAtributosAcademicosAPI(idEmpleadoAcciones), successGetEditarProcesoCierre, false);
    }

    const successSubmitCallback = function () {
        refreshTable();
        toggleModal();
    }

    const submit = async () => {
        let payload = armarPayload();

        let api = SeguimientoColaboradorGuardarAtributosAcademicosAPI();
        let keys = ["IdEmpleado", "IdAreaEscolarPrincipal", "IdsAreasEscolaresSecundarios", "IdGradoEscolarPrincipal", "IdsGradosEscolaresSecundarios"];

        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const armarPayload = () => {
        let payload = {
            "IdEmpleado": idEmpleadoAcciones,
            "IdAreaEscolarPrincipal": jsonSelectedAreaPrincipal?.value ?? null,
            "IdsAreasEscolaresSecundarios": selectedAreasSecundariasValues ?? [],
            "IdGradoEscolarPrincipal": jsonSelectedGradoPrincipal?.value ?? null,
            "IdsGradosEscolaresSecundarios": selectedGradosSecundariasValues ?? [],
            "CalcularModalidadDictado": calcularModalidadCheck,
            "IdModalidadDictado": selectedModalidad.value
        }

        return payload;
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"700px"} titulo={"Editar datos académicos"}>
                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Modalidad de dictado<span className="required"></span></label>
                            <div className="col-sm-8 black-color">
                                <Select
                                    options={allModalidades}
                                    onChange={setSelectedModalidad}
                                    value={selectedModalidad}
                                    isDisabled={calcularModalidadCheck}
                                />
                            </div>
                        </div>
                        <div className='checkbox mb-2'>
                            <label>
                                <input
                                    type="checkbox"
                                    id="esManual"
                                    name="esManual"
                                    value="manual"
                                    checked={calcularModalidadCheck}
                                    onChange={() => setCalcularModalidadCheck(!calcularModalidadCheck)}
                                />
                                <label className="modal-label"> Hacer que el sistema calcule la modalidad</label>
                            </label>
                        </div>
                        <p className="font-gothan-medium blue-standard-color">Áreas Asignadas</p>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label"> Principal<span className="required">*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={areaPrincipalOptions} jsonSelectedValue={jsonSelectedAreaPrincipal} setJsonSelectedValue={setJsonSelectedAreaPrincipal} placeholder={"Seleccionar"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdAreaEscolarPrincipal-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label"> Secundarias<span className="required">*</span></label>
                            <div className="col-sm-8 black-color">
                                <MultiSelectInput options={areasSecundariasOptions} selectedValues={selectedAreasSecundariasValues} setSelectedValues={setSelectedAreasSecundariasValues} isDisabled={false} placeholder={"Seleccionar"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsAreasEscolaresSecundarios-validation-message`}></p>
                            </div>
                        </div>
                        <p className="font-gothan-medium blue-standard-color">Grados Asignados</p>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Principal<span className="required">*</span></label>
                            <div className="col-sm-8 black-color">
                                <SelectInput options={gradoPrincipalOptions} jsonSelectedValue={jsonSelectedGradoPrincipal} setJsonSelectedValue={setJsonSelectedGradoPrincipal} placeholder={"Seleccionar"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdGradoEscolarPrincipal-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row mb-0">
                            <label className="col-sm-4 col-form-label modal-label">Secundarias<span className="required">*</span></label>
                            <div className="col-sm-8 black-color">
                                <MultiSelectInput options={gradosSecundariasOptions} selectedValues={selectedGradosSecundariasValues} setSelectedValues={setSelectedGradosSecundariasValues} isDisabled={false} placeholder={"Seleccionar"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsGradosEscolaresSecundarios-validation-message`}></p>
                            </div>
                        </div>

                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default EditarDatosAcademicosModal;