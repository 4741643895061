import React, { useState, useEffect, useRef } from 'react';
import ModalForm from '../../../../components/modalComponents/ModalForm';
import InputText from '../../../../components/modalComponents/InputText';
import InputMultiCheckbox from '../../../../components/modalComponents/InputMultiCheckbox';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../../hooks/useSubmitForm/useSubmitForm";
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { crearConfiguracionListaDocumentosAPI, getDocumentosNuevosIngresosAdminAPI } from '../../../../consumers/backendApisUrls';

const CrearListaDocumentos = ({ toggleModal, refreshPage }) => {
  const isMounted = useRef(true);
  const SECTION_NAME = "CrearNuevoDocumento";
  const KEYS = { ListName: "NombreLista", IdsDocumentos: "IdsDocumentos"};

  const [formValues, setFormValues] = useState({[KEYS.IdsDocumentos]: []});
  const [allDocuments, setAllDocuments] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const init = async () => {
    try {
      setAllDocuments(await getOptions(getDocumentosNuevosIngresosAdminAPI()));
    } catch (error) {
      console.error(error);
    }
  }

  const submit = async (e) => {
    e.preventDefault();
    const api = crearConfiguracionListaDocumentosAPI()
    let payload = formValues;
    setButtonDisabled(true);
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setButtonDisabled(false))
  };

  const successSubmitCallback = function () {
    setButtonDisabled(false);
    refreshPage();
    toggleModal();
  }

  useEffect(() => {
    if (isMounted) init();

    return () => {
      isMounted.current=false;
    }
  }, [])

  return (
    <ModalForm onSubmit={submit} showModal={toggleModal} width="700px" titulo="Nuevo documento" saveButtonDisabled={buttonDisabled}>
      <InputText label="Nombre de documento" value={formValues[KEYS.ListName]} onChange={handleChange} name={KEYS.ListName} sectionName={SECTION_NAME} isRequired/>
      <InputMultiCheckbox label="Documentos" options={allDocuments} values={formValues[KEYS.IdsDocumentos]} onChange={handleChange} name={KEYS.IdsDocumentos} sectionName={SECTION_NAME} isRequired/>
    </ModalForm>
  )
}

export default CrearListaDocumentos