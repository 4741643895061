import React, { useContext, useEffect, useState } from 'react';
import Select from "react-select";
import { AuthContext } from '../../../hooks/context/authContext'
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { setStateWithCallBackResponse } from '../../../helper/utils';
import { getEstadosFichaSaludAPI, getEstadosResultadosEncuestaSaludAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { set } from 'store/dist/store.modern.min';
import { ESTADOS_CONDICION_RIESGO, ESTADO_FICHA_SALUD, ESTADO_RESULTADO } from '../../../constants/hardCodedConstants';

const FormRow = ({ idEncuesta, payload, setPayload }) => {
  const { state } = useContext(AuthContext);
  const [executeGetRequest] = useGetRequest();
  const [currentDate, setCurrentDate] = useState(new Date());

  const [estadosFicha, setEstadosFicha] = useState({});
  const [resultadosEncuesta, setResultadosEncuesta] = useState({});

  const [estadoFicha, setEstadoFicha] = useState(null);
  const [condicionRiesgo, setCOndicionRiesgo] = useState(null);
  const [estadoValidcaion, setEstadoValidacion] = useState(null);
  const [resultado, setResultado] = useState(null);
  const [uploadFileCertificadoMedico, setUploadFileCertificadoMedico] = useState({});
  const [descripcion, setDescripcion] = useState("");


  const init = async () => {
    const responseEstados = await executeGetRequest(getEstadosFichaSaludAPI());
    if (responseCode200(responseEstados)) {
      setEstadosFicha(responseEstados?.data.map(item => ({
        value: item.Value, label: item.Text
      })))
    } else {
      mostrarMensajeDeErrorConToast(responseEstados);
    }

    const responseResultado = await executeGetRequest(getEstadosResultadosEncuestaSaludAPI());
    if (responseCode200(responseResultado)) {
      setResultadosEncuesta(responseResultado?.data.map(item => ({
        value: item.Value, label: item.Text
      })))
    } else {
      mostrarMensajeDeErrorConToast(responseResultado);
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (condicionRiesgo?.label === "No") {
      setEstadoValidacion("Completado");
      setResultado(ESTADO_RESULTADO);
      setEstadoFicha(ESTADO_FICHA_SALUD);
    } else {
      setEstadoValidacion("");
    }


    setPayload({
      IdEncuestaSaludDetalle: idEncuesta,
      IdEstadoFicha: estadoFicha?.value,
      PresentaCondicionMedica: condicionRiesgo?.value,
      IdResultado: resultado?.value,
      Descripcion: descripcion,
      Sustento: uploadFileCertificadoMedico
    })

  }, [estadoFicha, condicionRiesgo, resultado, uploadFileCertificadoMedico, descripcion])

  return (
    <tr>
      <td>
        {`${currentDate.getDate()} / ${currentDate.getMonth() + 1} / ${currentDate.getFullYear()}`}
      </td>
      <td>
        <Select
          value={estadoFicha}
          onChange={setEstadoFicha}
          options={estadosFicha}
          placeholder="Estado ficha"
        />
      </td>
      <td>
        <Select
          value={condicionRiesgo}
          onChange={setCOndicionRiesgo}
          options={ESTADOS_CONDICION_RIESGO}
          placeholder="Condición"
        />
      </td>
      <td>
        {estadoValidcaion}
      </td>
      <td>
        <Select
          value={resultado}
          onChange={setResultado}
          options={resultadosEncuesta}
          placeholder="Resultado"
        />
      </td>
      <td>
        <div>
          <input type="textarea" className="form-control" rows="5" value={descripcion} onChange={(e) => setDescripcion(e.target.value)} />
        </div>
      </td>
      <td>
        <div className="col-sm-5 black-color">
          <GenericUploadFileV2 uploadFileToS3={setUploadFileCertificadoMedico} id={"upload"} textButton={"Subir archivo"} />
        </div>
      </td>
      <td>
        {state.user.email}
      </td>
    </tr>
  );
};

export default FormRow;
