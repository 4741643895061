import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {toast} from "react-toastify";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {getActividadesRenunciasAPI, guardarActividadAmpliarLiquidacionAPI} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";

const ActualizarLiquidacionModal = ({showModal, idEmpleadoFlujoActividad, manageSearch}) => {
    const sectionName = "AmpliarLiquidacionModal";
    const [actividadRenuncia, setActividadRenuncia] = useState({});

    const initialFormValues =  {
        "ConfirmaLiquidacion": false,
        "Monto": ""
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    const [handleChange] = useForm(formValues, setFormValues);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        const response = await executeGetRequest(getActividadesRenunciasAPI(idEmpleadoFlujoActividad));
        if (responseCode200(response)) {
            setActividadRenuncia(response.data);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };



    const onSubmit = async (e) => {
        e.preventDefault();

        console.debug("formValues: ", formValues);
        let payload = {
            "IdEmpleadoFlujoActividad": idEmpleadoFlujoActividad,
            "ConfirmaLiquidacion": formValues.ConfirmaLiquidacion,
            "Monto": formValues.Monto
        };

        console.debug("payload: ", payload);

        let response = await executePostRequest(guardarActividadAmpliarLiquidacionAPI(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["ConfirmaLiquidacion", "Monto"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"700px"} titulo="Ampliar Liquidación">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label ">Colaborador:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.NombreEmpleado}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Último día de labores:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.FechaUltimoDia}</label>
                            </div>
                            <div className="row custom-center">
                                <label className="col-sm-4 col-form-label modal-label">Sede:</label>
                                <label className="col-sm-6 col-form-label modal-label black-color-important">{actividadRenuncia && actividadRenuncia.Sede}</label>
                            </div>
                            <hr/>
                            <div className="row custom-form-group-modal">
                                <label className="col-sm-4 col-form-label modal-label">Monto a Reintegrar<span className="required">*</span></label>
                                <div className="col-sm-3 flex-row justify-content-center">
                                    <input type="number" className="form-control" name="Monto" onChange={handleChange} defaultValue={formValues.Monto}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Monto-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal">
                                <div className="col-sm-12 flex-row justify-content-center">
                                    <input type="checkbox" name="ConfirmaLiquidacion" onChange={handleChange} defaultChecked={formValues.ConfirmaLiquidacion}/>
                                    <label className="col-form-label modal-label">Confirmo que se actualizó la liquidación correspondiente<span className="required"></span></label>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-ConfirmaLiquidacion-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ActualizarLiquidacionModal;

