import React, { useEffect, useState } from 'react'
import TableListaCuponeras from './TableListaCuponeras'
import PaginatorV2 from '../../../components/pageComponents/PaginatorV2'
import PageHeader from '../../../components/pageHeader/PageHeader'
import ModalMensaje from '../../../components/modalMensaje/ModalMensaje'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { listarCuponeraTiempoLibreColaboradorAPI } from '../../../consumers/backendApisUrls'

const ListaCuponeras = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeManagePost] = useManagePostRequest();

  const init = async () => {
    // getDataToShowModal();
    setIsLoading(true);
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    setIsFetching(true);
    const api = listarCuponeraTiempoLibreColaboradorAPI();
    const payload = getPayload();
    try {
      await executeManagePost(api, payload, successManageSearchCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  // const getDataToShowModal = () => {
  //   const showModalMessage = localStorage.getItem("showNoCuponerasActivasModal")
  //   if (showModalMessage == 'true') {
  //     const modalMensaje = "No hay ninguna cuponera vigente";
  //     const modalImage = "https://tstintranet.innovaschools.edu.pe/Content/image/Notificaciones/slightly_frowning_face.png";
  //     localStorage.setItem("showNoCuponerasActivasModal", false);
  //     setModalEnabled({ isEnable: true, component: ModalMensaje, data: { modalMensaje, modalImage } })
  //   }
  // }

  useDidMountEffect(() => {
    if (!isLoading) manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Lista de Cuponeras" />
      {!formValues.Items.length
        ? <p className='blue-standard-color text-center mt-4'>Por ahora no contamos con ningún cuponera vigente, espera novedades próximamente</p>
        : <>
            <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} />
            <TableListaCuponeras rows={formValues.Items} isFetching={isFetching}/>
          </>
      }
      <div className='px-5 mb-4'>
        <PaginatorV2 paginator={paginator} setPaginator={setPaginator} manageSearch={manageSearch}/>
      </div>
  </>
  )
}

export default ListaCuponeras