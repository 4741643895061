const SECTION_NAME = "CrearSolicitudVacaciones";
const TIME_STAMP = "T00:00:00";
const KEYS = ["StartDate", "EndDate"];
const ID_UPLOAD_FORMAT = "AgregarFirmarFormato";
const ESTADO = "enviar";
const KEYS_FORM = {desde: "FechaDesde", hasta: "FechaHasta", observaciones: "Comentarios"};
const dayAdded = [
  "Uno",
  "Dos",
  "Tres"
]

export { SECTION_NAME, TIME_STAMP, KEYS, ID_UPLOAD_FORMAT, ESTADO, KEYS_FORM, dayAdded }