import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {obtenerCartaAutorizacionFirmada, aprobarCartaAutorizacionFirmada} from "../../../consumers/backendApisUrls";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import { GENERIC_SUCCESS_TOAST_MESSAGE } from '../../../constants/hardCodedConstants';
import {downloadFile} from "../../../consumers/backendConsumer";
import {useForm} from "../../../hooks/useForm/UseForm";


const ValidarCartaAutorizacionModal = ({showModal, idEmpleadoFlujo, idEmpleadoFlujoActividad, manageSearch}) => {

    const {state} = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();
    const [fileViewModel, setFileViewModel] = useState({EsAprobada: "true"});
    const [validation, setValidation] = useState({});
    const [handleChange] = useForm(fileViewModel, setFileViewModel);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const init = async function () {
        const payload = {
            IdEmpleadoFlujo: idEmpleadoFlujo
        }
        const response = await executePostRequest(obtenerCartaAutorizacionFirmada(), payload);
        if (responseCode200(response)) {
            var file= response?.data?.fileViewModel;
            setFileViewModel(file);
        } else if (responseTieneModelState(response)) {

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const onSubmit = async (e) => {
        let payload = {
            IdEmpleadoFlujo: idEmpleadoFlujo,
            IdEmpleadoFlujoActividad: idEmpleadoFlujoActividad,
            EsAprobada: fileViewModel.EsAprobada,
            Mensaje: fileViewModel.Mensaje
        };
        const response = await executePostRequest(aprobarCartaAutorizacionFirmada(), payload);
        if (responseCode200(response)) {
            showModal();
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            await manageSearch();
        } else if (responseTieneModelState(response)) {
            setValidation(response?.response?.data.ModelState);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
     };

    return (
        <>
            <GenericModal showModal={showModal} width={"890px"} titulo="Validar firma de carta de autorización de datos personales">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-form-group-modal justify-content-center">
                                <label className="col-sm-4 col-form-label modal-label">Carta de autorización</label>
                                <div className="col-sm-8 flex-row justify-content-center align-center items-center">
                                    <u>
                                        <a className="text-link-appereance" href="#" onClick={async() => await downloadFile(state.token, fileViewModel.Path, fileViewModel.OriginalFileName)}>
                                            <FontAwesomeIcon icon='download'/> {fileViewModel.OriginalFileName}
                                        </a>
                                    </u>
                                    {validation["IdEmpleadoFlujoActividad"] && validation["IdEmpleadoFlujoActividad"].length > 0  && <p className="ficha-form-input-validation-message">{validation["IdEmpleadoFlujoActividad"]}</p>}
                                </div>
                            </div>
                            <div className="row custom-form-group-modal justify-content-center">
                                <label className="col-sm-4 col-form-label modal-label">¿La carta cargada es? <span className="required">*</span></label>
                                <div className="col-sm-8 flex-row justify-content-center">
                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="EsAprobada" value="true" checked={fileViewModel.EsAprobada == "true" ? "checked" : ""} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">
                                            Aprobada
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input modal-ceses-input-radio-button" type="radio" name="EsAprobada" value="false" checked={fileViewModel.EsAprobada == "false" ? "checked" : ""} onChange={handleChange}></input>
                                        <label className="form-check-label modal-ceses-label-radio-button">
                                            Observada
                                        </label>
                                    </div>
                                    {validation["EsAprobada"] && validation["EsAprobada"].length > 0  && <p className="ficha-form-input-validation-message">{validation["EsAprobada"]}</p>}
                                </div>
                            </div>
                            { fileViewModel.EsAprobada == "false" && <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Mensaje <span className="required">*</span></label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" rows="3" name="Mensaje" onChange={handleChange} value={fileViewModel.Mensaje}></textarea>
                                    {validation["Mensaje"] && validation["Mensaje"].length > 0  && <p className="ficha-form-input-validation-message">{validation["Mensaje"]}</p>}
                                </div>
                            </div>}
                        </form>
                    </div>
                </div>

                <div className="modal-footer" style={{'display': 'flex', 'justifyContent': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'alignSelf': 'flex-start'}}>
                        <strong></strong>
                    </div>
                    <div className="button" style={{'alignSelf': 'flex-end'}}>
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ValidarCartaAutorizacionModal;