import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from 'prop-types';
import { INPUT_DIRECTION } from "../../constants/hardCodedConstants";
import { useGetOptionsSelect } from "../../hooks/useGetOptionsSelect/useGetOptionsSelect";

const InputSelectCascade = ({ 
  label,
  value,
  onChange,
  name,
  sectionName,
  dependendOption,
  api,
  isRequired,
  isDisabled,
  dropdownFilter,
  isAutoselect = true,
  isIdSearch = true,
  direction = "row",
  placeholder = "Seleccionar",
}) => {
  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  const [dropdowns, setDropdowns] = useState([]);
  const [getOptions] = useGetOptionsSelect();

  const getDropdowns = async () => {
    const value = dependendOption.value ?? dependendOption;
    try {
      const response = isIdSearch ? await getOptions(api(value)) : await getOptions(api());
      const dropdown = dropdownFilter ? dropdownFilter(response) : response;
      setDropdowns(dropdown);
      if(isAutoselect && dropdown.length === 1){
        handleOnChange(dropdown.at(0));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (dependendOption !== undefined && dependendOption !== null) getDropdowns();
  }, [dependendOption])

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className="black-color">
        <Select
          options={dropdowns ?? []}
          value={dropdowns?.find(o => o.value === value) ?? ""}
          onChange={handleOnChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition='fixed' // check
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

InputSelectCascade.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sectionName: PropTypes.string,
  dependendOption: PropTypes.any,
  api: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default InputSelectCascade;