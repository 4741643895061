import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarPlantilla from './modales/EditarPlantilla';
import ModalActivarInactivarGeneric from '../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import { activarInactivarTemplateAPI } from '../../consumers/backendApisUrls';

const TableWebBody = ({
    filas,
    setModalEnabled,
}) => {

  return (
    <tbody className="listar-tbody">
        {filas.map((fila, i) => (
            <tr key={i}>
                <td className="col-5 pr-1 ml-3">{fila?.Name}</td>
                <td className="col-3 pr-1">{fila?.TemplateType}</td>
                <td className="col-3 pr-1">{fila?.Estado}</td>
                <td className="col-1 pr-1">
                    <div className="d-flex justify-content-start">
                        <div>
                            <FontAwesomeIcon
                                icon={fila.IsActive ? 'ban' : 'circle-notch'} 
                                size="sm" 
                                className="hand-pointer" 
                                title={fila.IsActive ? 'Inactivar' : 'Activar'}
                                onClick={() => setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
                                    data: {api: activarInactivarTemplateAPI, id: fila.IdTemplate, title: "plantilla", name: fila.Name, state: fila.IsActive}})}
                            />
                        </div>
                        {fila.IsActive &&
                            <div>
                                <FontAwesomeIcon 
                                    icon='edit' 
                                    size="sm" 
                                    className="hand-pointer ml-3" 
                                    title="Editar"
                                    onClick={() => setModalEnabled({isEnable: true, component: EditarPlantilla, data: {plantillaId: fila.IdTemplate}})}
                                />
                            </div>
                        }
                    </div>
                </td>
            </tr>
        ))}
        </tbody>
  )
}

export default TableWebBody