import React, { useState, useEffect } from "react";
import PageHeader from "../../components/pageHeader/PageHeader";
import Paginator from "../directorio/Paginator";
import { useManageGetRequest } from "../../hooks/useManageGetRequest/useManageGetRequest";
import { useManagePostRequest } from "../../hooks/useManagePostRequest/useManagePostRequest";
import Wrapper from "../../components/atoms/DirectorioWrapper";
import { Spinner } from "react-bootstrap";
import {
  getRegistroInvitacionesAPI,
  getUnidadesRegistroInvitacionAPI,
} from "../../consumers/backendApisUrls";
import TableWebBody from "./TableWebBody";
import CrearRegistroInvitacion from "./modales/CrearRegistroInvitacion";
import EditarRegistroInvitacion from "./modales/EditarRegistroInvitacion";
import ActivarInactivarRegistroInvitacion from "./modales/ActivarInactivarRegistroInvitacion";
import FormSelect from "../../components/atoms/Select";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";

const RegistroInvitacionOnboarding = () => {
  const [loading, setLoading] = useState(true);
  const [isFechaMasReciente, setIsFechaMasReciente] = useState(true);
  const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
  const [dataUnidades, setDataUnidades] = useState([]);
  const [unidadSelected, setUnidadSelected] = useState([]);
  const [filas, setFilas] = useState([]);
  const [idRegistroInvitacion, setIdRegistroInvitacion] = useState();
  const [rowSelected, setRowSelected] = useState({});

  const [page, setPage] = useState(1);
  const [extraData, setExtraData] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [firsItemPage, setFirsItemPage] = useState();
  const [lastItemPage, setLastItemPage] = useState();
  const [totalPages, setTotalPages] = useState();

  const [hideCrearRegistroInvitacion, setHideCrearRegistroInvitacion] =
    useState(true);
  const [hideEditarRegistroInvitacion, setHideEditarRegistroInvitacion] =
    useState(true);
  const [
    hideActivarInactivarRegistroInvitacion,
    setHideActivarInactivarRegistroInvitacion,
  ] = useState(true);

  const [executePostRequest] = useManagePostRequest();
  const [executeGetRequest] = useManageGetRequest();

  const getInitialData = async () => {
    setLoading(true);
    try {
      await manageSearch();
      await executeGetRequest(getUnidadesRegistroInvitacionAPI(), (res) =>
        setDataUnidades(res.data)
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const manageSearch = async () => {
    const api = getRegistroInvitacionesAPI();
    let payload = getPayload();
    await executePostRequest(api, payload, successInitialData, false);
  };

  const getPayload = () => {
    return {
      Page: page,
      Criteria: criterioDeBusqueda,
      IdsUnidades: unidadSelected,
      MasReciente: isFechaMasReciente,
    };
  };

  const successInitialData = (response) => {
    setFilas(response.data.Items);
    setTotalItems(response.data.TotalItems);
    setFirsItemPage(response.data.FirsItemPage);
    setLastItemPage(response.data.LastItemPage);
    setTotalPages(response.data.TotalPages);
    setExtraData(response.data.ExtraData);
  };

  const toggleCrearInvitacionModal = () => {
    setHideCrearRegistroInvitacion(!hideCrearRegistroInvitacion);
  };

  const toggleEditarInvitacionModal = (IdRegistroInvitacion) => {
    if(IdRegistroInvitacion) setIdRegistroInvitacion(IdRegistroInvitacion)
    setHideEditarRegistroInvitacion(!hideEditarRegistroInvitacion);
  };

  const toggleActivarInactivarInvitacionModal = (row) => {
    if (row) setRowSelected(row);
    setHideActivarInactivarRegistroInvitacion(
      !hideActivarInactivarRegistroInvitacion
    );
  };

  const onLeftClic = () => {
    const nextPage = Math.max(page - 1, 1);
    setPage(nextPage);
  };

  const onRightClic = () => {
    const nextPage = Math.min(page + 1, totalPages);
    setPage(nextPage);
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }

    return () => {
      unmounted = true;
    };
  }, []);

  useDidMountEffect(() => {
    manageSearch();
  }, [unidadSelected, isFechaMasReciente]);

  return (
    <>
      <div className="message-no-disponible-en-mobile">
        <p>Esta página no está disponible en mobile.</p>
      </div>
      <div className="no-disponible-en-mobile">
        {!hideCrearRegistroInvitacion && (
          <div className="modal-contactanos modal-response-ficha-background">
            <CrearRegistroInvitacion
              toggleModal={toggleCrearInvitacionModal}
              refreshTable={manageSearch}
            />
          </div>
        )}

        {!hideEditarRegistroInvitacion && (
          <div className="modal-contactanos modal-response-ficha-background">
            <EditarRegistroInvitacion
              toggleModal={toggleEditarInvitacionModal}
              refreshTable={manageSearch}
              idRegistroInvitacion={idRegistroInvitacion}
            />
          </div>
        )}

        {!hideActivarInactivarRegistroInvitacion && (
          <div className="modal-contactanos modal-response-ficha-background">
            <ActivarInactivarRegistroInvitacion
              row={rowSelected}
              toggleModal={toggleActivarInactivarInvitacionModal}
              refreshTable={manageSearch}
            />
          </div>
        )}

        <Wrapper>
          <PageHeader title="Registro Invitación Onboarding" />
          {loading === true && (
            <div className="spinner-cargando-container">
              <Spinner animation="grow" variant="primary" size="1sm" />
            </div>
          )}

          {loading !== true && (
            <div className="container-fluid body-content">
              <div className="generic-buscador-container">
                <div className="filtros-left-section"></div>
                {extraData?.PuedeAgregar && (
                  <div className="filtros-right-section">
                    <button
                      type="button"
                      className="generic-button-buscador"
                      onClick={toggleCrearInvitacionModal}
                    >
                      Nuevo
                    </button>
                  </div>
                )}
              </div>
              <div className="pt-2">
                <div className="table-scroll">
                  <table className="table">
                    <thead className="listar-thead">
                      <tr>
                        <th className="pb-0">
                          <div className="font-gothan-medium">
                            Fecha de Registro
                          </div>
                          <div className="custom-control custom-switch mt-1">
                            <input
                              className="custom-control-input hand-pointer"
                              type="checkbox"
                              checked={isFechaMasReciente}
                              onChange={(e) =>
                                setIsFechaMasReciente(e.target.checked)
                              }
                              id="admin-solicitud-cambios-fecha-order"
                            ></input>
                            <label
                              className="custom-control-label admin-solicitud-cambios-fecha-order-checkbox hand-pointer"
                              htmlFor="admin-solicitud-cambios-fecha-order"
                            >
                              Más reciente
                            </label>
                          </div>
                        </th>
                        <th>
                          <FormSelect
                            description="Grupo"
                            options={dataUnidades}
                            selectedOptions={unidadSelected}
                            setSelectedOptions={setUnidadSelected}
                          />
                        </th>
                        <th>
                          <div className="font-gothan-medium">Fecha</div>
                        </th>
                        <th>
                          <div className="font-gothan-medium">Hora</div>
                        </th>
                        <th>
                          <div className="font-gothan-medium">Acciones</div>
                        </th>
                      </tr>
                    </thead>
                    <TableWebBody
                      filas={filas}
                      toggleActivarInactivarInvitacionModal={
                        toggleActivarInactivarInvitacionModal
                      }
                      toggleEditarInvitacionModal={toggleEditarInvitacionModal}
                    />
                  </table>
                </div>
                {totalPages > 0 && (
                  <Paginator
                    totalItems={totalItems}
                    firsItemPage={firsItemPage}
                    lastItemPage={lastItemPage}
                    totalPages={totalPages}
                    page={page}
                    onLeftClic={onLeftClic}
                    onRightClic={onRightClic}
                  />
                )}
              </div>
            </div>
          )}
        </Wrapper>
      </div>
    </>
  );
};

export default RegistroInvitacionOnboarding;
