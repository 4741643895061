import React, {useContext, useState} from 'react';
import {AuthContext} from "../../hooks/context/authContext";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {deleteFileFromS3API, descargarVacacionesDisponiblesAPIMatricialReport} from "../../consumers/backendApisUrls";
import {downloadFile} from "../../consumers/backendConsumer";

const ReporteBBDDColaboradoresActivos = () => {
    const {state} = useContext(AuthContext);
    const [executePostRequest] = usePostRequest();
    const [loading, setLoading] = useState(false);

    const descargarReporte = async () => {
        setLoading(true);
        let responseReporteGenerado = await executePostRequest(descargarVacacionesDisponiblesAPIMatricialReport(), {});
        if (responseCode200(responseReporteGenerado)) {
            let descargarReporteResponse = await downloadFile(state.token, responseReporteGenerado?.data?.FilePath, responseReporteGenerado?.data?.OriginalFileName);
            if (responseCode200(descargarReporteResponse)) {
                await eliminarArchivoDeS3(responseReporteGenerado?.data?.FilePath);
            } else {
                mostrarMensajeDeErrorConToast(descargarReporteResponse);
            }
        } else {
            mostrarMensajeDeErrorConToast(responseReporteGenerado);
        }
        setLoading(false);
    };

    const eliminarArchivoDeS3 = async function (filePath) {
        let deleteFileFromS3Payload = {
            "Path": filePath
        };
        let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
        if (responseCode200(deleteFileFromS3Response)) {
            console.debug("delete file from s3 successfully");
        } else {
            mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
        }
    };

    return (
        <>
            <div className='card-body'>
                <div id='seccionReportesDeAsignacionFamiliar'>
                    <p className="reporte-asignacion-familiar-text ">Este reporte contiene Vacaciones de Jefe matriciales</p>
                    <div>
                        <button className="btn btn-primary generic-button-ficha reportes-descargar-button" onClick={descargarReporte}>{loading ? 'Descargando' : 'Descargar'} Reporte {loading ? '...' : ''}</button>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ReporteBBDDColaboradoresActivos;