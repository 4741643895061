import React, { useState, useContext } from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { getActividadFileAPI, saveBasicActivityAPI } from '../../../consumers/backendApisUrls';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { downloadFile } from '../../../consumers/backendConsumer';
import { AuthContext } from '../../../hooks/context/authContext';
import MensajeConfirmacionModal from './MensajeConfirmacionModal';

const InstructivoEncuestaTeletrabajo = ({ showModal, IdEmpleadoFlujoActividad, manageSearch }) => {

  const { state } = useContext(AuthContext);

  const ACTIVITY_DATA = {Title: "lectura", Activity: "Instructivo de teletrabajo"}
  const SECTION_NAME = "modal_Instructivo_EncuestaTeletrabajo";
  const KEYS = ["File"];

  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataToSend, setDataToSend] = useState({});
  const [showMensajeConfirmacionModal, setShowMensajeConfirmacionModal] = useState(false);

  const [executeGetRequest] = useManageGetRequest();
  const [executePostRequest] = useManagePostRequest();

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = saveBasicActivityAPI();
      let payload = {IdEmpleadoFlujoActividad};
      await executePostRequest(api, payload, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const successOnSubmitCallback = (res) => {
    manageSearch();
    showModal();
  }

  const handleDownloadFile = async () => {
    setIsDownloadingFile(true);
    try {
      const api = getActividadFileAPI(IdEmpleadoFlujoActividad);
      await executeGetRequest(api, successGetFileCallback);
    } catch (error) {
      console.error(error);
    }
    setIsDownloadingFile(false);
  };

  const successGetFileCallback = (res) => {
    downloadFile(
      state.token,
      res.data.KeyFile,
      res.data.NombreOriginal
    );
  }

  const toggleMensajeConfirmacion = () => {
    setDataToSend({
      ActivityTitle: ACTIVITY_DATA.Title,
      Activity: ACTIVITY_DATA.Activity
    })
    setShowMensajeConfirmacionModal(!showMensajeConfirmacionModal)
  }

  return (
    <>
      {showMensajeConfirmacionModal &&
        <div className="modal-contactanos modal-response-ficha-background">
          <MensajeConfirmacionModal 
            showModal={toggleMensajeConfirmacion}
            handleSend={onSubmit} 
            dataToSend={dataToSend}
            isSending={isSubmitting}/>
        </div>
      }
      <GenericModal showModal={showModal} width={"700px"} titulo="Instructivo">
        <div className="margin-modal-body">
          <form>
            <div className="row ml-1 mr-2 mb-3 blue-standard-color">
              Paso 2 - Lineamientos Generales de Seguridad y Salud en el Trabajo (Instructivo).<br></br>
              Descarga y lee el instructivo que te ayudará a reconocer las condiciones de Seguridad y Salud en el Trabajo (modalidad teletrabajo) y podrás responder la encuesta que te compartimos en el Paso 3.
            </div>
            <div className="form-group row mb-0">
              <label className="col-sm-4 col-form-label modal-label">
                Material
              </label>
            </div>
            <div className="row custom-form-group-modal justify-content-center">
              <label className="col-sm-5 col-form-label">
                Instructivo de la actividad
              </label>
              <div className="d-flex flex-column justify-content-center">
                <div>
                  <button type="button" className="btn btn-primary generic-button-ficha" onClick={handleDownloadFile} disabled={isDownloadingFile}>
                    Descargar
                  </button>
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-File-validation-message`}/>
                </div>
              </div>
            </div>
            <div className="mb-2 mt-4">
            </div>
            <div className="modal-footer justify-content-end">
              <button type="button" className="generic-button-buscador" onClick={toggleMensajeConfirmacion} disabled={isSubmitting}>
                Completar
              </button>
            </div>
          </form>
        </div>
      </GenericModal>
    </>
  )
}

export default InstructivoEncuestaTeletrabajo