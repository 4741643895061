import React, { useState, useEffect } from 'react'
import ModalForm from '../../../../../components/modalComponents/ModalForm'
import InputText from '../../../../../components/modalComponents/InputText'
import InputDate from '../../../../../components/modalComponents/InputDate'
import InputMultiSelect from '../../../../../components/modalComponents/InputMultiSelect'
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect'
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput'
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import { initialAllOptionPromises } from '../../../../../helper/utils'
import { crearCuponeraTiempoLibreAPI, getDivisionesAPI } from '../../../../../consumers/backendApisUrls'
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile'

const CrearCuponera = ({ toggleModal, refreshPage }) => {

  const SECTION_NAME = "CrearCuponera";
  const KEYS_INIT = ["IdsDivisiones"];
  const KEYS = {Nombre: "Nombre", ImagenCuponera: "ImagenCuponeraFileViewModel", LineamientosCuponera: "LineamientosCuponeraFileViewModel", FechaInicio: "FechaInicio", FechaFin: "FechaFin", IdsDivisiones: "IdsDivisiones", prefijo: "Prefijo"};

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const allGetPromises = () => {
    return [
      getOptions(getDivisionesAPI()),
    ]
  }

  const init = async () => {
    try {
      initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), (res) => setAllDropdowns(res));
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = crearCuponeraTiempoLibreAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, successSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const successSubmit = async () => {
    await refreshPage();
    toggleModal();
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Crear Cuponera" saveButtonDisabled={isUploading} isSubmitting={isSubmitting} width="800px">
      <div className='col-md-12'>
        <InputText label='Nombre' {...commonProps(KEYS.Nombre)} direction='column'/>
      </div>
      <div className="displayFlexWrap">
        <Div><InputDate label='Desde' {...commonProps(KEYS.FechaInicio)} direction='column'/></Div>
        <Div><InputDate label='Hasta' {...commonProps(KEYS.FechaFin)} direction='column'/></Div>
        <Div><InputText label='Prefijo Código' {...commonProps(KEYS.prefijo)} direction='column'/></Div>
        <Div><InputMultiSelect label='División' {...commonPropsSelect(KEYS.IdsDivisiones, [], true)} direction='column'/></Div>
        <Div><InputUploadFile label="Imagen" name={KEYS.ImagenCuponera} uploadFile={formValues[KEYS.ImagenCuponera]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.ImagenCuponera]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={["jpg", "png"]} direction='column'/></Div>
        <Div><InputUploadFile label="Lineamientos" name={KEYS.LineamientosCuponera} uploadFile={formValues[KEYS.LineamientosCuponera]} setUploadFile={(res) => setFormValues({...formValues, [KEYS.LineamientosCuponera]: res})} sectionName={SECTION_NAME} setIsUploading={setIsUploading} validExtensions={["pdf"]} direction='column'/></Div>
      </div>
    </ModalForm>
  )
}

export default CrearCuponera

const Div = ({ children }) => (
  <div className='col-md-6'>
    {children}
  </div>
)