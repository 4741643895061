import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import confetti from "../../../image/confetti.svg"
import { jsonNoEstaVacio } from "../../../helper/utils";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import ModalMensajeSuccessfull from "../../../components/modalMensaje/ModalMensajeSuccessfull";
import PageButtons from "../../../components/pageComponents/PageButtons";
import PageHeader from "../../../components/pageHeader/PageHeader";
import TabsHeader from "../../../components/pageComponents/TabsHeader";
import ListaDocumentos from "./tabs/ListaDocumentos";
import DatosFamiliares from "./tabs/SectionsEdit/DatosFamiliares";
import DatosPersonalesEditar from "./tabs/DatosPersonalesEditar";
import ObservarFichaNuevoIngreso from "./modales/ObservarFichaNuevoIngreso";
import DesistirFichaNuevoIngreso from "./modales/DesistirFichaNuevoIngreso";
import { ALL_SECTION_KEYS_NUEVOS_INGRESOS as SECTION_KEYS } from "../../../constants/hardCodedConstants";
import { mostrarMensajeDeErrorConToast, responseTieneModelState } from "../../../consumers/httpRequiestsUtils";
import { clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput } from "../../../validations/FormValidatorUtils";
import { GetBaseNuevoIngresoGestionAPI, getDocumentosEmpleadoAPI, validarNuevoIngresoAPI } from "../../../consumers/backendApisUrls";

const EditarFichaDatosNuevosIngresos = () => {
  
  const history = useHistory();
  const { id } = useParams();
  const ID_COLABORADOR = 0;
  const [listDocuments, setListDocumentos] = useState({});
  const [modalEnabled, setModalEnabled] = useState({});
  const [permissions, setPermissions] = useState({});
  const [isSending, setIsSending] = useState(false);

  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();

  const init = async () => {
    let permissions = await executeGet(GetBaseNuevoIngresoGestionAPI(id), (res) => setPermissions(res.data));
    if(jsonNoEstaVacio(permissions)) manageSearch();
  }

  const manageSearch = async () => {
    try {
      const api = getDocumentosEmpleadoAPI(id);
      await executeGet(api, (res) => setListDocumentos(res.data));
    } catch (error) {
      console.error(error);
    }
  }

  const handleButtonsOnClick = async (id, api) => {
    setIsSending(true);
    try {
      await executePost(api(id), {}, successSendCallback, false, failureSendCallback, false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successSendCallback = () => {
    init();
    SECTION_KEYS.map(o => clearAllInputValidationMessages(o?.KEYS, o?.SECTION));
    let message = "Datos validados correctamente"
    setModalEnabled({isEnable: true, component: ModalMensajeSuccessfull, data:{ toggleModal: toggleModalAndRedirect, modalMensaje: message, image: confetti }})
  }

  const toggleModalAndRedirect = () => {
    setModalEnabled({isEnable: false});
    history.push("/adminNuevosIngresos");
  }

  const failureSendCallback = (response) => {
    const message_fail = "Faltan completar datos";
    if (responseTieneModelState(response)){
      SECTION_KEYS.map(o => mostrarMensajesDeValidacionEnCadaInput(response, o?.KEYS, o?.SECTION));
      return toast.error(message_fail);
    }
    mostrarMensajeDeErrorConToast(response);
  }

  useEffect(() => {
    init()
  }, [])

  const OPTIONS = [
    {id: "DatosPersonales", title: "Datos Personales", component: <DatosPersonalesEditar idNuevosIngresosEmpleado={id} manageSearchListaDocumentos={manageSearch} inputDisabled={!permissions?.PuedeGuardar}/>},
    {id: "DatosFamiliares", title: "Datos Familiares", component: <DatosFamiliares idNuevosIngresosEmpleado={id} idParent="EditarDatosFamiliaresNuevosIngresosConId" isColaborador={parseInt(id) === ID_COLABORADOR} inputDisabled={!permissions?.PuedeGuardar} fichaStep={permissions.PasoActual}/>},
    {id: "ListaDocumentos", title: "Lista de Documentos", component: <ListaDocumentos listDocuments={listDocuments?.Documentos} idNuevosIngresosEmpleado={id} manageSearchListaDocumentos={manageSearch} inputDisabled={!permissions?.PuedeGuardar} signature={listDocuments?.FirmaFileViewModel} puedeGestionarFirma={listDocuments?.PuedeGestionarFirma}/>},
  ];

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnabled.isEnable &&
          <div className="modal-contactanos modal-response-ficha-background">
            <modalEnabled.component toggleModal={() => setModalEnabled({isEnable: false})} manageSearch={init} {...modalEnabled.data}/>
          </div>
        }
        <PageHeader title="Editar Ficha - Datos Colaborador" />
        <div className="generic-buscador-container mb-4">
          <div className="d-flex flex-column ml-5">
            <label className="ficha-detalle-nombre-completo font-gothan-medium mb-0">{permissions?.NombreCompleto}</label>
            <label className="mb-3" style={{fontSize: "15px"}}>{permissions?.Cargo}</label>
            <label className="ml-2">(<span className="required">*</span>) Dato Obligatorio</label>
          </div>
          <PageButtons hasBackButton>
            <div className="d-flex justify-content-between ml-5" style={{gap: "10px"}}>
              {permissions?.PuedeObservar && <button type="button" disabled={isSending} className="btn generic-button-buscador generic-button-hover-amarillo" onClick={() => setModalEnabled({isEnable: true, component: ObservarFichaNuevoIngreso, data:{ id: id }})} style={{backgroundColor: "#E9C46A"}}>Observado</button>}
              {permissions?.PuedeValidar && <button type="button"  disabled={isSending} className="btn generic-button-buscador generic-button-hover-verde" onClick={() => handleButtonsOnClick(id, validarNuevoIngresoAPI)} style={{backgroundColor: "#2A9D8F"}}>Validado</button>}
              {permissions?.PuedeDesistir && <button type="button" disabled={isSending} className="btn generic-button-buscador generic-button-hover-rojo" onClick={() => setModalEnabled({isEnable: true, component: DesistirFichaNuevoIngreso, data:{ id: id }})} style={{backgroundColor: "#E76F51"}}>Desiste</button>}
            </div>
          </PageButtons>
        </div>
      </div>
      <hr className="my-0"/>
      <TabsHeader list={OPTIONS}/>
    </div>
  );
};

export default EditarFichaDatosNuevosIngresos;