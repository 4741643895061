import React, { useEffect, useState, useContext } from "react";
import InputDate from "../../components/modalComponents/InputDate";
import InputSelect from "../../components/modalComponents/InputSelect";
import InputMultiSelect from "../../components/modalComponents/InputMultiSelect";
import { AuthContext } from "../../hooks/context/authContext";
import { useSubmitForm } from "../../hooks/useSubmitForm/useSubmitForm";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import { usePropsInputs } from "../../hooks/useProps/usePropsInput";
import { usePropsSelect } from "../../hooks/useProps/usePropsSelect";
import { usePostRequest } from "../../hooks/usePostRequest/usePostRequest";
import { useGetOptionsSelect } from "../../hooks/useGetOptionsSelect/useGetOptionsSelect";
import { handleFind, initialAllOptionPromises } from "../../helper/utils";
import { downloadFile } from "../../consumers/backendConsumer";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../consumers/httpRequiestsUtils";
import { deleteFileFromS3API, downloadReporteVacacionesAPI, getAreasVacacionesByDepartamentoAPI, getDepartamentosVacacionesAPI } from "../../consumers/backendApisUrls";

const ReporteDeVacaciones = () => {

  const SECTION_NAME = "ReporteVacaciones";
  const INIT_KEYS = {departamentos: "IdsDepartamentos", areas: "IdsAreas", desde: "FechaInicio", hasta: "FechaFin"};

  const {state} = useContext(AuthContext);

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [loading, setLoading] = useState(false);

  const [executePostRequest] = usePostRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const initialPromises = () => {
    return [
        getOptions(getDepartamentosVacacionesAPI()),
    ]
  }

  const init = async () => {
    await initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), updateFormValues);
  }

  const updateFormValues = (data) => {
    setAllDropdowns(data);
    const targetDepartamentos = handleFind(data, INIT_KEYS.departamentos);
    if (targetDepartamentos?.length === 1){
      const targetDepartamento = targetDepartamentos[0].value;
      setFormValues({ [INIT_KEYS.departamentos]: targetDepartamento })
    }
  }

  const handleDescargarReporte = async () => {
    setLoading(true);
    try {
        const api = downloadReporteVacacionesAPI();
        let payload = formValues;
        if (!formValues[INIT_KEYS.areas])
          { payload[INIT_KEYS.areas] = []}
        await submitForm(Object.values(INIT_KEYS), api, payload, SECTION_NAME, successDescargarReporteCallback)
    } catch (error) {
        console.error(error);
    }
    setLoading(false);
  };

  const successDescargarReporteCallback = async (res) => {
    let data = res.data;
    let reporte = await downloadFile(state.token, data?.FilePath, data?.OriginalFileName);
    if(responseCode200(reporte)){
        await eliminarArchivoDeS3(data?.FilePath);
    } else {
        mostrarMensajeDeErrorConToast(reporte);
    }
  }

  const eliminarArchivoDeS3 = async function (filePath) {
    let deleteFileFromS3Payload = {
        "Path": filePath
    };
    let deleteFileFromS3Response = await executePostRequest(deleteFileFromS3API(), deleteFileFromS3Payload);
    if (responseCode200(deleteFileFromS3Response)) {
        console.debug("delete file from s3 successfully");
    } else {
        mostrarMensajeDeErrorConToast(deleteFileFromS3Response);
    }
  };

  const getAreaDropdowns = async () => {
    const valueId = formValues[INIT_KEYS.departamentos];
    const api = getAreasVacacionesByDepartamentoAPI(valueId);
    const response = await getOptions(api);
    const allDropdownsUpdated = allDropdowns.filter(o => o.key !== INIT_KEYS.areas);
    setAllDropdowns([...allDropdownsUpdated, { key: INIT_KEYS.areas, value: response }]);
    if (response?.length === 1 && response[0].value?.length > 0){
      const targetArea = response[0].value;
      setFormValues(prev => ({...prev, [INIT_KEYS.areas]: [targetArea]}))
    }
  }

  useDidMountEffect(() => {
    if(formValues[INIT_KEYS.departamentos]) getAreaDropdowns();
  }, [formValues[INIT_KEYS.departamentos]])

  useEffect(() => {
    init();
  }, [])

  return (
    <div className='card-body'>
        <div id='ReporteNuevosIngresos'>
          <Div><InputSelect label="Region" {...selectProps(INIT_KEYS.departamentos, [INIT_KEYS.areas])}/></Div>
          <Div><InputMultiSelect label="Sede" {...selectProps(INIT_KEYS.areas, [], true)} /></Div>
          <Div><InputDate label="Fecha Inicio" {...commonProps(INIT_KEYS.desde)}/></Div>
          <Div><InputDate label="Fecha Fin" {...commonProps(INIT_KEYS.hasta)}/></Div>
        </div>
        <div className="reporte-datos-generales-dato-obligatorio">
            <p>(<span className="required ">*</span>) Dato obligatorio</p>
        </div>
        <div className="ml-2">
            <button className="btn btn-primary generic-button-ficha" onClick={handleDescargarReporte} disabled={loading}>{loading ? 'Descargando' : 'Descargar'} Reporte {loading ? '...' : ''}</button>
        </div>
    </div>
  );
};

export default ReporteDeVacaciones;

const Div = ({ children }) => (
    <div className="col-sm-6">
        {children}
    </div>
)