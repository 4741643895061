import React, { useEffect, useState } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import {
  getOnboardingInfoActivityAPI,
  saveConfirmarAsistenciaActivityAPI,
} from "../../../consumers/backendApisUrls";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useForm } from "../../../hooks/useForm/UseForm";
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import useDidMountEffect from "../../../hooks/useDidMountEffect/useDidMountEffect";
import "react-datepicker/dist/react-datepicker.css";
import ModalMensaje from "../../../components/modalMensaje/ModalMensaje";
import {toast} from "react-toastify";

const ConfirmarAsistenciaModal = ({
  showModal,
  IdEmpleadoFlujoActividad,
  manageSearch,
}) => {
  const sectionName = "ConfirmarAsistencia";
  const [value, setValue] = useState({Asistio: 'true'});
  const [responseMessage, setResponseMessage] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dataEmpleado, setDataEmpleado] = useState({});
  const [handleChange] = useForm(value, setValue);
  const [executeGetRequest] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const getInitialData = async () => {
    const api = getOnboardingInfoActivityAPI(IdEmpleadoFlujoActividad);
    await executeGetRequest(api, successInitialDataCallBack);
  };

  const successInitialDataCallBack = (response) => {
    setDataEmpleado(response?.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const keys = ["Asistio"];
      const api = saveConfirmarAsistenciaActivityAPI();
      let payload = getPayload();

      await submitForm(keys, api, payload, sectionName, saveSuccessCallback, false);

    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const getPayload = () => {
    return {
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      Asistio: value?.Asistio,
    };
  };

  const saveSuccessCallback = (response) => {
    let data = response?.data;
    if (data.MostrarCuadro){
      setResponseMessage(data);
      setShowCard(true);
    } else {
      toast.success(data?.Message);
      showModal();
      manageSearch();
    }
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) getInitialData();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    showCard 
    ? (<ModalMensaje 
      toggleModal={showModal} 
      manageSearch={manageSearch} 
      modalMensaje={responseMessage.Message}
      />)
    : (<GenericModal
      showModal={showModal}
      width={"500px"}
      titulo="Confirmar Asistencia"
    >
      <div className="modal-body pt-0 pl-0 pb-0">
        <form onSubmit={handleSubmit}>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">
              Colaborador
            </label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.NombreEmpleado}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">
              Fecha de Ingreso
            </label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.FechaIngreso}
            </label>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 col-form-label modal-label">Sede</label>
            <label className="col-sm-8 col-form-label black-color">
              {dataEmpleado?.NombreSede}
            </label>
          </div>
          <div className="form-group mb-1">
            <label className="col-form-label modal-label">
              Indica si asististe a la sesión de inducción cultural:
            </label>
          </div>
          <div className="form-check mb-4">
            <div className="pl-4">
              <input
                className="form-check-input"
                type="radio"
                id="asistenciaTrue"
                name="Asistio"
                value={true}
                onChange={handleChange}
                checked={value?.Asistio === "true"}
              />
              <label className="form-check-label" htmlFor="asistenciaTrue">
                Sí, asistí
              </label>
            </div>
            <div className="pl-4">
              <input
                className="form-check-input"
                type="radio"
                id="asistenciaFalse"
                name="Asistio"
                value={false}
                onChange={handleChange}
                checked={value?.Asistio === "false"}
              />
              <label className="form-check-label" htmlFor="asistenciaFalse">
                No asistí
              </label>
            </div>
            <p
              className="ficha-form-input-validation-message"
              id={`ficha-edit-${sectionName}-Asistio-validation-message`}
            ></p>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>)
  );
};

export default ConfirmarAsistenciaModal;
