import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericModal from '../../../../../components/modal/GenericModal'
import InputTextArea from '../../../../../components/modalComponents/InputTextArea';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { rechazarCondicionAPI } from '../../../../../consumers/backendApisUrls';

const SECTION_NAME = "noAprobarMotivoMaternidad";
const KEYS = { motivo: "MotivoRechazo" };

const NoAprobarMotivo = ({ toggleModal, refreshPage, toggleMainModal, IdCondicion }) => {

  const [formValues, setFormValues] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [submitForm] = useSubmitForm();

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleAction = async () => {
    setIsSending(true);
    const api = rechazarCondicionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successHandleCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  };

  const getPayload = () => {
    return {
      IdCondicion,
      ...formValues
    }
  }

  const successHandleCallback = async () => {
    toggleModal();
    toggleMainModal();
    await refreshPage();
  }

  return (
    <GenericModal
      titulo="No Aprobar"
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          <InputTextArea label="Motivo" {...commonProps(KEYS.motivo)}/>
        </div>
      </div>
      <div className="modal-footer justify-content-end">
        <button
          className="btn btn-primary generic-button-ficha mr-2"
          disabled={isSending}
          onClick={handleAction}>
          No Aprobar {isSending && (<FontAwesomeIcon icon='spinner' transform="left-9" className="spinner ml-3" />)}
        </button>
        <button
          className="btn btn-primary generic-button-ficha"
          disabled={isSending}
          onClick={() => { toggleModal() }}>
          Cancelar
        </button>
      </div>
    </GenericModal>
  )
}

export default NoAprobarMotivo