import styled from 'styled-components';
import {string} from 'prop-types';

import CircleProgressBarVacunas from './CircleProgressBarVacunas';

const VacunaGraph = styled(CircleProgressBarVacunas)`
  max-width: ${(props)=> props.width || "85%"};
  vertical-align: middle;
  .chart-text {
    fill: ${(props) => props.textColor};
  }
  .chart-number {
    font-size: 0.6em;
    line-height: 1;
    text-anchor: middle;
    transform: translateY(0.1em);
  }
  .chart-label {
    font-size: 0.2em;
    text-transform: uppercase;
    text-anchor: middle;
    transform: translateY(0.7em);
  }
  .figure-key [class*='shape-'] {
    margin-right: 8px;
  }
  .figure-key-list {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .figure-key-list li {
    margin: 5px auto;
  }
  .shape-circle {
    display: block;
    vertical-align: middle;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background-color: ${(props) => props.strokeColor};
    text-transform: capitalize;
  }
  @media (max-width: 992px) {
    max-width: 120px;
  }
`;

VacunaGraph.propTypes = {
    textColor: string,
    strokeColor: string,
    maxSize: string,
};

VacunaGraph.defaultProps = {
    textColor: 'black',
    strokeColor: 'blueviolet',
};

export default VacunaGraph;
