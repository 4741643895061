import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {agregarStringAArraydDeStringsSiNoExiste, eliminarStringDeArraydDeStrings, notNullAndNotUndefinded, stringSeEncuentraEnArray} from "../../helper/utils";
import EditarLiquidacion from './modales/EditarLiquidacion';
import TestEmail from './modales/TestEmail';

const TableWebBody = ({ filas, eliminar, reprocesar, setModalEnabled, setIdsRowsConCheck, idsRowsConCheck, enableMultiSelectInput}) => {
    const checkboxClic = (e, item) => {
        let value = e.target.checked;
        let idFichaCampoSolicitud = item["IdEmpleadoLiquidaciones"];
        let tempSolicitudes = [...idsRowsConCheck];
        if (value === true && !stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = agregarStringAArraydDeStringsSiNoExiste(idFichaCampoSolicitud, tempSolicitudes);
            setIdsRowsConCheck(tempSolicitudes);
        } else if (value === false && stringSeEncuentraEnArray(idFichaCampoSolicitud, tempSolicitudes)) {
            tempSolicitudes = eliminarStringDeArraydDeStrings(idFichaCampoSolicitud, tempSolicitudes);
            setIdsRowsConCheck(tempSolicitudes);
        }
    };

    const isChecked = (fila) => {
        let firstMatch = idsRowsConCheck.find((item) =>  item === fila["IdEmpleadoLiquidaciones"]);
        return (notNullAndNotUndefinded(firstMatch))
    }

    return (
        <tbody className="listar-tbody">
            {filas.map((fila, i) => (
                <tr key={i}>
                    <td>
                        <div className="d-flex align-items-center">
                            {enableMultiSelectInput &&
                                <input type="checkbox" onChange={(e) => checkboxClic(e, fila)} checked={isChecked(fila)}/>
                            }
                            <div style={{margin: '0 10px'}}>{fila?.CodigoTrabajador}</div>
                        </div>
                    </td>
                    <td className="col-1 pl-1 pr-1">{fila?.NombreCompleto}</td>
                    <td className="col-1 pl-1 pr-1">{fila?.Sede}</td>
                    <td className="col-1 pl-1 pr-1 ">{fila?.Cargo}</td>
                    <td className="col-1 pl-1 pr-1 ">{fila?.ProcesoCierreName}</td>
                    <td className="col-1 pl-1 pr-1 ">{fila?.IdCese}</td>
                    <td className="col-1 pl-1 pr-1 ">{fila?.FechaFinContrato}</td>
                    <td className="col-1 pl-1 pr-1 ">{fila?.Estado}</td>
                    <td className="col-1 pl-1 pr-1">
                        <div className="d-flex justify-content-start">
                            {fila?.PuedeEditar &&
                                <div>
                                    <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer ml-3" title="Editar" onClick={() => {
                                        setModalEnabled({isEnabled: true, idEmpleadoLiquidaciones: fila?.IdEmpleadoLiquidaciones, component: EditarLiquidacion})
                                    }} />
                                </div>
                            }
                            {fila?.PuedeEliminar &&
                                <div>
                                    <FontAwesomeIcon icon='times' size="sm" className="hand-pointer ml-3" title="Eliminar" onClick={() => {
                                        eliminar(fila?.IdEmpleadoLiquidaciones)
                                    }} />
                                </div>
                            }
                            {fila?.PuedeReprocesar &&
                                <div>
                                    <FontAwesomeIcon icon='envelope' size="sm" className="hand-pointer ml-3" title="Reprocesar" onClick={() => {
                                        reprocesar(fila?.IdEmpleadoLiquidaciones)
                                    }} />
                                </div>
                            }
                            <div>
                                <FontAwesomeIcon icon='flask' size='sm' className='hand-pointer ml-3' title='Test' onClick={() => {
                                    setModalEnabled({isEnabled: true, idEmpleadoLiquidaciones: fila?.IdEmpleadoLiquidaciones, component: TestEmail})
                                }} />
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

export default TableWebBody;
