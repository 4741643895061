import React from 'react'
import { Div } from './Components';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputSelect from '../../../../components/modalComponents/InputSelect';
import GenericTooltip from '../../../../components/GenericTooltip/GenericTooltip';
import { addDays, getTodayDate, toIsoForInput } from '../../../../helper/utils';

const Gestante = ({ commonProps, selectProps, keys: KEYS, isDisabled = false }) => {

  const NUEVE_MESES_FUTURO = 273;

  return (
    <>
      <Div>
        <InputDate
          label={TooltipText("FPP", "Fecha Probable de Parto")}
          {...commonProps(KEYS.fechaProbable)}
          min={toIsoForInput(addDays(getTodayDate(), 1))}
          max={toIsoForInput(addDays(getTodayDate(), NUEVE_MESES_FUTURO))}
          isDisabled={isDisabled}
        />
      </Div>
      <Div>
        <InputDate
          label={TooltipText("FUM", "Fecha Última Menstruación")}
          {...commonProps(KEYS.fechaUltMenstruacion)}
          max={toIsoForInput(getTodayDate())}
          isDisabled={isDisabled}
        />
      </Div>
      <Div>
        <InputSelect
          label="Tiempo Gestación"
          {...selectProps(KEYS.meses)}
          isDisabled={isDisabled}
        />
      </Div>
    </>
  );
}

export default Gestante;

const TooltipText = (id, text) => (
  <>
    <label>{id}</label>
    <GenericTooltip id={id} text={text}/>
  </>
)