import React, {useState} from 'react'
import GenericModal from '../../../components/modal/GenericModal'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { deleteArchivosFichaDatosAPI } from '../../../consumers/backendApisUrls';

const EliminarArchivo = ({ toggleModal, idEmpleadoArchivoFichaDatos, archivoTitle, manageSearch}) => {

  const [isDeleting, setIsDeleting] = useState(false);
  const [managePost] = useManagePostRequest();

  const eliminarArchivo = async() => {
    setIsDeleting(true);
    try {
        await managePost(deleteArchivosFichaDatosAPI(idEmpleadoArchivoFichaDatos),{}, () => {
            manageSearch();
            toggleModal();
        })
    } catch (error) {
        console.error(error);
    }
    setIsDeleting(false);
  }

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo="Eliminar Archivo">
        <div className="modal-body pt-0 pl-0">
                <div className="container-fluid">
                    Se va a eliminar el siguiente archivo:
                    <ul>
                        <li><strong>{archivoTitle}</strong></li>
                    </ul>
                    ¿Está seguro de esta acción?
                </div>
        </div>
        <div className="modal-footer d-flex justify-content-end">
            <div className="button">
                <button className="btn btn-primary generic-button-ficha mr-2" onClick={() => {toggleModal()}}>
                    Cancelar
                </button>
                <button className="btn btn-primary generic-button-ficha" onClick={() => {eliminarArchivo()}} disabled={isDeleting}>
                    Eliminar
                </button>
            </div>
        </div>
    </GenericModal>
  )
}

export default EliminarArchivo