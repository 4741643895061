import React, { useState } from 'react';
import DatosIdentificacion from './SectionsEdit/DatosIdentificacion';
import DatosNacimiento from './SectionsEdit/DatosNacimiento';
import DatosResidencia from './SectionsEdit/DatosResidencia';
import DatosFormacionAcademica from './SectionsEdit/DatosFormacionAcademica';
import DatosEmpresa from './SectionsEdit/DatosEmpresa';
import DatosInformacionEconomica from './SectionsEdit/DatosInformacionEconomica';
import DatosNuevoIngreso from './SectionsEdit/DatosNuevoIngreso';

const DatosPersonalesEditar = ({ idNuevosIngresosEmpleado, manageSearchListaDocumentos, inputDisabled }) => {

  const ID_COLABORADOR = 0;
  const isColaborador = idNuevosIngresosEmpleado === ID_COLABORADOR;
  const ID_PARENT = "editarDatospersonalesConId";

  const [commonStates, setCommonStates] = useState({});

  const commonProps = () => {
    return {
      idNuevosIngresosEmpleado,
      idParent: ID_PARENT,
      inputDisabled,
      isColaborador
    }
  }

  return (
    <div id={`accordion-${ID_PARENT}`}>
      <DatosIdentificacion {...commonProps()} manageSearchListaDocumentos={manageSearchListaDocumentos}/>
      <DatosNacimiento {...commonProps()}/>
      <DatosResidencia {...commonProps()}/>
      <DatosFormacionAcademica {...commonProps()}/>
      <DatosEmpresa {...commonProps()} commonStates={commonStates} setCommonStates={setCommonStates}/>
      <DatosInformacionEconomica {...commonProps()} commonStates={commonStates} manageSearchListaDocumentos={manageSearchListaDocumentos}/>
      {!isColaborador && <DatosNuevoIngreso {...commonProps()} manageSearchListaDocumentos={manageSearchListaDocumentos}/>}
    </div>
  )
}

export default DatosPersonalesEditar;