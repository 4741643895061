import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {isNullOrUndefinded} from "../utils";

export const criterioDeBusquedaVacio = (criterioDeBusqueda) => {
    return (criterioDeBusqueda === "" || criterioSoloContieneEspacios(criterioDeBusqueda));
};

const criterioSoloContieneEspacios = (criterioDeBusqueda) => {
    return !criterioDeBusqueda.replace(/\s/g, '').length;
};

export const obtenerPalabrasDeCriterioDeBusqueda = (criterioDeBusqueda) => {
    let palabras = criterioDeBusqueda.toLowerCase().split(" ");
    palabras = palabras.filter((palabra) => {
        return palabra != ""
    });
    return palabras;
};

export const obtenerUltimaPagina = (rows, filasPorPagina) => {
    return Math.ceil(rows.length / filasPorPagina);
};

const getDropdownOptions = async (getFunction) => {
    const response = await getFunction();
    if (responseCode200(response)) {
        return response.data;
    } else {
        mostrarMensajeDeErrorConToast(response);
    }
};

export const ifAllOptionsIsEmptyRequestForAllOptionsAgain = async function (allOptions, getFunction) {
    if (isNullOrUndefinded(allOptions) || allOptions.length === 0) {
        return await getDropdownOptions(getFunction);
    }
    return allOptions;
};

export const updateOptionsDeFiltro = async function (allOptions, optionsIds = [], setNewTempOptions, getFunction) {
    let newOptions = [];
    allOptions = await ifAllOptionsIsEmptyRequestForAllOptionsAgain(allOptions, getFunction);
    if (allOptions && allOptions.length > 0) {
        for (let optionId of optionsIds) {
            let firstMatch = allOptions.find((element) => (element.Value === optionId));
            if (firstMatch) newOptions = [...newOptions, firstMatch];
        }
        setNewTempOptions(newOptions);
    }
};