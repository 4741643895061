import React, {useState} from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { activarInactivarRegistroInvitacionAPI } from "../../../consumers/backendApisUrls";

const ActivarInactivarRegistroInvitacion = ({
  row,
  toggleModal,
  refreshTable,
}) => {
  const [managePost] = useManagePostRequest();
  const [isSending, setIsSending] = useState(false);

  const activarInactivarRegistro = async () => {
    setIsSending(true);
    try {
      const api = activarInactivarRegistroInvitacionAPI(row.IdRegistroInvitacion);
      await managePost(api, {}, successActivarInactivarCallBack, true);
      
    } catch (error) {
      console.error(error);
    }
    setIsSending(false);
  };

  const successActivarInactivarCallBack = () => {
    refreshTable();
    toggleModal();
  };

  return (
    <GenericModal
      titulo={row.EsActivo ? "Inactivar Registro" : "Activar Registro"}
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {row.EsActivo ? "inactivar" : "activar"} el siguiente registro:
          <ul>
            <li>
              <strong>{row.Unidad}</strong>
            </li>
            <li className="react-autosuggest__suggestions-list">
            Fecha: <strong>{row.Fecha}</strong>
            </li>
            <li className="react-autosuggest__suggestions-list">
            Hora: <strong>{row.Hora}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            disabled={isSending}
            onClick={activarInactivarRegistro}
          >
            {row.EsActivo ? "Inactivar" : "Activar"}
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancelar
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default ActivarInactivarRegistroInvitacion;
