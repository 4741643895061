import styled from 'styled-components';

const ActionsIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  svg{
    transition: color 0.3s;

    &:hover{
      color: #3a87c8;
    }
  }
`;

export default ActionsIcons