import React, { useState, useEffect, useContext } from 'react'
import { initialAllOptionPromises } from '../../../helper/utils';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { AuthContext } from '../../../hooks/context/authContext';
import InputDeclaracionJurada from '../../../components/modalComponents/InputDeclaracionJurada';
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import InputSelect from '../../../components/modalComponents/InputSelect';
import InputText from '../../../components/modalComponents/InputText';
import InputDate from '../../../components/modalComponents/InputDate';
import ModalForm from '../../../components/modalComponents/ModalForm'
import { crearConvocatoriaTrasladoEmpleadoAPI, getAllSedesAPI, getConvocatoriaTrasladosToEditAPI, getEspecialidadSeleccionAPI, getTiposTrasladoAPI } from '../../../consumers/backendApisUrls';

const DetallePostularConvocatoria = ({ toggleModal, refreshPage, title, isApply, IdConvocatoriaTraslado }) => {

  const { state } = useContext(AuthContext);

  const SECTION_NAME = "DestalleConvotacoria";
  const ID_TYPES = {TipoTraslado: "IdTipoTraslado", MatrizEspecialidadSeleccion: "IdMatrizEspecialidadSeleccion"};
  const KEYS = ["IdSedePrimeraOpcion", "IdSedeSegundaOpcion", "Motivo"];
  const IdEmpleado = state.user.id_empleado;

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [inputProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [selectProps] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [executeGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      getOptions(getTiposTrasladoAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
    ]
  }
  
  const init = () => {
    try {
      initialAllOptionPromises(initialPromises, Object.values(ID_TYPES), setAllDropdowns);
      manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getConvocatoriaTrasladosToEditAPI(IdConvocatoriaTraslado);
    await executeGet(api, handleSearchCallback);
  }

  const handleSearchCallback = (res) => {
    setFormValues(res.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const api = crearConvocatoriaTrasladoEmpleadoAPI();
    const payload = getPayload();
    await submitForm(KEYS, api, payload, SECTION_NAME, handleSubmitCallback);
  }

  const getPayload = () => {
    let { IdMatrizEspecialidadSeleccion, IdSedePrimeraOpcion, IdSedeSegundaOpcion, Motivo, AceptaDj } = formValues;
    return {
      IdConvocatoriaTraslado,
      IdEmpleado,
      IdMatrizEspecialidadSeleccion,
      IdSedePrimeraOpcion,
      IdSedeSegundaOpcion,
      Motivo,
      AceptaDj
    }
  }

  const handleSubmitCallback = () => {
    refreshPage();
    toggleModal();
  }

  const handleFilterSecondOption = (res) => {
    return res.filter(o => o.value !== formValues.IdSedePrimeraOpcion);
  }

  useEffect(() => {
    init();
  }, [])

  if(isLoading) return <SpinnerLoading />

  return (
    <ModalForm onSubmit={handleSubmit} titulo={title} showModal={toggleModal} width="900px" hasSaveButton={false}>
      <div className='mt-2'>
        <label className='mb-0'>Datos de Traslado</label>
        <hr className='mt-0'/>
      </div>
      <div className="displayFlexWrap mt-4">
        <div className='col-md-6'><InputSelect label="Tipo" {...selectProps("IdTipoTraslado")} direction="row" isDisabled/></div>
        <div className='col-md-6'><InputDate label="Inicio Publicación" {...inputProps("FechaInicio")} direction="row" isDisabled/></div>
        <div className='col-md-6'><InputDate label="Fin Publicación" {...inputProps("FechaFin")} direction="row" isDisabled/></div>
        <div className='col-md-6'><InputDate label="Cierre Convocatoria" {...inputProps("FechaCierre")} direction="row" isDisabled/></div>
        <div className='col-md-12'><InputText label="Nombre de convocatoria" {...inputProps("Nombre")} direction="row" isDisabled/></div>
      </div>
      <div className="displayFlexWrap">
        <div className='col-md-12'><InputTextArea label="Descripción y etapas del proceso" {...inputProps("Descripcion")} direction="row" maxLength="500" isDisabled/></div>
        <div className='col-md-12'><InputTextArea label="¿Cómo se evaluarán las solicitudes de traslado de sede?" {...inputProps("ComoSeEvaluaSolicitud")} direction="row" maxLength="1000" isDisabled/></div>
        <div className='col-md-12'><InputTextArea label="Requisitos" {...inputProps("Requisitos")} direction="row" maxLength="500" isDisabled/></div>
      </div>
      {isApply &&
        <>
          <hr />
          <div className="displayFlexWrap">
            <div className='col-md-12'><InputSelect label="Curso / nivel que dicto actualmente" {...selectProps("IdMatrizEspecialidadSeleccion")} direction="row"/></div>
            <div className='col-md-12'><InputSelectCascade label="Primera Opción" dependendOption={isApply} isIdSearch={false} api={getAllSedesAPI} {...inputProps("IdSedePrimeraOpcion", ["IdSedeSegundaOpcion"])} direction="row"/></div>
            <div className='col-md-12'><InputSelectCascade label="Segunda Opción" dependendOption={formValues.IdSedePrimeraOpcion} isIdSearch={false} api={getAllSedesAPI} dropdownFilter={handleFilterSecondOption} {...inputProps("IdSedeSegundaOpcion")} direction="row"/></div>
            <div className='col-md-12'><InputTextArea label="Motivo" {...inputProps("Motivo")} direction="row" maxLength="500"/></div>
            <div className='col-md-12'><InputDeclaracionJurada {...inputProps("AceptaDj")} declaracionJurada={declaracionJurada}/></div>
            
          </div>
        </>
      }
      <div className="modal-footer justify-content-end">
          <button 
            type='button'
            className="btn btn-primary generic-button-ficha" 
            onClick={() => {toggleModal()}}>
            Cerrar
          </button>
          {isApply &&
            <button 
              type='submit'
              className="btn btn-primary generic-button-ficha mr-2">
              Enviar
            </button>
          }
      </div>
    </ModalForm>
  )
}

export default DetallePostularConvocatoria

const declaracionJurada =
<>
  <div className='pb-1'>Por medio de la presente confirmo que la información que he registrado en el presente formulario es verídica y doy conformidad a los siguientes condiciones del proceso:</div>
  <ul className='ml-2 pl-1'>
    <li className='react-autosuggest__suggestions-list'>(a) He informado a mi director/a sobre mi postulación al traslado de sede, la respuesta que brinde será verificada con el/ director/a de mi sede.</li>
    <li className='react-autosuggest__suggestions-list'>(b) Cumplo con TODOS los requisitos solicitados.</li>
    <li className='react-autosuggest__suggestions-list'>(c) Estoy de acuerdo que GDH me ofrezca trasladarme a cualquiera de las opciones seleccionadas  considerando el curso y nivel que dicto actualmente.</li>
  </ul>
</>