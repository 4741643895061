import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { NavDropdown } from 'react-bootstrap';
import TableWeb from './TableWeb';
import TableWebBody from './TableWebBody';
import PageHeader from '../../../components/pageHeader/PageHeader'
import PageButtons from '../../../components/pageComponents/PageButtons';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import CustomAlert from '../../../components/alerts/CustomAlert';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../../components/pageComponents/SearchContainer';
import PageLoadingRedirectHomeModal from '../../../components/modal/PageLoadingRedirectHomeModal';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import NuevaConfiguracion from './modales/NuevaConfiguracion';
import EditarConfiguracion from './modales/EditarConfiguracion';
import { downloadFile } from '../../../consumers/backendConsumer';
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils';
import { getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getProcesosSolicitudDescuentoPensionAPI, listarSolicitudesDescuentoPensionAPI, solicitudDescuentoPensionDescargarExcelAPI } from '../../../consumers/backendApisUrls';

const GestionSolicitudDescuentoPension = () => {

  const {state} = useContext(AuthContext);
  const idEmpleado = state.user.id_empleado;
  const INIT_KEYS = {Sedes: "IdsSedes", Cargos: "IdsCargos", EstadoSolicitud: "IdsEstadoSolicitud", EstadoEmpleado: "IdsEstadoEmpleado"};
  const KEYS_BLOCK = {Procesando: "ProcesandoArchivo", Error: "HuboErrorEnLaUltimaValidacion", FechaFin: "FechaFinUltimaValidacionExcel", EsVigente: "EsVigente", PasaronTresDias: "PasaronTresDiasDespuesDeUltimaValidacion", FechaInicioUltimaValidacion: "FechaInicioUltimaValidacionExcel", LogUltimaValidacionFileViewModel: "LogUltimaValidacionFileViewModel", PuedeEditarProceso: "PuedeEditarProceso"};
  
  const [procesosSolicitud, setProcesosSolicitud] = useState({IdsProcesoSolicitud: [], procesoSelected: null, ProcesandoArchivo: false});
  const [formValues, setFormValues] = useState({Items: [], ExtraData: {}, filters: [], selectedFilters: {}});
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({isEnable: false});

  const [executeG] = useGetRequest();
  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeG(getAllSedesAPI()),
      executeG(getAllCargosAPI()),
      executeG(getAllEstadosAPI()),
      executeG(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await getProcesos({ isNew: true });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getProcesos = async ({ isNew = true }) => {
    const api = getProcesosSolicitudDescuentoPensionAPI(idEmpleado);
    await executeGet(api, (res) => sucessInitCallback(res, isNew));
  }

  const sucessInitCallback = ({ data }, isNew) => {
    const IdsProcesoSolicitud = data.map(o => ({...o, value: o.IdProcesoSolicitudDescuentoPension, label: o.Nombre}));
    const isProcesando = IdsProcesoSolicitud.some(proceso => proceso[KEYS_BLOCK.Procesando]);

    if (isProcesando) {
      setModalEnabled({ isEnable: true, component: PageLoadingRedirectHomeModal });
      return;
    }

    const procesoSelected = isNew
    ? IdsProcesoSolicitud.find(o => o[KEYS_BLOCK.EsVigente]) ?? null
    : (IdsProcesoSolicitud.find(o => o.value === procesosSolicitud.procesoSelected?.value) ?? null);

    setProcesosSolicitud(lastData => ({...lastData, IdsProcesoSolicitud, procesoSelected }));
  }

  const manageSearch = async () => {
    const api = listarSolicitudesDescuentoPensionAPI();
    const payload = getPayload();
    await executePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      IdProcesoSolicitudDescuentoPension: procesosSolicitud.procesoSelected?.value,
      EstadoRegistro: true,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data.data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleSelectedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleDownloadExcel = async () => {
    let payload = {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      IdProcesoSolicitudDescuentoPension: procesosSolicitud.procesoSelected?.value,
      EstadoRegistro: true,
      ...formValues.selectedFilters
    };
    await downloadAndDelete(solicitudDescuentoPensionDescargarExcelAPI(), payload);
  }

  const handleEditSelect = () => {
    const IdProcesoSolicitudDescuentoPension = procesosSolicitud.procesoSelected?.value;
    if (IdProcesoSolicitudDescuentoPension === null || IdProcesoSolicitudDescuentoPension === undefined){
      return toast.warning("Debe Seleccionar un proceso")
    }
    setModalEnabled({isEnable: true, component: EditarConfiguracion, data:{ IdProcesoSolicitudDescuentoPension: procesosSolicitud.procesoSelected?.value, getProcesos, PuedeEditarProceso: procesosSolicitud.procesoSelected?.[KEYS_BLOCK.PuedeEditarProceso] }})
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [procesosSolicitud.procesoSelected, paginator.CurrentPage, formValues.selectedFilters ])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleSelectedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Gestión de descuento en la pensión escolar"/>
      {(procesosSolicitud.procesoSelected?.[KEYS_BLOCK.Error] && !procesosSolicitud.procesoSelected?.[KEYS_BLOCK.PasaronTresDias]) &&
        <CustomAlert variant="danger">
          <div className='d-flex'>
            <p className="ficha-secciones-title-text font-gothan-light">
              {`Hubo un error en la última validación de archivo ${procesosSolicitud.procesoSelected?.[KEYS_BLOCK.FechaInicioUltimaValidacion]?.split("T")[0]}`}
            </p>
            <button className='generic-button-ficha ml-2 px-2' style={{fontSize: "12px"}}
              onClick={() => downloadFile(
                state.token,
                procesosSolicitud.procesoSelected?.[KEYS_BLOCK.LogUltimaValidacionFileViewModel]?.Path,
                procesosSolicitud.procesoSelected?.[KEYS_BLOCK.LogUltimaValidacionFileViewModel]?.OriginalFileName,
              )}>
              Descargar Log
            </button>
          </div>
        </CustomAlert>
      }
      <SelectProcesoSolicitud procesosSolicitud={procesosSolicitud} setProcesosSolicitud={setProcesosSolicitud}/>
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons >
          <div className="">
            <button className="generic-button-buscador" type="button" onClick={handleDownloadExcel}>
                Descargar Excel
            </button>
          </div>
          <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Configuración</span>}>
            <NavDropdown.Item className='font-gothan-light' onClick={() => setModalEnabled({isEnable: true, component: NuevaConfiguracion, data:{ setProcesosSolicitud, getProcesos }})}>Cargar nuevo</NavDropdown.Item>
            {(procesosSolicitud.IdsProcesoSolicitud.length > 0) &&
              <NavDropdown.Item className='font-gothan-light' onClick={handleEditSelect}>{procesosSolicitud.procesoSelected?.[KEYS_BLOCK.PuedeEditarProceso] ? "Editar actual" : "Ver actual"}</NavDropdown.Item>
            }
          </NavDropdown>
        </PageButtons>
      </SearchContainer>
      <TableWeb paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS} filters={formValues.filters} selectedFilters={formValues.selectedFilters} setFormValues={setFormValues}>
        <TableWebBody filas={formValues.Items} setModalEnabled={setModalEnabled}/>
      </TableWeb>
    </PageWrapper>
  )
}

export default GestionSolicitudDescuentoPension;

const SelectProcesoSolicitud = ({ procesosSolicitud, setProcesosSolicitud }) => (
  <div className='col-sm-4 mt-2 ml-2'>
    <Select 
      options={procesosSolicitud.IdsProcesoSolicitud}
      value={procesosSolicitud.procesoSelected}
      onChange={(res) => setProcesosSolicitud(lastData => ({...lastData, procesoSelected: res}))}
      placeholder="Seleccionar proceso"
    />
  </div>
)