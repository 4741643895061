import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {NavDropdown} from 'react-bootstrap';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import FormSelect from '../../components/atoms/Select';
import SearchBar from '../../components/pageComponents/SearchBar';
import PageButtons from '../../components/pageComponents/PageButtons';
import PaginatorV2 from '../../components/pageComponents/PaginatorV2';
import TableWebBody from "./TableWebBody";
import CrearPlantilla from "./modales/CrearPlantilla";
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { getAllEstadosAPI, getAllTemplatesAPI, getTiposTemplateAPI } from '../../consumers/backendApisUrls';
import SpinnerLoading from '../../components/pageComponents/SpinnerLoading';

const GestionPlantillas = () => {

  const [allStateOptions, setAllStatesOptions] = useState([]);
  const [allTemplateTypes, setAllTemplateTypes] = useState([]);
  const [filterSelected, setFilterSelected] = useState({});

  const [formValues, setFormValues] = useState([]);
  const [extraData, setExtraData] = useState({});
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);
  
  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1})

  const [executePost] = useManagePostRequest();
  const [executeGet] = useManageGetRequest();

  const init = async () => {
    setLoading(true);
    try {
      executeGet(getAllEstadosAPI(), (res) => setAllStatesOptions(res.data));
      executeGet(getTiposTemplateAPI(), (res) => setAllTemplateTypes(res.data));
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const manageSearch = async () => {
    const api = getAllTemplatesAPI();
    let payload = getPayload();
    await executePost(api, payload, successManageSearchCallback);
  };

  const getPayload = () => {
    return {
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
      IdsTemplatesTypes: filterSelected?.IdsTemplatesTypes,
      IdsEstados: filterSelected?.IdsEstados,
    };
  };

  const successManageSearchCallback = (response) => {
    setFormValues(response.data.Items);
    setExtraData(response.data.ExtraData);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  };

  const handleSelectedFilter = (res, name) => {
    setFilterSelected(lastData => ({...lastData, [name]: res}));
  };

  const limpiarBusqueda = () => {
    setSearchCriteria("");
    setPaginator(lastData => ({...lastData, CurrentPage: 1}));
    setFilterSelected({});
  };

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, filterSelected]);

  useEffect(() => {
    init();
  }, []);

  if (loading) return <SpinnerLoading />

  return (
    <div className="app-wrapper">
      <div className="no-disponible-en-mobile">
        {modalEnabled.isEnable &&
          <div className="modal-contactanos modal-response-ficha-background">
            <modalEnabled.component toggleModal={() => setModalEnabled({isEnable: false})} refreshPage={manageSearch} {...modalEnabled.data}/>
          </div>
        }
        <Wrapper className="App">
          <PageHeader title={"Administración de plantillas"} />
          <div className="generic-buscador-container">
            <SearchBar value={searchCriteria} onChange={setSearchCriteria} cleanSearch={limpiarBusqueda} manageSearch={manageSearch}/>
            <PageButtons handleCreateButton={() => setModalEnabled({isEnable: true, component: CrearPlantilla})} hasCreateButton labelCreate="Crear Plantilla">
              <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Administrar</span>}>
                <Link className='dropdown-item' to={"/adminEtiquetas"}>Administrar Etiquetas</Link>
                <Link className='dropdown-item' to={"/adminComponentes"}>Administrar Componentes</Link>
              </NavDropdown>
            </PageButtons>
          </div>
          <div className="container-fluid body-content">
            <div className="table-responsive form-group-sm pt-3">
              <table className="table table-hover listar-table-cierre-anio">
                <thead className="listar-thead">
                  <tr className="font-gothan-medium">
                    <th className="col-5 pr-1">Plantilla</th>
                    <th className="col-3 pr-1">
                      <FormSelect
                        options={allTemplateTypes.filter(o => extraData?.IdsTemplatesTypes?.includes(o.Value))}
                        description="Tipo"
                        selectedOptions={filterSelected?.IdsTemplatesTypes}
                        setSelectedOptions={(res) => handleSelectedFilter(res, "IdsTemplatesTypes")}
                      />
                    </th>
                    <th className="col-3 pr-1">
                      <FormSelect
                        options={allStateOptions.filter(o => extraData?.IdsEstados?.includes(o.Value))}
                        description="Estado"
                        selectedOptions={filterSelected?.IdsEstados}
                        setSelectedOptions={(res) => handleSelectedFilter(res, "IdsEstados")}
                      />
                    </th>
                    <th className="col-1 pr-1">Acciones</th>
                  </tr>
                </thead>
                <TableWebBody filas={formValues} setModalEnabled={setModalEnabled}/>
              </table>
              <PaginatorV2 paginator={paginator} setPaginator={setPaginator}/>
            </div>
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default GestionPlantillas;
