import React, {useEffect, useRef, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import {useForm} from '../../../hooks/useForm/UseForm';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {editarPoliticaAPI, getAllCargosAPI, getAllColorsAPI, getDetalleEditarPoliticaAPI, getDivisionesAPI, listarAllCategoriasAPI} from "../../../consumers/backendApisUrls";
import GenericUploadFileV2 from "../../../components/GenericUploadFile/GenericUploadFileV2";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";
import {convertFromRaw, EditorState, convertToRaw} from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SelectInput from '../../../components/Select/SelectInput';
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric, notNullAndNotUndefinded, responseUploadFileSuccess} from '../../../helper/utils';
import {Editor} from 'react-draft-wysiwyg';
import {richTextToolbarOptions} from './richTextToolbar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import MultiSelectInput from '../../../components/MultiSelectInput/MultiSelectInput';
import {useGetRequest} from '../../../hooks/useGetRequest/useGetRequest';
import {mostrarMensajeDeErrorConToast, responseCode200} from '../../../consumers/httpRequiestsUtils';
import {AuthContext} from '../../../hooks/context/authContext';
import {useContext} from 'react';
import {uploadFile} from '../../../consumers/backendConsumer';
import {toast} from 'react-toastify';
import {estados} from '../../../constants/states';
import CustomInfoTooTip from "../../../components/tooltip/CustomInfoToolTip";


const EditarPolitica = ({showModal, politicaId, refreshPage}) => {
    const {state} = useContext(AuthContext);
    const sectionName = "crearNuevaPolitica";
    const multiselectPlaceholder = "Selecciona una o más opciones";

    const [manageGetRequest] = useManageGetRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const [formFileValues, setFormFileValues] = useState({});
    const [handleFormFileChange] = useForm(formFileValues, setFormFileValues);

    const [detalle, setDetalle] = useState({});

    const [coloresOptions, setColoresOptions] = useState([]);
    const [colorSeleccionado, setColorSeleccionado] = useState(null);

    const [estadoOptions, setEstadoOptions] = useState([
        {value: estados.Activo.id, label: estados.Activo.nombre},
        {value: estados.Inactivo.id, label: estados.Inactivo.nombre},
    ]);

    const [estadoSeleccionado, setEstadoSEleccionado] = useState(null);


    const [uploadedFile, setUploadedFile] = useState([]);
    const [initialUploadedFile, setInitialUploadedFile] = useState(null);
    const INPUT_FILE_ICONO = "INPUT_FILE_ICONO";
    const EXTENSIONES_VALIDAS = ["png", "jpg"];

    const [politicasFilesUploaded, setPoliticasFilesUploaded] = useState([]);
    const hiddenFileInput = useRef(null);

    const [divisionesOptions, setDivisionesOptions] = useState([]);
    const [jsonSelectedDivisionValue, setJsonSelectedDivisionValue] = useState([]);

    const [cargosOptions, setCargosOptions] = useState([]);
    const [jsonSelectedCargosValueInclude, setJsonSelectedCargosValueInclude] = useState([]);
    const [jsonSelectedCargosValueExclude, setJsonSelectedCargosValueExclude] = useState([]);

    const [editorState, setEditorState] = useState();

    const [categoriasParentOptions, setCategoriasParentOptions] = useState([]);
    const [categoriaParentSeleccionado, setCategoriaParentSeleccionado] = useState({});

    const [categoriasParentsConChilds, setCategoriasParentsConChilds] = useState([]);

    const [categoriasChildOptions, setCategoriasChildOptions] = useState([]);
    const [categoriaChildSeleccionado, setCategoriaChildSeleccionado] = useState({});


    const descripcionComoTexto = 0;
    const descripcionComoImagen = 1;
    const textDescriptionMode = {value: descripcionComoTexto, label: "Texto"};
    const imageDescriptionMode = {value: descripcionComoImagen, label: "Imagen"};

    const [descriptionOptions] = useState([textDescriptionMode, imageDescriptionMode]);
    const [descriptionOptionSelected, setDescriptionOptionSelected] = useState({});

    const [uploadedFileDescription, setUploadedFileDescription] = useState({});
    const [initialUploadedFileDescription, setInitialUploadedFileDescription] = useState({});
    const INPUT_FILE_DESCRIPCION = "INPUT_FILE_DESCRIPCION";
    const EXTENSIONES_VALIDAS_DESCRIPTION = ["png", "jpg"];

    const [uploadedFileMasInfo, setUploadedFileMasInfo] = useState({});
    const [initialUploadedFileMasInfo, setInitialUploadedFileMasInfo] = useState({});
    const INPUT_FILE_MAS_INFO = "INPUT_FILE_MAS_INFO";
    const EXTENSIONES_VALIDAS_MAS_INFO = ["png", "jpg"];


    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    useEffect(() => {
        if (notNullAndNotUndefinded(categoriaParentSeleccionado?.value)) {
            let parent = categoriasParentsConChilds.find(o => o?.IdCategoria == categoriaParentSeleccionado?.value);
            const categoriasChilds = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(parent?.childs, "IdCategoria", "NombreCategoria");
            setCategoriasChildOptions(categoriasChilds);
            if (categoriasChilds.length === 0)
                setCategoriaChildSeleccionado({'value': null})
        }
    }, [categoriaParentSeleccionado]);

    const updateOptionBasedOnResponse = (apiOptions, setStatee) => {
        let options = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(apiOptions);
        setStatee(options);
    };

    const setOptionsDropDownIndependiente = async (getApiFunction, setStatee) => {
        const response = await getApiFunction(state.token);
        if (responseCode200(response)) {
            updateOptionBasedOnResponse(response.data, setStatee);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const callbackSuccessGetDetalle = function (response) {
        setDetalle(response.data);

        setFormValues({
            "Nombre": response.data?.Nombre,
            "FechaNuevo": response.data?.FechaNuevoValue,
            "FechaVigencia": response.data?.FechaVigenciaValue,
            "MostrarFecha": response.data?.MostrarFechaPublicacion
        });

        setEstadoSEleccionado({value: response.data?.IdEstado, label: response.data?.Estado});

        setJsonSelectedDivisionValue(response.data?.IdsDivisiones);
        setJsonSelectedCargosValueInclude(response.data?.IdsCargosIncluir);
        setJsonSelectedCargosValueExclude(response.data?.IdsCargosExcluir);

        setUploadedFile(response.data.Icono);
        setInitialUploadedFile(response.data.Icono);

        setPoliticasFilesUploaded(response.data?.Files);
        const aux = response?.data?.Files?.forEach((elemtent) => {
            setFormFileValues(prev => ({
                ...prev,
                [elemtent.File.OriginalFileName]: elemtent.Nombre
            }))
        });

        setInitialUploadedFileMasInfo(response.data.MasInfoFile);
        setUploadedFileMasInfo(response.data.MasInfoFile);

        if (isDescriptionTextMode(response.data.Descripcion)) {
            setDescriptionOptionSelected(textDescriptionMode);
            const parsedContent = convertFromRaw(JSON.parse(response.data.Descripcion));
            setEditorState(() => EditorState.createWithContent(parsedContent))
        } else {
            setDescriptionOptionSelected(imageDescriptionMode);
            setUploadedFileDescription(response.data.DescriptionFile);
            setInitialUploadedFileDescription(response.data.DescriptionFile);


        }
    }

    const getInitialData = async () => {
        try {
            let responseDetalle = await manageGetRequest(getDetalleEditarPoliticaAPI(politicaId), callbackSuccessGetDetalle, false);
            let responseListarCategorias = await manageGetRequest(listarAllCategoriasAPI(), ()=>{}, false);
            let responseGetAllColors = await manageGetRequest(getAllColorsAPI(), ()=>{}, false);

            await setOptionsDropDownIndependiente(executeGetRequestCallback(getDivisionesAPI()), setDivisionesOptions);
            await setOptionsDropDownIndependiente(executeGetRequestCallback(getAllCargosAPI()), setCargosOptions);

            if (responseCode200(responseGetAllColors) && responseCode200(responseDetalle)) {
                callbackSuccessGetAllColors(responseGetAllColors, responseDetalle);
            }

            if (responseCode200(responseListarCategorias) && responseCode200(responseDetalle)){
                callbackSuccessGetCategorias(responseListarCategorias, responseDetalle)
            }

        } catch (e) {
            console.error(e);
        }
    };

    const callbackSuccessGetCategorias = (responseListarCategorias, responseDetalle) => {
        let allCategorias = responseListarCategorias?.data;
        let parents = allCategorias.filter((value) => value?.IdParent === null);
        let childs = allCategorias.filter((value) => value?.IdParent !== null);

        let allParentsConChilds = [];
        for (const parent of parents) {
            let childss = childs.filter((o) => o?.IdParent === parent?.IdCategoria);
            allParentsConChilds = [...allParentsConChilds,
                {
                    ...parent, childs: [...childss]
                }]
        }

        setCategoriasParentsConChilds(allParentsConChilds);

        const categoriasParentOptionsTemp = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(parents, "IdCategoria", "NombreCategoria");
        setCategoriasParentOptions(categoriasParentOptionsTemp);

        if (responseDetalle?.data?.IdCategoria) {
            if (idCategoriaBelongsToParent(responseDetalle?.data?.IdCategoria, parents)) {
                const categoriaParentSeleccionado = categoriasParentOptionsTemp?.find(item => item.value == responseDetalle?.data?.IdCategoria);
                setCategoriaParentSeleccionado(categoriaParentSeleccionado);
                setCategoriaChildSeleccionado({'value': null})
            } else {
                let childObject = childs.find(o => o.IdCategoria === responseDetalle?.data?.IdCategoria);
                let parentId = childObject?.IdParent;

                const categoriaParentSeleccionado = categoriasParentOptionsTemp?.find(item => item.value == parentId);
                setCategoriaParentSeleccionado(categoriaParentSeleccionado);

                let parentObject = allParentsConChilds?.find(o => o.IdCategoria === parentId);
                let childsObjects = parentObject?.childs;

                const childsOptionsTemp = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(childsObjects, "IdCategoria", "NombreCategoria");
                setCategoriasChildOptions(childsOptionsTemp);

                const categoriaChildSeleccionado = childsOptionsTemp?.find(item => item.value == responseDetalle?.data?.IdCategoria);
                setCategoriaChildSeleccionado(categoriaChildSeleccionado)
            }
        }
    }

    const idCategoriaBelongsToParent = (IdCategoria, parents) => {
        return (parents?.find((o) => o.IdCategoria === IdCategoria) === undefined) ? false : true;
    }

    const callbackSuccessGetAllColors = (responseGetAllColors, responseDetalle) => {
        const coloresTemp = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(responseGetAllColors.data, "Value", "Key");
        const colores = coloresTemp.map(item => ({
            ...item,
            label: (
                <>
                    <FontAwesomeIcon icon={"square"} color={item.Key}/>{' '}{item.Key}
                </>
            )
        }));
        setColoresOptions(colores);

        const colorObject = colores?.find(item => item.value == responseDetalle?.data?.IdColor);
        setColorSeleccionado(colorObject);
    }

    const successSubmitCallback = function () {
        refreshPage();
        showModal();
    }

    const manageUploadFileResponse = (response) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            toast.success("El archivo se cargó exitosamente");
            return s3Response;
        } else {
            toast.error("Hubo un error al subir el archivo");
            return null;
        }
    };

    const submit = async () => {
        let payload = armarPayload();
        let api = editarPoliticaAPI();
        let keys = [
            "Nombre",
            "IdCategoria",
            "Descripcion",
            "IdColor",
            "Icono",
            "IdsDivisiones",
            "Files",
            "IdsCargosIncluir",
            "IdsCargosExcluir",
            "DescriptionFile"
        ];

        await submitForm(keys, api, payload, sectionName, successSubmitCallback);
    };

    const armarPayload = () => {
        const filesAux = politicasFilesUploaded.map(element => ({
            Nombre: formFileValues[element?.File?.OriginalFileName],
            File: element.File
        }))

        let payload = {
            "IdPolitica": politicaId,
            "Nombre": formValues?.Nombre,
            "IdCategoria": getIdCategoria(),
            "Descripcion": getDescripcion(),
            "DescriptionFile": getDescripcionFile(),
            "MasInfoFile": uploadedFileMasInfo,
            "IdColor": colorSeleccionado?.value,
            "Icono": uploadedFile,
            "FechaNuevo": formValues?.FechaNuevo,
            "FechaVigencia": formValues?.FechaVigencia,
            "IdsDivisiones": jsonSelectedDivisionValue,
            "IdsCargosIncluir": jsonSelectedCargosValueInclude,
            "IdsCargosExcluir": jsonSelectedCargosValueExclude,
            "MostrarFechaPublicacion": formValues?.MostrarFecha,
            "Files": filesAux,
        }

        return payload;
    }

    const getDescripcionFile = () => (descriptionOptionSelected?.value === descripcionComoImagen) ? uploadedFileDescription :  null

    const getDescripcion = () => {
        let empty = "{\"blocks\":[{\"key\":\"22ad0\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}";
        if (descriptionOptionSelected?.value === descripcionComoImagen)
            return null;

        try{
            return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        }catch (e){
            return empty;
        }


    }

    const getIdCategoria = () => {
        return showCategoriaChildInput() ? categoriaChildSeleccionado?.value : categoriaParentSeleccionado?.value;
    }

    const handleClickSubirPoliticas = event => {
        hiddenFileInput.current.click();
    };

    const handleChengeSubirPoliticas = async (event) => {
        const fileUploaded = event.target.files[0];
        let response = await uploadFile(fileUploaded, state.token);
        const politicFile = manageUploadFileResponse(response);
        if (politicFile) {
            setPoliticasFilesUploaded(prev => [...prev, {
                File: politicFile,
                Nombre: politicFile.OriginalFileName
            }]);
            setFormFileValues(prev => ({
                ...prev,
                [politicFile.OriginalFileName]: politicFile.OriginalFileName,
            }));
        }
    }

    const handleDeleteFile = fileId => {
        setPoliticasFilesUploaded(prev => prev.filter(politica => {
            return fileId !== politica.File.OriginalFileName;
        }))
    }

    const showCategoriaChildInput = () => notNullAndNotUndefinded(categoriasChildOptions) && categoriasChildOptions?.length > 0

    const isDescriptionTextMode = (descriptionTextAlreadySaved) => descriptionTextAlreadySaved!=null;

    return (
        <>
            <GenericModal showModal={showModal} width={"1180px"} titulo={"Editar política"}>
                <div className="modal-body pt-0 pl-0">
                    <form action="">
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-9">
                                <label className="modal-label mb-0" >Titulo</label>
                                <input type="text" className="form-control w-100" name="Nombre" onChange={handleChange} value={formValues?.Nombre ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Nombre-validation-message`}></p>

                            </div>
                            <div className="form-group mb-0 col-md-3">
                                <label className="modal-label mb-0">Estado</label>
                                <div className="w-100 black-color">
                                    <SelectInput
                                        options={estadoOptions}
                                        jsonSelectedValue={estadoSeleccionado}
                                        setJsonSelectedValue={setEstadoSEleccionado}
                                        placeholder={"Seleccionar"}
                                        isDisabled={true}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-6">
                                <label className="modal-label mb-0" >Categoría</label>
                                <SelectInput
                                    options={categoriasParentOptions}
                                    jsonSelectedValue={categoriaParentSeleccionado}
                                    setJsonSelectedValue={setCategoriaParentSeleccionado}
                                    placeholder={"Seleccionar"}
                                />
                                {showCategoriaChildInput() === false &&
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCategoria-validation-message`}></p>
                                }
                            </div>
                            {showCategoriaChildInput() &&
                            <div className="form-group mb-0 col-md-6">
                                <label className="modal-label mb-0">Sub Categoría</label>
                                <SelectInput
                                    options={categoriasChildOptions}
                                    jsonSelectedValue={categoriaChildSeleccionado}
                                    setJsonSelectedValue={setCategoriaChildSeleccionado}
                                    placeholder={"Seleccionar"}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdCategoria-validation-message`}></p>

                            </div>}
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-3">
                                <label className="modal-label mb-0" >Formato de descripción</label>
                                <SelectInput
                                    options={descriptionOptions}
                                    jsonSelectedValue={descriptionOptionSelected}
                                    setJsonSelectedValue={setDescriptionOptionSelected}
                                    placeholder={"Seleccionar"}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NONE-validation-message`}></p>
                            </div>
                        </div>

                        {descriptionOptionSelected?.value === descripcionComoTexto &&
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0" >Descripción</label>
                                <div className="w-100">
                                    <Editor
                                        editorState={editorState}
                                        wrapperClassName="demo-wrapper w-100 bg-white"
                                        editorClassName="demo-editor"
                                        toolbar={richTextToolbarOptions}
                                        onEditorStateChange={setEditorState}
                                    />
                                </div>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Descripcion-validation-message`}></p>

                            </div>
                        </div>}
                        {descriptionOptionSelected?.value === descripcionComoImagen &&
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0">
                                    Descripción <CustomInfoTooTip text={"Seleccionar una imagen en formato png, jpg 700x393 de máximo 8MB"}/>
                                </label>
                                <div className="w-100">
                                    <GenericUploadFileV2
                                        uploadFileToS3={setUploadedFileDescription}
                                        id={INPUT_FILE_DESCRIPCION}
                                        textButton={"Seleccionar Imagen"}
                                        extensionesValidas={EXTENSIONES_VALIDAS_DESCRIPTION}
                                        resetInitialFile={false}
                                    />

                                    {initialUploadedFileDescription?.FileId === uploadedFileDescription?.FileId ? (
                                        <span>
                                             <p className="d-inline">{initialUploadedFileDescription?.OriginalFileName}</p>
                                        </span>
                                    ) : null}
                                </div>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-DescriptionFile-validation-message`}></p>

                            </div>
                        </div>}



                        <div className="form-row">
                            <div className="form-group mb-0 col-md-3">
                                <label className="modal-label mb-0" >Color</label>
                                <div className="w-100 black-color">
                                    <SelectInput
                                        options={coloresOptions}
                                        jsonSelectedValue={colorSeleccionado}
                                        setJsonSelectedValue={setColorSeleccionado}
                                        placeholder={"Seleccionar"}
                                    />
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdColor-validation-message`}></p>
                                </div>
                            </div>
                            <div className="form-group mb-0 col-md-4">
                                <label className="modal-label mb-0" >Ícono</label>
                                <div className="w-100 black-color">
                                    <div className="d-flex align-items-center">
                                        <GenericUploadFileV2
                                            uploadFileToS3={setUploadedFile}
                                            id={INPUT_FILE_ICONO}
                                            textButton={"Seleccionar Imagen"}
                                            extensionesValidas={EXTENSIONES_VALIDAS}
                                            resetInitialFile={false}
                                        />
                                        {initialUploadedFile?.FileId === uploadedFile?.FileId ? (
                                            <span>
                                                <p className="d-inline">{initialUploadedFile?.OriginalFileName}</p>
                                           </span>
                                        ) : null}
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Icono-validation-message`}></p>
                                </div>
                            </div>

                            <div className="form-group mb-0 col-md-4">
                                <label className="modal-label mb-0">
                                    Más Información
                                    <CustomInfoTooTip text={"Seleccionar una imagen en formato png, jpg 350x622 de máximo 8MB"}/>
                                </label>
                                <div className="w-100 black-color">
                                    <div className="d-flex align-items-center">
                                    <GenericUploadFileV2
                                        uploadFileToS3={setUploadedFileMasInfo}
                                        id={INPUT_FILE_MAS_INFO}
                                        textButton={"Seleccionar Imagen"}
                                        extensionesValidas={EXTENSIONES_VALIDAS_MAS_INFO}
                                        resetInitialFile={false}
                                    />
                                    {initialUploadedFileMasInfo?.FileId === uploadedFileMasInfo?.FileId ? (
                                        <span>
                                             <p className="d-inline">{initialUploadedFileMasInfo?.OriginalFileName}</p>
                                        </span>
                                    ) : null}
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NONE-validation-message`}></p>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-3">
                                <label className="modal-label mb-0" >Vigencia de Nuevo</label>
                                <input
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    className="form-control"
                                    name="FechaNuevo"
                                    value={formValues.FechaNuevo}
                                    onChange={handleChange}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaNuevo`}></p>
                            </div>
                            <div className="form-group mb-0 col-md-3">
                                <label className="modal-label mb-0" >Fecha de Vigencia</label>
                                <input
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}
                                    className="form-control"
                                    name="FechaVigencia"
                                    value={formValues.FechaVigencia}
                                    onChange={handleChange}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaVigencia-validation-message`}></p>
                            </div>
                            <div className="form-group mb-0 col-md-6">
                                <label className="modal-label mb-0" ></label>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={"MostrarFecha"}
                                        data-id={"MostrarFechaLabel"}
                                        name={"MostrarFecha"}
                                        defaultChecked={formValues?.MostrarFecha}
                                        onClick={handleChange}
                                        value={formValues?.MostrarFecha}
                                    />
                                    <label className="form-check-label" htmlFor={"MostrarFechaLabel"}>
                                        Mostrar fecha de publicación
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0" >Cargar políticas</label>
                                <div>
                                    <div className="w-100 d-flex justify-content-between">
                                        <button
                                            type="button"
                                            className="generic-button-buscador"
                                            onClick={handleClickSubirPoliticas}
                                        >
                                            Subir
                                        </button>
                                        <input
                                            type="file"
                                            className="d-none"
                                            onChange={handleChengeSubirPoliticas}
                                            ref={hiddenFileInput}
                                        />
                                    </div>
                                    <div className="w-100">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th className="col-9">Nombre</th>
                                                <th className="col-3">Acción</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {politicasFilesUploaded?.map(file => (
                                                <tr key={file?.File?.FileName}>
                                                    <th>
                                                        <input
                                                            className="w-100"
                                                            type="text"
                                                            name={file?.File?.OriginalFileName}
                                                            value={formFileValues[file?.File?.OriginalFileName]}
                                                            onChange={handleFormFileChange}
                                                        />
                                                    </th>
                                                    <th>
                                                        <FontAwesomeIcon
                                                            icon='trash'
                                                            onClick={() => {
                                                                handleDeleteFile(file?.File?.OriginalFileName)
                                                            }}/>
                                                    </th>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                        <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Files-validation-message`}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0" >División (incluir)</label>
                                <MultiSelectInput
                                    options={divisionesOptions}
                                    selectedValues={jsonSelectedDivisionValue}
                                    setSelectedValues={setJsonSelectedDivisionValue}
                                    placeholder={multiselectPlaceholder}
                                    resetSelectedValues={false}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsDivisiones-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0" >Cargo (incluir)</label>
                                <MultiSelectInput
                                    options={cargosOptions}
                                    selectedValues={jsonSelectedCargosValueInclude}
                                    setSelectedValues={setJsonSelectedCargosValueInclude}
                                    placeholder={multiselectPlaceholder}
                                    resetSelectedValues={false}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargosIncluir-validation-message`}></p>

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group mb-0 col-md-12">
                                <label className="modal-label mb-0" >Cargo (excluir)</label>
                                <MultiSelectInput
                                    options={cargosOptions}
                                    selectedValues={jsonSelectedCargosValueExclude}
                                    setSelectedValues={setJsonSelectedCargosValueExclude}
                                    placeholder={multiselectPlaceholder}
                                    resetSelectedValues={false}
                                />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargosExcluir-validation-message`}></p>

                            </div>
                        </div>
                    </form>
                    
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Guardar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default EditarPolitica;
