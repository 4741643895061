import React, { useState } from 'react'
import { handleChangeSelect } from '../../../helper/utils';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import InputSelect from '../../../components/modalComponents/InputSelect';
import ModalForm from '../../../components/modalComponents/ModalForm';
import { asignacionFamiliarAdminRechazarSolicitudAPI } from '../../../consumers/backendApisUrls'

const RechazarSolicitudModal = ({ toggleModal, refreshPage, IdsSolicitudAsignacionFamiliar }) => {

  const SECTION_NAME = "motivoRechazoSolicitudAsignaciónFAmiliar";
  const KEYS = {motivo: "Motivo"};

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = asignacionFamiliarAdminRechazarSolicitudAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmit)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdsSolicitudAsignacionFamiliar,
      ...formValues
    }
  }

  const successSubmit = async () => {
    await refreshPage();
    toggleModal();
  }

  return (
    <ModalForm titulo='Motivo de Rechazo' onSubmit={handleSubmit} showModal={toggleModal} isSubmitting={isSubmitting}>
      <InputSelect 
        label='Motivo'
        options={messageOptions}
        value={messageOptions.find(o => o.value === formValues[KEYS.motivo]) ?? null}
        onChange={(res) => handleChangeSelect(setFormValues, res, KEYS.motivo)}
        name={KEYS.motivo}
        sectionName={SECTION_NAME}
        direction='column'/>
    </ModalForm>
  )
}

export default RechazarSolicitudModal;

const DNI = "DNI del menor solo se subió la primera cara (Regularizar ambas caras para la validación de los datos de los padres de familia)";
const ACTA_NACIMIENTO = "Documentos por regularizar (ACTA DE NACIMIENTO)";
const DOCUMENTOS_ILEGIBLES = "Documentos ilegibles";
const DOCUMENTOS_NO_PERTINENTES = "Documentos no pertinentes";

const messageOptions = [
  {value: DNI, label: DNI, origin: 1},
  {value: ACTA_NACIMIENTO, label: ACTA_NACIMIENTO, origin: 2},
  {value: DOCUMENTOS_ILEGIBLES, label: DOCUMENTOS_ILEGIBLES, origin: 3},
  {value: DOCUMENTOS_NO_PERTINENTES, label: DOCUMENTOS_NO_PERTINENTES, origin: 4},
]