import React, {useContext, useEffect, useState, useRef} from 'react';
import {useForm} from "../../../hooks/useForm/UseForm";
import {AuthContext} from "../../../hooks/context/authContext";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {validarFormulario} from "../../../helper/utils";
import {toast} from "react-toastify";
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {getNotasCeseByIdProcesoCierreEmpleadoAPI, registrarNotaCierreDeAnioAPI} from "../../../consumers/backendApisUrls";

const RegistrarNotaCierreDeAnioModal = ({toggleModal, idProcesoCierreEmpleado, refreshTable}) => {
    const refContainer = useRef(null);

    const {state} = useContext(AuthContext);
    const [notasDeCese, setNotasDeCese] = useState([]);
    const [nombreColaborador, setNombreColaborador] = useState("");
    const [validator, setValidator] = useState({});
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        let response = await executeGetRequest(getNotasCeseByIdProcesoCierreEmpleadoAPI(idProcesoCierreEmpleado));
        if (responseCode200(response)) {
            setNombreColaborador(response.data.NombreColaborador);
            setNotasDeCese(response.data.Notas);
            let element = document.getElementById("contenedor-notas");
            element.scrollTop = element.scrollHeight;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }

    };

    const submitModalRegistrarNotaProxy = async () => {
        if (validarFormulario(".form-control[data-required='true']", refContainer, setValidator)) {
            let payload = formValues;
            payload["IdProcesoCierreEmpleado"] = idProcesoCierreEmpleado;
            payload["Nota"] = formValues.Nota;
            let response = await executePostRequest(registrarNotaCierreDeAnioAPI(), payload);
            if (responseCode200(response)) {
                toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
                let element = document.getElementById("Nota");
                element.value = null;
                init();

            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const toggleModalProxy = async function () {
        toggleModal();
        await refreshTable();
    }

    return (
        <>
            <GenericModal showModal={toggleModalProxy} width={"900px"} titulo={"Registrar Nota"}>
                <div>
                    <div>
                        <form ref={refContainer} className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Datos colaborador</label>
                                <div className="col-sm-6 col-form-label modal-label ">
                                    <label className="add-nota-cese-colaborador">{nombreColaborador}</label>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Notas del caso</label>
                            </div>
                            <div class="contenedor-scroll-notas scroll-awesome" id="contenedor-notas">
                                {notasDeCese && notasDeCese.map((nota, index) => {
                                    return (
                                        <div key={index} className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group">
                                            <div className="col-sm-1 modal-add-nota-imagen-comtainer">
                                                <img className="modal-add-nota-imagen" src={nota.FotoPerfilUrl}></img>
                                            </div>
                                            <div className="col-sm-10">
                                                <div>{nota.FechaNota}</div>
                                                <div>{nota.NombreEmpleado} - {nota.NombreCargo}</div>
                                                <div className="modal-add-nota-creada">{nota.Nota}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="form-group row form-input-modal-contactanos black-color modal-ceses-notas-group py-3">
                                <div className="col-sm-1 modal-add-nota-imagen-comtainer">
                                    <img className="modal-add-nota-imagen-input-new-nota" src={state.user.photo}></img>
                                </div>
                                <div className="col-sm-10">
                                    <textarea autoFocus data-required="true" className="form-control" rows="5" id="Nota" name="Nota" onChange={handleChange}
                                              value={formValues.Comentario} maxLength="2000"
                                              placeholder={"Máximo 2000 caracteres."}></textarea>

                                    {validator.Nota && validator.Nota.length != 0 && <p className="ficha-form-input-validation-message">{validator.Nota[0]}</p>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer mt-15">
                        <div className="message font-gothan-light w-500">
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha" onClick={submitModalRegistrarNotaProxy}>Registrar Nota</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default RegistrarNotaCierreDeAnioModal;

