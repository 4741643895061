import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from "../../hooks/useForm/UseForm";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {toast} from "react-toastify";
import {isTrue, notNullAndNotUndefinded, stringSeEncuentraEnArray} from "../../helper/utils";
import {updateFormValidationMessage} from "../../validations/FormValidatorUtils";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {getMotivosRechazoSolicitudAPI, rechazarSolicitudAPI} from "../../consumers/backendApisUrls";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {Spinner} from "react-bootstrap";

const RechazarSolicitudModal = ({showRechazarSolicitudModal, idsFichaCamposSolicitudesConCheck, allSolicitudes, getInitialData}) => {
        const seccionName = "modal-rechazar-solicitud-cambio";
        const validationMessagePrefixId = `${seccionName}-validation-message`;

        const [formValues, setFormValues] = useState({});
        const [handleChange] = useForm(formValues, setFormValues);
        const [loading, setLoading] = useState(true);

        const [motivosRechazoSolicitud, setMotivosRechazoSolicitud] = useState([]);
        const [executeGetRequest] = useGetRequest();
        const [executePostRequest] = usePostRequest();

        useEffect(() => {
            let unmounted = false;
            if (!unmounted) {
                init();
            }

            return () => {
                unmounted = true;
            };
        }, []);

        const init = async () => {
            setLoading(true);
            console.debug("init RechazarSolicitudModal");
            let response = await executeGetRequest(getMotivosRechazoSolicitudAPI());
            if (responseCode200(response)) {
                setMotivosRechazoSolicitud(response.data);
                let formInputValues = getInitialFormValues(response.data);
                formInputValues = addNombreCompletoColaborador(formInputValues);
                setFormValues(formInputValues);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
            setLoading(false);
        };

        const getNombreColaborador = () => {
            let nombresColaboradores = [];
            let solicitudesSelecionadas = allSolicitudes.filter((solicitud) => (stringSeEncuentraEnArray(solicitud["IdFichaCampoSolicitud"], idsFichaCamposSolicitudesConCheck)));
            for (let solicitud of solicitudesSelecionadas) {
                if (!stringSeEncuentraEnArray(solicitud["NombreCompleto"], nombresColaboradores)) {
                    nombresColaboradores = [...nombresColaboradores, solicitud["NombreCompleto"]];
                }
            }
            return nombresColaboradores;
        };

        const addNombreCompletoColaborador = (formInputValues) => {
            console.debug("updateNombreCompletoColaborador");
            let nombreColaboradorArray = getNombreColaborador();
            let nombreColaborador = (nombreColaboradorArray.length > 1 ? "varios.." : nombreColaboradorArray[0])

            let tempFormInputValues = formInputValues;
            tempFormInputValues = {...tempFormInputValues, datosColaborador: nombreColaborador};

            return tempFormInputValues;
        };

        const getInitialFormValues = (documentosPorCorregir) => {
            let formInputValues = {comentario: ""};

            for (let documento of documentosPorCorregir) {
                let key = "documentosPorCorregir" + documento["Value"];
                formInputValues = {...formInputValues, [key]: false}
            }

            formInputValues = {...formInputValues};
            return formInputValues;
        };

        const closeSolicitarCambiosModal = () => {
            showRechazarSolicitudModal();
            resetForm();
        };

        const resetForm = () => {
            let formInputValues = getInitialFormValues(motivosRechazoSolicitud);
            setFormValues(formInputValues);
        };

        const obtenerIdsDocumentosPorCorregirConCheckTrue = () => {
            let idsDocumentosPorCorregirConCheckTrue = [];
            for (let documento of motivosRechazoSolicitud) {
                let key = "documentosPorCorregir" + documento["Value"];
                if (formValues[key]) {
                    idsDocumentosPorCorregirConCheckTrue = [...idsDocumentosPorCorregirConCheckTrue, documento["Value"]]
                }
            }
            return idsDocumentosPorCorregirConCheckTrue;
        };

        const updateValidationMessagee = (keyField, message) => {
            let htmlElementId = `${validationMessagePrefixId}-${keyField}`;
            updateFormValidationMessage(htmlElementId, message);
        };

        const campoTextOrListValidoo = (formKey, empty, maxlength) => {
            let value = formValues[formKey];
            let campoValido = true;
            if (value == empty) {
                updateValidationMessagee(formKey, "Este campo es obligatorio");
                campoValido = false;
            } else {
                if (notNullAndNotUndefinded(maxlength) && value.length > maxlength) {
                    updateValidationMessagee(formKey, `Ingresar máximo ${maxlength} caracteres`);
                    campoValido = false;
                } else {
                    updateValidationMessagee(formKey, "");
                }
            }
            return campoValido;
        };

        const alMenosUnDocumentoConCheckValidator = () => {
            let allDocumentosConCheck = document.querySelectorAll(`#${seccionName} [name^='documentosPorCorregir']:checked`);
            let alMenosUnDocumentoConCheck = allDocumentosConCheck.length > 0 ? true : false;
            let formKey = "documentosPorCorregir";
            if (alMenosUnDocumentoConCheck) {
                updateValidationMessagee(formKey, "");
            } else {
                updateValidationMessagee(formKey, "Este campo es obligatorio");
            }
            return alMenosUnDocumentoConCheck;
        };

        const formularioValido = async () => {
            let alMenosUnDocumentoConCheck = alMenosUnDocumentoConCheckValidator();
            let mensajeEsValido = campoTextOrListValidoo("comentario", "", 500);

            return (isTrue(alMenosUnDocumentoConCheck) && isTrue(mensajeEsValido));
        };

        const submitRechazarSolicitud = async (event) => {
            event.preventDefault();
            console.debug("submitRechazarSolicitud");
            console.debug("formValues: ", formValues);

            let formValido = await formularioValido();
            if (formValido) {
                let idsDocumentosPorCorregirConCheckTrue = obtenerIdsDocumentosPorCorregirConCheckTrue();

                for (let id of idsFichaCamposSolicitudesConCheck) {
                    let payload = {
                        "IdFichaCampoSolicitud": id,
                        "IdsMotivosRechazo": idsDocumentosPorCorregirConCheckTrue,
                        "MotivoObservacion": formValues["comentario"]
                    };

                    console.debug("payload ", payload);
                    let response = await executePostRequest(rechazarSolicitudAPI(), payload);
                    if (responseCode200(response)) {
                        toast.success(response.data.Message);
                    } else {
                        mostrarMensajeDeErrorConToast(response);
                    }
                }

                showRechazarSolicitudModal();
                resetForm();
                await getInitialData();
            }
        };
        if (loading) return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
            </div>
        );
        return (
            <>
                <div className="ficha-modal-solicitar-cambios" style={{'width': '800px'}} id={seccionName}>
                    <div className="modalHeader">
                        <div className="title">
                            <p className="font-gothan-bold">Solicitud de cambios observada</p>
                        </div>
                        <div className="icon">
                            <FontAwesomeIcon icon='times-circle' className="hand-pointer" transform="left-9" onClick={() => closeSolicitarCambiosModal()}/>
                        </div>
                    </div>
                    <div className="modalContent">
                        <form id="modal-solicitar-cambios-generic">
                            <div className="form-group row form-input-modal-contactanos" style={{'marginTop': '35px'}}>
                                <label className="col-sm-4 col-form-label modal-label">Datos colaborador</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name="datosColaborador" value={formValues.datosColaborador} onChange={handleChange} disabled></input>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Documentos por corregir</label>
                                <div className="col-sm-8">
                                    {motivosRechazoSolicitud &&
                                    motivosRechazoSolicitud.map((motivoRechazo, index) => (
                                        <div key={index}>
                                            <input type="checkbox" name={"documentosPorCorregir" + motivoRechazo.Value} onChange={handleChange} checked={formValues["documentosPorCorregir" + motivoRechazo.Value]}></input>
                                            <label className="form-check-label ">
                                                <p>{motivoRechazo.Text}</p>
                                            </label>
                                        </div>
                                    ))}
                                    <p className="ficha-form-input-validation-message" id={`${validationMessagePrefixId}-documentosPorCorregir`}></p>
                                </div>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-4 col-form-label modal-label">Comentario</label>
                                <div className="col-sm-8">
                                    <textarea className="form-control" rows="3" name="comentario" onChange={handleChange} value={formValues.comentario}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`${validationMessagePrefixId}-comentario`}></p>
                                </div>
                            </div>
                        </form>

                        <div className="modal-footer" style={{'marginTop': '15px'}}>
                            <div className="message font-gothan-light" style={{'width': '500px'}}>
                                <strong>
                                    <p>*El colaborador recibirá un mail con el detalle de la respuesta sobre su solicitud.</p>
                                </strong>
                            </div>
                            <div className="button">
                                <button className="btn btn-primary" onClick={submitRechazarSolicitud}>Enviar notificación</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
;

export default RechazarSolicitudModal;
