import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditarDocumento from './modales/EditarDocumento';
import ModalActivarInactivarGeneric from '../../../components/modalActivarInactivar/ModalActivarInactivarGeneric';
import { activarInactivarConfiguracionDocumentoAPI } from '../../../consumers/backendApisUrls';

const TableWebBody = ({ filas, setModalEnabled }) => {
  return (
    <tbody className="listar-tbody">
        {filas.map((fila,i)=> (
            <tr key={i}>
                <td className="pl-3 ml-3">{fila?.IdNuevosIngresosDocumentos}</td>
                <td className="pl-1 ml-3">{fila?.NombreDocumento}</td>
                <td className="pl-1 pr-1">{fila?.Origen}</td>
                <td className="pl-1 pr-1">{fila?.Estado}</td>
                <td className="pl-1 pr-3">
                    {!fila.IsBlocked &&
                        <div className="d-flex justify-content-start">
                            <div>
                                <FontAwesomeIcon
                                    icon={fila.EsActivo ? 'ban' : 'circle-notch'}
                                    size="sm"
                                    className='hand-pointer'
                                    title={fila.EsActivo ? 'Inactivar': 'Activar'}
                                    onClick={() => setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
                                        data: {api: activarInactivarConfiguracionDocumentoAPI, id: fila.IdNuevosIngresosDocumentos, title: "documento", name: fila.NombreDocumento, state: fila.EsActivo}})}
                                />
                            </div>
                            {fila.EsActivo &&
                                <div>
                                    <FontAwesomeIcon 
                                        icon='edit'
                                        size='sm'
                                        className="hand-pointer ml-3"
                                        title="Editar"
                                        onClick={() => setModalEnabled({isEnable: true, component: EditarDocumento, 
                                                data: {documentoId: fila.IdNuevosIngresosDocumentos}})}
                                    />
                                </div>
                            }
                        </div>
                    }
                </td>
            </tr>
        ))}
    </tbody>
  )
}

export default TableWebBody