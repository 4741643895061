import React from 'react'
import { useLocation } from 'react-router-dom';
import PageHeader from '../../../../components/pageHeader/PageHeader';
import PageWrapper from '../../../../components/pageComponents/PageWrapper';
import { CuponeraWrapper } from '../../components/StyledComponents';
import ListaCuponesAsignados from './ListaCuponesAsignados';

const CuponesAsignadosByCuponera = () => {
  
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const idCuponeraTiempoLibre = params.get("search")
  const fechaInicio = params.get("from")
  const fechaFin = params.get("to")

  return (
    <PageWrapper >
      <PageHeader title='Cupones Asignados a Cuponera'/>
      <CuponeraWrapper className='wrapper'>
        <ListaCuponesAsignados IdCuponeraTiempoLibre={idCuponeraTiempoLibre} fechaInicioCuponera={fechaInicio} fechaFinCuponera={fechaFin}/>
      </CuponeraWrapper>
    </PageWrapper>
  )
}

export default CuponesAsignadosByCuponera;