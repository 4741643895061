import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import IconCheck from '../icons/IconCheck';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useClickOutside from "../../hooks/useClickOutside/useClickOutside";
import {notNullAndNotUndefinded} from "../../helper/utils";

const SelectSearch = styled.div`
  font-size: 12px;
  position: relative;
  cursor: pointer;
  margin-top: 0px;

  .description {
    width: 100%;
    margin: auto;
    border-radius: 6px;
    font-family: GothamLight;
    color: #565656;
    font-size: 12px;
  }

  @media (max-width: 992px) {
    .description {
      border: 1px solid #8b9099;
      font-size: 12px;
    }

    margin-bottom: 10px;
    margin-top: 0px;
  }
`;

export const Li = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 4px 3px;
  height: auto;
  width: 100%;
  margin-bottom: 1px;
`;

export const Marker = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;

  svg {
    opacity: 0;
    fill: #fff;
  }
`;

export const Input = styled.input`
  font-family: GothamLight;
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked {
    + ${Marker} {
      background-color: #1890ff;
      border-color: #1890ff;

      svg {
        opacity: 1;
      }
    }
  }
`;

export const Label = styled.span`
  display: inline-block;
  margin-left: 0.5rem;
  color: #595959;
  font-size: 11px;
`;

const FormSelectSearch = ({description, options, selectedOptions, setSelectedOptions, custom = {}}) => {
    const [checkedOptionsValues, setCheckedOptionsValues] = useState([]);
    const optionsObjects = convertOptionsToObjects();
    const [optionsObjectsState, setOptionsObjectsState] = useState(optionsObjects);
    const [open, setOpen] = useState(false);
    const [limpiarBusqueda, setLimpiarBusqueda] = useState("");
    const [criterioBusqueda, setCriterioBusqueda] = useState("");

    let selectContainer = useClickOutside(() => {
        setOpen(false);
    });

    let title = description;

    function convertOptionsToObjects() {
        let optionsObjectss = [];

        if (options) {
            let temp = []
            temp = options.map((option) => {
                return Object({
                    Value: option.Value,
                    Text: option.Text,
                    isChecked: notNullAndNotUndefinded(checkedOptionsValues?.find(val => val == option.Value)) ? true : false
                });
            });

            optionsObjectss = temp.sort((a, b) => (a.Text > b.Text) ? 1 : ((b.Text > a.Text) ? -1 : 0))
        }

        return optionsObjectss;
    }

    useEffect(() => {
        const optionsObjectss = convertOptionsToObjects();
        setOptionsObjectsState(optionsObjectss);
    }, [options]); //, checkedOptionsValues

    useEffect(() => {
        if (selectedOptions) setCheckedOptionsValues(selectedOptions);
    }, [selectedOptions]);

    const updateTableRows = function (checkedOptionsValuessss) {
        setSelectedOptions([...checkedOptionsValuessss]);
    };

    const handleClickOnOptionCheckbox = (event) => {
        let optionsObjectsStateTemp = optionsObjectsState;
        const { value, checked} = event.target;
        optionsObjectsStateTemp.forEach((option) => {

            if (option.Value == value) {
                option.isChecked = checked;
            }
        });
        const checkedOptions = optionsObjectsStateTemp.filter((option) => option.isChecked === true);
        const checkedOptionsValuess = checkedOptions.map((option) => option.Value);
        setCheckedOptionsValues([...checkedOptionsValuess]);
        setOptionsObjectsState([...optionsObjectsStateTemp]);
    };

    const setCriterioDeBusqueda = (value) => {
        setCriterioBusqueda(value)
        const newData = value.length > 0
            ? optionsObjectsState.filter(item => item.Text.toLowerCase().includes(value.toLowerCase()))   
            : optionsObjects;
        setOptionsObjectsState(newData);
    }
    
    return (
        <div ref={selectContainer} className="flex ">
            <SelectSearch>
                <div className={'description font-gothan-medium hand-pointer '} onClick={() => setOpen(!open)} style={custom}>
                    {title} <FontAwesomeIcon size={"xs"} icon={['fas', 'filter']} className="hand-pointer change-requested-field-clock"/>
                </div>
                <div className="custom-scroll">
                    <div className={(open === true) ? 'filters-modal-open modal-Filtros' : 'filters-modal-closed modal-Filtros'}>
                        <div className="custom-select-options text-wrap">
                            <input
                                type='text'
                                className='input-buscador'
                                placeholder='Buscar nombre'
                                style={{'width': '93%','margin': '5px'}}
                                onChange={(e) => { setCriterioDeBusqueda(e.target.value);}}
                                value={criterioBusqueda}
                             />
                            {optionsObjectsState.map((option, i) => (
                                <Li key={i}>
                                    <Input
                                        checked={option.isChecked}
                                        value={option.Value}
                                        type='checkbox'
                                        onChange={(e) => {
                                            handleClickOnOptionCheckbox(e);
                                        }}
                                    />
                                    <Marker>
                                        <IconCheck/>
                                    </Marker>
                                    <Label>{option.Text}</Label>
                                </Li>
                            ))}
                        </div>
                        <div className='listar-aplicar-filtros-button font-gothan-medium' onClick={() => {
                            setCriterioBusqueda("");
                            setSelectedOptions(checkedOptionsValues);
                            setOpen(!open);
                 
                        }}>
                            Aplicar Filtros
                        </div>
                    </div>
                </div>
            </SelectSearch>
        </div>
    );
};

export default FormSelectSearch;
