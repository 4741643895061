import React, { useState, useEffect } from 'react'
import validateFormData from '../components/validateFormData';
import InputText from '../../../../../../components/modalComponents/InputText'
import ModalForm from '../../../../../../components/modalComponents/ModalForm'
import InputDate from '../../../../../../components/modalComponents/InputDate'
import InputSelect from '../../../../../../components/modalComponents/InputSelect'
import InputUploadFile from '../../../../../../components/modalComponents/InputUploadFile'
import InputSelectCascade from '../../../../../../components/modalComponents/InputSelectCascade';
import { usePropsInputs } from '../../../../../../hooks/useProps/usePropsInput'
import { usePropsSelect } from '../../../../../../hooks/useProps/usePropsSelect'
import { useGetOptionsSelect } from '../../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import useGetOptionsSelectWithRegex from '../../../../../../hooks/useGetOptionsSelectWithRegex/useGetOptionsSelectWithRegex';
import { PAISES, TIPOS_VINCULOS } from '../../../../../../constants/hardCodedConstants';
import { getDepartamentosAPI, getDistritosPorProvinciaAPI, getGenerosAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, getTiposDocumentoAPI, getTiposVinculosAPI } from '../../../../../../consumers/backendApisUrls'
import { clearAllInputValidationMessages, updateValidationMessage } from '../../../../../../validations/FormValidatorUtils';
import { getTodayDate, handleFindDropdownSelected, initialAllOptionPromises, toIsoForInput } from '../../../../../../helper/utils'

const EditarDatoFamiliar = ({ toggleModal, familiar = {}, setFormValues: setFormValuesParent }) => {

  const MESSAGE = "Campo Obligatorio";
  const SECTION_NAME = "EditarDatoFamiliarNuevosIngresos";
  const KEYS = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", numeroDocumento: "NumeroDocumento", idGenero: "IdGenero", nombres: "Nombres",  apellidoPaterno: "ApellidoPaterno", apellidoMaterno: "ApellidoMaterno", fechaNacimiento: "FechaNacimiento", fileViewModel: "FileViewModel" };
  const KEYS_NACIMIENTO = { idPaisNacimiento: "IdPaisNacimiento", idDepartamento: "IdDepartamento", idProvincia: "IdProvincia", idDistrito: "IdDistrito", ciudadNacimiento: "CiudadNacimiento" };
  const KEY_TYPES = { idVinculo: "IdVinculo", idTipoDocumento: "IdTipoDocumento", idGenero: "IdGenero", idPaisNacimiento: "IdPaisNacimiento" };
  const KEY_REGEX = { "NumeroDocumento": { regex: "IdTipoDocumento" } };

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState(familiar);

  const [getOptions] = useGetOptionsSelect();
  const [getOptionsWithRegex] = useGetOptionsSelectWithRegex();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const initialPromises = () => ([
    getOptions(getTiposVinculosAPI()),
    getOptionsWithRegex(getTiposDocumentoAPI()),
    getOptions(getGenerosAPI()),
    getOptions(getPaisesAPI()),
  ])

  const init = async () => {
    initialAllOptionPromises(initialPromises, Object.values(KEY_TYPES), setAllDropdowns);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const keysToValidate = getRequiredKeys();
    const emptyInputs = validateFormData(keysToValidate, formValues, KEY_REGEX, allDropdowns);
    if (emptyInputs.length) return showErrorOnInputs(emptyInputs);
    saveDataAndCloseModal();
  }

  const getRequiredKeys = () => {
    const keysArray = Object.values(KEYS);
    if (formValues[KEYS.idVinculo] !== TIPOS_VINCULOS.Hijo.id) return keysArray;
    const keysToValidate = formValues[KEYS_NACIMIENTO.idPaisNacimiento] === PAISES.PERU 
      ? [...keysArray, KEYS_NACIMIENTO.idPaisNacimiento, KEYS_NACIMIENTO.idDepartamento, KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito]
      : [...keysArray, KEYS_NACIMIENTO.idPaisNacimiento, KEYS_NACIMIENTO.ciudadNacimiento]
    return keysToValidate;
  }

  const saveDataAndCloseModal = () => {
    setFormValuesParent(lastData => {
      const familiares = lastData.Familiares;
      const NombreVinculo = handleFindDropdownSelected(allDropdowns, formValues, KEY_TYPES.idVinculo)?.label;
      const NombreGenero = handleFindDropdownSelected(allDropdowns, formValues, KEY_TYPES.idGenero)?.label;
      const NombreTipoDocumento = handleFindDropdownSelected(allDropdowns, formValues, KEY_TYPES.idTipoDocumento)?.label;
      const PaisNacimiento = handleFindDropdownSelected(allDropdowns, formValues, KEY_TYPES.idPaisNacimiento)?.label;
      const familiaresUpdated = familiares.map(o => {
        if (o.IdPersonaFamiliar === familiar.IdPersonaFamiliar){
          return {...formValues, NombreVinculo, NombreGenero, NombreTipoDocumento, PaisNacimiento}
        }
        return o;
      })
      return {
        ...lastData,
        Familiares: familiaresUpdated
      }
    });
    toggleModal();
  }

  const showErrorOnInputs = (emptyInputs) => {
    const keysToValidate = getRequiredKeys();
    clearAllInputValidationMessages(keysToValidate, SECTION_NAME);
    for (const inputKey of emptyInputs){
      updateValidationMessage(inputKey.key, inputKey.message ?? MESSAGE, SECTION_NAME);
    }
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, FileViewModel: res}))
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm showModal={toggleModal} titulo="Editar Dato Familiar" onSubmit={handleSubmit} label='Editar'>
      <div className="displayFlexWrap">
        <Div><InputSelect label='Tipo de vínculo' {...commonSelect(KEYS.idVinculo, [KEYS_NACIMIENTO.idPaisNacimiento, KEYS_NACIMIENTO.idDepartamento, KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito, KEYS_NACIMIENTO.ciudadNacimiento])} isRequired/></Div>
        <Div><InputSelect label='Tipo de documento' {...commonSelect(KEYS.idTipoDocumento)} isRequired/></Div>
        <Div><InputText label='N° de documento' {...commonProps(KEYS.numeroDocumento)} isRequired/></Div>
        <Div><InputSelect label='Género' {...commonSelect(KEYS.idGenero)} isRequired/></Div>
        <Div><InputText label='Nombres' {...commonProps(KEYS.nombres)} isRequired/></Div>
        <Div><InputText label='Apellido Paterno' {...commonProps(KEYS.apellidoPaterno)} isRequired/></Div>
        <Div><InputText label='Apellido Materno' {...commonProps(KEYS.apellidoMaterno)} isRequired/></Div>
        <Div><InputDate label='Fecha de Nacimiento' {...commonProps(KEYS.fechaNacimiento)} isRequired max={toIsoForInput(getTodayDate())}/></Div>
        {formValues[KEYS.idVinculo] === TIPOS_VINCULOS.Hijo.id &&
          <>
            <Div><InputSelect label='País de nacimiento' {...commonSelect(KEYS_NACIMIENTO.idPaisNacimiento, [KEYS_NACIMIENTO.idDepartamento, KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito, KEYS_NACIMIENTO.ciudadNacimiento])} isRequired/></Div>
            {formValues[KEYS_NACIMIENTO.idPaisNacimiento] === PAISES.PERU ?
              <>
                <Div><InputSelectCascade label='Departamento' dependendOption={formValues[KEYS_NACIMIENTO.idPaisNacimiento]} api={getDepartamentosAPI} {...commonProps(KEYS_NACIMIENTO.idDepartamento, [KEYS_NACIMIENTO.idProvincia, KEYS_NACIMIENTO.idDistrito])} isRequired isIdSearch={false} isAutoselect={false}/></Div>
                <Div><InputSelectCascade label='Provincia' dependendOption={formValues[KEYS_NACIMIENTO.idDepartamento]} api={getProvinciasPorDepartamentoAPI} {...commonProps(KEYS_NACIMIENTO.idProvincia, [KEYS_NACIMIENTO.idDistrito])} isRequired isAutoselect={false}/></Div>
                <Div><InputSelectCascade label='Distrito' dependendOption={formValues[KEYS_NACIMIENTO.idProvincia]} api={getDistritosPorProvinciaAPI} {...commonProps(KEYS_NACIMIENTO.idDistrito)} isRequired/></Div>
              </> :
              <Div><InputText label='Ciudad' {...commonProps(KEYS_NACIMIENTO.ciudadNacimiento)} isRequired/></Div>
            }
          </>
        }
        <Div col={12}><InputUploadFile label='Adjuntar DNI' uploadFile={formValues.FileViewModel} setUploadFile={handleUploadFile} name="FileViewModel" validExtensions={["pdf", "jpg", "png"]} sectionName={SECTION_NAME} colLabel={2} colInput={10} isRequired/></Div>
      </div>
    </ModalForm>
  )
}

export default EditarDatoFamiliar;

const Div = ({ children, col = 6 }) => (
  <div className={`col-sm-${col}`}>
    { children }
  </div>
)