import React from 'react';

const ModalMensaje = ({toggleModal, manageSearch, modalMensaje, modalImage, styleFicha = {}, hasBackButton = true}) => {

  const closeAll = () => {
    toggleModal();
    if (manageSearch) manageSearch();
  }

  return (
    <div className="modal-response-ficha-background">
      <div className="modal-response-ficha-content">
        {modalImage &&
            <img src={modalImage} />
        }
        <p className="modal-response-ficha-content-message px-5 text-center" style={styleFicha}>
          {modalMensaje}
        </p>
        {hasBackButton &&
          <button className="modal-response-ficha-content-button" onClick={closeAll}> Volver a actividades </button>
        }
      </div>
    </div>
  )
}

export default ModalMensaje