import React, {useEffect, useState} from 'react';
import {getAllProcesosDeCierreListAPI,
        downloadReporteCierreAnioRenovacionAPI,
} from "../../consumers/backendApisUrls";
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { useGetOptionsSelect } from '../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import SelectInput from '../../components/Select/SelectInput';
import { useDescargarArchivoYEliminarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3';

const ReporteCierreAnioRenovacion = () => {
  const SECTION_NAME = "ReporteDeCierreDeAnioRenovacion";
  const KEYS = ["IdProcesoCierre"];
  
  const [isDownloading, setIsDownloading] = useState(false);

  const [procesosDeCierreOptions, setProcesosDeCierreOptions] = useState([]);
  const [jsonSelectedProcesoCierre, setJsonSelectedProcesoCierre] = useState({});

  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [DownloadDeleteFile] = useDescargarArchivoYEliminarArchivoDeS3();

  const init = async () => {
    setProcesosDeCierreOptions(await getOptions(getAllProcesosDeCierreListAPI()));
  };

  const handleDownloadReport = async () => {
    setIsDownloading(true);
    try {
      const api = downloadReporteCierreAnioRenovacionAPI();
      let payload = {IdProcesoCierre: jsonSelectedProcesoCierre?.value};
      await submitForm(KEYS, api, payload, SECTION_NAME, successDownloadReportCallback)
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  }

  const successDownloadReportCallback = async (res) => {
    let data = res?.data;
    DownloadDeleteFile(data?.OriginalFileName, data?.FilePath);
  }

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, [])

  return (
    <div className='card-body'>
      <div id={SECTION_NAME}>
        <div>
          <div className="form-group row form-input-modal-contactanos reportes-label-container">
            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Proceso de cierre<span className="required">*</span></label>
            <div className="col-sm-4">
              <SelectInput 
                options={procesosDeCierreOptions} 
                jsonSelectedValue={jsonSelectedProcesoCierre} 
                setJsonSelectedValue={setJsonSelectedProcesoCierre} 
                placeholder={"Seleccionar"} 
                isDisabled={isDownloading}
              />
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdProcesoCierre-validation-message`}></p>
            </div>
          </div>
        </div>

        <div className="reporte-datos-generales-dato-obligatorio">
            <p>(<span className="required ">*</span>) Dato obligatorio</p>
        </div>

        <div>
          <button className="btn btn-primary generic-button-ficha" onClick={handleDownloadReport} disabled={isDownloading}>
            {isDownloading ? 'Descargando Reporte ...' : 'Descargar Reporte'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReporteCierreAnioRenovacion