import React from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { eliminarAsignacionTemplateByProcesoCierreAPI } from '../../../../consumers/backendApisUrls';

const EliminarPlantilla = ({toggleModal, refreshTable, title, idprocesoCierreConfig}) => {

    const [managePost] = useManagePostRequest();

    const executeEliminarPlantilla = async () => {
        await managePost(eliminarAsignacionTemplateByProcesoCierreAPI(idprocesoCierreConfig), {}, () =>{
            refreshTable();
            toggleModal();
        });
      }

  return (
    <GenericModal titulo={"Eliminar"} showModal={ toggleModal } width={"620px"}>
        <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a eliminar la siguiente plantilla del proceso de cierre:
          <ul>
            <li><strong>{title}</strong></li>
          </ul>

          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end" >
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={executeEliminarPlantilla}
            >
            Eliminar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => { toggleModal() }}
            >
            Cancelar
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default EliminarPlantilla