import React, { useState } from 'react'
import { ModalFormContent, ModalFormHeader, ModalFormWrapper } from '../../../components/modalComponents/ModalForm'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { noMostrarPopupAPI } from '../../../consumers/backendApisUrls'
import { Carousel } from 'react-bootstrap'

const ModalPopup = ({ toggleModal, popups }) => {

  const [index, setIndex] = useState(0);
  const [noMostrarPopupIds, setNoMostrarPopupIds] = useState([]);

  const [executePost] = useManagePostRequest();

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleClose = async () => {
    toggleModal();
    submit();
  }

  const handleCheckboxChange = (popupId) => {
    setNoMostrarPopupIds((prevIds) =>
      prevIds.includes(popupId)
        ? prevIds.filter((id) => id !== popupId)
        : [...prevIds, popupId]
    );
  };

  const submit = async () => {
    for (let idPopup of noMostrarPopupIds){
      const api = noMostrarPopupAPI(idPopup);
      await executePost(api, {}, () => {}, false);
    }
  }

  return (
    <ModalFormWrapper width='560px' className='custom-scroll'>
      <ModalFormHeader>
        <p className="font-gothan-bold">{popups[index]?.Titulo || "Info"}</p>
        <div className="icon">
          <FontAwesomeIcon
            icon="times-circle"
            className="hand-pointer"
            onClick={() => handleClose()}
          />
        </div>
      </ModalFormHeader>
      <ModalContent>
        <div className="modalBody">
          <Carousel activeIndex={index} onSelect={handleSelect} className='carouselItem'>
            {popups.map(popup => (
              <Carousel.Item key={popup.IdPopup} interval={2500}>
                {popup.LinkRedirigir
                  ? <a href={popup.LinkRedirigir ?? "#" } target='_blank' rel='noopener noreferrer'>
                      <Image src={popup.Imagen} alt={popup.Titulo}/>
                    </a>
                  : <Image src={popup.Imagen} alt={popup.Titulo}/>
                }
                <div className="noMostrar">
                  <input type="checkbox" id='inputNoMostrar' onChange={() => handleCheckboxChange(popup.IdPopup)} />
                  <label htmlFor="inputNoMostrar">No mostrarme esta ventana otra vez</label>
                </div>
              </Carousel.Item>
            ))
            }
          </Carousel>
        </div>
      </ModalContent>
    </ModalFormWrapper>
  )
}

export default ModalPopup;

const ModalContent = styled(ModalFormContent)`
  padding: 0;
  overflow-x: hidden;

  .modalBody {
    padding: 1.2rem;
    height: 580px;
  }

  .carouselItem {
    height: 500px;
    width: 500px;
  }

  .noMostrar {
    text-align: center;
    margin: 15px 20px 0px;
  }
`

const Image = styled.img`
  height: 500px;
  width: 500px;
  object-fit: contain;
`;