import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";

const ExtraInfoToolTip = ({datosSeccion, fileName}) => {
    return (
        <>
            {datosSeccion && fileName === "" && datosSeccion.FileViewModel !== null &&
            <>
                <p data-tip data-for="informacion-residencia-sustento-already-saved" style={{display: 'inline'}}>
                    <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                </p>
                <ReactTooltip id="informacion-residencia-sustento-already-saved" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                    Usted ya tiene un adjunto guardado previamente por lo que no es necesario que adjunte un nuevo archivo.
                </ReactTooltip>
            </>}
        </>
    )
};

export default ExtraInfoToolTip;