import React from 'react'

const InputWeekDay = ({
  label,
  options,
  value,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
}) => {
  const handleCheck = (data) => {
    let idRaw = parseInt(data.target.value);
    let id = isNaN(idRaw) ? data.target.value : idRaw;
    let isCheked = data.target.checked;

    let temp = isCheked
      ? [...(value ?? ""), id]
      : value.filter((o) => o !== id);
    let selected = {
      target: {
        value: temp,
        name: name,
      },
    };
    onChange(selected);
  };

  return (
    <>
      <div className="form-group">
        <label className={`col-sm-12 pl-1 col-form-label modal-label ${isRequired ? "required" : ""}`}>
          {label}
        </label>
      </div>
      <div className="form-group d-flex col-sm-12 justify-content-center" style={{ gap: "20px" }}>
        {options.map((option, i) => (
          <div
            key={`${option.label}-${i}-${option.value}`}
            className="d-flex flex-column justify-content-center"
          >
            <label
              className="black-color font-gothan-light"
              htmlFor={`${option.label}-${i}-${option.value}`}
            >
              {option.label}
            </label>
            <input
              id={`${option.label}-${i}-${option.value}`}
              type="checkbox"
              name={name}
              value={option.value}
              checked={value?.includes(option.value) ?? false}
              onChange={handleCheck}
              disabled={isDisabled}
              className="m-0"
            />
          </div>
        ))}
      </div>
      <div className="col-sm-12 d-flex justify-content-center mb-2">
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </>
  )
}

export default InputWeekDay