import React, {useState} from 'react';
import styled, {keyframes} from 'styled-components';

export const fadeAnimation = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 100%;
  }
`;

export const loader = keyframes`
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
`;

export const Wrapper = styled.div`
  /* display: flex; */
  display: ${(props) => (props.active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  backdrop-filter: blur(4px);
  z-index: ${(props) => (props.active ? '1000000' : '-1')};
  opacity: ${(props) => (props.active ? '1' : '0')};
  animation: ${fadeAnimation} 0.5s ease-in;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 69px 88px 48px;
  text-align: center;
  /* width: 448px; */
  background-color: #ffffff;
  color: #2b2b2b;
  position: relative;
  padding: 0;
  width: 700px;
  border-radius: 25px;
  text-align: left;
  height: calc(var(--vh, 1vh) * 75);
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));

  @media (max-width: 992px) {
    width: 90%;
    height: 90%;
    border-radius: 15px;
  }
`;

export const Close = styled.a`
  position: absolute;
  top: ${(props) => (props.isMobile ? -30 : -27)}px;
  right: ${(props) => (props.isMobile ? 15 : -27)}px;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 0 50px;
  width: 100%;
  height: 100%;
  overflow: auto;
  p {
    margin: 10px 0;
  }
  .subtitle {
    margin: 35px 0 20px;
    font-size: 20px;
    font-weight: 800;
  }
  @media (max-width: 992px) {
    padding: 0 20px;
    .subtitle,
    p {
      font-size: 12px;
    }
  }
`;

const H2 = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: #0063a1;
  padding: 50px 40px 0 40px;
  width: 100%;
  @media (max-width: 992px) {
    font-size: 20px;
    padding: 20px 20px 0px 20px;
  }
`;

const ContainerCheck = styled.div`
  width: 100%;
  padding: 40px;

  position: relative;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrapper-checkbox {
    border-top: 0.5px solid #565656;
    margin-bottom: 20px;
  }
  .checkbox {
    margin: 10px 0;
  }
  input {
    width: 18px;
    height: 18px;
    border: 0px solid transparent;
  }
  input[type='checkbox']:before,
  input[type='checkbox']:after {
    background-color: transparent;
    border: 2px solid #797979;
    box-sizing: border-box;
    content: '';
    height: 18px;
    position: absolute;
    transition: all 0.3s ease-in-out;
    width: 18px;
    z-index: 1;
    border-radius: 5px;
  }
  label {
    display: flex;
    align-items: center;
    div {
      margin: 0 5px;
    }
  }
  @media (max-width: 992px) {
    font-size: 10px;
    padding: 20px;
    .wrapper {
      display: block;
    }
    label {
      margin: 10px 0;
    }
  }
`;

const Button = styled.div`
  width: 143px;
  height: 44px;
  border-radius: 50px;
  background-color: ${(props) => (props.active ? '#9ebf43' : '#797979')};
  cursor: ${(props) => (props.active ? 'pointer' : '')};
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalTerms = (props) => {
    const [accept, setAccept] = useState(false);
    const [accept1, setAccept1] = useState(false);
    return (
        <Wrapper active={props.active}>
            <Background/>
            <Modal>
                <H2>Política de privacidad</H2>
                <Content isMobile={props.isMobile}>
                    <p className='subtitle'>I. Objetivo</p>

                    <p>
                        A continuación, informamos como COLEGIOS PERUANOS S.A., con
                        domicilio en AV. CARLOS VILLARÁN NRO. 140 URB. SANTA CATALINA LA
                        VICTORIA – LIMA (Titular de los Banco de Datos) realiza el
                        tratamiento de los datos personales de sus colaboradores, los que se
                        recopilan a través de fichas, correos y el Portal Web Intranet
                        Innova (la “Intranet”) siendo que en la Intranet se realiza la
                        obtención y almacenamiento de los consentimientos informados de
                        tratamiento de datos personales.
                    </p>
                    <p>
                        Los colaboradores de COLEGIOS PERUANOS S.A. podrán acceder a la
                        Intranet utilizando su usuario y contraseña.
                    </p>

                    <p className='subtitle'>
                        II. Marco normativo y banco de datos personales
                    </p>
                    <p>
                        Estas condiciones de tratamiento de datos se encuentran reguladas
                        por la legislación peruana en materia de protección de datos
                        personales (el “Marco Normativo”):
                    </p>

                    <p>● Ley No. 29733, Ley de Protección de Datos Personales</p>
                    <p>
                        ● Decreto Supremo No. 003-2013-JUS, que aprueba el Reglamento de la
                        Ley No. 29733
                    </p>
                    <p>
                        ● Directiva de Seguridad de la Información, aprobada por la
                        Resolución Directoral No. 019-2013-JUS/DGPDP
                    </p>
                    <p>
                        La información será almacenada en los bancos de datos personales de
                        “Colaboradores” de COLEGIOS PERUANOS S.A.
                    </p>

                    <p className='subtitle'>III. Datos personales a tratar</p>
                    <p>
                        Para llevar a cabo las finalidades descritas en la presente Política
                        de Privacidad, es obligatorio que el titular de datos personales (el
                        “Colaborador”) proporcione los datos personales requeridos en el
                        formulario, incluyendo el correo personal.
                    </p>
                    <p>
                        Adicionalmente, COLEGIOS PERUANOS S.A., en el contexto de la
                        relación contractual, realiza el tratamiento de los siguientes datos
                        personales (que podrían contener datos sensibles) que recopila a
                        través de correos, fichas y la página web
                        https://intranet.innovaschools.edu.pe:
                    </p>
                    <p>
                        ● Datos de carácter identificativos: Nombres y apellidos; documentos
                        de identidad; domicilio; teléfonos y correo electrónico personal.
                    </p>
                    <p>
                        ● Datos de características personales: Estado civil, fecha de
                        nacimiento, nacionalidad, sexo, edad, profesión.
                    </p>
                    <p>
                        ● Datos de carácter social: Dirección de domicilio actual, domicilio
                        indicado en su DNI, características de domicilio.
                    </p>
                    <p>
                        ● Datos sensibles: huella dactilar, firmas holográficas y
                        electrónicas; estado civil; fecha de nacimiento; nacionalidad;
                        profesión, ocupación; edad; datos académicos; datos de
                        derechohabientes; datos relativos a la salud; remuneración e
                        ingresos entregados por la empresa, datos financieros para efectos
                        del pago y obligaciones de seguridad social. Datos biométricos como
                        las imágenes de las que soy parte y que se registraron en las fotos
                        y/o videos efectuados por COLEGIOS PERUANOS S.A. Datos económicos –
                        financieros como el historial de préstamos de la organización,
                        información tributaria, historial de préstamos realizados por un
                        ente bancario descontado por la compañía
                    </p>
                    <p className='subtitle'>IV. Finalidad</p>
                    <p>
                        Los datos personales proporcionados serán tratados por COLEGIOS
                        PERUANOS S.A. para realizar la gestión, ejecución y control de la
                        relación laboral, incluyendo pero sin limitarse a la remisión al
                        Colaborador de la documentación referida a las boletas de pago de
                        remuneración mensual, remuneración vacacional, gratificaciones
                        legales, liquidación de beneficios sociales y demás documentos que
                        se generen al cese, así como información relevante de carácter
                        laboral durante todo el tiempo que resulte necesario, así como para
                        suministrar la información a aquellas personas y entidades que en
                        mérito de su relación laboral con el Colaborador la requieran, tal
                        como se describe en la sección VI.
                    </p>
                    <p className='subtitle'>V. Plazo de conservación</p>
                    <p>
                        Los datos personales proporcionados se conservarán mientras que el
                        Colaborador mantenga una relación laboral con COLEGIOS PERUANOS S.A
                        e incluso posteriormente en cumplimiento con la normativa laboral
                        vigente.
                    </p>
                    <p className='subtitle'>VI. Transferencia y destinatarios</p>
                    <p>
                        Se informa al Colaborador que los datos personales proporcionados a
                        COLEGIOS PERUANOS S.A. son transferidos de manera necesaria a los
                        siguientes destinatarios:
                    </p>
                    <p>
                        (i) A nivel nacional e internacional; A las personas y entidades con
                        las que el Colaborador mantiene una relación contractual y para cuyo
                        cumplimiento es necesaria la información del titular que su
                        empleador gestiona, tales como: (a) administradoras de fondos de
                        pensiones; (b) entidades gubernamentales; (c) la Superintendencia
                        Nacional de Aduanas y Administración Tributaria - SUNAT; (d) seguro
                        social; (e) Oficina de Normalización Previsional - ONP; (f)
                        entidades prestadoras de salud; (g) entidades aseguradoras; (h)
                        empresas prestadoras de servicios de mensajería; (i) Microsoft
                        Corporation, proveedor de servicios de nube cuyo servidor se
                        encuentra en Estados Unidos; (j) Amazon Web Server, proveedor de
                        servicios de nube cuyo servidor se encuentra en Estados Unidos; (k)
                        G Suite, proveedor de servicios de correo y nube cuyo servidor se
                        encuentra en Estados Unidos; y (l) Marsh, bróker de seguros.(ii)
                        COLEGIOS PERUANOS S.A. con domicilio en Av. Carlos Villarán No. 140,
                        Urbanización Santa Catalina, La Victoria, Lima, Perú, como titular
                        de los bancos de datos personales, y en la medida que es la empresa
                        que administra los servicios en la nube para la empresa.
                    </p>

                    <p className='subtitle'>VII. Ejercicio de derechos</p>
                    <p>
                        El Colaborador podrá ejercer los derechos de acceso, rectificación
                        cancelación y oposición de los datos personales; así como revocar el
                        consentimiento para las finalidades adicionales y no necesarias para
                        la ejecución de la relación laboral, a través del correo
                        electrónico: divisiongdh@innovaschools.edu.pe en nuestras oficinas
                        ubicadas en la dirección señalada líneas arriba.
                    </p>
                    <p>
                        De considerar que no ha sido atendido en el ejercicio de los
                        derechos puede presentar una reclamación ante la Autoridad Nacional
                        de Protección de Datos Personales, dirigiéndose a la Mesa de Partes
                        del Ministerio de Justicia y Derechos Humanos: Calle Scipión Llona
                        350, Miraflores, Lima, Perú llenando el formulario publicado en el
                        siguiente enlace:
                    </p>
                    <p>
                        https://www.minjus.gob.pe/wp-content/uploads/2018/12/FORMULARIO-DE-
                        PROCEDIMIENTO-TRILATERAL-DE-TUTELA.pdf
                    </p>

                    <p className='subtitle'>VIII. Resguardo de la información</p>
                    <p>
                        COLEGIOS PERUANOS S.A ha adoptado las medidas de seguridad
                        necesarias para garantizar la protección de los datos personales
                        proporcionados por el Colaborador a fin de evitar su alteración,
                        pérdida, acceso o procesamiento no autorizado por parte de terceros
                        que puedan afectar la información de carácter personal.
                    </p>

                    <p className='subtitle'>IX. Divulgación</p>
                    <p>
                        COLEGIOS PERUANOS S.A puede revelar los datos personales cuando
                        estos sean requeridos por autoridades judiciales, administrativas u
                        otras, en el debido ejercicio de sus funciones, a efectos de
                        colaborar con las investigaciones o procedimientos llevados a cabo
                        por dichas autoridades gubernamentales.
                    </p>
                    <p className='subtitle'>X. Cookies</p>
                    <p>
                        La Página Web utiliza cookies, estrictamente necesarias y esenciales
                        para que se utilice la Página Web y se permita un libre movimiento,
                        utilizar áreas seguras u opciones personalizadas. Asimismo, las
                        cookies se utilizan para analizar la experiencia de los usuarios de
                        Internet y poder brindarles mejores servicios y mostrar contenido
                        personalizado.
                    </p>
                    <p>
                        COLEGIOS PERUANOS S.A. utilizará cookies para realizar análisis
                        estadísticos sobre el número de visitantes de la Página Web y otros
                        estudios sobre la utilización de los servicios. COLEGIOS PERUANOS
                        S.A. también utilizará cookies para poder gestionar la experiencia
                        del visitante en la Página Web, con la finalidad de mejorar su
                        experiencia de navegación y mejorar sus servicios.
                    </p>

                    <p className='subtitle'>
                        XI. Modificaciones de la Política de Privacidad
                    </p>
                    <p>
                        COLEGIOS PERUANOS S.A se reserva expresamente el derecho a
                        modificar, actualizar o completar en cualquier momento las presentes
                        condiciones de tratamiento de datos. Cualquier modificación,
                        actualización o ampliación producida en las condiciones de
                        tratamiento de datos será inmediatamente comunicada al Colaborador y
                        publicada en la Página Web, por lo cual el titular del dato estará
                        enterado de qué información se recopila, cómo y bajo qué
                        circunstancias COLEGIOS PERUANOS S.A utiliza y trata su información.
                    </p>
                </Content>

                <ContainerCheck>
                    <div className='wrapper-checkbox'></div>
                    <div className='wrapper'>
                        <div>
                            <div className='checkbox'>
                                <label>
                                    <input
                                        checked={accept}
                                        type='checkbox'
                                        onChange={(event) => setAccept(event.target.checked)}
                                    />
                                    <div>He leído la Política de Privacidad</div>
                                </label>
                            </div>
                            <div className='checkbox'>
                                <label>
                                    <input
                                        checked={accept1}
                                        type='checkbox'
                                        onChange={(event) => setAccept1(event.target.checked)}
                                    />
                                    <div>Acepto la Política de Privacidad</div>
                                </label>
                            </div>
                        </div>

                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button
                                onClick={() => (accept && accept1 ? props.sendTerms() : null)}
                                active={accept && accept1}>
                                Continuar
                            </Button>
                        </div>
                    </div>
                </ContainerCheck>
            </Modal>
        </Wrapper>
    );
};

export default ModalTerms;
