import React, { useState, useEffect, useRef } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { useManageGetRequest } from "../../../hooks/useManageGetRequest/useManageGetRequest";
import { useManagePostRequest } from "../../../hooks/useManagePostRequest/useManagePostRequest";
import { useForm } from "../../../hooks/useForm/UseForm";
import {
  getEncuestaActivityAPI,
  saveEncuestaActivityAPI,
} from "../../../consumers/backendApisUrls";
import Select from "react-select";

const EncuestaInduccionModal = ({
  showModal,
  IdEmpleadoFlujoActividad,
  manageSearch,
  nombreUser,
  isAdmin,
}) => {
  const [dataEncuesta, setDataEncuesta] = useState({});
  const [value, setValue] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleChange] = useForm(value, setValue);
  const [executeGetRequest] = useManageGetRequest();
  const [executePostRequest] = useManagePostRequest();

  const getInitialData = async () => {
    const api = getEncuestaActivityAPI(IdEmpleadoFlujoActividad);
    await executeGetRequest(api, successInitialDataCallBack);
  };

  const successInitialDataCallBack = (response) => {
    setDataEncuesta(response?.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = saveEncuestaActivityAPI();
      const payload = setPayload();

      await executePostRequest(api, payload, successEncuestaCallBack, true);
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  };

  const setPayload = () => {
    let result = Object.keys(value).map((key) => ({
      IdPregunta: parseInt(key.substring(4)),
      Alternativa: value[key],
    }));

    let payload = {
      IdEmpleadoFlujoActividad: IdEmpleadoFlujoActividad,
      Idencuesta: dataEncuesta.IdEncuesta,
      Items: result,
    };
    return payload;
  };

  const successEncuestaCallBack = () => {
    showModal();
    manageSearch();
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      getInitialData();
    }
    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <GenericModal showModal={showModal} width={"800px"} titulo={"Encuesta"}>
      <div className="w-100">
        <form className="d-flex flex-column" onSubmit={handleSubmit}>
          <div className="w-100 mb-2 font-weight-bold">
            {dataEncuesta.NombreEncuesta}
          </div>
          {isAdmin &&
            <div className="w-100 mb-3 font-weight-bold">
                {nombreUser}
            </div>
          }
          {dataEncuesta.Questions?.map((question) => (
            <MultiTypeSelect
              key={question.IdQuestion}
              question={question}
              handleInputChange={handleChange}
              value={value}
              setValue={setValue}
            />
          ))}
          <div className="d-flex justify-content-end">
            <button
              type="submit"
              className="generic-button-buscador"
              disabled={isSubmitting}
            >
              Enviar
            </button>
          </div>
        </form>
      </div>
    </GenericModal>
  );
};

export default EncuestaInduccionModal;

const MultiTypeSelect = ({ question, handleInputChange, value, setValue }) => {
  const [questionType, setQuestionType] = useState(question?.QuestionType);
  const [questionExtraData, setQuestionExtraData] = useState(JSON.parse(question?.ExtraJsonData)?.Values);
  const [textAreaCount, setTextAreaCount] = useState(0);

  const mapDropDopwnOptionsArray = (options) => {
    if (options)
      return options.map((option, i) => ({ value: i, label: option }));
  };

  const handleSelect = (e) => {
    let name = `quiz${question.IdQuestion}`;
    setValue({ ...value, [name]: e.label });
  };

  const handleTextArea = (e) => {
    setTextAreaCount(e.target.value.length)
    handleInputChange(e)
  }

  const TipoDatoAlternativa = {
    List: (
      <Select
        placeholder="Seleccionar"
        options={mapDropDopwnOptionsArray(questionExtraData)}
        onChange={handleSelect}
        name={`quiz${question?.IdQuestion}`}
      />
    ),
    TextMultiLine: (
      <div className="form-group mb-0 pb-0">
        <textarea
          className="form-control"
          rows="3"
          name={`quiz${question?.IdQuestion}`}
          onChange={handleTextArea}
          placeholder="Máximo 1000 Caracteres"
          maxLength="1000"
        ></textarea>
        <div className="text-no-hay-novedades">{textAreaCount}/1000</div>
      </div>
    ),
    Values: (
      <div className="d-flex justify-content-around mx-5">
        {questionExtraData &&
          questionExtraData.map((value, i) => (
            <div key={i} className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`quiz${question?.IdQuestion}`}
                id={`quiz${question?.IdQuestion}${i}`}
                value={value}
                onChange={handleInputChange}
              />
              <label
                className="form-check-label"
                htmlFor={`quiz${question?.IdQuestion}${i}`}
              >
                {value}
              </label>
            </div>
          ))}
      </div>
    ),
  };

  return (
    <div className="mb-5">
      <label className="mb-2">{question.Question}</label>
      {TipoDatoAlternativa[questionType]}
    </div>
  );
};
