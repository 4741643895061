import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
import ReactTooltip from "react-tooltip";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import { getComponentesNuevaPlantillaAPI, getEtiquetasNuevaPlantillaAPI } from "../../../../consumers/backendApisUrls";

function Editor({ value, handleChange, placeholder, idTemplateType, setLoading }) {
  const dropdownQuillRef = useRef(null);

  const [tags, setTags] = useState([]);
  const [components, setComponents] = useState([]);
  const [newLoading, setNewLoading] = useState(true);

  const [executeGet] = useManageGetRequest();

  const setContainerStyles = () => {
    document.querySelector(".ql-container").style.minHeight = "12rem";
    document.querySelector(".ql-editor").style.minHeight = "12rem";

    document.querySelector(".ql-container").style.maxHeight = "20rem";
    document.querySelector(".ql-editor").style.maxHeight = "20rem";
  };

  const setDropdownLabel = (placeholder, query = "") => {
    let dropdownElement = document?.querySelector(`.ql-${query} .ql-picker-label`);
    if (dropdownElement) dropdownElement.innerHTML = `${placeholder} ▾`;

    let dropdownSelect = document?.querySelector(`span.ql-${query}.ql-picker span.ql-picker-options span.ql-picker-item`);
    if (dropdownSelect) dropdownSelect.classList.remove("ql-selected");
  };

  const setItemsLabel = (query) => {
    const itemsWrapper = Array.prototype.slice.call(document.querySelectorAll(`.ql-${query} .ql-picker-item`));

    itemsWrapper.forEach(item => {
      const dataValue = item.dataset.value;
      const commaIndex = dataValue.indexOf(",");

      item.textContent = dataValue.slice(0, commaIndex);
    });
  };

  const initEditorModules = async () => {
    try {
      await executeGet(getEtiquetasNuevaPlantillaAPI(idTemplateType), (res) => setTags(handleResponse(res.data, "NombreEtiqueta")));
      await executeGet(getComponentesNuevaPlantillaAPI(), (res) => setComponents(handleResponse(res.data, "NombreComponente")));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setNewLoading(false);
    }
  }

  const handleResponse = (response, name) => {
    return response.map((o) => [o[name], o.Codigo]);
  };

  const genericDropdownHandler = (value, boolNewLine = false) => {
    if (value && dropdownQuillRef.current) {
      const commaIndex = value.indexOf(",");

      const cursorPosition = dropdownQuillRef.current.getEditor().getSelection().index;
      dropdownQuillRef.current.getEditor().insertText(cursorPosition, value.slice(commaIndex + 1) + (boolNewLine ? "\n\n" : ""));
      dropdownQuillRef.current.getEditor().setSelection(cursorPosition + value.length + (boolNewLine ? 2 : 0));
    }
  }

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ align: [] }],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          ["link"],
          [{ tags: tags }, { componentes: components }],
        ],
        handlers: {
          componentes: (value) => genericDropdownHandler(value, true),
          tags: (value) => genericDropdownHandler(value),
        },
      },
    }),
    [tags, components]
  );

  useEffect(() => {
    if (idTemplateType) {
      setLoading(true);
      setNewLoading(true);
      initEditorModules();
    }
  }, [idTemplateType]);

  useEffect(() => {
    if (!newLoading) {
      setContainerStyles();
      setDropdownLabel("Componentes", "componentes");
      setItemsLabel("componentes");
      setDropdownLabel("Etiquetas", "tags");
      setItemsLabel("tags");
    }
  }, [newLoading, dropdownQuillRef]);

  if (newLoading) {
    return (
      <ReactTooltip
        id="informacion-tipo-plantilla"
        place="top"
        effect="solid"
        className="tooltip-style tooltip-change-witdh"
      >
        Seleccione el tipo de plantilla
      </ReactTooltip>
    );
  }

  return (
    <div className="text-editor" name="ReactQuillEditor" style={{ borderSpacing: "1px", minHeight: "7rem" }}>
      <ReactQuill
        key="uniqueReactQuillEditor"
        theme="snow"
        onChange={handleChange}
        value={value}
        modules={modules}
        placeholder={placeholder}
        ref={dropdownQuillRef}
      />
    </div>
  );
}

export default Editor;