import axios from "axios";
import {useContext} from "react";
import {AuthContext} from "../context/authContext";
import {useHistory} from "react-router-dom";


export const useGetRequest = () => {
    const {state, dispatch} = useContext(AuthContext);
    const history = useHistory();
    const urlApiBackend = process.env.REACT_APP_URL_API_BACKEND;

    const executeGetRequest = (api) => {
        if (api) {
            return axios.get(`${urlApiBackend}${api}`, {
                headers: {
                    'content-type': 'application/json',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'GET, POST',
                    Authorization: 'Bearer ' + state.token,
                },
            }).catch(response => {
                console.error("error response", response);
                manageResponse(response);
                return response;
            });
        }
    };

    function executeGetRequestCallback(functionThatReturnsAPI) {
        return function () {
            return executeGetRequest(functionThatReturnsAPI);
        }
    }

    const manageResponse = (response) => {
        if (response.response !== undefined) {
            if (response.response.status === 401) {
                console.error("Error 401");
                redirectToLogIn(dispatch);
                mostrarMensajeSesionExpirada(dispatch);
            } else if (response.response.status === 403) {
                console.error("Error 403 ", response);
                history.push("/");
            } else if (response.response.status === 500) {
                console.error("Error 500 ", response);
                redirectToLogIn(dispatch);
            }
        }
    };

    function mostrarMensajeSesionExpirada(dispatch) {
        console.debug("mostrarMensajeSesionExpirada");
        dispatch({
            type: 'SHOW_MESSAGE_EXPIRED_SESSION',
        });
    }

    function redirectToLogIn(dispatch) {
        console.debug("redirectToLogIn");
        dispatch({
            type: 'LOGOUT',
        });
    }

    return [executeGetRequest, executeGetRequestCallback]
};