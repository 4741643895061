import React from 'react'
import GenericModal from '../../../../../../components/modal/GenericModal'

const EliminarDatoFamiliar = ({ toggleModal, familiar, setFormValues }) => {

  const handleAction = () => {
    setFormValues(lastData => {
      const familiares = lastData.Familiares;
      const updatedFamiliares = familiares.filter(o => o.IdPersonaFamiliar !== familiar.IdPersonaFamiliar);
      return {
        ...lastData,
        Familiares: updatedFamiliares
      }
    })
    toggleModal();
  }

  return (
    <GenericModal
      titulo="Eliminar"
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a eliminar el siguiente item:
          <ul>
            <li>
              <strong>{familiar.NombreTipoDocumento} - {familiar.NumeroDocumento}: {familiar.Nombres} {familiar.ApellidoPaterno} {familiar.ApellidoMaterno}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer justify-content-end">
          <button 
            className="btn btn-primary generic-button-ficha mr-2" 
            onClick={handleAction}>
            Eliminar
          </button>
          <button 
            className="btn btn-primary generic-button-ficha" 
            onClick={() => {toggleModal()}}>
            Cancelar
          </button>
      </div>
    </GenericModal>
  )
}

export default EliminarDatoFamiliar