import React, {useState} from 'react';
import {FROM_GESTION_FICHA} from "../../constants/hardCodedConstants";

const TableWebBodyGestionFicha = ({users}) => {
    return (
        <tbody className="listar-tbody">
        {users.map((user, i) => (
            <tr key={i}>
                <td>{user.IdEmpleado}</td>
                <td onClick={() => {
                    window.open(`/fichadedatos/${FROM_GESTION_FICHA}/${user.IdEmpleado}`, '_self');
                }}>
                    <div style={{display: 'flex', alignItems: 'center'}} className="text-link-appereance font-gothan-light">
                        <img
                            style={{borderRadius: '50%', width: '25px'}}
                            src={user.ProfileImageUrl}
                            alt=''
                        />
                        <div style={{margin: '0 10px'}}>{user.NombreCompleto}</div>
                    </div>
                </td>
                <td>{user.Estado}</td>
                <td>{user.NombreCargo}</td>
                <td>{user.NombreJefe}</td>
                <td>{user.NombreDepartamento}</td>
                <td>{user.NombreArea}</td>
                <td>{user.NombreSede}</td>
            </tr>
        ))}
        </tbody>
    );
};

export default TableWebBodyGestionFicha;
