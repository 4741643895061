import React from 'react'
import InputSelect from '../../../../components/modalComponents/InputSelect';

const HorarioLactancia = ({ selectProps, keys: KEYS, isDisabled = false }) => {
  return (
    <>
      <InputSelect label="Horario lactancia" {...selectProps(KEYS.idLactancia)} isDisabled={isDisabled}/>
    </>
  )
}

export default HorarioLactancia;