import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from "../../../components/Select/SelectInput";
import { guardarReservaOcurrenciaAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';

const Ocurrencia = ({ informacionOcurrencia, showModal }) => {

    const SECTION_NAME = "OCURRENCIA";
    const allInputKeys = ["IdEmpleado", "DetalleOcurrencia"];
    const [executePostRequest] = usePostRequest();
    const [participanteOptions, setParticipanteOptions] = useState([]);
    const [jsonSelectedParticipante, setJsonSelectedParticipante] = useState({});
    const [detalleOcurrencia, setDetallerOcurrencia] = useState("");

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = () => {
        let participantesOptions = informacionOcurrencia.participantes.map(o => {
            return {
                value: o.IdParticipante,
                label: o.NombreParticipante
            };
        });
        setParticipanteOptions(participantesOptions);
    };

    const closeForm = () => {
        showModal();
    };

    const handleChange = (event) => {
        setDetallerOcurrencia(event.target.value);
    };

    const submit = async (event) => {
        try {
            event.preventDefault();
            let payload = obtenerPayload();
            let response = await executePostRequest(guardarReservaOcurrenciaAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                closeForm();
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, SECTION_NAME);
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }

    }

    const obtenerPayload = () => {
        let payload = {
            IdReserva: informacionOcurrencia.idReserva,
            IdEmpleado: jsonSelectedParticipante?.value,
            DetalleOcurrencia: detalleOcurrencia
        };
        return payload;
    }

    return (
        <>
            <GenericModal showModal={closeForm} width={"750px"} titulo={"Ocurrencia"}>
                <div className="modal-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Sede</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    options={participanteOptions}
                                    jsonSelectedValue={jsonSelectedParticipante}
                                    setJsonSelectedValue={setJsonSelectedParticipante}
                                    placeholder={"Seleccionar"} />
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdEmpleado-validation-message`}></p>

                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element">Ocurrencia</label>
                            <div className="col-sm-8 black-color">
                                <textarea className="form-control" rows="3" onChange={handleChange} value={detalleOcurrencia} placeholder="Máximo 200 caractares" maxLength="200"></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-DetalleOcurrencia-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button d-flex justify-content-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default Ocurrencia;