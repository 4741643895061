import {clearInputValue, extensionYtamañoDeArchivoEsValido, getFormattedName, obtenerArchivoDeEvent, responseUploadFileSuccess} from "./utils";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS} from "../constants/hardCodedConstants";
import {uploadFile} from "../consumers/backendConsumer";
import {showToastErrorMaxFileSize} from "../components/alerts/AlertErrorMaxFileSize";
import {toast} from "react-toastify";

export const handleUploadFileGeneric = async (event, setSubiendoArchivo, token, setS3UploadFileResponse, setFileName, inputFileId) => {
    try {
        let file = obtenerArchivoDeEvent(event);
        if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
            setSubiendoArchivo(true);
            let response = await uploadFile(file, token);
            setSubiendoArchivo(false);
            console.debug("response ", response);
            manageUploadFileResponseGeneric(response, file, setS3UploadFileResponse, setFileName, inputFileId);
        } else {
            resetUploadFile();
            showToastErrorMaxFileSize();
        }
    } catch (e) {
        console.error(e);
    }
};

export const removeUploadedFileGeneric = function (setFileName, setS3UploadFileResponse, inputFileId) {
    resetUploadFile(setFileName, setS3UploadFileResponse, inputFileId);
};

const manageUploadFileResponseGeneric = (response, file, setS3UploadFileResponse, setFileName, inputFileId) => {
    if (responseUploadFileSuccess(response)) {
        let s3Response = response.data.file;
        setS3UploadFileResponse(s3Response);
        setFileName(getFormattedName(file.name));
        toast.success("El archivo se cargó exitosamente");
    } else {
        resetUploadFile(setFileName, setS3UploadFileResponse, inputFileId);
        toast.error("Hubo un error al subir el archivo");
    }
};

export const resetUploadFile = (setFileName, setS3UploadFileResponse, inputFileId) => {
    setFileName("");
    setS3UploadFileResponse({});
    clearInputValue(inputFileId);
};

export const resetUploadFileV2 = (setS3UploadFileResponse, inputFileId) => {
    setS3UploadFileResponse({});
    clearInputValue(inputFileId);
};