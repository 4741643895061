import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom';
import DirectorioWrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import {AuthContext} from "../../hooks/context/authContext";
import {getConsultarEncuestaSaludPendienteAPI, retornoClasesListar} from "../../consumers/backendApisUrls";
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest";
import RegistroVacunaModal from "../../components/modalVacunas/RegistroVacunaModal";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {useManageGetRequest} from "../../hooks/useManageGetRequest/useManageGetRequest";
import RegistroEncuestaSaludModal from "../../components/encuestaSaludModal/RegistroEncuestaSaludModal";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import AgregarSustento from "./modales/AgregarSustento";
import GenericTooltip from "../../components/GenericTooltip/GenericTooltip";
import SolicitoRevisarCaso from "./modales/SolicitoRevisarCaso";
import DetalleEncuestaSaludModal from '../encuestasDeSalud/modales/DetalleEncuestaSaludModal';
import CapacitacionModal from './modales/CapacitacionModal/CapacitacionModal';

const CheckRetornoClases = () => {
    const [loading, setLoading] = useState(true);
    const {state} = useContext(AuthContext);
    const [managePostRequest] = useManagePostRequest();
    const [manageGetRequest] = useManageGetRequest();
    const [listarData, setListarData] = useState([]);
    const [hideRegistroVacunaModal, setHideRegistroVacunaModal] = useState(true);
    const [hideRegistroVacunaEditModal, setHideRegistroVacunaEditModal] = useState(true);
    const [hideEncuestaSaludModal, setHideEncuestaSaludDetail] = useState(true);
    const [hideEncuestaSaludEditModal, setHideEncuestaSaludEdit] = useState(true);
    const [hideCapacitacionModal, setHideCapacitacionModal] = useState(true);
    const [hideAgregarSustentoModal, setHideAgregarSustentoModal] = useState(true);
    const [hideSolicitoRevisarCasoModal, setHideSolicitoRevisarCasoModal] = useState(true);
    const [idEncuestaSaludDetalle, setIdEncuestaSaludDetalle] = useState(0);
    const [modalEncuestaSaludTitle, setModalEncuestaSaludTitle] = useState("");
    const [executeGetRequest] = useGetRequest();
    const [reload, setReload] = useState(false);

    const [idItem, setIdItem] = useState(0);
    const history = useHistory();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, [reload]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await managePostRequest(retornoClasesListar(), {}, listarSucessCallback, false);
            await manageShowModalEncuestaSalud();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const manageShowModalEncuestaSalud = async function () {
        const response = await executeGetRequest(getConsultarEncuestaSaludPendienteAPI());
        if (responseCode200(response)) {
            setModalEncuestaSaludTitle(response?.data?.NombreEncuesta);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const listarSucessCallback = function (response) {
        setListarData(response.data);
    }

    const toggleRegistroVacunaModal = (idItem) => {
        setHideRegistroVacunaModal(!hideRegistroVacunaModal);
        setIdItem(idItem);
    };

    const toggleRegistroVacunaEditModal = (idItem) => {
        setHideRegistroVacunaEditModal(!hideRegistroVacunaEditModal);
        setIdItem(idItem);
    };

    const toggleEncuestaSaludModal = (idItem) => {
        setHideEncuestaSaludDetail(!hideEncuestaSaludModal);
        setIdItem(idItem);
    };

    const toggleEncuestaSaludEditModal = (idItem) => {
        setHideEncuestaSaludEdit(!hideEncuestaSaludEditModal);
        setIdItem(idItem);
    };

    const toggleAgregarSustentoModal = (IdEncuestaSaludDetalle) => {
        setHideAgregarSustentoModal(!hideAgregarSustentoModal);
        setIdEncuestaSaludDetalle(IdEncuestaSaludDetalle);
    };

    const toggleSolicitoRevisarCasoModal = (IdEncuestaSaludDetalle) => {
        setHideSolicitoRevisarCasoModal(!hideSolicitoRevisarCasoModal);
        setIdEncuestaSaludDetalle(IdEncuestaSaludDetalle);
    };

    const toggleCapacitacionModal = (idItem) => {
        setHideCapacitacionModal(!hideCapacitacionModal);
        setIdItem(idItem);
    };

    const RedirectToFichaDatos = () =>{
        const handleOnClickPush = () => history.push('/fichadedatos/');
        handleOnClickPush();
    };

    const RedirectToMisCapacitaciones = () =>{
        const handleOnClickPush = () => history.push('/misCapacitaciones/');
        handleOnClickPush();
    };

    const callbackSuccess = () => {
        setReload(!reload);
    };

    return (
        <DirectorioWrapper className='App'>
            <PageHeader title={"Retorno a clases 2023"}/>
            <div className='container-fluid body-content'>
                {hideRegistroVacunaModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroVacunaModal 
                    userId={state.user.id_usuario}
                    showModal={toggleRegistroVacunaModal} 
                    reload={callbackSuccess}/>
                </div>
                }

                {hideRegistroVacunaEditModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroVacunaModal 
                    showModal={toggleRegistroVacunaEditModal} 
                    reload={callbackSuccess}/>
                </div>
                }

                {hideEncuestaSaludEditModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <RegistroEncuestaSaludModal showModal={toggleEncuestaSaludEditModal} title={modalEncuestaSaludTitle} setReload={setReload}/>
                </div>
                }

                {hideEncuestaSaludModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <DetalleEncuestaSaludModal showModal={toggleEncuestaSaludModal} idEncuesta={idItem}/>
                </div>
                }

                {hideCapacitacionModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <CapacitacionModal showModal={toggleCapacitacionModal} setReload={callbackSuccess} idCursoCapacitacion={idItem}/>
                </div>
                }

                {hideAgregarSustentoModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <AgregarSustento toggleModal={toggleAgregarSustentoModal} idEncuestaDetalle={idEncuestaSaludDetalle} setReload={setReload}/>
                </div>
                }

                {hideSolicitoRevisarCasoModal === false &&
                <div className="modal-contactanos modal-response-ficha-background">
                    <SolicitoRevisarCaso toggleModal={toggleSolicitoRevisarCasoModal} idEncuestaDetalle={idEncuestaSaludDetalle} setReload={setReload}/>
                </div>
                }

                <div className='mt-2 mb-0 alert alert-light'>
                    <div className="row">
                        <div className="col-sm-3">
                            <strong>Nombre: </strong> {listarData && listarData?.NombreEmpleado}
                            <br/>
                            <strong>Sede: </strong> {listarData && listarData?.Sede}
                        </div>
                    </div>
                </div>
                <div className='table-responsive form-group-sm pt-3'>
                    <table className='table table-hover listar-table-cierre-anio'>
                        <thead className="listar-thead">
                        <tr>
                            <th>
                                <span className='font-gothan-medium d-inline'>Nombre</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Ver</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Estado</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Nota</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Cargar archivos</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Información adicional</span>
                            </th>
                            <th>
                                <span className='font-gothan-medium d-inline'>Semáforo</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="listar-tbody">
                        {listarData?.Items && listarData.Items.map((item, i) =>
                            <tr key={i}>
                                <td>{item?.NombreItem}</td>
                                <td>
                                    <div className="d-flex justify-content-start">
                                        {
                                            item?.VerLinkCode === 'show_vacuna_modal_edit' && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>toggleRegistroVacunaEditModal(item?.IdItem)}/>
                                        }
                                        {
                                            item?.VerLinkCode === 'show_vacuna_modal_detail' && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>toggleRegistroVacunaModal(item?.IdItem)}/>
                                        }
                                        {
                                            (item?.VerLinkCode === 'show_ficha_medica_modal_detail') && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>toggleEncuestaSaludModal(item?.IdItem)}/>
                                        }
                                        {
                                            (item?.VerLinkCode === 'show_ficha_medica_modal_edit') && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>toggleEncuestaSaludEditModal(item?.IdItem)}/>
                                        }
                                        {
                                            (item?.VerLinkCode === 'show_curso_capacitacion_modal') && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>toggleCapacitacionModal(item?.IdItem)}/>
                                        }
                                        {
                                            (item?.VerLinkCode === 'show_mis_capacitaciones_modal') && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>RedirectToMisCapacitaciones()}/>
                                        }
                                        {
                                            (item?.VerLinkCode === 'show_ficha_datos_modal') && 
                                            <FontAwesomeIcon icon='eye' className="hand-pointer ml-3" title="Link" onClick={()=>RedirectToFichaDatos()}/>
                                        }
                                    </div>
                                </td>
                                <td>{item?.EstadoValidacíon}</td>
                                <td>
                                    {item?.Nota &&
                                    <GenericTooltip id={item?.IdItem + '-'} text={item?.Nota}/>
                                    }</td>
                                <td>{item?.CargarArchivoLinkCode === 'show_cargar_archivo_modal' && <FontAwesomeIcon onClick={() => toggleAgregarSustentoModal(item?.IdItem)} icon='paperclip' className="hand-pointer ml-3" title="Adjuntar archivo"/>}</td>
                                <td>
                                    {item?.InformacionAdicional && !(item?.InformacionAdicional?.includes("show_modal_pasar_a_revision")) && <p>{item?.InformacionAdicional}</p>}
                                    {item?.InformacionAdicional && item?.InformacionAdicional?.includes("show_modal_pasar_a_revision") && item?.InformacionAdicional?.replace("show_modal_pasar_a_revision", "")}
                                    {item?.InformacionAdicional && item?.PuedoVerBoton && 
                                    <>
                                        <button type='button' className='generic-button-buscador' onClick={() => toggleSolicitoRevisarCasoModal(item?.IdItem)}>Solicita Revisión Ficha</button>
                                        <GenericTooltip id={item?.IdItem + '-'} text="Click aquí para notificar al médico que revise tu caso y reconsidere la condición de riesgo"/>
                                    </>
                                    }
                                </td>
                                <td>
                                    <FontAwesomeIcon icon='circle' size="2x" className="hand-pointer ml-3" title="Estado" color={item?.SemaforoHexadecimalColorCode}/>
                                </td>
                            </tr>
                        )}
                        
                        </tbody>
                    </table>
                </div>
            </div>
        </DirectorioWrapper>
    )
}

export default CheckRetornoClases;
