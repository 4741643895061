import {CHECKBOX_TRUE, SOLO_NUMEROS, SOLO_LETRAS_O_ESPACIOS, ANYTHING} from "./Regex";

export const INGRESAR_SOLO_LETRAS = "Ingresar solo letras sin espacios";
export const INGRESAR_SOLO_LETRAS_O_PALABRA_GUION_PALABRA = "Ingresar solo letras o palabra guión palabra o punto";
export const INGRESAR_SOLO_LETRAS_O_ESPACIOS = "Ingresar solo letras o espacios";
export const INGRESAR_SOLO_NUMEROS = "Ingresar solo números";
export const SELECCIONAR_UNA_OPCION = "Seleccionar una opción";
export const INGRESAR_UN_CORREO_VALIDO = "Ingresar un correo válido";
export const INGRESAR_SOLO_NUEVE_NUMEROS = "Ingresar solo nueve números";
export const INGRESAR_SOLO_LETRAS_O_ESPACIOS_O_NUMEROS = "Ingresar solo letras, números o espacios";
export const INGRESAR_SOLO_LETRAS_O_ESPACIOS_O_NUMEROS_DIEZ_LENGH = "Ingresar solo letras, números o espacios; como máximo 10";
export const NONE = ":(";
export const DECLARACION_JURADA = "Debe aceptar la declaración Jurada";

export const VALIDACIONES = {
    AceptaDeclaracionJurada: {regexs: [CHECKBOX_TRUE], obligatorio: true, message: DECLARACION_JURADA},
    IdVinculo: {regexs: [SOLO_NUMEROS], obligatorio: false, message: SELECCIONAR_UNA_OPCION},
    NombreContacto: {regexs: [SOLO_LETRAS_O_ESPACIOS], obligatorio: false, message: INGRESAR_SOLO_LETRAS_O_ESPACIOS},
    NumeroContacto: {regexs: [SOLO_NUMEROS], obligatorio: false, message: INGRESAR_SOLO_NUMEROS},
    Condicion: {regexs: [ANYTHING], obligatorio: false, message: INGRESAR_SOLO_LETRAS_O_ESPACIOS_O_NUMEROS}
};

export const updateValidationMessage = (fieldId, message) => {
    let element = document.getElementById(`ficha-informacion-medica-${fieldId}-validation-message`);
    element.innerHTML = message;
};

export const validarCampo = (fieldId) => {
    switch (fieldId) {
        default: {
            return elCampoEsInvalido(fieldId);
        }
    }
};

export const obtenerElementoDeFichaMedicaPorId = (id) => {
    return document.getElementById(`ficha-informacion-medica-${id}`);
};

export function obtenerValorDeElementoDeFichaMedicaPorId(id) {
    let element = obtenerElementoDeFichaMedicaPorId(id);
    return (element.matches('[type="checkbox"]')) ? element.checked : element.value;
}

const elCampoEsInvalido = (fieldId) => {
    let inputValue = obtenerValorDeElementoDeFichaMedicaPorId(fieldId);
    let campoInvalido = false;

    updateValidationMessage(fieldId, "");

    let validator = VALIDACIONES[fieldId];

    let esValidoConAlgunRegex = false;

    if (validator.regexs[0] != 'any') {
        if (inputValue === "") {
            if (validator.obligatorio) {
                updateValidationMessage(fieldId, "Este campo es obligatorio");
                campoInvalido = true;
            }
        } else {
            validator.regexs.forEach((regex) => {
                if (!(regex.test(inputValue))) {
                    campoInvalido = true;
                } else {
                    esValidoConAlgunRegex = true;
                }
            });

            campoInvalido = (esValidoConAlgunRegex) ? false : campoInvalido;
            if (campoInvalido) {
                updateValidationMessage(fieldId, validator.message);
            }
        }
    }
    return campoInvalido;
};