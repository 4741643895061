import React, {useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import popUpContent from '../../../image/ficha-popupInformativo-content.jpg';
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {aceptarOcultarModalInformativoAPI} from "../../../consumers/backendApisUrls";

const ModalInformativo = ({showModal}) => {
    const [checkNoVolverAMostrarModal, setCheckNoVolverAMostrarModal] = useState(false);
    
    const [executePostRequest] = usePostRequest();

    const clickOnCheck = function (e) {
        setCheckNoVolverAMostrarModal(e.target.checked);
    };

    const submit = async function () {
        if (checkNoVolverAMostrarModal === true) {
            const response = await executePostRequest(aceptarOcultarModalInformativoAPI(), {});
            if (!responseCode200(response)) {
                mostrarMensajeDeErrorConToast(response);
            }
        }
    };

    const closeModal = async function () {
        await submit();
        showModal();
    };

    return (
        <>
            <GenericModal showModal={closeModal} width={"750px"} titulo={"Noticias"}>
                <div className="modal-body" style={{'padding': '0'}}>
                    <img src={popUpContent} alt='' width="100%" style={{'margin': '-5px -5px -5px -5px'}}/>
                </div>
                <div className="modal-footer" style={{'display': 'flex', 'justify-content': 'space-between'}}>
                    <div className="message font-gothan-light" style={{'align-self': 'flex-start'}}>
                    </div>
                    <div className="button" style={{'align-self': 'flex-end'}}>
                        <input type="checkbox" checked={checkNoVolverAMostrarModal} onClick={clickOnCheck}/> No mostrarme esta ventana otra vez&nbsp;&nbsp;
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ModalInformativo;

