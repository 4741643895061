import React, {useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { cleanExecutingCommandScheduleAPI } from '../../../consumers/backendApisUrls';

const CleanSchedule = ({ toggleModal, id, title, manageSearch }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [managePost] = useManagePostRequest();

  const cleanSchedule = async() => {
    setIsDeleting(true);
    try {
        await managePost(cleanExecutingCommandScheduleAPI(id), {}, successCleanCallback)
    } catch (error) {
        console.error(error);
    }
    setIsDeleting(false);
  }

  const successCleanCallback = async () => {
    manageSearch();
    toggleModal();
  }

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo="Limpiar ejecución">
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a limpiar la ejecución del siguiente runner:
          <ul>
              <li><strong>{title}</strong></li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button className="btn btn-primary generic-button-ficha mr-2" onClick={() => {toggleModal()}}>
              Cancelar
          </button>
          <button className="btn btn-primary generic-button-ficha" onClick={() => {cleanSchedule()}} disabled={isDeleting}>
              Ejecutar
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default CleanSchedule