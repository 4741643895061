import React, { useContext, useEffect, useState } from 'react'
import PrePostNatal from '../modales/components/PrePostNatal';
import { Container } from '../modales/components/Components';
import confetti from "../../../image/confetti.svg"
import PageHeader from '../../../components/pageHeader/PageHeader';
import FooterModal from '../../../components/modalComponents/FooterModal';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import ModalMensaje from '../../../components/modalMensaje/ModalMensaje';
import ModalMensajeSuccessfull from '../../../components/modalMensaje/ModalMensajeSuccessfull';
import { AuthContext } from '../../../hooks/context/authContext';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { getBaseGuardarPrePostNatalAPI, guardarPrePostNatalAPI } from '../../../consumers/backendApisUrls';

const IMAGE_LOCK = "https://intranet.innovaschools.edu.pe/Content/image/lock_1.png";
const MENSAJE_EXPIRO = "El link de acceso para completar los datos ha caducado.";
const SECTION_NAME = "RegistrarPrePostNatal";
const message = "Sus datos han sido guardados.";
const KEYS = { fechaInicio: "FechaInicioPrePostNatal", fechaFin: "FechaFinPrePostNatal", sustento: "PrePostNatalFileViewModel" };

const PrePostNatalPublic = (props) => {
  const searchParams = new URLSearchParams(props.location.search);
  const token = searchParams.get('token');

  const [formValues, setFormValues] = useState({});
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });
  const [hasPermission, setHasPermission] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { dispatch } = useContext(AuthContext);
  const [executeGet] = useGetRequest();
  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const setTokenAuth = () => {
    if (token) {
      dispatch({
        type: 'PUBLIC_TOKEN',
        payload: {
          token: token,
        }
      });
      setIsPageLoaded(true);
    }
  }

  const getPermissions = async () => {
    const api = getBaseGuardarPrePostNatalAPI(0);
    
    try {
      let result = await executeGet(api);
      if (responseCode200(result)){
        const {PuedeRegistrarFechaInicioFinPrePostNatal} = result.data;
        setHasPermission(PuedeRegistrarFechaInicioFinPrePostNatal);
        if (PuedeRegistrarFechaInicioFinPrePostNatal)
          return;
      }
      showMessage(MENSAJE_EXPIRO);
    } catch (error) {
      console.error(error);
      showMessage("Ha ocurrido un error");
    }    
  }

  const showMessage = (message) => {
    setModalEnabled({isEnable: true, component: ModalMensaje, data: {modalMensaje: message, modalImage: IMAGE_LOCK, styleFicha: {color: "red"}, hasBackButton: false}});
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = guardarPrePostNatalAPI();
    try {
      await submitForm(Object.values(KEYS), api, formValues, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleSuccessSubmit = () => {
    setModalEnabled({ isEnable: true, component: ModalMensajeSuccessfull, data: { modalMensaje: message, image: confetti, hasBackButton: false } });
  }

  useEffect(() => {
    setTokenAuth();
  }, [token])

  useDidMountEffect(() => {
    getPermissions();
  }, [isPageLoaded])

  return (
    <PageWrapper>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} />
      <PageHeader title='Registrar Pre/Post Natal' />
      <Container>
        <form onSubmit={handleSubmit}>
          <PrePostNatal formValues={formValues} setFormValues={setFormValues} isUploading={isUploading} setIsUploading={setIsUploading} sectionName={SECTION_NAME} commomProps={commomProps} keys={KEYS} isEditable={hasPermission}/>
          <FooterModal label='Enviar' isDisabled={isUploading} isSubmitting={isSubmitting} />
        </form>
      </Container>
    </PageWrapper>
  )
}

export default PrePostNatalPublic;