import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({color, size, viewBox, style, onClick, children}) => {
    return (
        <svg onClick={onClick}
            fill={color}
            height={size}
            width={size}
            viewBox={viewBox}
            style={style}
        >
            {children}
        </svg>
    );
};

Icon.defaultProps = {
    color: '#8B9099',
    size: 24,
    viewBox: '0 0 24 24'
};

Icon.propTypes = {
    color: PropTypes.string,
    viewBox: PropTypes.string,
    size: PropTypes.number,
    style: PropTypes.any,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default Icon;
