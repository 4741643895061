import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import SeccionViewDatosPersonales from "./SeccionViewDatosPersonales";
import SeccionEditDatosPersonales from "./SeccionEditDatosPersonales";
import CustomAlert from "../../components/alerts/CustomAlert";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const DatosPersonales = ({empleadoId, showUpdateConfirmationModal, showSolicitarCambiosModal, reloadView, reloadSeccionesDatosPersonales}) => {
    const [showViewSeccion, setShowViewSeccion] = useState(true);
    const defaultAlertMessage = "Para validar tus actualizaciones o solicitudes de cambio te pediremos adjuntar PDF o imagen de tu DNI/CE. ¡Tenlo a mano!";

    return (
        <div>
            <CustomAlert variant={"warning"}>
                <p className="ficha-secciones-title-text font-gothan-light">
                    {defaultAlertMessage}&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer"/>
                </p>
            </CustomAlert>
            <div className='card-body'>
                {showViewSeccion === true &&
                <SeccionViewDatosPersonales empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadView={reloadView}/>
                }
            </div>
            <div>
                {(showViewSeccion === false) &&
                <SeccionEditDatosPersonales empleadoId={empleadoId} showUpdateConfirmationModal={showUpdateConfirmationModal} setShowViewSeccion={setShowViewSeccion} showSolicitarCambiosModal={showSolicitarCambiosModal} reloadSeccionesDatosPersonales={reloadSeccionesDatosPersonales}/>
                }
            </div>
        </div>
    );
};

export default DatosPersonales;
