import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputDate from '../../../components/modalComponents/InputDate'
import InputText from '../../../components/modalComponents/InputText'
import InputUploadFile from '../../../components/modalComponents/InputUploadFile'
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm'
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest'
import { asignacionFamiliarAdminActualizarFechasSolicitudAPI, getDetalleAsignacionFamiliarAPI } from '../../../consumers/backendApisUrls'

const VerDetalleModal = ({ toggleModal, refreshPage, IdSolicitudAsignacionFamiliar, PuedeEditar = false }) => {

  const SECTION_NAME = "verDetalleAsignacionFamiliar";
  const KEYS = {IdHijo: "IdHijo", Hijo: "NombreHijo", Desde: "VigenciaStartDate", Hasta: "VigenciaEndDate", Sustento: "Sustento"};

  const [formValues, setFormValues] = useState({});
  const [formValuesEdited, setFormValuesEdited] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabledDates, setIsDisabledDates] = useState(true);

  const [commonProps] = usePropsInputs(formValuesEdited, setFormValuesEdited, SECTION_NAME);
  const [executeGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();

  const init = async () => {
    const api = getDetalleAsignacionFamiliarAPI(IdSolicitudAsignacionFamiliar);
    await executeGet(api, successInitCallback);
  }

  const successInitCallback = ({ data }) => {
    setFormValues(data);
    setFormValuesEdited(data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = asignacionFamiliarAdminActualizarFechasSolicitudAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdSolicitudAsignacionFamiliar,
      VigenciaStartDate: formValuesEdited[KEYS.Desde],
      VigenciaEndDate: formValuesEdited[KEYS.Hasta],
    }
  }

  const successSubmitCallback = async () => {
    await refreshPage();
    toggleModal();
  }

  const handleEditDates = () => {
    setIsDisabledDates(prevIsDisabled => {
      const updatedIsDisabled = !prevIsDisabled;
      if (updatedIsDisabled) setFormValuesEdited(formValues);
      return updatedIsDisabled;
    });
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm hasSaveButton={!isDisabledDates} showModal={toggleModal} onSubmit={handleSubmit} isSubmitting={isSubmitting} titulo="Detalle Asignación Familiar" width="750px">
      <div className="col-sm-12"><InputText label='Hijo Seleccionado' {...commonProps(KEYS.Hijo)} direction='column' isDisabled/></div>
      {(formValues[KEYS.Desde] && formValues[KEYS.Hasta])&&
        <>
          <label className="ml-3">Vigencia</label>
          <div className="displayFlexWrap">
            <div className="col-sm-5"><InputDate label="Desde" {...commonProps(KEYS.Desde)} isDisabled={isDisabledDates}/></div>
            <div className="col-sm-5"><InputDate label="Hasta" {...commonProps(KEYS.Hasta)} isDisabled={isDisabledDates}/></div>
            <div className='col-sm-2 d-flex justify-content-center mt-2'>
              {PuedeEditar && 
                <FontAwesomeIcon
                  title='Editar Fechas'
                  icon={isDisabledDates ? "edit" : "times"}
                  size='lg'
                  className='blue_innova_text'
                  onClick={handleEditDates}
                />
              }
            </div>
          </div>
        </>
      }
      <div className="col-sm-12">
        <InputUploadFile label="Sustento" name="Sustento" uploadFile={formValues[KEYS.Sustento]} sectionName={SECTION_NAME} isDisabled/>
      </div>
    </ModalForm>
  )
}

export default VerDetalleModal