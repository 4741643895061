import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'react-quill/dist/quill.snow.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from '../../../components/Select/SelectInput';
import Editor from '../componentes/CustomEditor';
import { useForm } from '../../../hooks/useForm/UseForm';
import { useSubmitForm } from "../../../hooks/useSubmitForm/useSubmitForm";
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { getTiposTemplateAPI, getEditarTemplateAPI, editarTemplateAPI, previsualizarTemplateAPI } from "../../../consumers/backendApisUrls";

const EditarPlantilla = ({ toggleModal, refreshPage, plantillaId }) => {
  const isMounted = useRef(true);
  const SECTION_NAME = "AgregarNuevaPlantilla";
  const KEYS = {Name: "Nombre", Type: "IdTemplateType", FileName: "NombreArchivo", Template: "Plantilla"};

  const [formValues, setFormValues] = useState({});
  const [allTemplateTypes, setAllTemplateTypes] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isLoadingEditor, setIsLoadingEditor] = useState(true);
  
  const [handleChange] = useForm(formValues, setFormValues);

  const [getOptions] = useGetOptionsSelect();
  const [submitForm] = useSubmitForm();
  const [manageGetRequest] = useManageGetRequest();
  const [managePostRequest] = useManagePostRequest();

  const init = async () => {
    try {
      let options = await getOptions(getTiposTemplateAPI());
      setAllTemplateTypes(options);
      await manageGetRequest(getEditarTemplateAPI(plantillaId), (res) => successInitCallback(res.data, options), false);
    } catch (error) {
      console.error(error);
    }
  }

  const successInitCallback = (res, options) => {
    setFormValues({
      ...res,
      [KEYS.Type]: options.find(o => o.value == res[KEYS.Type])
    })
  }

  const submit = async () => {
    let payload = armarPayload();
    let api = editarTemplateAPI(plantillaId);
    setButtonDisabled(true);
    await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setButtonDisabled(false));
  };

  const armarPayload = () => {
    return {
      ...formValues,
      IdTemplate: plantillaId,
      [KEYS.Type]: formValues[KEYS.Type]?.value
    }
  }

  const successSubmitCallback = function () {
    setButtonDisabled(false);
    refreshPage();
    toggleModal();
  }

  const plantillaPreview = async () => {
    const api = previsualizarTemplateAPI();
    let payload = { Plantilla: formValues[KEYS.Template] };
    setButtonDisabled(true);
    await managePostRequest(api, payload, successPreviewCallback, false, () => setButtonDisabled(false));
  }

  const successPreviewCallback = (response) => {
    setButtonDisabled(false);
    window.open(response.data.FileTempUrl);
  }

  useEffect(() => {
    if (isMounted) init();

    return () => {
      isMounted.current=false;
    }
  },[])

  return (
    <>
      <GenericModal showModal={toggleModal} width={"900px"} titulo={"Editar template"}>
        <div className="modal-body pt-0 pl-0">
          <form>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Nombre<span className="required">*</span></label>
              <div className="col-sm-9 black-color">
                <input type="text" className="form-control" name={KEYS.Name} onChange={handleChange} value={formValues[KEYS.Name] ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Name}-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label">Nombre del archivo<span className="required">*</span></label>
              <div className="col-sm-9 black-color">
                <input type="text" className="form-control" name={KEYS.FileName} onChange={handleChange} value={formValues[KEYS.FileName] ?? ""}></input>
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.FileName}-validation-message`}></p>
              </div>
            </div>

            <div className="form-group row">
              <label className="col-sm-3 col-form-label modal-label required">Tipo</label>
              <div className="col-sm-9 black-color">
                <SelectInput
                  options={allTemplateTypes}
                  jsonSelectedValue={formValues[KEYS.Type] ?? ""}
                  setJsonSelectedValue={(res) => setFormValues(lastData => ({...lastData, [KEYS.Type]: res}))}
                  placeholder="Seleccionar"
                  resetSelectedValue={false}
                  isDisabled
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.Type}-validation-message`}></p>
              </div>
            </div>

            <div className="">
              <label className="modal-label required">
                Plantilla
              </label>
              <div style={{backgroundColor: 'white'}}>
                <Editor
                  value={formValues[KEYS.Template] ?? ""} 
                  handleChange={(res) => setFormValues(lastData => ({...lastData, [KEYS.Template]: res}))}
                  idTemplateType={formValues[KEYS.Type]?.value ?? ""}
                  loading={isLoadingEditor}
                  setLoading={setIsLoadingEditor}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Plantilla-validation-message`}></p>
              </div>
            </div>
          </form>
        </div>
        <div className="modal-footer d-flex justify-content-end pr-4">
          <div className="button">
            <button 
              className="btn btn-primary generic-button-ficha-modal" 
              onClick={plantillaPreview}
              disabled={buttonDisabled}
            >
              PDF - vista previa {buttonDisabled && (<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>)}
            </button>
            </div>
            <div className="button">
            <button 
              className="btn btn-primary generic-button-ficha-modal" 
              onClick={submit}
              disabled={buttonDisabled}
            >
              Guardar
            </button>
          </div>
        </div>
      </GenericModal>
    </>
  )
}

export default EditarPlantilla;