import React from 'react'
import Wrapper from '../../components/atoms/DirectorioWrapper'
import PageHeader from '../../components/pageHeader/PageHeader'
import TabsHeader from '../../components/pageComponents/TabsHeader'
import ListaSolicitudes from './misVacaciones/solicitudes/ListaSolicitudes'
import ListaHistoricoVacaciones from './misVacaciones/historico/ListaHistoricoVacaciones'

const MisVacaciones = () => {

  const OPTIONS = [
    {id: "tabListaSolicitudesVacaciones", title: "Mis Solicitudes", component: <ListaSolicitudes/>} ,
    {id: "tabListaHistoricoVacaciones", title: "Historico de Vacaciones", component: <ListaHistoricoVacaciones/>} ,
  ]

  return (
    <>
        <div className="message-no-disponible-en-mobile">
          <p>Esta página no está disponible en mobile.</p>
        </div>
        <div className="no-disponible-en-mobile">
          <Wrapper className="App">
            <PageHeader title="Mis Vacaciones" />
            <TabsHeader list={OPTIONS}/>
          </Wrapper>
        </div>
      </>
  )
}

export default MisVacaciones