import React, { useState, useEffect } from 'react'
import InputText from '../../../components/modalComponents/InputText'
import ModalForm from '../../../components/modalComponents/ModalForm'
import InputDate from '../../../components/modalComponents/InputDate'
import InputNumber from '../../../components/modalComponents/InputNumber'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest'
import { rechazarSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls'
import styled from 'styled-components'
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useHistory } from 'react-router-dom';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';

import { observarNuevoIngresoAPI } from '../../../consumers/backendApisUrls';

import ConfirmarRechazarVacaciones from './ConfirmarRechazarVacaciones';

const RechazarVacaciones = ({ toggleModal, idSolicitudMovimiento, refreshPage, toggleModalConfirmarRechazarVacaciones, setPayloadRechazarVacaciones }) => {
  const SECTION_NAME = "RechazarVacaciones";
  const KEYS = ["MotivoDeRechazo"];

  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);


  const init = async () => {
    setIsLoading(true);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  const guardar = (e) => {
    e.preventDefault();
    const payload = { IdSolicitudMovimiento: parseInt(idSolicitudMovimiento), ...formValues };
    setPayloadRechazarVacaciones(payload);
    toggleModalConfirmarRechazarVacaciones();
  }

  return (
    <>
    <ModalForm showModal={toggleModal} titulo="Rechazar" width="600px" hasSaveButton={false}>
      <InputTextArea label="Motivo Rechazo" {...commonProps("MotivoDeRechazo")}/>
      <div className="modal-footer d-flex justify-content-end pb-0">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mb-0"
            onClick={(e) => guardar(e)}
            disabled={false}>
            Guardar
          </button>
        </div>
      </div>
    </ModalForm>
    </>
  )
}

export default RechazarVacaciones
