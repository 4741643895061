import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../hooks/context/authContext';
import { useHistory } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import TableMaternidad from './TableMaternidad'
import { ROLES } from '../../constants/hardCodedConstants';
import PageHeader from '../../components/pageHeader/PageHeader';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import PageButtons from '../../components/pageComponents/PageButtons';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { useGenerarDescargarEliminarArchivo } from '../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData, usuarioLogueadoTieneAlgunoDeLosSiguientesRoles } from '../../helper/utils';
import { descargarExcelMaternidadAPI, getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getTipoDeCondicionAPI, getListaCondicionAPI, getMotivoTerminoMaternidadAPI } from '../../consumers/backendApisUrls';

const INIT_KEYS = { cargos: "IdsCargos", sedes: "IdsSedes", estadosAprobacion: "IdsEstadosAprobacion", estadoProceso: "IdsEstadosProceso", condicion: "IdsTipoDeCondicion", motivo: "IdsMotivoTerminoMaternidad", preExistencia: "IdsPreExistencia" };

const GestionMaternidad = () => {

  const { state } = useContext(AuthContext);
  const history = useHistory();
  const isAdmin = usuarioLogueadoTieneAlgunoDeLosSiguientesRoles(state.roleIds, [ROLES.SuperAdmin.id, ROLES.Compensaciones.id, ROLES.TecnicoTopico.id]);

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();
  const [downloaAndDeletedReport] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getTipoDeCondicionAPI()),
      executeGet(getMotivoTerminoMaternidadAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getListaCondicionAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleDescargar = async () => {
    const api = descargarExcelMaternidadAPI();
    const payload = getPayload();
    await downloaAndDeletedReport(api, payload);
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({ ...lastData, filters: res }))
  }

  const handleCleanSearch = () => {
    setFormValues({ ...formValues, selectedFilters: null });
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch} />
      <PageHeader title="Listado de registros de maternidad" />
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch} addCleanSearch={handleCleanSearch}>
        <PageButtons>
          <NavDropdown className='generic-button-dropdown' title={<span className='generic-button-dropdown-title'>Acciones</span>}>
            <NavDropdown.Item onClick={() => history.push("/registroCondicion/asistenciaLactario")}>Asistencia Lactario</NavDropdown.Item>
            <NavDropdown.Item onClick={handleDescargar}>Descargar</NavDropdown.Item>
          </NavDropdown>
        </PageButtons>
      </SearchContainer>
      <TableMaternidad formValues={formValues} setFormValues={setFormValues} extraData={formValues.ExtraData} setModalEnabled={setModalEnabled} paginator={paginator} setPaginator={setPaginator} keys={INIT_KEYS} isAdmin={isAdmin} />
    </PageWrapper>
  )
}

export default GestionMaternidad