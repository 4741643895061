import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import {AuthContext} from "../../hooks/context/authContext";
import {uploadFile} from "../../consumers/backendConsumer";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../consumers/httpRequiestsUtils";
import {clearValidationMessage, obtenerElementoInputDeFichaDatosNacimientoPorId, obtenerValorDeInputDeFichaDatosNacimientoPorId, updateValidationMessage} from "../../validations/SeccionDatosDeNacimientoValidator";
import {agregarStringAArraydDeStringsSiNoExiste, agregarTextOfSelectInputType, clearInputValue, eliminarStringDeArraydDeStrings, extensionYtamañoDeArchivoEsValido, getFormattedName, hideElement, notNullAndNotUndefinded, obtenerArchivoDeEvent, obtenerPaisPeru, resetForm, responseUploadFileSuccess, showElement, stringSeEncuentraEnArray} from "../../helper/utils";
import {toast} from "react-toastify";
import {EXTENSIONES_VALIDAS_DE_ARCHIVOS, LIST_FIELD_TYPE} from "../../constants/hardCodedConstants";
import SpinnerCargandoSeccionFicha from "../fichaMisDatos/SpinnerCargandoSeccionFicha";
import ConsultarModificarFechaCumpleanios from "./modals/ConsultarModificarFechaCumpleanios";
import {showToastErrorMaxFileSize} from "../../components/alerts/AlertErrorMaxFileSize";
import {askForNewBellNotifications} from "../../hooks/hooksUtils";
import GenericUploadFile from "../../components/GenericUploadFile/GenericUploadFile";
import ExtraInfoToolTip from "../../components/GenericUploadFile/ExtraInfoToolTip";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../hooks/usePostRequest/usePostRequest";
import {getDatosNacimientoAPI, getDepartamentosAPI, getDistritosPorProvinciaAPI, getPaisesAPI, getProvinciasPorDepartamentoAPI, guardarDatosNacimientoAPI} from "../../consumers/backendApisUrls";

const SeccionEditDatosDeNacimiento = ({empleadoId, showUpdateConfirmationModal, setShowViewSeccion, showSolicitarCambiosModal, datosNacimientoHasBeenUpdated, setDatosNacimientoHasBeenUpdated, reloadSeccionesDatosPersonales}) => {
    const {state, dispatch} = useContext(AuthContext);
    const [idActualizado, setId] = useState();
    const [datosDeNacimiento, setDatosNacimiento] = useState({});

    const [editFields, setEditFields] = useState([]);

    const firstGeneralJsonOptionSelect = {"Value": 0, "Text": "Seleccionar"};

    const [paises, setPaises] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [distritos, setDistritos] = useState([]);

    const [paisSeleccionado, setPaisSeleccionado] = useState();
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState();
    const [provinciaSeleccionado, setProvinciaSeleccionado] = useState();
    const [distritoSeleccionado, setDistritoSeleccionado] = useState();

    const [ubigeo, setUbigeo] = useState();
    const [descripcionDeUbigeo, setDescripcionDeUbigeo] = useState();

    const [declaracionJurada, setDeclaracionJurada] = useState(false);

    const [currentYear] = useState(new Date().getFullYear());

    const [s3UploadFileResponse, setS3UploadFileResponse] = useState({});
    const [fileName, setFileName] = useState("");

    const [dataFields, setDataFields] = useState([]);
    const [dataFieldsTemp, setDataFieldsTemp] = useState([]);

    const [subiendoArchivo, setSubiendoArchivo] = useState(false);

    const [loading, setLoading] = useState(true);
    const [hideConsultarModificarCumpleaniosModal, setHideConsultarModificarCumpleaniosModal] = useState(true);
    const [modificarDocumento, setModificarDocumento] = useState(false);

    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            updateId();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        updateId();
    }, [empleadoId]);

    useDidMountEffect(() => {
        updateComponentStates();
    }, [idActualizado]);

    useDidMountEffect(() => {
        updateProvincias();
    }, [departamentoSeleccionado]);

    useDidMountEffect(() => {
        updateDistritos();
    }, [provinciaSeleccionado]);

    useDidMountEffect(() => {
        hacerEditablesLosCampos();
    }, [editFields]);

    useDidMountEffect(() => {
        console.debug("[DatosDeNacimiento] useDidMountEffect dataFieldsTemp");
        updateHideShowElementsInHTMLWhenDataFieldsTempChange();
    }, [dataFieldsTemp]);

    useDidMountEffect(() => {
        const distrito = distritos.filter(d => d.Value == distritoSeleccionado);

        if (distrito.length > 0) {
            setDescripcionDeUbigeo(distrito[0].Text);
            setUbigeo(distrito[0].Ubigeo);
        }
    }, [distritoSeleccionado]);

    const updateId = () => {
        if (empleadoId) {
            setId(empleadoId);
        } else {
            setId(state.user.id_empleado);
        }
    };

    const updateComponentStates = async () => {
        setLoading(true);
        try {
            if (idActualizado) {
                let paisess = await executeGetRequest(getPaisesAPI());
                if (responseCode200(paisess)) {
                    setPaises([firstGeneralJsonOptionSelect, ...paisess.data]);
                } else {
                    mostrarMensajeDeErrorConToast(paisess);
                }

                let departamentosResponse = await executeGetRequest(getDepartamentosAPI());
                if (responseCode200(departamentosResponse)) {
                    setDepartamentos([firstGeneralJsonOptionSelect, ...departamentosResponse.data]);
                } else {
                    mostrarMensajeDeErrorConToast(departamentosResponse);
                }

                const datosNacimientoResponse = await executeGetRequest(getDatosNacimientoAPI(idActualizado));
                if (responseCode200(datosNacimientoResponse)) {
                    setDatosNacimiento(datosNacimientoResponse.data.data);
                    setEditFields(datosNacimientoResponse.data.data.EditFields);
                    setDataFields(datosNacimientoResponse.data.data.DataFields);
                    setDataFieldsTemp(datosNacimientoResponse.data.data.DataFields);

                    if (datosNacimientoResponse.data.data.IdDepartamento && datosNacimientoResponse.data.data.IdProvincia && datosNacimientoResponse.data.data.IdDistrito) {
                        setDepartamentoSeleccionado(datosNacimientoResponse.data.data.IdDepartamento);
                        setProvinciaSeleccionado(datosNacimientoResponse.data.data.IdProvincia);
                        setDistritoSeleccionado(datosNacimientoResponse.data.data.IdDistrito);

                        setUbigeo(datosNacimientoResponse.data.data.Ubigeo);
                        setDescripcionDeUbigeo(datosNacimientoResponse.data.data.DescripcionUbigeo);
                    }

                    setDeclaracionJurada(false);

                    setPaisSeleccionado(datosNacimientoResponse.data.data.IdPaisNacimiento);

                    await mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange(datosNacimientoResponse.data.data.IdPaisNacimiento, datosNacimientoResponse.data.data.DataFields);
                } else {
                    mostrarMensajeDeErrorConToast(datosNacimientoResponse);
                }
            }
        } catch (e) {
            console.error(e);
        }

    };

    const hacerEditablesLosCampos = () => {
        try {
            for (const field of editFields) {
                let optionalParams = document.querySelector("#frmEditDatosDeNacimiento input[name='" + field + "']");

                if (optionalParams == null) {
                    optionalParams = document.querySelector("#frmEditDatosDeNacimiento select[name='" + field + "']");
                }

                if (optionalParams != null) {
                    optionalParams.removeAttribute('disabled');
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    const reset = async () => {
        setS3UploadFileResponse({});
        setFileName("");
        await updateComponentStates();
    };

    const cancelar = async () => {
        try {
            await reset();
            setShowViewSeccion(true);
        } catch (e) {
            console.error(e);
        }
    };

    const validaRegex = (regexString, fieldKey, inputValue, isRequired, validationMessage) => {
        let campoInvalido = false;
        if (regexString) {
            console.debug(`[DatosDeNacimiento] validar fieldKey: "${fieldKey}", regexString: "${regexString}", value: "${inputValue}", isRequired: "${isRequired}", validationMessage: "${validationMessage}"`);
            let regex = new RegExp(regexString);
            if (regex.test(inputValue)) {
                clearValidationMessage(fieldKey);
            } else {
                updateValidationMessage(fieldKey, validationMessage);
                campoInvalido = true;
                console.debug("[DatosDeNacimiento] => campoInvalido: ", campoInvalido);
            }
        } else {
            console.warn(`no se va a validar porque no tiene regex fieldKey: "${fieldKey}", regexString: "${regexString}", value: ${inputValue}, isRequired: ${isRequired}`);
            clearValidationMessage(fieldKey);
        }
        return campoInvalido;
    };

    const validarFormulario = () => {
        let nroCamposInvalidos = 0;
        for (let dataField of dataFields) {
            let campoInvalido = false;
            if (stringSeEncuentraEnArray(dataField.KeyField, editFields)) {
                let fieldKey = dataField.KeyField;
                let regexString = dataField.ValidationString;
                let validationMessage = dataField.ValidationMessage;
                let isRequired = idRequiredd(dataField);
                let fieldType = dataField["FieldType"];
                let inputValue = obtenerValorDeInputDeFichaDatosNacimientoPorId(fieldKey);
                if (isRequired === true) {
                    if (((fieldType == "Text" || fieldType == "Calendar") && inputValue == "") || (fieldType == "List" && inputValue == 0)) {
                        updateValidationMessage(fieldKey, "Este campo es obligatorio");
                        campoInvalido = true;
                    } else {
                        campoInvalido = validaRegex(regexString, fieldKey, inputValue, isRequired, validationMessage);
                    }
                } else {
                    if (inputValue != "" || inputValue != undefined) {
                        campoInvalido = validaRegex(regexString, fieldKey, inputValue, isRequired, validationMessage);
                    }
                }
            }
            if (campoInvalido) {
                nroCamposInvalidos++;
            }
        }

        let formularioInvalido = (nroCamposInvalidos > 0) ? true : false;
        let declaracionJuradaFieldKey = "AceptaDeclaracionJurada";
        if (declaracionJurada == false) {
            updateValidationMessage(declaracionJuradaFieldKey, "Este campo es obligatorio");
            formularioInvalido = true;
        } else {
            clearValidationMessage(declaracionJuradaFieldKey);
        }
        return formularioInvalido;
    };

    const idRequiredd = (dataField) => {
        let input = document.querySelector(`#ficha-edit-inputGroup-datosDeNacimiento-${dataField.KeyField}`);
        if (input && input.hidden == true) {
            return false;
        }
        return dataField["IsRequired"];
    };

    const agregarCamposDeFormulario = (payload) => {
        for (let dataField of dataFields) {
            if (dataField["CanEdit"] === true) {
                let keyField = dataField["KeyField"];
                let element = obtenerElementoInputDeFichaDatosNacimientoPorId(keyField);
                if (notNullAndNotUndefinded(element)) {
                    if (element.matches('[type="checkbox"]')) {
                        payload = {...payload, [keyField]: element.checked};
                    } else {
                        payload = {...payload, [keyField]: element.value};
                    }

                    if (dataField["FieldType"] == LIST_FIELD_TYPE) {
                        payload = agregarTextOfSelectInputType(keyField, element, payload);
                    }
                } else {
                    console.warn(`no se encontró campo con el id "${keyField}", por lo que no se agregará al payload, `);
                }
            }
        }

        return payload;
    };

    const agregarFileViewModelEnCasoExista = (payload) => {
        if (!(s3UploadFileResponse && Object.keys(s3UploadFileResponse).length === 0 && s3UploadFileResponse.constructor === Object)) {
            payload = {...payload, FileViewModel: s3UploadFileResponse};
        }
        return payload;
    };

    const onSubmit = async (event) => {
        try {
            console.debug("[DatosDeNacimiento] submit");
            event.preventDefault();

            let formularioInvalido = validarFormulario();
            console.debug("[DatosDeNacimiento] formularioInvalido ", formularioInvalido);
            if (!formularioInvalido) {
                let payload = {
                    AceptaDeclaracionJurada: event.target.AceptaDeclaracionJurada.checked,
                    IdEmpleado: idActualizado,
                };

                payload = agregarCamposDeFormulario(payload);
                payload = agregarFileViewModelEnCasoExista(payload);
                console.debug("[DatosDeNacimiento] payload:", payload);

                let response = await executePostRequest(guardarDatosNacimientoAPI(), payload);
                console.debug("[DatosDeNacimiento] response ", response);
                if (responseCode200(response)) {
                    showUpdateConfirmationModal(response.data.UrlImage, response.data.Message);

                    resetForm("frmEditDatosDeNacimiento");
                    await reset();

                    askForNewBellNotifications(dispatch);

                    setDatosNacimientoHasBeenUpdated(!datosNacimientoHasBeenUpdated);
                    setShowViewSeccion(true);
                    await reloadSeccionesDatosPersonales(empleadoId);
                } else {
                    mostrarMensajeDeErrorConToast(response);
                }
            }
        } catch (e) {
            console.error(e);
        }
    };

    // update provincia distritos usados en use effect
    const updateProvincias = async () => {
        if (departamentoSeleccionado) {
            let provinciass = await executeGetRequest(getProvinciasPorDepartamentoAPI(departamentoSeleccionado));
            if (responseCode200(provinciass)) {
                setProvincias([firstGeneralJsonOptionSelect, ...provinciass.data]);
            } else {
                mostrarMensajeDeErrorConToast(provinciass);
            }
        } else {
            setProvincias([firstGeneralJsonOptionSelect]);
            setDistritos([firstGeneralJsonOptionSelect]);
            setUbigeo("");
            setDescripcionDeUbigeo("");
        }
    };

    const updateDistritos = async () => {
        if (provinciaSeleccionado) {
            let distritoss = await executeGetRequest(getDistritosPorProvinciaAPI(provinciaSeleccionado));
            if (responseCode200(distritoss)) {
                setDistritos([firstGeneralJsonOptionSelect, ...distritoss.data]);
            } else {
                mostrarMensajeDeErrorConToast(distritoss);
            }
        } else {
            setDistritos([firstGeneralJsonOptionSelect]);
            setUbigeo("");
            setDescripcionDeUbigeo("");
        }
    };


    //handles
    const handleDepartamentoChange = async (event) => {
        setDepartamentoSeleccionado(event.target.value);
        setProvinciaSeleccionado();
        setDistritoSeleccionado();
        handleChangeInputForDocumentoRequerido();
    };

    const handleProvinciaChange = async (event) => {
        setProvinciaSeleccionado(event.target.value);
        setDistritoSeleccionado();
        handleChangeInputForDocumentoRequerido();
    };

    const handleDistritoChange = async (event) => {
        setDistritoSeleccionado(event.target.value);
        handleChangeInputForDocumentoRequerido();
    };

    const handleUbigeoChange = async (event) => {
        setUbigeo(event.target.value);
    };

    const handleDescripcionUbigeoChange = async (event) => {
        setDescripcionDeUbigeo(event.target.value);
    };

    const handleOnChangePais = async (event) => {
        let idPaisSeleccionado = event.target.value;
        setPaisSeleccionado(event.target.value);
        handleChangeInputForDocumentoRequerido();
        await mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange(idPaisSeleccionado, dataFieldsTemp);
    };

    const handleDeclaracionJurada = (e) => {
        setDeclaracionJurada(e.target.checked);
    };

    const resetUploadFile = () => {
        setFileName("");
        setS3UploadFileResponse({});
        clearInputValue("seccionEditDatosNacimientoUploadSustento");
    };

    const manageUploadFileResponse = (response, file) => {
        if (responseUploadFileSuccess(response)) {
            let s3Response = response.data.file;
            setS3UploadFileResponse(s3Response);
            setFileName(getFormattedName(file.name));
            toast.success("El archivo se cargó exitosamente");
        } else {
            resetUploadFile();
            toast.error("Hubo un error al subir el archivo");
        }
    };

    const handleUploadFile = async (event) => {
        try {
            let file = obtenerArchivoDeEvent(event);
            if (extensionYtamañoDeArchivoEsValido(file, EXTENSIONES_VALIDAS_DE_ARCHIVOS)) {
                setSubiendoArchivo(true);
                let response = await uploadFile(file, state.token);
                setSubiendoArchivo(false);
                manageUploadFileResponse(response, file);
            } else {
                showToastErrorMaxFileSize();
                resetUploadFile();
            }
        } catch (e) {
            console.error(e);
        }
    };


    //hide show logic

    const updateHideShowElementsInHTMLWhenDataFieldsTempChange = () => {
        console.debug("[DatosDeNacimiento] updateHideShowElementsInHTMLWhenDataFieldsTempChange dataFieldsTemp: ", dataFieldsTemp);
        try {
            for (const field of dataFieldsTemp) {
                let fieldKey = field["KeyField"];
                if (field["IsHidden"] === true) {
                    console.debug("[DatosDeNacimiento] esconder fieldKey: ", fieldKey);
                    hideElement(`ficha-edit-inputGroup-datosDeNacimiento-${fieldKey}`);
                }

                if (field["IsHidden"] === false) {
                    console.debug("[DatosDeNacimiento] mostrar fieldKey: ", fieldKey);
                    showElement(`ficha-edit-inputGroup-datosDeNacimiento-${fieldKey}`);
                }
            }
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };


    //hide show logic

    const mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange = async (idPaisSeleccionado, dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] mostrarCiudadSoloSiEsDiferenteDePeruLogicOnPaisChange idPaisSeleccionado: ", {idPaisSeleccionado, dataFieldsTemp: dataFieldsTempp});
        let paisesResponse = await executeGetRequest(getPaisesAPI());
        let idPaisPeru = obtenerPaisPeru(paisesResponse);
        console.debug({idPaisPeru});
        if (idPaisSeleccionado == idPaisPeru) {
            mostrarDepartamentoProvinciaDistritoEsconderCiudad(dataFieldsTempp);
        } else {
            mostrarCiudadEsconderDepartamentoProvinciaDistrito(dataFieldsTempp);
        }
    };

    const mostrarDepartamentoProvinciaDistritoEsconderCiudad = (dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] se ha seleccionado Perú, por lo que se mostrará departamento, provincia y distrito y se esconderá ciudad");
        let hiddenFields = obtenerHiddenFields(dataFieldsTempp);
        console.debug("[DatosDeNacimiento] current hidden fields: ", hiddenFields);

        let newHiddenFields = hiddenFields;
        let inputsKeysToNotBeHidden = ["IdDepartamento", "IdProvincia", "IdDistrito"];
        let inputsKeysToBeHidden = ["CiudadNacimiento"];
        newHiddenFields = getNewHiddenFields(inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden);

        console.debug("[DatosDeNacimiento] new hidden fields final: ", newHiddenFields);
        updateDataFieldTemp(newHiddenFields, dataFieldsTempp);
    };

    const mostrarCiudadEsconderDepartamentoProvinciaDistrito = (dataFieldsTempp) => {
        console.debug("[DatosDeNacimiento] se ha seleccionado País diferente a Perú, por lo que se mostrará ciudad y se esconderá departamento, provincia y distritos");
        let hiddenFields = obtenerHiddenFields(dataFieldsTempp);
        console.debug("[DatosDeNacimiento] current hidden fields: ", hiddenFields);

        let newHiddenFields = hiddenFields;
        let inputsKeysToNotBeHidden = ["CiudadNacimiento"];
        let inputsKeysToBeHidden = ["IdDepartamento", "IdProvincia", "IdDistrito"];

        newHiddenFields = getNewHiddenFields(inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden);

        console.debug("[DatosDeNacimiento] new hidden fields final: ", newHiddenFields);
        updateDataFieldTemp(newHiddenFields, dataFieldsTempp);
    };

    const obtenerHiddenFields = (dataFieldssss) => {
        let hiddenFields = [];
        for (let dataField of dataFieldssss) {
            if (dataField["IsHidden"] === true) {
                hiddenFields = [...hiddenFields, dataField["KeyField"]];
            }
        }
        return hiddenFields;
    };

    const getNewHiddenFields = (inputsKeysToNotBeHidden, newHiddenFields, inputsKeysToBeHidden) => {
        for (let inputKey of inputsKeysToNotBeHidden) {
            newHiddenFields = eliminarStringDeArraydDeStrings(inputKey, newHiddenFields);
        }

        for (let inputKey of inputsKeysToBeHidden) {
            newHiddenFields = agregarStringAArraydDeStringsSiNoExiste(inputKey, newHiddenFields);
        }
        return newHiddenFields;
    };

    const updateDataFieldTemp = (newHiddenFieldsArray, dataFieldss) => {
        console.debug("[DatosDeNacimiento] updateDataFieldTemp newHiddenFieldsArray:", newHiddenFieldsArray);
        let newDataFieldsTemp = [];
        console.debug("[DatosDeNacimiento] dataFieldss: ------ ", dataFieldss);
        for (let dataField of dataFieldss) {
            let dataFieldTemp = dataField;
            if (stringSeEncuentraEnArray(dataFieldTemp["KeyField"], newHiddenFieldsArray)) {
                dataFieldTemp = {...dataFieldTemp, "IsHidden": true};
            } else {
                dataFieldTemp = {...dataFieldTemp, "IsHidden": false};
            }
            newDataFieldsTemp = [...newDataFieldsTemp, dataFieldTemp];
        }
        console.debug("[DatosDeNacimiento] updateDataFieldTemp result newDataFieldsTemp ", newDataFieldsTemp);
        setDataFieldsTemp(newDataFieldsTemp);
    };

    const consultarModificarFechaDeCumpleanios = () => {
        showConsultarModificarCumpleaniosModal();
        handleChangeInputForDocumentoRequerido();
    };

    const showConsultarModificarCumpleaniosModal = async () => {
        let currentState = hideConsultarModificarCumpleaniosModal;
        setHideConsultarModificarCumpleaniosModal(!currentState);
    };

    const modificarFechaCumpleaniosSegunRespuesta = (siModificar) => {
        if (siModificar) {
            let value = obtenerValorDeInputDeFichaDatosNacimientoPorId("FechaNacimiento");
            let fechaCumpleanios = document.getElementById("ficha-edit-datosDeNacimiento-FechaCumpleanios");
            fechaCumpleanios.value = value;
            handleChangeInputForDocumentoRequerido();
        }
        showConsultarModificarCumpleaniosModal();
    };

    const handleChangeInputForDocumentoRequerido = () => {
        let fechaNacimientoNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-FechaNacimiento");
        let fechaCumpleaniosNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-FechaCumpleanios");
        let paisNacimientoNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-IdPaisNacimiento");
        let departamenteNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-IdDepartamento");
        let provinciaNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-IdProvincia");
        let distritoNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-IdDistrito");
        let ciudadNacimientoNuevoValue = document.getElementById("ficha-edit-datosDeNacimiento-CiudadNacimiento");
        if ((fechaNacimientoNuevoValue && fechaNacimientoNuevoValue.value == datosDeNacimiento.FechaNacimientoValue) &&
            (fechaCumpleaniosNuevoValue && fechaCumpleaniosNuevoValue.value == datosDeNacimiento.FechaCumpleaniosValue) &&
            (paisNacimientoNuevoValue && paisNacimientoNuevoValue.value == datosDeNacimiento.IdPaisNacimiento) &&
            (departamenteNuevoValue && departamenteNuevoValue.value == datosDeNacimiento.IdDepartamento) &&
            (provinciaNuevoValue && provinciaNuevoValue.value == datosDeNacimiento.IdProvincia) &&
            (distritoNuevoValue && distritoNuevoValue.value == datosDeNacimiento.IdDistrito) &&
            (ciudadNacimientoNuevoValue && (ciudadNacimientoNuevoValue.value == "" ? null : ciudadNacimientoNuevoValue.value) == datosDeNacimiento.CiudadNacimiento)) {
            setModificarDocumento(false);
            console.debug("modificarDocumento ", false, modificarDocumento);
        }
        else {
            setModificarDocumento(true);
            console.debug("modificarDocumento ", true, modificarDocumento);
        }
    };

    const removeUploadedFile = function () {
        resetUploadFile();
    };

    return (
        <>
            {(loading === true) && (
                <SpinnerCargandoSeccionFicha/>
            )}

            <div className="modal-contactanos modal-response-ficha-background" hidden={hideConsultarModificarCumpleaniosModal}>
                {hideConsultarModificarCumpleaniosModal === false &&
                <ConsultarModificarFechaCumpleanios showModal={showConsultarModificarCumpleaniosModal} respuestaConsultar={modificarFechaCumpleaniosSegunRespuesta}/>
                }
            </div>

            <form id='frmEditDatosDeNacimiento' className="ficha-edit-form" onSubmit={onSubmit} hidden={loading}>
                <div className="displayFlexWrap">
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-FechaNacimiento">
                        <label className="ficha-edit-form-label">Fecha de nacimiento</label><span className="required"></span>
                        <input type="date" min={currentYear - 70 + "-01-01"} max={currentYear - 18 + "-12-31"} className="form-control" name="FechaNacimiento" defaultValue={datosDeNacimiento && datosDeNacimiento.FechaNacimientoValue} id="ficha-edit-datosDeNacimiento-FechaNacimiento"
                               disabled onChange={() => {
                            consultarModificarFechaDeCumpleanios();
                        }}/>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-FechaNacimiento-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-FechaCumpleanios">
                        <label className="ficha-edit-form-label">Fecha de cumpleaños</label><span className="required"></span>
                        <div style={{display: 'flex'}}>
                            <input type="date" min={currentYear - 70 + "-01-01"} max={currentYear - 18 + "-12-31"} className="form-control" name="FechaCumpleanios" defaultValue={datosDeNacimiento && datosDeNacimiento.FechaCumpleaniosValue} id="ficha-edit-datosDeNacimiento-FechaCumpleanios"
                                   disabled onChange={handleChangeInputForDocumentoRequerido}/>
                            <div className="custom-control custom-switch" style={{"paddingTop": '6px'}} id="ficha-edit-inputGroup-datosDeNacimiento-MostrarCumpleanios">
                                <input type="checkbox" className="custom-control-input hand-pointer" id="ficha-edit-datosDeNacimiento-MostrarCumpleanios" name="MostrarCumpleanios" defaultChecked={datosDeNacimiento && datosDeNacimiento.MostrarCumpleanios} disabled></input>
                                <label className="custom-control-label ficha-edit-form-label-checkbox hand-pointer" htmlFor="ficha-edit-datosDeNacimiento-MostrarCumpleanios">Usar mi cumpleaños en los saludos

                                    <p data-tip data-for="informacion-datosDeNacimiento-fechacumpleanios" style={{display: 'inline'}}>
                                        <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                                    </p>
                                    <ReactTooltip id="informacion-datosDeNacimiento-fechacumpleanios" place="bottom" effect="solid" className="tooltip-style tooltip-change-witdh">
                                        Si desactivas el botón de cumpleaños, no enviaremos un correo con tu saludo a toda la Familia Innova
                                    </ReactTooltip>
                                </label>
                                <p className="ficha-form-input-validation-message" id="ficha-edit-inputGroup-datosDeNacimiento-MostrarCumpleanios-validation-message"></p>
                            </div>
                        </div>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-FechaCumpleanios-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-IdPaisNacimiento">
                        <label className="ficha-edit-form-label">País de nacimiento</label><span className="required"></span>
                        {datosDeNacimiento &&
                        <select className="form-control" name="IdPaisNacimiento" id="ficha-edit-datosDeNacimiento-IdPaisNacimiento" onChange={handleOnChangePais} disabled>
                            paises && datosDeNacimiento && paisSeleccionado
                            {
                                paises.map((option) => {
                                        {
                                            if (option.Value === paisSeleccionado) {
                                                return (<option key={option.Value} selected value={option.Value}>{option.Text}</option>)
                                            } else {
                                                return (<option key={option.Value} value={option.Value}>{option.Text}</option>)
                                            }
                                        }
                                    }
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-IdPaisNacimiento-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-CiudadNacimiento">
                        <label className="ficha-edit-form-label">Ciudad de nacimiento</label><span className="required"></span>
                        <input type="text" className="form-control" name="CiudadNacimiento" defaultValue={datosDeNacimiento && datosDeNacimiento.CiudadNacimiento} id="ficha-edit-datosDeNacimiento-CiudadNacimiento" disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-CiudadNacimiento-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-IdDepartamento">
                        <label className="ficha-edit-form-label">Departamento</label><span className="required"></span>
                        {datosDeNacimiento &&
                        <select defaultValue={departamentoSeleccionado} value={departamentoSeleccionado} className="form-control" name="IdDepartamento" id="ficha-edit-datosDeNacimiento-IdDepartamento" onChange={handleDepartamentoChange} disabled>
                            departamentos &&
                            {
                                departamentos.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-IdDepartamento-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-IdProvincia">
                        <label className="ficha-edit-form-label">Provincia</label><span className="required"></span>
                        {datosDeNacimiento &&
                        <select defaultValue={provinciaSeleccionado} value={provinciaSeleccionado} className="form-control" name="IdProvincia" id="ficha-edit-datosDeNacimiento-IdProvincia" onChange={handleProvinciaChange} disabled>
                            provincias &&
                            {
                                provincias.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-IdProvincia-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-IdDistrito">
                        <label className="ficha-edit-form-label">Distrito</label><span className="required"></span>
                        {datosDeNacimiento &&
                        <select defaultValue={distritoSeleccionado} value={distritoSeleccionado} className="form-control" name="IdDistrito" id="ficha-edit-datosDeNacimiento-IdDistrito" onChange={handleDistritoChange} disabled>
                            distritos &&
                            {
                                distritos.map((option) => (
                                        <option key={option.Value} value={option.Value}>{option.Text}</option>
                                    )
                                )
                            }
                        </select>
                        }
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-IdDistrito-validation-message"></p>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-IdUbigeoNacimiento">
                        <label className="ficha-edit-form-label">Ubigeo</label><span className="required"></span>
                        <input type="text" value={ubigeo} className="form-control" name="IdUbigeoNacimiento" id="ficha-edit-datosDeNacimiento-IdUbigeoNacimiento" onChange={handleUbigeoChange} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-Ubigeo-validation-message"></p>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 mb-3" id="ficha-edit-inputGroup-datosDeNacimiento-DescripcionUbigeo">
                        <label className="ficha-edit-form-label">Descripción Ubigeo</label><span className="required"></span>
                        <p data-tip data-for="informacion-datosDeNacimiento-ubigeo" style={{display: 'inline'}}>
                            <FontAwesomeIcon icon='info-circle' transform="left-9" className="hand-pointer ficha-residencia-ver-info-icon"/>
                        </p>
                        <ReactTooltip id="informacion-datosDeNacimiento-ubigeo" place="top" effect="solid" className="tooltip-style tooltip-change-witdh">
                            El campo <strong>Ubigeo</strong> y <strong>Descripción Ubigeo</strong> son resultado de la selección de los campos <strong>Departamento, Provincia y Distrito.</strong>
                        </ReactTooltip>
                        <input type="text" defaultValue={descripcionDeUbigeo} value={descripcionDeUbigeo} className="form-control" name="DescripcionUbigeo" id="ficha-edit-datosDeNacimiento-DescripcionUbigeo" onChange={handleDescripcionUbigeoChange} disabled></input>
                        <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-DescripcionUbigeo-validation-message"></p>
                    </div>

                </div>
                <div style={{
                    display: 'flex',
                    'justifyContent': 'space-between',
                    'marginTop': '31.9px',
                    'marginBottom': '15px'
                }}>
                    <div style={{'alignSelf': 'flex-start'}}>
                        <GenericUploadFile handleUploadFile={handleUploadFile} datosSeccion={datosDeNacimiento} fileName={fileName} subiendoArchivo={subiendoArchivo} removeUploadedFile={removeUploadedFile} id={"seccionEditDatosNacimientoUploadSustento"} textButton={"Adjunta PDF o imagen de tu DNI"}>
                            <>
                                <ExtraInfoToolTip datosSeccion={datosDeNacimiento} fileName={fileName}/>
                            </>
                        </GenericUploadFile>

                        <div className="form-check margin-top-15px">
                            <input className="form-check-input" type="checkbox" id="ficha-edit-datosDeNacimiento-AceptaDeclaracionJurada" name="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
                            <label className="form-check-label ficha-declaracion-jurada-message" htmlFor="AceptaDeclaracionJurada">
                                <strong><p>Los datos inscritos como Información de Nacimiento se considerarán como Declaración Jurada.</p></strong>
                            </label>
                            <p className="ficha-form-input-validation-message" id="ficha-edit-datosDeNacimiento-AceptaDeclaracionJurada-validation-message"></p>
                        </div>
                        <div>
                            <br/>
                            <p>(<span className="required ">*</span>) Dato obligatorio</p>
                        </div>
                    </div>

                    <div style={{'alignSelf': 'flex-end'}}>
                        <button
                            onClick={cancelar}
                            type='button'
                            className='btn btn-primary ficha-form-button-cancelar'>
                            Cancelar
                        </button>

                        {datosDeNacimiento && datosDeNacimiento.CanSeeButtonEditar === true &&
                        <button
                            type="submit"
                            className='btn btn-primary ficha-form-button-guardar'
                            disabled={subiendoArchivo}>
                            Guardar
                        </button>
                        }
                    </div>
                </div>
            </form>
        </>
    );
};

export default SeccionEditDatosDeNacimiento;