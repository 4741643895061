import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import useDidMountEffect from '../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useForm } from '../../../../hooks/useForm/UseForm';
import InputText from '../../../../components/modalComponents/InputText';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputBool from '../../../../components/modalComponents/InputBool';
import InputSelect from '../../../../components/modalComponents/InputSelect';
import CardAccordion from '../../../../components/pageComponents/CardAccordion'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading';
import InputSearchAsync from '../../../../components/modalComponents/InputSearchAsync';
import InputMultiSelect from '../../../../components/modalComponents/InputMultiSelect';
import InputSelectCascade from '../../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../../components/modalComponents/InputSelectCascadePost';
import { CARGOS, ID_TYPES_NUEVOS_INGRESOS, INPUT_DIRECTION, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, SEDES, TIPOS_CONTRATO, TIPOS_DOCUMENTO_IDENTIDAD, TIPOS_TRABAJADOR, TIPO_DE_CONTRATO_INDEFINIDO_ID } from '../../../../constants/hardCodedConstants';
import { esArrayVacio, getCurrentDate, handleFind, handleFindDropdownSelected, initialAllOptionPromises, jsonNoEstaVacio, mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../../../helper/utils';
import { getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI, getTiposDocumentoAPI, getGenerosAPI, getListaDocumentosAPI, getDocumentosByIdListaAPI, getDivisionesAPI, getAllSedesAPI, getGradoDesarrolloAPI, getEspecialidadesAPI, getGerenciasByDivisionAPI, getAreasByGerenciaAPI, getSeccionesByAreaAPI, getAllCargosAPI, getTiposInstruccionAPI, getResponsableEmpleadoAPI, getTiposContrato, getResponsablesNuevoIngresoGestionAPI, buscarColaboradorAPI, getAllJornadasLaboralesAPI, consultarReingresoNuevoIngresoGestionAPI, consultarSedeNuevoIngresoGestionAPI, getEspecialidadSeleccionAPI, getTiposLicenciaAPI, getPeriodosPruebaAPI, getPerfilPuestoByCargoAPI, getCondicionesAcademicasAPI, getAllTiposTrabajadorAPI, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, getRelatedDataTypesAPI, getListaFuentesReclutamientoAPI, getFormasIngresoAPI } from '../../../../consumers/backendApisUrls';

const DatosPersonales = ({ sectionName: SECTION_NAME, setFormValuesData, initialData = {} }) => {

  const ID_PARENT = "datospersonales";
  const TRIM_INPUTS = ["NumeroDocumento", "NumeroPasaporte"];
  const ID_AND_TEXT_INPUTS = {IdEmpleadoLider: "EmpleadoLiderText", IdEmpleadoMatricialSuperior: "EmpleadoMatricialSuperior", IdColaboradorSuplencia: "ColaboradorSuplenciaText"};
  const IDS_TIPO_EMPLEADO_PRACTICANTE = [TIPOS_TRABAJADOR.PRACTICANTE_PRE, TIPOS_TRABAJADOR.PRACTICANTE_PRO];

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [allIdGerencia, setAllIdGerencia] = useState([]);
  const [allIdArea, setAllIdArea] = useState([]);
  const [allIdSeccion, setAllIdSeccion] = useState([]);
  const [dataSelected, setDataSelected] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasEffectRun, setHasEffectRun] = useState(false);

  const [formValues, setFormValues] = useState({ IdGerencia: "", IdArea: "", IdSeccion: "", EsReingreso: false, EmpleadoMatricialSuperior:"-" });

  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useGetRequest();

  const [executePost] = useManagePostRequest();

  const allGetPromises = () => {
    return [
      getOptions(getFormasIngresoAPI()),
      getOptions(getTiposDocumentoAPI()),
      getOptions(getGenerosAPI()),
      getOptions(getTiposInstruccionAPI()),
      getOptions(getAllCargosAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getDivisionesAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
      getOptions(getGradoDesarrolloAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getTiposContrato()),
      getOptions(getListaDocumentosAPI()),
      getOptions(getResponsablesNuevoIngresoGestionAPI()),
      getOptions(getTiposLicenciaAPI()),
      getOptions(getPeriodosPruebaAPI()),
      getOptions(getCondicionesAcademicasAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getListaFuentesReclutamientoAPI()),
    ]
  }

  const init = () => {
    try {
      initialAllOptionPromises(allGetPromises, Object.values(ID_TYPES_NUEVOS_INGRESOS), successInitCallback)
    } catch (error) {
      console.error();
    }
  }

  const successInitCallback = (res) => {
    setAllDropdowns(res);
    setIsLoading(false);
  }

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    let inputValueRaw = TRIM_INPUTS.includes(name) ? String(value).trim() : value;
    let inputValue = (type === "checkbox") ? checked : inputValueRaw;
    setFormValues({...formValues, [name]: inputValue === "" ? null : inputValue})
  }

  const handleSubmitData = (res, cleanValues) => {
    handleChange(res);
    const { name, type, value, checked } = res.target;
    const inputValueRaw = value?.value ?? value;
    const inputValue = TRIM_INPUTS.includes(name) ? String(inputValueRaw).trim() : inputValueRaw;
    
    setFormValuesData(lastData => ({ ...lastData, [name]: type === "checkbox" ? checked : inputValue }));

    if(cleanValues?.length) {
      cleanDropdownSelected(cleanValues)
    };
  }

  const handleCheckReEnter = async () => {
    let response = await executeGet(consultarReingresoNuevoIngresoGestionAPI(formValues.NumeroDocumento));
    if(responseCode200(response)){
      let data = response.data;
      let dataChanged = {
        // FechaNacimiento: data.FechaNacimiento?.split("T")[0],
        IdGenero: handleFind(allDropdowns, "IdGenero")?.find(o => o.value === data.IdGenero),
        IdTipoDocumento: handleFind(allDropdowns, "IdTipoDocumento")?.find(o => o.value === data.IdTipoDocumento),
        IdGradoDesarrollo: handleFind(allDropdowns, "IdGradoDesarrollo")?.find(o => o.value === data.IdGradoDesarrollo),
        EsReingreso: true,
      };
      setFormValues(lastData => ({...lastData, ...data, ...dataChanged}));
      setFormValuesData(lastData => ({...lastData, ...response.data, EsReingreso: true}));
    }
  }

  const cleanDropdownSelected = (keys) => {
    keys.map(o => {
      setFormValues(lastData => ({...lastData, [o]: ""}));
      setFormValuesData(lastData => ({...lastData, [o]: ""}));
    })
  }

  const commonProps = ({name, isSelect, cleanValues}) => {
    let common = {
      name,
      value: formValues[name],
      onChange: (res) => handleSubmitData(res, cleanValues),
      sectionName: SECTION_NAME
    }
    if(isSelect) return {...common, options: handleFind(allDropdowns, name)};
    return common;
  }

  const propsMultiSelect = (name) => {
    let options = handleFind(allDropdowns, name);
    return {
      name,
      options,
      value: options?.filter(o => formValues["IdsListasDocumentos"]?.includes(o.value)),
      onChange: handleSubmitData, 
      sectionName: SECTION_NAME
    }
  }

  const setIfSedeSelected = (dropdown, name) => {
    if (dataSelected[name] && dataSelected.IdSede !== SEDES.BACK_OFFICE) {
      let filter = dropdown?.find(o => o.value === dataSelected[name]);
      setFormValues(lastData => ({...lastData, [name]: filter}));
      setFormValuesData(lastData => ({...lastData, [name]: filter?.value}));
    }
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[ID_TYPES_NUEVOS_INGRESOS.TipoTrabajador]?.value,
      IdJornadaLaboral: formValues[ID_TYPES_NUEVOS_INGRESOS.TipoEmpleado]?.value,
    }
  }


  const getJefeMatricial = async () => {
    const api = getEmpleadoLiderMatricialByIdDepartamentoAndIdCargoAndIdAreaAPI();

    const payload = {
      "IdArea": formValues?.IdArea?.value,
      "IdCargo": formValues?.IdCargo?.value,
      "IdDepartamento": formValues?.IdGerencia?.value,
    }
    
    return await executePost(api, payload, successJefeMatricialCallback)
  }

  const successJefeMatricialCallback = ({ data }) => {
    if (formValues?.EmpleadoMatricialSuperior !== data?.EmpleadoMatricialSuperior){
      setFormValues({ ...formValues, EmpleadoMatricialSuperior: (data?.EmpleadoMatricialSuperior ?? "-")});
    }
  }

  useDidMountEffect(() => {
    if(!isLoading) {
      getJefeMatricial();
    }
  }, [formValues.IdGerencia, formValues.IdArea, formValues.IdCargo ])

  useDidMountEffect( async() => {
    if(formValues.IdDivision?.value) {
      let keysToClean = ["IdGerencia", "IdArea"];
      if (formValues.IdSede?.value !== SEDES.BACK_OFFICE) keysToClean.push("IdSeccion");
      cleanDropdownSelected(keysToClean);
      let response = await getOptions(getGerenciasByDivisionAPI(formValues.IdDivision.value));
      setAllIdGerencia(response);
      setIfSedeSelected(response, "IdGerencia");
    }
  }, [formValues.IdDivision])

  useDidMountEffect( async() => {
    if(formValues.IdGerencia?.value) {
      let keysToClean = ["IdArea"];
      if (formValues.IdSede?.value !== SEDES.BACK_OFFICE) keysToClean.push("IdSeccion");
      cleanDropdownSelected(keysToClean);
      let response = await getOptions(getAreasByGerenciaAPI(formValues.IdGerencia.value));
      setAllIdArea(response);
      setIfSedeSelected(response, "IdArea");
    };
  }, [formValues.IdGerencia])
  
  useDidMountEffect( async() => {
    if(formValues.IdArea?.value) {
      if (formValues.IdSede?.value !== SEDES.BACK_OFFICE) cleanDropdownSelected(["IdSeccion"]);
      setAllIdSeccion(await getOptions(getSeccionesByAreaAPI(formValues.IdArea.value)));
    };
  }, [formValues.IdArea])

  useDidMountEffect( async () => {
    if (formValues.IdSede?.value){
      let response = await executeGet(consultarSedeNuevoIngresoGestionAPI(formValues.IdSede?.value));
      if(responseCode200(response)){
        let data = response.data;
        if (data.IdSede !== SEDES.BACK_OFFICE){
          cleanDropdownSelected(["IdDivision"]);
          let IdDivision = handleFind(allDropdowns, "IdDivision")?.find(o => o.value === data.IdDivision);
          setDataSelected(data);
          setFormValues(lastData => ({...lastData, IdDivision }));
          setFormValuesData(lastData => ({...lastData, IdDivision: IdDivision?.value}));
        }
        if (!hasEffectRun){
          if (formValues.IdCargo?.value !== CARGOS.DIRECTOR_COLEGIO){
            let IdEmpleadoLider = {label: data?.EmpleadoLider, value: data?.IdEmpleadoLider};
            setFormValues(lastData => ({...lastData, IdEmpleadoLider }));
            setFormValuesData(lastData => ({...lastData, IdEmpleadoLider: data.IdEmpleadoLider}));
          } else {
            cleanDropdownSelected(["IdEmpleadoLider"]);
          }
        }
      } else {
        cleanDropdownSelected(["IdEmpleadoLider", "IdDivision", "IdGerencia", "IdArea"]);
        setDataSelected({});
        if (formValues.IdSede?.value === SEDES.BACK_OFFICE) {
          let IdSeccion = {label: "(Solo BO)"};
          setFormValues(lastData => ({...lastData, IdSeccion}));
        }
      }
    }
  }, [formValues.IdSede, formValues.IdCargo])

  useDidMountEffect(async () => {
    if (formValues.IdCargo?.value && formValues.IdTipoContrato?.value === TIPOS_CONTRATO.INCREMENTO_ACTIVIDAD){
      let response = await executeGet(getPerfilPuestoByCargoAPI(formValues.IdCargo?.value));
      if(responseCode200(response)){
        if (response.data.IdPeriodoPrueba){
          let IdPeriodoPrueba = handleFindDropdownSelected(allDropdowns, response.data, ID_TYPES_NUEVOS_INGRESOS.PeriodoPrueba);
          setFormValues(lastData => ({...lastData, IdPeriodoPrueba}));
          setFormValuesData(lastData => ({...lastData, IdPeriodoPrueba: IdPeriodoPrueba?.value}));
        } else {
          cleanDropdownSelected(["IdPeriodoPrueba"]);
          toast.error("El cargo seleccionado no cuenta con periodo de prueba");
        }
      } else {
        cleanDropdownSelected(["IdPeriodoPrueba"]);
        toast.error("El cargo seleccionado no cuenta con periodo de prueba");
      }
    }

    if (formValues.IdCargo?.value === CARGOS.DIRECTOR_COLEGIO) cleanDropdownSelected(["IdEmpleadoLider"]);
  }, [formValues.IdCargo, formValues.IdTipoContrato])

  useDidMountEffect(() => {
    if (allDropdowns.length && jsonNoEstaVacio(initialData)) {
      const newFormValues = {};
      for (const key of Object.keys(initialData)) {
        if (ID_AND_TEXT_INPUTS[key]) {
          newFormValues[key] = { value: initialData[key], label: initialData[ID_AND_TEXT_INPUTS[key]] };
          continue;
        }
        if(/^Id/.test(key)){
          const dropdownFound = allDropdowns.find(dropdown => dropdown.key === key);
          if (dropdownFound) {
            const selectedItem = dropdownFound.value.find(item => item.value === initialData[key]);
            if (selectedItem) {
              newFormValues[key] = selectedItem;
            }
          }
        } else {
          newFormValues[key] = initialData[key];
        }
      }
      setHasEffectRun(true);
      setFormValues(lastData => ({ ...lastData, ...newFormValues }));
      setFormValuesData(lastData => ({ ...lastData, ...initialData }));
    }
  }, [initialData, allDropdowns])

  useEffect(() => {
    init();
  }, [])

  if(isLoading) return <SpinnerLoading />

  return (
    <div id={`accordion-${ID_PARENT}`} className='first-tab-content' style={{marginBottom: "180px"}}>
      <form className="">
        <CardAccordion id={OPTIONS.idDatosIdentificacion} idParent={ID_PARENT} label="1. Datos de Identificación" isShow>
          <div className="displayFlexWrap mt-4">
            <DivCol required><InputSelect label="Tipo de documento" {...commonProps({name: "IdTipoDocumento", isSelect: true, cleanValues: ["NumeroPasaporte"]})} direction="column"/></DivCol>
            <DivCol required><InputText label="N° de documento" {...commonProps({name: "NumeroDocumento"})} isDisabled={!formValues?.IdTipoDocumento?.value} onBlur={handleCheckReEnter} direction="column"/></DivCol>
            {formValues?.IdTipoDocumento?.value === TIPOS_DOCUMENTO_IDENTIDAD.CEX &&
              <DivCol required><InputText label="Pasaporte" {...commonProps({name: "NumeroPasaporte"})} direction="column"/></DivCol>
            }
            <DivCol required><InputText label="Nombres" {...commonProps({name: "Nombres"})} direction="column"/></DivCol>
            <DivCol required><InputText label="Apellido Paterno" {...commonProps({name: "ApellidoPaterno"})} direction="column"/></DivCol>
            <DivCol required><InputText label="Apellido Materno" {...commonProps({name: "ApellidoMaterno"})} direction="column"/></DivCol>
            <DivCol><InputSelect label="Sexo" {...commonProps({name: "IdGenero", isSelect: true})} direction="column"/></DivCol>
            <DivCol required><InputText label="Correo Personal" {...commonProps({name: "Correo"})} direction="column"/></DivCol>
            <DivCol required><InputText label="Celular Personal" {...commonProps({name: "Celular"})} direction="column"/></DivCol>
          </div>
        </CardAccordion>
        {/* <CardAccordion id={OPTIONS.idDatosNacimiento} idParent={ID_PARENT} label="2. Datos de Nacimiento" isShow>
          <div className="displayFlexWrap mt-4">
            <DivCol><InputDate label="Fecha de nacimiento" {...commonProps({name: "FechaNacimiento"})} direction="column"/></DivCol>
          </div>
        </CardAccordion> */}
        {/* <CardAccordion id={OPTIONS.idFormacionAcademica} idParent={ID_PARENT} label="3. Formación Académica" isShow>
          <div className="displayFlexWrap mt-4">
            <DivCol required><InputSelect label="Tipo de instrucción" {...commonProps({name: "IdTipoInstruccion", isSelect: true})} direction="column"/></DivCol>
          </div>
        </CardAccordion> */}
        <CardAccordion id={OPTIONS.idDatosEmpresa} idParent={ID_PARENT} label="2. Datos de Empresa" isShow>
          <div className="displayFlexWrap mt-4">
            <DivCol required><InputDate label="Fecha Ingreso Empresa" {...commonProps({name: "FechaIngresoEmpresa"})} direction="column" min={getCurrentDate()}/></DivCol>
            <DivCol required><InputDate label="Fecha Ingreso real" {...commonProps({name: "FechaIngresoReal"})} direction="column" min={getCurrentDate()}/></DivCol>
            <DivCol required><InputSelect label="Cargo" {...commonProps({name: "IdCargo", isSelect: true, cleanValues: ["IdEspecialidadSeleccion", "IdGradoDesarrollo"]})} direction="column"/></DivCol>
            <DivCol required><InputSelect label="Sede" {...commonProps({name: "IdSede", isSelect: true, cleanValues: ["Profesion", "Especialidad"]})} direction="column"/></DivCol>
            <DivCol><InputSelect label="Sede Secundaria" {...commonProps({name: "IdSedeSecundaria", isSelect: true})} direction="column"/></DivCol>
            <DivCol required><InputSelect label="División" {...commonProps({name: "IdDivision", isSelect: true})} direction="column"/></DivCol>
            <DivCol required><InputSelect label="Gerencia" name="IdGerencia" options={allIdGerencia} value={formValues.IdGerencia} onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>
            <DivCol required><InputSelect label="Área" name="IdArea" options={allIdArea} value={formValues.IdArea} onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>      
            <DivCol><InputSelect label="Sección" name="IdSeccion" options={allIdSeccion} value={formValues.IdSeccion} onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>
            {(formValues.IdCargo?.value === CARGOS.PROFESOR || formValues.IdCargo?.value === CARGOS.ASISTENTE_EDUCACION) &&
              <>
                <DivCol required><InputSelect label="Especialidad Selección Ingreso" {...commonProps({name: "IdEspecialidadSeleccion", isSelect: true})} direction="column"/></DivCol>
                <DivCol><InputSelect label="Grado de Desarrollo Ingreso" {...commonProps({name: "IdGradoDesarrollo", isSelect: true})} direction="column"/></DivCol>
              </>
            }
            <DivCol required><InputText label="Remuneración" {...commonProps({name: "Remuneracion"})} direction="column"/></DivCol>
            <DivCol required><InputSelect label="Tipo Trabajador" {...commonProps({name: "IdTipoTrabajador", isSelect: true, cleanValues: ["IdTipoContrato", "IdRegimenLaboral"]})} direction="column"/></DivCol>
            <DivCol required><InputSelect label="Jornada Laboral" {...commonProps({name: "IdTipoEmpleado", isSelect: true, cleanValues: ["IdTipoContrato", "IdRegimenLaboral"]})} direction="column"/></DivCol>
            <DivCol required><InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonProps({name: "IdTipoContrato", cleanValues: ["CantDiasSemana", "NombreProyectoServicio", "IdColaboradorSuplencia", "IdTipoLicencia", "DiasLicencia", "IdPeriodoPrueba", "IdCondicionAcademica", "Profesion", "Especialidad", "IdRegimenLaboral", "FechaVencimientoContrato"]})} direction="column"/></DivCol>
            <DivCol required><InputSelectCascade label="Régimen Laboral" dependendOption={formValues[ID_TYPES_NUEVOS_INGRESOS.TipoContrato]?.value} api={getRelatedDataTypesAPI} {...commonProps({name: "IdRegimenLaboral", isSelect: true})} value={formValues.IdRegimenLaboral?.value} direction="column"/></DivCol>
            <DivCol><InputDate label="Fecha de Inicio de Contrato" value={formValues.FechaIngresoEmpresa} isDisabled direction="column"/></DivCol>
            {formValues[ID_TYPES_NUEVOS_INGRESOS.TipoContrato]?.value !== TIPO_DE_CONTRATO_INDEFINIDO_ID &&
              <DivCol required><InputDate label="Fecha de Vencimiento de Contrato" {...commonProps({name: "FechaVencimientoContrato"})} direction="column"/></DivCol>
            }
            <DivCol required><InputSelect label="Forma de Ingreso" {...commonProps({name: "IdFormaIngreso", isSelect: true})} direction="column"/></DivCol>
            <DivCol required><InputSearchAsync typeGet label="Empleado Líder" value={formValues.IdEmpleadoLider?.value} valueText={formValues.IdEmpleadoLider?.label} api={buscarColaboradorAPI} name="IdEmpleadoLider" onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>
            {/* <DivCol><InputSearchAsync typeGet label="Empleado Líder Matricial" value={formValues.IdEmpleadoMatricialSuperior?.value} valueText={formValues.IdEmpleadoMatricialSuperior?.label} api={buscarColaboradorAPI} name="IdEmpleadoMatricialSuperior" onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol> */}
            <DivCol><InputText label="Empleado Líder Matricial" {...commonProps({name:"EmpleadoMatricialSuperior"})} isDisabled={true} direction='column'/></DivCol>

            
            <DivCol required><InputSelect label="Fuente Reclutamiento" {...commonProps({name: "IdFuenteReclutamiento", isSelect: true})} direction="column"/></DivCol>
            
            {((formValues.IdSede?.value === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues?.IdTipoTrabajador?.value)) || TIPOS_CONTRATO.PART_TIME.includes(formValues.IdTipoContrato?.value) || formValues.IdTipoContrato?.value === TIPOS_CONTRATO.OBRA_SERVICIO || formValues.IdTipoContrato?.value === TIPOS_CONTRATO.SUPLENCIA || formValues.IdTipoContrato?.value === TIPOS_CONTRATO.INCREMENTO_ACTIVIDAD || formValues.IdTipoContrato?.value === TIPOS_CONTRATO.NECESIDAD_MERCADO) &&
              <div className="col-sm-12 ficha-datos-empresa-seccion-title"></div>
            }
            {((formValues.IdSede?.value === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues?.IdTipoTrabajador?.value)) || (formValues.IdTipoContrato?.value === TIPOS_CONTRATO.SUPLENCIA) || (formValues.IdTipoContrato?.value === TIPOS_CONTRATO.NECESIDAD_MERCADO)) && 
              <DivCol required><InputText label="Profesión del Candidato" {...commonProps({name: "Profesion"})} direction="column"/></DivCol>
            }
            {((formValues.IdSede?.value === SEDES.BACK_OFFICE && !IDS_TIPO_EMPLEADO_PRACTICANTE.includes(formValues?.IdTipoTrabajador?.value)) || (formValues.IdTipoContrato?.value === TIPOS_CONTRATO.NECESIDAD_MERCADO)) && 
              <DivCol required><InputText label="Especialidad del Candidato" {...commonProps({name: "Especialidad"})} direction="column"/></DivCol>
            }
            {TIPOS_CONTRATO.PART_TIME.includes(formValues.IdTipoContrato?.value) &&
              <DivCol><InputSelect label="Cant.Días a la semana Part-Time" options={diasPartTime} {...commonProps({name: "CantDiasSemana"})} direction="column"/></DivCol>
            }
            {formValues.IdTipoContrato?.value === TIPOS_CONTRATO.OBRA_SERVICIO &&
              <DivCol required><InputText label="Nombre del proyecto o Servicio" {...commonProps({name: "NombreProyectoServicio"})} direction="column"/></DivCol>
            }
            {formValues.IdTipoContrato?.value === TIPOS_CONTRATO.SUPLENCIA &&
              <>
                <DivCol required><InputSearchAsync typeGet label="Colaborador Suplencia" value={formValues.IdColaboradorSuplencia?.value} valueText={formValues.IdColaboradorSuplencia?.label} api={buscarColaboradorAPI} name="IdColaboradorSuplencia" onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>
                <DivCol required><InputSelect label="Tipo de Licencia" {...commonProps({name: "IdTipoLicencia", isSelect: true})} direction="column"/></DivCol>
                <DivCol required><InputText label="Días de licencia" {...commonProps({name: "DiasLicencia"})} direction="column"/></DivCol>
                <DivCol required><InputSelect label="Condición Académica" {...commonProps({name: "IdCondicionAcademica", isSelect: true})} direction="column"/></DivCol>
              </>
            }
            {formValues.IdTipoContrato?.value === TIPOS_CONTRATO.INCREMENTO_ACTIVIDAD &&
              <DivCol required><InputSelect label="Periodo de Prueba" {...commonProps({name: "IdPeriodoPrueba", isSelect: true})} direction="column"/></DivCol>
            }
          </div>
        </CardAccordion>
        <CardAccordion id={OPTIONS.idDatosNuevoIngreso} idParent={ID_PARENT} label="3. Datos nuevo ingreso" isShow>
          <div className="displayFlexWrap mt-4">
            <DivCol required><InputMultiSelect label="Listas de Documentos" {...propsMultiSelect("IdsListasDocumentos")} direction="column"/></DivCol>
            <DivCol required><InputBool label="Reingreso" {...commonProps({name: "EsReingreso"})} colLabel={6} colInput={6}/></DivCol>
            <DivCol required><InputSelect label="Responsable de Proceso" {...commonProps({name: "IdResponsableProceso", isSelect: true})} direction="column"/></DivCol>
            <DivCol required><InputDate label="Fecha límite de entrega de documentos" {...commonProps({name: "FechaLimiteDocumentos"})} direction="column"/></DivCol>
            <DivCol><InputBool label="Bono Movilidad" {...commonProps({name: "TieneBonoMovilidad", cleanValues: ["MontoBonoMovilidad"]})} colLabel={6} colInput={6}/></DivCol>
            {formValues?.TieneBonoMovilidad &&
              <DivCol><InputText label="Monto Movilidad" {...commonProps({name: "MontoBonoMovilidad"})} direction="column"/></DivCol>
            }
            <DivCol><InputBool label="Bono Destajo" {...commonProps({name: "TieneBonoDesatajo", cleanValues: ["MontoBonoDesatajo"]})} colLabel={6} colInput={6}/></DivCol>
            {formValues?.TieneBonoDesatajo && 
              <DivCol><InputText label="Monto Destajo" {...commonProps({name: "MontoBonoDesatajo"})} direction="column"/></DivCol>
            }
            <DivCol><InputBool label="Bono Referido" {...commonProps({name: "TieneBonoReferido", cleanValues: ["MontoBonoReferido", "IdEmpleadoReferido"]})} colLabel={6} colInput={6}/></DivCol>
            {formValues.TieneBonoReferido &&
              <>
                <DivCol><InputText label="Monto Referido" {...commonProps({name: "MontoBonoReferido"})} direction="column"/></DivCol>
                <DivCol><InputSearchAsync typeGet label="Nombre referido" api={buscarColaboradorAPI} name="IdEmpleadoReferido" onChange={handleSubmitData} sectionName={SECTION_NAME} direction="column"/></DivCol>
              </>
            }
          </div>
        </CardAccordion>
      </form>
    </div>
  )
}

export default DatosPersonales;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""} my-auto`}>
      {children}
    </div>
  )
}

const diasPartTime = [
  {label: "Un (1) día", value: "1"}, 
  {label: "Dos (2) días", value: "2"}
]