import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalForm from '../../../components/modalComponents/ModalForm'
import { uploadFile } from '../../../consumers/backendConsumer';
import { AuthContext } from '../../../hooks/context/authContext';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import { cargarTRegistrosNuevosIngresosAPI } from '../../../consumers/backendApisUrls';
import { extensionYtamañoDeArchivoEsValido, parseExtensionesToString } from '../../../helper/utils';

const VALID_EXTENTIONS = ["pdf"];

const FirmaActaTRegistro = ({ toggleModal, manageSearch }) => {
  const { state } = useContext(AuthContext);

  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [executePost] = useManagePostRequest();

  const handleUpload = async (e) => {
    if (!e.target.files.length) {
      return toast.warn("Tiene que cargar al menos un archivo");
    };
    setIsUploading(true);
    try {
      const uploadedFiles = await getUploadedFiles(e);
      setFiles(prev => [...prev, ...uploadedFiles]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
    }
  }

  const getUploadedFiles = async (e) => {
    const files = e.target.files;
    let uploadedFilesPromises = [];
    for (let file of files) {
      if (!extensionYtamañoDeArchivoEsValido(file, VALID_EXTENTIONS)) continue;
      uploadedFilesPromises.push(uploadFile(file, state.token));
    }
    const response = await Promise.allSettled(uploadedFilesPromises);
    const uploadedFiles = response.filter(o => o.status === "fulfilled")?.map(o => o.value.data?.file);
    return uploadedFiles;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!files.length){
      return toast.warn("Tiene que cargar al menos un archivo");
    }
    setIsSubmitting(true);
    const api = cargarTRegistrosNuevosIngresosAPI();
    try {
      await executePost(api, files, handleSuccessSubmit);
    } catch (error) {
      console.error();
    } finally {
      setIsSubmitting(false);
    }
  }
  const handleSuccessSubmit = async () => {
    toggleModal();
    await manageSearch();
  }
  return (
    <ModalForm onSubmit={handleSubmit} titulo='Firmar Acta T-Registro' width='800px' showModal={toggleModal} saveButtonDisabled={isUploading || isSubmitting} isSubmitting={isSubmitting}>
      <div className='custom-file-upload d-flex justify-content-center align-items-center flex-column'>
        <div role="alert" class="fade alert alert-warning alert-dismissible show text-center w-100">
          <p class="ficha-secciones-title-text font-gothan-light">Tener en cuenta que el límite máximo son 20 archivos</p>
        </div>
        <label htmlFor='file-FirmaActaTRegistro' className='btn btn-primary generic-button-ficha mb-3'>
          {isUploading
            ? <FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>
            : "Cargar Archivos"
          }
        </label>
        <input type="file" id='file-FirmaActaTRegistro' multiple onChange={handleUpload} accept={parseExtensionesToString(VALID_EXTENTIONS)} disabled={isUploading || isSubmitting}/>
        {files.length > 0 &&
          <p className='text-center'>{files.length} archivos cargados</p>
        }
      </div>
    </ModalForm>
  )
}

export default FirmaActaTRegistro