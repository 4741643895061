import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {getEtapasYGruposAPI, procesoCierreEjecutarEtapaAPI, procesoCierreEjecutarGrupoAPI, procesoCierreIniciarEtapaAPI, procesoCierreIniciarGrupoAPI, procesoCierreReprocesarEtapaAPI, procesoCierreReprocesarGrupoAPI} from "../../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDescargarArchivoYEliminarArchivoDeS3} from "../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3";
import GenericTooltip from "../../../components/GenericTooltip/GenericTooltip";
import {toast} from "react-toastify";
import {useDescargarArchivoDeS3} from "../../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoDeS3";

const MonitorDeProcesos = ({toggleModal}) => {
    const acciones = {
        'etapas': {
            'iniciar': {
                'textButton': "Iniciar", api() {
                    return procesoCierreIniciarEtapaAPI
                }
            },
            'finalizar': {
                'textButton': "Finalizar", api() {
                    return procesoCierreEjecutarEtapaAPI
                }
            },
            'reprocesar': {
                'textButton': "Reprocesar", api() {
                    return procesoCierreReprocesarEtapaAPI
                }
            }
        },
        'grupos': {
            'iniciar': {
                'textButton': "Iniciar", api() {
                    return procesoCierreIniciarGrupoAPI
                }
            },
            'finalizar': {
                'textButton': "Finalizar", api() {
                    return procesoCierreEjecutarGrupoAPI
                }
            },
            'reprocesar': {
                'textButton': "Reprocesar", api() {
                    return procesoCierreReprocesarGrupoAPI
                }
            }
        }
    }

    const [etapasYgrupos, setEtapasYgrupos] = useState([]);
    const [isLoadingEtapas, setIsLoadingEtapas] = useState({});
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [descargarArchivoDeS3] = useDescargarArchivoDeS3();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    const getEtapasYgrupos = async function () {
        const response = await executeGetRequest(getEtapasYGruposAPI());
        if (responseCode200(response)) {
            setEtapasYgrupos(response?.data);
            setIsLoadingEtapas(Object.fromEntries(response.data?.Etapas.map(etapa => [etapa.IdMonitorProcesoCierre, false])))
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const init = async function () {
        await getEtapasYgrupos();
    };

    const callApi = async function (api, id) {
        const response = await executePostRequest(api, {});
        await manageApiResponseCustom(response, getEtapasYgrupos);
        if (response) setIsLoadingEtapas({...isLoadingEtapas, [id]: false});
    };

    const manageApiResponseCustom = async (response, callbackSuccess) => {
        if (responseCode200(response)) {
            toast.success(response?.data?.Message);
            callbackSuccess(response);
        } else {
            mostrarMensajeDeErrorConToast(response);
            if (response.response.status === 400) {
                callbackSuccess(response)
            }
        }
    };

    const descargarLog = async function (originalFileName, filePath) {
        await descargarArchivoDeS3(originalFileName, filePath);
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"1300px"} titulo={"Monitor del Proceso"}>
                <div className="modal-body">
                    <form className="blue-standard-color">
                        <div>
                            <h6><strong>Etapas</strong></h6>
                            <table className="table table-hover" style={{backgroundColor: '#fff'}}>
                                <thead>
                                <tr className="d-flex">
                                    <th className="p-0 col-2">Nombre</th>
                                    <th className="p-0 col-2">Inicio</th>
                                    <th className="p-0 col-2">Fin</th>
                                    <th className="p-0 col-1">Estado Proceso</th>
                                    <th className="p-0 col-1">Estado Resultado</th>
                                    <th className="p-0 col-2">Acción</th>
                                    <th className="p-0 col-2">Detalles</th>
                                </tr>
                                </thead>
                                <tbody>
                                {etapasYgrupos?.Etapas && etapasYgrupos?.Etapas.map((etapa, index) => {
                                    return (
                                        <tr key={index} className="d-flex">
                                            <td className="p-0 col-2">{etapa?.Nombre}</td>
                                            <td className="p-0 col-2">{etapa?.FechaInicio}</td>
                                            <td className="p-0 col-2">{etapa?.FechaFin}</td>
                                            <td className="p-0 col-1">{etapa?.EstadoProceso}</td>
                                            <td className="p-0 col-1">{isLoadingEtapas[etapa.IdMonitorProcesoCierre]? "Procesando": etapa?.EstadoResultado}</td>
                                            <td className="p-0 col-2">
                                                {etapa?.Code && etapa?.MostrarBoton === true && !isLoadingEtapas[etapa.IdMonitorProcesoCierre] &&
                                                <button className="btn btn-primary generic-button-ficha pt-0 pb-0" onClick={async (e) => {
                                                    e.preventDefault();
                                                    setIsLoadingEtapas({...isLoadingEtapas, [etapa.IdMonitorProcesoCierre]: true});
                                                    let api = acciones['etapas'][etapa?.Code].api();
                                                    await callApi(api(etapa?.IdMonitorProcesoCierre), etapa?.IdMonitorProcesoCierre);
                                                }}>
                                                    {acciones['etapas'][etapa?.Code]['textButton']}
                                                </button>
                                                }
                                            </td>
                                            <td className="p-0 col-2">
                                                {etapa?.FileViewModel &&
                                                <FontAwesomeIcon icon='file-alt' transform="left-1" className="hand-pointer blue-standard-color" onClick={() => descargarLog(etapa?.FileViewModel?.OriginalFileName, etapa?.FileViewModel?.Path)} title={"Descargar log"}/>
                                                }

                                                {etapa?.Detalle &&
                                                <GenericTooltip id={"monitor-proceso-detalle" + index} text={etapa?.Detalle}/>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <h6><strong>Grupos</strong></h6>
                            <table className="table table-hover" style={{backgroundColor: '#fff'}}>
                                <thead>
                                <tr>
                                    <th className="p-0 col-2">Nombre</th>
                                    <th className="p-0 col-2">Inicio</th>
                                    <th className="p-0 col-2">Fin</th>
                                    <th className="p-0 col-1">Estado Proceso</th>
                                    <th className="p-0 col-1">Estado Resultado</th>
                                    <th className="p-0 col-2">Acción</th>
                                    <th className="p-0 col-2">Detalles</th>
                                </tr>
                                </thead>
                                <tbody>
                                {etapasYgrupos?.Grupos && etapasYgrupos?.Grupos.map((grupo, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="p-0 col-2">{grupo?.Nombre}</td>
                                            <td className="p-0 col-2">{grupo?.FechaInicio}</td>
                                            <td className="p-0 col-2">{grupo?.FechaFin}</td>
                                            <td className="p-0 col-1">{grupo?.EstadoProceso}</td>
                                            <td className="p-0 col-1">{grupo?.EstadoResultado}</td>
                                            <td className="p-0 col-2">
                                                {grupo?.Code && grupo?.MostrarBoton === true &&
                                                <button className="btn btn-primary generic-button-ficha pt-0 pb-0" onClick={
                                                    async (e) => {
                                                        e.preventDefault();
                                                        let api = acciones['grupos'][grupo?.Code].api();
                                                        await callApi(api(grupo?.IdMonitorProcesoCierre));
                                                    }}>
                                                    {acciones['grupos'][grupo?.Code]['textButton']}
                                                </button>
                                                }
                                            </td>
                                            <td className="p-0 col-2">
                                                {grupo?.FileViewModel &&
                                                <FontAwesomeIcon icon='file-alt' transform="left-1" className="hand-pointer blue-standard-color" onClick={() => descargarLog(grupo?.FileViewModel?.OriginalFileName, grupo?.FileViewModel?.Path)} title={"Descargar log"}/>
                                                }

                                                {grupo?.Detalle &&
                                                <GenericTooltip id={"monitor-proceso-detalle" + index} text={grupo?.Detalle}/>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </form>
                </div>

                <div className="modal-footer d-flex justify-content-between">
                </div>
            </GenericModal>
        </>
    )
}

export default MonitorDeProcesos;