import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Wrapper from '../../../components/atoms/DirectorioWrapper';
import PageHeader from '../../../components/pageHeader/PageHeader';
import { procesoCierreAdminGetAllGruposAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { onKeyPressEnter } from '../../../helper/utils';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import Paginator from '../../directorio/Paginator';
import CrearGrupo from './modales/CrearGrupo';
import EditarGrupo from './modales/EditarGrupo';
import EliminarGrupo from './modales/EliminarGrupo';
import TableWebBody from './TableWebBody';

const AdminGrupos = () => {
    const [executePostRequest] = usePostRequest();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");
    const [gruposSeleccionados, setGruposSeleccionados] = useState([]);

    const [filas, setFilas] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [grupoId, setGrupoId] = useState(null);
    const [grupoTitle, setGrupoTitle] = useState(null);

    const [loading, setLoading] = useState(true);
    const [hideModalCrearGrupo, setHideModalCrearGrupo] = useState(false);
    const [hideModalEditarGrupo, setHideModalEditarGrupo] = useState(false);
    const [hideModalEliminarGrupo, setHideModalEliminarGrupo] = useState(false);


    const history = useHistory();

    const getInitialData = async () => {
        setLoading(true);
        try {
            await manageSearch();
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }

    const manageSearch = async (rendererTable = false) => {
        let payload = obtenerPayload();
        await executeSearch(payload, rendererTable);
    }

    const obtenerPayload = () => {
        let payload = {
            "Page": page,
            "Criteria": criterioDeBusqueda,
            "IdsGrupos": gruposSeleccionados,
        };
        return payload;
    }

    const limpiarBusqueda = () => {
        setCriterioDeBusqueda("");
        setGruposSeleccionados([]);
    }

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const executeSearch = async (payload, rendererTable) => {
        rendererTable === true && setFilas([]);
        const response = await executePostRequest(procesoCierreAdminGetAllGruposAPI(), payload)
        if (responseCode200(response)) {
            setFilas(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const toggleCrearGrupoModal = () => {
        setHideModalCrearGrupo(!hideModalCrearGrupo);
    }

    const toggleEditarGrupoModal = () => {
        setHideModalEditarGrupo(!hideModalEditarGrupo);
    }

    const toggleEliminarGrupoModal = () => {
        setHideModalEliminarGrupo(!hideModalEliminarGrupo);
    }

    useEffect(() => {
        getInitialData();
    }, [])

    useEffect(() => {
        setPage(1);
    }, [criterioDeBusqueda]);

    useEffect(() => {
        manageSearch();
    }, [gruposSeleccionados])

    useEffect(() => {
        manageSearch();
    }, [page]);

    return (
        <>
            <div className="message-no-disponible-en-mobile">
                <p>Esta página no está disponible en mobile.</p>
            </div>
            <div className="no-disponible-en-mobile">

                {hideModalCrearGrupo &&
                    <div className='modal-contactanos modal-response-ficha-background'>
                        <CrearGrupo
                            toggleModal={toggleCrearGrupoModal}
                            refreshTable={manageSearch}
                        />
                    </div>
                }

                {hideModalEditarGrupo &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <EditarGrupo
                            toggleModal={toggleEditarGrupoModal}
                            refreshTable={manageSearch}
                            grupoId={grupoId}
                        />
                    </div>
                }

                {hideModalEliminarGrupo &&
                    <div className="modal-contactanos modal-response-ficha-background">
                        <EliminarGrupo
                            toggleModal={toggleEliminarGrupoModal}
                            refreshTable={manageSearch}
                            grupoTitle={grupoTitle}
                            grupoId={grupoId}
                        />
                    </div>
                }

                <Wrapper className='App'>
                    <PageHeader title={"Administración de grupos predefinidos"} />
                    <div className="generic-buscador-container">
                        <div className="filtros-left-section">
                            <div className="filtros-left-section">
                                <input
                                    type="text"
                                    className="generic-input-criterio-busqueda"
                                    placeholder='Buscar por nombre'
                                    value={criterioDeBusqueda}
                                    onChange={(e) => setCriterioDeBusqueda(e.target.value)}
                                    onKeyPress={(e) => onKeyPressEnter(e.manageSearch)} />
                                <button
                                    onClick={manageSearch}
                                    className="generic-button-buscador"
                                    type='button'>
                                    Buscar
                                </button>
                                <a className="clean-filters"
                                    onClick={limpiarBusqueda}>
                                    Limpiar búsqueda
                                </a>
                            </div>
                        </div>
                        <div className="filtros-right-section">
                            <button className="generic-button-buscador" onClick={() => history.goBack()}>
                                <FontAwesomeIcon icon='angle-left' size='lg' className='hand-pointer' title='Atras' /> Atrás
                            </button>
                            <button
                                className="generic-button-buscador"
                                type='button'
                                onClick={toggleCrearGrupoModal}
                            >
                                Crear grupo predefinido
                            </button>
                        </div>
                    </div>
                    <div className="container-fluid body-content">
                        <div className="table-responsive form-group-sm pt-3">
                            <table className="table table-hover listar-table-cierre-anio">
                                <thead className="listar-thead">
                                    <tr>
                                        <th className="col-2 pr-1">
                                            <span className="font-gothan-medium d-inline">Nombre</span>
                                        </th>
                                        <th className="col-9 pr-1">
                                            <span className="font-gothan-medium d-inline">Cargos</span>
                                        </th>
                                        <th className="col-1 pr-1">
                                            <span className="font-gothan-medium d-inline">Acciones</span>
                                        </th>
                                    </tr>
                                </thead>
                                <TableWebBody
                                    filas={filas}
                                    setFilas={setFilas}
                                    toggleEditarGrupoModal={toggleEditarGrupoModal}
                                    toggleEliminarGrupoModal={toggleEliminarGrupoModal}
                                    setGrupoTitle={setGrupoTitle}
                                    setGrupoId={setGrupoId}
                                />
                            </table>

                            {totalPages > 0 && (
                                <Paginator totalItems={totalItems}
                                    firsItemPage={firsItemPage}
                                    lastItemPage={lastItemPage}
                                    totalPages={totalPages}
                                    page={page}
                                    onLeftClic={onLeftClic}
                                    onRightClic={onRightClic} />
                            )}
                        </div>
                    </div>
                </Wrapper>
            </div>
        </>
    )
}

export default AdminGrupos