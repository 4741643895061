import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import { tieneAccesoAMenu } from './permisosUtils'
import NoTienePermisos from './NoTienePermisos'
import { AuthContext } from '../../hooks/context/authContext'

const ProtectedRoute = ({ component: Component, id, ...rest }) => {
  const {state} = useContext(AuthContext);

  return (
    <Route
        {...rest}
        render={() =>
            tieneAccesoAMenu(state?.menusAcceso, id)
                ? <Component/>
                : <NoTienePermisos/>
    }/>
  )
}

export default ProtectedRoute