import React from 'react';
import {FROM_MI_EQUIPO} from "../../constants/hardCodedConstants";

const FichaDetalleEmpleado = ({hideDetalleEmpleado, detalleEmpleado, openFromPageName}) => {
    return (
        <>
            <div hidden={hideDetalleEmpleado} style={{'display': 'flex', 'justifyContent': 'space-between', 'marginTop': '25px', 'marginBottom': '25px'}}>

                <div style={{'display': 'flex', 'justifyContent': 'flex-start'}}>
                    <div style={{'marginLeft': '45px', 'marginRight': '15px'}}>
                        <img
                            style={{borderRadius: '50%', width: '70px'}}
                            src={detalleEmpleado.ProfileImageUrl}
                            alt=''
                        />
                    </div>

                    <div style={{'marginTop': '12px'}}>
                        <div className='ficha-detalle-nombre-completo font-gothan-medium'>{detalleEmpleado.NombreCompleto}</div>
                        <div className='area-cargo'>{detalleEmpleado.NombreCargo}</div>
                    </div>
                </div>
                <div style={{'marginRight': '45px'}}>
                    <button className='btn btn-primary ficha-detalle-button-regresar mt-12' style={{'marginTop': '24px'}} onClick={() => {
                        if (openFromPageName === FROM_MI_EQUIPO){
                            window.open(`/miequipo`, '_self');
                        }else {
                            window.open(`/gestionFichaDatos`, '_self');
                        }

                    }}>Regresar
                    </button>
                </div>
            </div>
        </>
    )
};
export default FichaDetalleEmpleado;