import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditarPresupuesto from './modales/EditarPresupuesto'
import ModalActivarInactivarGeneric from '../../../components/modalActivarInactivar/ModalActivarInactivarGeneric'
import { activarIncativarPresupuestoAPI } from '../../../consumers/backendApisUrls'

const TableWebBody = ({ filas, setModalEnabled }) => {

  const handleActivarInactivar = (fila) => {
    setModalEnabled({isEnable: true, component: ModalActivarInactivarGeneric, 
      data: {
        api: activarIncativarPresupuestoAPI, 
        id: fila.IdPresupuesto, 
        title: "Presupuesto", 
        name: fila.Nombre, 
        state: fila.EsActivo
      }
    })
  }

  return (
    <tbody className="listar-tbody">
      {filas?.map((fila,i)=> (
        <tr key={`${i}-${fila.IdPresupuesto}`}>
          <td className="">{fila?.Nombre}</td>
          <td className="text-center">{fila?.FechaInicio}</td>
          <td className="text-center">{fila?.FechaFin}</td>
          <td className="text-center">{fila?.Estado}</td>
          <td className="d-flex justify-content-center">
            <div className="d-flex justify-content-start">
              <div>
                <FontAwesomeIcon
                  icon={fila.EsActivo ? 'ban' : 'circle-notch'}
                  className="hand-pointer ml-3"
                  title={fila.EsActivo ? 'Inactivar': 'Activar'}
                  onClick={() => handleActivarInactivar(fila)}
                />
              </div>
              {fila.EsActivo &&
                <div>
                  <FontAwesomeIcon 
                    icon='edit'
                    size='sm'
                    className="hand-pointer ml-3"
                    title="Editar"
                    onClick={() => setModalEnabled({isEnable: true, component: EditarPresupuesto, 
                            data: {idPresupuesto: fila.IdPresupuesto}})}
                  />
                </div>
              }
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody