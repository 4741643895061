import React, {useState, useEffect, useContext} from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { getEditarSolicitudVacacionesAPI } from '../../../consumers/backendApisUrls';
import { AuthContext } from '../../../hooks/context/authContext';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { downloadFile } from '../../../consumers/backendConsumer';
import PreviewFormato from './PreviewFormato';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { calculateDaysInBetween, toIsoForInput } from '../../../helper/utils';

const VerSolicitudVacaciones = ({ showModal, idSolicitudMovimiento }) => {

  const TIME_STAMP = "T00:00:00";
  const {state} = useContext(AuthContext);

  const [formValues, setFormValues] = useState({});
  const [diasVacacionesCalculado, setDiasVacacionesCalculado] = useState(0);
  const [showModalPreviewFormato, setShowModalPreviewFormato] = useState(false);

  const [executeGet] = useManageGetRequest();

  const init = async () => {
    try {
      await manageSearch();
    } catch (error) {
      console.error(error);
    }
  }
  
  const manageSearch = async () => {
    let api = getEditarSolicitudVacacionesAPI(idSolicitudMovimiento);
    await executeGet(api, (res) => setFormValues(res.data), false);
  }

  const togglePreviewFormato = () => {
    setShowModalPreviewFormato(!showModalPreviewFormato);
  }

  const handleDescargarFormato = () => {
    downloadFile(state.token, formValues.Formato?.FilePath, formValues.Formato?.OriginalFileName);
  }

  const calculateVacationDays = () => {
    if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaHasta >= formValues.FechaDesde){
      let days = calculateDaysInBetween(formValues.FechaDesde, formValues.FechaHasta);
      let countNewDays = 0;
      for (let day of dayAdded){
        const FechaDesde = `FechaDesde${day}`;
        const FechaHasta = `FechaHasta${day}`;
        if (formValues[FechaDesde] === undefined || formValues[FechaDesde] === null) continue;
        countNewDays += calculateDaysInBetween(formValues[FechaDesde], formValues[FechaHasta]);
      }
      setDiasVacacionesCalculado(days+countNewDays);
    } else if(formValues.FechaDesde && formValues.FechaHasta && formValues.FechaDesde > formValues.FechaHasta){
      let day = new Date(formValues.FechaDesde+TIME_STAMP);
      let dayISO = toIsoForInput(day);
      setFormValues({...formValues, FechaHasta: dayISO});
    } else {
      setDiasVacacionesCalculado(0);
    }
  }

  useDidMountEffect(() => {
    calculateVacationDays();
  },[formValues])

  useEffect(() => {
    let unmounted = false;
    if(!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    }
  },[])

  return (
    <>
      {showModalPreviewFormato && 
        <div className="modal-contactanos modal-response-ficha-background">
          <PreviewFormato showModal={togglePreviewFormato} idSolicitudMovimiento={idSolicitudMovimiento}/>
        </div>
      }
      <GenericModal
        showModal={showModal}
        titulo="Solicitud de Vacaciones"
        width="700px"
      >
        <div className="modal-body">
          <form>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Solicitante</label>
                <input type="text" className="form-control" value={formValues.Solicitante ?? ""} disabled/>
              </div>
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">email</label>
                <input type="text" className="form-control" value={formValues.Email ?? ""} disabled/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Área</label>
                <input type="text" className="form-control" value={formValues.Area ?? ""} disabled/>
              </div>
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Aprobador</label>
                <input type="text" className="form-control" value={formValues.Aprobador ?? ""} disabled/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-3">
                <label className="modal-label mb-0">Días disponibles</label>
                <input type="text" className="form-control" value={formValues.DiasDisponibles ?? 0} disabled/>
              </div>
              <div className="form-group col-sm-3">
                <label className="modal-label mb-0">Días Seleccionados</label>
                <input type="text" className="form-control" value={diasVacacionesCalculado ?? "-"} disabled/>
              </div>
              <div className="col-sm-6 d-flex align-items-center justify-content-center">
                <a className="btn generic-button-buscador" style={{width: "135px"}} target="_blank" href={formValues.LinkPolitica ?? ""}>
                  Mira aquí la política
                </a>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Desde</label>
                <input type="date" className="form-control" value={formValues.FechaDesde ?? ""} disabled/>
              </div>
              <div className="form-group col-sm-6">
                <label className="modal-label mb-0">Hasta</label>
                <input type="date" className="form-control" value={formValues.FechaHasta ?? ""} disabled/>
              </div>
            </div>
            {formValues.FechaDesdeUno &&
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaDesdeUno" value={formValues.FechaDesdeUno ?? ""} disabled/>
                </div>
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaHastaUno" value={formValues.FechaHastaUno ?? ""} disabled/>
                </div>
              </div>
            }
            {formValues.FechaDesdeDos &&
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaDesdeDos" value={formValues.FechaDesdeDos ?? ""} disabled/>
                </div>
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaHastaDos" value={formValues.FechaHastaDos ?? ""} disabled/>
                </div>
              </div>
            }
            {formValues.FechaDesdeTres &&
              <div className="form-row">
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaDesdeTres" value={formValues.FechaDesdeTres ?? ""} disabled/>
                </div>
                <div className="form-group col-sm-6">
                  <input type="date" className="form-control" name="FechaHastaTres" value={formValues.FechaHastaTres ?? ""} disabled/>
                </div>
              </div>
            }
            <div className="form-row">
              <div className="form-group col-sm-12">
                <label className="modal-label mb-0">Observaciones</label>
                <textarea className="form-control" value={formValues.Comentarios ?? "-"} disabled/>
              </div>
            </div>
            <div className="modal-footer pl-0">
              <div>
                {formValues?.Firma && 
                  <button type='button' className="generic-button-buscador ml-0" style={{width: "135px"}} onClick={togglePreviewFormato}>
                    Preview Formato
                  </button>
                }
                {formValues?.Formato && 
                  <button type='button' className='generic-button-buscador ml-0' style={{width: '135px'}} onClick={handleDescargarFormato}>
                    Descargar Formato
                  </button>
                }
              </div>
              <div>
              </div>
            </div>
            <div className="form-row">
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha registo</label>
                <label className="">{formValues.FechaRegistro ?? "-"}</label>
              </div>
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha modificación</label>
                <label className="">{formValues.FechaModificacion ?? "-"}</label>
              </div>
              <div className="col-sm-4 d-flex flex-column align-items-center">
                <label className="modal-label mb-1">Fecha aprobación.</label>
                <label className="">{formValues.FechaAprobacion ?? "-"}</label>
              </div>
            </div>
          </form>
        </div>
      </GenericModal>
    </>
  )
}

export default VerSolicitudVacaciones;

const dayAdded = [
  "Uno",
  "Dos",
  "Tres"
]