import React from 'react'
import { Spinner } from 'react-bootstrap'

const SpinnerLoading = () => {
  return (
    <div className="spinner-cargando-container">
      <Spinner animation="grow" variant="primary" size="1sm"/>
    </div>
  )
}

export default SpinnerLoading