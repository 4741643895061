import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import GenericModal from '../../../components/modal/GenericModal';
import { getDetalleFichaMedicaAPI, RegistrarEstadoEncuestaSaludAPI } from '../../../consumers/backendApisUrls';
import { downloadFile } from '../../../consumers/backendConsumer';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../consumers/httpRequiestsUtils';
import { AuthContext } from '../../../hooks/context/authContext';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import FormRow from './FormRow';
import {ALL_ESTADOS} from "../../../constants/hardCodedConstants";
import { notNullAndNotUndefinded } from '../../../helper/utils';

const NuevoDetalleEncuestaSaludModal = ({ showModal, idEncuesta }) => {
  const { state } = useContext(AuthContext);

  const [executeGetRequest] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const [loadingFicha, setLoadingFicha] = useState(true);
  const [detalleFicha, setDetalleFicha] = useState(null);

  const [payload, setPayload] = useState({});

  const [showFormRow, setShowFormRow] = useState(false);

  const [sustento, setSustento] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    setLoadingFicha(true);
    const detalle = await getSavedInfoModal();
    if (detalle) {
      setDetalleFicha(detalle);
      setLoadingFicha(false);
      
      for (let item of detalle?.Historial)
      {
        
        if (notNullAndNotUndefinded(item?.Sustento))
        {
          setSustento(item?.Sustento)
          break;
        }
      }
    }
  }

  const getSavedInfoModal = async function () {
    const response = await executeGetRequest(getDetalleFichaMedicaAPI(idEncuesta));
    if (responseCode200(response)) {
      return response?.data;
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const descargarArchivo = (e, archivo) => {
    e.preventDefault()
    downloadFile(state.token, archivo.Path, archivo.OriginalFileName);
  }

  const successSubmitCallback = function () {
    init();
    setShowFormRow(!showFormRow)
  }

  const handleSubmit = async () => {
    const api = RegistrarEstadoEncuestaSaludAPI();
    const keys = [];
    await submitForm(keys, api, payload, "", successSubmitCallback);
  }

  return (
    <>
      <GenericModal showModal={showModal} width={"1350px"} titulo={detalleFicha?.NombreEncuestaSalud}>
        <div className="modal-body">
          <div className="alert alert-light" role="alert">
            {loadingFicha ? (
              <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
              </div>
            ) : (
              <>
                <table className="table table-borderless w-auto">
                  <tbody>
                    <tr>
                      <th className="col-2 p-0 pr-2 mr-3"><strong>Nombre:</strong></th>
                      <td className="p-0">{detalleFicha?.Empleado}</td>
                    </tr>
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Sede:</strong></th>
                      <td className="p-0">{detalleFicha?.Sede}</td>
                    </tr>
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Estado:</strong></th>
                      <td className="p-0">{detalleFicha?.EstadoFicha}</td>
                    </tr>
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Validación:</strong></th>
                      <td className="p-0">{detalleFicha?.EstadoValidacion}</td>
                    </tr>
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Condición de riesgo:</strong></th>
                      <td className="p-0">{detalleFicha?.CondicionRiesgo}</td>
                    </tr>
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Condiciones médicas:</strong></th>
                      <td className="p-0">
                        {detalleFicha?.CondicionesMedicas.map(condicion => (
                          <h5><span className="badge bg-warning text-dark" style={{whiteSpace: "normal"}}>{condicion}</span></h5>
                        ))}
                      </td>
                    </tr>
                    {
                      detalleFicha?.CondicionesMedicas.includes("Embarazo") &&
                      <tr>
                        <th className="p-0 pr-2 mr-3"><strong>Fecha probable de parto:</strong></th> 
                        <td className="p-0">{detalleFicha?.FechaEmbLacMostrar}</td>
                      </tr>
                    }
                    {
                      detalleFicha?.CondicionesMedicas.includes("Madre lactante con bebés menores de 6 meses de edad") &&
                      <tr>
                        <th className="p-0 pr-2 mr-3"><strong>Fecha de nacimiento:</strong></th> 
                        <td className="p-0">{detalleFicha?.FechaEmbLacMostrar}</td>
                      </tr>
                    }
                    <tr>
                      <th className="p-0 pr-2 mr-3"><strong>Sustento:</strong></th>
                      <td className="p-0">
                        <a href="#" className="alert-link" onClick={(e) => descargarArchivo(e, sustento)}>{sustento.OriginalFileName}</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>


          <div className="alert alert-light" role="alert">
            {loadingFicha ? (
              <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm" />
              </div>
            ) : (
              <>
                <div className="row justify-content-between m-0 mb-2">
                  <h4 className="alert-heading">Historial de acciones</h4>
                  {detalleFicha && detalleFicha?.IdEstadoFicha !== ALL_ESTADOS.Cerrado && !showFormRow &&
                    <button type='button' className='generic-button-buscador' onClick={() => setShowFormRow(!showFormRow)}>
                      Añadir estado
                    </button>
                  }

                  {detalleFicha && detalleFicha?.IdEstadoFicha !== ALL_ESTADOS.Cerrado && showFormRow &&
                  <div>
                    <button type='button' className='generic-button-buscador' onClick={() => setShowFormRow(!showFormRow)}>
                      Cancelar
                    </button>
                    <button type='button' className='generic-button-buscador' onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}>
                      Guardar
                    </button>
                  </div>
                  }

                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col-2">Fecha</th>
                      <th className="col-2">Estado Ficha</th>
                      <th className="col-1">Condición de riesgo</th>
                      <th className="col-1">Estado Validación</th>
                      <th className="col-2">Resultado</th>
                      <th className="col">Descripción</th>
                      <th className="col">Adjuntos</th>
                      <th className="col-1">Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detalleFicha?.Historial?.map((item, i) => (
                      <tr key={`${i} + ${item.Fecha} + ${item.EstadoFicha} + ${item.CondicionRiesgo} + ${item.EstadoValidacion}`}>
                        <td>{item?.Fecha}</td>
                        <td>{item?.EstadoFicha}</td>
                        <td>{item?.CondicionRiesgo}</td>
                        <td>{item?.EstadoValidacion}</td>
                        <td>{item?.Resultado}</td>
                        <td>{item?.Descripcion}</td>
                        <td>
                          <a href="#" className="alert-link" onClick={(e) => descargarArchivo(e, item?.Sustento)}>
                            {item?.Sustento?.OriginalFileName}
                          </a>
                        </td>
                        <td><div style={{wordWrap: "break-word", width: "60%"}}>{item?.Usuario}</div></td>
                      </tr>
                    ))}
                    {showFormRow ? <FormRow payload={payload} setPayload={setPayload} idEncuesta={idEncuesta} /> : null}
                  </tbody>
                </table>
              </>)}
          </div>
        </div>
      </GenericModal>
    </>
  );
};

export default NuevoDetalleEncuestaSaludModal;
