import React, { useState, useEffect, useContext } from 'react'
import Select from 'react-select';
import { toast } from 'react-toastify'
import { NavDropdown } from 'react-bootstrap'
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest'
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest'
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect'
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest'
import SearchContainer from '../../../components/pageComponents/SearchContainer'
import ModalEnabled from '../../../components/modalComponents/ModalEnabled'
import PageButtons from '../../../components/pageComponents/PageButtons'
import PageWrapper from '../../../components/pageComponents/PageWrapper'
import PaginatorV2 from '../../../components/pageComponents/PaginatorV2'
import PageHeader from '../../../components/pageHeader/PageHeader'
import FormSelect from '../../../components/atoms/Select'
import TableWebBody from './TableWebBody'
import CrearProcesoModal from './modales/CrearProcesoModal'
import EditarProcesoModal from './modales/EditarProcesoModal'
import ConfigurarProcesoModal from './modales/ConfigurarProcesoModal'
import { handleFind, initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../../helper/utils'
import { getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getProcesosSolicitudTaiLoyAPI, listarSolicitudesTaiLoyAPI, solicitudTaiLoyDescargarExcelAPI } from '../../../consumers/backendApisUrls'

const GestionSolicitudValeDescuento = () => {

  const INIT_KEYS = { Cargos: "IdsCargos", Sedes: "IdsSedes", EstadoSolicitud: "IdsEstadoSolicitud" };

  const [procesosSolicitud, setProcesosSolicitud] = useState({ IdsProcesoSolicitud: [], procesoSelected: null });
  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeG] = useGetRequest();
  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();
  const [downloadAndDelete] = useGenerarDescargarEliminarArchivo();

  const initialPromises = () => {
    return [
      executeG(getAllCargosAPI()),
      executeG(getAllSedesAPI()),
      executeG(getAllEstadosAPI()),
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await getProcesos({});
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getProcesos = async ({ isNew = true }) => {
    const api = getProcesosSolicitudTaiLoyAPI();
    await executeGet(api, ({ data }) => sucessInitCallback(data, isNew));
  }

  const sucessInitCallback = ( data , isNew) => {
    const IdsProcesoSolicitud = data.map(o => ({...o, value: o.IdProcesoSolicitudTaiLoy, label: o.Nombre}));

    const procesoSelected = IdsProcesoSolicitud?.at(0) ?? null;
    setProcesosSolicitud(lastData => ({...lastData, IdsProcesoSolicitud, procesoSelected }));
  }

  const manageSearch = async () => {
    const api = listarSolicitudesTaiLoyAPI();
    const payload = getPayload();
    await executePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      EstadoRegistro: true,
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      IdProcesoSolicitudTaiLoy: procesosSolicitud.procesoSelected.IdProcesoSolicitudTaiLoy,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data.data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleSelectedFilters = (res) => {
    setFormValues(lastData => ({...lastData, filters: res }))
  }

  const handleDownloadExcel = async () => {
    const api = solicitudTaiLoyDescargarExcelAPI();
    let payload = getPayload();
    try {
      await downloadAndDelete(api, payload);
    } catch (error) {
      console.error(error);
    } finally {

    }
  }

  const handleFilterSelected = (res, key) => {
    const updatedSelectedFilters = {...formValues.selectedFilters, [key]: res};
    setFormValues(lastData => ({...lastData, selectedFilters: updatedSelectedFilters}));
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [procesosSolicitud.procesoSelected, paginator.CurrentPage, formValues.selectedFilters ])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleSelectedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Gestión de solicitud de vale de descuento"/>
      <SelectProcesoSolicitud procesosSolicitud={procesosSolicitud} setProcesosSolicitud={setProcesosSolicitud}/>
      <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
        <PageButtons >
          <div className="">
            <button className="btn generic-button-buscador" type="button" onClick={handleDownloadExcel} disabled={!procesosSolicitud.procesoSelected} title={procesosSolicitud.procesoSelected ? "Descargar" : "Seleccionar proceso para descargar"}>
                Descargar Excel
            </button>
          </div>
          <NavDropdown className="generic-button-dropdown font-gothan-light" title={<span className='generic-button-dropdown-title'>Acciones</span>}>
            <NavDropdown.Item className='font-gothan-light' onClick={() => setModalEnabled({ isEnable: true, component: CrearProcesoModal, data: { getProcesos } })}>Crear Proceso</NavDropdown.Item>
            {!(procesosSolicitud.procesoSelected === null || procesosSolicitud.procesoSelected === undefined) &&
              <>
                <NavDropdown.Item className='font-gothan-light' onClick={() => setModalEnabled({ isEnable: true, component: EditarProcesoModal, data: { getProcesos, procesosSolicitud: procesosSolicitud.procesoSelected } })}>Editar Proceso</NavDropdown.Item>
                <NavDropdown.Item className='font-gothan-light' onClick={() => setModalEnabled({ isEnable: true, component: ConfigurarProcesoModal, data: { IdProcesoSolicitudTaiLoy: procesosSolicitud.procesoSelected.IdProcesoSolicitudTaiLoy } })}>Configurar Proceso</NavDropdown.Item>
              </>
            }
          </NavDropdown>
        </PageButtons>
      </SearchContainer>
      <div className="container-fluid body-content">
        <div className="table-scroll pt-3">
          <table className="table table-hover">
            <thead className="listar-thead">
              <tr className="font-gothan-medium">
                <th className='text-center col-sm-1'>DNI</th>
                <th className='text-center col-sm-3'>Nombre</th>
                <th className='text-center col-sm-3'>
                  <div className='d-flex justify-content-center'>    
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.Cargos)}
                      description="Cargo"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.Cargos] ?? []}
                      setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.Cargos)}
                    />
                  </div>
                </th>
                <th className='text-center col-sm-3'>
                  <div className='d-flex justify-content-center'>
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.Sedes)}
                      description="Sede"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.Sedes] ?? []}
                      setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.Sedes)}
                    />
                  </div>
                </th>
                <th className='text-center col-sm-2'>Fecha Solicitud</th>
                <th className='text-center'>Vale</th>
                <th className='text-center'>
                  <div className='d-flex justify-content-center'>
                    <FormSelect 
                      options={handleFind(formValues.filters, INIT_KEYS.EstadoSolicitud)}
                      description="Estado de Solicitud"
                      selectedOptions={formValues.selectedFilters?.[INIT_KEYS.EstadoSolicitud] ?? []}
                      setSelectedOptions={(res) => handleFilterSelected(res, INIT_KEYS.EstadoSolicitud)}
                    />
                  </div>
                </th>
                <th className='text-center'>Acciones</th>
              </tr>
            </thead>
            <TableWebBody filas={formValues.Items} setModalEnabled={setModalEnabled}/>
          </table>
          <PaginatorV2 paginator={paginator} setPaginator={setPaginator} />
        </div>
      </div>
    </PageWrapper>
  )
}

export default GestionSolicitudValeDescuento;

const SelectProcesoSolicitud = ({ procesosSolicitud, setProcesosSolicitud }) => (
  <div className='col-sm-4 mt-2 ml-2'>
    <Select
      options={procesosSolicitud.IdsProcesoSolicitud}
      value={procesosSolicitud.procesoSelected}
      onChange={(res) => setProcesosSolicitud(lastData => ({...lastData, procesoSelected: res}))}
      placeholder="Seleccionar proceso"
    />
  </div>
)