import React, {useContext} from 'react';
import styled from 'styled-components';
import {Link, useHistory, withRouter} from 'react-router-dom';
import {AuthContext} from '../../hooks/context/authContext';
import ArrowRight from '../icons/ArrowRight';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { tieneAccesoAMenu } from '../../pages/router/permisosUtils';
import { ADMIN_GESTION_CUPONERA, COVID_ICON, DIRECTORIO_ICON, MENU_MIS_SOLICITUDES, MIS_DATOS_ICON, MIS_OPCIONES_ICON, MI_EQUIPO_ICON, MI_TABLERO_ICON, REPORTES_ICON } from '../../constants/hardCodedConstants';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    textColor: {
        color: '#797979'
    }
  }));

const SidebarMenu = styled.ul`
  display: none;
 
  @media (max-width: 767px) {
    height: 100%;
    width: 40%;
    display: block;
    right: ${({active}) => (active ? '0px !important' : '-40% !important')};
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    background: #ffffff;
    overflow-x: hidden;
    transition: all 1s ease;
    padding: 0;
  }
  
   @media (max-width: 550px) {
    width: 55%;
    right: ${({active}) => (active ? '0px !important' : '-55% !important')};
  }
  
  @media (max-width: 390px) {
    width: 70%;
    right: ${({active}) => (active ? '0px !important' : '-70% !important')};
  }
  

  a {
    color: #797979;
    i,
    svg {
      color: #0063a1;
    }
  }
  .header-info {
    height: 67px;
    background: linear-gradient(180deg, #0063a1 0%, #4c87c3 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    color: #ffffff;
    img {
      height: 55px;
      width: 55px;
      border-radius: 50%;
    }
  }
  .body-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 80px);

    .mobile-footer-icons{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        display: flex;
        text-align: left;
        align-items: center;
        gap: 8px;
        margin-left: 5px;
    }
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Arrow = () => (
    <svg
        width='6'
        height='10'
        viewBox='0 0 6 10'
        fill='none'
        >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0.120378 9.84628C-0.0526941 9.65572 -0.0373159 9.36227 0.154727 9.19084L4.84624 5.00293L0.154727 0.81502C-0.0373162 0.643591 -0.0526945 0.350141 0.120378 0.159578C0.29345 -0.0309839 0.589435 -0.0464955 0.781478 0.124932L5.84004 4.64049C5.94735 4.73628 5.9995 4.87017 5.99443 5.00293C5.9995 5.13568 5.94735 5.26958 5.84004 5.36537L0.781478 9.88093C0.589435 10.0524 0.293451 10.0368 0.120378 9.84628Z'
            fill='#0063A1'
        />
    </svg>
);
const Sidebar = (props) => {
    const {state, dispatch} = useContext(AuthContext);
    let history = useHistory();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const route = props.history.location.pathname;
    const logout = () => {
        history.push('/login');
        dispatch({
            type: 'LOGOUT',
        });
    };
    return (
        <SidebarMenu active={props.active} isMobile={props.isMobile}>
            <div className='header-info' onClick={() => props.close()}>
                <img src={state.user.photo} alt=''/>
                <div>{state.user.name}</div>
                <div>
                    <ArrowRight/>
                </div>
            </div>
            <div className='body-info'>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    className={classes.root}
                >
                    <ListItem 
                        button 
                        component={Link}
                        onClick={() => props.close()}
                        to='/'
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={MI_TABLERO_ICON} alt="Mi Tablero" className='h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Mi Tablero" />
                    </ListItem>
                    <ListItem 
                        button 
                        component={Link}
                        onClick={() => props.close()}
                        to='/directorio'
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={DIRECTORIO_ICON} alt="Directorio" className='blue_innova_text_filter h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Directorio" />
                    </ListItem>
                    <ListItem 
                        button 
                        component={Link}
                        onClick={() => props.close()}
                        to='/fichadedatos/'
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={MIS_DATOS_ICON} alt="Mis Datos" className='blue_innova_text_filter h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Mis Datos" />
                    </ListItem>
                    <ListItem 
                        button 
                        component={Link}
                        onClick={() => props.close()}
                        to='/miequipo'
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={MI_EQUIPO_ICON} alt="Mi Equipo" className='blue_innova_text_filter h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Mi Equipo" />
                    </ListItem>
                    <ListItem 
                        button 
                        component={Link}
                        onClick={() => props.close()}
                        to='/reportes'
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={REPORTES_ICON} alt="Reportes" className='blue_innova_text_filter h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Reportes" />
                    </ListItem>

                    {tieneAccesoAMenu(state?.menusAcceso, ADMIN_GESTION_CUPONERA)
                        ? <ListItem 
                            button 
                            component={Link}
                            onClick={() => props.close()}
                            to='/adminCuponeraTiempoLibre'
                            >
                            <ListItemIcon className='mobile-header-icons mr-2 d-flex justify-content-center'>
                                <FontAwesomeIcon
                                    icon={faTicketAlt}
                                    title="Cupón tiempo libre"
                                    size='lg'
                                />
                            </ListItemIcon>
                            <ListItemText primary="Gestión de Solicitudes de Cupones de tiempo libre" className={classes.textColor} />
                            </ListItem>
                        : <></>
                    }

                    <ListItem 
                        button 
                        onClick={handleClick}
                    >
                        <ListItemIcon className='mobile-header-icons mr-2'>
                            <img src={MIS_OPCIONES_ICON} alt="Mis Opciones" className='blue_innova_text_filter h-100'/>
                        </ListItemIcon>
                        <ListItemText primary="Mis Opciones" className={classes.textColor}/>
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem 
                                button
                                onClick={() => {
                                    props.close(); 
                                    props.toggleFichaSintomatologicoModal();
                                }}
                                className={classes.nested}
                            >
                                <ListItemIcon className='mobile-header-icons mr-2'>
                                    <img src={COVID_ICON} alt="Ficha sintomatológica" className='blue_innova_text_filter h-100'/>
                                </ListItemIcon>
                                <ListItemText primary="Ficha sintomatológica" className={classes.textColor} />
                            </ListItem>

                            {tieneAccesoAMenu(state?.menusAcceso, MENU_MIS_SOLICITUDES.childMenuIds.MisCuponesTiempoLibre)
                                ? <ListItem 
                                    button 
                                    className={classes.nested}
                                    onClick={() => props.close()}
                                    component={Link}
                                    to='/misCuponesTiempoLibre'
                                >
                                    <ListItemIcon className='mobile-header-icons mr-2 d-flex justify-content-center'>
                                        <FontAwesomeIcon
                                            icon={faTicketAlt}
                                            title="Cupón tiempo libre"
                                            size='lg'
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Mis Cupones de tiempo libre" className={classes.textColor} />
                                </ListItem>
                                : <></>
                            }
                        </List>
                    </Collapse>
                </List>
                <div>
                    <Link
                        onClick={() => props.close()}
                        to='/reportes'
                        className={`nav-item nav-link mobile-footer-icons`}>
                        <FontAwesomeIcon icon='bell'/>

                        <Flex>
                            <div>Notificaciones</div>
                            <Arrow/>
                        </Flex>
                    </Link>
                    <Link
                        onClick={() => logout()}
                        to='/reportes'
                        className={`nav-item nav-link mobile-footer-icons`}>
                        <FontAwesomeIcon icon='sign-out-alt'/>

                        <Flex>
                            <div>Cerrar sesión</div>
                            <Arrow/>
                        </Flex>
                    </Link>
                </div>
            </div>
        </SidebarMenu>
    );
};

export default withRouter(Sidebar);
