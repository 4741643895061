import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import SpinnerLoading from '../../../components/pageComponents/SpinnerLoading'
import { CuponCard, CuponImage, CuponWrapper, CuponeraStatistics } from '../components/StyledComponents'

const TableListaCuponeras = ({ rows, isFetching }) => {

  const history = useHistory();

  const handleRedirect = (idCuponera) => {
    const url = `/misCuponesTiempoLibre/crearSolicitud?search=${idCuponera}`;
    history.push(url)
  }

  if (isFetching) return <div className='d-flex' style={{ height: "60vh" }}><SpinnerLoading /></div>

  return (
    <CuponWrapper>
      {rows.map((row, i) => (
        <div key={`${row.Id}-${i}`} className="position-relative">
          <Card isActive={row.EsVigente} onClick={row.EsVigente && (() => handleRedirect(row.IdCuponeraTiempoLibre))}>
            <CuponeraStatistics>
              <div className="info">
                <p># Cupones disponibles:</p>
                <span>{row.NroCuponesDisponibles ?? "-"}</span>
              </div>
              <div className="info">
                <p># Cupones utilizados:</p>
                <span>{row.NroCuponesUtilizados ?? "-"}</span>
              </div>
            </CuponeraStatistics>
            <div className='inner-content'>
              <Image src={row.LinkImagenCuponera} loading='lazy'/>
            </div>
          </Card>
        </div>
      ))}
    </CuponWrapper>
  )
}

export default TableListaCuponeras

const Card = styled(CuponCard)`
  
  ${({ isActive }) => isActive && css `
    cursor: pointer;
    &:hover {
      .inner-content {
        transform: scale(1.05);
      }
    }
  `
  }

  .inner-content {
    filter: ${({isActive}) => isActive ? "" : "grayscale(100%)"};
  }
`;

const Image = styled(CuponImage)`
  object-position: initial;
`