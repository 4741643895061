import React from "react";
import GenericModal from "../../../components/modal/GenericModal";

const MensajeConfirmacionModal = ({ showModal, handleSend, dataToSend,isSending }) => {
  return (
    <GenericModal showModal={showModal} width="500px" titulo={`Confirmación`}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a completar la {dataToSend?.ActivityTitle}:
          <ul>
            <li>
              <strong>{dataToSend?.Activity}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={showModal}
            disabled={isSending}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={handleSend}
            disabled={isSending}
          >
            Confirmar
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default MensajeConfirmacionModal;
