import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadFile } from "../../consumers/backendConsumer";
import { AuthContext } from "../../hooks/context/authContext";

const TableWebBody = ({ filas, toggleDetalleEncuestaModal, eliminarEncuesta }) => {
    const [filass, setFilass] = useState([]);
    const { state } = useContext(AuthContext);

    useEffect(() => {
        setFilass(filas)
    }, [filas]);

    const descargarArchivo = (e, archivo) => {
        e.preventDefault()
        downloadFile(state.token, archivo.Path, archivo.OriginalFileName);
    }

    return (
        <tbody className="listar-tbody">
            {filass.map((fila, i) => (
                <tr key={i}>
                    <td>{fila?.FechaRegistro}</td>
                    <td>
                        <div className="d-flex align-items-center" >
                            <img
                                style={{ borderRadius: '50%', width: '25px' }}
                                src={fila?.ProfileImageUrl}
                                alt=''
                            />
                            <div style={{ margin: '0 10px' }}>{fila.NombreCompleto}</div>
                        </div>
                    </td>
                    <td>{fila?.Cargo}</td>
                    {/*<td>{fila?.NombreSede}</td>*/}
                    <td>{fila?.NombreDivision}</td>
                    <td>{fila?.NombreGerencia}</td>
                    <td>{fila?.NombreArea}</td>
                    <td>{fila?.TieneCondicionRiesgo}</td>
                    <td>
                    <div className="d-flex justify-content-start">
                        {
                            fila?.CertificadoColaborador &&
                            <div>
                                <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer ml-3" title="Certificado del colaborador" onClick={(e) => descargarArchivo(e, fila.CertificadoColaborador)}/>
                            </div>
                        }
                        {
                            fila?.CertificadoPersonaACargo &&
                            <div>
                                <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer ml-3" title="Certificado del familiar" onClick={(e) => descargarArchivo(e, fila.CertificadoPersonaACargo)}/>
                            </div>
                        }
                        {
                            fila?.SustentoPersonaACargo &&
                            <div>
                                <FontAwesomeIcon icon='paperclip' size="lg" className="hand-pointer ml-3" title="Sustento sobre el familiar a cargo" onClick={(e) => descargarArchivo(e, fila.SustentoPersonaACargo)}/>
                            </div>
                        }
                        </div>
                    </td>
                    <td>
                        {fila?.Estado}
                    </td>
                    <td>
                        <div className="d-flex justify-content-start">
                            <div>
                                <FontAwesomeIcon icon='table' size="lg" className="hand-pointer ml-3" title="Ver detalle" onClick={() => toggleDetalleEncuestaModal(fila?.IdEncuestaSaludDetalle)} />
                            </div>
                            <div>
                                <FontAwesomeIcon icon='times' size="lg" className="hand-pointer ml-3" title="Eliminar" onClick={() => eliminarEncuesta(fila?.IdEncuestaSaludDetalle)} />
                            </div>

                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    );
};

export default TableWebBody;
