import React from 'react';
import GenericModal from '../../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest';
import { eliminarFormacionProfesionalAPI } from '../../../../consumers/backendApisUrls';

const EliminarFormacionProfesional = ({toggleModal, setSubmitExecute, formacionProfesional, setFormacionProfesional}) => {

    const [manageExecutePost] = useManagePostRequest();

    const handleDeleteFormacionProfesional = async (e) => {
        e.preventDefault();
        let api = eliminarFormacionProfesionalAPI(formacionProfesional.IdEmpleadoFormacionProfesional);
        await manageExecutePost(api, {}, successDeleteCallBack)
    }

    const successDeleteCallBack = () => {
        setSubmitExecute(true);
        toggleModal();
        setFormacionProfesional({});
    }

  return (
    <GenericModal titulo={"Eliminar"} showModal={ toggleModal } width={"650px"}>
        <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
            <p> Se va a eliminar la Formación Profesional {formacionProfesional?.IsSelected && 'de "Mayor grado de instrucción"'}<strong>{formacionProfesional.GradoObtenido}</strong> en <strong>{formacionProfesional.NombreCarrera}</strong> </p>

            {formacionProfesional?.IsSelected === true &&
                <p>Luego de eliminarlo, no se olvide de asignar la estrella de "Mayor grado de instrucción" a la Formación Profesional correspondiente.</p>
            }
            <p>¿Está seguro de que desea eliminar esta Formación Profesional?</p>

        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end pt-0 pb-0" >
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={handleDeleteFormacionProfesional}
            >
            Eliminar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => { toggleModal() }}
            >
            Cancelar
          </button>
        </div>
      </div>
    </GenericModal>
  )
}

export default EliminarFormacionProfesional
