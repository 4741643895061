import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AsignarCupones from './modales/AsignarCupones'
import EditarCuponAsignado from './modales/EditarCuponAsignado'
import PageButtons from '../../../../components/pageComponents/PageButtons'
import PaginatorV2 from '../../../../components/pageComponents/PaginatorV2'
import ModalEnabled from '../../../../components/modalComponents/ModalEnabled'
import SpinnerLoading from '../../../../components/pageComponents/SpinnerLoading'
import SearchContainer from '../../../../components/pageComponents/SearchContainer'
import ActionGenericModal from '../../../../components/modal/ActionGenericModal'
import { useGetOptionsSelect } from '../../../../hooks/useGetOptionsSelect/useGetOptionsSelect'
import { useManagePostRequest } from '../../../../hooks/useManagePostRequest/useManagePostRequest'
import { initialAllOptionPromises } from '../../../../helper/utils'
import { eliminarCuponTiempoLibreAPI, getAllCargosAPI, getCuponesByIdCuponeraAPI, inactivarActivarCuponTiempoLibreAPI } from '../../../../consumers/backendApisUrls'
import { CuponCard, CuponCodigo, CuponImage, CuponLineamiento, CuponWrapper, ListaCuponesWrapper } from '../../components/StyledComponents'

const ListaCuponesAsignados = ({ IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera }) => {

  const INIT_KEYS = {Cargos: "IdsCargos"};

  const [formValues, setFormValues] = useState([]);
  const [allDropdowns, setAllDropdowns] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeManagePost] = useManagePostRequest();
  const [getOptions] = useGetOptionsSelect();

  const getInitial = () => {
    return [
      getOptions(getAllCargosAPI()),
    ]
  }

  const init = async () => {
    initialAllOptionPromises(getInitial, Object.values(INIT_KEYS), setAllDropdowns);
    await manageSearch();
  }

  const manageSearch = async () => {
    setIsLoading(true);
    const api = getCuponesByIdCuponeraAPI();
    const payload = getPayload();
    try {
      await executeManagePost(api, payload, successManageSearchCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getPayload = () => {
    return {
      IdCuponeraTiempoLibre,
      Page: paginator.CurrentPage,
      Criteria: searchCriteria,
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    const ITemsUpdated = Items.map(o => {
      const { PlantillaCuponRequerimientos, IdsCargos } = o;
      const RequerimientosArray = PlantillaCuponRequerimientos.split(",");
      const IdsCargosArray = IdsCargos.split(",")?.map(o => parseInt(o));
      return {...o, Requerimientos: RequerimientosArray, IdsCargos: IdsCargosArray};
    })
    setFormValues(ITemsUpdated);
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleActiveInactiveCupon = (row) => {
    const api = inactivarActivarCuponTiempoLibreAPI;
    const payload = {IdCuponTiempoLibre: row.IdCuponTiempoLibre};
    setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { title: row.EstadoRegistroCupon ? "Inactivar" : "Activar", actionLabel:  row.EstadoRegistroCupon ? "inactivar" : "activar", api, payload, name: row.PlantillaCuponNombre}})
  }

  useEffect(() => {
    if (IdCuponeraTiempoLibre !== undefined && IdCuponeraTiempoLibre !== null) init();
  }, [IdCuponeraTiempoLibre])

  return (
    <ListaCuponesWrap className='custom-scroll'>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <div className='cupon-search-container'>
        <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
          <PageButtons hasBackButton hasCreateButton labelCreate='Asignar Cupón' handleCreateButton={() => setModalEnabled({ isEnable: true, component: AsignarCupones, data: { IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera }})}/>
        </SearchContainer>
      </div>
      {!formValues.length
        ? <p className='blue-standard-color text-center mt-5'>Cuponera no cuenta con cupones asignados</p>
        : <TableCuponesAsignados rows={formValues} dropdowns={allDropdowns} handleActiveInactiveCupon={handleActiveInactiveCupon} setModalEnabled={setModalEnabled} isLoading={isLoading}/>
      }
      <div className='px-5 mb-4'><PaginatorV2 paginator={paginator} setPaginator={setPaginator} manageSearch={manageSearch}/></div>
    </ListaCuponesWrap>
  )
}

export default ListaCuponesAsignados;

const TableCuponesAsignados = ({ rows, dropdowns, handleActiveInactiveCupon, setModalEnabled, isLoading }) => {

  if (isLoading) return <div className='h-100 d-flex'><SpinnerLoading /></div>

  return (
    <Wrapper>
      {rows.map((row, i) => (
        <Card key={row.IdCuponTiempoLibre} isActive={row.EstadoRegistroCupon} isRequirementsVisible={row.isRequirementsVisible ?? false}>
          <div className='inner-content'>
            <CuponCodigo>{row.CodigoCupon}</CuponCodigo>
            <CuponImage src={row.PlantillaImagenCuponTiempoLibreURL} loading='lazy'/>
            <div className="cupon_text_overlay">
              <div className='overlay_header'>Ver Lineamientos</div>
              <div className='overlay_body'>
                <CuponLineamiento dangerouslySetInnerHTML={{ __html: row.LineamientosHTML }} />
              </div>
            </div>
          </div>
          <div className="icons">
            {row.EstadoRegistroCupon &&
              <FontAwesomeIcon icon="edit" title='Editar Cupón' size='4x' className='hand-pointer' onClick={() => setModalEnabled({ isEnable: true, component: EditarCuponAsignado, data: { row, dropdowns }})}/>
            }
            <FontAwesomeIcon icon={row.EstadoRegistroCupon ? 'ban' : 'circle-notch'} title={row.EstadoRegistroCupon  ? 'Inactivar' : 'Activar'} size='4x'  className='hand-pointer' onClick={() => handleActiveInactiveCupon(row)}/>
            <FontAwesomeIcon icon="trash" title='Eliminar' size='4x' className='hand-pointer' onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { api: eliminarCuponTiempoLibreAPI, id: row.IdCuponTiempoLibre, name: `${row.PlantillaCuponNombre} - ${row.CodigoCupon}` }})}/>
          </div>
        </Card>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled(CuponWrapper)`
  margin: 20px;
`;

const Card = styled(CuponCard)`
  transition: all 0.5s ease;
  .icons {
    width: 60%;
    left: 0;
  }
  .inner-content {
    filter: ${({isActive}) => isActive ? "" : "grayscale(100%)"};
  }

  &:hover {
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.3);
  }

  @media ( 1300px < width < 1470px){
    .icons {
      width: calc(60% + 20 * ((100vw - 1470px) / 100));
      gap: 30px;
    }
  }

  @media (max-width: 1300px){
    .icons {
      flex-direction: column;
      gap: 30px;
      width: calc(60% + 20 * ((100vw - 1470px) / 100));
      padding-left: 10px;
    }
  }
`;

const ListaCuponesWrap = styled(ListaCuponesWrapper)`
  .cupon-search-container {
    &>div {
      margin-top: 0;
    }
  }
`