import React, {useState} from "react";
import GenericModal from "../../../../components/modal/GenericModal";
import { useManagePostRequest } from "../../../../hooks/useManagePostRequest/useManagePostRequest";
import { cambiarPermitirVacacionesAPI } from "../../../../consumers/backendApisUrls";

const MensajeConfirmacionModal = ({ showModal, empleado, manageSearch}) => {

  const [isSending, setIsSending] = useState(false);

  const [executePost] = useManagePostRequest();

  const handleSend = async () => {
    setIsSending(true);
    try {
      const api = cambiarPermitirVacacionesAPI(empleado?.IdEmpleado);
      executePost(api, {}, successSendCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSending(false);
  }

  const successSendCallback = () => {
    manageSearch();
    showModal();
  }

  return (
    <GenericModal showModal={showModal} width="500px" titulo={`Confirmación`}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {`${empleado?.PuedeSolicitar ? "quitar" : "otorgar"}`} permisos de vacaciones a:
          <ul>
            <li>
              <strong>{empleado?.NombreCompleto}</strong>
            </li>
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={() => showModal()}
            disabled={isSending}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={(e) => handleSend(e)}
            disabled={isSending}
          >
            Confirmar
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default MensajeConfirmacionModal;
