import React from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';

const ModalConfirmarEditar = ({ showModal, manageSearch, idCese, setConfirmarEdit, datosConfirmar, setDatosConfirmar, setEstadoCeseRegistro, estadoCeseRegistro }) => {
  const [submitForm] = useSubmitForm();
  const mostrarModalInformarRenuncia = function () {
    showModal();
    manageSearch();
  }

  const confirmarEdicion = async() => {
    setConfirmarEdit(true);
    const {keys, api, payload, sectionName, successSubmitCallback}  = datosConfirmar;
    await submitForm(keys, api, payload, sectionName, successSubmitCallback);
    showModal();
    setDatosConfirmar(null);
    setEstadoCeseRegistro(null);
    manageSearch();
  }

  return (
    <>
      <GenericModal showModal={showModal} width={"750px"} titulo={"Confirmar"}>
        <div className="modal-body">
          <div className="font-gothan-light">
            {estadoCeseRegistro === "PROCESO"? 
              <p>Cuando se apruebe la renuncia, se cesará inmediatamente al colaborador ya que la fecha de cese es menor a la fecha de hoy</p>
              :<p>Se procederá a cesar al colaborador ya que la fecha de cese es menor a la fecha de hoy</p>}
          </div>
        </div>
        <div className="modal-footer d-flex  justify-content-end">
          <button
            type="button"
            className="btn btn-primary generic-button-ficha-modal font-gothan-light"
            onClick={()=> {setConfirmarEdit(false); showModal()}}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="btn btn-primary generic-button-ficha-modal font-gothan-light"
            onClick={()=> confirmarEdicion()}
          >
            Entendido
          </button>
        </div>
      </GenericModal>
    </>
  );
};

export default ModalConfirmarEditar;

