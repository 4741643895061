import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { initialAllOptionPromises } from '../../../../../helper/utils';
import { useGetOptionsSelect } from '../../../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { useManageGetRequest } from '../../../../../hooks/useManageGetRequest/useManageGetRequest';
import { usePropsSelect } from '../../../../../hooks/useProps/usePropsSelect';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import InputText from '../../../../../components/modalComponents/InputText';
import InputBool from '../../../../../components/modalComponents/InputBool';
import FooterModal from '../../../../../components/modalComponents/FooterModal';
import InputSelect from '../../../../../components/modalComponents/InputSelect';
import CardAccordion from '../../../../../components/pageComponents/CardAccordion';
import GenericTooltip from '../../../../../components/GenericTooltip/GenericTooltip';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import InputUploadFile from '../../../../../components/modalComponents/InputUploadFile';
import { BANCOS, ESTADOS_YES_NO, INPUT_DIRECTION, KEYS_INFORMACION_ECONOMICA_NI, MONEDAS, KEYS_CARD_NUEVOS_INGRESOS as OPTIONS, SISTEMAS_PENSIONARIOS, TIPOS_EMPLEADO } from '../../../../../constants/hardCodedConstants';
import { getBancosAPI, getInformacionEconomicaGestionAPI, getMonedasSDAPI, getSistemaPensionesAPI, saveInformacionEconomicaAPI } from '../../../../../consumers/backendApisUrls';

const DatosInformacionEconomica = ({ idNuevosIngresosEmpleado, idParent: ID_PARENT, inputDisabled, isColaborador, manageSearchListaDocumentos, sendCurrentStep, setSendCurrentStep, commonStates, componentStep }) => {

  const NUMERO_CUSPP_PLACEHOLDER = "Si estas afiliado a una AFP ingresa tu número CUSPP";
  const URL_CUPSS = "https://servicios.sbs.gob.pe/ReporteSituacionPrevisional/Afil_Consulta.aspx";
  const SECTION_NAME = "EditarDatosInformacionEconomicaFichaNuevosIngresos";
  const KEYS_INIT = {FondoPensiones: "IdFondoPensiones", BancoSueldo: "IdBancoSueldo", MonedaCts: "IdMonedaCts", BancoCts: "IdBancoCts"};

  const [allDropdowns, setAllDropdowns] = useState([]);
  const [formValues, setFormValues] = useState({ AceptaDj: false, TieneQuintaCategoria: false });

  const [isLoading, setIsLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [isInterbank, setIsInterbank] = useState(false);

  const [handleChange] = useForm(formValues, setFormValues);
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();
  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();

  const allGetPromises = () => {
    return [
      getOptions(getSistemaPensionesAPI()),
      getOptions(getBancosAPI()),
      getOptions(getMonedasSDAPI()),
      getOptions(getBancosAPI()),
    ]
  }

  const init = async () => {
    try {
      await manageSearch();
      initialAllOptionPromises(allGetPromises, Object.values(KEYS_INIT), successInitCallback);
    } catch (error) {
      console.error(error);
    }
  }

  const successInitCallback = (res) => {
    let dropdowns = [...res]
    if(isColaborador){
      dropdowns = dropdowns.map(r =>{
        if(r.key === KEYS_INIT.BancoSueldo){
          const array = r.value
           return { ...r , value : array.filter( res => res.value !== BANCOS.NOAPLICA)}
        } 
          return r
      })
    }
    setAllDropdowns(dropdowns);
    setIsLoading(false);
   
   

  }

  const manageSearch = async () => {
    const api = getInformacionEconomicaGestionAPI(idNuevosIngresosEmpleado);
    await executeGet(api, handleSuccessSearch);
  }

  const handleSuccessSearch = ({ data }) => {
    const TieneQuintaCategoria = data.TieneQuintaCategoria ?? false;
    setFormValues(lastData => ({...lastData, ...data, TieneQuintaCategoria}));
  }

  const handleSubmit = async (e) => {
    if(e) e.preventDefault();
    setIsSending(true);
    try {
      const api = saveInformacionEconomicaAPI();
      const payload = {...formValues, IdMonedaSueldo: MONEDAS.SOLES};
      await submitForm(KEYS_INFORMACION_ECONOMICA_NI, api, payload, SECTION_NAME, successSubmitCallback, !isColaborador, () => toast.error("Faltan completar datos"));
    } catch (error) {
      console.error(error);
    } finally{
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    manageSearch();
    manageSearchListaDocumentos();
    if(isColaborador) setSendCurrentStep(lastData => ({...lastData, isSent: false, isSaved: true}));
  }

  const isPensionAFP = () => {
    return allDropdowns?.find(o => o.key === "IdFondoPensiones")?.value?.find(o => o.value === formValues.IdFondoPensiones)?.label?.includes("AFP");
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, Sustento: res}));
  }

  const handleUpdateYesNo = ({ target }) => {
    let { name, value: res } = target;
    setFormValues(lastData => ({...lastData, [name]: res?.value}));
  }

  useDidMountEffect(() => {
    setIsInterbank(formValues.IdBancoSueldo === BANCOS.INTERBANK);
  }, [formValues.IdBancoSueldo])

  useDidMountEffect(async () => {
    if (isColaborador && sendCurrentStep?.step === componentStep && sendCurrentStep?.isSent){
      await handleSubmit();
    }
  }, [sendCurrentStep])

  useDidMountEffect(() => {
    if (commonStates?.IdTipoEmpleado === TIPOS_EMPLEADO.PRACTICANTE){
      setFormValues(lastData => ({...lastData, IdFondoPensiones: SISTEMAS_PENSIONARIOS.SIN_REGISTRO_PENSIONARIO}));
    }
  }, [commonStates?.IdTipoEmpleado])

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading/>
  return (
    <form onSubmit={handleSubmit} className={`${isColaborador ? "form-colaborador-inputs" : ""}`}>
      <CardAccordion id={OPTIONS.idInformacionEconomica} idParent={ID_PARENT} label="6. Información Económica" isShow isShowGeneral={!isColaborador}>
        <div className="displayFlexWrap mt-4">
          <div className='col-sm-12 d-flex flex-wrap'>
            <DivCol required={!(inputDisabled || (commonStates?.IdTipoEmpleado === TIPOS_EMPLEADO.PRACTICANTE))}><InputSelect label="Tipo de Sistema Pensionario" {...commonPropsSelect("IdFondoPensiones", ["Cuspp"])} direction='column' isDisabled={inputDisabled || (commonStates?.IdTipoEmpleado === TIPOS_EMPLEADO.PRACTICANTE)}/></DivCol>
            <DivCol><InputTextCuspp label="Número CUSPP" url={URL_CUPSS} placeholder={NUMERO_CUSPP_PLACEHOLDER} {...commonProps("Cuspp")} isDisabled={!isPensionAFP() || inputDisabled} direction='column'/></DivCol>
          </div>
          <div className='col-sm-12 d-flex flex-wrap mt-2'>
            <DivCol required={!isColaborador}><InputSelect label="Banco sueldo" {...commonPropsSelect("IdBancoSueldo", isInterbank ? ["NumeroCuentaSueldo", "Sustento"] : [])} direction='column' isDisabled={inputDisabled}/></DivCol>
            {!isInterbank &&
              <DivCol required={formValues[KEYS_INIT.BancoSueldo] && !isInterbank}><InputText label={labelTooltip("sustento_info", "Número cuenta sueldo", tooltipSustento(), false, !isInterbank)} {...commonProps("NumeroCuentaSueldo")} direction='column' placeholder={`${isInterbank ? "Ingrese Número de cuenta" : "Ingrese CCI"}`} isDisabled={inputDisabled}/></DivCol>
            }
            {!isInterbank &&
              <DivCol isInterbank={isInterbank}><InputUploadFile label={tooltipArchivoSustento()} uploadFile={formValues.Sustento} setUploadFile={handleUploadFile} id="uploadSustentoInformacionFianciera" validExtensions={["pdf", "png", "jpg"]} sectionName={SECTION_NAME} name="Sustento" direction='column' isIcon isDisabled={inputDisabled}/></DivCol>
            }
          </div>
          {!isColaborador &&
            <div className='col-sm-12 d-flex flex-wrap'>
              <DivCol ><InputSelect label="Banco CTS" {...commonPropsSelect("IdBancoCts")} direction='column' isDisabled={inputDisabled}/></DivCol>
              <DivCol ><InputText label="Número cuenta CTS" {...commonProps("CuentaCts")} direction='column' isDisabled={inputDisabled}/></DivCol>
              <DivCol ><InputSelect label="Moneda CTS" {...commonPropsSelect("IdMonedaCts")} direction='column' isDisabled={inputDisabled}/></DivCol>
            </div>
          }
          <div className='col-sm-12 pt-2 d-flex flex-wrap'>
            {/* <DivCol ><InputSelect label={labelTooltip("asignacionFamiliar_info", "Asignación Familiar", tooltipAsignacionFamiliar(), true)} name='AsignacionFamiliar' options={ESTADOS_YES_NO} value={ESTADOS_YES_NO.find(o => o.value === formValues.AsignacionFamiliar)} onChange={handleUpdateYesNo} isDisabled={inputDisabled || (commonStates?.IdTipoEmpleado === TIPOS_EMPLEADO.PRACTICANTE)} direction="column" sectionName={SECTION_NAME}/></DivCol> */}
            <DivCol required><InputSelect label="¿Percibe ingresos actualmente de otro empleador?" name='IngresosOtroEmpleador' options={ESTADOS_YES_NO} value={ESTADOS_YES_NO.find(o => o.value === formValues.IngresosOtroEmpleador)} onChange={handleUpdateYesNo} isDisabled={inputDisabled} direction="column" sectionName={SECTION_NAME}/></DivCol>
            <DivCol required><InputBool label="¿Cuenta con Certificado de 5ta Categoría?" textInput="No / Sí" {...commonProps("TieneQuintaCategoria")} colLabel={6} colInput={6} isDisabled={inputDisabled}/></DivCol>
          </div>
          <div className='col-sm-12 pt-2 d-flex flex-wrap'>
            <DivCol required><InputSelect label="¿Tiene hijos?" name='TieneHijos' options={ESTADOS_YES_NO} value={ESTADOS_YES_NO.find(o => o.value === formValues.TieneHijos)} onChange={handleUpdateYesNo} isDisabled={inputDisabled} direction="column" sectionName={SECTION_NAME}/></DivCol>
          </div>
          {isColaborador &&
            <NotaCTS />
          }
        </div>
        {(!inputDisabled && !isColaborador) && <FooterModal isDisabled={isSending}/>}
      </CardAccordion>
    </form>
  )
}

export default DatosInformacionEconomica;

const DivCol = ({ children, required }) => {
  return (
    <div className={`col-md-4 ${required ? "div-label-required" : ""}`}>
      {children}
    </div>
  )
}

const labelTooltip = (id, label, tooltipText, isRequired, isShowToolTip=true) => (
  <div className={`d-flex ${isRequired ? "div-label-required" : ""}`}>
    <div>
      <label className='mb-0'>{label}</label>
    </div>
    {isShowToolTip && 
      <div className='ml-1' style={{right: "0"}}>
          <p data-tip data-for={id} className="d-inline">
            <FontAwesomeIcon icon='info-circle'className="hand-pointer blue-standard-color ml-1 mr-1"/>
          </p>
          <ReactTooltip id={id} place="top" effect="solid" className="tooltip-style tooltip-change-witdh tooltip-with-buttons" 
            getContent={() => (tooltipText)}
          />
      </div>
    }
  </div>
)

const InputTextCuspp = ({
  label,
  name,
  value,
  onChange,
  onBlur,
  sectionName,
  isRequired,
  isDisabled,
  direction = "row",
  placeholder = `Ingresar ${label}`,
  url: URL_CUPSS
}) => {
  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <div className='input-form-column flex-row justify-content-between mb-0'>
        <label className='mb-0 modal-label'>{label}</label>
        <ButtonCUSSP>
          <span className='cussp-label'>Consulta tu CUSPP</span>
          <button className='cussp-button' type='button' onClick={() => window.open(URL_CUPSS)}>
              Aquí
          </button>
        </ButtonCUSSP>
      </div>
      <div className={`black-color ${value ? "" : "highlight-input"}`}>
        <input
          type="text"
          placeholder={placeholder}
          className="form-control"
          name={name}
          onChange={onChange}
          value={value ?? ""}
          disabled={isDisabled}
          onBlur={onBlur}
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

const tooltipSustento = () => (
  <div>
    <span>Asegurate de registrar correctamento la cuenta y subir un documento (voucher) que nos ayude a validar los datos, en caso contrario se te habilitará una cuenta de Interbank</span>
  </div>
)

const tooltipAsignacionFamiliar = () => (
  <div>
    <span>Registra como SÍ, si es que tienes hijos menores de 18 años</span>
  </div>
)

const tooltipArchivoSustento = () => (
  <div>
    <label>Adj. Sustento</label>
    <GenericTooltip id="tooltipSubirDescargar" text="Solo son válidos archivos jpg, png o pdf de máximo 8MB." />
  </div>
)

const NotaCTS = () => (
  <div className='col-sm-12 pl-3 pt-3'>
    <label style={{color: "red"}} className='mx-3 text-justify'>
      Colegios Peruanos te aperturará una cuenta CTS en Interbank, si requieres que se te deposite tu CTS en otro banco, una vez que te incorpores a Innova Schools, contactáte con el área de compensaciones para la gestión correspondiente. Los datos de contacto se te comunicarán en tu inducción de ingreso.
    </label>
  </div>
)

const ButtonCUSSP = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  font-size: 12px;

  .cussp-label {
    font-family: 'GothamLight';
    color: #EA8215;
  }

  .cussp-button {
    margin-left: 10px;
    border: 1px solid #3a87c8;
    border-radius: 12px;
    background-color: #3a87c8;
    color: white;
    padding: 0px 15px;

    &:hover{
      border-color: #2e6a9b;
      background-color: #2e6a9b;
    }
  }
`