import React, { useState, useEffect, useRef, useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GenericModal from '../../../components/modal/GenericModal';
import SelectInput from '../../../components/Select/SelectInput';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { AuthContext } from '../../../hooks/context/authContext';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { clearAllInputValidationMessages } from '../../../validations/FormValidatorUtils';
import { downloadFile } from '../../../consumers/backendConsumer';
import { getTiposArchivoFichaDatosAPI, getEditArchivosFichaDatosAPI, editArchivosFichaDatosAPI } from '../../../consumers/backendApisUrls';

const EditarArchivo = ({ toggleModal, idEmpleadoArchivoFichaDatos, manageSearch }) => {
  const isMounted = useRef(true);
  const { state } = useContext(AuthContext);
  const SECTION_NAME = "EDITAR_ARCHIVO_CARD_CIERRE_ANIO";
  const KEYS = {EmpleadoArchivo: "IdEmpleadoArchivoFichaDatos", TipoArchivo: "IdTipoArchivo", File: "File"};

  const [isLoading, setIsLoading] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allData, setAllData] = useState([]);
  const [formValues, setFormValues] = useState({});

  const [submitForm] = useSubmitForm();
  const [executeGet] = useManageGetRequest();
  const [getOptions] = useGetOptionsSelect();

  const initialData = async () => {
    setIsLoading(true);
    try {
      let newAllData = await getOptions(getTiposArchivoFichaDatosAPI());
      setAllData(newAllData);
      await executeGet(getEditArchivosFichaDatosAPI(idEmpleadoArchivoFichaDatos), (res) => successInitialData(res.data, newAllData));
    } catch (error) {
      console.error();
    }
    setIsLoading(false);
  }
  
  const successInitialData = (res, allData) => {
    let IdTipoArchivo = allData.find(o => o.value == res.IdTipoArchivo)
    setFormValues({...res, IdTipoArchivo})
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = editArchivosFichaDatosAPI();
      let payload = getPayload();
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const getPayload = () => {
    return {
      ...formValues,
      [KEYS.EmpleadoArchivo]: idEmpleadoArchivoFichaDatos,
      [KEYS.TipoArchivo]: formValues[KEYS.TipoArchivo]?.value,
    }
  }

  const successOnSubmitCallback = () => {
    manageSearch();
    toggleModal();
  }

  const handleChange = (id, res) => {
    setFormValues(lastData => ({...lastData, [id]: res}))
  }

  useDidMountEffect(() => {
    if (formValues.File?.Path) clearAllInputValidationMessages([KEYS.File], SECTION_NAME);
    if (formValues.IdTipoArchivo) clearAllInputValidationMessages([KEYS.TipoArchivo], SECTION_NAME);
  }, [formValues])

  useEffect(() => {
    initialData();
    return () => {isMounted.current = false;}
  }, [])

  return (
    isLoading ? 
      <div className="spinner-cargando-container">
          <Spinner animation="grow" variant="primary" size="1sm" />
      </div> :
      <GenericModal showModal={toggleModal} width="700px" titulo="Editar Archivo">
        <div className="modal-body pt-0 pl-0">
          <form onSubmit={handleOnSubmit}>
            <div className='form-group row'>
              <label className='col-sm-3 col-form-label modal-label'>
                Tipo
              </label>
              <div className='col-sm-9 black-color'>
                <SelectInput
                  options={allData}
                  jsonSelectedValue={formValues[KEYS.TipoArchivo]}
                  setJsonSelectedValue={(res) => handleChange(KEYS.TipoArchivo, res)}
                  placeholder="Seleccionar"
                  resetSelectedValue={false}
                />
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.TipoArchivo}-validation-message`}></p>
              </div>
            </div>
            <div className='form-group row'>
              <label className='col-sm-3 col-form-label modal-label'>
                Archivo
              </label>
              <div className='col-sm-9 black-color'>
                {formValues.File?.Path ?
                  <div>
                    <a className='mx-4 hand-pointer blue-standard-color' onClick={(e) => {
                        e.preventDefault();
                        downloadFile(state.token, formValues.File?.Path, formValues.File?.OriginalFileName);
                    }}><FontAwesomeIcon icon='paperclip' transform="left-9" /> {formValues.File?.OriginalFileName} </a>
                    <FontAwesomeIcon icon='trash' size='lg' title='Eliminar' className="hand-pointer blue-standard-color" onClick={() => setFormValues(last => ({...last, File:{}}))} />
                  </div> :
                  <div>
                    <GenericUploadFileV2 
                      id="ARCHIVO_CARD_SECCION_CIERRE_ANIO"
                      extensionesValidas={["pdf"]}
                      uploadFileToS3={(res) => handleChange(KEYS.File, res)}
                      textButton="Subir Archivo"
                      subiendoArchivoPublicState={isUploading}
                      setSubiendoArchivoPublicState={setIsUploading}
                      resetInitialFile={false}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-${KEYS.File}-validation-message`}></p>
                  </div>
                }
              </div>
            </div>
            <div className='modal-footer d-flex justify-content-end'>
              <div className='button'>
                <button className='btn btn-primary generic-button-ficha-modal' type='submit' disabled={isUploading || isSubmitting}>
                  Guardar
                </button>
              </div>
            </div>
          </form>
        </div>
      </GenericModal>
  )
}

export default EditarArchivo