import React, {useState} from 'react';
import {useForm} from "../../hooks/useForm/UseForm";
import {downloadReporteFamiliarAPI} from "../../consumers/backendApisUrls";
import { useSubmitForm } from '../../hooks/useSubmitForm/useSubmitForm';
import { useDescargarArchivoYEliminarArchivoDeS3 } from '../../hooks/useGenerarDescargarEliminarArchivo/useDescargarArchivoYEliminarArchivoDeS3';

const ReporteAsignacionFamiliar = () => {
  const SECTION_NAME = "ReporteDeAsignacionFamiliar";
  const KEYS = ["FechaInicio", "FechaFin"];
  
  const [isDownloading, setIsDownloading] = useState(false);

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);

  const [submitForm] = useSubmitForm();
  const [DownloadDeleteFile] = useDescargarArchivoYEliminarArchivoDeS3();

  const handleDownloadReport = async () => {
    setIsDownloading(true);
    try {
      const api = downloadReporteFamiliarAPI();
      let payload = formValues;
      await submitForm(KEYS, api, payload, SECTION_NAME, successDownloadReportCallback)
    } catch (error) {
      console.error(error);
    }
    setIsDownloading(false);
  }

  const successDownloadReportCallback = async (res) => {
    let data = res?.data;
    DownloadDeleteFile(data?.OriginalFileName, data?.FilePath);
  }

  return (
    <div className='card-body'>
      <div id={SECTION_NAME}>
        <div>
          <div className="form-group row form-input-modal-contactanos reportes-label-container">
            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Inicio<span className="required">*</span></label>
            <div className="col-sm-4">
              <input type="date" className="form-control" name="FechaInicio" onChange={handleChange} disabled={isDownloading}></input>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaInicio-validation-message`}></p>
            </div>
          </div>
          <div className="form-group row form-input-modal-contactanos reportes-label-container">
            <label className="col-sm-2 col-form-label modal-label blue-standard-color reportes-label">Fecha Fin<span className="required">*</span></label>
            <div className="col-sm-4">
              <input type="date" className="form-control" name="FechaFin" onChange={handleChange} disabled={isDownloading}
              ></input>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-FechaFin-validation-message`}></p>
            </div>
          </div>
        </div>

        <div className="reporte-datos-generales-dato-obligatorio">
          <p>(<span className="required ">*</span>) Dato obligatorio</p>
        </div>

        <div>
          <button className="btn btn-primary generic-button-ficha" onClick={handleDownloadReport} disabled={isDownloading}>
            {isDownloading ? 'Descargando Reporte ...' : 'Descargar Reporte'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReporteAsignacionFamiliar