import React, {useEffect, useState} from 'react';
import {toast} from "react-toastify";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";
import GenericModal from "../../../components/modal/GenericModal";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../../consumers/httpRequiestsUtils";
import {useForm} from "../../../hooks/useForm/UseForm";
import {jsonNoEstaVacio} from "../../../helper/utils";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {getConfiguracionDeRolesDeUnUsuarioAPI, updateRolesDeUsuarioAPI} from "../../../consumers/backendApisUrls";

const EditarUsuarioModal = ({showModal, idUsuarioEditar, manageSearch}) => {
    const [configuracionDeRolesDeUsuario, setConfiguracionDeRolesDeUsuario] = useState([]);
    const [tempConfiguracionDeRolesDeUsuario, setTempConfiguracionDeRolesDeUsuario] = useState([]);
    const [camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues] = useState({});
    const [handleChangeCamposCheckboxFormValues] = useForm(camposCheckboxJsonFormValues, setCamposCheckboxJsonFormValues);
    const seccionName = "modal-actualizar-roles-de-usuario";
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();
    const [userInfo, setUserInfo] = useState({});
    const [userFormValuesWithoutPermissions, setUserFormValuesWithoutPermissions] = useState({});
    const [handleChange] = useForm(userFormValuesWithoutPermissions, setUserFormValuesWithoutPermissions);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useEffect(() => {
        camposCheckboxJsonFormValuesUseEffect();
    }, [camposCheckboxJsonFormValues]);

    const camposCheckboxJsonFormValuesUseEffect = () => {
        if (jsonNoEstaVacio(camposCheckboxJsonFormValues)) {
            console.debug("useEffect, camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);
            let reporteGeneralCamposCheckboxsTemp = [];

            for (let configuracionRol of configuracionDeRolesDeUsuario) {
                let key = configuracionRol["IdRol"];
                let value = camposCheckboxJsonFormValues[key];
                let tempCampo = {};
                tempCampo = {...configuracionRol, "TieneRol": value};

                reporteGeneralCamposCheckboxsTemp = [...reporteGeneralCamposCheckboxsTemp, tempCampo];
            }
            setTempConfiguracionDeRolesDeUsuario(reporteGeneralCamposCheckboxsTemp);
        }
    };

    const getCheckBoxInitialFormValues = (camposCheckbox) => {
        let formInputValues = {};
        for (let campoCheckbox of camposCheckbox) {
            let key = campoCheckbox["IdRol"];
            let value = campoCheckbox["TieneRol"];
            formInputValues = {...formInputValues, [key]: value}
        }

        formInputValues = {...formInputValues};
        console.debug("formInputValues", formInputValues);
        return formInputValues;
    };

    const submitEditarUsuario = async () => {
        console.debug("camposCheckboxJsonFormValues: ", camposCheckboxJsonFormValues);
        console.debug("tempConfiguracionDeRolesDeUsuario: ", tempConfiguracionDeRolesDeUsuario);
        console.debug("userFormValuesWithoutPermissions: ", userFormValuesWithoutPermissions);

        let payload = {
            "IdUsuario": idUsuarioEditar,
            "Permisos": tempConfiguracionDeRolesDeUsuario,
            "CorreoCorporativo": userFormValuesWithoutPermissions?.CorreoCorporativo
        };

        console.debug("payload: ", payload);

        const response = await executePostRequest(updateRolesDeUsuarioAPI(), payload);
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            showModal();
            manageSearch();
        } else if (responseTieneModelState(response)) {
            let allInputKeys = ["IdUsuario", "Permisos","CorreoCorporativo"];
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, seccionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const init = async () => {
        const response = await executeGetRequest(getConfiguracionDeRolesDeUnUsuarioAPI(idUsuarioEditar));
        if (responseCode200(response)) {
            setConfiguracionDeRolesDeUsuario(response.data.Permisos);
            let camposCheckboxFormValues = getCheckBoxInitialFormValues(response.data.Permisos);
            setCamposCheckboxJsonFormValues(camposCheckboxFormValues);
            setUserInfo(response.data);

            let formInputValues = getInitialFormValues(response.data);
            setUserFormValuesWithoutPermissions(formInputValues);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }


    };

    const getInitialFormValues = (info) => {
        return {
            "CorreoCorporativo": info.CorreoCorporativo,
        };
    };

    return (
        <>
            <GenericModal showModal={showModal} width={"850px"} titulo={"Editar Roles de un Usuario"}>
                <div>
                    <div>
                        <form className="blue-standard-color">
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-3 col-form-label modal-label">Usuario:</label>
                                <label className="col-sm-6 col-form-label black-color">{userInfo?.NombreUsuario && userInfo.NombreUsuario}</label>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-IdUsuario-validation-message`}></p>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-3 col-form-label modal-label">Correo Corporativo<span className="required">*</span></label>
                                <div className="col-sm-7">
                                    <input type="text" className="form-control" name="CorreoCorporativo" value={userFormValuesWithoutPermissions.CorreoCorporativo} onChange={handleChange}></input>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-CorreoCorporativo-validation-message`}></p>
                                </div>
                            </div>

                            <div className="form-group row form-input-modal-contactanos">
                                <label className="col-sm-12 col-form-label modal-label">Seleccionar el (los) rol(es)<span className="required">*</span></label>
                            </div>
                            <div className="form-group row form-input-modal-contactanos">
                                <div className="col-sm-12 custom-scroll">
                                    <div className="gestion-usuarios-roles-checkboxs-group ">
                                        {tempConfiguracionDeRolesDeUsuario && tempConfiguracionDeRolesDeUsuario.map((campo, index) => {
                                            return (
                                                <div className="gestion-usuarios-roles-checkbox-container" key={index}>
                                                    <input type="checkbox" name={campo["IdRol"]} checked={campo["TieneRol"]} onChange={handleChangeCamposCheckboxFormValues}></input><p className="resportes-checkbox-input-text">{campo.NombreRol}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-Permisos-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div>
                    <div className="modal-footer" style={{'marginTop': '15px'}}>
                        <div className="message font-gothan-light">
                            <strong>
                                <p>* Campos obligatorios</p>
                            </strong>
                        </div>
                        <div className="button">
                            <button className="btn btn-primary generic-button-ficha-modal" onClick={submitEditarUsuario}>Guardar</button>
                        </div>
                    </div>
                </div>
            </GenericModal>
        </>
    )
};

export default EditarUsuarioModal;