import {useEffect, useRef} from "react";

const useClickOutside = (handler) => {
    let domNodeRef = useRef();
    useEffect(() => {
        let maybeHandler = (e) => {
            if (!domNodeRef.current.contains(e.target)) {
                handler();
            }
        };
        document.addEventListener("mousedown", maybeHandler);

        return () => {
            document.removeEventListener("mousedown", maybeHandler)
        }
    });
    return domNodeRef;
};

export default useClickOutside;