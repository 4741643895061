import React, {useEffect, useState} from 'react';
import GenericModal from '../../../components/modal/GenericModal';

import {useForm} from '../../../hooks/useForm/UseForm';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {procesoCierreConfigCrearProcesoAPI} from "../../../consumers/backendApisUrls";

const AgregarProcesoDeCierre = ({toggleModal, refreshTable}) => {
    const sectionName = "AgregarProcesoDeCierre";
    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);
    const [submitForm] = useSubmitForm();

    const successSubmitCallback = function () {
        refreshTable();
        toggleModal();
    }

    const submit = async () => {
        let payload = armarPayload();
        let api = procesoCierreConfigCrearProcesoAPI()
        let keys = ["Nombre", "Descripcion", "FechaInicio", "FechaFin"];

        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    };

    const armarPayload = () => {
        let payload = {
            Nombre: formValues?.Nombre,
            Descripcion: formValues?.Descripcion,
            FechaInicio: formValues?.FechaInicio,
            FechaFin: formValues?.FechaFin
        }

        return payload;
    }

    return (
        <>
            <GenericModal showModal={toggleModal} width={"900px"} titulo={"Agregar Proceso de Cierre"}>

                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Nombre<span className="required">*</span></label>
                            <div className="col-sm-9 black-color">
                                <input type="text" className="form-control" name="Nombre" onChange={handleChange} value={formValues?.Nombre ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Nombre-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Descripción<span className="required"></span></label>
                            <div className="col-sm-9 black-color">
                                <textarea className="form-control" rows="3" name="Descripcion" onChange={handleChange} value={formValues?.Descripcion}  placeholder="Máximo 100 caractares" maxLength="100"></textarea>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Descripcion-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Fecha Inicio<span className="required">*</span></label>
                            <div className="col-sm-4 black-color">
                                <input type="date" className="form-control" name="FechaInicio" onChange={handleChange} value={formValues?.FechaInicio ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaInicio-validation-message`}></p>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Fecha Fin<span className="required">*</span></label>
                            <div className="col-sm-4 black-color">
                                <input type="date" className="form-control" name="FechaFin" onChange={handleChange} value={formValues?.FechaFin ?? ""}></input>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-FechaFin-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha-modal" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    )
}

export default AgregarProcesoDeCierre;