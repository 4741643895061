import React, {useEffect, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import PageHeader from '../../components/pageHeader/PageHeader';
import {
    getAllEstadosAPI,
    procesoCierreConfigEliminarAPI,
    procesoCierreConfigGetAllProcesosAPI,
} from '../../consumers/backendApisUrls';
import {mostrarMensajeDeErrorConToast, responseCode200} from '../../consumers/httpRequiestsUtils';
import {setStateWithCallBackResponse, onKeyPressEnter, jsonEstaVacio} from '../../helper/utils';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import {useGetRequest} from '../../hooks/useGetRequest/useGetRequest';
import {usePostRequest} from '../../hooks/usePostRequest/usePostRequest';
import Paginator from '../directorio/Paginator';
import TableWebBody from './TableWebBody';
import {useManagePostRequest} from "../../hooks/useManagePostRequest/useManagePostRequest";
import {updateOptionsDeFiltro} from "../../helper/Listas/ListasUtils";
import AgregarProcesoDeCierre from "./modales/AgregarProcesoDeCierre";
import FormSelect from "../../components/atoms/Select";
import EditarProcesoDeCierre from "./modales/EditarProcesoDeCierre";
import {Link, useHistory } from 'react-router-dom';
import AsignarTipoContrato from './modales/AsignarTipoContrato';
import ActivarInactivarProcesoDeCierre from './modales/ActivarInactivarProcesoDeCierre';


const ProcesosDeCierre = () => {
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const [managePostRequest] = useManagePostRequest();
    const [executePostRequest] = usePostRequest();

    const [criterioDeBusqueda, setCriterioDeBusqueda] = useState("");

    const [estadosOptions, setEstadosOptions] = useState([]);
    const [allEstadosoOptions, setAllEstadosoOptions] = useState([]);
    const [estadosSeleccionados, setEstadosSeleccionados] = useState([]);

    const [filas, setFilas] = useState([]);
    const [initialResponse, setInitialResponse] = useState({});
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(10);
    const [firsItemPage, setFirsItemPage] = useState({});
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [loading, setLoading] = useState(true);
    const [hideModalAgregar, setHideModalAgregar] = useState(false);
    const [hideModalEditar, setHideModalEditar] = useState(false);
    const [hideAsignarTipoContrato, sethideAsignarTipoContrato] = useState(false);
    const [idProcesoCierre, setIdProcesoCierre] = useState(0);
    const [procesoCierre, setProcesoCierre] = useState();

    const [procesoDeCierreId, setProcesoDeCierreId] = useState(null);
    const [procesoDeCierreTitle, setProcesoDeCierreTitle] = useState(null);
    const [procesoDeCierreEstado, setProcesoDeCierreEstado] = useState(null);
    const [hideModalActivarInactivarProcesoDeCierre, setHideModalActivarInactivarProcesoDeCierre] = useState(false);

    const history = useHistory();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    useDidMountEffect(() => {
        setPage(1);
        manageSearch();
    }, [estadosSeleccionados]);

    useDidMountEffect(() => {
        manageSearch();
    }, [page]);

    const getInitialData = async () => {
        setLoading(true);
        try {
            await setStateWithCallBackResponse(executeGetRequestCallback(getAllEstadosAPI()), setEstadosOptions);
            await setStateWithCallBackResponse(executeGetRequestCallback(getAllEstadosAPI()), setAllEstadosoOptions);

            await manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const manageSearch = async (rerenderTable = false) => {
        let payload = obtenerPayload();
        await executeSearch(payload, rerenderTable);
    }

    const obtenerPayload = function () {
        let payload = {
            "Criteria": criterioDeBusqueda,
            "Page": page,
            "IdsEstadosregistro": estadosSeleccionados,
        };
        return payload;
    }

    const limpiarBusqueda = () => {
        setCriterioDeBusqueda("");
        setEstadosSeleccionados([]);
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const executeSearch = async (payload, rerenderTable) => {
        rerenderTable === true && setFilas([]);
        const response = await executePostRequest(procesoCierreConfigGetAllProcesosAPI(), payload);
        if (responseCode200(response)) {
            setFilas(response.data.Items);
            setTotalItems(response.data.TotalItems);
            setFirsItemPage(response.data.FirsItemPage);
            setLastItemPage(response.data.LastItemPage);
            setTotalPages(response.data.TotalPages);
            jsonEstaVacio(initialResponse) && setInitialResponse(response.data);

            updateOptionsDeFiltros(response.data.ExtraData);

        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    const updateOptionsDeFiltros = function (lists) {
        updateOptionsDeFiltro(allEstadosoOptions, lists["IdsEstados"], setEstadosOptions, executeGetRequestCallback(getAllEstadosAPI()));
    };

    const toggleEditarModal = (idProcesoCierre) => {
        setHideModalEditar(!hideModalEditar);
        setIdProcesoCierre(idProcesoCierre)
    };

    const toggleAgregarModal = () => {
        setHideModalAgregar(!hideModalAgregar);
    };

    const toggleAsignarTipoContrato = () => {
        sethideAsignarTipoContrato(!hideAsignarTipoContrato);
    };

    const SaveProcesoCierre = (procesoCierre) => {
        setProcesoCierre(procesoCierre)
    };

    const manageSearchProxy = (response) => {
        manageSearch();
    }

    const eliminar = async function (idProcesoCierreTemp) {
        await managePostRequest(procesoCierreConfigEliminarAPI(idProcesoCierreTemp), {}, manageSearchProxy)
    }

    const   asignarPlantilla = (idProcesoCierre) => {
        history.push(`/procesosDeCierre/asignarPlantillas/${idProcesoCierre}`);
    }

    if (loading) {
        return (
            <div className="spinner-cargando-container">
                <Spinner animation="grow" variant="primary" size="1sm"/>
            </div>
        );
    }

    const toggleActivarInactivarProcesoDeCierreModal = () => {
        setHideModalActivarInactivarProcesoDeCierre(!hideModalActivarInactivarProcesoDeCierre);
    }

    return (<>
        <div className="message-no-disponible-en-mobile">
            <p>Esta página no está disponible en mobile.</p>
        </div>
        <div className="no-disponible-en-mobile">

            {hideModalAgregar &&
            <div className="modal-contactanos modal-response-ficha-background">
                <AgregarProcesoDeCierre toggleModal={toggleAgregarModal} refreshTable={manageSearch}/>
            </div>
            }

            {hideAsignarTipoContrato &&
            <div className="modal-contactanos modal-response-ficha-background">
                <AsignarTipoContrato toggleModal={toggleAsignarTipoContrato} procesoCierre ={procesoCierre}/>
            </div>
            }

            {hideModalEditar &&
            <div className="modal-contactanos modal-response-ficha-background">
                <EditarProcesoDeCierre toggleModal={toggleEditarModal} refreshTable={manageSearch} idProcesoCierre={idProcesoCierre}/>
            </div>
            }

            {hideModalActivarInactivarProcesoDeCierre && 
                <div className="modal-contactanos modal-response-ficha-background">
                <ActivarInactivarProcesoDeCierre
                    toggleModal={toggleActivarInactivarProcesoDeCierreModal}
                    refreshPage={manageSearch}
                    procesoDeCierreTitle={procesoDeCierreTitle}
                    procesoDeCierreId={procesoDeCierreId}
                    procesoDeCierreEstado={procesoDeCierreEstado}
                />
            </div>
        }

            <Wrapper className='App'>
                <PageHeader title={"Procesos de Cierre"}/>
                <div className="generic-buscador-container">
                    <div className="filtros-left-section">
                        <input
                            type='text'
                            className='generic-input-criterio-busqueda'
                            placeholder='Buscar por nombre'
                            value={criterioDeBusqueda}
                            onChange={(e) => setCriterioDeBusqueda(e.target.value)}
                            onKeyPress={(e) => onKeyPressEnter(e, manageSearch)}
                        />
                        <button
                            onClick={manageSearch}
                            type='button'
                            className='generic-button-buscador'>
                            Buscar
                        </button>
                        <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                    </div>
                    <div className="filtros-right-section">
                        <div className='d-flex'>
                        <Link className='btn generic-button-buscador' to={"/procesosDeCierre/adminGrupos"}>Administrar Grupos Predefinidos</Link>
                        <button
                            onClick={(e) => toggleAgregarModal()}
                            type='button'
                            className='generic-button-buscador'>
                            Agregar
                        </button>
                        </div>
                    </div>
                </div>
                <div className='pt-1'>
                    <div className="table-scroll">
                        <table className='table table-hover listar-table-cierre-anio'>
                            <thead className="listar-thead">
                            <tr>
                                <th className="col-3 pl-3 pr-3">
                                    <span className='font-gothan-medium d-inline'>Nombre</span>
                                </th>
                                <th className="col-3 pl-1 pr-1">
                                    <span className='font-gothan-medium d-inline'>Descripción</span>
                                </th>
                                <th className="col-1 pl-1 pr-1">
                                    <span className='font-gothan-medium d-inline'>Inicio</span>
                                </th>
                                <th className="col-1 pl-1 pr-1">
                                    <span className='font-gothan-medium d-inline'>Fin</span>
                                </th>
                                <th className="col-1 pl-1 pr-1">
                                    <FormSelect
                                        options={estadosOptions}
                                        description={'Estado'}
                                        setSelectedOptions={setEstadosSeleccionados}
                                        selectedOptions={estadosSeleccionados}
                                    />
                                </th>
                                <th className="col-1 pl-1 pr-1">
                                    <span className='font-gothan-medium d-inline'>Estado Activo</span>
                                </th>
                                <th className="col-1 pl-1 pr-1">
                                    <span className='font-gothan-medium d-inline'>Acciones</span>
                                </th>
                            </tr>
                            </thead>
                            <TableWebBody
                                filas={filas}
                                editar={toggleEditarModal} 
                                eliminar={eliminar} 
                                asignarPlantilla={asignarPlantilla} 
                                initialResponse={initialResponse} 
                                toggleAsignarTipoContrato = {()=>{toggleAsignarTipoContrato()}} 
                                SaveProcesoCierre = {SaveProcesoCierre} 
                                manageSearchProxy={manageSearchProxy}
                                toggleActivarInactivarProcesoDeCierreModal={toggleActivarInactivarProcesoDeCierreModal}
                                setProcesoDeCierreTitle={setProcesoDeCierreTitle}
                                setProcesoDeCierreId={setProcesoDeCierreId}
                                setProcesoDeCierreEstado={setProcesoDeCierreEstado} />
                        </table>
                    </div>
                        {totalPages > 0 && (
                            <Paginator totalItems={totalItems}
                                       firsItemPage={firsItemPage}
                                       lastItemPage={lastItemPage}
                                       totalPages={totalPages}
                                       page={page}
                                       onLeftClic={onLeftClic}
                                       onRightClic={onRightClic}/>
                        )}

                </div>
            </Wrapper>
        </div>
    </>)

}

export default ProcesosDeCierre;
