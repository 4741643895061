import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { usePostRequest } from "../../../hooks/usePostRequest/usePostRequest";
import { setAsPredefinidoCallApiAPI } from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";


const TableWebBody = ({
    filas,
    toogleEditar,
    toogleEliminar,
    manageSearch
}) => {
    const [executePostRequest] = usePostRequest();
    const [move, setMove] = useState(false)
    const [procesoCierre, setProcesoCierre] = useState({})

    const setAsPredefinido = async function (IdMonitor) {
        const response = await executePostRequest(setAsPredefinidoCallApiAPI(IdMonitor));
        if (responseCode200(response)) {
            toast.success(response.data.Message);
            manageSearch();
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    if (move) {
        return <Redirect to={{ pathname: '/procesosDeCierre/AsignarGruposIndex/' + procesoCierre.IdProcesoCierre, state: { procesoCierre: procesoCierre } }} />;
    }
    return (
        <tbody className="listar-tbody">
            {filas.map((fila, i) => (
                <tr key={i}>
                    <td className="col-4 pl-1 pr-1">{fila?.Nombre}</td>
                    <td className="col-2 pl-1 pr-1">{fila?.Estado}</td>
                    <td className="col-1 pl-1 pr-1">
                        <div className="d-flex justify-content-start">
                            {fila?.CanEdit &&
                                <div>
                                    <FontAwesomeIcon icon='edit' size="sm" className="hand-pointer" title="Editar" onClick={() => {
                                        toogleEditar(fila)
                                    }} />
                                </div>
                            }
                            {fila?.CanDelete &&
                                <div>
                                    <FontAwesomeIcon icon='times' size="sm" className="hand-pointer ml-3" title="Eliminar" onClick={() => {
                                        toogleEliminar(fila?.IdMonitor, fila?.Nombre);
                                    }} />
                                </div>
                            }
                            {fila?.CanBePredefined &&
                                <div>
                                    <FontAwesomeIcon icon='plus' size="sm" className="hand-pointer ml-3" title="Convertir a predefinido" onClick={() => setAsPredefinido(fila?.IdMonitor)} />
                                </div>
                            }
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

export default TableWebBody;