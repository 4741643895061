import React, { useContext, useEffect, useState } from 'react'
import GenericUploadFileV2 from '../../../../components/GenericUploadFile/GenericUploadFileV2'
import { cerrarCasoCovid, enviarCartaLicencia, getComplejidades, getDetalleTabSeguimiento, getEstadosResultadoPruebaLaboratorio, getNotasSeguimientoCasosCovid, postNotasSeguimientoCasosCovid, saveTabSeguimiento } from '../../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200 } from '../../../../consumers/httpRequiestsUtils';
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../../../helper/utils';
import { useForm } from '../../../../hooks/useForm/UseForm';
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { usePostRequest } from '../../../../hooks/usePostRequest/usePostRequest';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import Select from "react-select";
import { ESTADO_RESULTADO_COVID } from "../../../../constants/hardCodedConstants"
import { downloadFile } from '../../../../consumers/backendConsumer';
import { AuthContext } from '../../../../hooks/context/authContext';
import { getFormattedName } from '../../../../helper/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const SeguimientoMedico = ({
  idRegistro,
  successSubmitCallback,
  showProgramarPrueba,
  informacionGeneral,
  permisos
}) => {
  const { state } = useContext(AuthContext);
  const SECTION_NAME = "SEGUIMIENTO";

  const EXTENSIONES_VALIDAS = ["jpg", "pdf", "jpeg", "png"];

  const [uploadFilePruebaCovid, setUploadFilePruebaCovid] = useState(null);
  const UPLOAD_FILE_PRUEBA_COVID = "ficha-carga-prueba-covid-file";

  const [uploadFileDescanso, setUploadFileDescanso] = useState(null);
  const UPLOAD_FILE_DESCANSO = "ficha-carga-descanso-file";

  const [uploadFileLicencia, setUploadFileLicencia] = useState(null);
  const UPLOAD_FILE_LICENCIA = "ficha-carga-licencia-covid-file";

  const [complejidades, setComplejidades] = useState([]);
  const [complejidadSelected, setComplejidadSelected] = useState({});

  const [resultados, setResultados] = useState([]);
  const [resultadoSelected, setResultadoSelected] = useState({});

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);

  const [executeGetRequest] = useGetRequest();
  const [executePostRequest] = usePostRequest();

  const [detalleSeguimiento, setDetalleSeguimiento] = useState({});
  const [notes, setNotes] = useState([]);

  const [submitForm] = useSubmitForm();


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);


  const getNotas = async () => {
    const response = await executeGetRequest(getNotasSeguimientoCasosCovid(idRegistro));
    if (responseCode200(response)) {
      setFormValues({
        ...formValues,
        nota: "",
      })
      setNotes(response?.data);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const getDetallesSeguimiento = async () => {
    const response = await executeGetRequest(getDetalleTabSeguimiento(idRegistro));
    if (responseCode200(response)) {
      setDetalleSeguimiento(response.data);
      setFormValues({
        RequiereLicencia: response.data.SeccionGeneralData.RequiereLicencia,
        AltaMedica: response.data.SeccionGeneralData.AltaMedica,
        ContactoMinsa: response.data.SeccionGeneralData.ContactoMinsa,
        EstadoDescarte: response.data.DescarteData.Estado || "",
        ResultadoDescarte: response.data.DescarteData.Estado || "",
        FechaInicioDescanso: response.data.DescansoData.FechaInicio,
        FechaFinDescanso: response.data.DescansoData.FechaFin,
        EstadoLicencia: response.data.LicenciaData.Estado || "",
        FechaInicioLicencia: response.data.LicenciaData.FechaInicio,
        FechaFinLicencia: response.data.LicenciaData.FechaFin,
      })
      setComplejidadSelected({
        value: response.data.SeccionGeneralData.IdComplejidad,
        label: response.data.SeccionGeneralData.Complejidad || ""
      });
      setResultadoSelected({
        value: response.data.DescarteData.IdResultado,
        label: response.data.DescarteData.Resultado
      })
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const getDropdowns = async () => {
    const responseComplejidad = await executeGetRequest(getComplejidades());
    if (responseCode200(responseComplejidad)) {
      let complejidadesAux = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(responseComplejidad.data);
      setComplejidades(complejidadesAux);
    }

    const responseResultados = await executeGetRequest(getEstadosResultadoPruebaLaboratorio());
    if (responseCode200(responseResultados)) {
      let resultadosAux = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(responseResultados.data);
      setResultados(resultadosAux);
    }
  }

  const init = async function () {
    await getNotas();
    await getDetallesSeguimiento();
    await getDropdowns();
  }

  const enviarNota = async () => {
    const payload = {
      "IdCasoCovid": idRegistro,
      "Nota": formValues?.nota,
    }
    let response = await executePostRequest(postNotasSeguimientoCasosCovid(), payload);
    if (responseCode200(response)) {
      await getNotas(response);
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const enviarLicencia = async () => {
    let response = await executePostRequest(enviarCartaLicencia(idRegistro), {});
    if (responseCode200(response)) {
      toast.success(response.data.Message);
      init()
    } else {
      mostrarMensajeDeErrorConToast(response);
    }
  }

  const guardar = async () => {
    const payload = {
      "IdCasoCovid": idRegistro,
      "IdComplejidad": complejidadSelected.value,
      "RequiereLicencia": formValues.RequiereLicencia,
      "AltaMedica": formValues.AltaMedica,
      "ContactoMinsa": formValues.ContactoMinsa,
      "Descanso": {
        "DescansoStartDate": formValues.FechaInicioDescanso,
        "DescansoEndDate": formValues.FechaFinDescanso,
        "DescansoFile": uploadFileDescanso,
        "DescansoPreviousFile": detalleSeguimiento.DescansoData.File
      },
      "Licencia": {
        "LicenciaStartDate": formValues.FechaInicioLicencia,
        "LicenciaEndDate": formValues.FechaFinLicencia,
        "LicenciaFile": uploadFileLicencia,
        "LicenciaPreviousFile": detalleSeguimiento.LicenciaData.File
      }
    }

    const keys = ["IdComplejidad", "IdResultado", "DescansoFile", "DescansoStartDate", "DescansoEndDate", "LicenciaStartDate", "LicenciaEndDate", "LicenciaFile", "DescarteFile"];
    const api = saveTabSeguimiento();

    await submitForm(keys, api, payload, SECTION_NAME, successSubmitCallback);
  }

  const descargarArchivo = (e, archivo) => {
    e.preventDefault()
    downloadFile(state.token, archivo.Path, archivo.OriginalFileName);
  }

  const cerrarCaso = async () => {
    let response = await executePostRequest(cerrarCasoCovid(idRegistro), {});
    if (!responseCode200(response)) {
      mostrarMensajeDeErrorConToast(response);
    } else {
      successSubmitCallback();
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end align-items-end">
        {permisos?.PuedeCerrarCaso && !(informacionGeneral?.Estado === "Cerrado") ? (
          <div>
            <button className="generic-button-buscador" onClick={cerrarCaso}>
              Cerrar caso
            </button>
          </div>
        ) : null}
        {informacionGeneral?.Estado === "Cerrado" ? null : (
          <div>
            <button
              className="generic-button-buscador"
              onClick={guardar}
            >
              Guardar
            </button>
          </div>
        )}
      </div>
      <hr />
      <div >
        <div><strong>Seguimiento del médico ocupacional</strong></div>
        <div className="row">
          <div className="col-3">
            <div >
              <div className="form-group">
                <label htmlFor="Complejidad">Complejidad</label>
                <Select
                  options={complejidades}
                  placeholder={"complejidad"}
                  onChange={setComplejidadSelected}
                  value={complejidadSelected}
                  isDisabled={!detalleSeguimiento?.Permissions?.PuedeEditarDatosGenerales}
                />
                {/* <input
                  type="text"
                  name="Complejidad"
                  className="form-control"
                  id="Complejidad"
                  disabled={!detalleSeguimiento?.Permissions?.PuedeEditarDatosGenerales}
                  onChange={handleChange}
                  value={formValues?.Complejidad}
                /> */}
                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdComplejidad-validation-message`}></p>
              </div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={"RequiereLicencia"}
                data-id={"RequiereLicencia"}
                name={"RequiereLicencia"}
                defaultChecked={formValues?.RequiereLicencia}
                disabled={!detalleSeguimiento?.Permissions?.PuedeEditarDatosGenerales}
                onClick={handleChange}
                value={formValues?.RequiereLicencia}
              />
              <label className="form-check-label" htmlFor={"RequiereLicencia"}>
                Requiere Licencia
              </label>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={"AltaMedica"}
                data-id={"AltaMedica"}
                name={"AltaMedica"}
                defaultChecked={formValues?.AltaMedica}
                disabled={!detalleSeguimiento?.Permissions?.PuedeEditarDatosGenerales}
                onClick={handleChange}
                value={formValues?.AltaMedica}
              />
              <label className="form-check-label" htmlFor={"AltaMedica"}>
                Alta médica
              </label>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={"ContactoMinsa"}
                data-id={"ContactoMinsa"}
                name={"ContactoMinsa"}
                defaultChecked={formValues?.ContactoMinsa}
                disabled={!detalleSeguimiento?.Permissions?.PuedeEditarDatosGenerales}
                onClick={handleChange}
                value={formValues?.ContactoMinsa}
              />
              <label className="form-check-label" htmlFor={"ContactoMinsa"}>
                Ha establecido contacto con el MINSA
              </label>
            </div>
          </div>

        </div>
      </div>
      <hr />
      <div className={`${!detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescarte ? "d-test" : ""}`}>
        <div><strong>Descarte prueba Covid</strong></div>
        <div className="row mt-2">


          <div className="col-5">
            <div className="container-fluid">
              <div className="row align-items-center">
                {detalleSeguimiento?.DescarteData?.PuedeProgramar ? (
                  <div className="col-4 pl-0">
                    <button className="generic-button-buscador p-2 m-0" onClick={showProgramarPrueba}>
                      Programar
                    </button>
                  </div>
                ) : null}
                <div className="col-auto pl-0">
                  <div className="form-group">
                    <label htmlFor="EstadoDescarte">Estado: {formValues?.EstadoDescarte}</label>
                    {/* <input
                      type="text"
                      className="form-control"
                      id="EstadoDescarte"
                      name="EstadoDescarte"
                      onChange={handleChange}
                      value={formValues?.EstadoDescarte}
                      disabled={true}
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group w-50 pl-0">
                  <label htmlFor="ResultadoDescarte">Resultado: {detalleSeguimiento?.DescarteData?.Resultado}</label>
                  {/* <Select
                    style={{ width: '5rem' }}
                    options={resultados}
                    placeholder={"resultado"}
                    onChange={setResultadoSelected}
                    value={resultadoSelected}
                    isDisabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescarte}
                  /> */}
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-IdResultado-validation-message`}></p>
                </div>
              </div>
            </div>
          </div>


          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="container-fluid">
            </div>
          </div>


          <div className="col-4 d-flex align-items-center">
            <div className="container-fluid">
              <div className="row text-wrap">
                {"Usuario: " + detalleSeguimiento?.DescarteData?.User}
              </div>
              <div className="row">
                {"Fecha/Hora: " + detalleSeguimiento?.DescarteData?.DateTime}
              </div>

            </div>
          </div>

          <div className="col-12">
            <strong>Nota: </strong> {detalleSeguimiento?.DescarteData?.Nota}
          </div>

        </div>
      </div>
      <hr />
      <div className={`${!detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescanso ? "d-test" : ""}`}>
        <div><strong>Descanso médico</strong></div>
        <div className="row mt-2">


          <div className="col-5">
            <div className="container-fluid">
              <div className="row">
                <div className="form-group col-lg-12 col-xl-6 pl-0 mb-lg-0">
                  <label htmlFor="FechaInicioDescanso">Inicio</label>
                  <input
                    type="date"
                    className="form-control"
                    id="FechaInicioDescanso"
                    name={"FechaInicioDescanso"}
                    onChange={handleChange}
                    value={formValues?.FechaInicioDescanso}
                    disabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescanso}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-DescansoStartDate-validation-message`}></p>
                </div>
                <div className="form-group col-lg-12 col-xl-6 pl-lg-0">
                  <label htmlFor="FechaFinDescanso">Fin</label>
                  <input
                    type="date"
                    className="form-control"
                    id="FechaFinDescanso"
                    name={"FechaFinDescanso"}
                    onChange={handleChange}
                    value={formValues?.FechaFinDescanso}
                    disabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescanso}
                  />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-DescansoEndDate-validation-message`}></p>
                </div>
              </div>
            </div>
          </div>


          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="container-fluid">
              {detalleSeguimiento?.Permissions?.PuedeEditarSeccionDescanso ? (
                <div className="d-flex justify-content-center align-items-center">
                  <GenericUploadFileV2 uploadFileToS3={setUploadFileDescanso} id={UPLOAD_FILE_DESCANSO} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS} />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-DescansoFile-validation-message`}></p>
                </div>
              ) : null}
              {detalleSeguimiento?.DescansoData?.File ? (
                <div className="d-flex justify-content-center align-items-center">
                  <a href="#" className="text-center" onClick={(e) => descargarArchivo(e, detalleSeguimiento?.DescansoData?.File)}>
                    {getFormattedName(detalleSeguimiento?.DescansoData?.File?.OriginalFileName, 15)} <FontAwesomeIcon icon='download' className="hand-pointer" />
                  </a>
                </div>
              ) : null}
            </div>
          </div>


          <div className="col-4 d-flex align-items-center">
            <div className="container-fluid">
              <div className="row text-wrap">
                {"Usuario: " + detalleSeguimiento?.DescansoData?.User}
              </div>
              <div className="row">
                {"Fecha/Hora: " + detalleSeguimiento?.DescansoData?.DateTime}
              </div>

            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={`${!detalleSeguimiento?.Permissions?.PuedeEditarSeccionLicencias ? "d-test" : ""}`}>
        <div><strong>Licencia</strong></div>

        <div className="row mt-2">


          <div className="col-5">
            <div className="container-fluid">
              <div className="row align-items-center">
                {detalleSeguimiento?.LicenciaData?.PuedeEnviarLicencia ? (
                  <div className="col-4 pl-0">
                    <button className="generic-button-buscador p-2 m-0" onClick={enviarLicencia} disabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionLicencias}>
                      Enviar
                    </button>
                  </div>
                ) : null}
                <div className="col-auto pl-0">
                  <div className="form-group">
                    <label htmlFor="Estado">Estado</label>
                    <input
                      type="text"
                      className="form-control"
                      id="EstadoLicencia"
                      name={"EstadoLicencia"}
                      onChange={handleChange}
                      value={formValues?.EstadoLicencia}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="container-fluid px-0">
                <div className="row">
                  <div className="form-group col-lg-12 col-xl-6 pl-0 mb-lg-0">
                    <label htmlFor="FechaInicioLicencia">Inicio</label>
                    <input
                      type="date"
                      className="form-control"
                      id="FechaInicioLicencia"
                      name={"FechaInicioLicencia"}
                      onChange={handleChange}
                      value={formValues?.FechaInicioLicencia}
                      disabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionLicencias}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-LicenciaStartDate-validation-message`}></p>
                  </div>
                  <div className="form-group col-lg-12 col-xl-6 pl-lg-0">
                    <label htmlFor="FechaFinLicencia">Fin</label>
                    <input
                      type="date"
                      className="form-control"
                      id="FechaFinLicencia"
                      name={"FechaFinLicencia"}
                      onChange={handleChange}
                      value={formValues?.FechaFinLicencia}
                      disabled={!detalleSeguimiento?.Permissions?.PuedeEditarSeccionLicencias}
                    />
                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-LicenciaEndDate-validation-message`}></p>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-3 d-flex justify-content-center align-items-center">
            <div className="container-fluid">
              {detalleSeguimiento?.Permissions?.PuedeEditarSeccionLicencias && detalleSeguimiento?.LicenciaData?.PuedeVerBotonSubirArchivo ? (
                <div className="d-flex justify-content-center align-items-center">
                  <GenericUploadFileV2 uploadFileToS3={setUploadFileLicencia} id={UPLOAD_FILE_LICENCIA} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS} />
                  <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-LicenciaFile-validation-message`}></p>
                </div>
              ) : null}
              {detalleSeguimiento?.LicenciaData?.File ? (
                <div className="d-flex justify-content-center align-items-center">
                  <a href="#" className="text-center" onClick={(e) => descargarArchivo(e, detalleSeguimiento?.LicenciaData?.File)}>
                    {getFormattedName(detalleSeguimiento?.LicenciaData?.File?.OriginalFileName, 15)} <FontAwesomeIcon icon='download' className="hand-pointer" />
                  </a>
                </div>
              ) : null}
            </div>
          </div>


          <div className="col-4 d-flex align-items-center">
            <div className="container-fluid">
              <div className="row text-wrap">
                {"Usuario: " + detalleSeguimiento?.LicenciaData?.User}
              </div>
              <div className="row">
                {"Fecha/Hora: " + detalleSeguimiento?.LicenciaData?.DateTime}
              </div>

            </div>
          </div>
        </div>
      </div>
      <hr />
      <div >
        <div><strong>Observaciones</strong></div>
        {notes.map(note => (
          <div key={note.FechaNota + note.Nota} className="row mt-1">
            <div className="col-1">
              {
                <div className="d-flex align-items-center" >
                  <img
                    style={{ borderRadius: '50%', width: '25px' }}
                    src={note?.FotoPerfilUrl}
                    alt={note?.NombreEmpleado}
                  />
                </div>
              }
            </div>
            <div className="col-2">
              {note?.FechaNota.substring(0, 15)}
            </div>
            <div className="col-auto">
              {note?.Nota}
            </div>
          </div>
        ))}
        <div className={`${!detalleSeguimiento?.Permissions?.PuedeEditarSeccionObservaciones ? "d-none row mt-3" : "row mt-3"}`}>
          <form className="d-flex w-100 px-3" onSubmit={(e) => { e.preventDefault(); enviarNota(); }}>
            <input name="nota" className="col-10" type="text" onChange={handleChange} value={formValues.nota} />
            <button type='submit' className="col-2 generic-button-buscador p-2 ml-1">
              Enviar observación
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SeguimientoMedico