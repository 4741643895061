import React, { useState } from 'react'
import styled from 'styled-components';
import NoAprobarMotivo from '../modales/NoAprobarMotivo';
import TerminoMaternidad from '../../TerminoMaternidad';
import ModalEnabled from '../../../../../components/modalComponents/ModalEnabled';
import ActionGenericModal from '../../../../../components/modal/ActionGenericModal';
import { aprobarCondicionAPI } from '../../../../../consumers/backendApisUrls';
import ObservarMaternidad from '../modales/ObservarMaternidad';

export const DatosMaternidadActions = ({ idCondicion: IdCondicion, item, refreshMainPage, toggleMainModal, puedeObservar, preExistencia }) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  return (
    <div className="modal-footer justify-content-end pr-4">
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={refreshMainPage} />
      <FooterButtons>
        {puedeObservar && (
          <button
            type="button"
            className="btn btn-primary generic-button-ficha-modal generic-button-hover-azul"
            disabled={isSubmitting}
            onClick={() => setModalEnabled({ isEnable: true, component: ObservarMaternidad, data: { toggleMainModal, IdCondicion, preExistencia } })}
          >
            Observar
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary generic-button-ficha-modal generic-button-hover-rojo"
          disabled={isSubmitting}
          onClick={() => setModalEnabled({ isEnable: true, component: NoAprobarMotivo, data: { toggleMainModal, IdCondicion } })}
        >
          No Aprobar
        </button>
        <button
          type="button"
          className="btn btn-primary generic-button-ficha-modal generic-button-hover-verde"
          disabled={isSubmitting}
          onClick={() => setModalEnabled({ isEnable: true, component: ActionGenericModal, data: { externalCallback: toggleMainModal, title: "Aprobar", actionLabel: "aprobar", api: aprobarCondicionAPI, name: item, payload: { IdCondicion } } })}
        >
          Aprobar
        </button>
      </FooterButtons>
    </div>
  )
};

export const SeguimientoMaternidadActions = ({ refreshMainPage, toggleMainModal, idCondicion, idTipoDeCondicion }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const toggleAll = () => {
    setModalEnabled({ isEnable: false });
    toggleMainModal();
  }

  return (
    <div className="modal-footer justify-content-end pr-4">
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={refreshMainPage} />
      <FooterButtons>
        <button
          type="button"
          className="btn btn-primary generic-button-ficha-modal generic-button-hover-rojo"
          disabled={isSubmitting}
          onClick={() => setModalEnabled({ isEnable: true, component: TerminoMaternidad, data: { externalCallback: toggleAll, idCondicion, idTipoDeCondicion }})}
        >
          Terminar condición
        </button>
      </FooterButtons>
    </div>
  )
};

const FooterButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;