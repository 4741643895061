import React, { useState, useEffect } from "react";
import GenericModal from "../../../../components/modal/GenericModal";
import { getAllCargosAPI, getGrupoAPI, procesoCierreEditarGrupoAPI } from "../../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../../consumers/httpRequiestsUtils";
import { useManageGetRequest } from "../../../../hooks/useManageGetRequest/useManageGetRequest";
import { useSubmitForm } from "../../../../hooks/useSubmitForm/useSubmitForm";
import { useGetRequest } from '../../../../hooks/useGetRequest/useGetRequest';
import { mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary } from '../../../../helper/utils';
import MultiSelectInput from '../../../../components/MultiSelectInput/MultiSelectInput';


const EditarGrupo = ({
    toggleModal,
    refreshTable,
    grupoId
}) => {

    const sectionName = "EditarGrupo";
    const [nombreGrupo, setNombreGrupo] = useState('');
    const [allCargos, setAllCargos] = useState([]);
    const [cargosSeleccionados, setCargosSeleccionados] = useState([]);
    const [submitForm] = useSubmitForm();
    const [manageGetRequest] = useManageGetRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();

    const getInitialData = async () => {
        await manageGetRequest(
            getGrupoAPI(grupoId),
            (response) => {
                setNombreGrupo(response.data.NombreGrupo);
                setCargosSeleccionados(response.data.IdsCargo);
            },
            false
        )
    }

    const getAllCargos = async () => {
        try{
            await getDropdownOptions(executeGetRequestCallback(getAllCargosAPI()), setAllCargos);
        } catch(e){
            console.error(e);
        }
    };

    const getDropdownOptions = async (getFunction, setState) => {
        const response = await getFunction();
        if (responseCode200(response)) {
          let cargos = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary(response.data);
          setState(cargos);
        } else {
          mostrarMensajeDeErrorConToast(response);
        }
      };

    useEffect(() => {
        getInitialData();
    },[])

    useEffect(() => {
        getAllCargos();
    },[])


    const submit = async () => {
        let payload = armarPayload();
        let api = procesoCierreEditarGrupoAPI(grupoId);
        let keys = ["NombreGrupo", "IdsCargo"];
        await submitForm(keys, api, payload, sectionName, successSubmitCallback)
    }

    const successSubmitCallback = () => {
        refreshTable();
        toggleModal();
    }

    const armarPayload = () => {
        let payload ={
            IdGrupo: grupoId,
            NombreGrupo: nombreGrupo,
            IdsCargo: cargosSeleccionados,
        }
        return payload;
    }

    return(
        <>
            <GenericModal showModal={toggleModal} width={"1100px"} titulo={"Editar grupo predefinido"}>
                <div className="modal-body pt-0 pl-0">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label modal-label">Nombre<span className='required'>*</span></label>
                            <div className="col-8 ml-3 black-color">
                                <input type="text" className="form-control" name='Nombre' onChange={(e) => setNombreGrupo(e.target.value)} value={nombreGrupo}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-NombreGrupo-validation-message`}></p>
                            </div>
                        </div>
                        <div className="form-group row agergar-grupo-cargos">
                            <label className="col-sm-3 col-form-label modal-label">Cargos<span className='required'>*</span></label>
                            <div className="col-8 ml-3 black-color">
                                <MultiSelectInput name='IdsCargo' options={allCargos} selectedValues={cargosSeleccionados}
                                    setSelectedValues={setCargosSeleccionados} placeholder={"Seleccionar cargos"}/>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdsCargo-validation-message`}></p>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end pr-4">
                    <div className="button">
                        <button 
                            className="btn btn-primary generic-button-ficha-modal"
                            onClick={submit}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </GenericModal>
        </>
    )

}

export default EditarGrupo;