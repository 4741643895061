import React from "react";
import Select from "react-select";
import PropTypes from 'prop-types';
import { INPUT_DIRECTION } from "../../constants/hardCodedConstants";

const InputSelect = ({
  label,
  options,
  value,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
  direction = "row",
  placeholder = "Seleccionar",
}) => {
  const handleOnChange = (res) => {
    let data = {
      target: {
        value: res,
        name: name,
      },
    };
    onChange(data);
  };

  return (
    <div className={`${INPUT_DIRECTION[direction]}`}>
      <label className={`modal-label ${isRequired ? "required" : ""}`}>
        {label}
      </label>
      <div className={`black-color ${value ? "" : "highlight-input"}`}>
        <Select
          options={options ?? []}
          value={value ?? ""}
          onChange={handleOnChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
          menuPosition='fixed' // check
        />
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </div>
  );
};

InputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  sectionName: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default InputSelect;