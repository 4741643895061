import React, {useEffect, useState} from 'react';
import Wrapper from '../../components/atoms/DirectorioWrapper';
import TableWebBodyGestionFicha from "./TableWebBodyGestionFicha";
import PageHeader from "../../components/pageHeader/PageHeader";
import FormSelect from '../../components/atoms/Select';
import Paginator from "../directorio/Paginator";
import useDidMountEffect from "../../hooks/useDidMountEffect/useDidMountEffect";
import {getAllAreasAPI, getAllDepartamentosAPI, getAllNivelesAPI, getAllSedesAPI, getEspecialidadesAPI, getAllEstadosAPI, getAllCargosAPI, listarGestionFichaDatos} from "../../consumers/backendApisUrls";
import {useGetRequest} from "../../hooks/useGetRequest/useGetRequest";
import {Spinner} from "react-bootstrap";
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { notNullAndNotUndefinded } from '../../helper/utils';

const GestionFichaDatos = () => {

    const TYPES = ["Id", "NombreCompleto", "Estados", "Cargos", "Lider", "Departamentos", "Areas", "Sedes"]

    const [loading, setLoading] = useState(true);
    const [usersFiltered, setUsersFiltered] = useState([]);
    const [extraData, setExtraData] = useState([]);
    
    const [searchCriteria, setSearchCriteria] = useState("");
    const [dropdownsOptions, setDropdownOptions] = useState([]);
    const [dataFilteredOptions, setDataFilteredOptions] = useState([]);
    const [dataSelected, setDataSelected] = useState({})

    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState();
    const [firsItemPage, setFirsItemPage] = useState();
    const [lastItemPage, setLastItemPage] = useState();
    const [totalPages, setTotalPages] = useState();

    const [executePost] = useManagePostRequest();
    const [executeGetRequest] = useGetRequest();

    const getAllDataPromises = () => {
        return [  
            executeGetRequest(getAllEstadosAPI()),
            executeGetRequest(getAllCargosAPI()),
            executeGetRequest(getAllDepartamentosAPI()),
            executeGetRequest(getAllAreasAPI()),
            executeGetRequest(getAllSedesAPI()),
            executeGetRequest(getAllNivelesAPI()),
            executeGetRequest(getEspecialidadesAPI()),
        ]
    };

    const getInitialData = async () => {
        setLoading(true);
        try {
            await Promise
                    .allSettled(getAllDataPromises())
                    .then(res => res.map(o => updateSetInitialData(o)));
            await manageSearch();
        } catch (e) {
            console.error(e);
        }
        setLoading(false);
    };

    const updateSetInitialData = (res) => {
        if(res.status === "fulfilled") {
            let value = res.value.data;
            let keyName = res.value?.config?.url?.split("/").at(-1);
            let keyWithAll = keyName.split("All");
            let key = (keyWithAll.length > 1) ? keyWithAll.at(-1) : keyName.slice(3);
            setDropdownOptions(lastData => [...lastData, {key, value}])
        }
    }

    const manageSearch = async () => {
        const api = listarGestionFichaDatos();
        let payload = getPayload();

        await executePost(api, payload, successManageSearchCallback);
    };

    const getPayload = () => {
        return {
            "Criteria": searchCriteria,
            "IdsDepartamentos": dataSelected.Departamentos,
            "IdsAreas": dataSelected.Areas,
            "IdsSedes": dataSelected.Sedes,
            "IdsNiveles": dataSelected.NivelesEducativos,
            "IdsEspecialidades": dataSelected.Especialidades,
            "IdsEstados": dataSelected.Estados,
            "IdsCargos": dataSelected.Cargos,
            "Page": page}
    };

    const successManageSearchCallback = (res) => {
        let data = res.data?.data;
        setUsersFiltered(data.Items);
        setExtraData(data.ExtraData);
        setTotalItems(data.TotalItems);
        setFirsItemPage(data.FirsItemPage);
        setLastItemPage(data.LastItemPage);
        setTotalPages(data.TotalPages);
    };

    const setFilterWithExtraData = () => {
        let newData = dropdownsOptions.map(o => {
            if(extraData["Ids"+o.key]){
                let value = o.value.filter(x => extraData["Ids"+o.key]?.includes(x.Value));
                return {
                    key: o.key,
                    value: value
                }
            }
            return {key: o.key, value: o.value}
        })
        setDataFilteredOptions(newData);
    }

    const handleSelectedOptions = (key, value) => {
        setDataSelected({...dataSelected, [key]: value})
    }

    const handleKeypress = (e) => {
        if (e.key === 'Enter') {
            manageSearch();
        }
    };

    const onLeftClic = () => {
        const nextPage = Math.max(page - 1, 1);
        setPage(nextPage);
    };

    const onRightClic = () => {
        const nextPage = Math.min(page + 1, totalPages);
        setPage(nextPage);
    };

    const limpiarBusqueda = () => {
        setDataSelected({});
        setSearchCriteria("");
    };

    useDidMountEffect(() => {
        setPage(1);
    }, [searchCriteria, dataSelected]);

    useDidMountEffect(() => {
        manageSearch();
    }, [dataSelected, page]);

    useDidMountEffect(() => {
        notNullAndNotUndefinded(extraData) && setFilterWithExtraData();
    }, [extraData])

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            getInitialData();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    if (loading) return (
        <div className="spinner-cargando-container">
            <Spinner animation="grow" variant="primary" size="1sm"/>
        </div>
    );

    return (
        <Wrapper className='App'>
            <PageHeader title={"Gestión de ficha de datos"}/>
            <div className='container-fluid body-content'>
                <div className='input-group mb-3 buscador'>
                    <input
                        type='text'
                        className='input-buscador'
                        placeholder='Buscar por nombre y/o apellidos'
                        value={searchCriteria}
                        onChange={(e) => {
                            setSearchCriteria(e.target.value);
                        }}
                        onKeyUp={handleKeypress}
                    />
                    <button
                        onClick={manageSearch}
                        type='button'
                        className='btn btn-primary generic-button-ficha-modal boton-buscar-generic-listar font-gothan-light'>
                        Buscar
                    </button>
                    <a className="clean-filters " onClick={limpiarBusqueda}>Limpiar búsqueda</a>
                </div>

                <div className='pt-1'>
                    <div className="table-scroll">
                    <table className='table table-hover'>
                        <thead className="listar-thead">
                        <tr className='bg-cl-gris'>
                            {TYPES.map((o, index) => {
                                let render = dataFilteredOptions.find(filter => filter.key == o);
                                return (render?
                                    <th key={index}>
                                        <FormSelect
                                        options={render?.value}
                                        description={o.slice(0,-1)}
                                        setSelectedOptions={(res) => handleSelectedOptions(render?.key, res)}
                                        selectedOptions={dataSelected[render?.key] ?? []}
                                        />
                                    </th> : 
                                    <th key={index}>
                                        <div className='flex font-gothan-medium'>{o}</div>
                                    </th>)
                            })}
                        </tr>
                        </thead>
                        <TableWebBodyGestionFicha users={usersFiltered}/>
                    </table>
                    </div>

                    {totalPages > 1 && (
                        <Paginator totalItems={totalItems}
                                   firsItemPage={firsItemPage}
                                   lastItemPage={lastItemPage}
                                   totalPages={totalPages}
                                   page={page}
                                   onLeftClic={onLeftClic}
                                   onRightClic={onRightClic}/>
                    )}
                </div>
            </div>
        </Wrapper>
    );
};


export default GestionFichaDatos;
