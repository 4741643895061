import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cleanDropdown, initialAllOptionPromises } from '../../../helper/utils';
import { responseCode200 } from '../../../consumers/httpRequiestsUtils';
import InputDate from '../../../components/modalComponents/InputDate';
import InputText from '../../../components/modalComponents/InputText';
import FooterModal from '../../../components/modalComponents/FooterModal';
import InputSelect from '../../../components/modalComponents/InputSelect';
import InputTextArea from '../../../components/modalComponents/InputTextArea';
import InputSearchAsync from '../../../components/modalComponents/InputSearchAsync';
import InputSelectCascade from '../../../components/modalComponents/InputSelectCascade';
import InputSelectCascadePost from '../../../components/modalComponents/InputSelectCascadePost';
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { usePropsInputs } from '../../../hooks/useProps/usePropsInput';
import { usePropsSelect } from '../../../hooks/useProps/usePropsSelect';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useGetOptionsSelect } from '../../../hooks/useGetOptionsSelect/useGetOptionsSelect';
import { CARGOS, FILE_FORMATS, FILE_SIZE_2MB, SEDES, TIPOS_CONTRATO } from '../../../constants/hardCodedConstants';
import { getEditRequerimientoPersonalAPI, saveEditRequerimientoPersonalAPI, buscarColaboradorAPI, getAllCargosAPI, getAllSedesAPI, getAllTiposContratoAPI, getAreasByGerenciaAPI, getDivisionesAPI, getGerenciasByDivisionAPI, getPresupuestosAPI, getResponsablesNuevoIngresoGestionAPI, getTiposVacanteAPI, getAllEstadosAPI, consultarSedeNuevoIngresoGestionAPI, getAllJornadasLaboralesAPI, getAllTiposTrabajadorAPI, getRelatedDataTypesAPI, getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI, getEspecialidadSeleccionAPI, getAvanceRequerimientoAPI, getReemplazoByFiltersRequerimientosAPI } from '../../../consumers/backendApisUrls';
import AsyncSelectWithOtherIds from '../../../components/Select/AsyncSelectWithOtherIds';
import InputUploadFile from '../../../components/modalComponents/InputUploadFile';
import { AlertErrorPDFFile2MBSizeMessage } from '../../../components/alerts/AlertErrorMaxFileSizeMessage';

const EditarRequerimiento = ({ toggleModal, refreshPage, id }) => {

  const SECTION_NAME = "crearRequerimientoPersonal";
  const KEYS_INIT = { estadoProceso: "IdEstadoProceso", presupuesto: "IdPresupuesto", sedePrincipal: "IdSedePrincipal", sedeSecundaria: "IdSedeSecundaria", unidad: "IdUnidad", cargo: "IdCargo", tipoVacante: "IdTipoVacante", tipoContrato: "IdTipoContrato", Responsable: "IdResponsable", TipoEmpleado: "IdTipoEmpleado", TipoTrabajador: "IdTipoTrabajador", EspecialidadSeleccion: "IdEspecialidadSeleccion", AvanceRequerimiento: "IdAvanceRequerimiento", ArchivoResultadoEvaluacion: "ArchivoResultadoEvaluacion" };
  const KEYS = ["IdPresupuesto", "IdSedePrincipal", "IdSedeSecundaria", "IdUnidad", "IdGerencia", "IdArea", "IdCargo", "IdTipoVacante", "IdEmpleadoReemplazo", "IdSolicitante", "Detalle", "IdTipoTrabajador", "IdTipoEmpleado", "IdTipoContrato", "IdRegimenLaboral", "DiasxSemana", "FechaInicio", "FechaFin", "Sueldo", "IdResponsable", "IdEspecialidadSeleccion", "IdAvanceRequerimiento", "ArchivoResultadoEvaluacion"];

  const [formValues, setFormValues] = useState({});
  const [allDropdowns, setAllDropdowns] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [isFirstEnter, setIsFirstEnter] = useState(true);

  const [commomProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [commonPropsSelect] = usePropsSelect(allDropdowns, formValues, setFormValues, SECTION_NAME);

  const [getOptions] = useGetOptionsSelect();
  const [executeGet] = useManageGetRequest();
  const [executeGetBasic] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const initialPromises = () => {
    return [
      getOptions(getAllEstadosAPI()),
      getOptions(getPresupuestosAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getAllSedesAPI()),
      getOptions(getDivisionesAPI()),
      getOptions(getAllCargosAPI()),
      getOptions(getTiposVacanteAPI()),
      getOptions(getAllTiposContratoAPI()),
      getOptions(getResponsablesNuevoIngresoGestionAPI()),
      getOptions(getAllJornadasLaboralesAPI()),
      getOptions(getAllTiposTrabajadorAPI()),
      getOptions(getEspecialidadSeleccionAPI()),
      getOptions(getAvanceRequerimientoAPI()),
    ]
  }

  const init = () => {
    initialAllOptionPromises(initialPromises, Object.values(KEYS_INIT), setAllDropdowns);
    manageSearch();
  }

  const manageSearch = async () => {
    const api = getEditRequerimientoPersonalAPI(id);
    await executeGet(api, successManageSearch);
  }

  const successManageSearch = ({ data }) => {
    setFormValues(data)
    setIsFirstEnter(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true);
    try {
      const api = saveEditRequerimientoPersonalAPI();
      await submitForm(KEYS, api, formValues, SECTION_NAME, successSubmitCallback)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  const successSubmitCallback = () => {
    toggleModal();
    refreshPage();
  }

  const getDependetsTipoContrato = () => {
    return {
      IdTipoTrabajador: formValues[KEYS_INIT.TipoTrabajador],
      IdJornadaLaboral: formValues[KEYS_INIT.TipoEmpleado],
    }
  }

  useDidMountEffect( async() => {
    if (!isFirstEnter){
      let response = await executeGetBasic(consultarSedeNuevoIngresoGestionAPI(formValues.IdSedePrincipal));
      if(responseCode200(response)){
        let data = response.data;
        if(data.IdSedePrincipal !== SEDES.BACK_OFFICE){
          cleanDropdown(setFormValues, ["IdUnidad", "IdSolicitante"]);
          let idsData = {IdUnidad: data.IdDivision, Solicitante : data.EmpleadoLider, IdSolicitante: data.IdEmpleadoLider, ...data}   
          setFormValues(lastData => ({...lastData, ...idsData}));
        }
      } else {
        cleanDropdown(setFormValues, ["IdUnidad", "IdGerencia", "IdArea", "IdSolicitante"]);
      }
    }
  }, [formValues.IdSedePrincipal])

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Editar Requerimiento" saveButtonDisabled={isSending} state={<DivState {...commonPropsSelect('IdEstadoProceso')}/>}>
      <section>
        <div className="displayFlexWrap">
          <DivCol><InputSelect label='Presupuesto' {...commonPropsSelect('IdPresupuesto')}/></DivCol>
          <DivCol><InputSelect label='Sede Principal' {...commonPropsSelect('IdSedePrincipal')}/></DivCol>
          <DivCol><InputSelect label='Sede Secundaria' {...commonPropsSelect('IdSedeSecundaria')}/></DivCol>
          <DivCol><InputSelect label='Unidad' {...commonPropsSelect('IdUnidad', ['IdGerencia', 'IdArea'])}/></DivCol>
          <DivCol><InputSelectCascade label='Gerencia' dependendOption={formValues.IdUnidad} api={getGerenciasByDivisionAPI} {...commomProps('IdGerencia', ['IdArea'])}/></DivCol>
          <DivCol><InputSelectCascade label='Área' dependendOption={formValues.IdGerencia} api={getAreasByGerenciaAPI} {...commomProps("IdArea")}/></DivCol>
          <DivCol><InputSelect label='Cargo' {...commonPropsSelect('IdCargo', ["IdEspecialidadSeleccion"])}/></DivCol>
          <DivCol><InputSelect label='Tipo de Vacante' {...commonPropsSelect('IdTipoVacante')}/></DivCol>
          <DivCol><InputSearchAsync typeGet label='Reemplazo' api={buscarColaboradorAPI} valueText={formValues.EmpleadoReemplazo} {...commomProps('IdEmpleadoReemplazo')}/></DivCol>
          <DivCol><InputSelect label='Avance' {...commonPropsSelect('IdAvanceRequerimiento')}/></DivCol>
          
          {(formValues.IdCargo === CARGOS.PROFESOR || formValues.IdCargo === CARGOS.ASISTENTE_EDUCACION) &&
            <DivCol required><InputSelect label="Especialidad Selección Ingreso" {...commonPropsSelect("IdEspecialidadSeleccion")}/></DivCol>
          }
        </div>
      </section>
      <section className='row mt-2'>
        <label className='mb-3 col-sm-12'><strong>Detalle del Requerimiento:</strong></label>
        <DivCol>
          <InputSearchAsync typeGet label='Solicitante' api={buscarColaboradorAPI} valueText={formValues.Solicitante} {...commomProps('IdSolicitante')}/>
        </DivCol>
        <DivCol>
          <InputUploadFile 
            label="Archivo Resultado Evaluación" 
            name={KEYS_INIT.ArchivoResultadoEvaluacion} 
            uploadFile={formValues[KEYS_INIT.ArchivoResultadoEvaluacion]} 
            setUploadFile={(res) => 
              setFormValues({...formValues, [KEYS_INIT.ArchivoResultadoEvaluacion]: res})} 
            sectionName={SECTION_NAME} 
            setIsUploading={setIsSending} 
            validExtensions={FILE_FORMATS.PDF_FORMAT}
            maxFileSize={FILE_SIZE_2MB}
            ToastArchivoNoValido={AlertErrorPDFFile2MBSizeMessage}/>
        </DivCol>
        <div className='col-sm-12'><InputTextArea label='Detalle' {...commomProps('Detalle')} direction='column'/></div>
      </section>
      <section className='mt-2'>
        <label className='mb-3 col-sm-12'><strong>Datos de la asignación:</strong></label>
        <div className='displayFlexWrap'>
          <DivCol><InputSelect label="Tipo Trabajador" {...commonPropsSelect("IdTipoTrabajador", ["IdTipoContrato", "IdRegimenLaboral"])}/></DivCol>
          <DivCol><InputSelect label="Jornada Laboral" {...commonPropsSelect("IdTipoEmpleado", ["IdTipoContrato", "IdRegimenLaboral"])}/></DivCol>
          <DivCol><InputSelectCascadePost label="Tipo Contrato" dependendOption={getDependetsTipoContrato()} api={getTiposContratoByIdTipoTrabajadorAndIdJornadaLaboralAPI} {...commonPropsSelect("IdTipoContrato", ["IdRegimenLaboral", "DiasxSemana", "FechaFin"])}/></DivCol>
          <DivCol><InputSelectCascade label="Régimen Laboral" dependendOption={formValues["IdTipoContrato"]} api={getRelatedDataTypesAPI} {...commomProps("IdRegimenLaboral")}/></DivCol>

          {TIPOS_CONTRATO.PART_TIME.includes(formValues.IdTipoContrato) &&
            <DivCol><InputText label='Días por semana' {...commomProps('DiasxSemana')}/></DivCol>
          }

          <DivCol><InputSelect label='Responsable' {...commonPropsSelect('IdResponsable')}/></DivCol>
          {formValues[KEYS_INIT.tipoContrato] === TIPOS_CONTRATO.SUPLENCIA &&
            <>
              <DivCol><InputDate label='Fecha Inicio' {...commomProps('FechaInicio')}/></DivCol>
              <DivCol><InputDate label='Fecha de Fin' {...commomProps('FechaFin')}/></DivCol>
            </>
          }
        </div>
      </section>
    </ModalForm>
  )
}


export default EditarRequerimiento;

const DivCol = ({ children }) => (
  <div className='col-sm-6'>
    {children}
  </div>
)

const DivState = ({ value }) => {
  return (
    <div className='modal-state'>
      {value &&
        <>
          <strong>Estado:</strong>
          <div className='mr-3'>
            {value ?? ""}
          </div>
        </>
      }
    </div>
  )
}

const ModalForm = ({ onSubmit, showModal, children, width, titulo, saveButtonDisabled, state, hasSaveButton = true }) => {

  return (
    <div className="ficha-modal-solicitar-cambios custom-scroll" style={{'width': `${width}`}}>
      <div className="modalHeader">
        <div className="title">
          <p className="font-gothan-bold">{titulo}</p>
        </div>
        <div className='infos'>
          {state}
          <div className="icon">
            <FontAwesomeIcon
              icon="times-circle"
              className="hand-pointer"
              transform="left-9"
              onClick={() => showModal()}
            />
          </div>
        </div>
      </div>
      <div className="modalContent">
        <div className="modal-body pt-0 pl-0">
          <form onSubmit={onSubmit}>
            {children}
            {hasSaveButton && <FooterModal isDisabled={saveButtonDisabled}/>}
          </form>
        </div>
      </div>
    </div>
  );
};