import React, {useEffect, useState} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {useForm} from "../../../hooks/useForm/UseForm";
import {getObservarProcesoAPI, procesoCierreObservarProcesoAPI} from "../../../consumers/backendApisUrls";
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";
import {useManageGetRequest} from "../../../hooks/useManageGetRequest/useManageGetRequest";
import SelectInput from "../../../components/Select/SelectInput";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric} from "../../../helper/utils";

const ObservarRegistroModal = ({toggleModal, idProcesoCierreEmpleado, refreshTable}) => {
    const [submitForm] = useSubmitForm();
    const sectionName = "ObservarRegistroModal";
    const [manageGetRequest] = useManageGetRequest();
    const [formConfigData, setFormConfigData] = useState({});
    const [destinosOptions, setDestinosOptions] = useState([]);
    const [jsonSelectedDestino, setJsonSelectedDestino] = useState({});
    const [disableInputDestino, setDisableInputDestino] = useState(true);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const sucessCallbackGetObservarProceso = function (response) {
        setFormConfigData(response.data);
        let optionsMappedTemp = mapDropDownOptionsFromBackendToSelectOptionsNeededForLibraryGeneric(response.data.Destinos, "Value", "Text");
        setDestinosOptions(optionsMappedTemp);
        setDisableInputDestino(!response.data?.PuedeEscogerDestino);
        setJsonSelectedDestino({"label": response?.data?.DestinoObservacion, "value": response?.data?.IdDestinoObservacion})
    }

    const init = async function () {
        await manageGetRequest(getObservarProcesoAPI(idProcesoCierreEmpleado), sucessCallbackGetObservarProceso, false);
    };

    const initialFormValues = {
        "Observacion": ""
    };

    const [formValues, setFormValues] = useState(initialFormValues);
    const [handleChange] = useForm(formValues, setFormValues);

    const successResponseCallback = (response) => {
        refreshTable();
        toggleModal();
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        let payload = {
            "IdProcesoCierreEmpleado": idProcesoCierreEmpleado,
            "Observacion": formValues.Observacion,
            "IdDestinoObservacion": jsonSelectedDestino?.value
        };

        let keyFiles = ["Observacion", "IdDestinoObservacion"];
        await submitForm(keyFiles, procesoCierreObservarProcesoAPI(), payload, sectionName, successResponseCallback)

    };

    return (
        <>
            <GenericModal showModal={toggleModal} width={"700px"} titulo="Observar">
                <div className="margin-modal-body">
                    <div>
                        <form className="black-color-important">
                            <div className="row custom-form-group-modal mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Destino<span className="required"></span></label>
                                <div className="col-sm-9 flex-row justify-content-center ">
                                    <SelectInput options={destinosOptions} jsonSelectedValue={jsonSelectedDestino} setJsonSelectedValue={setJsonSelectedDestino} placeholder={"Seleccionar"} isDisabled={disableInputDestino}/>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-IdDestinoObservacion-validation-message`}></p>
                                </div>
                            </div>
                            <div className="row custom-form-group-modal mb-0">
                                <label className="col-sm-3 col-form-label modal-label">Observación<span className="required">*</span></label>
                                <div className="col-sm-9 flex-row justify-content-center ">
                                    <textarea className="form-control" rows="5" name="Observacion" onChange={handleChange} defaultValue={formValues.Observaciones} maxLength="200" placeholder={"Máximo 200 caracteres."}></textarea>
                                    <p className="ficha-form-input-validation-message" id={`ficha-edit-${sectionName}-Observacion-validation-message`}></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer d-flex justify-content-between pt-0 pb-0">
                    <div className="message font-gothan-light align-self-start">
                    </div>
                    <div className="button align-self-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={onSubmit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ObservarRegistroModal;

