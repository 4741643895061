import React, { useState } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputBool from '../../../../components/modalComponents/InputBool';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { asignarEquipoNuevosIngresosAdminAPI } from '../../../../consumers/backendApisUrls';

const AsignarEquipos = ({ toggleModal, manageSearch, EsSupervisorSoporteOAdmin = false, EsAsistenteSoporte = false, IdNuevosIngresosEmpleado, AsignacionEquipoValue = false, EquiposAsignados = [] }) => {


  const SECTION_NAME = "AsignarEquiposNuevosIngresos";
  const KEYS = { id: "IdNuevosIngresosEmpleado", asignacionEquipo: "AsignacionEquipo", equiposAsignados: "EquiposAsignados" };

  const [formValues, setFormValues] = useState({ [KEYS.asignacionEquipo]: AsignacionEquipoValue, [KEYS.equiposAsignados]: EquiposAsignados });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);
  const [submitForm] = useSubmitForm();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = asignarEquipoNuevosIngresosAdminAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const EquiposAsignados = formValues[KEYS.equiposAsignados]?.length ? formValues[KEYS.equiposAsignados].join(",") : "";
    return {
      ...formValues,
      EquiposAsignados,
      IdNuevosIngresosEmpleado,
    }
  }

  const handleSuccessSubmit = async () => {
    toggleModal()
    await manageSearch();
  }

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo="Asignar Equipos" width="700px" hasSaveButton={EsAsistenteSoporte || EsSupervisorSoporteOAdmin} isSubmitting={isSubmitting}>
      <InputBool label='Asignación de Equipos' {...commonProps(KEYS.asignacionEquipo, [KEYS.equiposAsignados])} isDisabled={!EsSupervisorSoporteOAdmin} />
      {formValues[KEYS.asignacionEquipo] &&
        <InputMultiCheckbox label="Equipos" options={AllEquipos} {...commonProps(KEYS.equiposAsignados)} values={formValues[KEYS.equiposAsignados]} isDisabled={!(EsAsistenteSoporte || EsSupervisorSoporteOAdmin)} />
      }
    </ModalForm>
  )
}

export default AsignarEquipos;

const AllEquipos = [
  {value: "Laptop", label: "Laptop"},
  {value: "Celular", label: "Celular"},
  {value: "Modem", label: "Modem"},
]

const InputMultiCheckbox = ({
  label,
  options,
  values,
  onChange,
  name,
  sectionName,
  isRequired,
  isDisabled,
}) => {
  const handleCheck = ({ target }) => {
    const { value, checked } = target;
    let temp = checked
    ? [...(values ?? ""), value]
    : values.filter((o) => o !== value);
    let selected = {
      target: {
        value: temp,
        name: name,
      },
    };
    onChange(selected);
  };

  return (
    <>
      <div className="form-group">
        <label className={`col-sm-12 pl-1 col-form-label modal-label ${isRequired ? "required" : ""}`}>
          {label}
        </label>
      </div>
      <div className="form-group d-flex justify-content-around align-items-center overflow-y-scroll" style={{maxHeight: "360px"}}>
        {options.map((option, i) => (
          <div
            key={`${option.label}-${i}-${option.value}`}
            className="black-color scrollBarAsignarTipoContrato d-flex align-items-start mb-3"
          >
            <input
              id={`${option.label}-${i}-${option.value}`}
              type="checkbox"
              name={name}
              value={option.value}
              checked={values?.includes(option.value) ?? false}
              onChange={handleCheck}
              disabled={isDisabled}
              className="mt-2"
            />
            <label
              className="black-color font-gothan-light pl-3"
              htmlFor={`${option.label}-${i}-${option.value}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <p
          className="ficha-form-input-validation-message"
          id={`ficha-edit-${sectionName}-${name}-validation-message`}
        ></p>
      </div>
    </>
  );
};