import React, {useContext} from 'react';
import styled from 'styled-components';
import {AuthContext} from "../../hooks/context/authContext";

const Wrapper = styled.div`
  height: 75px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background: #565656;
  /* box-shadow: 0px -2px 16px rgba(0, 0, 0, 0.25); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #ffffff;

  @media (max-width: 992px) {
    font-size: 0.75rem;
    height: 65px;
  }
  
  @media (max-width: 650px) {
    height: auto;
  }
`;

const Button = styled.div`
  border-radius: 0.25rem;
  color: #fff;
  background-color: #9ebf43;
  border-color: #9ebf43;
  padding: 0.375rem 1.5rem;
`;
const Cookies = (props) => {
    const {state} = useContext(AuthContext);
    return (
        <Wrapper>
            <span>{state.cookiesMessage}</span>
            <Button onClick={props.cookies}>Si autorizo</Button>
        </Wrapper>
    );
};

export default Cookies;
