import React, { useState } from "react";
import GenericModal from "../../../components/modal/GenericModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MensajeConfirmacionModal = ({ toggleModal, handleSend, dataToModal }) => {

  const [isSending, setIsSending] = useState(false);

  const handleSendData = async (e) => {
    setIsSending(true);
    try {
      await handleSend(e)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
    }
  }

  return (
    <GenericModal showModal={toggleModal} width="500px" titulo={`Confirmación`}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          Se va a {dataToModal.estado} la siguiente solicitud, con periodo:
          <ul>
            {dataToModal.periodo?.map((o, i) => (
              <li key={i}>
                <strong>{o}</strong>
              </li>
            ))}
          </ul>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={() => toggleModal()}
            disabled={isSending}
          >
            Cancelar
          </button>
          <button
            className={`btn btn-primary generic-button-ficha ${isSending ? "pr-2" : ""}`}
            onClick={(e) => handleSendData(e)}
            disabled={isSending}
          >
            Confirmar {isSending && (<FontAwesomeIcon icon='spinner' transform="left-9" className="hand-pointer spinner ml-3"/>)}
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default MensajeConfirmacionModal;
