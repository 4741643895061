import {toast} from "react-toastify";
import React from "react";

export const showToastError = function (ToastArchivoNoValido) {
    toast.error( <ToastArchivoNoValido/>, {
        position: "top-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });
};