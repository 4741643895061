import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import PrePostNatal from '../../components/PrePostNatal'
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import { usePropsInputs } from '../../../../../hooks/useProps/usePropsInput';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import { getCondicionToEditAPI, guardarPrePostNatalAPI, toggleValidarFechasPrePostNatalAPI } from '../../../../../consumers/backendApisUrls';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';

const SECTION_NAME = "detallePrePostNatalMaternidad";
const KEYS = Object.freeze({ fechaInicio: "FechaInicioPrePostNatal", fechaFin: "FechaFinPrePostNatal", sustento: "PrePostNatalFileViewModel" });
const idCheckBox = "toggleValidarRegistroPrePostNatalMaternidad"

const PrePostNatalMaternidad = ({ idCondicion: IdCondicion, showValidar = false, isEditable = false, refreshMainPage, toggleMainModal }) => {
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitForm] = useSubmitForm();

  const [executePost] = useManagePostRequest();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const init = async () => {
    setIsLoading(true);
    manageSearch();
    setIsLoading(false);
  }

  const manageSearch = async () => {
    const api = getCondicionToEditAPI();
    const payload = { IdCondicion };
    await executePost(api, payload, successManageSearch);
  }

  const successManageSearch = ({ data }) => {
    if (data[KEYS.fechaInicio] && data[KEYS.fechaFin]){
      const { FechaInicioPrePostNatal, FechaFinPrePostNatal, SustentoPrePostNatalArchivo: PrePostNatalFileViewModel, FechasPrePostNatalSonValidas } = data;
      setFormValues({ FechaInicioPrePostNatal, FechaFinPrePostNatal, PrePostNatalFileViewModel, FechasPrePostNatalSonValidas });
    }
  }

  const handleChangeSubmit = async () => {
    setIsSubmitting(true);
    const api = toggleValidarFechasPrePostNatalAPI();
    const payload = { IdCondicion };
    try {
      await executePost(api, payload, handleSuccessChange)
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleSuccessChange = async () => {
    await refreshMainPage();
    toggleMainModal();
  }

  const handleEdit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = guardarPrePostNatalAPI();
    const payload = getPayload();    
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successEditCallback);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    return {
      IdCondicion,
      [KEYS.fechaInicio]: formValues[KEYS.fechaInicio],
      [KEYS.fechaFin]: formValues[KEYS.fechaFin],
      [KEYS.sustento]: formValues[KEYS.sustento],
    }
  }

  const successEditCallback = async () => {
    await refreshMainPage();
    toggleMainModal();
  }

  useEffect(() => {
    init();
  }, [])

  if (isLoading) return <SpinnerLoading />

  return (
    <form onSubmit={handleEdit}>
      <div className={`px-5 ${isEditable ? 'pt-4' : 'py-4'}`}>
        <PrePostNatal
          formValues={formValues}
          setFormValues={setFormValues}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          sectionName={SECTION_NAME}
          commomProps={commonProps}
          keys={KEYS}
          isEditable={isEditable}
        />
      </div>
      {showValidar && (
        <div className="text-right pb-3 pr-4">
          <input
            id={idCheckBox}
            type="checkbox"
            disabled={!isEditable || isLoading || isUploading || isSubmitting}
            checked={formValues["FechasPrePostNatalSonValidas"] || false}
            onChange={handleChangeSubmit}
          />
          <label htmlFor={idCheckBox}>Validar</label>
        </div>
      )}
      {isEditable && (
        <div className="modal-footer justify-content-end pr-4">
          <button
            type="submit"
            className="btn btn-primary generic-button-ficha-modal"
            disabled={!isEditable || isSubmitting || isUploading}>
            Guardar
          </button>
        </div>
      )}
    </form>
  );
}

export default PrePostNatalMaternidad;

const FooterButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  label {
    margin: 0;
    font-weight: bold;
  }
`;