import React from 'react'
import { dayAdded } from './HardCodedConstants';
import { getddMMYY } from './Utils';
import GenericModal from '../../../../../components/modal/GenericModal';
import { useManagePostRequest } from '../../../../../hooks/useManagePostRequest/useManagePostRequest';
import { eliminarSolicitudVacacionesAPI } from '../../../../../consumers/backendApisUrls';

const MensajeDiasAdicionalesModal = ({ toggleModal, formValues, setAddedDates, FechasAdicionales = [], MostrarMensaje = "Se van a agregar los siguientes días a la solicitud de vacaciones:", setIsAcceptedAdditionalDays, isLoading, idSolicitudMovimiento }) => {

  const [executePost] = useManagePostRequest();

  let FechasAdicionalesToEdit = [...FechasAdicionales];
  const {FechaExtraDesde, FechaExtraHasta} = FechasAdicionalesToEdit[0];
  
  const handleAceptaDiasAdicionales = () => {
    let FechasAdded = {};
    const isFechaReemplazo = formValues.FechaDesde === FechaExtraDesde;
    if (isFechaReemplazo){
      FechasAdded.FechaHasta = FechaExtraHasta
      FechasAdicionalesToEdit.shift();
    }
    if(FechasAdicionalesToEdit.length){
      FechasAdicionalesToEdit.forEach((day, index) => {
        FechasAdded[`FechaDesde${dayAdded[index]}`] = day.FechaExtraDesde;
        FechasAdded[`FechaHasta${dayAdded[index]}`] = day.FechaExtraHasta;
      });
    }
    toggleModal();
    setIsAcceptedAdditionalDays(true);
    setAddedDates(lastData => ({...lastData, ...FechasAdded}));
  }

  const handleCancelarDiasAdicionales = () => {
    const api = eliminarSolicitudVacacionesAPI(idSolicitudMovimiento);
    executePost(api, {}, ()=>{}, false);
    toggleModal();
  }

  return (
    <GenericModal showModal={toggleModal} width="700px" titulo={`Confirmación`}>
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          {MostrarMensaje}
          <ul className='mt-3'>
            {FechasAdicionales.map((o, i) => (
              <li key={i}>
                <strong>{getddMMYY(o.FechaExtraDesde)} - {getddMMYY(o.FechaExtraHasta)}</strong>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="modal-footer d-flex justify-content-end">
        <div className="button">
          <button
            className="btn btn-primary generic-button-ficha mr-2"
            onClick={() => handleCancelarDiasAdicionales()}
            disabled={isLoading}
          >
            Cancelar
          </button>
          <button
            className="btn btn-primary generic-button-ficha"
            onClick={() => handleAceptaDiasAdicionales()}
            disabled={isLoading}
          >
            Confirmar
          </button>
        </div>
      </div>
    </GenericModal>
  );
};

export default MensajeDiasAdicionalesModal;