import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { asignacionFamiliarAdminAprobarSolicitudAPI, asignacionFamiliarAdminRechazarSolicitudAPI } from '../../consumers/backendApisUrls'

const TableWebBody = ({ filas, showDetailModal, handleSelectRow, handleActionRow }) => {
  return (
    <tbody className="listar-tbody">
      {filas.map(( fila, i ) => (
        <tr key={`${i}-${fila.IdSolicitudAsignacionFamiliar}`}>
          <td className='text-center'>
            <div className='d-flex justify-content-center align-items-center'>
              <input type="checkbox" checked={fila.isChecked ?? false} value={fila.IdSolicitudAsignacionFamiliar} onChange={handleSelectRow}/>
            </div>
          </td>
          <td>
            <div className='d-flex flex-column'>  
              <label className='mb-0'>{fila.TipoDocumento}</label>
              <label className='mb-0'>{fila.NumeroDocumento}</label>
            </div>
          </td>
          <td>{fila.NombreCompleto}</td>
          <td>{fila.Cargo}</td>
          <td>{fila.Sede}</td>
          <td className='text-center'>{fila.FechaRegistro}</td>
          <td>
            <div className='d-flex flex-column'>
              <label className='mb-0'>{fila.TipoDocumentoHijo}</label>
              <label className='mb-0'>{fila.NumeroDocumentoHijo}</label>
            </div>
          </td>
          <td>{fila.NombreHijo}</td>
          <td className='text-center'>{fila.EstadoAprobacion}</td>
          <td className='text-center'>
            <div className='d-flex flex-column'>  
              <label className='mb-0'>{fila.VigenciaStartDate}</label>
              <label className='mb-0'>{fila.VigenciaEndDate}</label>
            </div>
          </td>
          <td className='text-center'>
            {fila.TipoSolicitudAsignacionFamiliar ?? "-"}
          </td>
          <td>
            <div className='d-flex justify-content-center' style={{gap: "10px"}}>
              {fila.PuedeAprobar &&
                <FontAwesomeIcon
                  icon={faCheck}
                  className='blue-standard-color'
                  size='lg'
                  title='Aprobar'
                  onClick={() => handleActionRow({ fila, api: asignacionFamiliarAdminAprobarSolicitudAPI, title: "Aprobar", actionLabel: "aprobar" })}
                />
              }
              {fila.PuedeRechazar && 
                <FontAwesomeIcon
                  icon="times"
                  className='blue-standard-color'
                  size='lg'
                  title='Rechazar'
                  onClick={() => handleActionRow({ fila, api: asignacionFamiliarAdminRechazarSolicitudAPI, title: "Rechazar", actionLabel: "rechazar", isMotivo: true })}
                />
              }
              <FontAwesomeIcon 
                title='Ver Detalle'
                className='blue-standard-color'
                icon='file-alt'
                size='lg'
                onClick={() => showDetailModal(fila.IdSolicitudAsignacionFamiliar, fila.PuedeEditar)}
              />
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  )
}

export default TableWebBody