import React, { useEffect, useState } from 'react'
import TableMaternidad from './TableMaternidad';
import CrearMaternidad from './modales/CrearMaternidad';
import PageHeader from '../../components/pageHeader/PageHeader';
import PageButtons from '../../components/pageComponents/PageButtons';
import PageWrapper from '../../components/pageComponents/PageWrapper';
import ModalEnabled from '../../components/modalComponents/ModalEnabled';
import SearchContainer from '../../components/pageComponents/SearchContainer';
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import { useGetRequest } from '../../hooks/useGetRequest/useGetRequest';
import { useManagePostRequest } from '../../hooks/useManagePostRequest/useManagePostRequest';
import { initialAllOptionPromises, jsonNoEstaVacio, updateFiltersWithExtraData } from '../../helper/utils';
import { getAllCargosAPI, getAllEstadosAPI, getAllSedesAPI, getTipoDeCondicionAPI, getListaMisCondicionesAPI, getMotivoTerminoMaternidadAPI } from '../../consumers/backendApisUrls';
import CustomAlert from '../../components/alerts/CustomAlert';

const INIT_KEYS = { cargos: "IdsCargos", sedes: "IdsSedes", estadosAprobacion: "IdsEstadosAprobacion", estadoProceso: "IdsEstadosProceso", condicion: "IdsTipoDeCondicion", motivo: "IdsMotivoTerminoMaternidad", preExistencia: "IdsPreExistencia", puedeCrearMaternidad: "PuedeCrearMaternidad" };

const MiRegistroMaternidad = () => {

  const [formValues, setFormValues] = useState({ Items: [], ExtraData: {}, filters: [], selectedFilters: {} });
  const [allFilters, setAllFilters] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({ CurrentPage: 1 });
  const [isLoading, setIsLoading] = useState(true);

  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });

  const [executeGet] = useGetRequest();
  const [executeManagePost] = useManagePostRequest();

  const initialPromises = () => {
    return [
      executeGet(getAllCargosAPI()),
      executeGet(getAllSedesAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getAllEstadosAPI()),
      executeGet(getTipoDeCondicionAPI()),
      executeGet(getMotivoTerminoMaternidadAPI())
    ]
  }

  const init = async () => {
    setIsLoading(true);
    try {
      initialAllOptionPromises(initialPromises, Object.values(INIT_KEYS), setAllFilters);
      await manageSearch();
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const manageSearch = async () => {
    const api = getListaMisCondicionesAPI();
    const payload = getPayload();
    await executeManagePost(api, payload, successManageSearchCallback);
  }

  const getPayload = () => {
    return {
      Criteria: searchCriteria,
      Page: paginator.CurrentPage,
      ...formValues.selectedFilters
    }
  }

  const successManageSearchCallback = ({ data }) => {
    const { Items, ExtraData, TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage } = data;
    setFormValues(lastData => ({ ...lastData, Items, ExtraData }));
    setPaginator({ TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage });
  }

  const handleUpdatedFilters = (res) => {
    setFormValues(lastData => ({ ...lastData, filters: res }))
  }

  const handleCleanSearch = () => {
    setFormValues({ ...formValues, selectedFilters: null });
  }

  useDidMountEffect(() => {
    manageSearch();
  }, [paginator.CurrentPage, formValues.selectedFilters])

  useDidMountEffect(() => {
    jsonNoEstaVacio(formValues.ExtraData) && updateFiltersWithExtraData(allFilters, formValues.ExtraData, handleUpdatedFilters);
  }, [formValues.ExtraData])

  useEffect(() => {
    init();
  }, [])

  return (
    <PageWrapper isLoading={isLoading}>
      <ModalEnabled
        modalEnabled={modalEnabled}
        setModalEnabled={setModalEnabled}
        refreshPage={manageSearch}
      />
      <PageHeader title="Listado de registros de maternidad" />
      {formValues.ExtraData[INIT_KEYS.puedeCrearMaternidad] ? (
        <>
          <SearchContainer
            searchCriteria={searchCriteria}
            setSearchCriteria={setSearchCriteria}
            setPaginator={setPaginator}
            manageSearch={manageSearch}
            addCleanSearch={handleCleanSearch}>
            <PageButtons
              hasCreateButton
              handleCreateButton={() =>
                setModalEnabled({ isEnable: true, component: CrearMaternidad })
              }
            />
          </SearchContainer>
          <TableMaternidad
            formValues={formValues}
            setFormValues={setFormValues}
            extraData={formValues.ExtraData}
            setModalEnabled={setModalEnabled}
            paginator={paginator}
            setPaginator={setPaginator}
            keys={INIT_KEYS}
            isAdmin={false}
          />
        </>
      ) : (
        <CustomAlert variant={"info"} isDismissible={false}>
          Esta opción no está disponible para ti
        </CustomAlert>
      )}
    </PageWrapper>
  );
}

export default MiRegistroMaternidad