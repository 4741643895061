import React, { useState, useEffect } from 'react'
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import SearchContainer from '../../../components/pageComponents/SearchContainer';
import ModalEnabled from '../../../components/modalComponents/ModalEnabled';
import PageButtons from '../../../components/pageComponents/PageButtons';
import PageWrapper from '../../../components/pageComponents/PageWrapper';
import PageHeader from '../../../components/pageHeader/PageHeader';
import PageTable from '../../../components/pageComponents/PageTable';
import TableWebBody from './TableWebBody';
import CrearPresupuesto from './modales/CrearPresupuesto';
import { presupuestoIndexGestionRequerimientoAPI } from '../../../consumers/backendApisUrls';

const ConfigurarPresupuesto = () => {

  const [formValues, setFormValues] = useState([]);
  const [modalEnabled, setModalEnabled] = useState({isEnable: false});
  const [loading, setLoading] = useState(true);

  const [searchCriteria, setSearchCriteria] = useState("");
  const [paginator, setPaginator] = useState({CurrentPage: 1});

  const [executePost] = useManagePostRequest();

  const init = async () => {
    setLoading(true);
    try {
      manageSearch()
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const manageSearch = async () => {
    const api = presupuestoIndexGestionRequerimientoAPI();
    let payload = {Page: paginator.CurrentPage, Criteria: searchCriteria};
    await executePost(api, payload, successManageSearchCallback, false);
  };

  const successManageSearchCallback = async (response) => {
    setFormValues(response.data.Items);
    const {TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage} = response.data;
    setPaginator({TotalItems, FirsItemPage, LastItemPage, TotalPages, CurrentPage});
  }

  const handleCreatePresupuesto = () => {
    setModalEnabled({isEnable: true, component: CrearPresupuesto});
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <PageWrapper isLoading={loading}>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled} refreshPage={manageSearch}/>
      <PageHeader title="Configuración de Presupuesto"/>

    <SearchContainer searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPaginator={setPaginator} manageSearch={manageSearch}>
      <PageButtons hasBackButton hasCreateButton handleCreateButton={handleCreatePresupuesto}/>
    </SearchContainer>

    <PageTable headers={tableHeaders} paginator={paginator} setPaginator={setPaginator}>
      <TableWebBody filas={formValues} setModalEnabled={setModalEnabled} manageSearch={manageSearch} />
    </PageTable>
  </PageWrapper>
  )
}

export default ConfigurarPresupuesto;

const tableHeaders = [
  {label: "Nombre", col: "col-4"},
  {label: "FechaInicio", col: "col-2"},
  {label: "FechaFin", col: "col-2"},
  {label: "Estado", col: "col-2"},
  {label: "Acciones", col: "col-2"},
]