import React, { useState, useEffect } from 'react';
import GenericModal from '../../../components/modal/GenericModal';
import { getActividadVideoAPI, saveBasicActivityAPI } from '../../../consumers/backendApisUrls';
import { useManageGetRequest } from '../../../hooks/useManageGetRequest/useManageGetRequest';
import { useManagePostRequest } from '../../../hooks/useManagePostRequest/useManagePostRequest';
import MensajeConfirmacionModal from './MensajeConfirmacionModal';

const LinkCapacitacionEncuestaTeletrabajo = ({showModal, IdEmpleadoFlujoActividad, manageSearch}) => {

  const ACTIVITY_DATA = {Title: "capacitación", Activity: "Seguridad y salud en el trabajo"}

  const [formValues, setFormValues] = useState({});
  const [dataToSend, setDataToSend] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMensajeConfirmacionModal, setShowMensajeConfirmacionModal] = useState(false);

  const [executeGet] = useManageGetRequest();
  const [executePost] = useManagePostRequest();

  const init = async () => {
    try {
      let api = getActividadVideoAPI(IdEmpleadoFlujoActividad);
      await executeGet(api, (res) => setFormValues(res.data));
    } catch (error) {
      console.error(error);
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const api = saveBasicActivityAPI();
      let payload = {IdEmpleadoFlujoActividad};
      await executePost(api, payload, successOnSubmitCallback)
    } catch (error) {
      console.error(error);
    }
    setIsSubmitting(false);
  }

  const successOnSubmitCallback = () => {
    manageSearch();
    showModal();
  }

  const toggleMensajeConfirmacion = () => {
    setDataToSend({
      ActivityTitle: ACTIVITY_DATA.Title,
      Activity: ACTIVITY_DATA.Activity
    })
    setShowMensajeConfirmacionModal(!showMensajeConfirmacionModal)
  }

  useEffect(() => {
    let unmounted = false;
    if(!unmounted) init();

    return () => {
      unmounted = true
    }

  },[])

  return (
    <>
      {showMensajeConfirmacionModal &&
        <div className="modal-contactanos modal-response-ficha-background">
          <MensajeConfirmacionModal 
            showModal={toggleMensajeConfirmacion}
            handleSend={onSubmit} 
            dataToSend={dataToSend}
            isSending={isSubmitting}/>
        </div>
      }
      <GenericModal showModal={showModal} width={"700px"} titulo="Capacitación">
        <div className="margin-modal-body">
          <form>
            <div className="row ml-1 mr-2 mb-3 blue-standard-color">
              Paso 1 - Visualiza el video <br></br>
              Mira el siguiente video que te capacitará sobre las condiciones de Seguridad y Salud en el Trabajo (modalidad teletrabajo) y que te ayudará a responder la encuesta que te compartimos en el Paso 3.
            </div>
            <div className="row custom-form-group-modal justify-content-center align-items-center mb-3 mr-3">
              <iframe width="700" height="400" style={{border: "0"}}
                src={`https://www.youtube.com/embed/${formValues?.KeyVideo}`}
                title="Capacitación" 
                allow="autoplay; clipboard-write; encrypted-media; web-share" 
                allowFullScreen>
              </iframe>
            </div>
            <div className="row ml-1 mr-2 mb-3 blue-standard-color">
                Una vez que hayas visualizado el video, presiona el botón completar para ir a la actividad 2.
              </div>
            <div className="modal-footer justify-content-end">
              <div className="button">
                <button className="generic-button-buscador" type="button" onClick={toggleMensajeConfirmacion} disabled={isSubmitting}>
                  Completar
                </button>
              </div>
            </div>
          </form>
        </div>
      </GenericModal>
    </>
  )
}

export default LinkCapacitacionEncuestaTeletrabajo