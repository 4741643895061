import React, { useContext, useEffect, useState } from 'react';
import GenericUploadFileV2 from '../../../components/GenericUploadFile/GenericUploadFileV2';
import GenericModal from '../../../components/modal/GenericModal';
import { descargarFormatoCargaColaboradoresAPI, getCargarResutadosEvaluacion, saveCargarColaboradoresAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { jsonEstaVacio, setStateWithCallBackResponse } from '../../../helper/utils';
import { AuthContext } from '../../../hooks/context/authContext';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { useGetRequest } from '../../../hooks/useGetRequest/useGetRequest';
import { toast } from 'react-toastify';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import { useGenerarDescargarEliminarArchivo } from '../../../hooks/useGenerarDescargarEliminarArchivo/useGenerarDescargarEliminarArchivo';
import {useSubmitForm} from "../../../hooks/useSubmitForm/useSubmitForm";

const CargarColaboradores = ({ showModal, refreshTable }) => {
    const UPDATE_REGISTROS_EVALUACION_INPUT_FILE = "ficha-edit-carga_colaboradores-file";
    const EXTENSIONES_VALIDAS = ["xls", "xlsx"];
    const SECTION_NAME = "CargarColaboradores";
    const [executePostRequest] = usePostRequest();
    const [executeGetRequest, executeGetRequestCallback] = useGetRequest();
    const { state } = useContext(AuthContext);
    const [uploadFile, setUploadFile] = useState();
    const [procesoCierre, setProcesoCierre] = useState({});
    const allInputKeys = ["File"];
    const [generarDescargarEliminarArchivo] = useGenerarDescargarEliminarArchivo();
    const [submitForm] = useSubmitForm();

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    const init = async () => {
        await setStateWithCallBackResponse(executeGetRequestCallback(getCargarResutadosEvaluacion()), setProcesoCierre);
    };

    const downloadFormato = async () => {
        await generarDescargarEliminarArchivo(descargarFormatoCargaColaboradoresAPI(), "");
    }

    const submit = async (event) => {
        event.preventDefault();
        let payload = {
            "File": !jsonEstaVacio(uploadFile) ? uploadFile : null,
        };
        await submitForm(allInputKeys, saveCargarColaboradoresAPI(), payload, SECTION_NAME, successSubmitCallback);
    };

    const successSubmitCallback = () => {
        refreshTable();
        showModal();
    }

    return (
        <>
            <GenericModal showModal={showModal} width={"750px"} titulo={"Cargar colaboradores"}>
                <div className="modal-body" style={{ height: "100%" }}>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-justify">
                            Proceso
                        </label>
                        <div className="black-color">
                            <p className="pt-2">{procesoCierre?.NombreProcesoCierre}</p>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-3 col-form-label text-justify">
                            Cargar archivo
                        </label>
                        <div className="black-color">
                            <GenericUploadFileV2 uploadFileToS3={setUploadFile} id={UPDATE_REGISTROS_EVALUACION_INPUT_FILE} textButton={"Subir archivo"} extensionesValidas={EXTENSIONES_VALIDAS}/>
                            <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-File-validation-message`}></p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex justify-content-end">
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={downloadFormato}>Descargar formato</button>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha" onClick={submit}>Enviar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
}

export default CargarColaboradores;