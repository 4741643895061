import React from 'react'
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { jsonNoEstaVacio } from '../../../../helper/utils';
import { CuponCard, CuponCodigo, CuponImage, CuponLineamiento, CuponWrapper, ListaCuponesWrapper } from '../../components/StyledComponents';

const ListaCuponesDisponibles = ({ cuponesDisponibles: rows, setFormValues, cuponSelected, setCuponSelected }) => {

  const handleCardSelected = (row) => {
    if (isCuponUnusable(row)) return;
    setFormValues(lastData => {
      const updatedRows = lastData.Cupones?.map(o => {
        let isSelected = false;
        if (o.IdCuponTiempoLibre === row.IdCuponTiempoLibre){
          isSelected = !row.isSelected;
          setCuponSelected(isSelected ? row : {});
        }
        return {...o, isSelected};
      })
      return {...lastData, Cupones: updatedRows};
    });
  }

  const isCuponUnusable = (row) => {
    return row.EsCuponUsado || row.EsCuponNoVigente || row.PlantillaCuponRequerimientos?.split(",").includes("EsCuponBloqueado");
  }

  const textForUnusableCupon = (row) => {
    const { EsCuponUsado, EsCuponNoVigente, PlantillaCuponRequerimientos } = row;
    const EsCuponBloqueado = PlantillaCuponRequerimientos.split(",")?.includes("EsCuponBloqueado");
    return EsCuponUsado ? "Cupón Usado" : (EsCuponBloqueado ? "Cupón Bloqueado" : "");
  }

  return (
    <ListaCuponesWrapper className='custom-scroll'>
      {!rows.length
        ? <p className='blue-standard-color text-center mt-4'>Por ahora no contamos con ningún cupón vigente para ti, espera novedades próximamente</p>
        : <Wrapper>
          {rows.map((row, i) => (
            <CuponDetail key={row.IdCuponTiempoLibre} isSelected={row.isSelected} isBackgroundGray={jsonNoEstaVacio(cuponSelected) || isCuponUnusable(row)}>
              <div className='inner-content' onClick={() => handleCardSelected(row)}>
                <CuponCodigo>{row.CodigoCupon}</CuponCodigo>
                <CuponImage src={row.PlantillaImagenCuponTiempoLibreURL} loading='lazy'/>
                <div className="cupon_text_overlay">
                  <div className='overlay_header'>Ver Lineamientos</div>
                  <div className='overlay_body'>
                    <CuponLineamiento dangerouslySetInnerHTML={{ __html: row.LineamientosHTML }} />
                  </div>
                </div>
                {isCuponUnusable(row)
                  ? <div className='card-bloqued'>{textForUnusableCupon(row)}</div>
                  : <div className='card-checkbox'>
                    <FontAwesomeIcon size='2x' icon='check' />
                  </div>
                }
              </div>
            </CuponDetail>
          ))}
        </Wrapper>
      }
    </ListaCuponesWrapper>
  )
}

export default ListaCuponesDisponibles;

const Wrapper = styled(CuponWrapper)`
  margin: 0;
`;

const CuponDetail = styled(CuponCard)`
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.3);
  }

  .inner-content {
    cursor: pointer;
    filter: ${({ isBackgroundGray, isSelected }) => isBackgroundGray && !isSelected ? "grayscale(100%)" : ""};
  }
`;