import React, { useState } from 'react'
import SearchBar from './SearchBar'
import useDidMountEffect from '../../hooks/useDidMountEffect/useDidMountEffect';
import styled from 'styled-components';

const SearchContainer = ({ searchCriteria, setSearchCriteria, setPaginator, addCleanSearch, manageSearch, children }) => {

  const [filtered, setFiltered] = useState({});

  const limpiarBusqueda = () => {
    setSearchCriteria("");
    if (setPaginator) setPaginator(lastData => ({...lastData, CurrentPage: 1}));
    if (addCleanSearch) addCleanSearch();
    setFiltered({});
  };

  useDidMountEffect(() => {
    manageSearch();
  }, [filtered])

  return (
    <Wrapper>
      <SearchBar value={searchCriteria} onChange={setSearchCriteria} cleanSearch={limpiarBusqueda} manageSearch={manageSearch} />
      {children}
    </Wrapper>
  )
}

export default SearchContainer;

const Wrapper = styled.div`
  margin: 20px 20px 0px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 767px){
    flex-direction: column;
  }
`;