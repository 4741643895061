import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from "../../../hooks/context/authContext";
import { useGetRequest } from "../../../hooks/useGetRequest/useGetRequest";
import { enviarFichaSintomatologica, getFichaSintomatologica } from "../../../consumers/backendApisUrls";
import { mostrarMensajeDeErrorConToast, responseCode200 } from "../../../consumers/httpRequiestsUtils";
import GenericModal from "../../../components/modal/GenericModal";
import { useSubmitForm } from '../../../hooks/useSubmitForm/useSubmitForm';
import { useForm } from '../../../hooks/useForm/UseForm';

const FichaSintomatologicaModal = ({ showModal, idRegistro, reload }) => {
  const { state } = useContext(AuthContext);
  const [executeGetRequest] = useGetRequest();
  const [submitForm] = useSubmitForm();

  const seccionName = "data";

  const [formValues, setFormValues] = useState({});
  const [handleChange] = useForm(formValues, setFormValues);


  const [hideModalConfirmacion, setHideModalConfirmacion] = useState(true);
  const [atLeastOneCheck, setAtLeastOneCheck] = useState(false);
  const [fechaVigenciaText, setFechaVigenciaText] = useState();
  const [fechaVigenciaValue, setFechaVigenciaValue] = useState();
  const [questions, setQuestions] = useState([]);
  const [declaracionJurada, setDeclaracionJurada] = useState(false);
  const [checkedQuestions, setCheckedQuestions] = useState({});
  const [alreadyAnswered, setAlreadyAnswered] = useState(false);


  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      init();
    }

    return () => {
      unmounted = true;
    };
  }, []);


  const init = async function () {
    const response = await executeGetRequest(getFichaSintomatologica());
    if (responseCode200(response)) {
      setFechaVigenciaText(response.data.FechaVigenciaText);
      setFechaVigenciaValue(response.data.FechaVigenciaValue);
      const questionsResponse = response.data.Questions;
      setQuestions(questionsResponse);
      const selectedValues = questionsResponse.reduce((obj, item) => {
        return {
          ...obj,
          [item.IdFichaSintomatologicaAsk]: false
        }
      }, {});

      setCheckedQuestions(selectedValues);
    } else {
      mostrarMensajeDeErrorConToast(response);
      setAlreadyAnswered(true);
    }
  }

  const clickOnCheckbox = async function (e) {
    let checkedValue = e.target.checked;
    let idAcceso = e.target.dataset.id;


    if (checkedValue && idAcceso != questions[questions.length - 1].IdFichaSintomatologicaAsk) setAtLeastOneCheck(true);
    else setAtLeastOneCheck(false);

    setCheckedQuestions({
      ...checkedQuestions,
      [idAcceso]: checkedValue
    })
  };

  const handleDeclaracionJurada = (e) => {
    setDeclaracionJurada(e.target.checked);
  };

  const preSubmit = () => {
    if (atLeastOneCheck) toggleModalConfirmacion();
    else submit();
  }

  const submit = async (e) => {
    let payload = armarPayload();
    let api = enviarFichaSintomatologica();
    let checksValidation = questions.map(question => question.ValidationCode);
    let keys = [...checksValidation, "AceptaDeclaracionJurada"];
    await submitForm(keys, api, payload, seccionName, successSubmitCallback)
  }

  const armarPayload = () => {
    const items = Object.keys(checkedQuestions).map(key => ({
      IdFichaSintomatologicaAsk: key,
      Answer: checkedQuestions[key],
      Detail: formValues[key] || ""
    }))

    let payload = {
      "Items": items,
      "AceptaDeclaracionJurada": declaracionJurada,
      "FechaVigencia": fechaVigenciaValue
    }

    return payload;
  }

  const successSubmitCallback = function () {
    if (reload) reload();
    showModal();
  }

  const toggleModalConfirmacion = () => {
    setHideModalConfirmacion(!hideModalConfirmacion);
  }

  const closeModal = function () {
    if(alreadyAnswered) showModal();
    if (!alreadyAnswered && hideModalConfirmacion){
      showModal()
    }else{
      toggleModalConfirmacion();
    }
  }

  return (
    <>
      <GenericModal showModal={closeModal} width={"960px"} titulo={"Ficha sintomatológica Covid-19"}>
        {!alreadyAnswered && hideModalConfirmacion ? (<>
          <div className="modal-body pt-0 pl-2 pr-1 pb-0">
            <p className="blue-standard-color text-sm-right mb-2"><strong>Completa tu siguiente ficha cada día a partir de las 12:00 p.m.</strong></p>
            <div><strong>Fecha de vigencia:</strong> {fechaVigenciaText}</div>
            <div className="d-flex justify-content-between mb-4">
              <div>En los últimos 7 días calendarios ha tenido alguno de los síntomas siguientes: </div>
            </div>
            <div className="container-fluid">
              <div className="row g-5">
                {questions.map(question => (
                  <div key={question.IdFichaSintomatologicaAsk} className="col-xs-12 col-md-6 px-md-4 px-0">
                    <div className="d-flex justify-content-between" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
                      <div>
                        {question.Ask}
                      </div>
                      <div>
                        <div className="custom-control custom-switch ml-3" style={{ zIndex: 0 }}>
                          <input
                            type="checkbox"
                            className="custom-control-input hand-pointer"
                            id={question.ValidationCode}
                            data-id={question.IdFichaSintomatologicaAsk}
                            name={question.Nombre} defaultChecked={checkedQuestions[question.IdFichaSintomatologicaAsk]}
                            onChange={clickOnCheckbox}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={question.ValidationCode}
                          >
                            {checkedQuestions[question.IdFichaSintomatologicaAsk] ? "Si" : "No"}
                          </label>
                        </div>
                      </div>
                    </div>
                    {question.NeedDetail && checkedQuestions[question.IdFichaSintomatologicaAsk] ? (
                      <div>
                        <textarea
                          name={question.IdFichaSintomatologicaAsk}
                          onChange={handleChange}
                          value={formValues[question.IdFichaSintomatologicaAsk]}
                          className="form-control"
                          placeholder="Detallar qué medicamentos"
                          id="floatingTextarea"
                        ></textarea>
                      </div>
                    ) : null}
                    <div>
                      <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-${question.ValidationCode}-validation-message`}></p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="container-fluid mt-4" style={{ textAlign: 'justify', textJustify: 'inter-word' }}>
              <input className="form-check-input" type="checkbox" id="AceptaDeclaracionJurada" checked={declaracionJurada} onChange={handleDeclaracionJurada}></input>
              <label className="form-check-label ficha-declaracion-jurada-message" htmlFor="AceptaDeclaracionJurada">
                <strong><p className={"mb-0"}>Todos los datos y documentos ingresados en el presente Registro constituyen una Declaración Jurada de mi parte. He sido informado que de proporcionar información falsa puedo perjudicar la salud de mis compañeros/as, estudiantes, familias y la mía propia, lo cual constituye una falta grave contra la salud pública ante la cual la empresa podrá adoptar las acciones de índole laboral y legal correspondientes.</p></strong>
              </label>
              <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-AceptaDeclaracionJurada-validation-message`}></p>
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-end" >
            <div className="button">
              <button className="btn btn-primary generic-button-ficha" onClick={preSubmit}>Enviar</button>
            </div>
          </div>
        </>) : null}

        {!hideModalConfirmacion ? (
          <>
            <div class="alert alert-info" role="alert">
              Has completado tu ficha con síntoma/s sospechoso/s a COVID - 19, activaremos alertas para el seguimiento de tu caso.
            </div>
            <p className="ficha-form-input-validation-message" id={`ficha-edit-${seccionName}-AceptaDeclaracionJurada-validation-message`}></p>
            <div className="modal-footer d-flex justify-content-end" >
              <div className="button">
                <button className="btn btn-primary generic-button-ficha" onClick={toggleModalConfirmacion}>Atrás</button>
              </div>
              <div className="button">
                <button className="btn btn-primary generic-button-ficha" onClick={submit}>De acuerdo</button>
              </div>
            </div>
          </>
        ) : null}

        {alreadyAnswered ? (
          <div class="alert alert-danger" role="alert">
            El día de hoy has reportado tu ficha sintomatológica con síntomas, para actualizar el registro, debes contactarte con tu Líder.
          </div>
        ) : null}
      </GenericModal>
    </>
  );
};

export default FichaSintomatologicaModal;
