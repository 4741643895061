import {clearAllInputValidationMessages, mostrarMensajesDeValidacionEnCadaInput} from "../../validations/FormValidatorUtils";
import {mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState} from "../../consumers/httpRequiestsUtils";
import {usePostRequest} from "../usePostRequest/usePostRequest";
import {toast} from "react-toastify";

export const useSubmitForm = () => {
    const [executePostRequest] = usePostRequest();

    const submitForm = async function (allInputKeys, api, payload, sectionName, callbackSuccess, showToastSuccess = true, callbackFailure = null) {
        let response = await executePostRequest(api, payload);
        if (responseCode200(response)) {
            clearAllInputValidationMessages(allInputKeys, sectionName);
            if (showToastSuccess === true) {
                toast.success(response?.data?.Message);
            }
            await callbackSuccess(response);
        } else if (responseTieneModelState(response)) {
            if(callbackFailure)
                await callbackFailure(response);
            mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, sectionName);
        } else {
            clearAllInputValidationMessages(allInputKeys, sectionName);
            mostrarMensajeDeErrorConToast(response);
        }
    }

    return [submitForm]
};