import React, {useEffect, useState, useRef} from 'react';
import GenericModal from "../../../components/modal/GenericModal";
import {
    mostrarMensajeDeErrorConToast,
    responseCode200,
    responseTieneModelState
} from "../../../consumers/httpRequiestsUtils";
import {esValidacionCorrectaRegex} from "../../../helper/utils";
import {toast} from "react-toastify";
import TagsInput from '../../../components/TagsInput/TagsInput';
import {GENERIC_CORREO_ELECTRONICO} from '../../../validations/Regex';
import {GENERIC_SUCCESS_TOAST_MESSAGE} from "../../../constants/hardCodedConstants";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {usePostRequest} from "../../../hooks/usePostRequest/usePostRequest";
import {configurarCeseAPI, getConfiguracionCeseAPI} from "../../../consumers/backendApisUrls";
import {mostrarMensajesDeValidacionEnCadaInput} from "../../../validations/FormValidatorUtils";

const ConfiguracionCeseModal = ({showModal}) => {
    const sectionName = "ConfiguracionCeseModal";
    const refContainer = useRef(null);
    const [isLoading, setIsLoading] = useState(true);
    const [formValues, setFormValues] = useState({});
    const [codes, setCodes] = useState([]);
    const [executeGetRequest] = useGetRequest();
    const [executePostRequest] = usePostRequest();

    useEffect(() => {
        obtenerConfiguraciones();
    }, []);

    const obtenerConfiguraciones = async () => {
        try {
            const response = await executeGetRequest(getConfiguracionCeseAPI());
            if (responseCode200(response)) {
                setFormValues(response.data);
                setIsLoading(false);

                setCodes(response.data.SettingsList.map((item) => item.Code));
            } else {
                mostrarMensajeDeErrorConToast(response);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const validarInput = (valor) => {
        return esValidacionCorrectaRegex(GENERIC_CORREO_ELECTRONICO, valor, "El valor ingresado no coincide con un correo.");
    };

    const anadirEmails = (emails, itemCode) => {
        let tempSettingList = formValues.SettingsList.map((item) => {
            if (item.Code === itemCode) {
                return {...item, Emails: emails}
            }
            return {...item};
        })

        let newFormValues = {...formValues};
        newFormValues.SettingsList = tempSettingList;

        setFormValues(newFormValues);
    };

    const submitConfiguracionCeseModal = async () => {
        let response = await executePostRequest(configurarCeseAPI(), formValues);
        if (responseCode200(response)) {
            toast.success((response?.data?.Message) ? response?.data?.Message : GENERIC_SUCCESS_TOAST_MESSAGE);
            showModal();
        } else if (responseTieneModelState(response)) {
            mostrarMensajesDeValidacionEnCadaInput(response, codes, sectionName);
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    };

    if (isLoading)
        return (<></>);

    return (
        <>
            <GenericModal showModal={showModal} width={"1200px"} titulo={"Configuración de Notificaciones"}>
                <div className="modal-body">
                    <form ref={refContainer} className="blue-standard-color" onSubmit={(e) => {
                        e.preventDefault()
                    }}>
                        {formValues && formValues.SettingsList.map((item) => (
                            <div className="form-group row form-input-modal-contactanos mb-5" key={item.IdLista}>
                                <label className="col-sm-3 col-form-label modal-label">{item?.NombreLista}<span
                                    className="required">*</span></label>
                                <div className="col-sm-9 col-form-label modal-label">
                                    <TagsInput itemInfo={item} validarInput={validarInput} anadirEmails={anadirEmails}/>
                                    <p className="ficha-form-input-validation-message"
                                       id={`ficha-edit-${sectionName}-${item.Code}-validation-message`}></p>
                                </div>
                            </div>
                        ))}
                    </form>
                </div>
                <div className="modal-footer" style={{'marginTop': '35px'}}>
                    <div className="message font-gothan-light" style={{'width': '500px'}}>
                        <strong>
                            <p>* Campos obligatorios</p>
                        </strong>
                    </div>
                    <div className="button">
                        <button className="btn btn-primary generic-button-ficha"
                                onClick={submitConfiguracionCeseModal}>Guardar
                        </button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ConfiguracionCeseModal;

