import React from 'react';

const VideoCurso = ({embedId}) => {
  return <div style={{ height: '300px'}}>
    <div style={{
      overflow: "hidden",
      position: "relative",
      height: "300px"
    }}
    >
      <iframe
        style={{
          left: "0",
          top: "0",
          height: "300px",
          width: "100%",
          position: "absolute"
        }}
        width="853"
        height="300px"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  </div >;
};

export default VideoCurso;
