import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from "../../../hooks/context/authContext";
import {useGetRequest} from "../../../hooks/useGetRequest/useGetRequest";
import {mapDropDownOptionsFromBackendToSelectOptionsNeededForLibrary} from "../../../helper/utils";
import {getCondicionesMedicasAPI, getDetalleEncuestaAPI} from "../../../consumers/backendApisUrls";
import {mostrarMensajeDeErrorConToast, responseCode200} from "../../../consumers/httpRequiestsUtils";
import GenericModal from "../../../components/modal/GenericModal";
import SelectInput from "../../../components/Select/SelectInput";
import MultiSelectInput from "../../../components/MultiSelectInput/MultiSelectInput";
import {downloadFile} from "../../../consumers/backendConsumer";
import { useForm } from '../../../hooks/useForm/UseForm';

const DetalleEncuestaSaludModal = ({showModal, idEncuesta}) => {
    const {state} = useContext(AuthContext);
    const [executeGetRequest] = useGetRequest();
    const [jsonSelectedPresentaCondicionMedica, setJsonSelectedPresentaCondicionMedica] = useState({});
    const [multiSelectedCondicionMedica, setMultiSelectedCondicionMedica] = useState([]);
    const setFechaEmbarazoLactante = "";
    const [jsonSelectedConviveConPersonaCondicionMedica, setJsonSelectedConviveConPersonaCondicionMedica] = useState({});
    const [jsonSelectedEsResponsableDePersonaCondicionMedica, setJsonSelectedEsResponsableDePersonaCondicionMedica] = useState({});
    const [YesOrNoOptions] = useState([{value: false, label: "No"}, {value: true, label: "Si"}]);
    const [condicionesMedicasOptions, setCondicionesMedicasOptions] = useState([]);
    const [detalleEncuesta, setDetalleEncuesta] = useState({});

    const [formValues, setFormValues] = useState({});
    const [handleChange] = useForm(formValues, setFormValues);

    const EMBARAZO_ID = 993;
    const LACTANTE_ID = 994;

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }

        return () => {
            unmounted = true;
        };
    }, []);


    const init = async function () {
        let detalleEncuesta = await getSavedInfoModal();
        if (detalleEncuesta) {
            getAndSetSelectedValueObject(YesOrNoOptions, setJsonSelectedPresentaCondicionMedica, detalleEncuesta.PresentaCondicionMedica);
            getAndSetSelectedValueObject(YesOrNoOptions, setJsonSelectedConviveConPersonaCondicionMedica, detalleEncuesta.ConviveConPersonaCondicionMedica);
            getAndSetSelectedValueObject(YesOrNoOptions, setJsonSelectedEsResponsableDePersonaCondicionMedica, detalleEncuesta.EsResponsableDePersonaCondicionMedica);
            setDetalleEncuesta(detalleEncuesta);
            setMultiSelectedCondicionMedica(detalleEncuesta.IdsCondicionesMedicas);
            mapCondicionesMedicasOptions(detalleEncuesta?.CondicionesMedicas);
            let formValuess = {
                "FechaEmbarazoLactante": detalleEncuesta?.FechaEmbLacMostrar,
            }
            setFormValues(formValuess);
        }
    }

    const mapCondicionesMedicasOptions = function (condicionesMedicas) {
        if (condicionesMedicas){
            let mappedOptions = condicionesMedicas.map((item)=> {return{'value': item?.Value, 'label': item?.Text}})
            setCondicionesMedicasOptions(mappedOptions);
        }
    }

    const getAndSetSelectedValueObject = function (optionsArray, setFunctionCallBack, selectedValueOption) {
        let valueSelectedOption = getSelectedValueObject(optionsArray, selectedValueOption);
        setSelectedValueObject(valueSelectedOption, setFunctionCallBack);
    }

    const setSelectedValueObject = function (valueSelectedOption, setFunctionCallBack) {
        setFunctionCallBack(valueSelectedOption);
    }

    const getSelectedValueObject = function (arrayOptions, selectedValueOption) {
        return arrayOptions.find((element) => element.value === selectedValueOption);
    }

    const getSavedInfoModal = async function () {
        const response = await executeGetRequest(getDetalleEncuestaAPI(idEncuesta));
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const getCondicionesMedicas = async function () {
        const response = await executeGetRequest(getCondicionesMedicasAPI());
        if (responseCode200(response)) {
            return response?.data;
        } else {
            mostrarMensajeDeErrorConToast(response);
        }
    }

    const showAllOptions = (event) => {
        let element = document.getElementById("focus-element");
        element.scrollIntoView({block: "start", behavior: "smooth"});
    }

    return (
        <>

            <GenericModal showModal={showModal} width={"1300px"} titulo={"Detalle Formulario Salud"}>
                <div className="modal-body">
                    <form>
                        <div className="row">
                            <p className="text-justify">
                                Nos preparamos para el retorno a las escuelas y desde el área de Seguridad y Salud en el Trabajo es indispensable tener la información sobre las condiciones de salud de nuestros trabajadores.
                                Antes que completes esta ficha es importante que realices el cálculo de tu IMC (Índice de Masa Corporal) en el siguiente link: &nbsp;
                                <a href="https://www.cdc.gov/healthyweight/spanish/assessing/bmi/adult_bmi/metric_bmi_calculator/bmi_calculator.html" target="_blank">
                                    IMC Calculator</a>
                                <br/>
                                Recuerda que el presente documento tiene validez de declaración jurada, es obligatoria y de carácter confidencial. Toda información sobre tu salud será manejada de manera confidencial y solo será accesible a nuestro médico ocupacional.
                                En caso marques "Sí" a alguna de las condiciones médicas del listado, es obligatorio  que subas el informe médico o certificado médico que lo acrediten.
                            </p>
                        </div>
                        <div className="row">
                            <h5 className="text-dark"><strong>Sección 1: Sobre tu salud</strong></h5>
                            <ol>
                                <li>
                                    <div className="form-group row">
                                        <label className="col-sm-7 col-form-label text-justify" id="focus-element">
                                            ¿Presentas alguna de las siguientes condiciones consideradas de riesgo ante el contagio de Covid-19?
                                            Cáncer, enfermedad renal crónica, enfermedad pulmonar crónica: EPOC (enfermedad pulmonar obstructiva crónica); fibrosis quística; hipertensión pulmonar;  asma grave o no controlada, afecciones cardiacas tales como: insuficiencia cardiaca; enfermedad de las arterias coronarias o miocardiopatías, diabetes mellitus tipo 1 y tipo 2, obesidad con IMC de 30 kg/m2 o más, es paciente inmunodeprimido (sistema inmunológico debilitado) con inmunodeficiencias primarias, uso prolongado de corticosteroides u otros medicamentos inmunosupresores, es receptor de trasplante de órganos sólidos o células madre sanguíneas, enfermedad cerebrovascular (infarto o hemorragia cerebral), hipertensión arterial, síndrome de down, infección por VIH, embarazo, madre lactante con bebés menores de 6 meses de edad, edad mayor a 65 años.
                                            <span className="required"></span>
                                        </label>
                                        <div className="col-sm-5 black-color">
                                            <SelectInput options={YesOrNoOptions} jsonSelectedValue={jsonSelectedPresentaCondicionMedica} setJsonSelectedValue={setJsonSelectedPresentaCondicionMedica} isDisabled={true} placeholder={"Seleccionar"}/>
                                        </div>
                                    </div>
                                </li>
                                {
                                    jsonSelectedPresentaCondicionMedica.value &&
                                    <li>
                                        <div className="form-group row">
                                            <label className="col-sm-7 col-form-label text-justify">
                                                ¿Cuál de estas condiciones médicas presentas? Dependiendo de tu condición, puedes elegir más de una.
                                                <span className="required"></span>
                                            </label>
                                            <div className="col-sm-5 black-color" onClick={showAllOptions}>
                                                <MultiSelectInput options={condicionesMedicasOptions} selectedValues={multiSelectedCondicionMedica} setSelectedValues={setMultiSelectedCondicionMedica} isDisabled={true} placeholder={"Seleccionar condición médica"}/>
                                            </div>
                                        </div>
                                    </li>
                                }
                                {
                                    jsonSelectedPresentaCondicionMedica.value &&
                                    <li>
                                        <div className="form-group row">
                                            <label className="col-sm-7 col-form-label text-justify">
                                                Adjunta un documento emitido por tu médico tratante, certificando la condición médica que presentas (informe médico o certificado médico). El documento debe contener datos personales, diagnóstico, fecha de emisión (debe ser reciente), firma y sello del médico. Este documento será de acceso exclusivo al médico ocupacional de Colegios Peruanos S.A.
                                                <span className="required"></span>
                                            </label>
                                            <div className="col-sm-5 black-color">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                {detalleEncuesta.CertificadoCondicionMedica &&
                                                                <button
                                                                    type='button'
                                                                    className='btn btn-primary ficha-form-button-actualizar'
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        downloadFile(state.token, detalleEncuesta.CertificadoCondicionMedica.Path, detalleEncuesta.CertificadoCondicionMedica.OriginalFileName);
                                                                    }}>
                                                                    Descargar archivo
                                                                </button>
                                                                }
                                                            </td>
                                                            <td>
                                                                {multiSelectedCondicionMedica.filter(e => e === EMBARAZO_ID || e === LACTANTE_ID).length > 0 &&
                                                                    <input type="date" className="form-control" name="FechaEmbarazoLactante" onChange={handleChange} disabled={true} value={formValues?.FechaEmbarazoLactante ?? ""} ></input>    
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p className="modal-label text-justify">* Colegios Peruanos S.A. puede solicitar información/documentación adicional en caso sea necesario.</p>
                                        </div>
                                    </li>
                                }

                            </ol>

                        </div>
                        {/*<div className="row">
                            <h4 className="text-dark"><strong>Sección 2: Nos interesa conocer la salud de tu entorno familiar</strong></h4>
                            <br/>
                            <br/>
                            <ol>
                                <li>
                                    <div className="form-group row">
                                        <label className="col-sm-7 col-form-label text-justify">
                                            ¿Vives con alguna persona que presenta algunas de estas condiciones: persona mayor de 65 años, hipertensión arterial
                                            refractaria, enfermedades cardiovasculares graves, cáncer, diabetes mellitus, asma moderada o grave, enfermedad pulmonar
                                            crónica, insuficiencia renal crónica en tratamiento con hemodiálisis, enfermedad o tratamiento inmunosupresor, obesidad
                                            con IMC de 40 a mas.
                                            <span className="required"></span>
                                        </label>
                                        <div className="col-sm-5 black-color">
                                            <SelectInput options={YesOrNoOptions} jsonSelectedValue={jsonSelectedConviveConPersonaCondicionMedica} setJsonSelectedValue={setJsonSelectedConviveConPersonaCondicionMedica} isDisabled={true} placeholder={"Seleccionar"}/>
                                        </div>
                                    </div>
                                </li>
                                {
                                    jsonSelectedConviveConPersonaCondicionMedica.value &&
                                    <li>
                                        <div className="form-group row">
                                            <label className="col-sm-7 col-form-label text-justify">
                                                En caso hayas marcado SI en la respuesta anterior, ¿eres el/la responsable del cuidado y sostén de dicho(s) familiar(es)
                                                directo(s)?
                                                <span className="required"></span>
                                            </label>
                                            <div className="col-sm-5 black-color">
                                                <SelectInput options={YesOrNoOptions} jsonSelectedValue={jsonSelectedEsResponsableDePersonaCondicionMedica} setJsonSelectedValue={setJsonSelectedEsResponsableDePersonaCondicionMedica} isDisabled={true}
                                                             placeholder={"Seleccionar"}/>
                                            </div>
                                        </div>
                                    </li>
                                }

                                {
                                    jsonSelectedConviveConPersonaCondicionMedica.value && jsonSelectedEsResponsableDePersonaCondicionMedica.value &&
                                    <li>
                                        <div className="form-group row">
                                            <label className="col-sm-7 col-form-label text-justify">
                                                Adjunta un documento, emitido por el médico tratante, certificando la condición médica de dicho(s) <strong>familiar(es) directo(s)</strong> así
                                                como documentación que certifique que eres responsable del cuidado y sostén de dichos <strong>familiares directos</strong> (recibo de servicios o
                                                contrato de arrendamiento o DNI de familiar con similar dirección a la tuya u otro documento similar).
                                                <span className="required"></span>
                                            </label>
                                            <div className="col-sm-5 black-color">
                                                {detalleEncuesta.PersonaACargoCertificadoCondicionMedica &&
                                                <button
                                                    type='button'
                                                    className='btn btn-primary ficha-form-button-actualizar mb-3'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(state.token, detalleEncuesta.PersonaACargoCertificadoCondicionMedica.Path, detalleEncuesta.PersonaACargoCertificadoCondicionMedica.OriginalFileName);
                                                    }}>
                                                    Descargar Certificado Familiar
                                                </button>
                                                }

                                                {detalleEncuesta.SustentoPersonaACargoCondicionMedica &&
                                                <button
                                                    type='button'
                                                    className='btn btn-primary ficha-form-button-actualizar'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadFile(state.token, detalleEncuesta.SustentoPersonaACargoCondicionMedica.Path, detalleEncuesta.SustentoPersonaACargoCondicionMedica.OriginalFileName);
                                                    }}>
                                                    Descargar Sustento Resp.
                                                </button>
                                                }
                                            </div>
                                            <p className="modal-label text-justify">* Colegios Peruanos S.A. puede solicitar información/documentación adicional en caso sea necesario.</p>
                                        </div>
                                    </li>
                                }
                            </ol>
                        </div>*/}
                    </form>
                </div>
            </GenericModal>
        </>
    );
};

export default DetalleEncuestaSaludModal;
