import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EliminarContrato from './modalesDatosContratacion/EliminarContrato';
import { useManageGetRequest } from '../../hooks/useManageGetRequest/useManageGetRequest';
import { getDatosContratosAPI } from '../../consumers/backendApisUrls';
import EditarContrato from './modalesDatosContratacion/EditarContrato';

const SeccionDatosContratacion = ({ empleadoId }) => {
    const [datosContratos, setDatosContratos] = useState([]);
    const [modalEnable, setModalEnable] = useState({isEnable: false});

    const [executeGet] = useManageGetRequest();

    const initialData = async () => {
        try {
            await executeGet(getDatosContratosAPI(empleadoId), (res) => setDatosContratos(res.data));
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        initialData();
    }, [])

  return (
    <>
        {modalEnable.isEnable && 
            <div className="modal-contactanos modal-response-ficha-background">
                <modalEnable.component toggleModal={() => setModalEnable({isEnable: false})} idEmpleadoContrato={modalEnable.idEmpleadoContrato} archivoTitle={modalEnable.archivoTitle} manageSearch={initialData}/>
            </div>
        }

        <div className='w-100 mt-4'>
            <p className="font-gothan-medium ficha-sub-title-color ficha-datos-empresa-seccion-title">Datos Contratación</p>
        </div>

        <div className='table-responsive form-group-sm mt-2'>
            <table className='table table-hover'>
                <thead>
                    <tr>
                        <th className='text-center font-gothan-medium'>Estado</th>
                        <th className='col-sm-1 text-center font-gothan-medium'>Tipo Trabajador</th>
                        <th className='col-sm-1 text-center font-gothan-medium'>Jornada Laboral</th>
                        <th className='col-sm-3 text-center font-gothan-medium'>Tipo Contrato</th>
                        <th className='col-sm-3 text-center font-gothan-medium'>Régimen Laboral</th>
                        <th className='col-sm-1 text-center font-gothan-medium'>Remuneración</th>
                        <th className='col-sm-1 text-center font-gothan-medium'>Fecha de inicio de Contrato</th>
                        <th className='col-sm-1 text-center font-gothan-medium'>Fecha de Vencimiento de Contrato</th>
                        <th className='text-center font-gothan-medium'>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {datosContratos?.Contratos?.map((contrato, index) => (
                        <tr key={index}>
                            <td className='text-center'>{contrato?.EsActivo ? "Activo" : "Inactivo"}</td>
                            <td className='text-center'>{contrato?.TipoTrabajador}</td>
                            <td className='text-center'>{contrato?.TipoEmpleado}</td>
                            <td className='pl-4 pt-3 small'>{contrato?.TipoContrato}</td>
                            <td className='pl-4 pt-3 small'>{contrato?.RegimenLaboral}</td>
                            <td className='text-center'>{contrato?.Remuneracion}</td>
                            <td className='text-center'>{contrato?.FechaInicioContrato}</td>
                            <td className='text-center'>{contrato?.FechaVencimientoContrato}</td>
                            <td className="p-2 text-center col-sm-2">
                                <div className="d-flex justify-content-center">
                                    {
                                        contrato?.EsActivo &&  datosContratos?.PuedeEditar ? (
                                         <>
                                            <div className="mr-3">
                                                <FontAwesomeIcon icon='edit' className="hand-pointer blue-standard-color" title="Editar" 
                                                onClick={() => setModalEnable({isEnable: true, component: EditarContrato, idEmpleadoContrato: contrato?.IdEmpleadoContrato})}
                                                />
                                            </div>
                                            <div className="">
                                                <FontAwesomeIcon icon='trash' className="hand-pointer blue-standard-color" title="Eliminar" onClick={() => setModalEnable({isEnable: true, component: EliminarContrato, idEmpleadoContrato: contrato?.IdEmpleadoContrato, archivoTitle: contrato?.TipoContrato})}/>
                                            </div>
                                        </>
                                        ):null
                                    
                                    }
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </>
  )
}

export default SeccionDatosContratacion