import React, { useEffect, useState } from 'react';
import { RESERVA_TIPO_UBICACION_ENTITY } from '../../../constants/hardCodedConstants';
import SelectInput from "../../../components/Select/SelectInput";
import GenericModal from '../../../components/modal/GenericModal';
import AsyncSelectInput from '../../../components/Select/AsyncSelectInput';
import { usePostRequest } from '../../../hooks/usePostRequest/usePostRequest';
import { confirmarReservaAPI } from '../../../consumers/backendApisUrls';
import { mostrarMensajeDeErrorConToast, responseCode200, responseTieneModelState } from '../../../consumers/httpRequiestsUtils';
import { toast } from 'react-toastify';
import { mostrarMensajesDeValidacionEnCadaInput } from '../../../validations/FormValidatorUtils';
import { getCurrentDay, getCurrentDate } from '../../../helper/utils';

const ConfirmarReserva = ({ showModal, informacionConfirmarReserva, showModalReserva }) => {

    const SECTION_NAME = "CONFIRMAR_RESERVA";
    const allInputKeys = ["Participantes"];
    const [executePostRequest] = usePostRequest();
    const [participantes, setParticipantes] = useState([]);

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            init();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    const init = () => {
        setParticipantes(informacionConfirmarReserva.participantes);
    }

    const closeForm = () => {
        showModal();
    }

    const closeAllForms = () => {
        showModal();
        showModalReserva();
    }

    const handleChange = (event, participante) => {
        let temperatura = event.target.value;
        let tempParticipantes = [...participantes];
        let tempParticipante = tempParticipantes.find(o => o.IdParticipante == participante.IdParticipante);
        tempParticipante.Temperatura = temperatura;
        setParticipantes(tempParticipantes)
    };

    const submitConfirmacionReserva = async (event) => {
        try {
            event.preventDefault();
            let payload = obtenerPayload();
            let response = await executePostRequest(confirmarReservaAPI(), payload);
            if (responseCode200(response)) {
                toast.success(response.data.Message);
                closeAllForms();
            } else if (responseTieneModelState(response)) {
                mostrarMensajesDeValidacionEnCadaInput(response, allInputKeys, SECTION_NAME);
            } else {
                mostrarMensajeDeErrorConToast(response);
                closeAllForms();
            }
        } catch (e) {
            console.error(e);
        }
    };

    const obtenerPayload = () => {
        return {
            IdReserva: informacionConfirmarReserva?.idReserva,
            Participantes: participantes
        }
    };

    return (
        <>
            <GenericModal showModal={closeForm} width={"1000px"} titulo={"Encuesta de salud - Triaje por Reserva"}>
                <div className="modal-body">
                    <form>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-justify" id="focus-element">Sede</label>
                            <div className="col-sm-4 black-color">
                                <SelectInput
                                    setJsonSelectedValue={() => { }}
                                    jsonSelectedValue={informacionConfirmarReserva?.sede}
                                    isDisabled={true} />
                            </div>
                            <label className="col-sm-2 col-form-label text-justify" id="focus-element">Fecha Reserva</label>
                            <div className="col-sm-4">
                                <input type="date" className="form-control" value={informacionConfirmarReserva?.fechaReserva} disabled={true} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-justify" id="focus-element">Responsable</label>
                            <div className="col-sm-4 black-color">
                                <AsyncSelectInput
                                    isDisabled={true}
                                    defaultValue={informacionConfirmarReserva?.responsable} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label text-justify" id="focus-element">Tipo Ubicación</label>
                            <div className="col-sm-4 black-color">
                                <SelectInput
                                    setJsonSelectedValue={() => { }}
                                    jsonSelectedValue={informacionConfirmarReserva?.tipoUbicacion}
                                    isDisabled={true} />
                            </div>
                            <label className="col-sm-2 col-form-label text-justify" id="focus-element">
                                {informacionConfirmarReserva?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL && "Área de trabajo"}
                                {informacionConfirmarReserva?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.SALA && "Nombre sala"}
                            </label>
                            <div className="col-sm-4 black-color">
                                <SelectInput
                                    setJsonSelectedValue={() => { }}
                                    jsonSelectedValue={informacionConfirmarReserva?.ubicacion}
                                    isDisabled={true} />
                            </div>
                        </div>
                        {informacionConfirmarReserva?.tipoUbicacion?.idEntity == RESERVA_TIPO_UBICACION_ENTITY.INDIVIDUAL &&
                            <div className="form-group row">
                                <label className="col-sm-2 col-form-label text-justify" id="focus-element">Posición</label>
                                <div className="col-sm-4 black-color">
                                    <SelectInput
                                        setJsonSelectedValue={() => { }}
                                        jsonSelectedValue={informacionConfirmarReserva?.posicion}
                                        isDisabled={true} />
                                </div>
                            </div>
                        }
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-justify" id="focus-element" >¿Cual es el motivo de asistencia?</label>
                            <div className="col-sm-8 black-color">
                                <SelectInput
                                    setJsonSelectedValue={() => { }}
                                    jsonSelectedValue={informacionConfirmarReserva?.motivoReserva}
                                    isDisabled={true} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-12 col-form-label text-justify" id="focus-element" >Ingresar temperatura</label>
                            <div className="col-sm-12 flex-row justify-content-center">
                                <table className='table table-hover'>
                                    <thead className="listar-thead">
                                        <tr>
                                            <th className="col-2">
                                                <div className='flex font-gothan-medium'>#</div>
                                            </th>
                                            <th className="col-6">
                                                <div className='flex font-gothan-medium'>Nombre Participante</div>
                                            </th>
                                            <th className="col-4">
                                                <div className='flex font-gothan-medium'>Temperatura(C°)</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="listar-tbody">
                                        {participantes.map((participante, i) => (
                                            <tr key={i}>
                                                <td className="col-2">{i + 1}</td>
                                                <td className="col-6">{participante?.NombreParticipante}</td>
                                                <td className="col-4">
                                                    <input type="number" className="form-control"
                                                        onChange={(event) => handleChange(event, participante)} value={participante?.temperatura} min="35" max="45" />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <p className="ficha-form-input-validation-message" id={`ficha-edit-${SECTION_NAME}-Participantes-validation-message`}></p>               
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer d-flex justify-content-end">
                    {informacionConfirmarReserva?.fechaReserva == getCurrentDate()}
                    <div className="button d-flex justify-content-end">
                        <button className="btn btn-primary generic-button-ficha" onClick={submitConfirmacionReserva}>Confirmar</button>
                    </div>
                </div>
            </GenericModal>
        </>
    );
};

export default ConfirmarReserva;