import React, { useState, useEffect } from 'react'
import ModalForm from '../../../../components/modalComponents/ModalForm'
import InputText from '../../../../components/modalComponents/InputText';
import InputDate from '../../../../components/modalComponents/InputDate';
import InputUploadFile from '../../../../components/modalComponents/InputUploadFile';
import { useSubmitForm } from '../../../../hooks/useSubmitForm/useSubmitForm';
import { usePropsInputs } from '../../../../hooks/useProps/usePropsInput';
import { useManageGetRequest } from '../../../../hooks/useManageGetRequest/useManageGetRequest';
import { getProcesoSolicitudDescuentoPensionByIdAPI, saveProcesoSolicitudDescuentoPensionAPI } from '../../../../consumers/backendApisUrls';

const EditarConfiguracion = ({ toggleModal, refreshPage, IdProcesoSolicitudDescuentoPension, getProcesos, PuedeEditarProceso = false }) => {

  const SECTION_NAME = "nuevaConfiguracionSolcitudDEscuento"
  const KEYS = { nombre: "Nombre", desde: "FechaInicio", hasta: "FechaFin", archivo: "FileViewModel" };

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [firstFile, setFirstFile] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [executeGet] = useManageGetRequest();
  const [submitForm] = useSubmitForm();
  const [commonProps] = usePropsInputs(formValues, setFormValues, SECTION_NAME);

  const init = async () => {
    const api = getProcesoSolicitudDescuentoPensionByIdAPI(IdProcesoSolicitudDescuentoPension);
    await executeGet( api, successInitCallback);
  }

  const successInitCallback = ({ data }) => {
    setFormValues(data);
    setFirstFile(data[KEYS.archivo]);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const api = saveProcesoSolicitudDescuentoPensionAPI();
    const payload = getPayload();
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, handleSuccessSubmit);
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000);
    }
  }

  const getPayload = () => {
    const FileViewModel = firstFile?.Path === formValues[KEYS.archivo]?.Path
      ? null 
      : formValues[KEYS.archivo];
    return {
      IdProcesoSolicitudDescuentoPension,
      ...formValues,
      FileViewModel
    }
  }

  const handleSuccessSubmit = () => {
    setTimeout(() => {
      toggleModal();
      getProcesos({ isNew: false });
    }, 1000);
  }

  const handleUploadFile = (res) => {
    setFormValues(lastData => ({...lastData, [KEYS.archivo]: res}));
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <ModalForm onSubmit={handleSubmit} showModal={toggleModal} titulo={`${PuedeEditarProceso ? "Editar" : "Ver"} Configuración de Solicitud`} width='750px' saveButtonDisabled={isUploading} isSubmitting={isSubmitting} hasSaveButton={PuedeEditarProceso}>
      <Div col={12}><InputText label='Nombre' {...commonProps(KEYS.nombre)} direction='column' isDisabled={!PuedeEditarProceso} /></Div>
      <label className='ml-3 mb-3'>Vigencia</label>
      <div className='displayFlexWrap'>
        <Div col={6}><InputDate label='Desde' {...commonProps(KEYS.desde)} isDisabled={!PuedeEditarProceso}/></Div>
        <Div col={6}><InputDate label='Hasta' {...commonProps(KEYS.hasta)} isDisabled={!PuedeEditarProceso}/></Div>
      </div>
      <Div col={12}><InputUploadFile label='Lista de Matriculados' uploadFile={formValues[KEYS.archivo]} setUploadFile={handleUploadFile} id="uploadNuevaConfiguracionSolcitudDEscuento" validExtensions={["xls", "xlsx"]} sectionName={SECTION_NAME} name={KEYS.archivo} isDisabled={!PuedeEditarProceso} isUploading={isUploading} setIsUploading={setIsUploading}/></Div>
    </ModalForm>
  )
}

export default EditarConfiguracion

const Div = ({ col, children }) => (
  <div className={`col-sm-${col}`}>
    {children}
  </div>
)