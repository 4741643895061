import React, { useEffect, useState } from 'react'
import logo from "../../../../../image/logo_menu.svg";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputDate from '../../../../../components/modalComponents/InputDate';
import ModalForm from '../../../../../components/modalComponents/ModalForm';
import InputNumber from '../../../../../components/modalComponents/InputNumber';
import ModalEnabled from '../../../../../components/modalComponents/ModalEnabled';
import GenericModal from '../../../../../components/modal/GenericModal';
import SpinnerLoading from '../../../../../components/pageComponents/SpinnerLoading';
import InputEditorHtml from '../../../../../components/modalComponents/InputEditorHtml';
import InputMultiSelect from '../../../../../components/modalComponents/InputMultiSelect';
import { useForm } from '../../../../../hooks/useForm/UseForm';
import { useSubmitForm } from '../../../../../hooks/useSubmitForm/useSubmitForm';
import useDidMountEffect from '../../../../../hooks/useDidMountEffect/useDidMountEffect';
import { crearCuponTiempoLibreAPI } from '../../../../../consumers/backendApisUrls';
import { getCurrentDate, getCurrentDay, getTodayDate, handleFind, handleFindDropdownMultiSelected } from '../../../../../helper/utils';
import { CuponCard, CuponImage, CuponName, CuponWrapper, CuponXmark } from '../../../components/StyledComponents';
import ActionGenericModal from '../../../../../components/modal/ActionGenericModal';

const SECTION_NAME = "asignarPlantillasCupon";
const KEYS = {lineamientos: "LineamientosHTML", desde: "FechaInicio", hasta: "FechaFin", cantidadDias: "CantidadDias", idsCargos: "IdsCargos"};
const REQUERIMIENTO_KEYS = { fecha: "Fecha", cargos: "Cargos", cantidadDias: "CantidadDias" };
const MessageEmptyRow = "Cupones no encontrados";

const ListaCuponesActivos = ({ rows, setRows, dropdowns, handleSelectedCupon, setModalEnabled, toggleModal: toggleMainModal, IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera, isFetching }) => {

  const handleCardClick = (id) => {
    const updatedRows = rows.map(o => {
      if (o.IdPlantillaCuponTiempoLibre === id){
        const isRequirementsVisible = true;
        return {...o, isRequirementsVisible };
      }
      return o;
    })
    setRows(updatedRows);
  }

  const handleCardClose = (id) => {
    const updatedRows = rows.map(o => {
      if (o.IdPlantillaCuponTiempoLibre === id){
        const isRequirementsVisible = false;
        for (let key of Object.values(KEYS)){
          if (o.hasOwnProperty(key)) {
            o[key]= null;
          }
        }
        return {...o, isRequirementsVisible}
      };
      return o;
    });
    setRows(updatedRows);
  }

  if (isFetching) return <div className='d-flex' style={{ height: "360px" }}><SpinnerLoading /></div>

  if (rows.length === 0) return <MessageEmpty>{MessageEmptyRow}</MessageEmpty>

  return (
    <CuponWrapper>
      {rows.map((row, i) => (
        <Card key={row.IdPlantillaCuponTiempoLibre} isRequirementsVisible ={row.isRequirementsVisible ?? false}>
          <div className='inner-content' onClick={() => setModalEnabled({ isEnable: true, component: CuponRequirements, data: { row, dropdowns, toggleMainModal, IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera } })}>
            <CuponImage src={row.ImagenCuponTiempoLibreURL} loading='lazy'/>
          </div>
          <div className="requirements">
            <div className='requirements-form'>
              <CuponXmark onClick={() => handleCardClose(row.IdPlantillaCuponTiempoLibre)}><FontAwesomeIcon icon="times"/></CuponXmark>
              <div className="col-sm-12"><InputEditorHtml label="Lineamientos" name={KEYS.lineamientos} value={row[KEYS.lineamientos]} onChange={(res) => handleSelectedCupon(row.IdPlantillaCuponTiempoLibre, res)} sectionName={`${SECTION_NAME}-${row.IdPlantillaCuponTiempoLibre}`} direction='column' minHeight="3rem"/></div>
              {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.fecha) &&
                <div className='displayFlexWrap'>
                  <div className="col-sm-6"><InputDate label="Desde" name={KEYS.desde} value={row[KEYS.desde]} onChange={(res) => handleSelectedCupon(row.IdPlantillaCuponTiempoLibre, res)} sectionName={`${SECTION_NAME}-${row.IdPlantillaCuponTiempoLibre}`} direction='column'/></div>
                  <div className="col-sm-6"><InputDate label="Hasta" name={KEYS.hasta} value={row[KEYS.hasta]} onChange={(res) => handleSelectedCupon(row.IdPlantillaCuponTiempoLibre, res)} sectionName={`${SECTION_NAME}-${row.IdPlantillaCuponTiempoLibre}`} direction='column'/></div>
                </div>
              }
              {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.cargos) &&
                <div className="col-sm-12"><InputMultiSelect label="Cargos" options={handleFind(dropdowns, KEYS.idsCargos)} name={KEYS.idsCargos} value={handleFindDropdownMultiSelected(dropdowns, row, KEYS.idsCargos)} onChange={(res) => handleSelectedCupon(row.IdPlantillaCuponTiempoLibre, res)} sectionName={`${SECTION_NAME}-${row.IdPlantillaCuponTiempoLibre}`} direction='column'/></div>
              }
            </div>
          </div>
        </Card>
      ))}
    </CuponWrapper>
  )
}

export default ListaCuponesActivos;

const CuponRequirements = ({ toggleModal, refreshPage, row, dropdowns, toggleMainModal, IdCuponeraTiempoLibre, fechaInicioCuponera, fechaFinCuponera }) => {

  const [formValues, setFormValues] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalEnabled, setModalEnabled] = useState({ isEnable: false, component: React.Fragment });
  const [isPastDateAccepted, setIsPastDateAccepted] = useState(false);

  const [submitForm] = useSubmitForm();
  const [onChange] = useForm(formValues, setFormValues);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    const api = crearCuponTiempoLibreAPI();
    const payload = getPayload();

    const datesBeforeToday = areDatesBeforeToday();

    if (datesBeforeToday && !isPastDateAccepted) {
      return setModalEnabled({ isEnable: true, component: ShowAceptarCancelarModal, data: { setIsPastDateAccepted }});
    }

    setIsSubmitting(true);
    try {
      await submitForm(Object.values(KEYS), api, payload, SECTION_NAME, successSubmitCallback, true, () => setIsPastDateAccepted(false));
    } catch (error) {
      console.error(e)
    } finally {
      setIsSubmitting(false);
    }
  }

  const getPayload = () => {
    const IdPlantillaCuponTiempoLibre = row.IdPlantillaCuponTiempoLibre;
    return {
      IdCuponeraTiempoLibre,
      IdPlantillaCuponTiempoLibre,
      ...formValues,
    }
  }

  const successSubmitCallback = async () => {
    toggleModal();
    toggleMainModal();
    await refreshPage();
  }

  const areDatesBeforeToday = () => {
    if (!(row.Requerimientos?.includes(REQUERIMIENTO_KEYS.fecha) && formValues[KEYS.desde] && formValues[KEYS.hasta])) return false;
    
    const TIME_STAMP = "T00:00:00";
    const today = new Date().setHours(0,0,0,0);
    const startDateTime = (new Date(formValues[KEYS.desde]+TIME_STAMP)).getTime();
    const endDateTime = new Date(formValues[KEYS.hasta]+TIME_STAMP).getTime();
    return (startDateTime < today) || (endDateTime < today);
  }

  useDidMountEffect(() => {
    if (isPastDateAccepted) handleSubmit();
  }, [isPastDateAccepted])

  return(
    <>
      <ModalEnabled modalEnabled={modalEnabled} setModalEnabled={setModalEnabled}/>
      <ModalForm showModal={toggleModal} onSubmit={handleSubmit} titulo="Asignar cupón" width="700px" isSubmitting={isSubmitting}>
        <div className="col-sm-12"><InputEditorHtml label="Lineamientos" name={KEYS.lineamientos} value={formValues[KEYS.lineamientos]} onChange={onChange} sectionName={SECTION_NAME} direction='column' minHeight="5rem"/></div>
        {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.fecha) &&
          <div className='displayFlexWrap'>
            <div className="col-sm-6"><InputDate label="Desde" name={KEYS.desde} value={formValues[KEYS.desde]} onChange={onChange} sectionName={SECTION_NAME} min={fechaInicioCuponera} max={fechaFinCuponera} direction='column'/></div>
            <div className="col-sm-6"><InputDate label="Hasta" name={KEYS.hasta} value={formValues[KEYS.hasta]} onChange={onChange} sectionName={SECTION_NAME} min={fechaInicioCuponera} max={fechaFinCuponera}  direction='column'/></div>
          </div>
        }
        {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.cantidadDias) &&
          <div className='displayFlexWrap'>
            <div className="col-sm-12"><InputNumber label="Cantidad de días" name={KEYS.cantidadDias} value={formValues[KEYS.cantidadDias]} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
          </div>
        }
        {row.Requerimientos?.includes(REQUERIMIENTO_KEYS.cargos) &&
          <div className="col-sm-12"><InputMultiSelect label="Cargos" options={handleFind(dropdowns, KEYS.idsCargos)} name={KEYS.idsCargos} value={handleFindDropdownMultiSelected(dropdowns, formValues, KEYS.idsCargos)} onChange={onChange} sectionName={SECTION_NAME} direction='column'/></div>
        }
      </ModalForm>
    </>
  )
}

const ShowAceptarCancelarModal = ({ toggleModal, setIsPastDateAccepted }) => {

  const handleClick = () => {
    setIsPastDateAccepted(true);
    toggleModal();
  }

  return (
    <GenericModal
      titulo="Alerta"
      showModal={toggleModal}
      width={"600px"}
    >
      <div className="modal-body pt-0 pl-0">
        <div className="container-fluid">
          <p>
            El cupón que estás asignando tiene la fecha no vigente.
          </p>
          ¿Está seguro de esta acción?
        </div>
      </div>
      <div className="modal-footer justify-content-end">
          <button 
            type='button'
            className="btn btn-primary generic-button-ficha mr-2" 
            onClick={() => handleClick()}>
            Aceptar
          </button>
          <button 
            type='button'
            className="btn btn-primary generic-button-ficha" 
            onClick={() => toggleModal()}>
            Cancelar
          </button>
      </div>
    </GenericModal>
  );
}

const Card = styled(CuponCard)`
  transition: all 0.5s ease;

  &:hover {
    box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.3);
  }

  .inner-content {
    cursor: pointer;
    filter: ${({ isBackgroundGray, isSelected }) => isBackgroundGray && !isSelected ? "grayscale(100%)" : ""};
  }
`;

const MessageEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  margin-top: 30px;
  font-size: 16px;
  color: red;
`;